import React from 'react';
import PropTypes from 'prop-types';

import Divider from '../../ui/components/Divider.js';
import Menu from '../../ui/components/Menu.js';
import MoreVertButton from '../../ui/components/MoreVertButton.js';
import {ALIGN_RIGHT, EXPAND_BOTTOM} from '../../ui/constants/PupupMenuConstants.js';
import ChangePasswordMenuEntry from './ChangePasswordMenuEntry.js';
import LogoutMenuEntry from './LogoutMenuEntry.js';

export default class AppBarOverflowButton extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.buttonElement = <MoreVertButton color='inherit' />;
	}

	render() {
		const {visible, children} = this.props;
		const childrenArray = React.Children.toArray(children);
		const atLeastOneChild = childrenArray.length > 0;

		return visible ? (
			<Menu id='app-bar-overflow-menu' buttonElement={this.buttonElement} menuComponent='div' align={ALIGN_RIGHT} expandDirection={EXPAND_BOTTOM}>
				{childrenArray}
				{atLeastOneChild && <Divider />}
				<ChangePasswordMenuEntry />
				<LogoutMenuEntry />
			</Menu>
		) : null;
	}
}

AppBarOverflowButton.propTypes = {
	visible: PropTypes.bool
};
AppBarOverflowButton.defaultProps = {
	visible: true
};
