import React from 'react';
import PropTypes from 'prop-types';

import FullWidthColumn from '../../../material-design/components/layout/FullWidthColumn.js';
import List from '../../../material-design/components/List.js';
import Row from '../../../material-design/components/Row.js';
import {debugLog} from '../../utils/DebugLog.js';
import {markAsHandled} from '../../utils/ErrorHandlingUtils.js';
import UnhandledErrorListItem from './UnhandledErrorListItem.js';

function DefaultErrorView({error}) {
	return (
		<Row>
			<FullWidthColumn>
				<List>
					<UnhandledErrorListItem error={error} />
				</List>
			</FullWidthColumn>
		</Row>
	);
}
DefaultErrorView.propTypes = {
	error: PropTypes.instanceOf(Error)
};

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {error: null};
	}

	render() {
		const {errorRenderer: ErrorViewComponent = DefaultErrorView, children} = this.props;
		const {error} = this.state;
		return error
			? <ErrorViewComponent error={error} />
			: children;
	}

	componentDidCatch(error, info) {
		const {onError} = this.props;
		markAsHandled(error);
		debugLog(`ErrorBoundary caught error: ${error}`);
		onError && onError(error, info);
	}

	static getDerivedStateFromError(error) {
		return {error};
	}
}

ErrorBoundary.propTypes = {
	errorRenderer: PropTypes.elementType,
	onError: PropTypes.func
};
