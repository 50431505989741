import {sequentialReentry} from './FunctionUtils.js';
import {shallowEqual} from './ObjectUtils';

export default class Observable {
	#atomicState = null;
	#notificationHelper = new NotificationHelper();

	constructor(initialState) {
		this.#atomicState = new AtomicValue(initialState);
	}

	updateValue(updater, ...updaterArgs) {
		const shouldNotify = this.#atomicState.updateValue(updater, updaterArgs) === true;
		if (shouldNotify) {
			this.#notificationHelper.notifySubscribers(this);
		}
	}

	getValue() {
		return this.#atomicState.getValue();
	}

	subscribe(subscriber) {
		return this.#notificationHelper.subscribe(subscriber);
	}
}

class AtomicValue {
	#value = undefined;
	constructor(initialState) {
		this.updating = false;
		this.scheduledUpdates = [];
		this.#value = initialState;
	}

	updateValue(updater, updaterArgs) {
		this.scheduledUpdates.push([updater, updaterArgs]);
		let valueChanged = false;
		if (!this.updating) {
			this.updating = true;
			valueChanged = this.processScheduledUpdates();
			this.updating = false;
		}
		return valueChanged;
	}

	processScheduledUpdates() {
		let valueChanged = false;
		const processedUpdates = this.scheduledUpdates;
		this.scheduledUpdates = [];
		while (processedUpdates.length > 0) {
			const [currentUpdater, currentUpdaterArgs] = processedUpdates.shift();
			const updateChangedValue = this.performStateUpdate(currentUpdater, currentUpdaterArgs);
			valueChanged = valueChanged || updateChangedValue;
			if (this.scheduledUpdates.length > 0) {
				const scheduledUpdateChangedValue = this.processScheduledUpdates();
				valueChanged = valueChanged || scheduledUpdateChangedValue;
			}
		}
		return valueChanged;
	}

	performStateUpdate(updater, updaterArgs) {
		const state = this.#value;
		const updaterIsFunction = (typeof updater) === 'function';
		const nextState = updaterIsFunction
			? updater(state, ...updaterArgs)
			: AtomicValue.#defaultUpdater(state, updater);
		const stateChanged = !shallowEqual(state, nextState);
		if (stateChanged) {
			this.#value = nextState;
		}
		return stateChanged;
	}

	getValue() {
		return this.#value;
	}

	static #defaultUpdater(state, partialState) {
		const partialStateIsObject = partialState && (Object.getPrototypeOf(partialState)) === Object.prototype;
		return partialStateIsObject ? {...state, ...partialState} : partialState;
	}
}

class NotificationHelper {
	constructor() {
		this.subscribers = new Set();
		this.nextSubscribers = this.subscribers;
		this.notifySubscribers = sequentialReentry(this.notifySubscribers.bind(this));
	}

	subscribe(subscriber) {
		this.nextSubscribers.add(subscriber);
		return () => {
			this.subscribers.delete(subscriber);
			this.nextSubscribers.delete(subscriber);
		};
	}

	notifySubscribers(...args) {
		this.nextSubscribers = new Set();
		this.subscribers.forEach(subscriber => subscriber(...args));
		if (this.nextSubscribers.size > 0) {
			this.nextSubscribers.forEach(newSubscriber => this.subscribers.add(newSubscriber));
		}
		this.nextSubscribers = this.subscribers;
	}
}
