import React from 'react';
import {mat3, vec2} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import ErrorBoundary from '../../commons/components/errors/ErrorBoundary.js';
import {
	getHeight,
	getHeightToWidthPixelSizeRatio,
	getInvertOutputColors,
	getPixelValueInformation,
	getWidth
} from '../../commons/data/aim/SynAdvancedImage.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe, memoizeLast} from '../../commons/utils/FunctionUtils.js';
import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import withViewerLayoutProps from '../flux/containers/withViewerLayoutProps.js';
import {calculateZoomPanMatrix} from '../utils/ImageViewerUtils.js';
import {getPanX, getPanY, getZoom} from '../utils/math/MatrixHelper.js';
import {getToolCursorClassName} from '../utils/ViewerUtils.js';
import {canLoadTextureOfSize, hasWebGLSupport} from '../utils/WebGLUtils.js';
import CanvasImageViewer from './CanvasImageViewer.js';
import ImageViewerGesturesRecognizer from './ImageViewerGesturesRecognizer.js';
import ViewerErrorDisplay from './ViewerErrorDisplay.js';
import WebGL16ImageViewer from './WebGLImageViewer.js';

import '../../../styles/viewer/components/ImageViewer.scss';
import '../../../styles/viewer/components/ToolCursorStyles.scss';

const ZOOM_SPEED_FACTOR = 5.0;
const ZOOM_SPEED = 1.0 / ZOOM_SPEED_FACTOR;
const RANGE_ALLOWANCE_FACTOR = 0.1; // see LevelTool::updateLevel(...)
const DEFAULT_TOOL = 'window';
const ZOOM_OUT_FACTOR_BASE = 0.9;
const ZOOM_IN_FACTOR_BASE = 1.1;
const WINDOW_CHANGE_RATE_DENOMINATOR = 500.0;

class ImageViewer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundOnWindowChange = this.onWindowChange.bind(this);
		this.boundOnPan = this.onPan.bind(this);
		this.boundOnZoom = this.onZoom.bind(this);
		this.boundOnPinchZoom = this.onPinchZoom.bind(this);
		this.boundOnToolChanged = this.onToolChanged.bind(this);
		this.boundOnViewerError = this.onViewerError.bind(this);
		this.calculateZoomPanMatrixMemoized = memoizeLast(calculateZoomPanMatrix);
		this.calculateTransformationMatrixMemoized = memoizeLast(calculateTransformationMatrix);

		const canvasProperties = ImageViewer.calculateCanvasDimensions(this.props);
		const {width: canvasWidth, height: canvasHeight} = canvasProperties;
		const {decodedImage, width, height, devicePixelRatio, defaultTool} = this.props;
		this.state = {
			viewerError: null,
			detectedTool: defaultTool,
			scaleMatrix: createScaleMatrixToFitImageInContainer(decodedImage, canvasWidth, canvasHeight),
			...ImageViewer.getWindowingParameters(decodedImage),
			...canvasProperties,
			prevDecodedImage: decodedImage,
			prevWidth: width,
			prevHeight: height,
			prevDevicePixelRatio: devicePixelRatio
		};
	}

	render() {
		const {containerWidth} = this.state;
		const {decodedImage, forwardRef} = this.props;
		return (!containerWidth || decodedImage === null)
			? ImageViewer.renderEmptyImageViewer(forwardRef)
			: this.renderImageViewer();
	}

	static renderEmptyImageViewer(forwardRef) {
		return <div ref={forwardRef} className='image-viewer--container' />;
	}

	renderImageViewer() {
		const {
			isPrintPreview, className, onTapLeft, onTapRight, onToolActivation, decodedImage, overlayRenderer,
			devicePixelRatio, forwardRef, swipeToolDetectors
		} = this.props;
		const {width, height, viewerError, detectedTool} = this.state;
		const finalTool = viewerError ? '' : detectedTool;

		const ImplementationSpecificImageViewer = ImageViewer.getImplementationSpecificImageViewer(decodedImage);
		const transformationMatrix = this.getTransformationMatrix();
		const finalClassName = combineClassNames('image-viewer--container', className, getToolCursorClassName(finalTool));
		const hasViewerError = Boolean(viewerError);
		const viewer = !hasViewerError && (
			<ErrorBoundary onError={this.boundOnViewerError}>
				<ImplementationSpecificImageViewer decodedImage={decodedImage}
				                                   isPrintPreview={isPrintPreview}
				                                   transformationMatrix={transformationMatrix}
				                                   windowWidth={this.getWindowWidth()}
				                                   windowCenter={this.getWindowCenter()}
				                                   width={width} height={height} devicePixelRatio={devicePixelRatio} />
			</ErrorBoundary>
		);
		return (
			<ImageViewerGesturesRecognizer className={finalClassName}
			                               onGoToNext={onTapRight}
			                               onGoToPrevious={onTapLeft}
			                               onWindow={this.boundOnWindowChange}
			                               onZoom={this.boundOnZoom}
			                               onPan={this.boundOnPan}
			                               onToolChanged={this.boundOnToolChanged}
			                               onPinchZoom={this.boundOnPinchZoom}
			                               onToolActivation={onToolActivation}
													 swipeToolDetectors={swipeToolDetectors}
			                               targetRef={forwardRef}>
				{viewer}
				{overlayRenderer && overlayRenderer(transformationMatrix, this.getContainerSize())}
				{this.renderViewerError()}
			</ImageViewerGesturesRecognizer>
		);
	}

	renderViewerError() {
		const {viewerError} = this.state;
		return viewerError && <ViewerErrorDisplay error={viewerError} />;
	}

	static getDerivedStateFromProps(props, state) {
		const {decodedImage: currentImage, width: widthProp, height: heightProp, devicePixelRatio} = props;
		const {prevDecodedImage: prevImage, prevWidth, prevHeight, prevDevicePixelRatio} = state;
		let changedState = {
			prevDecodedImage: currentImage,
			prevWidth: widthProp,
			prevHeight: heightProp,
			prevDevicePixelRatio: devicePixelRatio
		};
		const prevProps = {width: prevWidth, height: prevHeight, devicePixelRatio: prevDevicePixelRatio};
		if (ImageViewer.canvasDimensionsParametersChanged(props, prevProps)) {
			const newCanvasProperties = ImageViewer.calculateCanvasDimensions(props);
			if (newCanvasProperties) {
				changedState = {
					...changedState,
					...newCanvasProperties
				};
				changedState.scaleMatrix =
					createScaleMatrixToFitImageInContainer(currentImage, changedState.width, changedState.height);
			}
		} else if (dimensionsDifferBetween(currentImage, prevImage)) {
			const {width, height} = state;
			changedState = {
				...changedState,
				scaleMatrix: createScaleMatrixToFitImageInContainer(currentImage, width, height)
			};
		}

		if (prevImage !== currentImage) {
			changedState = Object.assign(changedState, ImageViewer.getWindowingParameters(currentImage));
		}

		return changedState;
	}

	static getWindowingParameters(decodedImage) {
		const {
			minPixelValue,
			maxPixelValue,
			pixelValueRange
		} = getPixelValueInformation(decodedImage);
		const extendedRangeAllowance = pixelValueRange * RANGE_ALLOWANCE_FACTOR;

		return {
			minWindowCenter: minPixelValue - extendedRangeAllowance,
			maxWindowCenter: maxPixelValue + extendedRangeAllowance,
			maxWindowWidth: pixelValueRange + (2 * extendedRangeAllowance) + 1,
			windowCenterStepFactor: getInvertOutputColors(decodedImage) ? -1.0 : 1.0
		};
	}

	onToolChanged(nextTool) {
		const {defaultTool} = this.props;
		const newTool = nextTool || defaultTool;
		this.setState({detectedTool: newTool});
	}

	getPanZoomMatrix() {
		const {pan, zoom} = this.props;
		return this.calculateZoomPanMatrixMemoized(pan, zoom);
	}

	getWindowWidth() {
		const {windowWidth} = this.props;
		return windowWidth;
	}

	getWindowCenter() {
		const {windowCenter} = this.props;
		return windowCenter;
	}

	updatePanZoomMatrix(panZoomMatrix) {
		const {onPanZoom} = this.props;
		const zoom = getZoom(panZoomMatrix);
		const panX = getPanX(panZoomMatrix);
		const panY = getPanY(panZoomMatrix);
		onPanZoom(Immutable.Map({x: panX, y: panY}), zoom);
	}

	componentDidMount() {
		const {setPrintable} = this.props;
		callSafe(setPrintable);
	}

	static calculateCanvasDimensions(props) {
		const {width, height, devicePixelRatio} = props;

		let canvasProperties = {};
		if (width !== undefined && height !== undefined) {
			const devicePixelContainerWidth = Math.floor(width * devicePixelRatio);
			const devicePixelContainerHeight = Math.floor(height * devicePixelRatio);

			canvasProperties = {
				containerWidth: width,
				containerHeight: height,
				width: devicePixelContainerWidth,
				height: devicePixelContainerHeight
			};
		}
		return canvasProperties;
	}

	onPinchZoom(panX, panY, pinchCenterX, pinchCenterY, zoomFactor) {
		const {devicePixelRatio} = this.props;
		const {containerWidth, containerHeight} = this.state;
		const newMatrix = applyPan(this.getPanZoomMatrix(), panX, panY, devicePixelRatio);
		this.updatePanZoomMatrix(applyFocusedZoom(
			newMatrix,
			pinchCenterX - containerWidth / 2,
			pinchCenterY - containerHeight / 2,
			zoomFactor,
			devicePixelRatio
		));
	}

	onPan(panX, panY) {
		const {devicePixelRatio} = this.props;
		this.updatePanZoomMatrix(applyPan(this.getPanZoomMatrix(), panX, panY, devicePixelRatio));
	}

	onZoom(focusX, focusY, deltaY) {
		const {devicePixelRatio} = this.props;
		const {containerWidth, containerHeight} = this.state;
		const deltaZoomFactor = Math.pow(
			deltaY < 0 ? ZOOM_OUT_FACTOR_BASE : ZOOM_IN_FACTOR_BASE, Math.abs(deltaY) * ZOOM_SPEED
		);

		this.updatePanZoomMatrix(applyFocusedZoom(
			this.getPanZoomMatrix(),
			focusX - containerWidth / 2,
			focusY - containerHeight / 2,
			deltaZoomFactor,
			devicePixelRatio
		));
	}

	onWindowChange(windowCenterChangeRate, windowWidthChangeRate) {
		const {onWindowChange} = this.props;
		if (onWindowChange) {
			const {maxWindowWidth, minWindowCenter, maxWindowCenter, windowCenterStepFactor} = this.state;
			const scaledDiffs = this.getScaledWindowValueDiffs(windowWidthChangeRate, windowCenterChangeRate);
			const clampedWindowWidth = Math.max(
				1.0,
				Math.min(maxWindowWidth, this.getWindowWidth() + scaledDiffs[0])
			);
			const clampedWindowCenter = Math.max(
				minWindowCenter,
				Math.min(maxWindowCenter, this.getWindowCenter() + (scaledDiffs[1] * windowCenterStepFactor))
			);
			callSafe(onWindowChange, clampedWindowWidth, clampedWindowCenter);
		}
	}

	getScaledWindowValueDiffs(widthChangeRate, centerChangeRate) {
		const {containerWidth: xPixelSpan, containerHeight: yPixelSpan, maxWindowWidth} = this.state;

		const signX = widthChangeRate < 0 ? -1 : 1;
		const signY = centerChangeRate < 0 ? -1 : 1;

		const squaredSpeedX = signX * Math.pow(widthChangeRate / WINDOW_CHANGE_RATE_DENOMINATOR, 2.0);
		const squaredSpeedY = signY * Math.pow(centerChangeRate / WINDOW_CHANGE_RATE_DENOMINATOR, 2.0);

		const containerPercentX = squaredSpeedX / xPixelSpan;
		const containerPercentY = squaredSpeedY / yPixelSpan;

		return [
			maxWindowWidth * containerPercentX,
			maxWindowWidth * containerPercentY
		];
	}

	getTransformationMatrix() {
		const {scaleMatrix} = this.state;
		return this.calculateTransformationMatrixMemoized(this.getPanZoomMatrix(), scaleMatrix);
	}

	getContainerSize() {
		const {containerWidth, containerHeight} = this.state;
		return {
			containerWidth,
			containerHeight
		};
	}

	static canvasDimensionsParametersChanged(props, prevProps) {
		const {width: prevWidth, height: prevHeight, devicePixelRatio: prevDevicePixelRatio} = prevProps;
		const {width, height, devicePixelRatio} = props;

		return prevWidth !== width || prevHeight !== height || prevDevicePixelRatio !== devicePixelRatio;
	}

	static getImplementationSpecificImageViewer(decodedImage) {
		return (
			(hasWebGLSupport() && canLoadTextureOfSize(getWidth(decodedImage), getHeight(decodedImage)))
				? WebGL16ImageViewer
				: CanvasImageViewer
		);
	}

	onViewerError(error) {
		this.setState({viewerError: error});
	}
}

ImageViewer.propTypes = {
	decodedImage: immutableMapPropType,
	isPrintPreview: PropTypes.bool,
	className: PropTypes.string,
	onTapLeft: PropTypes.func,
	onTapRight: PropTypes.func,
	onToolActivation: PropTypes.func,
	onPanZoom: PropTypes.func,
	onWindowChange: PropTypes.func,
	overlayRenderer: PropTypes.func,
	devicePixelRatio: PropTypes.number,
	windowCenter: PropTypes.number,
	windowWidth: PropTypes.number,
	pan: immutableMapPropType,
	zoom: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	setPrintable: PropTypes.func,
	forwardRef: withForwardRef.PropTypes.Ref,
	defaultTool: PropTypes.string,
	swipeToolDetectors: ImageViewerGesturesRecognizer.propTypes.swipeToolDetectors
};

ImageViewer.defaultProps = {
	defaultTool: DEFAULT_TOOL
};

function dimensionsDifferBetween(nextImage, currentImage) {
	return getWidth(nextImage) !== getWidth(currentImage) ||
		getHeight(nextImage) !== getHeight(currentImage) ||
		getHeightToWidthPixelSizeRatio(nextImage) !== getHeightToWidthPixelSizeRatio(currentImage);
}

function createScaleMatrixToFitImageInContainer(synAdvancedImage, containerWidth, containerHeight) {
	const scaleMatrix = mat3.create();
	if (synAdvancedImage && containerWidth && containerHeight) {
		const heightToWidthPixelSizeRatio = getHeightToWidthPixelSizeRatio(synAdvancedImage);

		const imageWidth = getWidth(synAdvancedImage);
		const imageHeightInPixels = getHeight(synAdvancedImage);

		const compensatedImageHeight = imageHeightInPixels * heightToWidthPixelSizeRatio;

		const containerRatio = containerWidth / containerHeight;
		const imgRatio = imageWidth / compensatedImageHeight;

		const scale = (containerRatio < imgRatio)
			? (containerWidth / imageWidth)
			: (containerHeight / compensatedImageHeight);
		const scaleVector = vec2.set(vec2.create(), scale, scale * heightToWidthPixelSizeRatio);

		mat3.scale(scaleMatrix, scaleMatrix, scaleVector);
	}
	return scaleMatrix;
}

function calculateTransformationMatrix(panAndZoomMatrix, scaleMatrix) {
	return mat3.multiply(mat3.create(), panAndZoomMatrix, scaleMatrix);
}

function applyPan(zoomMatrix, panX, panY, devicePixelRatio) {
	const newPanZoomMatrix = mat3.create();
	const panVector = vec2.fromValues(panX * devicePixelRatio, panY * devicePixelRatio);
	mat3.translate(newPanZoomMatrix, newPanZoomMatrix, panVector);
	mat3.multiply(newPanZoomMatrix, newPanZoomMatrix, zoomMatrix);
	return newPanZoomMatrix;
}

function applyFocusedZoom(zoomMatrix, focusX, focusY, zoomFactor, devicePixelRatio) {
	const scaleVector = vec2.set(vec2.create(), zoomFactor, zoomFactor);

	const scaledPanZoomMatrix = mat3.create();
	mat3.scale(scaledPanZoomMatrix, scaledPanZoomMatrix, scaleVector);
	mat3.multiply(scaledPanZoomMatrix, scaledPanZoomMatrix, zoomMatrix);

	const currentPoint = vec2.set(vec2.create(), focusX, focusY);
	const correctionVector = vec2.scale(currentPoint, currentPoint, (1 - zoomFactor));
	vec2.scale(correctionVector, correctionVector, devicePixelRatio);
	const panCorrectionMatrix = mat3.create();
	mat3.translate(panCorrectionMatrix, panCorrectionMatrix, correctionVector);

	mat3.multiply(scaledPanZoomMatrix, panCorrectionMatrix, scaledPanZoomMatrix);
	return scaledPanZoomMatrix;
}

export default withViewerLayoutProps(withForwardRef(ImageViewer, 'forwardRef'));
