import React from 'react';
import {styled} from '@mui/material/styles';

import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import VirtualizedOptionsList, {virtualizedOptionsListEntryHeightPixels} from './VirtualizedOptionsList.js';

export default withForwardRef(FixedVirtualizedOptionsList, 'forwardedRef');

const StyledVirtualizedOptionsList = styled(VirtualizedOptionsList)(({children}) => {
	const childrenAsArray = React.Children.toArray(children);
	const itemCount = childrenAsArray.length;
	const dynamicHeight = virtualizedOptionsListEntryHeightPixels * itemCount;
	return {
		'&.sized': {
			minHeight: `min(${dynamicHeight}px,30vh)`,
			maxHeight: '30vh'
		}
	};
});

function FixedVirtualizedOptionsList(props) {
	const {forwardedRef, className, ...remainingProps} = props;
	const finalContainerClasses = combineClassNames('sized', className);
	return <StyledVirtualizedOptionsList ref={forwardedRef} className={finalContainerClasses} {...remainingProps} />;
}

FixedVirtualizedOptionsList.propTypes = {
	forwardedRef: withForwardRef.PropTypes.Ref,
	...VirtualizedOptionsList.propTypes
};
