import {cancelled, put, take} from 'redux-saga/effects';

import InsufficientPermissionsError from '../../commons/api/InsufficientPermissionsError.js';
import UnauthorizedError from '../../commons/api/UnauthorizedError.js';
import {LOGOUT} from '../constants/SessionActionTypes.js';
import {logout} from '../flux/actions/SessionActions.js';
import createSessionTask from './createSessionTask.js';

export function* handleAllAuthErrorsTaskImpl() {
	let loggedIn = true;
	while (loggedIn && !(yield cancelled())) {
		const action = yield take();
		loggedIn = action.type !== LOGOUT;
		if (loggedIn && action.error === true) {
			const error = action.payload;
			if ((error instanceof InsufficientPermissionsError) || (error instanceof UnauthorizedError)) {
				yield put(logout(error));
			}
		}
	}
}

export default createSessionTask(handleAllAuthErrorsTaskImpl);
