import React from 'react';
import MUIListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';

export default React.memo(ListItemText);

function ListItemText(props) {
	const {children, primary, secondary} = props;
	return (
		<MUIListItemText primary={primary} secondary={secondary}>
			{children}
		</MUIListItemText>
	);
}

ListItemText.propTypes = {
	primary: PropTypes.node,
	secondary: PropTypes.node
};
