import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks/index.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';

import '../../../../styles/material-design/components/text/PrimaryTileText.scss';

export default function PrimaryTileText(props) {
	const {className, children} = props;
	const finalClassName = useMemoFactory(combineClassNames, 'material-primary-tile-text', className);
	return (
		<div className={finalClassName}>
			{children}
		</div>
	);
}

PrimaryTileText.propTypes = {
	className: PropTypes.string
};
