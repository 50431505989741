import React from 'react';

import useOnBeforeUnload from '../../webview/commons/utils/customHooks/useOnBeforeUnload.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import useTranslation from '../../webview/i18n/hooks/useTranslation.js';
import Alert from '../../webview/ui/components/Alert.js';
import {H5} from '../../webview/ui/components/typography/Typography.js';
import UploadProgressBar from './UploadProgressBar.js';
import UploadProgressFileCounter from './UploadProgressFileCounter.js';
import WebUploadCard from './WebUplaodCard.js';

export default function StepPerformUpload() {
	const message = useTranslation('UploadNotFinishedWarning');
	useOnBeforeUnload(message);
	return (
		<React.Fragment>
			<div>
				<Alert severity='info'>
					<SynFormattedMessage message='WindowCloseWarning' />
				</Alert>
			</div>
			<WebUploadCard>
				<SynFormattedMessage element={H5} message='UploadHeading' />
				<div>
					<UploadProgressBar />
					<UploadProgressFileCounter />
				</div>
			</WebUploadCard>
		</React.Fragment>
	);
}
