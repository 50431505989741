import React from 'react';
import PropTypes from 'prop-types';

import {getInstanceNumber, loadSynAdvancedDomImages} from '../../commons/data/aim/SynAdvancedImage.js';
import CachingResourceLoader from '../../commons/utils/CachingResourceLoader.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {memoizeLast} from '../../commons/utils/FunctionUtils.js';
import GenericFileMetaDataOverlayContainer from '../flux/containers/GenericFileMetaDataOverlayContainer.js';
import ImageViewerContainer from '../flux/containers/ImageViewerContainer.js';
import ImageViewerSVGOverlayContainer from '../flux/containers/ImageViewerSVGOverlayContainer.js';
import {createAnnotationItem} from '../utils/AnnotationUtils.js';
import {getEmptyImage, releaseImageSafe} from '../utils/ImageViewerUtils.js';
import createViewerItemViewer from './createViewerItemViewer.js';
import wrapInImageDecoder from './wrapInImageDecoder.js';

const CACHING_IMAGE_DECODER = new CachingResourceLoader(loadSynAdvancedDomImages, releaseImageSafe, getInstanceNumber);
const ImageViewerComponent = wrapInImageDecoder(
	ImageViewerContainer,
	CACHING_IMAGE_DECODER.loadResource.bind(CACHING_IMAGE_DECODER),
	CACHING_IMAGE_DECODER.freeLoadedResource.bind(CACHING_IMAGE_DECODER),
	getEmptyImage()
);

class GenericImageViewer extends React.PureComponent {
	constructor(props) {
		super(props);
		this.boundRenderOverlays = this.renderOverlays.bind(this);
		this.createFileAnnotationItem = memoizeLast(fileId => createAnnotationItem('file', fileId));
	}

	render() {
		const {activateViewer, rawImage} = this.props;
		return (
			<ImageViewerComponent onTapLeft={activateViewer}
								  onTapRight={activateViewer}
								  rawImage={rawImage}
								  overlayRenderer={this.boundRenderOverlays} />
		);
	}
	
	renderOverlays(transformationMatrix, containerSize) {
		const {fileViewerItem, fileId} = this.props;
		return (
			<React.Fragment>
				<ImageViewerSVGOverlayContainer viewerItem={fileViewerItem}
							  transformationMatrix={transformationMatrix} {...containerSize}
							  annotationItems={[this.createFileAnnotationItem(fileId)]} />
				{/* NOTE: Always render MetaDataOverlay as last element, to stay above all other overlays */}
				<GenericFileMetaDataOverlayContainer fileId={fileId} {...containerSize} />
			</React.Fragment>
		);
	}
}

GenericImageViewer.propTypes = {
	activateViewer: PropTypes.func,
	rawImage: immutableMapPropType,
	fileViewerItem: immutableMapPropType,
	fileId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	])
};
GenericImageViewer.supportsImageTools = true;

export default createViewerItemViewer(GenericImageViewer, 'generic-image-viewer');

