import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../utils/ObjectUtils';
import {getHocDisplayName} from '../utils/ReactUtils.js';

class DelayedPropsComponent extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundClearPropsOverlay = this.clearPropsOverlay.bind(this);
		this.currentTimerId = null;

		this.state = {
			propsOverlay: this.getInitialPropsOverlay(),
			prevProps: props
		};
	}

	render() {
		const {component: ActualComponent} = this.props;
		const {propsOverlay} = this.state;
		const viewerProps = {
			...cloneWithoutProperties(this.props, 'delayedProps', 'delay'),
			...propsOverlay
		};
		return <ActualComponent {...viewerProps} />;
	}


	getInitialPropsOverlay() {
		const {delayedProps} = this.props;
		let propsOverlay = {};
		if (delayedProps && delayedProps.length > 0) {
			propsOverlay = delayedProps.reduce((accumulatedPropsOverlay, propName) => {
				accumulatedPropsOverlay[propName] = undefined;
				return accumulatedPropsOverlay;
			}, {});
		}
		return propsOverlay;
	}

	componentDidMount() {
		const {delay} = this.props;
		const {propsOverlay} = this.state;
		if (propsOverlay !== null) {
			this.schedulePropsApplication(delay);
		}
	}

	componentWillUnmount() {
		this.cancelTimer();
	}

	schedulePropsApplication(delay = 0) {
		this.cancelTimer();
		if (delay !== 0) {
			this.currentTimerId = window.setTimeout(this.boundClearPropsOverlay, delay);
		}
	}

	cancelTimer() {
		if (this.currentTimerId !== null) {
			window.clearTimeout(this.currentTimerId);
			this.currentTimerId = null;
		}
	}

	clearPropsOverlay() {
		this.setState({propsOverlay: null});
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.delayedProps && nextProps.delayedProps.length > 0) {
			const delayedPropsUpdated = nextProps.delayedProps.some(
				propName => nextProps[propName] !== prevState.prevProps[propName]
			);
			if (delayedPropsUpdated) {
				const propsOverlay = nextProps.delayedProps.reduce((accumulatedPropsOverlay, propName) => {
					accumulatedPropsOverlay[propName] = prevState.prevProps[propName];
					return accumulatedPropsOverlay;
				}, {});
				return {
					propsOverlay,
					prevProps: nextProps
				};
			}
		} else if (prevState.propsOverlay !== null) {
			return {
				propsOverlay: null,
				prevProps: nextProps
			};
		}
		return null;
	}

	componentDidUpdate(prevProps, prevState) {
		const {delay} = this.props;
		const {propsOverlay} = this.state;
		if (propsOverlay !== null && prevState.propsOverlay !== propsOverlay) {
			this.schedulePropsApplication(delay);
		}

		if (prevState.propsOverlay !== null && propsOverlay === null) {
			this.cancelTimer();
		}
	}
}

DelayedPropsComponent.propTypes = {
	delayedProps: PropTypes.arrayOf(PropTypes.string),
	delay: PropTypes.number,
	component: PropTypes.elementType
};

export default function createDelayedPropsComponent(ActualComponent) {
	function DelayedPropsComponentWrapper(props) {
		return <DelayedPropsComponent component={ActualComponent} {...props} />;
	}

	DelayedPropsComponentWrapper.displayName = getHocDisplayName(ActualComponent, 'createDelayedPropsComponent');
	DelayedPropsComponentWrapper.propTypes = {
		component: PropTypes.elementType
	};

	return React.memo(DelayedPropsComponentWrapper);
}
