import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {STEPPER_CURRENT_STEP, STEPPER_DATA} from '../constants/StepperStateFieldNames.js';
import {alwaysValid, buildCombinedSelector, buildDefaultFooterState} from '../utils/StepperUtils.js';
import {createStructureSelector} from './StructuredDataSelectors.js';

function createAlwaysValidSelector() {
	return () => alwaysValid();
}

function createDefaultFooterStateSelector(baseSelectors, stepIndex, steps) {
	return () => buildDefaultFooterState(stepIndex, steps);
}

function createStepperSelectors(stepperId, steps) {
	const nrSteps = steps.length;
	function selectNrSteps() {
		return nrSteps;
	}

	const selectStepperState = createStructureSelector(stepperId);
	const createStepperFieldSelector = (rootSelector, fieldName, defaultValue = null) => createSelector(
		rootSelector,
		stepperState => (stepperState ? stepperState.get(fieldName, defaultValue) : defaultValue)
	);
	const selectCurrentStep = createStepperFieldSelector(selectStepperState, STEPPER_CURRENT_STEP, 0);
	const selectData = createStepperFieldSelector(selectStepperState, STEPPER_DATA, Immutable.Map());

	const selectHasFinished = createSelector(
		selectCurrentStep,
		currentStep => currentStep >= nrSteps
	);
	const selectWasCancelled = createSelector(
		selectCurrentStep,
		currentStep => currentStep < 0
	);

	const baseSelectors = {
		selectNrSteps,
		selectCurrentStep,
		selectData,
		selectHasFinished,
		selectWasCancelled,
		createSelectDataField:
			(fieldName, defaultValue = null) => createStepperFieldSelector(selectData, fieldName, defaultValue)
	};

	const selectIsStepComplete = createSelector(
		buildCombinedSelector(baseSelectors, steps, 'createSelectDataValid', createAlwaysValidSelector),
		isStepDataValid => Boolean(isStepDataValid)
	);
	const selectFooterState =
		buildCombinedSelector(baseSelectors, steps, 'createSelectFooterState', createDefaultFooterStateSelector);

	return Object.assign(baseSelectors, {
		selectIsStepComplete,
		selectFooterState
	});
}

export default createStepperSelectors;
