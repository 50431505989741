import {shallowEqual} from './ObjectUtils/index.js';

const ALL_SETTLED_FULFILLED = 'fulfilled';
const ALL_SETTLED_REJECTED = 'rejected';
const ALL_SETTLED_VALUE = 'value';
const ALL_SETTLED_REASON = 'reason';

export function isAllSettledFulFilled(result) {
	return result.status === ALL_SETTLED_FULFILLED;
}

export function isAllSettledRejected(result) {
	return result.status === ALL_SETTLED_REJECTED;
}

export function getAllSettledPayLoad(result) {
	let payload = null;

	if (isAllSettledFulFilled(result)) {
		payload = result[ALL_SETTLED_VALUE];
	} else if (isAllSettledRejected(result)) {
		payload = result[ALL_SETTLED_REASON];
	}

	return payload;
}

export function cancellable(promise) {
	return new Cancellable(promise);
}

class Cancellable {
	constructor(promise) {
		this.cancelCallbacks = null;
		this.wasCancelled = false;
		this.cancellablePromise = new Promise((resolve, reject) => {
			promise
				.then(result => {
					if (!this.wasCancelled) {
						resolve(result);
					}
				})
				.catch(error => {
					if (!this.wasCancelled) {
						reject(error);
					}
				});
		});
	}

	cancel() {
		if (!this.wasCancelled) {
			this.wasCancelled = true;
			this.#invokeCancelCallbacks();
		}
	}

	cancelled(callback) {
		if (this.cancelCallbacks === null) {
			this.cancelCallbacks = new Set();
		}
		this.cancelCallbacks.add(callback);
		if (this.wasCancelled) {
			callback();
		}
		return this;
	}

	maybe(...args) {
		return this.#cancelableAfter(this.cancellablePromise.then, ...args);
	}

	catch(...args) {
		return this.#cancelableAfter(this.cancellablePromise.catch, ...args);
	}

	finally(...args) {
		return this.#cancelableAfter(this.cancellablePromise.finally, ...args);
	}

	#cancelableAfter(method, ...args) {
		const newCancellable = new Cancellable(method.apply(this.cancellablePromise, args));
		this.cancelled(newCancellable.cancel.bind(this));
		return newCancellable;
	}

	#invokeCancelCallbacks() {
		if (this.cancelCallbacks) {
			this.cancelCallbacks.forEach(callback => callback());
		}
	}
}

/**
 * Wraps the passed promiseFactory in an enhancing factory.
 *
 * The returned factory returns the previously created and currently pending promise
 * if the argsAreEqual function returns true for the calling arguments
 * and the arguments the current promise was created with.
 *
 * @param promiseFactory - the original promise creation function to wrap.
 * @param argsAreEqual - function returning true if the passed arguments are equal (default: shallowEqual)
 */
export function reusePending(promiseFactory, argsAreEqual = shallowEqual) {
	let pendingPromise = null;
	let pendingArguments = null;
	return (...args) => {
		if (!pendingPromise || !argsAreEqual(args, pendingArguments)) {
			pendingPromise = promiseFactory(...args).finally(() => {
				pendingPromise = null;
				pendingArguments = null;
			});
			pendingArguments = args;
		}
		return pendingPromise;
	};
}

/**
 * Wraps the passed promiseFactory in an enhancing factory producing a cancellable.
 *
 * The returned function will automatically cancel a pending cancellable from the previous call
 * and replace it with the newly created one.
 *
 * @param promiseFactory - Function returning a promise.
 * @return {function(...[*]): Cancellable} - cancellable Promise.
 */
export function onlyMostResentCancellable(promiseFactory) {
	let pendingCancellable = null;
	return (...args) => {
		if (pendingCancellable) {
			pendingCancellable.cancel();
			pendingCancellable = null;
		}
		const newCancellable = cancellable(promiseFactory(...args))
			.finally(() => {
				if (newCancellable === pendingCancellable) {
					pendingCancellable = null;
				}
			});
		pendingCancellable = newCancellable;
		return newCancellable;
	};
}
