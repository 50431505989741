import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import GeneralTranslator from '../translators/GeneralTranslator.js';
import SynFormattedMessage from './SynFormattedMessage.js';

export default function NumericDate(props) {
	const {date, ...remainingProps} = props;
	const messageParams = useMemo(() => ({date}), [date]);
	return <SynFormattedMessage translator={GeneralTranslator} message='ShortDate' messageParams={messageParams} {...remainingProps} />;
}

NumericDate.propTypes = {
	date: PropTypes.instanceOf(Date)
};
