import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import BrickRegistry from '../BrickRegistry.js';
import BrickRegistryContext from './BrickRegistryContext.js';

export default React.memo(BrickRegistryProvider);

function BrickRegistryProvider(props) {
	const {children} = props;
	const renderChainedRegistryProvider = useMemoFactory(createRegistryRenderer, children);
	return (
		<BrickRegistryContext.Consumer>
			{renderChainedRegistryProvider}
		</BrickRegistryContext.Consumer>
	);
}

function createRegistryRenderer(children) {
	return function RegistryRenderer(fallbackRegistry) {
		return (
			<ChainedBrickRegistryProvider fallbackRegistry={fallbackRegistry}>
				{children}
			</ChainedBrickRegistryProvider>
		);
	};
}

function ChainedBrickRegistryProvider(props) {
	const {children, fallbackRegistry} = props;
	const registry = useMemoFactory(createRegistry, fallbackRegistry);
	return (
		<BrickRegistryContext.Provider value={registry}>
			{children}
		</BrickRegistryContext.Provider>
	);
}
ChainedBrickRegistryProvider.propTypes = {
	fallbackRegistry: PropTypes.instanceOf(BrickRegistry)
};

function createRegistry(fallbackRegistry) {
	return new BrickRegistry(fallbackRegistry);
}
