import Immutable from 'immutable';
import {createSelector} from 'reselect';

import createCrudResourceApiActions from '../../actions/crudresources/createCrudResourceApiActions.js';
import {CLEAR_RESOURCE_DATA} from '../../constants/synCrud/ActionTypes.js';
import createCrudResourceApiSelectors from '../../selectors/crudresources/createCrudResourceApiSelectors.js';
import {createAction} from '../../utils/ActionUtils.js';
import {identity} from '../../utils/FunctionUtils.js';
import {mapProperties} from '../../utils/ObjectUtils';

const clearResourceResults = createAction(CLEAR_RESOURCE_DATA);

function createResourceActions(resourceId, crudResourceDefinition) {
	const {actionsCreator = identity, api} = crudResourceDefinition;

	const clearResourceResultsAction = clearResourceResults(resourceId);
	const apiActions = createCrudResourceApiActions(resourceId, api);
	const additionalActions = {
		clear: () => clearResourceResultsAction,
		load: () => apiActions.get()
	};
	const crudResourceActions = Object.assign(additionalActions, apiActions);
	return actionsCreator(crudResourceActions, api);
}

function createResourceSelectors(crudResourceDefinition) {
	const {selectorsCreator = identity} = crudResourceDefinition;
	const crudResourceSelectors = createCrudResourceApiSelectors();
	return selectorsCreator(crudResourceSelectors);
}

function createCrudResourceFluxApi(crudResourcesDefinitions) {
	return mapProperties(crudResourcesDefinitions,
		(crudResourceDefinition, resourceId) => ({
			actions: createResourceActions(resourceId, crudResourceDefinition),
			selectors: createResourceSelectors(crudResourceDefinition)
		})
	);
}

function mountCrudResourceFluxApi(storeSelector, crudFluxApis) {
	return mapProperties(crudFluxApis,
		({actions, selectors}, resourceId) => ({
			actions,
			selectors: mountSelectors(storeSelector, resourceId, selectors)
		})
	);
}

function mountSelectors(storeSelector, resourceId, selectors) {
	const resourceStoreSelector = createSelector(storeSelector, store => store.get(resourceId, Immutable.Map()));
	return mapProperties(selectors, selector => createSelector(resourceStoreSelector, selector));
}

export {
	createCrudResourceFluxApi,
	mountCrudResourceFluxApi
};
