import Immutable from 'immutable';
import {createSelector} from 'reselect';

export default function createScopedSelectors(keysSelector, selectorCreator) {
	let savedSelectors = Immutable.Map();
	let currentSelector = null;

	function recreateSelector(keys) {
		const boundSelectors = [];
		const boundSelectorsKeysSequence = [];
		savedSelectors = keys.reduce(
			(finalSelectors, key) => {
				const boundSelector = savedSelectors.get(key) || selectorCreator(key);
				boundSelectors.push(boundSelector);
				boundSelectorsKeysSequence.push(key);
				return finalSelectors.set(key, boundSelector);
			},	Immutable.Map().asMutable()
		).asImmutable();

		return createSelector(...boundSelectors, (...results) => results.reduce(
			(finalMap, result, index) => finalMap.set(boundSelectorsKeysSequence[index], result),
			Immutable.Map().asMutable()
		).asImmutable());
	}

	return function scopedSelector(state) {
		const nextKeys = keysSelector(state);
		if (!Immutable.is(nextKeys, savedSelectors.keySeq())) {
			currentSelector = recreateSelector(nextKeys);
		}
		return currentSelector ? currentSelector(state) : Immutable.Map();
	};
}
