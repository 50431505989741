import React from 'react';

import ExpandButton from '../../../ui/components/ExpandButton.js';
import {useMemoFactory} from '../../utils/customHooks';
import {mergeClasses} from '../../utils/StyleUtils.js';

import '../../../../styles/commons/components/webview/SidebarToggleButton.scss';

const SIDEBAR_TOGGLE_BUTTON_CLASSES = {
	root: 'sidebar-toggle-button--root',
	checked: 'sidebar-toggle-button--checked'
};

export default function SidebarToggleButton(props) {
	const {classes: classesFromProps, ...remainingProps} = props;
	const classes = useMemoFactory(mergeClasses, SIDEBAR_TOGGLE_BUTTON_CLASSES, classesFromProps);
	return <ExpandButton classes={classes} {...remainingProps} aria-hidden='true' />;
}
SidebarToggleButton.propTypes = ExpandButton.propTypes;
