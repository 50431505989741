/**
 * Extracts a number from the passed array by adding up all count entries starting from offset.
 * For each entry the previous result is multiplied by 256 and then added to the current entry.
 * So the result for reading a number from the first two entries of a Uint8Array would be composed by:
 * n = typedArray[0] * 256 + typedArray[1]
 *
 * This implies that the byte ordering is expected to be BIG-ENDIAN!
 *
 * NOTE:
 * This function only supports data stored in an Uint8Array.
 * It will return undefined for any invalid input.
 *
 * @param typedArray {Uint8Array} the data to read the number from
 * @param count {Number} How many entries should be used to read the number
 * @param offset {Number} offset into the typedArray to start extracting the number
 */
export function readNumber(typedArray, count, offset = 0) {
	let finalNumber;
	if ((typedArray instanceof Uint8Array) && validOffsetAndCount(typedArray, offset, count)) {
		finalNumber = readNumberFromUint8Array(typedArray, count, offset);
	}
	return finalNumber;
}

/**
 * Utilizes readNumber to read an unsigned short from the passed array.
 * NOTE: Byte ordering in the array is expected to be BIG-ENDIAN!
 * @param typedArray {Uint8Array} the array to read the number from.
 * @param offset {Number} offset within the array to start reading from.
 * @returns {Number} containing the result
 */
export function readUnsignedShort(typedArray, offset = 0) {
	return readNumber(typedArray, 2, offset);
}

/**
 * Utilizes readNumber to read an unsigned long from the passed array.
 * NOTE: Byte ordering in the array is expected to be BIG-ENDIAN!
 * @param typedArray {Uint8Array} the array to read the number from.
 * @param offset {Number} offset within the array to start reading from.
 * @returns {Number} containing the result
 */
export function readUnsignedLong(typedArray, offset = 0) {
	const readCount = 4;
	return readNumber(typedArray, readCount, offset);
}

function validOffsetAndCount(typedArray, offset, count) {
	return offset >= 0 && count > 0 && (offset + count) <= typedArray.length;
}

function readNumberFromUint8Array(unsignedTypedArray, count, offset) {
	const entryWeight = 256;
	let finalResult = 0;
	const endIndex = offset + count;
	for (let entryIndex = offset; entryIndex < endIndex && entryIndex < unsignedTypedArray.length; ++entryIndex) {
		finalResult = finalResult * entryWeight + unsignedTypedArray[entryIndex];
	}
	return finalResult;
}

export function createView(TypedArrayType, source) {
	return new TypedArrayType(source.buffer, source.byteOffset, source.byteLength / TypedArrayType.BYTES_PER_ELEMENT);
}
