import PasswordResetPageContainer from '../../../authentication/flux/containers/PasswordResetPageContainer.js';
import {selectCanReset} from '../../../authentication/flux/selectors/NewPasswordSelectors.js';

function createPasswordResetComponentSelector(pageSelector) {
	return state => (selectCanReset(state) ? PasswordResetPageContainer : pageSelector(state));
}

export default function createPasswordResetRoute(route) {
	return route.update('pageSelector', pageSelector => createPasswordResetComponentSelector(pageSelector));
}
