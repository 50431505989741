import {vec2} from 'gl-matrix';

import {memoizeLast} from './FunctionUtils.js';
import generateUniqueKey from './generateUniqueKey.js';
import {escapeToHTML} from './StringUtils.js';
import {reduceClasses} from './StyleUtils.js';

import '../../../styles/commons/utils/DOMUtils.scss';

export function getClientBoundingRectSafe(node) {
	return node
		? node.getBoundingClientRect()
		: {x: 0, y: 0, left: 0, right: 0, top: 0, bottom: 0, width: 0, height: 0};
}

export function getTopLeftPosition(rootElement) {
	let offsetLeft = 0;
	let offsetTop = 0;
	let currentElement = rootElement;
	while (currentElement) {
		if (!isNaN(currentElement.offsetLeft)) {
			offsetLeft += currentElement.offsetLeft;
		}
		if (!isNaN(currentElement.offsetTop)) {
			offsetTop += currentElement.offsetTop;
		}
		currentElement = currentElement.offsetParent;
	}
	return vec2.set(vec2.create(), offsetLeft, offsetTop);
}

export function findAncestorBy(rootElement, ancestorMatcher) {
	let currentElement = rootElement;
	while (currentElement && (!ancestorMatcher(currentElement))) {
		currentElement = currentElement.parentNode;
	}
	return currentElement || null;
}

export function findAncestorOfType(rootElement, parentType) {
	return findAncestorBy(rootElement,
		element => Boolean(element.tagName) && element.tagName.toLowerCase() === parentType.toLowerCase()
	);
}

const {container: MEASURE_SPAN_CONTAINER, span: MEASURE_SPAN} = createOffScreenSpan();
function createOffScreenSpan() {
	const zeroHeightDivElement = document.createElement('div');
	zeroHeightDivElement.className = 'text-measure--container';
	zeroHeightDivElement.id = `text-measure-helper-${generateUniqueKey()}`;
	const spanElement = document.createElement('span');
	zeroHeightDivElement.appendChild(spanElement);
	document.getElementsByTagName('body')[0].appendChild(zeroHeightDivElement);
	return {container: zeroHeightDivElement, span: spanElement};
}

/**
 * Returns an object containing the measured width and height of the passed text.
 * @param text {string} the text to measure (will be HTML-escaped automatically)
 * @param className {string|number} CSS-Class to use for measuring the text.
 * @param [fontSize] {number} the optional font-size to use for text measuring. It is used as the base font size in the
 *       parent element
 * @param [lineHeight] {number} the optional line-height to use for text measuring. It is used as the base line size in
 *       the parent element
 * @returns {{width,height}} object containing the width and height of the measured text.
 */
export function measureText(text, className = '', fontSize = null, lineHeight = null) {
	const dimensions = {
		width: 0,
		height: 0
	};
	if (text) {
		//reset base font sizes and line heights
		MEASURE_SPAN_CONTAINER.style.removeProperty('fontSize');
		MEASURE_SPAN_CONTAINER.style.removeProperty('lineHeight');

		if (fontSize !== null) {
			MEASURE_SPAN_CONTAINER.style.fontSize = `${fontSize}px`;
		}
		if (lineHeight !== null) {
			MEASURE_SPAN_CONTAINER.style.lineHeight = `${lineHeight}px`;
		}

		MEASURE_SPAN.className = className || '';
		MEASURE_SPAN.innerHTML = escapeToHTML(text);
		dimensions.width = MEASURE_SPAN.offsetWidth;
		dimensions.height = MEASURE_SPAN.offsetHeight;
		MEASURE_SPAN.textContent = '';
		MEASURE_SPAN.className = '';
	}
	return dimensions;
}

function determineVisibilityRelatedNames() {
	// Opera 12.10 and Firefox 18 and later support
	let names = {
		hiddenProperty: 'hidden',
		visibilityChangeEvent: 'visibilitychange'
	};
	if (typeof document.hidden === 'undefined') {
		if (typeof document.msHidden !== 'undefined') {
			names = {
				hiddenProperty: 'msHidden',
				visibilityChangeEvent: 'msvisibilitychange'
			};
		} else if (typeof document.webkitHidden !== 'undefined') {
			names = {
				hiddenProperty: 'webkitHidden',
				visibilityChangeEvent: 'webkitvisibilitychange'
			};
		}
	}
	return names;
}

let DOCUMENT_VISIBILITY_RELATED_NAMES;
function getVisibilityRelatedNames() {
	if (DOCUMENT_VISIBILITY_RELATED_NAMES === undefined) {
		DOCUMENT_VISIBILITY_RELATED_NAMES = determineVisibilityRelatedNames();
	}
	return DOCUMENT_VISIBILITY_RELATED_NAMES;
}

export function isDocumentVisible() {
	const visibilityRelatedNames = getVisibilityRelatedNames();
	return document[visibilityRelatedNames.hiddenProperty] === false;
}

export function getVisibilityChangeEventName() {
	return getVisibilityRelatedNames().visibilityChangeEvent;
}

export function nodeContains(containerNode, child) {
	return Boolean(containerNode) && containerNode.contains(child);
}

export function getSumOfChildNodesHeights(domElem) {
	let height = 0;
	if (domElem) {
		const elements = domElem.childNodes;
		for (const element of elements) {
			if (element.getBoundingClientRect !== undefined) {
				height += element.getBoundingClientRect().height;
			}
		}
	}
	return height;
}

export function addDocumentClassNames(classNames) {
	modifyDocumentClassNames(documentClasses => reduceClasses(documentClasses, classNames));
}

export function removeDocumentClassNames(classNames) {
	modifyDocumentClassNames(
		documentClasses => reduceClasses(documentClasses, classNames,
			(currentClassNames, deletedClassNames) => {
				deletedClassNames.forEach(currentClassNames.delete.bind(currentClassNames));
				return currentClassNames;
			})
	);
}

function modifyDocumentClassNames(modificationFunction) {
	document.documentElement.className = modificationFunction(document.documentElement.className);
}

export const testFileInputDirectory = memoizeLast(() => {
	const inputElem = document.createElement('input');
	return 'directory' in inputElem || 'webkitdirectory' in inputElem;
});
