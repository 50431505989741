import React, {useReducer, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {useEffectEasily, useMemoFactory} from '../utils/customHooks';
import {getSumOfChildNodesHeights} from '../utils/DOMUtils.js';
import {callSafe} from '../utils/FunctionUtils.js';
import {combineClassNames} from '../utils/StyleUtils.js';

import '../../../styles/commons/components/Collapsible.scss';

export default React.memo(Collapsible);
export const COLLAPSIBLE_DEFAULT_TRANSITION_TIME = 200;

const STATE_COLLAPSED_NO_CHILDREN = 'stateCollapsedNoChildren';
const STATE_COLLAPSED_WITH_CHILDREN = 'stateCollapsedWithChildren';
const STATE_PRE_EXPANDED = 'statePreExpanded';
const STATE_EXPANDED = 'stateExpanded';

function Collapsible(props) {
	const {
		element: Element, onExpandEnd, onCollapseEnd, onExpandStart, onCollapseStart, minimalHeight, className,
		isExpanded: shouldBeExpanded, transitionTime, removeCollapsedChildren, children
	} = props;
	const [collapsibleState, dispatch] = useReducer(
		reducer, {shouldBeExpanded, removeCollapsedChildren}, getInitialCollapsibleState
	);
	const elementRef = useRef(null);
	const [isAnimating, setIsAnimating] = useState(false);
	useEffectEasily(
		handleCollapsibleStateChange, collapsibleState, shouldBeExpanded, removeCollapsedChildren, onCollapseStart,
		onExpandStart, isAnimating, setIsAnimating, dispatch
	);
	const handleTransitionEnd = useMemoFactory(
		createTransitionEndHandler, setIsAnimating, shouldBeExpanded, collapsibleState, removeCollapsedChildren,
		dispatch, onExpandEnd, onCollapseEnd, elementRef
	);
	const style = useMemoFactory(
		getStyle, collapsibleState, minimalHeight, elementRef, shouldBeExpanded, transitionTime
	);
	const combinedClassName = useMemoFactory(getClassNames, className, shouldBeExpanded, isAnimating);
	return (
		<Element ref={elementRef} style={style} onTransitionEnd={handleTransitionEnd} className={combinedClassName}>
			{isCurrentState(collapsibleState, STATE_COLLAPSED_NO_CHILDREN) ? null : children}
		</Element>
	);
}

Collapsible.propTypes = {
	element: PropTypes.elementType,
	className: PropTypes.string,
	isExpanded: PropTypes.bool,
	transitionTime: PropTypes.number,
	removeCollapsedChildren: PropTypes.bool,
	onExpandEnd: PropTypes.func,
	onCollapseEnd: PropTypes.func,
	onExpandStart: PropTypes.func,
	onCollapseStart: PropTypes.func,
	minimalHeight: PropTypes.number
};

Collapsible.defaultProps = {
	element: 'ul',
	isExpanded: false,
	transitionTime: COLLAPSIBLE_DEFAULT_TRANSITION_TIME,
	removeCollapsedChildren: false,
	minimalHeight: 0
};

function getClassNames(className, shouldBeExpanded, isAnimating) {
	const isExpanded = shouldBeExpanded && !isAnimating;
	const collapsibleCurrentClassName = isExpanded ? 'collapsible--expanded' : 'collapsible--collapsed';
	return combineClassNames(className, 'collapsible', collapsibleCurrentClassName);
}

function getStyle(collapsibleState, minimalHeight, ref, shouldBeExpanded, transitionTime) {
	const style = {};
	if (
		isCurrentState(collapsibleState, STATE_COLLAPSED_NO_CHILDREN) ||
		isCurrentState(collapsibleState, STATE_COLLAPSED_WITH_CHILDREN)
	) {
		style.maxHeight = `${minimalHeight}px`;
	} else if (isCurrentState(collapsibleState, STATE_PRE_EXPANDED)) {
		style.maxHeight = `${getSumOfChildNodesHeights(ref.current)}px`;
	}
	if (transitionTime !== COLLAPSIBLE_DEFAULT_TRANSITION_TIME) {
		style.transitionDuration = `${transitionTime}ms`;
	}
	return style;
}

function createTransitionEndHandler(setIsAnimating, shouldBeExpanded, collapsibleState, removeCollapsedChildren,
		dispatch, onExpandEnd, onCollapseEnd, ref) {
	return e => {
		if (e.target === ref.current) {
			setIsAnimating(false);
			if (shouldBeExpanded && isCurrentState(collapsibleState, STATE_PRE_EXPANDED)) {
				updateState(dispatch, STATE_EXPANDED);
				callSafe(onExpandEnd);
			} else if (
				removeCollapsedChildren &&
				isCurrentState(collapsibleState, STATE_COLLAPSED_WITH_CHILDREN) && !shouldBeExpanded
			) {
				updateState(dispatch, STATE_COLLAPSED_NO_CHILDREN);
				callSafe(onCollapseEnd);
			} else if (
				!removeCollapsedChildren &&
				isCurrentState(collapsibleState, STATE_COLLAPSED_WITH_CHILDREN) && !shouldBeExpanded
			) {
				callSafe(onCollapseEnd);
			}
		}
	};
}

function handleCollapsibleStateChange(collapsibleState, shouldBeExpanded, removeCollapsedChildren, onCollapseStart,
		onExpandStart, isAnimating, setIsAnimating, dispatch) {
	let frameId = null;
	let nextFrameStatus = null;

	const conditionalStateUpdates = [
		{
			condition: () => shouldBeExpanded && isCurrentState(collapsibleState, STATE_COLLAPSED_NO_CHILDREN),
			callback: () => {
				updateState(dispatch, STATE_COLLAPSED_WITH_CHILDREN);
				callSafe(onExpandStart);
			}
		}, {
			condition: () => shouldBeExpanded && isCurrentState(collapsibleState, STATE_COLLAPSED_WITH_CHILDREN),
			callback: () => {
				setIsAnimating(true);
				updateState(dispatch, STATE_PRE_EXPANDED);
				if (!removeCollapsedChildren && !isAnimating) {
					callSafe(onExpandStart);
				}
			}
		}, {
			condition: () => !shouldBeExpanded && isCurrentState(collapsibleState, STATE_EXPANDED),
			callback: () => {
				updateState(dispatch, STATE_PRE_EXPANDED);
				callSafe(onCollapseStart);
			}
		}, {
			condition: () => isCurrentState(collapsibleState, STATE_PRE_EXPANDED) &&
				isPreviousState(collapsibleState, STATE_EXPANDED),
			callback: () => {
				setIsAnimating(true);
				nextFrameStatus = STATE_COLLAPSED_WITH_CHILDREN;
			}
		}, {
			condition: () => !shouldBeExpanded && isCurrentState(collapsibleState, STATE_PRE_EXPANDED),
			callback: () => {
				updateState(dispatch, STATE_COLLAPSED_WITH_CHILDREN);
			}
		}
	];

	conditionalStateUpdates.some(({condition, callback}) => {
		const conditionResult = condition();
		if (conditionResult) {
			callback();
		}
		return conditionResult;
	});

	if (nextFrameStatus) {
		frameId = window.requestAnimationFrame(() => {
			updateState(dispatch, nextFrameStatus);
			frameId = null;
		});
	}
	return () => frameId && window.cancelAnimationFrame(frameId);
}

function reducer(state, action) {
	if (action.type === 'updateCollapsibleState') {
		return {
			currentState: action.payload,
			previousState: state.currentState
		};
	}
	throw new Error();
}

function isCurrentState(state, currentState) {
	return state.currentState === currentState;
}

function isPreviousState(state, previousState) {
	return state.previousState === previousState;
}

function updateState(dispatch, newState) {
	dispatch({type: 'updateCollapsibleState', payload: newState});
}

function getInitialCollapsibleState(initialArgs) {
	const {shouldBeExpanded, removeCollapsedChildren} = initialArgs;
	let initialState = STATE_EXPANDED;
	if (!shouldBeExpanded) {
		initialState = removeCollapsedChildren ? STATE_COLLAPSED_NO_CHILDREN : STATE_COLLAPSED_WITH_CHILDREN;
	}
	return {currentState: initialState, previousState: initialState};
}
