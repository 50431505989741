import React from 'react';
import PropTypes from 'prop-types';

import '../../../../styles/viewer/components/annotations/AnnotationIconsGroup.scss';

export default function AnnotationIconsGroup(props) {
	const {readOnly, position, children} = props;
	return (!readOnly &&
		<g transform={`translate(${position[0]}, ${position[1]})`} className='annotation-icons-group'>
			{children}
		</g>
	);
}
AnnotationIconsGroup.propTypes = {
	readOnly: PropTypes.bool,
	position: PropTypes.instanceOf(Float32Array)
};
