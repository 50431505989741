import React from 'react';
import PropTypes from 'prop-types';

import {ExpandRightIcon} from './CollapseIcons.js';

import '../../../../styles/material-design/components/FoldIndicator.scss';

function FoldIcon(props) {
	const {isFolded} = props;
	return <ExpandRightIcon className={`foldIcon foldIcon--${isFolded ? 'folded' : 'unfolded'}`} />;
}

FoldIcon.propTypes = {
	isFolded: PropTypes.bool
};

export default React.memo(FoldIcon);
