import React from 'react';
import PropTypes from 'prop-types';

import PatientInfo from '../../commons/components/webview/PatientInfo.js';
import {USE_LOSSLESS_IMAGE_COMPRESSION} from '../../commons/constants/SynSettingsConstants.js';
import ShortDateTime from '../../i18n/components/ShortDateTime.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../i18n/translators/ViewerTranslator.js';
import {isDisplaySupported} from '../utils/SupportedDocumentFormats.js';
import {checkEmpty, default as ViewerDetailsDialog} from './ViewerDetailsDialog.js';
import ViewerDetailsPropertyItem from './ViewerDetailsPropertyItem.js';

export default class GenericFileDetailsDialog extends React.PureComponent {
	render() {
		const {onClose, metadata, show} = this.props;
		const fileExtension = metadata.FileExtension;
		const fileExtensionDisplay = fileExtension ? `${fileExtension.toUpperCase()}, ` : false;
		const imageLoadFormatHintMessage = USE_LOSSLESS_IMAGE_COMPRESSION ? 'ConvertedToPNG' : 'ConvertedToJPG';
		const isImage = Boolean(metadata.Resolution);
		return (
			<ViewerDetailsDialog onClose={onClose} show={show}>
				<ViewerDetailsPropertyItem name='Patient'>
					<PatientInfo patientInfo={metadata.PatientInfo} birthDate={metadata.PatientBirthDate} />
				</ViewerDetailsPropertyItem>
				<ViewerDetailsPropertyItem name='DocumentDescription'>
					{checkEmpty(metadata.DocumentDescription)}
				</ViewerDetailsPropertyItem>
				<ViewerDetailsPropertyItem name='DocumentCreationDate'>
					<ShortDateTime date={metadata.DocumentCreationDate} />
				</ViewerDetailsPropertyItem>
				<ViewerDetailsPropertyItem name='FileDescription'>
					{checkEmpty(metadata.FileDescription)}
				</ViewerDetailsPropertyItem>
				{isImage &&
				<React.Fragment>
					<ViewerDetailsPropertyItem name='Resolution'>
						{checkEmpty(metadata.Resolution)}
					</ViewerDetailsPropertyItem>
					<ViewerDetailsPropertyItem name='FileType'>
						{
							isDisplaySupported(fileExtension) ? (
								<React.Fragment>
									{fileExtensionDisplay}
									<SynFormattedMessage message={imageLoadFormatHintMessage}
																translator={ViewerTranslator} />
								</React.Fragment>)
								: '-'
						}
					</ViewerDetailsPropertyItem>
				</React.Fragment>
				}
			</ViewerDetailsDialog>
		);
	}
}

GenericFileDetailsDialog.propTypes = {
	onClose: PropTypes.func,
	metadata: PropTypes.object,
	show: PropTypes.bool
};
