import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import useTranslation from '../../webview/i18n/hooks/useTranslation.js';
import Dialog from '../../webview/ui/components/Dialog.js';
import HorizontalLayout from '../../webview/ui/components/layout/HorizontalLayout.js';
import NotchInset from '../../webview/ui/components/layout/NotchInset.js';
import EditUploadListButton from './EditUploadListButton.js';

export default function DialogBasedUploadFilesList(props) {
	const {showButton, children} = props;
	const editUploadListTitle = useTranslation('EditUploadListTitle');
	const [showUploadFileListDialog, setShowUploadFileListDialog] = useState(false);
	const handleDialogOpen = useCallback(() => {
		setShowUploadFileListDialog(true);
	}, [setShowUploadFileListDialog]);
	const handleDialogClose = useCallback(() => {
		setShowUploadFileListDialog(false);
	}, [setShowUploadFileListDialog]);
	return (
		<React.Fragment>
			<EditUploadListButton onClick={handleDialogOpen} label={editUploadListTitle} visible={showButton} />
			<Dialog title={editUploadListTitle} show={showUploadFileListDialog} onClose={handleDialogClose}
			        fullScreen>
				<HorizontalLayout>
					<NotchInset variant='left' />
					{children}
					<NotchInset variant='right' />
				</HorizontalLayout>
				<NotchInset variant='bottom' />
			</Dialog>
		</React.Fragment>
	);
}

DialogBasedUploadFilesList.propTypes = {
	showButton: PropTypes.bool
};

DialogBasedUploadFilesList.defaultProps = {
	showButton: true
};
