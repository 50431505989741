import React from 'react';
import MUIPaper from '@mui/material/Paper';
import PropTypes from 'prop-types';

export default function Paper(props) {
	const {elevation, notRounded, ...remainingProps} = props;
	return (
		<MUIPaper elevation={elevation} square={notRounded} {...remainingProps} />
	);
}

Paper.propTypes = {
	elevation: PropTypes.number,
	notRounded: PropTypes.bool,
	...MUIPaper.propTypes
};

Paper.defaultProps = {
	elevation: 0,
	notRounded: false
};
