import {createSelector} from 'reselect';

import {
	SESSION_STATE_LOGGED_IN,
	SESSION_STATE_LOGGED_OUT, SESSION_STATE_UNDEFINED
} from '../../constants/SessionReducerStates.js';
import {
	SESSION_CURRENT_TRANSITION,
	SESSION_ERROR,
	SESSION_STATE, SESSION_USERCONTEXT,
	SESSION_USERNAME
} from '../../constants/SessionStateFields.js';
import {
	USER_CONTEXT_FIRSTNAME,
	USER_CONTEXT_ID, 	USER_CONTEXT_LASTNAME,
	USER_CONTEXT_LOGIN, USER_CONTEXT_USER_PATIENT_FK
} from '../../constants/UserContextFieldNames.js';

export const selectSessionData = state => state.session;
export const selectSessionState = createSelector(
	selectSessionData,
	sessionData => sessionData.get(SESSION_STATE)
);
export const selectCurrentTransition = createSelector(
	selectSessionData,
	sessionData => sessionData.get(SESSION_CURRENT_TRANSITION)
);
export const selectIsLoggedIn = createSelector(
	selectSessionState, selectCurrentTransition,
	(state, transition) => state === SESSION_STATE_LOGGED_IN && transition !== SESSION_STATE_LOGGED_OUT
);
export const selectStateIsUndefined = createSelector(
	selectSessionState,
	sessionState => sessionState === SESSION_STATE_UNDEFINED
);
export const selectSessionError = createSelector(
	selectSessionData,
	sessionData => sessionData.get(SESSION_ERROR)
);
export const selectUsername = createSelector(
	selectSessionData,
	sessionData => sessionData.get(SESSION_USERNAME, null)
);
export const selectIsSessionBusy = createSelector(
	selectCurrentTransition, selectSessionError,
	(transition, error) => !error && transition !== null
);
export const selectUserContext = createSelector(
	selectSessionData,
	sessionData => sessionData.get(SESSION_USERCONTEXT)
);
export const selectUserContextName = createSelector(
	selectUserContext, userContext => {
		const userFirstName = userContext.get(USER_CONTEXT_FIRSTNAME, '');
		const userLastName = userContext.get(USER_CONTEXT_LASTNAME, '');
		return `${userFirstName} ${userLastName}`;
	}
);
export const selectUserContextLoginName = createSelector(
	selectUserContext,
	userContext => userContext.get(USER_CONTEXT_LOGIN)
);
export const selectUserContextID = createSelector(
	selectUserContext,
	userContext => userContext.get(USER_CONTEXT_ID)
);
export const selectUserContextData = createSelector(
	selectUserContext,
	userContext => userContext.toJS()
);
export const selectUserContextUserPatientFK = createSelector(
	selectUserContext,
	userContext => userContext.get(USER_CONTEXT_USER_PATIENT_FK)
);
