import React from 'react';
import MUIAlert from '@mui/material/Alert';
import PropTypes from 'prop-types';

export default function Alert(props) {
	const {className, children, severity, sx, action} = props;
	return (
		<MUIAlert className={className} severity={severity} sx={sx} action={action}>
			{children}
		</MUIAlert>
	);
}

Alert.propTypes = {
	severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
	sx: PropTypes.object,
	action: PropTypes.node,
	className: PropTypes.string
};
