import React from 'react';
import {vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {arePointerEventsSupported, preventEventDefault} from '../../../commons/utils/DOMEventUtils.js';
import {callSafe, synchronizedWithAnimationFrame} from '../../../commons/utils/FunctionUtils.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import DomEventsManager from '../../../events/DomEventsManager.js';

import '../../../../styles/viewer/components/annotations/MoveableSVGGroup.scss';

class AbstractMoveable extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnBlur = this.onBlur.bind(this);
		this.domEventsManager = new DomEventsManager();

		this.state = {
			trackedId: null,
			position: null
		};

		this.notImplementedError = new Error('not implemented yet');
	}

	render() {
		const {children} = this.props;
		return children(this.createEventHandlers());
	}

	createEventHandlers() {
		throw this.notImplementedError;
	}

	releaseEventListeners() {
		this.domEventsManager.removeAllListeners();
	}

	beginMove(event, trackedId, position, eventHandlers) {
		if (!event.defaultPrevented) {
			preventEventDefault(event);
			this.initializePosition(trackedId, position);
			this.releaseEventListeners();
			Object.keys(eventHandlers)
				.forEach(eventName => this.domEventsManager.addEventListener(
					window, eventName, eventHandlers[eventName], true
				));
			this.domEventsManager.addEventListener(window, 'blur', this.boundOnBlur, true);
		}
	}

	initializePosition(trackedId, position) {
		this.setState({
			trackedId,
			position
		});
	}

	getTrackedId() {
		const {trackedId} = this.state;
		return trackedId;
	}

	updatePosition(newPosition) {
		this.setState(prevState => {
			const {onMoveBy} = this.props;
			const {position} = prevState;
			const diff = vec2.subtract(position, newPosition, position);
			callSafe(onMoveBy, diff);
			return {
				...prevState,
				position: newPosition
			};
		});
	}

	onBlur() {
		this.releaseEventListeners();
	}

	endMove() {
		this.releaseEventListeners();
	}

	componentWillUnmount() {
		this.releaseEventListeners();
	}

	static convertPointerToPosition(pointer) {
		return vec2.fromValues(pointer.pageX, pointer.pageY);
	}
}

AbstractMoveable.propTypes = {
	onMoveBy: PropTypes.func
};

class PointerMoveable extends AbstractMoveable {
	constructor(props, context) {
		super(props, context);
		this.boundOnPointerUp = this.onPointerUp.bind(this);
		this.boundOnPointerMove = this.onPointerMove.bind(this);
		this.eventHandlers = {
			onPointerDown: this.onPointerDown.bind(this)
		};
	}

	onPointerDown(event) {
		this.beginMove(event, event.pointerId, AbstractMoveable.convertPointerToPosition(event), {
			pointermove: this.boundOnPointerMove,
			pointerup: this.boundOnPointerUp
		});
	}

	onPointerMove(pointerEvent) {
		if (pointerEvent.pointerId === this.getTrackedId()) {
			this.updatePosition(AbstractMoveable.convertPointerToPosition(pointerEvent));
		}
	}

	onPointerUp(pointerEvent) {
		if (pointerEvent.pointerId === this.getTrackedId()) {
			this.endMove();
		}
	}

	createEventHandlers() {
		return this.eventHandlers;
	}
}

class MouseMoveable extends PointerMoveable {
	constructor(props, context) {
		super(props, context);
		this.eventHandlers = {
			onMouseDown: this.onMouseDown.bind(this)
		};
	}

	createEventHandlers() {
		return this.eventHandlers;
	}

	onMouseDown(event) {
		this.beginMove(event, event.pointerId, AbstractMoveable.convertPointerToPosition(event), {
			mousemove: this.boundOnPointerMove,
			mouseup: this.boundOnPointerUp
		});
	}
}

class TouchMoveable extends AbstractMoveable {
	constructor(props, context) {
		super(props, context);
		this.boundOnTouchEnd = this.onTouchEnd.bind(this);
		this.boundOnTouchMove = this.onTouchMove.bind(this);
		this.evenHandlers = {
			onTouchStart: this.onTouchStart.bind(this)
		};
	}

	onTouchStart(event) {
		const startTouches = event.changedTouches;
		const touch = startTouches.item(0);
		this.beginMove(event, touch.identifier, AbstractMoveable.convertPointerToPosition(touch), {
			touchmove: this.boundOnTouchMove,
			touchend: this.boundOnTouchEnd
		});
	}

	onTouchMove(e) {
		const trackedTouch = this.getTrackedTouch(e);
		if (trackedTouch) {
			this.updatePosition(AbstractMoveable.convertPointerToPosition(trackedTouch));
		}
	}

	onTouchEnd(e) {
		const trackedTouch = this.getTrackedTouch(e);
		if (trackedTouch) {
			this.endMove();
		}
	}

	getTrackedTouch(e) {
		let correspondingTouch = null;
		const touches = e.changedTouches;
		for (let i = 0; i < touches.length; ++i) {
			const touch = touches.item(i);
			if (touch.identifier === this.getTrackedId()) {
				correspondingTouch = touch;
			}
		}
		return correspondingTouch;
	}

	createEventHandlers() {
		return this.evenHandlers;
	}
}

class Moveable extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnMoveBy = this.onMoveBy.bind(this);
		this.boundRenderWithHandlers = this.renderWithHandlers.bind(this);
	}

	render() {
		return arePointerEventsSupported()
			? this.renderWithPointerEventSupport()
			: this.renderWithoutPointerEventSupport();
	}

	renderWithPointerEventSupport() {
		return (
			<PointerMoveable onMoveBy={this.boundOnMoveBy}>
				{this.boundRenderWithHandlers}
			</PointerMoveable>
		);
	}

	renderWithoutPointerEventSupport() {
		return (
			<MouseMoveable onMoveBy={this.boundOnMoveBy}>
				{
					mouseHandlers => (
						<TouchMoveable onMoveBy={this.boundOnMoveBy}>
							{touchHandlers => this.renderWithHandlers(mouseHandlers, touchHandlers)}
						</TouchMoveable>
					)
				}
			</MouseMoveable>
		);
	}

	renderWithHandlers(...handlers) {
		const {children} = this.props;
		return children(Object.assign({}, ...handlers));
	}

	onMoveBy(diff) {
		const {onMoveBy} = this.props;
		callSafe(onMoveBy, diff);
	}
}

Moveable.propTypes = {
	onMoveBy: PropTypes.func
};

export default class MoveableSVGGroup extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.onMoveBy = synchronizedWithAnimationFrame(this.onMoveBy.bind(this), MoveableSVGGroup.accumulateDiff);
		this.boundRenderWithEventHandlers = this.renderWithEventHandlers.bind(this);
	}

	render() {
		const {readOnly} = this.props;
		return readOnly ? this.renderReadOnly() : this.renderMoveable();
	}

	renderReadOnly() {
		return this.renderWithEventHandlers({});
	}

	renderMoveable() {
		return (
			<Moveable onMoveBy={this.onMoveBy}>
				{this.boundRenderWithEventHandlers}
			</Moveable>
		);
	}

	renderWithEventHandlers(eventHandlers) {
		const {className: passedClassName, children} = this.props;
		const {onPointerDown, onMouseDown, onTouchStart} = eventHandlers;
		const className = combineClassNames('moveable-svg-group', passedClassName);
		return (
			<g className={className} onPointerDown={onPointerDown} onMouseDown={onMouseDown}
			   onTouchStart={onTouchStart}>
				{children}
			</g>
		);
	}

	onMoveBy(diff) {
		const {onMove} = this.props;
		callSafe(onMove, diff);
	}

	componentWillUnmount() {
		this.onMoveBy.stop();
	}

	static accumulateDiff(moveByArgs, previousDiff) {
		const {0: diff} = moveByArgs;
		const accumulatedDiff = previousDiff || vec2.fromValues(0, 0);
		vec2.add(accumulatedDiff, diff, accumulatedDiff);
		return accumulatedDiff;
	}
}

MoveableSVGGroup.propTypes = {
	readOnly: PropTypes.bool,
	onMove: PropTypes.func,
	className: PropTypes.string
};
