import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {selectCurrentTransition} from '../../../authentication/flux/selectors/SessionSelectors.js';
import OnUnmount from '../../../commons/components/OnUnmount';
import {useAction} from '../../../commons/flux/ReactHooks.js';
import {resetViewerState} from '../actions/ViewerActions.js';

export default React.memo(ViewerStateResetter, () => true);

function ViewerStateResetter() {
	const sessionTransition = useSelector(selectCurrentTransition);
	const resetViewerStateAction = useAction(resetViewerState);
	const onUmount = useCallback(() => {
		if (!sessionTransition) {
			resetViewerStateAction();
		}
	}, [sessionTransition, resetViewerStateAction]);

	return <OnUnmount call={onUmount} />;
}
