import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';

import DeleteAnnotationIconContainer from '../../flux/containers/DeleteAnnotationIconContainer.js';
import {
	getLineAroundContainerCenter,
	renderTransformedAnnotationIconComponents,
	toContainerPosition,
	toImagePosition
} from '../../utils/AnnotationUtils.js';
import {getNormalizedDirection} from '../../utils/VectorUtils.js';
import {TOUCH_SIZE} from './AnnotationConstants.js';
import AnnotationIconsGroup from './AnnotationIconsGroup.js';
import createAnnotation, {ANNOTATION_PROP_TYPES} from './createAnnotation.js';
import MarkerArrowHead from './MarkerArrowHead.js';
import ModifiablePath from './ModifiablePath.js';

function isArrowAnnotationSupported(viewerItem) {
	return Boolean(viewerItem);
}

function getDefaultPropertiesForArrowAnnotation(props) {
	return Immutable.Map({
		points: getLineAroundContainerCenter(props).map(point => toImagePosition(props, point))
	});
}

function ArrowAnnotation(props) {
	const {
		AnnotationRoot, annotationId, onAnnotationPropertiesChanged, annotationProperties,
		transformationMatrix, inverseTransformationMatrix, isPrintPreview
	} = props;
	const points = annotationProperties.get('points');
	const onPointsUpdate = React.useCallback(
		newPoints => onAnnotationPropertiesChanged(annotationId, Immutable.Map({points: newPoints})),
		[onAnnotationPropertiesChanged, annotationId]
	);
	const containerPositions = points.map(point => toContainerPosition(props, point));
	const normalizedDirectionalVector = getNormalizedDirection(containerPositions[0], containerPositions[1]);
	const deleteIconPosition = vec2.scale(normalizedDirectionalVector, normalizedDirectionalVector, -TOUCH_SIZE);
	const readOnly = annotationProperties.get('readOnly', false);

	return (
		<AnnotationRoot>
			<ModifiablePath isPrintPreview={isPrintPreview} points={points}
				inverseTransformationMatrix={inverseTransformationMatrix}
				transformationMatrix={transformationMatrix} onPointsUpdate={onPointsUpdate}
				markerEnd={MarkerArrowHead} readOnly={readOnly} />
			<AnnotationIconsGroup position={containerPositions[0]} readOnly={readOnly}>
				{!isPrintPreview &&
					renderTransformedAnnotationIconComponents(
						[DeleteAnnotationIconContainer], [deleteIconPosition], annotationId
					)
				}
			</AnnotationIconsGroup>
		</AnnotationRoot>
	);
}
ArrowAnnotation.propTypes = ANNOTATION_PROP_TYPES;

export default createAnnotation(
	isArrowAnnotationSupported, getDefaultPropertiesForArrowAnnotation, ArrowAnnotation
);
