import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import defaultThumbnail from '../../../images/material/file-document-custom.png';
import {IMAGE_JPEG, IMAGE_PNG} from '../../../webview/commons/constants/MimeTypes.js';
import {
	DICOMFileEntryType,
	FileEntryType,
	HTMLFileObjectShape
} from '../../../webview/commons/utils/CustomPropTypes.js';

const IMAGE_MIME_TYPES = [IMAGE_JPEG, IMAGE_PNG];

const StyledThumbnail = styled('img', {name: Thumbnail})({
	width: '100%',
	height: '100%',
	objectFit: 'contain'
});

export default React.memo(Thumbnail);

function Thumbnail(props) {
	const {fileEntry: {type, file}} = props;
	if (IMAGE_MIME_TYPES.includes(type)) {
		return <ImageThumbnail file={file} />;
	}
	return <StyledThumbnail alt='thumbnail' src={defaultThumbnail} />;
}

Thumbnail.propTypes = {
	fileEntry: PropTypes.oneOfType([
		FileEntryType,
		DICOMFileEntryType
	])
};

function ImageThumbnail(props) {
	const {file} = props;
	const [srcUrl, setSrcUrl] = useState('');
	if (file && !srcUrl) {
		setSrcUrl(URL.createObjectURL(file));
	}
	useEffect(() => () => URL.revokeObjectURL(srcUrl), [srcUrl]);
	return Boolean(srcUrl) && <StyledThumbnail alt='thumbnail' src={srcUrl} />;
}

ImageThumbnail.propTypes = {
	file: HTMLFileObjectShape
};
