import React from 'react';

import HorizontalLayout from '../../webview/ui/components/layout/HorizontalLayout.js';
import DeleteSelectedFilesButton from './UploadFilesGrid/DeleteSelectedFilesButton.js';
import UploadGridViewToggleButton from './UploadGridViewToggleButton.js';

export default function FileBrowserTools() {
	return (
		<HorizontalLayout justify='end' noGrow>
			<DeleteSelectedFilesButton />
			<UploadGridViewToggleButton />
		</HorizontalLayout>
	);
}
