import React, {useContext, useMemo} from 'react';

import createLocationValueSynchronizerContainer from '../flux/containers/createLocationValueSynchronizerContainer.js';
import {createSelectLocationState} from '../flux/selectors/ViewerSelectors.js';
import ViewerContext from './ViewerContext.js';

export default function ViewerLocationSynchronizer() {
	const {viewerId} = useContext(ViewerContext);
	const locationStateSelector = useMemo(() => createSelectLocationState(viewerId), [viewerId]);
	const SynchronizerContainer = useMemo(() => createLocationValueSynchronizerContainer(
		locationStateSelector, ['query', 'viewers', viewerId]
	), [locationStateSelector, viewerId]);
	return <SynchronizerContainer />;
}
