import {
	DIRECTORY_RECORD_SEQUENCE,
	MODALITY_TAG_ID,
	PATIENT_BIRTH_DATE,
	PATIENT_NAME,
	PATIENT_SEX,
	REFERENCED_FILE_ID,
	STUDY_DESCRIPTION,
	STUDY_INSTANCE_UID
} from '../../webview/commons/constants/DicomTagIDs.js';

export const PARSER_STUDY_INSTANCE_UID = dicomParserTagId(STUDY_INSTANCE_UID);
export const PARSER_MODALITY = dicomParserTagId(MODALITY_TAG_ID);
export const PARSER_STUDY_DESCRIPTION = dicomParserTagId(STUDY_DESCRIPTION);
export const PARSER_PATIENT_NAME = dicomParserTagId(PATIENT_NAME);
export const PARSER_PATIENT_BIRTH_DATE = dicomParserTagId(PATIENT_BIRTH_DATE);
export const PARSER_PATIENT_SEX = dicomParserTagId(PATIENT_SEX);
export const PARSER_DIRECTORY_RECORD_SEQUENCE = dicomParserTagId(DIRECTORY_RECORD_SEQUENCE);
export const PARSER_REFERENCED_FILE_ID	= dicomParserTagId(REFERENCED_FILE_ID);

function dicomParserTagId(tagNumber) {
	return `x${tagNumber.toLowerCase()}`;
}
