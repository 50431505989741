import React from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import {OPEN_BOX} from '../../commons/constants/UnicodeCharacterConstants.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {H5Mono} from './typography/Typography.js';

export default function RevealedPassword(props) {
	const {password, ...remainingProps} = props;
	const letters = useMemoFactory(createLetters, password);
	return (
		<H5Mono {...remainingProps}>
			{letters}
		</H5Mono>
	);
}

function createLetters(password) {
	const hasOnlyDigits = (/^\d*$/).test(password);
	if (hasOnlyDigits) {
		return password;
	}
	return password.split('')
		.map((letter, idx) => {
			const key = `${letter}_${idx}`;
			return (/\s/).test(letter) ? <OpenBoxLetter key={key} /> : letter;
		})
		.map((letter, idx) => {
			const key = `${letter}_${idx}`;
			return (
				(/\d/).test(letter)
					? (
						<PasswordDigit key={key}>
							{letter}
						</PasswordDigit>
					)
					: letter
			);
		});
}

RevealedPassword.propTypes = {
	...Typography.propTypes,
	password: PropTypes.string
};

const PasswordDigit = styled('span')(styledProps => {
	const {theme} = styledProps;
	return {color: theme.palette.primary.main};
});

function OpenBox(props) {
	const {className} = props;
	return (
		<span className={className}>
			{OPEN_BOX}
		</span>
	);
}

OpenBox.propTypes = {
	className: PropTypes.string
};

const OpenBoxLetter = styled(OpenBox)(styledProps => {
	const {theme} = styledProps;
	return {color: theme.palette.grey['400']};
});
