import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {noop} from '../../../commons/utils/FunctionUtils.js';
import {replaceLocation} from '../../../router/flux/LocationActions.js';
import {uiShowPrintLayout} from '../../../ui/flux/UISelectors.js';
import LocationValueSynchronizer from '../../components/LocationValueSynchronizer.js';

export default function createLocationValueSynchronizerContainer(valueSelector, path) {
	function LocationValueSynchronizerRenderer(props) {
		return <LocationValueSynchronizer {...props} path={path} />;
	}

	const finalValueSelector = createStructuredSelector({
		value: valueSelector,
		isPrintPreview: uiShowPrintLayout
	});

	return connect(
		finalValueSelector,
		bindActions({replaceLocation}),
		function mergeStateProps(stateProps, dispatchProps, ownProps) {
			const replaceLocationHandler = stateProps.isPrintPreview ? noop : dispatchProps.replaceLocation;
			return {...stateProps, replaceLocation: replaceLocationHandler, ...ownProps};
		}
	)(LocationValueSynchronizerRenderer);
}
