import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {BEGIN_SESSION, END_SESSION} from '../../../authentication/constants/SessionActionTypes.js';
import ResultList from '../../api/ResultList.js';
import {
	CLEAR_RESOURCE_DATA,
	SET_RESOURCE_RESPONSE,
	START_RESOURCE_LOAD,
	UPDATE_RESOURCE_RESPONSE
} from '../../constants/synCrud/ActionTypes.js';
import {RESOURCE_DATA, RESOURCE_DATA_RESPONSE, RESOURCE_STATE} from '../../constants/synCrud/FieldNames.js';
import {RESOURCE_STATE_LOADED, RESOURCE_STATE_LOADING} from '../../constants/synCrud/States.js';
import {identity} from '../../utils/FunctionUtils.js';
import {mapProperties} from '../../utils/ObjectUtils';

const EMPTY_STORE = Immutable.Map();

function handleStartResourceLoad(state, {payload: resourceId}) {
	return state.updateIn(
		[resourceId], EMPTY_STORE, resourceStore => resourceStore.set(RESOURCE_STATE, RESOURCE_STATE_LOADING)
	);
}

function clearResourceStores(/*state, action*/) {
	return EMPTY_STORE;
}

function createResponseHandler(crudResourceDefinitions) {
	const finalReducers = mapProperties(crudResourceDefinitions,
		({reducer}) => {
			const finalReducer = reducer || identity;
			return data => {
				const response = data[RESOURCE_DATA_RESPONSE];
				const convertedResponse = (response instanceof ResultList) ? response : Immutable.fromJS(response);

				const remainingData = {...data};
				delete remainingData[RESOURCE_DATA_RESPONSE];

				return Immutable.fromJS(remainingData)
					.set(RESOURCE_DATA_RESPONSE, finalReducer(convertedResponse));
			};
		}
	);

	return (state, {error, payload: {resourceId, data}}) => {
		let newState = state;
		if (error !== true) {
			newState = state.updateIn([resourceId], EMPTY_STORE,
				resourceStore => resourceStore
					.set(RESOURCE_DATA, finalReducers[resourceId](data))
					.set(RESOURCE_STATE, RESOURCE_STATE_LOADED)
			);
		}
		return newState;
	};
}

function handleClearResourceResults(state, {payload: resourceId}) {
	return state.delete(resourceId);
}

function handleResourceUpdate(state, {payload: {resourceId, updateFunction}}) {
	return state.updateIn([resourceId, RESOURCE_DATA, RESOURCE_DATA_RESPONSE], null, updateFunction);
}

export default function createCrudResourcesReducer(crudResourcesDefinitions) {
	return handleActions({
		[START_RESOURCE_LOAD]: handleStartResourceLoad,
		[SET_RESOURCE_RESPONSE]: createResponseHandler(crudResourcesDefinitions),
		[UPDATE_RESOURCE_RESPONSE]: handleResourceUpdate,
		[CLEAR_RESOURCE_DATA]: handleClearResourceResults,
		[BEGIN_SESSION]: clearResourceStores,
		[END_SESSION]: clearResourceStores
	}, EMPTY_STORE);
}
