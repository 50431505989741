import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../ui/components/Checkbox.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';

export default class SelectItemCheckbox extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnToggle = this.onToggle.bind(this);
	}

	render() {
		const remainingProps = cloneWithoutProperties(this.props, 'onSelect', 'onDeselect', 'item');
		return <Checkbox onToggle={this.boundOnToggle} {...remainingProps} />;
	}

	onToggle(checked) {
	  const {id, item, onSelect, onDeselect} = this.props;
	  if (checked && onSelect) {
		 onSelect(id, item);
	  }
	  if (!checked && onDeselect) {
		 onDeselect(id, item);
	  }
	}
}

SelectItemCheckbox.propTypes = {
	onSelect: PropTypes.func,
	onDeselect: PropTypes.func,
	item: PropTypes.object,
	...Checkbox.propTypes
};
