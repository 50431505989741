export const STEPPER_ID = 'shareDocumentStepper';
export const MODE_EXISTING_RECEIVER = 'MODE_EXISTING_RECEIVER';
export const MODE_NEW_RECEIVER = 'MODE_NEW_RECEIVER';
export const CREATE_SHARE_RECEIVER_FORM_ID = 'createShareReceiverForm';
export const DATA_FIELD_IS_CREATING_SHARE = 'isCreatingShare';
export const DATA_FIELD_SHARE_CREATION_ERROR = 'shareCreationError';
export const DATA_FIELD_SHARE_CREATED_SUCCESSFULLY = 'shareCreatedSuccessfully';
export const DATA_FIELD_RECEIVER_FILTER = 'receiverFilter';
export const DATA_FIELD_SELECTED_RECEIVER = 'receiver';
export const DATA_FIELD_SHARE_MODE = 'shareMode';
export const NR_STEPS = 3;
export const SHARE_DOCUMENTS_DIALOG_ID = 'ShareDocumentDialog';
export const SHARE_DOCUMENTS_DIALOG_ID_PATTERN = `${SHARE_DOCUMENTS_DIALOG_ID}-.*`;
