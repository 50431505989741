import {IS_DEBUG_BUILD} from '../../constants/EnvironmentConstants.js';
import {debugWarn} from '../DebugLog.js';
import getPrototypes from './getPrototypes.js';

const AUTO_BIND_MARKER = Symbol('AUTO_BIND_MARKER');
const AUTO_BIND_METHOD_TO_NAME_MAP = Symbol('AUTO_BIND_METHOD_TO_NAME_MAP');

export default function autoBind(obj, ...methods) {
	if (wasBoundBefore(obj)) {
		printWarning(obj);
	} else {
		doAutoBind(obj, ...methods);
		markAsAutoBound(obj);
	}
}

function wasBoundBefore(obj) {
	return obj[AUTO_BIND_MARKER] === AUTO_BIND_MARKER;
}

function printWarning(obj) {
	debugWarn(`autoBind called twice on object of type ${typeof obj}.`);
}

function doAutoBind(obj, ...methods) {
	let methodsToBind;
	if (methods.length > 0) {
		assertOnlyFunctions(methods);
		methodsToBind = mapToNames(obj, methods);
	} else {
		methodsToBind = getAllMethods(obj);
	}
	methodsToBind.forEach(name => {
		obj[name] = obj[name].bind(obj);
	});
}

function assertOnlyFunctions(methods) {
	if (IS_DEBUG_BUILD) {
		methods.forEach(assertIsInstanceOfFunction);
	}
}

function assertIsInstanceOfFunction(method) {
	if (!(method instanceof Function)) {
		throw new Error('Passed method is not a function!');
	}
}

function markAsAutoBound(obj) {
	obj[AUTO_BIND_MARKER] = AUTO_BIND_MARKER;
}

function mapToNames(obj, methods) {
	return methods.map(method => findName(obj, method));
}

function getAllMethods(obj) {
	const allPrototypes = getPrototypes(obj);
	const allProperties = [];
	const uniquePropertyNames = new Set();
	allPrototypes.forEach(prototype => collectUniqueOwnProperties(prototype, uniquePropertyNames, allProperties));
	return allProperties;
}

function collectUniqueOwnProperties(obj, uniqueProperties, allNames) {
	Object.getOwnPropertyNames(obj)
		.filter(name => name !== 'constructor')
		.filter(name => !uniqueProperties.has(name))
		.filter(name => isWritableMethod(obj, name))
		.forEach(name => {
			uniqueProperties.add(name);
			allNames.push(name);
		});
}

function isWritableMethod(obj, name) {
	const {writable, value} = Object.getOwnPropertyDescriptor(obj, name);
	return writable && value instanceof Function;
}

function findName(obj, method) {
	let {name} = method;
	if (obj[name] !== method) {
		name = findInPrototypes(obj, method);
	}
	assertNameFound(obj, name, method);
	return name;
}

function findInPrototypes(obj, method) {
	let name;
	const prototypes = getPrototypes(obj);
	while (name === undefined && prototypes.length > 0) {
		const currentPrototype = prototypes.shift();
		if (!hasReverseMethodMap(currentPrototype)) {
			buildReverseMethodMap(currentPrototype);
		}
		name = findInReverseMethodMap(currentPrototype, method);
	}
	return name;
}

function assertNameFound(obj, methodName, method) {
	if (methodName === undefined) {
		throw new Error(`Failed to find name for autobound method in ${Object.getPrototypeOf(obj).constructor.name}: ${method}`);
	}
}

function hasReverseMethodMap(obj) {
	return obj[AUTO_BIND_METHOD_TO_NAME_MAP] !== undefined;
}

function buildReverseMethodMap(obj) {
	const ownProperties = [];
	const uniqueProperties = new Set();
	collectUniqueOwnProperties(obj, uniqueProperties, ownProperties);
	const methodToNameMap = new Map();
	ownProperties
		.filter(propertyName => obj[propertyName] instanceof Function)
		.forEach(methodName => methodToNameMap.set(obj[methodName], methodName));
	obj[AUTO_BIND_METHOD_TO_NAME_MAP] = methodToNameMap;
}

function findInReverseMethodMap(obj, method) {
	return obj[AUTO_BIND_METHOD_TO_NAME_MAP].get(method);
}
