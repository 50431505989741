import {StatusCodes} from 'http-status-codes';

import {APPLICATION_JSON} from '../../constants/MimeTypes.js';
import {handleUnexpectedResponse} from '../../utils/AjaxUtils.js';

/**
 * Sends a DELETE request to the given url and hence destroys the corresponding resource.
 * If the delete request fails (a response status different from 200). The promise representing
 * the result will be rejected with a FetchError.
 *
 *
 * @param {String} url the url of the resource to destroy
 * @returns {Promise} a promise representing the result of the request.
 * 	If the resource can be destroyed the result will be empty.
 * 	If the destruction request fails the promise will be rejected.
 */
export default function destroy(url) {
	return fetch(url, {
		method: 'DELETE',
		credentials: 'include',
		headers: {
			Accept: APPLICATION_JSON
		}
	}).then(handleUnexpectedResponse(StatusCodes.OK, `Could not destroy resource at ${url}`));
}
