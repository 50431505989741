import React from 'react';

import Footer from '../../a11y/components/landmarks/Footer.js';
import A11yTranslator from '../../a11y/i18n/A11yTranslator.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import ProductInfo from './webview/ProductInfo.js';

import '../../../styles/commons/components/SidebarFooter.scss';

export default wrapInLocalizationContext(function SidebarFooter(props) {
	const finalProps = {
		className: 'webview-sidebar-footer',
		title: A11yTranslator.getFormattedMessage('A11YVersionInfo', props.locale)
	};
	return (
		<Footer {...finalProps}>
			<ProductInfo />
		</Footer>
	);
});
