import React from 'react';
import PropTypes from 'prop-types';

import {ENFORCE_TWO_FACTOR_LOGIN} from '../../commons/constants/SynSettingsConstants.js';
import FormContainer from '../../commons/containers/FormContainer.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import GeneralTranslator from '../../i18n/translators/GeneralTranslator.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import SingleLineFormField from '../../material-design/components/form/SingleLineFormField.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import LoginFormMessagesTranslator from '../i18n/LoginFormMessagesTranslator.js';
import LoginPageMessagesTranslator from '../i18n/LoginPageMessagesTranslator.js';
import credentialsFormAccessors from './accessors/CredentialsFormAccessors.js';
import PasswordFormField from './PasswordFormField.js';

export default class StepEnterCredentials extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnSubmit = this.onSubmit.bind(this);
	}

	render() {
		const {isBusy} = this.props;
		return (
			<React.Fragment>
				<SynFormattedMessage translator={LoginPageMessagesTranslator} message='EnterUsernameMessage' />
				<VerticalLayout justify={'end'} element={FormContainer} description={credentialsFormAccessors} onSubmit={this.boundOnSubmit}>
					<SingleLineFormField autoFocus forceFloating fieldName='username' name='username' autoCorrect='off' autoCapitalize='off' autoComplete='username' />
					<PasswordFormField forceFloating fieldName='password' name='password' autoComplete='current-password' />
					<MaterialFormButton disabled={isBusy} flat opaque style={{width: '100%'}} type='submit'>
						{this.getCredentialsProceedButtonMessage()}
					</MaterialFormButton>
				</VerticalLayout>
			</React.Fragment>
		);
	}

	onSubmit(fields) {
		const {username, password} = fields;
		const {login} = this.props;
		callSafe(login, username, password);
	}

	getCredentialsProceedButtonMessage() {
		if (ENFORCE_TWO_FACTOR_LOGIN) {
			return <SynFormattedMessage message='ForwardPhrase' translator={GeneralTranslator} />;
		}
		const {isBusy} = this.props;
		return <SynFormattedMessage message={isBusy ? 'LoggingIn' : 'Login'} translator={LoginFormMessagesTranslator} />;
	}
}

StepEnterCredentials.propTypes = {
	login: PropTypes.func,
	isBusy: PropTypes.bool
	
};
