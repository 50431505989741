import React from 'react';
import MUITypography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {getHocDisplayName, memoWithName} from '../../../commons/utils/ReactUtils.js';
import {
	COLOR_DEFAULT,
	COLOR_PRIMARY,
	COLOR_SECONDARY,
	TEXT_PRIMARY,
	TEXT_SECONDARY
} from '../../constants/SynMUIOptions.js';

// https://material.io/design/typography/the-type-system.html

export const H5 = createTypographyComponent('H5', 'h5');
export const H6 = createTypographyComponent('H6', 'h6');
export const Body1 = createTypographyComponent('Body1', 'body1');
export const Body2 = createTypographyComponent('Body2', 'body2');
export const Subtitle1 = createTypographyComponent('Subtitle1', 'subtitle1');
export const CaptionText = createTypographyComponent('CaptionText', 'caption');
export const H5Mono = createTypographyComponent('H5Mono', 'h5Mono');

function createTypographyComponent(name, variant) {
	function TypographyRenderer(props) {
		return <MUITypography variant={variant} {...props} />;
	}
	const propTypes = {
		...MUITypography.propTypes,
		color: PropTypes.oneOf([COLOR_DEFAULT, COLOR_PRIMARY, COLOR_SECONDARY, TEXT_PRIMARY, TEXT_SECONDARY])
	};
	delete propTypes.variant;
	TypographyRenderer.displayName = getHocDisplayName(name, 'createTypographyComponent');
	TypographyRenderer.propTypes = propTypes;
	TypographyRenderer.defaultProps = cloneWithoutProperties(MUITypography.defaultProps, 'variant');
	return memoWithName(TypographyRenderer);
}
