import React from 'react';
import PropTypes from 'prop-types';

import {memoizeLast} from '../../../utils/FunctionUtils.js';

const MAX_IDLE_NODES_DEFAULT = 5;

class NodeMapper {
	constructor(maxIdleNodes = MAX_IDLE_NODES_DEFAULT) {
		this.idleNodes = [];
		this.maxIdleNodes = maxIdleNodes;
		this.nextNodeId = 0;
		this.firstMappedItem = -1;
		this.lastMappedItem = -1;
		this.mapping = new Map();
		this.update = memoizeLast(this.update.bind(this));
	}

	forEachMappedNode(visitor) {
		this.mapping.forEach(visitor);
	}

	forEachIdleNode(visitor) {
		this.idleNodes.forEach(visitor);
	}

	getNumberOfMappedNodes() {
		return this.mapping.size;
	}

	getNumberOfIdleNodes() {
		return this.idleNodes.length;
	}

	update(firstItem, lastItem) {
		if (this.mapping.size > 0) {
			this.updateMap(firstItem, lastItem);
		}
		for (let itemIndex = firstItem; itemIndex < this.firstMappedItem; ++itemIndex) {
			const nodeId = this.idleNodes.length > 0 ? this.idleNodes.pop() : this.nextNodeId++;
			this.mapping.set(itemIndex, nodeId);
		}
		for (let itemIndex = Math.max(firstItem, this.lastMappedItem + 1); itemIndex <= lastItem; ++itemIndex) {
			const nodeId = this.idleNodes.length > 0 ? this.idleNodes.pop() : this.nextNodeId++;
			this.mapping.set(itemIndex, nodeId);
		}
		this.firstMappedItem = firstItem;
		this.lastMappedItem = lastItem;
		if (this.idleNodes.length > this.maxIdleNodes) {
			this.idleNodes = this.idleNodes.slice(0, this.maxIdleNodes);
		}
	}

	updateMap(firstItem, lastItem) {
		if (firstItem <= this.lastMappedItem && lastItem >= this.firstMappedItem) {
			if (firstItem > this.firstMappedItem) {
				for (let itemIndex = this.firstMappedItem; itemIndex < firstItem; ++itemIndex) {
					this.idleNodes.push(this.mapping.get(itemIndex));
					this.mapping.delete(itemIndex);
				}
				this.firstMappedItem = firstItem;
			}
			if (lastItem < this.lastMappedItem) {
				for (let itemIndex = lastItem + 1; itemIndex <= this.lastMappedItem; ++itemIndex) {
					this.idleNodes.push(this.mapping.get(itemIndex));
					this.mapping.delete(itemIndex);
				}
				this.lastMappedItem = lastItem;
			}
		} else {
			this.mapping.forEach(nodeId => {
				this.idleNodes.push(nodeId);
			});
			this.mapping.clear();
			this.firstMappedItem = -1;
			this.lastMappedItem = -1;
		}
	}
}

export default class OptimizedGrid extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.rowMapper = new NodeMapper();
		this.columnMapper = new NodeMapper();
	}

	render() {
		const {width, height, firstRow, lastRow, firstColumn, lastColumn} = this.props;
		this.rowMapper.update(firstRow, lastRow);
		this.columnMapper.update(firstColumn, lastColumn);

		const totallyRenderedCells =
			this.rowMapper.getNumberOfMappedNodes() *
			(this.columnMapper.getNumberOfMappedNodes() + this.columnMapper.getNumberOfIdleNodes()) +
			this.columnMapper.getNumberOfMappedNodes() * this.rowMapper.getNumberOfIdleNodes();

		let renderedCellIndex = 0;
		const renderedCells = new Array(totallyRenderedCells);
		this.rowMapper.forEachMappedNode((rowId, rowIndex) => {
			this.columnMapper.forEachMappedNode((columnId, columnIndex) => {
				const nodeId = `cell_${columnId}_${rowId}`;
				renderedCells[renderedCellIndex++] = this.renderCell(columnIndex, rowIndex, nodeId);
			});
			this.columnMapper.forEachIdleNode(columnId => {
				const nodeId = `cell_${columnId}_${rowId}`;
				renderedCells[renderedCellIndex++] = OptimizedGrid.renderIdleCell(nodeId);
			});
		});
		this.columnMapper.forEachMappedNode(columnId => {
			this.rowMapper.forEachIdleNode(rowId => {
				const nodeId = `cell_${columnId}_${rowId}`;
				renderedCells[renderedCellIndex++] = OptimizedGrid.renderIdleCell(nodeId);
			});
		});

		const containerStyle = {
			position: 'relative',
			height: `${height}px`,
			width: `${width}px`
		};
		return (
			<div style={containerStyle}>
				{renderedCells}
			</div>
		);
	}

	renderCell(columnIndex, rowIndex, key) {
		const {renderCell, measureCell} = this.props;
		const itemGeometry = measureCell(columnIndex, rowIndex);
		const style = {
			position: 'absolute',
			top: `${itemGeometry.top}px`,
			left: `${itemGeometry.left}px`,
			height: `${itemGeometry.height}px`,
			width: `${itemGeometry.width}px`
		};
		return renderCell(columnIndex, rowIndex, key, style);
	}

	static renderIdleCell(key) {
		const style = {
			position: 'absolute',
			display: 'none'
		};
		return <div key={key} style={style} />;
	}
}

OptimizedGrid.propTypes = {
	firstRow: PropTypes.number,
	lastRow: PropTypes.number,
	firstColumn: PropTypes.number,
	lastColumn: PropTypes.number,
	height: PropTypes.number,
	width: PropTypes.number,
	renderCell: PropTypes.func,
	measureCell: PropTypes.func
};
