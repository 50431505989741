import {connect} from 'react-redux';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {showViewer} from '../../../viewer/flux/actions/ViewerActions.js';
import ShowViewerButton from '../../components/ShowViewerButton.js';

export default connect(undefined,
	bindActions({
		showViewer
	}),
	(stateProps, dispatchProps, ownProps) => ({...stateProps, ...ownProps, onClick: () => dispatchProps.showViewer()})
)(ShowViewerButton);
