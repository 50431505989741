import React from 'react';
import PropTypes from 'prop-types';

import Warning from '../../commons/components/text/Warning.js';
import {IS_RELEASE_BUILD} from '../../commons/constants/SynSettingsConstants.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerPopupHelpMessagesTranslator from '../../i18n/translators/ViewerPopupHelpMessagesTranslator.js';
import Spacer from '../../ui/components/layout/Spacer';

import '../../../styles/commons/components/webview/ViewerPopupHelp.scss';

export function WebViewPopupHelp(props) {
	const {touch} = props;
	let help;
	if (touch) {
		help = (
			<TouchHelp {...props}>
				<SynedraViewNotes />
				<DiagnosticWarning />
			</TouchHelp>
		);
	} else {
		help = (
			<ClickHelp {...props}>
				<SynedraViewNotes />
				<DiagnosticWarning />
			</ClickHelp>
		);
	}
	return help;
}

export function WebPatientPopupHelp(props) {
	const {touch} = props;
	let help;
	if (touch) {
		help = (
			<TouchHelp {...props}>
				<DiagnosticWarning paragraphs={['One']} />
			</TouchHelp>
		);
	} else {
		help = (
			<ClickHelp {...props}>
				<DiagnosticWarning paragraphs={['One']} />
			</ClickHelp>
		);
	}
	return help;
}

export function FilterPopupHelp(props) {
	return (
		<PopupHelp {...props}>
			<Translate message='FilterHelpHeading' className='caption' />
			<Description message='FilterHelpIntro' />
			<div className='topic'>
				<Description message='FilterListHeading' />
				<ul>
					<li>
						<Description message='FilterHelpAnd' />
					</li>
					<li>
						<Description message='FilterHelpOr' />
					</li>
					<li>
						<Description message='FilterHelpNot' />
					</li>
					<li>
						<Description message='FilterHelpExact' />
					</li>
				</ul>
			</div>
			<Spacer minHeight='10px' />
			<Description message='FilterHelpOutro' />
		</PopupHelp>
	);
}

export function RemoteViewPopupHelp(props) {
	const {touch, ...remainingProps} = props;
	let help;
	if (touch) {
		help = (
			<PopupHelp {...remainingProps}>
				<Caption message='Zooming' />
				<Description message='ZoomingGestureDescription' />
				<Caption message='Panning' />
				<Description message='PanningGestureDescription' />
			</PopupHelp>
		);
	} else {
		help = (
			<PopupHelp {...remainingProps}>
				<Topic message='Panning'>
					<Description message='PanningMouseDescriptionOneRemoteView' />
				</Topic>
				<Topic message='Zooming'>
					<Description message='ZoomingMouseDescriptionOne' />
					<Description message='ZoomingMouseDescriptionTwo' />
				</Topic>
			</PopupHelp>
		);
	}
	return help;
}

function Translate(props) {
	const {message, element, className} = props;
	return (
		<SynFormattedMessage translator={ViewerPopupHelpMessagesTranslator} message={message} element={element}
		                     className={className} />
	);
}

Translate.propTypes = {
	message: PropTypes.string,
	element: PropTypes.elementType,
	className: PropTypes.string
};

function Description(props) {
	return <Translate className='description' {...props} />;
}

function Caption(props) {
	return <Translate className='caption' {...props} />;
}

function Topic(props) {
	const {message, children} = props;
	return (
		<div className='topic'>
			<Translate message={message} className='caption' />
			<ul>
				{React.Children.map(children, topicChildRenderer)}
			</ul>
		</div>
	);
}

Topic.propTypes = {
	message: PropTypes.string
};

function topicChildRenderer(child) {
	const {message: childMessage} = child.props;
	return childMessage ? (
		<li key={childMessage}>
			{child}
		</li>
	) : null;
}

function SynedraViewNotes() {
	return (
		<section>
			<span className='caption'>
synedra View Personal/Professional
			</span>
			<Description message='SynedraViewNotes' element='p' />
		</section>
	);
}

function DiagnosticWarning(props) {
	const {paragraphs = ['One', 'Two', (IS_RELEASE_BUILD ? 'CEMarking' : 'CEMarkingPlaceholder')]} = props;
	const descriptions = paragraphs.map(paragraph => (
		<Description key={paragraph} message={`DiagnosticWarningMessageParagraph${paragraph}`} element='p' />
	));
	return (
		<Warning>
			{descriptions}
		</Warning>
	);
}

DiagnosticWarning.propTypes = {
	paragraphs: PropTypes.arrayOf(PropTypes.string)
};

function ClickHelp(props) {
	const {children, ...remainingProps} = props;
	return (
		<PopupHelp {...remainingProps}>
			<Topic message='Windowing'>
				<Description message='WindowingMouseDescription' />
			</Topic>
			<Topic message='Zooming'>
				<Description message='ZoomingMouseDescriptionOne' />
				<Description message='ZoomingMouseDescriptionTwo' />
			</Topic>
			<Topic message='Panning'>
				<Description message='PanningMouseDescriptionOne' />
				<Description message='PanningMouseDescriptionTwo' />
			</Topic>
			<Topic message='Scrolling'>
				<Description message='ScrollingMouseDescriptionOne' />
				<Description message='ScrollingMouseDescriptionTwo' />
			</Topic>
			{children}
		</PopupHelp>
	);
}

function TouchHelp(props) {
	const {children, ...remainingProps} = props;
	return (
		<PopupHelp {...remainingProps}>
			<Caption message='Windowing' />
			<Description message='WindowingGestureDescription' />
			<Caption message='Zooming' />
			<Description message='ZoomingGestureDescription' />
			<Caption message='Panning' />
			<Description message='PanningGestureDescription' />
			<Caption message='Scrolling' />
			<Description message='ScrollingGestureDescription' />
			{children}
		</PopupHelp>
	);
}

function PopupHelp(props) {
	const {children} = props;
	return (
		<section className='viewer-popup-help'>
			<section>
				{children}
			</section>
		</section>
	);
}
