import React from 'react';
import PropTypes from 'prop-types';

import {closeAllMatchingPortals, closePersistentPortal} from '../actions/PersistentPortalActions.js';
import OnUnmount from '../components/OnUnmount.js';
import {useAction} from '../flux/ReactHooks.js';

export default function AutoClosePersistentPortal(props) {
	const {withId, matching} = props;
	if (withId && matching) {
		throw new Error('Either provide "withId" OR "matching" not both');
	}
	const closeActionCreator = withId ? closePersistentPortal : closeAllMatchingPortals;
	const closeAction = useAction(closeActionCreator, withId || matching);
	return <OnUnmount call={closeAction} />;
}

AutoClosePersistentPortal.propTypes = {
	withId: PropTypes.string,
	matching: PropTypes.string
};
