import Immutable from 'immutable';
import reduceReducers from 'reduce-reducers';
import {handleActions} from 'redux-actions';

import {CLEAR_ERRORS, RECORD_ERROR} from '../constants/UnhandledErrorsActionTypes.js';
import UnknownError from '../errors/UnknownError.js';
import {isHandled} from '../utils/ErrorHandlingUtils.js';

function hasUnhandledError(action) {
	return action.error && !isHandled(action.payload);
}

function recordError(state, error) {
	return state.push(error instanceof Error ? error : (new UnknownError(error)));
}

function getInitialErrorState() {
	return Immutable.List();
}

function captureUnhandledErrors(state, action) {
	let currentState = state ? state : getInitialErrorState();
	if (action.type === RECORD_ERROR || hasUnhandledError(action)) {
		currentState = recordError(currentState, action.payload);
	}
	return currentState;
}

export default reduceReducers(
	handleActions({
		[CLEAR_ERRORS]: () => getInitialErrorState()
	}, getInitialErrorState()),
	captureUnhandledErrors
);
