import {
	KEY_DIAGNOSTIC_WARNING_ACCEPTED_FOR,
	SECTION_WEBVIEW
} from '../../constants/crudresources/ClientConfigConstants.js';
import {MINIMUM_REQUIRED_ACCEPTED_VERSION_STRING} from '../../constants/WebViewConstants.js';
import {clientConfig as clientConfigFluxApi} from '../../flux/crudresources/CrudResourcesFluxApi.js';

const {
	search: searchAction,
	create: createAction,
	updateResponse: updateClientConfig
} = clientConfigFluxApi.actions;

export function load() {
	return searchAction();
}

export function setConfigEntry(section, key, value, resetOnError = true) {
	const actionParameters = {
		section, key, value
	};
	return createAction(actionParameters,
		(apiPromise, dispatch) => {
			let previousValue;
			dispatch(updateClientConfig(
				config => {
					previousValue = config.getIn([section, key]);
					return config.setIn([section, key], value);
				}
			));
			if (resetOnError) {
				apiPromise.catch(() => {
					dispatch(updateClientConfig(
						config => {
							let newConfig = config;
							if (previousValue === undefined) {
								newConfig = newConfig.deleteIn([section, key]);
							} else {
								newConfig = newConfig.setIn([section, key], previousValue);
							}
							return newConfig;
						}
					));
				});
			}
		}
	);
}

export function acceptNonDiagnosticWarning() {
	return setConfigEntry(
		SECTION_WEBVIEW, 
		KEY_DIAGNOSTIC_WARNING_ACCEPTED_FOR, 
		MINIMUM_REQUIRED_ACCEPTED_VERSION_STRING, 
		false
	);
}
