import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';

const STYLES = ({theme}) => ({
	justifyContent: 'start',
	lineHeight: 'normal',
	textTransform: 'none',
	textAlign: 'left',
	height: '70px',
	width: '100%',
	border: `2px dashed ${theme.palette.primary.main}`,
	backgroundColor: 'rgba( 153, 153, 153, 0.2)',
	'&:hover:not(.disabled)': {
		backgroundColor: 'rgba( 153, 153, 153, 0.4)'
	},
	'&:active:not(.disabled)': {
		backgroundColor: 'rgba( 153, 153, 153, 0.6)'
	}
});

const WebUploadFilesButton = styled(Button, {name: 'WebUploadFilesButton'})(STYLES);

export default WebUploadFilesButton;
