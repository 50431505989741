import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import SelectDocumentCheckbox from '../../commons/components/webview/SelectDocumentCheckbox.js';
import {FEATURE_DOCUMENT_SHARING_ENABLED} from '../../commons/constants/SynSettingsConstants.js';
import {
	getAllExtensions,
	getAllModalities,
	getNumberFiles,
	getNumberSeries,
	isDicom
} from '../../commons/data/aim/DocumentHelpers.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import NumericDate from '../../i18n/components/NumericDate.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import PrimaryItemText from '../../material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../material-design/components/text/SecondaryItemText.js';
import ExpandButton from '../../ui/components/ExpandButton.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';

import '../../../styles/patient-details/components/PatientDetailsDocumentHeader.scss';

export default React.memo(PatientDetailsDocumentHeader);

const MIN_WIDTH_ADDITION = 8;

function PatientDetailsDocumentHeader(props) {
	const {itemsPerRow, contentWidth, document, label, isExpanded, onToggleExpand} = props;
	const {count, message} = getCountInfo(document);

	const countMessage =
		<SynFormattedMessage translator={PatientDetailsMessagesTranslator} message={message} messageParams={{count}} />;
	return (
		<ContentRow className='patient-details-document-header--row'>
			<div className='patient-details-document-header--content'
				     style={{minWidth: contentWidth + MIN_WIDTH_ADDITION}}>
				{FEATURE_DOCUMENT_SHARING_ENABLED &&
					<SelectDocumentCheckbox document={document} className='patient-details-document-header--row--checkbox' />
				}
				{renderDocumentDescription(label, document, countMessage)}
				{count > itemsPerRow &&
					renderExpandButton(onToggleExpand, isExpanded)
				}
			</div>
		</ContentRow>
	);
}

PatientDetailsDocumentHeader.propTypes = {
	itemsPerRow: PropTypes.number,
	contentWidth: PropTypes.number,
	document: immutableMapPropType,
	label: PropTypes.node,
	isExpanded: PropTypes.bool,
	onToggleExpand: PropTypes.func
};

function renderDocumentDescription(label, document, countMessage) {
	return (
		<VerticalLayout justify='center' className='patient-details-document-header--row--document-info'>
			<PrimaryItemText>
				<b>
					{label}
				</b>
			</PrimaryItemText>
			<SecondaryItemText>
				<NumericDate date={document.get('document_created_when')} element='span' />
					&nbsp;|&nbsp;
				{isDicom(document, true) ? getAllModalities(document, true) : getAllExtensions(document, true)}
					&nbsp;|&nbsp;
				{countMessage}
			</SecondaryItemText>
		</VerticalLayout>
	);
}

function renderExpandButton(onToggleExpand, isExpanded) {
	return (
		<CenterLayout className='patient-details-document-header--row--expand-button'>
			<ExpandButton onToggle={onToggleExpand} checked={isExpanded} />
		</CenterLayout>
	);
}

function getCountInfo(document) {
	return isDicom(document)
		? {
			count: getNumberSeries(document),
			message: 'NumberSeries'
		}
		: {
			count: getNumberFiles(document),
			message: 'NumberFiles'
		};
}
