import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import SubtextLinkContainer from '../../containers/SubtextLinkContainer.js';
import SubtextLink from '../SubtextLink.js';

import '../../../../styles/commons/components/webview/ErrorHint.scss';

class ErrorHint extends React.PureComponent {
	render() {
		const {
			translator, hintMessage, solutionMessage, solution, messageParams, textCenter,
			wrapperComponent: WrapperComponent
		} = this.props;
		return (
			<WrapperComponent className={`status-message--error-hint${textCenter ? ' status-message--error-hint--center-text' : ''}`}>
				<SynFormattedMessage id={`${solutionMessage}_id`} translator={translator} message={hintMessage} messageParams={messageParams} />
				<br />
				{solutionMessage && renderSolution(translator, solutionMessage, solution)}
			</WrapperComponent>
		);
	}
}

ErrorHint.propTypes = {
	translator: PropTypes.object,
	hintMessage: PropTypes.string.isRequired,
	solutionMessage: PropTypes.string,
	solution: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
	messageParams: PropTypes.object,
	textCenter: PropTypes.bool,
	wrapperComponent: PropTypes.elementType
};

ErrorHint.defaultProps = {
	textCenter: false,
	wrapperComponent: 'div'
};

function renderSolution(translator, solutionMessage, solution) {
	let solutionElement = <SynFormattedMessage translator={translator} message={solutionMessage} />;
	if (typeof (solution) === 'string') {
		solutionElement = (
			<SubtextLinkContainer path={solution}>
				{solutionElement}
			</SubtextLinkContainer>
		);
	} else if (typeof (solution) === 'function') {
		solutionElement = (
			<SubtextLink onClick={solution} tabIndex='0' aria-describedby={`${solutionMessage}_id`}>
				{solutionElement}
			</SubtextLink>
		);
	}
	return solutionElement;
}

export default ErrorHint;
