import {getFileExtension} from '../../webview/commons/utils/StringUtils.js';

const LEADING_BYTES = 128; // bytes;
const MARKER_TEXT = 'DICM';
const MARKER_TEXT_LENGTH = MARKER_TEXT.length;
const MINIMUM_DICOM_FILE_SIZE = LEADING_BYTES + MARKER_TEXT_LENGTH;

export async function couldBeDicomFile(file) {
	const {name} = file;
	const extension = getFileExtension(name).toLowerCase();
	const hasMatchingExtension = extension === 'dcm' || extension.length === 0;
	const isLargeEnough = file.size > MINIMUM_DICOM_FILE_SIZE;
	let mightBeDicom = hasMatchingExtension && isLargeEnough;
	if (mightBeDicom) {
		mightBeDicom = await startsWithDICOMMarker(file);
	}
	return mightBeDicom;
}

async function startsWithDICOMMarker(possibleDicomFile) {
	const markerBlob = possibleDicomFile.slice(LEADING_BYTES, MINIMUM_DICOM_FILE_SIZE);
	const markerText = await markerBlob.text();
	return markerText === MARKER_TEXT;
}
