import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import DomEventsManager from '../../events/DomEventsManager.js';
import {refPropType} from '../utils/CustomPropTypes.js';
import {arePointerEventsSupported, preventEventDefault} from '../utils/DOMEventUtils.js';
import {getClientBoundingRectSafe, getTopLeftPosition} from '../utils/DOMUtils.js';
import {createEvent} from '../utils/EventUtils.js';
import {callSafe} from '../utils/FunctionUtils.js';

export const LEFT_BUTTON = 1;
export const RIGHT_BUTTON = 2;
export const MIDDLE_BUTTON = 4;

export const NO_ACTIVE_BUTTON = 0;

const BUTTON_MAPPING = [LEFT_BUTTON, MIDDLE_BUTTON, RIGHT_BUTTON];

export const NO_TOUCHES = Immutable.Map();

export const MOUSE_POINTER_ID = 'mouse';

const preventContextMenu = preventEventDefault;

export default class EventTracker extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.handleBlur = this.createEventHandlerFunction('blur', this.blurToNewState);
		this.handleMouseEnterEvent = this.createEventHandlerFunction('pointerenter', this.createNewInputStateFromEvent);
		this.handleMouseOverEvent = this.createEventHandlerFunction('pointerover', this.createNewInputStateFromEvent);
		this.handleMouseUp = this.createEventHandlerFunction('pointerup', this.mouseUpToNewInputState);
		this.handlePointerCancel = this.createEventHandlerFunction('pointercancel', this.pointerCancelEventToNewInputState);
		this.handlePointerMove = this.createEventHandlerFunction('pointermove', this.pointerMoveToNewInputState);
		this.handlePointerUp = this.createEventHandlerFunction('pointerup', this.pointerUpEventToNewInputState);
		this.handleMouseMove = this.createEventHandlerFunction('pointermove', this.mouseEventToNewInputState);
		this.handleTouchEnd = this.createEventHandlerFunction('pointerup', this.touchEventToNewInputState);
		this.handleTouchMove = this.createEventHandlerFunction('pointermove', this.touchEventToNewInputState);
		this.handleKeyboardEvent = this.createEventHandlerFunction('keydown', this.keyEventToNewInputState);
		this.handleKeyboardEvent = this.createEventHandlerFunction('keyup', this.keyEventToNewInputState);
		this.animationFrameHandler = null;
		this.domEventsManager = new DomEventsManager();
		const {targetRef} = this.props;
		this.trackerDivRef = targetRef || React.createRef();

		this.pointerSupportDependentListeners = arePointerEventsSupported()
			? this.createPointerEventListeners()
			: this.createNonPointerEventListeners();

		this.state = {
			eventTrackerState: Immutable.Map({
				mouseActive: false,
				touchActive: false,
				inputState: Immutable.Map({
					buttons: 0,
					pointers: NO_TOUCHES,
					shiftKey: false,
					ctrlKey: false
				})
			})
		};
	}

	render() {
		const {className, style, children} = this.props;
		const {
			onPointerDown, onTouchStart, onMouseDown
		} = this.pointerSupportDependentListeners;
		return (
			<div className={className} style={style} ref={this.trackerDivRef}
				  onMouseEnter={this.handleMouseEnterEvent}
				  onMouseOver={this.handleMouseOverEvent}
				  onContextMenu={preventContextMenu}
				  onPointerDown={onPointerDown}
				  onMouseDown={onMouseDown}
				  onTouchStart={onTouchStart}>
				{children}
			</div>
		);
	}

	componentDidMount() {
		if (arePointerEventsSupported()) {
			this.domEventsManager.addEventListener(document, 'pointerup', this.handlePointerUp, true);
			this.domEventsManager.addEventListener(document, 'pointercancel', this.handlePointerCancel, true);
		} else {
			this.domEventsManager.addEventListener(document, 'mouseup', this.handleMouseUp);
		}
		this.domEventsManager.addEventListener(document, 'keydown', this.handleKeyboardEvent);
		this.domEventsManager.addEventListener(document, 'keyup', this.handleKeyboardEvent);
		this.domEventsManager.addEventListener(window, 'blur', this.handleBlur);
	}

	componentWillUnmount() {
		const {eventTrackerState} = this.state;
		this.disableTouchTracking(eventTrackerState);
		this.disableMouseTracking(eventTrackerState);
		this.disablePointerTracking(eventTrackerState);
		this.domEventsManager.removeAllListeners();
		window.cancelAnimationFrame(this.animationFrameHandler);
		this.animationFrameHandler = null;
	}

	getTrackingRect() {
		return getClientBoundingRectSafe(this.trackerDivRef.current);
	}

	createPointerEventListeners() {
		return {
			onPointerDown: this.createEventHandlerFunction('pointerdown', this.pointerDownEventToNewInputState)
		};
	}

	createNonPointerEventListeners() {
		return {
			onTouchStart: this.createEventHandlerFunction('pointerdown', this.touchEventToNewInputState),
			onMouseDown: this.createEventHandlerFunction('pointerdown', this.mouseDownToNewInputState)
		};
	}

	convertPointerToLocalVector(pointer) {
		const vector = vec2.fromValues(pointer.pageX, pointer.pageY);
		return vec2.sub(vector, vector, getTopLeftPosition(this.trackerDivRef.current));
	}

	createEventHandlerFunction(outputEventType, convertEventToInputState) {
		const boundConvertEventToInputState = convertEventToInputState.bind(this);

		return e => {
			if (!e.defaultPrevented) {
				const newInputState = boundConvertEventToInputState(e);
				const newEvent = createEvent(outputEventType, newInputState, e);
				this.setState(prevState => ({
					eventTrackerState: this.updateEventTracking(prevState.eventTrackerState.set('inputState', newInputState))
				}));
				this.fireEvent(newEvent);
			}
		};
	}

	fireEvent(eventToFire) {
		const {onInput} = this.props;
		callSafe(onInput, eventToFire);
	}

	updateEventTracking(eventTrackerState) {
		const newInputState = eventTrackerState.get('inputState', Immutable.Map());
		let newTrackerState = eventTrackerState;
		if (arePointerEventsSupported()) {
			newTrackerState = this.updateByPointerEvent(newTrackerState, newInputState);
		} else if (newInputState.get('pointerType') === 'mouse') {
			newTrackerState = this.updateByMouseEvent(newTrackerState, newInputState);
		} else if (newInputState.get('pointerType') === 'touch') {
			newTrackerState = this.updateByTouchEvent(newTrackerState, newInputState);
		}
		return newTrackerState;
	}

	updateByPointerEvent(eventTrackerState, inputState) {
		let newTrackerState = eventTrackerState;
		if (inputState.get('pointers').size === 0) {
			newTrackerState = this.disablePointerTracking(eventTrackerState);
		} else if (!eventTrackerState.get('pointerActive')) {
			newTrackerState = this.enablePointerTracking(eventTrackerState);
		}
		return newTrackerState;
	}

	updateByMouseEvent(eventTrackerState, inputState) {
		let newTrackerState = eventTrackerState;
		if (inputState.get('buttons', NO_ACTIVE_BUTTON) === NO_ACTIVE_BUTTON) {
			newTrackerState = this.disableMouseTracking(eventTrackerState);
		} else if (!eventTrackerState.get('mouseActive') && inputState.get) {
			newTrackerState = this.enableMouseTracking(eventTrackerState);
		}
		return newTrackerState;
	}

	updateByTouchEvent(eventTrackerState, inputState) {
		let newTrackerState = eventTrackerState;
		if (inputState.get('pointers').size === 0) {
			newTrackerState = this.disableTouchTracking(eventTrackerState);
		} else if (!eventTrackerState.get('touchActive')) {
			newTrackerState = this.enableTouchTracking(eventTrackerState);
		}
		return newTrackerState;
	}

	enableMouseTracking(eventTrackerState) {
		let newTrackerState = eventTrackerState;
		if (!newTrackerState.get('mouseActive')) {
			this.domEventsManager.addEventListener(document, 'mousemove', this.handleMouseMove, true);
			newTrackerState = newTrackerState.set('mouseActive', true);
		}
		return newTrackerState;
	}

	disableMouseTracking(eventTrackerState) {
		let newTrackerState = eventTrackerState;
		if (newTrackerState.get('mouseActive')) {
			this.domEventsManager.removeEventListener(document, 'mousemove', this.handleMouseMove, true);
			newTrackerState = newTrackerState.set('mouseActive', false).setIn(['inputState', 'buttons'], NO_ACTIVE_BUTTON);
		}
		return newTrackerState;
	}

	enableTouchTracking(eventTrackerState) {
		let newTrackerState = eventTrackerState;
		if (!newTrackerState.get('touchActive')) {
			this.domEventsManager.addEventListener(document, 'touchend', this.handleTouchEnd, true);
			this.domEventsManager.addEventListener(document, 'touchmove', this.handleTouchMove, true);
			newTrackerState = newTrackerState.set('touchActive', true);
		}
		return newTrackerState;
	}

	disableTouchTracking(eventTrackerState) {
		let newTrackerState = eventTrackerState;
		if (newTrackerState.get('touchActive')) {
			this.domEventsManager.removeEventListener(document, 'touchend', this.handleTouchEnd, true);
			this.domEventsManager.removeEventListener(document, 'touchmove', this.handleTouchMove, true);
			newTrackerState = newTrackerState.set('touchActive', false);
		}
		return newTrackerState;
	}

	enablePointerTracking(eventTrackerState) {
		let newTrackerState = eventTrackerState;
		if (!eventTrackerState.get('pointerActive')) {
			this.domEventsManager.addEventListener(document, 'pointermove', this.handlePointerMove, true);
			newTrackerState = newTrackerState.set('pointerActive', true);
		}
		return newTrackerState;
	}

	disablePointerTracking(eventTrackerState) {
		let newTrackerState = eventTrackerState;
		if (newTrackerState.get('pointerActive')) {
			this.domEventsManager.removeEventListener(document, 'pointermove', this.handlePointerMove, true);
			newTrackerState = newTrackerState.set('pointerActive', false);
		}
		return newTrackerState;
	}

	blurToNewState() {
		return this.createNewInputState().merge({buttons: NO_ACTIVE_BUTTON, touches: NO_TOUCHES});
	}

	mouseDownToNewInputState(e) {
		return this.mouseEventToNewInputState(e).update('buttons', buttons => buttons | BUTTON_MAPPING[e.button]);
	}

	mouseUpToNewInputState(e) {
		return this.mouseEventToNewInputState(e).update('buttons', buttons => buttons & ~(BUTTON_MAPPING[e.button]))
			.set('pointers', Immutable.Map());
	}

	pointerDownEventToNewInputState(e) {
		const newPosition = this.convertPointerToLocalVector(e);
		const inputState = this.createNewInputStateFromEvent(e);
		const newPointers = inputState.get('pointers').set(e.pointerId, newPosition);

		return inputState.merge({
			buttons: e.buttons,
			changedPointers: Immutable.Map().set(e.pointerId, newPosition),
			pointers: newPointers,
			pointerType: e.pointerType,
			pointersInvolved: newPointers.size
		});
	}

	pointerMoveToNewInputState(e) {
		const newPosition = this.convertPointerToLocalVector(e);

		const inputState = this.createNewInputStateFromEvent(e);
		return inputState.merge({
			pointers: inputState.get('pointers', Immutable.Map()).set(e.pointerId, newPosition),
			changedPointers: Immutable.Map().set(e.pointerId, newPosition),
			pointerType: e.pointerType,
			buttons: e.buttons
		});
	}

	pointerCancelEventToNewInputState(e) {
		const inputState = this.createNewInputStateFromEvent(e);
		return inputState.merge({
			pointers: Immutable.Map(),
			changedPointers: Immutable.Map(),
			pointersInvolved: inputState.get('pointers').size,
			buttons: e.buttons,
			pointerType: e.pointerType
		});
	}

	pointerUpEventToNewInputState(e) {
		const inputState = this.createNewInputStateFromEvent(e);
		return inputState.merge({
			pointers: inputState.get('pointers', Immutable.Map()).delete(e.pointerId),
			changedPointers: Immutable.Map().set(e.pointerId, this.convertPointerToLocalVector(e)),
			pointersInvolved: inputState.get('pointers').size,
			buttons: e.buttons,
			pointerType: e.pointerType
		});
	}

	mouseEventToNewInputState(e) {
		const inputState = this.createNewInputStateFromEvent(e);
		const pointers = Immutable.Map().set(MOUSE_POINTER_ID, this.convertPointerToLocalVector(e));
		return inputState.merge({
			changedPointers: pointers,
			pointers,
			pointerType: 'mouse',
			pointersInvolved: 1
		});
	}

	touchEventToNewInputState(e) {
		let pointers = Immutable.Map();
		for (let i = 0; i < e.touches.length; ++i) {
			const touch = e.touches.item(i);
			pointers = pointers.set(touch.identifier, this.convertPointerToLocalVector(touch));
		}
		let pointersInvolved = pointers.size;

		const changedPointers = Immutable.Map();
		for (let i = 0; i < e.changedTouches.length; ++i) {
			const changedTouch = e.changedTouches.item(i);
			let alreadyTracked = false;
			changedPointers.set(changedTouch.identifier, this.convertPointerToLocalVector(changedTouch));
			for (let j = 0; j < e.touches.length && !alreadyTracked; ++j) {
				alreadyTracked |= e.touches.item(j) === changedTouch;
			}
			if (!alreadyTracked) {
				++pointersInvolved;
			}
		}

		return this.createNewInputStateFromEvent(e).merge({
			pointers,
			changedPointers,
			pointerType: 'touch',
			pointersInvolved
		});
	}

	keyEventToNewInputState(e) {
		return this.createNewInputStateFromEvent(e);
	}

	createNewInputStateFromEvent(e) {
		return this.createNewInputState()
			.merge({
				shiftKey: e.shiftKey,
				ctrlKey: e.ctrlKey,
				timeStamp: e.timeStamp || (new Date().getTime())
			});
	}

	createNewInputState() {
		const {eventTrackerState} = this.state;
		return eventTrackerState.get('inputState', Immutable.Map());
	}

	shouldComponentUpdate(nextProps) {
		return this.props !== nextProps;
	}
}

EventTracker.propTypes = {
	onInput: PropTypes.func,
	style: PropTypes.object,
	className: PropTypes.string,
	targetRef: refPropType
};
