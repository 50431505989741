import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicator from './LoadingIndicator.js';
import OverlayContainer from './OverlayContainer.js';

import '../../../../styles/commons/components/webview/LoadingIndicatorOverlay.scss';

export default function LoadingIndicatorOverlay(props) {
	const {loading, label} = props;
	return (
		<OverlayContainer visible={loading}>
			<LoadingIndicator className='loading-indicator-overlay' label={label} />
		</OverlayContainer>
	);
}

LoadingIndicatorOverlay.propTypes = {
	loading: PropTypes.bool,
	label: PropTypes.string
};
