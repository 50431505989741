export default function createGroupTaskSelector(groupId, isPermanent = false) {
	return function groupTaskSelector(taskCollection) {
		const task = taskCollection.removeFirstTaskInGroup(groupId);
		const nextSelector = !isPermanent && task === null ? null : groupTaskSelector;

		return {
			task,
			nextSelector
		};
	};
}
