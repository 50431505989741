import React from 'react';
import PropTypes from 'prop-types';

import GenderFemale from '../../../../images/icons/webview/sex-female.svg';
import GenderMale from '../../../../images/icons/webview/sex-male.svg';
import GenderOther from '../../../../images/icons/webview/sex-other.svg';
import GenderUnknown from '../../../../images/icons/webview/sex-unknown.svg';
import {createSVGComponentIcon} from './SVGIconCreationUtils.js';

function withGenderIconStylingCorrections(Component) {
	const strokeStyle = {
		stroke: 'currentColor',
		strokeWidth: '2px'
	};
	function StyledGenderIcon(props) {
		const {style, ...remainingProps} = props;
		const finaleStyles = {
			...strokeStyle,
			...style
		};
		return <Component style={finaleStyles} {...remainingProps} />;
	}

	StyledGenderIcon.propTypes = {
		style: PropTypes.object
	};

	return StyledGenderIcon;
}

const GenderMaleIcon = createSVGComponentIcon(withGenderIconStylingCorrections(GenderMale), undefined, '40');
const GenderFemaleIcon = createSVGComponentIcon(withGenderIconStylingCorrections(GenderFemale), undefined, '40');
const GenderOtherIcon = createSVGComponentIcon(withGenderIconStylingCorrections(GenderOther), undefined, '40');
const GenderUnknownIcon = createSVGComponentIcon(GenderUnknown, undefined, '40');

export {
	GenderMaleIcon,
	GenderFemaleIcon,
	GenderOtherIcon,
	GenderUnknownIcon
};
