import {mat3, vec2} from 'gl-matrix';

import {DEVICE_ORIENTATION_LANDSCAPE} from '../../commons/constants/DeviceInformation.js';
import {IS_DEBUG_BUILD} from '../../commons/constants/EnvironmentConstants.js';
import {identity} from '../../commons/utils/FunctionUtils.js';
import {DEFAULT_LAYOUT_MODE, MAX_MOBILE_LAYOUT_MODE, MODE_TO_LAYOUT} from '../constants/ViewerConstants.js';

export function getRestrictedLayoutForMode(desiredLayout, isMobile, deviceOrientation) {
	let layout = MODE_TO_LAYOUT.get(DEFAULT_LAYOUT_MODE);
	if (desiredLayout !== 0) {
		if (isMobile && desiredLayout > MAX_MOBILE_LAYOUT_MODE) {
			if (deviceOrientation === DEVICE_ORIENTATION_LANDSCAPE) {
				layout = MODE_TO_LAYOUT.get(2); // 2x1;
			} else {
				layout = MODE_TO_LAYOUT.get(1); // 1x2;
			}
		} else {
			layout = MODE_TO_LAYOUT.get(desiredLayout, layout);
		}
	}
	return layout;
}

export function viewerIndexToViewerId(viewerIndex) {
	if (typeof (viewerIndex) !== 'number') {
		throw new Error('Passed viewerIndex must be a valid number.');
	}
	return viewerIndex.toString();
}

export function viewerIdToViewerIndex(viewerId) {
	return Number.parseInt(validateViewerId(viewerId), 10);
}

export const validateViewerId = IS_DEBUG_BUILD ? validateViewerIdDebug : identity;

function validateViewerIdDebug(viewerId) {
	const valid = typeof (viewerId) === 'string' &&	!Number.isNaN(parseInt(viewerId, 10));
	if (!valid) {
		throw new Error(`Passed invalid viewerId: ${viewerId}`);
	}
	return viewerId.trim();
}

export function scaleByInverseDevicePixelRatio(transformationMatrix, devicePixelRatio) {
	const inverseDevicePixelRatio = 1 / devicePixelRatio;
	let devicePixelRatioMatrix = mat3.create();
	devicePixelRatioMatrix = mat3.scale(
		devicePixelRatioMatrix, devicePixelRatioMatrix,
		vec2.set(vec2.create(), inverseDevicePixelRatio, inverseDevicePixelRatio)
	);
	return mat3.multiply(devicePixelRatioMatrix, devicePixelRatioMatrix, transformationMatrix);
}

export function getToolCursorClassName(tool) {
	return tool ? `active-tool-${tool}` : '';
}

export function isDicomViewer(viewerType) {
	return viewerType === 'series';
}

export function isMultiColumnLayout(viewerLayout) {
	return Boolean(viewerLayout) && viewerLayout.get('columns', 0) > 1;
}

