import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import useTranslator from '../../../i18n/hooks/useTranslator.js';
import ViewerTranslator from '../../../i18n/translators/ViewerTranslator.js';
import ViewerContext from '../../components/ViewerContext.js';
import {createSelectChainID} from '../flux/ViewerSyncSelectors.js';

export default function SyncStateIndicatorOverlay(props) {
	const {yem} = props;
	const {viewerId} = useContext(ViewerContext);
	const selectChainID = useMemoFactory(createSelectChainID, viewerId);
	const chainId = useSelector(selectChainID);
	return chainId === null ? false : <SyncStateIndicator yem={yem} />;
}
SyncStateIndicatorOverlay.propTypes = {
	yem: PropTypes.number
};

function SyncStateIndicator(props) {
	const {yem} = props;
	const yOffsetValue = `${yem}em`;
	return (
		<text x={0} y={yOffsetValue}>
			<SyncStateText />
		</text>
	);
}
SyncStateIndicator.propTypes = {
	yem: PropTypes.number
};

function SyncStateText() {
	const translator = useTranslator(ViewerTranslator);
	return translator('SyncStateIndicatorLabel');
}
