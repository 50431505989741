import {RBTree} from 'bintrees';

import DoublyLinkedList from '../commons/utils/DoublyLinkedList.js';

const TASK_GROUP_ID = Symbol('taskGroupID');
const TASK_ID = Symbol('taskID');

function generateUniqueTaskId(id) {
	const padding = '0000000';
	const numericPart = (padding + id).slice(-(padding.length));
	return `$$TaskID${numericPart}`;
}


export default class TaskCollection {
	constructor() {
		this.taskQueue = new DoublyLinkedList();
		this.taskGroups = {};
		this.nextTaskNumber = 0;
	}

	isEmpty() {
		return this.taskQueue.isEmpty();
	}

	clear() {
		this.taskQueue.clear();
		this.taskGroups = {};
	}

	add(task, groupIdentifier = '&&Default$$TaskGroup$$', taskId = generateUniqueTaskId(this.nextTaskNumber++)) {
		const taskContainer = {
			[TASK_ID]: taskId,
			[TASK_GROUP_ID]: groupIdentifier,
			task
		};

		const taskGroup = this.ensureTaskGroup(groupIdentifier);

		if (!taskGroup.find(taskContainer)) {
			taskGroup.insert(taskContainer);
			this.taskQueue.push(taskContainer);
		}
		return {taskIdentifier: taskId, groupIdentifier};
	}

	ensureTaskGroup(taskGroupIdentifier) {
		if (!(taskGroupIdentifier in this.taskGroups)) {
			this.taskGroups[taskGroupIdentifier] = new RBTree(taskContainerComparator);
		}
		return this.taskGroups[taskGroupIdentifier];
	}

	dequeueTask() {
		return this.removeTaskContainer(this.taskQueue.tail);
	}

	removeTaskContainer(taskContainer) {
		let task = null;
		if (taskContainer !== null) {
			const taskGroupId = taskContainer[TASK_GROUP_ID];
			const taskGroup = this.getTaskGroup(taskGroupId);
			taskGroup.remove(taskContainer);
			this.removeTaskGroupIfEmpty(taskGroup, taskGroupId);
			DoublyLinkedList.unlink(taskContainer);
			task = taskContainer.task;
		}
		return task;
	}

	getTaskGroup(taskGroupId) {
		return this.taskGroups[taskGroupId] || null;
	}

	removeTaskGroupIfEmpty(taskGroup, taskGroupId) {
		if (taskGroup.size === 0) {
			delete this.taskGroups[taskGroupId];
		}
	}

	removeTask(groupIdentifier, taskId) {
		const taskGroup = this.getTaskGroup(groupIdentifier);
		return taskGroup && this.removeTaskContainer(taskGroup.find(generateNeedle(taskId)));
	}

	removeFirstTaskInGroup(groupIdentifier) {
		const taskGroup = this.getTaskGroup(groupIdentifier);
		return taskGroup && this.removeTaskContainer(taskGroup.iterator().next());
	}

	/**
	 * Tries to take the task specified by the given identifier. If the task is already taken
	 *
	 * @param groupIdentifier
	 * @param taskId
	 * @param preferredSearchDirectionIsForward if true and the exact task could not be found it is checked whether
	 * a next task exist if not it will be checked in the reverse order if a previous task exist. If false is provided
	 * first it is checked whether a previous task exists and next whether a next task exists.
	 * @return returns the task which was removed from the collection
	 */
	removeTaskOrSibling(groupIdentifier, taskId, preferredSearchDirectionIsForward = true) {
		const taskGroup = this.getTaskGroup(groupIdentifier);
		const taskContainer = taskGroup &&
			findTaskOrSiblingInTaskGroup(taskGroup, taskId, preferredSearchDirectionIsForward);
		return this.removeTaskContainer(taskContainer);
	}

	removeClosestTask(groupIdentifier, taskId, calculateDistance) {
		const taskGroup = this.getTaskGroup(groupIdentifier);
		const taskContainer = taskGroup &&
			(
				taskGroup.find(generateNeedle(taskId)) ||
				findClosesTaskContainerInGroup(taskGroup, taskId, calculateDistance)
			);
		return this.removeTaskContainer(taskContainer);
	}
}

function taskContainerComparator(taskA, taskB) {
	const taskAId = taskA[TASK_ID];
	const taskBId = taskB[TASK_ID];

	if (taskAId === taskBId) {
		return 0;
	} else if (taskAId < taskBId) {
		return -1;
	}
	return 1;
}

function generateNeedle(taskId) {
	return {[TASK_ID]: taskId};
}

function findTaskOrSiblingInTaskGroup(taskGroup, taskId, preferredSearchDirectionIsForward) {
	const needle = generateNeedle(taskId);
	let taskContainer = taskGroup.find(needle);

	if (taskContainer === null) {
		if (preferredSearchDirectionIsForward) {
			const it = taskGroup.lowerBound(needle);
			taskContainer = it.data() || it.prev();
		} else {
			const it = taskGroup.lowerBound(needle);
			taskContainer = it.prev() || it.next();
		}
	}
	return taskContainer;
}

function findClosesTaskContainerInGroup(taskGroup, taskId, calculateDistance) {
	const needle = generateNeedle(taskId);
	const forwardFacingIt = taskGroup.lowerBound(needle);
	const nextContainer = forwardFacingIt.data() || forwardFacingIt.prev();
	const backwardFacingIt = taskGroup.lowerBound(needle);
	const previousContainer = backwardFacingIt.prev() || backwardFacingIt.next();
	let taskContainer;
	if (nextContainer === previousContainer) {
		taskContainer = nextContainer;
	} else {
		const distanceToLowerTaskId = calculateDistance(previousContainer[TASK_ID], taskId);
		const distanceToHigherTaskId = calculateDistance(taskId, nextContainer[TASK_ID]);
		taskContainer = distanceToLowerTaskId < distanceToHigherTaskId ? previousContainer : nextContainer;
	}
	return taskContainer;
}
