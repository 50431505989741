export const IMAGE_JPEG = 'image/jpeg';
export const IMAGE_PNG = 'image/png';
export const SYN_ADVANCED_JPEG = 'application/x.syn-advanced-jpeg';
export const SYN_ADVANCED_PNG = 'application/x.syn-advanced-png';
export const APPLICATION_PDF = 'application/pdf';
export const APPLICATION_OCTET_STREAM = 'application/octet-stream';
export const APPLICATION_JSON = 'application/json';
export const APPLICATION_XML = 'application/xml';
export const APPLICATION_XHTML_XML = 'application/xhtml+xml';
export const TEXT_PLAIN = 'text/plain';
export const TEXT_HTML = 'text/html';
export const TEXT_JAVASCRIPT = 'text/javascript';
