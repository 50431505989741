import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {vacuumContainerAlongPath} from '../../commons/utils/ImmutableUtils.js';
import {RESTORE_FROM_HISTORY, UPDATE_LOCATION_STATE} from '../constants/LocationActionTypes.js';

const FORCED_LOCATION_UPDATE_PATH = ['hidden', 'last_forced_location_update'];

export default handleActions({
	[RESTORE_FROM_HISTORY]: handleRestoreFromHistory,
	[UPDATE_LOCATION_STATE]: handleUpdateLocationState
}, Immutable.Map());

function handleUpdateLocationState(state, {payload: {value, path, forceUpdate}}) {
	return state.update('location', Immutable.Map(), location => {
		let nextLocation;
		if (!path || path.length === 0) {
			nextLocation = value ? Immutable.fromJS(value) : Immutable.Map();
		} else if (value !== null && value !== undefined) {
			nextLocation = location.setIn(path, value);
		} else {
			nextLocation = vacuumContainerAlongPath(location.deleteIn(path), path.slice(0, path.length - 1));
		}
		if (forceUpdate) {
			nextLocation = nextLocation.setIn(FORCED_LOCATION_UPDATE_PATH, new Date().getTime());
		}
		return handleHiddenStateUpdate(nextLocation, location);
	});
}

function handleHiddenStateUpdate(nextLocation, location) {
	let locationWithHiddenState = nextLocation;
	if (locationWithHiddenState.has('hidden')) {
		const newHiddenState = locationWithHiddenState.get('hidden');
		if (newHiddenState === null || newHiddenState === undefined) {
			locationWithHiddenState = locationWithHiddenState.remove('hidden');
		}
	} else if (location.has('hidden')) {
		locationWithHiddenState = locationWithHiddenState.set('hidden', location.get('hidden'));
	}
	return locationWithHiddenState;
}

function handleRestoreFromHistory(state, {payload}) {
	return state.set('location', payload);
}
