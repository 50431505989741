import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import RequestPasswordResetPage from '../../components/RequestPasswordResetPage.js';
import {clearError, requestPasswordReset} from '../actions/PasswordActions.js';
import {logout} from '../actions/SessionActions.js';
import {selectError, selectIsBusy, selectOperationSucceeded} from '../selectors/PasswordStateSelectors.js';
import {selectIsLoggedIn} from '../selectors/SessionSelectors.js';

export default connect(
	createStructuredSelector({
		isLoggedIn: selectIsLoggedIn,
		isBusy: selectIsBusy,
		passwordError: selectError,
		requestSucceeded: selectOperationSucceeded
	}),
	bindActions({
		resetPasswordState: clearError,
		requestPasswordReset,
		logout
	})
)(RequestPasswordResetPage);
