import {connect} from 'react-redux';

import {getShareId, isShareDone} from '../../../commons/data/aim/DocumentHelpers.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {markSharesDone} from '../../../inbox/flux/InboxActions.js';
import ShareDoneButton from '../../components/ShareDoneButton.js';

export default connect(undefined, bindActions({
	markSharesDone
}), (stateProps, dispatchProps, ownProps) => {
	const done = isShareDone(ownProps.document);

	return {
		...ownProps,
		done,
		onClick() {
			const {document} = ownProps;
			dispatchProps.markSharesDone([{patId: document.getIn(['patient', 'id']), shareId: getShareId(document), done: !done}]);
		}
	};
})(ShareDoneButton);
