import {useMemoFactory} from '../../commons/utils/customHooks';
import useTranslator from './useTranslator.js';

export default function useTranslation(messageId, messageParams = {}, translator = undefined, locale = undefined) {
	const translate = useTranslator(translator, locale);
	return useMemoFactory(translateMessage, translate, messageId, messageParams);
}

function translateMessage(translate, messageId, params) {
	return translate(messageId, params);
}
