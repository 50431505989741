import {clearPatientData} from '../../patient-details/flux/PatientDetailsActions.js';
import {cancelAllTasks} from '../../scheduling/api';
import {removeReadOnlyAnnotations} from '../../viewer/flux/actions/AnnotationActions.js';
import {clearViewerItems} from '../../viewer/flux/actions/ViewerItemsActions.js';

export function unloadCurrentPatient() {
	return dispatch => {
		cancelAllTasks();
		dispatch(clearViewerItems());
		dispatch(clearPatientData());
		dispatch(removeReadOnlyAnnotations());
	};
}
