import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {arePointerEventsSupported, preventEventDefault} from '../../../commons/utils/DOMEventUtils.js';
import {callSafe} from '../../../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../../../commons/utils/ObjectUtils/index.js';

import '../../../../styles/viewer/components/annotations/AnnotationRootSVGGroup.scss';

export default class AnnotationRootSVGGroup extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.eventHandlers = this.createEventHandlers();

		this.state = {
			hover: false
		};
	}

	render() {
		const {
			readOnly, children, lineHeight, fontSize, active,
			className: passedClassName, isPrintPreview
		} = this.props;
		const {hover} = this.state;
		const style = {lineHeight, fontSize};
		const className = classNames('annotation', passedClassName, {
			hover: (hover && !isPrintPreview),
			'annotation-active': active
		});
		const {onPointerDown, onPointerEnter, onPointerLeave, onMouseLeave, onMouseDown, onMouseEnter, onTouchStart} =
			(readOnly ? {} : this.eventHandlers);
		return (
			<g style={style} className={className}
					onMouseDown={onMouseDown} onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}
					onTouchStart={onTouchStart}
					onPointerDown={onPointerDown} onPointerEnter={onPointerEnter} onPointerLeave={onPointerLeave}>
				{children}
			</g>
		);
	}

	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return !shallowEqual(this.props, nextProps) ||
			!shallowEqual(this.state, nextState) ||
			!shallowEqual(this.context, nextContext);
	}

	createEventHandlers() {
		const {onMouseEnter, onMouseLeave, ...remainingEventHandlers} =
			arePointerEventsSupported() ? this.createPointerEventHandlers() : this.createNonPointerEventHandlers();
		const finalOnMouseEnter = e => {
			const {onMouseEnter: propsOnMouseEnter} = this.props;
			callSafe(onMouseEnter, e);
			callSafe(propsOnMouseEnter, e);
		};
		const finalOnMouseLeave = e => {
			callSafe(onMouseLeave, e);
			const {onMouseLeave: propsOnMouseLeave} = this.props;
			callSafe(propsOnMouseLeave, e);
		};
		return {
			...remainingEventHandlers,
			onMouseLeave: finalOnMouseLeave,
			onMouseEnter: finalOnMouseEnter
		};
	}

	createPointerEventHandlers() {
		return {
			onPointerDown: this.activate.bind(this),
			onPointerEnter: this.setHover.bind(this),
			onPointerLeave: this.clearHover.bind(this)
		};
	}

	createNonPointerEventHandlers() {
	    const boundActivate = this.activate.bind(this);
		return {
			onTouchStart: boundActivate,
			onMouseDown: boundActivate,
			onMouseEnter: this.setHover.bind(this),
			onMouseLeave: this.clearHover.bind(this)
		};
	}

	setHover() {
		this.setState({hover: true});
	}

	clearHover() {
		this.setState({hover: false});
	}

	activate(e) {
		const {onActivate, active, annotationId} = this.props;
		if (!active) {
			onActivate(annotationId);
		}
		preventEventDefault(e);
	}
}

AnnotationRootSVGGroup.propTypes = {
	annotationId: PropTypes.string,
	active: PropTypes.bool,
	onActivate: PropTypes.func,
	className: PropTypes.string,
	readOnly: PropTypes.bool,
	lineHeight: PropTypes.number,
	fontSize: PropTypes.number,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	isPrintPreview: PropTypes.bool
};
