import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../ui/components/Button.js';
import NextIcon from '../../ui/components/icons/NextIcon.js';
import PreviousIcon from '../../ui/components/icons/PreviousIcon.js';
import LinkLikeButton from '../../ui/components/LinkLikeButton.js';
import StepperProgress from './StepperProgress.js';

import '../../../styles/material-design/components/StepperFooter.scss';

export default function StepperFooter(props) {
	const {
		showPreviousButton, previousLabel, nextLabel, currentStep, nrSteps, stepComplete, toNextStep, toPreviousStep
	} = props;

	const BackButtonComponent = currentStep === 1 ? LinkLikeButton : Button;
	const previousButton = showPreviousButton && (
		<BackButtonComponent flat onClick={toPreviousStep}>
			{currentStep > 1 && <PreviousIcon />}
			{Boolean(previousLabel) && previousLabel}
		</BackButtonComponent>
	);

	const footerClassNames = classNames({
		'material-stepper--footer': true,
		'only-next-button': !previousButton
	});
	return (
		<footer className={footerClassNames}>
			{previousButton}
			<StepperProgress className='progress' currentStep={currentStep} nrSteps={nrSteps} />
			<Button id='stepperNext' flat disabled={!stepComplete} onClick={toNextStep}>
				{Boolean(nextLabel) && nextLabel}
				{currentStep < nrSteps && <NextIcon />}
			</Button>
		</footer>
	);
}

StepperFooter.propTypes = {
	showPreviousButton: PropTypes.bool,
	previousLabel: PropTypes.node,
	nextLabel: PropTypes.node,
	currentStep: PropTypes.number,
	nrSteps: PropTypes.number,
	stepComplete: PropTypes.bool,
	toNextStep: PropTypes.func,
	toPreviousStep: PropTypes.func
};
