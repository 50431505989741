import Immutable from 'immutable';

import {debugLog} from '../commons/utils/DebugLog.js';
import BrickBase from './BrickBase.js';
import {declareBrick, getProvidedBricks} from './brickTools.js';

export default class BrickRegistry extends BrickBase {
	constructor(fallbackRegistry) {
		super();
		this.fallbackRegistry = fallbackRegistry;
		this.updateBrickState(() => ({
			registry: Immutable.Map(),
			fallbackRegistryUpdates: 0
		}));
		this.registerBrick(this);
		if (fallbackRegistry) {
			this.subscribeTo(fallbackRegistry, this.onFallbackRegistryChanged);
		}
	}

	onFallbackRegistryChanged() {
		this.updateBrickState(oldState => ({
			...oldState,
			fallbackRegistryUpdates: oldState.fallbackRegistryUpdates + 1
		}));
	}

	registerBrick(brick) {
		this.updateBrickState(oldState => {
			const providedBricks = getProvidedBricks(brick);
			const {registry} = oldState;
			const newRegistry = providedBricks.reduce(
				(newState, BrickType) => {
					debugLog(`BrickRegistry: registering new brick of type ${BrickType.name}`);
					return newState.update(BrickType, Immutable.Set(), bricks => bricks.add(brick));
				},
				registry
			);
			return {
				...oldState,
				registry: newRegistry
			};
		});
		return this.unregisterBrick.bind(this, brick);
	}

	unregisterBrick(brick) {
		this.updateBrickState(oldState => {
			const providedBricks = getProvidedBricks(brick);
			const {registry} = oldState;
			const newRegistry = providedBricks.reduce(
				(newState, BrickType) => {
					debugLog(`BrickRegistry: unregistering brick of type ${BrickType.name}`);
					const bricksOfType = newState
						.get(BrickType, Immutable.Set())
						.delete(brick);
					if (bricksOfType.isEmpty()) {
						return newState.delete(BrickType);
					}
					return newState.set(BrickType, bricksOfType);
				},
				registry
			);
			return {
				...oldState,
				registry: newRegistry
			};
		});
	}

	getOnlyBrickOfType(BrickType) {
		const bricksOfType = this.getBrickState().registry
			.get(BrickType, Immutable.Set());
		if (bricksOfType.size === 1) {
			return bricksOfType.first();
		} else if (bricksOfType.size > 1) {
			throw new Error(`More than one brick of type ${BrickType.name} registered.`);
		} else if (bricksOfType.size === 0 && this.fallbackRegistry) {
			return this.fallbackRegistry.getOnlyBrickOfType(BrickType);
		}
		return null;
	}

	getAllBricksOfType(BrickType) {
		let myBricks =
			this.getBrickState().registry
				.get(BrickType, Immutable.Set())
				.toArray();
		if (this.fallbackRegistry) {
			myBricks = [
				...myBricks,
				...this.fallbackRegistry.getAllBricksOfType(BrickType)
			];
		}
		return Object.freeze(myBricks);
	}
}
declareBrick(BrickRegistry);
