import React from 'react';

import {tr} from '../../i18n/components/SynFormattedMessage.js';
import AnnotationsTranslator from '../../i18n/translators/AnnotationsTranslator.js';
import {useToggleAnnotationState} from '../hooks/useToggleAnnotationState.js';
import ViewerToolsMenuEntry from './ViewerToolsMenuEntry.js';

export default function ViewerToggleAnnotationsToolsMenu() {
	const [toggleAnnotations, icon] = useToggleAnnotationState();
	const AnnotationLabel = tr(AnnotationsTranslator, 'AnnotationsVisible');
    
	return (
		<ViewerToolsMenuEntry onClick={toggleAnnotations}
            metricsKey='ToggleAnnotations'
				id='toggle-annotations-menu-entry'
            label={AnnotationLabel}
            icon={icon} />
	);
}
