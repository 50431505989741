import React, {useContext} from 'react';
import {bool, instanceOf, number, object, string} from 'prop-types';

import {DEVICE_SIZE_LARGE, DEVICE_SIZE_MEDIUM, DEVICE_SIZE_SMALL} from '../../../commons/constants/DeviceInformation.js';
import {currentLocale} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {useMemoFactory} from '../../../commons/utils/customHooks';
import {MATERIAL_SLIDER_HEIGHT} from '../../../material-design/constants/SliderConstants.js';
import {isScreenSizeSmallerThanDesktop} from '../../../material-design/Utils.js';
import DicomImage from '../../data/DicomImage.js';
import {isMultiColumnLayout} from '../../utils/ViewerUtils.js';
import ViewerContext from '../ViewerContext.js';
import DicomImageMetaDataOverlay from './DicomImageMetaDataOverlay.js';
import {useDicomViewerState} from './DicomSeriesContextProvider.js';

const HORIZONTAL_PADDING = 10;

// This component was extracted because it's responsibility is solely to compute display properties for the actual
// MetaDataOverlay presentational component. The contained logic would pollute the container component.
export default function DicomImageMetaDataOverlayPresenter(props) {
	const {isPrintPreview, deviceSize, viewerLayout, screenSize, dicomImage, ...viewerProps} = props;
	const {isFullScreen} = useContext(ViewerContext);
	const {numberOfFrames} = useDicomViewerState();
	const isSliderVisible = numberOfFrames > 1;

	const respectSlider = (isSliderVisible && !isPrintPreview) &&
		shouldRespectSlider(deviceSize, viewerLayout, isFullScreen, screenSize);
	const margins = useMemoFactory(getViewportMargins, dicomImage.supportsLengthScale, respectSlider);

	return (
		<DicomImageMetaDataOverlay {...viewerProps} deviceSize={deviceSize} margins={margins}
		                           isFullScreen={isFullScreen} locale={currentLocale()} dicomImage={dicomImage} />
	);
}

DicomImageMetaDataOverlayPresenter.propTypes = {
	deviceSize: string,
	viewerLayout: object,
	screenSize: object,
	containerWidth: number,
	containerHeight: number,
	dicomImage: instanceOf(DicomImage),
	isPrintPreview: bool
};

function getViewportMargins(isLengthScaleVisible, respectSlider) {
	const EDGE_PADDING = HORIZONTAL_PADDING;
	const LENGTH_SCALE_PADDING = isLengthScaleVisible ? HORIZONTAL_PADDING : 0;
	const SLIDER_HEIGHT = respectSlider ? MATERIAL_SLIDER_HEIGHT : 0;
	return {
		left: EDGE_PADDING,
		top: EDGE_PADDING + LENGTH_SCALE_PADDING,
		bottom: EDGE_PADDING + SLIDER_HEIGHT,
		right: EDGE_PADDING + LENGTH_SCALE_PADDING
	};
}

function shouldRespectSlider(deviceSize, viewerLayout, isFullScreen, screenSize) {
	let reserveSpaceForSlider = true;
	if (isFullScreen) {
		reserveSpaceForSlider &= deviceSize === DEVICE_SIZE_SMALL;
	} else if (deviceSize === DEVICE_SIZE_LARGE) {
		reserveSpaceForSlider &= isScreenSizeSmallerThanDesktop(screenSize);
		reserveSpaceForSlider &= isMultiColumnLayout(viewerLayout);
	} else if (deviceSize === DEVICE_SIZE_MEDIUM) {
		reserveSpaceForSlider &= isMultiColumnLayout(viewerLayout);
	} else {
		reserveSpaceForSlider &= deviceSize === DEVICE_SIZE_SMALL;
	}
	return reserveSpaceForSlider;
}
