import {originatedFromKeyEvent, stopOriginalDOMEvent} from '../../commons/utils/EventUtils.js';

export default function stopToolEvent(event, next) {
	// Make sure key events can pass through to allow ctrl-c copy in pdf viewer.
	if (isToolEvent(event) && !originatedFromKeyEvent(event)) {
		stopOriginalDOMEvent(event);
	}
	next(event);
}

function isToolEvent(event) {
	return event.get('tool', '') !== '';
}
