import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from './layout/CenterLayout.js';

export default function ErrorGuard(props) {
	const {error, errorNode, children} = props;
	return error ? (
		<CenterLayout>
			{errorNode}
		</CenterLayout>
	) : children;
}

ErrorGuard.propTypes = {
	error: PropTypes.bool,
	errorNode: PropTypes.node.isRequired
};
