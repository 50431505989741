import {WEBAPP_NAME} from '../../../commons/constants/EnvironmentConstants.js';
import createFormAccessors from '../../../commons/data/FormAccessorsFactory.js';
import {required} from '../../../commons/data/validators';
import PasswordMessagesTranslator from '../../i18n/PasswordMessagesTranslator.js';

const USERNAME_FIELD_LABEL = WEBAPP_NAME === 'webpatient' ? 'UsernameOrEmail' : 'Username';

const RESET_PASSWORD_FORM_SCHEMA = {
	fields: {
		username: {
			label: USERNAME_FIELD_LABEL,
			defaultValue: null,
			validators: [required]
		}
	}
};

export default createFormAccessors(RESET_PASSWORD_FORM_SCHEMA, PasswordMessagesTranslator);
