import createFormAccessors from '../../commons/data/FormAccessorsFactory.js';
import {allFieldsFilledOut, email, minLength, phonenumber, required} from '../../commons/data/validators';
import ValidatorsMessagesTranslator from '../../commons/data/validators/i18n/ValidatorsMessagesTranslator.js';
import {CREATE_SHARE_RECEIVER_FORM_ID} from '../constants/ShareDocumentConstants.js';
import ShareDocumentTranslator from '../i18n/ShareDocumentTranslator.js';

const FIELD_MIN_LENGTH = 3;

const CREATE_SHARE_RECEIVER_FORM_DEFINITION = {
	fields: {
		email: {
			label: 'FieldLabelEMailAddress',
			validators: [required, email]
		},
		firstName: {
			label: 'FieldLabelFirstName',
			validators: [required, minLength(FIELD_MIN_LENGTH)]
		},
		lastName: {
			label: 'FieldLabelLastName',
			validators: [required, minLength(FIELD_MIN_LENGTH)]
		},
		mobileNumber: {
			label: 'FieldLabelMobileNumber',
			validators: [required, phonenumber]
		}
	},
	validators: [allFieldsFilledOut(ValidatorsMessagesTranslator, 'AllFieldsAreRequired')]
};

export default createFormAccessors(
	CREATE_SHARE_RECEIVER_FORM_DEFINITION, ShareDocumentTranslator, CREATE_SHARE_RECEIVER_FORM_ID
);
