import Immutable from 'immutable';
import {createSelector} from 'reselect';

export function selectSelectedItems(state) {
	return state.selectedItems || Immutable.Map();
}

export function createSelectIsItemSelected(itemId) {
	return createSelector(
		selectSelectedItems,
		selectedItems => selectedItems.has(itemId)
	);
}

export function selectIsAnyItemSelected(state) {
	return !selectSelectedItems(state).isEmpty();
}


export const selectSelectedItemIds = createSelector(
	selectSelectedItems,
	items => items.keySeq().toArray()
);

