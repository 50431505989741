import React, {useContext, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {mat4} from 'gl-matrix';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import {immutableMapPropType} from '../../../../commons/utils/CustomPropTypes.js';
import SyncPointHandle from '../../../components/annotations/SyncPointHandle.js';
import {useDicomViewerContext} from '../../../components/dicom/DicomSeriesContextProvider.js';
import ViewerContext from '../../../components/ViewerContext.js';
import DicomImage from '../../../data/DicomImage.js';
import {
	createSelectClosestFrameOffset,
	selectIsPointSyncActive,
	selectSyncPointDisplayNumber
} from '../../selectors/DicomPointSyncSelectors.js';
import selectWithArgs from '../../selectors/selectWithArgs.js';

function invertProjectionMatrix(projectionMatrix) {
	return projectionMatrix ? mat4.invert(mat4.create(), projectionMatrix) : null;
}

function createSelectSyncPointInfo(seriesImageIndex) {
	return createStructuredSelector({
		isPointSyncActive: selectIsPointSyncActive,
		syncPointNumber: selectSyncPointDisplayNumber,
		closestFrameOffset: createSelectClosestFrameOffset(seriesImageIndex)
	});
}

function SyncPointHandleContainer(props) {
	const {viewerItem: {dicomDump}, annotationProperties} = props;
	const {isActive: isActiveViewer} = useContext(ViewerContext);
	const [viewerState, {setFrameIndex}] = useDicomViewerContext();
	const {seriesImageIndex, currentFrameOffset} = viewerState;

	const frameOfReferenceUID = dicomDump && dicomDump.getFrameOfReferenceUID();
	const projectionMatrix = dicomDump && dicomDump.getPatientToImagePixelsProjectionMatrix();
	const inverseProjectionMatrix = useMemo(() => invertProjectionMatrix(projectionMatrix), [projectionMatrix]);

	const syncPointInfoSelector = useMemo(() => createSelectSyncPointInfo(seriesImageIndex), [seriesImageIndex]);
	const syncPoint3d = annotationProperties.get('syncPoint3d');
	const {isPointSyncActive, closestFrameOffset, syncPointNumber} = useSelector(
		selectWithArgs(syncPointInfoSelector, frameOfReferenceUID, syncPoint3d)
	);

	const shouldUpdateFrameOffset = isPointSyncActive &&
		!isActiveViewer &&
		closestFrameOffset !== null &&
		currentFrameOffset !== closestFrameOffset;

	useEffect(() => {
		shouldUpdateFrameOffset && setFrameIndex(closestFrameOffset, false);
	}, [closestFrameOffset, setFrameIndex, shouldUpdateFrameOffset]);

	return (
		<SyncPointHandle isActiveViewer={isActiveViewer}
			display={isPointSyncActive}
			syncPointNumber={syncPointNumber}
			frameOfReferenceUID={frameOfReferenceUID}
			patientToImagePixelsProjectionMatrix={projectionMatrix}
			imagePixelsToPatientProjectionMatrix={inverseProjectionMatrix}
			{...props} />
	);
}

SyncPointHandleContainer.propTypes = {
	annotationProperties: immutableMapPropType,
	viewerItem: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.instanceOf(DicomImage)
	])
};

export default SyncPointHandleContainer;
