import {vec2} from 'gl-matrix';
import Immutable from 'immutable';

import createStateMachine from '../commons/utils/createStateMachine.js';
import {createEvent} from '../commons/utils/EventUtils.js';
import {noop} from '../commons/utils/FunctionUtils.js';

const TAP_RECOGNITION_IN_PROGRESS = Symbol('TAP_RECOGNITION_IN_PROGRESS');
const TAPS_RECOGNIZED = Symbol('TAPS_RECOGNIZED');
const INITIAL_STATE = Symbol('INITIAL_STATE');

const MAXIMUM_TAP_RADIUS = 20;
const MAXIMUM_TAP_DELAY = 400; //in ms
const DELAY_BETWEEN_TAPS = 150; //in ms

export default function createTapRecognizer(maximalNumberTaps) {
	return createStateMachine(INITIAL_STATE, transitionTo => ({
		[INITIAL_STATE]: (state, event, next) => {
			if (event.get('type') === 'pointerdown' && event.get('pointers').size === 1) {
				transitionToTapRecognitionInProgress(transitionTo, state, event);
			} else {
				next(event);
			}
		},
		[TAP_RECOGNITION_IN_PROGRESS]: (state, event, next) => {
			if (event.get('pointers').size > 1 || (event.get('pointers').size === 1 && !isEventInArea(state, event)) || hasMaximumDelayOccured(state)) {
				transitionToInitialStateOnFailure(transitionTo, state.set(event.type, event), event, next);
			} else if (event.get('pointers').size === 0 && event.get('type') === 'pointerup') {
				if (state.get('tapcount') === (maximalNumberTaps - 1)) {
					transitionToInitialStateOnSuccess(transitionTo, state, event, next);
				} else {
					transitionToRecognizedState(transitionTo, state, event, next);
				}
			} else if (event.get('type') === 'pointermove') {
				transitionTo(TAP_RECOGNITION_IN_PROGRESS, state.set('pointermove', event));
			} else {
				transitionToInitialStateOnFailure(transitionTo, state, event, next);
			}
		},
		[TAPS_RECOGNIZED]: (state, event, next) => {
			let newState = cancelTimer(state);
			if (event.get('type') === 'pointerdown' && event.get('pointers').size === 1) {
				if (!isEventInArea(newState, event)) {
					newState = handlePossibleTaps(newState, event, next).set('position', event.get('pointers').first());
				}
				transitionToTapRecognitionInProgress(transitionTo, newState, event);
			} else {
				transitionToInitialStateOnFailure(transitionTo, handlePossibleTaps(newState, event, next), event, next);
			}
		}
	}), Immutable.Map({tapcount: 0}));
}

function isEventInArea(state, event) {
	const distance = vec2.distance(state.get('position'), event.get('pointers').first());
	return distance < MAXIMUM_TAP_RADIUS;
}

function hasMaximumDelayOccured(state) {
	return (new Date().getTime() - state.get('startTime')) > MAXIMUM_TAP_DELAY;
}

function transitionToInitialStateOnFailure(transitionTo, state, event, next) {
	handlePossibleTaps(state, event, next);
	['pointerdown', 'pointermove', 'pointerup'].forEach(eventName => {
		if (state.has(eventName)) {
			next(state.get(eventName));
		}
	});
	transitionTo(INITIAL_STATE, Immutable.Map({tapcount: 0}));
}

function transitionToTapRecognitionInProgress(transitionTo, state, event) {
	transitionTo(TAP_RECOGNITION_IN_PROGRESS,
		state.set('pointerdown', event).set('position', event.get('pointers').first())
			.set('startTime', new Date().getTime())
	);
}

function transitionToRecognizedState(transitionTo, state, event, next) {
	function tapFinished() {
		transitionToInitialStateOnSuccess(transitionTo, state, event, next);
	}
	const timer = window.setTimeout(tapFinished, DELAY_BETWEEN_TAPS);
	function cancelTimerTransitionToRecognizedState() {
		window.clearTimeout(timer);
	}
	transitionTo(TAPS_RECOGNIZED, state
		.update('tapcount', tapcount => (tapcount + 1))
		.delete('pointermove')
		.delete('pointerdown')
		.delete('pointerup')
		.set('cancelTimer', cancelTimerTransitionToRecognizedState)
	);
}

function transitionToInitialStateOnSuccess(transitionTo, state, event, next) {
	handlePossibleTaps(state.update('tapcount', tapcount => (tapcount + 1)), event, next);
	transitionTo(INITIAL_STATE, Immutable.Map({tapcount: 0}));
}


function handlePossibleTaps(state, event, next) {
	let newState = state;
	if (state.get('tapcount') > 0) {
		next(createEvent('tap', {
			position: state.get('position'),
			tapcount: state.get('tapcount')
		}, event));
		newState = state.set('tapcount', 0);
	}
	return newState;
}

function cancelTimer(state) {
	state.get('cancelTimer', noop)();
	return state.delete('cancelTimer');
}
