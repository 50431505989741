import _once from 'lodash.once';

import {getMaxTextureSize} from '../utils/WebGLUtils.js';

export const getImageScalingRequestParameters = _once(() => {
	const maxTextureSize = getMaxTextureSize();
	return maxTextureSize > 0
		? {
			max_width: maxTextureSize,
			max_height: maxTextureSize,
			upscale: false
		} : {original_size: 1};
});

