import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import AnnotationsTranslator from '../../../i18n/translators/AnnotationsTranslator.js';
import {
	createMemoizedTextMeasureTool,
	determinePixelSize,
	getAnnotationDisplayPrecision,
	getAreaWithUnit,
	getEllipsePoints,
	getLengthWithUnit,
	toContainerPosition
} from '../../utils/AnnotationUtils.js';
import {createRectangle, moveRect, rectInRect, resizeRect} from '../../utils/math/Rectangle.js';
import {RAD_90_DEGREES, RAD_180_DEGREES, RAD_270_DEGREES, RAD_360_DEGREES} from '../../utils/MathUtils.js';
import {renderMultilineTextElements} from '../../utils/SVGUtils.js';
import {getFullVectorAngle, getMiddlePoint, getMirrorPoint} from '../../utils/VectorUtils.js';
import {getDicomDump} from '../../utils/ViewerItemUtils.js';
import {TOUCH_SIZE} from './AnnotationConstants.js';
import AnnotationLabel from './AnnotationLabel.js';
import createAnnotation, {ANNOTATION_PROP_TYPES} from './createAnnotation.js';
import Ellipse from './Ellipse.js';
import Path from './Path.js';
import SupportPath from './SupportPath.js';

function isEllipseMeasurementSupported() {
	return false;
}

function getDefaultPropertiesForEllipseMeasurement() {
	return Immutable.Map({});
}

function EllipseMeasurementComponent(props) {
	const textMeasureTool = useMemoFactory(createMemoizedTextMeasureTool);
	const {
		AnnotationRoot, annotationProperties, locale, viewerItem, lineHeight, fontSize, containerWidth, containerHeight
	} = props;
	const points = annotationProperties.get('points');
	const [vertice0, vertice1, covertice0] = points.map(point => toContainerPosition(props, point));
	const center = getMiddlePoint(vertice0, vertice1);
	const covertice1 = getMirrorPoint(vertice0, vertice1, covertice0);
	const labelInformation = useMemoFactory(calculateLabelInformation, locale, points, viewerItem);
	const textDimensions = textMeasureTool(labelInformation, 'annotation-label', fontSize, lineHeight);
	const labelProps = getLabelProps(
		{vertice0, vertice1, covertice0, center}, textDimensions, {containerWidth, containerHeight, fontSize}
	);
	const annotationLabelContent = useMemoFactory(renderMultilineTextElements, labelInformation, lineHeight, {x: '0'});
	return (
		<AnnotationRoot>
			<Ellipse points={[vertice0, center, covertice0]} />
			<Path points={[vertice0, vertice1]} />
			<SupportPath points={[covertice0, covertice1]} />
			<AnnotationLabel {...labelProps}>
				{annotationLabelContent}
			</AnnotationLabel>
		</AnnotationRoot>
	);
}

EllipseMeasurementComponent.propTypes = ANNOTATION_PROP_TYPES;

function getLabelProps({vertice0, vertice1, covertice0, center}, textDimensions, props) {
	const {containerWidth, containerHeight, fontSize} = props;
	const textRect = createRectangle(
		[-textDimensions.width / 2, -textDimensions.height / 2],
		[textDimensions.width / 2, textDimensions.height / 2]
	);
	const viewportRect = createRectangle(
		[-containerWidth / 2, -containerHeight / 2],
		[containerWidth / 2, containerHeight / 2]
	);
	const clippingRect = resizeRect(viewportRect, -fontSize * 2, -fontSize, -fontSize * 2, -fontSize * 2);
	const path = getEllipseWrapperPoints(vertice0, vertice1, covertice0, center);
	let labelPoint;
	path.some((pathSegment, pointIndex) => {
		const nextPointIndex = pointIndex + 1 >= path.length ? 0 : pointIndex + 1;
		const labelOrientation = getLabelOrientation([pathSegment, path[nextPointIndex]]);
		labelPoint = [
			pathSegment[0] + labelOrientation[0] * textDimensions.width,
			pathSegment[1] + labelOrientation[1] * textDimensions.height
		];
		return rectInRect(moveRect(textRect, labelPoint[0], labelPoint[1]), clippingRect);
	});
	return {x: labelPoint[0], y: labelPoint[1]};
}

function getLabelOrientation(pathSegment) {
	const [pointA, pointB] = pathSegment;
	const angle = getFullVectorAngle(vec2.sub(vec2.create(), pointB, pointA));
	let labelMovement = [0, 0];
	if (angle > 0 && angle <= RAD_90_DEGREES) {
		labelMovement = [0, -1];
	} else if (angle > RAD_180_DEGREES && angle <= RAD_270_DEGREES) {
		labelMovement = [-1, 0];
	} else if (angle > RAD_270_DEGREES && angle <= RAD_360_DEGREES) {
		labelMovement = [-1, -1];
	}
	return labelMovement;
}

function getEllipseWrapperPoints(vertice0, vertice1, covertice0, center) {
	const a = vec2.distance(vertice0, vertice1) / 2;
	const b = vec2.distance(covertice0, center);
	const angle = getFullVectorAngle(vec2.subtract(vec2.create(), vertice1, vertice0));
	return getEllipsePoints(a + TOUCH_SIZE / 2, b + TOUCH_SIZE / 2, center, angle);
}

function calculateLabelInformation(locale, points, viewerItem) {
	const dicomDump = getDicomDump(viewerItem);
	const pixelSize = determinePixelSize(points, dicomDump);
	const canMeasurePhysicalLength = Boolean(pixelSize);
	let information;
	if (canMeasurePhysicalLength) {
		information = extractInformationWithPixelSize(locale, points, pixelSize);
	} else {
		information = AnnotationsTranslator.getFormattedMessage('MeasurementNotPossible', locale);
	}
	return information;
}

function extractInformationWithPixelSize(locale, points, pixelSize) {
	const diameterLabel = AnnotationsTranslator.getFormattedMessage('Diameter', locale);
	const circumferenceLabel = AnnotationsTranslator.getFormattedMessage('Circumference', locale);
	const areaLabel = AnnotationsTranslator.getFormattedMessage('Area', locale);
	const [vertice0, vertice1, covertice0] = points;
	const center = getMiddlePoint(vertice0, vertice1);
	const r1 = calculateLength(vertice0, vertice1, pixelSize) / 2;
	const r2 = calculateLength(covertice0, center, pixelSize);
	const d1 = r1 * 2;
	const d2 = r2 * 2;
	const displayPrecision = getAnnotationDisplayPrecision();
	const {perimeter, area} = calculateAreaAndPerimeterForEllipse(r1, r2);
	const d1WithUnit = getLengthWithUnit(d1, displayPrecision);
	const d2WithUnit = getLengthWithUnit(d2, displayPrecision);
	const perimeterWithUnit = getLengthWithUnit(perimeter, displayPrecision);
	const areaWithUnit = getAreaWithUnit(area, displayPrecision);
	return `${diameterLabel} 1: ${d1WithUnit}\n${diameterLabel} 2: ${d2WithUnit}\n${circumferenceLabel}: ${perimeterWithUnit}\n${areaLabel}: ${areaWithUnit}`;
}

function calculateLength(pointA, pointB, pixelSize) {
	const distanceVector = vec2.subtract(new Float64Array(2), pointA, pointB);
	vec2.multiply(distanceVector, distanceVector, pixelSize);
	return vec2.length(distanceVector);
}

function calculateAreaAndPerimeterForEllipse(r1, r2) {
	const h = Math.pow(r1 - r2, 2) / Math.pow(r1 + r2, 2);
	// eslint-disable-next-line no-magic-numbers -- Ramanujan 2 formula should be expressed in its humble glory
	const perimeter = Math.PI * (r1 + r2) * (1 + 3 * h / (10 + Math.sqrt(4 - 3 * h))); // Ramanujan 2
	const area = Math.PI * r1 * r2;
	return {perimeter, area};
}

export default createAnnotation(
	isEllipseMeasurementSupported, getDefaultPropertiesForEllipseMeasurement, EllipseMeasurementComponent
);
