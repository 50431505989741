import {removeAnnotationsOfType} from '../../../../flux/actions/AnnotationActions.js';
import {selectAllDicomSeriesViewerIDs} from '../../../../flux/selectors/DicomSeriesViewerSelectors.js';
import {
	activateSyncTool,
	deactivateSyncTool,
	disconnectAllSyncedViewers,
	syncAllViewers
} from '../../../flux/ViewerSyncActions.js';
import {SYNC_TOOL_ID} from '../index.js';

export function activateOffsetPaging() {
	return function activateOffsetPagingThunk(dispatch) {
		dispatch(removeAnnotationsOfType('syncpoint'));
		dispatch(syncAllDicomSeriesViewers());
		dispatch(activateSyncTool(SYNC_TOOL_ID));
	};
}

export function deactivateOffsetPaging() {
	return function deactivateOffsetPagingThunk(dispatch) {
		dispatch(deactivateSyncTool(SYNC_TOOL_ID));
		dispatch(disconnectAllDicomSeriesViewers());
	};
}

export function disconnectAllDicomSeriesViewers() {
	return function disconnectAllDicomSeriesViewersThunk(dispatch, getState) {
		const dicomSeriesViewers = selectAllDicomSeriesViewerIDs(getState());
		dispatch(disconnectAllSyncedViewers(dicomSeriesViewers));
	};
}

export function syncAllDicomSeriesViewers() {
	return function syncAllDicomSeriesViewersThunk(dispatch, getState) {
		const allSeriesViewerIDs = selectAllDicomSeriesViewerIDs(getState());
		dispatch(syncAllViewers(allSeriesViewerIDs));
	};
}
