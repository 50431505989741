import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';

import DataGuard from '../../commons/components/data/DataGuard.js';
import ErrorGuard from '../../commons/components/ErrorGuard.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerMessagesTranslator from '../../i18n/translators/ViewerTranslator.js';
import {loadSeries} from '../flux/actions/ViewerItemsActions.js';
import {selectSeriesViewerItems} from '../flux/selectors/ViewerItemsSelectors.js';
import {getLoadError, hasSeriesLoadFinished, hasSeriesLoadStarted} from '../utils/ViewerItemUtils.js';

const ERROR_NODE = <SynFormattedMessage translator={ViewerMessagesTranslator} message='SeriesResolveErrorMessage' />;

export default function SeriesDataGuard(props) {
	const {seriesId, children} = props;
	const dispatch = useDispatch();
	const stateSelector = useMemo(() => selectLoadingState(seriesId), [seriesId]);
	const {loadStarted, loadFinished, error} = useSelector(stateSelector);
	const loadHandler = useMemo(() => () => dispatch(loadSeries(seriesId)), [seriesId, dispatch]);
	return (
		<DataGuard isLoadNeeded={!loadStarted} data={loadFinished} load={loadHandler}>
			<ErrorGuard errorNode={ERROR_NODE} error={error !== null}>
				{children}
			</ErrorGuard>
		</DataGuard>
	);
}

SeriesDataGuard.propTypes = {
	seriesId: PropTypes.number
};

function selectLoadingState(seriesId) {
	const selectSeriesViewerItem = state => selectSeriesViewerItems(state).get(seriesId);
	return createSelector(
		selectSeriesViewerItem,
		seriesViewerItem => ({
			loadStarted: hasSeriesLoadStarted(seriesViewerItem),
			loadFinished: hasSeriesLoadFinished(seriesViewerItem),
			error: getLoadError(seriesViewerItem)
		})
	);
}
