import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {ClickTrackedMenuEntry} from '../../metrics-collector/components/TrackedComponents.js';
import ViewerContext from './ViewerContext.js';

function ViewerToolsMenuEntry(props) {
	const {viewerId} = useContext(ViewerContext);
	const {id, label: LabelComponent, icon: IconComponent, onClick, metricsKey} = props;
	return (
		<ClickTrackedMenuEntry id={`${id}-${viewerId}`} onClick={onClick} metricsKey={metricsKey}
		                       icon={<IconComponent />} primaryText={<LabelComponent />} asListItem />
	);
}

ViewerToolsMenuEntry.propTypes = {
	id: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	label: PropTypes.func.isRequired,
	metricsKey: PropTypes.string.isRequired,
	icon: PropTypes.elementType.isRequired
};

export default ViewerToolsMenuEntry;
