import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../utils/ObjectUtils';
import ExpandingTransitionRenderer from './ExpandingTransitionRenderer.js';
import SynTransition from './SynTransition.js';

function ExpandingTransition(props) {
	const {sourceBoundingRect, targetBoundingRect, className, transitionDuration, children} = props;
	const remainingTransitionProps = cloneWithoutProperties(
		props, 'children', 'sourceBoundingRect', 'targetBoundingRect', 'className'
	);
	return (
		<SynTransition {...remainingTransitionProps}>
			<ExpandingTransitionRenderer sourceBoundingRect={sourceBoundingRect} targetBoundingRect={targetBoundingRect}
				className={className} transitionDuration={transitionDuration}>
				{children}
			</ExpandingTransitionRenderer>
		</SynTransition>
	);
}

ExpandingTransition.propTypes = {
	sourceBoundingRect: PropTypes.exact({
		left: PropTypes.number,
		top: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number
	}),
	targetBoundingRect: PropTypes.exact({
		left: PropTypes.number,
		top: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number
	}),
	className: PropTypes.string,
	transitionDuration: PropTypes.number
};

export default React.memo(ExpandingTransition);
