import React from 'react';

import Checkbox from './Checkbox.js';
import {ExpandLessIcon, ExpandMoreIcon} from './icons/ExpandIcons.js';

/**
 * Provides checkbox functionality, styled as collapse/uncollapse button
 * @author p.spitzlinger@synedra.com
 */
export default function ExpandButton(props) {
	return <Checkbox checkedIcon={<ExpandLessIcon />} uncheckedIcon={<ExpandMoreIcon />} {...props} />;
}
ExpandButton.proptTypes = Checkbox.propTypes;
