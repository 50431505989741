import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../../a11y/components/landmarks/Alert.js';
import ErrorHint from '../../commons/components/webview/ErrorHint.js';
import LinkButton from '../../commons/components/webview/LinkButton.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {STATUS_WARNING} from '../../commons/constants/StatusMessageConstants.js';
import {WEB_PATIENT_URL} from '../../commons/constants/SynSettingsConstants.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import LinkLikeButton from '../../ui/components/LinkLikeButton.js';
import LoginPageMessagesTranslator from '../i18n/LoginPageMessagesTranslator.js';

class StepPatientUserLoginError extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundRestartLoginWorkflow = this.restartLoginWorkflow.bind(this);
	}

	render() {
		return (
			<VerticalLayout key='centerLayout' className='login-page--status' element={Alert}>
				<StatusMessage status={STATUS_WARNING} statusId='patientUserNoteShort'>
					<SynFormattedMessage translator={LoginPageMessagesTranslator} message='PatientNoteShort' />
				</StatusMessage>
				<ErrorHint translator={LoginPageMessagesTranslator} hintMessage='PatientNoteDetail' />
				{WEB_PATIENT_URL &&
					<LinkButton href={WEB_PATIENT_URL} flat opaque>
						<SynFormattedMessage message='PatientNoteButton' translator={LoginPageMessagesTranslator} />
					</LinkButton>
				}
				<LinkLikeButton flat onClick={this.boundRestartLoginWorkflow} opaque={!WEB_PATIENT_URL}>
					<SynFormattedMessage message='ReLoginButtonLabel' translator={LoginPageMessagesTranslator} />
				</LinkLikeButton>
			</VerticalLayout>
		);
	}

	restartLoginWorkflow() {
		const {resetLoginWorkflow} = this.props;
		callSafe(resetLoginWorkflow);
	}
}

StepPatientUserLoginError.propTypes = {
	resetLoginWorkflow: PropTypes.func.isRequired
};

export default StepPatientUserLoginError;
