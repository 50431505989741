export default function getPrototypes(obj, stopBefore = Object) {
	const prototypes = [];
	let currentProto = Object.getPrototypeOf(obj);
	if (obj.prototype && obj.prototype.constructor === obj) {
		currentProto = obj.prototype;
	}
	while (currentProto !== stopBefore.prototype && currentProto !== Object.prototype) {
		prototypes.push(currentProto);
		currentProto = Object.getPrototypeOf(currentProto);
	}
	return prototypes;
}
