import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

const POSITION = {
	vertical: 'top',
	horizontal: 'center'
};

export default function Toast(props) {
	const {message, open, onClose, autoHideDuration} = props;

	return (
		<Snackbar anchorOrigin={POSITION} open={open} autoHideDuration={autoHideDuration} onClose={onClose}
		          message={message} />
	);
}

Toast.propTypes = {
	message: PropTypes.node,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	autoHideDuration: PropTypes.number
};

Toast.defaultProps = {
	autoHideDuration: 6000
};
