import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {bool, func, instanceOf} from 'prop-types';

import DicomImage from '../../data/DicomImage.js';
import DicomSeriesViewerToolbarContainer from '../../flux/containers/DicomSeriesViewerToolbarContainer.js';
import {createDicomImageSelector} from '../../flux/selectors/DicomSeriesViewerSelectors.js';
import ViewerBox from '../ViewerBox.js';
import ViewerContext from '../ViewerContext.js';
import {useDicomViewerContext} from './DicomSeriesContextProvider.js';
import DicomViewer from './DicomViewer.js';

export default function DicomSeriesViewerController() {
	const {isPrintPreview, isActive, activate} = useContext(ViewerContext);
	const [viewerState, {moveNextFrame, movePreviousFrame}] = useDicomViewerContext();
	const selectDicomImage = useMemo(() => createDicomImageSelector(viewerState), [viewerState]);
	const dicomImage = useSelector(selectDicomImage);
	return (
		<DicomSeriesViewer showToolbar={!isPrintPreview} isActive={isActive} activate={activate} rawImage={dicomImage}
		                   moveNextFrame={moveNextFrame} movePreviousFrame={movePreviousFrame} />
	);
}

class DicomSeriesViewer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.handleMouseWheel = this.handleMouseWheel.bind(this);
		this.onTapLeft = this.onTapLeft.bind(this);
		this.onTapRight = this.onTapRight.bind(this);
	}

	render() {
		const {showToolbar, rawImage} = this.props;
		const toolbar = showToolbar && <DicomSeriesViewerToolbarContainer dicomImage={rawImage} />;

		return (
			<ViewerBox onWheel={this.handleMouseWheel} toolbar={toolbar}>
				<DicomViewer dicomImage={rawImage} onTapLeft={this.onTapLeft} onTapRight={this.onTapRight} />
			</ViewerBox>
		);
	}

	handleMouseWheel(event) {
		const {movePreviousFrame, moveNextFrame} = this.props;
		const delta = event.deltaY <= 0 ? -1 : +1;
		delta < 0 ? movePreviousFrame() : moveNextFrame();
	}

	onTapLeft() {
		const {isActive, activate, movePreviousFrame} = this.props;
		isActive ? movePreviousFrame() : activate();
	}

	onTapRight() {
		const {isActive, activate, moveNextFrame} = this.props;
		isActive ? moveNextFrame() : activate();
	}
}

DicomSeriesViewer.propTypes = {
	showToolbar: bool.isRequired,
	movePreviousFrame: func.isRequired,
	moveNextFrame: func.isRequired,
	isActive: bool.isRequired,
	activate: func.isRequired,
	rawImage: instanceOf(DicomImage)
};
