import React, {useCallback} from 'react';
import Box from '@mui/material/Box';
import MuiButton, {buttonClasses} from '@mui/material/Button';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {preventEventDefault} from '../../commons/utils/DOMEventUtils.js';
import {findAncestorOfType} from '../../commons/utils/DOMUtils.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {
	COLOR_ERROR, COLOR_INFO,
	COLOR_INHERIT,
	COLOR_PRIMARY,
	COLOR_SECONDARY,
	COLOR_SUCCESS, COLOR_WARNING,
	MUI_FIELD_VARIANTS
} from '../constants/SynMUIOptions.js';

const BUTTON_TYPES = ['submit', 'reset', 'button'];
const BUTTON_VARIANTS = Object.keys(MUI_FIELD_VARIANTS).map(key => MUI_FIELD_VARIANTS[key]);
const BUTTON_SIZES = ['small', 'medium', 'large'];
const BUTTON_COLORS = [
	COLOR_INHERIT,
	COLOR_PRIMARY,
	COLOR_SECONDARY,
	COLOR_SUCCESS,
	COLOR_ERROR,
	COLOR_INFO,
	COLOR_WARNING
];

function useDispatchTypeSpecificFormEvent(form, type) {
	const getFormNode = useCallback(e => document.getElementById(form) || findAncestorOfType(e.target, 'form'), [form]);
	return useCallback(originalEvent => {
		if (!originalEvent.defaultPrevented && Boolean(type)) {
			const formNode = getFormNode(originalEvent);
			if (formNode) {
				switch (type) {
					case 'reset':
					case 'submit': {
						const generatedEvent = document.createEvent('Event');
						generatedEvent.initEvent(type, true, true);
						formNode.dispatchEvent(generatedEvent);
						preventEventDefault(originalEvent);
						break;
					}
					default:
						break;
				}
			}
		}
	}, [getFormNode, type]);
}

function Button(props) {
	const {
		id, className, color, flat, startIcon, opaque, endIcon, children, onClick, type, form, fullWidth,
		labelLeftAlign, sx, component: Component, ...remainingProps
	} = props;
	const contained = opaque ? opaque : !flat;
	const dispatchTypeSpecificFormEvent = useDispatchTypeSpecificFormEvent(form, type);
	const handleOnClick = useCallback(e => {
		if (onClick) {
			callSafe(onClick, e);
		} else {
			dispatchTypeSpecificFormEvent(e);
		}
	}, [dispatchTypeSpecificFormEvent, onClick]);
	const boxStyle = useMemoFactory(createBoxStyle, fullWidth);
	const sxProps = useMemoFactory(getSxProps, labelLeftAlign, sx);
	return (
		<Box sx={{pt: '6px', pb: '6px'}} style={boxStyle} className={className}>
			<Component id={id} startIcon={startIcon} onClick={handleOnClick}
			           type={type} form={form} endIcon={endIcon} color={color} variant={contained ? 'contained' : 'text'}
			           disableElevation={flat} sx={sxProps} fullWidth {...remainingProps}>
				{children}
			</Component>
		</Box>
	);
}

Button.propTypes = {
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func,
	type: PropTypes.oneOf(BUTTON_TYPES),
	form: PropTypes.string,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	color: PropTypes.oneOfType([
		PropTypes.oneOf(BUTTON_COLORS),
		PropTypes.string
	]),
	flat: PropTypes.bool,
	fullWidth: PropTypes.bool,
	opaque: PropTypes.bool,
	startIcon: PropTypes.node,
	endIcon: PropTypes.node,
	children: PropTypes.node.isRequired,
	labelLeftAlign: PropTypes.bool,
	variant: PropTypes.oneOf(BUTTON_VARIANTS),
	size: PropTypes.oneOf(BUTTON_SIZES),
	component: PropTypes.elementType
};

Button.defaultProps = {
	color: COLOR_PRIMARY,
	flat: false,
	fullWidth: false,
	opaque: undefined,
	labelLeftAlign: false,
	type: 'button',
	component: MuiButton
};

function getSxProps(labelLeftAlign, sx) {
	return {
		justifyContent: labelLeftAlign && 'space-between',
		...sx
	};
}

function createBoxStyle(fullWidth) {
	return fullWidth ? {width: '100%'} : undefined;
}

const MemoizedButton = React.memo(Button);
MemoizedButton.propTypes = Button.propTypes;
MemoizedButton.defaultProps = Button.defaultProps;

export default MemoizedButton;
export {buttonClasses};
