import {getInstanceNumber, loadSynAdvancedDomImages} from '../../commons/data/aim/SynAdvancedImage.js';
import CachingResourceLoader from '../../commons/utils/CachingResourceLoader.js';
import {releaseImageSafe} from './ImageViewerUtils.js';

const DECODED_SYN_ADVANCED_IMAGE_CACHE =
		new CachingResourceLoader(loadSynAdvancedDomImages, releaseImageSafe, getInstanceNumber);

export async function decodeDicomImage(dicomImage) {
	let result = dicomImage;
	if (dicomImage && dicomImage.isDisplaySupported && dicomImage.rawImageData && !dicomImage.isDecoded) {
		const decodedImage = await DECODED_SYN_ADVANCED_IMAGE_CACHE.loadResource(dicomImage.rawImageData);
		result = dicomImage.setRawImage(decodedImage);
	}
	return result;
}

export async function releaseDicomImage(dicomImage) {
	let result = dicomImage;
	if (dicomImage && dicomImage.isDecoded) {
		const releasedImage = await DECODED_SYN_ADVANCED_IMAGE_CACHE.freeLoadedResource(dicomImage.rawImageData);
		result = dicomImage.setRawImage(releasedImage);
	}
	return result;
}
