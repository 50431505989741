import React from 'react';
import PropTypes from 'prop-types';

import FullScreenDialogAnchorContainer from '../../commons/containers/FullScreenDialogAnchorContainer.js';
import {combineClassNames} from '../../commons/utils/StyleUtils';
import IconButton from '../../ui/components/IconButton.js';
import ShareIcon from '../../ui/components/icons/ShareIcon.js';
import {SHARE_DOCUMENTS_DIALOG_ID} from '../constants/ShareDocumentConstants.js';
import ShareDocumentDialogProxy from './ShareDocumentDialogProxy.js';

const NEXT_ANCHOR_ID_SYMBOL = Symbol('NEXT_ANCHOR_ID');
export default class ShareDocumentButton extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundSetDialogController = this.setDialogController.bind(this);
		this.boundOpenDialog = this.openDialog.bind(this);
		this.dialogController = null;
		this.dialogAnchorId = ShareDocumentButton.nextAnchorId();
	}

	render() {
		const {documentIds, className, ...remainingProps} = this.props;
		const combinedClassName = combineClassNames('share-button', className);
		return (
			<IconButton icon={<ShareIcon />} onClick={this.boundOpenDialog} className={combinedClassName}
				{...remainingProps}>
				<FullScreenDialogAnchorContainer id={this.dialogAnchorId}
															dialogControllerRef={this.boundSetDialogController}>
					<ShareDocumentDialogProxy documentIds={documentIds} />
				</FullScreenDialogAnchorContainer>
			</IconButton>
		);
	}

	openDialog() {
		if (this.dialogController) {
			this.dialogController.openDialog();
		}
	}

	setDialogController(dialogController) {
		this.dialogController = dialogController;
	}

	static nextAnchorId() {
		const thisId = ShareDocumentButton[NEXT_ANCHOR_ID_SYMBOL]++;
		return `${SHARE_DOCUMENTS_DIALOG_ID}-${thisId}`;
	}
}
ShareDocumentButton[NEXT_ANCHOR_ID_SYMBOL] = 0;
ShareDocumentButton.propTypes = {
	documentIds: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string).isRequired,
		PropTypes.arrayOf(PropTypes.number).isRequired
	]),
	className: PropTypes.string
};
