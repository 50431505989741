import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import FormContext from '../components/form/FormContext.js';
import {preventEventDefault, stopEventPropagation} from '../utils/DOMEventUtils.js';

function onlyIfValid(context, callback) {
	return function onlyIfValidImpl(e) {
		const submitIfInvalid = context.props.submitIfInvalid || false;
		if (context.props.formValid || submitIfInvalid) {
			callback(e);
		} else {
			preventEventDefault(e);
			stopEventPropagation(e);
			context.props.forceFormValidation();
		}
	};
}

function createActionOrEventEmitter(context, actionField, eventField, parameterSelector, autoBlur = true) {
	return e => {
		if (context.props[actionField]) {
			preventEventDefault(e);
			context.props.dispatch(context.props[actionField](parameterSelector(context)));
		} else if (context.props[eventField]) {
			preventEventDefault(e);
			context.props[eventField](parameterSelector(context));
		}
		if (autoBlur && Boolean(document.activeElement)) {
			document.activeElement.blur();
		}
	};
}

class FormContainer extends React.Component {
	constructor(props) {
		super(props);
		this.boundOnSubmitHandler = onlyIfValid(this, createActionOrEventEmitter(this, 'submitAction', 'onSubmit', selectorContext => selectorContext.props.selectedParams, props.blurOnSubmit));
		this.boundOnResetHandler = createActionOrEventEmitter(this, 'resetAction', 'onReset', () => undefined, props.blurOnSubmit);
	}

	render() {
		const {id, description, className, children} = this.props;
		return (
			<FormContext.Provider value={{formDescription: description}}>
				<form id={id} className={className} onSubmit={this.boundOnSubmitHandler}
					onReset={this.boundOnResetHandler}>
					{children}
				</form>
			</FormContext.Provider>
		);
	}

	componentDidMount() {
		const {restoreValues, initialValues} = this.props;
		restoreValues(initialValues);
	}

	componentWillUnmount() {
		const {deleteFormDataOnUnmount, deleteFormData} = this.props;
		if (deleteFormDataOnUnmount) {
			deleteFormData();
		}
	}
}

FormContainer.propTypes = {
	blurOnSubmit: PropTypes.bool,
	deleteFormDataOnUnmount: PropTypes.bool,
	id: PropTypes.string,
	description: PropTypes.object,
	className: PropTypes.string,
	deleteFormData: PropTypes.func,
	restoreValues: PropTypes.func,
	initialValues: PropTypes.object,
	submitIfInvalid: PropTypes.bool,
	selectedParams: PropTypes.object,
	formValid: PropTypes.bool,
	forceFormValidation: PropTypes.func,
	dispatch: PropTypes.func
};

FormContainer.defaultProps = {
	blurOnSubmit: true,
	deleteFormDataOnUnmount: false,
	submitIfInvalid: false
};

const FormContainerContainer = connect(
	(state, props) => ({
		selectedParams: props.description.getAllMappedFormFieldValues(state),
		formValid: props.description.isValid(state),
		initialValues: props.initialValuesSelector ? props.initialValuesSelector(state) : undefined
	}),
	(dispatch, props) => ({
		restoreValues: initialValues => dispatch(props.description.restoreValuesFromMap(initialValues)),
		deleteFormData: () => dispatch(props.description.delete()),
		forceFormValidation: () => dispatch(props.description.setFormValidationForced()),
		dispatch
	}),
	(stateProps, dispatchProps, ownProps) => {
		const {initialValues: initialStateValues, ...remainingStateProps} = stateProps;
		const {initialValues, ...remainingOwnProps} = ownProps;

		return {
			initialValues: initialStateValues || initialValues,
			...remainingStateProps,
			...dispatchProps,
			...remainingOwnProps
		};
	}
)(FormContainer);

FormContainerContainer.propTypes = {
	...FormContainer.propTypes,
	initialValuesSelector: PropTypes.func
};

export default FormContainerContainer;
