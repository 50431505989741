import React from 'react';
import {func, number} from 'prop-types';

import Slider from '../../../material-design/components/Slider.js';

function buildSliderIndicatorLabel(seriesRelativeImageOffset, imageRelativeFrameOffset) {
	let label = `${seriesRelativeImageOffset + 1}`;
	if (imageRelativeFrameOffset) {
		label = `${label}/${imageRelativeFrameOffset + 1}`;
	}
	return label;
}

export default class DicomSeriesViewerSlider extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundGetSliderIndicatorLabel = this.getSliderIndicatorLabel.bind(this);
	}

	render() {
		const {numberOfFrames, seriesRelativeFrameOffset, onChange} = this.props;
		return (
			<Slider key='imageSlider' className='dicom-series-toolbar--slider' indicatorLabel={this.boundGetSliderIndicatorLabel}
			        from={0} to={numberOfFrames - 1} discreteStep={1} value={seriesRelativeFrameOffset}
			        onChange={onChange} />
		);
	}

	getSliderIndicatorLabel() {
		const {seriesRelativeImageOffset, imageRelativeFrameOffset} = this.props;
		return buildSliderIndicatorLabel(seriesRelativeImageOffset, imageRelativeFrameOffset);
	}
}

DicomSeriesViewerSlider.propTypes = {
	seriesRelativeImageOffset: number.isRequired,
	seriesRelativeFrameOffset: number.isRequired,
	imageRelativeFrameOffset: number,
	numberOfFrames: number.isRequired,
	onChange: func
};
