import React from 'react';

import {IS_DEBUG_BUILD} from '../commons/constants/EnvironmentConstants.js';
import {getPrototypes, hasOwnPropertySafe} from '../commons/utils/ObjectUtils';
import BrickBase from './BrickBase.js';

export const BRICK_META_DATA = Symbol('BRICK_META_DATA');

export function isBrick(brick) {
	return Boolean(brick) && hasBrickMetaData(Object.getPrototypeOf(brick).constructor);
}

export function declareBrick(BrickConstructor, dependencies = []) {
	if (hasBrickMetaData(BrickConstructor)) {
		throw new Error(`${BrickConstructor.name} was already declared as Brick!`);
	}
	const metaData = getOrCreateMetaData(BrickConstructor);
	addProvidedBricks(metaData, BrickConstructor);
	addDependencies(metaData, dependencies);
	setMetaData(BrickConstructor, Object.freeze(metaData));
}

export function getProvidedBricks(brick) {
	const {providedBricks} = getBrickMetaData(undefined, brick);
	return providedBricks;
}

export function getDependencies(BrickConstructor) {
	const {dependencies} = getBrickMetaData(BrickConstructor);
	return dependencies;
}

export function getBrickMetaData(BrickConstructor, brick) {
	const realBrickType = BrickConstructor || Object.getPrototypeOf(brick).constructor;
	if (!hasBrickMetaData(realBrickType)) {
		throw new Error(`${realBrickType.name} doesn't seem to be a brick. Did you declare the provided bricks using ${declareBrick.name}?`);
	}
	return getOrCreateMetaData(realBrickType);
}

function addProvidedBricks(metaData, BrickConstructor) {
	if (IS_DEBUG_BUILD) {
		if (!BrickConstructor) {
			throw new Error(`Invalid brick type: ${BrickConstructor}`);
		}
	}
	const superOfBrickBase = Object.getPrototypeOf(BrickBase.prototype).constructor;
	const prototypes = getPrototypes(BrickConstructor, superOfBrickBase);
	const protoTypeConstructors = prototypes
		.map(proto => proto.constructor);
	if (IS_DEBUG_BUILD) {
		if (protoTypeConstructors.length === 0 ||
				protoTypeConstructors[protoTypeConstructors.length - 1] !== BrickBase) {
			throw new Error(`${BrickConstructor.name} is not derived from ${BrickBase.name}.`);
		}
	}
	metaData.providedBricks = Object.freeze(protoTypeConstructors);
}

function addDependencies(metaData, dependencies) {
	assertAllAreBricks(...dependencies);
	metaData.dependencies = Object.freeze([...dependencies]);
}

function assertAllAreBricks(...brickTypes) {
	if (IS_DEBUG_BUILD) {
		const nonBricks = brickTypes
			.filter(brickType => !hasBrickMetaData(brickType));
		if (nonBricks.length > 0) {
			const typeNames = nonBricks.map(type => type.name);
			throw new Error(`These are no bricks: ${typeNames}`);
		}
	}
}

function hasBrickMetaData(BrickConstructor) {
	return hasOwnPropertySafe(BrickConstructor, BRICK_META_DATA);
}

function getOrCreateMetaData(BrickConstructor) {
	return hasBrickMetaData(BrickConstructor) ? BrickConstructor[BRICK_META_DATA] : {};
}

function setMetaData(BrickConstructor, metaData) {
	BrickConstructor[BRICK_META_DATA] = metaData;
}
