import React from 'react';
import PropTypes from 'prop-types';

import {LANGUAGE_TAG} from '../../commons/constants/SynSettingsConstants.js';
import {getHocDisplayName} from '../../commons/utils/ReactUtils.js';
import LocalizationContext from './LocalizationContext.js';

/*
 * Provides a local property in the React context to be used in any descendants via this.context.locale.
 * For children to get the context provided, they must specify their expected context types via
 * the static class-property contextTypes like in the followin example
 * @example
 * MyClass.contextTypes.contextType = LocalizationContext
 *
 * @author p.spitzlinger@synedra.com
 * @class LocalizationContextWrapper
 */
export default class LocalizationContextWrapper extends React.PureComponent {
	render() {
		const {locale, children} = this.props;
		return (
			<LocalizationContext.Provider value={locale || LANGUAGE_TAG}>
				{React.Children.only(children)}
			</LocalizationContext.Provider>
		);
	}
}
LocalizationContextWrapper.propTypes = {
	locale: PropTypes.string
};

/**
 * Wraps the passed ChildComponent in a localization context, thus providing locale property to the ChildComponent
 * if no other locale property was explicitly passed to the component.
 * @param ChildComponent the wrapped component
 * @returns {WithLocalizationContext} component either passing its prop locale
 * or the locale from the context as property locale.
 */
export function wrapInLocalizationContext(ChildComponent) {
	class WithLocalizationContext extends React.PureComponent {
		render() {
			const {locale, ...remainingProps} = this.props;
			return (
				<LocalizationContext.Consumer>
					{contextLocale => <ChildComponent {...remainingProps} locale={locale || contextLocale} />}
				</LocalizationContext.Consumer>
			);
		}
	}
	const childPropTypes = ChildComponent.propTypes || {};
	WithLocalizationContext.propTypes = {
		...childPropTypes,
		locale: PropTypes.string
	};
	WithLocalizationContext.displayName = getHocDisplayName(ChildComponent, 'withLocalizationContext');
	return WithLocalizationContext;
}
