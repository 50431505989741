import {toContainerPosition} from '../../../utils/AnnotationUtils.js';
import GoniometryAnnotationBase from './GoniometryAnnotationBase.js';

export default class ThreePointGoniometryAnnotation extends GoniometryAnnotationBase {
	render() {
		const {annotationProperties} = this.props;
		const points = annotationProperties.get('points');
		const labelText = this.calcLabel(points, points);
		const containerPoints = points.map(point => toContainerPosition(this.props, point));
		return this.renderAnnotation(points, labelText, containerPoints);
	}
}
