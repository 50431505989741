import React from 'react';
import PropTypes from 'prop-types';

import ShortDateTime from '../../i18n/components/ShortDateTime';

export default function DocumentSummary(props) {
	const {description, createdWhen, containedTypes} = props;
	return (
		<React.Fragment>
			<ShortDateTime date={createdWhen} />
			&nbsp;|&nbsp;
			{containedTypes}
			&nbsp;|&nbsp;
			{description}
		</React.Fragment>
	);
}

DocumentSummary.propTypes = {
	description: PropTypes.string,
	createdWhen: PropTypes.instanceOf(Date),
	containedTypes: PropTypes.string
};
