import React from 'react';

import viewerLayout1x1Icon from '../../../../images/icons/webview/viewer-layout-1x1.svg';
import viewerLayout1x2Icon from '../../../../images/icons/webview/viewer-layout-1x2.svg';
import viewerLayout2x1Icon from '../../../../images/icons/webview/viewer-layout-2x1.svg';
import viewerLayout2x2Icon from '../../../../images/icons/webview/viewer-layout-2x2.svg';
import {createSVGComponentIcon} from './SVGIconCreationUtils.js';

const ViewerLayout1x1Icon = createSVGComponentIcon(viewerLayout1x1Icon);
const ViewerLayout1x2Icon = createSVGComponentIcon(viewerLayout1x2Icon);
const ViewerLayout2x1Icon = createSVGComponentIcon(viewerLayout2x1Icon);
const ViewerLayout2x2Icon = createSVGComponentIcon(viewerLayout2x2Icon);

export {ViewerLayout1x1Icon, ViewerLayout1x2Icon, ViewerLayout2x1Icon, ViewerLayout2x2Icon};
