import React from 'react';
import MuiAvatar from '@mui/material/Avatar';
import PropTypes from 'prop-types';

function Avatar(props) {
	const {children, className, sx} = props;
	const sxProps = {
		backgroundColor: 'text.secondary',
		...sx
	};
	return (
		<MuiAvatar className={className} sx={sxProps}>
			{children}
		</MuiAvatar>
	);
}

Avatar.propTypes = {
	className: PropTypes.string,
	sx: PropTypes.object
};

export default Avatar;
