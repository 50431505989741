import createDownloadURLFactory from '../../commons/api/createDownloadUrlFactory.js';
import getBlob from '../../commons/api/operations/getBlob.js';
import getImage from '../../commons/api/operations/getImage.js';
import getSynAdvancedImage from '../../commons/api/operations/getSynAdvancedImage.js';
import getTextFile from '../../commons/api/operations/getTextFile.js';
import {APPLICATION_OCTET_STREAM, TEXT_PLAIN} from '../../commons/constants/MimeTypes.js';
import {getImageScalingRequestParameters} from './pixelDataRequestParamHelpers.js';

const GENERIC_FILE_BASE_URL = '/file';
const DEFAULT_FILE_PREVIEW_SIZE = 160;

export function getPreviewImage(fileId, requestParameters = {}) {
	const {width = DEFAULT_FILE_PREVIEW_SIZE, height = DEFAULT_FILE_PREVIEW_SIZE, info = 0} = requestParameters;
	return getImage(`${GENERIC_FILE_BASE_URL}/${fileId}`, {
		width,
		height,
		info,
		originalSize: 0
	});
}

export function getGenericFileAsSynAdvancedImage(fileId) {
	return getSynAdvancedImage(`${GENERIC_FILE_BASE_URL}/${fileId}`, {
		...getImageScalingRequestParameters(),
		info: 0
	});
}

export const getFileDownloadURL = createDownloadURLFactory(GENERIC_FILE_BASE_URL);
export function getFileDownloadWithDocumentLoadURL(id, contentDisposition, extension) {
	const downloadUrl = getFileDownloadURL(id, contentDisposition, extension);
	const glue = contentDisposition ? '&' : '?';
	return `${downloadUrl}${glue}audit_export_as_load=1`;
}

export function getFileAsBlob(fileId, parameters = {}, mimeType = APPLICATION_OCTET_STREAM) {
	return getBlob(getFileDownloadWithDocumentLoadURL(fileId), parameters, mimeType);
}

export function getFileAsText(fileId, parameters = {}, mimeType = TEXT_PLAIN) {
	return getTextFile(getFileDownloadWithDocumentLoadURL(fileId), parameters, mimeType);
}
