import {vec2} from 'gl-matrix';

import {createLineBetween, intersectLines2d} from './Line.js';
import {containsPoint2d, createRectangle, overlap2d} from './Rectangle.js';

export function createSegment(from, to) {
	return {from, to};
}

export function toLine2d({from, to}) {
	return createLineBetween(from, to);
}

export function boundingBox2d(segment) {
	return createRectangle(
		vec2.fromValues(Math.min(segment.from[0], segment.to[0]), Math.min(segment.from[1], segment.to[1])),
		vec2.fromValues(Math.max(segment.from[0], segment.to[0]), Math.max(segment.from[1], segment.to[1]))
	);
}

/**
 * Calculates the intersection of the two given segments.
 * @param segmentA {object} the first segment
 * @param segmentB {object} the second segment to intersect with the first one
 * @returns {*,object} null if segments don't intersect. The intersection point otherwise.
 */
export function intersect2d(segmentA, segmentB) {
	let intersection = null;
	if (segmentA !== segmentB) {
		const boundingBoxA = boundingBox2d(segmentA);
		const boundingBoxB = boundingBox2d(segmentB);
		if (overlap2d(boundingBoxA, boundingBoxB)) {
			const lineIntersection = intersectLines2d(toLine2d(segmentA), toLine2d(segmentB));
			if (
				lineIntersection !== null &&
				containsPoint2d(boundingBoxA, lineIntersection) &&
				containsPoint2d(boundingBoxB, lineIntersection)
			) {
				intersection = lineIntersection;
			}
		}
	}
	return intersection;
}

/**
 * Returns a segment that was clipped to the specified rectangle.
 * If the segment lies completely within the rectangle, it is returned unchanged.
 * Otherwise the points of the segment are clipped to the rectangles edges.
 * If the segment does not completely lie within the rectangle and does not intersect with any
 * of the rectangles edges, null is returned.
 * @param segment {object} to be clipped
 * @param rectangle {object} specifying the clipping region
 * @returns {null,object} the clipped segment
 */
export function clipToRectangle2d(segment, rectangle) {
	let {from, to} = segment;
	const doesNotContainFrom = !containsPoint2d(rectangle, from);
	const doesNotContainTo = !containsPoint2d(rectangle, to);

	let clippedSegment = segment;
	if (doesNotContainFrom || doesNotContainTo) {
		const {topLeft, bottomRight} = rectangle;
		const topRight = vec2.fromValues(bottomRight[0], topLeft[1]);
		const bottomLeft = vec2.fromValues(topLeft[0], bottomRight[1]);
		const edgeIntersections = [
			createSegment(topLeft, topRight),
			createSegment(topRight, bottomRight),
			createSegment(bottomRight, bottomLeft),
			createSegment(bottomLeft, topLeft)
		].reduce((intersections, edgeSegment) => {
			const intersection = intersect2d(segment, edgeSegment);
			if (intersection !== null) {
				intersections.push(intersection);
			}
			return intersections;
		}, []);

		if (edgeIntersections.length > 0) {
			if (doesNotContainFrom) {
				from = findClosestPoint(from, edgeIntersections);
			}
			if (doesNotContainTo) {
				to = findClosestPoint(to, edgeIntersections);
			}
			clippedSegment = createSegment(vec2.copy(vec2.create(), from), vec2.copy(vec2.create(), to));
		} else {
			clippedSegment = null;
		}
	}
	return clippedSegment;
}

function findClosestPoint(reference, points) {
	let smallestDistance = null;
	return points.reduce((closestIntersection, point) => {
		let newClosestIntersection = closestIntersection;
		const thisSquareDistance = vec2.squaredDistance(reference, point);
		if (smallestDistance === null || thisSquareDistance < smallestDistance) {
			newClosestIntersection = point;
			smallestDistance = thisSquareDistance;
		}
		return newClosestIntersection;
	}, reference);
}
