import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import EditTextDialogTranslator from '../../../i18n/translators/EditTextDialogTranslator.js';
import Dialog from '../../../material-design/components/Dialog.js';
import DialogBody from '../../../material-design/components/DialogBody.js';
import DialogButtonContainer from '../../../material-design/components/DialogButtonContainer.js';
import DialogFooter from '../../../material-design/components/DialogFooter.js';
import DialogHeader from '../../../material-design/components/DialogHeader.js';
import Button from '../../../ui/components/Button.js';
import LinkLikeButton from '../../../ui/components/LinkLikeButton.js';
import MultiLineTextField from '../../../ui/components/MultiLineTextField.js';
import {refPropType} from '../../utils/CustomPropTypes.js';
import {callSafe, memoizeByFirstArg} from '../../utils/FunctionUtils.js';

import '../../../../styles/commons/components/webview/EditTextDialog.scss';

export default class EditTextDialog extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnAccepted = this.onAccepted.bind(this);
		this.boundOnTextChanged = this.onTextChanged.bind(this);
		this.boundOnCancel = this.onCancel.bind(this);
		this.getTranslatedText = memoizeByFirstArg(
			locale => memoizeByFirstArg(
				messageId => EditTextDialogTranslator.getFormattedMessage(messageId, locale)
			)
		);

		this.state = {
			text: props.text,
			prevText: props.text
		};
	}

	render() {
		const {onCancel, title, locale, label, inputRef, name} = this.props;
		const {text} = this.state;
		const finalTitle = title || this.getTranslatedText(locale)('DefaultTitle');
		const finalLabel = label || this.getTranslatedText(locale)('DefaultLabel');
		return (
			<Dialog onClose={this.boundOnCancel} autoFocus={false}>
				<DialogHeader onClose={this.boundOnCancel} title={finalTitle} />
				<DialogBody>
					<MultiLineTextField className='edit-text-dialog--input-field' onChange={this.boundOnTextChanged}
											  value={text} inputRef={inputRef}
											  autoFocus label={finalLabel} name={name} />
				</DialogBody>
				<DialogFooter alwaysVisible>
					<DialogButtonContainer alignVertically>
						<Button flat opaque onClick={this.boundOnAccepted}>
							<SynFormattedMessage translator={EditTextDialogTranslator} message='AcceptText' />
						</Button>
						<LinkLikeButton flat onClick={onCancel}>
							<SynFormattedMessage translator={EditTextDialogTranslator} message='CancelPhrase' />
						</LinkLikeButton>
					</DialogButtonContainer>
				</DialogFooter>
			</Dialog>
		);
	}

	static getDerivedStateFromProps(nextProps, state) {
		if (nextProps.text !== state.prevText) {
			return {
				text: nextProps.text,
				prevText: nextProps.text
			};
		}
		return null;
	}

	onCancel() {
		const {onCancel} = this.props;
		callSafe(onCancel);
	}

	onAccepted(/* clickEvent */) {
		const {onTextChanged} = this.props;
		const {text} = this.state;
		callSafe(onTextChanged, text);
	}

	onTextChanged(newText) {
		this.setState({
			text: newText
		});
	}
}

EditTextDialog.defaultProps = {
	onCancel: undefined,
	onTextChanged: undefined,
	title: undefined,
	label: undefined,
	text: undefined,
	locale: 'de'
};

EditTextDialog.propTypes = {
	onCancel: PropTypes.func,
	onTextChanged: PropTypes.func,
	title: PropTypes.node,
	label: PropTypes.string,
	text: PropTypes.string,
	locale: PropTypes.string,
	name: PropTypes.string,
	inputRef: refPropType
};
