import React from 'react';
import PropTypes from 'prop-types';

import SuggestComboBox from '../../ui/components/SuggestComboBox';

export default function OrgunitComboBox(props) {
	const {orgUnits, ...remainingProps} = props;
	return (
		<SuggestComboBox getOptionSelected={ordererMatches} options={orgUnits} getOptionLabel={getOrgunitDescription}
		                 {...remainingProps} />
	);
}

function getOrgunitDescription(orgUnit) {
	if (orgUnit) {
		return `${orgUnit.abk} - ${orgUnit.name}`;
	}
	return '';
}

function ordererMatches(first, second) {
	return first === second || first?.abk === second?.abk;
}

OrgunitComboBox.propTypes = {
	...SuggestComboBox.propTypes,
	orgUnits: PropTypes.arrayOf(PropTypes.exact({
		abk: PropTypes.string,
		name: PropTypes.string
	}))
};
