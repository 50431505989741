import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import Title from '../../../material-design/components/Title.js';
import VerticalLayout from '../../../ui/components/layout/VerticalLayout.js';
import ResponsiveCard from '../../../ui/components/ResponsiveCard.js';
import {DEVICE_SIZE_EXTRA_SMALL} from '../../constants/DeviceInformation.js';
import {LANDING_PAGE} from '../../constants/PageNames.js';
import IntermediatePageContainer from '../../containers/webview/IntermediatePageContainer.js';
import {combineClassNames} from '../../utils/StyleUtils.js';
import TemplateIFrame from './TemplateIFrame.js';

import '../../../../styles/commons/components/webview/LandingPage.scss';

const CenteredTitle = styled(Title)({
	textAlign: 'center'
});

export default function LandingPage(props) {
	const {headerTemplate, footerTemplate, children, title, className, maxCardWidth} = props;
	const finalClassName = combineClassNames(LANDING_PAGE, className);
	return (
		<IntermediatePageContainer className={finalClassName}>
			{Boolean(headerTemplate) && <TemplateIFrame className='header-iframe' templateName={headerTemplate} />}
			<VerticalLayout element='main' noShrink noGrow align='center'>
				<CenteredTitle>
					{title}
				</CenteredTitle>
				<VerticalLayout element={ResponsiveCard} className='landing-page--central-card' centered maxCardWidth={maxCardWidth}
												minCardWidth='270px' breakPoint={DEVICE_SIZE_EXTRA_SMALL}>
					{children}
				</VerticalLayout>
			</VerticalLayout>
			{Boolean(footerTemplate) && <TemplateIFrame className='footer-iframe' templateName={footerTemplate} />}
		</IntermediatePageContainer>
	);
}

LandingPage.defaultProps = {
	maxCardWidth: '300px'
};

LandingPage.propTypes = {
	headerTemplate: PropTypes.string,
	footerTemplate: PropTypes.string,
	children: PropTypes.node.isRequired,
	title: PropTypes.node.isRequired,
	className: PropTypes.string,
	maxCardWidth: PropTypes.string
};
