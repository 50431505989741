import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {number} from 'prop-types';
import {createSelector, createStructuredSelector} from 'reselect';

import XMLViewer from '../../components/XMLViewer.js';
import {getRawXML} from '../../utils/ViewerItemUtils.js';
import {loadXMLFile} from '../actions/ViewerItemsActions.js';
import {hasFileLoadStarted, selectGenericFileViewerItem} from '../selectors/ViewerItemsSelectors.js';

function createInstanceSelector(fileId) {
	const selectFileViewerItem = selectGenericFileViewerItem(fileId, null);
	const selectRawXML = createSelector(selectFileViewerItem, getRawXML);
	return createStructuredSelector({
		hasDownloadStarted: hasFileLoadStarted(fileId),
		fileViewerItem: selectFileViewerItem,
		rawXML: selectRawXML
	});
}

export default function XMLViewerContainer({fileId}) {
	const dispatch = useDispatch();
	const stateSelector = useMemo(() => createInstanceSelector(fileId), [fileId]);
	const stateProps = useSelector(stateSelector);
	const loadViewerItem = useCallback(() => dispatch(loadXMLFile(fileId)), [dispatch, fileId]);

	return <XMLViewer fileId={fileId} loadViewerItem={loadViewerItem} {...stateProps} />;
}

XMLViewerContainer.propTypes = {
	fileId: number.isRequired
};
