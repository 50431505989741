import React from 'react';
import PropTypes from 'prop-types';

const CONTAINER_STYLE = {
	position: 'relative',
	flexGrow: 1
};

export default function ListContainer(props) {
	const {children, className} = props;
	return (
		<div style={CONTAINER_STYLE} className={className}>
			{children}
		</div>
	);
}

ListContainer.propTypes = {
	className: PropTypes.string
};
