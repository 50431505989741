import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import PatientDetailsDocumentButton from './PatientDetailsDocumentButton.js';


export default function ShareDoneButton(props) {
	const {done, onClick, children} = props;
	const sxProps = useMemoFactory(getSxProps, done);
	return (
		<PatientDetailsDocumentButton icon={<DoneIcon />} onClick={onClick} sx={sxProps}>
			{children}
		</PatientDetailsDocumentButton>
	);
}

ShareDoneButton.propTypes = {
	done: PropTypes.bool,
	onClick: PropTypes.func
};

function getSxProps(done) {
	return {color: done ? 'primary.main' : 'text.secondary'};
}
