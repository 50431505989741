import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import GuardedUserInfo from '../../../authentication/flux/containers/GuardedUserInfo.js';
import SidebarToggleButtonContainer from '../../containers/webview/SidebarToggleButtonContainer.js';
import AppBarOverflowButton from '../AppBarOverflowButton.js';
import AppBarSidebarExtensionArea from './AppBarSidebarExtensionArea.js';

import '../../../../styles/commons/components/webview/AppBarSidebarHeader.scss';

export default function AppBarSidebarHeader(props) {
	const {sidebarElevated} = props;
	const classes = classNames({
		'appbar-sidebar-header': true,
		hidden: sidebarElevated
	});
	return (
		<AppBarSidebarExtensionArea className={classes}>
			<GuardedUserInfo />
			<SidebarToggleButtonContainer />
			<AppBarOverflowButton visible={!sidebarElevated} />
		</AppBarSidebarExtensionArea>
	);
}
AppBarSidebarHeader.propTypes = {
	sidebarElevated: PropTypes.bool
};
