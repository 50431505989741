import React from 'react';

import SingleLineTextField from '../../ui/components/SingleLineTextField.js';
import {useMemoFactory} from '../utils/customHooks/index.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils/index.js';
import {combineClassNames} from '../utils/StyleUtils.js';

import '../../../styles/commons/components/NumberCodeInputField.scss';

export function NumberCodeInputField(props) {
	const {className} = props;
	const finalClassName = useMemoFactory(combineClassNames, 'number-code-input', className);
	return <SingleLineTextField {...props} className={finalClassName} type='number' />;
}

NumberCodeInputField.propTypes = {
	...cloneWithoutProperties(SingleLineTextField.propTypes, 'type')
};
NumberCodeInputField.defaultProps = {
	...cloneWithoutProperties(SingleLineTextField.defaultProps, 'type')
};
