import PropTypes from 'prop-types';

import MaterialDeviceSizes from '../../../constants/MaterialDeviceSizes.json';

export const MAXIMUM_EXTRA_SMALL_WIDTH = MaterialDeviceSizes['device-size-extra-small']['max-width'];
export const MAXIMUM_SMALL_WIDTH = MaterialDeviceSizes['device-size-small']['max-width'];
export const MAXIMUM_MOBILE_WIDTH = MaterialDeviceSizes['device-size-medium']['max-width'];
export const MAXIMUM_LARGE_WIDTH = MaterialDeviceSizes['device-size-extra-large']['max-width'];
export const DEVICE_TYPE_DESKTOP = 'desktop';
export const DEVICE_TYPE_MOBILE = 'mobile';
export const DEVICE_SIZE_EXTRA_SMALL = 'device-size-extra-small';
export const DEVICE_SIZE_SMALL = 'device-size-small';
export const DEVICE_SIZE_MEDIUM = 'device-size-medium';
export const DEVICE_SIZE_LARGE = 'device-size-large';
export const DEVICE_SIZE_EXTRA_LARGE = 'device-size-extra-large';
export const DEVICE_SIZE_EXTRA_EXTRA_LARGE = 'device-size-extra-extra-large';
export const DEVICE_ORIENTATION_PORTRAIT = 'device_orientation_portrait';
export const DEVICE_ORIENTATION_LANDSCAPE = 'device_orientation_landscape';

export const deviceSizePropType = PropTypes.oneOf([
	DEVICE_SIZE_EXTRA_SMALL,
	DEVICE_SIZE_SMALL,
	DEVICE_SIZE_MEDIUM,
	DEVICE_SIZE_LARGE,
	DEVICE_SIZE_EXTRA_LARGE,
	DEVICE_SIZE_EXTRA_EXTRA_LARGE
]);
