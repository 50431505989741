import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import DataGuard from '../../components/data/DataGuard.js';
import {bindActions} from '../../utils/FluxUtils.js';

function createDefaultIsLoadNeededSelector(crudResourceFluxApi) {
	const {
		selectors: {isLoadInProgress, getRawResponse}
	} = crudResourceFluxApi;
	return createSelector(isLoadInProgress, getRawResponse,
		(loadInProgress, data) => data === null && !loadInProgress
	);
}

function createDefaultLoadAction(crudResourceFluxApi) {
	const {
		actions: {load}
	} = crudResourceFluxApi;
	return load;
}

export default function createCrudResourceLoader(crudResourceFluxApi, isLoadNeedSelector = null, loadAction = null) {
	const {
		selectors: {getResponse}
	} = crudResourceFluxApi;

	return connect(
		createStructuredSelector({
			isLoadNeeded: isLoadNeedSelector || createDefaultIsLoadNeededSelector(crudResourceFluxApi),
			data: getResponse
		}),
		bindActions({
			load: loadAction || createDefaultLoadAction(crudResourceFluxApi)
		}),
		(stateProps, dispatchProps, props) => ({...stateProps, ...dispatchProps, loadingIndicator: false, ...props})
	)(DataGuard);
}
