/**
 * Given a plain JSON object its properties are encoded into a url encoded query string.
 * Each property is converted into a encoded(key)=encoded(value) pair.
 *
 * @param {Object} queryParameters the parameter object to encode
 * @return {String} the urlencoded query string which can be appended to an url
 */
export function toQueryString(queryParameters) {
	return Object.keys(queryParameters).map(queryParamName => `${encodeURIComponent(queryParamName)}=${encodeURIComponent(queryParameters[queryParamName])}`)
		.join('&');
}

/**
 * Tests if a given URL ist a relative URL to the currently loaded URL.
 *
 * @param {String} url the url to test for being a relative URL
 * @return {Boolean} true if URL is relative
 */

export function isRelativeUrl(url) {
	const currentUrlOrigin = new URL(document.baseURI).origin;
	const assumedRelativeUrlOrigin = new URL(url, document.baseURI).origin;
	return currentUrlOrigin === assumedRelativeUrlOrigin;
}

/**
 * Converts a given urlEncoded queryString consisting of key=value... pairs into a json object
 * It is the inverse of {@link toQueryString}
 *
 * @param {String} queryString the queryString to decode and to convert to an object
 * @returns {object} the decoded queryString
 */
export function fromQueryString(queryString) {
	return queryString.split('&').reduce((queryParameters, queryParameter) => {
		const [key, value] = queryParameter.split('=');
		queryParameters[decodeURIComponent(key)] = decodeURIComponent(value);
		return queryParameters;
	}, {});
}

export function toRelativePath(path) {
	let relativePath = path || '';
	if (relativePath.length > 0 && relativePath[0] === '/') {
		relativePath = relativePath.substring(1);
	}
	return relativePath;
}
