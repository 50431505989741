import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../../a11y/components/landmarks/Alert.js';
import ErrorHint from '../../commons/components/webview/ErrorHint.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {AUTH_AUTHENTICATION_FAILED, AUTH_INVALID_CREDENTIALS} from '../../commons/constants/ResourceErrorStatus.js';
import {STATUS_FAILED} from '../../commons/constants/StatusMessageConstants.js';
import {ENFORCE_TWO_FACTOR_LOGIN} from '../../commons/constants/SynSettingsConstants.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Button from '../../ui/components/Button.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import {
	SESSION_STATE_LOGGED_OUT,
	SESSION_STATE_LOGIN_IN_PROGRESS,
	SESSION_STATE_REQUEST_CREDENTIALS
} from '../constants/SessionReducerStates.js';
import LoginPageMessagesTranslator from '../i18n/LoginPageMessagesTranslator.js';

const ERROR_SPECIFIC_MESSAGES = {
	[SESSION_STATE_REQUEST_CREDENTIALS]: {
		short: '2ndFactorRequestFailed',
		details: '2ndFactorRequestFailedTryLater'
	},
	'login-error': {
		short: ENFORCE_TWO_FACTOR_LOGIN ? 'TwoFactorLoginFailedShort' : 'LoginFailedShort',
		details: ENFORCE_TWO_FACTOR_LOGIN ? 'TwoFactorLoginFailedLong' : 'LoginFailedLong'
	},
	[SESSION_STATE_LOGGED_OUT]: {
		short: 'InvalidSession',
		buttonLabel: 'ReLoginButtonLabel'
	},
	'unknown-error': {
		short: 'UnknownLoginErrorShort',
		details: 'UnknownLoginErrorLong'
	}
};

function errorSpecificMessages(failedTransition, error) {
	let messages;
	switch (failedTransition) {
		case SESSION_STATE_LOGIN_IN_PROGRESS:
			messages = loginErrorSpecificMessages(error);
			break;
		case SESSION_STATE_REQUEST_CREDENTIALS:
			messages = ERROR_SPECIFIC_MESSAGES[SESSION_STATE_REQUEST_CREDENTIALS];
			break;
		case SESSION_STATE_LOGGED_OUT:
			messages = ERROR_SPECIFIC_MESSAGES[SESSION_STATE_LOGGED_OUT];
			break;
		default:
			messages = ERROR_SPECIFIC_MESSAGES['unknown-error'];
			break;
	}
	return messages;
}

function loginErrorSpecificMessages(loginError) {
	let messages;
	if (wasFailedLoginAttempt(loginError)) {
		messages = ERROR_SPECIFIC_MESSAGES['login-error'];
	} else {
		messages = ERROR_SPECIFIC_MESSAGES['unknown-error'];
	}
	return messages;
}

function wasFailedLoginAttempt(loginError) {
	const code = loginError && loginError.code || null;
	return code && (code === AUTH_INVALID_CREDENTIALS || code === AUTH_AUTHENTICATION_FAILED);
}

class StepLoginError extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundErrorHintSolution = this.errorHintSolution.bind(this);
	}

	render() {
		const {currentTransition, error} = this.props;
		const errorMessages = errorSpecificMessages(currentTransition, error);
		const {short, details, buttonLabel = 'RetryLoginButtonLabel'} = errorMessages;
		return (
			<VerticalLayout key='centerLayout' className='login-page--status' element={Alert}>
				<StatusMessage statusId={short} status={STATUS_FAILED}>
					<SynFormattedMessage translator={LoginPageMessagesTranslator} className='login-page--message' message={short} />
				</StatusMessage>
				{details && <ErrorHint translator={LoginPageMessagesTranslator} hintMessage={details} />}
				<Button autoFocus flat opaque onClick={this.boundErrorHintSolution}>
					<SynFormattedMessage message={buttonLabel} translator={LoginPageMessagesTranslator} />
				</Button>
			</VerticalLayout>
		);
	}

	errorHintSolution() {
		const {resetLoginWorkflow} = this.props;
		callSafe(resetLoginWorkflow);
	}
}

StepLoginError.propTypes = {
	currentTransition: PropTypes.string,
	error: PropTypes.instanceOf(Error),
	resetLoginWorkflow: PropTypes.func
};


export default StepLoginError;
