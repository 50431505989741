import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';

import {buildInputName} from '../../material-design/components/form/InputFieldUtils.js';
import wrapInFormContext from '../components/form/wrapInFormContext.js';
import {hasOwnPropertySafe} from '../utils/ObjectUtils';

function selectFieldRendererArguments(state, props) {
	const {formDescription, fieldName} = props;
	const validationResult = formDescription.getFieldValidationError(state, fieldName);
	const hintResult = formDescription.getFieldValidationHint(state, fieldName);
	return {
		label: formDescription.getFieldLabel(state, fieldName),
		value: formDescription.getFormFieldValue(state, fieldName),
		validationResult,
		hintResult,
		isFormValidationForced: formDescription.isFormValidationForced(state),
		isDefault: formDescription.isDefault(state, fieldName)
	};
}

const FORM_FIELD_CONTAINER_PROP_TYPES = {
	/**
	 * @field {Object} formDescription
	 * The form description object as generated by the {@link FormAccessorsFactory}.
	 * This property is optional and overrides the formDescription provided by the react context.
	 */
	formDescription: PropTypes.object,
	/**
	 * @field {String} name
	 * The generic name of the field as in <input name="fieldName" />
	 */
	name: nameOrFieldName,
	fieldName: nameOrFieldName
};

function nameOrFieldName(props) {
	return (!hasOwnPropertySafe(props, 'name') && !hasOwnPropertySafe(props, 'fieldName')) &&
		new Error('Either "name" or "fieldName" is required');
}

/**
 * This factory function creates a smart component, that is connected to the store via a formDescription, which
 * is provided via the FormFieldContext unless it is provided via the properties of the wrapped component.
 *
 * The propsMergingFunction is being passed three parameters:
 * <ol>
 *    <li><b>stateProps</b> contains the following fields
 *	   	<ul>
 *		      <li><i>label</i> The translated form label</li>
 *          <li><i>value</i> the current value of the formField extracted from the store</li>
 *          <li><i>validationResult</i> contains either true or the first error message corresponding to the field.</li>
 *          <li><i>isFormValidationForced</i> determines whether it is encouraged to the display error messages
 *          or not</li>
 *       </ul>
 *    </li>
 *    <li><b>dispatchProps</b> contains the following fields:
 *       <ul>
 *          <li><i>updateFieldValue</i> a function expecting the new field value to be written to the store</li>
 *       </ul>
 *    </li>
 *    <li><b>ownProps</b> the props passed to the component</li>
 * </ol>
 * @param Component {Object} The component to be rendered with selected props
 * @param propsMergingFunction {Function} prepares the passed prop object to be used as props of the Component
 * @returns {Object} Smart component that is connected to the specified form fields state
 */
export default function createFormFieldContainer(Component, propsMergingFunction) {
	const formFieldContainer = wrapInFormContext(connect(
		selectFieldRendererArguments,
		(dispatch, ownProps) => bindActionCreators({
			updateFieldValue: ownProps.formDescription.updateFieldValue.bind(undefined, [ownProps.fieldName])
		}, dispatch),
		withAppSpecificInputName(propsMergingFunction)
	)(Component));

	formFieldContainer.propTypes = {
		...Component.propTypes,
		...formFieldContainer.propTypes || {},
		...FORM_FIELD_CONTAINER_PROP_TYPES
	};
	formFieldContainer.defaultProps = Component.defaultProps;

	return formFieldContainer;
}

function withAppSpecificInputName(propsMergeFunction) {
	return function mergeProps(stateProps, dispatchProps, ownProps) {
		const {fieldName, name, ...remainingProps} = ownProps;
		const inputName = buildInputName(fieldName, name);
		const ownPropsWithInputName = {
			...remainingProps,
			name: inputName
		};
		return propsMergeFunction(stateProps, dispatchProps, ownPropsWithInputName);
	};
}
