import Immutable from 'immutable';

import {getDownscaleVector} from '../../commons/data/aim/DicomImageHelpers.js';
import {getAllWindowPresets} from '../../commons/data/aim/SeriesHelpers.js';
import {getGrayscaleValue, isDecoded} from '../../commons/data/aim/SynAdvancedImage.js';
import {hasOwnPropertySafe} from '../../commons/utils/ObjectUtils';
import {DICOM_ENC_PDF_DATA_TYPE, DICOM_IMAGE_DATA_TYPE, HTML_DATA_TYPE} from '../constants/ViewerItemConstants.js';
import {createAnnotationItem} from '../utils/AnnotationUtils.js';
import {isCompatible} from '../utils/DicomPointSyncUtils.js';

const DicomImageTemplate = Immutable.Record({
	type: null,
	imageId: null,
	rawImageData: null,
	dicomDump: null,
	metadata: null,
	loadError: null
});

class DicomImage extends DicomImageTemplate {
	get isDownloadComplete() { return Boolean(this.dicomDump) && Boolean(this.rawImageData); }
	get pixelSpacing() { return this.dicomDump && this.dicomDump.getPixelSpacing(); }
	get supportsLengthScale() { return Boolean(this.dicomDump) && Boolean(this.dicomDump.getPixelSize()); }
	get annotationItems() { return this.calcOnce(this.calcSupportedAnnotationItems, '_annotationItems_'); }
	get windowPresets() { return this.calcOnce(this.calcAllWindowPresets, '_windowPresets_'); }
	get supportsPointSynchronization() { return this.calcOnce(this.calcSupportsPointSync, '_supportsPointSynchronization_'); }
	get frameOfReferenceUID() { return this.dicomDump ? this.dicomDump.getFrameOfReferenceUID() : null; }
	get downscaleVector() { return this.calcOnce(this.calcDownscaleVector, '_downscaleVector_'); }
	get isDecoded() { return this.rawImageData && isDecoded(this.rawImageData); }
	get isImage() { return this.type === DICOM_IMAGE_DATA_TYPE; }
	get isEncapsulatedPdf() { return this.type === DICOM_ENC_PDF_DATA_TYPE; }
	get isHtml() { return this.type === HTML_DATA_TYPE; }
	get isHounsfieldValueAvailable() { return this.dicomDump && this.dicomDump.getModality().toUpperCase() === 'CT'; }

	get isDisplaySupported() {
		return Boolean(this.dicomDump) && (
			this.dicomDump.getSupportsImagePixelModule() ||
			this.isEncapsulatedPdf ||
			this.isHtml
		);
	}

	setRawImage(newImage) {
		return this.set('rawImageData', newImage);
	}

	calcSupportedAnnotationItems() {
		const annotationItems = [];
		if (this.imageId) {
			annotationItems.push(createAnnotationItem('image', this.imageId));
		}
		if (this.supportsPointSynchronization) {
			const frameOfReferenceUID = this.dicomDump.getFrameOfReferenceUID();
			annotationItems.push(createAnnotationItem('frameOfReference', frameOfReferenceUID));
		}
		return annotationItems;
	}

	calcAllWindowPresets() {
		const modality = this.dicomDump ? this.dicomDump.getModality() : undefined;
		return getAllWindowPresets(modality, this.rawImageData);
	}

	calcSupportsPointSync() {
		return this.isImage && isCompatible(this.dicomDump);
	}

	calcDownscaleVector() {
		return getDownscaleVector(this.dicomDump, this.rawImageData);
	}

	calcOnce(func, resultKey) {
		if (!hasOwnPropertySafe(this, resultKey)) {
			Object.defineProperty(this, resultKey, {enumerable: false, value: func.apply(this)});
		}
		return this[resultKey];
	}

	getGrayscaleValue(pointX, pointY) {
		return getGrayscaleValue(this.rawImageData, pointX, pointY);
	}
}

export const EMPTY_DICOM_IMAGE = new DicomImage({});

export default DicomImage;
