import {webAppURL} from './commons/WebViewHelpers.js';

// New urls
export const WEBVIEW_DOCUMENT_SHARE_PATH = webAppURL('documentshare');
export const WEBVIEW_DOCUMENT_PATH = webAppURL('document');
export const WEBVIEW_PATIENT_DETAILS_PATH = webAppURL('patient/:id/:itemType');
export const WEBVIEW_PATIENT_DETAILS_LOADER_PATH = webAppURL('patient');
export const WEBVIEW_UPLOAD_PATH = webAppURL('upload');
export const WEBVIEW_CREATE_ORDER_PATH = webAppURL('order');
export const WEBVIEW_DOCUMENT_PERMIT_CLAIM_PATH = webAppURL('documentpermit/:permitId');

// Urls for backward compatibility
export const LEGACY_SINGLE_DOCUMENT_PATH = '/document/:document_id.html';
export const LEGACY_SINGLE_INBOX_ITEM_PATH = '/inbox/:share_id.html';
export const LEGACY_DOCUMENT_QUERY_PATH = '/document:?ext';
