import FetchError from './FetchError.js';

/**
 * An error class to indicate that the current does not have 
 * sufficient permissions to intereact with the requested resource
 */
export default class InsufficientPermissionsError extends FetchError {
	constructor(response, responseAsJSON) {
		super('This request can only be handled with sufficient permissions.', response, responseAsJSON);
	}
}
