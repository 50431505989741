import {createSelector} from 'reselect';

import {getCurrentRoute} from '../../../router/flux/selectors/RoutingSelectors.js';

const getPatientIdFromRoute = createSelector(getCurrentRoute, currentRoute => parseInt(currentRoute.get('variables').get('id'), 10));

const getItemTypeFromRoute = createSelector(getCurrentRoute, currentRoute => currentRoute.get('variables').get('itemType'));

const RouteBasedSelectors = {
	selectPatientDetailsId: getPatientIdFromRoute,
	selectItemType: getItemTypeFromRoute
};

export default RouteBasedSelectors;
