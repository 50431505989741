/**
 * inspired by http://www.realtimerendering.com/resources/GraphicsGems/gemsiii/pl2plane.c
 */

import {vec3, vec4} from 'gl-matrix';

import {createLine} from './Line.js';

const THRESHOLD = 1.0e-8;
const THREE_DIMENSIONS = 3;
const FOUR_DIMENSIONS = 4;

/**
 * Constructs a vec4, representing the equation for the plane according to the Hesse Normal Form.
 * (see: https://en.wikipedia.org/wiki/Hesse_normal_form)
 *
 * To check if a point lies in the plane, simply create the dot product of this vector with the
 * vector [px, py, pz, 1] and check if the result is very close to 0.
 * @param point {vec3} a point on the plane to describe
 * @param direction1 {vec3} a directional vector in the plane
 * @param direction2 {vec3} a second directional vector in the plane
 * @returns {vec4} describing the planes equation [nx, ny, nz, -d]
 */
export function createPlaneFromOriginAndDirections(point, direction1, direction2) {
	const normal = vec3.cross(new Float64Array(THREE_DIMENSIONS), direction1, direction2);

	return vec4.set(new Float64Array(FOUR_DIMENSIONS),
		normal[0],
		normal[1],
		normal[2],
		-vec3.dot(point, normal)
	);
}

export function intersectPlanes(plane1, plane2) {
	//cross product of normal vectors
	const intersectionDirection = vec3.cross(new Float64Array(THREE_DIMENSIONS), plane1, plane2);

	//cross product
	// intersectionDirection.x = pl1.y*pl2.z - pl1.z*pl2.y
	// intersectionDirection.y = pl1.z*pl2.x - pl1.x*pl2.z
	// intersectionDirection.z = pl1.x*pl2.y - pl1.y*pl2.x


	const intersectionDirectionSquare = vec3.multiply(
		new Float64Array(THREE_DIMENSIONS), intersectionDirection, intersectionDirection
	);
	let intersection = null;

	//select the largest coordinate of the intersection vector. and set it to zero.
	//This results in a system of equations with two equations and two unknows.
	//
	//The reason to choose the largest coordinate is that this coordinate corresponds to the denominator
	//which is used in solving the equations (see later on). Hence, this coordinate must not be 0.
	if (intersectionDirectionSquare[2] > intersectionDirectionSquare[1] &&
		intersectionDirectionSquare[2] > intersectionDirectionSquare[0] &&
		intersectionDirectionSquare[2] > THRESHOLD) {
		/* then get a point on the XY plane */

		/* solve: //cross point has to be on both planes, solves the equation for xpt.z = 0
			< pl1.x * xpt.x + pl1.y * xpt.y = - pl1.w >
			< pl2.x * xpt.x + pl2.y * xpt.y = - pl2.w >

			->

		    < pl2.x * pl1.x * xpt.x + pl2.x * pl1.y * xpt.y = - pl1.w * pl2.x >
		    < pl2.x * pl1.x * xpt.x + pl1.x * pl2.y * xpt.y = - pl2.w * pl1.x >

			->

		    pl2.x * pl1.y * xpt.y - pl1.x * pl2.y * xpt.y = - pl1.w * pl2.x + pl2.w * pl1.x

			->

			xpt.y * (pl2.x * pl1.y - pl1.x*pl2.y) = pl2.w * pl1.x - pl1.w * pl2.x

			-> (* -1)

			xpt.y * (pl1.x * pl2.y - pl1.y * pl2.x) = pl2.x * pl1.w - pl1.x * pl2.w

			now it can be seen that

			this corresponds to intersectionDirection.z (=pl1.x*pl2.y - pl1.y*pl2.x)

			->

			(pl1.y * pl2.w - pl2.y * pl1.w)/intersectionDirection.z = xpt.x
		    (pl2.x * pl1.w - pl1.x * pl2.w)/intersectionDirection.z = xpt.y
		*/
		const origin = vec3.set(new Float64Array(THREE_DIMENSIONS),
			(plane1[1] * plane2[3] - plane2[1] * plane1[3]) / intersectionDirection[2],
			(plane2[0] * plane1[3] - plane1[0] * plane2[3]) / intersectionDirection[2],
			0.0
		);
		intersection = createLine(origin, vec3.normalize(intersectionDirection, intersectionDirection));
	} else if (intersectionDirectionSquare[1] > intersectionDirectionSquare[0] &&
		intersectionDirectionSquare[1] > THRESHOLD) {
		const origin = vec3.set(new Float64Array(THREE_DIMENSIONS),
			(plane2[2] * plane1[3] - plane1[2] * plane2[3]) / intersectionDirection[1],
			0.0,
			(plane1[0] * plane2[3] - plane2[0] * plane1[3]) / intersectionDirection[1]
		);
		intersection = createLine(origin, vec3.normalize(intersectionDirection, intersectionDirection));
	} else if (intersectionDirectionSquare[0] > THRESHOLD) {
		const origin = vec3.set(new Float64Array(THREE_DIMENSIONS),
			0.0,
			(plane1[2] * plane2[3] - plane2[2] * plane1[3]) / intersectionDirection[0],
			(plane2[1] * plane1[3] - plane1[1] * plane2[3]) / intersectionDirection[0]
		);
		intersection = createLine(origin, vec3.normalize(intersectionDirection, intersectionDirection));
	}
	return intersection;
}

export function isPointInPlane(point, plane) {
	return vec3.dot(point, plane) === -plane[3];
}

export function offsetFromPlane(point3d, plane) {
	return vec3.dot(point3d, plane) + plane[3];
}
