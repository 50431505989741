import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tappable from '../../commons/components/Tappable.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';

import '../../../styles/material-design/components/Sidebar.scss';

/**
 * Provides the base Widget class...
 *
 * @class Menu
 */
export default function Sidebar(props) {
	const {isVisible, disableTransitions, sidebarElevated, backgroundClicked, children} = props;
	const sidebarClassNames = classNames({
		sidebar: true,
		'is-hidden': !isVisible,
		transitions: !disableTransitions,
		'is-elevated': sidebarElevated
	});

	const sidebarContentClassNames = classNames({
		'sidebar--content': true,
		transitions: !disableTransitions
	});

	return (
		<Tappable onClick={backgroundClicked} className={sidebarClassNames} onlyDirectlyOnElement>
			<VerticalLayout className={sidebarContentClassNames}>
				{children}
			</VerticalLayout>
		</Tappable>
	);
}

Sidebar.propTypes = {
	isVisible: PropTypes.bool,
	disableTransitions: PropTypes.bool,
	backgroundClicked: PropTypes.func,
	sidebarElevated: PropTypes.bool
};

Sidebar.defaultProps = {
	isVisible: true,
	disableTransitions: false,
	backgroundClicked: () => {},
	sidebarElevated: false
};
