import Immutable from 'immutable';
import {createSelector} from 'reselect';

import createSessionGuardedComponentSelector from '../authentication/utils/createSessionGuardedComponentSelector.js';
import {webAppURL} from '../commons/WebViewHelpers.js';
import PatientDetailsLoaderPageContainer from '../patient-details/flux/containers/PatientDetailsLoaderPageContainer.js';
import {isPatientSearchQuery} from '../patient-details/flux/selectors/PatientDetailsLoaderSelectors.js';
import {WEBVIEW_PATIENT_DETAILS_LOADER_PATH} from '../WebViewRoutingConstants.js';
import createRedirectComponent from './components/createRedirectComponent.js';
import createLocation from './createLocation.js';
import createRoute from './createRoute.js';
import {locationSelector} from './flux/selectors/LocationSelectors.js';
import {getCurrentRouteVariables} from './flux/selectors/RoutingSelectors.js';

export function redirectTo(newLocation) {
	return createRedirectComponent(() => newLocation);
}

const DEFAULT_LOCATION = createLocation({
	path: '/'
});

export function removeExtention(location) {
	return location.update('path', path => {
		const parts = path.split('.');
		return parts.length > 1 ? parts.slice(0, -1).join('.') : path;
	});
}

export function toWebViewLocation(location) {
	return location.update('path', path => webAppURL(path));
}

export function page(component) {
	return () => component;
}

export function guarded(pageSelector, linkConditionSelector = () => true,
		alternativePageSelector = () => redirectTo(DEFAULT_LOCATION), parameterMatchers = Immutable.Map()) {
	const guardedPageSelector = createSessionGuardedComponentSelector(
		state => (linkConditionSelector(state) ? pageSelector(state) : alternativePageSelector(state))
	);
	return createRoute(guardedPageSelector, linkConditionSelector, parameterMatchers);
}

export function unguarded(pageSelector, linkConditionSelector = () => true,
		alternativePageSelector = () => redirectTo(DEFAULT_LOCATION)) {
	function finalPageSelector(state) {
		return linkConditionSelector(state) ? pageSelector(state) : alternativePageSelector(state);
	}
	return createRoute(finalPageSelector, linkConditionSelector);
}

export function patientDetailsRedirector(fallbackComponent) {
	return createSelector(isPatientSearchQuery,
		isSearchQuery => (isSearchQuery ? PatientDetailsLoaderPageContainer : fallbackComponent)
	);
}

export function redirector(redirectfunction, parameterMatchers = Immutable.Map()) {
	const Redirector = createRedirectComponent(
		createSelector(locationSelector, getCurrentRouteVariables, redirectfunction)
	);
	return createRoute(() => Redirector, () => true, parameterMatchers);
}

export function legacyDocumentToPatientDetailsLoader(location, routeVariables) {
	return location.update('path', () => WEBVIEW_PATIENT_DETAILS_LOADER_PATH)
		.update('query', query => query.set('document_id', routeVariables.get('document_id')));
}

export function legacyInboxItemToPatientDetailsLoader(location, routeVariables) {
	return location.update('path', () => WEBVIEW_PATIENT_DETAILS_LOADER_PATH)
		.update('query', query => query.set('share_id', routeVariables.get('share_id')));
}
