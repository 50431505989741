import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import {FULLSCREEN_TRANSITION_TIME, FULLSCREEN_TRANSITION_Z_INDEX} from '../constants/FullScreenWrapperConstants.js';

const STYLES = ({visible}) => ({
	position: 'fixed',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	backgroundColor: visible ? '#00000047' : '#00000000',
	transition: `background-color ${FULLSCREEN_TRANSITION_TIME}ms`,
	zIndex: FULLSCREEN_TRANSITION_Z_INDEX
});

const STYLES_OPTIONS = {
	shouldForwardProp: prop => prop !== 'visible'
};

function withModalBackgroundClasses(component) {
	return styled(component, STYLES_OPTIONS)(STYLES);
}

const ModalBackground = withModalBackgroundClasses('div');
ModalBackground.propTypes = {
	visible: PropTypes.bool
};
ModalBackground.defaultProps = {
	visible: true
};

export default React.memo(ModalBackground);
export {withModalBackgroundClasses};
