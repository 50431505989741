import React from 'react';
import PropTypes from 'prop-types';

import {ColumnCenterLayout} from '../../commons/components/layout/CenterLayout.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerMessagesTranslator from '../../i18n/translators/ViewerTranslator.js';
import {ViewerItemDownloadOptionsContainer} from '../flux/containers/ViewerItemDownloadOptionsContainer.js';
import ViewerError from '../ViewerError.js';

const DEFAULT_ERROR_MESSAGE =
	<SynFormattedMessage message='GenericDisplayErrorMessage' translator={ViewerMessagesTranslator} />;

export default function ViewerErrorDisplay(props) {
	const {error} = props;
	const isViewerError = error instanceof ViewerError;
	const errorDisplay = isViewerError ? renderViewerErrorMessage(error) : DEFAULT_ERROR_MESSAGE;

	return (
		<ColumnCenterLayout fitParent>
			{errorDisplay}
			<ViewerItemDownloadOptionsContainer />
		</ColumnCenterLayout>
	);
}

ViewerErrorDisplay.propTypes = {
	error: PropTypes.instanceOf(Error)
};

function renderViewerErrorMessage(error) {
	const {message, userMessage, translator} = error;
	const finalMessage = userMessage || message;
	const needsTranslation = translator && userMessage;
	return needsTranslation
		? <SynFormattedMessage translator={translator} message={finalMessage} />
		: finalMessage;
}
