import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../commons/containers/FormContainer.js';
import {translatorPropType} from '../../commons/utils/CustomPropTypes.js';
import generateUniqueKey from '../../commons/utils/generateUniqueKey.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import NumberCodeFormField from '../../material-design/components/form/NumberCodeFormField.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import VerificationCodeFormAccessors from './accessors/VerificationCodeFormAccessors.js';

export default class StepSecondFactorVerification extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.formId = `form${generateUniqueKey()}`;
	}

	render() {
		const {onSubmit, translator} = this.props;
		return (
			<React.Fragment>
				<VerticalLayout element={FormContainer} key='form' id={this.formId}
					description={VerificationCodeFormAccessors} onSubmit={onSubmit} deleteFormDataOnUnmount>
					<SynFormattedMessage translator={translator} message='SecondFactorRequiredMessage' className='new-password--message' />
					<NumberCodeFormField autoFocus fieldName='verificationCode' autoComplete='one-time-code' />
				</VerticalLayout>
				<MaterialFormButton flat opaque key='submitButton' formDescription={VerificationCodeFormAccessors}
				                    form={this.formId} type='submit'>
					<SynFormattedMessage translator={translator} message='SendVerificationCodeButtonLabel' />
				</MaterialFormButton>
			</React.Fragment>
		);
	}
}

StepSecondFactorVerification.propTypes = {
	onSubmit: PropTypes.func,
	translator: translatorPropType
};
