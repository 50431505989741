import {mat3, vec2} from 'gl-matrix';
import _debounce from 'lodash.debounce';

import {getDomImages, getHeight, getWidth, isMultiByteImage} from '../commons/data/aim/SynAdvancedImage.js';
import {callSafe, memoizeByFirstArg} from '../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../commons/utils/ObjectUtils';
import {createView} from '../commons/utils/TypedArrayUtils.js';
import canvasRenderingWorker from './canvasRenderingWorker.js';
import {
	calculateLut,
	getLutFunction,
	getLutSize,
	getPixelDataArrayType,
	getPixelFormat,
	scaleImagePixelsBilinear
} from './components/CanvasRendering.js';
import getPixelsPerMs from './components/getPixelsPerMs.js';

const HIGH_QUALITY_SCALE_FACTOR = 1.0;
const HIGH_QUALITY_RENDER_DELAY = 200;

const KBYTES = 1024;
const MEGA_BYTES = KBYTES * KBYTES;
const TEN = 10;
const BYTES_PER_RGBA_PIXEL = 4;
const RBGA_PIXELS_IN_TEN_MEGABYTES = (TEN * MEGA_BYTES) / BYTES_PER_RGBA_PIXEL;
const BITS_PER_BYTE = 8;

/**
 * This class implements image windowing using a web worker.
 * @author p.spitzlinger@synedra.com
 */
export default class CanvasImageRenderer {
	constructor(onNewImage) {
		this.onWorkerMessage = this.onWorkerMessage.bind(this);
		this.renderHighQualityImage = _debounce(this.renderHighQualityImage.bind(this), HIGH_QUALITY_RENDER_DELAY);
		this.getImageDependentData = memoizeByFirstArg(decodedImage => ({decodedImage}));
		this.nextRenderJobId = 0;
		this.currentRenderJob = {
			id: -1,
			renderParams: {},
			decodedImage: null
		};
		this.lastResult = null;
		this.worker = null;
		this.onNewImage = onNewImage;
		this.wasStopped = false;
	}

	renderImageSync(canvasContext, decodedImage, renderOptions) {
		this.extractPixelData(decodedImage, HIGH_QUALITY_SCALE_FACTOR);
		const pixelData = this.getPixelData(decodedImage)[HIGH_QUALITY_SCALE_FACTOR];
		if (pixelData) {
			const {rawPixels, rawPixelsFormat, width, height} = pixelData;
			const {windowWidth, windowCenter, minPixelValue, maxPixelValue, invertOutput} = renderOptions;
			// TODO: Optimize lut size to only include colors from minPixelValue to maxPixelValue
			const lutSize = getLutSize(rawPixelsFormat);
			const lut = new Uint8Array(lutSize);
			calculateLut(lut, windowCenter, windowWidth, minPixelValue, maxPixelValue, invertOutput);
			const formatSpecificApplyLut = getLutFunction(rawPixelsFormat);
			const imageData = canvasContext.createImageData(width, height);
			formatSpecificApplyLut(lut, imageData.data, rawPixels);
			canvasContext.putImageData(imageData, 0, 0);
		}
	}

	renderImage(decodedImage, renderOptions) {
		const lowQualityScaleFactor = getLowQualityScaleFactor(decodedImage);
		this.renderScaledImage(decodedImage, renderOptions, lowQualityScaleFactor);
	}

	renderScaledImage(decodedImage, renderOptions, scaleFactor) {
		let renderingScheduled = false;
		if (!this.wasStopped) {
			const renderParams = createRenderParams(renderOptions, scaleFactor);
			const {
				renderParams: currentRenderParams,
				decodedImage: currentDecodedImage
			} = this.currentRenderJob;
			const needsRendering = !shallowEqual(currentRenderParams, renderParams) ||
				currentDecodedImage !== decodedImage;
			if (needsRendering) {
				const newJob = this.createRenderJob(decodedImage, renderParams);
				this.scheduleRendering(newJob);
				this.postNextJob();
				renderingScheduled = true;
			}
		}
		return renderingScheduled;
	}

	stop() {
		this.wasStopped = true;
		if (this.worker) {
			this.worker.terminate();
			this.worker = null;
		}
	}

	scheduleRendering(nextJob) {
		this.nextRenderJob = nextJob;
	}

	postNextJob() {
		if (!this.isRendering()) {
			if (this.nextRenderJob) {
				this.currentRenderJob = this.nextRenderJob;
				this.nextRenderJob = null;
				const {
					decodedImage,
					renderParams: {scaleFactor}
				} = this.currentRenderJob;
				try {
					this.extractPixelData(decodedImage, scaleFactor);
					this.initializeWorker();
					this.postPixelData();
					this.postRenderParams();
				} catch (error) {
					this.handleRenderError(error);
				}
			} else {
				this.renderHighQualityImage();
			}
		}
	}

	handleRenderError(error) {
		this.lastResult = {error, isComplete: true};
		this.notifyListeners();
	}

	renderHighQualityImage() {
		if (this.lastResult) {
			const {scaleFactor} = this.lastResult;
			if (scaleFactor > HIGH_QUALITY_SCALE_FACTOR) {
				const {renderParams, decodedImage} = this.currentRenderJob;
				this.renderScaledImage(decodedImage, renderParams, HIGH_QUALITY_SCALE_FACTOR);
			}
		}
	}

	extractPixelData(decodedImage, scaleFactor) {
		if (decodedImage && !this.hasPixelData(decodedImage, scaleFactor)) {
			const width = getWidth(decodedImage);
			const height = getHeight(decodedImage);
			const highQualityPixelData = this.extractAndGetHighQualityPixelData(decodedImage);
			const {width: hqWidth, height: hqHeight, rawPixels: hqRawPixels} = highQualityPixelData;

			const scaledWidth = Math.floor(width / scaleFactor);
			const scaledHeight = Math.floor(height / scaleFactor);

			const samplesPerPixel = isMultiByteImage(decodedImage) ? 1 : BYTES_PER_RGBA_PIXEL;
			const scaledPixels = scaleImagePixelsBilinear(
				hqRawPixels, samplesPerPixel,
				hqWidth, hqHeight, scaledWidth, scaledHeight
			);
			this.storeExtractedPixels(decodedImage, scaleFactor, scaledWidth, scaledHeight, scaledPixels);
		}
	}

	extractAndGetHighQualityPixelData(decodedImage) {
		if (decodedImage && !this.hasPixelData(decodedImage, HIGH_QUALITY_SCALE_FACTOR)) {
			const width = getWidth(decodedImage);
			const height = getHeight(decodedImage);
			this.storeExtractedPixels(
				decodedImage, HIGH_QUALITY_SCALE_FACTOR,
				width, height, extractPixels(decodedImage)
			);
		}
		return this.getPixelData(decodedImage)[HIGH_QUALITY_SCALE_FACTOR];
	}

	storeExtractedPixels(decodedImage, scaleFactor, scaledWidth, scaledHeight, pixels) {
		const width = getWidth(decodedImage);
		const height = getHeight(decodedImage);
		const pixelFormat = getPixelFormat(decodedImage);

		let scaleMatrix = mat3.create();
		const scaleVector = vec2.set(vec2.create(), width / scaledWidth, height / scaledHeight);
		scaleMatrix = mat3.scale(scaleMatrix, scaleMatrix, scaleVector);

		this.getPixelData(decodedImage)[scaleFactor] = {
			width: scaledWidth,
			height: scaledHeight,
			scaleMatrix,
			scaleFactor,
			rawPixels: pixels,
			rawPixelsFormat: pixelFormat
		};
	}

	postPixelData() {
		const {
			decodedImage,
			renderParams: {scaleFactor}
		} = this.currentRenderJob;
		if (decodedImage && !this.postedPixelData(decodedImage, scaleFactor)) {
			const pixelData = this.getPixelData(decodedImage);
			this.tryToPostImageDataToWorker(pixelData[scaleFactor]);
			pixelData[scaleFactor].postedToWorker = true;
		}
	}

	hasPixelData(decodedImage, scaleFactor) {
		const {[scaleFactor]: extractedPixels} = this.getPixelData(decodedImage);
		return Boolean(extractedPixels);
	}

	isRendering() {
		const lastJobCompleted = this.lastResult && this.lastResult.isComplete === true;
		const {id} = this.currentRenderJob;
		return !this.wasStopped && id >= 0 && !lastJobCompleted;
	}

	postedPixelData(decodedImage, scaleFactor) {
		const {[scaleFactor]: extractedPixels} = this.getPixelData(decodedImage);
		const {postedToWorker} = extractedPixels;
		return Boolean(postedToWorker);
	}

	getPixelData(image) {
		const imageDependentData = this.getImageDependentData(image);
		if (!imageDependentData.pixelData) {
			imageDependentData.pixelData = {};
		}
		return imageDependentData.pixelData;
	}

	initializeWorker() {
		if (this.worker === null) {
			const newWorker = canvasRenderingWorker();
			newWorker.onmessage = this.onWorkerMessage;
			this.worker = newWorker;
		}
	}

	tryToPostImageDataToWorker(pixelData) {
		const {
			width,
			height
		} = pixelData;
		let maxHeight = Math.max(1, Math.min(height, Math.floor(RBGA_PIXELS_IN_TEN_MEGABYTES / width)));
		let posted = false;
		while (!posted) {
			try {
				const imageData = new ImageData(width, maxHeight);
				this.postImageDataToWorker({
					...pixelData,
					imageData
				});
				posted = true;
			} catch (e) {
				maxHeight = Math.floor(maxHeight / 2.0);
				if (maxHeight === 0) {
					// Simply give up :-/
					throw e;
				}
			}
		}
	}

	postRenderParams() {
		const {
			renderParams,
			id
		} = this.currentRenderJob;
		this.postToWorker('renderStep', {
			id,
			...renderParams
		});
	}

	postImageDataToWorker(imageData) {
		const {rawPixels, ...remainingImageData} = imageData;
		const bufferCopy = rawPixels.buffer.slice(0);
		const finalImageData = {
			...remainingImageData,
			rawPixelsBuffer: bufferCopy,
			rawPixelsBufferOffset: rawPixels.byteOffset
		};
		this.postToWorker('imageData', finalImageData, [bufferCopy]);
	}

	postToWorker(type, data, transferObjects = []) {
		this.worker.postMessage({type, ...data}, transferObjects);
	}

	onWorkerMessage(event) {
		const {data} = event;
		const {id: postedId} = data;
		const {id: currentJobId} = this.currentRenderJob;
		if (postedId === currentJobId) {
			const renderResult = event.data;
			if (!this.lastResult || this.lastResult.id !== renderResult.id) {
				this.lastResult = renderResult;
				this.lastResult.imageData = [renderResult.imageData];
			} else {
				this.lastResult.imageData.push(renderResult.imageData);
			}

			if (renderResult.isComplete) {
				this.lastResult.isComplete = true;
				this.notifyListeners();
				// Immediately free posted image data.
				this.lastResult.imageData = null;
				this.postNextJob();
			}
		}
	}

	notifyListeners() {
		if (this.lastResult && !this.wasStopped) {
			callSafe(this.onNewImage, {...this.lastResult});
		}
	}

	createRenderJob(decodedImage, renderParams) {
		return {
			decodedImage,
			id: this.getNextRenderJobId(),
			renderParams
		};
	}

	getNextRenderJobId() {
		return this.nextRenderJobId++;
	}
}

function getLowQualityScaleFactor(decodedImage) {
	const totalNumberPixels = getWidth(decodedImage) * getHeight(decodedImage);
	const msPerFrame = 14;
	const pixelsPerFrame = msPerFrame * getPixelsPerMs();
	const amountOfImageInOneFrame = pixelsPerFrame / totalNumberPixels;
	return Math.max(1, Math.ceil(Math.sqrt(1 / amountOfImageInOneFrame)));
}


function createRenderParams(renderOptions, scaleFactor) {
	return {
		...renderOptions,
		scaleFactor
	};
}

function extractPixels(decodedImage) {
	const allDomImages = getDomImages(decodedImage);
	return allDomImages.reduce((multiByteArray, domImage, imageIndex) => {
		let mergedMultiByteArray = multiByteArray;
		const extractedPixelData = extractRGBPixelData(domImage);
		if (mergedMultiByteArray) {
			const previousPixels = mergedMultiByteArray;
			let previousPixelsMultiplier = 1;
			if (imageIndex === 1) {
				previousPixelsMultiplier = BYTES_PER_RGBA_PIXEL;
				mergedMultiByteArray = createView(
					getPixelDataArrayType(getPixelFormat(decodedImage)),
					mergedMultiByteArray
				);
			}
			const pixelShift = imageIndex * BITS_PER_BYTE;
			for (let index = 0; index < mergedMultiByteArray.length; ++index) {
				const rgbSampleIndex = index * BYTES_PER_RGBA_PIXEL;
				const previousPixelsIndex = index * previousPixelsMultiplier;
				mergedMultiByteArray[index] =
					previousPixels[previousPixelsIndex] + (extractedPixelData[rgbSampleIndex] << pixelShift);
			}
		} else {
			mergedMultiByteArray = extractedPixelData;
		}
		return mergedMultiByteArray;
	}, null);
}

function extractRGBPixelData(domImage) {
	return domImage.data.slice(0);
}
