import {createSelector} from 'reselect';

import {hidePrintLayout, showPrintLayout} from '../../ui/flux/UIActions.js';
import {uiShowPrintLayout} from '../../ui/flux/UISelectors.js';
import {addDocumentClassNames, removeDocumentClassNames} from '../utils/DOMUtils.js';
import {mightBeSafari} from '../utils/FeatureDetectionUtils.js';

/**
 * The PrintController handles printing related events (onbeforeprint, onafterprint, media-query switch).
 * It ensures that the application is switched into/out from PrintPreview mode when necessary.
 *
 * Printing via the Print toolbar-button on developer builds leads to application crash in Chrome Version >= 71
 * due to an infinite recursion during rendering. This seems to be a react issue and does not occur on release builds !!
 */
class PrintController {
	constructor(printModeClassName) {
		this.inPrintMode = false;
		this.printModeClassName = printModeClassName;
	}

	attach(redux) {
		window.addEventListener('beforeprint', this.enablePrintMode.bind(this, redux));
		window.addEventListener('afterprint', this.disablePrintMode.bind(this, redux));

		const boundSelector = createSelector(
			uiShowPrintLayout,
			this.onPrintModeChanged.bind(this, redux)
		);
		redux.subscribe(() => boundSelector(redux.getState()));
	}

	onPrintModeChanged(redux, newPrintMode) {
		if (newPrintMode) {
			addDocumentClassNames(this.printModeClassName);
			this.handleSafariConfirmCancel(redux);
			this.startBrowserPrint();
		} else {
			removeDocumentClassNames(this.printModeClassName);
		}
		this.inPrintMode = newPrintMode;
	}

	startBrowserPrint() {
		if (!this.inPrintMode) {
			setTimeout(() => window.print(), 1);
		}
	}

	handleSafariConfirmCancel(redux) {
		if (mightBeSafari()) {
			window.onfocus = () => {
				window.onfocus = null;
				this.disablePrintMode(redux);
			};
		}
	}

	enablePrintMode(redux) {
		this.inPrintMode = true;
		redux.dispatch(showPrintLayout());
	}

	disablePrintMode(redux) {
		this.inPrintMode = false;
		redux.dispatch(hidePrintLayout());
	}
}

/**
 * Detects print mode changes triggered from the browser or through the redux store instance.
 *
 * It dispatches externally triggered print mode changes through the redux store
 * and sets the CSS class provided in printModeClassName on the document accordingly.
 *
 * @param redux - redux store instance to dispatch print mode changes
 * @param printModeClassName - CSS class name to indicate print mode
 */
export default function detectPrintMode(redux, printModeClassName) {
	const printController = new PrintController(printModeClassName);
	printController.attach(redux);
}
