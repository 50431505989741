import React from 'react';
import {vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {KEY_VALUE_RETURN} from '../constants/KeyValues.js';
import {createTouchEventHandler, preventEventDefault} from '../utils/DOMEventUtils.js';
import {callSafe, noop} from '../utils/FunctionUtils.js';
import {cloneWithoutProperties, shallowEqual} from '../utils/ObjectUtils';
import {withForwardRef} from '../utils/ReactUtils.js';

const MAXIMUM_TAP_DELAY = 300; //in ms
const MAXIMUM_TAP_RADIUS = 15;
const EVENT_TYPE_TOUCH = 'touch';
const EMPTY_TAP_STATE = {
	startIdentifier: null,
	startPosition: null,
	tapStartTime: null
};

function extractPositionVector(e) {
	return vec2.set(vec2.create(), e.pageX, e.pageY);
}

class Tappable extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundOnTouchStart = this.createEventHandler('onTouchStart', createTouchEventHandler(this.onTouchStart));
		this.boundOnTouchEnd = this.createEventHandler('onTouchEnd', createTouchEventHandler(this.onTouchEnd));
		this.boundOnMouseDown = this.createEventHandler('onMouseDown', noop);
		this.boundOnMouseUp = this.createEventHandler('onMouseUp', noop);
		this.boundOnMouseClick = this.createEventHandler('', this.doClick);
		this.boundOnPointerDown = this.createEventHandler('onPointerDown', noop);
		this.boundOnPointerUp = this.createEventHandler('onPointerUp', noop);
		this.boundOnKeyDown = this.createEventHandler('onKeyDown', this.onKeyDown);
		this.boundOnKeyUp = this.createEventHandler('onKeyUp', this.onKeyUp);
		this.boundCaptureMountedElement = this.captureMountedElement.bind(this);

		this.mountedElement = null;
		this.state = EMPTY_TAP_STATE;
		this.pressedKey = null;
	}

	render() {
		const {Component} = this.props;
		const tappableHandlers = this.createEventHandlers();
		const remainingProps = cloneWithoutProperties(this.props,
			'Component',
			'onTap', 'onClick',
			'onClickCapture',
			'onTouchStart', 'onTouchEnd',
			'onMouseDown', 'onMouseUp',
			'onPointerDown', 'onPointerUp',
			'onlyDirectlyOnElement', 'loadInProgress',
			'resultList', 'startIndex',
			'numberRows', 'a11yProps', 'forwardedRef'
		);
		return (
			<Component ref={this.boundCaptureMountedElement} onKeyUp={this.boundOnKeyUp} onKeyDown={this.boundOnKeyDown}
			           {...tappableHandlers} {...remainingProps} />
		);
	}

	createEventHandler(callBackName, handler) {
		return e => {
			const {onlyDirectlyOnElement, [callBackName]: propsCallBack} = this.props;
			if (this.areEventHandlersSpecified() &&
				(!onlyDirectlyOnElement || this.isEventForOurElement(e))) {
				if (propsCallBack) {
					callSafe(propsCallBack, e);
				}
				handler.call(this, e);
			}
		};
	}

	captureMountedElement(reactElement) {
		const {forwardedRef} = this.props;
		this.mountedElement = reactElement;
		callSafe(forwardedRef, reactElement);
	}

	isEventForOurElement(e) {
		return e && e.target === this.mountedElement;
	}

	onTouchStart(e) {
		if (e.touches.length === 1) {
			const touch = e.touches.item(0);
			this.handleBeginEvent(touch.identifier, extractPositionVector(touch));
		} else {
			this.setState(EMPTY_TAP_STATE);
		}
	}

	onTouchEnd(e) {
		const touch = e.changedTouches.item(0);
		this.handleEndEvent(touch.identifier, extractPositionVector(touch), EVENT_TYPE_TOUCH, e);
	}

	onKeyDown(e) {
		this.pressedKey = e.key;
		if (!e.defaultPrevented && e.key === KEY_VALUE_RETURN) {
			preventEventDefault(e);
		}
	}

	onKeyUp(e) {
		const wasPressed = this.pressedKey === e.key;
		this.pressedKey = null;
		if (!e.defaultPrevented && wasPressed && e.key === KEY_VALUE_RETURN) {
			this.doClick(e);
		}
	}

	handleBeginEvent(eventId, position) {
		this.setState({
			startIdentifier: eventId,
			startPosition: position,
			tapStartTime: new Date().getTime()
		});
	}

	handleEndEvent(eventId, position, type, e) {
		const {startIdentifier, startPosition} = this.state;
		const tapDetected =
			eventId === startIdentifier &&
			Tappable.isCloseEnough(position, startPosition) &&
			this.wasEndEventFastEnough();
		this.setState({...EMPTY_TAP_STATE});
		if (tapDetected) {
			if (type === EVENT_TYPE_TOUCH) {
				this.doTap(e);
			} else {
				this.doClick(e);
			}
		}
	}

	doTap(e) {
		const {onTap, onClick} = this.props;
		callSafe(onTap || onClick, e);
	}

	doClick(e) {
		const {onTap, onClick} = this.props;
		callSafe(onClick || onTap, e);
	}

	static isCloseEnough(vA, vB) {
		return vec2.distance(vA, vB) < MAXIMUM_TAP_RADIUS;
	}

	wasEndEventFastEnough() {
		const {tapStartTime} = this.state;
		return tapStartTime !== null &&
			(new Date().getTime() - tapStartTime) < MAXIMUM_TAP_DELAY;
	}

	areEventHandlersSpecified() {
		const {onTap, onClick} = this.props;
		return Boolean(onTap) || Boolean(onClick);
	}

	createEventHandlers() {
		return {
			onTouchStart: this.boundOnTouchStart,
			onTouchEnd: this.boundOnTouchEnd,
			onClick: this.boundOnMouseClick,
			onPointerDown: this.boundOnPointerDown,
			onPointerUp: this.boundOnPointerUp,
			onMouseDown: this.boundOnMouseDown,
			onMouseUp: this.boundOnMouseUp
		};
	}

	shouldComponentUpdate(nextProps /*, nextState*/) {
		// NOTE: Only update when props change but ignore state changes
		return !shallowEqual(nextProps, this.props);
	}
}

Tappable.propTypes = {
	Component: PropTypes.elementType,
	onlyDirectlyOnElement: PropTypes.bool,
	onClick: PropTypes.func,
	onTap: PropTypes.func,
	onTouchStart: PropTypes.func,
	onTouchEnd: PropTypes.func,
	onMouseUp: PropTypes.func,
	onMouseDown: PropTypes.func,
	onPointerUp: PropTypes.func,
	onPointerDown: PropTypes.func,
	forwardedRef: withForwardRef.PropTypes.Ref
};

Tappable.defaultProps = {
	Component: 'div',
	onlyDirectlyOnElement: false,
	onClick: undefined,
	onTap: undefined,
	onTouchStart: undefined,
	onTouchEnd: undefined,
	onMouseUp: undefined,
	onMouseDown: undefined,
	onPointerUp: undefined,
	onPointerDown: undefined
};

export default withForwardRef(Tappable, 'forwardedRef');
