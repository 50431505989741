import {getOffscreenRenderingContext} from '../../viewer/components/CanvasRendering';
import ImageLoadError from '../api/ImageLoadError.js';
import {mightBeIOS, mightBeSafari} from './FeatureDetectionUtils';

const IOS_MAX_CANVAS_SIZE = 4096;
const BYTES_PER_RGBA_PIXEL = 4;

// NOTE: createImageBitmap on WebKit based browsers doesn't support Blobs as input images.
// Unfortunately this is exactly our use-case!
// See https://caniuse.com/?search=createImageBitmap for details.
const IS_CREATE_IMAGE_BITMAP_SUPPORTED = (typeof createImageBitmap === 'function') && !mightBeSafari() && !mightBeIOS();
export function isCreateImageBitmapSupported() {
	return IS_CREATE_IMAGE_BITMAP_SUPPORTED;
}

export function createHiddenCanvas(width, height) {
	const canvas = document.createElement('canvas');

	canvas.style.width = `${width}px`;
	canvas.style.height = `${height}px`;
	canvas.width = width;
	canvas.height = height;
	canvas.style.position = 'absolute';
	canvas.style.visibility = 'hidden';
	canvas.style.display = 'none';

	return canvas;
}

export function loadImageFromBlob(imageBlob) {
	const blobURL = URL.createObjectURL(imageBlob);
	return loadImageFromURL(blobURL)
		.catch(e => {
			URL.revokeObjectURL(blobURL);
			throw e;
		});
}

export function convertToBlobUrls(blobList) {
	return blobList.map(blob => URL.createObjectURL(blob));
}

export function decodedBlobUrls(blobURLs) {
	return Promise.all(blobURLs.map(loadImageFromURL).toArray())
		.catch(e => {
			blobURLs.forEach(URL.revokeObjectURL);
			throw e;
		});
}

/**
 * Given a url this method asynchronously loads the respective dom Image
 *
 * @param imageURL the url to load the DOM Image for
 * @return a Promise resolving to the loaded dom Image
 */
function loadImageFromURL(imageURL) {
	return new Promise(((resolve, reject) => {
		const image = new Image();

		image.onload = function onImageLoad() {
			if (isImageValid(image)) {
				resolve(image);
			} else {
				reject(new ImageLoadError('Could not load image', imageURL));
			}
		};

		image.onerror = function onImageLoadError() {
			reject(new ImageLoadError('Could not load image.', imageURL));
		};

		image.src = imageURL;
	}));
}

export function decodeImageBlobs(blobList) {
	const promiseForDecodedImages = blobList.map(decodeImageFromBlob);
	return new Promise((resolve, reject) => {
		let finished = 0;
		let failed = 0;
		const decodedImages = new Array(promiseForDecodedImages.size);
		const resolveOrReject = () => {
			if (finished === promiseForDecodedImages.size) {
				if (failed > 0) {
					// At least one failed
					decodedImages
						.filter(decodedImage => Boolean(decodedImage))
						.forEach(decodedImage => {
							decodedImage.close();
						});
					reject(new ImageLoadError(`Failed to decode ${failed} images`, ''));
				} else {
					resolve(decodedImages);
				}
			}
		};
		promiseForDecodedImages.forEach((promise, index) => promise
			.then(decodedImage => {
				++finished;
				decodedImages[index] = decodedImage;
				resolveOrReject();
			})
			.catch(() => {
				++finished;
				++failed;
				resolveOrReject();
			})
		);
	});
}

export function decodeImageFromBlob(imageBlob) {
	return createImageBitmap(imageBlob)
		.catch(() => {
			throw new ImageLoadError('Could not load image.', '');
		});
}

export function isImageValid(image) {
	const supportsNaturalDimensions = image && 'naturalHeight' in image;
	return Boolean(image) &&
		(
			image[supportsNaturalDimensions ? 'naturalWidth' : 'width'] +
			image[supportsNaturalDimensions ? 'naturalHeight' : 'height']
		) !== 0;
}

export function loadImageDataFromBlobs(rawImageBlobs) {
	return loadImages(rawImageBlobs).then(images => {
		const imageData = images.map(extractImageData);
		images.forEach(releaseImage);
		return imageData;
	});
}

function loadImages(rawImagBlobs) {
	return isCreateImageBitmapSupported()
		? decodeImageBlobs(rawImagBlobs)
		: decodedBlobUrls(convertToBlobUrls(rawImagBlobs));
}

function extractImageData(domImage) {
	let imageData;
	const {width, height} = domImage;
	if ((width > IOS_MAX_CANVAS_SIZE || height > IOS_MAX_CANVAS_SIZE) && mightBeIOS()) {
		imageData = extractImageDataInTiles(domImage);
	} else {
		const renderContext = getOffscreenCanvasContext(width, height);
		renderContext.drawImage(domImage, 0, 0, width, height);
		imageData = renderContext.getImageData(0, 0, width, height);
	}
	return imageData;
}

function extractImageDataInTiles(domImage) {
	const {width, height} = domImage;
	const offscreenWidth = Math.min(width, IOS_MAX_CANVAS_SIZE);
	const offscreenHeight = Math.min(height, IOS_MAX_CANVAS_SIZE);
	const renderContext = getOffscreenCanvasContext(offscreenWidth, offscreenHeight);
	const imageData = renderContext.createImageData(width, height);
	for (let offsetRow = 0; offsetRow < height; offsetRow += offscreenHeight) {
		const remainingRows = Math.max(0, height - offsetRow);
		const drawnHeight = Math.min(remainingRows, offscreenHeight);
		const targetRowOffset = offsetRow * width * BYTES_PER_RGBA_PIXEL;

		for (let offsetColumn = 0; offsetColumn < width; offsetColumn += offscreenWidth) {
			const remainingColumns = Math.max(0, width - offsetColumn);
			const drawnWidth = Math.min(remainingColumns, offscreenWidth);
			const targetColumnOffset = offsetColumn * BYTES_PER_RGBA_PIXEL;
			renderContext.drawImage(domImage,
				offsetColumn, offsetRow, drawnWidth, drawnHeight,
				0, 0, drawnWidth, drawnHeight
			);
			const intermediateImageData = renderContext.getImageData(0, 0, drawnWidth, drawnHeight);
			copyTile(intermediateImageData, imageData, targetRowOffset, targetColumnOffset);
		}
	}
	return imageData;
}

function copyTile(from, to, targetRowOffsetBytes, targetColumnOffsetBytes) {
	const {width: targetWidth} = to;
	const {width, height} = from;
	const bytesPerRow = width * BYTES_PER_RGBA_PIXEL;
	const targetBytesPerRow = targetWidth * BYTES_PER_RGBA_PIXEL;
	for (let row = 0; row < height; row += 1) {
		const rowOffset = row * width * BYTES_PER_RGBA_PIXEL;
		const rowSubArray = from.data.subarray(rowOffset, rowOffset + bytesPerRow);
		const targetOffset = targetRowOffsetBytes + (row * targetBytesPerRow) + targetColumnOffsetBytes;
		to.data.set(rowSubArray, targetOffset);
	}
}

let offscreenCanvasProps = null;
function getOffscreenCanvasContext(width, height) {
	if (offscreenCanvasProps === null) {
		const newElement = document.createElement('canvas');
		offscreenCanvasProps = {
			element: newElement,
			context: getOffscreenRenderingContext(newElement)
		};
	}
	const {
		element,
		context
	} = offscreenCanvasProps;
	element.width = width;
	element.height = height;
	return context;
}

export function releaseImage(image) {
	if (image.close instanceof Function) {
		image.close();
	} else if (image.src) {
		URL.revokeObjectURL(image.src);
		image.src = '';
	}
}
