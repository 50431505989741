import React from 'react';

import DomEventsManager from '../../events/DomEventsManager.js';
import {synchronizedWithAnimationFrame} from '../utils/FunctionUtils.js';
import {shallowEqual} from '../utils/ObjectUtils';

export default function makeMeasured(measureCondition, Component) {
	class Measured extends React.Component {
		constructor(props, context) {
			super(props, context);
			this.throttledMeasure = synchronizedWithAnimationFrame(this.measure.bind(this));
			this.elementRef = React.createRef();
			this.domEventsManager = new DomEventsManager();
			this.state = {};
		}

		render() {
			const {width, height} = this.state;
			return <Component ref={this.elementRef} width={width} height={height} {...this.props} />;
		}
		
		watchTransitions() {
			this.domEventsManager.removeAllListeners();
			this.domEventsManager.addEventListener(window.document, 'transitioncancel', this.throttledMeasure.bind(this));
			this.domEventsManager.addEventListener(window.document, 'transitionend', this.throttledMeasure.bind(this));
		}

		unwatchTransitions() {
			if (this.domEventsManager) {
				this.domEventsManager.removeAllListeners();
				this.domEventsManager = null;
			}
		}

		measure() {
			const element = this.elementRef.current;
			if (element) {
				const newSize = measureElement(element);
				const oldSize = this.state;
				if (!shallowEqual(oldSize, newSize)) {
					this.setState(newSize);
				}
			}
		}

		componentDidUpdate(prevProps) {
			if (measureCondition(this.props, prevProps)) {
				this.measure();
			}
		}

		componentDidMount() {
			this.watchTransitions();
			this.measure();
		}

		componentWillUnmount() {
			this.unwatchTransitions();
			this.throttledMeasure.stop();
		}
	}
	return Measured;
}

function measureElement(element) {
	let size = {};
	if ('getBoundingClientRect' in element) {
		const clientRect = element.getBoundingClientRect();
		size = {
			width: clientRect.width,
			height: clientRect.height
		};
	} else {
		throw new Error('Invalid Component supplied to makeMeasured(). Expected an element type that can hold a ref that points to a DOM node');
	}
	return size;
}
