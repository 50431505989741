import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import {UploadStepperFooter} from '../../webview/commons/components/upload/UploadStepperFooter.js';
import {useMemoFactory} from '../../webview/commons/utils/customHooks';
import UploadController from '../bricks/UploadController.js';
import UploadRestoreTrashButton from './UploadRestoreTrashButton.js';

export default function WebUploadStepperFooter() {
	const {controllerState, startUpload} = useBrick(UploadController, selectControllerProps);
	const {buttonLabel, buttonHandler} = useMemoFactory(getForwardButtonLabel, controllerState, startUpload);
	const isPreparing = [
		UploadController.State.PREPARING,
		UploadController.State.READY
	].includes(controllerState);
	return (
		<UploadStepperFooter buttonLabel={buttonLabel} buttonHandler={buttonHandler}>
			{isPreparing && <UploadRestoreTrashButton />}
		</UploadStepperFooter>
	);
}

function selectControllerProps(brick) {
	return {
		startUpload: brick.startUpload,
		controllerState: brick.getState()
	};
}

function getForwardButtonLabel(uploadControllerState, startUpload) {
	switch (uploadControllerState) {
		case UploadController.State.PREPARING:
			return {
				buttonLabel: 'StartUpload'
			};
		case UploadController.State.READY:
			return {
				buttonLabel: 'StartUpload',
				buttonHandler: startUpload
			};
		default:
			return {};
	}
}
