import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import NotchInset from '../../ui/components/layout/NotchInset.js';
import {BACKGROUND_PALETTE_NAME, VIEWER_TOOLBAR_BACKGROUND_COLOR_NAME} from '../../ui/constants/ColorConstants.js';
import ViewerContext from './ViewerContext.js';

function ViewerToolbarNotchInset(props) {
	const {viewerId, isFullScreen} = useContext(ViewerContext);
	const {bottomViewerIds} = props;
	const isBottomViewerToolbar = bottomViewerIds.includes(viewerId);
	return (isBottomViewerToolbar || isFullScreen) &&
		<NotchInset variant='bottom' bgColor={`${BACKGROUND_PALETTE_NAME}.${VIEWER_TOOLBAR_BACKGROUND_COLOR_NAME}`} />;
}

ViewerToolbarNotchInset.propTypes = {
	bottomViewerIds: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default React.memo(ViewerToolbarNotchInset);
