import {memoize} from '@formatjs/fast-memoize';
import createFormatCache from 'intl-format-cache';
import IntlMessageFormat from 'intl-messageformat';

import {DEFAULT_ENGLISH_LOCALE} from '../commons/constants/SynSettingsConstants.js';
import {memoizeByFirstArg} from '../commons/utils/FunctionUtils.js';

// Format instance memoizer
const messageFormatter = createFormatCache(IntlMessageFormat);

const DEFAULT_TIMEZONE = 'UTC';
const SHORT_DATE_FORMAT_OPTIONS = {
	month: '2-digit',
	day: '2-digit',
	year: 'numeric',
	timeZone: DEFAULT_TIMEZONE
};
const VERY_SHORT_TIME_FORMAT_OPTIONS = {
	hour: 'numeric',
	minute: 'numeric',
	second: undefined,
	timeZone: DEFAULT_TIMEZONE
};

const ADDITIONAL_FORMATS = {
	date: {
		veryshort: {
			month: 'numeric',
			day: 'numeric',
			year: 'numeric',
			timeZone: DEFAULT_TIMEZONE
		},
		short: SHORT_DATE_FORMAT_OPTIONS,
		medium: {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			timeZone: DEFAULT_TIMEZONE
		},
		long: {
			month: 'long',
			day: 'numeric',
			year: 'numeric',
			timeZone: DEFAULT_TIMEZONE
		},
		full: {
			weekday: 'long',
			month: 'long',
			day: 'numeric',
			year: 'numeric',
			timeZone: DEFAULT_TIMEZONE
		}
	},
	time: {
		veryshort: VERY_SHORT_TIME_FORMAT_OPTIONS,
		short: VERY_SHORT_TIME_FORMAT_OPTIONS,
		// NOTE: medium is the default time format for intl-messageformat!
		medium: {
			hour: '2-digit',
			minute: '2-digit',
			second: undefined,
			timeZone: DEFAULT_TIMEZONE
		},
		long: {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			timeZone: DEFAULT_TIMEZONE,
			timeZoneName: undefined
		}
	}
};

const LOCALIZED_ADDITIONAL_FORMATS = {
	de: ADDITIONAL_FORMATS,
	fr: ADDITIONAL_FORMATS,
	[DEFAULT_ENGLISH_LOCALE]: {
		...ADDITIONAL_FORMATS,
		time: {
			...ADDITIONAL_FORMATS.time,
			medium: {
				...ADDITIONAL_FORMATS.time.medium,
				hour: 'numeric'
			},
			long: {
				...ADDITIONAL_FORMATS.time.long,
				hour: 'numeric'
			}
		}
	}
};

const DATE_FORMAT_PARSING_TRANSLATIONS = {
	day: 'DD',
	month: 'MM',
	year: 'YYYY'
};
const DATE_FORMAT_DISPLAY_TRANSLATIONS = {
	en: {
		day: 'DD',
		month: 'MM',
		year: 'YYYY'
	},
	de: {
		day: 'TT',
		month: 'MM',
		year: 'JJJJ'
	},
	fr: {
		day: 'JJ',
		month: 'MM',
		year: 'AAAA'
	}
};

const MESSAGE_FORMATTER_OPTS = {
	formatters: {
		getNumberFormat: memoize(
			(locale, opts) => new Intl.NumberFormat(locale, opts)
		),
		getDateTimeFormat: memoize((locale, opts) => {
			let dateTimeFormatOpts = opts;
			if (!opts) {
				const additionalFormats = LOCALIZED_ADDITIONAL_FORMATS[locale];
				dateTimeFormatOpts = additionalFormats.date.short;
			}
			return new Intl.DateTimeFormat(locale, dateTimeFormatOpts);
		}),
		getPluralRules: memoize((locale, opts) => new Intl.PluralRules(locale, opts))
	}
};

export function getFormattedMessage(messageText, locale, messageParams = {}) {
	const additionalFormats = LOCALIZED_ADDITIONAL_FORMATS[locale];
	const formatResult = messageFormatter(messageText, locale, additionalFormats, MESSAGE_FORMATTER_OPTS)
		.format(messageParams);
	return Array.isArray(formatResult) ? formatResult.join('') : formatResult;
}

export const getDateFormatString = memoizeByFirstArg(
	locale => getDateFormatStringFromIntl(locale, DATE_FORMAT_PARSING_TRANSLATIONS)
);

/**
 * Returns a localized string representing the expected date format like TT.MM.JJJJ for german.
 *
 * @param locale - Locale (optionally with region extension) for which to get the format.
 */
export const getDateFormatDisplayString = memoizeByFirstArg(locale => {
	const language = getLanguageAttribute(locale);
	const translations = DATE_FORMAT_DISPLAY_TRANSLATIONS[language] || DATE_FORMAT_DISPLAY_TRANSLATIONS.en;
	return getDateFormatStringFromIntl(locale, translations);
});

export const getLanguageAttribute = memoizeByFirstArg(locale => {
	if ('Locale' in Intl) {
		const intlLocale = new Intl.Locale(locale);
		return intlLocale.language;
	}
	return locale.substring(0, 2);
});

function getDateFormatStringFromIntl(locale, replacements) {
	const formatObj = new Intl.DateTimeFormat(locale).formatToParts(new Date());
	return formatObj
		.map(obj => replacements[obj.type] || obj.value)
		.join('');
}
