import React from 'react';
import PropTypes from 'prop-types';

import ErrorHint from '../../commons/components/webview/ErrorHint.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {STATUS_FAILED} from '../../commons/constants/StatusMessageConstants.js';
import {translatorPropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Button from '../../ui/components/Button.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import {
	PASSWORD_CHANGE_ERROR_INVALID_PASSWORD,
	PASSWORD_CHANGE_ERROR_OTHER,
	PASSWORD_CHANGE_ERROR_WEAK_PASSWORD,
	PASSWORD_TOO_WEAK
} from '../constants/PasswordStateReducerStates.js';

export default class NewPasswordError extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnRestartWorkflow = this.onRestartWorkflow.bind(this);
	}

	render() {
		const {translator} = this.props;
		const passwordTooWeak = this.isPasswordTooWeak();
		const solutionMessage = passwordTooWeak ? 'PickNewPasswordButtonLabel' : 'RestartWorkflowButtonLabel';

		return (
			<VerticalLayout key='error-message'>
				<StatusMessage status={STATUS_FAILED}>
					<SynFormattedMessage translator={translator} className='new-password--message'
					                     message='SettingNewPasswordFailed' />
				</StatusMessage>
				{this.getErrorDescriptions()}
				<Button autoFocus flat opaque onClick={this.boundOnRestartWorkflow}>
					<SynFormattedMessage message={solutionMessage} translator={translator} />
				</Button>
			</VerticalLayout>
		);
	}

	renderGeneralPasswordChangeErrorDescription() {
		const {translator} = this.props;
		return (
			<ErrorHint translator={translator} hintMessage='UnknownPasswordError'
			           solutionMessage='UnknownPasswordErrorDetails' />
		);
	}

	renderPasswordToWeakErrorDescription() {
		const {translator} = this.props;
		return (
			<ErrorHint translator={translator} hintMessage='PasswordTooWeakErrorHint' />
		);
	}

	renderAuthenticationErrorDescription() {
		const {translator, bailOutUrl, secondFactorAssumed} = this.props;
		const failedErrorHintProperties = {
			translator,
			hintMessage: 'SettingNewPasswordFailedHint'
		};
		if (bailOutUrl !== undefined) {
			failedErrorHintProperties.solutionMessage = 'SettingNewPasswordFailedSolution';
			failedErrorHintProperties.solution = bailOutUrl;
		}
		return (
			<React.Fragment>
				<ErrorHint {...failedErrorHintProperties} />
				{secondFactorAssumed && <ErrorHint translator={translator} hintMessage='InvalidVerificationCodeHint' />}
			</React.Fragment>
		);
	}

	onRestartWorkflow() {
		const {restartWorkflow} = this.props;
		callSafe(restartWorkflow);
	}

	isPasswordTooWeak() {
		const {error} = this.props;
		return error && (
			error === PASSWORD_TOO_WEAK ||
			error === PASSWORD_CHANGE_ERROR_WEAK_PASSWORD
		);
	}

	getErrorDescriptions() {
		const {error = PASSWORD_CHANGE_ERROR_OTHER} = this.props;
		let errorDescriptions;
		switch (error) {
			case PASSWORD_TOO_WEAK:
			case PASSWORD_CHANGE_ERROR_WEAK_PASSWORD:
				errorDescriptions = this.renderPasswordToWeakErrorDescription();
				break;
			case PASSWORD_CHANGE_ERROR_INVALID_PASSWORD:
				errorDescriptions = this.renderAuthenticationErrorDescription();
				break;
			default:
				errorDescriptions = this.renderGeneralPasswordChangeErrorDescription();
				break;
		}
		return errorDescriptions;
	}
}

NewPasswordError.propTypes = {
	translator: translatorPropType,
	bailOutUrl: PropTypes.string,
	secondFactorAssumed: PropTypes.bool,
	restartWorkflow: PropTypes.func,
	error: PropTypes.oneOf([
		PASSWORD_CHANGE_ERROR_WEAK_PASSWORD, PASSWORD_CHANGE_ERROR_INVALID_PASSWORD, PASSWORD_TOO_WEAK,
		PASSWORD_CHANGE_ERROR_OTHER
	])
};
