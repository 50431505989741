import {APPLICATION_PDF} from '../../commons/constants/MimeTypes.js';

const RESOUCES_PATH = 'Bundle > entry > resource';
export const FHIR_NAMESPACE = 'http://hl7.org/fhir';
export const XHTML_NAMESPACE = 'http://www.w3.org/1999/xhtml';
export const COMPOSITION_TEXT_NARRATIVE = `${RESOUCES_PATH} > Composition > section > text > div`;
export const DIAGNOSTIC_REPORT_TEXT_NARRATIVE = `${RESOUCES_PATH} > DiagnosticReport > text > div`;
export const DIAGNOSTIC_REPORT_PRESENTED_FORM =
	`${RESOUCES_PATH} > DiagnosticReport > presentedForm > contentType[value='${APPLICATION_PDF}'] + data`;

export const CDA_NAMESPACE = 'urn:hl7-org:v3';
export const CDA_NON_XML_BODY_PATH = `ClinicalDocument > component > nonXMLBody > text[mediaType='${APPLICATION_PDF}'][representation="B64"]`;
