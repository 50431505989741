import React from 'react';
import PropTypes from 'prop-types';

import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import {combineClassNames} from '../utils/StyleUtils.js';

import '../../../styles/commons/components/ApplicationMainArea.scss';

export default function ApplicationMainArea(props) {
	const {className, modalDialogIsVisible, children} = props;
	const finalClassNames = combineClassNames('application-main-area', className);
	return (
		<VerticalLayout className={finalClassNames} aria-hidden={modalDialogIsVisible ? 'true' : 'false'}>
			{children}
		</VerticalLayout>
	);
}

ApplicationMainArea.propTypes = {
	modalDialogIsVisible: PropTypes.bool,
	className: PropTypes.string
};
