import React from 'react';
import PropTypes from 'prop-types';

import useTranslation from '../../../i18n/hooks/useTranslation.js';
import GeneralMessagesTranslator from '../../../i18n/translators/GeneralTranslator.js';
import Spinner from '../../../ui/components/Spinner.js';
import {ColumnCenterLayout} from '../layout/CenterLayout.js';

export default function LoadingIndicator(props) {
	const {children, label, ...remainingProps} = props;
	let spinnerDescription = useTranslation(
		'LoadingSpinnerDescription', {}, GeneralMessagesTranslator
	);
	if (label) {
		spinnerDescription += ` (${label})`;
	}
	return (
		<ColumnCenterLayout {...remainingProps}>
			<Spinner description={spinnerDescription} />
			{children}
		</ColumnCenterLayout>
	);
}

LoadingIndicator.propTypes = {
	...ColumnCenterLayout.propTypes,
	label: PropTypes.string
};
