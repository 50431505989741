import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

function Ellipsis(props) {
	const sxProps = {display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};
	return <Box component='span' sx={sxProps} {...props} />;
}

Ellipsis.propTypes = {
	children: PropTypes.node.isRequired
};

export default React.memo(Ellipsis);
