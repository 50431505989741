import {styled} from '@mui/material/styles';

import GridListConstants from '../../../constants/GridListConstants.json';

const TILE_WIDTH = GridListConstants['tile-width'];
const TILE_BORDER_RADIUS = GridListConstants['tile-border-radius'];
const TILE_MARGIN = GridListConstants['tile-margin'];
const TILE_SIZE = TILE_WIDTH - (2 * TILE_MARGIN);

export default styled('div', {name: 'UploadGridTile'})({
	width: `${TILE_SIZE}px`,
	height: `${TILE_SIZE}px`,
	margin: `${TILE_MARGIN}px`,
	padding: 0,
	borderRadius: `${TILE_BORDER_RADIUS}px`,
	position: 'relative',
	overflow: 'hidden',
	transform: 'translateZ(0)',
	backgroundColor: 'black',
	cursor: 'pointer'
});
