import Immutable from 'immutable';
import PropTypes from 'prop-types';

import MessageTranslator from '../../i18n/translators/MessageTranslator.js';
import Patient from '../data/aim/Patient.js';
import {isFileEntry} from './FilesCollection.js';

export const immutablePropType = PropTypes.instanceOf(Immutable.Iterable);
export const immutableMapPropType = PropTypes.instanceOf(Immutable.Map);
export const immutableListPropType = PropTypes.instanceOf(Immutable.List);
export const immutableRecordType = PropTypes.instanceOf(Immutable.Record);

export const refPropType = PropTypes.oneOfType([
	PropTypes.func,
	PropTypes.shape({
		current: PropTypes.instanceOf(Element)
	})
]);

export const HTMLFileObjectShape = PropTypes.shape({
	name: PropTypes.string,
	size: PropTypes.number,
	type: PropTypes.string
});

export function FileEntryType(props, propName, componentName) {
	if (!isFileEntry(props[propName])) {
		return new Error(`Invalid prop \`${propName}\` supplied to '${componentName}'. FileEntry expected.`);
	}
}

export const DICOMFileEntryType = PropTypes.shape(
	{
		size: PropTypes.number,
		study: PropTypes.shape({
			description: PropTypes.string,
			modality: PropTypes.string
		}),
		patient: PropTypes.instanceOf(Patient)
	}
);

export const translatorPropType = PropTypes.instanceOf(MessageTranslator);
