import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import {TOUCH_SIZE} from './AnnotationConstants.js';

import '../../../../styles/viewer/components/annotations/DotTouchArea.scss';

const DEFAULT_RADIUS = (TOUCH_SIZE / 2);
export default function DotTouchArea(props) {
	const {point, hidden = true, radius = DEFAULT_RADIUS, className} = props;
	const finalClassNames = combineClassNames(className, 'dot-touch-area', (hidden ? 'hidden' : null));
	return <circle className={finalClassNames} cx={point[0]} cy={point[1]} r={radius} />;
}
DotTouchArea.propTypes = {
	point: PropTypes.instanceOf(Float32Array),
	hidden: PropTypes.bool,
	radius: PropTypes.number,
	className: PropTypes.string
};
