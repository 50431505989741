import React from 'react';

import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import LinkButtonContainer from '../../commons/containers/webview/LinkButtonContainer.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import PasswordResetMessagesTranslator from '../i18n/PasswordResetMessagesTranslator.js';

export default React.memo(PasswordResetSuccess);

function PasswordResetSuccess() {
	return (
		<React.Fragment>
			<VerticalLayout key='centerLayout'>
				<StatusMessage>
					<SynFormattedMessage translator={PasswordResetMessagesTranslator} message='PasswordResetRequestSuccessShort' />
				</StatusMessage>
				<SynFormattedMessage translator={PasswordResetMessagesTranslator} message='PasswordResetRequestSuccessDetails' />
			</VerticalLayout>
			<LinkButtonContainer flat opaque path=''>
				<SynFormattedMessage translator={PasswordResetMessagesTranslator} message='BackToWebAppButtonLabel' />
			</LinkButtonContainer>
		</React.Fragment>
	);
}
