import React from 'react';
import {glMatrix} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import generateUniqueKey from '../../../commons/utils/generateUniqueKey.js';
import {
	getLineAroundContainerCenter,
	toImagePosition
} from '../../utils/AnnotationUtils.js';
import createAnnotation from './createAnnotation.js';
import Rectangle from './Rectangle.js';

import '../../../../styles/viewer/components/annotations/ShutterAnnotation.scss';

class ShutterAnnotationRenderer extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.shutterId = `shutter-${generateUniqueKey()}`;
	}

	render() {
		const {AnnotationRoot, annotationProperties, transformationMatrix, isPrintPreview} = this.props;
		const points = annotationProperties.get('points');

		return (
			<AnnotationRoot className='shutter-annotation'>
				<g>
					<defs>
						<mask id={this.shutterId}>
							<rect x='-50%' y='-50%' width='100%' height='100%' fill='white' />
							<Rectangle isPrintPreview={isPrintPreview} points={points}
							           transformationMatrix={transformationMatrix}
							           className='shutter-annotation--rectangle' />
						</mask>
					</defs>
					<g>
						<rect x='-50%' y='-50%' width='100%' height='100%' fill='black' style={{mask: `url(#${this.shutterId})`}} />
					</g>
				</g>
			</AnnotationRoot>
		);
	}
}

ShutterAnnotationRenderer.propTypes = {
	AnnotationRoot: PropTypes.elementType,
	annotationProperties: immutableMapPropType,
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	isPrintPreview: PropTypes.bool
};

function isShutterSupported(viewerItem) {
	return viewerItem && true;
}

function getDefaultPropertiesForShutter(props) {
	return Immutable.Map({
		points: getLineAroundContainerCenter(props).map(point => toImagePosition(props, point))
	});
}

export default createAnnotation(
	isShutterSupported, getDefaultPropertiesForShutter, ShutterAnnotationRenderer
);
