// NOTE: As defined in DICOM Standard Part 03 - IOD - C.8.5.5.1.2 Region Data Type
export const REGION_DATA_TYPE = {
	NONE: 0,
	TISSUE: 1,
	COLOR_FLOW: 2,
	PW_SP_DOPPLER: 3,
	CW_SP_DOPPLER: 4,
	DOPPLER_MEAN_TRACE: 5,
	DOPPLER_MODE_TRACE: 6,
	DOPPLER_MAX_TRACE: 7,
	VOLUME_TRACE: 8,
	ECG_TRACE: 10,
	PULSE_TRACE: 11,
	PHONOCARDIOGRAM_TRACE: 12,
	GRAY_BAR: 13,
	COLOR_BAR: 14,
	INTEGRATED_BACKSCATTER: 15,
	AREA_TRACE: 16,
	DAREA_DT: 17,
	OTHER_PHYS_INPUT: 18
};

// NOTE: As defined in DICOM Standard Part 03 - IOD -
// C.8.5.5.1.15 Physical Units X Direction and Physical Units Y Direction
export const PHYSICAL_UNITS = {
	NONE: 0,
	PERCENT: 1,
	DB: 2,
	CM: 3,
	SECONDS: 4,
	HERTZ: 5,
	DB_PER_SECONDS: 6,
	CM_PER_SECOND: 7,
	SQUARE_CM: 8,
	SQUARE_CM_PER_SECOND: 9,
	CUBIC_CM: 10,
	CUBIC_CM_PER_SECOND: 11,
	DEGREES: 12
};

export const PHYSICAL_UNIT_TEXT = {
	[PHYSICAL_UNITS.NONE]: '',
	[PHYSICAL_UNITS.PERCENT]: '%',
	[PHYSICAL_UNITS.DB]: 'dB',
	[PHYSICAL_UNITS.CM]: 'cm',
	[PHYSICAL_UNITS.SECONDS]: 's',
	[PHYSICAL_UNITS.HERTZ]: 'Hz',
	[PHYSICAL_UNITS.DB_PER_SECONDS]: 'dB/s',
	[PHYSICAL_UNITS.CM_PER_SECOND]: 'cm/s',
	[PHYSICAL_UNITS.SQUARE_CM]: 'cm\xb2',
	[PHYSICAL_UNITS.SQUARE_CM_PER_SECOND]: 'cm\xb2/s',
	[PHYSICAL_UNITS.CUBIC_CM]: 'cm\xb3',
	[PHYSICAL_UNITS.CUBIC_CM_PER_SECOND]: 'cm\xb3/s',
	[PHYSICAL_UNITS.DEGREES]: '°'
};

// NOTE: As defined in DICOM Standard Part 03 - IOD - C.8.5.5.1.1 Region Spatial Format
export const REGION_SPATIAL_FORMAT = {
	NONE: 0,
	TWO_D: 1,
	M_MODE: 2,
	SPECTRAL: 3,
	WAVE_FORM: 4,
	GRAPHICS: 5
};

// NOTE: As defined in DICOM Standard Part 03 - IOD - C.8.5.5.1.3 Region Flags
export const REGION_FLAGS = {
	PRIORITY_HIGH: 0,
	PRIORITY_LOW: 0,
	DOPPLER_SCALE_TYPE_VELOCITY: 0,
	DOPPLER_SCALE_TYPE_FREQUENCY: 1,
	SCROLLING_REGION_UNSPECIFIED: 0,
	SCROLLING_REGION_SCROLLING: 1,
	SCROLLING_REGION_SWEEPING: 2,
	SCROLLING_REGION_SWEEPING_THEN_SCROLLING: 3
};
