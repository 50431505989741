import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../webview/commons/bricks/DeviceInfo.js';
import DropZone, {dropZonePropTypes} from '../../webview/commons/components/DropZone.js';
import FileSelectionForm from '../../webview/commons/components/form/FileSelectionForm.js';
import {ALLOWED_UPLOAD_FILE_EXTENSIONS} from '../../webview/commons/constants/SynSettingsConstants.js';
import {useMemoFactory} from '../../webview/commons/utils/customHooks';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import useTranslation from '../../webview/i18n/hooks/useTranslation.js';
import CameraIcon from '../../webview/ui/components/icons/CameraIcon.js';
import {UploadRoundedIcon} from '../../webview/ui/components/icons/UploadIcon.js';
import VerticalLayout from '../../webview/ui/components/layout/VerticalLayout.js';
import LinkLikeButton from '../../webview/ui/components/LinkLikeButton.js';
import {CaptionText, H5} from '../../webview/ui/components/typography/Typography.js';
import WebUploadFilesButton from '../../webview/ui/components/WebUploadFilesButton.js';
import {COLOR_PRIMARY} from '../../webview/ui/constants/SynMUIOptions.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';
import UploadFilesCollector from '../bricks/UploadFilesCollector';
import FileBrowserTools from './FileBrowserTools.js';
import FilesAddingSummaryToast from './FilesAddingSummaryToast.js';

import '../../styles/webupload/components/UploadArea.scss';

const ACCEPT_SPECIFIER = ALLOWED_UPLOAD_FILE_EXTENSIONS.map(extension => `.${extension}`).join(', ');
const DROP_ZONE_CONTENT = <SynFormattedMessage element={H5} message='DropZoneHint' align='center' />;
const CAMERA_BUTTON = <UploadFilesButton label='ChooseCameraOpen' sx={{mt: 1}} iconComponent={CameraIcon} />;

export default function UploadArea(props) {
	const {dropZoneProps: {handleDragLeave, dropZoneActive}} = props;

	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const isEmpty = useBrick(UploadFilesCollection, selectIsEmpty);
	const collectFromFileList = useBrick(UploadFilesCollector, selectCollectFunction);
	const chooseFilesButtonLabel = isEmpty ? 'ChooseUploadFiles' : 'ChooseMoreUploadFiles';
	const chooseDirectoryButtonLabel = useTranslation('ChooseUploadFolderPatientCD');
	const fileButton = useMemoFactory(createFileButton, chooseFilesButtonLabel);
	const directoryButton = useMemoFactory(createDirectoryButton, chooseDirectoryButtonLabel);
	const handleAddFiles = useMemoFactory(createHandleAddFiles, collectFromFileList);
	const handleFileList = useCallback(fileList => {
		handleAddFiles(fileList);
	}, [handleAddFiles]);
	const cameraButton = isMobileDevice && CAMERA_BUTTON;
	return (
		<React.Fragment>
			<FileSelectionForm className='upload--files-selection--form' handleFileList={handleFileList}
			                   fileButton={fileButton} directoryButton={directoryButton} cameraButton={cameraButton}
			                   enableDirectory={!isMobileDevice} accept={ACCEPT_SPECIFIER} multi>
				{!isMobileDevice && <FileBrowserTools />}
			</FileSelectionForm>
			<FilesAddingSummaryToast />
			<DropZone entered={dropZoneActive} onLeave={handleDragLeave} content={DROP_ZONE_CONTENT}
			          handleDroppedFiles={handleFileList} />
		</React.Fragment>
	);
}

UploadArea.propTypes = {
	dropZoneProps: dropZonePropTypes.dropZoneProps
};

function createFileButton(label) {
	return (
		<UploadFilesButton label={label}>
			<CaptionText>
				{`(${ACCEPT_SPECIFIER})`}
			</CaptionText>
		</UploadFilesButton>
	);
}

function createDirectoryButton(label) {
	return (
		<LinkLikeButton flat labelLeftAligned className='upload--files-selection--form--directory-button'>
			{label}
		</LinkLikeButton>
	);
}

function createHandleAddFiles(addFileList) {
	return fileList => {
		if (fileList.length > 0) {
			addFileList(fileList);
		}
	};
}

function selectIsEmpty(uploadFilesCollection) {
	return !uploadFilesCollection.hasPreparedFiles();
}

function selectCollectFunction(filesCollector) {
	return filesCollector.collectFromFileList;
}

function UploadFilesButton(props) {
	const {label, iconComponent: Icon, children, ...remainingProps} = props;
	return (
		<WebUploadFilesButton className='upload--files-selection--form--files-button' color={COLOR_PRIMARY}
		                      startIcon={<Icon style={{fontSize: 40}} />} {...remainingProps}>
			<VerticalLayout>
				<SynFormattedMessage message={label} />
				{children}
			</VerticalLayout>
		</WebUploadFilesButton>
	);
}

UploadFilesButton.propTypes = {
	label: PropTypes.string,
	iconComponent: PropTypes.elementType
};

UploadFilesButton.defaultProps = {
	iconComponent: UploadRoundedIcon
};
