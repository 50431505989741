import {call, cancelled, delay, put, race, select, take} from 'redux-saga/effects';

import UnauthorizedError from '../../commons/api/UnauthorizedError.js';
import {keepAlive} from '../api/SessionApi.js';
import {END_SESSION} from '../constants/SessionActionTypes.js';
import {logout} from '../flux/actions/SessionActions.js';
import {selectIsLoggedIn} from '../flux/selectors/SessionSelectors.js';
import createSessionTask from './createSessionTask.js';

const TO_MS = 1000;
const DEFAULT_SESSION_TIMEOUT_SECONDS = 600; // 10 minutes
const MINIMUM_SESSION_TIMEOUT_SECONDS = 30;
const TIMEOUT_REDUCTION_FACTOR = 3;

export function* keepAliveTaskImpl(beginAction) {
	const {timeout = DEFAULT_SESSION_TIMEOUT_SECONDS} = beginAction.payload;
	const keepAliveTimeout = Math.max(timeout, MINIMUM_SESSION_TIMEOUT_SECONDS) * TO_MS / TIMEOUT_REDUCTION_FACTOR;
	let wasUnauthorized = false;
	while (!wasUnauthorized && !(yield cancelled())) {
		const raceResult = yield race({
			endSession: take(END_SESSION),
			timeout: delay(keepAliveTimeout, true)
		});
		if (raceResult.timeout && !(yield cancelled()) && (yield select(selectIsLoggedIn))) {
			try {
				yield call(keepAlive);
			} catch (error) {
				if (error instanceof UnauthorizedError) {
					yield put(logout(error));
					wasUnauthorized = true;
				}
			}
		}
	}
}

export default createSessionTask(keepAliveTaskImpl);
