import Immutable from 'immutable';
import {createSelector} from 'reselect';

import createScopedSelectors from '../../../commons/selectors/createScopedSelectors.js';
import {booleanValue} from '../../../commons/utils/BooleanUtils.js';
import {selectAllSeries, selectFiles} from '../../../patient-details/flux/selectors/PatientDetailsSelectors.js';
import {querySelector} from '../../../router/flux/selectors/LocationSelectors.js';
import {uiDeviceOrientation, uiIsMobileDevice} from '../../../ui/flux/UISelectors.js';
import {
	DEFAULT_LAYOUT_MODE,
	MAX_LAYOUT_MODE,
	MAX_MOBILE_LAYOUT_MODE,
	VIEWER_MATRIX_LAYOUT_1X1,
	VIEWER_MATRIX_LAYOUT_1X2,
	VIEWER_MATRIX_LAYOUT_2X1,
	VIEWER_MATRIX_LAYOUT_2X2,
	VIEWER_QUERY_PROPERTY_SHOW_VIEWER,
	VIEWER_STATE_ACTIVE_VIEWER_ID,
	VIEWER_STATE_FULLSCREEN_VIEWER_ID,
	VIEWER_STATE_LAYOUT_MODE,
	VIEWER_STATE_META_INFO,
	VIEWER_STATE_PROPERTIES
} from '../../constants/ViewerConstants.js';
import {
	getRestrictedLayoutForMode,
	validateViewerId,
	viewerIdToViewerIndex,
	viewerIndexToViewerId
} from '../../utils/ViewerUtils.js';

const MAX_NUMBER_OF_VIEWERS = 4;
const LAYOUT_TO_IDSET = createLayoutToIdSetMap();

function createLayoutToIdSetMap() {
	const size2Set = createIdSet(2);
	return {
		[VIEWER_MATRIX_LAYOUT_1X1]: createIdSet(1),
		[VIEWER_MATRIX_LAYOUT_2X1]: size2Set,
		[VIEWER_MATRIX_LAYOUT_1X2]: size2Set,
		[VIEWER_MATRIX_LAYOUT_2X2]: createIdSet(MAX_NUMBER_OF_VIEWERS)
	};
}

function createIdSet(nrViewers) {
	const ids = Immutable.Set().asMutable();
	for (let index = 0; index < nrViewers; ++index) {
		ids.add(`${index}`);
	}
	return ids.asImmutable();
}

export const selectViewerState = state => (state.viewers || Immutable.Map());

export const getViewerLayoutMode = createSelector(
	state => selectViewerState(state).get(VIEWER_STATE_LAYOUT_MODE, DEFAULT_LAYOUT_MODE),
	uiIsMobileDevice,
	(layoutMode, isMobile) => (
		isMobile ? Math.min(MAX_MOBILE_LAYOUT_MODE, layoutMode) : Math.min(MAX_LAYOUT_MODE, layoutMode)
	)
);

export const selectIsSingleViewerLayout = state => getViewerLayoutMode(state) === 0;

export const selectAllProperties =
		createSelector(selectViewerState, state => state.get(VIEWER_STATE_PROPERTIES, Immutable.Map()));
export const selectAllMetaInfos =
		createSelector(selectViewerState, state => state.get(VIEWER_STATE_META_INFO, Immutable.Map()));
export const selectViewerIds =
		createSelector(getViewerLayoutMode, layoutMode => LAYOUT_TO_IDSET[layoutMode]);

export const selectViewerProperties = viewerId => state => selectAllProperties(state).get(viewerId, Immutable.Map());
export const selectSingleViewerProperty =
		(viewerId, propertyName, defaultValue) => (
			state => selectViewerProperties(viewerId)(state).get(propertyName, defaultValue)
		);

export function createAllViewerScopedSelectors(selectorCreator) {
	return createScopedSelectors(selectViewerIds, selectorCreator);
}

function createSelectMetaInfoProperty(metaInfoPropertyName) {
	return createAllViewerScopedSelectors(
		viewerId => createSelector(
			createSelectSingleViewerValue(selectAllMetaInfos, viewerId),
			metaInfo => (metaInfo && metaInfo.has(metaInfoPropertyName, null)
				? metaInfo.get(metaInfoPropertyName)
				: null)
		)
	);
}

export const selectAllItemIds = createSelectMetaInfoProperty('id');
export const selectAllTypes = createSelectMetaInfoProperty('type');

export const selectAllItemMetaInfos = createAllViewerScopedSelectors(
	viewerId => createSelector(
		createSelectSingleViewerValue(selectAllTypes, viewerId),
		createSelectSingleViewerValue(selectAllItemIds, viewerId),
		selectAllSeries,
		selectFiles,
		(type, itemId, series, files) => (type === 'series' ? series.get(itemId, null) : files.get(itemId, null))
	)
);

export function createSelectSingleViewerValue(allViewerValuesSelector, viewerId) {
	const validatedViewerId = validateViewerId(viewerId);
	return state => allViewerValuesSelector(state).get(validatedViewerId);
}

export const isViewerShown = createSelector(
	querySelector,
	viewerState => booleanValue(viewerState.get(VIEWER_QUERY_PROPERTY_SHOW_VIEWER, false))
);

export const getFullScreenViewerId = createSelector(
	selectViewerState, viewerState => viewerState.get(VIEWER_STATE_FULLSCREEN_VIEWER_ID, null)
);

export const isFullScreenViewer = viewerId => state => getFullScreenViewerId(state) === viewerId;

export const getViewerLayout = createSelector(
	getViewerLayoutMode, uiIsMobileDevice, uiDeviceOrientation,
	(layoutMode, isMobile, deviceOrientation) => getRestrictedLayoutForMode(layoutMode, isMobile, deviceOrientation)
);

export const getDisplayedItemIDs = createSelector(
	selectAllMetaInfos,
	metaInfos => metaInfos.map(metaInfo => (metaInfo && metaInfo.get('id')) || null)
);

export const hasDisplayedItem = createSelector(
	getDisplayedItemIDs,
	displayedItems => displayedItems.some(item => item !== null)
);

export const getActiveViewerId = createSelector(
	selectAllProperties,
	state => selectViewerState(state).get(VIEWER_STATE_ACTIVE_VIEWER_ID, '0'),
	getViewerLayout,
	(viewers, activeViewer, layout) => {
		let finalActiveViewer = activeViewer;
		if (viewers !== null) {
			finalActiveViewer = viewerIndexToViewerId(Math.min(
				viewerIdToViewerIndex(activeViewer),
				(layout.get('rows') * layout.get('columns')) - 1
			));
		}
		return finalActiveViewer;
	}
);

export const selectIsViewerActive = viewerId => state => getActiveViewerId(state) === viewerId;

export const getActiveViewerMetaInfo = createSelector(
	selectAllMetaInfos, getActiveViewerId,
	(allViewerMetaInfos, activeViewerId) => allViewerMetaInfos.get(activeViewerId) || Immutable.Map()
);

export const getActiveViewerProperties = createSelector(
	selectAllProperties, getActiveViewerId,
	(allViewerProperties, activeViewerId) => allViewerProperties.get(activeViewerId) || Immutable.Map()
);

export function createSelectLocationState(viewerId) {
	const selectSingleViewerProperties = createSelectSingleViewerValue(selectAllProperties, viewerId);
	const selectViewerMetaInfos = createSelectSingleViewerValue(selectAllMetaInfos, viewerId);
	return createSelector(
		selectSingleViewerProperties, selectViewerMetaInfos,
		(viewerProperties, viewerMetaInfos) => {
			let finalProperties = null;
			if (viewerProperties) {
				finalProperties = viewerProperties;
			}
			if (viewerMetaInfos) {
				finalProperties = viewerProperties ? viewerProperties.merge(viewerMetaInfos) : viewerMetaInfos;
			}
			return finalProperties;
		}
	);
}

export const anyPrintableViewerVisible = createSelector(
	selectAllProperties, selectViewerIds,
	(allProperties, viewerIds) => (
		allProperties.some((viewerProperties, id) => (
			viewerIds.includes(id) && viewerProperties.get('isPrintable', false))
		))
);

export const selectNonEmptyViewerIds = createSelector(
	selectAllMetaInfos,
	viewerMetaInfos => viewerMetaInfos
		.filter(metaInfo => metaInfo && !metaInfo.isEmpty() && metaInfo.get('id') !== null)
		.keySeq()
);
