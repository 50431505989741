import React from 'react';
import PropTypes from 'prop-types';

import ErrorGuard from '../../commons/components/ErrorGuard.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {getLoadError} from '../utils/ViewerItemUtils.js';
import ViewerItemLoadErrorMessage from './ViewerItemLoadErrorMessage.js';

export default function ViewerItemLoadErrorGuard(props) {
	const {viewerItem, itemType, children} = props;
	const errorNode = useMemoFactory(getErrorNode, viewerItem, itemType);
	return (
		<ErrorGuard errorNode={errorNode} error={getLoadError(viewerItem) !== null}>
			{children}
		</ErrorGuard>
	);
}

ViewerItemLoadErrorGuard.propTypes = {
	itemType: PropTypes.string,
	viewerItem: PropTypes.object
};

function getErrorNode(viewerItem, itemType) {
	return <ViewerItemLoadErrorMessage viewerItem={viewerItem} itemType={itemType} />;
}
