import React from 'react';
import PropTypes from 'prop-types';

import {stopEventPropagation} from '../utils/DOMEventUtils.js';
import {memoizeLast, noop, synchronizedWithAnimationFrame} from '../utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';
import {combineClassNames} from '../utils/StyleUtils.js';

import '../../../styles/commons/components/ScrollView.scss';

// TODO: Remove requirement for property width and height: auto expand by default unless width and height are specified.
export default class ScrollView extends React.PureComponent {
	constructor(props) {
		super(props);
		this.emitScrollPosition = memoizeLast(this.emitScrollPosition.bind(this));
		this.memoizedSyncScrollPos = memoizeLast(ScrollView.#doSyncScrollPos);
		this.scrollInAnimationFrame = synchronizedWithAnimationFrame(this.onScroll.bind(this), noop);
		this.node = React.createRef();
	}

	render() {
		const {element: Element, width, height, style, className} = this.props;
		const remainingProps = cloneWithoutProperties(this.props,
			'width', 'height', 'element', 'style', 'className', 'onScroll', 'onViewportChange',
			'scrollTop', 'scrollLeft'
		);

		const finalClassNames = combineClassNames('scroll-view', className);
		const finalStyle = ScrollView.#buildStyle(width, height, style);
		return (
			<Element className={finalClassNames} ref={this.node} style={finalStyle}
					onScroll={this.scrollInAnimationFrame} onWheel={stopEventPropagation} {...remainingProps} />
		);
	}

	componentDidMount() {
		this.scrollInAnimationFrame();
		this.#syncScrollPos();
	}

	componentWillUnmount() {
		this.scrollInAnimationFrame.stop();
	}

	componentDidUpdate() {
		this.#syncScrollPos();
	}

	#syncScrollPos() {
		const {scrollLeft, scrollTop} = this.props;
		this.memoizedSyncScrollPos(this.node.current, scrollTop, scrollLeft);
	}

	onScroll() {
		if (this.node.current !== null) {
			const {scrollTop, scrollLeft} = this.node.current;
			const {onScroll, onViewportChange} = this.props;
			this.emitScrollPosition(onScroll, onViewportChange, scrollLeft, scrollTop);
		}
	}

	emitScrollPosition(onScroll, onViewportChange, scrollLeft, scrollTop) {
		if (onScroll) {
			onScroll({scrollLeft, scrollTop});
		}
		if (onViewportChange) {
			const size = this.getSize();
			onViewportChange({
				top: scrollTop,
				left: scrollLeft,
				bottom: scrollTop + size.height - 1,
				right: scrollLeft + size.width - 1,
				width: size.width,
				height: size.height
			});
		}
	}

	getSize() {
		let {width, height} = this.props;
		if (width === undefined || height === undefined) {
			const clientRect = this.node.current.getBoundingClientRect();
			const {width: clientWidth, heigth: clientHeigth} = clientRect;
			width = clientWidth;
			height = clientHeigth;
		}
		return {width, height};
	}

	static #buildStyle(width, height, style) {
		let finalStyle = style;
		if (width || width === 0) {
			finalStyle = {
				width: `${width}px`
			};
		}
		if (height || height === 0) {
			if (!finalStyle) {
				finalStyle = {};
			}
			finalStyle.height = `${height}px`;
		}
		return finalStyle;
	}

	static #doSyncScrollPos(node, scrollTop, scrollLeft) {
		if (node && (typeof scrollTop) === 'number' && (typeof scrollLeft) === 'number') {
			if (node.scrollTop !== scrollTop) {
				node.scrollTop = scrollTop;
			}
			if (node.scrollLeft !== scrollLeft) {
				node.scrollLeft = scrollLeft;
			}
		}
	}
}

ScrollView.propTypes = {
	element: PropTypes.elementType,
	onViewportChange: PropTypes.func,
	onScroll: PropTypes.func,
	width: PropTypes.number,
	height: PropTypes.number,
	className: PropTypes.string,
	style: PropTypes.object,
	scrollLeft: PropTypes.number,
	scrollTop: PropTypes.number
};

ScrollView.defaultProps = {
	element: 'div'
};
