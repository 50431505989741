import {createSelector} from 'reselect';

import {buildDicomImage} from '../../utils/DicomSeriesViewerUtils.js';
import {getDicomDump} from '../../utils/ViewerItemUtils.js';
import {createDicomImageMetadataSelector} from './MetadataSelectors.js';
import {
	selectActiveDicomImageViewerItem,
	selectImageDataViewerItem,
	selectImageViewerItem
} from './ViewerItemsSelectors.js';
import {selectAllTypes} from './ViewerSelectors.js';

export const selectActiveViewerDicomDump = createSelector(selectActiveDicomImageViewerItem, getDicomDump);

export function createDicomImageSelector(dicomViewerState) {
	const {currentImageId, currentFrameId, imageRelativeFrameOffset} = dicomViewerState;

	const selectMetadata = createDicomImageMetadataSelector(currentImageId, imageRelativeFrameOffset);
	return createSelector(
		selectImageViewerItem(currentImageId, null),
		selectImageDataViewerItem(currentFrameId, null),
		selectMetadata,
		buildDicomImage
	);
}

export const selectAllDicomSeriesViewerIDs = createSelector(
	selectAllTypes,
	allViewerTypes => allViewerTypes
		.filter(type => type === 'series')
		.keySeq()
);

