import React, {useState} from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../webview/commons/bricks/DeviceInfo.js';
import {useDropZone} from '../../webview/commons/components/DropZone.js';
import {useEffectEasily, useMemoFactory} from '../../webview/commons/utils/customHooks';
import VerticalLayout from '../../webview/ui/components/layout/VerticalLayout.js';
import Paper from '../../webview/ui/components/Paper.js';
import UploadController from '../bricks/UploadController.js';
import ProcessingOverlay from './ProcessingOverlay.js';
import UploadWizardSteps from './UploadWizardSteps.js';
import WebUploadStepperFooter from './WebUploadStepperFooter.js';
import WebUploadStepperInfo from './WebUploadStepperInfo.js';

const DELAY_PROGRESSBAR_UNMOUNT = 1500;

export default function UploadWizard(props) {
	const {stepsComponent: StepsComponent, infoComponent: InfoComponent, footerComponent: FooterComponent} = props;
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const controllerState = useBrick(UploadController, controller => controller.getState());
	const [uploadState, setUploadState] = useState(controllerState);
	const {dropZoneTriggerProps, dropZoneProps} = useDropZone();
	useEffectEasily(waitForUploadFinish, uploadState, controllerState, setUploadState);
	const sxProps = useMemoFactory(getSxProps, isMobileDevice);
	return (
		<Paper component={VerticalLayout} element='main' sx={sxProps} notRounded {...dropZoneTriggerProps}>
			<InfoComponent />
			<StepsComponent controllerState={uploadState} dropZoneProps={dropZoneProps} />
			<FooterComponent />
			<ProcessingOverlay />
		</Paper>
	);
}

UploadWizard.propTypes = {
	stepsComponent: PropTypes.elementType,
	infoComponent: PropTypes.elementType,
	footerComponent: PropTypes.elementType
};

UploadWizard.defaultProps = {
	stepsComponent: UploadWizardSteps,
	infoComponent: WebUploadStepperInfo,
	footerComponent: WebUploadStepperFooter
};

function getSxProps(isMobileDevice) {
	const styles = {
		justify: 'start',
		paddingX: isMobileDevice ? 0 : 2,
		paddingBottom: 2,
		position: 'relative'
	};
	if (isMobileDevice) {
		styles['& > *'] = {
			padding: '8px 16px'
		};
	}
	return styles;
}

function waitForUploadFinish(uploadState, newState, setUploadState) {
	if (newState !== uploadState && newState === UploadController.State.FINISHED) {
		window.setTimeout(() => setUploadState(newState), DELAY_PROGRESSBAR_UNMOUNT);
	} else if (newState !== uploadState) {
		setUploadState(newState);
	}
}
