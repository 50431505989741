import {nDistinctChars} from '../../utils/StringUtils.js';
import {createSingleFieldValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';

export default function minDistinctCharsValidator(minDistinctLength, errorMessage = 'ToFewDistinctCharacters', translator = ValidatorsMessagesTranslator) {
	return createSingleFieldValidatorFactory(
		(locale, translatedFieldLabel, fieldValue) => fieldValue !== null && fieldValue !== undefined && nDistinctChars((`${fieldValue}`).trim()) >= minDistinctLength ||
		translator.getFormattedMessage(errorMessage, locale, {
			minDistinctLength,
			inputFieldLabel: translatedFieldLabel
		})
	);
}
