import Immutable from 'immutable';

import createDownloadURLFactory from '../../commons/api/createDownloadUrlFactory.js';
import get from '../../commons/api/operations/get.js';
import getBlob from '../../commons/api/operations/getBlob.js';
import getSynAdvancedImage from '../../commons/api/operations/getSynAdvancedImage.js';
import search from '../../commons/api/operations/search.js';
import {SYN_ADVANCED_PNG} from '../../commons/constants/MimeTypes.js';
import DicomDump from '../../commons/data/aim/dicom/DicomDump.js';
import {getNumberOfFrames, isEmbeddedVideo} from '../../commons/data/aim/DicomImageHelpers.js';
import {getImageScalingRequestParameters} from './pixelDataRequestParamHelpers.js';

const IMAGE_BASE_URL = '/image';

function getImageUrl(imageId) {
	return `${IMAGE_BASE_URL}/${imageId}`;
}

export const getImageDownloadURL = createDownloadURLFactory(IMAGE_BASE_URL);

export function getDicomImagesForSeries(seriesID) {
	const searchParams = {
		params: {dicom_series_fk: seriesID}
	};
	return search(IMAGE_BASE_URL, searchParams)
		.then(fixNumberOfFramesForVideos);
}

export function getDicomDump(imageID) {
	return get(getImageUrl(imageID)).then(rawDump => new DicomDump(Immutable.fromJS(rawDump)));
}

export function getDicomImageAsSynAdvancedImage(imageId, frameNumber = null, useLosslessCompression = undefined) {
	const mimeTypeOverride = useLosslessCompression ? SYN_ADVANCED_PNG : undefined;
	return getSynAdvancedImage(getImageUrl(imageId), {
		info: 0,
		...(frameNumber && {frame_number: frameNumber}),
		...getImageScalingRequestParameters()
	}, mimeTypeOverride);
}

export function getEncapsulatedPdf(imageId) {
	return getBlob(getEncapsulatedPdfDownloadUrl(imageId));
}

export function getMarkup(imageId, markup = 'html') {
	return getBlob(getImageDownloadURL(imageId, 'inline', markup));
}

export function getEncapsulatedPdfDownloadUrl(imageID) {
	return getImageDownloadURL(imageID, 'inline', 'pdf');
}

function fixNumberOfFramesForVideos(dicomSeriesSearchResult) {
	return dicomSeriesSearchResult.map(dicomImage => {
		const needsFixing = getNumberOfFrames(dicomImage) > 1 && isEmbeddedVideo(dicomImage);
		if (needsFixing) {
			return dicomImage.set('number_of_frames', 1);
		}
		return dicomImage;
	});
}
