import React from 'react';
import PropTypes from 'prop-types';

import {preventEventDefault} from '../utils/DOMEventUtils.js';
import {withForwardRef} from '../utils/ReactUtils.js';
import {combineClassNames} from '../utils/StyleUtils.js';

import '../../../styles/commons/components/Link.scss';

class Link extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundOnClick = this.onClick.bind(this);
	}

	render() {
		const {
			className, title, role, children, href, tabIndex, id, 'aria-disabled': ariaDisabled, onBlur, onDragLeave,
			onFocus, onKeyDown, onKeyUp, onMouseDown, onMouseLeave, onMouseUp, onTouchEnd, onTouchMove, onTouchStart,
			forwardedRef
		} = this.props;

		return (
			<a id={id} href={href} title={title} className={combineClassNames('link', className)} role={role} tabIndex={tabIndex}
			   aria-disabled={ariaDisabled} onClick={this.boundOnClick} onBlur={onBlur} onDragLeave={onDragLeave}
			   onFocus={onFocus} onKeyDown={onKeyDown} onKeyUp={onKeyUp} onMouseDown={onMouseDown}
			   onMouseLeave={onMouseLeave} onMouseUp={onMouseUp} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove}
			   onTouchStart={onTouchStart} ref={forwardedRef}>
				{children}
			</a>
		);
	}

	onClick(event) {
		preventEventDefault(event);
		const {onClick, onTap, href} = this.props;
		const handler = onClick || onTap;
		if (handler) {
			handler(event);
		} else if (href) {
			window.location.href = href;
		}
	}
}

Link.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	onTap: PropTypes.func,
	onBlur: PropTypes.func,
	onDragLeave: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	onKeyUp: PropTypes.func,
	onMouseDown: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onMouseUp: PropTypes.func,
	onTouchEnd: PropTypes.func,
	onTouchMove: PropTypes.func,
	onTouchStart: PropTypes.func,
	href: PropTypes.string,
	role: PropTypes.string,
	tabIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	'aria-disabled': PropTypes.bool,
	title: PropTypes.string,
	children: PropTypes.node.isRequired,
	id: PropTypes.string,
	forwardedRef: withForwardRef.PropTypes.Ref
};

export default withForwardRef(Link, 'forwardedRef');
