import React from 'react';
import PropTypes from 'prop-types';

import {noop} from '../../commons/utils/FunctionUtils.js';
import CheckBoxBlankIcon from '../../ui/components/icons/CheckboxBlankIcon.js';
import CheckBoxCheckedIcon from '../../ui/components/icons/CheckboxCheckedIcon.js';
import {getAnnotationLabel} from './annotations.js';
import ViewerToolsMenuEntry from './ViewerToolsMenuEntry.js';

export default class EnablePointSyncToolMenuEntry extends React.Component {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	render() {
		const {isActive} = this.props;
		const AnnotationLabel = getAnnotationLabel('syncpoint');
		const AnnotationIcon = isActive ? CheckBoxCheckedIcon : CheckBoxBlankIcon;

		return (
			<ViewerToolsMenuEntry id={`point-sync${isActive ? '-active' : ''}`}
												onClick={this.onClick}
												metricsKey='PointSyncTool'
												label={AnnotationLabel}
												icon={AnnotationIcon} />
		);
	}

	onClick() {
		const {isActive, onActivate, onDeactivate} = this.props;
		isActive ? onDeactivate() : onActivate();
	}
}

EnablePointSyncToolMenuEntry.propTypes = {
	onActivate: PropTypes.func,
	onDeactivate: PropTypes.func,
	isActive: PropTypes.bool
};

EnablePointSyncToolMenuEntry.defaultProps = {
	onActivate: noop,
	onDeactivate: noop,
	isActive: false
};
