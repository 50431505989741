import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import {pushLocation, replaceLocation} from '../../router/flux/LocationActions.js';
import {getCurrentPage, getCurrentRouteVariables} from '../../router/flux/selectors/RoutingSelectors.js';
import {uiDeviceSize, uiSidebarModeElevatedSelector} from '../../ui/flux/UISelectors.js';
import {isCacheValid} from '../selectors/CacheSelectors.js';
import {bindActions} from '../utils/FluxUtils.js';

export default connect(
	createStructuredSelector({
		isCacheValid,
		sidebarElevated: uiSidebarModeElevatedSelector,
		deviceSize: uiDeviceSize,
		urlVariables: getCurrentRouteVariables,
		Component: getCurrentPage
	}),
	bindActions({
		pushLocation,
		replaceLocation
	})
)(CurrentRouteRenderer);

function CurrentRouteRenderer(props) {
	const {
		Component, deviceSize, urlVariables, sidebarElevated,
		pushLocation: pushLocationProp, 
		replaceLocation: replaceLocationProp
	} = props;
	return (<Component deviceSize={deviceSize}
		urlVariables={urlVariables}
		pushLocation={pushLocationProp}
		replaceLocation={replaceLocationProp}
		sidebarElevated={sidebarElevated} />);
}

CurrentRouteRenderer.propTypes = {
	Component: PropTypes.elementType,
	deviceSize: PropTypes.string, 
	urlVariables: PropTypes.object, 
	pushLocation: PropTypes.func, 
	replaceLocation: PropTypes.func, 
	sidebarElevated: PropTypes.bool
};
