import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {unloadCurrentPatient} from '../../../commons/actions/WebViewActions.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {noop} from '../../../commons/utils/FunctionUtils.js';
import PatientDetailsDataFetcher from '../../components/PatientDetailsDataFetcher.js';
import {loadPatient as loadPatientAction} from '../PatientDetailsActions.js';
import {
	getLoadError, hasMatchingPatientDetails, isFiltered, selectCanLoad, selectDocuments,
	selectItemType, selectPatientDetails, selectPatientDetailsId, selectRequestedDisplayFormat
} from '../selectors/PatientDetailsSelectors.js';

export default connect(
	createStructuredSelector({
		hasPatientDetails: hasMatchingPatientDetails,
		patientId: selectPatientDetailsId,
		canLoad: selectCanLoad,
		itemType: selectItemType,
		patient: selectPatientDetails,
		documents: selectDocuments,
		loadError: getLoadError,
		requestedDisplayFormat: selectRequestedDisplayFormat,
		isFiltered
	}),
	bindActions({
		loadPatient: loadPatientAction,
		unloadCurrentPatient
	}),
	function merge(stateProps, dispatchProps, ownProps) {
		const {patientId, documents} = stateProps || {};
		const {unloadCurrentPatient: boundUnloadCurrentPatient} = dispatchProps;
		let loadPatient = noop;
		if (patientId) {
			const {itemType, requestedDisplayFormat} = stateProps;
			const {loadPatient: boundLoadPatient} = dispatchProps;
			const onlyShares = itemType === 'documentshare';
			loadPatient = boundLoadPatient.bind(undefined, patientId, onlyShares, requestedDisplayFormat);
		}
		return {
			...ownProps,
			...stateProps,
			loadPatient,
			unloadCurrentPatient: boundUnloadCurrentPatient,
			hasDocuments: documents.size > 0
		};
	}
)(PatientDetailsDataFetcher);
