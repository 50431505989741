import {RESOURCE_DATA_PARAMS, RESOURCE_DATA_RESPONSE, RESOURCE_DATA_SORT_BY} from '../constants/synCrud/FieldNames.js';
import {identity} from '../utils/FunctionUtils.js';
import {default as createOperation} from './operations/create.js';
import {default as getOperation} from './operations/get.js';
import {default as searchOperation} from './operations/search.js';

function normalizeBaseUrl(baseUrl) {
	const trimmedBaseUrl = baseUrl.trim();
	return (trimmedBaseUrl.length > 0 && trimmedBaseUrl[0] !== '/') ? `/${trimmedBaseUrl}` : trimmedBaseUrl;
}

function createSearchResult(searchOperationParams, results) {
	const {params = null, sortBy = null} = searchOperationParams || {};
	return {
		[RESOURCE_DATA_PARAMS]: params,
		[RESOURCE_DATA_SORT_BY]: sortBy,
		[RESOURCE_DATA_RESPONSE]: results
	};
}

function createGetResult(requestParams, results) {
	return createSearchResult({params: requestParams}, results);
}

export default class SynCrudResourceApi {
	constructor(baseUrl) {
		this.baseUrl = normalizeBaseUrl(baseUrl);
	}

	search(searchParams, resultsProcessor) {
		return searchOperation(this.baseUrl, searchParams, resultsProcessor)
			.then(results => createSearchResult(searchParams, results));
	}

	get(params) {
		return getOperation(this.baseUrl, params)
			.then(results => createGetResult(params, results));
	}

	create(jsonBody, urlVariableExtractionFunction = identity) {
		return createOperation(this.baseUrl, jsonBody, urlVariableExtractionFunction);
	}
}
