import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Spinner from '../../ui/components/Spinner.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';

export default class PatientDetailsDataFetcher extends React.Component {
	render() {
		const {
			hasPatientDetails, hasDocuments, loadError, canLoad, children, itemType, noDocsMessageElement,
			isFiltered, errorComponent: ErrorComponent
		} = this.props;
		let content;
		if (hasPatientDetails) {
			if (hasDocuments || isFiltered) {
				content = children;
			} else {
				content = noDocsMessageElement || PatientDetailsDataFetcher.renderNoDocsMessage();
			}
		} else if (loadError || !canLoad) {
			content = (
				<CenterLayout element='Main'>
					<ErrorComponent itemType={itemType} />
				</CenterLayout>
			);
		} else {
			content = (
				<CenterLayout element='Main'>
					<Spinner />
				</CenterLayout>
			);
		}
		return content;
	}

	static renderNoDocsMessage() {
		return (
			<CenterLayout element='Main'>
				<SynFormattedMessage translator={PatientDetailsMessagesTranslator} message='NoStudiesAvailable' />
			</CenterLayout>
		);
	}

	ensureCorrectPatientDetailsData() {
		const {hasPatientDetails, loadPatient} = this.props;
		if (!hasPatientDetails) {
			loadPatient();
		}
	}

	componentDidMount() {
		this.ensureCorrectPatientDetailsData();
	}

	componentWillUnmount() {
		const {unloadCurrentPatient} = this.props;
		unloadCurrentPatient();
	}

	componentDidUpdate() {
		const {loadError} = this.props;
		if (!loadError) {
			this.ensureCorrectPatientDetailsData();
		}
	}
}

PatientDetailsDataFetcher.propTypes = {
	unloadCurrentPatient: PropTypes.func.isRequired,
	hasPatientDetails: PropTypes.bool.isRequired,
	hasDocuments: PropTypes.bool.isRequired,
	canLoad: PropTypes.bool.isRequired,
	itemType: PropTypes.string.isRequired,
	errorComponent: PropTypes.elementType.isRequired,
	loadPatient: PropTypes.func.isRequired,
	loadError: PropTypes.instanceOf(Error),
	noDocsMessageElement: PropTypes.element,
	isFiltered: PropTypes.bool
};
