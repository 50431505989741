import Immutable from 'immutable';

import {tr as baseTr} from '../../i18n/components/SynFormattedMessage.js';
import WindowPresetsTranslator from '../../i18n/translators/WindowPresetsTranslator.js';

export function tr(message) {
	return baseTr(WindowPresetsTranslator, message);
}

// NOTE: The values are based on the values specified in the file WindowPresetTool.cpp
const CT_WINDOW_PRESETS = Immutable.fromJS([
	{
		label: tr('WindowPresetSkull'),
		toolIdentifier: 'WindowPresetSkull',
		center: 25,
		width: 95
	}, {
		label: tr('WindowPresetLung'),
		toolIdentifier: 'WindowPresetLung',
		center: -400,
		width: 1600
	}, {
		label: tr('WindowPresetAbdomen'),
		toolIdentifier: 'WindowPresetAbdomen',
		center: 10,
		width: 400
	}, {
		label: tr('WindowPresetLiver'),
		toolIdentifier: 'WindowPresetLiver',
		center: 88,
		width: 150
	}, {
		label: tr('WindowPresetMediastinum'),
		toolIdentifier: 'WindowPresetMediastinum',
		center: 10,
		width: 450
	}, {
		label: tr('WindowPresetBone'),
		toolIdentifier: 'WindowPresetBone',
		center: 300,
		width: 2500
	}, {
		label: tr('WindowPresetSpine'),
		toolIdentifier: 'WindowPresetSpine',
		center: 20,
		width: 300
	}, {
		label: tr('WindowPresetPostmyelo'),
		toolIdentifier: 'WindowPresetPostmyelo',
		center: 200,
		width: 1000
	}, {
		label: tr('WindowPresetParsPetrosaOssisTemporalis'),
		toolIdentifier: 'WindowPresetParsPetrosaOssisTemporalis',
		center: 500,
		width: 4000
	}
]);

export const DICOM_MODALITY_TO_PRESETS = Immutable.Map({
	CT: CT_WINDOW_PRESETS
});
