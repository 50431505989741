import React from 'react';
import {vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../../../commons/utils/CustomPropTypes.js';
import AnnotationsTranslator from '../../../../i18n/translators/AnnotationsTranslator.js';
import DicomImage from '../../../data/DicomImage.js';
import DeleteAnnotationIconContainer from '../../../flux/containers/DeleteAnnotationIconContainer.js';
import {
	createMemoizedTextMeasureTool, determinePixelAspectRatio, getAnnotationDisplayPrecision,
	measureAngle,
	MEASUREMENT_NOT_POSSIBLE,
	renderTransformedAnnotationIconComponents
} from '../../../utils/AnnotationUtils.js';
import {containsPoint2d, createRectangle} from '../../../utils/math/Rectangle.js';
import {clipToRectangle2d, createSegment} from '../../../utils/math/Segment.js';
import {DEGREES_OF_HALF_CIRCLE, round} from '../../../utils/MathUtils.js';
import {renderMultilineTextElements} from '../../../utils/SVGUtils.js';
import {getNormalizedDirection} from '../../../utils/VectorUtils.js';
import {getDicomDump} from '../../../utils/ViewerItemUtils.js';
import {TOUCH_SIZE} from '../AnnotationConstants.js';
import AnnotationIconsGroup from '../AnnotationIconsGroup.js';
import AnnotationLabel from '../AnnotationLabel.js';
import ModifiablePath from '../ModifiablePath.js';

const HALF_ANNOTATION_TOUCH_SIZE = TOUCH_SIZE / 2;
const READ_ONLY_SIZE_DIV = 3;
const MIDDLE_VECTOR_OFFSET = 10;

export default class GoniometryAnnotationBase extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.textMeasureTool = createMemoizedTextMeasureTool();
		this.onPathMove = this.onPathMove.bind(this);
		this.onUpdateFromFirstPoint = this.onPointsUpdate.bind(this, 0);
	}

	renderAnnotation(annotationPath, labelText, labelAlignmentPath, additionalElements = false) {
		const {AnnotationRoot} = this.props;
		const labelAndIcons = this.renderLabelAndIcons(labelText, labelAlignmentPath);
		return (
			<AnnotationRoot>
				{additionalElements}
				{this.renderModifiablePath(annotationPath, this.onUpdateFromFirstPoint)}
				{labelAndIcons}
			</AnnotationRoot>
		);
	}

	renderModifiablePath(points, onPointsUpdate) {
		const {readOnly, isPrintPreview, transformationMatrix, inverseTransformationMatrix} = this.props;
		return (
			<ModifiablePath readOnly={readOnly} isPrintPreview={isPrintPreview} points={points}
								 inverseTransformationMatrix={inverseTransformationMatrix}
								 transformationMatrix={transformationMatrix}
								 onPointsUpdate={onPointsUpdate} onPathMove={this.onPathMove} />
		);
	}

	renderLabelAndIcons(labelText, threePointGoniometryPoints) {
		const annotationIconsGroup = this.renderAnnotationIconsGroup(
			threePointGoniometryPoints[2], threePointGoniometryPoints[1], threePointGoniometryPoints[2]
		);
		const annotationLabel = this.renderLabel(labelText, threePointGoniometryPoints);
		return (
			<React.Fragment>
				{annotationLabel}
				{annotationIconsGroup}
			</React.Fragment>
		);
	}

	renderAnnotationIconsGroup(directionFrom, directionTo, anchor) {
		const {annotationId, isPrintPreview, readOnly} = this.props;
		const displayIcons = !isPrintPreview && !readOnly;
		if (displayIcons) {
			const mainAnnotationDirection = getNormalizedDirection(directionFrom, directionTo);
			const deleteIconPosition = vec2.scale(mainAnnotationDirection, mainAnnotationDirection, -TOUCH_SIZE);
			const annotationIcons = renderTransformedAnnotationIconComponents([DeleteAnnotationIconContainer],
				[deleteIconPosition], annotationId);
			return (
				<AnnotationIconsGroup position={anchor}>
					{annotationIcons}
				</AnnotationIconsGroup>
			);
		}
		return false;
	}

	renderLabel(labelText, alignementPath) {
		const {lineHeight, fontSize, containerWidth, containerHeight} = this.props;
		const textRect = this.textMeasureTool(labelText, 'annotation-label', fontSize, lineHeight);
		const angelInformationTextElements = renderMultilineTextElements(labelText, lineHeight, {x: '0'});
		const labelProperties = calculateLabelProperties(
			alignementPath, textRect, containerWidth, containerHeight, fontSize
		);
		return (
			<AnnotationLabel {...labelProperties}>
				{angelInformationTextElements}
			</AnnotationLabel>
		);
	}

	onPointsUpdate(startOffset, newPoints) {
		const {onAnnotationPropertiesChanged, annotationId, annotationProperties} = this.props;
		onAnnotationPropertiesChanged(annotationId, annotationProperties.update('points', oldPoints => {
			const modifiedPoints = oldPoints.slice(0);
			for (let i = 0; i < newPoints.length; ++i) {
				modifiedPoints[startOffset + i] = newPoints[i];
			}
			return modifiedPoints;
		}));
	}

	onPathMove(diff) {
		const {onAnnotationPropertiesChanged, annotationId, annotationProperties} = this.props;
		onAnnotationPropertiesChanged(annotationId, annotationProperties
			.update('points', oldPoints => oldPoints.map(oldPoint => vec2.add(vec2.create(), oldPoint, diff)))
		);
	}

	calcLabel(annotationPoints, threePointGoniometryPoints) {
		const [a, intersectionPoint, c] = threePointGoniometryPoints;
		const angle = measureAngle(
			this.getPixelAspectRatio(annotationPoints),
			[intersectionPoint, a],
			[intersectionPoint, c]
		);
		if (angle === MEASUREMENT_NOT_POSSIBLE) {
			return this.getMeasurementNotPossibleLabel();
		}
		return calcLabelText(angle);
	}

	getMeasurementNotPossibleLabel() {
		const {locale} = this.props;
		return AnnotationsTranslator.getFormattedMessage('MeasurementNotPossible', locale);
	}

	getPixelAspectRatio(annotationPoints) {
		const {viewerItem} = this.props;
		const dicomDump = getDicomDump(viewerItem);
		return determinePixelAspectRatio(annotationPoints, dicomDump);
	}
}

function calcLabelText(angle) {
	const supplementaryAngle = round(DEGREES_OF_HALF_CIRCLE - angle, getAnnotationDisplayPrecision());
	const roundedAngle = round(angle, getAnnotationDisplayPrecision());
	return `${roundedAngle}° (${supplementaryAngle}°)`;
}

function calculateLabelProperties(
		containerPositions, textRect, 
		containerWidth, containerHeight, 
		fontSize, readOnly = false) {
	const middleVector = getMiddleVector(containerPositions, readOnly);

	const middleVectorRightOfCenter = middleVector[0] >= 0;
	const textAnchor = middleVectorRightOfCenter ? 'start' : 'end';
	let textPosition = vec2.add(vec2.create(), containerPositions[1], middleVector);

	const clippingRect = getClippingRect(containerWidth, containerHeight, 
		fontSize, middleVectorRightOfCenter, textRect);

	if (!containsPoint2d(clippingRect, textPosition)) {
		let primaryIndex = middleVectorRightOfCenter ? 2 : 0;
		if (containerPositions[2][0] < containerPositions[0][0]) {
			primaryIndex = primaryIndex === 2 ? 0 : 2;
		}
		const alignmentSegment = getAligmentSegment(clippingRect, containerPositions, 
			primaryIndex, textPosition, middleVector);
		const clippedSegment = clipToRectangle2d(alignmentSegment, clippingRect);
		if (clippedSegment) {
			textPosition = clippedSegment.from;
		} else {
			textPosition = alignmentSegment.to;
		}
	}
	return {x: round(textPosition[0], 2), y: round(textPosition[1], 2), textAnchor};
}

function getAligmentSegment(clippingRect, containerPositions, primaryIndex, textPosition, middleVector) {
	const isInClippingRect = containsPoint2d(clippingRect, containerPositions[primaryIndex]);
	let newPrimaryIndex;
	if (isInClippingRect) {
		newPrimaryIndex = primaryIndex;
	} else {
		newPrimaryIndex = primaryIndex === 2 ? 0 : 2;
	}
	return createSegment(textPosition, vec2.add(vec2.create(), 
		containerPositions[newPrimaryIndex], middleVector));
}

function getMiddleVector(containerPositions, readOnly) {
	const middleVector = vec2.subtract(vec2.create(), containerPositions[0], containerPositions[2]);
	vec2.add(middleVector, containerPositions[2], vec2.scale(middleVector, middleVector, 0.5));
	vec2.subtract(middleVector, containerPositions[1], middleVector);
	vec2.normalize(middleVector, middleVector);

	const offset = Math.abs(middleVector[1]) * MIDDLE_VECTOR_OFFSET;
	vec2.scale(middleVector, middleVector, 
		offset + readOnly ? TOUCH_SIZE / READ_ONLY_SIZE_DIV : HALF_ANNOTATION_TOUCH_SIZE);
	return middleVector;
}

function getClippingRect(containerWidth, containerHeight, fontSize, middleVectorRightOfCenter, textRect) {
	const halfContainerWidth = containerWidth / 2;
	const halfContainerHeight = containerHeight / 2 - fontSize;
	return createRectangle(
		vec2.fromValues(
			-(halfContainerWidth - (middleVectorRightOfCenter ? 0 : textRect.width)),
			-halfContainerHeight
		), vec2.fromValues(
			halfContainerWidth - (middleVectorRightOfCenter ? textRect.width : 0),
			halfContainerHeight
		)
	);
}

GoniometryAnnotationBase.propTypes = {
	AnnotationRoot: PropTypes.elementType,
	readOnly: PropTypes.bool, 
	isPrintPreview: PropTypes.bool, 
	transformationMatrix: PropTypes.instanceOf(Float32Array), 
	inverseTransformationMatrix: PropTypes.instanceOf(Float32Array), 
	annotationId: PropTypes.string,
	lineHeight: PropTypes.number, 
	fontSize: PropTypes.number, 
	containerWidth: PropTypes.number, 
	containerHeight: PropTypes.number,
	onAnnotationPropertiesChanged: PropTypes.func,
	annotationProperties: immutableMapPropType,
	locale: PropTypes.string,
	viewerItem: PropTypes.instanceOf(DicomImage)
};
