import React from 'react';
import PropTypes from 'prop-types';

import {getHocDisplayName} from '../utils/ReactUtils.js';

import '../../../styles/commons/components/SizeAwareComponent.scss';

class MeasureWrapper extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.measurementContainer = null;
		this.boundMeasurementContainerMounted = this.measurementContainerMounted.bind(this);

		this.state = {
			boundingRect: null
		};
	}

	render() {
		return this.measurementContainer === null ? this.renderMeasureContainer() : this.renderMeasuredComponent();
	}

	renderMeasureContainer() {
		return <div ref={this.boundMeasurementContainerMounted} className='size-aware-component-wrapper' />;
	}

	renderMeasuredComponent() {
		const {boundingRect} = this.state;
		const {component: Component} = this.props;
		return (
			<div ref={this.boundMeasurementContainerMounted} className='size-aware-component-wrapper'>
				<Component {...this.props} boundingRect={boundingRect} />
			</div>
		);
	}

	componentDidUpdate() {
		this.updateBoundingClientRect();
	}

	measurementContainerMounted(measurementContainer) {
		this.measurementContainer = measurementContainer;
		this.updateBoundingClientRect();
	}

	updateBoundingClientRect() {
		if (this.measurementContainer !== null) {
			const {boundingRect} = this.state;
			const boundingClientRect = this.measurementContainer.getBoundingClientRect();
			const previousBoundingRect = boundingRect;
			if (previousBoundingRect === null ||
				previousBoundingRect.left !== boundingClientRect.left ||
				previousBoundingRect.top !== boundingClientRect.top ||
				previousBoundingRect.width !== boundingClientRect.width ||
				previousBoundingRect.height !== boundingClientRect.height
			) {
				this.setState({
					boundingRect: {
						left: boundingClientRect.left,
						top: boundingClientRect.top,
						width: boundingClientRect.width,
						height: boundingClientRect.height
					}
				});
			}
		}
	}
}
MeasureWrapper.propTypes = {
	component: PropTypes.elementType
};

export default function createSizeAwareComponent(ActualComponent) {
	const SizeAwareComponentWrapper = React.forwardRef(
		(props, forwardRef) => <MeasureWrapper ref={forwardRef} component={ActualComponent} {...props} />
	);
	SizeAwareComponentWrapper.displayName = getHocDisplayName(ActualComponent, 'sizeAware');
	return SizeAwareComponentWrapper;
}

export const BOUNDING_RECT_SHAPE = PropTypes.shape({
	left: PropTypes.number,
	top: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number
});
