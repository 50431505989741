import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../commons/utils/FluxUtils.js';
import {replaceLocation} from '../flux/LocationActions.js';

export default function createRedirectComponent(redirectLocationSelector) {
	return connect(
		createStructuredSelector({
			redirectLocation: redirectLocationSelector
		}),
		bindActions({replaceLocation}),
		(stateProps, dispatchProps, ownProps) => {
			const {redirectLocation} = stateProps || {};
			const {replaceLocation: boundReplaceLocation} = dispatchProps;
			return {
				redirect: () => {
					boundReplaceLocation(redirectLocation);
				},
				...ownProps
			};
		}
	)(Redirector);
}

function Redirector(props) {
	const {redirect} = props;
	useEffect(redirect, [redirect]);
	return false;
}

Redirector.propTypes = {
	redirect: PropTypes.func.isRequired
};
