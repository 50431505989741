import {memoizeLast} from '../../commons/utils/FunctionUtils.js';

export function formatResolution(imageRows, imageColumns) {
	return imageRows && imageColumns
		? `RES: ${imageRows}x${imageColumns}`
		: '';
}

export function formatSeriesInfo(seriesNumber, instanceNumber, imageRelativeFrameOffset = null) {
	const textParts = [];
	const frameOffsetValue = parseInt(imageRelativeFrameOffset, 10);
	seriesNumber && textParts.push(`S: ${seriesNumber}`);
	instanceNumber && textParts.push(`B: ${instanceNumber}`);
	!isNaN(frameOffsetValue) && textParts.push(`F: ${frameOffsetValue}`);
	return textParts.join(' ');
}

export function createDataSelector(...dataFields) {
	// ensures a stable array instance if none of the values has changed
	const memoizedCombine = memoizeLast((...values) => [...values]);
	return metadataMap => memoizedCombine(...dataFields.map(fieldName => metadataMap[fieldName]));
}
