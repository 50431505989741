import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import ProducerField from '../../../archive-documents/components/ProducerField';
import ArchiveDocumentsTranslator from '../../../archive-documents/i18n/ArchiveDocumentsTranslator';
import {TranslationProvider} from '../../../i18n/components/TranslationContext';
import useTranslation from '../../../i18n/hooks/useTranslation';
import Spinner from '../../../ui/components/Spinner.js';
import {Body1} from '../../../ui/components/typography/Typography';
import {useMemoFactory} from '../../utils/customHooks/index';
import {callSafe} from '../../utils/FunctionUtils';

const UPLOAD_PRODUCER_KEY = 'orgunitAbk';

export function UploadInfoForm(props) {
	const {
		archivePermissions, selectProducer, currentProducer,
		orgunitHintMessage, orgunitFieldLabel, orgunitNoErrorMessage
	} = props;
	const onProducerChangeHandler = useMemoFactory(createOnChangeHandler, archivePermissions, selectProducer);
	const producerChoice = useMemoFactory(getIndexOfItem, archivePermissions, currentProducer, UPLOAD_PRODUCER_KEY);
	const producerHintMessage = useTranslation('ProducerHintMessage', undefined, ArchiveDocumentsTranslator);

	return (
		<TranslationProvider translator={ArchiveDocumentsTranslator}>
			<Body1>
				{orgunitHintMessage ? orgunitHintMessage : producerHintMessage}
			</Body1>
			{archivePermissions 
				? <ProducerField onChange={onProducerChangeHandler} producerChoice={producerChoice}
							archivePermissions={archivePermissions} fieldLabel={orgunitFieldLabel} 
							noSelectionErrorMessage={orgunitNoErrorMessage} />
				: <Spinner />}
		</TranslationProvider>
	);
}

function getIndexOfItem(list, item, key) {
	if (!list) {
		return undefined;
	}
	const elementFound = list.findIndex(
		producerEntry => producerEntry[key] === item
	); 
	return elementFound === -1 ? undefined : elementFound;
}

function createOnChangeHandler(list, selectionHandler) {
	return getSelectedIndex => {
		const selectedIndex = list.get(getSelectedIndex);
		if (selectedIndex || getSelectedIndex === null) {
			callSafe(selectionHandler, selectedIndex);
		}
	};
}

UploadInfoForm.propTypes = {
	archivePermissions: PropTypes.instanceOf(Immutable.List),
	selectProducer: PropTypes.func,
	currentProducer: PropTypes.string,
	orgunitHintMessage: PropTypes.string,
	orgunitFieldLabel: PropTypes.string,
	orgunitNoErrorMessage: PropTypes.string
};
