import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import useTranslation from '../../../webview/i18n/hooks/useTranslation.js';
import IconButton from '../../../webview/ui/components/IconButton.js';
import DeleteAllIcon from '../../../webview/ui/components/icons/DeleteAllIcon.js';
import HorizontalLayout from '../../../webview/ui/components/layout/HorizontalLayout.js';
import {DeleteHead, NameHead, PatientHead, SizeHead} from './Columns.js';

export default function DesktopListHeader(props) {
	const {removeAll} = props;
	const removeAllDisabled = !removeAll;
	const deleteAllButtonTitle = useTranslation('RemoveAllButtonTitle');
	return (
		<HorizontalLayout noGrow justify='start' className='table-header table-row'>
			<DeleteHead>
				<IconButton title={deleteAllButtonTitle} icon={<DeleteAllIcon />}
								disabled={removeAllDisabled} onClick={removeAll} />
			</DeleteHead>
			<NameHead>
				<SynFormattedMessage message='Column_UploadEntryName' />
			</NameHead>
			<PatientHead>
				<SynFormattedMessage message='Column_UploadEntryPatient' />
			</PatientHead>
			<SizeHead>
				<SynFormattedMessage message='Column_UploadSize' />
			</SizeHead>
		</HorizontalLayout>
	);
}

DesktopListHeader.propTypes = {
	removeAll: PropTypes.func
};
