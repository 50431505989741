import React from 'react';

import AutoComplete from '../AutoComplete.js';

const STYLES = {minWidth: 100};

/**
 * See the following links for details about the AutoCompleteTextField component.
 * https://material.io/components/text-fields
 * https://material.io/components/menus
 */
export default function AutoCompleteTextField(props) {
	return <AutoComplete sx={STYLES} {...props} />;
}

AutoCompleteTextField.propTypes = AutoComplete.propTypes;
AutoCompleteTextField.defaultProps = AutoComplete.defaultProps;
