import {IS_DEBUG_BUILD} from '../commons/constants/EnvironmentConstants.js';
import {noop} from '../commons/utils/FunctionUtils.js';
import {default as ProbabilisticQueue, PRIORITY_LOW} from '../commons/utils/ProbabilisticQueue.js';
import createFIFOTaskSelector from './createFIFOTaskSelector.js';
import processTask from './processTask.js';
import TaskCollection from './TaskCollection.js';

export default class Scheduler {
	constructor(maxNumberChannels, taskProcessor = processTask) {
		this.selectorQueue = new ProbabilisticQueue();
		this.taskCollection = new TaskCollection();
		this.freeChannels = [];
		this.maxNumberChannels = maxNumberChannels;
		this.channels = [];
		this.processTask = taskProcessor;
		this.suspended = false;
		this.idleCallback = null;
		for (let i = 0; i < maxNumberChannels; ++i) {
			this.freeChannels.push(i);
			this.channels.push(null);
		}
		//Do not remove this selector as it is important to prevent starvation
		//Do not replace the fifo selector with a static fallback mechanism,
		// as it would prevent that the  fifo selector is selected with low priority in case of high congestion
		this.addSelector('Scheduler$$defaultFIFOSelector', PRIORITY_LOW, createFIFOTaskSelector(true));
	}

	scheduleTask(taskCreator, taskConsumer, groupIdentifier, taskIdentifier) {
		const taskHolder = {
			taskCreator,
			taskConsumer
		};
		Object.assign(taskHolder, this.taskCollection.add(taskHolder, groupIdentifier, taskIdentifier));
		this.assignTasksToFreeChannels();
	}

	suspend(idleCallback) {
		if (this.isIdle()) {
			idleCallback();
		} else {
			this.suspended = true;
			this.idleCallback = idleCallback;
		}
	}

	resume() {
		if (this.suspended) {
			this.suspended = false;
			this.idleCallback = null;
			this.assignTasksToFreeChannels();
		}
	}

	isIdle() {
		return this.freeChannels.length === this.maxNumberChannels;
	}

	/**
	 * removes a task from the schedule if the task is already assigned to a channel the task handler will be replaced
	 * by the abortedTaskHandler,
	 *
	 * By default, the aborted task handler is a noop
	 *
	 * @param groupIdentifier the groupIdentifier of the task to cancel
	 * @param taskIdentifier the taskIdentifier of the task to cancel
	 * @param abortedTaskHandler the taskConsumer which will be called in case the task is already assigned
	 * to a channel. In case the task has been assigned to a channel, it must be expected that it's asynchronous
	 * work has already been started.
	 */
	cancelTask(groupIdentifier, taskIdentifier, abortedTaskHandler = noop) {
		const taskRemoved = this.taskCollection.removeTask(groupIdentifier, taskIdentifier);
		if (!taskRemoved) {
			this.getChannelsForTask(groupIdentifier, taskIdentifier).forEach(channel => {
				channel.taskConsumer = abortedTaskHandler;
			});
		}
	}

	cancelAllTasks() {
		this.taskCollection.clear();
		this.channels
			.filter(channel => Boolean(channel))
			.forEach(channel => {
				channel.taskConsumer = noop;
			});
	}

	taskFinished(channelId) {
		this.channels[channelId] = null;
		this.freeChannels.push(channelId);
		this.assignTasksToFreeChannels();

		if (this.suspended && this.isIdle()) {
			this.idleCallback && this.idleCallback();
		}
	}

	getChannelsForTask(groupIdentifier, taskIdentifier) {
		return this.channels.filter(
			channel => channel !== null &&
				channel.taskIdentifier === taskIdentifier &&
				channel.groupIdentifier === groupIdentifier
		);
	}

	getCurrentTaskInChannel(channelId) {
		return this.channels[channelId] || null;
	}

	addSelector(selectorIdentifier, priority, selector) {
		this.selectorQueue.enqueue(priority, {
			id: selectorIdentifier,
			priority,
			selector
		});
	}

	removeSelector(selectorIdentifier) {
		this.selectorQueue.removeMatchingItems(selectorHolder => selectorHolder.id === selectorIdentifier);
	}

	assignTasksToFreeChannels() {
		if (this.suspended) {
			return;
		}

		const consumedPermanentSelectors = [];
		while (this.freeChannels.length > 0 && !this.taskCollection.isEmpty() && !this.selectorQueue.isEmpty()) {
			const selectorHolder = this.selectorQueue.take();
			const {id, priority, selector} = selectorHolder;
			const {nextSelector, task} = selector(this.taskCollection);
			if (task !== null) {
				this.pushTaskIntoNextFreeChannel(task);
				if (nextSelector !== null) {
					this.addSelector(id, priority, nextSelector);
				}
			} else if (nextSelector !== null) {
				//Keep in mind: This branch is important to prevent starvation.
				//The reason is that until this method has exited, no new tasks will be pushed into the task collection.
				//Therefore, do not replace it with this.addSelector(...).
				consumedPermanentSelectors.push({id, priority, nextSelector});
			}
		}
		consumedPermanentSelectors.forEach(({id, priority, nextSelector}) => {
			this.addSelector(id, priority, nextSelector);
		});
	}

	pushTaskIntoNextFreeChannel(task) {
		const channelIndex = this.freeChannels.shift();
		if (IS_DEBUG_BUILD) {
			const matchingChannels = this.channels.filter(
				channel => channel !== null &&
					channel.taskIdentifier === task.taskIdentifier &&
					channel.groupIdentifier === task.groupIdentifier
			);
			if (matchingChannels.length > 0) {
				throw new Error(`Task ${task.groupIdentifier} :: ${task.taskIdentifier} scheduled twice.`);
			}
		}
		this.channels[channelIndex] = task;
		this.processTask(task, () => this.taskFinished(channelIndex));
	}
}
