import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from '../../material-design/components/icons/ErrorIcon.js';
import {cancelTask, scheduleTask} from '../../scheduling/api';
import {noop} from '../utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';
import {combineClassNames} from '../utils/StyleUtils.js';
import CenterLayout from './layout/CenterLayout.js';

import '../../../styles/commons/components/PreviewableImage.scss';

let instanceId = 0;
export default class PreviewableImage extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.actualNewDomImageHandler = this.handleNewPreviewDomImage;
		this.actualDomImageLoadErrorHandler = this.handleDomImageLoadError;
		this.loadInitialized = false;
		this.state = {
			instanceId: instanceId++,
			image: null,
			loadError: false,
			imageProps: {}
		};
	}

	render() {
		const childProps = cloneWithoutProperties(this.props,
			'width', 'height', 'isVisible', 'loadPreview', 'className', 'taskIdentifier', 'devicePixelRatio'
		);
		
		const {width, height, className, isVisible} = this.props; 
		const sizeProps = {width, height, overflow: 'hidden'};
		const LayoutClassName = combineClassNames('previewable-image', className);

		let content = false;
		if (isVisible) {
			if (this.isLoaded()) {
				content = this.getImage();
			} else if (this.loadFailed()) {
				content = <ErrorIcon />;
			}
		}

		return (
			<CenterLayout style={sizeProps} className={LayoutClassName} {...childProps}>
				{content}
			</CenterLayout>
		);
	}

	getTaskId() {
		const {taskIdentifier} = this.props;
		const {instanceId: StateInstanceId} = this.state;
		return `${taskIdentifier}/${StateInstanceId}`;
	}

	componentDidMount() {
		this.initializeLoad();
	}

	componentDidUpdate() {
		this.initializeLoad();
	}

	initializeLoad() {
		const {isVisible, width, height, loadPreview, devicePixelRatio} = this.props;
		if (isVisible && !this.loadInitialized) {
			this.loadInitialized = true;

			const previewImageDimensions = {
				width: Math.round(width * devicePixelRatio),
				height: Math.round(height * devicePixelRatio)
			};
			const boundLoadPreview = () => loadPreview(previewImageDimensions);

			scheduleTask({
				groupIdentifier: 'preview',
				taskIdentifier: this.getTaskId(),
				taskCreator: boundLoadPreview,
				taskConsumer: loadPromise => loadPromise
					.then(image => this.actualNewDomImageHandler(image))
					.catch(() 	=> this.actualDomImageLoadErrorHandler())
			});
		}
	}

	handleNewPreviewDomImage(domImage) {
		this.setState({
			image: domImage,
			imageProps: PreviewableImage.computeImageProps(domImage)
		});
	}

	static revokeLoadedDomImage(domImage) {
		if (domImage) {
			URL.revokeObjectURL(domImage.src);
		}
	}

	handleDomImageLoadError() {
		this.setState({
			loadError: true
		});
	}

	componentWillUnmount() {
		const {image} = this.state;
		this.actualNewDomImageHandler = PreviewableImage.revokeLoadedDomImage;
		this.actualDomImageLoadErrorHandler = noop;
		if (image) {
			URL.revokeObjectURL(image.src);
		} else if (this.loadInitialized) {
			cancelTask('preview', this.getTaskId());
		}
	}

	isLoaded() {
		const {image} = this.state;
		return image !== null;
	}

	loadFailed() {
		const {loadError} = this.state;
		return loadError !== false;
	}

	getImage() {
		const {imageProps} = this.state;
		const {src, width, height} = imageProps;
		return <img alt='' src={src} width={width} height={height} />;
	}

	static computeImageProps(image) {
		const props = {
			src: image.src
		};
		if (image.naturalWidth > image.naturalHeight) {
			props.width = '100%';
		} else {
			props.height = '100%';
		}
		return props;
	}
}

PreviewableImage.propTypes = {
	width: PropTypes.number.isRequired,
	height: PropTypes.number.isRequired,
	isVisible: PropTypes.bool,
	loadPreview: PropTypes.func.isRequired,
	devicePixelRatio: PropTypes.number.isRequired,
	className: PropTypes.string,
	taskIdentifier: PropTypes.string
};

PreviewableImage.defaultProps = {
	isVisible: true
};
