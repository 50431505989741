import {createSelector} from 'reselect';

const selectProps = (state, props) => props;

export default function selectFromProps(key, defaultValue = null) {
	return createSelector(
		selectProps,
		props => (props && props[key] ? props[key] : defaultValue)
	);
}
