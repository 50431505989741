import React from 'react';
import {Tooltip as MUITooltip} from '@mui/material';
import PropTypes from 'prop-types';

const CLOSE_AFTER_TOUCH_DELAY = 3000;

export default function ToolTip(props) {
	const {onClose, open, title, children, touchDelay} = props;
	return (
		<MUITooltip title={title} open={open} onClose={onClose}
						enterTouchDelay={touchDelay} leaveTouchDelay={CLOSE_AFTER_TOUCH_DELAY}>
			{children}
		</MUITooltip>
	);
}
ToolTip.propTypes = {
	onClose: PropTypes.func,
	open: PropTypes.bool,
	title: PropTypes.node,
	touchDelay: PropTypes.number
};
ToolTip.defaultProps = {
	touchDelay: 0
};
