import React from 'react';
import PropTypes from 'prop-types';

import {immutableListPropType, immutableMapPropType} from '../../commons/utils/CustomPropTypes';
import {identity} from '../../commons/utils/FunctionUtils.js';

import '../../../styles/viewer/components/MetaDataOverlay.scss';

const DEFAULT_MARGIN = 10;

export default class MetaDataOverlayArea extends React.PureComponent {
	render() {
		const x = this.getX();
		const y = this.getY();
		return (
			<g className={this.getClassName()} transform={`translate(${x},${y})`}>
				{this.renderLines()}
			</g>
		);
	}

	renderLines() {
		const {renderer, formatter, data} = this.props;
		const lines = formatter(data);
		return renderer(this.getRendererProps(lines), formatter(data));
	}

	getRendererProps(lines) {
		return {...this.props, y: this.getRendererY(lines)};
	}

	getRendererY(lines) {
		const {vAlign} = this.props;
		return vAlign === 'center' ? `-${lines.length / 2}em` : '0';
	}

	getClassName() {
		const {vAlign, align} = this.props;
		return `${vAlign}-${align}`;
	}

	getX() {
		const {align, containerWidth, margins: {left, right}} = this.props;
		let x;
		switch (align) {
			case 'left':
			    x = left;
				break;
			case 'center':
				x = containerWidth / 2;
				break;
			default:
				x = containerWidth - right;
				break;
		}
		return x;
	}

	getY() {
		const {vAlign, containerHeight, margins: {top, bottom}} = this.props;
		let y;
		switch (vAlign) {
			case 'bottom':
				y = containerHeight - bottom;
				break;
			case 'center':
				y = (containerHeight / 2);
				break;
			default:
				y = top;
				break;
		}
		return y;
	}
}
MetaDataOverlayArea.propTypes = {
	vAlign: PropTypes.string,
	align: PropTypes.string,
	containerHeight: PropTypes.number,
	containerWidth: PropTypes.number,
	margins: PropTypes.shape({
		top: PropTypes.number,
		bottom: PropTypes.number,
		left: PropTypes.number,
		right: PropTypes.number
	}),
	formatter: PropTypes.func,
	renderer: PropTypes.func,
	data: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.object,
		immutableMapPropType,
		immutableListPropType
	]))
};

MetaDataOverlayArea.defaultProps = {
	formatter: identity,
	renderer: stackLines,
	margins: {
		left: DEFAULT_MARGIN,
		top: DEFAULT_MARGIN,
		right: DEFAULT_MARGIN,
		bottom: DEFAULT_MARGIN
	}
};

function getDy(props) {
	const {vAlign} = props;
	return (vAlign === 'top' || vAlign === 'center') ? '1em' : '-1em';
}

function drawTextBlock(className, x, y, dy, isBottom, formattedLines) {
	const textSpans = formattedLines.map((line, index) => {
		const key = `line-${index}`;
		return (
			<tspan key={key} x={x} dy={(isBottom && index === 0) ? 0 : dy}>
				{line}
			</tspan>
		);
	});
	return (
		<text x={x} y={y} className={className}>
			{textSpans}
		</text>
	);
}

export function stackLines(props, formattedLines) {
	const {x = 0, y = 0, vAlign} = props;
	const dy = getDy(props);
	const isBottom = vAlign === 'bottom';
	return drawTextBlock('', x, y, dy, isBottom, formattedLines);
}
