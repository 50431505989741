import React from 'react';
import PropTypes from 'prop-types';

import {useObjectUrl} from '../../commons/utils/customHooks';
import DicomImage from '../data/DicomImage';
import HTMLViewer from './HTMLViewer.js';

/**
 * A viewer component for DICOM structured reports.
 *
 * @param props - hold the dicom image to be displayed in the field dicomImage.
 * @returns {JSX.Element}
 * @constructor
 */
export default function SRViewer(props) {
	const {dicomImage} = props;
	const {rawImageData: blob} = dicomImage;
	const objectUrl = useObjectUrl(blob);
	return <HTMLViewer srcUrl={objectUrl} />;
}
SRViewer.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage).isRequired
};
