import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {uiIsMobileDevice} from '../../../ui/flux/UISelectors.js';
import {extractViewerItem,	getDicomDump, getMetadata, getSeriesImageIndex, isSeriesViewerItem} from '../../utils/ViewerItemUtils.js';
import {getActiveViewerMetaInfo, getActiveViewerProperties} from './ViewerSelectors.js';

const MAX_PERCENT = 100;

const selectViewerItems = state => state.viewerItems;
const selectImageDataItems = createSelectViewerItemsOfType('imageData');
const selectGenericFileViewerItems = createSelectViewerItemsOfType('file');
export const selectImageViewerItems = createSelectViewerItemsOfType('image');
export const selectSeriesViewerItems = createSelectViewerItemsOfType('series');

export function selectSeriesViewerItem(seriesId, notFound = Immutable.Map()) {
	return createSelector(selectSeriesViewerItems, allSeries => allSeries.get(seriesId, notFound));
}

export function selectImageViewerItem(imageId, notFound = Immutable.Map()) {
	return createSelector(selectImageViewerItems, image => image.get(imageId, notFound));
}

export function selectImageDataViewerItem(frameId, notFound = null) {
	return createSelector(selectImageDataItems, imageData => imageData.get(frameId, notFound));
}

export function selectGenericFileViewerItem(fileId, notFound = Immutable.Map()) {
	return createSelector(selectGenericFileViewerItems, files => files.get(fileId, notFound));
}

export const selectDicomDump = imageId => createSelector(selectImageViewerItem(imageId), getDicomDump);
export const selectImageMetadata = imageId => createSelector(selectImageViewerItem(imageId), getMetadata);

export function hasFileLoadStarted(fileId) {
	return createSelector(selectGenericFileViewerItem(fileId, null), file => Boolean(file));
}

export function getFileLoadedPercent(state, fileId) {
	const viewerItem = getViewerItem(state, 'file', fileId);
	return viewerItem ? viewerItem.get('loadedPercent', 0) : 0;
}

export function createSelectViewerItemsOfType(type) {
	return state => selectViewerItems(state).get(type, Immutable.Map());
}

function getViewerItem(state, itemType, itemId) {
	return extractViewerItem(selectViewerItems(state), itemType, itemId);
}

export const getActiveViewerItem = createSelector(getActiveViewerMetaInfo, selectViewerItems, (activeMetaInfo, viewerItems) => activeMetaInfo.size > 0 && extractViewerItem(viewerItems, activeMetaInfo.get('type'), activeMetaInfo.get('id')) || null);

const selectActiveDicomSeriesViewerItem = createSelector(
	getActiveViewerItem,
	viewerItem => ((viewerItem && isSeriesViewerItem(viewerItem)) ? viewerItem : null)
);

const selectActiveDicomImageId = createSelector(
	selectActiveDicomSeriesViewerItem,
	getActiveViewerProperties,
	(seriesViewerItem, viewerProperties) => {
		const frameOffset = viewerProperties.get('seriesRelativeFrameOffset', 0);
		const seriesImageIndex = getSeriesImageIndex(seriesViewerItem);
		const imageIndexEntry = seriesImageIndex.getEntry(seriesImageIndex.imageIndexForFrame(frameOffset));
		return imageIndexEntry ? imageIndexEntry.id : null;
	}
);

export const selectActiveDicomImageViewerItem = createSelector(
	selectImageViewerItems,
	selectActiveDicomImageId,
	(imageViewerItems, imageId) => (imageId ? imageViewerItems.get(imageId) : null)
);

export function createSelectSeriesLoadedPercentBySeriesId(seriesId) {
	return createSelectSeriesLoadedPercent(selectSeriesViewerItem(seriesId));
}

export function createSelectSeriesLoadedPercent(selectViewerItem) {
	const totalNrLoadActionsSelector = createSelector(
		selectViewerItem,
		seriesItem => {
			const imageIndex = getSeriesImageIndex(seriesItem, null);
			return imageIndex ? imageIndex.numberOfFrames + imageIndex.numberOfImages : 0;
		});
	const nrCompletedLoadActionsSelector = createSelector(selectViewerItem, seriesItem => (seriesItem
		? (Math.max(0, seriesItem.get('nrLoadedDicomDumps', 0)) + Math.max(0, seriesItem.get('nrLoadedImages', 0)))
		: 0));
	return createSelector(
		totalNrLoadActionsSelector, nrCompletedLoadActionsSelector,
		(totalNrLoadActions, nrCompletedLoadActions) => {
			if (totalNrLoadActions > 0) {
				return Math.min(
					MAX_PERCENT,
					Math.round((nrCompletedLoadActions / totalNrLoadActions) * MAX_PERCENT)
				);
			}
			return 0;
		}
	);
}

export function createSelectSeriesLoadedPercentDeviceAware(seriesId) {
	return createSelector(
		createSelectSeriesLoadedPercentBySeriesId(seriesId),
		uiIsMobileDevice,
		(loadedPercent, isMobileDevice) => (isMobileDevice ? loadedPercent : MAX_PERCENT)
	);
}
