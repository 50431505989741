import {APPLICATION_JSON} from '../constants/MimeTypes.js';
import {createMultipartRequest} from '../utils/AjaxUtils.js';

/**
 * Formats a request to fulfill the requirements of the backend upload services.
 * @param file - the file to be uplaoded.
 * @param fileName - Override of the filename to be uploaded
 * @param context - additional context params like producer or description
 * @returns The prepared file upload request
 */
export default function createFileUploadRequest(file, fileName = null, context = {}) {
	const finalName = fileName || file.name;
	const fileInfo = {
		size: file.size,
		name: finalName,
		...context
	};
	const fileInfoBlob = new Blob([JSON.stringify(fileInfo, null, 0)], {type: APPLICATION_JSON});
	return createMultipartRequest('PUT', fileInfoBlob, file);
}
