import ExtendableError from '../commons/errors/ExtendableError.js';

export default class ViewerError extends ExtendableError {
	constructor(description, cause, userMessage, userMessageTranslator) {
		super(buildDescription(description, cause));
		this.userMessage = userMessage;
		this.translator = userMessageTranslator;
	}
}

function buildDescription(description, cause) {
	if (cause) {
		return `${description} (caused by: ${cause})`;
	}
	return description;
}
