import {createSelector} from 'reselect';

import {
	PASSWORD_BUSY,
	PASSWORD_ERROR,
	PASSWORD_OPERATION_SUCCEEDED,
	PASSWORD_STATE,
	PASSWORD_USER
} from '../../constants/PasswordStateFieldNames.js';
import {PASSWORD_TOO_WEAK} from '../../constants/PasswordStateReducerStates.js';

function getPasswordStore(state) {
	return state.passwordState;
}

export const selectPasswordState = createSelector(getPasswordStore, store => store.get(PASSWORD_STATE));
export const selectIsPasswordTooWeak = createSelector(selectPasswordState, state => state === PASSWORD_TOO_WEAK);
export const selectIsPasswordInvalid = selectIsPasswordTooWeak;
export const selectUserName = createSelector(getPasswordStore, store => store.get(PASSWORD_USER, null));
export const selectIsBusy = createSelector(getPasswordStore, store => store.get(PASSWORD_BUSY, false));
export const selectError = createSelector(getPasswordStore, store => store.get(PASSWORD_ERROR, null));
export const selectOperationSucceeded = createSelector(
	getPasswordStore, 
	store => store.get(PASSWORD_OPERATION_SUCCEEDED, 
		false)
);

