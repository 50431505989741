import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../webview/commons/containers/SynFormattedMessageContainer.js';
import {useEffectEasily, useMemoFactory} from '../../webview/commons/utils/customHooks';
import Toast from '../../webview/ui/components/Toast.js';

export default React.memo(UploadAreaToast);

function UploadAreaToast(props) {
	const {status} = props;
	const toastMessage = useMemoFactory(createToastMessage, status);
	const [open, setOpen] = useState(false);
	useEffectEasily(() => setOpen(Boolean(status)), setOpen, status);
	const handleToastClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);
	return <Toast message={toastMessage} open={open} onClose={handleToastClose} />;
}

UploadAreaToast.propTypes = {
	status: PropTypes.shape({
		addedFiles: PropTypes.number.isRequired,
		ignoredFiles: PropTypes.number.isRequired
	})
};

function createToastMessage(status) {
	const {addedFiles = 0, ignoredFiles = 0} = status || {};
	return (
		<React.Fragment>
			{addedFiles >= 0 && (
				<SynFormattedMessage element='div' message='FilesAdded' messageParams={{count: addedFiles}} />
			)}
			{ignoredFiles > 0 && (
				<SynFormattedMessage element='div' message='FilesIgnored' messageParams={{count: ignoredFiles}} />
			)}
		</React.Fragment>
	);
}
