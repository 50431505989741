import React from 'react';
import PropTypes from 'prop-types';

import TreeViewGroup from '../../a11y/treeView/TreeViewGroup.js';
import TreeViewItem from '../../a11y/treeView/TreeViewItem.js';
import Collapsible from '../../commons/components/Collapsible.js';
import {memoizeByFirstArg} from '../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import {
	getDocumentId,
	getNodeId,
	getNodeLabel,
	isCategoryNode,
	isDocumentNode,
	isNotAssignedDocumentsCategoryNode
} from '../data/PatientDetailsDataUtils.js';
import PatientDetailsDocumentContainer from '../flux/containers/PatientDetailsDocumentContainer.js';
import PatientDetailsCategoryHeader from './PatientDetailsCategoryHeader.js';

export default class PatientDetailsNodeRenderer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.memoizedGetNumDocuments = memoizeByFirstArg(PatientDetailsNodeRenderer.#countDocuments);
	}

	render() {
		const {node} = this.props;
		const numCategories = node.filter(isCategoryNode).size;
		let categoryPosition = 0;
		return node.map(branch => (isDocumentNode(branch)
			? this.renderDocument(getDocumentId(branch), getNodeLabel(branch))
			: this.renderCategory(branch, numCategories, ++categoryPosition))
		).toArray();
	}

	renderDocument(documentId, label) {
		const {
			itemsPerRow, tileWidth, expandedDocuments, viewportDimensions, scrollOffset,
			initiallySelectedDocumentIds, onExpandDocument, onCollapseDocument, categoryDepth
		} = this.props;
		const isExpanded = expandedDocuments.has(documentId);
		return (
			<li key={documentId} className='patient-details-document-list--entry'>
				<PatientDetailsDocumentContainer documentId={documentId} expandedDocuments={expandedDocuments}
												 isExpanded={isExpanded}
												 initiallySelectedDocumentIds={initiallySelectedDocumentIds}
												 onExpand={onExpandDocument}
												 onCollapse={onCollapseDocument}
												 itemsPerRow={itemsPerRow} totalTileWidth={tileWidth}
												 viewportDimensions={viewportDimensions}
												 scrollOffset={scrollOffset}
												 categoryDepth={categoryDepth}
												 label={label} />
			</li>
		);
	}

	renderCategory(node, numCategories, categoryPosition) {
		const {
			itemsPerRow, tileWidth, onExpandCategory, onCollapseCategory, expandedCategories, categoryDepth
		} = this.props;
		const categoryId = getNodeId(node);
		const isExpanded = expandedCategories.has(categoryId);
		const remainingProps = cloneWithoutProperties(this.props, 'node', 'categoryDepth');

		return node.has('children') && !node.get('children').isEmpty()
			? (
				<TreeViewItem key={categoryId} className='patient-details-category--entry' ariaLevel={categoryDepth + 1}
				              ariaExpanded={isExpanded} ariaSetSize={numCategories} ariaPosInSet={categoryPosition}>
					<PatientDetailsCategoryHeader label={getNodeLabel(node)}
					numChildItems={this.memoizedGetNumDocuments(node)}
					onExpand={onExpandCategory}
					onCollapse={onCollapseCategory}
					isNotAssignedDocumentsCategory={isNotAssignedDocumentsCategoryNode(node)}
					categoryId={categoryId}
					itemsPerRow={itemsPerRow}
					totalTileWidth={tileWidth}
					categoryDepth={categoryDepth}
					isExpanded={isExpanded} />
					<Collapsible isExpanded={isExpanded} className='patient-details-document-list--container' element={TreeViewGroup} removeCollapsedChildren>
						<PatientDetailsNodeRenderer node={node.get('children')} categoryDepth={categoryDepth + 1} {...remainingProps} />
					</Collapsible>
				</TreeViewItem>
			)
			: null;
	}

	static #countDocuments(node) {
		if (isCategoryNode(node)) {
			let numDocuments = 0;
			const children = node.get('children');
			if (children) {
				numDocuments = children
					.map(PatientDetailsNodeRenderer.#countDocuments)
					.reduce((docs, count) => docs + count, 0);
			}
			return numDocuments;
		}
		return 1;
	}
}

PatientDetailsNodeRenderer.propTypes = {
	node: PropTypes.object,
	itemsPerRow: PropTypes.number,
	tileWidth: PropTypes.number,
	expandedDocuments: PropTypes.object,
	viewportDimensions: PropTypes.object,
	scrollOffset: PropTypes.number,
	initiallySelectedDocumentIds: PropTypes.object,
	expandedCategories: PropTypes.object,
	categoryDepth: PropTypes.number,
	onExpandDocument: PropTypes.func,
	onCollapseDocument: PropTypes.func,
	onExpandCategory: PropTypes.func,
	onCollapseCategory: PropTypes.func
};
