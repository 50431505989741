import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {USE_LOSSLESS_IMAGE_COMPRESSION} from '../../../commons/constants/SynSettingsConstants';
import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import {UNINITIALIZED_ANNOTATION_PROPERTIES} from '../../constants/AnnotationConstants.js';
import DicomImage from '../../data/DicomImage';
import DeleteAnnotationIconContainer from '../../flux/containers/DeleteAnnotationIconContainer.js';
import {
	toImagePosition
} from '../../utils/AnnotationUtils.js';
import createAnnotation from './createAnnotation.js';
import TextAnnotationRenderer from './TextAnnotationRenderer.js';

import '../../../../styles/viewer/components/annotations/HounsfieldMeasurement.scss';

function isHounsfielMeasementSupported(viewerItem) {
	return (
		viewerItem && 
		viewerItem.get('type') === 'image' && 
		viewerItem.get('dicomDump').getIsGreyscaleImage() &&
		USE_LOSSLESS_IMAGE_COMPRESSION
	);
}

function getDefaultPropertiesForHounsfieldMeasurement(props) {
	return Immutable.Map({
		points: [toImagePosition(props, vec2.set(vec2.create(), 0.0, 0.0))]
	});
}

function HounsfieldMeasurement(props) {
	const {
		annotationProperties, viewerItem
	} = props;

	const [point] = annotationProperties.get('points');
	const [x, y] = point;
	const isHU = viewerItem.isHounsfieldValueAvailable;
	const greyScaleValue = viewerItem.getGrayscaleValue(x, y);
	let labelText = `${greyScaleValue} ${isHU ? 'HU' : ''}`;
	if (greyScaleValue === null) {
		// don't let the icon jump so tell renderMultilineTextElements there is still a line
		labelText = '\0\n';
	}

	return (
		<TextAnnotationRenderer iconComponents={[DeleteAnnotationIconContainer]}
			labelText={labelText} {...props} />
	);
}

HounsfieldMeasurement.propTypes = {
	annotationProperties: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.oneOf([UNINITIALIZED_ANNOTATION_PROPERTIES])
	]),
	viewerItem: PropTypes.instanceOf(DicomImage)
};

export default createAnnotation(
	isHounsfielMeasementSupported, getDefaultPropertiesForHounsfieldMeasurement, HounsfieldMeasurement
);
