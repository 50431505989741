import {all} from 'redux-saga/effects';

import allAuthErrorsTask from './allAuthErrorsTask.js';
import collectDeviceMetricsTask from './collectDeviceMetricsTask.js';
import keepAliveTask from './keepAliveTask.js';
import logoutTask from './logoutTask.js';
import sessionAttachTask from './sessionAttachTask.js';
import userLoginTask from './userLoginTask.js';

export default function* sessionSaga() {
	yield all([
		userLoginTask(),
		sessionAttachTask(),
		logoutTask(),
		keepAliveTask(),
		allAuthErrorsTask(),
		collectDeviceMetricsTask()
	]);
}
