import React from 'react';

import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import ViewerToolbarButton from './ViewerToolbarButton.js';

export default function createViewerToolbarIconButton(Icon) {
	return withForwardRef(props => {
		const {forwardRef, ...remainingProps} = props;
		return <ViewerToolbarButton {...remainingProps} ref={forwardRef} icon={<Icon />} />;
	}, 'forwardRef');
}
