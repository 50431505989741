import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
	SYNC_POINT_STATE_IN_SLICE,
	SYNC_POINT_STATE_NOT_IN_SLICE,
	SYNC_POINT_STATE_UNDEFINED
} from '../../../commons/constants/SyncPointStates.js';
import {SYNC_CIRCLE_RADIUS} from './AnnotationConstants.js';

import '../../../../styles/viewer/components/annotations/SyncPointMarker.scss';

const RADIUS_TICK_RATIO = 0.375; // Similar to the material design icon ic_location_searching
const TICK_LENGTH = SYNC_CIRCLE_RADIUS * RADIUS_TICK_RATIO;
const RADIUS_PLUS_TICK_LENGTH = SYNC_CIRCLE_RADIUS + TICK_LENGTH;
const TARGET_TICKS_PATH =
	`m${-RADIUS_PLUS_TICK_LENGTH} 0 h${TICK_LENGTH} m${2 * SYNC_CIRCLE_RADIUS} 0 h${TICK_LENGTH} ` +
	`m${-RADIUS_PLUS_TICK_LENGTH} ${-RADIUS_PLUS_TICK_LENGTH} v${TICK_LENGTH} m0 ${2 * SYNC_CIRCLE_RADIUS} v${TICK_LENGTH}`;
const OUT_OF_SLICE_STATE_PATH = `m${-SYNC_CIRCLE_RADIUS} ${-SYNC_CIRCLE_RADIUS} l${2 * SYNC_CIRCLE_RADIUS} ${2 * SYNC_CIRCLE_RADIUS}`;

export default function SyncPointMarker({referencePoint, isPrintPreview, syncPointState}) {
	const posX = referencePoint[0];
	const posY = referencePoint[1];
	const className = classNames({
		'sync-point-marker': true,
		'not-in-slice-state': syncPointState === SYNC_POINT_STATE_NOT_IN_SLICE,
		'undefined-state': syncPointState === SYNC_POINT_STATE_UNDEFINED
	});
	const fragments = [
		<circle key='circle' className='sync-point-marker--circle' cx='0' cy='0' r={SYNC_CIRCLE_RADIUS} />,
		(syncPointState === SYNC_POINT_STATE_UNDEFINED
			? <path key='state' d={OUT_OF_SLICE_STATE_PATH} />
			: <path key='state' d={TARGET_TICKS_PATH} />
		)
	];
	return (
		<g className={className} transform={`translate(${posX}, ${posY})`}>
			{
				isPrintPreview && fragments.map(fragment => React.cloneElement(
					fragment, {
						key: `${fragment.key}--contour`,
						className: `sync-point-marker--contour ${fragment.props.className}`
					})
				)
			}
			{fragments}
		</g>
	);
}

SyncPointMarker.propTypes = {
	referencePoint: PropTypes.instanceOf(Float32Array),
	isPrintPreview: PropTypes.bool,
	syncPointState: PropTypes.oneOf(
		[SYNC_POINT_STATE_NOT_IN_SLICE, SYNC_POINT_STATE_IN_SLICE, SYNC_POINT_STATE_UNDEFINED])
};
