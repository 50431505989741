import {createSelector} from 'reselect';

import {
	DEVICE_ORIENTATION_LANDSCAPE,
	DEVICE_ORIENTATION_PORTRAIT,
	DEVICE_SIZE_LARGE,
	DEVICE_SIZE_MEDIUM,
	DEVICE_SIZE_SMALL,
	DEVICE_TYPE_MOBILE,
	MAXIMUM_EXTRA_SMALL_WIDTH,
	MAXIMUM_LARGE_WIDTH,
	MAXIMUM_SMALL_WIDTH
} from '../../commons/constants/DeviceInformation.js';
import {deduceDeviceType, isSideBarElevated} from '../UIUtils.js';

// Store selector
export const uiSelector = createSelector(state => state.ui, ui => ui);

// Device Information
export const uiWindowSize = createSelector(uiSelector, ui => ui.get('windowSize'));
export const uiWindowScreenSize = createSelector(uiSelector, ui => ui.get('windowScreenSize', ui.get('windowSize')));
export const uiDeviceType = createSelector(uiWindowScreenSize, deduceDeviceType);
export const uiIsMobileDevice = createSelector(uiDeviceType, type => type === DEVICE_TYPE_MOBILE);
export const uiIsSmallDevice = createSelector(uiWindowScreenSize, size => size.get('width') <= MAXIMUM_SMALL_WIDTH);
export const uiIsExtraSmallDevice = createSelector(uiWindowScreenSize, size => size.get('width') <= MAXIMUM_EXTRA_SMALL_WIDTH);
export const uiIsExtraLargeDevice = createSelector(uiWindowScreenSize, size => size.get('width') > MAXIMUM_LARGE_WIDTH);
export const uiDeviceSize = createSelector(uiIsMobileDevice, uiIsSmallDevice, (isMobile, isSmall) => {
	let size = DEVICE_SIZE_SMALL;
	if (!isMobile) {
		size = DEVICE_SIZE_LARGE;
	} else if (!isSmall) {
		size = DEVICE_SIZE_MEDIUM;
	}
	return size;
});
export const uiDeviceOrientation = createSelector(uiWindowSize, windowSize => (windowSize.get('width') > windowSize.get('height') ? DEVICE_ORIENTATION_LANDSCAPE : DEVICE_ORIENTATION_PORTRAIT));

// Sidebar state
export const uiSidebarModeElevatedSelector = createSelector(uiWindowScreenSize, isSideBarElevated);
export const uiIsSidebarVisible = createSelector(uiSelector, uiSidebarModeElevatedSelector,
	(ui, elevated) => ui.get('sidebar').get(elevated ? 'visibleOnMobile' : 'visibleOnDesktop')
);
export const uiSidebarTransitionsEnabled = createSelector(uiSelector,
	ui => ui.get('sidebar').get('transitionsEnabled')
);
export const uiShowPrintLayout = createSelector(uiSelector, ui => ui.get('showPrintLayout', false));

export const uiShowPrivacyPolicyDialog = createSelector(uiSelector, ui => ui.get('showPrivacyPolicyDialog'));
export const uiShowChangePasswordDialog = createSelector(uiSelector, ui => ui.get('showChangePasswordDialog'));
export const uiIsFullscreenElementVisible = createSelector(uiSelector, ui => ui.get('fullScreenElementIsVisible'));
export const uiDevicePixelRatio = createSelector(uiSelector, ui => ui.get('devicePixelRatio', 1));
