import React, {useCallback, useState} from 'react';
import {fromEvent} from 'file-selector';
import PropTypes from 'prop-types';

import DomEventsManager from '../../../events/DomEventsManager.js';
import HorizontalLayout from '../../../ui/components/layout/HorizontalLayout.js';
import Invisible from '../../../ui/components/layout/Invisible.js';
import {useEffectEasily, useMemoFactory} from '../../utils/customHooks';
import {testFileInputDirectory} from '../../utils/DOMUtils.js';
import {mightBeIOS} from '../../utils/FeatureDetectionUtils.js';
import {callSafe} from '../../utils/FunctionUtils.js';

const DEFAULT_CAMERA_ACCEPT = 'image/*';

export default function FileSelectionForm(props) {
	const {
		multi, capture, handleFileList, accept, enableDirectory, fileButton, directoryButton, className, cameraButton,
		children
	} = props;
	const [domEventsManager] = useState(new DomEventsManager());
	const [fileInputElement, setFileInputElement] = useState(null);
	const [cameraInputElement, setCameraInputElement] = useState(null);
	const [directoryInputElement, setDirectoryInputElement] = useState(null);
	const handleFileButtonClick = useInputButtonClickHandler(fileInputElement);
	const handleCameraButtonClick = useInputButtonClickHandler(cameraInputElement);
	const handleDirectoryButtonClick = useInputButtonClickHandler(directoryInputElement);
	const fileButtonElement = useMemoFactory(prepareUploadButton, fileButton, handleFileButtonClick);
	const cameraButtonElement = useMemoFactory(prepareUploadButton, cameraButton, handleCameraButtonClick);
	const directoryButtonElement = useMemoFactory(prepareUploadButton, directoryButton, handleDirectoryButtonClick);
	const handleOnChange = useMemoFactory(
		createHandleOnChange, handleFileList, fileInputElement, cameraInputElement, directoryInputElement
	);
	useEffectEasily(
		handleInputFieldHandlers, domEventsManager, handleOnChange, fileInputElement, cameraInputElement,
		directoryInputElement
	);
	const directorySupported = enableDirectory && directoryButtonElement && testFileInputDirectory() && !mightBeIOS();
	const cameraAccept = accept || DEFAULT_CAMERA_ACCEPT;
	const childrenJustify = getChildrenJustify(directorySupported, children);
	return (
		<div className={className}>
			<Invisible component='form'>
				<input ref={setFileInputElement} type='file' accept={accept} multiple={multi} capture={capture} />
				<input ref={setCameraInputElement} type='file' accept={cameraAccept} multiple={multi} capture='environment' />
				<input ref={setDirectoryInputElement} type='file' multiple={multi} accept={accept} webkitdirectory='webkitdirectory' />
			</Invisible>
			{fileButtonElement}
			{cameraButtonElement}
			<HorizontalLayout justify={childrenJustify}>
				{directorySupported && directoryButtonElement}
				{children}
			</HorizontalLayout>
		</div>
	);
}

FileSelectionForm.propTypes = {
	accept: PropTypes.string,
	handleFileList: PropTypes.func.isRequired,
	capture: PropTypes.string,
	multi: PropTypes.bool,
	enableDirectory: PropTypes.bool,
	fileButton: PropTypes.node,
	cameraButton: PropTypes.node,
	directoryButton: PropTypes.node,
	className: PropTypes.string
};

FileSelectionForm.defaultProps = {
	enableDirectory: false,
	multi: false
};

function getChildrenJustify(directorySupported, children) {
	if (directorySupported && children) {
		return 'between';
	} else if (directorySupported) {
		return 'start';
	}
	return 'end';
}

function createHandleOnChange(handleFileList, ...elements) {
	return async event => {
		const files = await fromEvent(event);
		if (files.length > 0) {
			callSafe(handleFileList, files);
		}
		elements.forEach(element => clearInputField(element));
	};
}

function clearInputField(element) {
	if (element) {
		window.requestAnimationFrame(() => {
			element.value = null;
		});
	}
}

function prepareUploadButton(buttonComponent, handleFunction) {
	if (buttonComponent) {
		return React.cloneElement(buttonComponent, {onClick: handleFunction});
	}
	return null;
}

function useInputButtonClickHandler(inputElement) {
	return useCallback(() => {
		if (inputElement !== null) {
			inputElement.click();
		}
	}, [inputElement]);
}

function handleInputFieldHandlers(domEventsManager, handleOnChange, ...elements) {
	elements.forEach(element => element && domEventsManager.addEventListener(element, 'change', handleOnChange));
	return () => domEventsManager.removeAllListeners();
}
