/*
 * This file polyfills text [1] and arrayBuffer [2] on the prototypes of File and Blob.
 * According to MDN these methods are not supported on:
 *  - internet explorer
 *  - Firefox on Android#
 *
 * [1] - https://developer.mozilla.org/en-US/docs/Web/API/Blob/text
 * [2] - https://developer.mozilla.org/en-US/docs/Web/API/Blob/arrayBuffer
 */

/*
 * Yes I know disabling eslint-rules is ugly.
 * But I don't know any other way to get rid of the flagged errors
 * in the implementation of the polyfills for now.
 */
/* eslint-disable no-invalid-this */

const POLYFILLS = {
	text: textViaFileReader,
	arrayBuffer: arrayBufferViaFileReader
};
const POLYFILLED_OBJECTS = [Blob, File];
applyPolyfills(POLYFILLS, POLYFILLED_OBJECTS);

function applyPolyfills(polyfills, constructors) {
	Object.keys(polyfills).forEach(polyfill => {
		constructors.forEach(cot => {
			if (needsPolyfill(cot, polyfill)) {
				applyPolyfill(cot, polyfill, POLYFILLS[polyfill]);
			}
		});
	});
}

function needsPolyfill(Constructor, method) {
	return Constructor.prototype[method] === undefined;
}
function applyPolyfill(Constructor, method, polyfill) {
	Constructor.prototype[method] = polyfill;
}

function textViaFileReader() {
	const self = this;
	return promiseFileReaderResult(fileReader => {
		fileReader.readAsText(self);
	});
}

function arrayBufferViaFileReader() {
	const self = this;
	return promiseFileReaderResult(fileReader => {
		fileReader.readAsArrayBuffer(self);
	});
}

function promiseFileReaderResult(startRead) {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.onload = () => resolve(fileReader.result);
		fileReader.onerror = () => reject(fileReader.error);
		fileReader.onabort = () => reject(new Error('Read aborted.'));
		startRead(fileReader);
	});
}
