import {createSingleFieldValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';

export function regexValidatorFunction(locale, translatedFieldLabel, regex, errorMessage, translator, fieldValue) {
	return (fieldValue !== null && fieldValue !== undefined) && regex.test(`${fieldValue}`) ||
		translator.getFormattedMessage(errorMessage, locale, {inputFieldLabel: translatedFieldLabel, regex});
}

export function createRegExValidator(regex, errorMessage = 'RegExDoesNotMatch', translator = ValidatorsMessagesTranslator) {
	return createSingleFieldValidatorFactory(
		(locale, translatedFieldLabel, fieldValue) => regexValidatorFunction(
			locale, translatedFieldLabel, regex, errorMessage, translator, fieldValue
		)
	);
}
