import React, {useCallback} from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';

import AnnotationsTranslator from '../../../i18n/translators/AnnotationsTranslator.js';
import DeleteAnnotationIconContainer from '../../flux/containers/DeleteAnnotationIconContainer.js';
import {
	calculateLabelProperties,
	determinePixelSize,
	ERROR_POLYLINE_INTERSECTIONS, getAnnotationDisplayPrecision, getAreaWithUnit, getLengthWithUnit,
	getLineAroundContainerCenter,
	measureArea,
	measureLength,
	renderTransformedAnnotationIconComponents,
	toContainerPosition,
	toImagePosition
} from '../../utils/AnnotationUtils.js';
import {createRectangle} from '../../utils/math/Rectangle.js';
import {renderMultilineTextElements} from '../../utils/SVGUtils.js';
import {getNormalizedDirection} from '../../utils/VectorUtils.js';
import {getDicomDump} from '../../utils/ViewerItemUtils.js';
import {TOUCH_SIZE} from './AnnotationConstants.js';
import AnnotationIconsGroup from './AnnotationIconsGroup.js';
import AnnotationLabel from './AnnotationLabel.js';
import createAnnotation from './createAnnotation.js';
import ModifiablePath from './ModifiablePath.js';

export default createAnnotation(
	function isDistanceMeasurementSupported(viewerItem) {
		return isLoadedDicomImage(viewerItem) && hasCorrectPixelSpacing(viewerItem);
	},
	function getInitialMeasurementProperties(props) {
		return Immutable.Map.of(
			'points', getLineAroundContainerCenter(props).map(point => toImagePosition(props, point))
		);
	},
	function PolyLineAnnotationRenderer(props) {
		const {
			AnnotationRoot, annotationId, onAnnotationPropertiesChanged, annotationProperties,
			transformationMatrix, inverseTransformationMatrix, viewerItem, isPrintPreview,
			containerWidth, containerHeight, readOnly, locale, fontSize, lineHeight
		} = props;
		const viewportRect = createRectangle(
			[-containerWidth / 2, -containerHeight / 2],
			[containerWidth / 2, containerHeight / 2]
		);
		const points = annotationProperties.get('points');
		const closed = annotationProperties.get('closed', false);
		const onPointsUpdate = useCallback(newPoints => {
			onAnnotationPropertiesChanged(annotationId, annotationProperties.set('points', newPoints));
		}, [annotationId, annotationProperties, onAnnotationPropertiesChanged]);
		const containerPositions = points.map(point => toContainerPosition(props, point));
		const firstDirectionalVector = getNormalizedDirection(containerPositions[0], containerPositions[1]);
		const labelInformation = calculateLabelInformation(
			locale, points, getDicomDump(viewerItem), closed, lineHeight
		);
		const labelProperties = calculateLabelProperties(
			containerPositions, viewportRect, fontSize, isPrintPreview || readOnly
		);
		const deleteIconPosition = vec2.scale(firstDirectionalVector, firstDirectionalVector, -TOUCH_SIZE);

		return (
			<AnnotationRoot>
				<ModifiablePath readOnly={readOnly} isPrintPreview={isPrintPreview} points={points} closed={closed}
				                inverseTransformationMatrix={inverseTransformationMatrix}
				                transformationMatrix={transformationMatrix} onPointsUpdate={onPointsUpdate} />
				<AnnotationLabel {...labelProperties}>
					{labelInformation}
				</AnnotationLabel>
				<AnnotationIconsGroup position={containerPositions[0]} readOnly={readOnly}>
					{props.isPrintPreview
						? false
						: renderTransformedAnnotationIconComponents(
							[DeleteAnnotationIconContainer], [deleteIconPosition], annotationId
						)
					}
				</AnnotationIconsGroup>
			</AnnotationRoot>
		);
	}
);

function isLoadedDicomImage(viewerItem) {
	const isValidViewerItem = (viewerItem && viewerItem.get('type') === 'image' && getDicomDump(viewerItem));
	return isValidViewerItem && hasCorrectPixelSpacing(viewerItem);
}

function hasCorrectPixelSpacing(viewerItem) {
	const pixelSpacing = viewerItem.get('dicomDump').getPixelSpacing();
	return pixelSpacing && pixelSpacing[0] > 0 && pixelSpacing[1] > 0;
}

function calculateLabelInformation(locale, points, dicomDump, closed, lineHeight) {
	const pixelSize = determinePixelSize(points, dicomDump);
	const canMeasurePhysicalLength = Boolean(pixelSize);
	let information;
	if (canMeasurePhysicalLength) {
		information = calculateLabel(points, closed, locale, pixelSize);
	} else {
		information = AnnotationsTranslator.getFormattedMessage('MeasurementNotPossible', locale);
	}
	return renderMultilineTextElements(information, lineHeight, {x: '0'});
}

function calculateLabel(points, closed, locale, pixelSize) {
	const lengthLabel = AnnotationsTranslator.getFormattedMessage('Length', locale);
	const areaLabel = AnnotationsTranslator.getFormattedMessage('Area', locale);
	const lengthWithUnit = getLengthWithUnit(measureLength(points, pixelSize, closed));
	let label = `${lengthLabel}: ${lengthWithUnit}`;
	if (closed) {
		//can be optimized by using the original points or additionally Bentley–Ottmann sweep line algorithm
		let areaInfo = measureArea(points, pixelSize);
		if (areaInfo === ERROR_POLYLINE_INTERSECTIONS) {
			areaInfo = `${AnnotationsTranslator.getFormattedMessage('PolygonIntersection', locale)}!`;
		} else {
			const areaWithUnit = getAreaWithUnit(areaInfo, getAnnotationDisplayPrecision());
			areaInfo = `${areaLabel}: ${areaWithUnit}`;
		}
		label += `\n${areaInfo}`;
	}
	return label;
}
