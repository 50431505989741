import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes, {bool, number} from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import DeviceInfo from '../../commons/bricks/DeviceInfo.js';
import {WEBAPP_NAME} from '../../commons/constants/EnvironmentConstants.js';
import {GenericFileDownloadMenuButtonContainer} from '../flux/containers/DownloadMenuButtonContainer.js';
import {GenericImageAnnotationMenuEntriesContainer} from '../flux/containers/ImageAnnotationsMenuEntriesContainer.js';
import {GenericFileDetailsDialogContainer} from '../flux/containers/ViewerDetailsDialogContainer.js';
import {selectGenericFileViewerItem} from '../flux/selectors/ViewerItemsSelectors.js';
import ImageViewerToolsMenu from './ImageViewerToolsMenu.js';
import InfoDialogToolbarButton from './InfoDialogToolbarButton.js';
import ViewerFullscreenToolbarButton from './ViewerFullscreenToolbarButton.js';
import ViewerToggleAnnotationsButton from './ViewerToggleAnnotationsButton.js';
import ViewerToggleAnnotationsToolsMenu from './ViewerToggleAnnotationsToolsMenu.js';
import ViewerToolbar from './ViewerToolbar.js';

class GenericViewerToolbar extends React.PureComponent {
	render() {
		const {fileId, percent, showTools = false, isMobileDevice} = this.props;
		return (
			<ViewerToolbar percent={percent}>
				<ViewerFullscreenToolbarButton />
				{!isMobileDevice && showTools && <ViewerToggleAnnotationsButton />}
				<InfoDialogToolbarButton key='info'>
					<GenericFileDetailsDialogContainer fileId={fileId} style={{display: 'inline-block'}} />
				</InfoDialogToolbarButton>
				{WEBAPP_NAME !== 'webpatient' && <GenericFileDownloadMenuButtonContainer fileId={fileId} />}
				{showTools && (
					<ImageViewerToolsMenu>
						<GenericImageAnnotationMenuEntriesContainer fileId={fileId} />
						{isMobileDevice && <ViewerToggleAnnotationsToolsMenu />}
					</ImageViewerToolsMenu>
				)}
			</ViewerToolbar>
		);
	}
}

GenericViewerToolbar.propTypes = {
	fileId: number.isRequired,
	percent: number,
	showTools: bool,
	isMobileDevice: bool
};

export default function GenericViewerToolbarContainer(props) {
	const {fileId, showTools} = props;
	const fileSelector = useMemo(() => selectGenericFileViewerItem(fileId), [fileId]);
	const file = useSelector(fileSelector);
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	return (<GenericViewerToolbar fileId={fileId}
		percent={file.get('loadedPercent', 0)} showTools={showTools}
		isMobileDevice={isMobileDevice} />);
}
GenericViewerToolbarContainer.propTypes = {
	fileId: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]),
	showTools: PropTypes.bool
};
