import React from 'react';
import intersections from '2d-polygon-self-intersections';
import {mat2d, vec2} from 'gl-matrix';
import _partial from 'lodash.partial';

import {measureText} from '../../commons/utils/DOMUtils.js';
import {memoizeByFirstArg} from '../../commons/utils/FunctionUtils.js';
import {findProperUnit} from '../../commons/utils/NumberUtils.js';
import {LABEL_TEXT_PROPERTY_NAME, TOUCH_SIZE} from '../components/annotations/AnnotationConstants.js';
import {
	calculateRectArea,
	confineRect,
	confineRectInplace, containsPoint2d,
	createRectangle,
	distanceToPoint2d,
	getCenter,
	intersectRects,
	moveRectInplace,
	overlap2d, resizeRect
} from './math/Rectangle.js';
import {clipToRectangle2d, createSegment} from './math/Segment.js';
import {RAD_45_DEGREES, RAD_180_DEGREES, RAD_360_DEGREES, rad2deg, round} from './MathUtils.js';
import {isAreaRegion} from './UltrasoundRegionUtils.js';
import {getNormalizedDirection, perpendicularVector} from './VectorUtils.js';

export const MEASUREMENT_NOT_POSSIBLE = Symbol('MEASUREMENT_NOT_POSSIBLE');
export const ERROR_POLYLINE_INTERSECTIONS = Symbol('ERROR_POLYLINE_INTERSECTIONS');
const MM_PER_CM = 10.0;
const QUARTER_DENOMINATOR = 4;
const INTERSECTING_POLYGON_MIN_VERTICES = 3;
const NUM_ELLIPSE_INTERPOLATION_POINTS = 50;

const ANNOTATION_UNITS = [
	{factor: 1, symbol: 'mm'},
	{factor: 1000, symbol: 'μm'}
];

const ANNOTATION_SQUARE_UNITS = ANNOTATION_UNITS.map(unit => {
	const {factor, symbol} = unit;
	return {
		factor: factor * factor,
		symbol: `${symbol}²`
	};
});

/**
 * returns the precision the annotations are displayed in.
 */
export function getAnnotationDisplayPrecision() {
	return 1;
}

export function getEllipsePoints(a, b, center = vec2.create(), rotation = 0) {
	const normalizedRotation = rotation > RAD_180_DEGREES ? rotation - RAD_180_DEGREES : rotation;
	const delta = RAD_360_DEGREES / NUM_ELLIPSE_INTERPOLATION_POINTS;
	const points = [];
	for (let t = 0; t <= NUM_ELLIPSE_INTERPOLATION_POINTS; ++t) {
		const angel = t * delta + RAD_45_DEGREES;
		const newPoint = vec2.fromValues(
			a * Math.cos(angel) + center[0],
			b * Math.sin(angel) + center[1]
		);
		if (rotation !== 0) {
			vec2.rotate(newPoint, newPoint, center, normalizedRotation);
		}
		points.push(newPoint);
	}
	return points;
}

/**
 * Returns an array of vec2 that represent a line that goes through the center of the viewport
 * specified by containerWidth, containerHeight.
 * @param containerWidth (number) the width in pixels of the container
 * @param containerHeight (number) the height in pixels of the container
 * @returns {[vec2,vec2]}
 */
export function getLineAroundContainerCenter({containerWidth, containerHeight}) {
	return [
		vec2.set(vec2.create(), (-containerWidth / QUARTER_DENOMINATOR), containerHeight / QUARTER_DENOMINATOR),
		vec2.set(vec2.create(), containerWidth / QUARTER_DENOMINATOR, (-containerHeight / QUARTER_DENOMINATOR))
	];
}

/**
 * Uses the property transformationMatrix on the passed props to transform the passed point.
 * This property is expected to contain a matrix that performs the Image -> Container transformation.
 * @param props ({transformationMatrix: Mat3}) render properties containing the transformation matrix.
 * @param point (vec2) the point to transform
 * @returns {vec2}
 */
export function toContainerPosition(props, point) {
	const {transformationMatrix} = props;
	return vec2.transformMat3(vec2.create(), point, transformationMatrix);
}

/**
 * Uses the property inverseTransformationMatrix on the passed props to transform the passed point.
 * This property is expected to contain a matrix that performs the Container -> Image transformation.
 * @param props ({inverseTransformationMatrix: Mat3}) render properties containing the inverse transformation matrix.
 * @param point (vec2) the point to transform
 * @returns {vec2}
 */
export function toImagePosition(props, point) {
	const {inverseTransformationMatrix} = props;
	return vec2.transformMat3(vec2.create(), point, inverseTransformationMatrix);
}

/**
 * Convenience function to extract the label text from the annotation properties.
 * @param annotationProperties {Immutable.Map} all annotation properties.
 * @param defaultText {string} a possible default text if the property is unset (default: '')
 * @returns {string} the currently set label text or the defaultText if unset.
 */
export function getAnnotationLabelText(annotationProperties, defaultText = '') {
	return annotationProperties.get(LABEL_TEXT_PROPERTY_NAME, defaultText);
}

/**
 * Creates a memoized version of measureLine to avoid remeasuring already measured text.
 * NOTE: The memoized values never expire! So be careful with long living memoized functions!
 */
export function createMemoizedTextMeasureTool() {
	return memoizeByFirstArg(measureText, fontMeasureCacheKeyResolver);
}
function fontMeasureCacheKeyResolver(...parts) {
	return parts.join('');
}

/**
 * Renders the passed Components in the parameter Components distributed around thd origin at the given radius and
 * item distance.
 * @param Components {[React.Component]} Components to be rendered in a circle around the anchorPoint
 * @param annotationMainDirection {vec2} unit-vector describing the main expanse of the annotation from the anchor
 * @param annotationId {number} id of the annotation to remove if the icon is clicked
 * @param itemDistance {float} distance between the items.
 * @param radius {float} radius at which to distribute the items
 * @returns {Array} an array of React component instances.
 */
export function renderAnnotationToolIconComponents(
		Components, annotationMainDirection, annotationId,
		itemDistance = TOUCH_SIZE, radius = TOUCH_SIZE
) {
	const radiusToItemDistanceRatio = itemDistance / radius;
	const componentPoints = circularDistribution(
		vec2.scale(vec2.create(), annotationMainDirection, -radius),
		radiusToItemDistanceRatio, Components.length
	);
	return renderTransformedAnnotationIconComponents(Components, componentPoints, annotationId);
}

/**
 * Renders all components specified in Components at the corresponding position in positions.
 * This implies that the length of both arrays must be the same!
 * Each Component gets passed the following properties:
 *  - annotationId: the annotation id of the annotation it is rendered for.
 *  - style: a react style object that has the necessary transformation applied to position the icon correctly.
 * @param Components {[React.Component]} components to render
 * @param positions {[vec2]} positions to transform the components to
 * @param annotationId {number} id of the annotation the components are rendered for.
 */
export function renderTransformedAnnotationIconComponents(Components, positions, annotationId) {
	if (Components.length !== positions.length) {
		throw Error('Number of given Components does not match the number of positions.');
	}
	return Components.map((CurrentComponent, index) => {
		const key = `${annotationId}-icon-${index}`;
		const iconPoint = positions[index];
		const iconProperties = {
			transform: `translate(${iconPoint[0]}, ${iconPoint[1]})`,
			annotationId
		};
		return <CurrentComponent key={key} {...iconProperties} />;
	});
}

/**
 * Creates nrOfItems vec2 instances representing normalized vectors describing normalized positions around the origin.
 * These vectors are calculated in such a way, that the passed radiusToItemDistanceRatio is satisfied for two adjacent
 * points in the returned array.
 * This means that the distance between two adjacent points, can be calculated by multiplying the used radius with
 * the radiusToItemDistanceRatio.
 * The parameter distributionMainDistance describes the orientation of the distribution. All other points are
 * distributed equally counter- and clockwise relative to this vector:
 *
 *                 o P0
 *                  \
 * Origin            \
 *   o---------------> distributionMainDirection
 *                   /
 *                  /
 *                 o P1
 *
 * @param distributionMainDirection {vec2} normalized vector describing the
 * @param radiusToItemDistanceRatio {float}
 * 		describing the ratio between the radius and the distance between the items: distance/radius
 * @param nrOfItems {number} number of items to distribute around the origin
 * @returns {Array} an array of vec2 containing the distributed points [P0...P(nrOfItems-1)]
 */
export function circularDistribution(distributionMainDirection, radiusToItemDistanceRatio, nrOfItems) {
	const angelBetweenElementsRad = Math.acos(1 - (Math.pow(radiusToItemDistanceRatio, 2) / 2));
	const rotationBetweenElements = mat2d.create();
	mat2d.rotate(rotationBetweenElements, rotationBetweenElements, angelBetweenElementsRad);

	const rotationFromDistributionDirectionVector = mat2d.create();
	const rotationInRad = -((nrOfItems - 1) * angelBetweenElementsRad) / 2;
	mat2d.rotate(rotationFromDistributionDirectionVector, rotationFromDistributionDirectionVector, rotationInRad);

	let currentPoint = vec2.clone(distributionMainDirection);
	vec2.transformMat2d(currentPoint, currentPoint, rotationFromDistributionDirectionVector);

	const finalPoints = [];
	for (let currentPointIndex = 0; currentPointIndex < nrOfItems; ++currentPointIndex) {
		finalPoints.push(currentPoint);
		currentPoint = vec2.transformMat2d(vec2.create(), currentPoint, rotationBetweenElements);
	}
	return finalPoints;
}

/**
 * Calculate length of all the points, taking the passed pointSize into account.
 * @param points - array of vec2 instances to calculate the length for
 * @param pixelSize - array with two elements, describing the horizontal and vertical pixelSize
 * @param closed specifies whether the provided points describe an open or closed polygon
 * @returns {*} the length in mm
 */
export function measureLength(points, pixelSize = null, closed = false) {
	if (pixelSize && pixelSize.length === 2) {
		return calculateLength(points, pixelSize, closed);
	}
	return MEASUREMENT_NOT_POSSIBLE;
}

function calculateLength(points, pixelSize, closed) {
	let distance = 0;
	const numberPoints = points.length;
	const steps = closed ? numberPoints : numberPoints - 1;
	for (let i = 0; i < steps; ++i) {
		const distanceVector = vec2.subtract(new Float64Array(2), points[(i + 1) % numberPoints], points[i]);
		if (pixelSize) {
			vec2.multiply(distanceVector, distanceVector, pixelSize);
		}
		distance += vec2.length(distanceVector);
	}
	return distance;
}

/**
 * For details of how to calculate the area of a polygon see: https://en.wikipedia.org/wiki/Shoelace_formula
 * @param polygon
 * @param pixelSize the size of each pixel which must be incorported into the area calculation
 * @returns {number || Symbol} the area of the given polygon
 * or ERROR_POLYGON_INTERSECTIONS if the polygon has any intersections
 */
export function measureArea(polygon, pixelSize) {
	const allIntersections = intersections(polygon.map(point => [point[0], point[1]]));
	if (allIntersections.length === 0) {
		if (pixelSize && pixelSize.length === 2) {
			return calculateAreaWithoutIntersections(polygon, pixelSize);
		}
		return MEASUREMENT_NOT_POSSIBLE;
	}
	return ERROR_POLYLINE_INTERSECTIONS;
}

function calculateAreaWithoutIntersections(polygon, pixelSize) {
	const pixelArea = pixelSize[0] * pixelSize[1];
	let area = 0;
	const numberVertices = polygon.length;
	if (numberVertices >= INTERSECTING_POLYGON_MIN_VERTICES) {
		for (let i = 0; i < numberVertices; ++i) {
			const firstVertex = polygon[i];
			const secondVertex = polygon[(i + 1) % numberVertices];
			area += (firstVertex[1] + secondVertex[1]) * (firstVertex[0] - secondVertex[0]);
		}
		area = Math.abs(area / 2.0);
	}
	return area * pixelArea;
}

/**
 * Tries to find the pixel size that applies to the passed list of points.
 * If the dicomDump contains any UltrasoundRegions the function tries to find the first region that
 * contains ALL points and is an area region (all axis describe cm). If such a region can be found
 * the function then return that regions PhysicalDelta in mm.
 *
 * If the dicomDump does not contain any UltrasoundRegions, it simply returns whatever the dicomDump's
 * getPixelSize method returns.
 *
 * The parameter additionalRegionCheck might get passed a function that is called for a region
 * containing all points. If that function returns true, the region is finally accepted or rejected if
 * the function returns false.
 * @param {Array} points - the points for which to determine the pixel size for.
 * @param {Object} dicomDump - the dicom dump meta information.
 * @param {function} additionalRegionCheck - might be specified to further examine a containing region.
 * @returns null if no pixel size applies to the list of points or if no pixel size is defined.
 */
export function determinePixelSize(points, dicomDump, additionalRegionCheck = null) {
	let pixelSize = null;
	const regionCheck = additionalRegionCheck ? additionalRegionCheck : () => true;
	const containingRegion = findFirstContainingRegion(dicomDump, points, isAreaRegion, regionCheck);
	if (containingRegion) {
		pixelSize = containingRegion.getPhysicalDelta().map(convertToMM);
	} else if (dicomDump) {
		pixelSize = dicomDump.getPixelSize();
	}
	return pixelSize;
}

/**
 * Tries to determine the pixel aspect ratio that applies to the provided list of points.
 *
 * If the passed DicomDump does not define a pixel aspect ratio, the function tries to find
 * the UltrasoundRegion that contains all passed points and calculates the aspect ratio
 * for that region.
 *
 * If no aspect ratio is defined or no region containing all points can be found, the function
 * returns null
 * @param {Array} points - the points for which to find the aspect ratio
 * @param {DicomDump} dicomDump - the dicom dump containing all necessary meta info
 * @returns {*} either null or the pixel aspect ratio that applies to the passed points.
 */
export function determinePixelAspectRatio(points, dicomDump) {
	let aspectRatio = null;
	const containingRegion = findFirstContainingRegion(dicomDump, points, isAreaRegion);
	if (containingRegion) {
		const physicalUnits = containingRegion.getPhysicalUnits();
		aspectRatio = physicalUnits[1] / physicalUnits[0];
	} else if (dicomDump) {
		aspectRatio = dicomDump.getPixelAspectRatio();
	}
	return aspectRatio;
}

function convertToMM(cm) {
	return cm * MM_PER_CM;
}

export function findFirstContainingRegion(dicomDump, points, ...regionConditions) {
	let containingRegion;
	if (Boolean(dicomDump) && !dicomDump.getUltrasoundRegions().isEmpty()) {
		containingRegion = dicomDump.getUltrasoundRegions()
			.find(
				region => region.containsAllImagePoints(points) &&
					regionConditions.every(condition => condition(region))
			);
	}
	return containingRegion;
}

export function snapToRectInplace(rect, anchorRect) {
	let snapCorrectionX = 0;
	let snapCorrectionY = 0;
	if (anchorRect.bottomRight[0] < rect.topLeft[0]) {
		snapCorrectionX = anchorRect.bottomRight[0] - rect.topLeft[0];
	} else if (anchorRect.topLeft[0] > rect.bottomRight[0]) {
		snapCorrectionX = anchorRect.topLeft[0] - rect.bottomRight[0];
	}
	if (anchorRect.bottomRight[1] < rect.topLeft[1]) {
		snapCorrectionY = anchorRect.bottomRight[1] - rect.topLeft[1];
	} else if (anchorRect.topLeft[1] > rect.bottomRight[1]) {
		snapCorrectionY = anchorRect.topLeft[1] - rect.bottomRight[1];
	}
	if (snapCorrectionX !== 0 || snapCorrectionY !== 0) {
		moveRectInplace(rect, snapCorrectionX, snapCorrectionY);
	}
}

const UP = 'up';
const DOWN = 'down';
const LEFT = 'left';
const RIGHT = 'right';

export function alignRectToCircle(elementRect, referenceCircle, viewportRect) {
	const referenceRect = createRectangle(
		[referenceCircle.center[0] - referenceCircle.radius, referenceCircle.center[1] - referenceCircle.radius],
		[referenceCircle.center[0] + referenceCircle.radius, referenceCircle.center[1] + referenceCircle.radius]
	);
	return alignElement(elementRect, referenceRect, viewportRect,
		_partial(circleOverlapsRect, referenceCircle),
		_partial(calculateCircleMove, referenceCircle)
	);
}

function circleOverlapsRect(circle, rect) {
	const distanceToRect = distanceToPoint2d(rect, circle.center);
	const distanceLessThanRadius = distanceToRect[0] <= circle.radius || distanceToRect[1] <= circle.radius;
	return distanceLessThanRadius &&
		getCornerPoints(rect).some(point => circleContainsPoint(circle, point));
}

function getCornerPoints(rect) {
	return [
		rect.topLeft,
		[rect.bottomRight[0], rect.topLeft[1]],
		rect.bottomRight,
		[rect.topLeft[0], rect.bottomRight[1]]
	];
}

function circleContainsPoint(circle, point) {
	const squareRadius = circle.radius * circle.radius;
	return vec2.squaredDistance(circle.center, point) <= squareRadius;
}

function calculateCircleMove(circle, moveOption, elementRect) {
	let moveComponent;
	let moveDirection;
	switch (moveOption) {
		case UP:
			moveComponent = 1;
			moveDirection = -1;
			break;
		case DOWN:
			moveComponent = 1;
			moveDirection = 1;
			break;
		case LEFT:
			moveComponent = 0;
			moveDirection = -1;
			break;
		case RIGHT:
			moveComponent = 0;
			moveDirection = 1;
			break;
		default:
			throw new Error('InvalidState');
	}

	const sourceComponentIndex = (moveComponent + 1) % 2;
	const squaredRadius = circle.radius * circle.radius;
	let circlePointDistance;
	if (circle.center[sourceComponentIndex] >= elementRect.topLeft[sourceComponentIndex] &&
			circle.center[sourceComponentIndex] <= elementRect.bottomRight[sourceComponentIndex]) {
		circlePointDistance = circle.radius;
	} else {
		const distance = distanceToPoint2d(elementRect, circle.center);
		const minDistanceToCenter = Math.abs(distance[sourceComponentIndex]);
		circlePointDistance = Math.sqrt(
			squaredRadius - (minDistanceToCenter * minDistanceToCenter)
		);
	}

	const move = [0.0, 0.0];
	const offset = moveDirection < 0 ? -circlePointDistance : circlePointDistance;
	const moveDiff = Math.max(elementRect.topLeft[moveComponent], elementRect.bottomRight[moveComponent]);
	move[moveComponent] = (circle.center[moveComponent] - offset) - moveDiff;
	return move;
}

export function alignRectToRect(elementRect, referenceRect, viewportRect) {
	return alignElement(elementRect, referenceRect, viewportRect, overlap2d, calculateRectMove);
}

function calculateRectMove(moveOption, elementRect, referenceRect) {
	let move = null;
	switch (moveOption) {
		case UP:
			move = [0, referenceRect.topLeft[1] - elementRect.bottomRight[1]];
			break;
		case DOWN:
			move = [0, referenceRect.bottomRight[1] - elementRect.topLeft[1]];
			break;
		case LEFT:
			move = [referenceRect.topLeft[0] - elementRect.bottomRight[0], 0];
			break;
		case RIGHT:
			move = [referenceRect.bottomRight[0] - elementRect.topLeft[0], 0];
			break;
		default:
			break;
	}
	return move;
}

function alignElement(elementBoundingRect, referenceRect, viewportRect, overlapping, calculateMove) {
	const confinedElementRect = confineRect(elementBoundingRect, viewportRect);
	const movement = vec2.subtract(new Float64Array(2), confinedElementRect.topLeft, elementBoundingRect.topLeft);
	if ((movement[0] !== 0 || movement[1] !== 0) && overlapping(confinedElementRect, referenceRect)) {
		vec2.scale(movement, movement, -1);
		const solveOptions = getAllSolveOptionsSet();
		removeUsedSolveOptions(solveOptions, movement);
		solveOverlapInplace(solveOptions, confinedElementRect, referenceRect, viewportRect, calculateMove);
	}
	return confinedElementRect;
}

function getAllSolveOptionsSet() {
	return new Set([UP, DOWN, LEFT, RIGHT]);
}

function removeUsedSolveOptions(remainingOptions, lastMovement) {
	if (lastMovement[0] > 0) {
		remainingOptions.delete(RIGHT);
	} else if (lastMovement[0] < 0) {
		remainingOptions.delete(LEFT);
	}
	if (lastMovement[1] > 0) {
		remainingOptions.delete(DOWN);
	} else if (lastMovement[1] < 0) {
		remainingOptions.delete(UP);
	}
}

function solveOverlapInplace(solveOptions, elementRect, referenceRect, viewportRect, calculateMove) {
	let solved = false;
	const referenceCenter = getCenter(referenceRect);
	const sections = sectionRectangle(solveOptions, referenceRect, referenceCenter);
	while (sections.size > 0 && !solved) {
		const nextMoveOption = selectNextOption(sections, elementRect);
		const nextMovement = calculateMove(nextMoveOption, elementRect, referenceRect);
		moveRectInplace(elementRect, nextMovement[0], nextMovement[1]);
		sections.delete(nextMoveOption);

		const originalTopLeft = [elementRect.topLeft[0], elementRect.topLeft[1]];
		confineRectInplace(elementRect, viewportRect);
		solved = originalTopLeft[0] === elementRect.topLeft[0] && originalTopLeft[1] === elementRect.topLeft[1];
	}
	return solved;
}

function selectNextOption(sections, elementRect) {
	let largestOption = null;
	const elementCenter = getCenter(elementRect);
	for (const section of sections) {
		const overlappingArea = calculateRectArea(intersectRects(section[1].rect, elementRect));
		const distanceToElement = vec2.squaredDistance(section[1].center, elementCenter);
		if (largestOption === null || overlappingArea > largestOption.overlappingArea) {
			largestOption = {
				...section[1], option: section[0],
				distance: distanceToElement,
				overlappingArea
			};
		} else if (overlappingArea === largestOption.overlappingArea) {
			if (distanceToElement < largestOption.distance) {
				largestOption = {
					...section[1], option: section[0],
					distance: distanceToElement,
					overlappingArea
				};
			}
		}
	}
	return largestOption ? largestOption.option : false;
}

function sectionRectangle(solveOptions, rect, referenceCenter) {
	const sections = new Map();
	for (const option of solveOptions) {
		let sectionRect = null;
		switch (option) {
			case UP:
				sectionRect = createRectangle(
					rect.topLeft,
					[rect.bottomRight[0], referenceCenter[1]]
				);
				break;
			case DOWN:
				sectionRect = createRectangle(
					[rect.topLeft[0], referenceCenter[1]],
					rect.bottomRight
				);
				break;
			case LEFT:
				sectionRect = createRectangle(
					rect.topLeft,
					[referenceCenter[0], rect.bottomRight[1]]
				);
				break;
			case RIGHT:
				sectionRect = createRectangle(
					[referenceCenter[0], rect.topLeft[1]],
					rect.bottomRight
				);
				break;
			default:
				break;
		}
		sections.set(option, {
			rect: sectionRect,
			center: getCenter(sectionRect)
		});
	}
	return sections;
}

export function calcLabelOffset(fontSize, isPrintPreview) {
	const halfFontSize = fontSize / 2;
	return isPrintPreview ? halfFontSize : (TOUCH_SIZE / 2 + halfFontSize);
}

export function calculateLabelProperties(containerPositions, viewPortRect, fontSize, isPrintPreview) {
	const currentPointIndex = containerPositions.length - 1;
	const currentDirectionalVector = calcDirectionalVector(currentPointIndex, containerPositions);
	const orthogonalVector = perpendicularVector(currentDirectionalVector);
	const offset = calcLabelOffset(fontSize, isPrintPreview);
	const directionalOffset = (((containerPositions[currentPointIndex][0] < 0 ? -1.0 : 1.0) * orthogonalVector[0] > 0)
		? -offset
		: offset
	);

	const scaledOrthogonalVector = vec2.scale(orthogonalVector, orthogonalVector, directionalOffset);
	let textPosition = vec2.add(vec2.create(), containerPositions[currentPointIndex], scaledOrthogonalVector);

	const clippingRect = resizeRect(viewPortRect, 0, fontSize / 2, 0, -fontSize / 2);

	textPosition = getTextPosition(
		clippingRect, textPosition, currentPointIndex, containerPositions, scaledOrthogonalVector,
		currentDirectionalVector, directionalOffset
	);

	const textAnchor = orthogonalVector[0] > 0 ? 'start' : 'end';
	return {
		textAnchor,
		x: textPosition[0],
		y: textPosition[1]
	};
}

function getTextPosition(
		clippingRect, textPosition, pointIndex, containerPositions, scaledOrthogonalVector,
		directionalVector, directionalOffset
) {
	let newTextPosition = textPosition;
	let currentPointIndex = pointIndex;
	let currentDirectionalVector = directionalVector;
	let newScaledOrthogonalVector = scaledOrthogonalVector;
	if (!containsPoint2d(clippingRect, textPosition)) {
		let clippedSegment = null;
		let textSegment = null;
		let currentPosition = textPosition;
		while (currentPointIndex > 0 && !clippedSegment) {
			textSegment = createSegment(
				vec2.add(vec2.create(), containerPositions[--currentPointIndex], newScaledOrthogonalVector),
				currentPosition
			);
			clippedSegment = clipToRectangle2d(textSegment, clippingRect);
			if (!clippedSegment && currentPointIndex >= 1) {
				currentDirectionalVector = getNormalizedDirection(
					containerPositions[currentPointIndex],
					containerPositions[currentPointIndex - 1]
				);
				newScaledOrthogonalVector = vec2.scale(vec2.create(),
					perpendicularVector(currentDirectionalVector),
					directionalOffset
				);
				currentPosition = vec2.add(vec2.create(),
					containerPositions[currentPointIndex],
					newScaledOrthogonalVector
				);
			}
		}
		if (clippedSegment) {
			newTextPosition = clippedSegment.to;
		} else if (textSegment) {
			newTextPosition = textSegment.from;
		}
	}
	return newTextPosition;
}

function calcDirectionalVector(currentPointIndex, containerPositions) {
	if (currentPointIndex === 1) {
		return getNormalizedDirection(containerPositions[0], containerPositions[1]);
	}
	return getNormalizedDirection(containerPositions[currentPointIndex], containerPositions[currentPointIndex - 1]);
}

export function createAnnotationItem(itemType, identifier) {
	return {
		type: itemType,
		id: identifier.toString()
	};
}

/**
 * Measures the angle in degrees between the passed segments.
 * Each segment must consist of two points in image coordinates.
 * Each point is represented as an array of two numbers.
 *
 * @param pixelAspectRatio - The height for a 1mm wide pixel
 * @param segmentA - Array of two points represented as Array of x- and y-coordinates
 * @param segmentB - Array of two points represented as Array of x- and y-coordinates
 * @returns {symbol|*} The measured angle in degrees
 * 		or the constant MEASUREMENT_NOT_POSSIBLE if not all requirements were met.
 */
export function measureAngle(pixelAspectRatio, segmentA, segmentB) {
	if (pixelAspectRatio) {
		return calculateAngle(segmentA, segmentB, pixelAspectRatio);
	}
	return MEASUREMENT_NOT_POSSIBLE;
}

function calculateAngle(segmentA, segmentB, pixelAspectRatio) {
	const aspectRatioVector = vec2.fromValues(1.0, pixelAspectRatio);
	const v1 = vec2.subtract(vec2.create(), segmentA[1], segmentA[0]);
	vec2.multiply(v1, v1, aspectRatioVector);
	const v2 = vec2.subtract(vec2.create(), segmentB[1], segmentB[0]);
	vec2.multiply(v2, v2, aspectRatioVector);
	return rad2deg(vec2.angle(v1, v2));
}

/**
 * Takes the length in mm and renders it in a reader friendly form.
 * It currently performs the following translations:
 * length >= 1 => "<length> mm"
 * length < 1 => "<length> µm"
 *
 * @param lengthInMM
 * @return - a string including a suitable unit.
 */
export function getLengthWithUnit(lengthInMM) {
	const unit = findProperUnit(lengthInMM, ANNOTATION_UNITS);
	return getValueWithUnit(lengthInMM, unit);
}

/**
 * Takes the area in square mm and renders it in a reader friendly form.
 * It currently performs the following translations:
 * length >= 1 => "<length> mm²"
 * length < 1 => "<length> µm²"
 *
 * @param areaInSquareMM
 * @return - a string including a suitable unit.
 */
export function getAreaWithUnit(areaInSquareMM) {
	const unit = findProperUnit(areaInSquareMM, ANNOTATION_SQUARE_UNITS);
	return getValueWithUnit(areaInSquareMM, unit);
}

function getValueWithUnit(valueInMM, unit) {
	const {factor, symbol} = unit;
	const scaledLength = valueInMM * factor;
	const roundedLength = round(scaledLength, 1);
	const dotZeroExtension = Number.isInteger(roundedLength) ? '.0' : '';
	return `${roundedLength}${dotZeroExtension} ${symbol}`;
}
