import React from 'react';

import {IS_DEBUG_BUILD} from '../constants/EnvironmentConstants.js';
import {refPropType} from './CustomPropTypes.js';
import {debugWarn} from './DebugLog.js';

function getDisplayName(component) {
	return component.displayName || component.name || (isHTMLTagElement(component) ? component : 'Unknown');
}

export function getHocDisplayName(wrappedComponent, ...wrappers) {
	const devToolsFriendlyWrapperDescription = `${wrappers.join('(')}(`;
	const componentDisplayName = getDisplayName(wrappedComponent);
	const devToolsFriendlyWrapperTrailer = ')'.repeat(wrappers.length);
	return `${devToolsFriendlyWrapperDescription}${componentDisplayName}${devToolsFriendlyWrapperTrailer}`;
}

export function memoWithName(component) {
	return Object.assign(React.memo(component), {displayName: getDisplayName(component)});
}

export function withForwardRef(Component, refPropName) {
	if (IS_DEBUG_BUILD) {
		checkForwardRefPropType(Component, refPropName);
	}
	function render(props, forwardRef) {
		const propsWithForwardRef = {
			...props,
			[refPropName]: forwardRef
		};
		return (
			<Component {...propsWithForwardRef} />
		);
	}
	const result = React.forwardRef(render);
	result.propTypes = {
		...result.propTypes,
		...Component.propTypes
	};
	result.defaultProps = {
		...result.defaultProps,
		...Component.defaultProps
	};
	result.displayName = getHocDisplayName(Component, 'withForwardRef');
	return result;
}

withForwardRef.PropTypes = {
	Ref: refPropType
};

function checkForwardRefPropType(Component, forwardRefName) {
	const {propTypes} = Component;
	if (propTypes) {
		const {[forwardRefName]: forwardRefType} = propTypes;
		if (forwardRefType !== withForwardRef.PropTypes.Ref) {
			const componentName = Component.displayName || Component.name || Component;
			debugWarn(`Consider using withForwardRef.PropTypes.Ref as prop type checker for ${forwardRefName} of ${componentName}.`);
		}
	}
}

export function isHTMLTagElement(element) {
	return typeof (element) === 'string';
}
