import React from 'react';
import Immutable from 'immutable';

import BrickBase from '../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../webview/bricks/brickTools.js';

export default class UploadContext extends BrickBase {
	constructor() {
		super();
		this.updateBrickState(() => ({
			uploadContext: null,
			state: UploadContext.State.UNDEFINED
		}));
	}

	loadContext(newContext) {
		this.updateBrickState(oldState => ({
			...oldState,
			uploadContext: newContext ? Immutable.fromJS(newContext) : null,
			state: newContext ? UploadContext.State.LOADED : UploadContext.State.UNDEFINED
		}));
	}

	isLoaded() {
		return this.getBrickState().state === UploadContext.State.LOADED;
	}

	getUploadContext() {
		return this.getBrickState().uploadContext;
	}

	getUploadQuota() {
		const uploadContext = this.getUploadContext();
		return uploadContext
			? uploadContext.get('upload_quota') - uploadContext.get('bytes_uploaded')
			: 0;
	}

	hasError() {
		return this.getBrickState().state === UploadContext.State.ERROR;
	}

	setUploadState(newState) {
		this.updateBrickState(oldState => ({
			...oldState,
			state: newState
		}));
	}

	getUploadState() {
		return this.getBrickState().state;
	}

	reset() {
		this.updateBrickState(oldState => {
			this.setUploadState(UploadContext.State.UNDEFINED);
			this.loadContext(null);
			return oldState;
		});
	}
}
UploadContext.State = {
	UNDEFINED: 'UNDEFINED',
	LOADED: 'LOADED',
	LOADING: 'LOADING',
	ERROR: 'ERROR'
};
declareBrick(UploadContext);
