import React from 'react';
import Immutable from 'immutable';

import {
	getLineAroundContainerCenter,
	toImagePosition
} from '../../utils/AnnotationUtils.js';
import createAnnotation, {ANNOTATION_PROP_TYPES} from './createAnnotation.js';
import Rectangle from './Rectangle.js';

import '../../../../styles/viewer/components/annotations/MaskAnnotation.scss';


function isMaskAnnotationSupported(viewerItem) {
	return Boolean(viewerItem);
}

function getDefaultPropertiesForMaskAnnotation(props) {
	return Immutable.Map({
		points: getLineAroundContainerCenter(props).map(point => toImagePosition(props, point))
	});
}

function MaskAnnotation(props) {
	const {
		AnnotationRoot, annotationProperties,
		transformationMatrix, isPrintPreview
	} = props;
	const points = annotationProperties.get('points');
	const readOnly = annotationProperties.get('readOnly', false);

	return (
		<AnnotationRoot>
			<Rectangle isPrintPreview={isPrintPreview} points={points}
								 transformationMatrix={transformationMatrix}
								 readOnly={readOnly} className='annotation-mask' />
		</AnnotationRoot>
	);
}
MaskAnnotation.propTypes = ANNOTATION_PROP_TYPES;

export default createAnnotation(
	isMaskAnnotationSupported, getDefaultPropertiesForMaskAnnotation, MaskAnnotation
);
