import {createSelector} from 'reselect';

import {querySelector, selectLastForcedLocationUpdate} from '../../router/flux/selectors/LocationSelectors.js';
import {
	INBOX_FETCH_ID,
	INBOX_FILTERED_SHARES,
	INBOX_LAST_FETCH_TIME_STAMP,
	INBOX_QUERY_FILTER_PARAM,
	INBOX_SHARES
} from '../constants/InboxPropertyNames.js';

export const selectInboxData = state => state.inbox;
export const getInboxFilterString = createSelector(querySelector, query => query.get(INBOX_QUERY_FILTER_PARAM));
export const getInboxShares = createSelector(
	selectInboxData,
	getInboxFilterString,
	(inboxData, filterString) => Boolean(filterString) &&
		inboxData.get(INBOX_FILTERED_SHARES) || inboxData.get(INBOX_SHARES)
);
export const hasInboxData = createSelector(getInboxShares, shares => shares !== null);
export const isInboxDataUpToDate = createSelector(hasInboxData, selectInboxData, selectLastForcedLocationUpdate,
	(hasShares, inboxData, lastForcedLocationUpdate) => {
		const lastFetchTimeStamp = inboxData.get(INBOX_LAST_FETCH_TIME_STAMP);
		return hasShares && lastForcedLocationUpdate <= lastFetchTimeStamp;
	}
);
export const selectIsLoading = createSelector(selectInboxData, inboxData => inboxData.get(INBOX_FETCH_ID) !== null);
