import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {BEGIN_SESSION, END_SESSION} from '../../authentication/constants/SessionActionTypes.js';
import {CLEAR_PATIENT_DATA, LOAD_PATIENT} from '../../patient-details/constants/PatientDetailsActionTypes.js';
import {IS_REPORT} from '../constants/AIMLinkTypes.js';
import {isDicom} from '../data/aim/DocumentHelpers.js';

function findFirstPdf(document) {
	return document.get('document_type_fk') === 'GENERIC_CONTAINER' &&
		document.getIn(['generic_container', 'files']).find(
			file => file.get('extension') === 'pdf'
		) || null;
}

function addToMap(map, accessionNumber, document) {
	if (map[accessionNumber] === undefined) {
		map[accessionNumber] = [];
	}
	map[accessionNumber].push(document);
	return map;
}

function findReportLinks(state, documents) {
	const dicomDocuments = [];
	let pdfDocuments = {};
	documents.forEach(document => {
		const accessionNumber = document.get('accessionnumber', null);
		if (accessionNumber) {
			if (isDicom(document)) {
				dicomDocuments.push({accessionNumber, document});
			} else {
				const firstPdf = findFirstPdf(document);
				if (firstPdf !== null) {
					pdfDocuments = addToMap(pdfDocuments, accessionNumber, {document, firstPdf});
				}
			}
		}
	});

	return dicomDocuments.reduce((documentLinksMap, dicomDocument) => {
		const {accessionNumber, document} = dicomDocument;
		const potentialReports = pdfDocuments[accessionNumber];
		if (Boolean(potentialReports) && potentialReports.length > 0) {
			const {document: linkedDocument, firstPdf: report} = potentialReports[0];
			return documentLinksMap.update(document.get('id'), Immutable.List(), linkList => linkList.push(Immutable.fromJS({
				from: document.get('id'),
				type: IS_REPORT,
				to: linkedDocument.get('id'),
				properties: {
					reportFile: report.get('id')
				}
			})));
		}
		return documentLinksMap;
	}, state);
}

function handleLoadPatient(state, {error, payload}) {
	if (error === true) {
		return getInitialState();
	}
	return findReportLinks(Immutable.Map(), payload.get('documents'));
}

export function getInitialState() {
	return Immutable.Map();
}

export default handleActions({
	[LOAD_PATIENT]: handleLoadPatient,
	[CLEAR_PATIENT_DATA]: getInitialState,
	[END_SESSION]: getInitialState,
	[BEGIN_SESSION]: getInitialState
}, getInitialState());
