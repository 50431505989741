import {connect} from 'react-redux';

import {clearSelection} from '../actions/SelectedItemsActions.js';
import Purger from '../components/data/Purger.js';

const SelectionPurgerContainer = connect(
	null,
	{
		onPurge: clearSelection
	}
)(Purger);

SelectionPurgerContainer.displayName = 'SelectionPurgerContainer';

export default SelectionPurgerContainer;
