import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {currentLocale} from '../selectors/GeneralConfigSelectors.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';

function selectMessageProperties(state, props) {
	const {messageSelector, message} = props;
	return createStructuredSelector({
		locale: currentLocale,
		message: typeof (messageSelector) === 'function' ? messageSelector : () => message
	});
}

export default connect(
	selectMessageProperties,
	undefined,
	(stateProps, dispatchProps, ownProps) => ({...cloneWithoutProperties(ownProps, 'messageSelector'), ...stateProps})
)(SynFormattedMessage);
