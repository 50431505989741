import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {uiIsSmallDevice} from '../../../ui/flux/UISelectors.js';
import {getAnnotationLayer} from '../../components/annotations.js';
import {
	DEFAULT_FONT_SIZE,
	LINE_HEIGHT_IN_EMS,
	SMALL_FONT_SIZE
} from '../../components/annotations/AnnotationConstants.js';
import {
	ACTIVE_ANNOTATION_ID_STATE_FIELD,
	ANNOTATION_MAPPINGS_STATE_FIELD,
	ANNOTATION_META_INFOS_STATE_FIELD,
	ANNOTATION_PROPERTIES_STATE_FIELD,
	UNINITIALIZED_ANNOTATION_PROPERTIES
} from '../../constants/AnnotationConstants.js';
import {VIEWER_MATRIX_LAYOUT_1X1} from '../../constants/ViewerConstants.js';
import {getFullScreenViewerId, getViewerLayoutMode} from './ViewerSelectors.js';

function getAnnotationReducerState(state) {
	return state.annotations;
}

function getAnnotationType(annotationState, annotationId) {
	const accessKey = [ANNOTATION_META_INFOS_STATE_FIELD, `${annotationId}`, 'annotationType'];
	return annotationState.getIn(accessKey);
}

export const selectAnnotationMappings = createSelector(
	getAnnotationReducerState,
	reducerState => reducerState.get(ANNOTATION_MAPPINGS_STATE_FIELD, Immutable.Map())
);

export const selectAnnotationMetaInfos = createSelector(
	getAnnotationReducerState,
	reducerState => reducerState.get(ANNOTATION_META_INFOS_STATE_FIELD, Immutable.Map())
);

export const getActiveAnnotation = createSelector(getAnnotationReducerState,
	annotationReducerState => annotationReducerState.get(ACTIVE_ANNOTATION_ID_STATE_FIELD, null));

export function createAnnotationPropertiesSelector(annotationId) {
	return createSelector(
		getAnnotationReducerState,
		annotationReducerState => {
			const accessKey = [ANNOTATION_PROPERTIES_STATE_FIELD, `${annotationId}`];
			return annotationReducerState.getIn(accessKey, UNINITIALIZED_ANNOTATION_PROPERTIES);
		}
	);
}

export function createAnnotationTypeSelector(annotationId) {
	return createSelector(
		getAnnotationReducerState,
		annotationsReducerState => getAnnotationType(annotationsReducerState, annotationId)
	);
}

export function createSelectAnnotationIdsForItems(...annotationItemInfos) {
	const finalAnnotationItemInfos = annotationItemInfos.map(itemInfos => ([itemInfos[0], `${itemInfos[1]}`]));
	return createSelector(
		selectAnnotationMappings,
		annotationMappings => finalAnnotationItemInfos.reduce(
			(finalIds, annotationPath) => finalIds.concat(annotationMappings.getIn(annotationPath, Immutable.Set()))
			, Immutable.Set()
		)
	);
}

export function createSelectLayeredAnnotationIds(selectAnnotationIds) {
	let previousIds;
	let layeredIds = new Immutable.Set();
	return state => {
		const nextIds = selectAnnotationIds(state);
		if (nextIds !== previousIds) {
			layeredIds = nextIds
				.map(id => {
					const type = getAnnotationType(getAnnotationReducerState(state), id);
					return {
						layer: getAnnotationLayer(type),
						id
					};
				})
				.sort(({layer: layerA}, {layer: layerB}) => layerA - layerB)
				.map(({id}) => id);
			previousIds = nextIds;
		}
		return layeredIds;
	};
}

export function createAnnotationFontSizeSelector(viewerId) {
	const selectIsFullScreenViewer = createSelector(getFullScreenViewerId,
		fullscreenViewer => viewerId === fullscreenViewer);
	return createSelector(
		selectIsFullScreenViewer,
		getViewerLayoutMode,
		uiIsSmallDevice,
		(isFullScreenViewer, layoutMode, isSmallDevice) => {
			const useDefaultViewerFontSize = !isSmallDevice &&
					(isFullScreenViewer || layoutMode === VIEWER_MATRIX_LAYOUT_1X1);
			return useDefaultViewerFontSize ? DEFAULT_FONT_SIZE : SMALL_FONT_SIZE;
		}
	);
}

export function createAnnotationLineHeightSelector(selectAnnotationFontSize) {
	return createSelector(
		selectAnnotationFontSize,
		annotationFontSize => annotationFontSize * LINE_HEIGHT_IN_EMS
	);
}

