import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {CLEAR_SELECTION, DESELECT_ITEM, SELECT_ITEM} from '../constants/SelectedItemsActionTypes.js';

const INITIAL_STATE = Immutable.Map();

const selectedItemsReducer = handleActions({
	[SELECT_ITEM]: selectItem,
	[DESELECT_ITEM]: deselectItem,
	[CLEAR_SELECTION]: clearSelection
}, INITIAL_STATE);

function selectItem(state, action) {
	const {id, item} = action.payload;
	return state.set(id, item);
}

function deselectItem(state, action) {
	const id = action.payload;
	return state.delete(id);
}

function clearSelection(/*state, action*/) {
	return INITIAL_STATE;
}

export default selectedItemsReducer;
