import React from 'react';
import PropTypes from 'prop-types';

export default class Purger extends React.Component {
	render() {
		const {children} = this.props;
		return children;
	}

	componentWillUnmount() {
		const {onPurge} = this.props;
		onPurge && onPurge();
	}
}

Purger.propTypes = {
	onPurge: PropTypes.func
};
