import React from 'react';
import {styled} from '@mui/material/styles';
import color from 'color';
import PropTypes from 'prop-types';

import HorizontalLayout from '../../../webview/ui/components/layout/HorizontalLayout.js';
import VerticalLayout from '../../../webview/ui/components/layout/VerticalLayout.js';
import SelectionIndicator from './SelectionIndicator.js';
import UploadFileTileCaption from './UploadFileTileCpation.js';

const BACKGROUND_ALPHA = 0.6;

export default styled(UploadFileTileOverlay, {name: 'UploadFileTileOverlay'})(props => {
	const {theme, isSelected} = props;
	return {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		background: isSelected
			            ? color(theme.palette.secondary.main).alpha(BACKGROUND_ALPHA)
				.string()
			            : 'transparent'
	};
});

function UploadFileTileOverlay(props) {
	const {fileName, fileSize, className, isSelected, children} = props;
	const isSelectable = true;
	return (
		<VerticalLayout className={className}>
			<HorizontalLayout align='start' sx={{p: '5px'}}>
				{isSelectable && <SelectionIndicator isSelected={isSelected} />}
				{children}
			</HorizontalLayout>
			<UploadFileTileCaption fileName={fileName} fileSize={fileSize} />
		</VerticalLayout>
	);
}

UploadFileTileOverlay.propTypes = {
	fileName: PropTypes.string,
	fileSize: PropTypes.node,
	isSelected: PropTypes.bool,
	className: PropTypes.string
};
