import React, {useCallback, useState} from 'react';
import MUIMenu from '@mui/material/Menu';
import PropTypes from 'prop-types';

import {KEY_VALUE_RETURN} from '../../commons/constants/KeyValues.js';
import {ALIGN_LEFT, EXPAND_TOP} from '../constants/PupupMenuConstants.js';
import createPopupMenuDirections from '../createPopupMenuDirection.js';

function Menu(props) {
	const {id, buttonElement, children, menuComponent, disabled, align, expandDirection} = props;
	const [anchorEl, setAnchorEl] = useState(null);

	function handleKeyDown(e) {
		if (e?.bubbles && e.key === KEY_VALUE_RETURN) {
			window.requestAnimationFrame(handleMenuClose);
		}
	}

	const handleButtonClick = useCallback(e => {
		setAnchorEl(e.currentTarget);
	}, []);

	const handleMenuClose = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);

	const menuListProps = {
		component: menuComponent,
		onClick: handleMenuClose,
		onKeyDown: handleKeyDown
	};

	const popoverDirections = createPopupMenuDirections(align, expandDirection);
	const finalButton = React.cloneElement(buttonElement, {
		className: disabled ? 'button-disabled' : '',
		id: `${id}-button`,
		onClick: handleButtonClick,
		'aria-controls': id,
		'aria-haspopup': 'true',
		disabled
	});

	return (
		<React.Fragment>
			{finalButton}
			<MUIMenu id={id} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}
			         MenuListProps={menuListProps} variant='menu' {...popoverDirections}>
				{children}
			</MUIMenu>
		</React.Fragment>
	);
}

Menu.propTypes = {
	buttonElement: PropTypes.element.isRequired,
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	menuComponent: PropTypes.node,
	disabled: PropTypes.bool,
	expandDirection: PropTypes.string,
	align: PropTypes.string
};

Menu.defaultProps = {
	disabled: false,
	expandDirection: EXPAND_TOP,
	align: ALIGN_LEFT
};

export default React.memo(Menu);
