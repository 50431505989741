import {createSelector} from 'reselect';

import {ERROR_FIELD, VALUE_FIELD} from '../constants/FormAccessorsFields.js';
import {currentLocale} from '../selectors/GeneralConfigSelectors.js';
import {firstErrorOrTrue} from '../utils/ValidatorUtils.js';

export function createChainedValidatorFactory(...validatorFactories) {
	return (...args) => {
		const instantiatedValidators = validatorFactories.map(factory => factory(...args));
		return createSelector(instantiatedValidators, firstErrorOrTrue);
	};
}

export function createSingleFieldValidatorFactory(validationFunction) {
	return (fieldSelectors, allFields, translator, contextFieldName) => createSelector(
		[currentLocale, fieldSelectors[contextFieldName]],
		(locale, fieldMap) => (Boolean(fieldMap) && fieldMap.has(ERROR_FIELD) && fieldMap.get(ERROR_FIELD).length > 0
			? fieldMap.get(ERROR_FIELD)
			: validationFunction(locale, translator.tr(allFields[contextFieldName].label, locale), fieldMap
				? fieldMap.get(VALUE_FIELD)
				: undefined)
		)
	);
}

export function createMultiFieldValidatorFactory(validationFunction) {
	return fieldNames => (fieldSelectors, allFields, translator, contextFieldName = '') => {
		let currentFieldNames = fieldNames;
		if (contextFieldName) {
			currentFieldNames = [contextFieldName].concat(currentFieldNames);
		}
		const allSelectors = currentFieldNames.map(fieldName => fieldSelectors[fieldName]);
		const fieldTranslator = locale => currentFieldNames.map(
			fieldName => translator.tr(allFields[fieldName].label, locale)
		);
		return createSelector([currentLocale].concat(allSelectors), (locale, ...fieldMaps) => {
			const translatedFieldLabels = fieldTranslator(locale);
			const fieldValues = [];
			const errorMessage = '';
			fieldMaps.every(fieldMap => {
				if (fieldMap) {
					fieldValues.push(fieldMap.has(VALUE_FIELD) ? fieldMap.get(VALUE_FIELD) : undefined);
				} else {
					fieldValues.push(undefined);
				}
				return errorMessage.length === 0;
			});
			return errorMessage.length === 0
				? validationFunction(locale, translatedFieldLabels, ...fieldValues)
				: errorMessage;
		});
	};
}

export function createFormValidatorFactory(validationFunction) {
	const formValidatorFactory = createMultiFieldValidatorFactory(validationFunction);
	return (fieldSelectors, allFields, translator) => formValidatorFactory(
		Object.keys(allFields)
	)(fieldSelectors, allFields, translator);
}
