import {call, put, take} from 'redux-saga/effects';

import {destroyCurrentSession} from '../api/SessionApi.js';
import {LOGOUT} from '../constants/SessionActionTypes.js';
import {SESSION_STATE_LOGGED_OUT} from '../constants/SessionReducerStates.js';
import {beginSessionTransition, endSession, finishSessionTransition} from '../flux/actions/SessionActions.js';
import createSessionTask from './createSessionTask.js';

function* logoutTaskImpl() {
	const logoutAction = yield take(LOGOUT);
	yield put(beginSessionTransition(SESSION_STATE_LOGGED_OUT));
	yield put(endSession(logoutAction.payload));

	try {
		yield call(destroyCurrentSession);
	} catch (error) {
		// Simply ignore any errors.
	}
	yield put(finishSessionTransition(logoutAction.payload));
}

export default createSessionTask(logoutTaskImpl);
