import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {selectAllSeries, selectFiles} from '../../../patient-details/flux/selectors/PatientDetailsSelectors.js';
import {uiShowPrintLayout} from '../../../ui/flux/UISelectors.js';
import GenericFileViewer from '../../components/GenericFileViewer.js';
import Viewer from '../../components/Viewer.js';
import ViewerContextProvider from '../../components/ViewerContextProvider.js';
import {isDicomViewer} from '../../utils/ViewerUtils.js';
import {
	mergeViewerProperties,
	mergeViewerPropertiesAndActivate,
	resetViewerProperties,
	setActiveViewer,
	toggleFullscreen
} from '../actions/ViewerActions.js';
import {
	isFullScreenViewer,
	selectAllMetaInfos,
	selectIsSingleViewerLayout,
	selectIsViewerActive
} from '../selectors/ViewerSelectors.js';
import DicomSeriesViewerContainer from './DicomSeriesViewerContainer.js';

export default function ViewerContainer(props) {
	const {viewerId} = props;
	const stateSelector = useMemo(() => createViewerStateSelector(viewerId), [viewerId]);
	const dispatch = useDispatch();
	const callbacks = useMemo(() => createViewerCallbacks(viewerId, dispatch), [viewerId, dispatch]);
	const {itemId, ViewerComponent, showActiveBorder, itemExists, ...contextProps} = useSelector(stateSelector);

	return (
		<ViewerContextProvider viewerId={viewerId} {...contextProps} {...callbacks}>
			<Viewer itemId={itemId} itemExists={itemExists} ViewerComponent={ViewerComponent}
			        showActiveBorder={showActiveBorder} isFullScreen={contextProps.isFullScreen} />
		</ViewerContextProvider>
	);
}

ViewerContainer.propTypes = {
	viewerId: PropTypes.string
};

function determineViewerComponent(itemType) {
	let typeSpecificViewer = null;
	if (itemType) {
		typeSpecificViewer = isDicomViewer(itemType) ? DicomSeriesViewerContainer : GenericFileViewer;
	}
	return typeSpecificViewer;
}

function determineItemExists(itemId, itemType, allSeries, allFiles) {
	const documents = isDicomViewer(itemType) ? allSeries : allFiles;
	return documents.map(document => document.get('id')).has(itemId);
}

function createViewerStateSelector(viewerId) {
	const selectViewerMetaInfo = createSelector(
		selectAllMetaInfos, metaInfos => metaInfos.get(viewerId, Immutable.Map())
	);
	const selectIsActiveViewer = selectIsViewerActive(viewerId);
	const isFullScreen = isFullScreenViewer(viewerId);
	const showActiveViewerBorder = createSelector(
		selectIsActiveViewer, selectIsSingleViewerLayout,
		(isActiveViewer, isSingleViewerLayout) => isActiveViewer && !isSingleViewerLayout
	);
	const selectItemId = createSelector(selectViewerMetaInfo, metaInfo => metaInfo.get('id'));
	const selectItemType = createSelector(selectViewerMetaInfo, metaInfo => metaInfo.get('type'));
	const selectViewerComponent = createSelector(selectItemType, determineViewerComponent);
	const selectItemExists = createSelector(
		selectItemId, selectItemType, selectAllSeries, selectFiles, determineItemExists
	);

	return createStructuredSelector({
		itemId:	selectItemId,
		ViewerComponent: selectViewerComponent,
		itemExists: selectItemExists,
		isFullScreen,
		isActive: selectIsActiveViewer,
		isPrintPreview: uiShowPrintLayout,
		showActiveBorder: showActiveViewerBorder
	});
}

function createViewerCallbacks(viewerId, dispatch) {
	return bindActionCreators({
		activate: () => setActiveViewer(viewerId),
		toggleFullScreen: () => toggleFullscreen(viewerId),
		resetProperties: () => resetViewerProperties(viewerId),
		updateProperties: (newProps, activate = true) => (activate
			? mergeViewerPropertiesAndActivate(viewerId, newProps)
			: mergeViewerProperties(viewerId, newProps))
	}, dispatch);
}
