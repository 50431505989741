import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {isDicomSeries} from '../../../commons/data/aim/SeriesHelpers';
import {useAction} from '../../../commons/flux/ReactHooks';
import {callSafe, noop} from '../../../commons/utils/FunctionUtils';
import {loadFileIntoViewer, loadSeriesIntoViewer} from '../../../viewer/flux/actions/ViewerActions';
import {hasDisplayedItem} from '../../../viewer/flux/selectors/ViewerSelectors';
import {viewerIndexToViewerId} from '../../../viewer/utils/ViewerUtils';
import {selectInitialItemToView} from '../selectors/PatientDetailsSelectors';

export default function InitialViewerItemLoader(/*props*/) {
	const loadAction = useItemSpecificLoadAction();
	const displayedItem = useSelector(hasDisplayedItem);
	const onAnimationFrame = useCallback(() => {
		if (!displayedItem) {
			loadAction();
		}
	}, [loadAction, displayedItem]);
	useInAnimationFrame(onAnimationFrame);
	return null;
}

function useItemSpecificLoadAction() {
	const initialItemToView = useSelector(selectInitialItemToView);
	const loadAction = isDicomSeries(initialItemToView) ? loadSeriesIntoViewer : loadFileIntoViewer;
	const dispatchLoadAction = useAction(loadAction);
	return useCallback(() => {
		if (initialItemToView !== null) {
			dispatchLoadAction(viewerIndexToViewerId(0), initialItemToView.get('id'));
		}
	}, [initialItemToView, dispatchLoadAction]);
}

function useInAnimationFrame(callback) {
	React.useEffect(() => {
		let cleanup = noop;
		if (callback) {
			const requestId = window.requestAnimationFrame(() => callSafe(callback));
			cleanup = () => window.cancelAnimationFrame(requestId);
		}
		return cleanup;
	}, [callback]);
}
