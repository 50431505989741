import {DEVICE_TYPE_DESKTOP, DEVICE_TYPE_MOBILE, MAXIMUM_MOBILE_WIDTH}
	from '../commons/constants/DeviceInformation.js';
import {SIDEBAR_MODE_BREAK_POINT} from './constants/AppSepcificUIConstants.js';

export const deduceDeviceType = size => (
	size.get('width') <= MAXIMUM_MOBILE_WIDTH
		? DEVICE_TYPE_MOBILE
		: DEVICE_TYPE_DESKTOP
);

export function isSideBarElevated(size) {
	return size.get('width') <= SIDEBAR_MODE_BREAK_POINT;
}

