import {StatusCodes} from 'http-status-codes';

import FetchError from '../FetchError.js';
import InsufficientPermissionsError from '../InsufficientPermissionsError.js';
import NotFoundError from '../NotFoundError.js';
import UnauthorizedError from '../UnauthorizedError.js';

export default function handleHttpErrorState(response, expectedState, defaultErrorMessage, responseJson) {
	const httpState = response.status;

	if (httpState === StatusCodes.UNAUTHORIZED) {
		throw new UnauthorizedError(response, responseJson);
	} else if (httpState === StatusCodes.FORBIDDEN) {
		throw new InsufficientPermissionsError(response, responseJson);
	} else if (httpState === StatusCodes.NOT_FOUND) {
		throw new NotFoundError(response, responseJson);
	} else if (httpState !== expectedState) {
		throw new FetchError(defaultErrorMessage, response, responseJson);
	}
}
