import {connect} from 'react-redux';
import Immutable from 'immutable';
import {createStructuredSelector} from 'reselect';

import {isDicomSeries} from '../../../commons/data/aim/SeriesHelpers.js';
import {uiIsMobileDevice} from '../../../ui/flux/UISelectors.js';
import {loadFileIntoViewer, loadSeriesIntoViewer, showViewer} from '../../../viewer/flux/actions/ViewerActions.js';
import {getDisplayedItemIDs, getViewerLayout} from '../../../viewer/flux/selectors/ViewerSelectors.js';
import HangMatrix from '../../components/HangMatrix.js';

export default connect(
	createStructuredSelector({
		viewerLayout: getViewerLayout,
		displayedItems: getDisplayedItemIDs,
		isMobile: uiIsMobileDevice
	}),
	(dispatch, {item}) => {
		const loadItemIntoViewerAction = isDicomSeries(item) ? loadSeriesIntoViewer : loadFileIntoViewer;
		return {
			showViewer: () => dispatch(showViewer()),
			loadItemIntoViewer: (viewerId, itemId) => dispatch(loadItemIntoViewerAction(viewerId, itemId))
		};
	},
	(stateProps, dispatchProps, props) => {
		const itemId = props.item.get('id');
		const {
			isMobile, viewerLayout, displayedItems
		} = stateProps || {};
		const rows = viewerLayout.get('rows');
		const columns = viewerLayout.get('columns');
		const oneByOneLayout = rows === 1 && rows === columns;
		const nrViewers = rows * columns;

		const activeViewerIds = displayedItems
			? displayedItems.map(displayedItemId => displayedItemId === itemId)
			: Immutable.Map.of();

		const shouldShowViewer = viewerId => {
			const itemAlreadyHungInViewer = activeViewerIds.get(viewerId) || false;
			return (isMobile && (itemAlreadyHungInViewer || oneByOneLayout));
		};
		const loadItemIntoViewer = createLoadFunction(
			viewerId => {
				dispatchProps.loadItemIntoViewer(viewerId, itemId);
			},
			viewerId => shouldShowViewer(viewerId) && dispatchProps.showViewer
		);

		return {
			...props, rows,
			columns,
			nrViewers,
			activeViewerIds,
			loadItemIntoViewer
		};
	}
)(HangMatrix);

function createLoadFunction(loadItemIntoViewer, createShowViewer) {
	return viewerId => {
		loadItemIntoViewer(viewerId);
		const showViewerById = createShowViewer(viewerId);
		if (showViewerById) {
			showViewerById();
		}
	};
}
