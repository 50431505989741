import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import Patient from '../../webview/commons/data/aim/Patient.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import ErrorIcon from '../../webview/material-design/components/icons/ErrorIcon.js';
import ToolTip from '../../webview/ui/components/ToolTip.js';

export default function OtherPatientIndicatorIcon(props) {
	const {patient} = props;
	const toolTipMessage = (
		<SynFormattedMessage message='DataOfOtherPatientsToolTip' messageParams={patient} />
	);
	return (
		<ToolTip title={toolTipMessage}>
			<ErrorSpan>
				<ErrorIcon />
			</ErrorSpan>
		</ToolTip>
	);
}
OtherPatientIndicatorIcon.propTypes = {
	patient: PropTypes.instanceOf(Patient)
};

const ErrorSpan = styled('span')(styledProps => {
	const {theme} = styledProps;
	return {
		fill: theme.palette.error.light
	};
});
