import React from 'react';

import createSimplePureRenderComponent from '../../commons/components/createSimplePureRenderComponent.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../i18n/translators/ViewerTranslator.js';
import List from '../../material-design/components/List.js';
import SimpleDialog from '../../ui/components/SimpleDialog.js';
import ThemeProvider from '../../ui/components/theming/ThemeProvider.js';

export default createSimplePureRenderComponent(props => (
	<ThemeProvider>
		<SimpleDialog {...props} title={<SynFormattedMessage message='ImageInformation' translator={ViewerTranslator} />}>
			<List>
				{props.children}
			</List>
		</SimpleDialog>
	</ThemeProvider>
));


const NO_INFORMATION_AVAILABLE = <SynFormattedMessage message='NoInformationAvailable' translator={ViewerTranslator} />;

export function checkEmpty(text) {
	return text ? text : NO_INFORMATION_AVAILABLE;
}
