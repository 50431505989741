import {Map} from 'immutable';
import {createSelector} from 'reselect';

import {getImageOrientationDescriptions} from '../../../commons/data/aim/DicomImageHelpers.js';
import {formatPatientInfo} from '../../../commons/data/aim/PatientHelpers.js';
import {
	selectAllSeries,
	selectDocuments,
	selectFileMetadata,
	selectPatient
} from '../../../patient-details/flux/selectors/PatientDetailsSelectors.js';
import {formatResolution, formatSeriesInfo} from '../../utils/MetadataUtils.js';
import {getDocumentId, getRawImage, getSeriesId} from '../../utils/ViewerItemUtils.js';
import {
	selectGenericFileViewerItem,
	selectImageViewerItems
} from './ViewerItemsSelectors.js';

const selectPatientProperties = createSelector(
	selectPatient,
	patient => ({
		PatientInfo: formatPatientInfo(patient.sex_fk, patient.last_name, patient.first_name),
		PatientBirthDate: patient.birth_date
	})
);

export function createDicomImageMetadataSelector(imageId, imageRelativeFrameOffset = null) {
	const selectDicomDump = state => selectImageViewerItems(state).getIn([imageId, 'dicomDump']);
	const selectImageMetadata = state => selectImageViewerItems(state).getIn([imageId, 'metaData']);
	const selectOrientationDescriptions = createSelector(selectDicomDump, getImageOrientationDescriptions);

	const selectSeries = createSelector(
		selectImageMetadata,
		selectAllSeries,
		(imageMetadata, allSeries) => allSeries.get(getSeriesId(imageMetadata), new Map())
	);

	const selectDocument = createSelector(
		selectImageMetadata,
		selectDocuments,
		(imageMetadata, documents) => documents.get(getDocumentId(imageMetadata), new Map())
	);

	return createSelector(
		selectPatientProperties,
		selectImageMetadata,
		selectDocument,
		selectSeries,
		selectOrientationDescriptions,
		(patientProps, imageMetadata, document, series, orientationDescriptions) => ({
			...patientProps,
			DocumentDescription: document.get('description'),
			DocumentCreationDate: document.get('document_created_when'),
			Modality: series.get('modality_fk'),
			InstitutionName: series.get('institutionname'),
			SeriesDescription: series.get('series_description'),
			SeriesInfo: formatSeriesInfo(series.get('seriesnumber'), imageMetadata.get('instancenumber'), imageRelativeFrameOffset),
			Resolution: formatResolution(imageMetadata.get('image_rows'), imageMetadata.get('image_columns')),
			ImageComments: imageMetadata.get('imagecomments'),
			...orientationDescriptions
		})
	);
}

export function createGenericFileMetadataSelector(fileId) {
	const selectMetadata = selectFileMetadata(fileId);
	const selectFileViewerItem = selectGenericFileViewerItem(fileId);
	const selectDocument = createSelector(
		selectMetadata,
		fileMetadata => fileMetadata.get('document')
	);
	const selectFileImage = createSelector(selectFileViewerItem, getRawImage);

	return createSelector(
		selectPatientProperties,
		selectDocument,
		selectMetadata,
		selectFileImage,
		(patientProps, document, file, fileImage) => ({
			...patientProps,
			DocumentDescription: document.get('description'),
			DocumentCreationDate: document.get('document_created_when'),
			FileDescription: file.get('description'),
			FileExtension: file.get('extension'),
			Resolution: fileImage && formatResolution(fileImage.get('height'), fileImage.get('width'))
		})
	);
}


