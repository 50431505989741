import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MUIStepper from '@mui/material/Stepper';
import PropTypes from 'prop-types';

export default function StepperHeader(props) {
	const {steps, activeStep, singleStep} = props;
	return singleStep ? (
		<Step active>
			<StepLabel icon={activeStep + 1}>
				{steps[activeStep].label}
			</StepLabel>
		</Step>
	) : (
		<MUIStepper activeStep={activeStep} style={{padding: 24}}>
			{steps.map(step => (
				<Step key={step.label} completed={step.completed}>
					<StepLabel>
						{step.label}
					</StepLabel>
				</Step>
			))}
		</MUIStepper>
	);
}

StepperHeader.propTypes = {
	activeStep: PropTypes.number.isRequired,
	singleStep: PropTypes.bool,
	steps: PropTypes.arrayOf(PropTypes.exact({
		label: PropTypes.string.isRequired,
		completed: PropTypes.bool.isRequired
	}))
};

StepperHeader.defaultProps = {
	singleStep: false
};
