import React from 'react';
import PropTypes from 'prop-types';

import PropertyItem from '../../commons/components/PropertyItem.js';
import MessageTranslator from '../../i18n/translators/MessageTranslator';
import ViewerTranslator from '../../i18n/translators/ViewerTranslator.js';

export default class ViewerDetailsPropertyItem extends React.PureComponent {
	render() {
		const {name, children, translator = ViewerTranslator} = this.props;
		return (
			<PropertyItem name={name} translator={translator}>
				{children}
			</PropertyItem>
		);
	}
}

ViewerDetailsPropertyItem.propTypes = {
	name: PropTypes.string,
	translator: PropTypes.instanceOf(MessageTranslator)
};
