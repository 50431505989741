import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {isFeatureTrackingEnabled} from '../../commons/selectors/GeneralConfigSelectors.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import {getHocDisplayName} from '../../commons/utils/ReactUtils.js';
import {registerMetricsAction} from '../flux/MetricsCollectorActions.js';

const DEFAULT_USAGE_CONTEXT = 'toolusage';

export default function clickTracked(TrackedComponent) {
	if (isFeatureTrackingEnabled()) {
		const clickTrackedComponent = connect(
			null, mapDispatchToProps, mergeProps, {forwardRef: true}
		)(TrackedComponent);
		clickTrackedComponent.displayName = getHocDisplayName(TrackedComponent, 'clickTracked');
		clickTrackedComponent.propTypes = {
			...TrackedComponent.propTypes,
			metricsKey: PropTypes.string.isRequired,
			usageContext: PropTypes.string
		};
		clickTrackedComponent.defaultProps = {
			...TrackedComponent.defaultProps,
			usageContext: DEFAULT_USAGE_CONTEXT
		};
		return clickTrackedComponent;
	}
	return function UnTrackedComponent(props) {
		const remainingProps = removeTrackingProps(props);
		return (
			<TrackedComponent {...remainingProps} />
		);
	};
}

function mergeProps(stateProps, dispatchProps, ownProps) {
	const remainingProps = removeTrackingProps(ownProps);
	return {...remainingProps, ...stateProps, ...dispatchProps};
}

function mapDispatchToProps(dispatch, ownProps) {
	const {onClick: clickHandler, onTap: tapHandler, metricsKey, usageContext} = ownProps;

	function createDispatchAndCall(handler) {
		return e => {
			dispatch(registerMetricsAction(metricsKey, usageContext));
			callSafe(handler, e);
		};
	}

	const finalHandlers = {};

	if (clickHandler) {
		finalHandlers.onClick = createDispatchAndCall(clickHandler);
	}

	if (tapHandler) {
		finalHandlers.onTap = createDispatchAndCall(tapHandler);
	}

	if (!clickHandler && !tapHandler) {
		finalHandlers.onClick = createDispatchAndCall();
	}

	return finalHandlers;
}

function removeTrackingProps(props) {
	return cloneWithoutProperties(props, 'metricsKey', 'usageContext');
}
