import React from 'react';
import PropTypes from 'prop-types';

import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import createLayoutComponent from './layout/createLayoutComponent.js';
import TemplateIFrame from './webview/TemplateIFrame.js';

import '../../../styles/commons/components/SidebarBranding.scss';

const VerticalLayoutTemplateIFrame = createLayoutComponent('webview-sidebar-branding', TemplateIFrame);
export default function SidebarBranding(props) {
	const {templateName = 'sidebar', ...remainingProps} = props;
	return <VerticalLayout element={VerticalLayoutTemplateIFrame} templateName={templateName} {...remainingProps} />;
}

SidebarBranding.propTypes = {
	templateName: PropTypes.string
};
