import React from 'react';
import MUIAlertTitle from '@mui/material/AlertTitle';

export default function AlertTitle(props) {
	const {children} = props;
	return (
		<MUIAlertTitle>
			{children}
		</MUIAlertTitle>
	);
}
