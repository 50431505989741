import Immutable from 'immutable';

import BrickBase from '../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../webview/bricks/brickTools.js';
import Patient from '../../webview/commons/data/aim/Patient.js';
import {memoizeLast} from '../../webview/commons/utils/FunctionUtils.js';
import PatientContext from './PatientContext.js';
import UploadFilesCollection from './UploadFilesCollection.js';

const ON_PATIENT_CONTEXT_CHANGED = Symbol('ON_PATIENT_CONTEXT_CHANGED');
const ON_UPLOAD_FILES_COLLECTION_CHANGED = Symbol('ON_UPLOAD_FILES_COLLECTION_CHANGED');

export default class PatientContextMonitor extends BrickBase {
	constructor(uploadFilesCollection, patientContext) {
		super();
		this.uploadFilesCollection = uploadFilesCollection;
		this.refreshConfirmationSets = memoizeLast(refreshConfirmationSets);
		this.updateBrickState(() => ({
			confirmedPatients: Immutable.Set(),
			unconfirmedPatients: Immutable.Set()
		}));
		this.subscribeTo(patientContext, this[ON_PATIENT_CONTEXT_CHANGED].bind(this));
		this.subscribeTo(uploadFilesCollection, this[ON_UPLOAD_FILES_COLLECTION_CHANGED].bind(this));
	}

	[ON_PATIENT_CONTEXT_CHANGED](patientContext) {
		this.updateBrickState(oldState => {
			const {confirmedPatients, unconfirmedPatients} = oldState;
			const contextPatient = patientContext.getPatient();
			const contextPatientKey = contextPatient ? Patient.from(contextPatient).uniqueId : null;
			const [filteredConfirmedPatients, filteredUnconfirmedPatients] = this.refreshConfirmationSets(
				confirmedPatients, unconfirmedPatients, contextPatientKey,
				this.uploadFilesCollection, this.uploadFilesCollection.getPreparedFiles()
			);
			return {
				...oldState,
				contextPatientKey,
				confirmedPatients: filteredConfirmedPatients,
				unconfirmedPatients: filteredUnconfirmedPatients
			};
		});
	}

	[ON_UPLOAD_FILES_COLLECTION_CHANGED](uploadFilesCollection) {
		this.updateBrickState(oldState => {
			const {contextPatientKey, confirmedPatients, unconfirmedPatients} = oldState;
			const [filteredConfirmedPatients, filteredUnconfirmedPatients] = this.refreshConfirmationSets(
				confirmedPatients, unconfirmedPatients, contextPatientKey,
				uploadFilesCollection, uploadFilesCollection.getPreparedFiles()
			);
			return {
				...oldState,
				contextPatientKey,
				confirmedPatients: filteredConfirmedPatients,
				unconfirmedPatients: filteredUnconfirmedPatients
			};
		});
	}

	hasUnconfirmedOtherPatients() {
		return !this.getBrickState().unconfirmedPatients.isEmpty();
	}

	isUnconfirmedOtherPatient(patient) {
		if (patient && this.hasUnconfirmedOtherPatients()) {
			const {uniqueId: patientKey} = Patient.from(patient);
			return this.getBrickState().unconfirmedPatients.has(patientKey);
		}
		return false;
	}

	confirmOtherPatientWarning() {
		this.updateBrickState(oldState => {
			const {unconfirmedPatients, confirmedPatients} = oldState;
			return {
				...oldState,
				unconfirmedPatients: Immutable.Set(),
				confirmedPatients: confirmedPatients.union(unconfirmedPatients)
			};
		});
	}
}
declareBrick(PatientContextMonitor, [UploadFilesCollection, PatientContext]);

function refreshConfirmationSets(
		confirmedPatients, unconfirmedPatients,
		contextPatientKey, uploadFilesCollection, preparedFiles
) {
	const allPatientKeys = preparedFiles
		.map(name => uploadFilesCollection.getPreparedContainerValue(name))
		.map(context => (context ? context.patient : null))
		.filter(patient => Boolean(patient))
		.map(patient => Patient.from(patient).uniqueId);
	const allOtherPatients = allPatientKeys
		.filter(key => key !== contextPatientKey);
	const filteredConfirmedPatients = confirmedPatients
		.intersect(allOtherPatients);
	const filteredUnconfirmedPatients = unconfirmedPatients
		.union(allOtherPatients)
		.intersect(allOtherPatients)
		.subtract(filteredConfirmedPatients);

	return [filteredConfirmedPatients, filteredUnconfirmedPatients];
}
