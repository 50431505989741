import React from 'react';

import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils/index.js';
import SingleLineFormField from './SingleLineFormField.js';

/**
 * Renders a material input field of type email.
 * This enables certain features in on-screen keyboards and adds some validators.
 *
 * @author p.spitzlinger@synedra.com
 */
export default function EMailFormField(props) {
	return <SingleLineFormField {...props} type='email' />;
}
EMailFormField.propTypes = cloneWithoutProperties(
	SingleLineFormField.propTypes,
	'type'
);
