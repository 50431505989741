import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {CLEAR_VIEWER_ITEMS, UPDATE_VIEWER_ITEMS} from '../../constants/ViewerItemsActionTypes.js';

export default handleActions({
	[UPDATE_VIEWER_ITEMS]: updateViewerItems,
	[CLEAR_VIEWER_ITEMS]: clearViewerItems
}, getInitialState());

function getInitialState() {
	return Immutable.Map();
}

function updateViewerItems(viewerItems, {payload: typedUpdates}) {
	return typedUpdates.reduce(
		(allViewerItems, partialViewerItems, type) => partialViewerItems.reduce(
			(mergedViewerItems, partialViewerItem, viewerItemId) => mergedViewerItems.updateIn(
				[type, viewerItemId], Immutable.Map({type, id: viewerItemId}),
				existingViewerItem => (typeof (partialViewerItem) === 'function'
					? partialViewerItem(existingViewerItem)
					: existingViewerItem.merge(partialViewerItem))
			), allViewerItems)
		, viewerItems);
}

function clearViewerItems() {
	return getInitialState();
}
