import {identity} from '../../webview/commons/utils/FunctionUtils.js';
import {capitalizeFirst} from '../../webview/commons/utils/StringUtils.js';

const PARTS = [
	['last_name', beautifyNamePart],
	['first_name', beautifyNamePart],
	['middle_name', beautifyNamePart],
	['prefix', identity],
	['suffix', identity]
];

export default function parsePersonName(nameTagValue) {
	const nameParts = nameTagValue
		.split('^');
	return PARTS.reduce((personName, [part, processor], index) => {
		personName[part] = processor(nameParts.length > index ? nameParts[index] : '');
		return personName;
	}, {});
}

function beautifyNamePart(part) {
	return capitalizeFirst(part.toLowerCase());
}
