import React from 'react';

import {
	GenderFemaleIcon,
	GenderMaleIcon,
	GenderOtherIcon,
	GenderUnknownIcon
} from '../../../../ui/components/icons/GenderIcons.js';

const GENDER_ICON_MAP = {
	M: <GenderMaleIcon />,
	F: <GenderFemaleIcon />,
	U: <GenderUnknownIcon />,
	O: <GenderOtherIcon />
};

export default function getGenderIcon(gender = 'U') {
	return GENDER_ICON_MAP[gender.toUpperCase()] || <GenderUnknownIcon />;
}
