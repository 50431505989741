import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../webview/commons/utils/customHooks';
import UploadController from '../bricks/UploadController.js';
import StepPerformUpload from './StepPerformUpload.js';
import StepPrepareUploadListForPatient from './StepPrepareUploadListForPatient.js';
import UploadReport from './UploadReport.js';

export default function UploadWizardSteps(props) {
	const {controllerState, dropZoneProps, mapStateToStepComponent} = props;
	const StepperContentComponent = useMemoFactory(mapStateToStepComponent, controllerState);
	return (
		StepperContentComponent &&
			<StepperContentComponent controllerState={controllerState} dropZoneProps={dropZoneProps} />
	);
}

UploadWizardSteps.propTypes = {
	controllerState: PropTypes.oneOf(
		Object.keys(UploadController.State).map(key => UploadController.State[key])
	),
	dropZoneProps: PropTypes.object,
	mapStateToStepComponent: PropTypes.func
};
UploadWizardSteps.defaultProps = {
	mapStateToStepComponent: getStepperContentComponent
};

function getStepperContentComponent(uploadControllerState) {
	switch (uploadControllerState) {
		case UploadController.State.PREPARING:
		case UploadController.State.READY:
			return StepPrepareUploadListForPatient;
		case UploadController.State.UPLOADING:
			return StepPerformUpload;
		case UploadController.State.FINISHED:
			return UploadReport;
		default:
			return null;
	}
}
