import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {END_SESSION} from '../../../authentication/constants/SessionActionTypes.js';
import {IS_DEBUG_BUILD} from '../../../commons/constants/EnvironmentConstants.js';
import {identity} from '../../../commons/utils/FunctionUtils.js';
import {RESTORE_FROM_HISTORY} from '../../../router/constants/LocationActionTypes.js';
import {
	LOAD_ITEM_INTO_VIEWER,
	MERGE_VIEWER_PROPERTIES,
	RESET_VIEWER_PROPERTIES,
	RESET_VIEWER_STATE,
	SET_ACTIVE_VIEWER_ID,
	SET_VIEWER_LAYOUT_MODE,
	TOGGLE_FULLSCREEN
} from '../../constants/ViewerActionTypes.js';
import {
	VIEWER_STATE_ACTIVE_VIEWER_ID,
	VIEWER_STATE_FULLSCREEN_VIEWER_ID,
	VIEWER_STATE_LAYOUT_MODE,
	VIEWER_STATE_META_INFO,
	VIEWER_STATE_PROPERTIES
} from '../../constants/ViewerConstants.js';
import {validateViewerId} from '../../utils/ViewerUtils.js';
import {loadItemIntoViewer} from '../actions/ViewerActions.js';

const validateViewerIdThen = IS_DEBUG_BUILD ? validateViewerIdInDebugModeThen : identity;
function validateViewerIdInDebugModeThen(handler) {
	return (state, action) => {
		const viewerId = typeof (action.payload) === 'object' ? action.payload.viewerId : action.payload;
		if (viewerId !== undefined) {
			validateViewerId(viewerId);
		}
		return handler(state, action);
	};
}

const INITIAL_STATE = Immutable.Map();
export function getInitialState() {
	return INITIAL_STATE;
}

export default handleActions({
	[MERGE_VIEWER_PROPERTIES]: validateViewerIdThen(mergeViewerProperties),
	[RESET_VIEWER_PROPERTIES]: validateViewerIdThen(resetViewerProperties),
	[SET_ACTIVE_VIEWER_ID]: validateViewerIdThen(setActiveViewer),
	[TOGGLE_FULLSCREEN]: validateViewerIdThen(toggleFullscreen),
	[LOAD_ITEM_INTO_VIEWER]: validateViewerIdThen(loadItem),
	[SET_VIEWER_LAYOUT_MODE]: setViewerLayoutMode,
	[RESTORE_FROM_HISTORY]:	handleRestoreFromHistory,
	[RESET_VIEWER_STATE]: getInitialState,
	[END_SESSION]: handleEndSession
}, getInitialState());

function handleEndSession(viewerState, endSessionAction) {
	const {error} = endSessionAction;
	// keep current viewerState if session was aborted accidentally
	return error ? viewerState : INITIAL_STATE;
}

function mergeViewerProperties(state, {payload: {viewerId, partialProperties}}) {
	return state.mergeIn([VIEWER_STATE_PROPERTIES, viewerId], partialProperties);
}

function setFullscreenViewer(state, viewerId) {
	return state
		.set(VIEWER_STATE_FULLSCREEN_VIEWER_ID, viewerId)
		.set(VIEWER_STATE_ACTIVE_VIEWER_ID, viewerId);
}

function clearFullscreenViewer(state) {
	return state.delete(VIEWER_STATE_FULLSCREEN_VIEWER_ID);
}

function toggleFullscreen(state, {payload: viewerId}) {
	const isFullscreen = state.get(VIEWER_STATE_FULLSCREEN_VIEWER_ID) === viewerId;
	return isFullscreen
		? clearFullscreenViewer(state)
		: setFullscreenViewer(state, viewerId);
}

function setActiveViewer(state, {payload: activeViewerId}) {
	return state.set(VIEWER_STATE_ACTIVE_VIEWER_ID, activeViewerId);
}

function loadItem(state, {payload: {viewerId, itemType: type, itemId: id}}) {
	const currentItemMetaInfo = state.getIn([VIEWER_STATE_META_INFO, viewerId], Immutable.Map());
	let nextState = state;
	if (currentItemMetaInfo.get('type', null) !== type || currentItemMetaInfo.get('id', null) !== id) {
		nextState = resetViewer(state, viewerId)
			.setIn([VIEWER_STATE_META_INFO, viewerId], Immutable.Map({type, id}));
	}
	return nextState;
}

function setViewerLayoutMode(state, {payload: layoutMode}) {
	return state.set(VIEWER_STATE_LAYOUT_MODE, layoutMode);
}

function resetViewerProperties(state, {payload: viewerId}) {
	return state
		.setIn([VIEWER_STATE_PROPERTIES, viewerId], Immutable.Map())
		.set(VIEWER_STATE_ACTIVE_VIEWER_ID, viewerId);
}

function resetViewer(state, viewerId) {
	return state.setIn([VIEWER_STATE_PROPERTIES, viewerId], Immutable.Map());
}

function handleRestoreFromHistory(ignoredState, {payload: restoredLocationState}) {
	// Clear all previously set properties or loaded items.
	let state = getInitialState();

	const viewersPart = restoredLocationState.getIn(['query', 'viewers'], Immutable.Map());
	viewersPart.forEach((viewerState, viewerId) => {
		const itemId = viewerState.get('id', null);
		const itemType = viewerState.get('type', null);
		if (itemId !== null && itemType !== null) {
			state = loadItem(resetViewer(state, viewerId), loadItemIntoViewer(viewerId, itemType, itemId))
				.setIn([VIEWER_STATE_PROPERTIES, viewerId], viewerState.delete('id').delete('type'));
		}
	});
	state = restoreProperty(state, restoredLocationState, VIEWER_STATE_LAYOUT_MODE);
	state = restoreProperty(state, restoredLocationState, VIEWER_STATE_FULLSCREEN_VIEWER_ID);
	return state;
}

function restoreProperty(viewerState, historyState, propertyName) {
	const value = historyState.getIn(['query', propertyName], null);
	if (value) {
		return viewerState.set(propertyName, value);
	}
	return viewerState;
}
