import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import {ClickTrackedIconButton} from '../../../metrics-collector/components/TrackedComponents.js';
import HelpIcon from '../../../ui/components/icons/HelpIcon.js';
import PopupButton from '../../../ui/components/PopupButton.js';
import {ALIGN_CENTER, EXPAND_BOTTOM} from '../../../ui/constants/PupupMenuConstants.js';
import {COLOR_INHERIT} from '../../../ui/constants/SynMUIOptions.js';
import {callSafe} from '../../utils/FunctionUtils.js';
import {getHocDisplayName} from '../../utils/ReactUtils.js';

import '../../../../styles/commons/components/webview/PopupHelpButton.scss';

export default class PopupHelpButton extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundOnClick = this.onClick.bind(this);
		this.boundOnTap = this.onTap.bind(this);

		this.state = {
			touch: false
		};
	}

	render() {
		return this.isHelpAvailable() ? this.renderHelpButton() : false;
	}

	renderHelpButton() {
		const {helpComponent: HelpComponent} = this.props;
		const {touch} = this.state;
		return (
			<PopupButtonWithTap id='help-button' buttonElement={HelpButton} onClick={this.boundOnClick} onTap={this.boundOnTap}
									  align={ALIGN_CENTER} expandDirection={EXPAND_BOTTOM} contentClassName='popup-help-button--popup'>
				<div className='help-component-container'>
					<HelpComponent touch={touch} />
				</div>
			</PopupButtonWithTap>
		);
	}

	onClick() {
		this.setState({
			touch: false
		});
	}

	onTap() {
		this.setState({
			touch: true
		});
	}

	isHelpAvailable() {
		const {helpComponent} = this.props;
		return Boolean(helpComponent);
	}
}

function HelpButton(props) {
	return <ClickTrackedIconButton metricsKey='ShowHelp' icon={<HelpIcon />} color={COLOR_INHERIT} {...props} />;
}

function withClickOrTap(Component) {
	let touched = false;
	function TouchOrTap(props) {
		const {onClick, onTap, ...remainingProps} = props;
		const onPointerUp = useCallback(pointerEvent => {
			touched = pointerEvent.pointerType === 'touch';
		}, []);
		const onClickHandler = useCallback(event => {
			const handler = touched ? onTap : onClick;
			callSafe(handler, event);
		}, [onClick, onTap]);
		return <Component onClick={onClickHandler} onPointerUp={onPointerUp} {...remainingProps} />;
	}
	TouchOrTap.displayName = getHocDisplayName(Component, 'touchOrTab');
	TouchOrTap.propTypes = {
		onClick: PropTypes.func,
		...Component.propTypes,
		onTap: PropTypes.func
	};
	return TouchOrTap;
}

const PopupButtonWithTap = React.memo(
	withClickOrTap(PopupButton)
);

PopupHelpButton.propTypes = {
	helpComponent: PropTypes.elementType
};
