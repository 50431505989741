import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import GeneralMessagesTranslator from '../../../i18n/translators/GeneralTranslator.js';
import {PrimaryActionAvatarArea} from '../../../material-design/components/layout/PrimaryActionSideAreas.js';
import HorizontalLayout from '../../../ui/components/layout/HorizontalLayout.js';
import {Body2, H5} from '../../../ui/components/typography/Typography.js';
import {NO_BREAK_SPACE} from '../../constants/UnicodeCharacterConstants.js';
import Patient from '../../data/aim/Patient.js';
import {formatPatientInfo} from '../../data/aim/PatientHelpers.js';
import {useMemoFactory} from '../../utils/customHooks';
import {immutablePropType} from '../../utils/CustomPropTypes.js';
import GenderAvatar from '../symbols/GenderAvatar.js';

import '../../../../styles/commons/components/PatientInfo.scss';

function formatBirthDateFragment(birthDateValue, shortFormat = false) {
	const birthDateFormat = shortFormat ? 'ShortBirthDate' : 'BirthDate';
	return birthDateValue instanceof Date
		? <SynFormattedMessage className='birth-date' message={birthDateFormat} messageParams={{birthDate: birthDateValue}}
							   translator={GeneralMessagesTranslator} />
		: birthDateValue;
}

export default function PatientInfo(props) {
	const {
		firstName, lastName, birthDate, sex = '', patientInfo = null, element: Element = 'span', nameElement: NameElement = 'span',
		birthDayElement: BirthDayElement = 'span', shortBirthDate = false, ...remainingProps
	} = props;
	const birthDateFragment = birthDate ? formatBirthDateFragment(birthDate, shortBirthDate) : false;
	return (
		<Element {...remainingProps}>
			<NameElement className='patient-name'>
				{patientInfo || formatPatientInfo(sex, lastName, firstName)}
				{Boolean(birthDateFragment) && `,${NO_BREAK_SPACE}`}
			</NameElement>
			<BirthDayElement className='patient-birthday'>
				{Boolean(birthDateFragment) && birthDateFragment}
			</BirthDayElement>
		</Element>
	);
}

PatientInfo.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	birthDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
	sex: PropTypes.oneOf(['M', 'F', 'U', 'O']),
	patientInfo: PropTypes.string,
	element: PropTypes.elementType,
	nameElement: PropTypes.elementType,
	birthDayElement: PropTypes.elementType,
	shortBirthDate: PropTypes.bool
};

function PatientInfoWithGenderAvatar(props) {
	const {patient, centered, disableGenderAvatar} = props;
	const normalizedPatient = Patient.from(patient);
	const gender = normalizedPatient.sex;
	const patientInfos = {
		firstName: normalizedPatient.firstName,
		lastName: normalizedPatient.lastName,
		birthDate: normalizedPatient.birthDate
	};

	if (disableGenderAvatar) {
		patientInfos.sex = gender;
	}

	const genderAvatar = useMemoFactory(genderAvatarCreator, gender, disableGenderAvatar);

	return (
		<HorizontalLayout noGrow justify={centered ? 'center' : 'start'} className='patient-info--with-gender-avatar' align='center'>
			{genderAvatar}
			<PatientInfo nameElement={H5} birthDayElement={Body2} element={HorizontalLayout} justify='start'
			             align='baseline' wrap noGrow {...patientInfos} />
		</HorizontalLayout>
	);
}

PatientInfoWithGenderAvatar.propTypes = {
	patient: PropTypes.oneOfType([
		immutablePropType.isRequired,
		PropTypes.instanceOf(Patient)
	]),
	className: PropTypes.string,
	centered: PropTypes.bool,
	disableGenderAvatar: PropTypes.bool
};

PatientInfoWithGenderAvatar.defaultProps = {
	centered: false,
	disableGenderAvatar: false
};

function genderAvatarCreator(gender, disableGenderAvatar) {
	return disableGenderAvatar ? null : (
		<PrimaryActionAvatarArea>
			<GenderAvatar gender={gender} />
		</PrimaryActionAvatarArea>
	);
}

export {PatientInfoWithGenderAvatar};
