import Immutable from 'immutable';

// TODO: Replace with data/Patient.js
const PatientInfo = Immutable.Record({
	id: null,
	title: '',
	patient_id: '',
	first_name: '',
	last_name: '',
	birth_date: null,
	sex_fk: '',
	accepts_uploads: false
}, 'PatientInfo');

export default PatientInfo;
