import {IS_DEBUG_BUILD} from '../constants/EnvironmentConstants.js';

const LEVEL_LOG = 'log';
const LEVEL_WARN = 'warn';
const LEVEL_ERROR = 'error';

export function debugLog(...parts) {
	debugOut(LEVEL_LOG, ...parts);
}

export function debugWarn(...parts) {
	debugOut(LEVEL_WARN, ...parts);
}

export function debugError(...parts) {
	debugOut(LEVEL_ERROR, ...parts);
}

function debugOut(level, ...parts) {
	if (IS_DEBUG_BUILD) {
		/* eslint-disable-next-line no-console -- we want to write to the console in debug mode.
			The console-statement won't be effective in a production build.
		 */
		console[level](...parts);
	}
}
