import React from 'react';
import PropTypes from 'prop-types';

import {withStatusRole} from '../../webview/a11y/components/landmarks/Status.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import VerticalLayout from '../../webview/ui/components/layout/VerticalLayout.js';
import {Body2} from '../../webview/ui/components/typography/Typography.js';

const StatusMessageComponent = withStatusRole(Body2);

export default function EmptyUploadFilesList(props) {
	const {visible} = props;
	return (
		<VerticalLayout align='center' justify='center' hidden={!visible}>
			<SynFormattedMessage element={StatusMessageComponent} message='EmptyFilesList' />
		</VerticalLayout>
	);
}

EmptyUploadFilesList.propTypes = {
	visible: PropTypes.bool
};

EmptyUploadFilesList.defaultProps = {
	visible: true
};
