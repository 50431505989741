import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import useTranslation from '../../../i18n/hooks/useTranslation.js';
import Button from '../../../ui/components/Button.js';
import PreviousIcon from '../../../ui/components/icons/PreviousIcon.js';
import {callSafe} from '../../utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../utils/ObjectUtils/index.js';

const DIRECTION_FORWARD = 'forward';
const DIRECTION_BACKWARD = 'back';

export default function UploadStepperButtonForward(props) {
	return <UploadStepperButton {...props} direction={DIRECTION_FORWARD} />;
}

UploadStepperButtonForward.propTypes = cloneWithoutProperties(UploadStepperButton.propTypes, 'direction');

export function UploadStepperButtonBack(props) {
	return <UploadStepperButton {...props} direction={DIRECTION_BACKWARD} />;
}

UploadStepperButtonBack.propTypes = cloneWithoutProperties(UploadStepperButton.propTypes, 'direction');

function UploadStepperButton(props) {
	const {label, handler, className, direction} = props;
	const fullLabel = useTranslation(label);
	const onClickHandler = useCallback(event => {
		callSafe(handler, event);
	}, [handler]);
	const content = direction === DIRECTION_BACKWARD ? <PreviousIcon /> : fullLabel;
	const title = direction === DIRECTION_BACKWARD ? fullLabel : undefined;
	const moreRelevance = direction === DIRECTION_FORWARD;
	return (
		<Button title={title} className={className} opaque={moreRelevance} flat onClick={onClickHandler}
		        disabled={!handler}>
			{content}
		</Button>
	);
}

UploadStepperButton.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	handler: PropTypes.func,
	direction: PropTypes.oneOf([DIRECTION_FORWARD, DIRECTION_BACKWARD]).isRequired
};
