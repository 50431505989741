import React from 'react';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {memoizeLast} from '../../commons/utils/FunctionUtils.js';
import {ClickTrackedMenuEntry} from '../../metrics-collector/components/TrackedComponents.js';

const getPresetWidth = createPresetPropertyAccessor('width');
const getPresetCenter = createPresetPropertyAccessor('center');
const getPresetLabel = createPresetPropertyAccessor('label');
const getPresetToolIdentifier = createPresetPropertyAccessor('toolIdentifier');

export default class WindowPresetMenuEntry extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.getBoundSetWindow = memoizeLast(
			(preset, setWindow) => WindowPresetMenuEntry.bindSetWindow({preset, setWindow})
		);
	}

	render() {
		const {preset, setWindow} = this.props;
		const Label = getPresetLabel(preset);
		const toolId = getPresetToolIdentifier(preset);
		return (
			<ClickTrackedMenuEntry id={`menuEntry-${toolId}`} primaryText={<Label />} asListItem textAlignRight
			                       onClick={this.getBoundSetWindow(preset, setWindow)} metricsKey={toolId} />
		);
	}

	static bindSetWindow({setWindow, preset}) {
		return () => setWindow(getPresetCenter(preset), getPresetWidth(preset));
	}
}

WindowPresetMenuEntry.propTypes = {
	preset: immutableMapPropType,
	setWindow: PropTypes.func
};

function createPresetPropertyAccessor(propertyName) {
	return preset => (preset.get ? preset.get(propertyName) : preset[propertyName]);
}
