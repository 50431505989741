import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../../commons/utils/StyleUtils.js';

import '../../../../styles/viewer/components/annotations/CircleTouchArea.scss';

export default function CircleTouchArea(props) {
	const {point, className, innerRadius, outerRadius, style} = props;
	const strokeWidth = outerRadius - innerRadius;
	const radius = innerRadius + strokeWidth / 2;

	const finalClassName = combineClassNames('circle-touch-area', className);
	const finalStyle = {strokeWidth, ...style};
	return <circle cx={point[0]} cy={point[1]} r={radius} style={finalStyle} className={finalClassName} />;
}

CircleTouchArea.propTypes = {
	point: PropTypes.instanceOf(Float32Array),
	className: PropTypes.string,
	innerRadius: PropTypes.number,
	outerRadius: PropTypes.number,
	style: PropTypes.object
};
