import React from 'react';
import {createSelector} from 'reselect';

import createReduxSelectedComponent from '../../commons/containers/createReduxSelectedComponent.js';
import LoginPage from '../components/LoginPage.js';
import ChangeInvalidPasswordPageContainer from '../flux/containers/ChangeInvalidPasswordPageContainer.js';
import {selectIsPasswordInvalid} from '../flux/selectors/PasswordStateSelectors.js';
import {selectIsLoggedIn, selectStateIsUndefined} from '../flux/selectors/SessionSelectors.js';

export default function createSessionGuardedComponentSelector(componentSelector, loginComponent = LoginPage) {
	const reduxSelectedComponent = createReduxSelectedComponent(componentSelector);

	function selectComponent(isPasswordInvalid, isLoggedIn, isUndefined) {
		let component;
		if (isLoggedIn) {
			component = reduxSelectedComponent;
		} else if (isPasswordInvalid) {
			component = ChangeInvalidPasswordPageContainer;
		} else if (isUndefined) {
			component = () => false;
		} else {
			component = loginComponent;
		}
		return component;
	}

	return createSelector(selectIsPasswordInvalid, selectIsLoggedIn, selectStateIsUndefined, selectComponent);
}

