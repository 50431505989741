import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import createPersistentPortalAnchor from '../containers/createPersistentPortalAnchor.js';
import {useMemoFactory} from '../utils/customHooks/index.js';
import {callSafe} from '../utils/FunctionUtils.js';
import DialogAnchorController from './DialogAnchorController.js';

function DialogAnchor(props) {
	const {updatePersistentPortal, dialogIsActive, closePersistentPortal, dialogControllerRef, children} = props;
	const renderPortalContent = useMemoFactory(createRenderPortalContent, children, closePersistentPortal);
	const requestOpening = useRef(() => {
		if (!dialogIsActive) {
			callSafe(updatePersistentPortal, renderPortalContent());
		}
	});
	const dialogAnchorController = useRef(new DialogAnchorController({requestOpening: requestOpening.current}));

	useEffect(() => {
		callSafe(dialogControllerRef, dialogAnchorController.current);
		return () => {
			callSafe(dialogControllerRef, null);
		};
	}, [dialogControllerRef]);

	return null;
}

function createRenderPortalContent(children, closePersistentPortal) {
	return () => React.cloneElement(children, {show: true, onClose: closePersistentPortal});
}

DialogAnchor.propTypes = {
	dialogControllerRef: PropTypes.func,
	dialogIsActive: PropTypes.bool,
	updatePersistentPortal: PropTypes.func,
	closePersistentPortal: PropTypes.func
};

export default createPersistentPortalAnchor(DialogAnchor);
