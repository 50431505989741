import ClientConfigCrudResourceDefinition from './ClientConfigCrudResource.js';
import ModalityCrudResourceDefinition from './ModalityCrudResource.js';
import ShareReceiversCrudResourceDefinition from './ShareReceiversCrudResource.js';

export default {
	modality: ModalityCrudResourceDefinition,
	clientConfig: ClientConfigCrudResourceDefinition,
	shareReceiver: ShareReceiversCrudResourceDefinition,
	shareReceiverBackgroundValidator: ShareReceiversCrudResourceDefinition
};
