import React from 'react';
import PropTypes from 'prop-types';

import FullScreenContainer from '../../commons/components/FullScreenContainer.js';
import SynFormattedMessage from '../../commons/containers/SynFormattedMessageContainer.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import Dialog from '../../material-design/components/Dialog.js';
import DialogBody from '../../material-design/components/DialogBody.js';
import DialogHeader from '../../material-design/components/DialogHeader.js';
import LinkLikeButton from '../../ui/components/LinkLikeButton.js';
import {STATE_SUCCESS} from '../constants/NewPasswordConstants.js';
import ChangePasswordControllerContainer from '../flux/containers/ChangePasswordControllerContainer.js';
import ChangePasswordDialogMessagesTranslator from '../i18n/ChangePasswordDialogMessagesTranslator.js';
import changePasswordFormAccessors, {CHANGE_PASSWORD_FORM_SCHEMA} from './accessors/ChangePasswordFormAccessors.js';

import '../../../styles/commons/components/webview/ChangePasswordDialog.scss';

const DIALOG_TITLE = <SynFormattedMessage translator={ChangePasswordDialogMessagesTranslator} message='DialogTitle' />;
export const NEW_PASSWORD_FORM_FIELDS = Object.keys(CHANGE_PASSWORD_FORM_SCHEMA.fields);

export default class ChangePasswordDialog extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnSetNewPassword = this.onSetNewPassword.bind(this);
	}

	render() {
		const {username, passwordOperationState, uiShowChangePasswordDialog, hideChangePasswordDialog} = this.props;
		const showCancelButton = passwordOperationState !== STATE_SUCCESS;
		return (
			<FullScreenContainer visible={uiShowChangePasswordDialog}>
				<Dialog onClose={hideChangePasswordDialog}>
					<DialogHeader onClose={hideChangePasswordDialog} title={DIALOG_TITLE} />
					<DialogBody className='change-password-dialog--content'>
						<ChangePasswordControllerContainer username={username} onFinish={hideChangePasswordDialog}
							setNewPassword={this.boundOnSetNewPassword}
							translator={ChangePasswordDialogMessagesTranslator}
							passwordFormAccessors={changePasswordFormAccessors}
							passwordFormFields={NEW_PASSWORD_FORM_FIELDS}
							hideCancelLink>

							{showCancelButton && (
								<LinkLikeButton flat onClick={hideChangePasswordDialog}>
									<SynFormattedMessage translator={ChangePasswordDialogMessagesTranslator} message='CancelPhrase' />
								</LinkLikeButton>
							)}
							
						</ChangePasswordControllerContainer>
					</DialogBody>
				</Dialog>
			</FullScreenContainer>
		);
	}

	onSetNewPassword(formData) {
		const {changePassword, username} = this.props;
		const {oldPassword, newPassword, newPasswordConfirmation, verificationCode} = formData;
		callSafe(changePassword, username, oldPassword, newPassword, newPasswordConfirmation, verificationCode);
	}

	componentWillUnmount() {
		const {clearChangeError} = this.props;
		clearChangeError();
	}
}

ChangePasswordDialog.propTypes = {
	passwordOperationState: PropTypes.string, 
	uiShowChangePasswordDialog: PropTypes.bool, 
	hideChangePasswordDialog: PropTypes.func,
	changePassword: PropTypes.func,
	clearChangeError: PropTypes.func,
	username: PropTypes.string
};
