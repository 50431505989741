import React from 'react';
import {glMatrix, vec2} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import AnnotationsTranslator from '../../../i18n/translators/AnnotationsTranslator.js';
import {UNINITIALIZED_ANNOTATION_PROPERTIES} from '../../constants/AnnotationConstants.js';
import DicomImage from '../../data/DicomImage.js';
import {
	calculateLabelProperties,
	determinePixelSize, getLengthWithUnit,
	measureLength,
	toContainerPosition,
	toImagePosition
} from '../../utils/AnnotationUtils.js';
import {containsPoint2d, createRectangle} from '../../utils/math/Rectangle.js';
import {renderMultilineTextElements} from '../../utils/SVGUtils.js';
import {getOrthogonalProjectionPoint} from '../../utils/VectorUtils.js';
import {getDicomDump} from '../../utils/ViewerItemUtils.js';
import AnnotationLabel from './AnnotationLabel.js';
import createAnnotation from './createAnnotation.js';
import Path from './Path.js';
import SupportPath from './SupportPath.js';

function isParallelLineMeasurementSupported() {
	return false;
}

function getDefaultPropertiesForParallelLineMeasurement() {
	return Immutable.Map({});
}

function ParallelLineMeasurementComponent(props) {
	const {
		AnnotationRoot, annotationProperties, viewerItem, locale, lineHeight, containerWidth, containerHeight, fontSize
	} = props;
	const [baseLinePointA, baseLinePointB, referencePoint] = annotationProperties.get('points')
		.map(point => toContainerPosition(props, point));
	const projectionPoint = getOrthogonalProjectionPoint(baseLinePointA, baseLinePointB, referencePoint);
	const distanceLinePoints = [projectionPoint, referencePoint];
	const baseLinePoints = [baseLinePointA, baseLinePointB];
	const offset = vec2.subtract(vec2.create(), referencePoint, projectionPoint);
	const parallelLinePointA = vec2.add(vec2.create(), baseLinePointA, offset);
	const parallelLinePointB = vec2.add(vec2.create(), baseLinePointB, offset);
	const labelInformation = calculateLabelInformation(
		locale,
		distanceLinePoints.map(point => toImagePosition(props, point)),
		baseLinePoints.map(point => toImagePosition(props, point)),
		viewerItem, lineHeight
	);
	const getLabelProps = useMemoFactory(createGetLabelProps, containerWidth, containerHeight, fontSize);
	const labelPropsInViewPortRect = useMemoFactory(createLabelPropsInViewPortRect, containerWidth, containerHeight);
	const labelPath = [
		[parallelLinePointA, parallelLinePointB],
		[baseLinePointA, baseLinePointB]
	];
	const labelProps = chooseLabelProps(labelPath, getLabelProps, labelPropsInViewPortRect);
	return (
		<AnnotationRoot>
			<Path points={[baseLinePointA, baseLinePointB]} />
			<Path points={distanceLinePoints} />
			<SupportPath points={[parallelLinePointA, parallelLinePointB]} />
			<AnnotationLabel {...labelProps}>
				{labelInformation}
			</AnnotationLabel>
		</AnnotationRoot>
	);
}

ParallelLineMeasurementComponent.propTypes = {
	AnnotationRoot: PropTypes.elementType,
	annotationProperties: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.oneOf([UNINITIALIZED_ANNOTATION_PROPERTIES])
	]),
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	inverseTransformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	viewerItem: PropTypes.instanceOf(DicomImage),
	locale: PropTypes.string,
	lineHeight: PropTypes.number,
	containerWidth: PropTypes.number,
	containerHeight: PropTypes.number,
	fontSize: PropTypes.number
};

function createGetLabelProps(containerWidth, containerHeight, fontSize) {
	const viewportRect = createRectangle(
		[-containerWidth / 2, -containerHeight / 2],
		[containerWidth / 2, containerHeight / 2]
	);
	return function getLabelProps(points) {
		return calculateLabelProperties(points, viewportRect, fontSize, true);
	};
}

function createLabelPropsInViewPortRect(containerWidth, containerHeight) {
	const viewportRect = createRectangle(
		[-containerWidth / 2, -containerHeight / 2],
		[containerWidth / 2, containerHeight / 2]
	);
	return function labelPropsInViewPortRect(labelProps) {
		const point = [labelProps.x, labelProps.y];
		return containsPoint2d(viewportRect, point);
	};
}

function chooseLabelProps(labelPath, labelPropsRetriever, validLabelPropsCondition) {
	let labelProps;
	labelPath.some(pathSegment => {
		labelProps = labelPropsRetriever(pathSegment);
		return validLabelPropsCondition(labelProps);
	});
	return labelProps;
}

function calculateLabelInformation(locale, distancePoints, baseLinePoints, viewerItem, lineHeight) {
	const dicomDump = getDicomDump(viewerItem);
	const pixelSize = determinePixelSize([...distancePoints, ...baseLinePoints], dicomDump);
	const canMeasurePhysicalLength = Boolean(pixelSize);
	let information;
	if (canMeasurePhysicalLength) {
		const distanceLabel = AnnotationsTranslator.getFormattedMessage('Distance', locale);
		const lengthLabel = AnnotationsTranslator.getFormattedMessage('Length', locale);
		const measuredDistanceLength = measureLength(distancePoints, pixelSize);
		const measuredBaseLineLength = measureLength(baseLinePoints, pixelSize);
		const measuredDistanceLengthWithUnit = getLengthWithUnit(measuredDistanceLength);
		const measuredBaseLineLengthWithUnit = getLengthWithUnit(measuredBaseLineLength);
		information = `${distanceLabel}: ${measuredDistanceLengthWithUnit}, ${lengthLabel}: ${measuredBaseLineLengthWithUnit}`;
	} else {
		information = AnnotationsTranslator.getFormattedMessage('MeasurementNotPossible', locale);
	}
	return renderMultilineTextElements(information, lineHeight);
}

export default createAnnotation(
	isParallelLineMeasurementSupported, getDefaultPropertiesForParallelLineMeasurement, ParallelLineMeasurementComponent
);
