import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

// https://www.a11yproject.com/posts/2013-01-11-how-to-hide-content/

export default function Invisible(props) {
	const {children, component} = props;
	const sxProps = {
		position: 'absolute',
		width: '1px',
		height: '1px',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		clip: 'rect(0 0 0 0)',
		clipPath: 'inset(50%)'
	};
	return (
		<Box component={component} sx={sxProps}>
			{children}
		</Box>
	);
}

Invisible.propTypes = {
	component: PropTypes.elementType
};
