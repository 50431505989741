import React from 'react';
import AppBar from '@mui/material/AppBar';
import MUIDialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';

import useBrick from '../../bricks/hooks/useBrick.js';
import DeviceInfo from '../../commons/bricks/DeviceInfo.js';
import ErrorBoundary from '../../commons/components/errors/ErrorBoundary.js';
import EventBarrier from '../../commons/components/EventBarrier.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import IconButton from './IconButton.js';
import CloseIcon from './icons/CloseIcon.js';
import NotchInset from './layout/NotchInset.js';
import {H6} from './typography/Typography.js';

const DIALOG_EVENT_BARRIER_STOP_PROPAGATION = ['onClick'];

export default React.memo(Dialog);

function Dialog(props) {
	const {show, title, onClose, fullScreen, disableRestoreFocus, children} = props;
	const viewportStyle = useBrick(DeviceInfo, selectViewportStyle);
	const viewportSpecificStyle = fullScreen ? viewportStyle : undefined;
	return (
		<EventBarrier component={MUIDialog} stopPropagation={DIALOG_EVENT_BARRIER_STOP_PROPAGATION} open={show}
		              onClose={onClose} disableRestoreFocus={disableRestoreFocus} fullScreen={fullScreen}
		              sx={viewportSpecificStyle} fullWidth maxWidth='md'>
			<ErrorBoundary>
				<AppBar position='relative'>
					<Toolbar>
						{fullScreen && <NotchInset variant='left' />}
						<IconButton color='inherit' onClick={onClose} icon={<CloseIcon />} sx={{mr: 2}} />
						{title && (
							<H6 sx={{flexGrow: 1, py: 1}}>
								{title}
							</H6>
						)}
						{fullScreen && <NotchInset variant='right' />}
					</Toolbar>
				</AppBar>
				{children}
			</ErrorBoundary>
		</EventBarrier>
	);
}

Dialog.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	fullScreen: PropTypes.bool,
	children: PropTypes.node.isRequired,
	disableRestoreFocus: PropTypes.bool,
	title: PropTypes.node
};

Dialog.defaultProps = {
	onClose: noop,
	fullScreen: false,
	disableRestoreFocus: false
};

function selectViewportStyle(deviceInfo) {
	return {
		top: `${deviceInfo.getViewport().top}px`,
		height: `${deviceInfo.getViewport().height}px`
	};
}
