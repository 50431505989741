import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';

import {getDicomDump} from '../../../utils/ViewerItemUtils.js';
import createAnnotation from '../createAnnotation.js';
import FourPointGoniometryAnnotation from './FourPointGoniometryAnnotation.js';
import HybridGoniometryAnnotation from './HybridGoniometryAnnotation.js';

const ONE_FOURTH_DENOMINATOR = 4;
const ONE_TWELFTH_DENOMINATOR = 12;

export const ThreePointGoniometry = createAnnotation(
	function isDistanceMassurementSupported(viewerItem) {
		return isLoadedDicomImage(viewerItem) && hasPixelAspectRatio(viewerItem);
	},
	function getInitialMeasurementProperties(props) {
		const {containerWidth, containerHeight, inverseTransformationMatrix} = props;
		const oneFourthOfWidth = containerWidth / ONE_FOURTH_DENOMINATOR;
		const oneFourthOfHeight = containerHeight / ONE_FOURTH_DENOMINATOR;

		const containerStart = vec2.set(vec2.create(), oneFourthOfWidth, -oneFourthOfWidth);
		const containerCenter = vec2.set(vec2.create(), -oneFourthOfWidth, 0);
		const containerEnd = vec2.set(vec2.create(), oneFourthOfWidth, oneFourthOfHeight);

		return Immutable.Map({
			points: [
				vec2.transformMat3(containerStart, containerStart, inverseTransformationMatrix),
				vec2.transformMat3(containerCenter, containerCenter, inverseTransformationMatrix),
				vec2.transformMat3(containerEnd, containerEnd, inverseTransformationMatrix)
			]
		});
	},
	HybridGoniometryAnnotation
);

export const FourPointGoniometry = createAnnotation(
	function isDistanceMeasurementSupported(viewerItem) {
		return isLoadedDicomImage(viewerItem) && hasPixelAspectRatio(viewerItem);
	},
	function getInitialMeasurementProperties(props) {
		const {containerWidth, containerHeight, inverseTransformationMatrix} = props;
		const oneFourthOfWidth = containerWidth / ONE_FOURTH_DENOMINATOR;
		const oneFourthOfHeight = containerHeight / ONE_FOURTH_DENOMINATOR;
		const oneTwelfthOfWidth = containerWidth / ONE_TWELFTH_DENOMINATOR;
		const oneTwelfthOfHeight = containerHeight / ONE_TWELFTH_DENOMINATOR;

		const lineOneA = vec2.set(vec2.create(), oneFourthOfWidth, -oneFourthOfHeight);
		const lineOneB = vec2.set(vec2.create(), -oneTwelfthOfWidth, -oneTwelfthOfHeight);
		const lineTwoA = vec2.set(vec2.create(), -oneTwelfthOfWidth, oneTwelfthOfHeight);
		const lineTwoB = vec2.set(vec2.create(), oneFourthOfWidth, oneFourthOfHeight);

		return Immutable.Map({
			points: [
				vec2.transformMat3(lineOneA, lineOneA, inverseTransformationMatrix),
				vec2.transformMat3(lineOneB, lineOneB, inverseTransformationMatrix),
				vec2.transformMat3(lineTwoA, lineTwoA, inverseTransformationMatrix),
				vec2.transformMat3(lineTwoB, lineTwoB, inverseTransformationMatrix)
			]
		});
	},
	FourPointGoniometryAnnotation
);

function isLoadedDicomImage(viewerItem) {
	const isValidViewerItem = (viewerItem && viewerItem.get('type') === 'image' && getDicomDump(viewerItem));
	return isValidViewerItem && hasPixelAspectRatio(viewerItem);
}

function hasPixelAspectRatio(viewerItem) {
	return getDicomDump(viewerItem).getPixelAspectRatio();
}
