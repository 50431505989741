import React from 'react';
import {object, string} from 'prop-types';

import {ColumnCenterLayout} from '../../commons/components/layout/CenterLayout.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {STATUS_WARNING} from '../../commons/constants/StatusMessageConstants.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerMessagesTranslator from '../../i18n/translators/ViewerTranslator.js';

import '../../../styles/viewer/components/UnsupportedFileViewer.scss';

export default function UnsupportedItemViewer(props) {
	const {message, messageParams = {}, children} = props;
	return (
		<ColumnCenterLayout className='image-viewer-unsupported--container'>
			<StatusMessage status={STATUS_WARNING} className='image-viewer-unsupported--status-message'>
				<SynFormattedMessage translator={ViewerMessagesTranslator}
					messageParams={messageParams} message={message} />
			</StatusMessage>
			{children}
		</ColumnCenterLayout>
	);
}

UnsupportedItemViewer.propTypes = {
	message: string.isRequired,
	messageParams: object
};
