import React from 'react';
import PropTypes from 'prop-types';

import clickTracked from '../../metrics-collector/utils/clickTracked.js';
import {useToggleAnnotationState} from '../hooks/useToggleAnnotationState.js';
import ViewerToolbarButton from './ViewerToolbarButton.js';

const ClickTrackedToggleAnnotationsButton = clickTracked(ViewerToolbarButton);

function ViewerToggleAnnotationsButton(props) {
	const {disabled} = props;
	const [toggleAnnotations, Icon] = useToggleAnnotationState();
	return (
		<ClickTrackedToggleAnnotationsButton onClick={toggleAnnotations} disabled={disabled} metricsKey='ToggleAnnotations'>
			<Icon />
		</ClickTrackedToggleAnnotationsButton>
	);
}
ViewerToggleAnnotationsButton.propTypes = {
	disabled: PropTypes.bool
};

export default ViewerToggleAnnotationsButton;
