import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import {MUI_FIELD_VARIANTS} from '../../constants/SynMUIOptions.js';

export default withForwardRef(DefaultAutoCompleteTextField, 'forwardedRef');

function DefaultAutoCompleteTextField(props) {
	const {
		params, className, label, name, noMargin, error, hint, title, small, onBlur, autoFocus, InputProps,
		forwardedRef
	} = props;
	const extendedInputProps = {
		...params.InputProps,
		...InputProps
	};
	return (
		<TextField {...params} InputProps={extendedInputProps} inputRef={forwardedRef} className={className}
		           label={label} name={name} margin={noMargin ? 'none' : 'normal'} variant={MUI_FIELD_VARIANTS.DEFAULT}
		           error={error} helperText={hint} title={title} size={small ? 'small' : undefined} onBlur={onBlur}
		           autoFocus={autoFocus} />
	);
}

DefaultAutoCompleteTextField.propTypes = {
	forwardedRef: withForwardRef.PropTypes.Ref,
	InputProps: PropTypes.object,
	params: PropTypes.object,
	label: PropTypes.string,
	className: PropTypes.string,
	small: PropTypes.bool,
	error: PropTypes.bool,
	noMargin: PropTypes.bool,
	title: PropTypes.string,
	hint: PropTypes.string,
	onBlur: PropTypes.func,
	autoFocus: PropTypes.bool,
	name: PropTypes.string
};
