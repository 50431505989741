import React from 'react';
import PropTypes from 'prop-types';

import DownloadLink from '../../commons/components/DownloadLink.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import PdfViewerTranslator from '../../i18n/translators/PdfViewerMessagesTranslator.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';

import '../../../styles/viewer/components/PdfViewer.scss';

export default class EmbeddedPdfView extends React.PureComponent {
	render() {
		const {inlineUrl, downloadUrl, rawViewerItem} = this.props;
		const data = inlineUrl ? inlineUrl : `data:application/pdf;base64,${rawViewerItem}`;
		return (
			<VerticalLayout element='object' justify='center' key={inlineUrl} data={data}
				className='embedded-pdf-viewer--container'>
				<SynFormattedMessage message='NoPdfSupport' translator={PdfViewerTranslator} />
				<DownloadLink href={downloadUrl}>
					<SynFormattedMessage translator={PdfViewerTranslator} message='ClickToDownloadPDF' />
				</DownloadLink>
			</VerticalLayout>
		);
	}
}

EmbeddedPdfView.propTypes = {
	inlineUrl: PropTypes.string,
	downloadUrl: PropTypes.string,
	rawViewerItem: immutableMapPropType
};
