import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import _debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import {useAction} from '../../commons/flux/ReactHooks';
import {useMemoFactory} from '../../commons/utils/customHooks';
import {callSafe} from '../../commons/utils/FunctionUtils';
import useTranslation from '../../i18n/hooks/useTranslation';
import ContentRow from '../../material-design/components/layout/ContentRow';
import IconButton from '../../ui/components/IconButton';
import CloseIcon from '../../ui/components/icons/CloseIcon';
import HelpIcon from '../../ui/components/icons/HelpIcon';
import SearchIcon from '../../ui/components/icons/SearchIcon';
import HorizontalLayout from '../../ui/components/layout/HorizontalLayout';
import PopupButton from '../../ui/components/PopupButton';
import SingleLineTextField from '../../ui/components/SingleLineTextField';
import {EXPAND_BOTTOM} from '../../ui/constants/PupupMenuConstants';
import {FilterPopupHelp} from '../../viewer/components/ViewerPopupHelp';
import {filterPatientDetails} from '../flux/PatientDetailsActions';
import {selectFilterString} from '../flux/selectors/PatientDetailsSelectors';

import '../../../styles/commons/components/webview/PopupHelpButton.scss';

const DEBOUNCE_TIME_MS = 500;

export default function PatientFileFilter(props) {
	const {activateFilter, deactivateFilter, autoFocus, alwaysShowClearIcon} = props;
	const filterString = useSelector(selectFilterString);
	const filterAction = useAction(filterPatientDetails);

	const [currentInputValue, setCurrentInputValue] = useState(filterString);
	const onChangeDebounced = useMemoFactory(createOnChangeDebounced, DEBOUNCE_TIME_MS, filterAction);
	const handleValueChange = useMemoFactory(createHandleValueChange,
		currentInputValue, setCurrentInputValue, onChangeDebounced);

	const onClear = useMemoFactory(createOnClear, deactivateFilter, filterAction, setCurrentInputValue);

	const filterPlaceholder = `${useTranslation('FilterPlaceholder')}...`;

	const closeIcon = <CloseIcon onClick={onClear} sx={{cursor: 'pointer'}} />;
	const showCloseIcon = filterString || alwaysShowClearIcon;

	return (
		<HorizontalLayout element={ContentRow} noGrow className='patient-details--patientfile' align='center'>
			<SingleLineTextField value={currentInputValue} placeholder={filterPlaceholder} name='PatientFileFilter'
					  onChange={handleValueChange} noMargin
					  onActivate={activateFilter} autoFocus={autoFocus} small fullWidth
				      textFieldButtonsStart={<SearchIcon />}
					  textFieldButtons={showCloseIcon ? closeIcon : null} />
			<PopupButton id='help-button' contentClassName='popup-help-button--popup'
						 buttonElement={HelpButton} expandDirection={EXPAND_BOTTOM}>
				<div className='help-component-container'>
					<FilterPopupHelp />
				</div>
			</PopupButton>
		</HorizontalLayout>

	);
}

function HelpButton(props) {
	return <IconButton icon={<HelpIcon />} sx={{mr: 1}} size='small' {...props} />;
}

function createOnClear(deactivateFilter, filterAction, setCurrentInputValue) {
	return () => {
		filterAction('');
		setCurrentInputValue(null);
		deactivateFilter();
	};
}

function createHandleValueChange(currentInputValue, setCurrentInputValue, onChangeDebounced) {
	return newValue => {
		if (currentInputValue !== newValue) {
			setCurrentInputValue(newValue);
			onChangeDebounced(newValue);
		}
	};
}

function createOnChangeDebounced(interval, action) {
	return _debounce(newValue => {
		callSafe(action, newValue);
	}, interval);
}

PatientFileFilter.propTypes = {
	activateFilter: PropTypes.func,
	deactivateFilter: PropTypes.func,
	autoFocus: PropTypes.bool,
	alwaysShowClearIcon: PropTypes.bool
};

