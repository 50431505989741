import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {isPasswordResetEnabled} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {locationSelector, querySelector} from '../../../router/flux/selectors/LocationSelectors.js';
import {
	QUERY_PARAM_ACTIVATION_TOKEN,
	QUERY_PARAM_RESET_TOKEN,
	QUERY_PARAM_USERNAME,
	STATE_2ND_FACTOR,
	STATE_ERROR,
	STATE_NEW_PASSWORD,
	STATE_SUCCESS
} from '../../constants/NewPasswordConstants.js';
import {PASSWORD_CHANGE_ERROR_2ND_FACTOR_REQUIRED} from '../../constants/PasswordStateReducerStates.js';
import {selectError, selectOperationSucceeded} from './PasswordStateSelectors.js';

export const selectActivationToken = createSelector(
	querySelector, query => query.get(QUERY_PARAM_ACTIVATION_TOKEN, null)
);

export const selectResetToken = createSelector(
	querySelector, query => query.get(QUERY_PARAM_RESET_TOKEN, null)
);

export const selectUsername = createSelector(
	querySelector, query => query.get(QUERY_PARAM_USERNAME, null)
);

export const selectCanActivate = createSelector(
	selectActivationToken, selectUsername,
	(token, username) => Boolean(token) && Boolean(username)
);

export const selectCanReset = createSelector(
	isPasswordResetEnabled, selectResetToken, selectUsername,
	(passwordResetEnabled, token, username) => passwordResetEnabled && Boolean(token) && Boolean(username)
);

export const selectOriginalLocation = createSelector(
	locationSelector,
	location => location.update('query', Immutable.Map(), query => query
		.delete(QUERY_PARAM_ACTIVATION_TOKEN)
		.delete(QUERY_PARAM_RESET_TOKEN)
		.delete(QUERY_PARAM_USERNAME)
	)
);

export const selectPasswordOperationState = createSelector(
	selectError, selectOperationSucceeded,
	(error, operationSucceeded) => {
		let state = operationSucceeded ? STATE_SUCCESS : STATE_NEW_PASSWORD;
		if (error === PASSWORD_CHANGE_ERROR_2ND_FACTOR_REQUIRED) {
			state = STATE_2ND_FACTOR;
		} else if (error) {
			state = STATE_ERROR;
		}
		return state;
	}
);

