import {createSelector} from 'reselect';

import {
	KEY_DIAGNOSTIC_WARNING_ACCEPTED_FOR,
	SECTION_WEBVIEW
} from '../../constants/crudresources/ClientConfigConstants.js';
import {MINIMUM_REQUIRED_ACCEPTED_VERSION} from '../../constants/WebViewConstants.js';
import {clientConfig as clientConfigFluxApi} from '../../flux/crudresources/CrudResourcesFluxApi.js';
import {compareVersions, parseVersionString} from '../../utils/VersionUtils.js';

const {
	getResponse: getClientConfigData
} = clientConfigFluxApi.selectors;

export function getConfigEntry(section, key, defaultValue = null) {
	const keyPath = [section, key];
	return createSelector(
		getClientConfigData,
		data => (Boolean(data) && data.getIn(keyPath, defaultValue) || defaultValue)
	);
}

export const nonDiagnosticWarningAccepted = createSelector(
	getConfigEntry(SECTION_WEBVIEW, KEY_DIAGNOSTIC_WARNING_ACCEPTED_FOR, ''),
	acceptedVersionString => compareVersions(parseVersionString(acceptedVersionString),
		MINIMUM_REQUIRED_ACCEPTED_VERSION) >= 0
);
