import React from 'react';
import PropTypes from 'prop-types';

import DownloadLink from '../../commons/components/DownloadLink.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DownloadOptionsMessagesTranslator from '../../i18n/translators/DownloadOptionsMessagesTranslator.js';
import DownloadIcon from '../../ui/components/icons/DownloadIcon.js';

import '../../../styles/viewer/components/ViewerItemDownloadOptions.scss';

export default function ViewerItemDownloadOptions(props) {
	const {downloadOptions = null} = props;
	return downloadOptions && (
		<div className='image-viewer-unsupported--download-items'>
			{
				downloadOptions.map(({message, url}) => (
					<DownloadLink key={url} href={url}>
						<DownloadIcon />
						<SynFormattedMessage translator={DownloadOptionsMessagesTranslator} message={message} />
					</DownloadLink>
				))
			}
		</div>
	);
}

ViewerItemDownloadOptions.propTypes = {
	downloadOptions: PropTypes.arrayOf(
		PropTypes.shape({
			message: PropTypes.string,
			url: PropTypes.string
		})
	)
};
