import {createMultiFieldValidatorFactory} from '../ValidatorFactories.js';
import {equalsValidatorFunction} from './equalsValidator.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';

export function notEqualsValidatorFunction(locale, translatedFieldLabelsArray, translator, message, fieldValues) {
	return equalsValidatorFunction(locale, translatedFieldLabelsArray, translator, message, fieldValues) !== true ||
		fieldValues.every(value => value === undefined) ||
		translator.getFormattedMessage(message, locale,
			{inputFieldLabels: translator.toListing(translatedFieldLabelsArray.slice(1), 'And', locale)});
}

export default function notEqualsValidator(fieldNames, translator = ValidatorsMessagesTranslator, message = 'NotEquals') {
	return createMultiFieldValidatorFactory(
		(locale, translatedFieldValues, ...fieldValues) => notEqualsValidatorFunction(
			locale, translatedFieldValues, translator, message, fieldValues
		)
	)(fieldNames);
}
