import React from 'react';
import {glMatrix, mat4, vec3} from 'gl-matrix';
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';

import {
	getDomImages,
	getHeight,
	getInvertOutputColors,
	getOverlayDomImages,
	getRenderingParameters,
	getWidth
} from '../../commons/data/aim/SynAdvancedImage.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {preventEventDefault} from '../../commons/utils/DOMEventUtils.js';
import {getVisibilityChangeEventName, isDocumentVisible} from '../../commons/utils/DOMUtils.js';
import {memoizeLastWithCleanup} from '../../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../../commons/utils/ObjectUtils';
import DomEventsManager from '../../events/DomEventsManager.js';
import viewerFragmentShader from '../shaders/viewer-fragment-shader.glsl';
import viewerOverlayFragmentShader from '../shaders/viewer-overlay-fragment-shader.glsl';
import viewerVertexShader from '../shaders/viewer-vertex-shader.glsl';
import {mat3ToMat4} from '../utils/math/MatrixHelper.js';
import {
	createArrayBuffer,
	createPreprocessorDefine,
	createTexture,
	createWebGLContext,
	disableAttribute,
	getDefaultPreprocessorDirectives,
	getMaxNrOfTextures,
	initializeProgram,
	setAttribute,
	setMatrix
} from '../utils/WebGLUtils.js';
import ViewerError from '../ViewerError.js';

import '../../../styles/viewer/components/WebGLImageViewer.scss';

const RECTANGLE_PERSPECTIVE_MATRIX = mat4.create();
mat4.scale(RECTANGLE_PERSPECTIVE_MATRIX, RECTANGLE_PERSPECTIVE_MATRIX, vec3.set(vec3.create(), 1.0, 1.0, 1.0));

const IMAGE_PLANE_TRIANGLES_COORDINATES = new Float32Array([
	1.0, -1.0, 0.0,
	-1.0, -1.0, 0.0,
	1.0, 1.0, 0.0,
	-1.0, 1.0, 0.0
]);
const POINTS_PER_TRIANGLE = 3;
const IMAGE_PLANE_TRIANGLE_COUNT = IMAGE_PLANE_TRIANGLES_COORDINATES.length / POINTS_PER_TRIANGLE;

const IMAGE_PLANE_TEXTURE_COORDINATES = new Float32Array([
	1.0, 0.0,
	0.0, 0.0,
	1.0, 1.0,
	0.0, 1.0
]);

const CAPTURE_IMAGE_TIMEOUT_MS = 200;

export default class WebGLImageViewer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.viewerCanvas = null;
		this.currentAnimationFrameRequest = null;
		this.captureImageTimer = null;
		this.boundCaptureImage = this.captureImage.bind(this);
		this.boundHandleWebContextLost = this.handleWebGLContextLost.bind(this);
		this.boundReInitializeWebGLContext = this.reInitializeWebGLContext.bind(this);
		this.boundCanvasReferenceCallback = this.canvasReferenceCallback.bind(this);
		this.boundHandleVisibilityChange = this.handleVisibilityChange.bind(this);
		this.boundOnWindowBlur = this.notInPrintPreview(this.onWindowBlur.bind(this));
		this.boundOnWindowFocus = this.notInPrintPreview(this.onWindowFocus.bind(this));
		this.domEventsManager = new DomEventsManager();
		this.resetMemoizedFunctions();

		this.selectMatrices = createMatricesSelector();

		this.state = {
			documentVisible: isDocumentVisible(),
			windowBlurred: false,
			capturedImage: null,
			glState: null,
			forceRendering: false
		};
	}

	render() {
		const {
			width,
			height
		} = this.props;
		const {capturedImage} = this.state;
		return (this.shouldRenderGLCanvas()
			? <canvas className='webgl-image-viewer--canvas' key='webgl-image-viewer'
							 width={width} height={height}
							 ref={this.boundCanvasReferenceCallback} />
			: <img className='webgl-image-viewer--canvas' key='webgl-image-viewer--temp-image' src={capturedImage}
						 width={width} height={height} alt='' />
		);
	}

	renderDecodedImage() {
		const glState = this.getGLState();
		const {glContext} = glState;
		if (glContext) {
			const {
				width,
				height,
				decodedImage
			} = this.props;
			const matrices = this.selectMatrices(this.props);

			glContext.viewport(0, 0, width, height);
			glContext.clear(glContext.COLOR_BUFFER_BIT | glContext.DEPTH_BUFFER_BIT);

			this.renderImage(glState, matrices, decodedImage);
			this.renderOverlays(glState, matrices, decodedImage);
		}
	}

	renderImage(glState, matrices, decodedImage) {
		const {
			glContext
		} = glState;
		const {images} = this.loadTextures(glContext, decodedImage);
		const {windowCenter: wCenterFromProps, windowWidth: wWidthFromProps} = this.props;
		const renderingParameters = getRenderingParameters(wCenterFromProps, wWidthFromProps, decodedImage);
		const {
			windowWidth,
			windowCenter
		} = renderingParameters;
		const program = this.compileProgram(glContext, decodedImage);
		WebGLImageViewer.setupProgram(glState, program, matrices);

		images.forEach((image, index) => {
			const uniformName = `uImageTexture${index}`;
			WebGLImageViewer.setupTexture(glContext, index, images[index]);
			glContext.uniform1i(glContext.getUniformLocation(program, uniformName), index);
		});

		WebGLImageViewer.setupRenderParameters(program, windowCenter, windowWidth, glContext, decodedImage);

		glContext.drawArrays(glContext.TRIANGLE_STRIP, 0, IMAGE_PLANE_TRIANGLE_COUNT);

		WebGLImageViewer.cleanupProgram(glState, program);
	}

	renderOverlays(glState, matrices, decodedImage) {
		const {
			renderOverlayProgram,
			glContext
		} = glState;
		const {overlays} = this.loadTextures(glContext, decodedImage);
		if (overlays && overlays.length > 0) {
			WebGLImageViewer.setupProgram(glState, renderOverlayProgram, matrices);
			overlays.forEach(overlayTexture => {
				// TODO PSp :: Fix repeating usage of TEXTURE2
				glContext.activeTexture(glContext.TEXTURE2);
				glContext.bindTexture(glContext.TEXTURE_2D, overlayTexture);
				glContext.texParameteri(glContext.TEXTURE_2D, glContext.TEXTURE_MIN_FILTER, glContext.LINEAR);
				glContext.texParameteri(glContext.TEXTURE_2D, glContext.TEXTURE_MAG_FILTER, glContext.LINEAR);
				glContext.uniform1i(glContext.getUniformLocation(renderOverlayProgram, 'uOverlayTexture'), 2);
				glContext.drawArrays(glContext.TRIANGLE_STRIP, 0, IMAGE_PLANE_TRIANGLE_COUNT);
			});
			WebGLImageViewer.cleanupProgram(glState, renderOverlayProgram);
		}
	}

	static setupProgram(glState, program, matrices) {
		const {
			buffers,
			glContext
		} = glState;
		glContext.useProgram(program);

		setAttribute(glContext, program, 'aVertexPosition', buffers.triangles, {dimension: 3});
		setAttribute(glContext, program, 'aTextureCoord', buffers.textureCoordinates, {dimension: 2});

		setMatrix(glContext, program, 'uPerspectiveMatrix', matrices.perspective);
		setMatrix(glContext, program, 'uModelViewMatrix', matrices.modelView);
		setMatrix(glContext, program, 'uTransformationMatrix', matrices.transformationMatrix);
	}

	static cleanupProgram(glState, program) {
		const {glContext} = glState;
		disableAttribute(glContext, program, 'aVertexPosition');
		disableAttribute(glContext, program, 'aTextureCoord');
	}

	static setupTexture(glContext, textureNumber, texture) {
		const textureName = `TEXTURE${textureNumber}`;
		glContext.activeTexture(glContext[textureName]);
		glContext.bindTexture(glContext.TEXTURE_2D, texture);
		glContext.texParameteri(glContext.TEXTURE_2D, glContext.TEXTURE_MIN_FILTER, glContext.NEAREST);
		glContext.texParameteri(glContext.TEXTURE_2D, glContext.TEXTURE_MAG_FILTER, glContext.NEAREST);
	}

	static setupRenderParameters(program, windowCenter, windowWidth, glContext, decodedImage) {
		const scaledWindowCenter = (windowCenter - 0.5);
		const scaledWindowWidth = (windowWidth - 1.0);
		const lowestVisibleValue = scaledWindowCenter - (scaledWindowWidth / 2.0);
		const highestVisibleValue = scaledWindowCenter + (scaledWindowWidth / 2.0);

		glContext.uniform1f(glContext.getUniformLocation(program, 'uImageWidth'), getWidth(decodedImage));
		glContext.uniform1f(glContext.getUniformLocation(program, 'uImageHeight'), getHeight(decodedImage));
		glContext.uniform1f(glContext.getUniformLocation(program, 'uScaledWindowCenter'), scaledWindowCenter);
		glContext.uniform1f(glContext.getUniformLocation(program, 'uScaledWindowWidth'), scaledWindowWidth);
		glContext.uniform1f(glContext.getUniformLocation(program, 'uLowestVisibleValue'), lowestVisibleValue);
		glContext.uniform1f(glContext.getUniformLocation(program, 'uHighestVisibleValue'), highestVisibleValue);
	}

	notInPrintPreview(wrappedCallee) {
		return (...args) => {
			const {isPrintPreview} = this.props;
			if (!isPrintPreview) {
				wrappedCallee(...args);
			}
		};
	}

	setGLState(partialState, state = this.state) {
		this.setState({
			glState: this.mergeGLState(partialState, state)
		});
	}

	getGLState(state = this.state) {
		const {glState = {}} = state || {};
		return glState;
	}

	mergeGLState(partialState, state = this.state) {
		return {...this.getGLState(state), ...partialState};
	}

	componentDidMount() {
		this.domEventsManager.addEventListener(
			document, getVisibilityChangeEventName(),
			this.boundHandleVisibilityChange, false
		);
		this.domEventsManager.addEventListener(window, 'blur', this.boundOnWindowBlur, false);
		this.domEventsManager.addEventListener(window, 'focus', this.boundOnWindowFocus, false);
		this.scheduleImageRendering();
	}

	handleVisibilityChange() {
		if (isDocumentVisible()) {
			this.setState({
				documentVisible: true
			});
		} else {
			this.setState({
				documentVisible: false,
				glState: this.mergeGLState(this.releaseGLContext())
			});
		}
	}

	setWindowBlurred(blurred) {
		this.setState({
			windowBlurred: blurred
		});
	}

	onWindowBlur() {
		this.setWindowBlurred(true);
		this.scheduleCaptureImage();
	}

	onWindowFocus() {
		this.setWindowBlurred(false);
		this.releaseCaptureImage();
		this.forceRendering();
	}

	forceRendering() {
		this.setState({
			capturedImage: null,
			forceRendering: true
		});
	}

	componentWillUnmount() {
		this.releaseCanvas();
		this.releaseCaptureImage();
		this.domEventsManager.removeAllListeners();
	}

	bindCanvas(viewerCanvas) {
		this.viewerCanvas = viewerCanvas;
		this.domEventsManager.addEventListener(this.viewerCanvas, 'webglcontextlost', this.boundHandleWebContextLost,
			false);
		this.domEventsManager.addEventListener(this.viewerCanvas, 'webglcontextrestored',
			this.boundReInitializeWebGLContext, false);
		this.initializeWebGLContext(this.props, this.state);
	}

	releaseCanvas() {
		if (this.viewerCanvas !== null) {
			this.cancelCurrentAnimationFrame();
			this.resetMemoizedFunctions();
			this.domEventsManager.removeEventListener(this.viewerCanvas, 'webglcontextlost',
				this.boundHandleWebContextLost, false);
			this.domEventsManager.removeEventListener(this.viewerCanvas, 'webglcontextrestored',
				this.boundReInitializeWebGLContext, false);
			this.viewerCanvas.width = 1;
			this.viewerCanvas.height = 1;
			this.viewerCanvas = null;
		}
	}

	cancelCurrentAnimationFrame() {
		if (this.currentAnimationFrameRequest !== null) {
			window.cancelAnimationFrame(this.currentAnimationFrameRequest);
			this.currentAnimationFrameRequest = null;
		}
	}

	handleWebGLContextLost(contextLostEvent) {
		preventEventDefault(contextLostEvent);
		this.cancelCurrentAnimationFrame();
		this.setGLState(this.releaseGLContext(true));
	}

	reInitializeWebGLContext() {
		this.initializeWebGLContext(this.props, this.state);
	}

	initializeWebGLContext(props, state) {
		const glContext = createWebGLContext(this.viewerCanvas);

		const buffers = initializeBuffers(glContext);

		const nrDomImages = getDomImages(props.decodedImage).size;
		const nrOverlayDomImages = getOverlayDomImages(props.decodedImage).size;
		const numImages = nrDomImages + nrOverlayDomImages;
		throwForTooFewSupportedTextures(glContext, numImages);

		const newGLState = this.mergeGLState({
			glContext,
			renderOverlayProgram: initializeProgram(glContext,
				viewerOverlayFragmentShader, viewerVertexShader),
			buffers
		}, state);

		glContext.flush();

		this.setGLState(newGLState);
	}

	finalizeImageRendering() {
		this.setState({
			forceRendering: false
		});
	}

	componentDidUpdate(prevProps/*, prevState*/) {
		const {
			decodedImage,
			transformationMatrix,
			isPrintPreview
		} = this.props;
		const {
			decodedImage: prevDecodedImage,
			transformationMatrix: prevTransformationMatrix
		} = prevProps;
		const {forceRendering} = this.state;

		const imageChanged = decodedImage !== prevDecodedImage;
		const transformationChanged = transformationMatrix !== prevTransformationMatrix;
		if (imageChanged || transformationChanged) {
			this.forceRendering();
		}
		if (this.shouldRenderGLCanvas()) {
			if (this.getGLState().glContext === null) {
				this.initializeWebGLContext(this.props, this.state);
			} else if (isPrintPreview) {
				if (decodedImage) {
					this.cancelCurrentAnimationFrame();
					this.performImageRendering();
				}
			} else {
				const needsRendering = !shallowEqual(this.props, prevProps);
				if (needsRendering || forceRendering) {
					this.scheduleImageRendering();
				}
			}
		}
	}

	scheduleImageRendering() {
		if (!this.currentAnimationFrameRequest) {
			this.currentAnimationFrameRequest = window.requestAnimationFrame(() => {
				this.performImageRendering();
				this.currentAnimationFrameRequest = null;
			});
		}
	}

	performImageRendering() {
		this.renderDecodedImage();
		this.finalizeImageRendering();
	}

	scheduleCaptureImage() {
		this.releaseCaptureImage();
		this.captureImageTimer = window.setTimeout(this.boundCaptureImage, CAPTURE_IMAGE_TIMEOUT_MS);
	}

	releaseCaptureImage() {
		if (this.captureImageTimer !== null) {
			window.clearTimeout(this.captureImageTimer);
			this.captureImageTimer = null;
		}
	}

	captureImage() {
		this.captureImageTimer = null;
		if (this.viewerCanvas !== null) {
			this.renderDecodedImage();
			this.setState({capturedImage: this.viewerCanvas.toDataURL()});
		}
	}

	canvasReferenceCallback(viewerCanvas) {
		if (viewerCanvas === null) {
			this.releaseCanvas();
			this.releaseCaptureImage();
		} else {
			this.bindCanvas(viewerCanvas);
		}
	}

	shouldRenderGLCanvas(state = this.state) {
		const {
			documentVisible,
			windowBlurred,
			capturedImage
		} = state;
		return documentVisible && !windowBlurred || capturedImage === null;
	}

	releaseGLContext(contextLost = false) {
		this.resetMemoizedFunctions(contextLost);
		return {glContext: null};
	}

	resetMemoizedFunctions(contextLost) {
		if (!contextLost) {
			const {glContext} = this.getGLState();
			if (this.loadTextures) {
				this.loadTextures(glContext, null);
			}
			if (this.compileProgram) {
				this.compileProgram(glContext, null);
			}
		}
		this.loadTextures = memoizeLastWithCleanup(
			WebGLImageViewer.doLoadTextures,
			WebGLImageViewer.doDeleteTextures
		);
		this.compileProgram = memoizeLastWithCleanup(
			WebGLImageViewer.doCompileImageProgram,
			WebGLImageViewer.doDeleteProgram
		);
	}

	static doLoadTextures(glContext, decodedImage) {
		const textures = {};
		if (glContext && decodedImage) {
			WebGLImageViewer.createTextures(glContext, decodedImage, textures);
			const {
				images,
				overlays
			} = textures;
			getDomImages(decodedImage)
				.forEach((domImage, imageIndex) => {
					bindTexture(glContext, images[imageIndex], domImage);
				});
			getOverlayDomImages(decodedImage)
				.forEach((overlayDomImage, imageIndex) => {
					bindTexture(glContext, overlays[imageIndex], overlayDomImage);
				});
		}
		return textures;
	}

	static doDeleteTextures(textures, glContext) {
		if (glContext && textures) {
			const {
				images,
				overlays
			} = textures;
			if (images) {
				images.forEach(glContext.deleteTexture.bind(glContext));
			}
			if (overlays) {
				overlays.forEach(glContext.deleteTexture.bind(glContext));
			}
		}
	}

	static createTextures(glContext, decodedImage, textures) {
		const nrDomImages = getDomImages(decodedImage).size;
		textures.images = new Array(nrDomImages);
		for (let textureIndex = 0; textureIndex < nrDomImages; ++textureIndex) {
			textures.images[textureIndex] = createTexture(glContext);
		}

		const nrOverlayImages = getOverlayDomImages(decodedImage).size;
		textures.overlays = new Array(nrOverlayImages);
		for (let textureIndex = 0; textureIndex < nrOverlayImages; ++textureIndex) {
			textures.overlays[textureIndex] = createTexture(glContext);
		}
	}

	static doCompileImageProgram(glContext, decodedImage) {
		let program = null;
		if (glContext && decodedImage) {
			const nrDomImages = getDomImages(decodedImage).size;
			const invertOutput = getInvertOutputColors(decodedImage);
			program = initializeProgram(glContext,
				viewerFragmentShader, viewerVertexShader,
				getPreprocessorDirectives(invertOutput, nrDomImages)
			);
		}
		return program;
	}

	static doDeleteProgram(compiledProgram, glContext) {
		if (glContext && compiledProgram) {
			glContext.deleteProgram(compiledProgram);
		}
	}
}

WebGLImageViewer.propTypes = {
	windowWidth: PropTypes.number,
	windowCenter: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
	decodedImage: immutableMapPropType,
	isPrintPreview: PropTypes.bool,
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE)
};

function initializeBuffers(glContext) {
	return {
		triangles: createArrayBuffer(glContext, IMAGE_PLANE_TRIANGLES_COORDINATES),
		textureCoordinates: createArrayBuffer(glContext, IMAGE_PLANE_TEXTURE_COORDINATES)
	};
}

function getPreprocessorDirectives(forInvertedColors, nrDomImages) {
	const defaultDirectives = getDefaultPreprocessorDirectives();
	if (forInvertedColors) {
		defaultDirectives.push(createPreprocessorDefine('INVERT_OUTPUT_COLORS', '1'));
	}
	defaultDirectives.push(createPreprocessorDefine('NR_OF_IMAGES', nrDomImages));
	return defaultDirectives;
}

function createMatricesSelector() {
	return createSelector(
		props => props.transformationMatrix,
		props => props.width,
		props => props.height,
		props => props.decodedImage,
		calculateMatrices
	);
}

function calculateMatrices(transformationMatrix3, width, height, decodedImage) {
	const transformationMatrix = mat3ToMat4(mat4.create(), transformationMatrix3);
	const perspectiveMatrix = mat4.scale(mat4.create(), mat4.create(),
		vec3.set(vec3.create(), 2.0 / width, -2.0 / height, 1.0)
	);
	const imageWidth = getWidth(decodedImage);
	const imageHeight = getHeight(decodedImage);
	const modelViewMatrix = mat4.scale(mat4.create(), mat4.create(),
		vec3.set(vec3.create(), imageWidth / 2, imageHeight / 2, 1.0)
	);
	return {
		perspective: perspectiveMatrix,
		modelView: modelViewMatrix,
		transformationMatrix
	};
}

function throwForTooFewSupportedTextures(glContext, requiredTextures) {
	if (getMaxNrOfTextures(glContext) < requiredTextures) {
		throw new ViewerError(`WebGL context dosn't support ${requiredTextures} textures at once.`);
	}
}

function bindTexture(glContext, texture, domImage) {
	glContext.bindTexture(glContext.TEXTURE_2D, texture);
	glContext.texImage2D(glContext.TEXTURE_2D,
		0, glContext.RGBA, glContext.RGBA, glContext.UNSIGNED_BYTE,
		domImage
	);
	glContext.bindTexture(glContext.TEXTURE_2D, null);
}
