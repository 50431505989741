import React from 'react';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import {useMemoFactory} from '../../../webview/commons/utils/customHooks/index.js';
import useTranslation from '../../../webview/i18n/hooks/useTranslation.js';
import IconButton from '../../../webview/ui/components/IconButton.js';
import DeleteIcon from '../../../webview/ui/components/icons/DeleteIcon.js';
import UploadFilesBrowserController from '../../bricks/UploadFilesBrowserController.js';
import UploadFilesCollection from '../../bricks/UploadFilesCollection.js';

export default function DeleteSelectedFilesButton() {
	const {
		isGridMode, getFilesSelection, resetFilesSelection, noFilesSelected, numFilesSelected: numberFiles
	} = useBrick(UploadFilesBrowserController, selectUploadFilesBrowserControllerProps);
	const removePreparedFile = useBrick(UploadFilesCollection, service => service.removePreparedFile);
	const onClickHandler = useMemoFactory(
		createRemoveSelectedFilesHandler, getFilesSelection, removePreparedFile, resetFilesSelection
	);
	const title = useTranslation('RemoveSelectedFiles', {numberFiles});
	return isGridMode && (
		<IconButton title={title} icon={<DeleteIcon />} onClick={onClickHandler} disabled={noFilesSelected} />
	);
}

function createRemoveSelectedFilesHandler(getFilesSelection, removePreparedFile, resetFilesSelection) {
	return () => {
		getFilesSelection().forEach(removePreparedFile);
		resetFilesSelection();
	};
}

function selectUploadFilesBrowserControllerProps(service) {
	return {
		isGridMode: service.isViewModeGrid(),
		getFilesSelection: service.getFilesSelection,
		noFilesSelected: service.getFilesSelection().isEmpty(),
		numFilesSelected: service.getFilesSelection().size,
		resetFilesSelection: service.resetFilesSelection
	};
}
