import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import StatusMessage from '../../webview/commons/components/webview/StatusMessage.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import Button from '../../webview/ui/components/Button.js';
import UploadController from '../bricks/UploadController.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';
import WebUploadCard from './WebUplaodCard.js';

export default function UploadSuccess() {
	const {uploadedCount} = useBrick(UploadFilesCollection, collection => collection.getUploadedFilesCount());
	const restart = useBrick(UploadController, controller => controller.restart);
	return (
		<WebUploadCard>
			<StatusMessage>
				<SynFormattedMessage message='UploadSuccess' messageParams={{uploadedCount}} />
			</StatusMessage>
			<Button className='upload-start-over-button' flat opaque onClick={restart}>
				<SynFormattedMessage message='StartOverWebUploadButton' />
			</Button>
		</WebUploadCard>
	);
}
