import {PHYSICAL_UNITS, REGION_DATA_TYPE, REGION_FLAGS, REGION_SPATIAL_FORMAT} from '../constants/DicomEnumerations.js';

export function isAreaRegion(region) {
	const physicalUnits = region ? region.getPhysicalUnits() : null;
	return physicalUnits &&
		physicalUnits[0] === physicalUnits[1] &&
		physicalUnits[0] === PHYSICAL_UNITS.CM;
}

export function isDopplerRegion(region) {
	let fitsCriteria = false;
	if (region) {
		const physicalUnits = region.getPhysicalUnits();
		const regionDataType = region.getRegionDataType();
		const spatialFormat = region.getRegionSpatialFormat();
		const {dopplerScaleType, scrollingRegion} = region.getRegionFlags();

		fitsCriteria = physicalUnits[0] === PHYSICAL_UNITS.SECONDS &&
			physicalUnits[1] === PHYSICAL_UNITS.CM_PER_SECOND &&
			(regionDataType === REGION_DATA_TYPE.PW_SP_DOPPLER || regionDataType === REGION_DATA_TYPE.CW_SP_DOPPLER) &&
			spatialFormat === REGION_SPATIAL_FORMAT.SPECTRAL &&
			dopplerScaleType === REGION_FLAGS.DOPPLER_SCALE_TYPE_VELOCITY &&
			scrollingRegion === REGION_FLAGS.SCROLLING_REGION_UNSPECIFIED;
	}
	return fitsCriteria;
}
