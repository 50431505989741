import React from 'react';
import PropTypes from 'prop-types';

import GridListConstants from '../../../constants/GridListConstants.json';
import makeMeasured from '../../commons/components/makeMeasured.js';
import {
	FEATURE_DOCUMENT_SHARING_ENABLED
} from '../../commons/constants/SynSettingsConstants.js';
import {immutableListPropType, immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import {PRIORITY_HIGH} from '../../commons/utils/ProbabilisticQueue.js';
import {withForwardRef} from '../../commons/utils/ReactUtils';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import DomEventsManager from '../../events/DomEventsManager.js';
import {TranslationProvider} from '../../i18n/components/TranslationContext';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import createGroupTaskSelector from '../../scheduling/createGroupTaskSelector.js';
import SelectedDocumentsShareButtonContainer from '../../share-document/flux/containers/SelectedDocumentsShareButtonContainer.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import ShowViewerButtonContainer from '../flux/containers/ShowViewerButtonContainer.js';
import ViewerMatrixMenuContainer from '../flux/containers/ViewerMatrixMenuContainer.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator';
import PatientDetailsDocumentList from './PatientDetailsDocumentList.js';
import PatientDetailsNumStudiesDisplay from './PatientDetailsNumStudiesDisplay.js';
import PatientFileSelectOrFilter from './PatientFileSelectOrFilter';

import '../../../styles/patient-details/components/PatientDetails.scss';

const TILE_WIDTH = GridListConstants['tile-width'];
const DEFAULT_PADDING = 24;

class PatientDetails extends React.Component {
	constructor(props, context) {
		super(props, context);
		const {initiallySelectedDocumentIds, initiallyExpandedCategoryIds} = this.props;
		this.cancelScrollOffsetUpdate = noop;
		this.boundThrottledUpdateItemsPerRow = this.throttledUpdateScrollOffset.bind(this);
		this.boundOnExpandDocument = this.onExpandDocument.bind(this);
		this.boundOnCollapseDocument = this.onCollapseDocument.bind(this);
		this.boundOnExpandCategory = this.onExpandCategory.bind(this);
		this.boundOnCollapseCategory = this.onCollapseCategory.bind(this);
		this.domEventsManager = new DomEventsManager();
		this.scrollContainer = React.createRef();
		this.state = {
			scrollOffset: 0,
			itemsPerRow: null,
			expandedDocuments: initiallySelectedDocumentIds,
			expandedCategories: initiallyExpandedCategoryIds
		};
	}

	render() {
		let documentsList = false;
		const {
			structure, numDocuments, numRemovedDocuments, initiallySelectedDocumentIds,
			isMobileDevice, className, forwardRef
		} = this.props;
		const {itemsPerRow, expandedDocuments, expandedCategories, viewportDimensions, scrollOffset} = this.state;

		if (itemsPerRow !== null) {
			documentsList = (
				<PatientDetailsDocumentList onExpandDocument={this.boundOnExpandDocument}
				                            onCollapseDocument={this.boundOnCollapseDocument}
				                            onExpandCategory={this.boundOnExpandCategory}
				                            onCollapseCategory={this.boundOnCollapseCategory} itemsPerRow={itemsPerRow}
				                            structure={structure} tileWidth={TILE_WIDTH}
				                            expandedDocuments={expandedDocuments}
				                            expandedCategories={expandedCategories}
				                            viewportDimensions={viewportDimensions} scrollOffset={scrollOffset}
				                            initiallySelectedDocumentIds={initiallySelectedDocumentIds} />
			);
		}

		return (
			<TranslationProvider translator={PatientDetailsMessagesTranslator}>
				<VerticalLayout justify='start' noGrow={!isMobileDevice} ref={forwardRef}
			                className={combineClassNames('patient-details--container', className)}>
					<ContentRow className='patient-details--nrstudies'>
						<PatientDetailsNumStudiesDisplay numDocuments={numDocuments}
					                                 numDocumentsRemoved={numRemovedDocuments} />
						{FEATURE_DOCUMENT_SHARING_ENABLED && <SelectedDocumentsShareButtonContainer />}
						<ViewerMatrixMenuContainer />
						<ShowViewerButtonContainer />
					</ContentRow>
					<PatientFileSelectOrFilter />
					<div ref={this.scrollContainer} onScroll={this.boundThrottledUpdateItemsPerRow}
				     className={combineClassNames('patient-details--scroll-container')}>
						{documentsList}
					</div>
				</VerticalLayout>
			</TranslationProvider>
		);
	}

	onExpandDocument(documentId) {
		const {expandedDocuments} = this.state;
		this.setState({expandedDocuments: expandedDocuments.add(documentId)});
	}

	onCollapseDocument(documentId) {
		const {expandedDocuments} = this.state;
		this.setState({expandedDocuments: expandedDocuments.delete(documentId)});
	}

	onExpandCategory(categoryId) {
		const {expandedCategories} = this.state;
		this.setState({expandedCategories: expandedCategories.add(categoryId)});
	}

	onCollapseCategory(categoryId) {
		const {expandedCategories} = this.state;
		this.setState({expandedCategories: expandedCategories.delete(categoryId)});
	}

	updateGridLayoutParams(width, height) {
		const {isExtraSmallDevice} = this.props;
		if (width !== undefined && height !== undefined) {
			const padding = isExtraSmallDevice ? 0 : DEFAULT_PADDING;
			const itemsPerRow = Math.floor((width - padding) / TILE_WIDTH);
			this.setState({
				viewportDimensions: {width, height},
				scrollOffset: this.scrollContainer.current.scrollTop,
				itemsPerRow
			});
		}
	}

	throttledUpdateScrollOffset() {
		if (this.cancelScrollOffsetUpdate === noop) {
			const requestedUpdateId = window.requestAnimationFrame(() => {
				this.cancelScrollOffsetUpdate = noop;
				this.updateScrollOffset();
			});
			this.cancelScrollOffsetUpdate = () => {
				this.cancelScrollOffsetUpdate = noop;
				window.cancelAnimationFrame(requestedUpdateId);
			};
		}
	}

	updateScrollOffset() {
		if (this.scrollContainer.current) {
			this.setState({
				scrollOffset: this.scrollContainer.current.scrollTop
			});
		}
	}

	componentDidMount() {
		const {addTaskSelector, width, height} = this.props;
		addTaskSelector('previewTaskSelector', PRIORITY_HIGH, createGroupTaskSelector('preview', true));
		this.updateGridLayoutParams(width, height);
	}

	componentDidUpdate(prevProps) {
		const {width, height} = this.props;
		if (prevProps.width !== width || prevProps.height !== height) {
			this.updateGridLayoutParams(width, height);
		}
	}

	componentWillUnmount() {
		const {removeTaskSelector} = this.props;
		this.domEventsManager.removeAllListeners();
		removeTaskSelector('previewTaskSelector');
	}
}

PatientDetails.propTypes = {
	addTaskSelector: PropTypes.func.isRequired,
	removeTaskSelector: PropTypes.func.isRequired,
	numDocuments: PropTypes.number.isRequired,
	numRemovedDocuments: PropTypes.number.isRequired,
	initiallySelectedDocumentIds: immutablePropType,
	initiallyExpandedCategoryIds: immutablePropType,
	structure: immutableListPropType,
	isMobileDevice: PropTypes.bool,
	className: PropTypes.string,
	isExtraSmallDevice: PropTypes.bool,
	width: PropTypes.number,
	height: PropTypes.number,
	forwardRef: withForwardRef.PropTypes.Ref
};

export default makeMeasured(
	({windowSize: nextWS}, {windowSize}) => nextWS !== windowSize,
	withForwardRef(PatientDetails, 'forwardRef')
);
