import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import NewPasswordController from '../../components/NewPasswordController.js';
import {clearError} from '../actions/PasswordActions.js';
import {logout} from '../actions/SessionActions.js';
import {selectPasswordOperationState} from '../selectors/NewPasswordSelectors.js';
import {selectError, selectIsBusy} from '../selectors/PasswordStateSelectors.js';
import {selectIsSessionBusy} from '../selectors/SessionSelectors.js';

export default connect(
	createStructuredSelector({
		isBusy: selectIsBusy,
		isSessionBusy: selectIsSessionBusy,
		passwordError: selectError,
		passwordOperationState: selectPasswordOperationState
	}),
	bindActions({
		resetPasswordState: clearError,
		logout
	})
)(NewPasswordController);
