import Immutable from 'immutable';

export function getDocumentInformation(document, hierarchical) {
	const description = document.get('description');
	const typeSpecificInformation = isDicom(document) 
		? getAllModalities(document, hierarchical) 
		: getAllExtensions(document, hierarchical);

	return `${typeSpecificInformation} ${description}`;
}

export function isShare(document) {
	return document.has('document_share');
}

export function isShareDone(document) {
	return document.getIn(['document_share', 'done']);
}

export function getShareId(document) {
	return document.getIn(['document_share', 'id']);
}

export function isDicom(document) {
	return document.get('document_type_fk') === 'DICOM_STUDY';
}

export function isGeneric(document) {
	return document.get('document_type_fk') === 'GENERIC_CONTAINER';
}

export function getAllModalities(document, hierachical) {
	return getDicomStudy(document, hierachical).get('all_modalities');
}

export function getDicomStudy(document, hierarchical = true) {
	return (hierarchical) ? document.get('dicom_study') : document;
}

export function getAllExtensions(document, hierarchical = true) {
	return (hierarchical) ? document.getIn(['generic_container', 'all_extensions'], '') : document.get('all_extensions');
}

export function getGenericContainer(document, hierarchical = true) {
	return (hierarchical) ? document.get('generic_container') : document;
}

export function getNumberSeries(document) {
	return getDicomSeries(document).size;
}

export function getDicomSeries(document) {
	return getDicomStudy(document).get('series');
}

export function getNumberFiles(document) {
	return getGenericFiles(document).size;
}

export function getGenericFiles(document) {
	return document.getIn(['generic_container', 'files'], Immutable.List());
}


