import React from 'react';
import PropTypes from 'prop-types';

import {noop} from '../../commons/utils/FunctionUtils.js';
import LinkIcon from '../../ui/components/icons/LinkIcon.js';
import PatientDetailsDocumentButton from './PatientDetailsDocumentButton.js';

export default class ShowReportButton extends React.Component {
	render() {
		const {hasReport} = this.props;
		return hasReport ? this.renderReportButton() : false;
	}

	renderReportButton() {
		const {showReport} = this.props;
		return (
			<PatientDetailsDocumentButton icon={<LinkIcon />} onClick={showReport}>
				PDF
			</PatientDetailsDocumentButton>
		);
	}
}

ShowReportButton.propTypes = {
	hasReport: PropTypes.bool,
	showReport: PropTypes.func
};

ShowReportButton.defaultProps = {
	hasReport: false,
	showReport: noop
};
