import React from 'react';
import PropTypes from 'prop-types';

import {memoWithName} from '../../../commons/utils/ReactUtils.js';

export default memoWithName(Header);

function Header(props) {
	const {className, children} = props;
	return (
		<header role='banner' className={className}>
			{children}
		</header>
	);
}

Header.propTypes = {
	className: PropTypes.string
};
