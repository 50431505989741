import React, {useState} from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import CenterLayout from '../../webview/commons/components/layout/CenterLayout.js';
import {withModalBackgroundClasses} from '../../webview/commons/components/ModalBackground.js';
import StatusMessage from '../../webview/commons/components/webview/StatusMessage.js';
import {STATUS_BUSY} from '../../webview/commons/constants/StatusMessageConstants.js';
import SynFormattedMessage from '../../webview/commons/containers/SynFormattedMessageContainer.js';
import {useEffectEasily, useMemoFactory} from '../../webview/commons/utils/customHooks';
import {callDelayed} from '../../webview/commons/utils/FunctionUtils.js';
import Card from '../../webview/material-design/components/Card.js';
import UploadFilesCollector from '../bricks/UploadFilesCollector';

export default React.memo(ProcessingOverlay);

const SHOW_DELAY_MS = 120;
const MINIMUM_DISPLAY_TIME_END = 1000;
const MINIMUM_DISPLAY_TIME = MINIMUM_DISPLAY_TIME_END - SHOW_DELAY_MS;

const OVERLAY_CONTENT = (
	<Card style={{'flex-grow': 0, 'flex-direction': 'row'}}>
		<StatusMessage status={STATUS_BUSY}>
			<SynFormattedMessage message='ProcessingSelectedFiles' />
		</StatusMessage>
	</Card>
);

function ProcessingOverlay() {
	const isCollecting = useBrick(UploadFilesCollector, selectIsCollecting);
	const [shown, setShown] = useState(isCollecting);
	const [hidingBlocked, setHidingBlocked] = useState(isCollecting);
	useEffectEasily(showOverlay, setShown, shown, isCollecting);
	useEffectEasily(hideOverlay, setShown, shown, hidingBlocked ? shown : isCollecting);
	useEffectEasily(delayHiding, setHidingBlocked, shown);
	return useOverlay(shown);
}

function selectIsCollecting(uploadFilesCollector) {
	return uploadFilesCollector.isCollecting();
}

function showOverlay(setShown, shown, shouldShow) {
	if (!shown && shouldShow) {
		return callDelayed(SHOW_DELAY_MS, (...args) => {
			setShown(...args);
		}, shouldShow);
	}
	return undefined;
}

function hideOverlay(setShown, shown, shouldShow) {
	if (shown && !shouldShow) {
		setShown(shouldShow);
	}
}

function delayHiding(setHidingBlocked, shown) {
	if (shown) {
		return callDelayed(MINIMUM_DISPLAY_TIME, (...args) => {
			setHidingBlocked(...args);
		}, false);
	}
	setHidingBlocked(true);
	return undefined;
}

const OverlayComponent = withModalBackgroundClasses(CenterLayout);

function useOverlay(shown) {
	const overlaySx = useMemoFactory(getOverlaySx, shown);
	return (
		<OverlayComponent visible={shown} className='remove-padding' sx={overlaySx}>
			{shown && OVERLAY_CONTENT}
		</OverlayComponent>
	);
}

function getOverlaySx(shown) {
	return {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		pointerEvents: 'none',
		opacity: shown ? 1.0 : 0,
		transition: 'opacity 120ms',
		'&.remove-padding': {
			padding: 0
		}
	};
}
