import {vec2} from 'gl-matrix';

import {RAD_360_DEGREES} from './MathUtils.js';

/**
 * Calculate the normalized vector describing the direction from the point from to the point to.
 * Both points are expected to be specified as vec2.
 * @param from (vec2) point to calculate the direction from
 * @param to (vec2) point to calculate the direction to
 */
export function getNormalizedDirection(from, to) {
	const directionalVector = vec2.subtract(vec2.create(), to, from);
	return vec2.normalize(directionalVector, directionalVector);
}

export function getDistance(from, to) {
	return vec2.length(vec2.subtract(vec2.create(), to, from));
}

export function getSquaredDistance(from, to) {
	return vec2.sqrDist(to, from);
}

export function approximatelyEqual(that, other) {
	return that === other || (that && other && vec2.equals(that, other));
}

export function getFullVectorAngle(vector) {
	let angle = vec2.angle(vector, vec2.fromValues(1, 0));
	if (vector[1] < 0) {
		angle = RAD_360_DEGREES - angle;
	}
	return angle;
}

export function perpendicularVector(vec) {
	return vec2.set(new Float64Array(2), vec[1] * -1.0, vec[0]);
}

export function getOrthogonalProjectionPoint(linePointA, linePointB, referencePoint) {
	const u = vec2.sub(vec2.create(), linePointB, linePointA);
	const lambda = vec2.dot(vec2.sub(vec2.create(), referencePoint, linePointA), u) / vec2.dot(u, u);
	return vec2.scaleAndAdd(vec2.create(), linePointA, u, lambda);
}

export function getMirrorPoint(linePointA, linePointB, referencePoint) {
	const closestPoint = getOrthogonalProjectionPoint(linePointA, linePointB, referencePoint);
	const distanceVector = vec2.sub(vec2.create(), closestPoint, referencePoint);
	return vec2.scaleAndAdd(vec2.create(), referencePoint, distanceVector, 2);
}

export function getMiddlePoint(linePointA, linePointB) {
	const distanceVector = vec2.sub(new Float64Array(2), linePointB, linePointA);
	return vec2.scaleAndAdd(new Float64Array(2), linePointA, distanceVector, 0.5);
}
