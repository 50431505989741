import React from 'react';

import {immutableMapPropType} from '../../../../commons/utils/CustomPropTypes';
import FourPointGoniometryAnnotation from './FourPointGoniometryAnnotation.js';
import ThreePointGoniometryAnnotation from './ThreePointGoniometryAnnotation.js';

const POINTS_OF_THREE_POINT_GONIOMETRY = 3;

export default React.memo(HybridGoniometryAnnotation);

function HybridGoniometryAnnotation(props) {
	const {annotationProperties} = props;
	const points = annotationProperties.get('points');
	const isThreePointGoniometry = points.length === POINTS_OF_THREE_POINT_GONIOMETRY;
	if (isThreePointGoniometry) {
		return <ThreePointGoniometryAnnotation {...props} />;
	}
	return <FourPointGoniometryAnnotation {...props} />;
}

HybridGoniometryAnnotation.propTypes = {
	annotationProperties: immutableMapPropType
};
