import React from 'react';
import PropTypes from 'prop-types';

import {memoWithName} from '../../../commons/utils/ReactUtils.js';

export default memoWithName(Region);

function Region(props) {
	const {title, className, children, onWheelCapture} = props;
	return (
		<section aria-label={title} className={className} onWheelCapture={onWheelCapture}>
			{children}
		</section>
	);
}

Region.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string,
	onWheelCapture: PropTypes.func
};
