import React from 'react';
import PropTypes from 'prop-types';

import GridLayout from '../../commons/components/layout/GridLayout.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import {viewerIndexToViewerId} from '../../viewer/utils/ViewerUtils.js';
import HangTile from './HangTile.js';

export default class HangMatrix extends React.PureComponent {
	render() {
		const {className, nrViewers, rows, columns, activeViewerIds} = this.props;
		const hangTiles = [];
		for (let viewerIndex = 0; viewerIndex < nrViewers; ++viewerIndex) {
			const viewerId = viewerIndexToViewerId(viewerIndex);
			const active = activeViewerIds.get(viewerId, false);
			const onClickHandler = this.createOnClickHandler(viewerId);
			hangTiles.push(
				<HangTile key={viewerIndex} onClick={onClickHandler} active={active} />
			);
		}
		return (
			<GridLayout rows={rows} columns={columns} className={className}>
				{hangTiles}
			</GridLayout>
		);
	}

	createOnClickHandler(viewerId) {
		const {loadItemIntoViewer} = this.props;
		return () => loadItemIntoViewer(viewerId);
	}
}

HangMatrix.propTypes = {
	className: PropTypes.string,
	nrViewers: PropTypes.number,
	rows: PropTypes.number,
	columns: PropTypes.number,
	activeViewerIds: immutableMapPropType,
	loadItemIntoViewer: PropTypes.func
};
