import {vec2} from 'gl-matrix';

import {ENCAPSULATED_PDF_STORAGE_UID, SR_UID_PREFIX} from '../../constants/DicomSOPClassUIDs.js';
import {EMBEDDED_VIDEO_TRANSFER_SYNTAX_UIDS_RE} from '../../constants/WebViewConstants.js';
import {translateOrientations} from '../../utils/DicomDumpUtils.js';
import {getImageScale} from './SynAdvancedImage.js';

export function getNumberOfFrames(image) {
	return image ? (image.get('number_of_frames') || 1) : 0;
}

export function isEncapsulatedPdf(image) {
	const sopclassuid = image.get('sopclassuid', null);
	return Boolean(sopclassuid) && ENCAPSULATED_PDF_STORAGE_UID === sopclassuid;
}

export function isEmbeddedVideo(image) {
	const transferSyntaxUID = image.get('transfersyntaxuid', null);
	return Boolean(transferSyntaxUID) && transferSyntaxUID.match(EMBEDDED_VIDEO_TRANSFER_SYNTAX_UIDS_RE) !== null;
}

export function isStructuredReport(image) {
	const sopclassuid = image.get('sopclassuid', null);
	return Boolean(sopclassuid) && sopclassuid.startsWith(SR_UID_PREFIX);
}

export function getImageOrientationDescriptions(dicomDump) {
	const rowOrientationDescriptions = dicomDump && dicomDump.getRowOrientationDescriptions() || [];
	const columnOrientationDescriptions = dicomDump && dicomDump.getColumnOrientationDescriptions() || [];

	return {
		horizontalOrientationFrom: translateOrientations(rowOrientationDescriptions[0]),
		horizontalOrientationTo: translateOrientations(rowOrientationDescriptions[1]),
		verticalOrientationFrom: translateOrientations(columnOrientationDescriptions[0]),
		verticalOrientationTo: translateOrientations(columnOrientationDescriptions[1])
	};
}

export function getDownscaleVector(dicomDump, rawImage) {
	const imageScale = getImageScale(rawImage);
	return vec2.fromValues(imageScale.get(0), imageScale.get(1));
}
