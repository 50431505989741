import {call, cancel, cancelled, fork} from 'redux-saga/effects';

import {debugLog} from '../../commons/utils/DebugLog.js';
import {waitForEndOfSession, waitForSession} from './sessionTaskHelpers.js';

export default function createNoSessionTask(taskImpl) {
	return function* sessionTask() {
		while (!(yield cancelled())) {
			const task = yield fork(taskImpl);
			debugLog(`Started no-session task: ${taskImpl.name}`);
			yield call(waitForSession);
			yield cancel(task);
			debugLog(`Stopped no-session task: ${taskImpl.name}`);
			yield call(waitForEndOfSession);
		}
	};
}
