import {isFeatureTrackingEnabled} from '../../commons/selectors/GeneralConfigSelectors.js';
import {createTrackingMetadata} from './metadataUtils.js';

/**
 * Returns a metaCreator function that applies feature-tracking information to redux-actions, based on given
 * trackingDataProvider.
 * @param trackingDataProvider 	Function that takes same arguments as the actionCreator and creates the required values
 * 								for featurer-tracking:
 * 								{
 * 									usageContext: CONTEXT,
 * 									key: METRICSKEY,
 * 									type: METRICS_TYPE ('count' as default)
 * 								}
 * @returns {function(...[*]): {}}
 */
export default function withTrackingMetadata(trackingDataProvider) {
	return (...args) => {
		let metaData;
		if (isFeatureTrackingEnabled()) {
			const {usageContext, key} = trackingDataProvider(...args);
			metaData = createTrackingMetadata(usageContext, key);
		}
		return metaData;
	};
}
