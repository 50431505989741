import React from 'react';
import PropTypes from 'prop-types';

import A11yDialog from '../../a11y/components/A11yDialog.js';
import ErrorBoundary from '../../commons/components/errors/ErrorBoundary.js';
import {KEY_VALUE_ESC} from '../../commons/constants/KeyValues.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import DomEventsManager from '../../events/DomEventsManager.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import ThemeProvider from '../../ui/components/theming/ThemeProvider.js';

import '../../../styles/material-design/components/Dialog.scss';

export default class Dialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.domEventsManager = new DomEventsManager();
		this.boundOuterClickHandler = this.outerClickHandler.bind(this);
		this.boundHandleEscape = this.handleEscape.bind(this);
	}

	render() {
		const {className, onFocus, onBlur, children, autoFocus} = this.props;
		const dialogClasses = combineClassNames('material-dialog--container', className);

		return (
			<ThemeProvider>
				<ErrorBoundary>
					<A11yDialog className={dialogClasses} autoFocus={autoFocus}
									onMouseDown={this.boundOuterClickHandler}>
						<VerticalLayout element='section' justify='start' noGrow className='material-dialog--content'
											 tabIndex='0' onFocus={onFocus} onBlur={onBlur}>
							{children}
						</VerticalLayout>
					</A11yDialog>
				</ErrorBoundary>
			</ThemeProvider>
		);
	}

	outerClickHandler(e) {
		if (e.target === e.currentTarget) {
			this.callOnClose(e);
		}
	}

	handleEscape(e) {
		if (e.key === KEY_VALUE_ESC) {
			this.callOnClose();
		}
	}

	callOnClose(e) {
		const {onClose} = this.props;
		callSafe(onClose, e);
	}

	componentDidMount() {
		this.domEventsManager.addEventListener(window, 'keyup', this.boundHandleEscape, true);
	}

	componentWillUnmount() {
		this.domEventsManager.removeEventListener(window, 'keyup', this.boundHandleEscape, true);
	}
}

Dialog.propTypes = {
	className: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	onClose: PropTypes.func,
	autoFocus: PropTypes.bool
};
