import {batchActions} from 'redux-batched-actions';
import {cancelled, put, select} from 'redux-saga/effects';

import {MERGE_VIEWER_PROPERTIES} from '../../../constants/ViewerActionTypes.js';
import {mergeViewerProperties} from '../../../flux/actions/ViewerActions.js';
import {selectSeriesViewerItems} from '../../../flux/selectors/ViewerItemsSelectors.js';
import {
	getActiveViewerId,
	selectSingleViewerProperty,
	selectViewerState
} from '../../../flux/selectors/ViewerSelectors.js';
import {getSeriesImageIndex} from '../../../utils/ViewerItemUtils.js';
import {SYNC_VIEWERS} from '../../constants/ViewerSyncActionTypes.js';
import {selectSyncedViewerIds, selectSyncToolsState} from '../../flux/ViewerSyncSelectors.js';
import {getSyncChainID, getViewerMetaInfo, getViewersInChain, takeBatched} from '../../flux/ViewerSyncUtils.js';

const VIEWER_PROPERTY_SERIES_RELATIVE_FRAME_OFFSET = 'seriesRelativeFrameOffset';
const SYNC_PROPERTY_PAGE_OFFSET = 'sync_pageOffset';

export default function* offsetPageSyncSaga(syncToolAPI) {
	yield* initChainedViewers(syncToolAPI);
	function* boundProcessAction(action) {
		yield* processActions(action, syncToolAPI);
	}
	while (!(yield cancelled())) {
		yield* takeBatched(
			boundProcessAction,
			MERGE_VIEWER_PROPERTIES,
			SYNC_VIEWERS
		);
	}
}

function* initChainedViewers(syncToolAPI) {
	const chainedViewerIds = yield select(selectSyncedViewerIds);
	const updateActions = [];
	for (const viewerId of chainedViewerIds) {
		yield* initPageOffset(viewerId, syncToolAPI, updateActions);
	}
	yield* flushUpdates(updateActions);
}

function* processActions(action, syncToolAPI) {
	const {type} = action;
	switch (type) {
		case MERGE_VIEWER_PROPERTIES:
			yield* processPropertyUpdate(action, syncToolAPI);
			break;
		case SYNC_VIEWERS:
			yield* initSyncedViewers(action, syncToolAPI);
			break;
		default:
			break;
	}
}

function* processPropertyUpdate(action, syncToolAPI) {
	const {payload: {viewerId, partialProperties}} = action;
	const hasSeriesRelativeFrameOffset = VIEWER_PROPERTY_SERIES_RELATIVE_FRAME_OFFSET in partialProperties;
	const viewerSyncToolsState = yield select(selectSyncToolsState);
	const sourceViewerChainId = getSyncChainID(viewerSyncToolsState, viewerId);
	const hasChainID = sourceViewerChainId !== null;
	const shouldProcessAction = hasSeriesRelativeFrameOffset && hasChainID && (yield* isActiveViewer(viewerId));
	if (shouldProcessAction) {
		const updateActions = [];
		const seriesRelativeFrameOffset = partialProperties[VIEWER_PROPERTY_SERIES_RELATIVE_FRAME_OFFSET];
		const pageOffset = yield* getPageOffset(viewerId, syncToolAPI);
		const pagingDiff = seriesRelativeFrameOffset - pageOffset;
		const otherViewerIds = getViewersInChain(viewerSyncToolsState, sourceViewerChainId);
		const viewerState = yield select(selectViewerState);
		for (const otherViewerId of otherViewerIds) {
			if (otherViewerId !== viewerId) {
				yield* syncPageDiff(viewerState, pagingDiff, otherViewerId, syncToolAPI, updateActions);
			}
		}
		yield* flushUpdates(updateActions);
	}
}

function* initSyncedViewers(syncViewersAction, syncToolAPI) {
	const {payload: {viewerA, viewerB}} = syncViewersAction;
	const updateActions = [];
	yield* initMissingPageOffset(viewerA, syncToolAPI, updateActions);
	yield* initMissingPageOffset(viewerB, syncToolAPI, updateActions);
	yield* flushUpdates(updateActions);
}

function* syncPageDiff(viewerState, pagingDiff, viewerId, syncToolAPI, updateActions) {
	const pageOffset = yield* getPageOffset(viewerId, syncToolAPI);
	const newFrameIdx = Math.max(0, pageOffset + pagingDiff);
	const metaInfo = getViewerMetaInfo(viewerState, viewerId);
	const seriesId = metaInfo.get('id', null);
	const seriesItemInfo = yield select(state => selectSeriesViewerItems(state).get(seriesId, null));
	const imageIndex = getSeriesImageIndex(seriesItemInfo, null);

	if (imageIndex) {
		const sanitizedNewIndex = Math.min(newFrameIdx, Math.max(0, imageIndex.numberOfFrames - 1));
		const currentFrameOffset = yield select(
			selectSingleViewerProperty(viewerId, VIEWER_PROPERTY_SERIES_RELATIVE_FRAME_OFFSET, 0)
		);
		if (currentFrameOffset !== sanitizedNewIndex) {
			updateActions.push(
				mergeViewerProperties(viewerId, {[VIEWER_PROPERTY_SERIES_RELATIVE_FRAME_OFFSET]: sanitizedNewIndex})
			);
		}
	}
	return updateActions;
}

function* initMissingPageOffset(viewerId, syncToolAPI, updateActions) {
	const pageOffset = yield* getPageOffset(viewerId, syncToolAPI);
	if (pageOffset === null) {
		yield* initPageOffset(viewerId, syncToolAPI, updateActions);
	}
}

function* initPageOffset(viewerId, syncToolAPI, updateActions) {
	const currentFrameOffset = yield select(
		selectSingleViewerProperty(viewerId, VIEWER_PROPERTY_SERIES_RELATIVE_FRAME_OFFSET, 0)
	);
	updateActions.push(
		syncToolAPI.setToolProperty(viewerId, SYNC_PROPERTY_PAGE_OFFSET, currentFrameOffset)
	);
}

function* getPageOffset(viewerId, syncToolAPI) {
	return yield select(syncToolAPI.selectToolProperty(viewerId, SYNC_PROPERTY_PAGE_OFFSET, null));
}

function* isActiveViewer(viewerId) {
	const activeViewerId = yield select(getActiveViewerId);
	return viewerId === activeViewerId;
}

function* flushUpdates(updateActions) {
	if (updateActions.length > 0) {
		yield put(batchActions(updateActions));
	}
}
