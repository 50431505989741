import React, {useCallback} from 'react';

import {logout} from '../../authentication/flux/actions/SessionActions.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import WebViewMessagesTranslator from '../../i18n/translators/WebViewMessagesTranslator.js';
import LogoutIcon from '../../ui/components/icons/LogoutIcon.js';
import MenuEntry from '../../ui/components/MenuEntry.js';
import LinkContainer from '../containers/LinkContainer.js';
import {useAction} from '../flux/ReactHooks.js';
import {callSafe} from '../utils/FunctionUtils.js';

const MENU_TEXT = <SynFormattedMessage message='Logout' translator={WebViewMessagesTranslator} />;
const MENU_ICON = <LogoutIcon />;

export default function LogoutMenuEntry(props) {
	const performLogout = useAction(logout);
	const onClickHandler = useCallback(() => {
		callSafe(performLogout);
	}, [performLogout]);
	return <MenuEntry id='logoutMenuEntry' component={LinkContainer} path='' onClick={onClickHandler} icon={MENU_ICON} primaryText={MENU_TEXT} {...props} />;
}
