import React from 'react';
import PropTypes from 'prop-types';

import {withForwardRef} from '../../commons/utils/ReactUtils.js';

import '../../../styles/material-design/components/Row.scss';

class Row extends React.PureComponent {
	render() {
		const {
			className: inputClassName,
			noWrap,
			noShrink,
			forwardRef,
			style,
			children,
			...remainingProps
		} = this.props;
		let className = 'material-grid-row';
		if (inputClassName) {
			className = `${className} ${inputClassName}`;
		}
		if (noWrap === true) {
			className = `${className} no-wrap`;
		}
		if (noShrink === true) {
			className = `${className} no-shrink`;
		}
		return (
			// NOTE: We explicitly want to pass any other HTML-Attributes like event handlers to the div-tag.
			// Refactoring the remainingProps into dedicated props is not worth the effort.
			// eslint-disable-next-line react/jsx-props-no-spreading
			<div ref={forwardRef} className={className} style={style} {...remainingProps}>
				{children}
			</div>
		);
	}
}

Row.propTypes = {
	noWrap: PropTypes.bool,
	noShrink: PropTypes.bool,
	className: PropTypes.string,
	forwardRef: withForwardRef.PropTypes.Ref,
	style: PropTypes.object
};

Row.defaultProps = {
	noWrap: false,
	noShrink: false
};

export default withForwardRef(Row, 'forwardRef');
