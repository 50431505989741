import React from 'react';
import PropTypes from 'prop-types';

import useTranslation from '../../webview/i18n/hooks/useTranslation.js';
import IconButtonWithBadge from '../../webview/ui/components/IconButtonWithBadge.js';
import RestoreFromTrashIcon from '../../webview/ui/components/icons/RestoreFromTrashIcon.js';
import {COLOR_SECONDARY} from '../../webview/ui/constants/SynMUIOptions.js';

const ICON = <RestoreFromTrashIcon />;

export default function RestoreTrashButton(props) {
	const {onClick, numTrashedFiles} = props;
	const title = useTranslation('RestoreTrash');
	return (
		<IconButtonWithBadge badgeContent={numTrashedFiles} badgeColor={COLOR_SECONDARY} onClick={onClick} icon={ICON}
		                     title={title} disabled={numTrashedFiles <= 0} badgeBottom />
	);
}

RestoreTrashButton.propTypes = {
	numTrashedFiles: PropTypes.number,
	onClick: PropTypes.func
};
