import React, {useCallback, useState} from 'react';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils/index.js';
import IconButton from '../../ui/components/IconButton.js';
import {VisibilityIcon, VisibilityOffIcon} from '../../ui/components/icons/VisibilityIcon.js';
import SingleLineTextField from '../../ui/components/SingleLineTextField';

import '../../../styles/authentication/components/PasswordTextField.scss';

function PasswordTextField(props) {
	const {id, name, ...remainingProps} = props;
	const [showPassword, setShowPassword] = useState(false);
	const onClickHandler = useCallback(() => setShowPassword(!showPassword), [showPassword]);
	const visibilityButton = useMemoFactory(createVisibilityButton, showPassword, onClickHandler);
	const textFieldProps = {
		...remainingProps,
		id: id ? id : `id-${name}`,
		type: showPassword ? 'text' : 'password',
		name
	};
	return <SingleLineTextField {...textFieldProps} textFieldButtons={visibilityButton} />;
}

PasswordTextField.propTypes = {
	...cloneWithoutProperties(SingleLineTextField.propTypes, 'type')
};

PasswordTextField.defaultProps = {
	...cloneWithoutProperties(SingleLineTextField, 'type')
};

function handleMouseDownPassword(event) {
	event.preventDefault();
}

function createVisibilityButton(showPassword, onClickHandler) {
	return (
		<IconButton aria-label='toggle password visibility' onClick={onClickHandler} onMouseDown={handleMouseDownPassword}
		            icon={showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />} />
	);
}

export default PasswordTextField;
