function isArray(seq) {
	return seq instanceof Array;
}

export function getLength(seq) {
	if (seq) {
		return isArray(seq) ? seq.length : seq.size;
	}
	return 0;
}

export function getElement(seq, index) {
	let element;
	if (seq) {
		if (isArray(seq)) {
			element = seq[index];
		} else {
			element = seq.get(index);
		}
	}
	return element;
}

export function groupSorted(seq, groupingCriteriaFunction) {
	let previousGroupKey = null;
	return seq.reduce((groups, entry) => {
		const currentGroupKey = groupingCriteriaFunction(entry);
		if (previousGroupKey !== currentGroupKey) {
			groups.push([]);
			previousGroupKey = currentGroupKey;
		}
		groups[groups.length - 1].push(entry);
		return groups;
	}, []);
}

export function binarySearch(seq, search, comparator) {
	let max = getLength(seq) - 1;
	let min = 0;
	while (max >= min) {
		const middle = min + Math.floor((max - min) / 2);
		const cmp = comparator(getElement(seq, middle), search);

		if (cmp < 0) {
			min = middle + 1;
		} else if (cmp > 0) {
			max = middle - 1;
		} else {
			return middle;
		}
	}
	return -1;
}

export function range(from, to) {
	const nrElements = 1 + (to - from);
	return Array(nrElements)
		.fill(0)
		.map((_, i) => from + i);
}

export function allEqual(seq) {
	return new Set(seq).size === 1;
}
