import React from 'react';
import MUICheckbox from '@mui/material/Checkbox';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {callSafe} from '../../commons/utils/FunctionUtils.js';

export default function Checkbox(props) {
	const {onToggle, checked, disabled, id, checkedIcon, uncheckedIcon, classes, toolTip} = props;
	const onToggleMapper = useMemoFactory(createOnToggleMapper, onToggle);
	const inputProps = useMemoFactory(buildInputProps, toolTip);
	return (
		<MUICheckbox id={id} color='primary' onChange={onToggleMapper}
	                    checked={checked} disabled={disabled} classes={classes}
		                 inputProps={inputProps}
	                    checkedIcon={checkedIcon} icon={uncheckedIcon} />
	);
}

Checkbox.propTypes = {
	toolTip: PropTypes.string,
	onToggle: PropTypes.func,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	checkedIcon: PropTypes.node,
	uncheckedIcon: PropTypes.node,
	classes: PropTypes.shape({
		root: PropTypes.string,
		checked: PropTypes.string
	})
};

function createOnToggleMapper(onToggle) {
	return function onChangeToOnToggle(event) {
		callSafe(onToggle, event.target.checked);
	};
}

function buildInputProps(toolTip) {
	return toolTip ? {title: toolTip} : undefined;
}
