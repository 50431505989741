import nodeCrypto from 'crypto';

const MIN_KEY_LENGTH = 8;
const DEFAULT_KEY_LENGTH = 64;
const DEFAULT_ARRAY_LENGTH = 32;
const BITS_PER_BYTE = 8;
const ARRAY_TYPE = Uint8Array;
const RANDOM_NUMBERS = new ARRAY_TYPE(DEFAULT_ARRAY_LENGTH);
const RANDOM_MAX_VALUE = Math.pow(2, ARRAY_TYPE.BYTES_PER_ELEMENT * BITS_PER_BYTE) - 1;
const KEY_ALPHABET = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
const ALPHABET_MAX_INDEX = KEY_ALPHABET.length - 1;
const CRYPTO_WEB_API = typeof window !== 'undefined' && (window.crypto || window.msCrypto) || nodeCrypto.webcrypto;

/** Generates a random string.
 * @param length {Number} the length of the generated key
 * @returns {String} containing the generated key.
 */
export default function generateUniqueKey(length = DEFAULT_KEY_LENGTH) {
	validateLength(length);
	let uniqueKey = '';
	while (uniqueKey.length < length) {
		CRYPTO_WEB_API.getRandomValues(RANDOM_NUMBERS);
		const requiredDigits = Math.min(RANDOM_NUMBERS.length, length - uniqueKey.length);
		uniqueKey += RANDOM_NUMBERS
			.slice(0, requiredDigits)
			.reduce((combinedValue, value) => combinedValue + digitFromValue(value), '');
	}
	return uniqueKey;
}

function validateLength(length) {
	if (length < MIN_KEY_LENGTH) {
		throw new Error(`length must be at least ${MIN_KEY_LENGTH}`);
	}
	if (!Number.isInteger(length)) {
		throw new Error('length must be an integer number');
	}
}

function digitFromValue(value) {
	const alphabetIndex = Math.round((value / RANDOM_MAX_VALUE) * ALPHABET_MAX_INDEX);
	return KEY_ALPHABET[alphabetIndex];
}
