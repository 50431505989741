import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {ERROR_FIELD, VALUE_FIELD} from '../constants/FormAccessorsFields.js';
import {currentLocale} from '../selectors/GeneralConfigSelectors.js';
import {createFieldSelector} from '../selectors/StructuredDataSelectors.js';
import {mapProperties} from '../utils/ObjectUtils';
import {firstErrorOrTrue} from '../utils/ValidatorUtils.js';
import createDataAccessors from './DataAccessorsFactory.js';

/** Creates selectors and action creators for the specified formMetaData.
 * The formMetaData must be an object structured as follows:
 *
 * metaData = '{' FieldDefinition [, FieldDefinition] '}'
 * FieldDefinition = FieldLabel ':{' [ FieldProperty [',' FieldProperty ] ] '}'
 * FieldLabel: Valid JSON field name
 * FieldProperty: DefaultValueDefinition | LabelDefinition | ValidatorsDefinition | FieldsToResetDefinition
 * DefaultValueDefinition: 'defaultValue:' DefaultValue
 * ValidatorsDefinition: 'validators:[' Validator [',' Validator ] ']'
 * LabelDefinition: 'label:'' TranslationID '''
 * FieldsToResetDefinition: 'fieldsToReset:[' [ FieldLabel [',' FieldLabel ]]
 * TranslationID: Valid Translation ID, that can be translated using the MessageTranslator
 * DefaultValue: Valid JSON Value expression
 *
 * Example:
 * metaData = {
 * 	fields: {
 * 		username: {
 * 			label: 'Login.Username',
 * 			defaultValue: '',
 * 		   validators: [ required, notEqual('password') ],
 * 		   resetFieldsToDefaultOnValue: ['password']
 * 		}
 * 		password: {
 * 			label: 'Login.Password',
 * 		   validators: [ required, notEqual('username') ]
 *    	}
 *    }
 *    validators: [ noneEmpty ]
 * }
 * NOTE: The available Validators can be found in the package data/validators.
 *
 * The factory function generates the same selectors and action creators as the DataAccessorsFactory
 * plus the following ones for the validation state of the fields and functions:
 *  - isValid(state)
 *  		returns whether the forms current input data is considered valid or not
 *  - getValidationError(state)
 *  		returns the first validation error message or an empty string if form is valid.
 *  - validationErrorSelector
 *  		An object containing a validation selector for each specified field.
 *  - getFieldValidationErrorSelector(fieldName)
 *  		Returns the validation selector for the specified field.
 *  - getFieldValidationError(state, fieldName)
 *  		Directly returns true or the first validation error for the field
 *  - getFieldLabel(fieldName)
 *  		Gets the translated field label
 *  - getFormFieldValue(state,fieldName)
 *  		Returns the fields value.
 *  		NOTE: this is not the same value as returned by the DataAccessorsFactory's getFieldValue function!
 *  - updateFieldValue(fieldName, value, error)
 *  		Updates the specified fields value and an optional error message signaling any other
 *  		problems with the field other than determined by the specified validators.
 *  - restoreValuesFromMap(valuesMap)
 *  		Restores all values from the passed Immutable.Map. The maps keys must correspond with the
 *  	   field names to be restored. Any field not present in the map is restored to its defined
 *  	   default value.
 *
 * @param formDefinition {Object} field definitions as described above
 * @param structureId {*} optionally an identifying value for the underlying structured data entry.
 * @param translator {MessageTranslator} used to translate the labels in the formMetaData
 * @returns {Object} as described above.
 */
export default function createFormAccessors(formDefinition, translator, structureId = undefined) {
	const {fields: fieldDefinitions, validators: formValidators} = formDefinition;
	const fieldNames = Object.keys(fieldDefinitions);
	const newFieldMetaData = mapProperties(
		fieldDefinitions,
		fieldInfo => ({...fieldInfo, defaultValue: Immutable.Map({[VALUE_FIELD]: fieldInfo.defaultValue})})
	);
	const dataAccessors = createDataAccessors(newFieldMetaData, structureId);
	const formFieldValueSelectors = {};
	const formFieldValueSelectorsArray = [];
	const formFieldValidators = [];
	const allFieldValidators = getAllFieldValidators(
		fieldDefinitions, dataAccessors, formFieldValueSelectors, formFieldValueSelectorsArray, newFieldMetaData,
		translator, formFieldValidators
	);
	const allFieldHintValidators = mapProperties(
		fieldDefinitions,
		(fieldInfo, fieldName) => createFieldValidator(
			dataAccessors.fieldSelectors, newFieldMetaData, translator, fieldName, newFieldMetaData[fieldName].hints
		)
	);
	const formFieldIsDefaultSelectors = mapProperties(
		fieldDefinitions,
		(fieldInfo, fieldName) => createSelector(
			dataAccessors.fieldSelectors[fieldName],
			formField => formField.get(VALUE_FIELD) === fieldInfo.defaultValue && !formField.get(ERROR_FIELD)
		)
	);
	const getAllMappedFieldValues = createMemoizedSelector(
		dataAccessors.get,
		createSelector(formFieldValueSelectorsArray, (...values) => fieldNames.reduce((mappedValues, name, index) => {
			mappedValues[name] = values[index];
			return mappedValues;
		}, {}))
	);
	const composedFormValidator =
		Boolean(formValidators) && createSelector(formValidators.map(
			validator => validator(dataAccessors.fieldSelectors, newFieldMetaData, translator)
		), firstErrorOrTrue) ||
	(() => true);
	const composedFieldValidators = createSelector(formFieldValidators, firstErrorOrTrue);
	const formValidator = createMemoizedSelector(dataAccessors.get, composedFieldValidators, composedFormValidator);
	const originalUpdateFieldValue = dataAccessors.updateFieldValue;
	const formValidationForcedKey = 'validationForced';
	const allFieldValueUpdaters = getAllFieldValueUpdaters(fieldNames, originalUpdateFieldValue);
	const updateFieldValue = getUpdateFieldValue(newFieldMetaData, allFieldValueUpdaters);
	return getFormAccessors(
		dataAccessors, formValidator, composedFormValidator, allFieldValidators, allFieldHintValidators, translator,
		newFieldMetaData, formFieldValueSelectors, formFieldIsDefaultSelectors, getAllMappedFieldValues,
		updateFieldValue, originalUpdateFieldValue, formValidationForcedKey
	);
}

function getAllFieldValidators(fieldDefinitions, dataAccessors, formFieldValueSelectors, formFieldValueSelectorsArray,
		newFieldMetaData, translator, formFieldValidators
) {
	return mapProperties(
		fieldDefinitions,
		(fieldInfo, fieldName) => {
			const formFieldValueSelector = createSelector(
				[dataAccessors.fieldSelectors[fieldName]],
				formField => formField.get(VALUE_FIELD)
			);
			formFieldValueSelectors[fieldName] = formFieldValueSelector;
			formFieldValueSelectorsArray.push(formFieldValueSelector);
			const fieldValidator = createFieldValidator(
				dataAccessors.fieldSelectors, newFieldMetaData, translator, fieldName,
				newFieldMetaData[fieldName].validators
			);
			formFieldValidators.push(fieldValidator);
			return fieldValidator;
		}
	);
}

function getFormAccessors(dataAccessors, formValidator, composedFormValidator, allFieldValidators,
		allFieldHintValidators, translator, newFieldMetaData, formFieldValueSelectors, formFieldIsDefaultSelectors,
		getAllMappedFieldValues, updateFieldValue, originalUpdateFieldValue, formValidationForcedKey
) {
	return {
		...dataAccessors,
		isValid: state => formValidator(state) === true,
		getValidationError: state => {
			const validationError = formValidator(state);
			return validationError === true ? '' : validationError;
		},
		getFormValidationError: state => {
			const validationError = composedFormValidator(state);
			return validationError === true ? '' : validationError;
		},
		formValidationErrorSelector: composedFormValidator,
		validationErrorSelector: formValidator,
		getFieldValidationErrorSelector: fieldName => allFieldValidators[fieldName],
		getFieldValidationError: (state, fieldName) => allFieldValidators[fieldName](state),
		getFieldValidationHint: (state, fieldName) => allFieldHintValidators[fieldName](state),
		getFieldLabel: (state, fieldName) => translator.tr(newFieldMetaData[fieldName].label, currentLocale(state)),
		getFormFieldValue: (state, fieldName) => formFieldValueSelectors[fieldName](state),
		isDefault: (state, fieldName) => formFieldIsDefaultSelectors[fieldName](state),
		getAllMappedFormFieldValues: getAllMappedFieldValues,
		updateFieldValue,
		setFormValidationForced: (forced = true) => originalUpdateFieldValue(formValidationForcedKey, forced),
		isFormValidationForced: createFieldSelector(dataAccessors.get, formValidationForcedKey, false),
		restoreValuesFromMap: createRestoreFromMapFunction(newFieldMetaData, dataAccessors)
	};
}

function getAllFieldValueUpdaters(fieldNames, originalUpdateFieldValue) {
	return fieldNames.reduce((allUpdaters, fieldName) => {
		allUpdaters[fieldName] =
			(value, error) => originalUpdateFieldValue(fieldName, Immutable.Map({
				[VALUE_FIELD]: value,
				[ERROR_FIELD]: error || ''
			}));
		return allUpdaters;
	}, {});
}

function getUpdateFieldValue(newFieldMetaData, allFieldValueUpdaters) {
	return (fieldName, newValue, error) => dispatch => {
		const {onChange = [], value} = newFieldMetaData[fieldName];
		dispatch(allFieldValueUpdaters[fieldName](newValue, error));
		onChange.forEach(onChangeHandler => {
			dispatch(onChangeHandler(value, newValue, newFieldMetaData, allFieldValueUpdaters));
		});
	};
}

function createFieldValidator(fieldSelectors, allFields, translator, contextFieldName, validatorsFunctions) {
	const validators = validatorsFunctions ? validatorsFunctions.map(
		validatorFactory => validatorFactory(fieldSelectors, allFields, translator, contextFieldName)
	) : [];
	validators.unshift(createSelector(
		fieldSelectors[contextFieldName],
		fieldValue => fieldValue.get(ERROR_FIELD, true) || true
	));

	return createSelector(validators, firstErrorOrTrue);
}

function createMemoizedSelector(structureSelector, ...composedFieldValidators) {
	let lastValidatedStructure = null;
	let validationResult = null;
	return state => {
		const currentStructure = structureSelector(state);
		if (lastValidatedStructure !== structureSelector(state)) {
			validationResult = firstErrorOrTrue(...(composedFieldValidators.map(validator => validator(state))));
			lastValidatedStructure = currentStructure;
		}
		return validationResult;
	};
}

function createRestoreFromMapFunction(fieldMetaData, dataAccessors) {
	const fieldNames = Object.keys(fieldMetaData);
	return function restoreFromMap(newValues = Immutable.Map()) {
		const checkedNewValues = newValues === null ? Immutable.Map() : newValues;
		const getFieldValue = (checkedNewValues.get !== undefined && typeof (checkedNewValues.get) === 'function')
			? getFieldValueFromMap
			: getFieldValueFromObject;
		return dataAccessors.update(fieldNames.reduce((newStructure, name) => {
			let newValue = getFieldValue(checkedNewValues, name);
			if (newValue === undefined) {
				newValue = fieldMetaData[name].defaultValue;
			} else {
				newValue = Immutable.Map({
					[VALUE_FIELD]: newValue
				});
			}
			return newStructure.set(name, newValue);
		}, Immutable.Map()));
	};
}

function getFieldValueFromMap(map, fieldName) {
	return map.get(fieldName);
}

function getFieldValueFromObject(obj, fieldName) {
	return obj[fieldName];
}
