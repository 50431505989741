import React from 'react';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {tr} from '../../i18n/components/SynFormattedMessage.js';
import GeneralMessagesTranslator from '../../i18n/translators/GeneralTranslator.js';

export function createStep(createSelectDataValid, createSelectFooterState) {
	return {
		createSelectDataValid,
		createSelectFooterState
	};
}

export function buildCombinedSelector(baseSelectors, steps, field, defaultSelectorCreator) {
	const validators = steps.map((step, stepIndex) => {
		const selectorCreator = step[field] || defaultSelectorCreator;
		return selectorCreator(baseSelectors, stepIndex, steps);
	});

	const {selectCurrentStep} = baseSelectors;
	const selectSelector = createSelector(
		selectCurrentStep,
		currentStep => {
			const indexValid = currentStep >= 0 && currentStep < steps.length;
			return indexValid ? validators[currentStep] : null;
		}
	);

	return (state, props) => {
		const selector = selectSelector(state);
		return selector === null ? null : selector(state, props);
	};
}

export function alwaysValid() {
	return true;
}

export function createStepperLabelStaticText(message, translator = GeneralMessagesTranslator) {
	const TextComponent = tr(translator, message);
	return <TextComponent />;
}

const CANCEL_BUTTON_LABEL = createStepperLabelStaticText('CancelPhrase');
const DEFAULT_NEXT_LABEL = createStepperLabelStaticText('ForwardPhrase');
const DEFAULT_FINISH_LABEL = createStepperLabelStaticText('FinishPhrase');
const DEFAULT_CLOSE_LABEL = createStepperLabelStaticText('ClosePhrase');
const INITIAL_FOOTER_STATE = Immutable.Map({
	showPreviousButton: true,
	previousLabel: null,
	nextLabel: DEFAULT_NEXT_LABEL
});

export class StepperFooterStateBuilder {
	constructor(state = INITIAL_FOOTER_STATE) {
		this.state = state === INITIAL_FOOTER_STATE ? state : Immutable.fromJS(state);
	}

	updateAndClone(property, value) {
		return new StepperFooterStateBuilder(this.state.set(property, value));
	}

	removePreviousLabel() {
		return this.setPreviousLabel(null);
	}

	setPreviousLabel(labelElement) {
		return this.updateAndClone('previousLabel', labelElement);
	}

	hidePreviousButton() {
		return this.updateAndClone('showPreviousButton', false);
	}

	showPreviousButton() {
		return this.updateAndClone('showPreviousButton', true);
	}

	nextIsFinish() {
		return this.setNextLabel(DEFAULT_FINISH_LABEL);
	}

	nextIsClose() {
		return this.setNextLabel(DEFAULT_CLOSE_LABEL);
	}

	previousIsCancel() {
		return this.setPreviousLabel(CANCEL_BUTTON_LABEL);
	}

	removeNextLabel() {
		return this.setNextLabel(null);
	}

	setNextLabel(labelElement) {
		return this.updateAndClone('nextLabel', labelElement);
	}

	build() {
		return this.state.toJS();
	}
}

export function buildDefaultFooterState(step, steps) {
	let footerStateBuilder = new StepperFooterStateBuilder();
	if (step <= 0) {
		footerStateBuilder = footerStateBuilder.setPreviousLabel(CANCEL_BUTTON_LABEL);
	}
	if (step >= steps.length - 1) {
		footerStateBuilder = footerStateBuilder.nextIsFinish();
	}
	return footerStateBuilder.build();
}

