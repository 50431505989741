import {buffers, channel, END} from 'redux-saga';
import {call, cancel, cancelled, delay, flush, fork, put, select, take} from 'redux-saga/effects';

import {PUSH_LOCATION, REPLACE_LOCATION, RESTORE_FROM_HISTORY} from '../constants/LocationActionTypes.js';
import {updateLocationState} from '../flux/LocationActions.js';
import {locationSelector} from '../flux/selectors/LocationSelectors.js';
import {toUrlString} from '../LocationUtils.js';

const HISTORY_PUSH = createHistoryApiCall(history.pushState);
const HISTORY_REPLACE = createHistoryApiCall(history.replaceState);
const MAX_PROCESSING_CHANNELS = 10;
const PERIODIC_REPLACE_DELAY = 250;

// TODO TIM: Test all sagas!

export default function* synchronizeHistory() {
	const processingChannel = channel(buffers.expanding(MAX_PROCESSING_CHANNELS));
	const processingTask = yield fork(processHistoryActions, processingChannel);
	while (!(yield cancelled())) {
		const historyAction = yield take([PUSH_LOCATION, REPLACE_LOCATION, RESTORE_FROM_HISTORY]);
		if (historyAction !== END) {
			yield put(processingChannel, historyAction);
		}
	}
	yield cancel(processingTask);
}

function* processHistoryActions(chan) {
	const replaceChannel = channel(buffers.sliding(1));
	const replaceTask = yield fork(periodicReplace, replaceChannel);
	while (!(yield cancelled())) {
		const currentAction = yield take(chan);
		if (currentAction !== END) {
			yield call(processHistoryAction, replaceChannel, currentAction);
		}
	}
	yield cancel(replaceTask);
}

function* processHistoryAction(replaceChannel, action) {
	const {type, payload} = action;
	if (type === RESTORE_FROM_HISTORY) {
		yield flush(replaceChannel);
	} else {
		yield put(updateLocationState(payload));

		const currentLocationState = yield select(locationSelector);
		if (type === PUSH_LOCATION) {
			yield call(replaceIfAvailable, replaceChannel);
			yield call(HISTORY_PUSH, currentLocationState);
		} else {
			yield put(replaceChannel, currentLocationState);
		}
	}
}

function* periodicReplace(chan) {
	while (!(yield cancelled())) {
		yield call(replaceIfAvailable, chan);
		yield delay(PERIODIC_REPLACE_DELAY);
	}
}

function* replaceIfAvailable(chan) {
	const replaceStates = yield flush(chan);
	for (let index = 0; index < replaceStates.length; ++index) {
		yield call(HISTORY_REPLACE, replaceStates[index]);
	}
}

function createHistoryApiCall(historyApiCall) {
	return function apiCall(locationState) {
		historyApiCall.call(history, locationState.toJS(), '', toUrlString(locationState.delete('hidden')) || '/');
	};
}
