import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {BEGIN_SESSION, END_SESSION} from '../../authentication/constants/SessionActionTypes.js';
import {
	UI_ENABLE_SIDEBAR_TRANSITIONS,
	UI_HIDE_CHANGE_PASSWORD_DIALOG,
	UI_HIDE_PRIVACY_POLICY_DIALOG,
	UI_SET_DEVICE_PIXEL_RATIO,
	UI_SHOW_CHANGE_PASSWORD_DIALOG,
	UI_SHOW_PRIVACY_POLICY_DIALOG,
	UI_TOGGLE_SIDEBAR,
	UI_UPDATE_FULLSCREEN_ELEMENT_VISIBILITY_STATUS,
	UI_UPDATE_WINDOW_LAYOUT
} from '../constants/UIActionTypes.js';
import {isSideBarElevated} from '../UIUtils.js';

function getInitialUIState(ui) {
	return Immutable.Map({
		sidebar: Immutable.Map({
			visibleOnDesktop: true,
			visibleOnMobile: false,
			transitionsEnabled: false
		}),
		showPrintLayout: window.matchMedia('print').matches,
		windowSize: Immutable.Map({
			width: window.innerWidth,
			height: window.innerHeight
		}),
		devicePixelRatio: ui ? ui.get('devicePixelRatio') : 1,
		showPrivacyPolicyDialog: false,
		showChangePasswordDialog: false,
		fullScreenElementIsVisible: false
	});
}

function setDevicePixelRatio(uiState, action) {
	const {payload: newDevicePixelRatio} = action;
	return uiState.set('devicePixelRatio', newDevicePixelRatio);
}

const uiReducer = handleActions({
	[UI_SET_DEVICE_PIXEL_RATIO]: setDevicePixelRatio,
	[UI_TOGGLE_SIDEBAR]: ui => ui.update('sidebar', sidebar => {
		const windowSize = ui.get('windowSize');
		return sidebar.update(
			isSideBarElevated(windowSize)
				? 'visibleOnMobile'
				: 'visibleOnDesktop'
			, previousVisibility => !previousVisibility
		);
	}),
	[UI_UPDATE_WINDOW_LAYOUT]:
		(uiState, {payload: {showPrintLayout, windowSize}}) => {
			let ui = uiState.update(
				'showPrintLayout',
				showsPrintLayout => (showPrintLayout === undefined ? showsPrintLayout : Boolean(showPrintLayout))
			);
			if (ui.get('showPrintLayout', false)) {
				ui = ui.set('windowScreenSize', ui.get('windowScreenSize', ui.get('windowSize')));
			} else {
				ui = ui.delete('windowScreenSize');
			}
			if (!Immutable.is(windowSize, ui.get('windowSize'))) {
				ui = ui.set('windowSize', windowSize);
			}
			return ui;
		},
	[UI_ENABLE_SIDEBAR_TRANSITIONS]:
			(ui, {payload}) => ui.update('sidebar', sidebar => sidebar.update('transitionsEnabled', () => payload)),
	[BEGIN_SESSION]: getInitialUIState,
	[END_SESSION]: getInitialUIState,
	[UI_SHOW_PRIVACY_POLICY_DIALOG]: ui => ui.set('showPrivacyPolicyDialog', true),
	[UI_HIDE_PRIVACY_POLICY_DIALOG]: ui => ui.set('showPrivacyPolicyDialog', false),
	[UI_SHOW_CHANGE_PASSWORD_DIALOG]: ui => ui.set('showChangePasswordDialog', true),
	[UI_HIDE_CHANGE_PASSWORD_DIALOG]: ui => ui.set('showChangePasswordDialog', false),
	[UI_UPDATE_FULLSCREEN_ELEMENT_VISIBILITY_STATUS]: (ui, {payload: {fullScreenElementVisibility}}) => ui.set('fullScreenElementIsVisible', fullScreenElementVisibility)
}, getInitialUIState());

export default uiReducer;
