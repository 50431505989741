import {END} from 'redux-saga';
import {take} from 'redux-saga/effects';

import {BEGIN_SESSION, END_SESSION, FINISH_SESSION_TRANSITION} from '../constants/SessionActionTypes.js';

export function* waitForEndOfSession() {
	const sessionEndAction = yield take(END_SESSION);
	let sessionEnded = sessionEndAction !== END;
	if (sessionEnded) {
		const finishedTransitionAction = yield take(FINISH_SESSION_TRANSITION);
		sessionEnded = finishedTransitionAction !== END;
	}
	return sessionEnded;
}

export function* waitForSession() {
	let beginSessionAction = yield take(BEGIN_SESSION);
	if (beginSessionAction !== END) {
		const transitionFinishedAction = yield take(FINISH_SESSION_TRANSITION);
		if (transitionFinishedAction === END || transitionFinishedAction.error) {
			beginSessionAction = END;
		}
	}
	return beginSessionAction;
}
