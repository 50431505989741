import React from 'react';
import PropTypes from 'prop-types';

import PrimaryAction from '../../material-design/components/layout/PrimaryAction.js';
import {PrimaryActionIconArea} from '../../material-design/components/layout/PrimaryActionSideAreas.js';
import Title from '../../material-design/components/Title.js';
import MenuIconContainer from '../containers/MenuIconContainer.js';

const DEFAULT_MENU_BUTTON = <MenuIconContainer />;

export default class AppBarPrimaryContent extends React.PureComponent {
	render() {
		const {menuButton, title, subTitle, children} = this.props;
		const titleNode = title
			? (
				<Title subTitle={subTitle}>
					{title}
				</Title>
			)
			: false;

		return (
			<PrimaryAction>
				{menuButton && (
					<PrimaryActionIconArea>
						{menuButton}
					</PrimaryActionIconArea>
				)}
				{titleNode}
				{children}
			</PrimaryAction>
		);
	}
}

AppBarPrimaryContent.propTypes = {
	menuButton: PropTypes.node,
	title: PropTypes.node,
	subTitle: PropTypes.node
};

AppBarPrimaryContent.defaultProps = {
	menuButton: DEFAULT_MENU_BUTTON
};
