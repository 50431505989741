import React from 'react';
import PropTypes from 'prop-types';

import {KEY_VALUE_BACKSPACE, KEY_VALUE_DELETE} from '../../commons/constants/KeyValues.js';
import {arePointerEventsSupported, preventEventDefault, stopEventPropagation} from '../../commons/utils/DOMEventUtils.js';
import DomEventsManager from '../../events/DomEventsManager.js';
import AnnotationContainer from '../flux/containers/AnnotationContainer.js';
import ViewerContext from './ViewerContext.js';

import '../../../styles/viewer/components/ImageViewerSVGOverlay.scss';

export default class ImageViewerSVGOverlay extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundOnInteraction = this.onInteraction.bind(this);
		this.boundRenderAnnotation = this.renderAnnotation.bind(this);
		this.domEventsManager = new DomEventsManager();
	}

	render() {
		const {
			containerWidth, containerHeight, children, 
			annotationIds = [], annotationProps, annotationsVisible
		} = this.props;
		const onPointerDown = arePointerEventsSupported() ? this.boundOnInteraction : undefined;
		const onMouseDown = arePointerEventsSupported() ? undefined : this.boundOnInteraction;
		const onTouchStart = arePointerEventsSupported() ? undefined : this.boundOnInteraction;
		const viewBox = `-${containerWidth / 2} -${containerHeight / 2} ${containerWidth} ${containerHeight}`;
		const style = {width: '100%', height: '100%', top: 0, left: 0, position: 'absolute'};
		return (
			
			<svg className='image-viewer-svg-overlay' style={style} viewBox={viewBox} 
				onPointerDown={onPointerDown} onMouseDown={onMouseDown} onTouchStart={onTouchStart}>
				{
					annotationsVisible && 
					annotationIds.map(this.boundRenderAnnotation)
				}
				{
					React.Children.map(children, 
						(child, index) => React.cloneElement(child, 
							{...annotationProps, key: `annotation::child::${String(index)}`}))
				}
			</svg>
		);
	}

	renderAnnotation(annotationId) {
		const {
			annotationProps, updateAnnotation, isPrintPreview,
			removeAnnotation, setActiveAnnotation, activeAnnotationId
		} = this.props;
		const isActiveAnnotation = activeAnnotationId === annotationId;
		return (<AnnotationContainer key={`annotation::${annotationId}`}
			active={isActiveAnnotation}
			annotationId={annotationId}
			onAnnotationPropertiesChanged={updateAnnotation}
			onActivate={setActiveAnnotation}
			onRemoveAnnotation={removeAnnotation}
			isPrintPreview={isPrintPreview}
			{...annotationProps} />);
	}

	componentDidMount() {
		this.domEventsManager.addEventListener(document, 'keydown', this.onKeyDown.bind(this), true);
		this.domEventsManager.addEventListener(window, 'blur', this.clearActiveAnnotation.bind(this), false);
	}

	componentWillUnmount() {
		this.domEventsManager.removeAllListeners();
	}

	onInteraction(e) {
		if (!e.defaultPrevented && this.hasActiveAnnotation()) {
			this.clearActiveAnnotation();
			preventEventDefault(e);
		}
	}

	onKeyDown(e) {
		const {isActive: isViewerActive} = this.context;
		const {removeAnnotation, activeAnnotationId} = this.props;
		if (isViewerActive && this.hasActiveAnnotation()) {
			switch (e.key) {
				case KEY_VALUE_BACKSPACE:
				case KEY_VALUE_DELETE:
					stopEventPropagation(e);
					removeAnnotation(activeAnnotationId);
					break;
				default:
					this.clearActiveAnnotation();
			}
		}
	}

	hasActiveAnnotation() {
		const {activeAnnotationId} = this.props;
		return activeAnnotationId !== null;
	}

	clearActiveAnnotation() {
		const {resetActiveAnnotation} = this.props;
		if (this.hasActiveAnnotation()) {
			resetActiveAnnotation();
		}
	}
}

ImageViewerSVGOverlay.contextType = ViewerContext;

ImageViewerSVGOverlay.propTypes = {
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired,
	annotationIds: PropTypes.arrayOf(PropTypes.string),
	annotationProps: PropTypes.object.isRequired,
	activeAnnotationId: PropTypes.string,
	annotationsVisible: PropTypes.bool,
	resetActiveAnnotation: PropTypes.func.isRequired,
	removeAnnotation: PropTypes.func.isRequired,
	updateAnnotation: PropTypes.func.isRequired,
	setActiveAnnotation: PropTypes.func.isRequired,
	isPrintPreview: PropTypes.bool.isRequired
};
