import {extractDate, formatLongDate} from '../../utils/DateUtils.js';

const CONSTRUCTOR_KEY = Symbol('CONSTRUCTOR_KEY');

export default class Patient {
	constructor(...args) {
		const [key, normalizedPatientInfo] = args;
		if (key !== CONSTRUCTOR_KEY) {
			throw new Error('Use Patient.from to construct patient objects.');
		}
		Object.assign(this, normalizedPatientInfo);
		this.birthDate = extractDate(this.birthDate);
		this.uniqueId = buildUniqueId(this);
		Object.freeze(this);
	}

	static from(obj) {
		if (obj instanceof Patient) {
			return obj;
		}
		if (!(obj instanceof Object)) {
			throw new Error('Passed patient information is not an object');
		}
		return new Patient(CONSTRUCTOR_KEY, normalizePatientInfo(obj));
	}
}

function normalizePatientInfo(obj) {
	if (obj.toJS instanceof Function) {
		return normalizePatientObj(obj.toJS());
	}
	return normalizePatientObj(obj);
}

function normalizePatientObj(obj) {
	const {
		id, title,
		first_name: fName, firstName,
		last_name: lName, lastName,
		sex, sex_fk: sexFk,
		birth_date: bDate, birthDate,
		patient_id: patId, patientId,
		...remainingProps
	} = obj;
	return {
		id,
		title,
		firstName: fName || firstName,
		lastName: lName || lastName,
		sex: sex || sexFk,
		birthDate: bDate || birthDate,
		patientId: patId || patientId,
		...remainingProps
	};
}

function buildUniqueId(patientObject) {
	const {
		firstName = '',
		lastName = '',
		sex = '',
		birthDate = ''
	} = patientObject;
	return `${lastName.toUpperCase()}${firstName.toUpperCase()}${sex.toUpperCase()}${formatLongDate(birthDate)}`;
}
