import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {isDicomSeries} from '../../../commons/data/aim/SeriesHelpers.js';
import {createSelectSeriesLoadedPercentBySeriesId, getFileLoadedPercent} from '../../../viewer/flux/selectors/ViewerItemsSelectors.js';
import PreviewTileCaption from '../../components/PreviewTileCaption.js';

export default connect(
	(ignoredState, {item}) => {
		let percentSelector;
		if (isDicomSeries(item)) {
			percentSelector = createSelectSeriesLoadedPercentBySeriesId(item.get('id'));
		} else {
			percentSelector = state => getFileLoadedPercent(state, item.get('id'));
		}
		return createStructuredSelector({
			percent: percentSelector
		});
	}, {}
)(PreviewTileCaption);
