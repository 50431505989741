import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import LocalizerOverlay from '../../components/LocalizerOverlay.js';
import DicomImage from '../../data/DicomImage';
import calculateLocalizer from '../../utils/calculateLocalizer.js';
import {selectActiveViewerDicomDump} from '../selectors/DicomSeriesViewerSelectors.js';

function LocalizerOverlayContainer({dicomImage, ...passedProps}) {
	const {dicomDump} = dicomImage;
	const activeViewerDicomDump = useSelector(selectActiveViewerDicomDump);
	const localizer = useMemo(
		() => calculateLocalizer(dicomDump, activeViewerDicomDump), [activeViewerDicomDump, dicomDump]
	);

	return <LocalizerOverlay localizer={localizer} {...passedProps} />;
}

export default LocalizerOverlayContainer;

LocalizerOverlayContainer.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage)
};
