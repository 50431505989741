import React, {useMemo} from 'react';
import Button, {buttonClasses} from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';

import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import {ALIGN_LEFT, EXPAND_BOTTOM} from '../constants/PupupMenuConstants.js';
import {COLOR_SECONDARY} from '../constants/SynMUIOptions.js';
import WarningIcon from './icons/WarningIcon.js';
import PopupButton from './PopupButton.js';
import Ellipsis from './typography/Ellipsis.js';

const BUTTON_STYLES = {
	textTransform: 'capitalize',
	[`& .${buttonClasses.startIcon}`]: {
		mr: '3px'
	}
};

function HasRemovedDocumentsButton(props) {
	const {forwardRef, children, ...remainingProps} = props;
	return (
		<Button color={COLOR_SECONDARY} sx={BUTTON_STYLES} ref={forwardRef} startIcon={<WarningIcon />} size='small'
		        {...remainingProps}>
			<Ellipsis>
				{children}
			</Ellipsis>
		</Button>
	);
}

HasRemovedDocumentsButton.propTypes = {
	forwardRef: withForwardRef.PropTypes.Ref
};

function RemovedNumStudiesPopupButton(props) {
	const {id, btnText, popupText, showButton} = props;
	const buttonElement = useMemo(() => withForwardRef(buttonProps => (
		<HasRemovedDocumentsButton {...buttonProps}>
			{btnText}
		</HasRemovedDocumentsButton>
	)
	, 'forwardRef'), [btnText]);

	return showButton ? (
		<PopupButton id={id} buttonElement={buttonElement} expandDirection={EXPAND_BOTTOM} align={ALIGN_LEFT}>
			<List>
				<ListItem>
					<ListItemText primary={popupText} />
				</ListItem>
			</List>
		</PopupButton>
	) : (
		<Ellipsis>
			{btnText}
		</Ellipsis>
	);
}

RemovedNumStudiesPopupButton.propTypes = {
	id: PropTypes.string.isRequired,
	btnText: PropTypes.node.isRequired,
	popupText(props, propName, componentName) {
		const {showButton, [propName]: propNameValue} = props;
		if (showButton && propNameValue === undefined) {
			return new Error(
				`'showButton' is true, please provide prop ${propName} to ${componentName}. Validation failed.`
			);
		}
		return null;
	},
	showButton: PropTypes.bool
};

RemovedNumStudiesPopupButton.defaultProps = {
	showButton: false
};

export default RemovedNumStudiesPopupButton;
