import Immutable from 'immutable';
import {createSelector} from 'reselect';

export const locationStateSelector = state => state.location;
export const locationSelector = createSelector(locationStateSelector, location => location.get('location', Immutable.Map()));
export const pathSelector = createSelector(locationSelector, location => location.get('path', ''));
export const querySelector = createSelector(locationSelector, location => location.get('query', Immutable.Map()));
export const fragmentSelector = createSelector(locationSelector, location => location.get('fragment', ''));
export const hiddenLocationDataSelector = createSelector(locationSelector, location => location.get('hidden', Immutable.Map()));
export const selectLastForcedLocationUpdate = createSelector(locationSelector, location => location.getIn(['hidden', 'last_forced_location_update'], 0));
