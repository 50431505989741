import {connect} from 'react-redux';

import DataGuard from '../../commons/components/data/DataGuard.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import {selectIsLoggedIn} from '../flux/selectors/SessionSelectors.js';

export default connect(
	state => ({
		data: selectIsLoggedIn(state),
		load: noop,
		isLoadNeeded: false,
		loadingIndicator: false
	})
)(DataGuard);
