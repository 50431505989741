import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {SYN_VIEWER_MUI_THEME} from '../../constants/SynMUIOptions.js';

const PROVIDED_THEME = createTheme(SYN_VIEWER_MUI_THEME);

function ViewerThemeProvider(props) {
	const {children} = props;
	return (
		<ThemeProvider theme={PROVIDED_THEME}>
			{children}
		</ThemeProvider>
	);
}

export default ViewerThemeProvider;
