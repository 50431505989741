import React from 'react';
import PropTypes from 'prop-types';

import LinkContainer from '../../commons/containers/LinkContainer.js';
import {webAppURL} from '../../commons/WebViewHelpers.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DialogButtonContainer from '../../material-design/components/DialogButtonContainer.js';
import Title from '../../material-design/components/Title.js';
import {
	ERROR_DOCUMENT_NOT_FOUND,
	ERROR_PATIENT_NOT_FOUND,
	ERROR_PATIENT_NOT_UNIQUE,
	ERROR_SHARE_NOT_FOUND
} from '../constants/PatientDetailsLoaderErrors.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';
import PatientDetailsLoadErrorCard from './PatientDetailsLoadErrorCard.js';

export default function PatientDetailsLoadError(props) {
	const {loadingError} = props;
	const message = getMessageFor(loadingError);
	return (
		<PatientDetailsLoadErrorCard>
			<Title>
				<SynFormattedMessage message='Error' translator={PatientDetailsMessagesTranslator} />
			</Title>
			<SynFormattedMessage className='message' message={message} translator={PatientDetailsMessagesTranslator} loadingError={loadingError} element={divWithError(loadingError)} />
			<DialogButtonContainer className='links-container'>
				<LinkContainer path={webAppURL('document')}>
					<SynFormattedMessage message='JumpToDocumentSearch' translator={PatientDetailsMessagesTranslator} />
				</LinkContainer>
				<LinkContainer path={webAppURL('documentshare')}>
					<SynFormattedMessage message='JumpToInbox' translator={PatientDetailsMessagesTranslator} />
				</LinkContainer>
			</DialogButtonContainer>
		</PatientDetailsLoadErrorCard>
	);
}

PatientDetailsLoadError.propTypes = {
	loadingError: PropTypes.oneOf([
		ERROR_DOCUMENT_NOT_FOUND,
		ERROR_PATIENT_NOT_FOUND,
		ERROR_PATIENT_NOT_UNIQUE,
		ERROR_SHARE_NOT_FOUND
	])
};

function getMessageFor(loadingError) {
	let message;
	switch (loadingError) {
		case ERROR_PATIENT_NOT_UNIQUE:
			message = 'PatientNotUniqueErrorMessage';
			break;
		case ERROR_PATIENT_NOT_FOUND:
			message = 'PatientNotFoundErrorMessage';
			break;
		case ERROR_SHARE_NOT_FOUND:
			message = 'ShareNotFoundErrorMessage';
			break;
		case ERROR_DOCUMENT_NOT_FOUND:
			message = 'DocumentNotFoundErrorMessage';
			break;
		default:
			break;
	}
	return message;
}

function divWithError(loadingError) {
	return function divWithLoadingError(props) {
		const {className, title, children} = props;
		return (
			<div data-loading-error={loadingError} title={title} className={className}>
				{children}
			</div>
		);
	};
}
