import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import {useFormattedDataSize} from '../../webview/commons/components/HumanReadableDataSize.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import CardActions from '../../webview/ui/components/Card/CardActions.js';
import CardContent from '../../webview/ui/components/Card/CardContent.js';
import OutlinedCard from '../../webview/ui/components/Card/OutlinedCard.js';
import EditListIcon from '../../webview/ui/components/icons/EditListIcon.js';
import VerticalLayout from '../../webview/ui/components/layout/VerticalLayout.js';
import LinkLikeButton from '../../webview/ui/components/LinkLikeButton.js';
import {Body2} from '../../webview/ui/components/typography/Typography.js';
import {TEXT_SECONDARY} from '../../webview/ui/constants/SynMUIOptions.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';

export default React.memo(EditUploadListButton);

function EditUploadListButton(props) {
	const {onClick, label, visible} = props;
	const amountWanted = useFormattedDataSize(useBrick(UploadFilesCollection, files => files.getTotalSize()));
	const numFiles = useBrick(UploadFilesCollection, brick => brick.getPreparedFilesCount());
	return (
		<VerticalLayout hidden={!visible}>
			<OutlinedCard>
				<CardContent>
					<SynFormattedMessage element={Body2} color={TEXT_SECONDARY} message='EditUploadListSummary' messageParams={{numFiles, amountWanted}} />
				</CardContent>
				<CardActions>
					<LinkLikeButton onClick={onClick} startIcon={<EditListIcon />} flat>
						{label}
					</LinkLikeButton>
				</CardActions>
			</OutlinedCard>
		</VerticalLayout>
	);
}

EditUploadListButton.propTypes = {
	onClick: PropTypes.func,
	label: PropTypes.string,
	visible: PropTypes.bool
};

EditUploadListButton.defaultProps = {
	visible: true
};
