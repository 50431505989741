import React from 'react';
import PropTypes from 'prop-types';

import DataGuard from '../../../commons/components/data/DataGuard.js';
import ViewerItemLoadErrorGuard from '../../components/ViewerItemLoadErrorGuard.js';
import DicomImage from '../../data/DicomImage.js';

export default function DicomImageDataGuard(props) {
	const {dicomImage, children} = props;
	const {isDownloadComplete} = dicomImage;
	return (
		<DataGuard data={isDownloadComplete}>
			<ViewerItemLoadErrorGuard viewerItem={dicomImage} itemType='image'>
				{children}
			</ViewerItemLoadErrorGuard>
		</DataGuard>
	);
}

DicomImageDataGuard.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage)
};
