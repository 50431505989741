import {changePassword} from '../api/PasswordApi.js';
import {CHANGE_PASSWORD} from '../constants/PasswordActionTypes.js';
import passwordOperationSaga from './passwordOperationSaga.js';

export default function* changePasswordSaga(operation = changePassword) {
	function changePasswordOperation(actionPayload) {
		const {username, oldPassword, newPassword, newPasswordConfirmation, secondFactorToken} = actionPayload;
		return operation(username, oldPassword, newPassword, newPasswordConfirmation, secondFactorToken);
	}
	yield* passwordOperationSaga(CHANGE_PASSWORD, changePasswordOperation);
}
