import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import {wrapInLocalizationContext} from './LocalizationContextWrapper.js';
import {wrapInTranslationContext} from './TranslationContext.js';

/**
 * @class SynFormattedMessage
 *
 */
class SynFormattedMessageImpl extends React.Component {
	render() {
		const {message} = this.props;
		return message === false ? false : this.renderMessage();
	}

	renderMessage() {
		const {
			className,
			locale,
			message,
			fallbackTranslation = null,
			element: Container,
			translator,
			messageParams
		} = this.props;
		const finalMessage = translator.getFormattedMessage(message, locale, messageParams, fallbackTranslation);
		const needsContainer = Boolean(Container) || Boolean(className);
		return needsContainer ? this.renderInContainer(finalMessage) : finalMessage;
	}

	renderInContainer(finalMessage) {
		const {element: Container} = this.props;
		const FinalContainer = Container || 'span';
		const remainingProps = cloneWithoutProperties(this.props,
			'locale', 'message', 'fallbackTranslation', 'element', 'translator', 'messageParams'
		);
		return (
			<FinalContainer {...remainingProps}>
				{finalMessage}
			</FinalContainer>
		);
	}
}

SynFormattedMessageImpl.propTypes = {
	message: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string
	]).isRequired,
	fallbackTranslation: PropTypes.string, /* displayed when message cannot be found by the translator */
	translator: PropTypes.object,
	locale: PropTypes.string,
	element: PropTypes.elementType,
	className: PropTypes.string,
	messageParams: PropTypes.object
};

SynFormattedMessageImpl.defaultProps = {
	fallbackTranslation: null,
	messageParams: {}
};

const SynFormattedMessage = wrapInTranslationContext(wrapInLocalizationContext(SynFormattedMessageImpl));

export default SynFormattedMessage;

export function tr(translator, message) {
	return function translatedMessage(props) {
		return <SynFormattedMessage message={message} translator={translator} {...props} />;
	};
}
