import React from 'react';
import Proptypes from 'prop-types';

import {ARCHIVE_DOCUMENTS_DIALOG_ID} from '../../../archive-documents/constants/ArchiveDocumentsConstants.js';
import ChangePasswordDialogContainer from '../../../authentication/flux/containers/ChangePasswordDialogContainer.js';
import SessionContextLoader from '../../../authentication/flux/containers/SessionContextLoader.js';
import {SHARE_DOCUMENTS_DIALOG_ID_PATTERN} from '../../../share-document/constants/ShareDocumentConstants.js';
import AutoClosePersistentPortal from '../../containers/AutoClosePersistentPortal.js';
import UnhandledErrorsDialogContainer from '../../containers/UnhandledErrorsDialogContainer.js';
import NonDiagnosticUsageMessageContainer from '../../containers/webview/NonDiagnosticUsageMessageContainer.js';
import PrivacyPolicyDialogContainer from '../../containers/webview/PrivacyPolicyDialogContainer.js';
import ApplicationMainArea from '../ApplicationMainArea.js';

export default class ContentPage extends React.PureComponent {
	render() {
		const {children, uiIsModalDialogVisible, className} = this.props;
		return (
			<React.Fragment>
				<ApplicationMainArea className={className} modalDialogIsVisible={uiIsModalDialogVisible}>
					{children}
				</ApplicationMainArea>

				<SessionContextLoader />
				<UnhandledErrorsDialogContainer />
				<NonDiagnosticUsageMessageContainer />
				<PrivacyPolicyDialogContainer />
				<ChangePasswordDialogContainer />
				<AutoClosePersistentPortal withId={ARCHIVE_DOCUMENTS_DIALOG_ID} />
				<AutoClosePersistentPortal matching={SHARE_DOCUMENTS_DIALOG_ID_PATTERN} />
			</React.Fragment>
		);
	}

	componentWillUnmount() {
	    const {isCacheValid} = this.props;
		if (!isCacheValid) {
			window.location.reload();
		}
	}
}

ContentPage.propTypes = {
	isCacheValid: Proptypes.bool,
	uiIsModalDialogVisible: Proptypes.bool,
	className: Proptypes.string
};
