import Immutable from 'immutable';

import {isKeyEvent, isStoppableDOMEvent, preventEventDefault, stopEventPropagation} from './DOMEventUtils.js';
import {capitalizeFirst} from './StringUtils.js';

const UNSET = Symbol('unset');
export function createEvent(type, properties = {}, originalEvent = null) {
	return Immutable.Map(properties)
		.set('type', type)
		.set('originalEvent', originalEvent);
}

export function stopOriginalDOMEvent(inputEvent) {
	const originalEvent = findOriginalEvent(inputEvent, isStoppableDOMEvent);
	if (Boolean(originalEvent) && Boolean(originalEvent.stopPropagation)) {
		stopEventPropagation(originalEvent);
		preventEventDefault(originalEvent);
	}
}

function findOriginalEvent(event, condition) {
	let originalEvent = event;
	while (Boolean(originalEvent) && !condition(originalEvent)) {
		originalEvent = originalEvent.get('originalEvent');
	}
	return originalEvent;
}

export function toHandlerName(eventName) {
	return `on${capitalizeFirst(eventName)}`;
}

export function findFirstEventProperty(event, propertyName, unsetValue = '') {
	const firstEventWithProperty = findOriginalEvent(
		event,
		originalEvent => originalEvent.get && originalEvent.get(propertyName, UNSET) !== UNSET
	);
	return firstEventWithProperty ? firstEventWithProperty.get(propertyName) : unsetValue;
}

export function originatedFromKeyEvent(inputEvent) {
	const originalEvent = findOriginalEvent(inputEvent, isStoppableDOMEvent);
	return originalEvent && isKeyEvent(originalEvent);
}
