/**
 * Notifies a listener whenever the device pixel ratio reported by the browser changes.
 * It Watches for a media query like '(resolution: 96dpi)' to change, which triggers
 * the notification.
 *
 * NOTE: As soon as we drop IE11 support we can switch to watch queries like '(resolution: 1dppx)' to
 * directly watch changes to the device pixel ratio. According to [1] the CSS unit dppx is not
 * supported by IE11 :-/
 *
 * [1] - https://caniuse.com/#search=dppx
 */
import {debugWarn} from '../commons/utils/DebugLog.js';

const DPI_96 = 96;
const DPI_76 = 76;

export default class BrowserDevicePixelRatioWatcher {
	constructor(listener) {
		this.listener = listener;
		this.mediaQuery = null;
		this.boundOnResolutionChanged = this.onResolutionChanged.bind(this);
		this.devicePixelRatio = BrowserDevicePixelRatioWatcher.getDevicePixelRatio();

		this.installWatcher();
	}

	installWatcher() {
		const queryString = BrowserDevicePixelRatioWatcher.mediaQueryForDPI(BrowserDevicePixelRatioWatcher.getDPI());
		this.mediaQuery = window.matchMedia(queryString);
		this.mediaQuery.addListener(this.boundOnResolutionChanged);
	}

	reinstallWatcher() {
		if (this.hasPreviousWatcher()) {
			this.uninstallPreviousWatcher();
		}
		this.installWatcher();
	}

	hasPreviousWatcher() {
		return Boolean(this.mediaQuery);
	}

	uninstallPreviousWatcher() {
		this.mediaQuery.removeListener(this.boundOnResolutionChanged);
		this.mediaQuery = null;
	}

	onResolutionChanged() {
		if (this.updateDevicePixelRatio()) {
			this.notifyListener(BrowserDevicePixelRatioWatcher.getDevicePixelRatio());
		}
		this.reinstallWatcher();
	}

	updateDevicePixelRatio() {
		const newDevicePixelRatio = BrowserDevicePixelRatioWatcher.getDevicePixelRatio();
		const ratioChanged = newDevicePixelRatio !== this.devicePixelRatio;
		if (ratioChanged) {
			this.devicePixelRatio = newDevicePixelRatio;
		}
		return ratioChanged;
	}

	notifyListener(newDevicePixelRatio) {
		if (this.listener) {
			try {
				this.listener(newDevicePixelRatio);
			} catch (e) {
				debugWarn(`Listener threw error: ${e}`);
			}
		}
	}

	static getDPI() {
		return BrowserDevicePixelRatioWatcher.getDevicePixelRatio() *
			BrowserDevicePixelRatioWatcher.getOrQueryBaseDPI();
	}

	static mediaQueryForDPI(dpi) {
		return `(resolution: ${dpi}dpi)`;
	}

	static getDevicePixelRatio() {
		return window.devicePixelRatio || 1;
	}

	static getOrQueryBaseDPI() {
		if (BrowserDevicePixelRatioWatcher.BASE_DPI === undefined) {
			BrowserDevicePixelRatioWatcher.BASE_DPI = BrowserDevicePixelRatioWatcher.queryBaseDPI();
		}
		return BrowserDevicePixelRatioWatcher.BASE_DPI;
	}

	static queryBaseDPI() {
		const devicePixelRatio = BrowserDevicePixelRatioWatcher.getDevicePixelRatio();
		// Known options According to: https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
		const knownDPIOptions = [DPI_96, DPI_76];
		const queriedDPI = knownDPIOptions.find(
			dpi => BrowserDevicePixelRatioWatcher.resolutionMatches(devicePixelRatio * dpi
			)
		);
		return queriedDPI === undefined ? knownDPIOptions[0] : queriedDPI;
	}

	static resolutionMatches(dpi) {
		return window.matchMedia(BrowserDevicePixelRatioWatcher.mediaQueryForDPI(dpi)).matches;
	}

	static onDeviceAspectRatioChange(processDeviceAspectRatio) {
		return new BrowserDevicePixelRatioWatcher(processDeviceAspectRatio);
	}
}
BrowserDevicePixelRatioWatcher.BASE_DPI = undefined;

