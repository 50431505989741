import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

// https://material.io/components/progress-indicators/#linear-progress-indicators

function DeterminateLinearProgressBar(props) {
	const {value, fullWidth} = props;
	return <LinearProgress variant='determinate' value={value} sx={fullWidth ? {width: '100%'} : null} />;
}

DeterminateLinearProgressBar.propTypes = {
	value: PropTypes.number.isRequired,
	fullWidth: PropTypes.bool
};

DeterminateLinearProgressBar.defaultProps = {
	fullWidth: false
};

export default React.memo(DeterminateLinearProgressBar);
