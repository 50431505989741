import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../ui/components/Avatar.js';
import getGenderIcon from '../webview/icons/getGenderIcon.js';

const SMALL_AVATAR_SIZE = 24;

function GenderAvatar(props) {
	const {className, gender, small} = props;
	let icon = getGenderIcon(gender);
	if (small) {
		icon = React.cloneElement(icon, {fontSize: 'small'});
	}

	const sxProps = small ? {
		width: SMALL_AVATAR_SIZE,
		height: SMALL_AVATAR_SIZE
	} : null;
	return (
		<Avatar className={className} sx={sxProps}>
			{icon}
		</Avatar>
	);
}

GenderAvatar.propTypes = {
	className: PropTypes.string,
	gender: PropTypes.string,
	small: PropTypes.bool
};

GenderAvatar.defaultProps = {
	small: false
};

export default GenderAvatar;
