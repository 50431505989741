import Immutable from 'immutable';
import {batchActions} from 'redux-batched-actions';

import {IS_DEBUG_BUILD} from '../../../commons/constants/EnvironmentConstants.js';
import {createAction} from '../../../commons/utils/ActionUtils.js';
import {pushLocation} from '../../../router/flux/LocationActions.js';
import {
	LOAD_ITEM_INTO_VIEWER,
	MERGE_VIEWER_PROPERTIES,
	RESET_VIEWER_PROPERTIES,
	RESET_VIEWER_STATE,
	SET_ACTIVE_VIEWER_ID,
	SET_VIEWER_LAYOUT_MODE,
	TOGGLE_FULLSCREEN
} from '../../constants/ViewerActionTypes.js';
import {VIEWER_QUERY_PROPERTY_SHOW_VIEWER} from '../../constants/ViewerConstants.js';

function activateAnd(actionCreator) {
	return (viewerId, ...args) => batchActions([
		setActiveViewer(viewerId),
		actionCreator(viewerId, ...args)
	]);
}

export const resetViewerState = createAction(RESET_VIEWER_STATE);
export const loadItemIntoViewer = createAction(LOAD_ITEM_INTO_VIEWER,
	(viewerId, itemType, itemId) => ({viewerId, itemType, itemId})
);
export const resetViewerProperties = createAction(RESET_VIEWER_PROPERTIES);
export function showViewer(doShow = true) {
	return pushLocation(doShow, ['query', VIEWER_QUERY_PROPERTY_SHOW_VIEWER]);
}
export const toggleFullscreen = createAction(TOGGLE_FULLSCREEN);

export const setActiveViewer = createAction(SET_ACTIVE_VIEWER_ID);
export const mergeViewerProperties = createAction(MERGE_VIEWER_PROPERTIES,
	IS_DEBUG_BUILD ? mergeViewerPropertiesDebugPaylodBuilder : mergeViewerPropertiesPaylodBuilder
);

function mergeViewerPropertiesPaylodBuilder(viewerId, partialProperties) {
	return {viewerId, partialProperties};
}

function mergeViewerPropertiesDebugPaylodBuilder(viewerId, partialProperties) {
	if (!Immutable.Map.isMap(partialProperties) && typeof (partialProperties) !== 'object') {
		throw new TypeError('Expected partialProperties be an Immutable.Map or object.');
	}
	return mergeViewerPropertiesPaylodBuilder(viewerId, partialProperties);
}

export const mergeViewerPropertiesAndActivate = activateAnd(mergeViewerProperties);
export const setViewerLayoutMode = createAction(SET_VIEWER_LAYOUT_MODE);

const loadItemIntoViewerAndActivate = activateAnd(loadItemIntoViewer);
export function loadSeriesIntoViewer(viewerId, seriesId) {
	return loadItemIntoViewerAndActivate(viewerId, 'series', seriesId);
}
export function loadFileIntoViewer(viewerId, fileId) {
	return loadItemIntoViewerAndActivate(viewerId, 'file', fileId);
}

export function restoreLayout(layoutMode, items) {
	return batchActions([setViewerLayoutMode(layoutMode)].concat(items.map(
		({viewerId, type, id}) => loadItemIntoViewer(viewerId, type, id))
	));
}
