import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import wrapInFormContext from '../components/form/wrapInFormContext.js';
import {preventEventDefault} from '../utils/DOMEventUtils.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';

export default function createFormButtonContainer(ButtonComponent) {
	return wrapInFormContext(connect(
		(state, ownProps) => {
			const selectedState = {
				isFormValid: ownProps.formDescription.isValid(state)
			};
			if (ownProps.propsSelector) {
				selectedState.selectedProps = ownProps.propsSelector(state);
			}
			return selectedState;
		},
		(dispatch, ownProps) => bindActionCreators({
			forceFormValidation: ownProps.formDescription.setFormValidationForced
		}, dispatch),
		(stateProps, dispatchProps, ownProps) => {
			let mergedProps = {
				...cloneWithoutProperties(stateProps, 'isFormValid', 'selectedProps'),
				...cloneWithoutProperties(ownProps, 'propsSelector', 'formDescription')
			};
			const isSubmitButton = Boolean(ownProps.type) && ownProps.type === 'submit';
			if (!stateProps.isFormValid && !mergedProps.onClick && isSubmitButton) {
				mergedProps.onClick = e => {
					preventEventDefault(e);
					dispatchProps.forceFormValidation();
				};
			}
			if (stateProps.selectedProps) {
				mergedProps = Object.assign(mergedProps, stateProps.selectedProps);
			}
			return mergedProps;
		}
	)(ButtonComponent));
}
