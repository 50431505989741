import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import StepDotIcon from './icons/StepDotIcon.js';

import '../../../styles/material-design/components/StepperProgress.scss';

const ACTIVE_DOT_CLASS_NAMES = 'dot active';
const DOT_CLASS_NAMES = 'dot';

function renderDots(props) {
	const {currentStep, nrSteps} = props;
	const dots = [];
	for (let step = 1; step <= nrSteps; ++step) {
		dots.push(
			<StepDotIcon key={step} className={step === currentStep ? ACTIVE_DOT_CLASS_NAMES : DOT_CLASS_NAMES} />
		);
	}
	return dots;
}

function StepperProgress(props) {
	const {className} = props;
	const finalClassName = combineClassNames('material-stepper--progress', className);
	return (
		<div className={finalClassName}>
			{renderDots(props)}
		</div>
	);
}

StepperProgress.propTypes = {
	className: PropTypes.string
};

export default StepperProgress;
