import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../utils/StyleUtils.js';
import {
	STATE_ENTERED,
	STATE_ENTERING,
	STATE_EXITED,
	STATE_EXITING,
	STATE_HIDDEN,
	STATE_MOUNTED
} from './SynTransition.js';

export default class ExpandingTransitionRenderer extends React.PureComponent {
	render() {
		const {transitionState, transitionDuration, className, children} = this.props;
		const finalClassName = combineClassNames(
			ExpandingTransitionRenderer.getAnimationStateClassName(transitionState),
			className
		);
		return (
			<div style={this.calculateTransitionStyle(transitionState, transitionDuration)}
				className={finalClassName}>
				{children}
			</div>);
	}

	static getAnimationStateClassName(transitionState) {
		return `animation-${transitionState.toLowerCase().replace('_', '-')}`;
	}

	calculateTransitionStyle(transitionState, durationInMs) {
		const transitionStyle = transitionState === STATE_HIDDEN ? {} : {visibility: 'visible'};

		if (transitionState === STATE_MOUNTED) {
			transitionStyle.transition = 'transform step-start, opacity step-start';
		} else if (transitionState === STATE_ENTERING || transitionState === STATE_ENTERED) {
			transitionStyle.transition = `transform ${durationInMs}ms ease-out, opacity ${durationInMs}ms ease-out`;
		} else if (transitionState === STATE_EXITING || transitionState === STATE_EXITED) {
			transitionStyle.transition = `transform ${durationInMs}ms ease-in, opacity ${durationInMs}ms ease-in`;
		}

		if (
			transitionState === STATE_EXITED ||
			transitionState === STATE_EXITING ||
			transitionState === STATE_MOUNTED
		) {
			transitionStyle.transform = this.calculateResizeTransform();
			transitionStyle.opacity = 0.1;
		}

		return transitionStyle;
	}

	calculateResizeTransform() {
		const {sourceBoundingRect, targetBoundingRect} = this.props;

		const targetWidth = targetBoundingRect.width;
		const targetHeight = targetBoundingRect.height;

		const startWidth = sourceBoundingRect.width;
		const startHeight = sourceBoundingRect.height;

		const startX = sourceBoundingRect.left + startWidth / 2;
		const startY = sourceBoundingRect.top + startHeight / 2;

		const endX = targetBoundingRect.left + targetWidth / 2;
		const endY = targetBoundingRect.top + targetHeight / 2;

		const translate = [
			`${startX - endX}px`,
			`${startY - endY}px`,
			0
		];

		const scale = [
			startWidth / targetWidth,
			startHeight / targetHeight
		];

		return `translate3d(${translate.join(',')}) scale(${scale.join(',')})`;
	}
}

ExpandingTransitionRenderer.propTypes = {
	transitionState: PropTypes.string,
	className: PropTypes.string,
	transitionDuration: PropTypes.number,
	sourceBoundingRect: PropTypes.exact({
		left: PropTypes.number,
		top: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number
	}),
	targetBoundingRect: PropTypes.exact({
		left: PropTypes.number,
		top: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number
	})
};
