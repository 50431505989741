import create from '../../commons/api/operations/create.js';
import destroy from '../../commons/api/operations/destroy.js';
import get from '../../commons/api/operations/get.js';
import update from '../../commons/api/operations/update.js';
import {debugLog} from '../../commons/utils/DebugLog.js';
import {noop} from '../../commons/utils/FunctionUtils.js';

const SESSION_BASE_URL = '/session';
const CURRENT_SESSION_URL = `${SESSION_BASE_URL}/current`;

/**
 * Creates a session for the given username and password and optional secondfactor token.
 *
 * @param {String} username
 * @param {String} password
 * @param {String} token - an optional secondfactor token
 * @returns {Promise} if the session can be created the payload of the promise is the id of the newly created session
 */
export function createSession(username, password, token = null) {
	const payload = {
		username,
		password
	};
	if (token) {
		payload.secondfactortoken = token;
	}
	return create(SESSION_BASE_URL, payload, noop);
}

/**
 * Retrieves information associated with the current session.
 * On the server side the current session is determined by the session-cookie.
 *
 * @returns {Promise} if the api call is successful the payload of the promise represents the session information.
 *  The session information consists of the current <li>username</li> and the <li>id></lid> of the session.
 */
export function getCurrentSession() {
	return get(CURRENT_SESSION_URL);
}

/**
 * Destroys the current session.
 * On the server side the current session is determined by the session-cookie.
 *
 * @returns {Promise} If successfully the promise is resolved with no payload.
 */
export async function destroyCurrentSession() {
	try {
		return await destroy(CURRENT_SESSION_URL);
	} catch (e) {
		// Error is swallowed, as the session obviously is no longer valid and can
		// no longer be used after the session cookie has been expired
		debugLog(`Failed to destroy session: ${e}`);
	}
	return undefined;
}

/**
 * Updates the current session with an empty JSON body to keep it alive.
 * @return {Promise} representing the update request.
 */
export function keepAlive() {
	return update(CURRENT_SESSION_URL, {});
}
