import React from 'react';
import PropTypes from 'prop-types';

import {memoWithName} from '../../../commons/utils/ReactUtils.js';

export default memoWithName(Footer);

function Footer(props) {
	const {title, className, children} = props;
	// JAWS need footer and role contentinfo
	return (
		<footer role='contentinfo' aria-label={title} className={className}>
			{children}
		</footer>
	);
}

Footer.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string
};
