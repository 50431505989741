import React from 'react';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import HangMatrixContainer from '../flux/containers/HangMatrixContainer.js';
import PreviewTileCaptionContainer from '../flux/containers/PreviewTileCaptionContainer.js';

import '../../../styles/patient-details/components/PreviewTileOverlay.scss';

export default class PreviewTileOverlay extends React.PureComponent {
	render() {
		const {item} = this.props;
		return (
			<VerticalLayout justify='start' noGrow className='preview-tile-overlay'>
				<HangMatrixContainer item={item} className='preview-tile-overlay--hang-matrix' />
				<PreviewTileCaptionContainer item={item} />
			</VerticalLayout>
		);
	}
}

PreviewTileOverlay.propTypes = {
	item: immutableMapPropType
};
