import {END} from 'redux-saga';
import {call, cancelled, put, take} from 'redux-saga/effects';

import {destroyCurrentSession, getCurrentSession} from '../api/SessionApi.js';
import {ATTACH_SESSION} from '../constants/SessionActionTypes.js';
import {SESSION_STATE_LOGGED_IN, SESSION_STATE_LOGGED_OUT} from '../constants/SessionReducerStates.js';
import {beginSession, beginSessionTransition, finishSessionTransition} from '../flux/actions/SessionActions.js';
import createNoSessionTask from './createNoSessionTask.js';

function* tryToAttachExistingSession() {
	try {
		yield* loadCurrentSession();
	} catch {
		yield put(finishSessionTransition(SESSION_STATE_LOGGED_OUT));
	}
}

function* loadCurrentSession() {
	yield put(beginSessionTransition(SESSION_STATE_LOGGED_IN));
	const sessionProperties = yield call(getCurrentSession);
	yield put(beginSession(sessionProperties));
	yield put(finishSessionTransition());
}

function* sessionAttachTaskImpl() {
	while (!(yield cancelled())) {
		const reattachAction = yield take(ATTACH_SESSION);
		if (reattachAction !== END) {
			try {
				yield* loadCurrentSession();
			} catch (error) {
				yield call(destroyCurrentSession);
				yield put(finishSessionTransition(error));
			}
		}
	}
}

const attachTask = createNoSessionTask(sessionAttachTaskImpl);

export default function* sessionAttachTask() {
	yield* tryToAttachExistingSession();
	yield* attachTask();
}
