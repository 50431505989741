import createDownloadURLFactory from './createDownloadUrlFactory.js';
import createRestSearchApiCall from './createRestSearchApiCall.js';

const DOCUMENT_BASE_URL = '/document';

const DATE_PARAMETERS = ['birth_date', 'document_created_when'];
const documentSearchApiCall = createRestSearchApiCall(DOCUMENT_BASE_URL, DATE_PARAMETERS);

export function searchDocuments(params, sortBy, start, fetchLimit, limit) {
	return documentSearchApiCall(params, sortBy, start, fetchLimit, limit);
}
export const getDocumentDownloadURL = createDownloadURLFactory(DOCUMENT_BASE_URL);
