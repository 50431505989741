import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../commons/containers/FormContainer.js';
import generateUniqueKey from '../../commons/utils/generateUniqueKey.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import PasswordFormField from './PasswordFormField';

export default class StepNewPassword extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.formId = `form${generateUniqueKey()}`;
	}

	render() {
		const {onSubmit, translator, passwordFormAccessors, passwordFormFields, username} = this.props;
		return (
			<React.Fragment>
				<VerticalLayout element={FormContainer} key='form' id={this.formId} className='new-password-form'
				                description={passwordFormAccessors} deleteFormDataOnUnmount onSubmit={onSubmit}>
					<SynFormattedMessage translator={translator} className='new-password--message'
					                     message='NewPasswordMessage' messageParams={{username}} />
					{passwordFormFields.map(StepNewPassword.renderPasswordField)}
				</VerticalLayout>
				<MaterialFormButton flat opaque key='submitButton' formDescription={passwordFormAccessors}
				                    form={this.formId} type='submit'>
					<SynFormattedMessage translator={translator} message='SetPasswordButtonLabel' />
				</MaterialFormButton>
			</React.Fragment>
		);
	}

	static renderPasswordField(fieldName, index) {
		return (
			<PasswordFormField autoFocus={index === 0}
			                     fieldName={fieldName}
			                     key={fieldName}
			                     autoComplete={fieldName === 'oldPassword' ? 'off' : 'new-password'} />
		);
	}
}

StepNewPassword.propTypes = {
	onSubmit: PropTypes.func,
	translator: PropTypes.object.isRequired,
	passwordFormAccessors: PropTypes.object.isRequired,
	passwordFormFields: PropTypes.arrayOf(PropTypes.string).isRequired,
	username: PropTypes.string.isRequired
};
