import React from 'react';
import PropTypes from 'prop-types';

import clickTracked from '../../metrics-collector/utils/clickTracked.js';
import {useMemoFactory} from '../utils/customHooks/index.js';
import {withForwardRef} from '../utils/ReactUtils.js';
import {capitalizeFirst} from '../utils/StringUtils.js';
import {combineClassNames} from '../utils/StyleUtils.js';
import {toRelativePath} from '../utils/URLUtils.js';

import '../../../styles/commons/components/Link.scss';

export default withForwardRef(DownloadLink, 'forwardedRef');

function DownloadLink(props) {
	const {className, href, forwardedRef, ...remainingProps} = props;
	const finalClassName = useMemoFactory(combineClassNames, 'link', className);
	const metricsKey = useMemoFactory(metricsKeyForLinkAddress, href);
	const ClickTrackedAnchor = useMemoFactory(clickTracked, 'a');
	return (
		<ClickTrackedAnchor ref={forwardedRef} metricsKey={metricsKey} href={href} className={finalClassName}
		                    usageContext='export' download='' {...remainingProps} />
	);
}

DownloadLink.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
	title: PropTypes.string,
	onClick: PropTypes.func,
	forwardedRef: withForwardRef.PropTypes.Ref
};

function firstPathSegment(path) {
	const relativePath = toRelativePath(path);
	return relativePath ? relativePath.split('/')[0] : '';
}

function metricsKeyForLinkAddress(href) {
	return `Download${capitalizeFirst(firstPathSegment(href))}`;
}
