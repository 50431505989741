import React from 'react';
import throttled from 'lodash.throttle';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';

const REPLACE_LOCATION_THROTTLE_TIME = 250;

export default class LocationValueSynchronizer extends React.Component {
	constructor(props, context) {
		super(props, context);
		const {replaceLocation} = this.props;
		this.updateThrottledReplace(replaceLocation);
		// Synchronize properties for the first time.
		this.synchronizeValue(this.props);
	}

	// Migration to a functional component is not worth the effort and might introduce bugs.
	// eslint-disable-next-line class-methods-use-this -- render() can not be made static.
	render() {
		return false;
	}

	updateThrottledReplace(newReplaceLocation) {
		const {replaceLocation} = this.props;
		if (replaceLocation !== newReplaceLocation || this.throttledReplaceLocation === undefined) {
			this.throttledReplaceLocation = throttled(newReplaceLocation, REPLACE_LOCATION_THROTTLE_TIME);
		}
	}

	shouldComponentUpdate(nextProps /*, nextState*/) {
		const {value} = this.props;
		this.updateThrottledReplace(nextProps.replaceLocation);
		if (nextProps.value !== value) {
			this.synchronizeValue(nextProps);
		}

		// This component should never update
		return false;
	}

	componentWillUnmount() {
		// Remove state from url
		this.removeValue();
	}

	synchronizeValue(props) {
		const {value, path} = props;
		this.throttledReplaceLocation(value, path);
	}

	removeValue() {
		const {path, replaceLocation} = this.props;
		this.throttledReplaceLocation.cancel();
		replaceLocation(null, path);
	}
}

LocationValueSynchronizer.propTypes = {
	path: PropTypes.arrayOf(PropTypes.string),
	replaceLocation: PropTypes.func,
	value: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.number,
		PropTypes.string
	])
};
