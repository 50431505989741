import React, {useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import {registerMetricsAction} from '../../../metrics-collector/flux/MetricsCollectorActions.js';
import {uiShowPrintLayout} from '../../../ui/flux/UISelectors.js';
import ImageViewer from '../../components/ImageViewer.js';
import ViewerContext from '../../components/ViewerContext.js';
import {DEFAULT_PAN, DEFAULT_ZOOM} from '../../constants/ImageViewerConstants.js';
import {createSelectWindowProperties} from '../selectors/ImageViewerSelectors.js';
import selectWithArgs from '../selectors/selectWithArgs.js';
import {selectSingleViewerProperty} from '../selectors/ViewerSelectors.js';

export default function ImageViewerContainer(props) {
	const {decodedImage} = props;
	const {viewerId, updateProperties} = useContext(ViewerContext);
	const renderPropertiesSelector = useMemo(() => createSelectRenderProps(viewerId), [viewerId]);
	const renderProperties = useSelector(selectWithArgs(renderPropertiesSelector, decodedImage));
	const dispatch = useDispatch();
	const toolActivationHandler = useMemo(() => tool => dispatch(registerMetricsAction(tool)), [dispatch]);
	const viewerCallbacks = useMemo(() => createViewerCallbacks(updateProperties), [updateProperties]);
	return (
		<ImageViewer onToolActivation={toolActivationHandler} {...renderProperties} {...viewerCallbacks} {...props} />
	);
}

ImageViewerContainer.propTypes = {
	decodedImage: immutableMapPropType
};


function createSelectRenderProps(viewerId) {
	const selectPan = selectSingleViewerProperty(viewerId, 'pan', DEFAULT_PAN);
	const selectZoom = selectSingleViewerProperty(viewerId, 'zoom', DEFAULT_ZOOM);

	const selectWindowProps = createSelectWindowProperties(viewerId);
	const selectWindowCenter = (state, decodedImage) => selectWindowProps(state, decodedImage).windowCenter;
	const selectWindowWidth = (state, decodedImage) => selectWindowProps(state, decodedImage).windowWidth;

	return createStructuredSelector({
		pan: selectPan,
		zoom: selectZoom,
		windowCenter: selectWindowCenter,
		windowWidth: selectWindowWidth,
		isPrintPreview: uiShowPrintLayout
	});
}

function createViewerCallbacks(updateHandler) {
	return {
		onPanZoom: (pan, zoom) => updateHandler({pan, zoom}),
		onWindowChange: (windowWidth, windowCenter) => updateHandler({windowWidth, windowCenter}),
		setPrintable: () => updateHandler({isPrintable: true}, false),
		setAnnotationVisibility: visible => updateHandler({showAnnotations: visible})
	};
}
