import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import {createSelector} from 'reselect';

import {pushLocation} from '../../router/flux/LocationActions.js';
import {getRouteMatcher} from '../../router/flux/selectors/RoutingSelectors.js';
import {toUrlString} from '../../router/LocationUtils.js';
import ConditionalLink from '../components/ConditionalLink.js';
import {immutableMapPropType} from '../utils/CustomPropTypes.js';
import {bindActions} from '../utils/FluxUtils.js';
import {noop} from '../utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';
import {withForwardRef} from '../utils/ReactUtils.js';

class LinkContainer extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			InternalLinkContainer: createInternalLinkContainer()
		};
	}

	render() {
		const {InternalLinkContainer} = this.state;
		return <InternalLinkContainer {...this.props} />;
	}
}

LinkContainer.propTypes = {
	...ConditionalLink.propTypes,
	path: PropTypes.string,
	query: immutableMapPropType,
	fragment: PropTypes.string,
	origin: PropTypes.string,
	forcedLocationUpdate: PropTypes.bool,
	onClick: PropTypes.func
};

function createInternalLinkContainer() {
	const linkRouteSelector = createSelector(
		getRouteMatcher,
		(state, props) => props.path || '', (routeMatcher, path) => routeMatcher(path)
	);

	const linkNotAllowed = {allowed: false};
	const linkAllowed = {allowed: true};

	function isLinkAllowedSelector(state, props) {
		const route = linkRouteSelector(state, props);
		return route !== null && route.get('linkConditionSelector')(state) ? linkAllowed : linkNotAllowed;
	}

	return connect(
		isLinkAllowedSelector,
		bindActions({pushLocation}),
		mergeLinkProps
	)(ConditionalLink);
}

function createOnClick(dispatchProps, ownProps, linkLocation) {
	const {onClick = noop, forcedLocationUpdate = false} = ownProps;
	return e => {
		onClick(e);
		dispatchProps.pushLocation(linkLocation, [], forcedLocationUpdate);
	};
}

function mergeLinkProps(stateProps, dispatchProps, ownProps) {
	const linkLocation = createLocation(ownProps);
	return {
		...stateProps,
		...cloneWithoutProperties(ownProps, 'forcedLocationUpdate'),
		onClick: createOnClick(dispatchProps, ownProps, linkLocation),
		href: toUrlString(linkLocation)
	};
}

function createLocation(props) {
	const {path = '', query: initialQuery = Immutable.Map(), fragment = '', origin = null} = props;
	let query = initialQuery;
	if (origin !== null) {
		query = query.set('origin', origin);
	}
	return Immutable.Map({path, query, fragment});
}

export default withForwardRef(LinkContainer, 'forwardedRef');
