import React from 'react';
import PropTypes from 'prop-types';

import MaterialDataTableConstants from '../../../constants/MaterialDataTableConstants.json';
import Patient from '../../../webview/commons/data/aim/Patient.js';
import IconButton from '../../../webview/ui/components/IconButton.js';
import DeleteIcon from '../../../webview/ui/components/icons/DeleteIcon.js';
import OtherPatientIndicator from '../OtherPatientIndicator.js';
import useFormattedEntry from '../useFormattedEntry.js';
import {DeleteColumn, NameColumn, PatientColumn, SizeColumn} from './Columns.js';
import {declareEntryHeight} from './FileList.js';

export default function DesktopListEntry(props) {
	const {entry, removeEntry, removeButtonTitle} = props;
	const {patient: patientFromEntry} = entry;
	const {name, patient, size} = useFormattedEntry(entry);
	return (
		<React.Fragment>
			<DeleteColumn>
				<IconButton title={removeButtonTitle} icon={<DeleteIcon />} onClick={removeEntry} />
			</DeleteColumn>
			<NameColumn>
				{name}
			</NameColumn>
			<PatientColumn>
				<OtherPatientIndicator patient={patientFromEntry}>
					&nbsp;
				</OtherPatientIndicator>
				{patient}
			</PatientColumn>
			<SizeColumn>
				{size}
			</SizeColumn>
		</React.Fragment>
	);
}
declareEntryHeight(DesktopListEntry, MaterialDataTableConstants['material-data-table']['row-min-height']);

DesktopListEntry.propTypes = {
	entry: PropTypes.shape({
		size: PropTypes.number,
		name: PropTypes.string,
		patient: PropTypes.instanceOf(Patient)
	}).isRequired,
	removeEntry: PropTypes.func,
	removeButtonTitle: PropTypes.string
};
