import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../../webview/commons/bricks/DeviceInfo.js';
import {useMemoFactory} from '../../../webview/commons/utils/customHooks';
import Divider from '../../../webview/ui/components/Divider.js';
import VerticalLayout from '../../../webview/ui/components/layout/VerticalLayout.js';
import UploadFilesCollection from '../../bricks/UploadFilesCollection.js';
import RestoreTrashToast from '../RestoreTrashToast.js';
import useGetFileInfo from '../useGetFileInfo.js';
import DesktopListEntry from './DesktopListEntry.js';
import DesktopListHeader from './DesktopListHeader.js';
import FileList from './FileList.js';
import MobileListEntry from './MobileListEntry.js';
import MobileListHeader from './MobileListHeader.js';

import '../../../styles/commons/components/data/DataTable.scss';
import '../../../styles/material-design/components/DataTable.scss';

export default function UploadFilesList(props) {
	const {visible} = props;
	const {
		getPreparedFiles, clearPreparedFiles, removePreparedFile, numberFiles, getPreparedFile, getPreparedFileSize,
		getPreparedContainerValue
	} = useBrick(UploadFilesCollection, selectUploadFilesBrickProps);
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const finalRemoveAllFiles = numberFiles > 0 ? clearPreparedFiles : null;
	const entryElement = isMobileDevice ? MobileListEntry : DesktopListEntry;
	const Header = isMobileDevice ? MobileListHeader : DesktopListHeader;
	const getFileInfo = useGetFileInfo(getPreparedFile, getPreparedFileSize, getPreparedContainerValue);
	const getFileInfoByIndex = useMemoFactory(createProcessByIndex, getPreparedFiles, getFileInfo);
	const removeFileByIndex = useMemoFactory(createProcessByIndex, getPreparedFiles, removePreparedFile);
	return (
		<VerticalLayout justifyContent='start' className='data-table material-data-table remove-padding'
		                sx={{'&.remove-padding': {p: 0}}} hidden={!visible}>
			<Header removeAll={finalRemoveAllFiles} />
			<Divider />
			<FileList nrFiles={getPreparedFiles().size} entryElementType={entryElement}
			          getFileInfo={getFileInfoByIndex} removeFile={removeFileByIndex} />
			<RestoreTrashToast />
		</VerticalLayout>
	);
}

UploadFilesList.propTypes = {
	visible: PropTypes.bool
};

UploadFilesList.defaultProps = {
	visible: true
};

function selectUploadFilesBrickProps(service) {
	return {
		getPreparedFiles: service.getPreparedFiles,
		clearPreparedFiles: service.clearPreparedFiles,
		removePreparedFile: service.removePreparedFile,
		numberFiles: service.getPreparedFilesCount(),
		getPreparedFile: service.getPreparedFile,
		getPreparedFileSize: service.getPreparedFileSize,
		getPreparedContainerValue: service.getPreparedContainerValue
	};
}

function createProcessByIndex(getFileNames, processor) {
	return function getFileByIndex(index) {
		return processor(getFileNames().get(index));
	};
}
