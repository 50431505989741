import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import ViewerMatrix from '../../components/ViewerMatrix.js';
import {getViewerLayout} from '../selectors/ViewerSelectors.js';

export default connect(
	createSelector(getViewerLayout, layout => ({
		rows: layout.get('rows'),
		columns: layout.get('columns')
	})),
	undefined,
	(stateProps, _, ownProps) => ({
		...stateProps,
		...ownProps
	})
)(ViewerMatrix);
