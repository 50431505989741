import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';

import {recordError} from '../actions/UnhandledErrorsActions.js';
import ErrorBoundary from './errors/ErrorBoundary.js';
import UnhandledApplicationErrorPage from './UnhandledApplicationErrorPage.js';

export default function ApplicationErrorBoundary({children}) {
	const dispatch = useDispatch();
	const onError = useCallback(error => {
		dispatch(recordError(error));
	}, [dispatch]);
	return (
		<ErrorBoundary onError={onError} errorRenderer={UnhandledApplicationErrorPage}>
			{children}
		</ErrorBoundary>
	);
}
