export function createSchedulingFunction(schedule, clear) {
	return function schedulingFunction(callback, delay) {
		let timerId = schedule(callback, delay);

		return function clearTimer() {
			const timerToCancel = timerId;
			timerId = null;
			if (timerToCancel !== null) {
				clear(timerToCancel);
			}
		};
	};
}

export const scheduleTimerOnce = createSchedulingFunction(setTimeout, clearTimeout);
export const scheduleTimerRepeated = createSchedulingFunction(setInterval, clearInterval);
