import {SYN_ADVANCED_JPEG, SYN_ADVANCED_PNG} from '../../constants/MimeTypes.js';
import {USE_LOSSLESS_IMAGE_COMPRESSION} from '../../constants/SynSettingsConstants.js';
import {decodeAdvancedImageFromBlob} from '../../data/aim/SynAdvancedImage.js';
import ImageLoadError from '../ImageLoadError.js';
import getBlob from './getBlob.js';

const DEFAULT_REQUEST_TYPE = USE_LOSSLESS_IMAGE_COMPRESSION ? SYN_ADVANCED_PNG : SYN_ADVANCED_JPEG;

export default function getSynAdvancedImage(url, loadParams = {}, imageMimeType = DEFAULT_REQUEST_TYPE) {
	return getBlob(url, loadParams, imageMimeType)
		.then(decodeAdvancedImageFromBlob)
		.catch(error => {
			throw new ImageLoadError(`Failed to load SynAdvancedImage.${error}`, url);
		});
}
