import {default as updateOperation} from '../../commons/api/operations/update.js';

const PASSWORD_BASE_URL = '/password';

function createUpdateUrl(username) {
	return `${PASSWORD_BASE_URL}/${username}`;
}

/**
 * Changes the password for the given user.
 * @param userName {String} the username for which to change the password
 * @param oldPassword {String} the currently used password
 * @param newPassword {String} the new password to set
 * @param newPasswordConfirmation {String} confirmation of the new password
 */
export function changePassword(userName, oldPassword, newPassword, newPasswordConfirmation, secondFactorToken) {
	const payload = {
		oldpassword: oldPassword,
		password: newPassword,
		passwordconfirmation: newPasswordConfirmation
	};
	setOptionalToken(payload, 'secondfactortoken', secondFactorToken);
	return updateOperation(createUpdateUrl(encodeURIComponent(userName)), payload);
}

export function setNewPassword(userName, newPassword, newPasswordConfirmation, changePasswordToken, secondFactorToken) {
	const payload = {
		password: newPassword,
		passwordconfirmation: newPasswordConfirmation
	};
	setOptionalToken(payload, 'changepasswordtoken', changePasswordToken);
	setOptionalToken(payload, 'secondfactortoken', secondFactorToken);
	return updateOperation(createUpdateUrl(encodeURIComponent(userName)), payload);
}

function setOptionalToken(container, tokenName, tokenValue) {
	if (tokenValue) {
		container[tokenName] = tokenValue;
	}
}
