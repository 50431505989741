import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../../i18n/components/SynFormattedMessage.js';
import DownloadOptionsMessagesTranslator from '../../../../i18n/translators/DownloadOptionsMessagesTranslator.js';
import Title from '../../../../material-design/components/Title.js';
import {DEVICE_SIZE_SMALL} from '../../../constants/DeviceInformation.js';
import AppBar from '../../AppBar.js';
import AppBarPrimaryContent from '../../AppBarPrimaryContent.js';
import AppBarSidebarHeader from '../AppBarSidebarHeader.js';

function getPateTitleMessage({itemType, downloadForView, deviceSize}) {
	let message = `Download${itemType}`;
	if (deviceSize !== DEVICE_SIZE_SMALL && downloadForView) {
		message = `${message}ForView`;
	}
	return message;
}

export default function DownloadAppBar(props) {
	const {sidebarElevated} = props;
	const appBarProps = {};
	if (!sidebarElevated) {
		appBarProps.menuButton = false;
	}
	return (
		<AppBar {...props}>
			<AppBarPrimaryContent {...appBarProps}>
				<Title>
					<SynFormattedMessage translator={DownloadOptionsMessagesTranslator}
												message={getPateTitleMessage(props)} />
				</Title>
			</AppBarPrimaryContent>
			<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
		</AppBar>
	);
}

DownloadAppBar.propTypes = {
	sidebarElevated: PropTypes.bool
};
