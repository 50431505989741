import React from 'react';
import {useSelector} from 'react-redux';

import Sidebar from '../../material-design/components/SideBar.js';
import {toggleSidebar} from '../../ui/flux/UIActions.js';
import {
	uiIsSidebarVisible,
	uiSidebarModeElevatedSelector,
	uiSidebarTransitionsEnabled
} from '../../ui/flux/UISelectors.js';
import {useAction} from '../flux/ReactHooks.js';

export default function SidebarContainer(props) {
	const isVisible = useSelector(uiIsSidebarVisible);
	const disableTransitions = !useSelector(uiSidebarTransitionsEnabled);
	const sidebarElevated = useSelector(uiSidebarModeElevatedSelector);
	const boundToggleSidebar = useAction(toggleSidebar);

	return (
		<Sidebar isVisible={isVisible}
			disableTransitions={disableTransitions}
			sidebarElevated={sidebarElevated}
			backgroundClicked={boundToggleSidebar} {...props} />
	);
}
