import {deleteStructure, resetStructure, updateField} from '../actions/StructuredDataActions.js';
import {createFieldSelector, createStructureSelector} from '../selectors/StructuredDataSelectors.js';
import generateUniqueKey from '../utils/generateUniqueKey.js';
import {mapProperties} from '../utils/ObjectUtils';

const STRUCTURE_ID_PREFIX = '$$STRUCTURE_ID$$';

/** Generates selectors and action creators for the passed fieldMetaData.
 * The fieldMetaData must be an object, structured as follows:
 *
 * metaData = '{' FieldDefinition [, FieldDefinition] '}'
 * FieldDefinition = FieldLabel ':{' [ DefaultValueDefinition ] '}'
 * FieldLabel: Valid JSON field name
 * DefaultValueDefinition: 'defaultValue:' DefaultValue
 * DefaultValue: Valid JSON Value expression
 *
 * Example:
 * let metaData = {
 * 	field1: {
 * 	},
 * 	field2: {
 * 		defaultValue: 1
 * 	}
 * }
 *
 * The factory function generates an object offering the following functions and fields:
 *  - get( state )
 *  		a selector for the whole structure storing the definitions data as a Immutable.Map instance
 *  - update( newValue )
 *  		returns an update action that replaces the whole underlying structure with the new value
 *  - reset()
 *  		clears the underlying structure
 *  - fieldSelectors
 *  		An object containing one selector for each specified field
 *  - getFieldValue(state, fieldName)
 *  		a selector function returning the specified fields value from the state
 *  - updateFieldValue(fieldName, value)
 *  		returns a FSA updating the value of the specified field with the new value
 *
 * Through these methods it is quite easy to connect to field-changes:
 * connect( (state) => { value1: dataAccessors.getFieldValue(state,'field1') } )( YourComponent )
 *
 * @param fieldMetaData {Object} the field specification (see description above)
 * @param structureId {String} optionally the unique id to use to identify the underlying structured data
 * @returns {Object} structured as described above
 */
export default function createDataAccessors(fieldMetaData, structureId) {
	const thisStructureId = structureId || STRUCTURE_ID_PREFIX + generateUniqueKey();
	const dataStructureSelector = createStructureSelector(thisStructureId);
	const fieldSelectors = mapProperties(
		fieldMetaData,
		(value, fieldName) => createFieldSelector(dataStructureSelector, fieldName, value.defaultValue)
	);

	return {
		get: dataStructureSelector,
		update: newValue => resetStructure(thisStructureId, newValue),
		reset: () => resetStructure(thisStructureId),
		delete: () => deleteStructure(thisStructureId),

		fieldSelectors,
		getFieldValue: (state, fieldName) => fieldSelectors[fieldName](state),
		updateFieldValue: (fieldName, value) => updateField(thisStructureId, fieldName, value)
	};
}
