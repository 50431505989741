import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../i18n/translators/ViewerTranslator.js';
import PdfView from './pdf/PdfView.js';
import PdfLoader from './PdfLoader.js';
import ViewerContext from './ViewerContext.js';

import '../../../styles/viewer/components/PdfViewer.scss';

class CDAView extends React.PureComponent {
	render() {
		const {nonXMLBody} = this.props;
		const {isPrintPreview} = this.context;
		return isPrintPreview
			? CDAView.renderPrintingNotSupported()
			: CDAView.renderCDAnonXMLBody(nonXMLBody);
	}

	static renderPrintingNotSupported() {
		return (
			<CenterLayout className='print-message'>
				<SynFormattedMessage translator={ViewerTranslator} message='PrintingCDANotSupported' />
			</CenterLayout>
		);
	}

	static renderCDAnonXMLBody(nonXMLBody) {
		return (
			<PdfLoader pdfFile={nonXMLBody}>
				<PdfView />
			</PdfLoader>
		);
	}
}

CDAView.contextType = ViewerContext;
CDAView.propTypes = {
	nonXMLBody: PropTypes.object.isRequired
};

export default CDAView;
