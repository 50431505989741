export const DEGREES_OF_HALF_CIRCLE = 180.0;
export const DEFAULT_EQUALITY_THRESHOLD = 1.0e-8;
export const DECIMAL_BASE = 10;
const ONE_FOURTH = 0.25;
export const RAD_45_DEGREES = Math.PI * ONE_FOURTH;
export const RAD_90_DEGREES = Math.PI / 2;
export const RAD_180_DEGREES = Math.PI;
const THREE_HALF = 1.5;
export const RAD_270_DEGREES = Math.PI * THREE_HALF;
export const RAD_360_DEGREES = Math.PI * 2;

export function round(numberToRound, precision) {
	const shift = Math.pow(DECIMAL_BASE, precision);
	const shiftedValue = numberToRound * shift;
	const shiftedRoundedValue = Math.round(shiftedValue);
	return shiftedRoundedValue / shift;
}

export function deg2rad(degrees) {
	return (degrees * Math.PI) / DEGREES_OF_HALF_CIRCLE;
}

export function rad2deg(radians) {
	return (radians * DEGREES_OF_HALF_CIRCLE) / Math.PI;
}

export function isCloseTo(a, b, threshold = DEFAULT_EQUALITY_THRESHOLD) {
	return Math.abs(a - b) <= threshold;
}

export function toScientificNotation(number) {
	const exponent = Math.floor(Math.log10(Math.abs(number)));
	const mantisse = number / Math.pow(DECIMAL_BASE, exponent);
	return {
		mantisse,
		exponent
	};
}

export function getMedian(...numberValues) {
	const numbersLength = numberValues.length;
	if (numbersLength < 2 || numberValues.some(number => typeof number !== 'number' || Number.isNaN(number))) {
		return NaN;
	}
	numberValues.sort((a, b) => a - b);
	const halfPosition = Math.floor(numbersLength / 2);
	if (numbersLength % 2 === 1) {
		return numberValues[halfPosition];
	}
	return (numberValues[halfPosition - 1] + numberValues[halfPosition]) / 2.0;
}
