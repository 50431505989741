/**
 * Helper class to build necessary context fields to upload a file.
 *
 * @author p.spitzlinger@synedra.com
 */
export class UploadContextBuilder {
	constructor(initialContext = {}) {
		this.context = initialContext;
	}

	setProducerAbk(abk) {
		return withField(this, 'producer', abk);
	}

	setPatientAimId(aimId) {
		return withField(this, 'patientAimId', aimId);
	}

	setVisitId(aimId) {
		return withField(this, 'visitAimId', aimId);
	}

	setDocumentDescription(description) {
		return withField(this, 'documentDescription', description);
	}

	setDocumentClassId(id) {
		return withField(this, 'documentClassId', id);
	}

	setGenericDocumentCreatedWhenToNow() {
		return this.setGenericDocumentCreatedWhen(new Date());
	}

	setGenericDocumentCreatedWhen(creationDate) {
		return withField(this, 'genericDocumentCreatedWhen', creationDate);
	}

	build() {
		return {...this.context};
	}
}

function withField(builder, name, value) {
	const nextContext = builder.build();
	nextContext[name] = value;
	return new UploadContextBuilder(nextContext);
}
