import React from 'react';
import {Portal} from 'react-portal';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../utils/CustomPropTypes.js';
import {callSafe} from '../utils/FunctionUtils.js';
import BoundingRectMeasurer from './BoundingRectMeasurer.js';
import FullScreenWrapper from './FullScreenWrapper.js';

export default class FullScreenContainer extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnClose = this.onClose.bind(this);
		this.boundUpdateBoundingRect = this.onBoundingRectUpdated.bind(this);

		const {visible} = this.props;
		this.state = {
			visible,
			boundingRect: null
		};
	}

	render() {
		const {visible} = this.state;
		return visible && this.renderVisible();
	}

	renderVisible() {
		const {boundingRect} = this.state;
		return boundingRect === null ? this.renderMeasureContainer() : this.renderFullscreenContainer();
	}

	renderMeasureContainer() {
		const {windowSize} = this.props;
		return (
			<BoundingRectMeasurer onBoundingRectUpdated={this.boundUpdateBoundingRect} windowSize={windowSize} />
		);
	}

	renderFullscreenContainer() {
		const {visible, className, children, windowSize} = this.props;
		const {boundingRect} = this.state;
		return (
			<BoundingRectMeasurer onBoundingRectUpdated={this.boundUpdateBoundingRect} windowSize={windowSize}>
				<Portal isOpen>
					<FullScreenWrapper onClose={this.boundOnClose} visible={visible} className={className}
											 sourceBoundingRect={boundingRect}
											 targetBoundingRect={{
												 left: 0,
												 top: 0,
												 width: window.innerWidth,
												 height: window.innerHeight
											 }}>
						{children}
					</FullScreenWrapper>
				</Portal>
			</BoundingRectMeasurer>
		);
	}

	onClose() {
		this.setState({
			visible: false,
			boundingRect: null
		});
		const {onClose} = this.props;
		callSafe(onClose);
	}

	onBoundingRectUpdated(boundingRect) {
		this.setState({boundingRect});
	}

	static getDerivedStateFromProps(props, state) {
		const {visible} = props;
		const {visible: stateVisible, boundingRect} = state;

		let derivedState = null;
		if (visible === true && stateVisible === false) {
			derivedState = {visible};
		}
		if (stateVisible && visible === false && boundingRect === null) {
			derivedState = {visible: false};
		}
		return derivedState;
	}
}

FullScreenContainer.propTypes = {
	visible: PropTypes.bool,
	onClose: PropTypes.func,
	className: PropTypes.string,
	windowSize: immutableMapPropType
};

FullScreenContainer.defaultProps = {
	visible: false
};
