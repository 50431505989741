import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import VirtualizedList from '../../../webview/commons/components/data/VirtualizedList.js';
import {useMemoFactory} from '../../../webview/commons/utils/customHooks';
import useTranslation from '../../../webview/i18n/hooks/useTranslation.js';
import ListContainer from '../../../webview/ui/components/List/ListContainer.js';
import ListRow from '../../../webview/ui/components/List/ListRow.js';

const ENTRY_HEIGHT_FIELD = Symbol('entry-height');
export function declareEntryHeight(EntryConstructor, height) {
	EntryConstructor[ENTRY_HEIGHT_FIELD] = height;
}

export default function FileList(props) {
	const {nrFiles, getFileInfo, removeFile, entryElementType} = props;
	const entryHeight = entryElementType[ENTRY_HEIGHT_FIELD];
	const boundRenderListEntry = useMemoFactory(createListEntryRenderer, getFileInfo, removeFile, entryElementType);
	return (
		<ListContainer className='table-body'>
			<VirtualizedList overscanCount={5} itemCount={nrFiles} itemSize={entryHeight}>
				{boundRenderListEntry}
			</VirtualizedList>
		</ListContainer>
	);
}

FileList.propTypes = {
	nrFiles: PropTypes.number.isRequired,
	getFileInfo: PropTypes.func.isRequired,
	removeFile: PropTypes.func.isRequired,
	entryElementType: PropTypes.elementType.isRequired
};

function createListEntryRenderer(getFileInfo, removeFile, EntryElement) {
	return function ListEntryRenderer(listProps) {
		const {index, style} = listProps;
		const file = getFileInfo(index);
		const boundRemoveEntry = useCallback(() => removeFile(index), [index]);
		const removeButtonTitle = useTranslation('RemoveButtonTitle');
		return (
			<ListRow style={style}>
				<EntryElement entry={file} index={index}
								  removeEntry={boundRemoveEntry} removeButtonTitle={removeButtonTitle} />
			</ListRow>
		);
	};
}
