import React from 'react';
import PropTypes from 'prop-types';

import useTranslation from '../../../i18n/hooks/useTranslation.js';
import GeneralMessagesTranslator from '../../../i18n/translators/GeneralTranslator.js';
import Spinner from '../../../ui/components/Spinner.js';
import {noop} from '../../utils/FunctionUtils.js';
import Delayed from '../Delayed.js';
import CenterLayout from '../layout/CenterLayout.js';

export default class DataGuard extends React.PureComponent {
	render() {
		const {indicatorDelayMS, children, loadingIndicator, data} = this.props;
		let content = false;
		if (React.Children.count(children) > 0) {
			if (data) {
				content = children;
			} else {
				content = (
					<Delayed delayMS={indicatorDelayMS}>
						{loadingIndicator}
					</Delayed>
				);
			}
		}
		return content;
	}

	componentDidMount() {
		this.checkedLoad();
	}

	componentDidUpdate() {
		this.checkedLoad();
	}

	checkedLoad() {
		const {data, doReload, isLoadNeeded, load} = this.props;
		const dataAvailable = Boolean(data);
		const loadFunctionAvailable = Boolean(load);
		const shouldLoad = isLoadNeeded && loadFunctionAvailable && (!dataAvailable || doReload);
		if (shouldLoad) {
			load();
		}
	}
}

DataGuard.propTypes = {
	indicatorDelayMS: PropTypes.number,
	data: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]),
	isLoadNeeded: PropTypes.bool,
	doReload: PropTypes.bool,
	load: PropTypes.func,
	loadingIndicator: PropTypes.node
};

DataGuard.defaultProps = {
	data: null,
	isLoadNeeded: false,
	doReload: false,
	load: noop,
	loadingIndicator: <CenteredSpinner />,
	indicatorDelayMS: 150
};

function CenteredSpinner() {
	const spinnerDescription = useTranslation('LoadingSpinnerDescription', {}, GeneralMessagesTranslator);
	return (
		<CenterLayout fitParent>
			<Spinner description={spinnerDescription} />
		</CenterLayout>
	);
}
