import {createSelector} from 'reselect';

import {
	RESOURCE_DATA,
	RESOURCE_DATA_PARAMS,
	RESOURCE_DATA_RESPONSE,
	RESOURCE_STATE
} from '../../constants/synCrud/FieldNames.js';
import {RESOURCE_STATE_LOADING, RESOURCE_STATE_UNINITIALIZED} from '../../constants/synCrud/States.js';

const DEFAULT_RESOURCE_STATE = RESOURCE_STATE_UNINITIALIZED;

export default function createCrudResourceApiSelectors() {
	function createDataFieldSelector(fieldName, defaultValue = null) {
		return store => store.getIn([RESOURCE_DATA, fieldName], defaultValue);
	}
	const getRawResponse = createDataFieldSelector(RESOURCE_DATA_RESPONSE);
	const getRequestParams = createDataFieldSelector(RESOURCE_DATA_PARAMS);
	const getState = store => store.get(RESOURCE_STATE, DEFAULT_RESOURCE_STATE);
	const isLoadInProgress = createSelector(
		getState,
		state => state === RESOURCE_STATE_LOADING
	);
	const getResponse = createSelector(
		getRawResponse, isLoadInProgress,
		(rawResponse, loading) => !loading && rawResponse || null
	);

	return {
		getRawResponse,
		getResponse,
		getState,
		isLoadInProgress,
		getRequestParams
	};
}
