import {put} from 'redux-saga/effects';

import {collectDeviceMetrics} from '../../metrics-collector/utils/collectDeviceMetrics.js';
import createSessionTask from './createSessionTask.js';

function* collectDeviceMetricsTaskImpl() {
	yield put(collectDeviceMetrics);
}

export default createSessionTask(collectDeviceMetricsTaskImpl);
