import React from 'react';
import PropTypes from 'prop-types';

import SubtextLink from '../../commons/components/SubtextLink.js';
import LoadingIndicatorOverlay from '../../commons/components/webview/LoadingIndicatorOverlay.js';
import {PASSWORDRESET_PATH} from '../../commons/constants/CommonRoutingConstants.js';
import {WEB_PATIENT_USER_FORBIDDEN} from '../../commons/constants/ResourceErrorStatus.js';
import SubtextLinkContainer from '../../commons/containers/SubtextLinkContainer.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import HorizontalLayout from '../../ui/components/layout/HorizontalLayout.js';
import {SESSION_STATE_LOGGED_OUT, SESSION_STATE_REQUEST_CREDENTIALS} from '../constants/SessionReducerStates.js';
import StepEnterCredentialsContainer from '../flux/containers/StepEnterCredentialsContainer.js';
import StepEnterSecondFactorContainer from '../flux/containers/StepEnterSecondFactorContainer.js';
import LoginPageMessagesTranslator from '../i18n/LoginPageMessagesTranslator.js';
import StepLoginError from './StepLoginError.js';
import StepPatientUserLoginError from './StepPatientUserLoginError.js';

export default class LoginWorkflowController extends React.Component {
	render() {
		const {isBusy, error, registerNewUserHandler} = this.props;
		let showPasswordResetLink = true;
		if (error?.code === WEB_PATIENT_USER_FORBIDDEN) {
			showPasswordResetLink = false;
		}
		return (
			<React.Fragment>
				{this.getContent()}
				<HorizontalLayout>
					{showPasswordResetLink &&
						<SubtextLinkContainer path={PASSWORDRESET_PATH}>
							<SynFormattedMessage message='ForgotPasswordQuestion' translator={LoginPageMessagesTranslator} />
						</SubtextLinkContainer>
					}
					{registerNewUserHandler && (
						<SubtextLink onClick={registerNewUserHandler}>
							<SynFormattedMessage message='RegisterNewUser' translator={LoginPageMessagesTranslator} />
						</SubtextLink>
					)}
				</HorizontalLayout>
				<LoadingIndicatorOverlay loading={isBusy} />
			</React.Fragment>
		);
	}

	renderCredentialsForm() {
		return <StepEnterCredentialsContainer {...this.props} />;
	}

	renderSecondFactorForm() {
		return <StepEnterSecondFactorContainer {...this.props} />;
	}

	renderError(error) {
		let ErrorComponent = StepLoginError;
		if (error?.code === WEB_PATIENT_USER_FORBIDDEN) {
			ErrorComponent = StepPatientUserLoginError;
		}
		return <ErrorComponent {...this.props} />;
	}

	getContent() {
		let content;
		const {workflowState, error, currentTransition} = this.props;
		switch (workflowState) {
			case SESSION_STATE_REQUEST_CREDENTIALS:
				content = this.renderSecondFactorForm();
				break;
			case SESSION_STATE_LOGGED_OUT:
			default:
				if (error && currentTransition) {
					content = this.renderError(error);
				} else {
					content = this.renderCredentialsForm();
				}
				break;
		}

		return content;
	}
}

LoginWorkflowController.propTypes = {
	isBusy: PropTypes.bool,
	workflowState: PropTypes.string,
	error: PropTypes.instanceOf(Error),
	currentTransition: PropTypes.string,
	registerNewUserHandler: PropTypes.func
};

LoginWorkflowController.defaultProps = {
	isBusy: false,
	error: null,
	workflowState: SESSION_STATE_LOGGED_OUT,
	currentTransition: null
};
