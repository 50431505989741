import React from 'react';

import createCenteredSVG from '../../createCenteredSVG.js';

const DEFAULT_SIZE = 24;

// path element constructed from material-design-icons/editor/ic_mode_edit_24.svg
export default createCenteredSVG(DEFAULT_SIZE, DEFAULT_SIZE,
	<path d='M 12,0 A 12,12 0 0 0 0,12 12,12 0 0 0 12,24 12,12 0 0 0 24,12 12,12 0 0 0 12,0 Z m 4.552734,4.765625 c 0.204941,0 0.409688,0.077656 0.566407,0.234375 L 19,6.8808594 c 0.313438,0.3134379 0.313438,0.8193746 0,1.1328125 L 17.529297,9.484375 14.515625,6.4707031 15.986328,5 C 16.143047,4.843281 16.347794,4.765625 16.552734,4.765625 Z M 13.65625,7.3300781 16.669922,10.34375 7.78125,19.232422 H 4.7675781 V 16.21875 Z' />
);
