import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {number} from 'prop-types';
import {createStructuredSelector} from 'reselect';

import {currentLocale} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {uiIsSmallDevice} from '../../../ui/flux/UISelectors.js';
import GenericFileMetaDataOverlay from '../../components/GenericFileMetaDataOverlay.js';
import ViewerContext from '../../components/ViewerContext.js';
import {createGenericFileMetadataSelector} from '../selectors/MetadataSelectors.js';

function createStatePropsSelector(fileId) {
	return createStructuredSelector({
		isSmallDevice: uiIsSmallDevice,
		locale: currentLocale,
		imageMetadata: createGenericFileMetadataSelector(fileId)
	});
}

function GenericFileMetaDataOverlayContainer(props) {
	const {isFullScreen} = useContext(ViewerContext);
	const {fileId, containerWidth, containerHeight} = props;
	const selector = useMemo(() => createStatePropsSelector(fileId), [fileId]);
	const stateProps = useSelector(selector);

	return (<GenericFileMetaDataOverlay isFullScreen={isFullScreen}
									   containerWidth={containerWidth}
									   containerHeight={containerHeight}
									   {...stateProps} />);
}


GenericFileMetaDataOverlayContainer.propTypes = {
	fileId: number.isRequired,
	containerWidth: number.isRequired,
	containerHeight: number.isRequired
};

export default GenericFileMetaDataOverlayContainer;
