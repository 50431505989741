/* eslint-disable no-magic-numbers -- The syntax should match the one found in ResourceErrorStatus.java. It makes no
sense to add 20000 - 20025 to be ignored by no-magic-numbers */

function createErrorStatus(code, category) {
	return `${category.toLowerCase()}${code}`;
}

// NOTE: Keep this file in sync with ResourceErrorStatus.java of java lib Logging!
export const CONTROL_STRING_TOO_LONG = createErrorStatus(20000, 'control');
export const CONTROL_UNDEFINED_PARENT = createErrorStatus(20001, 'control');
export const CONTROL_MAX_VALUE = createErrorStatus(20002, 'control');
export const CONTROL_MIN_VALUE = createErrorStatus(20003, 'control');
export const CONTROL_DUPLICATE_VALUE = createErrorStatus(20004, 'control');
export const CONTROL_MISSING_VALUE = createErrorStatus(20005, 'control');
export const CONTROL_OPERATION_NOT_ALLOWED_ON_KEYWORD_GROUP = createErrorStatus(20006, 'control');
export const AUTH_AUTHENTICATION_FAILED = createErrorStatus(20007, 'auth');
export const AUTH_INVALID_CREDENTIALS = createErrorStatus(20008, 'auth');
export const AUTH_PASSWORD_EXPIRED = createErrorStatus(20009, 'auth');
export const AUTH_INVALID_SESSION = createErrorStatus(20010, 'auth');
export const DIST_INCOMPLETE_FILE = createErrorStatus(20011, 'dist');
export const DIST_INVALID_BINARY = createErrorStatus(20012, 'dist');
export const CONTROL_INVALID_SQL = createErrorStatus(20013, 'control');
export const CONTROL_STORAGE_RULE_MUST_HAVE_MASTER = createErrorStatus(20014, 'control');
export const CONTROL_STORAGE_RULE_SINGLE_REPLICA_NODE_IN_MASTER_POOL = createErrorStatus(20015, 'control');
export const CONTROL_STORAGE_RULE_DELETE_NODE_IN_MASTER_OR_REPLICA_POOL = createErrorStatus(20016, 'control');
export const AUTH_2ND_FACTOR_REQUIRED = createErrorStatus(20017, 'auth');
export const AUTH_WEAK_PASSWORD = createErrorStatus(20018, 'auth');
export const TIMETRACK_UNCLEARED_EVENTS_BEFORE = createErrorStatus(20019, 'timetrack');
export const TIMETRACK_ALREADY_CLEARED = createErrorStatus(20020, 'timetrack');
export const AUTH_2ND_FACTOR_CREATION_FAILED = createErrorStatus(20021, 'auth');
export const XDS_MISSING_AUTHENTICATION_TOKEN = createErrorStatus(20022, 'xds');
export const QA_WRONG_DATE_FORMAT_TAG = createErrorStatus(20023, 'qa');
export const TIMETRACK_EMPLOYEE_LOCKED = createErrorStatus(20024, 'timetrack');
export const WEB_PATIENT_USER_FORBIDDEN = createErrorStatus(20025, 'web');
export const CONTROL_ARCHIVE_NODE_NO_TOTAL = createErrorStatus(20026, 'control');
