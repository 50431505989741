import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {currentLocale} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {debugWarn} from '../../../commons/utils/DebugLog.js';
import {getAnnotationComponent} from '../../components/annotations.js';
import ViewerContext from '../../components/ViewerContext.js';
import {
	createAnnotationFontSizeSelector,
	createAnnotationLineHeightSelector,
	createAnnotationPropertiesSelector,
	createAnnotationTypeSelector
} from '../selectors/AnnotationSelectors.js';
import selectWithArgs from '../selectors/selectWithArgs.js';

function createStateSelector(viewerId, annotationId) {
	const annotationPropertiesSelector = createAnnotationPropertiesSelector(annotationId);
	const annotationTypeSelector = createAnnotationTypeSelector(annotationId);
	const fontSizeSelector = createAnnotationFontSizeSelector(viewerId);
	const lineHeightSelector = createAnnotationLineHeightSelector(fontSizeSelector);
	return createStructuredSelector({
		annotationProperties: annotationPropertiesSelector,
		annotationType: annotationTypeSelector,
		locale: currentLocale,
		fontSize: fontSizeSelector,
		lineHeight: lineHeightSelector
	});
}

export default function AnnotationContainer(passedProps) {
	const {annotationId} = passedProps;
	const {viewerId} = useContext(ViewerContext);
	const stateSelector = useMemo(() => createStateSelector(viewerId, annotationId), [viewerId, annotationId]);
	const stateProps = useSelector(selectWithArgs(stateSelector, annotationId, viewerId));
	const AnnotationComponent = getAnnotationComponent(stateProps.annotationType);
	if (AnnotationComponent === null) {
		debugWarn(`Unable to handle annotation of type '${stateProps.annotationType}'.`);
		return null;
	}
	return <AnnotationComponent {...passedProps} {...stateProps} />;
}
