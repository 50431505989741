import React from 'react';

import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import FlexLayoutBase from './FlexLayoutBase.js';

function VerticalLayout(props) {
	return <FlexLayoutBase direction='column' {...props} />;
}

VerticalLayout.propTypes = cloneWithoutProperties(FlexLayoutBase.propTypes, 'direction');
VerticalLayout.propTypes.forwardRef = withForwardRef.PropTypes.Ref;
VerticalLayout.defaultProps = cloneWithoutProperties(FlexLayoutBase.defaultProps, 'direction');

export default withForwardRef(VerticalLayout, 'forwardRef');
