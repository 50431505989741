import React, {useCallback, useState} from 'react';
import MuiAutocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import ListSubheader from '@mui/material/ListSubheader';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {callSafe, identity} from '../../commons/utils/FunctionUtils.js';
import DefaultAutoCompleteTextField from './AutoCompleteTextField/DefaultAutoCompleteTextField.js';
import FixedVirtualizedOptionsList from './AutoCompleteTextField/FixedVirtualizedOptionsList.js';
import createAutoCompleteTextField from './createAutoCompleteTextField.js';
import Ellipsis from './typography/Ellipsis';

export {autocompleteClasses};
export default function AutoComplete(props) {
	const {
		id, lockToOptions, value = '', getOptionLabel, groupBy, getOptionSelected, onChange, options, disabled,
		getSelectedValue, getValue, name, disableClearable, noFullWidth, disablePortal, forcePopupIcon, open, classes,
		listComponent, popperComponent, paperComponent, onClose, renderOption, sx
	} = props;
	const [inputValue, setInputValue] = useState('');
	const handleOnChange = useCallback((event, newValue, reason) => {
		callSafe(onChange, getSelectedValue(newValue), reason);
	}, [onChange, getSelectedValue]);
	const renderTextField = useMemoFactory(
		createAutoCompleteTextField, {...props, onChange: handleOnChange, setInputValue}
	);
	const handledValue = getValue(value, options);
	const rootId = useMemoFactory(getRootId, id, name);
	const emptyAwareRenderOption = useMemoFactory(createEmptyAwareRenderOption, renderOption, getOptionLabel);
	const changeInputValue = useCallback((event, newValue /* reason */) => {
		setInputValue(newValue);
	}, [setInputValue]);

	return (
		<MuiAutocomplete freeSolo={!lockToOptions} id={rootId} options={options} disablePortal={disablePortal}
		                 getOptionLabel={getOptionLabel} isOptionEqualToValue={getOptionSelected} value={handledValue}
		                 fullWidth={!noFullWidth} disabled={disabled} onChange={handleOnChange} open={open}
		                 groupBy={groupBy} renderGroup={renderGroup} renderInput={renderTextField}
		                 ListboxComponent={listComponent} classes={classes} renderOption={emptyAwareRenderOption}
		                 disableClearable={disableClearable} forcePopupIcon={forcePopupIcon} onClose={onClose} sx={sx}
		                 PaperComponent={paperComponent} PopperComponent={popperComponent} inputValue={inputValue}
		                 onInputChange={changeInputValue} openOnFocus selectOnFocus />
	);
}

function getRootId(id, name) {
	let rootId;
	if (id) {
		rootId = id;
	} else if (name) {
		rootId = `id-${name}`;
	}
	return rootId;
}

AutoComplete.propTypes = {
	...DefaultAutoCompleteTextField.propTypes,
	noFullWidth: PropTypes.bool,
	disableClearable: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.string,
	lockToOptions: PropTypes.bool,
	onChange: PropTypes.func,
	options: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.object
	])),
	getOptionLabel: PropTypes.func,
	groupBy: PropTypes.func,
	getOptionSelected: PropTypes.func,
	getSelectedValue: PropTypes.func,
	value: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.object
	]),
	getValue: PropTypes.func,
	disabled: PropTypes.bool,
	disablePortal: PropTypes.bool,
	forcePopupIcon: PropTypes.bool,
	open: PropTypes.bool,
	classes: PropTypes.object,
	listComponent: PropTypes.elementType,
	paperComponent: PropTypes.elementType,
	popperComponent: PropTypes.elementType,
	onClose: PropTypes.func,
	renderOption: PropTypes.func,
	sx: PropTypes.object
};

AutoComplete.defaultProps = {
	getSelectedValue: identity,
	getValue: identity,
	listComponent: FixedVirtualizedOptionsList
};

function renderGroup(group) {
	const {group: groupLabel, children} = group;
	const groupNode = [children];
	if (groupLabel) {
		groupNode.unshift((
			<Ellipsis component={ListSubheader}>
				{groupLabel}
			</Ellipsis>
		));
	}
	return groupNode;
}

function createEmptyAwareRenderOption(renderOption, getOptionLabel) {
	return function EmptyAwareRenderOption(listItemProps, option) {
		const ListElement = 'li';
		const getOption = renderOption || getOptionLabel;
		const finalOption = callSafe(getOption, option);
		let resultingOption = option;
		if (finalOption === '') {
			resultingOption = '---';
		} else if (typeof finalOption !== 'undefined') {
			resultingOption = finalOption;
		}
		return (
			<ListElement {...listItemProps}>
				<Ellipsis>
					{resultingOption}
				</Ellipsis>
			</ListElement>
		);
	};
}
