import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {isRelativeUrl} from '../../../commons/utils/URLUtils.js';
import {webAppURL} from '../../../commons/WebViewHelpers.js';
import {querySelector} from '../../../router/flux/selectors/LocationSelectors.js';
import {
	getPatientDataStore,
	getQueryParametersUsedToLoadPatientDetailsData
} from './PatientDetailsSelectors.js';

const SUPPORTED_PATIENT_DETAILS_QUERY_PARAMETERS = Immutable.List(['share_id', 'document_id', 'accessionnumber', 'patient_id', 'patient_aimid', 'generic_container_uid', 'study_instance_uid']);

export const isPatientSearchQuery = createSelector(querySelector, query => SUPPORTED_PATIENT_DETAILS_QUERY_PARAMETERS.some(paramName => Boolean(query.get(paramName, ''))));

export const selectPatientDetailsLoaderQuery = createSelector(querySelector, query => query.delete('refererLocation'));

export const selectRefererLocation = createSelector(querySelector, query => {
	const refererLocation = query.get('refererLocation');
	if (refererLocation && !isRelativeUrl(refererLocation)) {
		return null;
	}
	return refererLocation;
});

export const selectItemTypeForQuery = createSelector(querySelector, (query = Immutable.Map()) => {
	let itemType = null;
	if (query.has('share_id', false) || query.get('onlyShares', 0) === 1) {
		itemType = 'documentshare';
	} else if (query.has('document_id', false)) {
		itemType = 'document';
	}
	return itemType;
});

export const selectHasPatientDetailsForQuery = createSelector(
	selectPatientDetailsLoaderQuery, getQueryParametersUsedToLoadPatientDetailsData,
	(patientDetailsLoaderQuery, queryParametersUsedToLoadPatientDetailsData) => (
		Boolean(queryParametersUsedToLoadPatientDetailsData) &&
		!queryParametersUsedToLoadPatientDetailsData.isEmpty() &&
		patientDetailsLoaderQuery.every(
			(value, key) => queryParametersUsedToLoadPatientDetailsData &&
				queryParametersUsedToLoadPatientDetailsData.get(key) === value
		)
	)
);

function createPatientDetailsPath(query, patientDetails) {
	let path;
	const patientAIMId = patientDetails.getIn(['patient', 'id']);
	if (patientAIMId) {
		path = `patient/${patientAIMId}`;
		const onlyShares = patientDetails.get('onlyShares', false) || Boolean(query.get('share_id', ''));
		path += onlyShares ? '/documentshare' : '/document';
	}
	return path;
}

export const getRedirectLocationForQuery = createSelector(
	querySelector, selectRefererLocation, selectHasPatientDetailsForQuery, getPatientDataStore,
	(query, refererLocation, hasPatientDetails, patientDetails) => {
		let newLocation = null;
		if (hasPatientDetails && patientDetails) {
			newLocation = Immutable.fromJS({
				path: webAppURL(createPatientDetailsPath(query, patientDetails))
			});
			const documentId = getInitialDocumentId(patientDetails);
			const isFiltered = Boolean(query.get('filtered', 0));
			if (isFiltered) {
				newLocation = newLocation.setIn(['query', 'filtered'], 1);
			}
			if (documentId) {
				newLocation = newLocation.set('fragment', `document=${documentId}`);
			}
			if (refererLocation) {
				newLocation = newLocation.setIn(['query', 'refererLocation'], refererLocation);
			}
		}
		return newLocation;
	}
);

function getInitialDocumentId(patientDetails) {
	return patientDetails.get('initial_document_id', null);
}
