import React from 'react';
import PropTypes from 'prop-types';

import UnhandledErrorsDialogContainer from '../containers/UnhandledErrorsDialogContainer.js';
import IntermediatePageContainer from '../containers/webview/IntermediatePageContainer.js';
import TemplateIFrame from './webview/TemplateIFrame.js';

import '../../../styles/commons/components/UnhandledApplicationErrorPage.scss';

export default function UnhandledApplicationErrorPage(props) {
	const {headerTemplate} = props;
	return (
		<IntermediatePageContainer className='error-page'>
			<TemplateIFrame className='header-iframe' templateName={headerTemplate} />
			<UnhandledErrorsDialogContainer />
		</IntermediatePageContainer>
	);
}

UnhandledApplicationErrorPage.propTypes = {
	headerTemplate: PropTypes.string
};

UnhandledApplicationErrorPage.defaultProps = {
	headerTemplate: 'loginPage_header'
};
