import {createSingleFieldValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';

export function minLengthValidator(minLength, errorMessage = 'FieldToShort', translator = ValidatorsMessagesTranslator) {
	return createSingleFieldValidatorFactory((locale, translatedFieldLabel, fieldValue) => fieldValue !== null && fieldValue !== undefined && (`${fieldValue}`).trim().length >= minLength ||
			translator.getFormattedMessage(errorMessage, locale, {minLength, inputFieldLabel: translatedFieldLabel}));
}

export function maxLengthValidator(maxLength, errorMessage = 'FieldToLong', translator = ValidatorsMessagesTranslator) {
	return createSingleFieldValidatorFactory((locale, translatedFieldLabel, fieldValue) => !fieldValue || (`${fieldValue}`).trim().length <= maxLength ||
			translator.getFormattedMessage(errorMessage, locale, {maxLength, inputFieldLabel: translatedFieldLabel}));
}
