/** Enriches a copy of the passed Object style with the needed properties
 * to reflect the specified box spacing property group
 * @param style {Object} the object to save the properties to
 * @param groupName {String} like 'margin' or 'padding'
 * @param groupValue {String|Number|Object} to transfer to style.
 * @returns {*}
 */
export function assignBoxStyleGroup(style, groupName, groupValue = {}) {
	const newStyle = {...style};
	if (groupValue) {
		if (typeof groupValue === 'object') {
			Object.keys(groupValue).forEach(side => {
				newStyle[`${groupName}-${side}`] = convertSingleBoxStyleValue(groupValue[side]);
			});
		} else {
			newStyle[groupName] = convertSingleBoxStyleValue(groupValue);
		}
	}
	return newStyle;
}

/** Converts the passed value into a valid box parameter value if value is an integer.
 * If value is a string, then this string is returned.
 * @param value {String} the value to be converted.
 * @returns {String} like '20px'
 */
export function convertSingleBoxStyleValue(value) {
	return typeof value === 'number' ? `${value}px` : value;
}

//noinspection JSCommentMatchesSignature
/** Reduces the passed style to an object containing only the properties named like the main box model
 * property groups 'margin', 'padding' and 'border' and returns a new Object containing at most all of the groups, which
 * themselves contain converted box styles as returned by assignBoxDimensions.
 * @param style: { {margin: Object, padding: Object, border: Object } } the object to reduce
 * @returns {Object} containing at most one property for each major box property group
*/
export function extractBoxStyles({margin, padding, border}) {
	const extractedStyles = {margin, padding, border};

	return Object.keys(extractedStyles)
		.reduce(
			(acc, styleProperty) => assignBoxStyleGroup(acc, styleProperty, extractedStyles[styleProperty]), {});
}

/** Concatenates the given classes into a space separated list of classes
 * @param styles { ...String } styles to be combined
 * @returns { String } space separated list of styles
 */
export function combineClassNames(...styles) {
	return styles.reduce((combinedStyles, nextStyle) => ((nextStyle) ? (Boolean(combinedStyles) && `${combinedStyles} ${nextStyle}` || nextStyle) : combinedStyles), '');
}

/**
 * Merges the provides lists of class names by calling the provided reducer.
 * The reducer is given the following parameters and should return the merged Set:
 *  - Set containing the currentClasses, split by Space
 *  - Array containing the classNames, split by Space
 * @param currentClasses - String containing the current list of classes, separated by Space
 * @param classNames - String containing the list of classes to be merged with the currentClasses
 * @param reducer - Function to merge to provided lists of classes (see above)
 * @returns {string} containing the merged classes separated by a single Space.
 */
export function reduceClasses(currentClasses, classNames, reducer = defaultMerge) {
	const currentClassNames = currentClasses
		.split(' ')
		.map(className => className.trim())
		.filter(entry => entry !== '');
	let uniqueClassNames = new Set(currentClassNames);
	const splitClassNames = classNames
		.split(' ')
		.map(className => className.trim())
		.filter(entry => entry !== '');
	uniqueClassNames = reducer(uniqueClassNames, splitClassNames);
	return Array.from(uniqueClassNames).join(' ');
}

function defaultMerge(setA, arrayB) {
	arrayB.forEach(setA.add.bind(setA));
	return setA;
}

export function mergeClasses(...classesObjects) {
	return classesObjects
		.filter(classes => classes)
		.reduce((mergedClasses, classes) => {
			Object.keys(classes).forEach(key => {
				const alreadyHasKey = key in mergedClasses;
				mergedClasses[key] = alreadyHasKey ? reduceClasses(mergedClasses[key], classes[key]) : classes[key];
			});
			return mergedClasses;
		}, {});
}
