import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import PatientContext from '../bricks/PatientContext.js';
import OtherPatientIndicatorIcon from './OtherPatientIndicatorIcon.js';

export default React.memo(OtherPatientIndicator);

function OtherPatientIndicator(props) {
	const {patient, children} = props;
	const {isOtherPatient, patient: realPatient} =
		useBrick(PatientContext, context => selectPatientContextProps(context, patient));
	if (patient && isOtherPatient) {
		return (
			<React.Fragment>
				<OtherPatientIndicatorIcon patient={realPatient} />
				{children}
			</React.Fragment>
		);
	}
	return null;
}

OtherPatientIndicator.propTypes = {
	patient: PropTypes.object
};

function selectPatientContextProps(context, patient) {
	return {
		isOtherPatient: context.isOtherPatient(patient),
		patient: context.getPatient()
	};
}
