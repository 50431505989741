import React from 'react';
import PropTypes from 'prop-types';

import {callSafe} from '../utils/FunctionUtils.js';
import createSizeAwareComponent, {BOUNDING_RECT_SHAPE} from './createSizeAwareComponent.js';

class SizeMeasurer extends React.PureComponent {
	render() {
		const {children} = this.props;
		return children || false;
	}

	componentDidMount() {
		const {boundingRect} = this.props;
		if (boundingRect !== null) {
			this.fireBoundingRectUpdated();
		}
	}

	componentDidUpdate(previousProps/*, prevState, snapshot*/) {
		const {boundingRect: prevBoundingRect} = previousProps;
		const {boundingRect} = this.props;
		if (boundingRect !== null && prevBoundingRect !== boundingRect) {
			this.fireBoundingRectUpdated();
		}
	}

	fireBoundingRectUpdated() {
		const {onBoundingRectUpdated, boundingRect} = this.props;
		callSafe(onBoundingRectUpdated, boundingRect);
	}
}

SizeMeasurer.propTypes = {
	boundingRect: BOUNDING_RECT_SHAPE,
	onBoundingRectUpdated: PropTypes.func
};

export default createSizeAwareComponent(SizeMeasurer);
