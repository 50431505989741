import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const JUSTIFY_VALUES = ['start', 'between', 'center', 'around', 'end'];
const JUSTIFY_VALUES_MAP = {
	start: 'flex-start',
	end: 'flex-end',
	between: 'space-between',
	around: 'space-around',
	center: 'center'
};
const DEFAULT_JUSTIFY = 'between';

const ALIGN_VALUES = ['start', 'center', 'stretch', 'end', 'baseline'];
const ALIGN_VALUES_MAP = {
	start: 'flex-start',
	end: 'flex-end',
	center: 'center',
	stretch: 'stretch',
	baseline: 'baseline'

};
const DEFAULT_ALIGN = 'stretch';
const DIRECTION_VALUES = ['row', 'column'];

export default function FlexLayoutBase(props) {
	const {
		className, element: Component, noGrow, wrap, noShrink, justify, align, direction, forwardRef, hidden, sx,
		...remainingProps
	} = props;

	const sxProperties = {
		display: hidden ? 'none' : 'flex',
		flexDirection: direction,
		flexGrow: noGrow ? '0' : '1',
		flexShrink: noShrink ? '0' : '1',
		justifyContent: JUSTIFY_VALUES_MAP[justify] || DEFAULT_JUSTIFY,
		alignItems: ALIGN_VALUES_MAP[align] || DEFAULT_ALIGN,
		flexWrap: wrap ? 'wrap' : 'nowrap',
		...sx
	};
	return <Box ref={forwardRef} component={Component} className={className} sx={sxProperties} {...remainingProps} />;
}

FlexLayoutBase.propTypes = {
	noGrow: PropTypes.bool,
	noShrink: PropTypes.bool,
	wrap: PropTypes.bool,
	element: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string, PropTypes.func]),
	justify: PropTypes.oneOf(JUSTIFY_VALUES),
	align: PropTypes.oneOf(ALIGN_VALUES),
	className: PropTypes.string,
	direction: PropTypes.oneOf(DIRECTION_VALUES),
	hidden: PropTypes.bool,
	...Box.propTypes
};

FlexLayoutBase.defaultProps = {
	element: 'div',
	noGrow: false,
	noShrink: false,
	wrap: false,
	justify: DEFAULT_JUSTIFY,
	align: DEFAULT_ALIGN,
	direction: 'row',
	hidden: false
};

