import {transformLine3d} from './math/Line.js';
import {intersectPlanes} from './math/Plane.js';

export default function calculateLocalizer(thisDicomDump, activeItemDicomDump) {
	if (thisDicomDump === activeItemDicomDump) {
		return null;
	}
	let localizer = null;
	const projectionMatrix = thisDicomDump &&
		thisDicomDump.hasLocalizerInformation() &&
		thisDicomDump.getPatientToImagePixelsProjectionMatrix();
	if (projectionMatrix &&
		activeItemDicomDump && activeItemDicomDump.hasLocalizerInformation() &&
		thisDicomDump.getFrameOfReferenceUID() === activeItemDicomDump.getFrameOfReferenceUID()
	) {
		const localizer3d = intersectPlanes(thisDicomDump.getPlane(), activeItemDicomDump.getPlane());
		if (localizer3d !== null) {
			localizer = transformLine3d(localizer3d, projectionMatrix);
		}
	}
	return localizer;
}

