import React from 'react';
import {vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import '../../../../styles/viewer/components/annotations/AnnotationBaseStyles.scss';

export default function Circle(props) {
	const {points, transformationMatrix, isPrintPreview} = props;
	const containerPoints = points.map(
		point => vec2.transformMat3(vec2.create(), point, transformationMatrix)
	);
	const [center] = containerPoints;
	const [cx, cy] = center;
	const radius = vec2.distance(containerPoints[0], containerPoints[1]);
	const circle = <circle className='annotation-element' cx={cx} cy={cy} r={radius} />;
	const contour = isPrintPreview && React.cloneElement(circle, {className: 'annotation-element--contour'});

	return (
		<g>
			{contour}
			{circle}
		</g>
	);
}

Circle.propTypes = {
	points: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.instanceOf(Float32Array),
		PropTypes.instanceOf(Float64Array)
	])),
	transformationMatrix: PropTypes.instanceOf(Float32Array),
	isPrintPreview: PropTypes.bool
};
