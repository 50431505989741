import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {fragmentSelector} from '../../../../router/flux/selectors/LocationSelectors.js';
import ClientDocumentation from '../../../components/webview/help/ClientDocumentation.js';
import {currentLocale} from '../../../selectors/GeneralConfigSelectors.js';

export default connect(
	createStructuredSelector({
		fragment: fragmentSelector,
		locale: currentLocale
	})
)(ClientDocumentation);
