import React from 'react';
import {vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {toContainerPosition} from '../../utils/AnnotationUtils.js';

export default function Rectangle(props) {
	const {points, className} = props;
	const containerPoints = points.map(point => toContainerPosition(props, point));
	const topLeft = vec2.min(vec2.create(), containerPoints[0], containerPoints[1]);
	const bottomRight = vec2.max(vec2.create(), containerPoints[0], containerPoints[1]);
	const diagonal = vec2.subtract(vec2.create(), bottomRight, topLeft);
	return <rect x={topLeft[0]} y={topLeft[1]} width={diagonal[0]} height={diagonal[1]} className={className} />;
}

Rectangle.propTypes = {
	points: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.instanceOf(Float32Array),
		PropTypes.instanceOf(Float64Array)
	])),
	className: PropTypes.string
};
