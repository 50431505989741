import React from 'react';
import Immutable from 'immutable';
import {array, func, oneOfType} from 'prop-types';

import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import List from '../../ui/components/List';
import WindowPresetMenuEntry from './WindowPresetMenuEntry.js';

import '../../../styles/viewer/components/WindowPresetsMenu.scss';

const DEFAULT_SPLIT_SIZE = 6;

export default class WindowPresetsMenu extends React.PureComponent {
	render() {
		const {setWindow, presets} = this.props;
		const splitUpPresets = splitPresets(presets);
		const menus = splitUpPresets.map(partition => (
			<List key={`presetPartition-${splitUpPresets.indexOf(partition)}`}>
				{createPresetEntries(setWindow, partition)}
			</List>
		));
		return (
			<div className='window-presets-menu--container'>
				{menus}
			</div>
		);
	}
}

WindowPresetsMenu.propTypes = {
	setWindow: func.isRequired,
	presets: oneOfType([array, immutableListPropType]).isRequired
};

function createPresetEntries(setWindow, presets) {
	return presets.map(preset => (
		<WindowPresetMenuEntry key={`preset-${presets.indexOf(preset)}`} setWindow={setWindow} preset={preset} />
	));
}

function splitPresets(allPresets, splitSize = DEFAULT_SPLIT_SIZE) {
	let startIndex = allPresets.size;
	let partitions = Immutable.List();
	do {
		startIndex -= splitSize;
		partitions = partitions.push(allPresets.slice(Math.max(startIndex, 0), startIndex + splitSize));
	} while (startIndex >= 0);
	return partitions.reverse();
}
