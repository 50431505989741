import React from 'react';

import BackgroundIndicator from '../../commons/components/BackgroundIndicator.js';
import {isDicomSeries} from '../../commons/data/aim/SeriesHelpers.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import PrimaryTileText from '../../material-design/components/text/PrimaryTileText.js';
import SecondaryTileText from '../../material-design/components/text/SecondaryTileText.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';

import '../../../styles/patient-details/components/PreviewTileCaption.scss';

export default function PreviewTileCaption(props) {
	const {item} = props;
	const CaptionComponent = isDicomSeries(item) ? DicomSeriesCaption : GenericFileCaption;
	return <CaptionComponent {...props} />;
}

PreviewTileCaption.propTypes = {
	item: immutableMapPropType
};

function DicomSeriesCaption(props) {
	const {item: series} = props;
	const numImages = series.get('num_images');
	return (
		<TileCaption {...props}>
			<PrimaryTileText>
				{series.get('modality_fk')}
&nbsp;|&nbsp;
				{numImages}
&nbsp;
				<SynFormattedMessage message='NumberImages' translator={PatientDetailsMessagesTranslator}
				                     messageParams={{count: series.get('num_images')}} />
			</PrimaryTileText>
			<SecondaryTileText>
				{series.get('series_description')}
			</SecondaryTileText>
		</TileCaption>
	);
}

DicomSeriesCaption.propTypes = {
	item: immutableMapPropType
};

function GenericFileCaption(props) {
	const {item: file} = props;
	return (
		<TileCaption {...props}>
			<PrimaryTileText>
				{file.get('extension')}
			</PrimaryTileText>
			<SecondaryTileText>
				{file.get('original_name')}
			</SecondaryTileText>
		</TileCaption>
	);
}

GenericFileCaption.propTypes = {
	item: immutableMapPropType
};

function TileCaption(props) {
	return (
		<VerticalLayout element={BackgroundIndicator} className='preview-tile-caption' align='start' justify='center'
		                noGrow noShrink {...props} />
	);
}

TileCaption.propTypes = {
	...GenericFileCaption.propTypes,
	...DicomSeriesCaption.propTypes
};
