import React from 'react';
import PropTypes from 'prop-types';

import {UploadSummary} from '../../../../webupload/components/UploadFilesList/index.js';
import HorizontalLayout from '../../../ui/components/layout/HorizontalLayout.js';
import UploadStepperButton, {UploadStepperButtonBack} from './UploadStepperButton.js';

export function UploadStepperFooter(props) {
	const {buttonLabel, buttonHandler, buttonLabelBack, buttonHandlerBack, children} = props;
	const justify = buttonLabelBack ? 'between' : 'end';
	return (
		<HorizontalLayout noGrow justify={justify} align='center'>
			{buttonLabelBack && <UploadStepperButtonBack className='upload-back-button' label={buttonLabelBack} handler={buttonHandlerBack} />}
			{children}
			<UploadSummary />
			{buttonLabel && <UploadStepperButton className='upload-forward-button' label={buttonLabel} handler={buttonHandler} />}
		</HorizontalLayout>
	);
}

UploadStepperFooter.propTypes = {
	buttonLabel: PropTypes.string,
	buttonHandler: PropTypes.func,
	buttonLabelBack: PropTypes.string,
	buttonHandlerBack: PropTypes.func
};
