import {useState} from 'react';

import {IS_DEBUG_BUILD} from '../../constants/EnvironmentConstants.js';
import {shallowEqual} from '../ObjectUtils';
import {useChangedState, useEffectOnUnmount, useMemoFactory} from './index.js';

const INITIAL_PROPS = Symbol('initial props');

export default function useObservable(observable, selectFunction) {
	if (IS_DEBUG_BUILD) {
		validateObservable(observable);
	}
	const [selectFunctionHolder] = useState({});
	selectFunctionHolder.selectFunction = selectFunction;
	const [selectedProps, setSelectedProps] = useChangedState(INITIAL_PROPS);

	const updateSelectedProps = useMemoFactory(createUpdateState, observable, setSelectedProps, selectFunctionHolder);
	const unsubscribe = useMemoFactory(subscribeTo, observable, updateSelectedProps);
	useEffectOnUnmount(unsubscribe, unsubscribe);

	const newProps = selectFunction(observable);
	const useNewProps = selectedProps === INITIAL_PROPS || !shallowEqual(selectedProps, newProps);
	return useNewProps ? newProps : selectedProps;
}

function createUpdateState(observable, setSelectedProps, selectFunctionHolder) {
	return () => setSelectedProps(selectFunctionHolder.selectFunction(observable));
}

function subscribeTo(observable, subscriber) {
	return observable.subscribe(subscriber);
}

function validateObservable(observable) {
	if (!observable || typeof (observable.subscribe) !== 'function') {
		throw new Error(`Invalid observable: ${observable}`);
	}
}
