import {APPLICATION_XML} from '../../commons/constants/MimeTypes.js';
import {stringToArrayBuffer} from '../../commons/utils/BlobUtils.js';
import {
	CDA_NAMESPACE,
	CDA_NON_XML_BODY_PATH,
	COMPOSITION_TEXT_NARRATIVE,
	DIAGNOSTIC_REPORT_PRESENTED_FORM,
	DIAGNOSTIC_REPORT_TEXT_NARRATIVE,
	FHIR_NAMESPACE, XHTML_NAMESPACE
} from '../constants/XMLViewerConstants.js';

const PARSE_ERROR = 'parsererror';

function extractPdfData(presentedFormBase64) {
	if (!presentedFormBase64) {
		return null;
	}
	const decodedString = window.atob(presentedFormBase64);
	return stringToArrayBuffer(decodedString);
}

function getPresentedForm(domRepresentation) {
	const presentedFormNode = domRepresentation.querySelector(DIAGNOSTIC_REPORT_PRESENTED_FORM);
	return presentedFormNode
		? extractPdfData(presentedFormNode.getAttribute('value'))
		: null;
}

function getNarrativeForm(domRepresentatiuon) {
	const DiagnosticReportnarrativeNode = domRepresentatiuon.querySelector(DIAGNOSTIC_REPORT_TEXT_NARRATIVE);
	const CompositionnarrativeNode = domRepresentatiuon.querySelector(COMPOSITION_TEXT_NARRATIVE);
	
	if (DiagnosticReportnarrativeNode && DiagnosticReportnarrativeNode.namespaceURI === XHTML_NAMESPACE) {
		return DiagnosticReportnarrativeNode.outerHTML;
	}
	if (CompositionnarrativeNode && CompositionnarrativeNode.namespaceURI === XHTML_NAMESPACE) {
		return CompositionnarrativeNode.outerHTML;
	}
	
	return null;
}

function getDomRepresentation(xmlString) {
	let domRepresentation = null;
	const domParser = new DOMParser();
	try {
		domRepresentation = domParser.parseFromString(xmlString, APPLICATION_XML);
	} catch (error) {
		domRepresentation = null;
	}
	return domRepresentation;
}

function containsFHIRData(domRepresentation) {
	return domRepresentation &&
		!domRepresentation.querySelector(PARSE_ERROR) &&
		domRepresentation.documentElement.namespaceURI === FHIR_NAMESPACE;
}

export function detectFHIRDiagnosticReport(xmlString) {
	let report = null;
	const domRepresentation = getDomRepresentation(xmlString);
	if (containsFHIRData(domRepresentation)) {
		report = {
			narrative: getNarrativeForm(domRepresentation),
			presentedForm: getPresentedForm(domRepresentation)
		};
		if (!report.narrative && !report.presentedForm) {
			return null;
		}
	}
	return report;
}

function getCDAnonXMLBody(domRepresentation) {
	const pdfContent = domRepresentation.querySelector(CDA_NON_XML_BODY_PATH);
	return pdfContent
		? extractPdfData(pdfContent.textContent)
		: null;
}

function containsCDAData(domRepresentation) {
	return domRepresentation &&
		!domRepresentation.querySelector(PARSE_ERROR) &&
		domRepresentation.documentElement.namespaceURI === CDA_NAMESPACE;
}

export function detectCDAnonXMLBody(xmlString) {
	let nonXMLBody = null;
	const domRepresentation = getDomRepresentation(xmlString);
	if (containsCDAData(domRepresentation)) {
		nonXMLBody = getCDAnonXMLBody(domRepresentation);
	}
	return nonXMLBody;
}
