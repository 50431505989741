import {call, cancelled, put, race, take} from 'redux-saga/effects';

import InsufficientPermissionsError from '../../commons/api/InsufficientPermissionsError.js';
import {ENFORCE_TWO_FACTOR_LOGIN} from '../../commons/constants/SynSettingsConstants.js';
import {createSession} from '../api/SessionApi.js';
import {requestSecondFactorToken} from '../api/UserTokenApi.js';
import {ACQUIRE_SECOND_FACTOR_TOKEN, LOGIN, RESET_LOGIN_WORKFLOW} from '../constants/SessionActionTypes.js';
import {
	SESSION_STATE_LOGIN_IN_PROGRESS,
	SESSION_STATE_REQUEST_CREDENTIALS
} from '../constants/SessionReducerStates.js';
import LoginError from '../errors/LoginError.js';
import {
	attachSession,
	beginSessionTransition,
	finishSessionTransition
} from '../flux/actions/SessionActions.js';
import createNoSessionTask from './createNoSessionTask.js';

function isTwoFactorLoginEnforced() {
	return ENFORCE_TWO_FACTOR_LOGIN;
}

function* acquireSecondFactorToken(username) {
	let token = null;
	if ((yield call(isTwoFactorLoginEnforced))) {
		yield put(beginSessionTransition(SESSION_STATE_REQUEST_CREDENTIALS));
		try {
			yield call(requestSecondFactorToken, username);
			yield put(finishSessionTransition());
			const loginAction = yield take(ACQUIRE_SECOND_FACTOR_TOKEN);
			token = loginAction.payload.token;
		} catch (error) {
			yield put(finishSessionTransition(error));
		}
	}
	return token;
}

function* login(credentials) {
	const {username, password} = credentials;
	const token = yield* acquireSecondFactorToken(username);
	yield put(beginSessionTransition(SESSION_STATE_LOGIN_IN_PROGRESS));
	try {
		yield call(createSession, username, password, token);
		yield put(finishSessionTransition());
		yield put(attachSession());
	} catch (error) {
		let finalError = error;
		if (error instanceof InsufficientPermissionsError) {
			finalError = new LoginError(error, username, error.code);
		}
		yield put(finishSessionTransition(finalError));
	}
}

function* resetOr(effect, ...args) {
	const raceResult = yield race({
		effect: call(effect, ...args),
		reset: take(RESET_LOGIN_WORKFLOW)
	});
	return raceResult.effect || false;
}

function* userLoginTaskImpl() {
	while (!(yield cancelled())) {
		const startLoginAction = yield take(LOGIN);
		const credentials = startLoginAction.payload;

		if (credentials) {
			yield* resetOr(login, credentials);
		}
	}
}

export default createNoSessionTask(userLoginTaskImpl);
