import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {uiIsFullscreenElementVisible} from '../../ui/flux/UISelectors.js';
import ContentPage from '../components/webview/ContentPage.js';
import {isCacheValid} from '../selectors/CacheSelectors.js';

export default connect(
	createStructuredSelector({
		isCacheValid,
		uiIsModalDialogVisible: uiIsFullscreenElementVisible
	})
)(ContentPage);

