/*
 *
 */
import React from 'react';

import ContentRow from './layout/ContentRow.js';
import ContentRowsContainer from './layout/ContentRowsContainer.js';

import '../../../styles/material-design/components/Toolbar.scss';

/**
 * Provides the base Widget class...
 *
 * @class Menu
 */
export default function Toolbar(props) {
	const {children} = props;
	return (
		<ContentRowsContainer className='material-toolbar' element='Header'>
			<ContentRow className='material-toolbar-row' noWrap>
				{children}
			</ContentRow>
		</ContentRowsContainer>
	);
}
