import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../i18n/translators/ViewerTranslator.js';
import PdfView from './pdf/PdfView.js';
import PdfLoader from './PdfLoader.js';
import ViewerContext from './ViewerContext.js';

import '../../../styles/viewer/components/FHIRViewer.scss';

class FHIRView extends React.PureComponent {
	render() {
		const {diagnosticReport} = this.props;
		const {isPrintPreview} = this.context;
		return isPrintPreview
			? FHIRView.renderPrintingNotSupported()
			: this.renderReport(diagnosticReport);
	}

	static renderPrintingNotSupported() {
		return (
			<CenterLayout className='print-message'>
				<SynFormattedMessage translator={ViewerTranslator} message='PrintingFHIRNotSupported' />
			</CenterLayout>
		);
	}

	static renderPresentedForm(presentedForm) {
		return (
			<PdfLoader pdfFile={presentedForm}>
				<PdfView />
			</PdfLoader>
		);
	}

	renderNarrative(narrative) {
		const {activate} = this.context;
		return (
			<div className='fhir-viewer--container' onClick={activate}>
				<div className='fhir-viewer--xhtml-content' dangerouslySetInnerHTML={{__html: narrative}} />
			</div>
		);
	}

	renderReport(diagnosticReport) {
		const {presentedForm, narrative} = diagnosticReport;
		return presentedForm
			? FHIRView.renderPresentedForm(presentedForm)
			: this.renderNarrative(narrative);
	}
}

FHIRView.contextType = ViewerContext;
FHIRView.propTypes = {
	diagnosticReport: PropTypes.object.isRequired
};

export default FHIRView;
