import React from 'react';
import PropTypes from 'prop-types';

import {TOUCH_SIZE} from './AnnotationConstants.js';
import DotTouchArea from './DotTouchArea.js';

export default function MarkerTouchArea(props) {
	const {referencePoint, isPrintPreview = false} = props;
	return !isPrintPreview && (
		<DotTouchArea point={referencePoint} radius={TOUCH_SIZE} />
	);
}
MarkerTouchArea.propTypes = {
	referencePoint: PropTypes.instanceOf(Float32Array),
	isPrintPreview: PropTypes.bool
};
