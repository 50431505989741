import React from 'react';
import {useSelector} from 'react-redux';

import {selectFilterString} from '../../../patient-details/flux/selectors/PatientDetailsSelectors';
import {useMemoFactory} from '../../utils/customHooks/index.js';
import PreviewImagesGrid from './PreviewImagesGrid.js';
import PreviewImagesGridItemTile from './PreviewImagesGridItemTile.js';

export default function createPreviewImagesGrid(overlayComponent, createLoadItemFunction, createTaskIdentifier) {
	function WrappedPreviewImagesGrid(props) {
		const filterString = useSelector(selectFilterString);
		const wrappedItemTile = useMemoFactory(
			createWrappedItemTile, overlayComponent, createLoadItemFunction, createTaskIdentifier
		);
		return <PreviewImagesGrid itemTileComponent={wrappedItemTile} filterString={filterString} {...props} />;
	}
	return WrappedPreviewImagesGrid;
}

function createWrappedItemTile(overlayComponent, createLoadItemFunction, createTaskIdentifier) {
	return function WrappedItemTile(props) {
		return (
			<PreviewImagesGridItemTile overLayComponent={overlayComponent}
			                           createLoadItemFunction={createLoadItemFunction}
			                           createTaskIdentifier={createTaskIdentifier} {...props} />
		);
	};
}
