import moment from 'moment';

import {IS_DEBUG_BUILD} from '../constants/EnvironmentConstants.js';

export const FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm:ss';
export const FORMAT_DATE = 'YYYY-MM-DD';

/**
 * Extracts a short or long date depending on the length of the passed date parameter.
 * Returns the passed parameter date directly if it is an instance of Date or moment.
 *
 * @param date - the date to parse
 * @returns {*|Date} - the parsed date.
 */
export function extractDate(date) {
	let extractedDate = null;
	if (date instanceof Date || moment.isMoment(date)) {
		return date;
	}
	if (date) {
		if (!(date instanceof String)) {
			throw new Error('date is expected to be a string.');
		}
		if (date.length > FORMAT_DATE.length) {
			extractedDate = extractLongDate(date);
		} else {
			extractedDate = extractShortDate(date);
		}
	}
	return extractedDate;
}

/**
 * Extracts a javascript date object from its string representation in the following format 'YYYY-MM-DD HH:MM:SS'
 * This string representation is usually provided by REST-APIs
 *
 * @param longDateString a date string in the format 'YYYY-MM-DD HH:MM:SS'
 * @returns {*|Date} a javascript date object representing the parse string or undefined
 * if the input string is undefined or null if the input string is null.
 */
export function extractLongDate(longDateString) {
	//converts long date string to iso date
	return longDateString && moment.utc(longDateString, FORMAT_DATE_TIME).toDate();
}

/**
 * Extracts a javascript date object from its string representation in the following format 'YYYY-MM-DD'
 * This string representation is usually provided by REST-APIs
 *
 * @param shortDateString a date string in the format 'YYYY-MM-DD'
 * @returns {*|Date} a javascript date object representing the parse string or undefined
 * if the input string is undefined or null if the input string is null.
 */
export function extractShortDate(shortDateString) {
	//converts long date string to iso date
	return shortDateString && moment.utc(shortDateString, FORMAT_DATE).toDate();
}

export function formatLongDate(date, asUTC = true) {
	const converter = asUTC ? moment.utc : moment;
	return converter(date).format(FORMAT_DATE_TIME);
}

export function getRemainingDays(until) {
	const now = moment.utc()
		.hours(0)
		.minutes(0)
		.seconds(0)
		.milliseconds(0);
	const validUntil = moment.utc(until)
		.hours(0)
		.minutes(0)
		.seconds(0)
		.milliseconds(0);
	return Math.max(0, validUntil.diff(now, 'days'));
}

/**
 * Returns a JS-Date object that contains the local time stored as time in UTC.
 * Example:
 *    LocalTime: 02.02.2021 12:45:00
 *    in UTC: 02.02.2021 12:45:00
 * The represented time doesn't change only the timezone does.
 * @returns {Date}
 */
export function createLocalDateAsUTC() {
	const localDate = new Date();
	return new Date(Date.UTC(
		localDate.getFullYear(),
		localDate.getMonth(),
		localDate.getDate(),
		localDate.getHours(),
		localDate.getMinutes(),
		localDate.getSeconds(),
		localDate.getMilliseconds()
	));
}

/**
 * Same as createLocaleDateAsUTC, but returns the date object as a moment.
 * @returns {moment.Moment} - The local date and time as UTC moment
 */
export function createLocalMomentAsUTC() {
	const localDateAsUTC = createLocalDateAsUTC();
	return moment.utc(localDateAsUTC);
}

/**
 * Creates an instance of moment only containing the current date with its time set to 00:00:00.
 * @returns {moment.Moment} - with its time set to 00:00:00
 */
export function createDateMoment() {
	return createLocalMomentAsUTC().startOf('day');
}

/**
 * Preventing Date.parse calls by overriding it with an error throwing function.
 */
if (IS_DEBUG_BUILD) {
	Date.parse = function parseIsFaulty() {
		throw Error('Date.parse must not be used! Use DateUtils.js::extractLongDate instead!');
	};
}

/**
 * Compares two dates on a day basis, non-alphanumeric characters are ignored
 *
 * @param givenDateString representation of date, as rendered in WebView fronted.
 * @param expectedDate parsable by momentJS
 * @returns {boolean}
 */
export function isSameDate(givenDateString, expectedDate) {
	return moment(givenDateString, 'DD-MM-YYYY').isSame(expectedDate, 'day');
}
