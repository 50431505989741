import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import {detectCDAnonXMLBody, detectFHIRDiagnosticReport} from '../utils/XMLViewerUtils.js';
import CDAView from './CDAView.js';
import createViewerItemViewer from './createViewerItemViewer.js';
import FHIRView from './FHIRView.js';
import UnsupportedItemViewer from './UnsupportedItemViewer.js';

function XMLViewer(props) {
	const {rawXML} = props;
	const diagnosticReport = useMemo(() => detectFHIRDiagnosticReport(rawXML), [rawXML]);
	const CDAnonXMLBody = useMemo(() => detectCDAnonXMLBody(rawXML), [rawXML]);

	if (diagnosticReport) {
		return <FHIRView diagnosticReport={diagnosticReport} />;
	} else if (CDAnonXMLBody) {
		return <CDAView nonXMLBody={CDAnonXMLBody} />;
	} 
	return <UnsupportedItemViewer message='FileTypeNotSupported' messageParams={{extension: 'XML'}} />;
}

XMLViewer.propTypes = {
	rawXML: PropTypes.string
};

export default createViewerItemViewer(XMLViewer, 'xml-viewer');
