import React from 'react';
import MuiBadge from '@mui/material/Badge';
import PropTypes from 'prop-types';

import {COLOR_PRIMARY, COLOR_SECONDARY} from '../constants/SynMUIOptions.js';

export default function Badge(props) {
	const {children, badgeContent, badgeColor, max, showZero, badgeBottom} = props;
	const anchorOrigin = {
		vertical: badgeBottom ? 'bottom' : 'top',
		horizontal: 'right'
	};
	return (
		<MuiBadge badgeContent={badgeContent} color={badgeColor} max={max} showZero={showZero}
		          anchorOrigin={anchorOrigin}>
			{children}
		</MuiBadge>
	);
}

Badge.propTypes = {
	badgeContent: PropTypes.node,
	badgeColor: PropTypes.oneOf([COLOR_PRIMARY, COLOR_SECONDARY]),
	max: PropTypes.number,
	showZero: PropTypes.bool,
	badgeBottom: PropTypes.bool
};

Badge.defaultProps = {
	max: 99,
	showZero: false,
	badgeBottom: false
};
