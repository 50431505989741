import React from 'react';
import MuiList from '@mui/material/List';
import PropTypes from 'prop-types';

export default React.memo(List);

function List(props) {
	const {children, noVerticalPadding} = props;
	return (
		<MuiList disablePadding={noVerticalPadding}>
			{children}
		</MuiList>
	);
}

List.propTypes = {
	noVerticalPadding: PropTypes.bool
};
