import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {uiShowPrintLayout} from '../../../ui/flux/UISelectors.js';
import {isViewerShown} from '../../../viewer/flux/selectors/ViewerSelectors.js';
import PatientDetailsPageBody from '../../components/PatientDetailsPageBody.js';

export default connect(
	createStructuredSelector({
		showViewer: isViewerShown,
		showPrintLayout: uiShowPrintLayout
	})
)(PatientDetailsPageBody);
