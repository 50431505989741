import React, {useContext} from 'react';
import {useSelector} from 'react-redux';
import {mat3} from 'gl-matrix';
import {Set} from 'immutable';
import PropTypes from 'prop-types';
import {createSelector, createStructuredSelector} from 'reselect';

import {useAction} from '../../../commons/flux/ReactHooks.js';
import {useMemoFactory} from '../../../commons/utils/customHooks';
import {uiDevicePixelRatio, uiShowPrintLayout} from '../../../ui/flux/UISelectors.js';
import ImageViewerSVGOverlay from '../../components/ImageViewerSVGOverlay.js';
import ViewerContext from '../../components/ViewerContext.js';
import {scaleByInverseDevicePixelRatio} from '../../utils/ViewerUtils.js';
import {
	removeAnnotation,
	resetActiveAnnotation,
	setActiveAnnotation,
	updateAnnotation
} from '../actions/AnnotationActions.js';
import {
	createSelectLayeredAnnotationIds,
	getActiveAnnotation,
	selectAnnotationMappings
} from '../selectors/AnnotationSelectors.js';
import {createSelectShowAnnotations} from '../selectors/ImageViewerSelectors.js';

function invertTransformationMatrix(transformationMatrix) {
	return mat3.invert(mat3.create(), transformationMatrix);
}

function combineItemsWithMappings(annotationItems, annotationMappings) {
	return annotationItems.reduce((acc, {type, id}) => acc.concat(annotationMappings.getIn([type, id], new Set()))
		, new Set()
	).toArray();
}

function ImageViewerSVGOverlayContainer(props) {
	const {containerWidth, containerHeight, ...remainingProps} = props;

	const annotationIds = useAnnotationIds(props);
	const activeAnnotationId = useActiveAnnotationId();
	const annotationProps = useAnnotationProps(props);
	const annotationsVisible = useAnnotationsVisible();
	const isPrintPreview = useSelector(uiShowPrintLayout);

	const boundUpdateAnnotation = useAction(updateAnnotation);
	const boundResetActiveAnnotation = useAction(resetActiveAnnotation);
	const boundRemoveAnnotation = useAction(removeAnnotation);
	const boundSetActiveAnnotation = useAction(setActiveAnnotation);
	return (
		<ImageViewerSVGOverlay annotationIds={annotationIds}
			activeAnnotationId={activeAnnotationId}
			containerWidth={containerWidth}
			containerHeight={containerHeight}
			annotationProps={annotationProps}
			annotationsVisible={annotationsVisible}
			updateAnnotation={boundUpdateAnnotation}
			resetActiveAnnotation={boundResetActiveAnnotation}
			removeAnnotation={boundRemoveAnnotation}
			setActiveAnnotation={boundSetActiveAnnotation}
			isPrintPreview={isPrintPreview}
			{...remainingProps} />
	);
}

function useAnnotationsVisible() {
	const {viewerId} = useContext(ViewerContext);
	const selectShowAnnotations = useMemoFactory(createSelectShowAnnotations, viewerId);
	return useSelector(selectShowAnnotations);
}

function useAnnotationProps(props) {
	const {transformationMatrix, viewerItem, containerWidth, containerHeight} = props;
	const selectTransformationMatrix = useMemoFactory(
		() => () => transformationMatrix,
		transformationMatrix
	);
	const selectScaledTransformationMatrix = useMemoFactory(
		createSelector,
		selectTransformationMatrix,
		uiDevicePixelRatio,
		scaleByInverseDevicePixelRatio
	);
	const selectInverseTransformationMatrix = useMemoFactory(
		createSelector,
		selectScaledTransformationMatrix,
		invertTransformationMatrix
	);
	const selectAnnotationProps = useMemoFactory(
		createSelectAnnotationProps,
		viewerItem, containerWidth, containerHeight,
		selectScaledTransformationMatrix, selectInverseTransformationMatrix
	);
	return useSelector(selectAnnotationProps);
}

function createSelectAnnotationProps(
		viewerItem, containerWidth, containerHeight,
		selectScaledTransformationMatrix, selectInverseTransformationMatrix
) {
	return createStructuredSelector({
		viewerItem: () => viewerItem,
		containerWidth: () => containerWidth,
		containerHeight: () => containerHeight,
		transformationMatrix: selectScaledTransformationMatrix,
		inverseTransformationMatrix: selectInverseTransformationMatrix
	});
}

function useActiveAnnotationId() {
	const finalSelector = React.useCallback(
		(activeAnnotationId, isPrintPreview) => (isPrintPreview ? null : activeAnnotationId),
		[]
	);
	const selectActiveAnnotationId = useMemoFactory(
		createSelector,
		getActiveAnnotation,
		uiShowPrintLayout,
		finalSelector
	);
	return useSelector(selectActiveAnnotationId);
}

function useAnnotationIds(props) {
	const {annotationItems} = props;
	const selectAnnotationItems = useMemoFactory(
		() => () => (annotationItems || []),
		annotationItems
	);
	const selectAnnotationIds = useMemoFactory(
		createSelector,
		selectAnnotationItems,
		selectAnnotationMappings,
		combineItemsWithMappings
	);
	const selectLayeredAnnotationIds = useMemoFactory(
		createSelectLayeredAnnotationIds,
		selectAnnotationIds
	);
	return useSelector(selectLayeredAnnotationIds);
}

ImageViewerSVGOverlayContainer.propTypes = {
	viewerItem: PropTypes.object,
	annotationItems: PropTypes.arrayOf(PropTypes.exact({
		id: PropTypes.string,
		type: PropTypes.string
	})),
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired,
	transformationMatrix: PropTypes.object.isRequired
};

export default React.memo(ImageViewerSVGOverlayContainer);
