import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import selectFromProps from '../../../viewer/flux/selectors/selectFromProps.js';
import PatientDetailsDocument from '../../components/PatientDetailsDocument.js';
import {selectDocuments} from '../selectors/PatientDetailsSelectors.js';

const makeGetDocument = () => createSelector(
	selectFromProps('documentId'),
	selectDocuments,
	(documentId, documents) => documents.get(documentId)
);

const mapState = () => {
	const getDocument = makeGetDocument();
	return (state, ownProps) => {
		const document = getDocument(state, ownProps);
		return {document};
	};
};

export default connect(
	mapState
)(PatientDetailsDocument);
