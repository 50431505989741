import {memoizeLast} from '../commons/utils/FunctionUtils.js';
import {
	calculateLut,
	getBytesPerPixel,
	getLutFunction,
	getLutSize,
	getPixelDataArrayType
} from './components/CanvasRendering.js';

export const BYTES_PER_RGBA_PIXEL = 4;
const mImageData = {};
const mRenderInformation = {};
const mRawPixels = {};
let mNextFragmentCalculationTimerId = null;

function getLutBufferImpl(rawPixelsFormat) {
	return new Uint8Array(getLutSize(rawPixelsFormat));
}
const getLutBuffer = memoizeLast(getLutBufferImpl);

function getLUTImpl(windowCenter, windowWidth, minPixelValue, maxPixelValue, invertOutput, rawPixelsFormat) {
	const lutBuffer = getLutBuffer(rawPixelsFormat);
	calculateLut(lutBuffer, windowCenter, windowWidth, minPixelValue, maxPixelValue, invertOutput);
	return lutBuffer;
}
const getLUT = memoizeLast(getLUTImpl);

function calculateNextFragment(
		bytesPerPixel, rawPixels, imageData,
		applyLut, renderInformation, renderStep, startY = 0) {
	const {height, width} = renderInformation;

	const remainingLines = height - startY;
	const remainingPixels = remainingLines * width;
	const pixelsToCalculate = Math.min(imageData.width * imageData.height, remainingPixels);
	const byteOffset = startY * width * bytesPerPixel;
	const viewSize = pixelsToCalculate * (bytesPerPixel / rawPixels.BYTES_PER_ELEMENT);
	const rawPixelsFragment = new rawPixels.constructor(rawPixels.buffer, rawPixels.byteOffset + byteOffset, viewSize);
	applyLut(imageData.data, rawPixelsFragment, pixelsToCalculate);

	const nextStartY = startY + imageData.height;
	const isComplete = nextStartY >= height;
	postMessage({...renderStep, ...renderInformation, imageData, isComplete});

	let nextCalculation = null;
	if (!isComplete) {
		nextCalculation = calculateNextFragment.bind(
			undefined, bytesPerPixel, rawPixels, imageData,
			applyLut, renderInformation, renderStep, nextStartY
		);
	}
	return nextCalculation;
}

function scheduleFragmentCalculation(scheduledCalculationFunction) {
	if (mNextFragmentCalculationTimerId !== null) {
		clearTimeout(mNextFragmentCalculationTimerId);
	}
	mNextFragmentCalculationTimerId = setTimeout(onCalculateNextFragment(scheduledCalculationFunction), 1);
}

function onCalculateNextFragment(calculationFunction) {
	return function calculateAndReschedule() {
		mNextFragmentCalculationTimerId = null;
		const nextCalculationFunction = calculationFunction();
		if (nextCalculationFunction !== null) {
			scheduleFragmentCalculation(nextCalculationFunction);
		}
	};
}

function onMessage(event) {
	const {data} = event;
	const {type} = data;
	switch (type) {
		case 'imageData':
			handleImageData(data);
			break;
		case 'renderStep':
			handleRenderStep(data);
			break;
		default:
			break;
	}
}

function handleImageData(data) {
	const {
		width, height, scaleFactor, scaleMatrix,
		imageData, rawPixelsBuffer, rawPixelsBufferOffset, rawPixelsFormat
	} = data;
	const nrPixels = width * height;
	const PixelsArrayType = getPixelDataArrayType(rawPixelsFormat);
	const pixelsArraySize = (getBytesPerPixel(rawPixelsFormat) / PixelsArrayType.BYTES_PER_ELEMENT) * nrPixels;
	mRawPixels[scaleFactor] = new PixelsArrayType(rawPixelsBuffer, rawPixelsBufferOffset, pixelsArraySize);
	mImageData[scaleFactor] = imageData;
	mRenderInformation[scaleFactor] = {width, height, scaleFactor, scaleMatrix, rawPixelsFormat};
}

function handleRenderStep(data) {
	const {windowCenter, windowWidth, scaleFactor, minPixelValue, maxPixelValue, invertOutput} = data;

	const renderInformation = mRenderInformation[scaleFactor];
	const imageData = mImageData[scaleFactor];
	const rawPixels = mRawPixels[scaleFactor];

	if (rawPixels && imageData) {
		const {rawPixelsFormat} = renderInformation;
		const bytesPerPixel = getBytesPerPixel(rawPixelsFormat);
		const formatSpecificApplyLut = getLutFunction(rawPixelsFormat);
		const lut = getLUT(windowCenter, windowWidth, minPixelValue, maxPixelValue, invertOutput, rawPixelsFormat);
		const boundApplyLut = formatSpecificApplyLut.bind(undefined, lut);
		const boundCalculation = calculateNextFragment.bind(
			undefined, bytesPerPixel, rawPixels, imageData,
			boundApplyLut, renderInformation, data, 0
		);
		scheduleFragmentCalculation(boundCalculation);
	}
}

self.onmessage = onMessage;
