import Immutable from 'immutable';

import SynCrudResourceApi from '../../api/SynCrudResourceApi.js';
import {noop} from '../../utils/FunctionUtils.js';

const CLIENT_CONFIG_CRUD_RESOURCE_DEFINITION = {
	api: new SynCrudResourceApi('/clientconfig'),
	actionsCreator: (apiActions, api) => {
		const searchAction = (options, ...args) => apiActions.search(options, ...args);
		const createAction = (parameters, actionThunk = null) => {
			const createPromise = api.create(parameters);
			const finalActionThunk = actionThunk || noop;
			return (dispatch, getState) => {
				finalActionThunk(createPromise, dispatch, getState);
			};
		};
		return {
			...apiActions, search: searchAction,
			create: createAction
		};
	},
	reducer: resultList => resultList.reduce((clientConfig, entry) => clientConfig.setIn([entry.get('section'), entry.get('key')], entry.get('value')), Immutable.Map())
};

export default CLIENT_CONFIG_CRUD_RESOURCE_DEFINITION;
