import ExtendableError from '../errors/ExtendableError.js';

/**
 * An error class which is used if the response.status of the request didn't match the required response status.
 * e.g.: in case of a create the expected result state is 201. In all other cases the expected response status is 200.
 */
export default class FetchError extends ExtendableError {
	constructor(message, response, responseAsJson = {}) {
		super(buildFetchErrorDescription(message, response));
		/**
		 * @field {Response} a whatwg response representing the response to the request.
		 */
		this.response = response;
		this.responseAsJson = responseAsJson;
		this.code = responseAsJson.code || null;
	}
}

function buildFetchErrorDescription(message, response = {}) {
	const {url} = response;
	return url ? `FetchError while fetching ${url}: ${message}` : message;
}
