import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import PropTypes from 'prop-types';

const SNACKBAR_CONTENT_STYLES = {
	maxWidth: {
		md: 0.8
	},
	minWidth: {
		md: 960
	}
};

function NonDiagnosticUsageSnackBar(props) {
	const {children, buttonElem} = props;
	return (
		<Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center'}} message={children} action={buttonElem}
		          ContentProps={{sx: SNACKBAR_CONTENT_STYLES}} open />
	);
}

NonDiagnosticUsageSnackBar.propTypes = {
	children: PropTypes.node.isRequired,
	buttonElem: PropTypes.node.isRequired
};

export default React.memo(NonDiagnosticUsageSnackBar);
