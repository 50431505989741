import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tappable from '../../commons/components/Tappable.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';

import '../../../styles/patient-details/components/HangTile.scss';

export default function HangTile(props) {
	const {active, onClick} = props;
	const tileClassNames = useMemoFactory(classNames, 'hang-tile', {active});
	return <Tappable className={tileClassNames} onTap={onClick} />;
}

HangTile.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func
};
