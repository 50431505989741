import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {locationSelector} from '../../../../router/flux/selectors/LocationSelectors.js';
import {getCurrentRouteVariables} from '../../../../router/flux/selectors/RoutingSelectors.js';
import {addCacheBuster, toUrlString} from '../../../../router/LocationUtils.js';
import {
	SYNEDRA_ZIP_FILE_EXTENSION,
	SYNEDRA_ZIP_FILE_EXTENSION_ALTERNATIVE
} from '../../../constants/WebViewConstants.js';
import {capitalizeFirst} from '../../../utils/StringUtils.js';

const downloadUrlSelector = createSelector(locationSelector, location => toUrlString(location.update('query', addCacheBuster)));

const itemTypeSelector = createSelector(getCurrentRouteVariables, routeVariables => capitalizeFirst(routeVariables.get('itemType')));

const downloadForViewSelector = createSelector(getCurrentRouteVariables, routerVariables => {
	const extension = routerVariables.get('ext').toLowerCase();
	return extension === SYNEDRA_ZIP_FILE_EXTENSION || extension === SYNEDRA_ZIP_FILE_EXTENSION_ALTERNATIVE;
});

export default connect(
	createStructuredSelector({
		downloadUrl: downloadUrlSelector,
		itemType: itemTypeSelector,
		downloadForView: downloadForViewSelector
	})
);
