import {debugLog} from './utils/DebugLog.js';

export default async function cleanUpServiceWorkersAndCaches() {
	await removeServiceWorkers();
	await deleteCaches();
}

async function removeServiceWorkers() {
	if ('serviceWorker' in navigator) {
		const registrations = await navigator.serviceWorker.getRegistrations();
		registrations.forEach(unregisterServiceWorker);
	}
}

async function unregisterServiceWorker(registration) {
	const unregisterSuccess = await registration.unregister();
	if (unregisterSuccess) {
		debugLog('Service worker unregistered.');
	} else {
		debugLog('Failed to unregister service worker.');
	}
}

async function deleteCaches() {
	if ('caches' in window) {
		const cacheKeys = await caches.keys();
		await Promise.all(
			cacheKeys.map(key => caches.delete(key))
		);
	}
}
