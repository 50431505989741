export const HEADER_LENGTH_SIZE_BYTES = 4;
export const FIELD_RAW_IMAGE_BLOBS = 'rawImageBlobs';
export const FIELD_RAW_OVERLAY_IMAGE_BLOBS = 'rawOverlayImageBlobs';
export const FIELD_DOM_IMAGES = 'domImages';
export const FIELD_DOM_OVERLAY_IMAGES = 'domOverlayImages';
export const FIELD_PIXEL_VALUE_OFFSET = 'pixelValueOffset';
export const FIELD_COLOR_DEPTHS = 'colorDepth';
export const FIELD_PIXEL_SPACING = 'pixelSpacing';
export const FIELD_PIXEL_ASPECT_RATIO = 'pixelAspectRatio';
export const FIELD_IMAGE_WIDTH = 'width';
export const FIELD_IMAGE_HEIGHT = 'height';
export const FIELD_INITIAL_WINDOW = 'initialWindow';
export const FIELD_MIN_PIXEL_VALUE = 'minPixelValue';
export const FIELD_MAX_PIXEL_VALUE = 'maxPixelValue';
export const FIELD_INVERT_OUTPUT_COLORS = 'invertOutputColors';
export const IMAGE_TYPE_PIXELS = 'pixels';
export const IMAGE_TYPE_OVERLAY = 'overlay';
export const FIELD_IMAGE_SCALE = 'scale';
export const FIELD_INSTANCE_NUMBER = '__instanceNumber';
