import Immutable from 'immutable';

import {
	BUGFIX_VERSION,
	INVALID_VERSION,
	MAJOR_VERSION,
	MINOR_VERSION,
	RELEASE_VERSION
} from '../constants/VersionConstants.js';

const INDEX_TO_VERSION_KEY = [RELEASE_VERSION, MAJOR_VERSION, MINOR_VERSION, BUGFIX_VERSION];
const VERSION_REGEX = /^([0-9]+|x)\.([0-9]+|x)\.([0-9]+|x)\.([0-9]+|x)$/;
const IGNORED_PART = 'x';

function isVersion(ver) {
	return Boolean(ver) && INDEX_TO_VERSION_KEY.every(key => ver.get(key, null) !== null);
}

export function parseVersionString(versionString) {
	let parsedVersion = INVALID_VERSION;
	if (Boolean(versionString) && versionString.match !== undefined) {
		const groups = versionString.match(VERSION_REGEX);
		if (groups !== null) {
			parsedVersion = Immutable.Map();
			for (let vIndex = 1; vIndex < groups.length; ++vIndex) {
				const groupValue = groups[vIndex];
				parsedVersion = parsedVersion.set(
					INDEX_TO_VERSION_KEY[vIndex - 1],
					groupValue === IGNORED_PART ? groupValue : Number(groupValue)
				);
			}
		}
	}
	return parsedVersion;
}

export function formatVersion(ver) {
	return isVersion(ver) && `${ver.get(RELEASE_VERSION)}.${ver.get(MAJOR_VERSION)}.${ver.get(MINOR_VERSION)}.${ver.get(BUGFIX_VERSION)}` ||
		null;
}

export function compareVersions(a, b) {
	let comparisonResult = 0;
	if (!isVersion(a)) {
		comparisonResult = isVersion(b) ? -1 : 0;
	} else if (isVersion(b)) {
		for (let keyIndex = 0; keyIndex < INDEX_TO_VERSION_KEY.length && comparisonResult === 0; ++keyIndex) {
			const key = INDEX_TO_VERSION_KEY[keyIndex];
			const aValue = a.get(key);
			const bValue = b.get(key);
			if (aValue !== IGNORED_PART && bValue !== IGNORED_PART) {
				if (aValue > bValue) {
					comparisonResult = 1;
				} else if (aValue < bValue) {
					comparisonResult = -1;
				}
			}
		}
	} else {
		comparisonResult = 1;
	}
	return comparisonResult;
}

export function isReleaseVersion(parsedVersion) {
	return Boolean(parsedVersion) && Boolean(parsedVersion.get) &&
		(Number(parsedVersion.get(MINOR_VERSION, '1')) % 2) === 0;
}
