import React from 'react';
import PropTypes from 'prop-types';

import Patient from '../../../webview/commons/data/aim/Patient.js';
import PrimaryItemText from '../../../webview/material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../../webview/material-design/components/text/SecondaryItemText.js';
import IconButton from '../../../webview/ui/components/IconButton.js';
import DeleteIcon from '../../../webview/ui/components/icons/DeleteIcon.js';
import OtherPatientIndicator from '../OtherPatientIndicator.js';
import useFormattedEntry from '../useFormattedEntry.js';
import {DeleteColumn, MobileInfoColumn, OtherPatientColumn} from './Columns.js';
import {declareEntryHeight} from './FileList.js';

const MOBILE_ENTRY_HEIGHT = 64;

export default function MobileListEntry(props) {
	const {entry, removeEntry, removeButtonTitle} = props;
	const {patient: patientFromEntry} = entry;
	const {name, size, patient} = useFormattedEntry(entry);
	return (
		<React.Fragment>
			<DeleteColumn>
				<IconButton title={removeButtonTitle} icon={<DeleteIcon />} onClick={removeEntry} />
			</DeleteColumn>
			<MobileInfoColumn style={{overflow: 'hidden'}}>
				<PrimaryItemText>
					{name}
				</PrimaryItemText>
				{patient && (
					<SecondaryItemText>
						{patient}
					</SecondaryItemText>
				)}
				<SecondaryItemText>
					{size}
				</SecondaryItemText>
			</MobileInfoColumn>
			{patientFromEntry && (
				<OtherPatientColumn>
					<OtherPatientIndicator patient={patientFromEntry} />
				</OtherPatientColumn>
			)}
		</React.Fragment>
	);
}
declareEntryHeight(MobileListEntry, MOBILE_ENTRY_HEIGHT);

MobileListEntry.propTypes = {
	entry: PropTypes.shape({
		patient: PropTypes.instanceOf(Patient),
		name: PropTypes.string,
		size: PropTypes.number
	}).isRequired,
	removeEntry: PropTypes.func,
	removeButtonTitle: PropTypes.string
};
