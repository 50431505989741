import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {
	selectUserContextData,
	selectUserContextID
} from '../../../authentication/flux/selectors/SessionSelectors.js';
import {uiShowPrintLayout} from '../../../ui/flux/UISelectors.js';
import {acceptNonDiagnosticWarning as acceptWarningAction} from '../../actions/crudresources/ClientConfigActions.js';
import NonDiagnosticUsageMessage from '../../components/webview/NonDiagnosticUsageMessage.js';
import {clientConfig as clientConfigApi} from '../../flux/crudresources/CrudResourcesFluxApi.js';
import {nonDiagnosticWarningAccepted as wasWarningAccepted} from '../../selectors/crudresources/ClientConfigSelectors.js';
import {bindActions} from '../../utils/FluxUtils.js';

const {
	getResponse: getClientConfigData
} = clientConfigApi.selectors;

export default connect(
	createStructuredSelector({
		userId: selectUserContextID,
		userContext: selectUserContextData,
		clientConfig: getClientConfigData,
		nonDiagnosticWarningAccepted: wasWarningAccepted,
		isPrintPreview: uiShowPrintLayout
	}),
	bindActions({
		acceptNonDiagnosticWarning: acceptWarningAction
	}),
	(stateProps, dispatchProps, props) => {
		const {
			userContext,
			clientConfig,
			nonDiagnosticWarningAccepted,
			isPrintPreview
		} = stateProps || {};
		const {
			acceptNonDiagnosticWarning
		} = dispatchProps;

		const showWarning = Boolean(userContext) && Boolean(clientConfig) && !nonDiagnosticWarningAccepted;
		return {
			...props,
			show: showWarning,
			onAccept: acceptNonDiagnosticWarning,
			isPrintPreview
		};
	}
)(NonDiagnosticUsageMessage);
