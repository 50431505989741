import createApiCall from '../../commons/api/operations/create.js';
import {identity} from '../../commons/utils/FunctionUtils.js';

// TODO PSp,RBi: Maybe move resource to webview/documentshare ??
const DOCUMENT_SHARE_URL = '/documentshare';

export function createPrivateShare(documentIds, message, pacsUserId, firstName, lastName, email, mobileNumber) {
	const payload = pacsUserId
		? {
			userId: pacsUserId
		}
		: {
			userFirstname: firstName,
			userLastname: lastName,
			userEmail: email,
			userMobileNumber: mobileNumber
		};

	payload.document_fk = documentIds;
	payload.message = message;

	return createApiCall(DOCUMENT_SHARE_URL, payload, identity);
}
