import React from 'react';
import {vec2, vec3} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {
	SYNC_POINT_STATE_IN_SLICE, 	SYNC_POINT_STATE_NOT_IN_SLICE, SYNC_POINT_STATE_UNDEFINED
} from '../../../commons/constants/SyncPointStates.js';
import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import {memoizeLast, noop} from '../../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import DicomImage from '../../data/DicomImage.js';
import DeleteAnnotationIconContainer from '../../flux/containers/DeleteAnnotationIconContainer.js';
import {renderAnnotationToolIconComponents, toContainerPosition} from '../../utils/AnnotationUtils.js';
import {DEFAULT_EQUALITY_THRESHOLD, isCloseTo} from '../../utils/MathUtils.js';
import {getDicomDump} from '../../utils/ViewerItemUtils.js';
import {TOUCH_SIZE} from './AnnotationConstants.js';
import AnnotationIconsGroup from './AnnotationIconsGroup.js';
import ModifiablePoint from './ModifiablePoint.js';
import SyncPointHandleNumberIcon from './SyncPointHandleNumberIcon.js';
import SyncPointHandleTouchArea from './SyncPointHandleTouchArea.js';
import SyncPointMarker from './SyncPointMarker.js';

const ANNOTATION_MAIN_DIRECTION_TWO_ICONS = vec2.set(new Float64Array(2), 0.0, -1.0);
const ANNOTATION_MAIN_DIRECTION_ONE_ICON = vec2.normalize(new Float64Array(2), [1.0, -1.0]);
const ACTIVE_ICON_DISTANCE_FACTOR = 1.5;
const INACTIVE_ICON_DISTANCE_FACTOR = 0.7;
const ACTIVE_ICONS_DISTANCE = TOUCH_SIZE * ACTIVE_ICON_DISTANCE_FACTOR;
const INACTIVE_ICONS_DISTANCE = TOUCH_SIZE * INACTIVE_ICON_DISTANCE_FACTOR;
const PONT_3D_ELEMENT_COUNT = 3;

export default class SyncPointHandle extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundOnPointUpdate = this.onPointUpdate.bind(this);
		this.boundRenderSyncPointNumberIcon = this.renderSyncPointNumberIcon.bind(this);
		this.boundRenderHandleTouchArea = this.renderHandleTouchArea.bind(this);
		this.boundRenderSyncPointMarker = this.createStateEvaluatingRenderFunction(SyncPointMarker);

		this.boundOnMouseEnter = this.onMouseEnter.bind(this);
		this.boundOnMouseLeave = this.onMouseLeave.bind(this);

		this.calculateSyncPointState = memoizeLast(calculateSyncPointState);
		this.calcSyncPointInImagePixels3d = memoizeLast(calcSyncPointInImagePixels3d);

		this.state = {
			hover: false
		};
	}

	render() {
		const {display} = this.props;
		return display && this.renderSyncPointHandle();
	}

	renderSyncPointHandle() {
		const {
			annotationId, AnnotationRoot,
			syncPointNumber
		} = this.props;
		const remainingProps = cloneWithoutProperties(this.props,
			'patientToImagePixelsProjectionMatrix', 'imagePixelsToPatientProjectionMatrix', 'AnnotationRoot'
		);

		const imagePoint = this.getSyncPointInImagePixels2d() || this.getViewportCenterInImageCoordinates();
		const syncPointState = this.getSyncPointState();
		const onPointUpdateFunction = syncPointState === SYNC_POINT_STATE_UNDEFINED ? noop : this.boundOnPointUpdate;

		const imagePointInContainerCoordinates = toContainerPosition(this.props, imagePoint);
		const annotationIcons = [DeleteAnnotationIconContainer];
		if (syncPointNumber) {
			annotationIcons.unshift(this.boundRenderSyncPointNumberIcon);
		}
		const iconDistance = this.isActive() ? ACTIVE_ICONS_DISTANCE : INACTIVE_ICONS_DISTANCE;
		const mainDirection = annotationIcons.length === 1
			? ANNOTATION_MAIN_DIRECTION_ONE_ICON
			: ANNOTATION_MAIN_DIRECTION_TWO_ICONS;
		const iconsGroup = (
			<AnnotationIconsGroup position={imagePointInContainerCoordinates}>
				{renderAnnotationToolIconComponents(
					annotationIcons, mainDirection, annotationId,
					iconDistance, iconDistance
				)}
			</AnnotationIconsGroup>
		);

		const StateFullSyncPointMarker = this.boundRenderSyncPointMarker;
		return (
			<AnnotationRoot onMouseEnter={this.boundOnMouseEnter} onMouseLeave={this.boundOnMouseLeave}>
				<ModifiablePoint key='handle' {...remainingProps} point={imagePoint} onPointUpdate={onPointUpdateFunction}
									  marker={StateFullSyncPointMarker} touchArea={this.boundRenderHandleTouchArea} />
				{iconsGroup}
			</AnnotationRoot>
		);
	}

	renderHandleTouchArea(props) {
		return <SyncPointHandleTouchArea active={this.isActive()} {...props} />;
	}

	renderSyncPointNumberIcon(props) {
		const {syncPointNumber} = this.props;
		return (
			<SyncPointHandleNumberIcon {...props}>
				{syncPointNumber}
			</SyncPointHandleNumberIcon>
		);
	}

	onMouseEnter() {
		this.setState({
			hover: true
		});
	}

	onMouseLeave() {
		this.setState({
			hover: false
		});
	}

	componentDidMount() {
		this.initializeSyncPointIfNecessary();
		this.snapToViewerIfNecessary();
	}

	initializeSyncPointIfNecessary() {
		const {isActiveViewer, imagePixelsToPatientProjectionMatrix} = this.props;
		if (isActiveViewer && !this.getSyncPoint3d() && Boolean(imagePixelsToPatientProjectionMatrix)) {
			this.update3dSyncPointFrom2dImagePixelsPoint(this.getViewportCenterInImageCoordinates());
		}
	}

	snapToViewerIfNecessary() {
		const {isActiveViewer} = this.props;
		if (isActiveViewer && this.getSyncPoint3d()) {
			const pointInImagePixels = this.getSyncPointInImagePixels3d();
			if (pointInImagePixels && !isCloseTo(pointInImagePixels[2], 0)) {
				const syncPointImagePixels2d = this.getSyncPointInImagePixels2d();
				this.update3dSyncPointFrom2dImagePixelsPoint(syncPointImagePixels2d);
			}
		}
	}

	getSyncPoint3d() {
		const {annotationProperties} = this.props;
		return calcSyncPoint3d(annotationProperties);
	}

	getViewportCenterInImageCoordinates() {
		const {inverseTransformationMatrix} = this.props;
		return vec2.transformMat3(new Float32Array(2), [0.0, 0.0], inverseTransformationMatrix);
	}

	onPointUpdate(new2dPoint) {
		this.update3dSyncPointFrom2dImagePixelsPoint(new2dPoint);
	}

	update3dSyncPointFrom2dImagePixelsPoint(imagePixelsPoint) {
		const {annotationId, onAnnotationPropertiesChanged, imagePixelsToPatientProjectionMatrix} = this.props;
		const imagePixels3d = vec3.fromValues(imagePixelsPoint[0], imagePixelsPoint[1], 0);
		const newSyncPoint = vec3.transformMat4(vec3.create(), imagePixels3d, imagePixelsToPatientProjectionMatrix);
		onAnnotationPropertiesChanged(annotationId, Immutable.Map({syncPoint3d: Immutable.List(newSyncPoint)}));
	}

	getSyncPointInImagePixels2d() {
		const imagePixels3d = this.getSyncPointInImagePixels3d();
		return imagePixels3d && vec2.set(new Float32Array(2), imagePixels3d[0], imagePixels3d[1]);
	}

	getSyncPointInImagePixels3d() {
		const {annotationProperties, patientToImagePixelsProjectionMatrix} = this.props;
		return this.calcSyncPointInImagePixels3d(patientToImagePixelsProjectionMatrix, annotationProperties);
	}

	getSyncPointState() {
		const {viewerItem, annotationProperties, patientToImagePixelsProjectionMatrix} = this.props;
		return this.calculateSyncPointState(
			viewerItem, patientToImagePixelsProjectionMatrix, annotationProperties,
			this.calcSyncPointInImagePixels3d
		);
	}

	componentDidUpdate(prevProps) {
		const {viewerItem, isActiveViewer} = this.props;
		if (isActiveViewer !== prevProps.isActiveViewer || viewerItem !== prevProps.viewerItem) {
			this.snapToViewerIfNecessary();
		}
	}

	isActive() {
		const {active} = this.props;
		const {hover} = this.state;
		return active || hover;
	}

	createStateEvaluatingRenderFunction(Component) {
		const getSyncPointState = this.getSyncPointState.bind(this);
		return function SyncPointStateStateEvaluatingRender(props) {
			const syncPointState = getSyncPointState();
			return <Component syncPointState={syncPointState} {...props} />;
		};
	}
}

SyncPointHandle.propTypes = {
	active: PropTypes.bool,
	display: PropTypes.bool,
	annotationId: PropTypes.string,
	syncPointNumber: PropTypes.oneOf(['', 1, 2]),
	onRemoveAnnotation: PropTypes.func,
	onAnnotationPropertiesChanged: PropTypes.func,
	isActiveViewer: PropTypes.bool,
	imagePixelsToPatientProjectionMatrix: PropTypes.instanceOf(Float32Array),
	inverseTransformationMatrix: PropTypes.instanceOf(Float32Array),
	patientToImagePixelsProjectionMatrix: PropTypes.instanceOf(Float64Array),
	annotationProperties: immutableMapPropType,
	AnnotationRoot: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.element
	]),
	viewerItem: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.instanceOf(DicomImage)
	])
};

function calculateSyncPointState(
		viewerItem, patientToImagePixelsProjectionMatrix, annotationProperties, syncPointToImagePixels3d) {
	let state = SYNC_POINT_STATE_UNDEFINED;

	const dicomDump = getDicomDump(viewerItem);
	const syncPointInImagePixels3d = syncPointToImagePixels3d(
		patientToImagePixelsProjectionMatrix, annotationProperties
	);
	if (syncPointInImagePixels3d && dicomDump) {
		const sliceThickness = dicomDump.getSliceThickness() || (2 * DEFAULT_EQUALITY_THRESHOLD);
		const imagePlane = dicomDump.getPlane();
		if (imagePlane) {
			const distanceToPlane = syncPointInImagePixels3d[2];
			if (Math.abs(distanceToPlane) > (sliceThickness / 2)) {
				state = SYNC_POINT_STATE_NOT_IN_SLICE;
			} else {
				state = SYNC_POINT_STATE_IN_SLICE;
			}
		}
	}
	return state;
}

function calcSyncPointInImagePixels3d(patientToImagePixelsProjectionMatrix, annotationProperties) {
	const syncPoint3d = calcSyncPoint3d(annotationProperties);
	return patientToImagePixelsProjectionMatrix && syncPoint3d
		? vec3.transformMat4(
			new Float32Array(PONT_3D_ELEMENT_COUNT),
			syncPoint3d.toArray(),
			patientToImagePixelsProjectionMatrix
		)
		: null;
}

function calcSyncPoint3d(annotationProperties) {
	return annotationProperties.get('syncPoint3d');
}
