import {useMemoFactory} from '../../webview/commons/utils/customHooks/index.js';
import {isFileEntry} from '../../webview/commons/utils/FilesCollection.js';

export default function useGetFileInfo(getPreparedFile, getPreparedFileSize, getPreparedContainerValue) {
	return useMemoFactory(
		createGetFileInfo, getPreparedFile, getPreparedFileSize, getPreparedContainerValue
	);
}

function createGetFileInfo(getPreparedFile, getPreparedFileSize, getPreparedContainerValue) {
	return function getFileInfo(name) {
		const entry = getPreparedFile(name);
		if (isFileEntry(entry)) {
			return entry;
		}
		const dirSize = getPreparedFileSize(name);
		const context = getPreparedContainerValue(name);
		return {
			size: dirSize,
			...context
		};
	};
}
