import React from 'react';
import PropTypes from 'prop-types';

import {getHocDisplayName} from '../../../commons/utils/ReactUtils.js';

export default function Status(props) {
	const {element: Element, ...remainingProps} = props;
	return <Element role='status' aria-live='assertive' {...remainingProps} />;
}

Status.propTypes = {
	element: PropTypes.elementType
};

Status.defaultProps = {
	element: 'div'
};

export function withStatusRole(element) {
	function WrappedComponent(props) {
		return <Status element={element} {...props} />;
	}
	WrappedComponent.propTypes = element.propTypes;
	WrappedComponent.defaultProps = element.defaultProps;
	WrappedComponent.displayName = getHocDisplayName(element, 'withStatusRole');
	return WrappedComponent;
}
