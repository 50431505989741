import {StatusCodes} from 'http-status-codes';

import {REQUEST_COUNT} from '../../constants/ApiConstants.js';
import {APPLICATION_JSON} from '../../constants/MimeTypes.js';
import {handleUnexpectedResponse, tryParseJson} from '../../utils/AjaxUtils.js';
import {toQueryString} from '../../utils/URLUtils.js';
import getRequestCount from './getRequestCount.js';

/**
 * Retrieves a json request from the given url.
 * Every request which has a status code different from 200 will reject the result promise with an error of type
 * FetchError.
 *
 * @param {String} url the url to retrieve the request from
 * @param {Object} parameters the parameters which are used to generate the URL query string from
 * @returns {Promise} a promise which will contain the response body as a parsed json object if the request finishes
 *    successfully.
 */
export default function get(url, parameters = {}) {
	const callCount = getRequestCount();
	const encodedParams = toQueryString(parameters);
	const finalUrl = encodedParams === '' ? url : `${url}?${encodedParams}`;
	return fetch(finalUrl, {
		method: 'GET',
		credentials: 'include',
		headers: new Headers({
			Accept: APPLICATION_JSON
		})
	}).then(handleUnexpectedResponse(StatusCodes.OK, `Fetch request could not be executed successfully ${finalUrl}`))
		.then(tryParseJson)
		.then((json = {}) => {
			json[REQUEST_COUNT] = callCount;
			return json;
		});
}
