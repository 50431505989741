import React from 'react';
import PropTypes from 'prop-types';

import LandingPage from '../../commons/components/webview/LandingPage.js';
import {translatorPropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import NewPasswordControllerContainer from '../flux/containers/NewPasswordControllerContainer.js';

export default class NewPasswordPage extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnFinish = this.onFinish.bind(this);
		this.boundOnSetNewPassword = this.onSetNewPassword.bind(this);
	}

	render() {
		const {translator, username, bailOutUrl} = this.props;
		const title = <SynFormattedMessage translator={translator} message='Title' />;
		return (
			<LandingPage headerTemplate='loginPage_header' footerTemplate='loginPage_footer' title={title}>
				<NewPasswordControllerContainer username={username} bailOutUrl={bailOutUrl}
				                                setNewPassword={this.boundOnSetNewPassword}
				                                onFinish={this.boundOnFinish} translator={translator} />
			</LandingPage>
		);
	}

	onFinish() {
		const {attachSession} = this.props;
		attachSession();
	}

	onSetNewPassword(formData) {
		const {newPassword, newPasswordConfirmation, verificationCode} = formData;
		const {setNewPassword, username, token} = this.props;
		callSafe(setNewPassword, username, newPassword, newPasswordConfirmation, token, verificationCode);
	}

	componentDidUpdate() {
		const {hasSession, isSessionBusy, originalLocation, replaceLocation} = this.props;
		if (hasSession && !isSessionBusy && originalLocation) {
			replaceLocation(originalLocation);
		}
	}
}

NewPasswordPage.propTypes = {
	token: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	originalLocation: PropTypes.object.isRequired,
	setNewPassword: PropTypes.func.isRequired,
	translator: translatorPropType,
	bailOutUrl: PropTypes.string,
	attachSession: PropTypes.func,
	replaceLocation: PropTypes.func,
	hasSession: PropTypes.bool,
	isSessionBusy: PropTypes.bool
};
