import createDownloadURLFactory from '../../commons/api/createDownloadUrlFactory.js';
import getImage from '../../commons/api/operations/getImage.js';
import {IMAGE_JPEG} from '../../commons/constants/MimeTypes.js';

const DICOM_SERIES_BASE_URL = '/series';
const DEFAULT_SERIES_PREVIEW_SIZE = 160;

export function getPreviewImage(seriesID, requestParameters = {}) {
	const {width = DEFAULT_SERIES_PREVIEW_SIZE, height = DEFAULT_SERIES_PREVIEW_SIZE, info = 0} = requestParameters;
	return getImage(`${DICOM_SERIES_BASE_URL}/${seriesID}`, {
		width,
		height,
		info,
		originalSize: 0
	}, IMAGE_JPEG);
}

export const getSeriesDownloadURL = createDownloadURLFactory(DICOM_SERIES_BASE_URL);
