import {useState} from 'react';

import {shallowEqual} from '../ObjectUtils';
import {useMemoFactory} from './index.js';

const NO_VALUE_SET_MARKER = Symbol('NO_VALUE_SET_MARKER');

/**
 * This hook works very similar to useState.
 * It also returns a [value, setter] pair that contains the current state and allows one to update it using the setter.
 *
 * In contrast to useState, this hook also always evaluates the passed value.
 * It uses the passed comparator to detect changes in the passed value.
 * It then returns the changed value until the returned setter function had been called once.
 * From there on it will always return the currently set value until another change in the input value is detected.
 *
 * This hook can be used to sync a components internal state with the value of a prop.
 * The state is initialized and updated with the props value
 * but can be updated by the component lik any other state variable.
 *
 * @param value - The initial or updated state value
 * @param comparator {function(a,b): boolean}- A function comparing the previous and new value.
 * It should return false if they are to be considered different.
 * @returns the initial or changed value until the returned setter was called.
 */
export default function useChangedValueOrState(value, comparator = shallowEqual) {
	const [initialStateHolder] = useState({current: value});
	const [stateHolder, setStateHolder] = useState({current: value});
	const resetInitialValue = useMemoFactory(createInitialValueResetter, initialStateHolder, stateHolder);
	const setVisibleValue = useMemoFactory(createVisibleValueSetter, initialStateHolder, setStateHolder);
	const getVisibleValue = useMemoFactory(createVisibleValueGetter, initialStateHolder, stateHolder);
	const valueChanged = !comparator(initialStateHolder.current, value);
	if (valueChanged) {
		resetInitialValue(value);
	}
	return [getVisibleValue(), setVisibleValue];
}

function createInitialValueResetter(initialValueHolder, stateHolder) {
	return function resetInitialValue(newValue) {
		initialValueHolder.current = newValue;
		stateHolder.current = NO_VALUE_SET_MARKER;
	};
}

function createVisibleValueSetter(initialValueHolder, setStateHolder) {
	return function setVisibleValue(newValue) {
		setStateHolder({current: newValue});
	};
}

function createVisibleValueGetter(initialValueHolder, stateHolder) {
	return function getVisibleValue() {
		return stateHolder.current === NO_VALUE_SET_MARKER ? initialValueHolder.current : stateHolder.current;
	};
}
