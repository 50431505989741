import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import GenericFileDetailsDialog from '../../components/GenericFileDetailsDialog.js';
import {createGenericFileMetadataSelector} from '../selectors/MetadataSelectors.js';

function createViewerDetailsDialogContainer(DialogComponent, selectorCreator, idParamName) {
	return function ViewerDetailsDialogContainer(props) {
		const {[idParamName]: id} = props;
		const metaDataSelector = useMemo(() => selectorCreator(id), [id]);
		const metadata = useSelector(metaDataSelector);

		return <DialogComponent metadata={metadata} {...props} />;
	};
}

export const GenericFileDetailsDialogContainer = createViewerDetailsDialogContainer(GenericFileDetailsDialog, createGenericFileMetadataSelector, 'fileId');
