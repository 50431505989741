import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {locationSelector} from '../../../router/flux/selectors/LocationSelectors.js';
import LinkButton from '../../components/webview/LinkButton.js';

export default connect(
	createStructuredSelector({
		location: locationSelector
	}),
	{}
)(LinkButton);
