import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../utils/ObjectUtils';
import {getHocDisplayName} from '../utils/ReactUtils.js';

export default function createInlineSVGIcon(IconComponent, defaultIconContainer = DefaultSpanIconContainer) {
	function SVGIcon(props) {
		const {iconContainer: IconContainer} = props;
		const remainingProps = cloneWithoutProperties(props, 'iconContainer');
		return (
			<IconContainer {...remainingProps}>
				<IconComponent />
			</IconContainer>
		);
	}

	SVGIcon.propTypes = {
		iconContainer: PropTypes.elementType
	};

	SVGIcon.defaultProps = {
		iconContainer: defaultIconContainer
	};

	SVGIcon.displayName = getHocDisplayName(SVGIcon, 'createInlineSVGIcon');
	return SVGIcon;
}

function DefaultSpanIconContainer(props) {
	const {style, ...remainingProps} = props;
	const finalStyle = {fontSize: '1px', display: 'block', ...style};
	const Element = 'span';
	return <Element style={finalStyle} {...remainingProps} />;
}

DefaultSpanIconContainer.propTypes = {
	style: PropTypes.object
};
