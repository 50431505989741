import Immutable from 'immutable';

import {offsetFromPlane} from './math/Plane.js';
import {getDicomDump} from './ViewerItemUtils.js';

export function getSyncPointDisplayNumber(syncPointNumber, numSyncPoints) {
	return numSyncPoints > 1 ? syncPointNumber : '';
}

export function isCompatible(dicomDump) {
	return Boolean(dicomDump) &&
		Boolean(dicomDump.getPlane()) &&
		Boolean(dicomDump.getPatientToImageProjectionMatrix()) &&
		Boolean(dicomDump.getFrameOfReferenceUID()) &&
		(dicomDump.getNumberOfFrames() === null || dicomDump.getNumberOfFrames() === 1);
}

function getImagePlane(dicomDump) {
	return isCompatible(dicomDump) ? dicomDump.getPlane() : null;
}

function getOffsetFromPlane(syncPoint, plane) {
	return plane
		? Math.abs(offsetFromPlane(syncPoint, plane))
		: Number.MAX_VALUE;
}

export function findClosestImageIndex(syncPoint, imagePlaneInfos) {
	const planeOffsets = imagePlaneInfos.map(({plane, imageIndex}) => ({
		imageIndex,
		offset: getOffsetFromPlane(syncPoint, plane)
	}));
	const closestEntry = planeOffsets.min((l, r) => l.offset - r.offset);
	return closestEntry ? closestEntry.imageIndex : -1;
}

export function buildImagePlaneInfos(seriesImages, currentFrameOfReferenceUID) {
	return seriesImages.reduce((resultList, image, key) => {
		let accumulatedImagePlaneInfos = resultList;
		const dicomDump = getDicomDump(image);
		const frameOfReferenceUID = dicomDump && dicomDump.getFrameOfReferenceUID();
		if (frameOfReferenceUID === currentFrameOfReferenceUID) {
			accumulatedImagePlaneInfos = accumulatedImagePlaneInfos.push({
				plane: getImagePlane(dicomDump),
				imageIndex: key
			});
		}
		return accumulatedImagePlaneInfos;
	}, Immutable.List());
}

