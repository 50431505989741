import Immutable from 'immutable';

import {LEFT_BUTTON, MIDDLE_BUTTON, NO_ACTIVE_BUTTON, RIGHT_BUTTON} from '../../commons/components/EventTracker.js';

const KNOWN_TOOLS = ['pinch/zoom', 'window', 'pan', 'zoom'];
const DEFAULT_TOOL_DETECTORS = {
	zoom: detectZoom,
	pan: detectPan,
	window: detectWindow,
	'pinch/zoom': detectPinchZoom
};
const UNSET = Symbol('UNSET');
export default function createToolRecognizer(trackedTools = KNOWN_TOOLS, toolDetectors = DEFAULT_TOOL_DETECTORS) {
	const detectTool = buildDetectTool(trackedTools, toolDetectors);
	return (event, next) => {
		let nextEvent = event;
		const currentTool = event.get('tool', UNSET);
		if (currentTool === UNSET) {
			const canceled = event.get('type') === 'pointercancel';
			const detectedTool = canceled ? '' : detectTool(event);
			nextEvent = event.set('tool', detectedTool);
		}
		next(nextEvent);
	};
}

function buildDetectTool(tools, toolDetectors) {
	const selectToolDetectors = tools
		.map(tool => toolDetectors[tool] || DEFAULT_TOOL_DETECTORS[tool]);
	return event => {
		let detectedTool = '';
		const eventProperties = {
			ctrlKey: event.get('ctrlKey', false),
			shiftKey: event.get('shiftKey', false),
			buttons: event.get('buttons', NO_ACTIVE_BUTTON),
			nrPointers: event.get('pointers', Immutable.List()).size,
			pointerType: event.get('pointerType', 'mouse')
		};
		for (let detectorIndex = 0; detectorIndex < selectToolDetectors.length && detectedTool === ''; ++detectorIndex) {
			detectedTool = selectToolDetectors[detectorIndex](eventProperties);
		}
		return detectedTool;
	};
}

function detectZoom(eventProperties) {
	const {ctrlKey, shiftKey, buttons} = eventProperties;
	const isZoom = (ctrlKey && !shiftKey) && buttons !== MIDDLE_BUTTON || buttons === RIGHT_BUTTON;
	return isZoom ? 'zoom' : '';
}

function detectPan(eventProperties) {
	const {ctrlKey, shiftKey, buttons} = eventProperties;
	const isPan = (!ctrlKey && shiftKey) && buttons !== RIGHT_BUTTON || buttons === MIDDLE_BUTTON;
	return isPan ? 'pan' : '';
}

function detectWindow(eventProperties) {
	const {ctrlKey, shiftKey, buttons, nrPointers, pointerType} = eventProperties;
	const isWindow = nrPointers === 1 && (shiftKey === ctrlKey && buttons === LEFT_BUTTON || pointerType === 'touch');
	return isWindow ? 'window' : '';
}

function detectPinchZoom(eventProperties) {
	const {nrPointers, pointerType} = eventProperties;
	const isPinchZoom = nrPointers === 2 && pointerType === 'touch';
	return isPinchZoom ? 'pinch/zoom' : '';
}
