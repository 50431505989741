import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {uiDeviceOrientation, uiIsMobileDevice} from '../../../ui/flux/UISelectors.js';
import {MAX_LAYOUT_MODE, MAX_MOBILE_LAYOUT_MODE} from '../../../viewer/constants/ViewerConstants.js';
import {setViewerLayoutMode} from '../../../viewer/flux/actions/ViewerActions.js';
import {getViewerLayoutMode} from '../../../viewer/flux/selectors/ViewerSelectors.js';
import {getRestrictedLayoutForMode} from '../../../viewer/utils/ViewerUtils.js';
import ViewerMatrixMenu from '../../components/ViewerMatrixMenu.js';

function toLayoutName(layout) {
	return `${layout.get('columns')}x${layout.get('rows')}`;
}

export default connect(
	createStructuredSelector({
		layoutMode: getViewerLayoutMode,
		isMobileDevice: uiIsMobileDevice,
		deviceOrientation: uiDeviceOrientation
	}),
	bindActions({
		setViewerLayoutMode
	}),
	(stateProps, dispatchProps, props) => {
		const {isMobileDevice, deviceOrientation, layoutMode} = stateProps;
		const maxMode = isMobileDevice ? MAX_MOBILE_LAYOUT_MODE : MAX_LAYOUT_MODE;
		const layoutOptions = [];
		for (let modeOption = 0; modeOption <= maxMode; ++modeOption) {
			layoutOptions.push(toLayoutName(getRestrictedLayoutForMode(modeOption, isMobileDevice, deviceOrientation)));
		}

		return {
			...props, layoutMode,
			layoutOptions,
			onLayoutModeChange: dispatchProps.setViewerLayoutMode
		};
	}
)(ViewerMatrixMenu);
