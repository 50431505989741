import React, {useState} from 'react';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import {callSafe, identity} from '../../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import {COLOR_ACTION} from '../../constants/SynMUIOptions.js';
import AutoComplete from '../AutoComplete.js';
import Dialog from '../Dialog.js';
import ArrowDropDownIcon from '../icons/ArrowDropDownIcon.js';
import SingleLineTextField from '../SingleLineTextField.js';
import FullScreenAutoComplete from './FullScreenAutoComplete.js';

export default withForwardRef(FullScreenAutoCompleteTextField, 'forwardedRef');

function FullScreenAutoCompleteTextField(props) {
	const {
		forwardedRef, onChange, value, getOptionLabel, getValue, options, label, id, name, error, hint, title, disabled,
		small, fullScreenTextFieldButtons, noMargin
	} = props;
	const [showDialog, setShowDialog] = useState(false);
	const handleDialogOpen = useMemoFactory(createHandleDialogOpen, setShowDialog, disabled);
	const handleDialogClose = useMemoFactory(createHandleDialogClose, setShowDialog);
	const onChangeHandler = useMemoFactory(createOnChangeHandler, setShowDialog, onChange);
	const onCloseHandler = useMemoFactory(createOnCloseHandler, setShowDialog);
	const singleLineTextFieldValue = useMemoFactory(
		getSingleLineTextFieldValue, value, options, getOptionLabel, getValue
	);
	const autoCompleteProps = cloneWithoutProperties(props, 'error', 'hint', 'fullScreenTextFieldButtons');
	const textFieldButtons = useMemoFactory(createTextFieldButtons, fullScreenTextFieldButtons);
	return (
		<React.Fragment>
			<SingleLineTextField value={singleLineTextFieldValue} name={name || id} invalid={error} hint={hint}
			                     title={title} onClick={handleDialogOpen} label={label} small={small}
			                     textFieldButtons={textFieldButtons} disabled={disabled} readOnly noMargin={noMargin} />
			<Dialog show={showDialog} onClose={handleDialogClose} fullScreen title={label}>
				<FullScreenAutoComplete {...autoCompleteProps} ref={forwardedRef} onChange={onChangeHandler}
				                        onClose={onCloseHandler} />
			</Dialog>
		</React.Fragment>
	);
}

FullScreenAutoCompleteTextField.propTypes = AutoComplete.propTypes;
FullScreenAutoCompleteTextField.defaultProps = AutoComplete.defaultProps;

function createTextFieldButtons(fullScreenTextFieldButtons) {
	return (
		<React.Fragment>
			<ArrowDropDownIcon color={COLOR_ACTION} />
			{fullScreenTextFieldButtons}
		</React.Fragment>
	);
}

function createOnChangeHandler(setShowDialog, onChange) {
	return (newValue, reason) => {
		if (reason !== 'clear') {
			setShowDialog(false);
			callSafe(onChange, newValue);
		}
	};
}

function createOnCloseHandler(setShowDialog) {
	return (event, reason) => {
		if (reason === 'selectOption') {
			setShowDialog(false);
		}
	};
}

function createHandleDialogOpen(setShowDialog, disabled) {
	return () => {
		if (!disabled) {
			setShowDialog(true);
		}
	};
}

function createHandleDialogClose(setShowDialog) {
	return () => {
		setShowDialog(false);
	};
}

function getSingleLineTextFieldValue(value, options, getOptionLabel = identity, getValue = identity) {
	return value && getOptionLabel(getValue(value, options));
}
