import createFormAccessors from '../../../commons/data/FormAccessorsFactory.js';
import {required} from '../../../commons/data/validators';
import LoginFormMessagesTranslator from '../../i18n/LoginFormMessagesTranslator.js';

const TWO_FACTOR_LOGIN_FORM_SCHEMA = {
	fields: {
		secondfactor: {
			label: 'VerificationCode',
			defaultValue: null,
			validators: [required]
		}
	}
};

export default createFormAccessors(TWO_FACTOR_LOGIN_FORM_SCHEMA, LoginFormMessagesTranslator);
