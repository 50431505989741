import {bindActionCreators} from 'redux';

/**
 * Creates a function, that can be passed a dispatch function which will then be bound to the initially passed
 * actions mapping via the bindActionCreators function of redux.
 * This finally creates a mapping of bound action creators, that can be used like simple functions and will
 * then dispatch the created actions via the dispatch function.
 * @param actions {Object} a mapping of key -> actionCreator, that will be bound to the passed dispatch function
 * @returns {Function} taking the dispatch function.
 */
export function bindActions(actions) {
	return function dispatchFunction(dispatch) {
		return bindActionCreators(actions, dispatch);
	};
}

/**
 * Creates a factory function that returns a thunk-middleware compatible action-function, which is bound to
 * the passed selectors and final action. The thunk-middleware will detect the function and call it with a
 * dispatch-function and getState-function instead of just passing it on to the next middleware as a simple action.
 * @param selectors {Array} selector functions to select the actual action parameters from the store
 * @param action {function} the actual action creator that will be passed the resolved parameters as
 *        selected by the passed selectors
 * @returns Factory {Function}, creating a bound thunk-middleware compatible action-function.
 */
export function bindThunkActionCreator(selectors, action) {
	return function thunkActionCreator() {
		return function actionFunction(dispatch, getState) {
			let resolvedActionParams = [];
			if (selectors.length > 0) {
				const state = getState();
				resolvedActionParams = selectors.map(selector => selector(state));
			}
			dispatch(action(...resolvedActionParams));
		};
	};
}
