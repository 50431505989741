import Immutable from 'immutable';

export default function createRoute(
		pageSelector,
		linkConditionSelector = () => true, parameterMatchers = Immutable.Map()) {
	return Immutable.Map({
		pageSelector,
		linkConditionSelector,
		parameterMatchers
	});
}
