import React from 'react';
import {bool, number, object, string} from 'prop-types';

import {formatBirthDate} from '../../commons/data/aim/PatientHelpers.js';
import {memoizeLast} from '../../commons/utils/FunctionUtils.js';
import {formatDateTime} from '../../i18n/FormatHelpers.js';
import GenericImageWindowParametersOverlayContainer from '../flux/containers/GenericImageWindowParametersOverlayContainer.js';
import {createDataSelector} from '../utils/MetadataUtils.js';
import MetaDataOverlay from './MetaDataOverlay.js';
import MetaDataOverlayArea from './MetaDataOverlayArea.js';

export default class GenericFileMetaDataOverlay extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundFormatBottomRight = this.formatBottomRight.bind(this);
		this.boundRenderBottomRight = GenericFileMetaDataOverlay.renderBottomRight.bind(this);
		this.boundFormatPatientInformation = this.formatPatientInformation.bind(this);
		this.getOverlayConfig = memoizeLast(this.getOverlayConfig.bind(this));
	}

	render() {
		const {containerWidth, containerHeight, isFullScreen, imageMetadata} = this.props;
		const {topLeft, topRight, bottomLeft, bottomRight} = this.getOverlayConfig(isFullScreen);
		return (
			<MetaDataOverlay containerWidth={containerWidth} containerHeight={containerHeight}>
				<MetaDataOverlayArea key='top-left' align='left' vAlign='top'
									 data={topLeft.dataSelector(imageMetadata)}
									 formatter={topLeft.formatter} />
				<MetaDataOverlayArea key='top-right' align='right' vAlign='top'
									 data={topRight.dataSelector(imageMetadata)} />
				<MetaDataOverlayArea key='bottom-left' align='left' vAlign='bottom'
									 data={bottomLeft.dataSelector(imageMetadata)} />
				<MetaDataOverlayArea key='bottom-right' align='right' vAlign='bottom'
									 data={bottomRight.dataSelector(imageMetadata)}
									 renderer={bottomRight.renderer} formatter={bottomRight.formatter} />
			</MetaDataOverlay>
		);
	}

	static renderBottomRight(areaProps, [documentCreationDate]) {
		return (
			<React.Fragment>
				<GenericImageWindowParametersOverlayContainer xem={0} yem={-1} {...areaProps} />
				<text x={0} y={0}>
					{documentCreationDate}
				</text>
			</React.Fragment>
		);
	}

	getOverlayConfig(isFullScreen) {
		const topLeftDataFields = isFullScreen ? ['PatientInfo', 'PatientBirthDate'] : [];
		return {
			topLeft: {
				dataSelector: createDataSelector(...topLeftDataFields),
				formatter: isFullScreen ? this.boundFormatPatientInformation : undefined
			},
			topRight: {
				dataSelector: createDataSelector('DocumentDescription', 'FileDescription')
			},
			bottomLeft: {dataSelector: createDataSelector('Resolution')},
			bottomRight: {
				dataSelector: createDataSelector('DocumentCreationDate'),
				formatter: this.boundFormatBottomRight,
				renderer: this.boundRenderBottomRight
			}
		};
	}

	formatPatientInformation([patientInfo, birthDate]) {
		const {locale, isSmallDevice} = this.props;
		const birthDateFormat = isSmallDevice ? 'ShortBirthDate' : 'BirthDate';
		return [patientInfo, formatBirthDate(birthDate, birthDateFormat, locale)];
	}

	formatBottomRight([documentCreationDate]) {
		const {isSmallDevice, locale} = this.props;
		const format = isSmallDevice ? 'VeryShortDateTime' : 'ShortDateTime';
		return [formatDateTime(documentCreationDate, format, locale)];
	}
}

GenericFileMetaDataOverlay.propTypes = {
	imageMetadata: object,
	containerWidth: number.isRequired,
	containerHeight: number.isRequired,
	isFullScreen: bool.isRequired,
	isSmallDevice: bool.isRequired,
	locale: string
};
