function doLoadPdfJS() {
	return import(/* webpackChunkName: 'pdfjs', webpackPrefetch: true */ 'pdfjs-dist/build/pdf.js')
		.then(pdfjs => {
			pdfjs.GlobalWorkerOptions.workerPort = new Worker(new URL('pdfjs-dist/build/pdf.worker.js', import.meta.url));
			return pdfjs;
		});
}

let pdfJSModule = null;
let pdfJSModuleLoadError = null;
export default function loadPdfJS() {
	let modulePromise;
	if (pdfJSModule) {
		modulePromise = Promise.resolve(pdfJSModule);
	} else if (pdfJSModuleLoadError) {
		modulePromise = Promise.reject(pdfJSModuleLoadError);
	} else {
		modulePromise = doLoadPdfJS()
			.then(pdfjs => {
				pdfJSModule = pdfjs;
				return pdfJSModule;
			})
			.catch(error => {
				pdfJSModuleLoadError = error;
			});
	}
	return modulePromise;
}

