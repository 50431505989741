import React, {useMemo} from 'react';
import throttled from 'lodash.throttle';
import {bool, func, string} from 'prop-types';

import {memoizeLast} from '../../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../../commons/utils/ObjectUtils';
import ViewerContext from './ViewerContext.js';

const FULLSCREEN_THROTTLE_TIME_MS = 800;

function createViewerContext(contextProps) {
	const {viewerId, isActive, isFullScreen, isPrintPreview} = contextProps;
	const {activate, toggleFullScreen, updateProperties, resetProperties} = contextProps;
	const activateViewer = () => !isActive && activate();
	return {
		viewerId,
		isActive,
		isFullScreen,
		isPrintPreview,
		updateProperties,
		resetProperties,
		activate: activateViewer,
		toggleFullScreen: throttled(toggleFullScreen, FULLSCREEN_THROTTLE_TIME_MS, {leading: true, trailing: false})
	};
}

export default function ViewerContextProvider(props) {
	const {children, ...contextProps} = props;
	const memoizedCreateContext = useMemo(() => memoizeLast(createViewerContext, shallowEqual), []);
	const context = memoizedCreateContext(contextProps);
	return (
		<ViewerContext.Provider value={context}>
			{children}
		</ViewerContext.Provider>
	);
}

ViewerContextProvider.propTypes = {
	viewerId: string.isRequired,
	isActive: bool.isRequired,
	isFullScreen: bool.isRequired,
	isPrintPreview: bool.isRequired,

	activate: func.isRequired,
	toggleFullScreen: func.isRequired,
	updateProperties: func.isRequired
};
