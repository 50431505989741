import React from 'react';
import PropTypes from 'prop-types';

import {binarySearch} from '../../../utils/SeqUtils.js';
import OptimizedGrid from './OptimizedGrid.js';

export default class GridView extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundMeasureCell = this.measureCell.bind(this);

		this.state = {
			firstVisibleRow: null,
			lastVisibleRow: null,
			firstVisibleColumn: null,
			lastVisibleColumn: null
		};
	}

	render() {
		const {viewport, cellGeometries} = this.props;
		return GridView.isViewportOpen(viewport) && cellGeometries ? this.renderList() : false;
	}

	renderList() {
		const {width, height, renderCell, overscanRows, overscanColumns, cellGeometries} = this.props;
		const {firstVisibleRow, lastVisibleRow, firstVisibleColumn, lastVisibleColumn} = this.state;

		const safeOverscanRows = Math.max(0, overscanRows);
		const safeOverscanColumns = Math.max(0, overscanColumns);
		const firstRenderedRow = Math.max(0, firstVisibleRow - safeOverscanRows);
		const lastRenderedRow = Math.min(cellGeometries.length - 1, lastVisibleRow + safeOverscanRows);
		const firstRenderedColumn = Math.max(0, firstVisibleColumn - safeOverscanColumns);
		const lastRenderedColumn = Math.min(cellGeometries[0].length - 1, lastVisibleColumn + safeOverscanColumns);

		return (
			<OptimizedGrid measureCell={this.boundMeasureCell} height={height} width={width}
				firstRow={firstRenderedRow} lastRow={lastRenderedRow}
				firstColumn={firstRenderedColumn} lastColumn={lastRenderedColumn}
				renderCell={renderCell} />
		);
	}

	measureCell(columnIndex, rowIndex) {
		const {cellGeometries} = this.props;
		return cellGeometries[rowIndex][columnIndex];
	}

	shouldComponentUpdate(nextProps, nextState) {
		const {cellGeometries, renderCell, overscanRows, overscanColumns} = this.props;
		const {firstVisibleRow, firstVisibleColumn, lastVisibleRow, lastVisibleColumn} = this.state;
		const relevantPropsChanged =
			cellGeometries !== nextProps.cellGeometries ||
			renderCell !== nextProps.renderCell;

		const relevantStateChanged =
			firstVisibleRow !== nextState.firstVisibleRow ||
			firstVisibleColumn !== nextState.firstVisibleColumn ||
			lastVisibleRow !== nextState.lastVisibleRow ||
			lastVisibleColumn !== nextState.lastVisibleColumn ||
			overscanRows !== nextProps.overscanRows ||
			overscanColumns !== nextProps.overscanColumns;

		return relevantPropsChanged || relevantStateChanged;
	}

	static getDerivedStateFromProps(props, state) {
		let stateUpdate = null;
		const {viewport, cellGeometries, width, height} = props;
		if (GridView.isViewportOpen(viewport) && cellGeometries) {
			const {top, bottom, right, left} = viewport;
			const {firstVisibleRow, lastVisibleRow, firstVisibleColumn, lastVisibleColumn} = state;
			if (
				firstVisibleColumn === null ||
				firstVisibleRow === null ||
				!GridView.isCellAtPosition(firstVisibleColumn, firstVisibleRow, left, top, cellGeometries)
			) {
				stateUpdate = {};
				stateUpdate.firstVisibleRow = Math.min(
					cellGeometries.length - 1,
					Math.max(0, GridView.findRowAtY(Math.max(0, top), cellGeometries)
					)
				);
				const row = cellGeometries[stateUpdate.firstVisibleRow];
				stateUpdate.firstVisibleColumn = Math.min(
					row.length - 1,
					Math.max(0, GridView.findColumnAtX(Math.max(0, left), row)
					)
				);
			}
			if (
				lastVisibleColumn === null ||
				lastVisibleRow === null ||
				!GridView.isCellAtPosition(lastVisibleColumn, lastVisibleRow, right, bottom, cellGeometries)
			) {
				stateUpdate = stateUpdate || {};
				stateUpdate.lastVisibleRow = Math.min(
					cellGeometries.length - 1,
					Math.max(0,
						GridView.findRowAtY(
							Math.min(bottom, height - 1),
							cellGeometries)
					)
				);
				const row = cellGeometries[stateUpdate.lastVisibleRow];
				stateUpdate.lastVisibleColumn = Math.min(
					row.length - 1,
					Math.max(
						0,
						GridView.findColumnAtX(
							Math.min(right, width - 1),
							row)
					)
				);
			}
		}
		return stateUpdate;
	}

	static isCellAtPosition(cellColumn, cellRow, posX, posY, cellGeometries) {
		let isItem = false;
		if (cellRow < cellGeometries.length) {
			const nthRow = cellGeometries[cellRow];
			if (cellColumn < nthRow.length) {
				const nthCell = nthRow[cellColumn];
				isItem = nthCell.top <= posY && nthCell.bottom >= posY &&
					nthCell.left <= posX && nthCell.right >= posX;
			}
		}
		return isItem;
	}

	static rowPositionComparator(row, pos) {
		let result = 0;
		if (row[0].top > pos) {
			result = 1;
		} else if (row[0].bottom < pos) {
			result = -1;
		}
		return result;
	}

	static columnPositionComparator(column, pos) {
		let result = 0;
		if (column.left > pos) {
			result = 1;
		} else if (column.right < pos) {
			result = -1;
		}
		return result;
	}

	static findRowAtY(posY, cellGeometries) {
		return binarySearch(cellGeometries, posY, GridView.rowPositionComparator);
	}

	static findColumnAtX(posX, row) {
		return binarySearch(row, posX, GridView.columnPositionComparator);
	}

	static isViewportOpen(viewport) {
		return viewport && viewport.width > 0 && viewport.height > 0;
	}
}

GridView.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	cellGeometries: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.exact({
				top: PropTypes.number,
				left: PropTypes.number,
				bottom: PropTypes.number,
				right: PropTypes.number,
				width: PropTypes.number,
				height: PropTypes.number
			})
		)
	),
	viewport: PropTypes.object,
	renderCell: PropTypes.func,
	overscanRows: PropTypes.number,
	overscanColumns: PropTypes.number
};

GridView.defaultProps = {
	overscanRows: 0,
	overscanColumns: 0
};
