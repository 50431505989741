import React from 'react';

import Section from '../../a11y/components/landmarks/Section.js';
import A11yTranslator from '../../a11y/i18n/A11yTranslator.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import PrimaryItemText from '../../material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../material-design/components/text/SecondaryItemText.js';

import '../../../styles/authentication/components/UserInfo.scss';

export default wrapInLocalizationContext(function UserInfo({username, loginname, className, locale}) {
	const classes = combineClassNames('user-info', className);
	const labelName = A11yTranslator.getFormattedMessage('A11YUserInfoLabel', locale);
	return (
		<Section className={classes} title={labelName}>
			<div className='user-info--text-container'>
				<PrimaryItemText>
					{username}
				</PrimaryItemText>
				<SecondaryItemText>
					{`(${loginname})`}
				</SecondaryItemText>
			</div>
		</Section>
	);
});
