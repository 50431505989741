import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import {PatientInfoWithGenderAvatar} from '../../webview/commons/components/webview/PatientInfo.js';
import PatientContext from '../bricks/PatientContext.js';
import StepPrepareUploadList from './StepPrepareUploadList.js';

export default function StepPrepareUploadListForPatient(props) {
	const {children, ...remainingProps} = props;
	const patient = useBrick(PatientContext, brick => brick.getPatient());
	return (
		<StepPrepareUploadList {...remainingProps}>
			<PatientInfoWithGenderAvatar patient={patient} disableGenderAvatar />
			{children}
		</StepPrepareUploadList>
	);
}
