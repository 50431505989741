import _once from 'lodash.once';

import {IS_DEBUG_BUILD} from '../../commons/constants/EnvironmentConstants.js';
import {debugError, debugLog, debugWarn} from '../../commons/utils/DebugLog.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';

const DEFAULT_WEBGL_PARAMETERS = {
	webGLSupported: false,
	supportsHighPrecisionFragmentShader: false,
	supportsHighPrecisionVertexShader: false,
	maxTextureSize: 0
};

const queryGLParameters = _once(() => {
	let glParameters = DEFAULT_WEBGL_PARAMETERS;
	const webGLSupported = Boolean(window.WebGLRenderingContext);
	if (webGLSupported) {
		const canvas = document.createElement('canvas');
		// Browser could not initialize WebGL. User probably needs to
		// update their drivers or get a new browser. Present a link to
		// http://get.webgl.org/troubleshooting
		const context = tryToCreateWebGLContext(canvas);
		if (context) {
			initContext(context);
			try {
				glParameters = {
					webGLSupported: true,
					...queryGLParametersFromContext(context)
				};
				if (IS_DEBUG_BUILD) {
					logContextInfo(context, glParameters);
				}
			} catch (e) {
				debugWarn(`Failed to query parameter from glContext due to error: ${e}`);
			}
		}
	}
	return glParameters;
});

export const areWebGLRequirementsMet = _once(() => {
	const glParameters = queryGLParameters();
	return glParameters.webGLSupported &&
		glParameters.supportsHighPrecisionVertexShader &&
		glParameters.supportsHighPrecisionFragmentShader;
});

function isWebGLExplicitlyDisabled() {
	const storageValue = sessionStorage.disableWebGL || localStorage.disableWebGL;
	return Boolean(storageValue) && storageValue.toLowerCase() === 'true';
}

export function hasWebGLSupport() {
	return !isWebGLExplicitlyDisabled() && areWebGLRequirementsMet();
}

export function getMaxTextureSize() {
	const glParameters = queryGLParameters();
	return glParameters.maxTextureSize;
}

export function getMaxNrOfTextures(glContext) {
	return glContext.getParameter(glContext.MAX_COMBINED_TEXTURE_IMAGE_UNITS);
}

function queryGLParametersFromContext(glContext) {
	let glParameters = null;
	if (glContext) {
		glParameters = {
			maxTextureSize: glContext.getParameter(glContext.MAX_TEXTURE_SIZE),
			supportsHighPrecisionVertexShader: isHighPrecisionSupported(glContext, glContext.VERTEX_SHADER),
			supportsHighPrecisionFragmentShader: isHighPrecisionSupported(glContext, glContext.FRAGMENT_SHADER)
		};
	}
	return glParameters;
}

function isHighPrecisionSupported(gl, shaderType) {
	const floatHighPrecisionFormat = gl.getShaderPrecisionFormat(shaderType, gl.HIGH_FLOAT);
	return floatHighPrecisionFormat.precision !== 0;
}

export function createWebGLContext(canvas) {
	const gl = tryToCreateWebGLContext(canvas);
	if (gl) {
		initContext(gl);
	}
	return gl;
}

function tryToCreateWebGLContext(canvas) {
	const contextOptions = {
		preserveDrawingBuffer: true,
		stencil: false,
		depths: false,
		antialias: false
	};
	return canvas.getContext('webgl', contextOptions) || canvas.getContext('experimental-webgl', contextOptions);
}

function logContextInfo(glContext, glParameters) {
	if (glContext) {
		debugLog('WebGLContextInfo: ', {
			...glParameters, ShaderPrecisionFormat: {
				HIGH_FLOAT: {
					FRAGMENT_SHADER: glContext.getShaderPrecisionFormat(
						glContext.FRAGMENT_SHADER, glContext.HIGH_FLOAT
					),
					VERTEX_SHADER: glContext.getShaderPrecisionFormat(
						glContext.VERTEX_SHADER, glContext.HIGH_FLOAT
					)
				},
				LOW_FLOAT: {
					FRAGMENT_SHADER: glContext.getShaderPrecisionFormat(
						glContext.FRAGMENT_SHADER, glContext.LOW_FLOAT
					),
					VERTEX_SHADER: glContext.getShaderPrecisionFormat(
						glContext.VERTEX_SHADER, glContext.LOW_FLOAT
					)
				}
			}
		});
	}
}

function initContext(glContext) {
	glContext.clearColor(0.0, 0.0, 0.0, 0.0); // Fully transparent background
	glContext.enable(glContext.BLEND); // Activate blending
	glContext.blendFunc(glContext.SRC_ALPHA, glContext.ONE); // Traditional Alpha-Blending.
	glContext.clear(glContext.COLOR_BUFFER_BIT | glContext.DEPTH_BUFFER_BIT); // Clear buffer
}

function getShaderOfType(gl, source, shaderType) {
	const shader = gl.createShader(shaderType);
	gl.shaderSource(shader, source);
	gl.compileShader(shader);
	if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
		debugError(`Could not compile shader: ${gl.getShaderInfoLog(shader)}`);
		return null;
	}
	return shader;
}

export function getFragmentShader(gl, source) {
	return getShaderOfType(gl, source, gl.FRAGMENT_SHADER);
}

export function getVertexShader(gl, source) {
	return getShaderOfType(gl, source, gl.VERTEX_SHADER);
}

export function initializeProgram(gl, fragmentShaderSource, vertexShaderSource, preprocessorDirectives = []) {
	const shaderProgram = gl.createProgram();

	gl.attachShader(shaderProgram,
		getFragmentShader(gl, addPreprocessorDirectives(preprocessorDirectives, fragmentShaderSource))
	);
	gl.attachShader(shaderProgram,
		getVertexShader(gl, addPreprocessorDirectives(preprocessorDirectives, vertexShaderSource))
	);
	gl.linkProgram(shaderProgram);

	const linkStatus = gl.getProgramParameter(shaderProgram, gl.LINK_STATUS);
	if (!linkStatus) {
		debugError(`Could not link shader program. : ${gl.getProgramInfoLog(shaderProgram)}`);
	}
	return shaderProgram;
}

export function createPreprocessorDefine(key, value) {
	return `#define ${key} ${value}`;
}

export function getDefaultPreprocessorDirectives() {
	return IS_DEBUG_BUILD ? [createPreprocessorDefine('DEBUG_MODE', '1')] : [];
}

function addPreprocessorDirectives(preprocessorDirectives, shaderSource) {
	const joinedPreprocessorDirectives = preprocessorDirectives.join('\n');
	return joinedPreprocessorDirectives ? (`${joinedPreprocessorDirectives}\n${shaderSource}`) : shaderSource;
}

export function setAttribute(gl, program, attributeName, buffer, {dimension, type = gl.FLOAT, normalized = false}) {
	const attributeLocation = gl.getAttribLocation(program, attributeName);
	gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
	gl.enableVertexAttribArray(attributeLocation);
	gl.vertexAttribPointer(
		attributeLocation,
		dimension,
		type,
		normalized, //see http://webglfundamentals.org/webgl/lessons/webgl-how-it-works.html
		0,
		0
	);
}

export function disableAttribute(gl, program, attributeName) {
	const attributeLocation = gl.getAttribLocation(program, attributeName);
	gl.disableVertexAttribArray(attributeLocation);
}

export function createEmptyTexture(glContext, width, height) {
	return createTexture(glContext, gl => {
		gl.texImage2D(
			gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0,
			gl.RGBA, gl.UNSIGNED_BYTE, null
		);
	});
}

export function createFramebuffer(gl, width, height) {
	const renderbuffer = gl.createRenderbuffer();
	gl.bindRenderbuffer(gl.RENDERBUFFER, renderbuffer);
	gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, width, height);

	const texture = createEmptyTexture(gl, width, height);
	const framebuffer = gl.createFramebuffer();
	gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);

	gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
	gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, renderbuffer);

	//cleanup part
	gl.bindFramebuffer(gl.FRAMEBUFFER, null);
	gl.bindRenderbuffer(gl.RENDERBUFFER, null);

	framebuffer.texture = texture;

	return framebuffer;
}

export function createTexture(gl, textureInitializationFunction) {
	const texture = gl.createTexture();
	gl.bindTexture(gl.TEXTURE_2D, texture);

	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
	gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

	callSafe(textureInitializationFunction, gl);

	gl.bindTexture(gl.TEXTURE_2D, null);
	return texture;
}

export function setTextures(gl, program, textures) {
	Object.keys(textures).forEach((textureName, textureIndex) => {
		gl.activeTexture(gl[`TEXTURE${textureIndex}`]);
		gl.bindTexture(gl.TEXTURE_2D, textures[textureName]);
		gl.uniform1i(gl.getUniformLocation(program, textureName), textureIndex);
	});
}

export function setMatrix(gl, program, uniformName, matrix) {
	const matrixUniform = gl.getUniformLocation(program, uniformName);
	gl.uniformMatrix4fv(matrixUniform, false, matrix);
}

export function setFloat(gl, program, uniformName, floatValue) {
	const matrixUniform = gl.getUniformLocation(program, uniformName);
	gl.uniform1f(matrixUniform, floatValue);
}

export function createArrayBuffer(gl, bufferData) {
	const buffer = gl.createBuffer();

	gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
	gl.bufferData(gl.ARRAY_BUFFER, bufferData, gl.STATIC_DRAW);
	gl.bindBuffer(gl.ARRAY_BUFFER, null);

	return buffer;
}

export function canLoadTextureOfSize(width, height) {
	const glParameters = queryGLParameters();
	return width <= glParameters.maxTextureSize && height <= glParameters.maxTextureSize;
}
