import React from 'react';

import LandingPage from '../../commons/components/webview/LandingPage.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import LoginWorkflowControllerContainer from '../flux/containers/LoginWorkflowControllerContainer.js';
import LoginPageMessagesTranslator from '../i18n/LoginPageMessagesTranslator.js';

const PAGE_TITLE = <SynFormattedMessage translator={LoginPageMessagesTranslator} message='Title' />;

export default function LoginPage() {
	return (
		<LandingPage headerTemplate='loginPage_header' footerTemplate='loginPage_footer' title={PAGE_TITLE}>
			<LoginWorkflowControllerContainer />
		</LandingPage>
	);
}
