import React from 'react';

import {restoreFromHistory} from '../../router/flux/LocationActions.js';
import LocationReducer from '../../router/flux/LocationReducer.js';
import {updateWindowLayout} from '../../ui/flux/UIActions.js';
import initUI from '../appStartup/initUI.js';
import cleanUpServiceWorkersAndCaches from '../cleanUpServiceWorkersAndCaches.js';
import CacheReducer from '../reducers/CacheReducer.js';
import PersistentPortalReducer from '../reducers/PersistentPortalReducer.js';
import UnhandledErrorsReducer from '../reducers/UnhandledErrorsReducer.js';
import attachJavaScriptRuntimeErrorsRecorder from './attachJavaScriptRuntimeErrosRecorder.js';
import detectPrintMode from './detectPrintMode.js';
import {createSynRedux} from './SynFlux.js';

export default function basicApplicationFlux(routes, reducers, rootSaga = null, allowedMetrics = null) {
	const reduxStore = createStore(routes, reducers, rootSaga, allowedMetrics);
	attachJavaScriptRuntimeErrorsRecorder(reduxStore.dispatch);
	watchWindowSize(reduxStore);
	detectPrintMode(reduxStore, 'print-mode');
	watchBrowserHistory(reduxStore);
	cleanUpServiceWorkersAndCaches();

	initUI(reduxStore);
	syncWithBrowserLocation(reduxStore);

	return reduxStore;
}

function createStore(routes, reducers, rootSaga, allowedMetrics) {
	return createSynRedux({
		routes: () => routes,
		cache: CacheReducer,
		location: LocationReducer,
		persistentPortals: PersistentPortalReducer,
		unhandledErrors: UnhandledErrorsReducer, ...reducers
	}, rootSaga, allowedMetrics);
}

function syncWithBrowserLocation(reduxStore, state = null) {
	reduxStore.dispatch(restoreFromHistory(window.location.href, state));
}

function watchBrowserHistory(reduxStore) {
	window.addEventListener('popstate', event => {
		syncWithBrowserLocation(reduxStore, event.state);
	});
}

function watchWindowSize(reduxStore) {
	function updateSize() {
		reduxStore.dispatch(updateWindowLayout(undefined, window.innerWidth, window.innerHeight));
	}
	window.addEventListener('resize', () => updateSize());
}
