import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';
import _partial from 'lodash.partial';
import PropTypes from 'prop-types';

import AnnotationsTranslator from '../../../i18n/translators/AnnotationsTranslator.js';
import {
	alignRectToCircle,
	createMemoizedTextMeasureTool,
	determinePixelSize, getAnnotationDisplayPrecision, getAreaWithUnit, getLengthWithUnit,
	getLineAroundContainerCenter,
	snapToRectInplace,
	toContainerPosition,
	toImagePosition
} from '../../utils/AnnotationUtils.js';
import {createRectangle, distanceToPoint2d, moveRectInplace} from '../../utils/math/Rectangle.js';
import {renderMultilineTextElements} from '../../utils/SVGUtils.js';
import {getDicomDump} from '../../utils/ViewerItemUtils.js';
import {TOUCH_SIZE} from './AnnotationConstants.js';
import AnnotationLabel from './AnnotationLabel.js';
import Circle from './Circle.js';
import createAnnotation, {ANNOTATION_PROP_TYPES} from './createAnnotation.js';

import '../../../../styles/viewer/components/annotations/AnnotationBaseStyles.scss';
import '../../../../styles/viewer/components/annotations/AnnotationLabel.scss';

function isCircleAnnotationSupported(viewerItem) {
	return Boolean(viewerItem);
}

function getDefaultPropertiesForCircleAnnotation(props) {
	return Immutable.Map({
		points: getLineAroundContainerCenter(props).map(point => toImagePosition(props, point))
	});
}

class CircleAnnotationRenderer extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.textMeasureTool = createMemoizedTextMeasureTool();
	}

	render() {
		const {
			AnnotationRoot, annotationProperties, transformationMatrix,
			inverseTransformationMatrix, isPrintPreview, showMeasuredArea, readOnly
		} = this.props;
		const points = annotationProperties.get('points');
		return (
			<AnnotationRoot>
				<Circle isPrintPreview={isPrintPreview} points={points}
						  inverseTransformationMatrix={inverseTransformationMatrix}
						  transformationMatrix={transformationMatrix}
						  readOnly={readOnly} />
				{showMeasuredArea &&
						this.renderLabel(points)
				}
			</AnnotationRoot>
		);
	}

	renderLabel(points) {
		const {locale, viewerItem, containerWidth, containerHeight, fontSize, lineHeight} = this.props;
		const containerPositions = points.map(point => toContainerPosition(this.props, point));
		const [circleCenter] = containerPositions;
		const labelText = calculateLabelContent(locale, points, getDicomDump(viewerItem));
		const textDimensions = this.textMeasureTool(labelText, 'annotation-label', fontSize, lineHeight);
		const radius = vec2.distance(containerPositions[0], containerPositions[1]);
		const labelDistanceRadius = radius + TOUCH_SIZE / 2;
		const labelDistance = Math.sqrt(Math.pow(labelDistanceRadius, 2.0) / 2.0);

		const labelRectangle = createRectangle(
			[0, 0], [textDimensions.width, textDimensions.height]
		);
		moveRectInplace(labelRectangle, labelDistance + 1, labelDistance + 1);
		moveRectInplace(labelRectangle, circleCenter[0], circleCenter[1]);
		moveRectInplace(labelRectangle, 0, -fontSize / 2);

		const viewportRect = createRectangle(
			[-containerWidth / 2, -containerHeight / 2],
			[containerWidth / 2, containerHeight / 2]
		);
		const textRect = alignRectToCircle(
			labelRectangle,
			{center: circleCenter, radius: labelDistanceRadius},
			viewportRect
		);
		snapToRectInplace(textRect, createRectangle(
			[circleCenter[0] - labelDistanceRadius, circleCenter[1] - labelDistanceRadius],
			[circleCenter[0] + labelDistanceRadius, circleCenter[1] + labelDistanceRadius]
		));
		const textPosition = [textRect.topLeft[0], textRect.topLeft[1] + fontSize / 2];
		return (
			<AnnotationLabel x={textPosition[0]} y={textPosition[1]} textAnchor='start'>
				{renderMultilineTextElements(labelText, lineHeight, {x: '0'})}
			</AnnotationLabel>
		);
	}
}
CircleAnnotationRenderer.propTypes = {
	...ANNOTATION_PROP_TYPES,
	showMeasuredArea: PropTypes.bool
};

function calculateLabelContent(locale, points, dicomDump) {
	const pixelSize = determinePixelSize(points, dicomDump, _partial(containsWholeCircle, points));
	const diameterLabel = AnnotationsTranslator.getFormattedMessage('Diameter', locale);
	const circumferenceLabel = AnnotationsTranslator.getFormattedMessage('Circumference', locale);
	const areaLabel = AnnotationsTranslator.getFormattedMessage('Area', locale);
	let text;
	if (pixelSize) {
		const {diameter, circumference, area} = calculateDiameterAreaCircumference(points, pixelSize);
		const displayPrecision = getAnnotationDisplayPrecision();
		const diameterWithUnit = getLengthWithUnit(diameter, displayPrecision);
		const circumferenceWithUnit = getLengthWithUnit(circumference, displayPrecision);
		const areaWithUnit = getAreaWithUnit(area, displayPrecision);
		text = [
			`${diameterLabel}: ${diameterWithUnit}`,
			`${circumferenceLabel}: ${circumferenceWithUnit}`,
			`${areaLabel}: ${areaWithUnit}`
		].join('\n');
	} else {
		text = AnnotationsTranslator.getFormattedMessage('MeasurementNotPossible', locale);
	}
	return text;
}

function containsWholeCircle(points, region) {
	const regionPixelSize = region.getPhysicalDelta();
	const regionRect = region.getImageRect();
	const scaledPoints = points
		.map(point => vec2.subtract(new Float64Array(2), point, regionRect.topLeft))
		.map(point => vec2.mul(point, point, regionPixelSize));
	const scaledRadius = vec2.distance(scaledPoints[0], scaledPoints[1]);
	const scaledRegionRect = createRectangle(
		[0.0, 0.0],
		[regionRect.width * regionPixelSize[0], regionRect.height * regionPixelSize[1]]
	);
	const edgeDistance = distanceToPoint2d(scaledRegionRect, scaledPoints[0]);
	return edgeDistance[0] <= -scaledRadius && edgeDistance[1] <= -scaledRadius;
}

function calculateDiameterAreaCircumference(points, pixelSize) {
	//Float 64 for higher precision in this case
	const imagePlaneCoordinates = points.map(pixel => vec2.multiply(new Float64Array(2), pixel, pixelSize));
	const radius = vec2.distance(imagePlaneCoordinates[0], imagePlaneCoordinates[1]);
	return {
		diameter: radius * 2,
		circumference: 2 * radius * Math.PI,
		area: radius * radius * Math.PI
	};
}

export const CircleAnnotation = createAnnotation(
	isCircleAnnotationSupported, getDefaultPropertiesForCircleAnnotation, CircleAnnotationRenderer
);

function isCircleMeasurementSupported(viewerItem) {
	const dicomDump = getDicomDump(viewerItem);
	return Boolean(viewerItem) && Boolean(dicomDump) && dicomDump.getPixelSize() !== null;
}

function CircleMeasurementRenderer(props) {
	return <CircleAnnotationRenderer {...props} showMeasuredArea />;
}

export const CircleMeasurement = createAnnotation(
	isCircleMeasurementSupported, getDefaultPropertiesForCircleAnnotation, CircleMeasurementRenderer
);
