import React from 'react';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import {createSelector} from 'reselect';

import LinkButton from '../../../commons/components/webview/LinkButton.js';
import {FEATURE_INBOX_ENABLED, FEATURE_SEARCH_ENABLED} from '../../../commons/constants/SynSettingsConstants.js';
import {webAppURL} from '../../../commons/WebViewHelpers.js';
import {fragmentSelector, pathSelector, querySelector} from '../../../router/flux/selectors/LocationSelectors.js';
import {getCurrentRouteVariables} from '../../../router/flux/selectors/RoutingSelectors.js';
import {fromUrlString} from '../../../router/LocationUtils.js';
import IconButton from '../../../ui/components/IconButton.js';
import {uiIsMobileDevice} from '../../../ui/flux/UISelectors.js';
import {VIEWER_QUERY_PROPERTY_SHOW_VIEWER} from '../../../viewer/constants/ViewerConstants.js';
import {selectRefererLocation} from '../selectors/PatientDetailsLoaderSelectors.js';

function itemTypeSelector(state) {
	return getCurrentRouteVariables(state).get('itemType');
}

const backLinkPathSelector = createSelector(
	itemTypeSelector,
	itemType => {
		let finalItemType = itemType;
		switch (itemType) {
			case 'document':
				if (!FEATURE_SEARCH_ENABLED) {
					finalItemType = 'documentshare';
				}
				break;
			case 'documentshare':
				if (!FEATURE_INBOX_ENABLED) {
					finalItemType = 'document';
				}
				break;
			default:
				// Leave the itemType unchanged
				break;
		}
		return webAppURL(finalItemType);
	}
);

const PatientDetailsBackLinkContainer = connect(
	createSelector(
		uiIsMobileDevice,
		backLinkPathSelector,
		pathSelector,
		querySelector,
		fragmentSelector,
		selectRefererLocation,
		(isMobile, backLinkPath, path, query, fragment, refererLocation) => {
			const showsMobileViewer = isMobile && query.get(VIEWER_QUERY_PROPERTY_SHOW_VIEWER, false);
			if (refererLocation && !showsMobileViewer) {
				return {location: fromUrlString(refererLocation)};
			}
			return {
				location: Immutable.Map({
					path: showsMobileViewer ? path : backLinkPath,
					query: showsMobileViewer ? query.delete(VIEWER_QUERY_PROPERTY_SHOW_VIEWER) : query.get('originQueryParams', Immutable.Map()),
					fragment: showsMobileViewer ? fragment : ''
				})
			};
		}
	),
	{}
)(LinkButton);

PatientDetailsBackLinkContainer.defaultProps = {
	...PatientDetailsBackLinkContainer.defaultProps,
	buttonComponent: IconButton,
	color: 'inherit'
};

export default PatientDetailsBackLinkContainer;
