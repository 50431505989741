import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../utils/customHooks/index.js';
import {combineClassNames} from '../../utils/StyleUtils.js';

import '../../../../styles/commons/components/layout/GridLayout.scss';

export default React.memo(GridLayout);

function GridLayout(props) {
	const {columns, rows, className, children} = props;
	const classNames = useMemoFactory(combineClassNames, 'grid-layout', `grid-columns-${columns}`, `grid-rows-${rows}`, className);
	return (
		<div className={classNames}>
			{children}
		</div>
	);
}

GridLayout.propTypes = {
	columns: PropTypes.number,
	rows: PropTypes.number,
	className: PropTypes.string
};
