export const INBOX_ID_MAP = 'id2index';
export const INBOX_SHARES = 'shares';
export const INBOX_SHARES_ID_MAP = `${INBOX_SHARES}_${INBOX_ID_MAP}`;
export const INBOX_FILTERED_SHARES = 'filteredShares';
export const INBOX_FILTERED_ID_MAP = `filtered_${INBOX_ID_MAP}`;
export const INBOX_QUERY_FILTER_PARAM = 'filter';
export const INBOX_LAST_FETCH_TIME_STAMP = 'inboxLastFetchTimeStamp';
export const INBOX_SCROLL_COMPONENT_ID = 'InboxResultList';
export const INBOX_FETCH_ID = 'INBOX_FETCH_ID';
export const INBOX_FETCH_ERROR = 'INBOX_FETCH_ERROR';
