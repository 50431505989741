import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../utils/ObjectUtils/index.js';
import {withForwardRef} from '../utils/ReactUtils.js';
import Link from './Link.js';

function ConditionalLink(props) {
	const {linkComponent, allowed, forwardedRef, ...remainingProps} = props;
	const linkProps = cloneWithoutProperties(remainingProps, 'dispatch');
	const LinkComponent = linkComponent || Link;
	return allowed ? <LinkComponent ref={forwardedRef} {...linkProps} /> : false;
}

ConditionalLink.propTypes = {
	...Link.propTypes,
	linkComponent: PropTypes.elementType,
	allowed: PropTypes.bool,
	forwardedRef: withForwardRef.PropTypes.Ref
};

export default ConditionalLink;
