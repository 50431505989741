import {useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useMemoFactory} from '../../commons/utils/customHooks/index';
import {VisibilityIcon, VisibilityOffIcon} from '../../ui/components/icons/VisibilityIcon';
import ViewerContext from '../components/ViewerContext';
import {setAnnotationsVisible} from '../flux/actions/ImageViewerActions';
import {createSelectShowAnnotations} from '../flux/selectors/ImageViewerSelectors';


export function useToggleAnnotationState() {
	const {viewerId} = useContext(ViewerContext);
	const selectShowAnnotations = useMemoFactory(createSelectShowAnnotations, viewerId);
	const annotationsVisible = useSelector(selectShowAnnotations);
	const dispatch = useDispatch();

	const toggleAnnotations = useCallback(
		() => {
			dispatch(
				setAnnotationsVisible(viewerId, !annotationsVisible)
			);
		}, [dispatch, viewerId, annotationsVisible]);

	const icon = annotationsVisible ? VisibilityOffIcon : VisibilityIcon;
	return [toggleAnnotations, icon];
}
