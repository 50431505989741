import {getFileExtension} from './StringUtils.js';

export function createFilterAllowedFileExtensions(fileExtensionList) {
	const lowerCaseFileExtensionList = fileExtensionList.map(fileExtension => fileExtension.toLowerCase());
	return createFileListFilter(fileEntry => lowerCaseFileExtensionList.includes(
		getFileExtension(fileEntry.name).toLowerCase()
	));
}

export function createFilterMaxFileSize(maxFileSizeBytes) {
	return createFileListFilter(fileEntry => fileEntry.size <= maxFileSizeBytes);
}

export function createFilterAllowedMimeTypes(mimeTypesList) {
	return function filterMimeTypes(filesList) {
		return filesList.filter(newFile => mimeTypesList.includes(newFile.type));
	};
}

function createFileListFilter(conditionFunction) {
	return function filterFileSize(fileList) {
		return fileList.filter(conditionFunction);
	};
}
