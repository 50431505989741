import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import GridListConstants from '../../../constants/GridListConstants.json';
import A11yGrid from '../../../webview/a11y/components/A11yGrid.js';
import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../../webview/commons/bricks/DeviceInfo.js';
import VirtualizedList from '../../../webview/commons/components/data/VirtualizedList.js';
import makeMeasured from '../../../webview/commons/components/makeMeasured.js';
import {useMemoFactory} from '../../../webview/commons/utils/customHooks/index.js';
import {withForwardRef} from '../../../webview/commons/utils/ReactUtils.js';
import HorizontalLayout from '../../../webview/ui/components/layout/HorizontalLayout.js';
import VerticalLayout from '../../../webview/ui/components/layout/VerticalLayout.js';
import {uiWindowSize} from '../../../webview/ui/flux/UISelectors.js';
import UploadFilesBrowserController from '../../bricks/UploadFilesBrowserController.js';
import UploadFilesCollection from '../../bricks/UploadFilesCollection.js';
import FileBrowserTools from '../FileBrowserTools.js';
import {MINIMUM_WINDOW_HEIGHT_UPLOAD_LIST} from '../StepPrepareUploadList.js';
import UploadRestoreTrashButton from '../UploadRestoreTrashButton.js';
import SelectableUploadFileTile from './SelectableUploadFileTile.js';

const TILE_WIDTH = GridListConstants['tile-width'];

function UploadFilesGrid(props) {
	const {visible, forwardRef, width} = props;
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const {getPreparedFiles, numPreparedFiles} = useBrick(UploadFilesCollection, selectUploadFilesCollectionProps);
	const resetFilesSelection = useBrick(UploadFilesBrowserController, service => service.resetFilesSelection);
	const tilesInRow = Math.floor(width / TILE_WIDTH);
	const rowsCount = tilesInRow > 0 ? Math.ceil(numPreparedFiles / tilesInRow) : 0;
	const boundRenderListEntry = useMemoFactory(
		createListEntryRenderer, getPreparedFiles, tilesInRow, numPreparedFiles
	);
	useEffect(() => resetFilesSelection, [resetFilesSelection]);
	const GridComponent = useMemoFactory(createGridComponent, rowsCount);
	const windowHeight = useBrick(DeviceInfo, deviceInfo => deviceInfo.getWindowSize().get('height'));
	const showRestoreTrashButton = windowHeight <= MINIMUM_WINDOW_HEIGHT_UPLOAD_LIST;
	return (
		<VerticalLayout ref={forwardRef} hidden={!visible} justify='start'>
			{isMobileDevice && (
				<HorizontalLayout justify='end'>
					{showRestoreTrashButton && <UploadRestoreTrashButton />}
					<FileBrowserTools />
				</HorizontalLayout>
			)}
			<div style={{height: '100%'}}>
				<VirtualizedList overscanCount={3} itemCount={rowsCount} itemSize={TILE_WIDTH}
				                 innerElementType={GridComponent}>
					{boundRenderListEntry}
				</VirtualizedList>
			</div>
		</VerticalLayout>
	);
}

UploadFilesGrid.propTypes = {
	visible: PropTypes.bool,
	forwardRef: withForwardRef.PropTypes.Ref,
	width: PropTypes.number
};

UploadFilesGrid.defaultProps = {
	visible: true
};

function createGridComponent(columnCount) {
	return function GridWithRowCount(props) {
		return <A11yGrid rowCount={columnCount} {...props} />;
	};
}

function selectUploadFilesCollectionProps(service) {
	return {
		getPreparedFiles: service.getPreparedFiles,
		numPreparedFiles: service.getPreparedFiles().size
	};
}

function createListEntryRenderer(getPreparedFiles, tilesInRow) {
	return function ListEntryRenderer(listProps) {
		const {index, style} = listProps;
		const startIndex = index * tilesInRow;
		const row = Math.floor(startIndex / tilesInRow) + 1;
		const rowSubSet = getPreparedFiles().slice(startIndex, startIndex + tilesInRow);
		return (
			<HorizontalLayout style={style} justify='start' role='row'>
				{rowSubSet.map((file, idx) => (
					<SelectableUploadFileTile key={file} file={file} row={row} column={idx + 1} />
				))}
			</HorizontalLayout>
		);
	};
}

export default connect(createStructuredSelector({
	windowSize: uiWindowSize
}))(makeMeasured(
	({windowSize: nextWS}, {windowSize}) => nextWS !== windowSize,
	withForwardRef(UploadFilesGrid, 'forwardRef')
));
