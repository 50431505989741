import {all} from 'redux-saga/effects';

import passwordManagementSaga from '../../authentication/sagas/passwordManagementSaga.js';
import sessionSaga from '../../authentication/sagas/sessionSaga.js';
import historySynchronizationSaga from '../../router/sagas/historySynchronizationSaga.js';
import createPrivateShareSaga from '../../share-document/sagas/createPrivateShareSaga.js';

export default function* webAppSaga() {
	yield all([
		historySynchronizationSaga(),
		createPrivateShareSaga(),
		passwordManagementSaga(),
		sessionSaga()
	]);
}
