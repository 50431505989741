const IS_EMPTY_CONDITIONS = [
	function isNullContainer(container) {
		return container === null;
	},

	function isUndefinedContainer(container) {
		return container === undefined;
	},

	function isEmptyCondition(container) {
		return container.isEmpty !== undefined && typeof (container.isEmpty) === 'function' && container.isEmpty();
	},

	function lengthCondition(container) {
		return container.length !== undefined && container.length === 0;
	},

	function sizeCondition(container) {
		return container.size !== undefined && container.size === 0;
	},

	function objectCondition(container) {
		return typeof (container) === 'object' && Object.keys(container).length === 0;
	}
];


export function isEmptyContainer(container) {
	return IS_EMPTY_CONDITIONS.some(condition => condition(container));
}

export function vacuumContainerAlongPath(container, path) {
	if (Boolean(container) && container.getIn !== undefined && typeof (container.getIn) === 'function' &&
		Boolean(path) && path.length !== undefined) {
		return vacuumPathChecked(container, path);
	}
	return container;
}

function vacuumPathChecked(container, path) {
	let newContainer = container;
	let workPath = path;
	let continueRemoving = true;
	while (continueRemoving && workPath.length > 0) {
		const value = newContainer.getIn(workPath, null);
		continueRemoving = isEmptyContainer(value);
		if (continueRemoving) {
			newContainer = newContainer.deleteIn(workPath);
		}
		workPath = workPath.slice(0, workPath.length - 1);
	}
	return newContainer;
}
