import {UPDATE_VIEWER_ITEMS} from '../../viewer/constants/ViewerItemsActionTypes.js';
import {UPDATE_PERSISTENT_PORTAL} from '../constants/PersistentPortalActionTypes.js';

const DEFAULT_SANITIZATION_STRING = '<<SANITIZED_CONTENT>>';

export function actionSanitizer(action) {
	const actionSanitizers = {
		...persistentPortalActionsSanitizer,
		...viewerItemsActionsSanitizer
	};
	if (action.type in actionSanitizers) {
		return actionSanitizers[action.type](action);
	}
	return action;
}

export function stateSanitizer(state) {
	const stateSanitizers = [
		persistentPortalStateSanitizer,
		viewerItemsStateSanitizer
	];
	return stateSanitizers.reduce((prev, currentStateSanitizer) => currentStateSanitizer(prev), state);
}

const persistentPortalActionsSanitizer = {
	[UPDATE_PERSISTENT_PORTAL]: action => ({
		...action,
		payload: {...action.payload, content: DEFAULT_SANITIZATION_STRING}
	})
};

function persistentPortalStateSanitizer(state) {
	if (state.persistentPortals) {
		return {
			...state,
			persistentPortals: state.persistentPortals.map(() => DEFAULT_SANITIZATION_STRING)
		};
	}
	return state;
}

const viewerItemsActionsSanitizer = {
	[UPDATE_VIEWER_ITEMS]: action => ({
		...action,
		payload: DEFAULT_SANITIZATION_STRING
	})
};

function viewerItemsStateSanitizer(state) {
	if (state.viewerItems) {
		return {
			...state,
			viewerItems: state.viewerItems.map(() => DEFAULT_SANITIZATION_STRING)
		};
	}
	return state;
}
