import React from 'react';
import _throttle from 'lodash.throttle';
import {createSelector, createStructuredSelector} from 'reselect';

import BrickBase from '../../bricks/BrickBase.js';
import {declareBrick} from '../../bricks/brickTools.js';
import {uiDeviceSize, uiIsMobileDevice, uiIsSmallDevice, uiWindowSize} from '../../ui/flux/UISelectors.js';
import {callSafe} from '../utils/FunctionUtils.js';
import {shallowEqual} from '../utils/ObjectUtils';

const MAX_VIEWPORT_UPDATE_DELAY = 100;

export default class DeviceInfo extends BrickBase {
	constructor(reduxStore) {
		super();
		this.updateBrickState(() => ({
			viewport: {}
		}));
		this.subscribeTo(reduxStore, createOnReduxStoreChanged(reduxStore, this.onStoreChange));
		watchViewport(_throttle(this.onViewportChange.bind(this), MAX_VIEWPORT_UPDATE_DELAY));
	}

	onStoreChange(newState) {
		this.updateBrickState(oldState => ({
			...oldState,
			storeState: newState
		}));
	}

	onViewportChange(newViewport) {
		this.updateBrickState(oldState => {
			const {viewport: oldViewport} = oldState;
			return {
				...oldState,
				viewport: shallowEqual(oldViewport, newViewport) ? oldViewport : newViewport
			};
		});
	}

	getViewport() {
		return this.getBrickState().viewport;
	}

	getDeviceSize() {
		return this.getBrickState().storeState.deviceSize;
	}

	isMobileDevice() {
		return this.getBrickState().storeState.isMobileDevice;
	}

	isSmallDevice() {
		return this.getBrickState().storeState.isSmallDevice;
	}

	getWindowSize() {
		return this.getBrickState().storeState.windowSize;
	}
}
declareBrick(DeviceInfo);

function createStoreSelector() {
	return createStructuredSelector({
		deviceSize: uiDeviceSize,
		isMobileDevice: uiIsMobileDevice,
		isSmallDevice: uiIsSmallDevice,
		windowSize: uiWindowSize
	});
}

function createOnReduxStoreChanged(reduxStore, callback) {
	const selector = createSelector(
		createStoreSelector(),
		callback
	);
	return () => selector(reduxStore.getState());
}

function watchViewport(callback) {
	if (window.visualViewport) {
		return watchVisualViewport(callback);
	}
	return watchWindowViewport(callback);
}

function watchVisualViewport(callback) {
	function onViewportChangeHandler() {
		const newViewport = Object.freeze({
			left: window.visualViewport.offsetLeft,
			top: window.visualViewport.offsetTop,
			width: window.visualViewport.width,
			height: window.visualViewport.height
		});
		callSafe(callback, newViewport);
	}
	window.visualViewport.addEventListener('resize', onViewportChangeHandler);
	window.visualViewport.addEventListener('scroll', onViewportChangeHandler);
	onViewportChangeHandler();

	return function unSubscribe() {
		window.visualViewport.removeEventListener('resize', onViewportChangeHandler);
		window.visualViewport.removeEventListener('scroll', onViewportChangeHandler);
	};
}

function watchWindowViewport(callback) {
	function onViewportChangeHandler() {
		const newViewport = Object.freeze({
			left: window.document.documentElement.scrollLeft,
			top: window.document.documentElement.scrollTop,
			width: window.innerWidth,
			height: window.innerHeight
		});
		callSafe(callback, newViewport);
	}
	window.addEventListener('resize', onViewportChangeHandler);
	window.document.addEventListener('scroll', onViewportChangeHandler);
	onViewportChangeHandler();

	return function unSubscribe() {
		window.removeEventListener('resize', onViewportChangeHandler);
		window.document.removeEventListener('scroll', onViewportChangeHandler);
	};
}
