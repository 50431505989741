import React from 'react';
import {useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import {uiShowPrintLayout} from '../../flux/UISelectors.js';

/*
	Design for newer devices for Sensor Housing Notch or Home Indicator Area.
	https://www.w3.org/TR/css-round-display-1
	https://drafts.csswg.org/css-env-1
*/

const VERTICAL_SAFE_AREA_INSETS = {
	top: 'env(safe-area-inset-top)',
	bottom: 'env(safe-area-inset-bottom)'
};

const HORIZONTAL_SAFE_AREA_INSETS = {
	left: 'env(safe-area-inset-left)',
	right: 'env(safe-area-inset-right)'
};

function NotchInset(props) {
	const isPrintPreview = useSelector(uiShowPrintLayout);
	const {enabled, children, ...remainingProps} = props;
	let notchInset = children || null;
	if (enabled && !isPrintPreview) {
		if (children) {
			notchInset = (
				<NotchInsetWithChildren {...remainingProps}>
					{children}
				</NotchInsetWithChildren>
			);
		} else {
			notchInset = <NotchInsetWithoutChildren {...remainingProps} />;
		}
	}
	return notchInset;
}

function NotchInsetWithChildren(props) {
	const {variant, bgColor, children} = props;
	const insetElement = <NotchInsetWithoutChildren variant={variant} bgColor={bgColor} />;
	const containerDirection = (variant === 'left' || variant === 'right') ? 'row' : 'column';
	const insertBefore = variant === 'left' || variant === 'top';
	const insertAfter = variant === 'right' || variant === 'bottom';
	return (
		<Box sx={{display: 'flex', flexDirection: containerDirection}}>
			{insertBefore && insetElement}
			{children}
			{insertAfter && insetElement}
		</Box>
	);
}

function NotchInsetWithoutChildren(props) {
	const {variant, bgColor} = props;
	const insetHeight = VERTICAL_SAFE_AREA_INSETS[variant];
	const insetWidth = HORIZONTAL_SAFE_AREA_INSETS[variant];
	return <Box sx={{height: insetHeight, width: insetWidth, bgcolor: bgColor, flexGrow: '0', flexShrink: '0'}} />;
}

const notchPropTypes = {
	bgColor: PropTypes.string,
	variant: PropTypes.oneOf(['top', 'bottom', 'left', 'right']).isRequired
};

NotchInsetWithoutChildren.propTypes = notchPropTypes;
NotchInsetWithChildren.propTypes = notchPropTypes;

NotchInset.propTypes = {
	...notchPropTypes,
	enabled: PropTypes.bool
};

NotchInset.defaultProps = {
	enabled: true
};

export default React.memo(NotchInset);
