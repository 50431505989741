import {createChainedValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';
import {maxLengthValidator, minLengthValidator} from './lengthValidator.js';
import {createRegExValidator} from './regexValidator.js';

const PHONE_NUMBER_PREFIX_LENGTH = 1;
const PHONE_NUMBER_DIGITS_MAX_LENGTH = 15;
const PHONE_NUMBER_MAX_LENGTH = PHONE_NUMBER_PREFIX_LENGTH + PHONE_NUMBER_DIGITS_MAX_LENGTH;
const PHONE_NUMBER_DIGITS_MIN_LENGTH = 9;
const PHONE_NUMBER_MIN_LENGTH = PHONE_NUMBER_PREFIX_LENGTH + PHONE_NUMBER_DIGITS_MIN_LENGTH;
const INTERNATIONAL_PHONE_NUMBER_REGEX = /^(\+|00)[1-9][0-9]/;
const PHONE_NUMBER_ONLY_DIGITS_REGEX = /^(\+|00)[\d]+$/;

export default createChainedValidatorFactory(
	createRegExValidator(INTERNATIONAL_PHONE_NUMBER_REGEX, 'PhoneNumberRequiresCountryCode', ValidatorsMessagesTranslator),
	createRegExValidator(PHONE_NUMBER_ONLY_DIGITS_REGEX, 'OnlyDigitsInPhoneNumber', ValidatorsMessagesTranslator),
	minLengthValidator(PHONE_NUMBER_MIN_LENGTH, 'MinPhoneNumberLength', ValidatorsMessagesTranslator),
	maxLengthValidator(PHONE_NUMBER_MAX_LENGTH, 'MaxPhoneNumberLength', ValidatorsMessagesTranslator)
);
