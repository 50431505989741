import {DICOM_ENC_PDF_DATA_TYPE, DICOM_IMAGE_DATA_TYPE, HTML_DATA_TYPE} from '../constants/ViewerItemConstants.js';
import DicomSeriesImageIndex from '../data/DicomSeriesImageIndex.js';

export function getRawImageData(imageDataViewerItem) {
	const type = getImageDataType(imageDataViewerItem);
	let rawImageData = null;
	switch (type) {
		case DICOM_IMAGE_DATA_TYPE:
			rawImageData = getRawImage(imageDataViewerItem);
			break;
		case DICOM_ENC_PDF_DATA_TYPE:
			rawImageData = getRawPdf(imageDataViewerItem);
			break;
		case HTML_DATA_TYPE:
			rawImageData = getRawMarkup(imageDataViewerItem);
			break;
		default:
			if (imageDataViewerItem) {
				throw new Error(`Image data type "${type}" not supported.`);
			}
			break;
	}
	return rawImageData;
}

export function getImageDataType(imageDataViewerItem) {
	return imageDataViewerItem ? imageDataViewerItem.get('type', null) : null;
}

export function getDocumentId(imageMetadata) {
	return imageMetadata && imageMetadata.get('document_aimid');
}

export function getSeriesId(imageMetadata) {
	return imageMetadata && imageMetadata.get('dicom_series_fk');
}

export function getRawImage(viewerItem) {
	return viewerItem ? viewerItem.get('rawImage', null) : null;
}

export function getRawXML(viewerItem) {
	return viewerItem ? viewerItem.get('rawXML', null) : null;
}

export function getRawPdf(viewerItem) {
	return viewerItem ? viewerItem.get('rawPdf', null) : null;
}

export function getRawMarkup(viewerItem) {
	return viewerItem ? viewerItem.get('rawMarkup', null) : null;
}

export function getLoadError(viewerItem) {
	return (Boolean(viewerItem) && viewerItem.get !== undefined) ? viewerItem.get('loadError', null) : null;
}

export function getDicomDump(imageViewerItem) {
	return imageViewerItem ? imageViewerItem.get('dicomDump', null) : null;
}

export function getMetadata(imageViewerItem) {
	return imageViewerItem ? imageViewerItem.get('metaData', null) : null;
}

export function buildDicomFrameId(seriesId, imageId, frameNumber) {
	return `dicom/${seriesId}/${imageId}/${frameNumber}`;
}

export function buildEncapsulatedPdfId(seriesId, imageId) {
	return buildDicomFrameId(seriesId, imageId, 0);
}

export function buildStructuredReportId(seriesId, imageId) {
	return buildDicomFrameId(seriesId, imageId, 0);
}

export function buildSchedulerSeriesGroupId(seriesId, groupType = '') {
	return `/viewerItems/series/${seriesId}/${groupType}`;
}

export function buildViewerTaskSelectorId(viewerId) {
	return `viewerSelector/${viewerId}`;
}

export function buildFileTaskSelectorId(fileId) {
	return `fileSelector/${fileId}`;
}

export function hasSeriesLoadStarted(seriesViewerItem) {
	return Boolean(seriesViewerItem);
}

export function hasSeriesLoadFinished(seriesViewerItem) {
	return hasSeriesLoadStarted(seriesViewerItem) && !seriesViewerItem.get('isLoading', true);
}

export function getNumberOfFrames(seriesViewerItem) {
	const imageIndex = getSeriesImageIndex(seriesViewerItem);
	return imageIndex.numberOfFrames;
}

export function getSeriesImageIndex(seriesViewerItem, defaultValue = new DicomSeriesImageIndex()) {
	return (Boolean(seriesViewerItem) && seriesViewerItem.get !== undefined && seriesViewerItem.get('imageIndex')) || defaultValue;
}

export function extractViewerItem(viewerItems, itemType, itemId, defaultValue = null) {
	return Boolean(viewerItems) &&
			viewerItems.getIn !== undefined &&
			viewerItems.getIn([itemType, itemId]) ||
			defaultValue;
}

export function isSeriesViewerItem(viewerItem) {
	return viewerItem && viewerItem.get('type') === 'series';
}

export function getFileExtension(fileViewerItem) {
	return fileViewerItem && fileViewerItem.get('extension', '') || '';
}

export function canDisplayLengthScale(dicomDump) {
	return Boolean(dicomDump) && Boolean(dicomDump.getPixelSize());
}
