import React from 'react';

import DefaultAutoCompleteTextField from './AutoCompleteTextField/DefaultAutoCompleteTextField.js';

export default function createAutoCompleteTextField(autoCompleteProps) {
	const {inputTextField: TextFieldComponent = DefaultAutoCompleteTextField} = autoCompleteProps;
	function autoCompleteTextFieldRenderer(params) {
		return <TextFieldComponent params={params} {...autoCompleteProps} />;
	}
	return autoCompleteTextFieldRenderer;
}
