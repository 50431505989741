import Immutable from 'immutable';

import {
	FRAME_OF_REFERENCE_UID_TAG_ID,
	IMAGE_COMMENTS_TAG_ID,
	IMAGE_ORIENTATION_PATIENT_TAG_ID,
	IMAGE_PIXEL_SPACING_TAG_ID,
	IMAGE_POSITION_PATIENT_TAG_ID,
	NUMBER_COLUMNS_TAG_ID,
	NUMBER_OF_FRAMES_TAG_ID,
	NUMBER_ROWS_TAG_ID,
	PIXEL_ASPECT_RATIO_TAG_ID,
	PIXEL_SPACING_TAG_ID,
	SLICE_THICKNESS_TAG_ID
} from './DicomTagIDs.js';

const TAGID_TAG_NAME_MAP = Immutable.Map({
	[FRAME_OF_REFERENCE_UID_TAG_ID]: 'FrameOfReferenceUID',
	[IMAGE_ORIENTATION_PATIENT_TAG_ID]: 'ImageOrientationPatient',
	[IMAGE_POSITION_PATIENT_TAG_ID]: 'ImagePositionPatient',
	[IMAGE_PIXEL_SPACING_TAG_ID]: 'ImagePixelSpacing',
	[PIXEL_SPACING_TAG_ID]: 'PixelSpacing',
	[PIXEL_ASPECT_RATIO_TAG_ID]: 'PixelAspectRatio',
	[IMAGE_COMMENTS_TAG_ID]: 'ImageComments',
	[NUMBER_ROWS_TAG_ID]: 'NumberRows',
	[NUMBER_COLUMNS_TAG_ID]: 'NumberColumns',
	[SLICE_THICKNESS_TAG_ID]: 'SliceThickness',
	[NUMBER_OF_FRAMES_TAG_ID]: 'NumberOfFrames'
});

export default TAGID_TAG_NAME_MAP;
