import DICOMParser from 'dicom-parser';

import Patient from '../../../webview/commons/data/aim/Patient.js';
import {couldBeDicomFile} from '../../dicom/couldBeDicomFile.js';
import {
	PARSER_MODALITY,
	PARSER_PATIENT_BIRTH_DATE,
	PARSER_PATIENT_NAME,
	PARSER_PATIENT_SEX,
	PARSER_STUDY_DESCRIPTION,
	PARSER_STUDY_INSTANCE_UID
} from '../../dicom/dicomParserTagIDs.js';
import {parseDate} from '../../dicom/parseDate.js';
import parsePersonName from '../../dicom/parsePersonName.js';

const MAX_PARALLEL_READS = 12;
const BYTES_OF_4KB = 4096;
const BYTES_OF_256KB = 262144;
const DICOM_PARSE_ATTEMPTS_BYTES = [
	BYTES_OF_4KB,
	BYTES_OF_256KB
];

const PROPERTY_TO_TAG = {
	modality: PARSER_MODALITY,
	studyDescription: PARSER_STUDY_DESCRIPTION,
	patientName: PARSER_PATIENT_NAME,
	patientBirthDate: PARSER_PATIENT_BIRTH_DATE,
	patientSex: PARSER_PATIENT_SEX,
	studyInstanceUID: PARSER_STUDY_INSTANCE_UID
};
const REQUIRED_PROPERTIES = new Set();
REQUIRED_PROPERTIES.add('patientName');
REQUIRED_PROPERTIES.add('patientBirthDate');
REQUIRED_PROPERTIES.add('studyInstanceUID');

export default function dicomFilter(fileList) {
	const promisedGroupings = new Array(MAX_PARALLEL_READS);
	return groupFiles(0, fileList, promisedGroupings, new Map());
}

async function groupFiles(fileListOffset, fileList, promisedGroupings, groups) {
	if (fileListOffset < fileList.length) {
		let processedFiles = 0;
		for (
			let entryIndex = fileListOffset;
			entryIndex < fileList.length && processedFiles < MAX_PARALLEL_READS;
			++entryIndex
		) {
			promisedGroupings[processedFiles] = groupFile(fileList[entryIndex], groups);
			++processedFiles;
		}
		await Promise.all(promisedGroupings.slice(0, processedFiles));
		return groupFiles(fileListOffset + processedFiles, fileList, promisedGroupings, groups);
	}
	return groups;
}

async function groupFile(fileEntry, groups) {
	const {file} = fileEntry;
	try {
		if (await couldBeDicomFile(file)) {
			await tryGroupDicomFile(fileEntry, groups);
		} else {
			groupNonDicomFile(fileEntry, groups);
		}
	} catch (error) {
		groupNonDicomFile(fileEntry, groups);
	}
}

async function tryGroupDicomFile(fileEntry, groups, attemptIndex = 0) {
	try {
		const bytesToRead = DICOM_PARSE_ATTEMPTS_BYTES[attemptIndex];
		await groupDicomFile(fileEntry, groups, bytesToRead);
	} catch (error) {
		const nextAttemptIndex = attemptIndex + 1;
		if (nextAttemptIndex < DICOM_PARSE_ATTEMPTS_BYTES.length) {
			await tryGroupDicomFile(fileEntry, groups, nextAttemptIndex);
		} else {
			throw error;
		}
	}
}

async function groupDicomFile(fileEntry, groups, bytesToRead) {
	const {file} = fileEntry;
	const arrayBuffer = await file.slice(0, bytesToRead).arrayBuffer();
	const byteArray = new Uint8Array(arrayBuffer);
	const {
		patientName, patientBirthDate, patientSex = 'U', studyDescription = '', modality, studyInstanceUID
	} = extractRelevantDICOMData(byteArray);

	const patient = Patient.from({
		...parsePersonName(patientName),
		birth_date: parseDate(patientBirthDate),
		sex_fk: patientSex
	});
	const study = Object.freeze({
		description: studyDescription,
		modality
	});

	const context = {study, patient};
	const groupName = `${patientName}${patientSex}${patientBirthDate}${studyInstanceUID}`;

	addToGroup(groups, fileEntry, groupName, context);
}

function extractRelevantDICOMData(byteArray) {
	const dataSet = DICOMParser.parseDicom(byteArray, {untilTag: PARSER_STUDY_INSTANCE_UID});
	const dicomValues = Object.keys(PROPERTY_TO_TAG)
		.map(key => ([dataSet.text(PROPERTY_TO_TAG[key]), key]));
	const undefinedProperties = dicomValues
		.filter(([value, propertyName]) => (value === undefined && REQUIRED_PROPERTIES.has(propertyName)))
		.map(([, propertyName]) => propertyName);
	if (undefinedProperties.length > 0) {
		throw new Error(`The following required DICOM-Tags are undefined: ${undefinedProperties}`);
	}
	return dicomValues
		.reduce((obj, [value, name]) => {
			obj[name] = value;
			return obj;
		}, {});
}

function groupNonDicomFile(fileEntry, groups) {
	addToGroup(groups, fileEntry, '');
}

function addToGroup(groups, file, containerName, containerData) {
	let entry = groups.get(containerName);
	if (!entry) {
		entry = {
			containerData,
			fileList: []
		};
		groups.set(containerName, entry);
	}
	const {fileList} = entry;
	fileList.push(file);
}

