import React from 'react';
import {mat2d, vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {deg2rad} from '../../utils/MathUtils.js';
import {renderPathString} from '../../utils/SVGUtils.js';
import {LINE_WIDTH} from './AnnotationConstants.js';

import '../../../../styles/viewer/components/annotations/MarkerArrowHead.scss';

const ARROW_OFFSET = LINE_WIDTH;
const ARROW_LENGTH = 20; //px
const THIRTY_DEGREES = 30;
const SIXTY_DEGREES = 60;
const ARROW_HELPER_MATRICES = [
	mat2d.fromRotation(mat2d.create(), -deg2rad(THIRTY_DEGREES)),
	mat2d.fromRotation(mat2d.create(), deg2rad(SIXTY_DEGREES))
];

export default function MarkerArrowHead(props) {
	const {referencePoint, orientation, isPrintPreview} = props;
	const tempVector = vec2.create();
	const thisReferencePoint = vec2.add(tempVector, referencePoint, vec2.scale(tempVector, orientation, ARROW_OFFSET));
	const arrowsLittleHelper = vec2.scale(vec2.create(), orientation, -ARROW_LENGTH);
	const pathString = renderPathString([
		vec2.add(vec2.create(),
			thisReferencePoint,
			vec2.transformMat2d(arrowsLittleHelper, arrowsLittleHelper, ARROW_HELPER_MATRICES[0])
		),
		thisReferencePoint,
		vec2.add(arrowsLittleHelper,
			thisReferencePoint,
			vec2.transformMat2d(arrowsLittleHelper, arrowsLittleHelper, ARROW_HELPER_MATRICES[1])
		)
	]);
	const contour = isPrintPreview && (<path key='contour-line' d={pathString} className='marker-arrow-head--contour' />);

	return (
		<g>
			{contour}
			<path d={pathString} className='marker-arrow-head' />
		</g>
	);
}

MarkerArrowHead.propTypes = {
	referencePoint: PropTypes.instanceOf(Float32Array),
	orientation: PropTypes.instanceOf(Float32Array),
	isPrintPreview: PropTypes.bool
};
