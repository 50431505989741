import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {PASSWORDRESET_PATH} from '../../../commons/constants/CommonRoutingConstants.js';
import PasswordResetMessagesTranslator from '../../i18n/PasswordResetMessagesTranslator.js';
import {selectResetToken} from '../selectors/NewPasswordSelectors.js';
import NewPasswordPageContainer from './NewPasswordPageContainer.js';

export default connect(
	createStructuredSelector({
		token: selectResetToken,
		bailOutUrl: () => PASSWORDRESET_PATH,
		translator: () => PasswordResetMessagesTranslator
	})
)(NewPasswordPageContainer);
