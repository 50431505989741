
/**
 * adds a property to the given object which is not enumerable but is configurable which implies that it can be removed
 * or modified
 *
 * @param object the object to add the property to
 * @param key the key of the property to add
 * @param value the value to set as the properties value
 */
export function setUnenumerableProperty(object, key, value) {
	Object.defineProperty(object, key, {value, enumerable: false, configurable: true});
}

export function cloneWithoutProperties(original, ...propertiesToRemove) {
	return propertiesToRemove.reduce((currentObject, propertyName) => {
		delete currentObject[propertyName];
		return currentObject;
	}, {...original});
}

export function mapProperties(obj, mapper) {
	return Object.keys(obj).reduce((finalObject, propertyName) => {
		finalObject[propertyName] = mapper(obj[propertyName], propertyName, obj);
		return finalObject;
	}, {});
}

export function hasProperty(obj, property) {
	return obj.has && obj.has(property) || hasOwnPropertySafe(obj, property);
}

export function hasOwnPropertySafe(obj, property) {
	return Object.prototype.hasOwnProperty.call(obj, property);
}

export {default as autoBind} from './autoBind.js';
export {default as shallowEqual} from 'react-pure-render/shallowEqual';
export {default as getPrototypes} from './getPrototypes.js';
