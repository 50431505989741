import React from 'react';
import PropTypes from 'prop-types';

import createViewerItemViewer from './createViewerItemViewer.js';
import PdfView from './pdf/PdfView.js';
import PdfLoader from './PdfLoader.js';

function PdfViewRenderer(props) {
	const {pdfFile} = props;
	return (
		<PdfLoader pdfFile={pdfFile}>
			<PdfView />
		</PdfLoader>
	);
}

PdfViewRenderer.propTypes = {
	pdfFile: PropTypes.object
};

export default createViewerItemViewer(PdfViewRenderer, 'pdf-viewer');

