/**
 * Utilizes FileReaderAPI to convert the passed Blob into a Uint8Array containing the blobs data.
 * @param blob {Blob} - the blob to be read
 * @returns {Promise} - Resolved with the read array. Rejected with null if any error occurs.
 */
export function readBlobAsArrayBuffer(blob) {
	return new Promise(((resolve, reject) => {
		if (blob === null || blob === undefined) {
			reject(new TypeError('TypeMismatchError'));
		}
		const reader = new FileReader();
		reader.onload = function readerOnLoadHandler(e) {
			resolve(new Uint8Array(e.target.result));
		};
		reader.onerror = function readerOnErrorHandler() {
			reject(reader.error);
		};
		try {
			reader.readAsArrayBuffer(blob);
		} catch (readError) {
			reject(readError);
		}
	}));
}

export function stringToArrayBuffer(str) {
	const buf = new ArrayBuffer(str.length * 2);
	const bufView = new Uint8Array(buf);
	for (let i = 0; i < str.length; ++i) {
		bufView[i] = str.charCodeAt(i);
	}
	return bufView;
}

/**
 * Utilizes FileReaderAPI to convert the passed Blob into a JavaScript string and parses the resulting string as JSON.
 * @param blob {Blob} - the blob to be read
 * @returns {Promise} - Resolved with the parsed JavaScript Object. Rejected with null if any error occurs.
 */
export function readBlobAsJSON(blob) {
	return readBlobAsString(blob)
		.then(JSON.parse);
}

function readBlobAsString(blob) {
	return new Promise(((resolve, reject) => {
		if (blob === null || blob === undefined) {
			reject(new TypeError('TypeMismatchError'));
		}
		const reader = new FileReader();
		reader.onload = function readerOnLoadHandler(e) {
			resolve(e.target.result);
		};
		reader.onerror = function readerOnErrorHandler() {
			reject(null);
		};
		reader.readAsText(blob);
	}));
}
