import {
	createAction,
	createErroneousAction
} from '../../../commons/utils/ActionUtils.js';
import {
	BEGIN_PASSWORD_OPERATION,
	CHANGE_PASSWORD,
	CLEAR_ERROR,
	FINISH_PASSWORD_OPERATION,
	REQUEST_PASSWORD_RESET,
	SET_NEW_PASSWORD
} from '../../constants/PasswordActionTypes.js';
import sanitizeUsername from '../../utils/sanitizeUsername.js';

const finishPasswordOperationAction = createAction(FINISH_PASSWORD_OPERATION);

export const clearError = createAction(CLEAR_ERROR);
export const beginPasswordOperation = createAction(BEGIN_PASSWORD_OPERATION);

export function finishPasswordOperation(error) {
	return error === undefined
		? finishPasswordOperationAction()
		: createErroneousAction(FINISH_PASSWORD_OPERATION, error);
}

export const changePassword = createAction(CHANGE_PASSWORD,
	(username, oldPassword, newPassword, newPasswordConfirmation, secondFactorToken) => ({
		username, oldPassword, newPassword, newPasswordConfirmation, secondFactorToken
	})
);

export const setNewPassword = createAction(SET_NEW_PASSWORD,
	(username, newPassword, newPasswordConfirmation, changePasswordToken, secondFactorToken) => ({
		username, newPassword, newPasswordConfirmation, changePasswordToken, secondFactorToken
	})
);

export const requestPasswordReset = createAction(REQUEST_PASSWORD_RESET, username => sanitizeUsername(username));
