import {PRIORITY_HIGH, PRIORITY_IMMEDIATE} from '../../commons/utils/ProbabilisticQueue.js';
import {addOrReplaceTaskSelector} from '../../scheduling/api';
import createClosestTaskSelector from '../../scheduling/createClosestTaskSelector.js';
import createExactMatcherTaskSelector from '../../scheduling/createExactMatcherTaskSelector.js';
import numericDistance from './math/numericDistance.js';
import {buildSchedulerSeriesGroupId, buildViewerTaskSelectorId} from './ViewerItemUtils.js';

export function scheduleFrameDownload(taskId, seriesId, frameTaskId) {
	return addOrReplaceTaskSelector(
		buildViewerTaskSelectorId(taskId), PRIORITY_HIGH,
		createClosestTaskSelector(
			buildSchedulerSeriesGroupId(seriesId, 'image'),
			frameTaskId,
			numericDistance,
			false
		));
}

export function scheduleImageDownload(taskId, seriesId, dicomDumpTaskId) {
	return addOrReplaceTaskSelector(
		buildViewerTaskSelectorId(taskId), PRIORITY_IMMEDIATE,
		createExactMatcherTaskSelector(
			buildSchedulerSeriesGroupId(seriesId, 'image'),
			dicomDumpTaskId
		)
	);
}

