import {IMAGE_JPEG} from '../../constants/MimeTypes.js';
import {loadImageFromBlob} from '../../utils/ImageUtils.js';
import getBlob from './getBlob.js';

/**
 * Retrieves a DOM Image Object for the given url.
 * Every request which has a status code different from 200 will reject the result promise
 * with an error of type FetchError.
 * If the image cannot be decoded or loaded from the response it will throw a ImageLoadError
 *
 * @param {String} url the url to retrieve the image from
 * @param {String} [imageMimeType] an optional parameter which defines the mime type of the image to retrieve.
 * it defaults to image/jpeg.
 * @param {Object} [loadParams] an optional parameter specifying additional load parameters which are encoded
 * and added to the URL
 * @returns {Promise} a promise which will contain the transferred and encoded Image if the request finishes
 * successfully.
 * @throws {FetchError|ImageLoadError} is thrown if either the request did not finish successfully
 * or the response cannot be decoded into an Image object.
 */

export default function getImage(url, loadParams = {}, imageMimeType = IMAGE_JPEG) {
	return getBlob(url, loadParams, imageMimeType).then(loadImageFromBlob);
}
