import {createSelector} from 'reselect';

import createRouteMatcher from '../../createRouteMatcher.js';
import {pathSelector} from './LocationSelectors.js';

export const routesSelector = state => state.routes;

export const getRouteMatcher = createSelector(routesSelector, routes => {
	const matchers = routes.map(
		(route, routeTemplate) => route.set('matcher', createRouteMatcher(routeTemplate, route.get('parameterMatchers')))
	);
	return function routeMatcher(path) {
		const entryIterator = matchers.values();
		let matchedRoute = null;
		let currentIteratorState = entryIterator.next();

		while (matchedRoute === null && currentIteratorState.done !== true) {
			const currentRoute = currentIteratorState.value;
			const match = currentRoute.get('matcher')(path);
			if (match === null) {
				currentIteratorState = entryIterator.next();
			} else {
				matchedRoute = currentRoute.set('variables', match);
			}
		}
		return matchedRoute;
	};
});

export const getDefaultRoute = createSelector(getRouteMatcher, routeMatcher => routeMatcher(''));

export const getMatchedRoute = createSelector(
	getRouteMatcher, pathSelector, (routeMatcher, path) => routeMatcher(path)
);

export const getCurrentRoute = createSelector(
	getMatchedRoute, getDefaultRoute, (matchedRoute, defaultRoute) => matchedRoute || defaultRoute
);

export const getCurrentPage = state => getCurrentRoute(state).get('pageSelector')(state);

export const getCurrentRouteVariables = createSelector(
	getCurrentRoute, currentRoute => currentRoute.get('variables')
);
