import {hasOwnPropertySafe} from '../../commons/utils/ObjectUtils';
import {METRICS_ACTION} from '../constants/MetricsCollectorActionTypes.js';
import {METRICS_CONTEXT, METRICS_KEY, TRACKING_METADATA_KEY} from '../constants/MetricsConstants.js';

function createTrackingMetaInfo(entry) {
	return {
		[TRACKING_METADATA_KEY]: entry
	};
}

export function createTrackingMetadata(usageContext, key) {
	return createTrackingMetaInfo({
		[METRICS_CONTEXT]: usageContext,
		[METRICS_KEY]: key
	});
}

export function isExplicitTrackingAction(action) {
	return action.type === METRICS_ACTION;
}

export function hasTrackingInformation(action) {
	return hasOwnPropertySafe(action, 'meta') &&
		hasOwnPropertySafe(action.meta, TRACKING_METADATA_KEY);
}

export function getTrackingInformation(action) {
	return action.meta[TRACKING_METADATA_KEY];
}
