import React from 'react';

import {immutableMapPropType} from '../utils/CustomPropTypes.js';

export default React.memo(PersistentPortalsPlaceholder);

function PersistentPortalsPlaceholder(props) {
	const {portals} = props;
	return portals.map(cloneWithKey).toArray();
}

PersistentPortalsPlaceholder.propTypes = {
	portals: immutableMapPropType
};

function cloneWithKey(element, key) {
	return React.cloneElement(element, {key});
}
