import React from 'react';

import {noop} from '../../commons/utils/FunctionUtils.js';

const ViewerContext = React.createContext({
	viewerId: null,
	isActive: false,
	isFullScreen: false,
	isPrintPreview: false,
	updateProperties: noop,
	resetProperties: noop,
	activate: noop,
	toggleFullScreen: noop
});

export default ViewerContext;
