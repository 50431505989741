import React from 'react';
import PropTypes from 'prop-types';

import {getLanguageAttribute} from '../../../../i18n/i18nUtils.js';
import {combineClassNames} from '../../../utils/StyleUtils.js';
import {toRelativePath} from '../../../utils/URLUtils.js';
import {webAppURL} from '../../../WebViewHelpers.js';
import SynIFrame from '../../SynIFrame.js';

import '../../../../../styles/commons/components/webview/help/ClientDocumentation.scss';

const HELP_URL_BASE = webAppURL('/documentation');

export default function ClientDocumentation(props) {
	const {path = '', fragment, locale, className} = props;
	const localizedHelpUrl = `${HELP_URL_BASE}/${getLanguageAttribute(locale)}`;
	const relativePath = toRelativePath(path);
	const srcUrlBase = `${localizedHelpUrl}/${relativePath}`;
	const srcUrl = fragment ? `${srcUrlBase}#${fragment}` : srcUrlBase;
	const combinedClassNames = combineClassNames('client-documentation', className);
	return (
		<SynIFrame className={combinedClassNames} src={srcUrl} />
	);
}

ClientDocumentation.propTypes = {
	path: PropTypes.string,
	className: PropTypes.string,
	fragment: PropTypes.string,
	locale: PropTypes.string
};
