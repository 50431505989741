import React, {useContext} from 'react';

import {tr} from '../../i18n/components/SynFormattedMessage.js';
import ViewerMessagesTranslator from '../../i18n/translators/ViewerTranslator.js';
import ResetIcon from '../../ui/components/icons/ResetIcon.js';
import ViewerContext from './ViewerContext.js';
import ViewerToolsMenuEntry from './ViewerToolsMenuEntry.js';

const ResetToolLabel = tr(ViewerMessagesTranslator, 'ResetToolLabel');

export default function ResetViewerMenuEntry() {
	const {resetProperties} = useContext(ViewerContext);
	return (<ViewerToolsMenuEntry id='reset-viewer'
								 onClick={resetProperties}
								 label={ResetToolLabel}
								 icon={ResetIcon}
								 metricsKey='ResetViewer' />);
}
