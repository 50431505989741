import Immutable from 'immutable';

import {REQUEST_COUNT} from '../constants/ApiConstants.js';
import {ASC, DESC} from '../constants/SortOrders.js';

function convertSortInfo(rawSortInfos) {
	let sortInfo = Immutable.List();
	if (rawSortInfos) {
		sortInfo = rawSortInfos.reduce(
			(finalSortInfo, rawSortInfo) => {
				const sortParams = Immutable.List([
					rawSortInfo.field,
					(rawSortInfo.direction === 'ASC') ? ASC : DESC
				]);
				return finalSortInfo.push(sortParams);
			},
			sortInfo
		);
	}
	return sortInfo;
}

const EMPTY_RESULT_LIST = {
	[REQUEST_COUNT]: 0,
	results: [],
	resultsize: 0,
	metaData: {
		sortInfo: []
	}
};

export default class ResultList {
	constructor(
		rawResultList = EMPTY_RESULT_LIST, rawResultListStart = 0, noCount = false, fetchTimeStamp = 0, sortInfo = null
	) {
		this[REQUEST_COUNT] = rawResultList[REQUEST_COUNT];
		delete rawResultList[REQUEST_COUNT];

		const rawResultListIsImmutable = Boolean(rawResultList) && rawResultList.get !== undefined && typeof (rawResultList.get) === 'function';

		this.rawResultList = rawResultListIsImmutable ? rawResultList : Immutable.fromJS(rawResultList);
		this.sortInfo = sortInfo || convertSortInfo(rawResultList.metaData.sortInfo);
		this.rawDataStart = rawResultListStart;
		this.noCount = noCount;
		this.fetchTimeStamp = fetchTimeStamp;
	}

	slice(from, toExclusive) {
		return this.#modified(
			this.getRawData()
				.update('results', results => results.slice(from, toExclusive))
				.update('resultsize', resultsize => resultsize - (this.noCount ? from : 0)),
			this.getRawDataStart() + from
		);
	}

	getRequestCount() {
		return this[REQUEST_COUNT];
	}

	getRawData() {
		return this.rawResultList;
	}

	getRawDataStart() {
		return this.rawDataStart;
	}

	get(entryIndex) {
		return this.rawResultList.get('results').get(entryIndex);
	}

	getLocalSize() {
		return this.rawResultList.get('results').size;
	}

	getTotalSize() {
		return this.rawResultList.get('resultsize') + (this.noCount ? this.rawDataStart : 0);
	}

	getSortInfo() {
		return this.sortInfo;
	}

	getList() {
		return this.rawResultList.get('results');
	}

	canLoadPreviousResults() {
		return this.rawDataStart > 0;
	}

	canLoadNextResults() {
		const knownResults = this.getLocalSize() + (this.noCount ? 0 : this.rawDataStart);
		return knownResults < this.rawResultList.get('resultsize');
	}

	forEach(iteratorFunction) {
		return this.rawResultList.get('results').forEach(iteratorFunction);
	}

	map(mappingFunction) {
		return this.#modified(this.rawResultList.update('results', results => results.map(mappingFunction)));
	}

	filter(predicate) {
		return this.#modified(this.rawResultList.update('results', results => results.filter(predicate)));
	}

	sortBy(...args) {
		return this.#modified(this.rawResultList.update('results', results => results.sortBy(...args)));
	}

	reduce(reducingFunction, initialValue) {
		return this.rawResultList.get('results').reduce(reducingFunction, initialValue);
	}

	#modified(
		rawResultList = this.rawResultList, rawResultListStart = this.rawDataStart,
		noCount = this.noCount, fetchTimeStamp = this.fetchTimeStamp, sortInfo = this.sortInfo
	) {
		let modifiedResultList = this;
		if (rawResultList !== this.rawResultList || rawResultListStart !== this.rawDataStart ||
				noCount !== this.noCount || fetchTimeStamp !== this.fetchTimeStamp || sortInfo !== this.sortInfo) {
			modifiedResultList = new ResultList(
				rawResultList, rawResultListStart, noCount, fetchTimeStamp, sortInfo
			);
			modifiedResultList[REQUEST_COUNT] = this[REQUEST_COUNT];
		}
		return modifiedResultList;
	}
}
