import React from 'react';

import SelectItemCheckboxContainer from '../../containers/SelectItemCheckboxContainer.js';
import {immutableMapPropType} from '../../utils/CustomPropTypes.js';

export default class SelectDocumentCheckbox extends React.PureComponent {
	render() {
		const {document} = this.props;
		const enabled = SelectDocumentCheckbox.isShareable(document);
		const idAsString = `${document.get('id')}`;
		return <SelectItemCheckboxContainer disabled={!enabled} id={idAsString} item={document} />;
	}

	static isShareable(document) {
		return Boolean(document) && document.get('shareable', false);
	}
}

SelectDocumentCheckbox.propTypes = {
	document: immutableMapPropType
};
