import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import UploadFilesBrowserController from '../bricks/UploadFilesBrowserController.js';
import UploadFilesGrid from './UploadFilesGrid/UploadFilesGrid.js';
import UploadFilesList from './UploadFilesList/index.js';

export default function UploadFilesBrowser(props) {
	const {visible} = props;
	const {isGridMode} = useBrick(UploadFilesBrowserController, selectFileListSettings);
	return isGridMode ? <UploadFilesGrid visible={visible} /> : <UploadFilesList visible={visible} />;
}

UploadFilesBrowser.propTypes = UploadFilesList.propTypes;
UploadFilesBrowser.defaultProps = UploadFilesList.defaultProps;

function selectFileListSettings(service) {
	return {
		isGridMode: service.isViewModeGrid()
	};
}
