import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import UserInfo from '../../components/UserInfo.js';
import SessionGuard from '../../utils/SessionGuard.js';
import {
	selectUserContextLoginName,
	selectUserContextName
} from '../selectors/SessionSelectors.js';

const UserInfoContainer = connect(
	createStructuredSelector({
		username: selectUserContextName,
		loginname: selectUserContextLoginName
	})
)(UserInfo);

export default function GuardedUserInfo() {
	return (
		<SessionGuard>
			<UserInfoContainer />
		</SessionGuard>
	);
}

