const NBSP_CHAR_CODE = 160;
const NBSP_REG_EX = new RegExp(String.fromCharCode(NBSP_CHAR_CODE), 'g');

export function capitalizeFirst(string) {
	return (!string || string.length === 0) ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}

export function escapeToHTML(text) {
	return Boolean(text) && text
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#039;')
		.replace(/\n/g, '<br>') ||
	text;
}

export function nDistinctChars(text) {
	return text.split('').filter((v, i, a) => a.indexOf(v) === i).length;
}

function areAllCharsAscending(inputArr) {
	return inputArr
		.map(char => char.charCodeAt())
		.every((charNum, idx, arr) => arr[0] + idx === charNum);
}

export function isSequenceOfAscendingCharsOnly(text) {
	return areAllCharsAscending(text.split(''));
}

export function isSequenceOfDescendingCharsOnly(text) {
	return areAllCharsAscending(text.split('').reverse());
}

export function notNullOrEmpty(str) {
	return Boolean(str) && str !== '';
}

export function getFileExtension(fileName) {
	// https://stackoverflow.com/a/12900504
	return fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
}

export function getFileName(fileName) {
	return fileName.slice(0, (fileName.indexOf('.') >>> 0));
}

export function removeNBSP(text) {
	if (typeof text === 'string') {
		return text.replace(NBSP_REG_EX, ' ');
	}
	return text;
}
