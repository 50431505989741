import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import GeneralMessagesTranslator from '../../../i18n/translators/GeneralTranslator.js';
import WarningIcon from '../../../ui/components/icons/WarningIcon.js';

import '../../../../styles/commons/components/text/Warning.scss';

const DEFAULT_TITLE = <SynFormattedMessage translator={GeneralMessagesTranslator} message='WarningTitle' />;

export default function Warning({title = DEFAULT_TITLE, children, element = 'section'}) {
	const ElementComponent = element;
	return (
		<ElementComponent>
			<span className='warning-caption'>
				<WarningIcon />
&nbsp;
				{title}
			</span>
			{children}
		</ElementComponent>
	);
}

Warning.propTypes = {
	title: PropTypes.node,
	element: PropTypes.elementType
};
