import React from 'react';
import PropTypes from 'prop-types';

import {default as SynFormattedMessage, tr} from '../../../i18n/components/SynFormattedMessage.js';
import List from '../../../material-design/components/List.js';
import SimpleDialog from '../../../material-design/components/SimpleDialog.js';
import {IS_DEBUG_BUILD} from '../../constants/EnvironmentConstants.js';
import {immutableListPropType} from '../../utils/CustomPropTypes.js';
import FullScreenContainer from '../FullScreenContainer.js';
import UnhandledErrorsDialogTranslator from './i18n/UnhandledErrorsDialogTranslator.js';
import UnhandledErrorListItem from './UnhandledErrorListItem.js';

import '../../../../styles/commons/components/errors/UnhandledErrorsDialog.scss';

const TranslatedDialogTitle = tr(UnhandledErrorsDialogTranslator, 'Title');
const RELOAD_BUTTON_LABEL = <SynFormattedMessage translator={UnhandledErrorsDialogTranslator} message={IS_DEBUG_BUILD ? 'ClosePhrase' : 'ReloadButtonLabel'} />;

export default class UnhandledErrorsDialog extends React.Component {
	render() {
		const {unhandledErrors, onClose} = this.props;
		const nrErrors = unhandledErrors.size;
		const isVisible = nrErrors > 0;
		let errorsDialog = false;
		if (isVisible) {
			const thisTitle = <TranslatedDialogTitle messageParams={{nrErrors}} />;
			errorsDialog = (
				<FullScreenContainer visible>
					<SimpleDialog className='unhandled-errors-dialog' title={thisTitle}
								  onClose={onClose} closeButtonLabel={RELOAD_BUTTON_LABEL}>
						<List>
							{unhandledErrors.map(
								(error, index) => {
									const key = `${index}_${error.toString()}`;
									return <UnhandledErrorListItem key={key} error={error} />;
								}).toArray()
							}
						</List>
					</SimpleDialog>
				</FullScreenContainer>
			);
		}
		return errorsDialog;
	}
}

UnhandledErrorsDialog.propTypes = {
	unhandledErrors: immutableListPropType,
	onClose: PropTypes.func
};
