import {IS_DEBUG_BUILD} from '../../commons/constants/EnvironmentConstants.js';

export default class AllowedMetricsSet {
	#knownMetrics;

	constructor(knownMetrics) {
		this.#knownMetrics = IS_DEBUG_BUILD ? new Set(knownMetrics) : {};
	}

	assertMetricIsKnown(usageContext, key) {
		const metricsKey = `${usageContext}.${key}`;
		if (!this.#knownMetrics.has(metricsKey)) {
			throw new Error(`Unknown metrics key: '${metricsKey}'`);
		}
	}

	forEach(consumer) {
		this.#knownMetrics.forEach(consumer);
	}
}
