const HANDLED_ERROR_SYMBOL = Symbol('HandledError');

export function markAsHandled(error) {
	if (error) {
		error[HANDLED_ERROR_SYMBOL] = HANDLED_ERROR_SYMBOL;
	}
	return error;
}

export function isHandled(error) {
	return Boolean(error) && error[HANDLED_ERROR_SYMBOL] === HANDLED_ERROR_SYMBOL;
}
