import React from 'react';
import PropTypes from 'prop-types';

import GridListConstants from '../../../../constants/GridListConstants.json';
import GridTile from '../../../material-design/components/layout/GridTile.js';
import PreviewableImageContainer from '../../containers/PreviewableImageContainer.js';
import {immutableMapPropType} from '../../utils/CustomPropTypes.js';

const TILE_BORDER_RADIUS = GridListConstants['tile-border-radius'];
const TILE_MARGIN = GridListConstants['tile-margin'];

export default class PreviewImagesGridItemTile extends React.PureComponent {
	render() {
		const {
			item, isVisible, tileWidth, tileHeight, previewImageClass = '', overLayComponent: OverLayComponent,
			createLoadItemFunction, createTaskIdentifier
		} = this.props;
		const contentWidth = tileWidth - 2 * TILE_MARGIN;
		const contentHeight = tileHeight - 2 * TILE_MARGIN;
		const tileStyle = {
			position: 'relative',
			width: `${contentWidth}px`,
			height: `${contentHeight}px`,
			borderRadius: `${TILE_BORDER_RADIUS}px`,
			overflow: 'hidden',
			transform: 'translateZ(0)'
		};
		return (
			<GridTile style={tileStyle}>
				<PreviewableImageContainer key={createTaskIdentifier(item)} width={contentWidth}
				                           height={contentHeight} isVisible={isVisible}
				                           loadPreview={createLoadItemFunction(item)}
				                           taskIdentifier={createTaskIdentifier(item)}
				                           className={previewImageClass} />
				{isVisible && <OverLayComponent item={item} /> || false}
			</GridTile>
		);
	}
}

PreviewImagesGridItemTile.propTypes = {
	item: immutableMapPropType,
	isVisible: PropTypes.bool,
	tileWidth: PropTypes.number,
	tileHeight: PropTypes.number,
	previewImageClass: PropTypes.string,
	overLayComponent: PropTypes.elementType,
	createLoadItemFunction: PropTypes.func,
	createTaskIdentifier: PropTypes.func
};

PreviewImagesGridItemTile.defaultProps = {
	previewImageClass: ''
};
