import React, {useCallback} from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import {useMemoFactory} from '../../../webview/commons/utils/customHooks/index.js';
import UploadFilesBrowserController from '../../bricks/UploadFilesBrowserController.js';
import UploadFilesCollection from '../../bricks/UploadFilesCollection.js';
import OtherPatientIndicator from '../OtherPatientIndicator.js';
import useFormattedEntry from '../useFormattedEntry.js';
import useGetFileInfo from '../useGetFileInfo.js';
import Thumbnail from './Thumbnail.js';
import UploadFileTileOverlay from './UploadFileTileOverlay.js';
import UploadGridTile from './UploadGridTile.js';

export default function SelectableUploadFileTile(props) {
	const {file, row, column} = props;
	const {
		getPreparedFile, getPreparedFileSize, getPreparedContainerValue
	} = useBrick(UploadFilesCollection, selectUploadFilesBrickProps);
	const brickSelector = useMemoFactory(createBrickSelector, file);
	const {isFileSelected, toggleFilesSelection} = useBrick(UploadFilesBrowserController, brickSelector);
	const onClickHandler = useCallback(() => toggleFilesSelection(file), [file, toggleFilesSelection]);
	const getFileInfo = useGetFileInfo(getPreparedFile, getPreparedFileSize, getPreparedContainerValue);
	const entry = getFileInfo(file);
	const {patient: patientFromEntry} = entry;
	const {name, size} = useFormattedEntry(entry);
	return (
		<UploadGridTile onClick={onClickHandler} role='gridcell' aria-rowindex={row} aria-colindex={column}>
			<Thumbnail fileEntry={entry} />
			<UploadFileTileOverlay fileName={name} fileSize={size} isSelected={isFileSelected}>
				<OtherPatientIndicator patient={patientFromEntry} />
			</UploadFileTileOverlay>
		</UploadGridTile>
	);
}

SelectableUploadFileTile.propTypes = {
	file: PropTypes.string,
	row: PropTypes.number,
	column: PropTypes.number
};

function createBrickSelector(file) {
	return function uploadFilesBrowserControllerProps(service) {
		return {
			isFileSelected: service.isFileSelected(file),
			toggleFilesSelection: service.toggleFilesSelection
		};
	};
}

function selectUploadFilesBrickProps(service) {
	return {
		getPreparedFile: service.getPreparedFile,
		getPreparedFileSize: service.getPreparedFileSize,
		getPreparedContainerValue: service.getPreparedContainerValue
	};
}
