import {
	EMBEDDED_MODE,
	FEATURE_BARCODE_SCANNER_ENABLED,
	FEATURE_CREATE_SHARE_RECEIVER_ENABLED, FEATURE_DOCUMENT_SHARING_ENABLED,
	FEATURE_DOCUMENT_UPLOAD_ENABLED,
	FEATURE_INBOX_ENABLED, FEATURE_ORDER_CREATION_ENABLED,
	FEATURE_PASSWORD_RESET_ENABLED,
	FEATURE_SEARCH_ENABLED,
	FEATURE_TRACKING_ENABLED,
	LANGUAGE_TAG,
	SHOW_PATIENT_ID_FORM_FIELD
} from '../constants/SynSettingsConstants.js';

export function currentLocale() {
	return LANGUAGE_TAG;
}
export function isInboxEnabled() {
	return FEATURE_INBOX_ENABLED;
}
export function isDocumentSearchEnabled() {
	return FEATURE_SEARCH_ENABLED;
}
export function isOrderCreationEnabled() {
	return FEATURE_ORDER_CREATION_ENABLED;
}
export function isPasswordResetEnabled() {
	return FEATURE_PASSWORD_RESET_ENABLED;
}
export function isFeatureTrackingEnabled() {
	return FEATURE_TRACKING_ENABLED;
}
export function isPatientIDSearchEnabled() {
	return SHOW_PATIENT_ID_FORM_FIELD;
}
export function isBarcodeScannerEnabled() {
	return FEATURE_BARCODE_SCANNER_ENABLED;
}
export function canCreateShareReceivers() {
	return FEATURE_CREATE_SHARE_RECEIVER_ENABLED;
}
export function isDocumentUploadEnabled() {
	return FEATURE_DOCUMENT_UPLOAD_ENABLED;
}

export function isEmbeddedModeEnabled() {
	return EMBEDDED_MODE;
}

export function isDocumentSharingEnabled() {
	return FEATURE_DOCUMENT_SHARING_ENABLED;
}
