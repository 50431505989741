import {useState} from 'react';

import DomEventsManager from '../../../events/DomEventsManager.js';
import useTranslation from '../../../i18n/hooks/useTranslation.js';
import GeneralTranslator from '../../../i18n/translators/GeneralTranslator.js';
import {useEffectOnMount, useEffectOnUnmount, useMemoFactory} from './index.js';

export default function useOnBeforeUnload(customMessage) {
	const [domEventsManager] = useState(new DomEventsManager());
	const defaultMessage = useTranslation('WindowUnloadWarning', GeneralTranslator);
	const message = customMessage || defaultMessage;
	const handleBeforeUnload = useMemoFactory(createHandleBeforeUnload, message);
	useEffectOnMount(() => {
		domEventsManager.addEventListener(window, 'beforeunload', handleBeforeUnload);
	});
	useEffectOnUnmount(() => {
		domEventsManager.removeAllListeners();
	});
}

function createHandleBeforeUnload(message) {
	return function handleBeforeUnload(event) {
		// https://developer.mozilla.org/en-US/docs/Web/API/BeforeUnloadEvent#examples
		event.preventDefault();
		(event || window.event).returnValue = message;
		return message;
	};
}
