/**
 * This Component provides a virtualized view onto the passed options.
 * It is strongly tied to the AutoCompleteTextField component from material-ui
 * and only meant to work properly with it.
 *
 * @author p.spitzilnger@synedra.com
 */

import React from 'react';
import PropTypes from 'prop-types';

import VirtualizedList from '../../../commons/components/data/VirtualizedList.js';
import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import InnerList from './InnerList.js';
import ListEntry from './ListEntry.js';

const RENDER_OVERSCAN_ITEMS = 5;
const ENTRY_HEIGHT_PIXELS = 48; // According to:https://material.io/components/lists#specs

export default withForwardRef(VirtualizedOptionsList, 'outerRef');
export {ENTRY_HEIGHT_PIXELS as virtualizedOptionsListEntryHeightPixels};
function VirtualizedOptionsList(props) {
	const {children, id, className, 'aria-labelledby': ariaLabelledby, onMouseDown, role, outerRef} = props;
	const childrenAsArray = React.Children.toArray(children);
	const itemCount = childrenAsArray.length;
	
	return (
		<div ref={outerRef} id={id} className={className} aria-labelledby={ariaLabelledby} onMouseDown={onMouseDown}
		     role={role}>
			<VirtualizedList itemData={childrenAsArray} innerElementType={InnerList}
			                 overscanCount={RENDER_OVERSCAN_ITEMS} itemCount={itemCount} itemSize={ENTRY_HEIGHT_PIXELS}>
				{ListEntry}
			</VirtualizedList>
		</div>
	);
}
VirtualizedOptionsList.propTypes = {
	outerRef: withForwardRef.PropTypes.Ref,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	className: PropTypes.string,
	'aria-labelledby': PropTypes.string,
	onMouseDown: PropTypes.func,
	role: PropTypes.string
};
