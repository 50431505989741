import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import {useDicomViewerState} from '../../components/dicom/DicomSeriesContextProvider.js';
import ViewerContext from '../../components/ViewerContext.js';
import ViewerItemDownloadOptions from '../../components/ViewerItemDownloadOptions.js';
import {
	createDicomImageDownloadOptionsSelector,
	createGenericFileDownloadOptionsSelector
} from '../selectors/DownloadOptionsSelectors.js';
import {createSelectSingleViewerValue, selectAllItemIds, selectAllTypes} from '../selectors/ViewerSelectors.js';

export function ViewerItemDownloadOptionsContainer() {
	const {viewerId} = useContext(ViewerContext);
	const selectItemType = useMemoFactory(createSelectSingleViewerValue, selectAllTypes, viewerId);
	const itemType = useSelector(selectItemType);
	if (itemType === 'file') {
		return <GenericFileDownloadOptionsContainer />;
	}
	return <DicomImageDownloadOptionsContainer />;
}

function GenericFileDownloadOptionsContainer() {
	const {viewerId} = useContext(ViewerContext);
	const selectItemId = useMemoFactory(createSelectSingleViewerValue, selectAllItemIds, viewerId);
	const itemId = useSelector(selectItemId);
	const downloadOptionsSelector = useMemo(() => createGenericFileDownloadOptionsSelector(itemId), [itemId]);
	const downloadOptions = useSelector(downloadOptionsSelector);
	return <ViewerItemDownloadOptions downloadOptions={downloadOptions} />;
}

function DicomImageDownloadOptionsContainer() {
	const {currentImageId: imageId} = useDicomViewerState();
	const downloadOptionsSelector = useMemo(() => createDicomImageDownloadOptionsSelector(imageId), [imageId]);
	const downloadOptions = useSelector(downloadOptionsSelector);
	return <ViewerItemDownloadOptions downloadOptions={downloadOptions} />;
}

