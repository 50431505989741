import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import PopupButton from '../../ui/components/PopupButton.js';
import {ALIGN_RIGHT, EXPAND_TOP} from '../../ui/constants/PupupMenuConstants.js';
import createViewerToolbarIconButton from './createViewerToolbarIconButton.js';

function ViewerToolBarMenu(props) {
	const {children, disabled, buttonIcon, id} = props;
	const StyledViewerToolBarButton = useMemo(() => createViewerToolbarIconButton(buttonIcon), [buttonIcon]);

	return (
		<PopupButton id={id} disabled={disabled}
				buttonElement={StyledViewerToolBarButton} align={ALIGN_RIGHT} expandDirection={EXPAND_TOP}>
			{children}
		</PopupButton>
	);
}

ViewerToolBarMenu.propTypes = {
	children: PropTypes.node.isRequired,
	buttonIcon: PropTypes.elementType.isRequired,
	id: PropTypes.string.isRequired,
	disabled: PropTypes.bool
};

ViewerToolBarMenu.defaultProps = {
	disabled: false
};

export default React.memo(ViewerToolBarMenu);
