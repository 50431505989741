import React, {useContext, useMemo} from 'react';
import PropTypes from 'prop-types';

import {buildNavigationActions, createViewerStateBuilder} from '../../utils/DicomSeriesViewerUtils.js';

const DicomSeriesViewerStateContext = React.createContext();
const DicomSeriesViewerActionsContext = React.createContext();

function DicomSeriesContextProvider({
	seriesId,
	imageIndex,
	frameOffset,
	setFrameIndex,
	children
}) {
	const viewerStateBuilder = useMemo(
		() => createViewerStateBuilder(seriesId, imageIndex), [seriesId, imageIndex]
	);
	const viewerState = useMemo(
		() => viewerStateBuilder.build(frameOffset), [frameOffset, viewerStateBuilder]
	);
	const actions = useMemo(
		() => buildNavigationActions(viewerState, imageIndex, setFrameIndex), [imageIndex, setFrameIndex, viewerState]
	);

	return (
		<DicomSeriesViewerStateContext.Provider value={viewerState}>
			<DicomSeriesViewerActionsContext.Provider value={actions}>
				{children}
			</DicomSeriesViewerActionsContext.Provider>
		</DicomSeriesViewerStateContext.Provider>
	);
}

DicomSeriesContextProvider.propTypes = {
	seriesId: PropTypes.number.isRequired,
	imageIndex: PropTypes.object.isRequired,
	frameOffset: PropTypes.number.isRequired,
	setFrameIndex: PropTypes.func.isRequired
};

export function useDicomViewerState() {
	const context = useContext(DicomSeriesViewerStateContext);
	if (context === undefined) {
		throw new Error('useDicomViewerState requires a DicomSeriesContextProvider.');
	}
	return context;
}

export function useDicomViewerActions() {
	const context = useContext(DicomSeriesViewerActionsContext);
	if (context === undefined) {
		throw new Error('useDicomViewerActions requires a DicomSeriesContextProvider.');
	}
	return context;
}

export function useDicomViewerContext() {
	const state = useDicomViewerState();
	const actions = useDicomViewerActions();
	return useMemo(() => [state, actions], [state, actions]);
}

export default DicomSeriesContextProvider;

