import {createSelector} from 'reselect';

import {selectUserContextID} from '../../../authentication/flux/selectors/SessionSelectors.js';
import {load as loadForUserAction} from '../../actions/crudresources/ClientConfigActions.js';
import {clientConfig as clientConfigFluxApi} from '../../flux/crudresources/CrudResourcesFluxApi.js';
import createCrudResourceLoader from './createCrudResourceLoader.js';

const {
	selectors: {isLoadInProgress, getRawResponse}
} = clientConfigFluxApi;

const isLoadNeededSelector = createSelector(
	isLoadInProgress, getRawResponse, selectUserContextID,
	(loadInProgress, data, userId) => Boolean(userId) && data === null && !loadInProgress
);

export default createCrudResourceLoader(clientConfigFluxApi, isLoadNeededSelector, loadForUserAction);
