import React from 'react';
import PropTypes from 'prop-types';

import {LINE_WIDTH} from './AnnotationConstants.js';

import '../../../../styles/viewer/components/annotations/MarkerDot.scss';

const LINE_WIDTH_TO_RADIUS_FACTOR = 1.5;
const POINT_RADIUS = LINE_WIDTH * LINE_WIDTH_TO_RADIUS_FACTOR;

export default class MarkerDot extends React.PureComponent {
	render() {
		const {isPrintPreview, drawContour = true} = this.props;
		return (isPrintPreview
			? (drawContour && this.renderWithContour())
			: this.renderNormal()
		);
	}

	renderWithContour() {
		const {referencePoint} = this.props;
		return (<circle key='marker-dot-contour' className='marker-dot--contour'
			cx={referencePoint[0]} cy={referencePoint[1]} r={LINE_WIDTH - 0.5} />);
	}

	renderNormal() {
		const {referencePoint} = this.props;
		return <circle className='marker-dot' cx={referencePoint[0]} cy={referencePoint[1]} r={POINT_RADIUS + 1} />;
	}
}
MarkerDot.propTypes = {
	isPrintPreview: PropTypes.bool,
	drawContour: PropTypes.bool,
	referencePoint: PropTypes.instanceOf(Float32Array)
};
