import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {hideChangePasswordDialog} from '../../../ui/flux/UIActions.js';
import {uiShowChangePasswordDialog} from '../../../ui/flux/UISelectors.js';
import ChangePasswordDialog from '../../components/ChangePasswordDialog.js';
import {changePassword, clearError} from '../actions/PasswordActions.js';
import {selectPasswordOperationState} from '../selectors/NewPasswordSelectors.js';
import {selectIsBusy} from '../selectors/PasswordStateSelectors.js';
import {selectUserContextLoginName} from '../selectors/SessionSelectors.js';

export default connect(
	createStructuredSelector({
		username: selectUserContextLoginName,
		passwordChangeInProgress: selectIsBusy,
		passwordOperationState: selectPasswordOperationState,
		uiShowChangePasswordDialog
	}),
	bindActions({
		clearChangeError: clearError,
		changePassword,
		hideChangePasswordDialog
	})
)(ChangePasswordDialog);
