import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {replaceLocation} from '../../../router/flux/LocationActions.js';
import NewPasswordPage from '../../components/NewPasswordPage.js';
import {setNewPassword} from '../actions/PasswordActions.js';
import {attachSession} from '../actions/SessionActions.js';
import {selectOriginalLocation, selectUsername} from '../selectors/NewPasswordSelectors.js';
import {selectIsLoggedIn, selectIsSessionBusy} from '../selectors/SessionSelectors.js';

export default connect(
	createStructuredSelector({
		hasSession: selectIsLoggedIn,
		isSessionBusy: selectIsSessionBusy,
		username: selectUsername,
		originalLocation: selectOriginalLocation
	}),
	bindActions({
		replaceLocation,
		attachSession,
		setNewPassword
	})
)(NewPasswordPage);
