import {mat4, vec2, vec3} from 'gl-matrix';
import Immutable from 'immutable';
import _once from 'lodash.once';

import {createPlaneFromOriginAndDirections} from '../../../../viewer/utils/math/Plane.js';
import {
	BITS_ALLOCATED_TAG_ID,
	BITS_STORED_TAG_ID,
	FRAME_OF_REFERENCE_UID_TAG_ID,
	HIGH_BIT_TAG_ID,
	IMAGE_COMMENTS_TAG_ID,
	IMAGE_ORIENTATION_PATIENT_TAG_ID,
	IMAGE_PIXEL_SPACING_TAG_ID,
	IMAGE_POSITION_PATIENT_TAG_ID, MODALITY_TAG_ID,
	NUMBER_COLUMNS_TAG_ID,
	NUMBER_OF_FRAMES_TAG_ID,
	NUMBER_ROWS_TAG_ID,
	PATIENT_ORIENTATION_TAG_ID,
	PHOTOMETRIC_INTERPRETATION_TAG_ID,
	PIXEL_ASPECT_RATIO_TAG_ID,
	PIXEL_REPRESENTATION_TAG_ID,
	PIXEL_SPACING_TAG_ID,
	SAMPLES_PER_PIXEL_TAG_ID,
	SEQUENCE_OF_ULTRASOUND_REGIONS_TAG_ID,
	SLICE_THICKNESS_TAG_ID
} from '../../../constants/DicomTagIDs.js';
import {
	calculateOrientationDescription,
	createTagEntry, getOppositeOrientations,
	getOrientationsFromAbbreviations
} from '../../../utils/DicomDumpUtils.js';
import ReadOnlyDicomMap from './ReadOnlyDicomMap.js';
import UltrasoundRegion from './UltrasoundRegion.js';

const ROW_ORIENTATION_PROPERTY = 'rowOrientation';
const COLUMN_ORIENTATION_PROPERTY = 'columnOrientation';
const ORIENTATION_VECTOR_SIZE = 3;
const X_COMPONENT_INDEX = 0;
const Y_COMPONENT_INDEX = 1;
const Z_COMPONENT_INDEX = 2;
const ROTATION_MATRIX_SIZE = 16;

export default class DicomDump {
	constructor(rawDicomDump = Immutable.Map()) {
		this.rawDump = rawDicomDump;
		this.dicomMap = new ReadOnlyDicomMap(rawDicomDump.getIn(['data-set', 'elements'], Immutable.Map()));

		this.getPixelSpacing = _once(
			this.calculatePixelSpacing.bind(this)
		);
		this.getPixelSize = _once(
			this.calculatePixelSize.bind(this)
		);
		this.getPixelAspectRatio = _once(
			this.calculateCheckedPixelAspectRatio.bind(this)
		);
		this.getImageOrientation = _once(
			this.calculateImageOrientation.bind(this)
		);
		this.getPatientOrientation = _once(
			this.calculatePatientOrientation.bind(this)
		);
		this.getPlane = _once(
			this.calculateImagePlane.bind(this)
		);
		this.getPatientToImageProjectionMatrix = _once(
			this.calculatePatientToImageProjectionMatrix.bind(this)
		);
		this.getPatientToImagePixelsProjectionMatrix = _once(
			this.calculatePatientToImagePixelsProjectionMatrix.bind(this)
		);
		this.getSliceThickness = _once(
			this.createFloatValueGetter(SLICE_THICKNESS_TAG_ID)
		);
		this.getNumberOfFrames = _once(
			this.createFloatValueGetter(NUMBER_OF_FRAMES_TAG_ID)
		);
		this.getUltrasoundRegions = _once(
			this.extractUltrasoundRegions.bind(this)
		);
		this.getRowOrientationDescriptions = _once(
			this.calculateOrientationDescriptions.bind(this, ROW_ORIENTATION_PROPERTY)
		);
		this.getColumnOrientationDescriptions = _once(
			this.calculateOrientationDescriptions.bind(this, COLUMN_ORIENTATION_PROPERTY)
		);
		this.getSupportsImagePixelModule = _once(
			this.supportsImagePixelModule.bind(this)
		);
		this.getIsGreyscaleImage = _once(
			this.isGreyscaleImage.bind(this)
		);
	}

	createFloatValueGetter(tagId) {
		return () => (this.hasTag(tagId) ? this.dicomMap.getNumericTagValue(tagId) : null);
	}

	setTagValue(tagId, value) {
		const updatedRawDump = this.rawDump.updateIn(['data-set', 'elements', tagId],
			element => (element ? element.set('value', `${value}`) : createTagEntry(tagId, `${value}`))
		);
		return new DicomDump(updatedRawDump);
	}

	removeTag(tagId) {
		let nextDump = this;
		const elementPath = ['data-set', 'elements', tagId];
		if (this.rawDump.hasIn(elementPath)) {
			nextDump = new DicomDump(this.rawDump.deleteIn(elementPath));
		}
		return nextDump;
	}

	/**
	 * reads a values for a specified tag
	 *
	 * @param {String} tagId the id of the tag to retrieve.
	 * 	   it has to be provided as string in the following form <group><element>
	 *     For instance to retrieve the content of the tag "Image Type" which has grou 0x0008 and element 0x0008
	 * 	   it has to be provided in the following form '00080008'
	 * @returns {String} the tags value
	 */
	getTagValue(tagId) {
		return this.dicomMap.getTagValue(tagId);
	}

	hasTag(tagId) {
		return this.dicomMap.hasTag(tagId);
	}

	/**
	 * Reads a collection of values for a specified tag which are separated by backslash.
	 * Furthermore each value is converted into a Number by using parseFloar
	 *
	 * @param {String} tagId the id of the tag to retrieve.
	 * 	   it has to be provided as string in the following form <group><element>
	 *     For instance to retrieve the content of the tag "Image Type" which has grou 0x0008 and element 0x0008
	 *     it has to be provided in the following form '00080008'
	 */
	getNumericTagValues(tagId) {
		return this.dicomMap.getNumericTagValues(tagId);
	}

	calculatePixelSpacing() {
		let pixelSpacing = null;
		if (this.getUltrasoundRegions().isEmpty()) {
			pixelSpacing =
				(this.hasTag(IMAGE_PIXEL_SPACING_TAG_ID) && this.getNumericTagValues(IMAGE_PIXEL_SPACING_TAG_ID)) ||
				(this.hasTag(PIXEL_SPACING_TAG_ID) && this.getNumericTagValues(PIXEL_SPACING_TAG_ID)) || null;
		}
		return pixelSpacing && vec2.set(new Float64Array(2), pixelSpacing.get(0), pixelSpacing.get(1));
	}

	calculatePixelSize() {
		const pixelSpacing = this.getPixelSpacing();
		return pixelSpacing && vec2.set(new Float64Array(2), pixelSpacing[1], pixelSpacing[0]);
	}

	getRawPixelAspectRatio() {
		let rawPixelAspectRatio = null;
		if (this.getUltrasoundRegions().isEmpty()) {
			const pixelAspectRatioDicomValues = this.getNumericTagValues(PIXEL_ASPECT_RATIO_TAG_ID);
			if (pixelAspectRatioDicomValues.size > 1) {
				rawPixelAspectRatio = pixelAspectRatioDicomValues.get(0) / pixelAspectRatioDicomValues.get(1);
			}
		}
		return rawPixelAspectRatio;
	}

	getFrameOfReferenceUID() {
		return this.getTagValue(FRAME_OF_REFERENCE_UID_TAG_ID);
	}

	calculateCheckedPixelAspectRatio() {
		const pixelSpacing = this.getPixelSpacing();
		let pixelAspectRatio = this.getRawPixelAspectRatio(); //if defined otherwise null
		if (pixelSpacing) {
			const pixelSpacingBasedRatio = pixelSpacing[0] / pixelSpacing[1];
			if (!pixelAspectRatio || Immutable.is(pixelAspectRatio, pixelSpacingBasedRatio)) {
				pixelAspectRatio = pixelSpacingBasedRatio;
			} else {
				pixelAspectRatio = null;
			}
		}
		return pixelAspectRatio;
	}

	hasLocalizerInformation() {
		return this.hasTag(FRAME_OF_REFERENCE_UID_TAG_ID) &&
		 this.hasTag(IMAGE_ORIENTATION_PATIENT_TAG_ID) &&
		 this.hasTag(IMAGE_POSITION_PATIENT_TAG_ID);
	}

	/**
	 * @return {Immutable.Map} an object containing the orientation information stored in the dicom header.
	 * This information consists of a row- and columOrientation.
	 * The cross product of both vectors defines the normal vector to the image plane
	 * @return {vec3} return.rowOrientation the orientation vector which defines the row direction
	 * @return {vec3} return.columnOrientation the orientation vector which defines the column orientation
	 */
	calculateImageOrientation() {
		let imageOrientation = null;
		if (this.hasTag(IMAGE_ORIENTATION_PATIENT_TAG_ID)) {
			const rawOrientation = this.getNumericTagValues(IMAGE_ORIENTATION_PATIENT_TAG_ID);
			const rowVector = vec3.set(new Float64Array(ORIENTATION_VECTOR_SIZE),
				  rawOrientation.get(X_COMPONENT_INDEX),
				  rawOrientation.get(Y_COMPONENT_INDEX),
				  rawOrientation.get(Z_COMPONENT_INDEX)
			);
			const columnVector = vec3.set(new Float64Array(ORIENTATION_VECTOR_SIZE),
				  rawOrientation.get(ORIENTATION_VECTOR_SIZE + X_COMPONENT_INDEX),
				  rawOrientation.get(ORIENTATION_VECTOR_SIZE + Y_COMPONENT_INDEX),
				  rawOrientation.get(ORIENTATION_VECTOR_SIZE + Z_COMPONENT_INDEX)
			);
			imageOrientation = Immutable.Map({
				[ROW_ORIENTATION_PROPERTY]: rowVector,
				[COLUMN_ORIENTATION_PROPERTY]: columnVector
			});
		}
		return imageOrientation;
	}

	calculatePatientOrientation() {
		let patientOrientation = null;
		if (this.hasTag(PATIENT_ORIENTATION_TAG_ID)) {
			const rawPatientOrientation = this.dicomMap.getTagValues(PATIENT_ORIENTATION_TAG_ID);
			if (rawPatientOrientation && rawPatientOrientation.size === 2) {
				patientOrientation = Immutable.Map({
					[ROW_ORIENTATION_PROPERTY]: getOrientationsFromAbbreviations(rawPatientOrientation.get(0)),
					[COLUMN_ORIENTATION_PROPERTY]: getOrientationsFromAbbreviations(rawPatientOrientation.get(1))
				});
			}
		}
		return patientOrientation;
	}

	calculateOrientationDescriptions(orientation) {
		let description = null;
		const imageOrientation = this.getImageOrientation();
		if (imageOrientation) {
			description = calculateOrientationDescription(imageOrientation.get(orientation));
		} else {
			const patientOrientation = this.getPatientOrientation();
			if (patientOrientation) {
				const orientationDescription = patientOrientation.get(orientation);
				description = [
					getOppositeOrientations(orientationDescription),
					orientationDescription
				];
			}
		}
		return description;
	}

	calculateImagePlane() {
		const imageOrientation = this.getImageOrientation();
		const imagePosition = this.getImagePositionPatient();
		let imagePlane = null;
		if (imageOrientation && imagePosition) {
			imagePlane = createPlaneFromOriginAndDirections(
				  imagePosition,
				  imageOrientation.get(ROW_ORIENTATION_PROPERTY),
				  imageOrientation.get(COLUMN_ORIENTATION_PROPERTY)
			);
		}
		return imagePlane;
	}

	/**
	 * the x,y,z coordinates of the upper left hand corner (first pixel transmitted of the image)
	 *
	 * @return {vec3} if provided a vector containing the image position otherwise null
	 */
	getImagePositionPatient() {
		return toVec3(this.getNumericTagValues(IMAGE_POSITION_PATIENT_TAG_ID));
	}

	getNumberRows() {
		return parseInt(this.getTagValue(NUMBER_ROWS_TAG_ID), 10);
	}

	getNumberColumns() {
		return parseInt(this.getTagValue(NUMBER_COLUMNS_TAG_ID), 10);
	}

	hasSquarePixels() {
		const pixelSpacing = this.getPixelSpacing();
		return pixelSpacing && pixelSpacing[0] === pixelSpacing[1];
	}

	getModality() {
		return this.getTagValue(MODALITY_TAG_ID);
	}

	isGreyscaleImage() {
		return this.getTagValue(PHOTOMETRIC_INTERPRETATION_TAG_ID).includes('MONOCHROME');
	}

	supportsImagePixelModule() {
		return [
			SAMPLES_PER_PIXEL_TAG_ID,
			PHOTOMETRIC_INTERPRETATION_TAG_ID,
			NUMBER_ROWS_TAG_ID,
			NUMBER_COLUMNS_TAG_ID,
			BITS_ALLOCATED_TAG_ID,
			BITS_STORED_TAG_ID,
			HIGH_BIT_TAG_ID,
			PIXEL_REPRESENTATION_TAG_ID
		].every(this.hasTag.bind(this));
	}

	/**
	 * Returns a Projection matrix, to project a point in patient coordinates into
	 * the coordinate system of the the image plane.
	 *
	 * The resulting point is in (sub)pixels originated in the images center.
	 * The image's x axis increases to the right of the image and its y axis increases to the image's bottom.
	 * @returns {mat4} the projection matrix to project into the image plane.
	 */
	calculatePatientToImagePixelsProjectionMatrix() {
		const pixelSpacing = this.getPixelSpacing();
		const projectionMatrixToImage = this.getPatientToImageProjectionMatrix();
		let projectionMatrix = null;
		if (pixelSpacing && projectionMatrixToImage) {
			const scaleVector = vec3.set(new Float64Array(ORIENTATION_VECTOR_SIZE),
				  1 / pixelSpacing[1], 1 / pixelSpacing[0], 1
			);
			const scaleToPixelsMatrix = mat4.identity(new Float64Array(ROTATION_MATRIX_SIZE));
			mat4.scale(scaleToPixelsMatrix, scaleToPixelsMatrix, scaleVector);
			projectionMatrix = mat4.multiply(scaleToPixelsMatrix, scaleToPixelsMatrix, projectionMatrixToImage);
		}
		return projectionMatrix;
	}

	/**
	 * Returns a Projection matrix, to project a point in patient coordinates into
	 * the coordinate system of the the image plane.
	 *
	 * The resulting point is in mm originated in the images center.
	 * The image's x axis increases to the right of the image and its y axis increases to the image's bottom.
	 * @returns {mat4} the projection matrix to project into the image plane.
	 */
	calculatePatientToImageProjectionMatrix() {
		const imagePositionPatient = this.getImagePositionPatient();
		const pixelSpacing = this.getPixelSpacing();
		const imageOrientationPatient = this.getImageOrientation();

		const numberRows = this.getNumberRows();
		const numberColumns = this.getNumberColumns();
		let projectionMatrix = null;

		if (imagePositionPatient && pixelSpacing && imageOrientationPatient) {
			const rowVector = imageOrientationPatient.get(ROW_ORIENTATION_PROPERTY);
			const columnVector = imageOrientationPatient.get(COLUMN_ORIENTATION_PROPERTY);
			const normalVector = vec3.cross(new Float64Array(ORIENTATION_VECTOR_SIZE), rowVector, columnVector);

			//Projects Vectors relative to the image Orientation onto the image plane
			//(still in patient coordinates (mm))
			//applying this matrix results in x and y being relative to the image plane and z set to 0
			projectionMatrix = projectVectors(rowVector, columnVector, normalVector);

			const manipulationVector = vec3.scale(new Float64Array(ORIENTATION_VECTOR_SIZE), imagePositionPatient, -1);
			mat4.translate(projectionMatrix, projectionMatrix, manipulationVector);

			const translationVector = vec3.set(manipulationVector,
				  -0.5 * pixelSpacing[1] * numberColumns, -0.5 * pixelSpacing[0] * numberRows, 0
			);
			const moveToCenterMatrix = mat4.identity(new Float64Array(ROTATION_MATRIX_SIZE));
			mat4.translate(moveToCenterMatrix, moveToCenterMatrix, translationVector);

			projectionMatrix = mat4.multiply(projectionMatrix, moveToCenterMatrix, projectionMatrix);
		}
		return projectionMatrix;
	}

	/**
	 * @return {String} the value of tag Image Comments
	 */
	getImageComments() {
		return this.getTagValue(IMAGE_COMMENTS_TAG_ID);
	}

	extractUltrasoundRegions() {
		const rows = this.getNumberRows();
		const columns = this.getNumberColumns();
		const imageCenter = new Float64Array([columns / 2.0, rows / 2.0]);
		return this.dicomMap.getSequence(SEQUENCE_OF_ULTRASOUND_REGIONS_TAG_ID)
			.map(item => new UltrasoundRegion(item, imageCenter));
	}
}

function projectVectors(rowVector, columnVector, normalVector) {
	const [rv1, rv2, rv3] = rowVector;
	const [cv1, cv2, cv3] = columnVector;
	const [nv1, nv2, nv3] = normalVector;
	return Float64Array.of(
		rv1, cv1, nv1, 0,
		rv2, cv2, nv2, 0,
		rv3, cv3, nv3, 0,
		0, 0, 0, 1
	);
}

function toVec3(coordinates) {
	return coordinates.size > 0
		? vec3.set(new Float64Array(ORIENTATION_VECTOR_SIZE),
			coordinates.get(0),
			coordinates.get(1),
			coordinates.get(2))
		: null;
}
