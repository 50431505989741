import Button from '../../ui/components/Button.js';
import IconButton from '../../ui/components/IconButton.js';
import MenuEntry from '../../ui/components/MenuEntry.js';
import RippleButton from '../../ui/components/RippleButton.js';
import clickTracked from '../utils/clickTracked.js';

export const ClickTrackedMenuEntry = clickTracked(MenuEntry);
export const ClickTrackedIconButton = clickTracked(IconButton);
export const ClickTrackedRippleButton = clickTracked(RippleButton);
export const ClickTrackedButton = clickTracked(Button);
