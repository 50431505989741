import React from 'react';
import Immutable from 'immutable';

import SyncPointHandleContainer from '../../flux/containers/annotations/SyncPointHandleContainer.js';
import createAnnotation from './createAnnotation.js';

function isSyncPointAnnotationSupported() {
	// Cannot be decided exclusively on the viewerItem alone.
	return false;
}

function getDefaultPropertiesForSyncPointAnnotation() {
	// We don't really have any properties
	return Immutable.Map();
}

const SyncPointAnnotation = createAnnotation(
	isSyncPointAnnotationSupported, getDefaultPropertiesForSyncPointAnnotation, SyncPointHandleContainer
);
export default SyncPointAnnotation;
