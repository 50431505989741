import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';

import {callSafe} from '../../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {uiIsSmallDevice} from '../../flux/UISelectors.js';
import AutoComplete from '../AutoComplete.js';
import AutoCompleteTextField from './AutoCompleteTextField.js';
import FullScreenAutoCompleteTextField from './FullScreenAutoCompleteTextField.js';

export default function MobileAwareAutoCompleteTextField(props) {
	const {onChange} = props;
	const isSmall = useSelector(uiIsSmallDevice);
	const handleOnBlur = useCallback(event => {
		callSafe(onChange, event.target.value);
	}, [onChange]);
	const autoCompleteTextFieldProps = cloneWithoutProperties(props, 'fullScreenTextFieldButtons');
	return isSmall
		? <FullScreenAutoCompleteTextField {...props} />
		: <AutoCompleteTextField onBlur={handleOnBlur} {...autoCompleteTextFieldProps} />;
}

MobileAwareAutoCompleteTextField.propTypes = AutoComplete.propTypes;
MobileAwareAutoCompleteTextField.defaultProps = AutoComplete.defaultProps;
