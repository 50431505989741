import React from 'react';
import {glMatrix, vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {renderPathString} from '../../utils/SVGUtils.js';

import '../../../../styles/viewer/components/annotations/Path.scss';

function Path(props) {
	const {points, closed, transformationMatrix, className} = props;
	let containerPoints = points;
	if (transformationMatrix) {
		containerPoints = points.map(
			point => vec2.transformMat3(vec2.create(), point, transformationMatrix)
		);
	}
	const finalClassName = className ? className : 'path--line';
	return (
		<path d={renderPathString(containerPoints, closed)} className={finalClassName} />
	);
}

Path.propTypes = {
	points: PropTypes.arrayOf(PropTypes.instanceOf(Float32Array)),
	closed: PropTypes.bool,
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	className: PropTypes.string
};

Path.defaultProps = {
	points: [],
	closed: false
};

export default Path;
