import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {extractBoxStyles} from '../../commons/utils/StyleUtils.js';

import '../../../styles/material-design/components/Card.scss';

function Card(props) {
	const {pickedUp, children, style, className} = props;
	const styles = {...extractBoxStyles(props), ...style || {}};
	const classNameFromProps = ` ${className || ''}`;
	return (
		<div className={classNames({
			'material-card': true,
			'material-card--picked-up': pickedUp
		}) + classNameFromProps} style={styles}>
			{children}
		</div>
	);
}

Card.defaultProps = {
	pickedUp: false
};

Card.propTypes = {
	pickedUp: PropTypes.bool,
	style: PropTypes.object,
	className: PropTypes.string
};

export default Card;
