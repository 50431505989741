import {connect} from 'react-redux';
import {createSelector, createStructuredSelector} from 'reselect';

import {currentLocale} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {LABEL_TEXT_PROPERTY_NAME} from '../../components/annotations/AnnotationConstants.js';
import EditLabelIcon from '../../components/annotations/EditLabelIcon.js';
import {UNINITIALIZED_ANNOTATION_PROPERTIES} from '../../constants/AnnotationConstants.js';
import {setActiveAnnotation, updateAnnotation} from '../actions/AnnotationActions.js';
import {createAnnotationPropertiesSelector} from '../selectors/AnnotationSelectors.js';

export default connect(
	(state, ownProps) => {
		const annotationPropertiesSelector = createAnnotationPropertiesSelector(ownProps.annotationId);
		return createStructuredSelector({
			locale: currentLocale,
			annotationProperties: annotationPropertiesSelector,
			isDialogVisible: createSelector(annotationPropertiesSelector, annotationProperties => (
				annotationProperties === UNINITIALIZED_ANNOTATION_PROPERTIES
					? false
					: annotationProperties.get('showDialog', false)
			)),
			[LABEL_TEXT_PROPERTY_NAME]: createSelector(annotationPropertiesSelector, annotationProperties => (
				annotationProperties === UNINITIALIZED_ANNOTATION_PROPERTIES
					? null
					: annotationProperties.get(LABEL_TEXT_PROPERTY_NAME)
			))
		});
	},
	bindActions({
		updateAnnotation,
		setActiveAnnotation
	}),
	function mergeProps(stateProps, dispatchProps, ownProps) {
		const {annotationProperties, isDialogVisible, [LABEL_TEXT_PROPERTY_NAME]: labelText} = stateProps;
		const {annotationId, locale} = ownProps;
		const updateAnnotationProperties = changedProps => {
			const modifiedProps = Object.keys(changedProps).reduce(
				(props, key) => props.set(key, changedProps[key]),
				annotationProperties
			);
			dispatchProps.updateAnnotation(annotationId, modifiedProps);
		};
		return {
			...ownProps, locale, isDialogVisible,
			[LABEL_TEXT_PROPERTY_NAME]: labelText,
			setAnnotationLabel: newLabel => updateAnnotationProperties({
				[LABEL_TEXT_PROPERTY_NAME]: newLabel,
				showDialog: false
			}),
			hideDialog: () => updateAnnotationProperties({showDialog: false}),
			showDialog: () => {
				updateAnnotationProperties({showDialog: true});
				dispatchProps.setActiveAnnotation(annotationId);
			}
		};
	}
)(EditLabelIcon);
