import {useEffect, useMemo} from 'react';

export function useMemoFactory(factory, ...params) {
	return useMemo(
		() => factory(...params),
		// We explicitly don't want factory to be a dependency.
		// This would prevent a user from using a lambda function as factory.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		params
	);
}

export function useFormattedMessage(translator, ...params) {
	return useMemoFactory(translator.getFormattedMessage.bind(translator), ...params);
}

// TODO PSp :: Remove this dangerous function!
export function useEffectOnMount(effect, ...params) {
	useEffectEasily(effect, ...params);
}

// TODO PSp :: Remove this dangerous function!
export function useEffectOnUnmount(effect, ...params) {
	useEffectOnMount((...effectParams) => () => effect(...effectParams), ...params);
}

export function useEffectEasily(effect, ...params) {
	useEffect(
		() => effect(...params),
		// We explicitly don't want factory to be a dependency.
		// This would prevent a user from using a lambda function as factory.
		// eslint-disable-next-line react-hooks/exhaustive-deps
		params
	);
}

export {default as useChangedState} from './useChangedState.js';
export {default as useObservable} from './useObservable.js';
export {default as useChangedValueOrState} from './useChangedValueOrState.js';
export {default as useObjectUrl} from './useObjectUrl.js';
export {default as useUniqueId} from './useUniqueId.js';
