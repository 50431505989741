import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import Button, {buttonClasses} from '../../ui/components/Button.js';

function PatientDetailsDocumentButton(props) {
	const {onClick, icon, sx, ...remainingProps} = props;
	const sxProps = useMemoFactory(getSxProps, sx);
	return <Button flat startIcon={icon} onClick={onClick} fullWidth sx={sxProps} {...remainingProps} />;
}

PatientDetailsDocumentButton.propTypes = {
	...cloneWithoutProperties(Button.propTypes, 'startIcon', 'endIcon'),
	icon: PropTypes.node.isRequired
};

function getSxProps(sx) {
	return {
		[`& .${buttonClasses.startIcon}`]: {marginRight: '2px'},
		...sx
	};
}

export default React.memo(PatientDetailsDocumentButton);
