import React from 'react';
import PropTypes from 'prop-types';

import NotchInset from '../../ui/components/layout/NotchInset.js';
import {BLACK_COLOR_NAME, WHITE_COLOR_NAME} from '../../ui/constants/ColorConstants.js';
import ContentPageContainer from '../containers/ContentPageContainer.js';
import SidebarContainer from '../containers/SidebarContainer.js';
import SidebarHeaderContainer from '../containers/SidebarHeaderContainer.js';
import SidebarBranding from './SidebarBranding.js';
import SidebarFooter from './SidebarFooter.js';

import '../../../styles/commons/components/Page.scss';

export default function createWebAppPage(TitleBarComponent, BodyComponent, MenuComponent, pageName = '') {
	function WebAppPage(props) {
		const {sidebarElevated} = props;
		return (
			<ContentPageContainer className={pageName}>
				<TitleBarComponent {...props} />
				<div className='webview-page--body-container'>
					<NotchInset variant='left' bgColor={BLACK_COLOR_NAME} />
					<BodyComponent {...props} />
					<NotchInset variant='right' bgColor={BLACK_COLOR_NAME} />
					<SidebarContainer>
						<SidebarHeaderContainer />
						<div className='webview-page--sidebar--menu-and-branding'>
							<NotchInset variant='left' bgColor={WHITE_COLOR_NAME} enabled={sidebarElevated}>
								<MenuComponent />
							</NotchInset>
							<SidebarBranding />
						</div>
						<SidebarFooter />
					</SidebarContainer>
				</div>
			</ContentPageContainer>
		);
	}
	WebAppPage.propTypes = {
		sidebarElevated: PropTypes.bool.isRequired
	};
	return WebAppPage;
}
