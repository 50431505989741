import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import LoginWorkflowController from '../../components/LoginWorkflowController.js';
import {resetLoginWorkflow} from '../actions/SessionActions.js';
import {
	selectCurrentTransition,
	selectIsSessionBusy,
	selectSessionError,
	selectSessionState
} from '../selectors/SessionSelectors.js';

export default connect(
	createStructuredSelector({
		isBusy: selectIsSessionBusy,
		workflowState: selectSessionState,
		error: selectSessionError,
		currentTransition: selectCurrentTransition
	}),
	bindActions({
		resetLoginWorkflow
	})
)(LoginWorkflowController);
