import React from 'react';
import PropTypes from 'prop-types';

import Footer from '../../../a11y/components/landmarks/Footer.js';
import A11yTranslator from '../../../a11y/i18n/A11yTranslator.js';
import useTranslation from '../../../i18n/hooks/useTranslation.js';
import {useMemoFactory} from '../../utils/customHooks/index.js';
import {combineClassNames} from '../../utils/StyleUtils.js';
import ProductInfo from './ProductInfo.js';
import SynedraLogoSmall from './SynedraLogoSmall.js';

import '../../../../styles/commons/components/webview/PageFooter.scss';

export default function PageFooter(props) {
	const {className, children} = props;
	const footerClassNames = useMemoFactory(combineClassNames, 'page-footer', className);
	const footerTitle = useTranslation('A11YVersionInfo', undefined, A11yTranslator);
	return (
		<Footer className={footerClassNames} title={footerTitle}>
			{children}
			<ProductInfo />
			<SynedraLogoSmall className='synedra-logo' />
		</Footer>
	);
}

PageFooter.propTypes = {
	className: PropTypes.string
};
