import React from 'react';
import PropTypes from 'prop-types';

export default function createCenteredSVG(width, height, element) {
	const halfWidth = width / 2;
	const halfHeight = height / 2;
	function CenteredSVG(props) {
		const {x, y, children} = props;
		return (
			<g transform={`translate(-${halfWidth}, -${halfHeight})`}>
				<svg x={x} y={y} width={width} height={height}>
					{element}
					{children}
				</svg>
			</g>
		);
	}
	CenteredSVG.propTypes = {
		x: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		y: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	};
	CenteredSVG.defaultProps = {
		x: 0,
		y: 0
	};
	return CenteredSVG;
}
