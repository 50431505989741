import {createEvent} from '../commons/utils/EventUtils.js';

const UNSET = Symbol('unset');

export default function createPropertyTracker(propertyName, initialValue) {
	let previousValue = initialValue;
	return function trackProperty(event, next) {
		const currentValue = event.get(propertyName, UNSET);
		if (currentValue !== UNSET && currentValue !== previousValue) {
			previousValue = currentValue;
			next(createEvent(buildEventName(propertyName), {[propertyName]: currentValue}, event));
		}
		next(event);
	};
}

function buildEventName(propertyName) {
	return `${propertyName}Changed`;
}
