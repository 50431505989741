import {createFormValidatorFactory} from '../ValidatorFactories.js';

function allFieldsFilledOutValidationFunction(locale, translatedFieldLabels, translator, message, fieldValues) {
	return !fieldValues.some(fieldValue => !fieldValue) || translator.getFormattedMessage(message, locale);
}

export default function allFieldsFilledOutValidator(translator, message) {
	return createFormValidatorFactory(
		(locale, translatedFieldLabels, ...fieldValues) => allFieldsFilledOutValidationFunction(
			locale, translatedFieldLabels, translator, message, fieldValues
		)
	);
}
