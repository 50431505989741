import React from 'react';
import PropTypes from 'prop-types';

import Alert from '../../../a11y/components/landmarks/Alert.js';
import LargeCheckIcon from '../../../material-design/components/icons/LargeCheckIcon.js';
import LargeErrorIcon from '../../../material-design/components/icons/LargeErrorIcon.js';
import LargeInfoIcon from '../../../material-design/components/icons/LargeInfoIcon.js';
import PrimaryItemText from '../../../material-design/components/text/PrimaryItemText.js';
import Spinner from '../../../ui/components/Spinner.js';
import {STATUS_BUSY, STATUS_FAILED, STATUS_SUCCESS, STATUS_WARNING} from '../../constants/StatusMessageConstants.js';
import {combineClassNames} from '../../utils/StyleUtils.js';
import {ColumnCenterLayout} from '../layout/CenterLayout.js';

import '../../../../styles/commons/components/webview/StatusMessage.scss';

const LARGE_CHECK_ICON_INSTANCE = <LargeCheckIcon />;
const LARGE_ERROR_ICON_INSTANCE = <LargeErrorIcon />;
const LARGE_WARNING_ICON_INSTANCE = <LargeInfoIcon />;
const SPINNER_INSTANCE = <Spinner />;

export default class StatusMessage extends React.PureComponent {
	render() {
		const {className, children, status, statusId} = this.props;
		const finalClassName = combineClassNames('status-message', className, getStatusClassName(status));
		return (
			<ColumnCenterLayout data-status-id={statusId} className={finalClassName} element={Alert}>
				{renderIcon(status)}
				<PrimaryItemText className='status-message--text'>
					{children}
				</PrimaryItemText>
			</ColumnCenterLayout>
		);
	}
}

StatusMessage.propTypes = {
	className: PropTypes.string,
	status: PropTypes.oneOf([STATUS_BUSY, STATUS_FAILED, STATUS_SUCCESS, STATUS_WARNING]),
	statusId: PropTypes.string
};

StatusMessage.defaultProps = {
	status: STATUS_SUCCESS
};

function renderIcon(status) {
	let icon = false;
	switch (status) {
		case STATUS_FAILED:
			icon = LARGE_ERROR_ICON_INSTANCE;
			break;
		case STATUS_WARNING:
			icon = LARGE_WARNING_ICON_INSTANCE;
			break;
		case STATUS_SUCCESS:
			icon = LARGE_CHECK_ICON_INSTANCE;
			break;
		case STATUS_BUSY:
			icon = SPINNER_INSTANCE;
			break;
		default:
	}
	return icon;
}

function getStatusClassName(status) {
	let statusClassName = '';
	switch (status) {
		case STATUS_SUCCESS:
			statusClassName = 'status-message--success';
			break;
		case STATUS_WARNING:
			statusClassName = 'status-message--warning';
			break;
		case STATUS_FAILED:
			statusClassName = 'status-message--error';
			break;
		default:
	}
	return statusClassName;
}
