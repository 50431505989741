import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import {updateFullScreenElementVisibilityStatus} from '../../ui/flux/UIActions.js';
import {FULLSCREEN_TRANSITION_TIME} from '../constants/FullScreenWrapperConstants.js';
import {bindActions} from '../utils/FluxUtils.js';
import {callSafe} from '../utils/FunctionUtils.js';
import {combineClassNames} from '../utils/StyleUtils.js';
import EventBarrier from './EventBarrier.js';
import ModalBackground from './ModalBackground.js';
import ExpandingTransition from './transitions/ExpandingTransition.js';

import '../../../styles/commons/components/FullScreenWrapper.scss';

const EVENT_BARRIER_STOP_PROPAGATION = [
	'onPointerDown', 'onPointerUp', 'onMouseDown', 'onMouseUp', 'onTouchStart', 'onTouchEnd', 'onClick', 'onKeyDown',
	'onKeyUp', 'onKeyPress', 'onMouseEnter', 'onMouseLeave', 'onPointerEnter', 'onPointerLeave'
];
const EVENT_BARRIER_HANDLE_NATIVE = ['onKeyDown', 'onKeyUp', 'onKeyPress'];

class FullScreenWrapper extends React.Component {
	constructor(props, context) {
		super(props, context);
		const {visible} = this.props;

		this.state = {
			visible: visible === undefined || visible,
			propsVisible: visible
		};
	}

	render() {
		const {visible} = this.state;
		const {children, className, onClose, sourceBoundingRect, targetBoundingRect} = this.props;

		const onlyChild = React.Children.only(children);
		const fullscreenElement = React.cloneElement(
			onlyChild,
			this.buildClonePropsForFullScreenElement(onlyChild)
		);

		return (
			<EventBarrier className={combineClassNames('full-screen-container', className)}
			              stopPropagation={EVENT_BARRIER_STOP_PROPAGATION}
			              handleNative={EVENT_BARRIER_HANDLE_NATIVE}>
				<ModalBackground visible={visible} />
				<ExpandingTransition visible={visible} onExited={onClose}
					sourceBoundingRect={sourceBoundingRect}
					targetBoundingRect={targetBoundingRect}
					className='full-screen-container--content'
					transitionDuration={FULLSCREEN_TRANSITION_TIME}>
					{fullscreenElement}
				</ExpandingTransition>
			</EventBarrier>
		);
	}

	hide() {
		this.setState({visible: false});
	}

	static getDerivedStateFromProps(nextProps, state) {
		let stateUpdate = null;
		if (nextProps.visible !== state.propsVisible) {
			stateUpdate = {
				visible: nextProps.visible === undefined || nextProps.visible,
				propsVisible: nextProps.visible
			};
		}
		return stateUpdate;
	}

	buildClonePropsForFullScreenElement(fullscreenElement) {
		const {onClose} = fullscreenElement.props;
		return {
			onClose: this.buildOnClose(onClose)
		};
	}

	buildOnClose(additionalOnClose) {
		return () => {
			this.hide();
			callSafe(additionalOnClose);
		};
	}

	componentDidMount() {
		const {visible} = this.state;
		const {updateModalDialogVisibilityStatus} = this.props;
		if (visible) {
			updateModalDialogVisibilityStatus({fullScreenElementVisibility: true});
		}
	}

	componentWillUnmount() {
		const {updateModalDialogVisibilityStatus} = this.props;
		updateModalDialogVisibilityStatus({fullScreenElementVisibility: false});
	}
}

export default connect(
	null,
	bindActions({
		updateModalDialogVisibilityStatus: updateFullScreenElementVisibilityStatus
	})
)(FullScreenWrapper);

FullScreenWrapper.propTypes = {
	visible: PropTypes.bool,
	className: PropTypes.string,
	onClose: PropTypes.func, 
	sourceBoundingRect: PropTypes.exact({
		left: PropTypes.number,
		top: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number
	}),
	targetBoundingRect: PropTypes.exact({
		left: PropTypes.number,
		top: PropTypes.number,
		width: PropTypes.number,
		height: PropTypes.number
	}),
	updateModalDialogVisibilityStatus: PropTypes.func
};
