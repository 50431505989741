import React from 'react';
import PropTypes from 'prop-types';

import {callSafe, memoizeByFirstArg} from '../../commons/utils/FunctionUtils.js';
import {ClickTrackedMenuEntry} from '../../metrics-collector/components/TrackedComponents.js';
import Button from '../../ui/components/Button.js';
import ArrowDropDownIcon from '../../ui/components/icons/ArrowDropDownIcon.js';
import {
	ViewerLayout1x1Icon,
	ViewerLayout1x2Icon,
	ViewerLayout2x1Icon,
	ViewerLayout2x2Icon
} from '../../ui/components/icons/ViewerMatrixLayoutIcons.js';
import Menu from '../../ui/components/Menu.js';
import {ALIGN_CENTER, EXPAND_BOTTOM} from '../../ui/constants/PupupMenuConstants.js';

const LAYOUT_ICONS = {
	'1x1': <ViewerLayout1x1Icon />,
	'1x2': <ViewerLayout1x2Icon />,
	'2x1': <ViewerLayout2x1Icon />,
	'2x2': <ViewerLayout2x2Icon />
};

function toolIdForLayoutName(layoutName) {
	return `Layout${layoutName}`;
}

export default class ViewerMatrixMenu extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.createOnClickHandler = memoizeByFirstArg(index => () => this.onLayoutMenuEntryClicked(index));
	}

	render() {
		const {layoutMode, layoutOptions} = this.props;
		const layoutName = layoutOptions[layoutMode];
		const buttonElement = (
			<Button startIcon={LAYOUT_ICONS[layoutName]} endIcon={<ArrowDropDownIcon />} flat>
				{layoutName}
			</Button>
		);
		return (
			<Menu id='viewer-matrix-menu' buttonElement={buttonElement} align={ALIGN_CENTER}
			      expandDirection={EXPAND_BOTTOM} menuComponent='div'>
				{this.renderMatrixMenuEntries()}
			</Menu>
		);
	}

	renderMatrixMenuEntries() {
		const {layoutOptions, layoutMode} = this.props;
		return layoutOptions.map((layoutName, index) => {
			const onClick = this.createOnClickHandler(index);
			return (
				<ClickTrackedMenuEntry id={`matrix-menu-${layoutName}`} key={layoutName} onClick={onClick}
				                       metricsKey={toolIdForLayoutName(layoutName)} icon={LAYOUT_ICONS[layoutName]}
				                       primaryText={layoutName} selected={index === layoutMode} />
			);
		});
	}

	onLayoutMenuEntryClicked(newMode) {
		const {onLayoutModeChange, layoutMode} = this.props;
		if (newMode !== layoutMode) {
			callSafe(onLayoutModeChange, newMode);
		}
	}
}

ViewerMatrixMenu.propTypes = {
	layoutMode: PropTypes.number,
	layoutOptions: PropTypes.arrayOf(PropTypes.string),
	onLayoutModeChange: PropTypes.func
};
