import React from 'react';
import PropTypes from 'prop-types';

import {FULLSCREEN_TRANSITION_TIME} from '../../commons/constants/FullScreenWrapperConstants.js';

export default class A11yDialog extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.elementRef = null;
		this.boundCaptureRef = this.captureRef.bind(this);
		this.timeoutID = null;
	}

	render() {
		const {className, onMouseDown, children} = this.props;
		return (
			<div ref={this.boundCaptureRef} aria-labelledby='modal-dialog-label' aria-modal='true' tabIndex='-1'
			          role='dialog' className={className} onMouseDown={onMouseDown}>
				{children}
			</div>

		);
	}

	captureRef(refElement) {
		this.elementRef = refElement;
	}

	componentDidMount() {
		const {autoFocus} = this.props;
		if (autoFocus) {
			this.timeoutID = window.setTimeout(() => {
				this.elementRef?.focus();
				this.timeoutID = null;
			}, FULLSCREEN_TRANSITION_TIME);
		}
	}

	componentWillUnmount() {
		if (this.timeoutID !== null) {
			window.clearTimeout(this.timeoutID);
		}
	}
}

A11yDialog.propTypes = {
	autoFocus: PropTypes.bool,
	className: PropTypes.string,
	onMouseDown: PropTypes.func
};

A11yDialog.defaultProps = {
	autoFocus: true
};
