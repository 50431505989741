import {createAction} from '../../../commons/utils/ActionUtils.js';
import {markAsHandled} from '../../../commons/utils/ErrorHandlingUtils.js';
import {
	ACQUIRE_SECOND_FACTOR_TOKEN,
	ATTACH_SESSION,
	BEGIN_SESSION,
	BEGIN_SESSION_TRANSITION,
	END_SESSION,
	FINISH_SESSION_TRANSITION,
	LOGIN,
	LOGOUT,
	RESET_LOGIN_WORKFLOW
} from '../../constants/SessionActionTypes.js';
import sanitizeUsername from '../../utils/sanitizeUsername.js';

export const login = createAction(LOGIN, (username, password) => ({username: sanitizeUsername(username), password}));
export const acquireSecondFactorToken = createAction(ACQUIRE_SECOND_FACTOR_TOKEN, token => ({token}));
export const logout = createAction(LOGOUT);
export const beginSessionTransition = createAction(BEGIN_SESSION_TRANSITION);
export const finishSessionTransition = createAction(FINISH_SESSION_TRANSITION,
	payload => ((payload && payload instanceof Error) ? markAsHandled(payload) : payload)
);
export const beginSession = createAction(BEGIN_SESSION);
export const endSession = createAction(END_SESSION);
export const resetLoginWorkflow = createAction(RESET_LOGIN_WORKFLOW);
export const attachSession = createAction(ATTACH_SESSION);
