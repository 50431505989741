import {offsetPageSyncSaga, SYNC_TOOL_ID as OFFSET_PAGE_SYNC_TOOL} from '../tools/offset-page-synchronization';
import viewerSyncSaga from './viewerSyncSaga.js';

const SYNC_TOOLS_MAP = {
	[OFFSET_PAGE_SYNC_TOOL]: offsetPageSyncSaga
};

export default function* defaultViewerSyncSaga() {
	yield* viewerSyncSaga(SYNC_TOOLS_MAP);
}
