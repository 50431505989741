import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import PrimaryItemText from '../../../material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../../material-design/components/text/SecondaryItemText.js';
import ExpandButton from '../../../ui/components/ExpandButton.js';
import HorizontalLayout from '../../../ui/components/layout/HorizontalLayout.js';
import VerticalLayout from '../../../ui/components/layout/VerticalLayout.js';
import ErrorDetails from './ErrorDetails.js';
import ErrorTranslator from './i18n/ErrorsTranslator.js';
import UnhandledErrorsDialogTranslator from './i18n/UnhandledErrorsDialogTranslator.js';

import '../../../../styles/commons/components/errors/UnhandledErrorListItem.scss';

export default class UnhandledErrorListItem extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundToggleShowDetails = this.toggleShowDetails.bind(this);
		this.state = {
			detailsVisible: false
		};
	}

	render() {
		const {detailsVisible} = this.state;
		const {error} = this.props;
		const {message, name} = error;

		const detailsClasses = classnames({
			details: true,
			collapsed: !detailsVisible
		});

		return (
			<VerticalLayout noShrink className='unhandled-error-list-item'>
				<HorizontalLayout>
					<VerticalLayout>
						<PrimaryItemText>
							<SynFormattedMessage message={`${name}Description`} translator={ErrorTranslator} fallbackTranslation={message} />
						</PrimaryItemText>
						<SecondaryItemText>
							<SynFormattedMessage message={`${name}Name`} translator={ErrorTranslator} fallbackTranslation={name} />
						</SecondaryItemText>
					</VerticalLayout>
					<ExpandButton checked={detailsVisible} onToggle={this.boundToggleShowDetails}>
						<SynFormattedMessage message={detailsVisible ? 'HideDetails' : 'ShowDetails'} translator={UnhandledErrorsDialogTranslator} />
					</ExpandButton>
				</HorizontalLayout>
				<ErrorDetails error={error} className={detailsClasses} />
			</VerticalLayout>
		);
	}

	toggleShowDetails() {
		this.setState(state => ({
			...state,
			detailsVisible: !state.detailsVisible
		}));
	}
}

UnhandledErrorListItem.propTypes = {
	error: PropTypes.instanceOf(Error)
};
