import BrickBase from '../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../webview/bricks/brickTools.js';
import FilesCollection, {
	createFileEntry,
	isFileEntry,
	renameFileEntry
} from '../../webview/commons/utils/FilesCollection.js';
import {getFileName} from '../../webview/commons/utils/StringUtils.js';

const PREPARED = 'prepared';
const UPLOADING = 'uploading';
const SUCCEEDED = 'succeeded';
const TRASHED = 'trashed';
const FAILED = 'failed';
const TRASHED_PATH = [TRASHED];
const PREPARED_PATH = [PREPARED];
const UPLOADING_PATH = [UPLOADING];
const FAILED_PATH = [FAILED];

export default class UploadFilesCollection extends BrickBase {
	constructor() {
		super();
		this.filesCollection = new FilesCollection();
		this.subscribeTo(this.filesCollection, this.onFilesCollectionChange);
	}

	onFilesCollectionChange(filesCollection) {
		this.updateBrickState((oldState = {}) => ({
			...oldState,
			uploadingFilesCount: filesCollection.getFilesCount(UPLOADING_PATH),
			uploadedFilesCount: filesCollection.getFilesCount([SUCCEEDED]),
			failedFilesCount: filesCollection.getFilesCount(FAILED_PATH),
			totalFilesCount: filesCollection.getFilesCount(),
			totalSize: filesCollection.getTotalSize(),
			trashedFilesCount: filesCollection.getFilesCount(TRASHED_PATH),
			trashedFilesSize: filesCollection.getTotalSize(TRASHED_PATH),
			trashedFiles: filesCollection.listEntries(TRASHED_PATH),
			hasPreparedFiles: filesCollection.hasEntriesIn(PREPARED_PATH),
			preparedFiles: filesCollection.listEntries(PREPARED_PATH)
		}));
	}

	setUploading(name, containerName) {
		this.#moveFile(name, PREPARED, UPLOADING, containerName);
	}

	getUploadingFilesCount() {
		return this.getBrickState().uploadingFilesCount;
	}

	setUploaded(name, containerName) {
		this.#moveFile(name, UPLOADING, SUCCEEDED, containerName);
	}

	getUploadedFilesCount() {
		return this.getBrickState().uploadedFilesCount;
	}

	setFailed(name, containerName) {
		this.#moveFile(name, UPLOADING, FAILED, containerName);
	}

	resetWithFailed() {
		this.getFailedFiles().forEach(name => this.#moveFile(name, FAILED, PREPARED));
		this.filesCollection.removeAll([SUCCEEDED]);
		this.#clearTrashedFiles();
	}

	getFailedFilesCount() {
		return this.getBrickState().failedFilesCount;
	}

	#moveFile(id, from, to, containerName) {
		this.updateBrickState(() => {
			const srcPath = toPath(from, containerName);
			const destPath = toPath(to, containerName);
			if (containerName) {
				const containerValue = this.filesCollection.getValue(containerName, from);
				if (containerValue) {
					this.filesCollection.setEntryValue(containerName, containerValue, to);
				}
			}
			const value = this.filesCollection.getValue(id, srcPath);
			if (isFileEntry(value)) {
				this.filesCollection.removeEntry(id, srcPath);
				this.filesCollection.addFile(value, destPath);
			} else if (this.filesCollection.hasEntry(id, srcPath)) {
				this.filesCollection.setEntryValue(id, value, destPath);
				this.filesCollection.listEntries(toPath(from, id)).forEach(
					entry => this.#moveFile(entry, from, to, id)
				);
			}
			if (this.filesCollection.getFilesCount(srcPath) === 0) {
				this.filesCollection.removeEntry(containerName, from);
			}
			return this.onFilesCollectionChange(this.filesCollection);
		});
	}

	addFileList(fileList, containerName) {
		if (fileList.length > 0) {
			this.updateBrickState(oldState => {
				fileList.forEach(entry => this.addFile(entry, containerName));
				return oldState;
			});
		}
	}

	addFile(file, containerName) {
		let fileEntry = createFileEntry(file);
		const {name} = fileEntry;
		const uniqueName = this.#findUniqueName(name, containerName);
		if (uniqueName !== name) {
			fileEntry = renameFileEntry(fileEntry, uniqueName);
		}
		this.filesCollection.addFile(fileEntry, toPath(PREPARED, containerName));
	}

	setPreparedContainerValue(containerName, value) {
		this.filesCollection.setEntryValue(containerName, value, PREPARED_PATH);
	}

	getPreparedContainerValue(containerName) {
		return this.filesCollection.getValue(containerName, PREPARED_PATH);
	}

	#findUniqueName(testName, containerName) {
		const fileName = getFileName(testName);
		const ext = testName.slice(fileName.length);
		let uniqueName = testName;
		let counter = 0;
		while (this.containsFile(uniqueName, containerName)) {
			uniqueName = `${fileName} (${++counter})${ext}`;
		}
		return uniqueName;
	}

	containsFile(name, containerName) {
		const existingFile = this.filesCollection.getValue(name, toPath(PREPARED, containerName)) ||
			this.filesCollection.getValue(name, toPath(TRASHED, containerName)) ||
			this.filesCollection.getValue(name, toPath(UPLOADING, containerName)) ||
			this.filesCollection.getValue(name, toPath(SUCCEEDED, containerName)) ||
			this.filesCollection.getValue(name, toPath(FAILED, containerName));
		return Boolean(existingFile);
	}

	removePreparedFile(id) {
		this.#moveFile(id, PREPARED, TRASHED);
	}

	restoreTrashedFiles() {
		this.#getTrashedFiles().forEach(name => this.#moveFile(name, TRASHED, PREPARED));
	}

	#clearTrashedFiles() {
		this.filesCollection.removeAll(TRASHED_PATH);
	}

	clearPreparedFiles() {
		this.getPreparedFiles().forEach(name => this.#moveFile(name, PREPARED, TRASHED));
	}

	clear() {
		this.filesCollection.removeAll();
	}

	getTrashedFilesCount() {
		return this.getBrickState().trashedFilesCount;
	}

	#getTrashedFilesSize() {
		return this.getBrickState().trashedFilesSize;
	}

	#getTrashedFiles() {
		return this.getBrickState().trashedFiles;
	}

	getPreparedFile(name, containerName) {
		return this.filesCollection.getValue(name, toPath(PREPARED, containerName));
	}

	getPreparedFiles(containerName) {
		return this.filesCollection.listEntries(toPath(PREPARED, containerName));
	}

	getFailedFiles() {
		return this.filesCollection.listEntries(FAILED_PATH);
	}

	getPreparedFilesCount() {
		return this.getBrickState().preparedFiles.size;
	}

	getPreparedFileSize(name) {
		return this.filesCollection.getTotalSize(name, PREPARED_PATH);
	}

	hasPreparedFiles() {
		return this.getBrickState().hasPreparedFiles;
	}

	getTotalFilesCount() {
		return this.getBrickState().totalFilesCount - this.getTrashedFilesCount();
	}

	getTotalSize() {
		return this.getBrickState().totalSize - this.#getTrashedFilesSize();
	}
}
declareBrick(UploadFilesCollection);

function toPath(...parts) {
	return parts.reduce((path, part) => {
		let nextPath = path;
		if (part) {
			if (nextPath) {
				nextPath.push(part);
			} else {
				nextPath = [part];
			}
		}
		return nextPath;
	}, undefined);
}
