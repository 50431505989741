import {createStructuredSelector} from 'reselect';

import connectWithInstanceSelector from '../../../commons/flux/connectWithInstanceSelector.js';
import PatientDetailsLoadError from '../../components/PatientDetailsLoadError.js';
import {createSelectErrorForItemType} from '../selectors/PatientDetailsSelectors.js';

export default connectWithInstanceSelector(
	props => createStructuredSelector({
		loadingError: createSelectErrorForItemType(props.itemType)
	})
)(PatientDetailsLoadError);
