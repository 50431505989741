import React from 'react';
import PropTypes from 'prop-types';

import LandingPage from '../../commons/components/webview/LandingPage.js';
import {ENFORCE_TWO_FACTOR_LOGIN} from '../../commons/constants/SynSettingsConstants.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {PASSWORD_TOO_WEAK} from '../constants/PasswordStateReducerStates.js';
import NewPasswordControllerContainer from '../flux/containers/NewPasswordControllerContainer.js';
import ChangeTooWeakPasswordPageTranslator from '../i18n/ChangeTooWeakPasswordPageTranslator.js';
import ChangePasswordFormAccessors, {CHANGE_PASSWORD_FORM_SCHEMA} from './accessors/ChangePasswordFormAccessors.js';

const PASSWORD_TOO_WEAK_PROPS = {
	title: <SynFormattedMessage translator={ChangeTooWeakPasswordPageTranslator} message='Title' />,
	translator: ChangeTooWeakPasswordPageTranslator
};

function getStateSpecificProps(passwordState) {
	let props;
	switch (passwordState) {
		case PASSWORD_TOO_WEAK:
			props = PASSWORD_TOO_WEAK_PROPS;
			break;
		default:
			throw Error('Unsupported password state');
	}
	return props;
}

export const NEW_PASSWORD_FORM_FIELDS = Object.keys(CHANGE_PASSWORD_FORM_SCHEMA.fields);

export default class ChangeInvalidPasswordPage extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnSetNewPassword = this.onSetNewPassword.bind(this);
		this.boundOnFinish = this.onFinish.bind(this);
	}

	render() {
		const {username, passwordState} = this.props;
		const {title, translator} = getStateSpecificProps(passwordState);
		return (
			<LandingPage title={title}>
				<NewPasswordControllerContainer username={username} setNewPassword={this.boundOnSetNewPassword}
				                                translator={translator} onFinish={this.boundOnFinish}
				                                passwordFormAccessors={ChangePasswordFormAccessors}
				                                passwordFormFields={NEW_PASSWORD_FORM_FIELDS}
				                                secondFactorAssumed={ENFORCE_TWO_FACTOR_LOGIN} hideCancelLink />
			</LandingPage>
		);
	}

	onFinish() {
		const {attachSession} = this.props;
		attachSession();
	}

	onSetNewPassword(formData) {
		const {changePassword, username} = this.props;
		const {oldPassword, newPassword, newPasswordConfirmation, verificationCode} = formData;
		changePassword(username, oldPassword, newPassword, newPasswordConfirmation, verificationCode);
	}
}

ChangeInvalidPasswordPage.propTypes = {
	username: PropTypes.string,
	passwordState: PropTypes.oneOf([PASSWORD_TOO_WEAK]),
	attachSession: PropTypes.func,
	changePassword: PropTypes.func
};
