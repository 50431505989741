import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {getHocDisplayName} from '../../utils/ReactUtils.js';
import FormContext from './FormContext.js';

export default function wrapInFormContext(ChildComponent) {
	function FormDescriptionContext(props) {
		const {formDescription: formDescriptionFromProps} = props;
		const {formDescription: formDescriptionFromContext} = useContext(FormContext) || {};
		const formDescription = formDescriptionFromProps || formDescriptionFromContext;
		return <ChildComponent {...props} formDescription={formDescription} />;
	}
	FormDescriptionContext.propTypes = {
		...ChildComponent.propTypes,
		formDescription: PropTypes.object
	};
	FormDescriptionContext.defaultProps = {
		...ChildComponent.defaultProps
	};
	FormDescriptionContext.displayName = getHocDisplayName(ChildComponent, 'wrapInFormContext');
	return FormDescriptionContext;
}
