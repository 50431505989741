import React from 'react';

import UploadStepperInfo from '../../webview/commons/components/upload/UploadStepperInfo.js';
import useTranslation from '../../webview/i18n/hooks/useTranslation.js';
import UploadController from '../bricks/UploadController.js';

export default function WebUploadStepperInfo() {
	const steps = [
		useTranslation('StepLabelChoseFiles'),
		useTranslation('StepLabelStartUpload'),
		useTranslation('StepLabelConfirm')
	];
	return <UploadStepperInfo stepMap={steps} getStepFromState={getActiveStep} />;
}

function getActiveStep(uploadControllerState) {
	switch (uploadControllerState) {
		case UploadController.State.PREPARING:
		case UploadController.State.READY:
			return 0;
		case UploadController.State.UPLOADING:
			return 1;
		case UploadController.State.FINISHED:
			return 2;
		default:
			return -1;
	}
}
