import React, {useCallback} from 'react';

import {getHocDisplayName} from '../../../commons/utils/ReactUtils.js';
import useTranslation from '../../../i18n/hooks/useTranslation.js';
import GeneralMessagesTranslator from '../../../i18n/translators/GeneralTranslator.js';
import IconButton from '../IconButton.js';
import ArrowForwardIcon from '../icons/ArrowForwardIcon.js';
import HorizontalLayout from '../layout/HorizontalLayout.js';
import DefaultAutoCompleteTextField from './DefaultAutoCompleteTextField.js';

export default function withConfirmButton(Component) {
	function AutoCompleteTextFieldWithConfirmButton(props) {
		const {noMargin, onChange, params: {inputProps: {value: inputValue}}} = props;
		const handleButtonOnClick = useCallback(e => {
			onChange(e, inputValue);
		}, [onChange, inputValue]);
		const buttonTitle = useTranslation('FinishPhrase', {}, GeneralMessagesTranslator);
		return (
			<HorizontalLayout sx={{my: noMargin ? 1 : 0}}>
				<Component {...props} />
				<IconButton title={buttonTitle} icon={<ArrowForwardIcon />} onClick={handleButtonOnClick} />
			</HorizontalLayout>
		);
	}
	AutoCompleteTextFieldWithConfirmButton.propTypes = DefaultAutoCompleteTextField.propTypes;
	AutoCompleteTextFieldWithConfirmButton.displayName = getHocDisplayName(Component, 'withConfirmButton');
	return AutoCompleteTextFieldWithConfirmButton;
}
