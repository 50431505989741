import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import NewPasswordController from '../../components/NewPasswordController.js';
import {clearError} from '../actions/PasswordActions.js';
import {selectPasswordOperationState} from '../selectors/NewPasswordSelectors.js';
import {selectError, selectIsBusy} from '../selectors/PasswordStateSelectors.js';

export default connect(
	createStructuredSelector({
		isBusy: selectIsBusy,
		passwordError: selectError,
		passwordOperationState: selectPasswordOperationState
	}),
	bindActions({
		resetPasswordState: clearError
	})
)(NewPasswordController);
