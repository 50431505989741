import React from 'react';
import PropTypes from 'prop-types';

import DomEventsManager from '../../events/DomEventsManager.js';
import {preventEventDefault, stopEventPropagation} from '../utils/DOMEventUtils.js';
import {mapProperties} from '../utils/ObjectUtils';

/**
 * This component stops propagation or prevents the default behaviour of all events that pass through it.
 * What events should be stopped or prevented can be specified in the properties stopPropagation or preventDefault
 * respectively.
 * The events need to be specified as an array of React-Event names
 * (e.g: ['onMouseDown', 'onMouseUp', 'onPointerDownCapture']).
 *
 * The component also supports stopping the events on the DOM-element it self.
 * Specify the list of events that should be stopped on the underlying DOM-element via the property handleNative.
 */
export default class EventBarrier extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.nodeRef = React.createRef();
		this.domEventsManager = new DomEventsManager();
		this.state = {
			props: null,
			component: null,
			eventInfo: null
		};
	}

	render() {
		const {component: Component, props} = this.state;
		return <Component ref={this.nodeRef} {...props} />;
	}

	componentDidMount() {
		this.updateNativeEventHandlers();
	}

	componentDidUpdate() {
		this.updateNativeEventHandlers();
	}

	updateNativeEventHandlers() {
		this.domEventsManager.removeAllListeners();
		if (this.nodeRef.current) {
			const {eventInfo, nativeHandlers} = this.state;
			Object.keys(nativeHandlers).forEach(eventName => {
				const {capture} = eventInfo[eventName];
				const handler = nativeHandlers[eventName];
				const {nativeName} = eventInfo[eventName];
				this.domEventsManager.addEventListener(this.nodeRef.current, nativeName, handler, capture);
			});
		}
	}

	componentWillUnmount() {
		this.domEventsManager.removeAllListeners();
	}

	static getDerivedStateFromProps(props) {
		const {stopPropagation, preventDefault, handleNative, ...remainingProps} = props;
		const eventInfo = composeEventInfo(stopPropagation, preventDefault);
		const barrierHandlers = createEventProps(eventInfo);
		const nativeHandlers = handleNative.reduce((accumulatedNativeHandlers, name) => {
			if (barrierHandlers[name]) {
				accumulatedNativeHandlers[name] = barrierHandlers[name];
			}
			return accumulatedNativeHandlers;
		}, {});
		const eventHandlers = mergeEventHandlers(remainingProps, barrierHandlers);
		const finalProps = {...remainingProps, ...eventHandlers};
		delete finalProps.component;
		return {
			eventInfo,
			nativeHandlers,
			props: finalProps,
			component: props.component
		};
	}
}

EventBarrier.propTypes = {
	component: PropTypes.elementType,
	stopPropagation: PropTypes.arrayOf(PropTypes.string),
	preventDefault: PropTypes.arrayOf(PropTypes.string),
	handleNative: PropTypes.arrayOf(PropTypes.string)
};

EventBarrier.defaultProps = {
	component: 'div',
	stopPropagation: [],
	preventDefault: [],
	handleNative: []
};

function extractBasicInfo(eventName) {
	let nativeName = eventName.substring(2).toLowerCase();
	const isCapture = nativeName.endsWith('capture');
	if (isCapture) {
		nativeName = nativeName.substring(0, nativeName.length - 'capture'.length);
	}
	return {
		capture: isCapture,
		nativeName
	};
}

function composeEventInfo(stopEvents, preventEvents) {
	let eventInfo = stopEvents.reduce((info, eventName) => {
		info[eventName] = Object.assign(extractBasicInfo(eventName), {stop: true});
		return info;
	}, {});
	eventInfo = preventEvents.reduce((info, eventName) => {
		const currentInfo = info[eventName] || extractBasicInfo(eventName);
		info[eventName] = {...currentInfo, prevent: true};
		return info;
	}, eventInfo);
	return eventInfo;
}

function createEventProps(eventInfo) {
	return Object.keys(eventInfo).reduce((props, eventName) => {
		props[eventName] = createEventHandler(eventInfo[eventName]);
		return props;
	}, {});
}

function createEventHandler(eventInfo) {
	const handlers = [];
	const {stop, prevent} = eventInfo;
	if (stop) {
		handlers.push(stopEventPropagation);
	}
	if (prevent) {
		handlers.push(preventEventDefault);
	}
	return e => handlers.forEach(handler => handler(e));
}

function mergeEventHandlers(originalProps, eventProps) {
	return mapProperties(eventProps, (handler, name) => {
		let finalHandler = handler;
		if (originalProps[name]) {
			finalHandler = e => {
				handler(e);
				originalProps[name](e);
			};
		}
		return finalHandler;
	});
}
