import React from 'react';
import PropTypes from 'prop-types';

import FullScreenContainer from '../../../commons/components/FullScreenContainer.js';
import EditTextDialog from '../../../commons/components/webview/EditTextDialog.js';
import CenteredEditIcon from '../../../commons/components/webview/icons/CenteredEditIcon.js';
import {callSafe} from '../../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import AnnotationTranslator from '../../../i18n/translators/AnnotationsTranslator.js';
import ViewerContext from '../ViewerContext.js';
import {LABEL_TEXT_PROPERTY_NAME} from './AnnotationConstants.js';
import TappableIcon from './TappableIcon.js';

const TEXT_FIELD_FOCUS_DELAY_MS = 50;

export default class EditLabelIcon extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnTextChanged = this.onTextChanged.bind(this);
		this.boundMountTextField = this.mountTextField.bind(this);
	}

	render() {
		const {locale, [LABEL_TEXT_PROPERTY_NAME]: labelText, isDialogVisible, showDialog, hideDialog} = this.props;
		const remainingProps = cloneWithoutProperties({...this.props}, ...Object.keys(EditLabelIcon.propTypes));
		const dialogTitle = AnnotationTranslator.getFormattedMessage('EditAnnotationTextDialogTitle', locale);
		const inputFieldLabel = AnnotationTranslator.getFormattedMessage('AnnotationTextFieldLabel', locale);
		return (
			<TappableIcon onTap={showDialog} {...remainingProps}>
				<CenteredEditIcon />
				<foreignObject>
					<FullScreenContainer visible={isDialogVisible}>
						<EditTextDialog name='text_annotation_field' label={inputFieldLabel} title={dialogTitle} text={labelText} locale={locale}
											 onCancel={hideDialog} onTextChanged={this.boundOnTextChanged}
											 inputRef={this.boundMountTextField} />
					</FullScreenContainer>
				</foreignObject>
			</TappableIcon>
		);
	}

	onTextChanged(newLabelText) {
		const {setAnnotationLabel} = this.props;
		callSafe(setAnnotationLabel, newLabelText);
	}

	mountTextField(element) {
		if (element) {
			const {[LABEL_TEXT_PROPERTY_NAME]: labelTextProperty} = this.props;
			const labelTextLength = labelTextProperty.length;
			setTimeout(
				() => {
					element.focus();
					element.setSelectionRange(0, labelTextLength);
				}, TEXT_FIELD_FOCUS_DELAY_MS
			);
		}
	}
}

EditLabelIcon.contextType = ViewerContext;

EditLabelIcon.defaultProps = {
	locale: 'de',
	setAnnotationLabel: undefined,
	[LABEL_TEXT_PROPERTY_NAME]: undefined
};

EditLabelIcon.propTypes = {
	locale: PropTypes.string,
	setAnnotationLabel: PropTypes.func,
	isDialogVisible: PropTypes.bool,
	showDialog: PropTypes.func,
	hideDialog: PropTypes.func,
	[LABEL_TEXT_PROPERTY_NAME]: PropTypes.string
};
