import React from 'react';

import {WEB_APP_PRODUCTNAME} from '../../constants/EnvironmentConstants.js';

export default function ProductInfo() {
	return (
		<span>
			{WEB_APP_PRODUCTNAME}
			{' '}
			{window.SynSettings.version}
		</span>
	);
}
