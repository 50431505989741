import {debugLog} from '../commons/utils/DebugLog.js';
import autoBind from '../commons/utils/ObjectUtils/autoBind.js';
import Observable from '../commons/utils/Observable.js';

export default class BrickBase extends Observable {
	#unsubscribeCallbacks = [];

	constructor(initialState = {}) {
		super(initialState);
		autoBind(this);
	}

	updateBrickState(updater, ...updaterArgs) {
		super.updateValue(updater, ...updaterArgs);
	}

	getBrickState() {
		return super.getValue();
	}

	shutdown() {
		debugLog(`Shutting down ${Object.getPrototypeOf(this).constructor.name}`);
		this.#unsubscribeFromAll();
	}

	subscribeTo(observable, callback) {
		const unsubscribe = observable.subscribe(callback);
		this.#addUnsubscribe(unsubscribe);
		callback(observable);
	}
	
	#addUnsubscribe(unsubscribeFunction) {
		this.#unsubscribeCallbacks.push(unsubscribeFunction);
	}

	#unsubscribeFromAll() {
		this.#unsubscribeCallbacks.forEach(callback => callback());
		this.#unsubscribeCallbacks = [];
	}
}
