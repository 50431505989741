import React from 'react';
import PropTypes from 'prop-types';

import PatientInfo from '../../../commons/components/webview/PatientInfo.js';
import {USE_LOSSLESS_IMAGE_COMPRESSION} from '../../../commons/constants/SynSettingsConstants.js';
import ShortDateTime from '../../../i18n/components/ShortDateTime.js';
import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../../i18n/translators/ViewerTranslator.js';
import {checkEmpty, default as ViewerDetailsDialog} from '../ViewerDetailsDialog.js';
import ViewerDetailsPropertyItem from '../ViewerDetailsPropertyItem.js';

class DicomImageDetailsDialog extends React.PureComponent {
	render() {
		const {onClose, imageMetadata, show} = this.props;
		return (
			<ViewerDetailsDialog onClose={onClose} show={show}>
				{imageMetadata && (
					<React.Fragment>
						<ViewerDetailsPropertyItem name='Patient'>
							<PatientInfo patientInfo={imageMetadata.PatientInfo}
													 birthDate={imageMetadata.PatientBirthDate} />
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='DocumentDescription'>
							{checkEmpty(imageMetadata.DocumentDescription)}
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='DocumentCreationDate'>
							<ShortDateTime date={imageMetadata.DocumentCreationDate} />
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='SeriesDescription'>
							{checkEmpty(imageMetadata.SeriesDescription)}
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='Modality'>
							{checkEmpty(imageMetadata.Modality)}
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='InstitutionName'>
							{checkEmpty(imageMetadata.InstitutionName)}
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='ImagePosition'>
							{checkEmpty(imageMetadata.SeriesInfo)}
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='Resolution'>
							{checkEmpty(imageMetadata.Resolution)}
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='ImageComments'>
							{checkEmpty(imageMetadata.ImageComments)}
						</ViewerDetailsPropertyItem>
						<ViewerDetailsPropertyItem name='FileType'>
							{'DICOM, '}
							<SynFormattedMessage message={USE_LOSSLESS_IMAGE_COMPRESSION ? 'ConvertedToPNG' : 'ConvertedToJPG'} translator={ViewerTranslator} />
						</ViewerDetailsPropertyItem>
					</React.Fragment>
				)}
			</ViewerDetailsDialog>);
	}
}

DicomImageDetailsDialog.propTypes = {
	imageMetadata: PropTypes.object,
	onClose: PropTypes.func,
	show: PropTypes.bool
};

export default DicomImageDetailsDialog;
