import {vec2} from 'gl-matrix';

/**
 * Creates a rectangle which is parallel to coordinate axes. Be aware that this does not respect rotated rectangles!!
 *
 * @param topLeft
 * @param bottomRight
 * @returns {{topLeft: *, bottomRight: *}}
 */
export function createRectangle(topLeft, bottomRight) {
	return {
		topLeft,
		bottomRight,
		height: bottomRight[1] - topLeft[1],
		width: bottomRight[0] - topLeft[0]
	};
}

/**
 * calculates the intersection of the rectangle with a line. Returns two points lying on the intersection if one exists.
 * These points are lying on the opposite sides of the rectangle and are ensured to ly on the edge or outside the
 * rectangle but never inside the rectangle.
 *
 * @param rectangle the rectangle to search intersection with
 * @param line the line to search an intersection with.
 * @returns {{point1: *, point2: *}} two opposite points lying on the intersection or null if no intersection
 * with the rectangle exists.
 */
export function getIntersection2d(rectangle, line) {
	const {topLeft: tl, bottomRight: br} = rectangle;
	const {direction, origin} = line;

	const normal = [-direction[1], direction[0]];
	const d = vec2.dot(normal, origin);
	const {width, height} = rectangle;

	const freeVariable = Math.abs(width * direction[1]) < Math.abs(height * direction[0]) ? 1 : 0;
	const fixedVarible = 1 - freeVariable;

	const point1 = new Float64Array(2);
	const point2 = new Float64Array(2);

	point1[fixedVarible] = tl[fixedVarible];
	point1[freeVariable] = (d - normal[fixedVarible] * tl[fixedVarible]) / normal[freeVariable];

	point2[fixedVarible] = br[fixedVarible];
	point2[freeVariable] = (d - normal[fixedVarible] * br[fixedVarible]) / normal[freeVariable];

	const hasIntersection = (point1[freeVariable] >= tl[freeVariable] && point1[freeVariable] <= br[freeVariable]) ||
		(point2[freeVariable] >= tl[freeVariable] && point2[freeVariable] <= br[freeVariable]);

	return hasIntersection ? {point1, point2} : null;
}

export function containsPoint2d(rectangle, point) {
	return point[0] <= rectangle.bottomRight[0] &&
		point[0] >= rectangle.topLeft[0] &&
		point[1] <= rectangle.bottomRight[1] &&
		point[1] >= rectangle.topLeft[1];
}

/*
 * Returns whether or not the two passed rectangles overlap in any way
 */
export function overlap2d(rectA, rectB) {
	return rectA.topLeft[0] <= rectB.bottomRight[0] &&
        rectA.bottomRight[0] >= rectB.topLeft[0] &&
        rectA.topLeft[1] <= rectB.bottomRight[1] &&
        rectA.bottomRight[1] >= rectB.topLeft[1];
}

export function getCenter(rect) {
	return [
		rect.topLeft[0] + rect.width / 2,
		rect.topLeft[1] + rect.height / 2
	];
}

export function moveRect(rect, dx, dy) {
	const newRect = cloneRect(rect);
	moveRectInplace(newRect, dx, dy);
	return newRect;
}

export function moveRectInplace(rect, dx, dy) {
	rect.topLeft[0] += dx;
	rect.topLeft[1] += dy;
	rect.bottomRight[0] += dx;
	rect.bottomRight[1] += dy;
}

export function confineRect(rect, inRect) {
	const newRect = cloneRect(rect);
	confineRectInplace(newRect, inRect);
	return newRect;
}

export function confineRectInplace(rect, inRect) {
	let dx = 0;
	let dy = 0;
	if (rect.width <= inRect.width) {
		if (rect.topLeft[0] < inRect.topLeft[0]) {
			dx = inRect.topLeft[0] - rect.topLeft[0];
		} else if (rect.bottomRight[0] > inRect.bottomRight[0]) {
			dx = inRect.bottomRight[0] - rect.bottomRight[0];
		}
	}
	if (rect.height <= inRect.height) {
		if (rect.topLeft[1] < inRect.topLeft[1]) {
			dy = inRect.topLeft[1] - rect.topLeft[1];
		} else if (rect.bottomRight[1] > inRect.bottomRight[1]) {
			dy = inRect.bottomRight[1] - rect.bottomRight[1];
		}
	}
	return moveRectInplace(rect, dx, dy);
}

export function cloneRect(rect) {
	return createRectangle(
		[rect.topLeft[0], rect.topLeft[1]],
		[rect.bottomRight[0], rect.bottomRight[1]]
	);
}

export function intersectRects(rectA, rectB) {
	return createRectangle(
		[Math.max(rectA.topLeft[0], rectB.topLeft[0]), Math.max(rectA.topLeft[1], rectB.topLeft[1])],
		[Math.min(rectA.bottomRight[0], rectB.bottomRight[0]), Math.min(rectA.bottomRight[1], rectB.bottomRight[1])]
	);
}

export function calculateRectArea(rect) {
	return (rect.bottomRight[0] - rect.topLeft[0]) * (rect.bottomRight[1] - rect.topLeft[1]);
}

export function distanceToPoint2d(rect, point) {
	return [
		Math.max(point[0] - rect.bottomRight[0], rect.topLeft[0] - point[0]),
		Math.max(point[1] - rect.bottomRight[1], rect.topLeft[1] - point[1])
	];
}

export function resizeRect(rect, topDx, topDy, bottomDx, bottomDy) {
	const tl = new Float64Array([rect.topLeft[0] + topDx, rect.topLeft[1] + topDy]);
	const br = new Float64Array([rect.bottomRight[0] + bottomDx, rect.bottomRight[1] + bottomDy]);
	return createRectangle(tl, br);
}

export function resizeRectSymetric(rect, horizontalDelta, verticalDelta) {
	return resizeRect(rect, horizontalDelta, verticalDelta, -horizontalDelta, -verticalDelta);
}

function rectPoints(rect) {
	return [
		[rect.topLeft[0], rect.topLeft[1]],
		[rect.bottomRight[0], rect.bottomRight[1]],
		[rect.topLeft[0], rect.bottomRight[1]],
		[rect.bottomRight[0], rect.topLeft[1]]
	];
}

export function rectInRect(rectA, rectB) {
	return rectPoints(rectA).every(point => containsPoint2d(rectB, point));
}
