import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {useAction} from '../../commons/flux/ReactHooks';
import ContentRow from '../../material-design/components/layout/ContentRow';
import IconButton from '../../ui/components/IconButton';
import SearchIcon from '../../ui/components/icons/SearchIcon';
import HorizontalLayout from '../../ui/components/layout/HorizontalLayout';
import SuggestComboBox from '../../ui/components/SuggestComboBox';
import {setDisplayFormat} from '../flux/PatientDetailsActions';
import {selectDisplayFormat} from '../flux/selectors/PatientDetailsSelectors';

export default function PatientFileFormatSelect(props) {
	const {patientFileOptions, activateFilter} = props;
	const onChangeHandler = useAction(item => setDisplayFormat(item && item.configuration));
	const selectedFormatFromStore = useSelector(selectDisplayFormat);
	const value = patientFileOptions.find(format => format.configuration === selectedFormatFromStore);

	return (
		<HorizontalLayout element={ContentRow} noGrow className='patient-details--patientfile' align='center'>
			<IconButton icon={<SearchIcon />} onClick={activateFilter} sx={{mr: 1}} size='small'
							className='patient-details--patientfile-filter-button' />
			<SuggestComboBox options={patientFileOptions} getOptionLabel={getPatientFileFormatLabel}
								  getOptionSelected={formatMatcher} onChange={onChangeHandler} value={value} 
								  noMargin small disableClearable name='patientFileFormatSelectField' />
		</HorizontalLayout>
	);
}

function getPatientFileFormatLabel(option) {
	if (option.displayName === undefined ||
		option.displayName === '') {
		if (option.configuration === undefined) {
			return '';
		}
		return option.configuration;
	}
	return option.displayName;
}

function formatMatcher(first, second) {
	return first === second || first?.displayName === second?.displayName;
}

PatientFileFormatSelect.propTypes = {
	patientFileOptions: PropTypes.arrayOf(PropTypes.shape({
		configuration: PropTypes.string,
		displayName: PropTypes.string
	})),
	activateFilter: PropTypes.func
};
