import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import LocalizationContext from '../../../i18n/components/LocalizationContext.js';
import {getLanguageAttribute} from '../../../i18n/i18nUtils.js';
import SynIFrameContainer from '../../containers/SynIFrameContainer.js';
import {useMemoFactory} from '../../utils/customHooks/index.js';

const BASE_ATTRIBUTES = {href: `//${document.location.host}/templates/`, target: '_top'};

export default function TemplateIFrame(props) {
	const {templateName, className} = props;
	const locale = useContext(LocalizationContext);
	const sourceURL = useMemoFactory(getSourceURL, templateName, locale);
	return <SynIFrameContainer className={className} src={sourceURL} base={BASE_ATTRIBUTES} />;
}

function getSourceURL(templateName, locale) {
	return `/templates/${templateName}_${getLanguageAttribute(locale)}.html`;
}

TemplateIFrame.propTypes = {
	templateName: PropTypes.string.isRequired,
	className: PropTypes.string
};
