import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import {callSafe} from '../utils/FunctionUtils.js';

export default function OnUnmount(props) {
	const {call} = props;
	const currentCall = useRef(call);
	currentCall.current = call;

	useEffect(() => () => {
		callSafe(currentCall.current);
	}, []);

	return null;
}

OnUnmount.propTypes = {
	call: PropTypes.func
};
