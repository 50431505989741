import React from 'react';
import {vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import PdfViewConstants from '../../../../constants/PdfViewConstants.json';
import VariableSizeList from '../../../commons/components/data/itemview/VariableSizeList.js';
import ScrollView from '../../../commons/components/ScrollView.js';
import {memoizeLast} from '../../../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../../../commons/utils/ObjectUtils';
import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import {binarySearch} from '../../../commons/utils/SeqUtils.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import withViewerLayoutProps from '../../flux/containers/withViewerLayoutProps.js';
import {getPageSize} from '../../utils/PDFUtils.js';
import {getToolCursorClassName} from '../../utils/ViewerUtils.js';
import ImageViewerGesturesRecognizer from '../ImageViewerGesturesRecognizer.js';
import Page from './Page.js';

import '../../../../styles/viewer/components/PdfView.scss';
import '../../../../styles/viewer/components/ToolCursorStyles.scss';

const PAGE_PADDING = PdfViewConstants['pdf-page-padding'];
const SCROLL_BAR_SPACE = 20; // Reserved space for vertical scrollbar.
const TOTAL_PAGE_PADDING = 2 * PAGE_PADDING;
const MAX_ZOOM = 3.0;
const ZOOM_FACTOR_BASE_DIFF = 0.1;
const ZOOM_SPEED = 0.2;
const OVERSCAN_ROWS_MIN = 5;
const MIN_ZOOM_PAGES = 5;

class PdfView extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundOnToolChanged = this.onToolChanged.bind(this);
		this.boundOnViewportChange = this.onViewportChange.bind(this);

		this.boundOnZoom = this.onZoom.bind(this);
		this.boundOnPan = this.onPan.bind(this);
		this.boundOnPinchZoom = this.onPinchZoom.bind(this);

		this.boundCreateRenderRowFor = memoizeLast(this.createRenderRowFor.bind(this));
		this.boundCalcRenderedCellMetrics = memoizeLast(this.calcRenderedCellMetrics.bind(this));
		this.boundCalcScaledDocumentMetrics = memoizeLast(this.calcScaledDocumentMetrics.bind(this));
		this.boundCalcMinZoom = memoizeLast(this.calcMinZoom.bind(this));
		this.boundCalcMaxZoom = memoizeLast(this.calcMaxZoom.bind(this));
		this.calcPageFitScale = memoizeLast(PdfView.calcPageFitScale);
		this.calcDocumentMetrics = memoizeLast(PdfView.calcDocumentMetrics);

		this.state = {
			tool: '',
			zoom: 1.0,
			zoomFocusLocation: null,
			viewport: {left: 0, top: 0, width: 0, height: 0}
		};
	}

	render() {
		const {width, height, pdfDocument, pages, devicePixelRatio, onTapLeft, onTapRight, forwardRef} = this.props;
		const {tool} = this.state;
		const canRenderGrid = Boolean(pdfDocument) && Boolean(pages) && Boolean(width) && Boolean(height);
		let grid = false;
		if (canRenderGrid) {
			const {viewport, zoom} = this.state;
			const {left, top} = viewport;

			const overscanRows = tool === 'pinch/zoom' ? 0 : Math.min(OVERSCAN_ROWS_MIN, Math.max(1, Math.ceil(1.0 / zoom)));
			const {renderedCellMetrics} = this.getRenderedCellMetrics();
			const renderRowForDevicePixelRatio = this.boundCreateRenderRowFor(devicePixelRatio);
			grid = (
				<ScrollView width={width} height={height} scrollLeft={left} scrollTop={top}
						onViewportChange={this.boundOnViewportChange}>
					<VariableSizeList viewport={viewport} itemDimensions={renderedCellMetrics}
											renderRow={renderRowForDevicePixelRatio} overscanRows={overscanRows} />
				</ScrollView>
			);
		}
		const recognizerClasses = combineClassNames('pdf-view--container', getToolCursorClassName(tool));
		return (
			<ImageViewerGesturesRecognizer targetRef={forwardRef} className={recognizerClasses} onPan={this.boundOnPan}
			                               onZoom={this.boundOnZoom} onPinchZoom={this.boundOnPinchZoom}
			                               onToolChanged={this.boundOnToolChanged} onGoToNext={onTapRight}
			                               onGoToPrevious={onTapLeft}>
				{grid}
			</ImageViewerGesturesRecognizer>
		);
	}
	
	renderRow(rowIndex, key, style, devicePixelRatio) {
		const {pages} = this.props;
		const {renderedCellMetrics} = this.getRenderedCellMetrics();
		const {pageWidth, pageHeight} = renderedCellMetrics[rowIndex];
		return (
			<div key={key} className='pdf-view--row-container' style={style}>
				<Page key='page-renderer' width={pageWidth} height={pageHeight} devicePixelRatio={devicePixelRatio} pdfPage={pages[rowIndex]} />
			</div>
		);
	}

	createRenderRowFor(devicePixelRatio) {
		return (...args) => this.renderRow(...args, devicePixelRatio);
	}

	onViewportChange(newViewport) {
		const {viewport} = this.state;
		if (!shallowEqual(viewport, newViewport)) {
			this.setState({
				viewport: newViewport
			});
		}
	}

	getDocumentMetrics() {
		const {pages} = this.props;
		return this.calcDocumentMetrics(pages);
	}

	static calcDocumentMetrics(allPages) {
		const pageSizes = allPages.map(getPageSize);
		const documentSize = PdfView.calcDocumentSize(pageSizes);
		return {pageSizes, documentSize};
	}

	static calcDocumentSize(pageSizes) {
		return pageSizes.reduce((documentSize, pageSize) => ({
			width: Math.max(documentSize.width, pageSize.width),
			height: documentSize.height + pageSize.height
		}), {width: 0, height: 0});
	}

	getScaledDocumentMetrics() {
		const {width, height} = this.props;
		const {zoom} = this.state;
		const documentMetrics = this.getDocumentMetrics();
		return this.boundCalcScaledDocumentMetrics(documentMetrics, width, height, zoom);
	}

	calcScaledDocumentMetrics(documentMetrics, width, height, zoom) {
		const {pageSizes} = documentMetrics;
		const pageFitScale = this.calcPageFitScale(width, height, pageSizes[0]);
		const scale = pageFitScale * zoom;
		const scaledPageSizes = pageSizes.map(pageSize => ({
			width: pageSize.width * scale,
			height: pageSize.height * scale
		}));
		const scaledDocumentSize = PdfView.calcDocumentSize(scaledPageSizes);
		return {pageFitScale, scale, scaledPageSizes, scaledDocumentSize};
	}

	static calcPageFitScale(rowWidth, rowHeight, pageSize) {
		let pageFitScale = null;
		if (Boolean(pageSize) && Boolean(rowWidth) && Boolean(rowHeight)) {
			const pageWidth = rowWidth - TOTAL_PAGE_PADDING;
			const pageHeight = rowHeight - TOTAL_PAGE_PADDING;
			pageFitScale = Math.min(
				pageWidth / pageSize.width,
				pageHeight / pageSize.height
			);
		}
		return pageFitScale;
	}

	calcMinZoom(documentMetrics, width, height) {
		const {pageSizes, documentSize} = documentMetrics;
		const [firstPage] = pageSizes;
		const pageFitScale = this.calcPageFitScale(width, height, firstPage);

		const remainingHeight = height - TOTAL_PAGE_PADDING * pageSizes.length;
		const minVerticalZoom = remainingHeight / (documentSize.height * pageFitScale);
		const remainingWidth = width - PAGE_PADDING - SCROLL_BAR_SPACE;
		const minHorizontalZoom = remainingWidth / (documentSize.width * pageFitScale);
		const heightOfMinZoomPages = (firstPage.height * pageFitScale) * MIN_ZOOM_PAGES;
		const zoomMinZoomPages = (height - TOTAL_PAGE_PADDING * MIN_ZOOM_PAGES) / heightOfMinZoomPages;
		return Math.max(zoomMinZoomPages, Math.min(minVerticalZoom, minHorizontalZoom));
	}

	calcMaxZoom(documentMetrics, width, height) {
		const {pageSizes, documentSize} = documentMetrics;
		const [firstPage] = pageSizes;
		const pageFitScale = this.calcPageFitScale(width, height, firstPage);

		const remainingWidth = width - TOTAL_PAGE_PADDING;
		const minHorizontalZoom = remainingWidth / (documentSize.width * pageFitScale);
		return Math.max(MAX_ZOOM, minHorizontalZoom);
	}

	getZoom() {
		const {width, height} = this.props;
		const {zoom} = this.state;
		const documentMetrics = this.getDocumentMetrics();
		return Math.min(
			this.boundCalcMaxZoom(documentMetrics, width, height),
			Math.max(
				zoom,
				this.boundCalcMinZoom(documentMetrics, width, height)
			)
		);
	}

	getRenderedCellMetrics() {
		return this.boundCalcRenderedCellMetrics(this.getScaledDocumentMetrics());
	}

	calcRenderedCellMetrics(scaledDocumentMetrics) {
		const {width} = this.props;
		const {scaledPageSizes, scaledDocumentSize} = scaledDocumentMetrics;

		let nextTop = 0;
		const renderedCellMetrics = scaledPageSizes.map(pageSize => {
			let {width: pageWidth, height: pageHeight} = pageSize;
			pageWidth = Math.floor(pageWidth);
			pageHeight = Math.floor(pageHeight);
			const remainingWidth = width - SCROLL_BAR_SPACE;
			const renderedHeight = pageHeight + TOTAL_PAGE_PADDING;
			const renderedWidth = Math.max(pageWidth + PAGE_PADDING, remainingWidth);
			const halfHorizontalPadding = Math.max(0, remainingWidth - PAGE_PADDING - pageWidth) / 2;
			const pageMetrics = {
				top: nextTop,
				width: renderedWidth,
				height: renderedHeight,
				verticalPadding: {top: PAGE_PADDING, bottom: PAGE_PADDING},
				horizontalPadding: {left: halfHorizontalPadding + PAGE_PADDING, right: halfHorizontalPadding},
				pageWidth,
				pageHeight
			};
			nextTop += renderedHeight;
			return pageMetrics;
		});
		const renderedSize = {
			width: Math.max(scaledDocumentSize.width + PAGE_PADDING, width - SCROLL_BAR_SPACE),
			height: scaledDocumentSize.height + renderedCellMetrics.length * TOTAL_PAGE_PADDING
		};

		return {
			renderedCellMetrics,
			renderedSize
		};
	}

	onZoom(focusX, focusY, zoomDiff) {
		const deltaZoomFactor = Math.pow(
			zoomDiff < 0
				? (1 - ZOOM_FACTOR_BASE_DIFF)
				: (1 + ZOOM_FACTOR_BASE_DIFF),
			Math.abs(zoomDiff) * ZOOM_SPEED);
		this.setState(state => this.applyZoom(state, focusX, focusY, deltaZoomFactor));
	}

	onToolChanged(tool) {
		const stateUpdate = {tool};
		const isZoomTool = tool === 'zoom' || tool === 'pinch/zoom';
		if (!isZoomTool) {
			stateUpdate.zoomFocusLocation = null;
		}
		this.setState(stateUpdate);
	}

	applyZoom(state, focusX, focusY, zoomFactor) {
		const {width, height} = this.props;
		let {zoomFocusLocation} = state;

		const zoom = this.getZoom();
		const documentMetrics = this.getDocumentMetrics();

		if (!zoomFocusLocation) {
			const scaledPosition = PdfView.toScaledPosition(state, vec2.fromValues(focusX, focusY));
			const scaledDocumentMetrics = this.boundCalcScaledDocumentMetrics(documentMetrics, width, height, zoom);
			const {renderedCellMetrics} = this.boundCalcRenderedCellMetrics(scaledDocumentMetrics);
			zoomFocusLocation = PdfView.toDocumentLocation(renderedCellMetrics, scaledPosition);
		}

		const minZoom = this.boundCalcMinZoom(documentMetrics, width, height);
		const maxZoom = this.boundCalcMaxZoom(documentMetrics, width, height);
		const newZoom = Math.min(maxZoom, Math.max(minZoom, zoom * zoomFactor));
		const zoomedScaledDocumentMetrics = this.boundCalcScaledDocumentMetrics(
			documentMetrics, width, height, newZoom
		);
		const {renderedCellMetrics, renderedSize} = this.boundCalcRenderedCellMetrics(zoomedScaledDocumentMetrics);
		const zoomedDocumentPos = PdfView.toDocumentPos(renderedCellMetrics, zoomFocusLocation);

		const newScrollPos = PdfView.clampedScrollPos(renderedSize, width, height, 
			zoomedDocumentPos.left - focusX, 
			zoomedDocumentPos.top - focusY);
		const {viewport} = state;
		return {
			...state, zoom: newZoom,
			viewport: PdfView.moveViewportTo(viewport, newScrollPos),
			zoomFocusLocation
		};
	}

	onPan(panX, panY) {
		this.setState(state => this.applyPan(state, panX, panY));
	}

	applyPan(state, panX, panY) {
		const {width, height} = this.props;
		const {viewport} = state;
		const {left, top} = viewport;
		const zoom = this.getZoom();
		const scaledDocumentMetrics = this.boundCalcScaledDocumentMetrics(
			this.getDocumentMetrics(), width, height, zoom
		);
		const {renderedSize} = this.boundCalcRenderedCellMetrics(scaledDocumentMetrics);

		const newScrollPos = PdfView.clampedScrollPos(renderedSize, width, height, left - panX, top - panY);
		return {...state, viewport: PdfView.moveViewportTo(viewport, newScrollPos)};
	}

	onPinchZoom(panX, panY, pinchCenterX, pinchCenterY, zoomFactor) {
		const pannedState = this.applyPan(this.state, panX, panY);
		const zoomedState = this.applyZoom(pannedState, pinchCenterX, pinchCenterY, zoomFactor);
		this.setState(zoomedState);
	}

	static toScaledPosition(state, viewerPos) {
		const {left, top} = state.viewport;
		return {
			left: left + viewerPos[0],
			top: top + viewerPos[1]
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		const {width, height, devicePixelRatio} = this.props;
		const {zoom, tool, viewport} = this.state;
		const relevantPropsChanged =
			width !== nextProps.width ||
			height !== nextProps.height ||
			devicePixelRatio !== nextProps.devicePixelRatio;

		const relevantStateChanged =
			zoom !== nextState.zoom ||
			tool !== nextState.tool && nextState.tool !== 'pinch/zoom' ||
			!shallowEqual(viewport, nextState.viewport);

		return relevantPropsChanged || relevantStateChanged;
	}

	static toDocumentPos(cellMetrics, documentLocation) {
		const {pageIndex, offset: {left, top}} = documentLocation;
		let documentPosition = null;
		if (pageIndex < cellMetrics.length) {
			const {
				top: cellTop, width: cellWidth, height: cellHeight,
				verticalPadding: {top: paddingTop, bottom: paddingBottom},
				horizontalPadding: {left: paddingLeft, right: paddingRight}
			} = cellMetrics[pageIndex];
			documentPosition = {
				left: PdfView.toAbsoluteOffset(left, cellWidth, paddingLeft, paddingRight),
				top: cellTop + PdfView.toAbsoluteOffset(top, cellHeight, paddingTop, paddingBottom)
			};
		}
		return documentPosition;
	}

	static toDocumentLocation(cellMetrics, documentPos) {
		const {left, top} = documentPos;
		const yPos = Math.max(top, 0); // #89864
		const cellIndex = PdfView.findPageForYPos(cellMetrics, yPos);
		let documentLocation = null;
		if (cellIndex !== -1) {
			const {
				top: cellTop, height: cellHeight, width: cellWidth,
				verticalPadding: {top: paddingTop, bottom: paddingBottom},
				horizontalPadding: {left: paddingLeft, right: paddingRight}
			} = cellMetrics[cellIndex];
			documentLocation = {
				pageIndex: cellIndex,
				offset: {
					top: PdfView.toNormalizedOffset(yPos - cellTop, cellHeight, paddingTop, paddingBottom),
					left: PdfView.toNormalizedOffset(left, cellWidth, paddingLeft, paddingRight)
				}
			};
		}
		return documentLocation;
	}

	static toNormalizedOffset(offset, totalSize, paddingBegin, paddingEnd = paddingBegin) {
		let relativePageOffset;
		const offsetToPaddingEnd = totalSize - paddingEnd;
		if (offset < paddingBegin) {
			// in leading padding
			relativePageOffset = -(offset / paddingBegin);
		} else if (offset < offsetToPaddingEnd) {
			// in page
			const totalPadding = paddingBegin + paddingEnd;
			relativePageOffset = (offset - paddingBegin) / (totalSize - totalPadding);
		} else {
			// in trailing padding
			relativePageOffset = 1.0 + (offset - offsetToPaddingEnd) / paddingEnd;
		}
		return relativePageOffset;
	}

	static toAbsoluteOffset(offset, totalSize, paddingBegin, paddingEnd = paddingBegin) {
		let absoulteOffset;
		if (offset < 0.0) {
			// in leading padding
			absoulteOffset = -offset * paddingBegin;
		} else if (offset < 1.0) {
			// in page
			const totalPadding = paddingBegin + paddingEnd;
			absoulteOffset = (totalSize - totalPadding) * offset + paddingBegin;
		} else {
			// in trailing padding
			absoulteOffset = (totalSize - paddingEnd) + (offset - 1.0) * paddingEnd;
		}
		return absoulteOffset;
	}

	static findPageForYPos(pageMetrics, posY) {
		return binarySearch(pageMetrics, posY, (pageMetric, needle) => {
			const {top, height} = pageMetric;
			if (top > needle) {
				return 1;
			}
			return ((top + height) < needle ? -1 : 0);
		});
	}

	static clampedScrollPos(renderedSize, width, height, scrollLeft, scrollTop) {
		const maxScrollTop = renderedSize.height - (height - PAGE_PADDING);
		const maxScrollLeft = renderedSize.width - (width - SCROLL_BAR_SPACE);
		return {
			left: Math.ceil(Math.min(maxScrollLeft, Math.max(0, scrollLeft))),
			top: Math.ceil(Math.min(maxScrollTop, Math.max(0, scrollTop)))
		};
	}

	static moveViewportTo(viewport, pos) {
		return {
			...viewport, ...pos, right: pos.left + viewport.width - 1,
			bottom: pos.top + viewport.height - 1
		};
	}
}

PdfView.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	pages: PropTypes.arrayOf(PropTypes.object),
	onTapLeft: PropTypes.func,
	onTapRight: PropTypes.func,
	devicePixelRatio: PropTypes.number,
	pdfDocument: PropTypes.object,
	forwardRef: withForwardRef.PropTypes.Ref
};
PdfView.defaultProps = {
	width: 0,
	height: 0,
	pages: null
};

export default withViewerLayoutProps(withForwardRef(PdfView, 'forwardRef'));
