import React from 'react';
import PropTypes from 'prop-types';

import {noop} from '../utils/FunctionUtils.js';
import {scheduleTimerOnce} from '../utils/TimerUtils.js';

export default class Timer extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundHandleTimeout = this.handleTimeout.bind(this);
		this.cancelCurrentTimer = noop;
	}

	render() {
		const {children} = this.props;
		return React.Children.count(children) > 0 ? children : false;
	}

	scheduleTimer(props) {
		const {delay, onTimeout, scheduleTimer} = props;
		this.cancelTimer();
		if (onTimeout !== noop) {
			this.cancelCurrentTimer = scheduleTimer(this.boundHandleTimeout, delay);
		}
	}

	handleTimeout() {
		const {onTimeout} = this.props;
		onTimeout();
	}

	cancelTimer() {
		this.cancelCurrentTimer();
		this.cancelCurrentTimer = noop;
	}

	componentDidMount() {
		this.scheduleTimer(this.props);
	}

	shouldComponentUpdate(nextProps) {
		const {delay, onTimeout, scheduleTimer, children} = this.props;
		if (
			delay !== nextProps.delay || (onTimeout === noop || nextProps.onTimeout === noop) ||
			scheduleTimer !== nextProps.scheduleTimer
		) {
			this.scheduleTimer(nextProps);
		}
		return React.Children.count(children) > 0 && children !== nextProps.children;
	}

	componentWillUnmount() {
		this.cancelTimer();
	}
}

Timer.propTypes = {
	scheduleTimer: PropTypes.func,
	delay: PropTypes.number,
	onTimeout: PropTypes.func
};

Timer.defaultProps = {
	scheduleTimer: scheduleTimerOnce,
	delay: 1,
	onTimeout: noop
};
