import React from 'react';

import {capitalizeFirst} from '../../commons/utils/StringUtils.js';
import {tr as baseTr} from '../../i18n/components/SynFormattedMessage.js';
import AnnotationsTranslator from '../../i18n/translators/AnnotationsTranslator.js';
import AnnotationArrowIcon from '../../ui/components/icons/AnnotationArrowIcon.js';
import AnnotationTextIcon from '../../ui/components/icons/AnnotationTextIcon.js';
import ColorizeIcon from '../../ui/components/icons/ColorizeIcon.js';
import DistanceMeasureIcon from '../../ui/components/icons/DistanceMeasureIcon.js';
import FourPointGoniometryIcon from '../../ui/components/icons/FourPointGoniometryIcon.js';
import GoniometryIcon from '../../ui/components/icons/GoniometryIcon.js';
import ArrowAnnotation from './annotations/ArrowAnnotation.js';
import {CircleAnnotation, CircleMeasurement} from './annotations/CircleAnnotations.js';
import EllipseMeasurementComponent from './annotations/EllipseMeasurement.js';
import {FourPointGoniometry, ThreePointGoniometry} from './annotations/Goniometry';
import HounsfieldMeasurement from './annotations/HounsfieldMeasurement';
import MaskAnnotation from './annotations/MaskAnnotation.js';
import ParallelLineMeasurement from './annotations/ParallelLineMeasurement.js';
import PolygonMeasurement from './annotations/PolygonMeasurement.js';
import ShutterAnnotation from './annotations/ShutterAnnotation.js';
import SyncPointAnnotation from './annotations/SyncPointAnnotation.js';
import TextAnnotation from './annotations/TextAnnotation.js';
import USMeasurement from './annotations/USMeasurement.js';

const LAYER_DEFAULT = 10;
const LAYER_MASK = 1;
const LAYER_SHUTTER = 0;

const ANNOTATION_MAPPINGS = ({
	polyline: {
		component: PolygonMeasurement,
		icon: DistanceMeasureIcon,
		label: tr('DistanceMeasurement'),
		menu: true
	},
	goniometry: {
		component: ThreePointGoniometry,
		icon: GoniometryIcon,
		label: tr('Goniometry'),
		menu: true
	},
	'four-point-goniometry': {
		component: FourPointGoniometry,
		icon: FourPointGoniometryIcon,
		label: tr('FourPointGoniometry'),
		menu: true
	},
	arrow: {
		component: ArrowAnnotation,
		icon: AnnotationArrowIcon,
		label: tr('Arrow'),
		menu: true
	},
	text: {
		component: TextAnnotation,
		icon: AnnotationTextIcon,
		label: tr('Text'),
		menu: true
	},
	syncpoint: {
		component: SyncPointAnnotation,
		icon: AnnotationTextIcon,
		label: tr('SyncPoint'),
		menu: true
	},
	circle: {
		component: CircleAnnotation,
		icon: AnnotationTextIcon,
		label: tr('Circle'),
		menu: false
	},
	circlemeasurement: {
		component: CircleMeasurement,
		icon: AnnotationTextIcon,
		label: tr('CircleMeasurement'),
		menu: false
	},
	'parallel-line-distance': {
		component: ParallelLineMeasurement,
		icon: false,
		label: false,
		menu: false
	},
	'ellipse-measurement': {
		component: EllipseMeasurementComponent,
		icon: false,
		label: false,
		menu: false
	},
	usmeasurement: {
		component: USMeasurement,
		icon: false,
		label: false,
		menu: false
	},
	mask: {
		component: MaskAnnotation,
		icon: AnnotationTextIcon,
		label: tr('Mask'),
		menu: false,
		layer: LAYER_MASK
	},
	shutter: {
		component: ShutterAnnotation,
		icon: AnnotationTextIcon,
		label: tr('Shutter'),
		menu: false,
		layer: LAYER_SHUTTER
	},
	hounsfield: {
		component: HounsfieldMeasurement,
		icon: ColorizeIcon,
		label: tr('HounsfieldMeasurement'),
		menu: true

	}
});

function tr(message) {
	return baseTr(AnnotationsTranslator, message);
}

export function getSupportedAnnotationTypes(viewerItem) {
	const isAnnotationSupportedForViewerItem = annotationType => isAnnotationSupported(viewerItem, annotationType);
	return getAnnotationTypes().filter(isAnnotationSupportedForViewerItem);
}

function isAnnotationSupported(viewerItem, annotationType) {
	const annotationComponent = getAnnotationComponent(annotationType);
	return annotationComponent.isAnnotationSupportedForViewerItem(viewerItem);
}

function getAnnotationTypes() {
	return Object.keys(ANNOTATION_MAPPINGS);
}

export function getAnnotationComponent(annotationType) {
	const annotationDefinition = annotationType ? ANNOTATION_MAPPINGS[annotationType] : null;
	return annotationDefinition ? annotationDefinition.component : null;
}

export function getAnnotationIcon(annotationType) {
	return annotationType ? ANNOTATION_MAPPINGS[annotationType].icon : undefined;
}

export function getAnnotationLabel(annotationType) {
	return annotationType ? ANNOTATION_MAPPINGS[annotationType].label : undefined;
}

export function getAnnotationToolId(annotationType) {
	return annotationType ? `${capitalizeFirst(annotationType)}Annotation` : undefined;
}

export function showAnnotationInMenu(annotationType) {
	return annotationType ? ANNOTATION_MAPPINGS[annotationType].menu !== false : false;
}

export function getAnnotationLayer(annotationType) {
	const isKnownAnnotationType = Boolean(annotationType) && Boolean(ANNOTATION_MAPPINGS[annotationType]);
	const layer = isKnownAnnotationType ? ANNOTATION_MAPPINGS[annotationType].layer : LAYER_DEFAULT;
	return layer === undefined ? LAYER_DEFAULT : layer;
}


