import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';
import UploadError from './UploadError.js';
import UploadSuccess from './UploadSuccess.js';

export default function UploadReport() {
	const uploadHasFailed = useBrick(UploadFilesCollection, collection => collection.getFailedFilesCount() > 0);
	return uploadHasFailed ? <UploadError /> : <UploadSuccess />;
}
