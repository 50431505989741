import createFormAccessors from '../../../commons/data/FormAccessorsFactory.js';
import {required} from '../../../commons/data/validators';
import PasswordMessagesTranslator from '../../i18n/PasswordMessagesTranslator.js';

const VERIFICATION_CODE_FORM_SCHEMA = {
	fields: {
		verificationCode: {
			label: 'VerificationCode',
			defaultValue: null,
			validators: [required]
		}
	}
};
export default createFormAccessors(VERIFICATION_CODE_FORM_SCHEMA, PasswordMessagesTranslator);
