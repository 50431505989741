import React from 'react';
import {vec2} from 'gl-matrix';
import {number, object, string} from 'prop-types';

import {getLengthScaleParameters} from '../utils/LengthScaleUtils.js';
import {getZoom} from '../utils/math/MatrixHelper.js';
import {approximatelyEqual} from '../utils/VectorUtils.js';

import '../../../styles/viewer/components/LengthScaleOverlay.scss';

const PADDING_PARALLEL_TO_RULER = 5;
const PADDING_ORTHOGONAL_TO_RULER = 100;
const TEXT_PADDING = 5;
const TICKS_LENGTH_LARGE = 6;
const TICKS_LENGTH_SMALL = 3;
const ORIENTATION_VERTICAL = 'vertical';
const ORIENTATION_HORIZONTAL = 'horizontal';

class Scale extends React.PureComponent {
	render() {
		const {length, numSubDivisions, dx, dy, rotation} = this.props;
		const path = Scale.calculatePath(length, numSubDivisions);
		return (
			<g transform={`translate(${dx} ${dy}) rotate(${rotation} 0 0)`}>
				<path className='length-scale--line' d={path} />
			</g>
		);
	}

	static calculatePath(viewportLength, numSubDivisions) {
		const delta = viewportLength / numSubDivisions;
		let path = `M 0 0 h ${Math.round(viewportLength)}`;
		for (let step = 0; step <= numSubDivisions; ++step) {
			const tickLength = (step === 0 || step === numSubDivisions || step === numSubDivisions / 2)
				? TICKS_LENGTH_LARGE
				: TICKS_LENGTH_SMALL;
			const xPos = Math.round(step * delta);
			path += ` M ${xPos} 0 v ${tickLength}`;
		}
		return path;
	}
}

Scale.propTypes = {
	length: number,
	numSubDivisions: number,
	dx: number,
	dy: number,
	rotation: number
};

Scale.defaultProps = {
	dx: 0,
	dy: 0,
	rotation: 0
};

class TextLabel extends React.PureComponent {
	render() {
		const {x, y, value, unit, orientation} = this.props;
		if (orientation === ORIENTATION_HORIZONTAL) {
			return TextLabel.renderHorizontalText(x, y, value, unit, false);
		}
		return TextLabel.renderVerticalText(x, y, value, unit, false);
	}

	static renderHorizontalText(x, y, value, unit, renderAsContour) {
		return (
			<text x={x} y={y} className={TextLabel.getTextClassName('horizontal', renderAsContour)}>
				{value}
				{' '}
				{unit}
			</text>
		);
	}

	static renderVerticalText(x, y, value, unit, renderAsContour) {
		return (
			<text y={y} className={TextLabel.getTextClassName('vertical', renderAsContour)}>
				<tspan x={x} dy='1em'>
					{value}
				</tspan>
				<tspan x={x} dy='1em'>
					{unit}
				</tspan>
			</text>
		);
	}

	static getTextClassName(orientation, forContour) {
		const baseClasses = `length-scale--text length-scale--text--${orientation}`;
		return forContour ? `${baseClasses} length-scale--text--contour` : baseClasses;
	}
}

TextLabel.propTypes = {
	x: number,
	y: number,
	orientation: string,
	value: number,
	unit: string
};

class LengthScale extends React.PureComponent {
	render() {
		const {containerWidth, containerHeight, orientation} = this.props;
		const {length, displayValue, numSubDivisions} =
			this.calcScaleProperties(this.getMaximumLength(orientation), this.getPixelSpacing(orientation));
		const offset = length / 2;
		const scaleOffset = -Math.round(offset);
		const textOffset = offset + TEXT_PADDING;
		const {dX, dY, textX, textY, scaleRotation} = orientation === ORIENTATION_HORIZONTAL
			? LengthScale.calcHorizontalRenderParameters(scaleOffset, textOffset, containerHeight)
			: LengthScale.calcVerticalRenderParameters(scaleOffset, textOffset, containerWidth);
		return (
			<React.Fragment>
				<Scale length={length} numSubDivisions={numSubDivisions} dx={dX} dy={dY} rotation={scaleRotation} />
				<TextLabel value={displayValue.value}
							  unit={displayValue.symbol} x={textX} y={textY} orientation={orientation} />
			</React.Fragment>
		);
	}

	calcScaleProperties(maxLengthViewer, pixelSpacing) {
		const {transformationMatrix, inverseTransformationMatrix} = this.props;
		const distanceVector = vec2.fromValues(maxLengthViewer, 0);
		// transform to image coordinates
		vec2.transformMat2(distanceVector, distanceVector, inverseTransformationMatrix);
		const maxScaleLength = vec2.length(vec2.scale(distanceVector, distanceVector, pixelSpacing));
		const {scaleLengthMM, displayValue, numSubDivisions} = getLengthScaleParameters(maxScaleLength);
		const imageLength = scaleLengthMM / pixelSpacing;
		const imageDistanceVector = vec2.fromValues(imageLength, 0);
		vec2.transformMat2(imageDistanceVector, imageDistanceVector, transformationMatrix);
		return {
			length: vec2.length(imageDistanceVector),
			displayValue,
			numSubDivisions
		};
	}

	getMaximumLength(orientation) {
		const {containerWidth, containerHeight} = this.props;
		const totalSize = orientation === ORIENTATION_HORIZONTAL ? containerWidth : containerHeight;
		return totalSize - 2 * PADDING_ORTHOGONAL_TO_RULER;
	}

	getPixelSpacing(orientation) {
		const {pixelSpacing} = this.props;
		return orientation === ORIENTATION_HORIZONTAL ? pixelSpacing[0] : pixelSpacing[1];
	}

	static calcHorizontalRenderParameters(scaleOffset, textOffset, containerHeight) {
		return {
			scaleRotation: 0,
			dX: scaleOffset,
			dY: -containerHeight / 2 + PADDING_PARALLEL_TO_RULER,
			textX: textOffset,
			textY: -containerHeight / 2 + PADDING_PARALLEL_TO_RULER + TEXT_PADDING + 2
		};
	}

	static calcVerticalRenderParameters(scaleOffset, textOffset, containerWidth) {
		return {
			scaleRotation: 90,
			dX: containerWidth / 2 - PADDING_PARALLEL_TO_RULER,
			dY: scaleOffset,
			textX: containerWidth / 2 - TEXT_PADDING,
			textY: textOffset
		};
	}
}

LengthScale.propTypes = {
	containerWidth: number,
	containerHeight: number,
	orientation: string,
	transformationMatrix: object,
	inverseTransformationMatrix: object,
	pixelSpacing: object
};

export default class LengthScaleOverlay extends React.Component {
	render() {
		const {pixelSpacing, transformationMatrix, inverseTransformationMatrix} = this.props;
		const canRender = pixelSpacing && transformationMatrix && inverseTransformationMatrix;
		let element = false;
		if (canRender) {
			element = (
				<React.Fragment>
					<LengthScale orientation={ORIENTATION_HORIZONTAL} {...this.props} />
					<LengthScale orientation={ORIENTATION_VERTICAL} {...this.props} />
				</React.Fragment>
			);
		}
		return element;
	}

	shouldComponentUpdate(nextProps) {
		const {
			containerWidth, containerHeight, pixelSpacing, transformationMatrix
		} = this.props;
		const {
			containerWidth: nextContainerWidth,
			containerHeight: nextContainerHeight,
			pixelSpacing: nextPixelSpacing,
			transformationMatrix: nextTransformationMatrix
		} = nextProps;
		return containerWidth !== nextContainerWidth ||
			containerHeight !== nextContainerHeight ||
			!approximatelyEqual(pixelSpacing, nextPixelSpacing) ||
			getZoom(transformationMatrix) !== getZoom(nextTransformationMatrix);
	}
}
LengthScaleOverlay.propTypes = {
	containerWidth: number,
	containerHeight: number,
	transformationMatrix: object,
	inverseTransformationMatrix: object,
	pixelSpacing: object
};
