import {createToken, CstParser, Lexer} from 'chevrotain';

import {documentMatches} from './PatientDetailsDataUtils';

const TOKEN_STRING = createToken({
	name: 'tokenString',
	pattern: /\S+/
});

const TOKEN_QUOTED = createToken({
	name: 'tokenQuoted',
	pattern: /"([^"]+)"/ // the capture group is used later on with $1
});

const TOKEN_OR = createToken({
	name: 'tokenOr',
	pattern: /\s+OR\s+/
});

const TOKEN_AND = createToken({
	name: 'tokenAnd',
	pattern: /\s+/
});

const TOKEN_NOT = createToken({
	name: 'tokenNot',
	pattern: /-\s*/
});

// note that the order matters here, the first matching token hits
const FILTER_TOKENS = [
	TOKEN_OR,
	TOKEN_AND,
	TOKEN_NOT,
	TOKEN_QUOTED,
	TOKEN_STRING
];

const filterLexer = new Lexer(FILTER_TOKENS);

class FilterParser extends CstParser {
	/* eslint-disable new-cap --
	* the chevrotain API uses these uppercase method names, like RULE.
	* the so dynamically generated method like filterTop
	* is used in the visitor class down */
	constructor() {
		super(FILTER_TOKENS);
		this.RULE('filterTop', () => {
			this.SUBRULE(this.filterOr);
		});

		this.RULE('filterOr', () => {
			this.AT_LEAST_ONE_SEP({
				SEP: TOKEN_OR,
				DEF: () => {
					this.SUBRULE(this.filterAnd);
				}
			});
		});

		this.RULE('filterAnd', () => {
			this.SUBRULE(this.filterNot);
			this.OPTION(() => {
				this.MANY(() => {
					this.CONSUME(TOKEN_AND);
					this.OPTION1(() => {
						this.SUBRULE1(this.filterNot);
					});
				});
			});
		});

		this.RULE('filterNot', () => {
			this.OPTION(() => {
				this.CONSUME(TOKEN_NOT);
			});
			this.OPTION1(() => {
				this.SUBRULE(this.filterAtom);
			});
		});

		this.RULE('filterAtom', () => {
			this.OR([
				{
					ALT: () => {
						this.SUBRULE(this.filterQuoted);
					}
				},
				{
					ALT: () => {
						this.SUBRULE(this.filterStringExp);
					}
				}
			]);
		});

		this.RULE('filterQuoted', () => {
			this.CONSUME(TOKEN_QUOTED);
		});

		this.RULE('filterStringExp', () => {
			this.CONSUME(TOKEN_STRING);
		});
		this.performSelfAnalysis();
	}
}

/* eslint-enable new-cap */
/* eslint-disable max-lines-per-function --
 * interface classes needs to be implemented like that
 */
export function createDocumentMatcher(filterText) {
	const parser = new FilterParser();
	const BaseCstVisitor = parser.getBaseCstVisitorConstructor();

	class FilterInterpreter extends BaseCstVisitor {
		/* eslint-disable class-methods-use-this --
		* chevrotain API needs the parsing expressions to be defined here
		* terminal expressions needs no this keyword, but the method must be defined */
		constructor() {
			super();
			this.validateVisitor();
		}

		filterTop(ctx, doc) {
			return this.visit(ctx.filterOr, doc);
		}

		filterOr(ctx, doc) {
			let result = false;
			for (const filter of ctx.filterAnd) {
				result = result || this.visit(filter, doc);
			}
			return result;
		}

		filterAnd(ctx, doc) {
			const {filterNot} = ctx;
			let result = true;
			for (const filter of filterNot) {
				result = result && this.visit(filter, doc);
			}
			return result;
		}

		filterNot(ctx, doc) {
			const {tokenNot, filterAtom} = ctx;
			if (!filterAtom) {
				return true;
			}
			const atom = this.visit(filterAtom, doc);
			if (tokenNot) {
				return !atom;
			}
			return atom;
		}

		filterAtom(ctx, doc) {
			const {filterStringExp, filterQuoted} = ctx;
			let result;
			if (filterStringExp) {
				result = this.visit(filterStringExp, doc);
			}
			if (filterQuoted) {
				result = this.visit(filterQuoted, doc);
			}
			return result;
		}

		filterStringExp(ctx, doc) {
			const {tokenString} = ctx;
			const [{image}] = tokenString;
			let filterString = image;
			if (filterString.startsWith('"')) {
				filterString = filterString.substring(1);
			}
			if (filterString === '') {
				return true;
			}
			return documentMatches(doc, filterString);
		}

		filterQuoted(ctx, doc) {
			const {tokenQuoted} = ctx;
			const [{image}] = tokenQuoted;
			if (image === '') {
				return true;
			}
			const filterString = image.substring(1, image.length - 1);
			return documentMatches(doc, filterString);
		}
		/* eslint-enable class-methods-use-this */
	}

	const interpreter = new FilterInterpreter();
	const {tokens, errors} = filterLexer.tokenize(filterText);
	if (errors && errors.length > 0) {
		throw new Error('Failed to parse filter string');
	}

	parser.input = tokens;
	const cst = parser.filterTop();

	return doc => interpreter.visit(cst, doc);
}
/* eslint-enable max-lines-per-function */

