import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon, {listItemIconClasses} from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import {withForwardRef} from '../../commons/utils/ReactUtils.js';

const LIST_ITEM_ICON_STYLES = {
	[`& .${listItemIconClasses.root}`]: {
		minWidth: '40px'
	}
};

function MenuEntry(props) {
	const {
		id, icon, primaryText, secondaryText, autoFocus, tabIndex, onClick, divider, component, selected, asListItem,
		textAlignRight, forwardRef, avatar, ...remainingProps
	} = props;
	const forwardedProps = cloneWithoutProperties(remainingProps, 'onTap');
	const ItemComponent = asListItem ? ListItem : MenuItem;
	return (
		<ItemComponent id={id} className='menu-item' component={component} ref={forwardRef} autoFocus={autoFocus}
		               tabIndex={tabIndex} onClick={onClick} divider={divider} selected={selected}
		               button={asListItem ? true : undefined} sx={LIST_ITEM_ICON_STYLES} {...forwardedProps}>
			{icon && (
				<ListItemIcon>
					{icon}
				</ListItemIcon>
			)}
			{avatar && (
				<ListItemAvatar>
					{avatar}
				</ListItemAvatar>
			)}
			<ListItemText primary={primaryText} secondary={secondaryText} primaryTypographyProps={{...(textAlignRight && {align: 'right'})}} />
		</ItemComponent>
	);
}

MenuEntry.propTypes = {
	component: PropTypes.elementType,
	id: PropTypes.string.isRequired,
	primaryText: PropTypes.node.isRequired,
	secondaryText: PropTypes.node,
	avatar: PropTypes.node,
	onClick: PropTypes.func,
	icon: PropTypes.node,
	autoFocus: PropTypes.bool,
	tabIndex: PropTypes.number,
	divider: PropTypes.bool,
	selected: PropTypes.bool,
	asListItem: PropTypes.bool,
	textAlignRight: PropTypes.bool,
	forwardRef: withForwardRef.PropTypes.Ref
};

MenuEntry.defaultProps = {
	asListItem: false,
	textAlignRight: false,
	component: 'div'
};

export default withForwardRef(MenuEntry, 'forwardRef');
