import React from 'react';

import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import FlexLayoutBase from './FlexLayoutBase.js';

function HorizontalLayout(props) {
	return <FlexLayoutBase direction='row' {...props} />;
}

HorizontalLayout.propTypes = cloneWithoutProperties(FlexLayoutBase.propTypes, 'direction');
HorizontalLayout.propTypes.forwardRef = withForwardRef.PropTypes.Ref;
HorizontalLayout.defaultProps = cloneWithoutProperties(FlexLayoutBase.defaultProps, 'direction');

export default withForwardRef(HorizontalLayout, 'forwardRef');
