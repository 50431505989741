import UserActivationPageContainer from '../../../authentication/flux/containers/UserActivationPageContainer.js';
import {selectCanActivate} from '../../../authentication/flux/selectors/NewPasswordSelectors.js';

function createUserActivationComponentSelector(pageSelector) {
	return state => (selectCanActivate(state) ? UserActivationPageContainer : pageSelector(state));
}

export default function createUserActivationRoute(route) {
	return route.update('pageSelector', pageSelector => createUserActivationComponentSelector(pageSelector));
}
