import React from 'react';
import PropTypes from 'prop-types';

import Tappable from '../../../commons/components/Tappable.js';
import {stopEventPropagation} from '../../../commons/utils/DOMEventUtils.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {TOUCH_SIZE} from './AnnotationConstants.js';
import AnnotationIcon from './AnnotationIcon.js';

import '../../../../styles/viewer/components/annotations/TappableAnnotationIcon.scss';

function createActivationHandler(passedHandler) {
	return e => {
		if (passedHandler) {
			passedHandler(e);
		}
		stopEventPropagation(e);
	};
}

export default function TappableIcon(props) {
	const {radius, x, y, onTap, onClick, children} = props;
	const remainingProps = cloneWithoutProperties(props, 'x', 'y', 'radius');
	const tappableProps = {
		onMouseDown: stopEventPropagation,
		onTouchStart: stopEventPropagation,
		onPointerDown: stopEventPropagation,
		onTap: createActivationHandler(onTap || onClick),
		onClick: createActivationHandler(onClick || onTap),
		...remainingProps
	};
	return (
		<Tappable Component={AnnotationIcon} {...tappableProps}>
			{children}
			<circle cx={x} cy={y} r={radius} className='tappable-annotation-icon--touch-area' />
		</Tappable>
	);
}

TappableIcon.propTypes = {
	radius: PropTypes.number,
	x: PropTypes.number,
	y: PropTypes.number,
	onTap: PropTypes.func,
	onClick: PropTypes.func
};

TappableIcon.defaultProps = {
	radius: TOUCH_SIZE / 2
};
