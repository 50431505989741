import React from 'react';
import Button from '@mui/material/Button';
import MUIDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

import {noop} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import GeneralMessagesTranslator from '../../i18n/translators/GeneralTranslator.js';
import {COLOR_PRIMARY} from '../constants/SynMUIOptions.js';

const DEFAULT_CLOSE_BUTTON_LABEL = <SynFormattedMessage message='ClosePhrase' translator={GeneralMessagesTranslator} />;

export default function SimpleDialog(props) {
	const {show, closeButtonLabel, onClose, children, title, id, customDialogContent, fullWidth} = props;
	const finalCloseButtonLabel = closeButtonLabel || DEFAULT_CLOSE_BUTTON_LABEL;
	return (
		<MUIDialog id={id} open={show} onClose={onClose} fullWidth={fullWidth}>
			<DialogTitle>
				{title}
			</DialogTitle>
			{customDialogContent ? children : (
				<React.Fragment>
					<DialogContent>
						{children}
					</DialogContent>
					<DialogActions>
						<Button autoFocus onClick={onClose} color={COLOR_PRIMARY} variant='contained' disableElevation>
							{finalCloseButtonLabel}
						</Button>
					</DialogActions>
				</React.Fragment>
			)}
		</MUIDialog>
	);
}

SimpleDialog.propTypes = {
	show: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	children: PropTypes.node.isRequired,
	title: PropTypes.node,
	closeButtonLabel: PropTypes.elementType,
	id: PropTypes.string,
	customDialogContent: PropTypes.bool,
	fullWidth: PropTypes.bool
};

SimpleDialog.defaultProps = {
	onClose: noop
};
