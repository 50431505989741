import Immutable from 'immutable';

function encodeValue(value) {
	const valueType = typeof value;
	return (valueType === 'string' || valueType === 'number') ? encodePrimitive(value, valueType) : encodeURIComponent(JSON.stringify(value));
}

function encodePrimitive(value, valueType) {
	if (valueType === 'string' &&
		(value.match(/^\d+$/) || value.match(/^\d+\.\d+$/))
	) {
		return `"${value}"`;
	}
	return encodeURIComponent(value);
}

/**
 * Converts a location to a url string
 *
 * @param location the provided location consisting of path, fragment and hash
 * @returns {String} the url representation of the location
 */
export function toUrlString(location) {
	let urlString = location.get('path', '');
	const fragment = location.get('fragment', '');

	const queryObject = location.get('query', Immutable.Map()).toJSON();
	const queryPart = Object.keys(queryObject).map(variableName => `${variableName}=${encodeValue(queryObject[variableName])}`)
		.join('&');

	if (queryPart !== '') {
		urlString += `?${queryPart}`;
	}
	if (fragment !== '') {
		urlString += `#${fragment}`;
	}
	return urlString;
}

/**
 * Converts a url string to a location immutable map.
 *
 * @param urlString the provided url consisting of path, fragment and hash
 * @returns {Immutable.Map} the location representation of the urlString
 */
export function fromUrlString(urlString) {
	try {
		const url = new URL(urlString, window.location.origin);
		const path = url.pathname;
		const query = processQueryParams(url.searchParams);
		const fragment = url.hash.substring(1);
		return Immutable.fromJS({path, query, fragment});
	} catch (e) {
		return new Immutable.Map();
	}
}

function processQueryParams(urlSearchParams) {
	const queryObject = {};
	for (const [variableName, variableValue] of urlSearchParams) {
		const decodedURIComponent = decodeURIComponent(variableValue);
		try {
			queryObject[variableName] = decodedURIComponent.trim().match(/^[[{]|^[0-9]|^".*"/)
				? JSON.parse(decodedURIComponent)
				: decodedURIComponent;
		} catch (e) {
			queryObject[variableName] = decodedURIComponent;
		}
	}
	return queryObject;
}

/**
 * Adds a cache buster parameter to the query.
 * @param {Immutable.Map} query as Immutable.Map, for which to set the cache buster parameter
 * @param {String} cacheBusterParameterName - the name of the cache buster parameter in the query (default: cb)
 * @param {Function} cacheBusterFactory - a function returning a new cacheBuster value
 * (default: () => 'cb' + Date.now() )
 * @returns {Immutable.Map} with the cache buster set
 */
let cbCounter = 0;
export function addCacheBuster(query, cacheBusterParameterName = 'cb', cacheBusterFactory = c => (`cb${Date.now()}${c}`)) {
	cbCounter += 1;
	return query.set(cacheBusterParameterName, cacheBusterFactory(cbCounter));
}

/**
 * Function to update the hidden state of the location reducer.
 * @param location - the location state to update the hidden part of.
 * @param updater - function that takes the current hidden state and returns the updated one.
 * @returns {*} - the updated location state.
 */
export function updateHiddenLocationState(location, updater) {
	return location.update('hidden', Immutable.Map(), updater);
}
