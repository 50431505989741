import React from 'react';
import MUIIconButton from '@mui/material/IconButton';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {memoWithName, withForwardRef} from '../../commons/utils/ReactUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import {COLOR_DEFAULT, COLOR_PRIMARY, COLOR_SECONDARY} from '../constants/SynMUIOptions.js';

function IconButton(props) {
	const {className, disabled, color, forwardRef, icon, children, size, sx, ...remainingProps} = props;
	const finalClassNames = combineClassNames(
		classnames({
			disabled
		}),
		className
	);
	const sxProps = useMemoFactory(getSxProps, color, sx);
	return (
		<MUIIconButton ref={forwardRef} className={finalClassNames} color={color} disabled={disabled} size={size}
		               sx={sxProps} {...remainingProps}>
			{icon}
			{children}
		</MUIIconButton>
	);
}

IconButton.propTypes = {
	...MUIIconButton.propTypes,
	forwardRef: withForwardRef.PropTypes.Ref,
	onClick: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	children: PropTypes.node,
	id: PropTypes.string,
	color: PropTypes.oneOf([COLOR_DEFAULT, COLOR_PRIMARY, COLOR_SECONDARY, 'inherit']),
	'aria-controls': PropTypes.string,
	'aria-haspopup': PropTypes.oneOf(['true', 'false']),
	icon: PropTypes.node,
	size: PropTypes.string
};

IconButton.defaultProps = {
	color: COLOR_DEFAULT,
	size: 'large'
};

function getSxProps(color, sx) {
	if (color === COLOR_DEFAULT) {
		return {
			'&:hover': {
				color: 'primary.main'
			},
			...sx
		};
	}
	return sx;
}

export default withForwardRef(memoWithName(IconButton), 'forwardRef');
