import {IS_DEBUG_BUILD} from '../../commons/constants/EnvironmentConstants.js';
import useObservable from '../../commons/utils/customHooks/useObservable.js';
import {debugError} from '../../commons/utils/DebugLog.js';
import useBrickRegistry from './useBrickRegistry.js';

export default function useBrick(brickType, selectFunction) {
	const brickRegistry = useBrickRegistry();
	const brick = brickRegistry.getOnlyBrickOfType(brickType);
	if (IS_DEBUG_BUILD) {
		logMissingBrick(brick, brickType);
	}
	return useObservable(brick, selectFunction);
}

function logMissingBrick(brick, brickType) {
	if (!brick) {
		debugError(`useBrick cannot find registered brick of type: ${brickType.name}`);
	}
}
