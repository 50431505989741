import once from 'lodash.once';

import {memoizeLast} from '../commons/utils/FunctionUtils.js';
import {getDependencies} from './brickTools.js';

export default function autoRegister(brickRegistry, BrickType, brickFactory = undefined, additionalDependencies = []) {
	const autoFactory = new AutoBrickFactory(brickRegistry, BrickType, brickFactory, additionalDependencies);
	return autoFactory.shutdown.bind(autoFactory);
}

class AutoBrickFactory {
	constructor(brickRegistry, BrickType, brickFactory = undefined, additionalDependencies = []) {
		this.brickRegistry = brickRegistry;
		this.unregisterFromRegistry = brickRegistry.subscribe(this.onBrickRegistryChanged.bind(this));
		this.instance = null;

		const realFactory = brickFactory || defaultBrickFactory(BrickType);
		this.brickFactory = createBrickFactory(BrickType, realFactory, additionalDependencies);

		this.onBrickRegistryChanged(brickRegistry);
	}

	onBrickRegistryChanged(registry) {
		this.replaceInstance(this.brickFactory(registry));
	}

	replaceInstance(newInstance) {
		if (newInstance !== this.instance) {
			const oldInstance = this.instance;
			this.instance = newInstance;

			if (oldInstance) {
				oldInstance.shutdown();
				this.brickRegistry.unregisterBrick(oldInstance);
			}
			if (newInstance) {
				this.brickRegistry.registerBrick(newInstance);
			}
		}
	}

	shutdown() {
		if (this.unregisterFromRegistry !== null) {
			this.unregisterFromRegistry();
			this.unregisterFromRegistry = null;
		}
		this.replaceInstance(null);
	}
}

function defaultBrickFactory(BrickType) {
	return (...args) => new BrickType(...args);
}

function createBrickFactory(BrickType, brickFactory, additionalDependencies) {
	const dependencySelectors = getDependencies(BrickType)
		.map(createDependencySelector);
	const additionalSelectors = additionalDependencies
		.map(createDependencySelector);
	const allDependencySelectors = [...dependencySelectors, ...additionalSelectors];
	if (allDependencySelectors.length > 0) {
		return createMemoizedFactory(allDependencySelectors, brickFactory);
	}
	return once(brickFactory);
}

function createDependencySelector(DependencyType) {
	return registry => registry.getOnlyBrickOfType(DependencyType);
}

function createMemoizedFactory(dependencySelectors, brickFactory) {
	const memoizedFactory = memoizeLast(brickFactory);
	return registry => {
		let newInstance = null;
		const selectedBricks = dependencySelectors.map(selector => selector(registry));
		if (selectedBricks.every(dependency => Boolean(dependency))) {
			newInstance = memoizedFactory(...selectedBricks);
		}
		return newInstance;
	};
}
