import React from 'react';
import MUICardActions from '@mui/material/CardActions';

export default class CardActions extends React.PureComponent {
	render() {
		const {children} = this.props;
		return (
			<MUICardActions>
				{children}
			</MUICardActions>
		);
	}
}
