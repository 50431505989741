import React from 'react';
import PropTypes from 'prop-types';

import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import MobileAwareAutoCompleteTextField from './AutoCompleteTextField/MobileAwareAutoCompleteTextField.js';

/**
 * Renders a ComboBox like element.
 * It allows only one option to be selected.
 * The available options can be filtered by entering text into the input field.
 * The value provided by the onChange event will be one entry of the options array not the displayed text.
 * The passed getOptionSelected function must match the provided value against one option.
 * If you want an empty option to be available for selection - provide one that matches against your empty value.
 *
 * @author p.spitzlinger@synedra.com
 */
export default withForwardRef(SuggestComboBox, 'forwardedRef');

function SuggestComboBox(props) {
	const {
		forwardedRef, id, options, onChange, value, disabled, label, error, hint, getOptionLabel, groupBy,
		getOptionSelected, getSelectedValue, getValue, name, title, small, className, onBlur, disableClearable,
		noFullWidth, noMargin, inputTextField, fullScreenTextFieldButtons
	} = props;
	return (
		<MobileAwareAutoCompleteTextField lockToOptions ref={forwardedRef} label={label} title={title} hint={hint}
		                                  disabled={disabled} value={value} options={options} id={id} groupBy={groupBy}
		                                  getOptionLabel={getOptionLabel} getOptionSelected={getOptionSelected}
		                                  error={error} onChange={onChange} getSelectedValue={getSelectedValue}
		                                  getValue={getValue} name={name} small={small} className={className}
		                                  onBlur={onBlur} noFullWidth={noFullWidth} disableClearable={disableClearable}
		                                  noMargin={noMargin} inputTextField={inputTextField}
		                                  fullScreenTextFieldButtons={fullScreenTextFieldButtons} />
	);
}

SuggestComboBox.propTypes = {
	forwardedRef: withForwardRef.PropTypes.Ref,
	noFullWidth: PropTypes.bool,
	noMargin: PropTypes.bool,
	disableClearable: PropTypes.bool,
	className: PropTypes.string,
	name: PropTypes.string,
	title: PropTypes.string,
	small: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])),
	getOptionLabel: PropTypes.func,
	groupBy: PropTypes.func,
	getOptionSelected: PropTypes.func,
	getSelectedValue: PropTypes.func,
	getValue: PropTypes.func,
	onChange: PropTypes.func,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	disabled: PropTypes.bool,
	label: PropTypes.string,
	error: PropTypes.bool,
	hint: PropTypes.string,
	id: PropTypes.string,
	onBlur: PropTypes.func,
	inputTextField: PropTypes.elementType,
	fullScreenTextFieldButtons: PropTypes.node
};
