import {connect} from 'react-redux';

import {preventEventDefault, stopEventPropagation} from '../../../commons/utils/DOMEventUtils.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import DeleteAnnotationIcon from '../../components/annotations/DeleteAnnotationIcon.js';
import {removeAnnotation} from '../actions/AnnotationActions.js';

export default connect(
	undefined,
	bindActions({
		removeAnnotation
	}),
	function mergeProps(stateProps, dispatchProps, ownProps) {
		return {
			...ownProps, onDeleteAnnotation(e) {
				preventEventDefault(e);
				stopEventPropagation(e);
				dispatchProps.removeAnnotation(ownProps.annotationId);
			}
		};
	}
)(DeleteAnnotationIcon);
