import {createSingleFieldValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';

export function requiredValidatorFunction(locale, translatedFieldLabel, fieldValue) {
	return Boolean(fieldValue) ||
		ValidatorsMessagesTranslator.getFormattedMessage('Required', locale,
			{inputFieldLabel: translatedFieldLabel});
}

export default createSingleFieldValidatorFactory(requiredValidatorFunction);
