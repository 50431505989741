import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import MaterialDeviceSizes from '../../../constants/MaterialDeviceSizes.json';
import {
	DEVICE_SIZE_EXTRA_LARGE,
	DEVICE_SIZE_EXTRA_SMALL,
	DEVICE_SIZE_LARGE,
	DEVICE_SIZE_MEDIUM,
	DEVICE_SIZE_SMALL
} from '../../commons/constants/DeviceInformation.js';
import {useMemoFactory} from '../../commons/utils/customHooks';
import Card from '../../material-design/components/Card.js';

const CARD_PADDING = 24;
const DOWN_TO = true;
const UP_TO = false;
const ALLOWED_BREAKPOINTS = [
	DEVICE_SIZE_EXTRA_SMALL,
	DEVICE_SIZE_SMALL,
	DEVICE_SIZE_MEDIUM,
	DEVICE_SIZE_LARGE,
	DEVICE_SIZE_EXTRA_LARGE
];

// TODO::PSp: Use DeviceMediaQuery Constants for Media query strings
export default function ResponsiveCard(props) {
	const {
		children, classNameRoot, className, style, breakPoint, cardComponent, centered, growHorizontally,
		minCardWidth, maxCardWidth, growVertically, sx, wrapperElement, ...remainingProps
	} = props;
	const rootSxProps = useMemoFactory(getRootSxProps, centered, growHorizontally, breakPoint);
	const cardSxProps = useMemoFactory(getCardSxProps, sx, minCardWidth, maxCardWidth, growVertically, breakPoint);
	return (
		<Box component={wrapperElement} className={classNameRoot} sx={rootSxProps}>
			<Box component={cardComponent} className={className} sx={cardSxProps} style={style} {...remainingProps}>
				{children}
			</Box>
		</Box>
	);
}

ResponsiveCard.propTypes = {
	cardComponent: PropTypes.elementType,
	wrapperElement: PropTypes.elementType,
	growVertically: PropTypes.bool,
	growHorizontally: PropTypes.bool,
	centered: PropTypes.bool,
	className: PropTypes.string,
	classNameRoot: PropTypes.string,
	maxCardWidth: PropTypes.string,
	minCardWidth: PropTypes.string,
	style: PropTypes.object,
	breakPoint: PropTypes.oneOf(ALLOWED_BREAKPOINTS),
	...Box.propTypes
};

ResponsiveCard.defaultProps = {
	centered: false,
	growVertically: false,
	breakPoint: DEVICE_SIZE_SMALL,
	cardComponent: Card
};

function getRootSxProps(centered, growHorizontally, breakPoint) {
	return {
		display: 'flex',
		flexDirection: 'column',
		boxSizing: 'border-box',
		flexGrow: 1,
		alignItems: calcContainerAlign(centered, growHorizontally),
		justifyContent: centered ? 'center' : 'space-between',
		padding: `${CARD_PADDING}px`,
		[getMediaQuery(breakPoint, UP_TO)]: {
			padding: 0,
			width: '100%',
			alignItems: calcSmallContainerAlign(centered, growHorizontally)
		}
	};
}

function getCardSxProps(sx, minCardWidth, maxCardWidth, growVertically, breakPoint) {
	return {
		maxWidth: 'none',
		minWidth: 'inherit',
		[getMediaQuery(breakPoint, DOWN_TO)]: {
			maxWidth: maxCardWidth,
			minWidth: minCardWidth,
			flexGrow: growVertically ? 1 : 0
		},
		[getMediaQuery(breakPoint, UP_TO)]: {
			boxShadow: 'none',
			borderRadius: 0,
			flexGrow: 1
		},
		...sx
	};
}

function getMediaQuery(breakPoint, downTo) {
	const selectedBreakPoint = downTo
		? MaterialDeviceSizes[MaterialDeviceSizes[breakPoint].next]['min-width']
		: MaterialDeviceSizes[breakPoint]['max-width'];
	return `@media screen and (${downTo ? 'min' : 'max'}-width: ${selectedBreakPoint}px)`;
}

function calcSmallContainerAlign(centered, growHorizontally) {
	const shouldStretch = !centered || growHorizontally !== false;
	if (shouldStretch) {
		return 'stretch';
	}
	return calcContainerAlign(centered, growHorizontally);
}

function calcContainerAlign(centered, growHorizontally) {
	let containerAlign = 'flex-start';
	if (centered) {
		containerAlign = 'center';
	} else if (growHorizontally !== false) {
		containerAlign = 'stretch';
	}
	return containerAlign;
}
