import React from 'react';
import {Provider} from 'react-redux';
import PropTypes from 'prop-types';

import BrickRegistryProvider from '../../bricks/components/BrickRegistryProvider.js';
import LocalizationContextWrapper from '../../i18n/components/LocalizationContextWrapper.js';
import ThemeProvider from '../../ui/components/theming/ThemeProvider.js';
import ApplicationErrorBoundary from '../components/ApplicationErrorBoundary.js';
import JustChildren from '../components/JustChildren.js';
import CurrentRouteRenderer from './CurrentRouteContainer.js';
import PersistentPortalsPlaceholderContainer from './PersistentPortalsPlaceholderContainer.js';

export default function MainApplicationContainer(props) {
	const {flux, appContextComponent: AppContextComponent} = props;
	return (
		<Provider store={flux}>
			<ApplicationErrorBoundary>
				<BrickRegistryProvider>
					<LocalizationContextWrapper>
						<ThemeProvider>
							<AppContextComponent>
								<CurrentRouteRenderer />
								<PersistentPortalsPlaceholderContainer />
							</AppContextComponent>
						</ThemeProvider>
					</LocalizationContextWrapper>
				</BrickRegistryProvider>
			</ApplicationErrorBoundary>
		</Provider>
	);
}

MainApplicationContainer.propTypes = {
	flux: PropTypes.object.isRequired,
	appContextComponent: PropTypes.elementType
};

MainApplicationContainer.defaultProps = {
	appContextComponent: JustChildren
};
