import React from 'react';

import IconButton from '../../ui/components/IconButton.js';
import EyeIcon from '../../ui/components/icons/EyeIcon.js';
import {COLOR_PRIMARY} from '../../ui/constants/SynMUIOptions.js';

import '../../../styles/patient-details/components/ShowViewerButton.scss';

function ShowViewerButton(props) {
	return <IconButton color={COLOR_PRIMARY} className='patient-details-show-viewer-button' icon={<EyeIcon />} {...props} />;
}

export default ShowViewerButton;
