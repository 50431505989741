import React from 'react';
import PropTypes from 'prop-types';

import HorizontalLayout from '../layout/HorizontalLayout.js';

const ROW_HOVER_STYLES = {
	'.material-data-table .table-body div&:hover': {
		backgroundColor: 'background.paper'
	}
};

export default function ListRow(props) {
	const {style, children} = props;
	const finalStyle = {
		...style,
		maxHeight: 'None'
	};
	return (
		<HorizontalLayout style={finalStyle} justify='start' className='table-row' sx={ROW_HOVER_STYLES}>
			{children}
		</HorizontalLayout>
	);
}

ListRow.propTypes = {
	style: PropTypes.object
};
