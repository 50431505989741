import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import WebViewMessagesTranslator from '../../../i18n/translators/WebViewMessagesTranslator.js';
import SimpleDialog from '../../../material-design/components/SimpleDialog.js';
import FullScreenContainer from '../FullScreenContainer.js';
import TemplateIFrame from './TemplateIFrame.js';

import '../../../../styles/commons/components/webview/PrivacyPolicyDialog.scss';

const PRIVACY_POLICY_TITLE = <SynFormattedMessage translator={WebViewMessagesTranslator} message='PrivacyPolicyTitle' />;

export default class PrivacyPolicyDialog extends React.PureComponent {
	render() {
		const {uiShowPrivacyPolicyDialog, hidePrivacyPolicyDialog} = this.props;
		return (
			<FullScreenContainer visible={uiShowPrivacyPolicyDialog}>
				<SimpleDialog className='privacy-policy-dialog' onClose={hidePrivacyPolicyDialog} title={PRIVACY_POLICY_TITLE}>
					<TemplateIFrame templateName='privacypolicy' />
				</SimpleDialog>
			</FullScreenContainer>
		);
	}
}

PrivacyPolicyDialog.propTypes = {
	uiShowPrivacyPolicyDialog: PropTypes.bool,
	hidePrivacyPolicyDialog: PropTypes.func	
};
