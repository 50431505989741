import Immutable from 'immutable';

const DicomSeriesImageIndexTemplate = Immutable.Record({
	entries: Immutable.List(),
	nrFrames: 0
}, 'DicomSeriesImageIndex');

const imageIndexEntry = Immutable.Record({
	id: null,
	numberOfFrames: null,
	firstFrameInSeries: null
}, 'imageIndexEntry');


class DicomSeriesImageIndex extends DicomSeriesImageIndexTemplate {
	addEntry(imageId, nrFrames) {
		return this
			.set('entries', this.entries.push(imageIndexEntry({id: imageId,	numberOfFrames: nrFrames, firstFrameInSeries: this.nrFrames})))
			.set('nrFrames', this.nrFrames + nrFrames);
	}

	getEntry(index) {
		return this.hasImage(index)
			? this.entries.get(index)
			: null;
	}

	hasFrame(frameIndex) {
		return frameIndex >= 0 && frameIndex < this.numberOfFrames;
	}

	hasImage(imageIndex) {
		return imageIndex >= 0 && imageIndex < this.numberOfImages;
	}

	frameIndexForImage(imageIndex) {
		return this.hasImage(imageIndex)
			? this.getEntry(imageIndex).firstFrameInSeries
			: -1;
	}

	imageIndexForFrame(frameIndex) {
		return this.entries.findIndex(entry => frameIndex >= entry.firstFrameInSeries &&
			frameIndex < entry.firstFrameInSeries + entry.numberOfFrames
		);
	}

	reduce(reducer, accumulator) {
		return this.entries.reduce(reducer, accumulator);
	}

	get numberOfFrames() { return this.nrFrames; }
	get numberOfImages() { return this.entries.size; }
}

export default DicomSeriesImageIndex;
