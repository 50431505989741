import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {useEffectEasily} from '../../commons/utils/customHooks/index.js';
import useBrickRegistry from '../hooks/useBrickRegistry.js';

//TODO PSp :: Refactor to use useBrick()
function BrickGuard(props) {
	const {brick, children} = props;
	const brickRegistry = useBrickRegistry();
	const [requiredBricksAvailable, setRequiredBricksAvailable] = useState(() => hasBrick(brickRegistry, brick));
	useEffectEasily(watchRegistry, setRequiredBricksAvailable, brickRegistry, brick);
	return requiredBricksAvailable ? children : false;
}

function watchRegistry(setDependenciesAvailable, registry, brick) {
	setDependenciesAvailable(hasBrick(registry, brick));
	return registry.subscribe(() => {
		setDependenciesAvailable(hasBrick(registry, brick));
	});
}

function hasBrick(registry, brick) {
	return registry.getAllBricksOfType(brick).length > 0;
}

BrickGuard.propTypes = {
	brick: PropTypes.func
};

export default React.memo(BrickGuard);
