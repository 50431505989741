import React from 'react';
import PropTypes from 'prop-types';

import {withForwardRef} from '../../commons/utils/ReactUtils.js';

export default withForwardRef(A11yGrid, 'forwardedRef');
function A11yGrid(props) {
	const {forwardedRef, rowCount, element: Element, ...remainingProps} = props;
	return <Element {...remainingProps} aria-rowcount={rowCount} ref={forwardedRef} role='grid' />;
}

A11yGrid.propTypes = {
	element: PropTypes.elementType,
	rowCount: PropTypes.number,
	forwardedRef: withForwardRef.PropTypes.Ref
};

A11yGrid.defaultProps = {
	element: 'div'
};
