import React from 'react';
import {glMatrix} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import AnnotationsTranslator from '../../../i18n/translators/AnnotationsTranslator.js';
import {PHYSICAL_UNIT_TEXT} from '../../constants/DicomEnumerations.js';
import DicomImage from '../../data/DicomImage.js';
import {
	calcLabelOffset,
	calculateLabelProperties,
	findFirstContainingRegion,
	toContainerPosition
} from '../../utils/AnnotationUtils.js';
import {containsPoint2d, createRectangle, resizeRect} from '../../utils/math/Rectangle.js';
import {renderMultilineTextElements} from '../../utils/SVGUtils.js';
import {isDopplerRegion} from '../../utils/UltrasoundRegionUtils.js';
import {getDicomDump} from '../../utils/ViewerItemUtils.js';
import AnnotationLabel from './AnnotationLabel.js';
import createAnnotation from './createAnnotation.js';
import ModifiablePath from './ModifiablePath.js';
import SupportPath from './SupportPath.js';

const SECOND_IN_MILLISECONDS = 1000.0;
const PROPORTIONAL_LINE_HEIGHT_QUOTIENT = 4;

export default createAnnotation(isUSMeasurementSupported, getDefaultPropertiesForUSMeasurement, USMeasurement);

function isUSMeasurementSupported() {
	return false;
}

function getDefaultPropertiesForUSMeasurement() {
	return Immutable.Map({});
}

function USMeasurement(props) {
	const {
		AnnotationRoot, isPrintPreview, fontSize, readOnly, containerWidth, containerHeight, transformationMatrix,
		inverseTransformationMatrix, annotationProperties, viewerItem
	} = props;
	const {containingRegion} = useMemoFactory(
		calcBaseParams, annotationProperties, containerWidth, containerHeight, transformationMatrix,
		inverseTransformationMatrix, isPrintPreview, readOnly, viewerItem, fontSize
	);
	const USMeasurementComponent = isDopplerRegion(containingRegion) ? DopplerRegionMeasurement : NonDopplerMeasurement;
	return (
		<AnnotationRoot>
			<USMeasurementComponent {...props} />
		</AnnotationRoot>
	);
}

USMeasurement.propTypes = {
	AnnotationRoot: PropTypes.elementType,
	annotationProperties: immutableMapPropType,
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	inverseTransformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	isPrintPreview: PropTypes.bool,
	viewerItem: PropTypes.instanceOf(DicomImage),
	lineHeight: PropTypes.number,
	fontSize: PropTypes.number,
	readOnly: PropTypes.bool,
	containerWidth: PropTypes.number,
	containerHeight: PropTypes.number,
	locale: PropTypes.string
};

function DopplerRegionMeasurement(props) {
	const {
		isPrintPreview, lineHeight, fontSize, readOnly, containerWidth, containerHeight, transformationMatrix,
		inverseTransformationMatrix, annotationProperties, viewerItem
	} = props;
	const {
		usMeasurementLine, measuredTimeText, containingRegion, containerPoints, viewportRect, sortedPoints,
		isVelocityMeasurementPossible, referencePixel, referencePixelPhysicalValues, labelOffset, containerSupportPoints
	} = useMemoFactory(
		calcBaseParams, annotationProperties, containerWidth, containerHeight, transformationMatrix,
		inverseTransformationMatrix, isPrintPreview, readOnly, viewerItem, fontSize
	);
	let measuredCMpsText = null;
	let maxMeasuredTextSize = 0;
	if (isVelocityMeasurementPossible) {
		const textSizeReduction = 0.65;
		const measuredCMpS = sortedPoints.map(point => {
			const pixelValue = Math.round(referencePixel[1] - point[1]);
			return Math.round(
				referencePixelPhysicalValues[1] + pixelValue * containingRegion.getPhysicalDelta()[1]
			);
		});
		measuredCMpsText = measuredCMpS.map(
			centimetersPerSecond => (
				`${centimetersPerSecond} ${PHYSICAL_UNIT_TEXT[containingRegion.getPhysicalUnits()[1]]}`
			)
		);
		maxMeasuredTextSize = measuredCMpsText
			.reduce((max, text) => Math.max(max, text.length), 0) * fontSize * textSizeReduction;
	}
	const {horizontalLabelOffset, verticalLabelOffset, horizontalTextAnchor, selectedSupportPoint} =
		calcRenderParameters(
			labelOffset, lineHeight, containerSupportPoints, containerPoints, viewportRect, maxMeasuredTextSize
		);
	const [selectedSupportPointX, selectedSupportPointY] = selectedSupportPoint;
	const durationTextPosX =
		containerSupportPoints[1][0] + (containerSupportPoints[0][0] - containerSupportPoints[1][0]) / 2;
	let velocityLabels = [];
	if (isVelocityMeasurementPossible) {
		velocityLabels = renderVelocityLabels(
			measuredCMpsText, containerSupportPoints, horizontalTextAnchor,
			selectedSupportPointX + horizontalLabelOffset, lineHeight
		);
	}
	const containerSupportPathPoints = [containerPoints[0], selectedSupportPoint, containerPoints[1]];
	return (
		<React.Fragment>
			<SupportPath points={containerSupportPathPoints} />
			{usMeasurementLine}
			<AnnotationLabel textAnchor='middle' x={durationTextPosX} y={selectedSupportPointY + verticalLabelOffset}>
				{renderMultilineTextElements(measuredTimeText, lineHeight)}
			</AnnotationLabel>
			{velocityLabels}
		</React.Fragment>
	);
}
DopplerRegionMeasurement.propTypes = USMeasurement.propTypes;

function NonDopplerMeasurement(props) {
	const {
		annotationProperties, transformationMatrix, inverseTransformationMatrix, isPrintPreview, lineHeight, fontSize,
		readOnly, containerWidth, containerHeight, locale, viewerItem
	} = props;
	const {usMeasurementLine, containerPoints, viewportRect} = useMemoFactory(
		calcBaseParams, annotationProperties, containerWidth, containerHeight, transformationMatrix,
		inverseTransformationMatrix, isPrintPreview, readOnly, viewerItem, fontSize
	);
	return (
		<React.Fragment>
			{usMeasurementLine}
			{renderMeasurementNotPossibleLabel(
				containerPoints, viewportRect, lineHeight, fontSize, locale, isPrintPreview || readOnly
			)}
		</React.Fragment>
	);
}
NonDopplerMeasurement.propTypes = USMeasurement.propTypes;

function calcBaseParams(
		annotationProperties, containerWidth, containerHeight, transformationMatrix, inverseTransformationMatrix,
		isPrintPreview, readOnly, viewerItem, fontSize
) {
	const viewportRect = createRectangle(
		[-containerWidth / 2, -containerHeight / 2],
		[containerWidth / 2, containerHeight / 2]
	);
	const points = annotationProperties.get('points');
	const usMeasurementLine = createUsMeasurementLine(
		isPrintPreview, points, inverseTransformationMatrix, transformationMatrix, readOnly
	);
	const sortedPoints = sortPointsByY(points);
	const containerPoints = sortedPoints.map(toContainerPosition.bind(undefined, {transformationMatrix}));
	const containingRegion = findFirstContainingRegion(getDicomDump(viewerItem), points, isDopplerRegion);
	let result = {
		viewportRect,
		containerPoints,
		usMeasurementLine,
		containingRegion
	};
	if (isDopplerRegion(containingRegion)) {
		const containerSupportPoints = calcSupportPoints(containerPoints);
		const labelOffset = calcLabelOffset(fontSize, isPrintPreview || readOnly);
		const referencePixel = containingRegion.getImageReferencePixel();
		const referencePixelPhysicalValues = containingRegion.getReferencePixelPhysicalValues();
		const isVelocityMeasurementPossible = referencePixel !== null && referencePixelPhysicalValues !== null;
		const timeDeltaPixels = Math.round(Math.abs(points[0][0] - points[1][0]));
		const measuredTimeMS = Math.round(
			timeDeltaPixels * containingRegion.getPhysicalDelta()[0] * SECOND_IN_MILLISECONDS
		);
		const measuredTimeText = `${measuredTimeMS} m${PHYSICAL_UNIT_TEXT[containingRegion.getPhysicalUnits()[0]]}`;
		result = {
			...result,
			points,
			sortedPoints,
			containerSupportPoints,
			labelOffset,
			isVelocityMeasurementPossible,
			referencePixel,
			referencePixelPhysicalValues,
			measuredTimeText
		};
	}
	return result;
}

function createUsMeasurementLine(isPrintPreview, points, inverseTransformationMatrix, transformationMatrix, readOnly) {
	return (
		<ModifiablePath isPrintPreview={isPrintPreview} points={points} transformationMatrix={transformationMatrix}
		                inverseTransformationMatrix={inverseTransformationMatrix} readOnly={readOnly} />
	);
}

function sortPointsByY(points) {
	return points.sort((a, b) => a[1] - b[1]);
}

function calcSupportPoints(sortedPoints) {
	return [
		Float32Array.from([
			sortedPoints[1][0],
			sortedPoints[0][1]
		]),
		Float32Array.from([
			sortedPoints[0][0],
			sortedPoints[1][1]
		])
	];
}

function renderVelocityLabels(measuredCMpsText, containerSupportPoints, textAnchor, positionX, lineHeight) {
	return measuredCMpsText.map((text, index) => (
		<AnnotationLabel key={`key_${text}`} textAnchor={textAnchor} x={positionX}
		                 y={containerSupportPoints[index][1]}>
			{renderMultilineTextElements(text, lineHeight)}
		</AnnotationLabel>
	));
}

function renderMeasurementNotPossibleLabel(
		containerPoints, viewportRect, lineHeight, fontSize, locale, isPrintPreview
) {
	const labelText = AnnotationsTranslator.getFormattedMessage('MeasurementNotPossible', locale);
	const labelProperties = calculateLabelProperties(containerPoints, viewportRect, fontSize, isPrintPreview);
	return (
		<AnnotationLabel {...labelProperties}>
			{renderMultilineTextElements(labelText, lineHeight)}
		</AnnotationLabel>
	);
}

function calcRenderParameters(
		labelOffset, lineHeight, [selectedSupportPointA, selectedSupportPointB], containerPoints, viewportRect,
		maxHorizontalTextSize
) {
	let selectedSupportPoint = selectedSupportPointB;
	let verticalLabelOffset = labelOffset + calcProportionalOffsetOf(lineHeight);
	let isEasternSupportPoint = selectedSupportPoint[0] === Math.max(containerPoints[0][0], containerPoints[1][0]);
	const horizontalDelta = isEasternSupportPoint ? -maxHorizontalTextSize : maxHorizontalTextSize;
	const verticalDelta = -(verticalLabelOffset + lineHeight / 2);

	const calculatedViewportRect = resizeRect(
		viewportRect, horizontalDelta, verticalDelta, horizontalDelta, verticalDelta
	);

	if (!containsPoint2d(calculatedViewportRect, selectedSupportPoint)) {
		selectedSupportPoint = selectedSupportPointA;
		verticalLabelOffset = -verticalLabelOffset;
		isEasternSupportPoint = !isEasternSupportPoint;
	}
	const horizontalLabelOffset = isEasternSupportPoint ? labelOffset : -labelOffset;
	const horizontalTextAnchor = isEasternSupportPoint ? 'start' : 'end';
	return {horizontalLabelOffset, verticalLabelOffset, horizontalTextAnchor, selectedSupportPoint};
}

function calcProportionalOffsetOf(lineHeight) {
	return lineHeight / PROPORTIONAL_LINE_HEIGHT_QUOTIENT;
}
