import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';

import EnabledPointSyncToolMenuEntry from '../../components/EnablePointSyncToolMenuEntry.js';
import DicomImage from '../../data/DicomImage.js';
import {deactivateOffsetPaging} from '../../synchronization/tools/offset-page-synchronization/flux/OffsetPageSyncActions.js';
import {addAnnotation, removeAnnotation} from '../actions/AnnotationActions.js';
import {selectIsPointSyncActive, selectSyncPointAnnotationId} from '../selectors/DicomPointSyncSelectors.js';
import selectWithArgs from '../selectors/selectWithArgs.js';
import {selectIsSingleViewerLayout} from '../selectors/ViewerSelectors.js';

export default function EnablePointSyncToolMenuEntryContainer({dicomImage}) {
	const {frameOfReferenceUID, supportsPointSynchronization} = dicomImage;

	const dispatch = useDispatch();
	const stateSelector = useMemo(() => createStateSelector(), []);
	const {annotationId, isPointSyncActive, isSingleViewerLayout} =
		useSelector(selectWithArgs(stateSelector, frameOfReferenceUID));

	const onActivate = useCallback(() => {
		dispatch(deactivateOffsetPaging());
		dispatch(addAnnotation('frameOfReference', frameOfReferenceUID, 'syncpoint'));
	},
	[dispatch, frameOfReferenceUID]
	);

	const onDeactivate = useCallback(() => dispatch(removeAnnotation(annotationId)), [annotationId, dispatch]);

	return !isSingleViewerLayout &&
		supportsPointSynchronization &&
		<EnabledPointSyncToolMenuEntry isActive={isPointSyncActive}
										  onActivate={onActivate}
										  onDeactivate={onDeactivate} />;
}

EnablePointSyncToolMenuEntryContainer.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage)
};

function createStateSelector() {
	return createStructuredSelector({
		isSingleViewerLayout: selectIsSingleViewerLayout,
		isPointSyncActive: selectIsPointSyncActive,
		annotationId: selectSyncPointAnnotationId
	});
}
