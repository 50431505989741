import React from 'react';
import {glMatrix, vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {callSafe} from '../../../commons/utils/FunctionUtils.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import {renderPathString} from '../../utils/SVGUtils.js';
import {getNormalizedDirection} from '../../utils/VectorUtils.js';
import AnnotationHandle from './AnnotationHandle.js';
import AnnotationPathTouchArea from './AnnotationPathTouchArea.js';
import MarkerDot from './MarkerDot.js';
import MoveableSVGGroup from './MoveableSVGGroup.js';

import '../../../../styles/viewer/components/annotations/ModifiablePath.scss';

export default class ModifiablePath extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnPathMove = this.onPathMove.bind(this);
	}

	render() {
		const {points, isPrintPreview, transformationMatrix, touchArea: TouchArea, readOnly, closed} = this.props;
		const remainingProps = cloneWithoutProperties(
			this.props, 'points', 'Container', 'markerEnd', 'markerStart', 'isPrintPreview',
			'inverseTransformationMatrix', 'transformationMatrix', 'onPointsUpdate', 'touchArea', 'marker', 'closed'
		);

		const containerPoints = points.map(
			point => vec2.transformMat3(vec2.create(), point, transformationMatrix)
		);
		const pathString = renderPathString(containerPoints, closed);
		const pathRepresentation = pathString
			? <path className='modifiable-path--line' d={pathString} />
			: false;

		const touchArea = !isPrintPreview && (<TouchArea points={containerPoints} />);
		const contour = isPrintPreview && <path className='modifiable-path--contour-line' d={pathString} />;

		return (
			<MoveableSVGGroup {...remainingProps} onMove={this.boundOnPathMove} readOnly={readOnly}>
				{!readOnly && touchArea}
				{contour}
				{this.renderHandles()}
				{pathRepresentation}
			</MoveableSVGGroup>
		);
	}

	renderHandles() {
		const {points, readOnly, marker = MarkerDot, markerStart = marker, markerEnd = marker} = this.props;
		let handles = [];
		if (readOnly) {
			if (points.length > 1) {
				if (markerStart !== marker) {
					handles.push(this.renderHandle(0, markerStart));
				}
				if (markerEnd !== marker) {
					handles.push(this.renderHandle(points.length - 1, markerEnd));
				}
			}
		} else {
			handles = points.map(
				(point, index) => this.renderHandle(index, getMarker({points, marker, markerStart, markerEnd}, index))
			);
		}
		return handles;
	}

	renderHandle(index, marker) {
		const {points, readOnly, isPrintPreview, transformationMatrix} = this.props;
		const handleProps = {
			marker,
			isPrintPreview,
			transformationMatrix,
			readOnly,
			handleOrientation: getMarkerOrientation(points, index),
			point: points[index],
			key: index,
			onMove: diff => this.onPointMove(index, diff)
		};
		return <AnnotationHandle {...handleProps} />;
	}

	onPathMove(diff) {
		const {points, onPointsUpdate, onPathMove} = this.props;
		if (onPathMove) {
			const {inverseTransformationMatrix, transformationMatrix} = this.props;
			const center = vec2.transformMat3(vec2.create(), vec2.fromValues(0.0, 0.0), transformationMatrix);
			vec2.add(center, center, diff);
			onPathMove(vec2.transformMat3(center, center, inverseTransformationMatrix));
		} else {
			const newPoints = points.map(point => this.movePoint(point, diff));
			callSafe(onPointsUpdate, newPoints);
		}
	}

	onPointMove(pointIndex, diff) {
		const {points, onPointsUpdate} = this.props;
		const newPoints = [].concat(points);
		newPoints[pointIndex] = this.movePoint(points[pointIndex], diff);
		onPointsUpdate(newPoints);
	}

	movePoint(point, diffVector) {
		const {transformationMatrix, inverseTransformationMatrix} = this.props;
		const newPoint = vec2.transformMat3(vec2.create(), point, transformationMatrix);
		vec2.add(newPoint, newPoint, diffVector);
		return vec2.transformMat3(newPoint, newPoint, inverseTransformationMatrix);
	}
}

ModifiablePath.propTypes = {
	points: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.instanceOf(Float32Array),
		PropTypes.instanceOf(Float64Array)
	])),
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE).isRequired,
	inverseTransformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE).isRequired,
	isPrintPreview: PropTypes.bool,
	readOnly: PropTypes.bool,
	closed: PropTypes.bool,
	touchArea: PropTypes.elementType,
	marker: PropTypes.elementType,
	markerStart: PropTypes.elementType,
	markerEnd: PropTypes.elementType,
	onPointsUpdate: PropTypes.func,
	onPathMove: PropTypes.func
};

ModifiablePath.defaultProps = {
	marker: MarkerDot,
	touchArea: AnnotationPathTouchArea,
	readOnly: false
};

function getMarkerOrientation(points, pointIndex) {
	let orientation;
	if (points.length > 1) {
		const point = points[pointIndex];
		orientation = pointIndex === 0
			? getNormalizedDirection(points[pointIndex + 1], point)
			: getNormalizedDirection(points[pointIndex - 1], point);
	}
	return orientation;
}

function getMarker({marker, markerStart, markerEnd, points}, pointIndex) {
	const onlyOnePoint = points.length === 1;
	const isNotStartOrEndPoint = pointIndex > 0 && pointIndex < (points.length - 1);
	const isFirstPoint = pointIndex === 0;
	if (onlyOnePoint || isNotStartOrEndPoint) {
		return marker;
	}
	return isFirstPoint ? markerStart : markerEnd;
}
