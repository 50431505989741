import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import DeterminateLinearProgressBar from '../../webview/ui/components/DeterminateLinearProgressBar.js';
import UploadController from '../bricks/UploadController.js';

export default function UploadProgressBar(/*props*/) {
	const progress = useBrick(UploadController, brick => brick.getUploadedPercent());
	return <DeterminateLinearProgressBar value={progress} />;
}
