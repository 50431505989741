import {fromJS} from 'immutable';

import get from '../../commons/api/operations/get.js';

const PATIENT_DETAILS_BASE_URL = '/patient';

export function getPatientDetails(queryParams) {
	const {patient_aimid: patientAimId, onlyShares = false, displayFormat, ...remainingQueryParams} = queryParams;
	const patientDetailsURL = getPatientDetailsURL(patientAimId);
	return get(patientDetailsURL, {
		onlyShares: onlyShares === true ? 1 : 0,
		display_format: displayFormat,
		...remainingQueryParams
	}).then(fromJS);
}

function getPatientDetailsURL(patientID) {
	const patientIdFragment = patientID ? `/${patientID}` : '';
	return `${PATIENT_DETAILS_BASE_URL}${patientIdFragment}/details`;
}
