import Immutable from 'immutable';

/**
 *
 */
/**
 * @author r.binna@synedra.com
 */
const PARAMETER_REGEX = new RegExp(/:\??([0-9A-Za-z_]*)/g);

/**
 *
 * @param {String} urlTemplate The urlPattern which corresponds to this route. Variables contained in the urlPattern are
 * prefixed with a colon and match till the next slash the query string or the end of the url
 * @param parameterMatchers {Immutable.Map} An optional map which defines a regular expressions, which is used to match
 * a given variable.
 *	If no regex is provided for a variable, the default regular expression [%a-zA-Z0-9\-\\_\\s,]+ is used
 *
 *		parameterMatchers: {
 *			'fileName': '[0-9a-zA-Z\.]+'
 *
 */
export default function createRouteMatcher(urlTemplate, parameterMatchers = Immutable.Map()) {
	const variableDefinitions = extractVariableDefinitions(urlTemplate);
	const routeRegex = extractRouteRegex(urlTemplate, variableDefinitions, parameterMatchers);

	return function routeMatcher(url) {
		return recognize(url, variableDefinitions, routeRegex);
	};
}

/**
 * Extracts all variable definitions from an url
 * A Variable definition is defined as:
 * {
 *    name: VARIABLE_NAME, // The name of the variable
 *    optional: BOOLEAN    // Whether the variable might be missing
 * }
 *
 * E.g. From the url patient/:id the array with the following variables names will be extracted [ ':id' ],
 */
function extractVariableDefinitions(urlTemplate) {
	const variableNamesWithModifiers = urlTemplate.match(PARAMETER_REGEX) || [];
	return variableNamesWithModifiers.map(variableNameWithModifiers => {
		const optional = variableNameWithModifiers[1] === '?';
		const nameStartIndex = optional ? 2 : 1;
		return {
			name: variableNameWithModifiers.substring(nameStartIndex),
			optional
		};
	});
}

const DEFAULT_VARIABLE_REGEX = '[%a-zA-Z0-9\\-\\_\\s\\.,;]+';

/**
 * Creates the regex which is used to match url parameters
 * @return {RegExp} The corresponding regex
 */
function extractRouteRegex(urlTemplate, variableDefinitions, parameterMatchers) {
	const urlPattern = variableDefinitions.reduce((previousPattern, variableDefinition) => {
		const {name: variableName, optional} = variableDefinition;
		const variableRegex = `(${parameterMatchers.get(variableName, DEFAULT_VARIABLE_REGEX)}${optional ? ')?' : ')'}`;
		return previousPattern.replace(new RegExp(`:\\??${variableName}`), variableRegex);
	}, urlTemplate);
	return new RegExp(`^${urlPattern}$`);
}

/**
 * Checks whether this route is responsible for the given url.
 * If this is the case a {@link React.Component} will be returned
 *
 * @returns {Immutable.Map} the matched url variables if the route matches or null if no variables match
 */
function recognize(url, variableDefinitions, routeRegex) {
	const matches = url.match(routeRegex);
	if (matches !== null) {
		matches.shift();
		return matches.reduce((urlVariables, match, i) => {
			const decodedMatch = match === undefined ? null : decodeURIComponent(match);
			return urlVariables.set(variableDefinitions[i].name, decodedMatch);
		}, Immutable.Map());
	}
	return null;
}


