import {setNewPassword} from '../api/PasswordApi.js';
import {SET_NEW_PASSWORD} from '../constants/PasswordActionTypes.js';
import passwordOperationSaga from './passwordOperationSaga.js';

function setNewPasswordOperation(actionPayload) {
	const {username, newPassword, newPasswordConfirmation, changePasswordToken, secondFactorToken} = actionPayload;
	return setNewPassword(username, newPassword, newPasswordConfirmation, changePasswordToken, secondFactorToken);
}

export default function* setNewPasswordSaga() {
	yield* passwordOperationSaga(SET_NEW_PASSWORD, setNewPasswordOperation);
}
