import {
	SET_RESOURCE_RESPONSE,
	START_RESOURCE_LOAD,
	UPDATE_RESOURCE_RESPONSE
} from '../../constants/synCrud/ActionTypes.js';
import {createAction} from '../../utils/ActionUtils.js';

const startResourceLoad = createAction(START_RESOURCE_LOAD);

export default function createCrudResourceApiActions(resourceId, api) {
	const setResponse = createAction(
		SET_RESOURCE_RESPONSE,
		actionData => actionData.then && actionData.then(data => ({resourceId, data})) || {resourceId, actionData}
	);

	const updateResourceResponse = createAction(
		UPDATE_RESOURCE_RESPONSE, updateFunction => ({resourceId, updateFunction})
	);

	function createApiLoadAction(apiFunction) {
		return function apiLoadAction(...args) {
			return dispatch => {
				dispatch(startResourceLoad(resourceId));
				dispatch(setResponse(apiFunction.apply(api, args)));
			};
		};
	}

	return {
		search: createApiLoadAction(api.search),
		get: createApiLoadAction(api.get),
		updateResponse: updateResourceResponse
	};
}
