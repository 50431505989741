import React from 'react';
import PropTypes from 'prop-types';

import {withForwardRef} from '../../commons/utils/ReactUtils.js';
import IconButton from '../../ui/components/IconButton.js';

const FONT_SIZE = 28;
const TOOLBAR_HEIGHT = 26;

const VIEWER_TOOLBAR_BUTTON_STYLES = {
	padding: '0 8px',
	borderRadius: '3px',
	height: `${TOOLBAR_HEIGHT / FONT_SIZE}em`,
	'&:hover': {
		backgroundColor: 'rgba(153, 153, 153, 0.2)'
	}
};
function ViewerToolbarButton(props) {
	const {forwardRef, ...remainingProps} = props;
	return <IconButton ref={forwardRef} disableRipple sx={VIEWER_TOOLBAR_BUTTON_STYLES} {...remainingProps} />;
}
ViewerToolbarButton.propTypes = {
	forwardRef: withForwardRef.PropTypes.Ref,
	icon:	PropTypes.node,
	disabled: PropTypes.bool
};

const ViewerToolbarButtonWithRef = withForwardRef(ViewerToolbarButton, 'forwardRef');
export default React.memo(ViewerToolbarButtonWithRef);
