import {vec2, vec3, vec4} from 'gl-matrix';

const THREE_DIMENSIONS = 3;
const FOUR_DIMENSIONS = 4;
const DEFAULT_INTERSECT_LINES_SCALE = 1000;

export function createLine(origin, directionAsUnitVector) {
	return {
		origin,
		direction: directionAsUnitVector
	};
}

export function createLineBetween(from, to) {
	const direction = vec2.sub(vec2.create(), to, from);
	return createLine(vec2.clone(from), vec2.normalize(direction, direction));
}

export function transformLine2d(line, matrix3) {
	const transformedOrigin = vec2.transformMat3(new Float64Array(THREE_DIMENSIONS), line.origin, matrix3);

	const transformedDirection = vec3.copy(new Float64Array(THREE_DIMENSIONS), line.direction);
	transformedDirection[2] = 0;
	vec3.transformMat3(transformedDirection, transformedDirection, matrix3);
	vec2.normalize(transformedDirection, transformedDirection);

	return {
		origin: transformedOrigin,
		direction: transformedDirection
	};
}

export function transformLine3d(line, matrix4) {
	const transformedOrigin = vec3.transformMat4(new Float64Array(FOUR_DIMENSIONS), line.origin, matrix4);

	const transformedDirection = vec3.copy(new Float64Array(FOUR_DIMENSIONS), line.direction);
	transformedDirection[3] = 0;
	vec4.transformMat4(transformedDirection, transformedDirection, matrix4);
	vec3.normalize(transformedDirection, transformedDirection);

	return {
		origin: transformedOrigin,
		direction: transformedDirection
	};
}

/**
 * Calculates the intersection of the two lines according to:
 * https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection#Given_two_points_on_each_line
 * @param lineA {object} the first line
 * @param lineB {object} the second line to intersect with lineA
 * @return null if lines don't intersect, the intersection point otherwise
 */
export function intersectLines2d(lineA, lineB) {
	const fromA = lineA.origin;
	const toA = vec2.scaleAndAdd(vec2.create(), fromA, lineA.direction, DEFAULT_INTERSECT_LINES_SCALE);
	const fromB = lineB.origin;
	const toB = vec2.scaleAndAdd(vec2.create(), fromB, lineB.direction, DEFAULT_INTERSECT_LINES_SCALE);

	const xDiffA = fromA[0] - toA[0];
	const yDiffA = fromA[1] - toA[1];
	const xDiffB = fromB[0] - toB[0];
	const yDiffB = fromB[1] - toB[1];
	const divisor = xDiffA * yDiffB - yDiffA * xDiffB;
	let intersection = null;
	if (divisor !== 0) {
		const factorA = (fromA[0] * toA[1] - fromA[1] * toA[0]);
		const factorB = (fromB[0] * toB[1] - fromB[1] * toB[0]);
		intersection = vec2.fromValues(
			(factorA * xDiffB - xDiffA * factorB) / divisor,
			(factorA * yDiffB - yDiffA * factorB) / divisor
		);
	}
	return intersection;
}
