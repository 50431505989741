import Immutable from 'immutable';

import BrickBase from '../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../webview/bricks/brickTools.js';

const INITIAL_FILES_SELECTION = Immutable.Set();
export default class UploadFilesBrowserController extends BrickBase {
	constructor(initialViewMode = UploadFilesBrowserController.ViewMode.TABLE) {
		super();
		this.updateBrickState(() => ({
			viewMode: initialViewMode,
			filesSelection: INITIAL_FILES_SELECTION
		}));
	}

	#getViewMode() {
		return this.getBrickState().viewMode;
	}

	#setViewMode(newViewMode) {
		this.updateBrickState(oldState => ({
			...oldState,
			viewMode: newViewMode
		}));
	}

	setViewModeGrid() {
		this.#setViewMode(UploadFilesBrowserController.ViewMode.GRID);
	}

	setViewModeTable() {
		this.#setViewMode(UploadFilesBrowserController.ViewMode.TABLE);
	}

	isViewModeGrid() {
		return this.#getViewMode() === UploadFilesBrowserController.ViewMode.GRID;
	}

	getFilesSelection() {
		return this.getBrickState().filesSelection;
	}

	isFileSelected(id) {
		return this.getFilesSelection().has(id);
	}

	toggleFilesSelection(id) {
		const currentFilesSelection = this.getFilesSelection();
		this.updateBrickState(oldState => ({
			...oldState,
			filesSelection: currentFilesSelection.has(id)
				? currentFilesSelection.delete(id)
				: currentFilesSelection.add(id)
		}));
	}

	resetFilesSelection() {
		this.updateBrickState(oldState => ({
			...oldState,
			filesSelection: INITIAL_FILES_SELECTION
		}));
	}
}
UploadFilesBrowserController.ViewMode = {
	TABLE: 'table',
	GRID: 'grid'
};
declareBrick(UploadFilesBrowserController);
