import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {number} from 'prop-types';
import {createStructuredSelector} from 'reselect';

import EmbeddedPdfViewer from '../../components/EmbeddedPdfViewer.js';
import {simulateFileLoad} from '../actions/ViewerItemsActions.js';
import {hasFileLoadStarted, selectGenericFileViewerItem} from '../selectors/ViewerItemsSelectors.js';

export default function EmbeddedPdfViewerContainer({fileId}) {
	const dispatch = useDispatch();
	const stateSelector = useMemo(() => createStateSelector(fileId), [fileId]);
	const stateProps = useSelector(stateSelector);
	const loadViewerItem = useCallback(() => dispatch(simulateFileLoad(fileId)), [dispatch, fileId]);
	return <EmbeddedPdfViewer fileId={fileId} loadViewerItem={loadViewerItem} renderToolbar {...stateProps} />;
}

EmbeddedPdfViewerContainer.propTypes = {
	fileId: number.isRequired
};

function createStateSelector(fileId) {
	return createStructuredSelector({
		hasDownloadStarted: hasFileLoadStarted(fileId),
		fileViewerItem: selectGenericFileViewerItem(fileId)
	});
}
