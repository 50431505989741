import {mat3} from 'gl-matrix';
import {List as createList} from 'immutable';

import {FIELD_DOM_IMAGES} from '../../commons/constants/SynAdvancedImageConstants.js';
import {
	createSynAdvancedImage,
	getInitialWindow,
	releaseAllDecodedImages
} from '../../commons/data/aim/SynAdvancedImage.js';
import {FULL_OPACITY} from '../components/CanvasRendering.js';
import {DEFAULT_WINDOW_CENTER, DEFAULT_WINDOW_WIDTH} from '../constants/ImageViewerConstants.js';
import {getRawImage} from './ViewerItemUtils.js';

const SINGLE_BLACK_PIXEL = new ImageData(
	new Uint8ClampedArray([0, 0, 0, FULL_OPACITY]),
	1, 1,
	{colorSpace: 'srgb'}
);
const EMPTY_IMAGE = createSynAdvancedImage(1, 1, [])
	.set(FIELD_DOM_IMAGES, createList([SINGLE_BLACK_PIXEL, SINGLE_BLACK_PIXEL]));

export function getEmptyImage() {
	return EMPTY_IMAGE;
}

function extractInitialWindow(decodedImage) {
	const initialWindow = getInitialWindow(decodedImage);
	return {
		center: initialWindow.get('center', DEFAULT_WINDOW_CENTER),
		width: initialWindow.get('width', DEFAULT_WINDOW_WIDTH)
	};
}

export function getWindowParams({
	windowCenter,
	windowWidth
}, decodedImage) {
	const initialWindow = extractInitialWindow(decodedImage);
	return {
		windowCenter: windowCenter || initialWindow.center,
		windowWidth: windowWidth || initialWindow.width
	};
}

export function calculateZoomPanMatrix(pan, zoom) {
	const panZoomMatrix = mat3.create();
	panZoomMatrix[0] = zoom;
	panZoomMatrix[4] = zoom;
	panZoomMatrix[6] = pan.get('x');
	panZoomMatrix[7] = pan.get('y');
	return panZoomMatrix;
}


export function createImageViewerOverlayProperties(viewerProperties, viewerItem) {
	let imageViewerOverlayProperties = {};
	if (Boolean(viewerItem) || Boolean(viewerProperties)) {
		const rawImage = getRawImage(viewerItem);
		let {
			center: windowCenter = DEFAULT_WINDOW_CENTER,
			width: windowWidth = DEFAULT_WINDOW_WIDTH
		} = getInitialWindow(rawImage)
			.toJS();

		if (viewerProperties) {
			windowCenter = viewerProperties.get('windowCenter', windowCenter);
			windowWidth = viewerProperties.get('windowWidth', windowWidth);
		}

		imageViewerOverlayProperties = {
			windowCenter: `L: ${windowCenter.toFixed(2)}`,
			windowWidth: `W: ${windowWidth.toFixed(2)}`
		};
	}
	return imageViewerOverlayProperties;
}

export function releaseImageSafe(decodedImage) {
	return decodedImage && releaseAllDecodedImages(decodedImage);
}

export function moveToCenter(
		transformationMatrix,
		containerWidth,
		containerHeight,
		imageWidth,
		imageHeight,
		devicePixelRatio
) {
	const translateX = ((containerWidth / devicePixelRatio) - imageWidth) / 2.0;
	const translateY = ((containerHeight / devicePixelRatio) - imageHeight) / 2.0;
	const translationMatrix = mat3.fromValues(
		0, 0, 0,
		0, 0, 0,
		translateX, translateY, 0
	);
	return mat3.add(mat3.create(), transformationMatrix, translationMatrix);
}
