import {createStructuredSelector} from 'reselect';

import {deselectItem, selectItem} from '../actions/SelectedItemsActions.js';
import SelectItemCheckbox from '../components/SelectItemCheckbox.js';
import connectWithInstanceSelector from '../flux/connectWithInstanceSelector.js';
import {createSelectIsItemSelected} from '../selectors/SelectedItemsSelectors.js';

function createMapStateToProps(props) {
	const selectIsItemSelected = createSelectIsItemSelected(props.id);

	return createStructuredSelector({
		checked: selectIsItemSelected
	});
}

export default connectWithInstanceSelector(
	createMapStateToProps,
	{
		onSelect: selectItem,
		onDeselect: deselectItem
	}
)(SelectItemCheckbox);
