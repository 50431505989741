import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {currentLocale} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import ChangeInvalidPasswordPage from '../../components/ChangeInvalidPasswordPage.js';
import {changePassword} from '../actions/PasswordActions.js';
import {attachSession} from '../actions/SessionActions.js';
import {selectPasswordState, selectUserName} from '../selectors/PasswordStateSelectors.js';

export default connect(
	createStructuredSelector({
		username: selectUserName,
		passwordState: selectPasswordState,
		locale: currentLocale
	}),
	bindActions({
		attachSession,
		changePassword
	})
)(ChangeInvalidPasswordPage);
