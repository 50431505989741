import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {getDocumentDownloadURL} from '../../../commons/api/DocumentApi.js';
import {selectDocuments, selectFiles} from '../../../patient-details/flux/selectors/PatientDetailsSelectors.js';
import {getImageDownloadURL} from '../../api/DicomImageApi.js';
import {getSeriesDownloadURL} from '../../api/DicomSeriesApi.js';
import {getFileDownloadURL} from '../../api/GenericFileApi.js';
import {selectImageMetadata} from './ViewerItemsSelectors.js';

function createDownloadOptionEntry(message, url) {
	return {message, url};
}

function createDicomImageDownloadOptions(studyId, seriesId, imageId) {
	return [
		createDownloadOptionEntry('DownloadStudy', getDocumentDownloadURL(studyId)),
		createDownloadOptionEntry('DownloadSeries', getSeriesDownloadURL(seriesId)),
		createDownloadOptionEntry('DownloadImage', getImageDownloadURL(imageId))
	];
}

function createGenericFileDownloadOptions(documentId, fileId) {
	return [
		createDownloadOptionEntry('DownloadDocument', getDocumentDownloadURL(documentId)),
		createDownloadOptionEntry('DownloadFile', getFileDownloadURL(fileId))
	];
}

export function createDicomImageDownloadOptionsSelector(imageId) {
	const selectMetadata = selectImageMetadata(imageId);

	const selectIsExportable = createSelector(
		selectDocuments,
		selectMetadata,
		(documents, imageMetadata) => {
			const documentId = imageMetadata.get('document_aimid');
			return documents.getIn([documentId, 'exportable'], false);
		}
	);

	return createSelector(
		selectMetadata,
		selectIsExportable,
		(imageMetadata, isExportable) => (isExportable
			? createDicomImageDownloadOptions(
				imageMetadata.get('document_aimid'),
				imageMetadata.get('dicom_series_fk'),
				imageMetadata.get('id')
			)
			: null)
	);
}

export function createGenericFileDownloadOptionsSelector(fileId) {
	const selectFile = createSelector(
		selectFiles,
		allFiles => allFiles.get(fileId, Immutable.Map())
	);

	const selectDocument = createSelector(
		selectFile,
		file => file.get('document', Immutable.Map())
	);

	const selectDocumentId = createSelector(selectDocument, document => document.get('id'));
	const selectIsExportable = createSelector(selectDocument, document => document.get('exportable', false));

	return createSelector(
		selectDocumentId,
		selectIsExportable,
		(documentId, isExportable) => (isExportable
			? createGenericFileDownloadOptions(documentId, fileId)
			: null)
	);
}
