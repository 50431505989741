import BrowserDevicePixelRatioWatcher from '../../ui/BrowserDevicePixelRatioWatcher.js';
import {setDevicePixelRatio} from '../../ui/flux/UIActions.js';

export default function initUI(store) {
	setInitialDevicePixelRatio(store);
	watchForDevicePixelRatioChanges(store);
}

function setInitialDevicePixelRatio(store) {
	const currentDevicePixelRatio = BrowserDevicePixelRatioWatcher.getDevicePixelRatio();
	dispatchSetDevicePixelRatio(store, currentDevicePixelRatio);
}

function watchForDevicePixelRatioChanges(store) {
	BrowserDevicePixelRatioWatcher.onDeviceAspectRatioChange(deviceAspectRatio => {
		dispatchSetDevicePixelRatio(store, deviceAspectRatio);
	});
}

function dispatchSetDevicePixelRatio(store, currentDevicePixelRatio) {
	store.dispatch(setDevicePixelRatio(currentDevicePixelRatio));
}
