import {mat3} from 'gl-matrix';

import canvasRenderingWorker from '../canvasRenderingWorker.js';
import {PIXEL_FORMAT_RGBA8} from '../constants/PixelFormatConstants.js';

const DEFAULT_WINDOW_CENTER = 128;
const MAX_PIXELS_PER_MS = 5000;
const DEFAULT_CALIBRATE_PERFORMANCE_TIMEOUT = 1000;

let pixelsPerMs = 500000;

export default function getPixelsPerMs() {
	return pixelsPerMs;
}

window.setTimeout(calibratePerformance, DEFAULT_CALIBRATE_PERFORMANCE_TIMEOUT);

function calibratePerformance() {
	if (localStorage.canvasRendererPixelsPerMs) {
		pixelsPerMs = localStorage.canvasRendererPixelsPerMs;
	} else {
		const tempCanvas = document.createElement('canvas');
		const context = tempCanvas.getContext('2d');
		const calibrationWidth = 800;
		const calibrationHeight = 800;
		let count = 0;
		const numberIterations = 10;
		const startTime = new Date().getTime();

		tempCanvas.width = calibrationWidth;
		tempCanvas.height = calibrationHeight;

		const canvasImageData = context.getImageData(0, 0, calibrationWidth, calibrationHeight);
		const rawPixels = new Uint8Array(canvasImageData.data);

		const imageData = {
			width: calibrationWidth,
			height: calibrationHeight,
			scaleFactor: 1,
			scaleMatrix: mat3.create(),
			imageData: canvasImageData,
			rawPixelsBuffer: rawPixels.buffer,
			rawPixelsBufferOffset: rawPixels.byteOffset,
			rawPixelsFormat: PIXEL_FORMAT_RGBA8
		};

		const worker = canvasRenderingWorker();

		worker.onmessage = function workerOnMessage(event) {
			++count;

			if (count < numberIterations) {
				submitData(worker, imageData, count);
			}
			context.putImageData(event.data.imageData, 0, 0);
			if (count === numberIterations) {
				const duration = new Date().getTime() - startTime;
				pixelsPerMs = Math.max(
					MAX_PIXELS_PER_MS, (calibrationWidth * calibrationHeight * numberIterations) / duration
				);
				try {
					localStorage.canvasRendererPixelsPerMs = pixelsPerMs;
				} catch (e) {
					//browser is in privacy mode, so it cannot store pixel performance in local storage
				}
				worker.terminate();
			}
		};
		submitData(worker, imageData, count);
	}
}

function postToWorker(worker, type, data) {
	worker.postMessage({type, ...data});
}

function submitData(worker, imageData, count) {
	postToWorker(worker, 'imageData', imageData);
	postToWorker(worker, 'renderStep', {
		id: count,
		scaleFactor: 1,
		windowCenter: DEFAULT_WINDOW_CENTER + count,
		windowWidth: 255
	});
}
