import Immutable from 'immutable';

import {getDicomSeries, getGenericFiles, isDicom, isGeneric} from '../../commons/data/aim/DocumentHelpers';
import {booleanValue} from '../../commons/utils/BooleanUtils';
import {PATIENT_DETAILS_SERIES_DESCRIPTION} from '../constants/PatientDetailsPropertyNames';

export function getNodeLabel(structureNode) {
	return structureNode ? structureNode.get('label') : null;
}

export function isNotAssignedDocumentsCategoryNode(structureNode) {
	return structureNode && structureNode.getIn(['nodeAttributes', 'structure']) === 'NotAssignedDocuments';
}

export function isDocumentNode(structureNode) {
	return structureNode && structureNode.get('nodeType') === 'document';
}

export function isCategoryNode(structureNode) {
	return structureNode && structureNode.get('nodeType') === 'category';
}

export function isRootNode(structureNode) {
	return !getParentId(structureNode);
}

export function getDocumentId(structureNode) {
	return structureNode.get('document_fk') || structureNode.get('id');
}

export function getNodeId(structureNode) {
	return structureNode.get('nodeId');
}

export function getParentId(structureNode) {
	return structureNode.get('parent');
}

export function parsePatientFileDisplayConfig(patientFileDisplay, separator) {
	return patientFileDisplay.map(item => {
		const [configuration, displayName] = item.split(separator);
		return {configuration, displayName};
	});
}

export function parseSeriesSortRegex(seriesSortRegex, separator) {
	const withoutDoubleBackslashes = seriesSortRegex.replace(/\\\\/g, '\\');
	const regexArray = withoutDoubleBackslashes.split(separator);
	if (regexArray.length === 1) {
		if (regexArray[0] === '') {
			return null;
		}
	}
	return regexArray;
}

export function filterStructureQualifier(node, filteredDocumentIds) {
	return node.get('nodeType') === 'category' || // keep all categories
		filteredDocumentIds.includes(getDocumentId(node));
}

export function sortSeries(series, regExps) {
	if (!series || !regExps) {
		return series;
	}
	let seriesSorted = series;
	seriesSorted = seriesSorted.sort((seriesA, seriesB) => compareSeries(seriesA, seriesB, regExps));
	return seriesSorted;
}

function compareSeries(seriesA, seriesB, regExps) {
	const descA = seriesA.get(PATIENT_DETAILS_SERIES_DESCRIPTION) || '';
	const descB = seriesB.get(PATIENT_DETAILS_SERIES_DESCRIPTION) || '';
	const weightA = getWeightForSort(descA, regExps);
	const weightB = getWeightForSort(descB, regExps);
	if (weightA < weightB) {
		return -1;
	} else if (weightA > weightB) {
		return 1;
	}
	return 0;
}

function getWeightForSort(description, regExps) {
	let weight = regExps.findIndex(regexString => {
		const regex = new RegExp(regexString, 'i');
		return booleanValue(description.match(regex));
	});
	if (weight === -1) {
		weight = regExps.length;
	}
	return weight;
}

export function documentMatches(document, filterString) {
	if (!document || !filterString) {
		return false;
	}
	const fileFound = isGeneric(document) && getGenericFiles(document)
		.find(file => includesInKeys(file, ['original_name', 'extension'], filterString));

	const seriesFound = isDicom(document) && getDicomSeries(document)
		.find(series => includesInKeys(series, ['series_description', 'modality_fk'], filterString));

	const descriptionFound = document.get('description').toLowerCase()
		.includes(filterString.toLowerCase());

	return booleanValue(descriptionFound) ||
		booleanValue(fileFound) ||
		booleanValue(seriesFound);
}

function includesInKeys(container, keys, substring) {
	return keys.reduce((acc, key) => acc || container.get(key) &&
		container.get(key).toLowerCase()
			.includes(substring?.toLowerCase()), false);
}

export function filterEmptyCategories(structure) {
	const category2parent = structure.reduce((map, node) => {
		if (isCategoryNode(node)) {
			const parent = getParentId(node);
			if (parent !== null && parent !== undefined) {
				const nodeId = getNodeId(node);
				map.set(nodeId, parent);
			}
		}
		return map;
	}, new Map());

	const nonEmptyCategories = structure.reduce((set, node) => {
		if (isDocumentNode(node)) {
			let parent = getParentId(node);
			while (parent !== null && parent !== undefined) {
				set.add(parent);
				parent = category2parent.get(parent);
			}
		}
		return set;
	}, new Set());

	return structure.filter(node => {
		if (isCategoryNode(node)) {
			const nodeId = getNodeId(node);
			return nonEmptyCategories.has(nodeId);
		}
		return true;
	});
}

export function filterPatientDocuments(newState, documentPredicate) {
	const documentsToBeFiltered = newState.get('documents');
	const structureToBeFiltered = newState.get('structure');
	const newDocuments = documentsToBeFiltered.filter(documentPredicate);
	const filteredDocumentIds = newDocuments.reduce((ids, doc) => ids.add(doc.get('id')), Immutable.Set());
	const newStructure = filterEmptyCategories(
		structureToBeFiltered.filter(node => filterStructureQualifier(node, filteredDocumentIds))
	);
	return newState.merge({
		documents: newDocuments,
		structure: newStructure
	});
}
