import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import GeneralMessagesTranslator from '../../i18n/translators/GeneralTranslator.js';
import Spinner from '../../ui/components/Spinner.js';
import {callSafe} from '../utils/FunctionUtils.js';

/**
 * This component loads the contents of the passed src-URL using an iframe.
 * It will emit events via the props:
 * - onBeforeLoad: when loading of a new url begins
 * - onLoad: when loading of the new url finished.
 *
 * It will reload the referenced content whenever the property src changes.
 *
 * @author p.spitzlinger@synedra.com
 */
export class SynIFrameLoader extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundOnIFrameLoad = this.#onIFrameLoad.bind(this);
		this.iframeRef = React.createRef();
		this.iframeId = SynIFrameLoader.#currentIframeId++;
		this.state = {
			lastLoadedSrc: null
		};
	}

	render() {
		return this.#shouldLoad() ? this.#renderLoadingFrame() : false;
	}

	#renderLoadingFrame() {
		const {src, locale} = this.props;
		const spinnerDescription = GeneralMessagesTranslator.getFormattedMessage('LoadingSpinnerDescription', locale);
		return (
			<React.Fragment>
				<iframe key='iframe' ref={this.iframeRef} onLoad={this.boundOnIFrameLoad} sandbox={this.#getSandboxAttributes()} src={src} />
				<Spinner key='loading-indicator' description={spinnerDescription} />
			</React.Fragment>
		);
	}

	componentDidMount() {
		if (this.#shouldLoad()) {
			this.#callOnBeforeLoad();
		}
	}

	componentDidUpdate() {
		if (this.#shouldLoad()) {
			this.#callOnBeforeLoad();
		}
	}

	#onIFrameLoad() {
		if (this.#isIFrameLoaded()) {
			const {onLoad, src} = this.props;
			callSafe(onLoad, this.iframeId, this.#getIFrameDocument());
			this.setState({lastLoadedSrc: src});
		}
	}

	#callOnBeforeLoad() {
		const {onBeforeLoad} = this.props;
		callSafe(onBeforeLoad);
	}

	#shouldLoad() {
		let shouldLoad = this.#canLoad();
		if (shouldLoad) {
			const {lastLoadedSrc} = this.state;
			const {src} = this.props;
			shouldLoad = lastLoadedSrc !== src;
		}
		return shouldLoad;
	}

	#canLoad() {
		const {src} = this.props;
		return Boolean(src);
	}

	#getIFrameDocument() {
		let contentDocument = null;
		try {
			contentDocument = this.iframeRef.current && this.iframeRef.current.contentDocument;
		} catch (e) {
			// Swallow contentDocument access errors
		}
		return contentDocument;
	}

	#isIFrameLoaded() {
		return this.#getIFrameDocument() !== null;
	}

	#getSandboxAttributes() {
		let attributes = SynIFrameLoader.#FORCED_SANDBOX_ATTRIBUTES;
		const {sandbox} = this.props;
		if (sandbox) {
			attributes = SynIFrameLoader.#FORCED_SANDBOX_ATTRIBUTES_SET
				.union(Immutable.Set.of(...sandbox.split(' ')))
				.reduce((value, entry) => {
					let reducedValue = value;
					if (entry) {
						reducedValue = value ? `${value} ${entry}` : entry;
					}
					return reducedValue;
				}, '');
		}
		return attributes;
	}

	static #currentIframeId = 0;
	static #FORCED_SANDBOX_ATTRIBUTES = 'allow-same-origin allow-scripts';
	static #FORCED_SANDBOX_ATTRIBUTES_SET = Immutable.Set.of(...SynIFrameLoader.#FORCED_SANDBOX_ATTRIBUTES.split(' '));
}

SynIFrameLoader.propTypes = {
	src: PropTypes.string.isRequired,
	locale: PropTypes.string.isRequired,
	onBeforeLoad: PropTypes.func,
	onLoad: PropTypes.func,
	sandbox: PropTypes.string
};
