import React from 'react';
import PropTypes from 'prop-types';

import {memoizeLast} from '../../../utils/FunctionUtils.js';
import {shallowEqual} from '../../../utils/ObjectUtils';
import GridView from './GridView.js';

export default class VariableSizeList extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundMeasureCell = this.measureCell.bind(this);

		this.momoizedCreateRenderListItemWith = memoizeLast(VariableSizeList.createRenderListItemWith);
		this.calcListGeometry = memoizeLast(calcVerticalListGeometry);
	}

	render() {
		const {viewport, itemDimensions} = this.props;
		return (viewport && itemDimensions) ? this.renderList() : false;
	}

	renderList() {
		const {itemDimensions, viewport, overscanRows, renderRow} = this.props;
		const listGeometry = this.calcListGeometry(itemDimensions, this.getListWidth());
		const renderListItemWithRenderRow = this.momoizedCreateRenderListItemWith(renderRow);
		return (
			<GridView measureCell={this.boundMeasureCell} renderCell={renderListItemWithRenderRow} viewport={viewport}
			          overscanColumns={0} overscanRows={overscanRows} width={listGeometry.width}
			          height={listGeometry.height} cellGeometries={listGeometry.cellGeometries} />
		);
	}

	static renderListItem(columnIndex, rowIndex, key, style, renderRow) {
		return renderRow(rowIndex, key, style);
	}

	static createRenderListItemWith(renderRow) {
		return (...args) => VariableSizeList.renderListItem(...args, renderRow);
	}

	getListWidth() {
		const {width: listWidth, viewport} = this.props;
		return listWidth === undefined ? viewport.width : listWidth;
	}

	measureCell(columnIndex, rowIndex) {
		const {itemDimensions} = this.props;
		const listGeometry = this.calcListGeometry(itemDimensions, this.getListWidth());
		return listGeometry.cellGeometries[rowIndex];
	}

	shouldComponentUpdate(nextProps) {
		const {itemDimensions, renderRow, width, overscanRows, viewport} = this.props;
		return itemDimensions !== nextProps.itemDimensions ||
			renderRow !== nextProps.renderRow ||
			width !== nextProps.width ||
			overscanRows !== nextProps.overscanRows ||
			!shallowEqual(viewport, nextProps.viewport);
	}
}

VariableSizeList.propTypes = {
	itemDimensions: PropTypes.arrayOf(
		PropTypes.object
	),
	viewport: PropTypes.object,
	width: PropTypes.number,
	renderRow: PropTypes.func,
	overscanRows: PropTypes.number
};

VariableSizeList.defaultProps = {
	overscanRows: 0
};

function calcVerticalListGeometry(itemDimensions, listWidth) {
	let itemTop = 0;
	let maxWidth = 0;
	const calculatedGeometries = itemDimensions.map(dimensions => {
		const {width = listWidth, height} = dimensions;
		const thisTop = itemTop;
		itemTop += height;

		maxWidth = Math.max(maxWidth, width);

		return [
			{
				top: thisTop,
				left: 0,
				bottom: thisTop + height - 1,
				right: width - 1,
				width,
				height
			}
		];
	});

	return {
		cellGeometries: calculatedGeometries,
		height: itemTop,
		width: maxWidth
	};
}
