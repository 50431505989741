import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import IconButton from '../../ui/components/IconButton.js';
import CloseIcon from '../../ui/components/icons/CloseIcon.js';
import {PrimaryActionIconArea} from './layout/PrimaryActionSideAreas.js';
import Title from './Title.js';

import '../../../styles/material-design/components/DialogHeader.scss';

export default function DialogHeader(props) {
	const {alwaysShowCloseButton, title, subTitle, onClose, children, className} = props;
	const headerClassName = combineClassNames(
		classnames({
			'material-dialog-header': true,
			'always-show-close-button': alwaysShowCloseButton
		}),
		className
	);
	return (
		<header className={headerClassName}>
			<PrimaryActionIconArea className='close-button'>
				<IconButton color='inherit' icon={<CloseIcon />} onClick={onClose} />
			</PrimaryActionIconArea>
			{Boolean(title) && (
				<Title id='modal-dialog-label' className='title' subTitle={subTitle}>
					{title}
				</Title>)}
			{children}
		</header>
	);
}

DialogHeader.propTypes = {
	alwaysShowCloseButton: PropTypes.bool,
	title: PropTypes.node,
	subTitle: PropTypes.node,
	onClose: PropTypes.func,
	className: PropTypes.string
};
