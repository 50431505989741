import {END} from 'redux-saga';
import {call, cancel, cancelled, fork} from 'redux-saga/effects';

import {debugLog} from '../../commons/utils/DebugLog.js';
import {waitForEndOfSession, waitForSession} from './sessionTaskHelpers.js';

export default function createSessionTask(taskImpl) {
	return function* sessionTask() {
		while (!(yield cancelled())) {
			const beginSessionAction = yield call(waitForSession);
			if (beginSessionAction !== END) {
				const task = yield fork(taskImpl, beginSessionAction);
				debugLog(`Started session task: ${taskImpl.name}`);
				yield call(waitForEndOfSession);
				yield cancel(task);
				debugLog(`Stopped session task: ${taskImpl.name}`);
			}
		}
	};
}
