import React from 'react';
import PropTypes from 'prop-types';

import UploadController from '../../../../webupload/bricks/UploadController.js';
import useBrick from '../../../bricks/hooks/useBrick.js';
import StepperHeader from '../../../ui/components/StepperHeader.js';
import DeviceInfo from '../../bricks/DeviceInfo.js';
import {useMemoFactory} from '../../utils/customHooks/index.js';

export default function UploadStepperInfo(props) {
	const {stepMap, getStepFromState} = props;
	const controllerState = useBrick(UploadController, controller => controller.getState());
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const activeStep = useMemoFactory(getStepFromState, controllerState);
	const steps = useMemoFactory(createSteps, activeStep, ...stepMap);
	return <StepperHeader steps={steps} activeStep={activeStep} singleStep={isMobileDevice} />;
}

UploadStepperInfo.propTypes = {
	stepMap: PropTypes.arrayOf(PropTypes.string),
	getStepFromState: PropTypes.func
};

function createSteps(activeStep, ...labels) {
	return labels.map((label, index) => ({
		label,
		completed: index < activeStep
	}));
}
