import React from 'react';
import {useSelector} from 'react-redux';
import {instanceOf, number} from 'prop-types';
import {createStructuredSelector} from 'reselect';

import {uiDeviceSize, uiShowPrintLayout, uiWindowScreenSize} from '../../../ui/flux/UISelectors.js';
import DicomImageMetaDataOverlayPresenter from '../../components/dicom/DicomImageMetaDataOverlayPresenter.js';
import DicomImage from '../../data/DicomImage.js';
import {getViewerLayout} from '../selectors/ViewerSelectors.js';

export default function DicomImageMetaDataOverlayContainer(props) {
	const {dicomImage, containerWidth, containerHeight} = props;
	const layoutProps = useSelector(selectLayoutProps);
	return (
		<DicomImageMetaDataOverlayPresenter dicomImage={dicomImage} containerWidth={containerWidth}
		                                    containerHeight={containerHeight} {...layoutProps} />
	);
}

DicomImageMetaDataOverlayContainer.propTypes = {
	dicomImage: instanceOf(DicomImage).isRequired,
	containerWidth: number,
	containerHeight: number
};

const selectLayoutProps = createStructuredSelector({
	deviceSize: uiDeviceSize,
	viewerLayout: getViewerLayout,
	screenSize: uiWindowScreenSize,
	isPrintPreview: uiShowPrintLayout
});
