import React from 'react';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../utils/CustomPropTypes.js';
import {callSafe} from '../utils/FunctionUtils.js';
import BoundingRectMeasurer from './BoundingRectMeasurer.js';
import DialogAnchorController from './DialogAnchorController.js';
import FullScreenWrapper from './FullScreenWrapper.js';

export default class FullScreenDialogAnchor extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.requestOpening = this.requestOpening.bind(this);

		this.state = {
			boundingRect: null
		};

		this.boundUpdateBoundingRect = this.updateBoundingRect.bind(this);
		this.dialogAnchorController = new DialogAnchorController(this);
	}

	render() {
		const {windowSize, dialogIsActive} = this.props;
		return dialogIsActive && (
			<BoundingRectMeasurer onBoundingRectUpdated={this.boundUpdateBoundingRect} windowSize={windowSize} />
		);
	}

	renderPortalContent() {
		const {boundingRect} = this.state;
		const {children, windowSize, closePersistentPortal} = this.props;
		const targetBoundingRect = {
			left: 0,
			top: 0,
			width: windowSize.get('width'),
			height: windowSize.get('height')
		};
		return (
			<FullScreenWrapper onClose={closePersistentPortal}
									sourceBoundingRect={boundingRect} targetBoundingRect={targetBoundingRect}>
				{children}
			</FullScreenWrapper>
		);
	}

	updateBoundingRect(boundingRect) {
		this.setState({boundingRect});
	}

	requestOpening() {
		const {updatePersistentPortal, dialogIsActive} = this.props;
		if (!dialogIsActive) {
			callSafe(updatePersistentPortal, this.renderPortalContent());
		}
	}

	componentDidMount() {
		const {dialogControllerRef} = this.props;
		callSafe(dialogControllerRef, this.dialogAnchorController);
	}

	componentWillUnmount() {
		const {dialogControllerRef} = this.props;
		callSafe(dialogControllerRef, null);
	}

	componentDidUpdate(prevProps, prevState) {
		const {boundingRect} = this.state;
		const {boundingRect: prevBoundingRect} = prevState;
		const {windowSize, dialogIsActive} = this.props;
		const {windowSize: prevWindowSize} = prevProps;
		const hasBoundingRect = boundingRect !== null;

		const didBoundingRectChange = boundingRect !== prevBoundingRect;
		const wasWindowResized = prevWindowSize !== windowSize;

		const shouldUpdateDialog = dialogIsActive && (didBoundingRectChange || wasWindowResized);

		if (hasBoundingRect && shouldUpdateDialog) {
			const {updatePersistentPortal} = this.props;
			callSafe(updatePersistentPortal, this.renderPortalContent());
		}
	}

	shouldDisplayDialog() {
		const {dialogIsActive} = this.props;
		const {openingRequested} = this.state;
		return dialogIsActive || openingRequested;
	}
}

FullScreenDialogAnchor.propTypes = {
	dialogControllerRef: PropTypes.func,
	dialogIsActive: PropTypes.bool,
	windowSize: immutableMapPropType,
	updatePersistentPortal: PropTypes.func,
	closePersistentPortal: PropTypes.func
};
