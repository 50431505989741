import Immutable from 'immutable';

import SidebarConstants from '../../../constants/SidebarConstants.json';
import {createAction} from '../../commons/utils/ActionUtils.js';
import withTrackingMetadata from '../../metrics-collector/utils/withTrackingMetadata.js';
import {
	UI_ENABLE_SIDEBAR_TRANSITIONS,
	UI_HIDE_CHANGE_PASSWORD_DIALOG,
	UI_HIDE_PRIVACY_POLICY_DIALOG,
	UI_SET_DEVICE_PIXEL_RATIO,
	UI_SHOW_CHANGE_PASSWORD_DIALOG,
	UI_SHOW_PRIVACY_POLICY_DIALOG,
	UI_TOGGLE_SIDEBAR,
	UI_UPDATE_FULLSCREEN_ELEMENT_VISIBILITY_STATUS,
	UI_UPDATE_WINDOW_LAYOUT
} from '../constants/UIActionTypes.js';

const toggleSideBarAction = createAction(UI_TOGGLE_SIDEBAR);

export const enableSidebarTransitions = createAction(UI_ENABLE_SIDEBAR_TRANSITIONS);
export const toggleSidebar = () => dispatch => {
	dispatch(enableSidebarTransitions(true));
	dispatch(toggleSideBarAction());

	window.setTimeout(() => {
		dispatch(enableSidebarTransitions(false));
	}, SidebarConstants['sidebar-animation-times'].desktop);
};

function createUpdateWindowLayoutPayload(
		inPrintLayout = undefined,
		width = window.innerWidth, height = window.innerHeight
) {
	return {
		showPrintLayout: inPrintLayout,
		windowSize: Immutable.Map({
			width: width && Math.max(0, width),
			height: height && Math.max(0, height)
		})
	};
}

export const updateWindowLayout = createAction(UI_UPDATE_WINDOW_LAYOUT, createUpdateWindowLayoutPayload);
export const hidePrintLayout = createAction(
	UI_UPDATE_WINDOW_LAYOUT,
	() => createUpdateWindowLayoutPayload(false, undefined, undefined),
	withTrackingMetadata(() => ({usageContext: 'export', key: 'Print'}))
);
export const showPrintLayout = createAction(
	UI_UPDATE_WINDOW_LAYOUT,
	() => createUpdateWindowLayoutPayload(true, undefined, undefined),
	withTrackingMetadata(() => ({usageContext: 'export', key: 'Print'}))
);
export const setDevicePixelRatio = createAction(
	UI_SET_DEVICE_PIXEL_RATIO,
	newDevicePixelRatio => {
		const devicePixelRatioAsNumber = Number(newDevicePixelRatio);
		if (isNaN(devicePixelRatioAsNumber)) {
			throw new Error(`Not a number: ${newDevicePixelRatio}`);
		}
		if (devicePixelRatioAsNumber <= 0) {
			throw new Error('Device pixel ratio must be greater than 0');
		}
		return devicePixelRatioAsNumber;
	}
);
export const showPrivacyPolicyDialog = createAction(UI_SHOW_PRIVACY_POLICY_DIALOG);
export const hidePrivacyPolicyDialog = createAction(UI_HIDE_PRIVACY_POLICY_DIALOG);
export const showChangePasswordDialog = createAction(UI_SHOW_CHANGE_PASSWORD_DIALOG);
export const hideChangePasswordDialog = createAction(UI_HIDE_CHANGE_PASSWORD_DIALOG);
export const updateFullScreenElementVisibilityStatus = createAction(UI_UPDATE_FULLSCREEN_ELEMENT_VISIBILITY_STATUS);
