import React from 'react';
import {List} from 'immutable';
import {array, bool, func, number, oneOfType, string} from 'prop-types';

import EventBarrier from '../../commons/components/EventBarrier.js';
import CenteredEditCircleIcon from '../../commons/components/webview/icons/CenteredEditCircleIcon.js';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import SvgButtonMenu from '../../ui/components/SvgButtonMenu.js';
import {stackLines} from './MetaDataOverlayArea.js';
import WindowPresetsMenu from './WindowPresetsMenu.js';

const TEXT_OFFSET = 2.2;

export default class WindowParametersOverlay extends React.PureComponent {
	render() {
		const {windowCenter, windowWidth, xem, yem, align, showPresetsMenu, presets} = this.props;
		const windowCenterText = `L: ${windowCenter.toFixed(2)}`;
		const windowWidthText = `W: ${windowWidth.toFixed(2)}`;
		const hasPresetsMenu = showPresetsMenu && presets.size > 0;
		const textOffset = hasPresetsMenu ? TEXT_OFFSET : 0;
		const presetsMenuHandle = hasPresetsMenu
			? this.createPresetsMenuHandle(estimateHeight(windowCenterText, windowWidthText))
			: false;

		const textX = xem + (align === 'right' ? -textOffset : textOffset);
		const stackProps = {
			...this.props,
			x: `${textX}em`,
			y: `${yem}em`
		};
		return (
			<g>
				{presetsMenuHandle}
				{stackLines(stackProps, [windowCenterText, windowWidthText])}
			</g>
		);
	}

	createPresetsMenuHandle(estimatedHeight) {
		const {xem, yem, align, vAlign, setWindow, presets} = this.props;
		const menuX = xem + (align === 'right' ? -1 : 1);
		const menuY = yem + (vAlign === 'bottom' ? -estimatedHeight : estimatedHeight) / 2.0;
		const presetsPopupMenu = <WindowPresetsMenu setWindow={setWindow} presets={presets} />;

		return (
			<EventBarrier preventDefault={['onPointerDown']} component='g'>
				<SvgButtonMenu x={`${menuX}em`} y={`${menuY}em`} icon={CenteredEditCircleIcon}>
					{presetsPopupMenu}
				</SvgButtonMenu>
			</EventBarrier>
		);
	}
}

WindowParametersOverlay.propTypes = {
	xem: oneOfType([number, string]),
	yem: oneOfType([number, string]),
	windowCenter: number,
	windowWidth: number,
	showPresetsMenu: bool,
	presets: oneOfType([array, immutableListPropType]),
	align: string,
	vAlign: string,
	setWindow: func
};

WindowParametersOverlay.defaultProps = {
	xem: 0,
	yem: 0,
	windowCenter: 0,
	windowWidth: 0,
	presets: new List()
};

function estimateHeight(...texts) {
	return texts.length;
}
