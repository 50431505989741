import {connect} from 'react-redux';

import {selectIsAnyItemSelected, selectSelectedItemIds} from '../../../commons/selectors/SelectedItemsSelectors.js';
import ShareDocumentButton from '../../components/ShareDocumentButton.js';

function mapStateToProps(state) {
	return {
		disabled: !selectIsAnyItemSelected(state),
		documentIds: selectSelectedItemIds(state)
	};
}

export default connect(
	mapStateToProps,
	{}
)(ShareDocumentButton);
