import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {unloadCurrentPatient} from '../../../commons/actions/WebViewActions.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import {replaceLocation} from '../../../router/flux/LocationActions.js';
import PatientDataLoaderPage from '../../components/PatientDetailsLoaderPage.js';
import {loadPatientForQuery} from '../PatientDetailsActions.js';
import {
	getRedirectLocationForQuery,
	selectHasPatientDetailsForQuery,
	selectItemTypeForQuery,
	selectPatientDetailsLoaderQuery
} from '../selectors/PatientDetailsLoaderSelectors.js';
import {getLoadError, selectHasPatientDetails} from '../selectors/PatientDetailsSelectors.js';

function createLoadActionForQuery(queryBasedLoadPatientForQuery, query) {
	const onlyShares = query.has('share_id', false) || query.get('onlyShares', 0) === 1;
	return () => queryBasedLoadPatientForQuery(query.toJS(), onlyShares);
}

export default connect(
	createStructuredSelector({
		hasPatientDetailsForQuery: selectHasPatientDetailsForQuery,
		hasRawPatientDetails: selectHasPatientDetails,
		patientDetailsLoaderQuery: selectPatientDetailsLoaderQuery,
		itemType: selectItemTypeForQuery,
		redirectLocation: getRedirectLocationForQuery,
		loadError: getLoadError
	}),
	bindActions({
		loadPatientForQuery,
		replaceLocation,
		unloadCurrentPatient
	}),
	(stateProps, dispatchProps, ownProps) => {
		const {
			itemType, patientDetailsLoaderQuery, redirectLocation,
			hasPatientDetailsForQuery,
			hasRawPatientDetails,
			loadError
		} = stateProps || {};
		const {
			unloadCurrentPatient: boundUnloadCurrentPatient,
			loadPatientForQuery: boundLoadPatientForQuery,
			replaceLocation: boundReplaceLocation
		} = dispatchProps;
		return {
			...ownProps,
			itemType,
			hasPatientDetailsForQuery,
			hasPatientDetails: hasRawPatientDetails,
			loadFailed: loadError !== null,
			unloadCurrentPatient: boundUnloadCurrentPatient,
			loadPatientForQuery: createLoadActionForQuery(boundLoadPatientForQuery, patientDetailsLoaderQuery),
			redirectToPatientDetails: () => boundReplaceLocation(redirectLocation)
		};
	}
)(PatientDataLoaderPage);
