import BrickBase from '../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../webview/bricks/brickTools.js';
import Patient from '../../webview/commons/data/aim/Patient.js';

/**
 * This class acts as an interface and thus each method throws a NotImplementedError.
 *
 * @author p.spitzlinger@synedra.com
 */
export default class PatientContext extends BrickBase {
	constructor() {
		super(null);
		this.updateBrickState(() => ({
			patient: null
		}));
	}

	loadPatient(patient) {
		this.updateBrickState(oldState => ({
			...oldState,
			patient: patient ? Patient.from(patient) : null
		}));
	}

	getPatient() {
		return this.getBrickState().patient;
	}

	getFirstName() {
		const patient = this.getPatient();
		return patient ? patient.firstName : null;
	}

	getLastName() {
		const patient = this.getPatient();
		return patient ? patient.lastName : null;
	}

	getSex() {
		const patient = this.getPatient();
		return patient ? patient.sex : null;
	}

	getBirthDate() {
		const patient = this.getPatient();
		return patient ? patient.birthDate : null;
	}

	isOtherPatient(otherPatient) {
		if (otherPatient && this.getPatient()) {
			const {uniqueId} = Patient.from(otherPatient);
			return this.getPatient().uniqueId !== uniqueId;
		}
		return true;
	}
}
declareBrick(PatientContext);
