import React from 'react';

import SubtextLink from '../components/SubtextLink.js';
import LinkContainer from './LinkContainer.js';

function SubtextLinkContainer(props) {
	return <LinkContainer linkComponent={SubtextLink} {...props} />;
}

SubtextLinkContainer.propTypes = LinkContainer.propTypes;

export default SubtextLinkContainer;
