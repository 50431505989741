import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import CheckCircleIcon, {
	CheckCircleIconBlank
} from '../../../webview/material-design/components/icons/CheckCircleIcon.js';

// TODO MPe :: Add shadow to svg icon. Was @include material-elevation(2, bottom, $cssFilter: true)
const SelectionIndicatorWrapper = styled('div', {name: 'SelectionIndicator'})(props => {
	const {theme} = props;
	return {
		width: '24px',
		height: '24px',
		color: theme.palette.primary.main,
		'& svg': {
			borderRadius: '50%',
			fill: 'white'
		}
	};
});

export default function SelectionIndicator(props) {
	const {isSelected} = props;
	return (
		<SelectionIndicatorWrapper>
			{isSelected ? <CheckCircleIcon /> : <CheckCircleIconBlank />}
		</SelectionIndicatorWrapper>
	);
}

SelectionIndicator.propTypes = {
	isSelected: PropTypes.bool
};
