import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {useDicomViewerState} from '../../components/dicom/DicomSeriesContextProvider.js';
import DownloadMenuButton from '../../components/DownloadMenuButton.js';
import {
	createDicomImageDownloadOptionsSelector,
	createGenericFileDownloadOptionsSelector
} from '../selectors/DownloadOptionsSelectors.js';

export function GenericFileDownloadMenuButtonContainer(props) {
	const {fileId} = props;
	const optionsSelector = useMemo(() => createGenericFileDownloadOptionsSelector(fileId), [fileId]);
	const downloadOptions = useSelector(optionsSelector);

	return <DownloadMenuButton downloadOptions={downloadOptions} />;
}
GenericFileDownloadMenuButtonContainer.propTypes = {
	fileId: PropTypes.number
};

export function DicomImageDownloadMenuButtonContainer() {
	const {currentImageId: imageId} = useDicomViewerState();
	const downloadOptionsSelector = useMemo(() => createDicomImageDownloadOptionsSelector(imageId), [imageId]);
	const downloadOptions = useSelector(downloadOptionsSelector);

	return <DownloadMenuButton downloadOptions={downloadOptions} />;
}
