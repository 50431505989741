import Immutable from 'immutable';

export const VIEWER_QUERY_PROPERTY_SHOW_VIEWER = 'showViewer';
export const VIEWER_STATE_PROPERTIES = 'properties';
export const VIEWER_STATE_META_INFO = 'metaInfo';
export const VIEWER_STATE_LAYOUT_MODE = 'layoutMode';
export const VIEWER_STATE_FULLSCREEN_VIEWER_ID = 'fullscreenViewerId';
export const VIEWER_STATE_ACTIVE_VIEWER_ID = 'activeViewerId';
export const VIEWER_INFO_DIALOG_ID = 'ViewerInfoDialog';

export const VIEWER_MATRIX_LAYOUT_1X1 = 0;
export const VIEWER_MATRIX_LAYOUT_2X1 = 1;
export const VIEWER_MATRIX_LAYOUT_1X2 = 2;
export const VIEWER_MATRIX_LAYOUT_2X2 = 3;

export const DEFAULT_LAYOUT_MODE = VIEWER_MATRIX_LAYOUT_1X1;
export const MAX_MOBILE_LAYOUT_MODE = VIEWER_MATRIX_LAYOUT_1X2;
export const MAX_LAYOUT_MODE = VIEWER_MATRIX_LAYOUT_2X2;

export const VIEWER_TOP_LEFT = 0;
export const VIEWER_TOP_RIGHT = 1;
export const VIEWER_BOTTOM_LEFT = 2;
export const VIEWER_BOTTOM_RIGHT = 3;

export const MODE_TO_LAYOUT = Immutable.fromJS([
	{rows: 1, columns: 1, top: ['0'], bottom: ['0'], left: ['0'], right: ['0']},
	{rows: 2, columns: 1, top: ['0'], bottom: ['1'], left: ['0', '1'], right: ['0', '1']},
	{rows: 1, columns: 2, top: ['0', '1'], bottom: ['0', '1'], left: ['0'], right: ['1']},
	{rows: 2, columns: 2, top: ['0', '1'], bottom: ['2', '3'], left: ['0', '2'], right: ['2', '3']}
]);

export const SUPPORTED_FILE_EXTENSIONS = new Set(['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'tif', 'xml', 'cda', 'pdf']);

export const LAYOUT_CLASS_2_MODE = {
	m11: VIEWER_MATRIX_LAYOUT_1X1,
	m21: VIEWER_MATRIX_LAYOUT_2X1,
	m12: VIEWER_MATRIX_LAYOUT_1X2,
	m22: VIEWER_MATRIX_LAYOUT_2X2
};

export const VIEWER_COUNT_FOR_MODE = {
	[VIEWER_MATRIX_LAYOUT_1X1]: 1,
	[VIEWER_MATRIX_LAYOUT_2X1]: 2,
	[VIEWER_MATRIX_LAYOUT_1X2]: 2,
	[VIEWER_MATRIX_LAYOUT_2X2]: 4
};
