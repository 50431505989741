import React from 'react';
import PropTypes from 'prop-types';

import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {noop} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import Button from '../../ui/components/Button.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';

export default class NewPasswordSuccess extends React.PureComponent {
	render() {
		const {translator, onFinish} = this.props;
		return (
			<React.Fragment>
				<VerticalLayout key='centerLayout' className='new-password--success'>
					<StatusMessage>
						<SynFormattedMessage translator={translator} className='new-password--message' message='SuccessMessageShort' />
					</StatusMessage>
					<SynFormattedMessage translator={translator} className='new-password--message' message='SuccessMessageDetails' />
				</VerticalLayout>
				<Button flat opaque key='continueButton' onClick={onFinish}>
					<SynFormattedMessage translator={translator} message='ContinueToWebAppButtonLabel' />
				</Button>
			</React.Fragment>
		);
	}
}

NewPasswordSuccess.propTypes = {
	translator: PropTypes.object.isRequired,
	onFinish: PropTypes.func
};

NewPasswordSuccess.defaultProps = {
	onFinish: noop
};
