import {markAsHandled} from '../utils/ErrorHandlingUtils.js';
import FetchError from './FetchError.js';

/**
 * An error class to indicate that the requested resource can only be accessed authorized
 */
export default class UnauthorizedError extends FetchError {
	constructor(response, responseAsJSON) {
		super('This request cannot be handled without authorization information.', response, responseAsJSON);
		markAsHandled(this);
	}
}
