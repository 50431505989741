import {isSequenceOfAscendingCharsOnly, isSequenceOfDescendingCharsOnly} from '../../utils/StringUtils.js';
import {createSingleFieldValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';

export const succeedingCharsOnlyValidator = createSingleFieldValidatorFactory(
	(locale, translatedFieldLabel, fieldValue) => fieldValue !== null &&
		fieldValue !== undefined &&
		!isSequenceOfAscendingCharsOnly((`${fieldValue}`).trim()) &&
		!isSequenceOfDescendingCharsOnly((`${fieldValue}`).trim()) ||
		ValidatorsMessagesTranslator.getFormattedMessage('OnlySuccessiveCharacters', locale, {inputFieldLabel: translatedFieldLabel})
);
