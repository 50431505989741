/**
 * Extracts a boolean value from its numeric representation
 * The value is true if its numeric representation is 1 and false otherwise
 *
 * @param potentialBoolean
 */
export function booleanValue(potentialBoolean) {
	let value;
	switch (typeof (potentialBoolean)) {
		case 'number':
			value = potentialBoolean > 0;
			break;
		case 'string':
			value = potentialBoolean === 'true' || potentialBoolean === '1';
			break;
		case 'object':
			value = potentialBoolean !== null && Object.keys(potentialBoolean).length > 0;
			break;
		default:
			value = Boolean(potentialBoolean);
			break;
	}
	return value;
}
