const IS_POINTER_ENABLED = (Boolean(window) && Boolean(window.PointerEvent)) ||
	(Boolean(navigator) && Boolean(navigator.pointerEnabled));

export function arePointerEventsSupported() {
	return IS_POINTER_ENABLED;
}

export function stopEventPropagation(e) {
	e.stopPropagation();
}

export function preventEventDefault(e) {
	e.preventDefault();
}

export function preventMouseEventForTouchEvent(touchEvent) {
	// To keep mouse events from being sent as well.
	// see: https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent
	preventEventDefault(touchEvent);
}

export function createTouchEventHandler(handlerFunction) {
	/** @this The context the handlerFunction will be called upon */
	return function touchEventHandler(e) {
		handlerFunction.apply(this, [e]);
		preventMouseEventForTouchEvent(e);
	};
}

export function isKeyEvent(e) {
	return Boolean(e) && (e.type === 'keydown' || e.type === 'keyup');
}

export function isStoppableDOMEvent(event) {
	return Boolean(event) && Boolean(event.stopPropagation);
}
