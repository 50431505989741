import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {addTaskSelector, removeTaskSelector} from '../../../scheduling/api';
import {uiIsExtraSmallDevice, uiIsMobileDevice, uiWindowSize} from '../../../ui/flux/UISelectors.js';
import PatientDetails from '../../components/PatientDetails.js';
import {
	selectInitiallyExpandedCategoryIds,
	selectInitiallySelectedDocumentIds,
	selectNumDocuments,
	selectNumDocumentsRemoved,
	selectStructureHierarchy
} from '../selectors/PatientDetailsSelectors.js';

export default connect(
	createStructuredSelector({
		structure: selectStructureHierarchy,
		numDocuments: selectNumDocuments,
		numRemovedDocuments: selectNumDocumentsRemoved,
		isExtraSmallDevice: uiIsExtraSmallDevice,
		isMobileDevice: uiIsMobileDevice,
		initiallySelectedDocumentIds: selectInitiallySelectedDocumentIds,
		initiallyExpandedCategoryIds: selectInitiallyExpandedCategoryIds,
		windowSize: uiWindowSize
	}),
	null,
	(stateProps, _, ownProps) => ({
		...ownProps,
		...stateProps,
		addTaskSelector,
		removeTaskSelector
	})
)(PatientDetails);
