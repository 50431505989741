import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {fromJS} from 'immutable';
import PropTypes from 'prop-types';

import ViewerContext from '../../components/ViewerContext.js';
import WindowParametersOverlay from '../../components/WindowParametersOverlay.js';
import DicomImage from '../../data/DicomImage.js';
import {createSelectWindowProperties} from '../selectors/ImageViewerSelectors.js';
import selectWithArgs from '../selectors/selectWithArgs.js';

export default function DicomImageWindowParametersOverlayContainer(props) {
	const {dicomImage: {windowPresets, rawImageData}, ...overlayProps} = props;
	const {viewerId, isPrintPreview, updateProperties} = useContext(ViewerContext);
	const setWindow = useMemo(() => createSetWindowCallback(updateProperties), [updateProperties]);
	const windowPropsSelector = useMemo(() => createSelectWindowProperties(viewerId), [viewerId]);
	const windowProps = useSelector(selectWithArgs(windowPropsSelector, rawImageData));
	return (
		<WindowParametersOverlay showPresetsMenu={!isPrintPreview} setWindow={setWindow} presets={windowPresets}
		                         {...windowProps} {...overlayProps} />
	);
}

DicomImageWindowParametersOverlayContainer.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage)
};

function createSetWindowCallback(updateProperties) {
	return (windowCenter, windowWidth) => updateProperties(fromJS({windowWidth, windowCenter}));
}
