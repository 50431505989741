import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {hidePrivacyPolicyDialog} from '../../../ui/flux/UIActions.js';
import {uiShowPrivacyPolicyDialog} from '../../../ui/flux/UISelectors.js';
import PrivacyPolicyDialog from '../../components/webview/PrivacyPolicyDialog.js';
import {bindActions} from '../../utils/FluxUtils.js';

export default connect(
	createStructuredSelector({
		uiShowPrivacyPolicyDialog
	}),
	bindActions({
		hidePrivacyPolicyDialog
	})
)(PrivacyPolicyDialog);
