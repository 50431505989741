export default function processTask(task, finishedHandler) {
	const {taskCreator} = task;
	const promise = taskCreator()
		.then(result => {
			finishedHandler();
			task.taskConsumer(promise);
			return result;
		})
		.catch(e => {
			finishedHandler();
			task.taskConsumer(promise);
			throw e;
		});
}
