import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import {UNINITIALIZED_ANNOTATION_PROPERTIES} from '../../constants/AnnotationConstants.js';
import AnnotationRootSVGGroup from './AnnotationRootSVGGroup.js';

export default function createAnnotation(isAnnotationSupported, getDefaultAnnotationProperties, AnnotationComponent) {
	class Annotation extends React.PureComponent {
		constructor(props, context) {
			super(props, context);
			this.AnnotationRoot = createAnnotationRoot(this);
		}

		render() {
			return this.isInitialized() ? this.renderAnnotationComponent() : false;
		}

		renderAnnotationComponent() {
			const {annotationProperties} = this.props;
			return (
				<AnnotationComponent AnnotationRoot={this.AnnotationRoot}
				                     readOnly={annotationProperties.get('readOnly', false)} {...this.props} />
			);
		}

		componentDidMount() {
			const {onAnnotationPropertiesChanged, annotationId} = this.props;
			if (!this.isInitialized()) {
				onAnnotationPropertiesChanged(annotationId, getDefaultAnnotationProperties(this.props));
			}
		}

		isInitialized() {
			const {annotationProperties} = this.props;
			return annotationProperties !== UNINITIALIZED_ANNOTATION_PROPERTIES;
		}
	}
	Annotation.propTypes = {
		...HIGHER_ORDER_ANNOTATION_PROP_TYPES
	};
	Annotation.isAnnotationSupportedForViewerItem = isAnnotationSupported;

	return Annotation;
}

const HIGHER_ORDER_ANNOTATION_PROP_TYPES = Object.freeze({
	annotationId: PropTypes.string,
	annotationType: PropTypes.string,
	active: PropTypes.bool,
	onActivate: PropTypes.func,
	fontSize: PropTypes.number,
	lineHeight: PropTypes.number,
	annotationProperties: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.oneOf([UNINITIALIZED_ANNOTATION_PROPERTIES])
	]),
	onAnnotationPropertiesChanged: PropTypes.func,
	transformationMatrix: PropTypes.instanceOf(Float32Array),
	inverseTransformationMatrix: PropTypes.instanceOf(Float32Array),
	isPrintPreview: PropTypes.bool,
	locale: PropTypes.string,
	viewerItem: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.instanceOf(Immutable.Record)
	]),
	containerWidth: PropTypes.number,
	containerHeight: PropTypes.number
});

export const ANNOTATION_PROP_TYPES = Object.freeze({
	AnnotationRoot: PropTypes.elementType,
	readOnly: PropTypes.bool,
	...HIGHER_ORDER_ANNOTATION_PROP_TYPES
});

function createAnnotationRoot(annotationInstance) {
	function AnnotationRoot(passedProps) {
		const {
			annotationId, annotationType, active, onActivate, fontSize, lineHeight,
			annotationProperties = Immutable.Map(), isPrintPreview
		} = annotationInstance.props;
		const {onMouseEnter, onMouseLeave, className, children} = passedProps;
		const readOnly = annotationProperties.get('readOnly', false);
		const finalClassName = combineClassNames(`${annotationType}-annotation`, className);
		return (
			<AnnotationRootSVGGroup annotationId={annotationId} className={finalClassName}
									readOnly={readOnly} active={active} fontSize={fontSize}
									isPrintPreview={isPrintPreview} lineHeight={lineHeight}
									onActivate={onActivate} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
				{children}
			</AnnotationRootSVGGroup>
		);
	}
	AnnotationRoot.displayName = 'AnnotationRoot';
	return AnnotationRoot;
}
