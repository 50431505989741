import React from 'react';

import IconButton from './IconButton.js';
import MoreVertIcon from './icons/MoreVertIcon.js';

function MoreVertButton(props) {
	return (
		<IconButton {...props}>
			<MoreVertIcon />
		</IconButton>
	);
}

MoreVertButton.propTypes = IconButton.propTypes;
MoreVertButton.defaultProps = IconButton.defaultProps;

export default React.memo(MoreVertButton);
