import {FEATURE_INTEGRATED_PDF_VIEWER_ENABLED} from '../../commons/constants/SynSettingsConstants.js';

const DISABLE_INTEGRATED_PDF_VIEWER_KEY = 'disableIntegratedPdfViewer';

export function isIntegratedPdfViewerExplicitlyDisabled() {
	const storageValue = sessionStorage[DISABLE_INTEGRATED_PDF_VIEWER_KEY] ||
		localStorage[DISABLE_INTEGRATED_PDF_VIEWER_KEY];
	return !FEATURE_INTEGRATED_PDF_VIEWER_ENABLED ||
		(Boolean(storageValue) && storageValue.toLowerCase() === 'true');
}

export function getNumberOfPages(pdfDocument) {
	return Boolean(pdfDocument) && pdfDocument.numPages || 0;
}

export function getScaledPageViewport(pdfPage, scale = 1.0) {
	return pdfPage.getViewport({scale});
}

export function getPageSize(pdfPage) {
	const viewport = getScaledPageViewport(pdfPage);
	return viewport ? {width: viewport.width, height: viewport.height} : null;
}

export function calculateViewportScale(pdfPage, renderWidth, renderHeight) {
	const pageSize = getPageSize(pdfPage);
	const {width: pageWidth, height: pageHeight} = pageSize;
	return Math.min(
		renderWidth / pageWidth,
		renderHeight / pageHeight
	);
}
