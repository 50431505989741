import {useEffectEasily, useMemoFactory} from './index.js';

/**
 * Returns the object url for the passed obj.
 * The url is created using URL.createObjectURL.
 * The hook also rejects the created object url on unmount by calling URL.revokeObjectURL.
 * @param obj - object to create url for (see documentation of URL.createObjectURL for details).
 * @returns {*} - the objects url to be passed to various html elements or WebAPI functions.
 */
export default function useObjectUrl(obj) {
	const objectUrl = useMemoFactory(URL.createObjectURL, obj);
	useEffectEasily(url => () => URL.revokeObjectURL(url), objectUrl);
	return objectUrl;
}
