import React from 'react';

import {toContainerPosition} from '../../../utils/AnnotationUtils.js';
import {createLineBetween, intersectLines2d} from '../../../utils/math/Line.js';
import {getSquaredDistance} from '../../../utils/VectorUtils.js';
import SupportPath from '../SupportPath.js';
import GoniometryAnnotationBase from './GoniometryAnnotationBase.js';

const SEGMENT_B_POINTS_OFFSET = 2;

export default class FourPointGoniometryAnnotation extends GoniometryAnnotationBase {
	constructor(props, context) {
		super(props, context);
		this.onUpdatePointsOfSegmentB = this.onPointsUpdate.bind(this, SEGMENT_B_POINTS_OFFSET);
	}

	render() {
		const {annotationProperties} = this.props;
		const points = annotationProperties.get('points');
		const segmentAPoints = points.slice(0, 2);
		const segmentBPoints = points.slice(SEGMENT_B_POINTS_OFFSET);
		const intersectionPoint = intersectLines2d(
			createLineBetween(...segmentAPoints),
			createLineBetween(...segmentBPoints)
		);
		const sortedSegmentA = sortByDistanceFrom(intersectionPoint, segmentAPoints);
		const [closestPointOnSegmentA, farthestPointOnSegmentA] = sortedSegmentA;

		const sortedSegmentB = sortByDistanceFrom(intersectionPoint, segmentBPoints);
		const [closestPointOnSegmentB, farthestPointOnSegmentB] = sortedSegmentB;

		const supportPathPoints = [closestPointOnSegmentA, intersectionPoint, closestPointOnSegmentB];
		const labelText = this.calcLabel(points, supportPathPoints);
		const containerSupportPathPoints = supportPathPoints.map(point => toContainerPosition(this.props, point));
		const additionalElements = (
			<React.Fragment>
				<SupportPath points={containerSupportPathPoints} />
				{this.renderModifiablePath(segmentBPoints, this.onUpdatePointsOfSegmentB)}
			</React.Fragment>
		);
		const annotationAlignmentPath = [
			toContainerPosition(this.props, farthestPointOnSegmentA),
			containerSupportPathPoints[1],
			toContainerPosition(this.props, farthestPointOnSegmentB)
		];
		return this.renderAnnotation(segmentAPoints, labelText, annotationAlignmentPath, additionalElements);
	}
}

function sortByDistanceFrom(reference, points) {
	const sortedPoints = [...points];
	sortedPoints.sort((a, b) => getSquaredDistance(reference, a) - getSquaredDistance(reference, b));
	return sortedPoints;
}
