import React, {useContext} from 'react';
import {deDE, enUS, frFR} from '@mui/material/locale';
import {createTheme, ThemeProvider as MUIThemeProvider} from '@mui/material/styles';
import PropTypes from 'prop-types';

import {wrapInLocalizationContext} from '../../../i18n/components/LocalizationContextWrapper.js';
import {getLanguageAttribute} from '../../../i18n/i18nUtils.js';
import {SYN_MUI_THEME} from '../../constants/SynMUIOptions.js';

const DEFAULT_THEME_CONTEXT = React.createContext(null);
const DefaultThemeProvider = DEFAULT_THEME_CONTEXT.Provider;

const MUI_LOCALES = new Map([
	['en', enUS],
	['de', deDE],
	['fr', frFR]
]);

function getMUILanguageTag(locale) {
	if (!MUI_LOCALES.has(locale)) {
		throw new ReferenceError(`MUI language tag not found for locale '${locale}'`);
	}

	return (MUI_LOCALES.get(locale));
}

class ThemeProvider extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		const {locale} = this.props;
		this.theme = createTheme(SYN_MUI_THEME, getMUILanguageTag(getLanguageAttribute(locale)));
	}

	render() {
		const {children} = this.props;
		return (
			<MUIThemeProvider theme={this.theme}>
				<DefaultThemeProvider value={this.theme}>
					{children}
				</DefaultThemeProvider>
			</MUIThemeProvider>
		);
	}
}

ThemeProvider.propTypes = {
	locale: PropTypes.string
};

/**
 * Always returns the theme provided by ThemeProvider.
 * Its result is not affected by nested ThemProviders shadowing the outer theme.
 * @returns {*} - the theme provided by ThemeProvider
 */
export function useDefaultTheme() {
	return useContext(DEFAULT_THEME_CONTEXT);
}

/**
 * Use this component to provide the default application theme.
 * It is based on the MUI-Default theme including synedra specific colors and adaptations.
 */
export default wrapInLocalizationContext(ThemeProvider);
