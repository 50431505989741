import {useMemo} from 'react';
import {useDispatch} from 'react-redux';

export function useAction(action, ...boundArgs) {
	const dispatch = useDispatch();
	// eslint-disable-next-line react-hooks/exhaustive-deps -- rule cannot evaluate spread dependencies. That's OK
	return useMemo(() => bindAction(dispatch, action, boundArgs), [action, dispatch, ...boundArgs]);
}

function bindAction(dispatch, action, boundArgs) {
	return function boundAction(...args) {
		dispatch(action(...boundArgs, ...args));
	};
}
