import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import TwoLineListItem from '../../../material-design/components/layout/TwoLineListItem.js';
import PrimaryItemText from '../../../material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../../material-design/components/text/SecondaryItemText.js';
import UnhandledErrorsDialogTranslator from './i18n/UnhandledErrorsDialogTranslator.js';

import '../../../../styles/commons/components/errors/ErrorDetails.scss';

const MAX_ERROR_DATA_LENGTH = 300;

function formatStack(stackTrace) {
	return stackTrace.split(' at ').reduce((allParts, part, index) => {
		const key = `${index}_part`;
		if (allParts.length > 0) {
			allParts.push(
				<span key={key}>
					<br />
					{' '}
at
					{' '}
					{part}
				</span>
			);
		} else {
			allParts.push(
				<span key={key}>
					{part}
				</span>
			);
		}
		return allParts;
	}, []);
}

function formatErrorData(obj) {
	const data = JSON.stringify(obj);
	return data.substring(0, Math.min(MAX_ERROR_DATA_LENGTH, data.length));
}

export default function ErrorDetails(props) {
	const {error, className} = props;
	const {stack, errorData} = error;
	const stackInfo = Boolean(stack) && (
		<TwoLineListItem style={{height: 'initial'}}>
			<PrimaryItemText>
Stacktrace
			</PrimaryItemText>
			<SecondaryItemText className='error-details-content'>
				{formatStack(stack)}
			</SecondaryItemText>
		</TwoLineListItem>
	);

	const details = Boolean(errorData) && (
		<TwoLineListItem style={{height: 'initial'}}>
			<PrimaryItemText>
				<SynFormattedMessage translator={UnhandledErrorsDialogTranslator} message='ErrorDetailsLabel' />
			</PrimaryItemText>
			<SecondaryItemText className='error-details-content'>
				{formatErrorData(errorData)}
			</SecondaryItemText>
		</TwoLineListItem>
	);

	const pureError = !details && (
		<TwoLineListItem style={{height: 'initial'}}>
			<PrimaryItemText>
				<SynFormattedMessage translator={UnhandledErrorsDialogTranslator} message='ErrorDetailsLabel' />
			</PrimaryItemText>
			<SecondaryItemText className='error-details-content'>
				{error.toString ? error.toString() : JSON.stringify(error)}
			</SecondaryItemText>
		</TwoLineListItem>
	);

	return (
		<div className={className}>
			{stackInfo}
			{details}
			{pureError}
		</div>
	);
}

ErrorDetails.propTypes = {
	error: PropTypes.instanceOf(Error),
	className: PropTypes.string
};
