import React from 'react';
import _debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import {memoizeLast, noop, synchronizedWithAnimationFrame} from '../../../commons/utils/FunctionUtils.js';
import {cancellable} from '../../../commons/utils/PromiseUtils.js';
import loadPdfJS from '../../../external/loadPdfJS.js';
import {calculateViewportScale, getScaledPageViewport} from '../../utils/PDFUtils.js';

const RENDER_DELAY = 500;

function delayedAndSynchronizedWithAnimationFrame(fn, delay) {
	const syncedWithAnimationFrame = synchronizedWithAnimationFrame(fn, noop);
	const delayed = _debounce(syncedWithAnimationFrame, delay);
	const cancelDelayed = delayed.cancel;
	delayed.cancel = () => {
		syncedWithAnimationFrame.stop();
		cancelDelayed();
	};
	return delayed;
}

export default class TextSelectionOverlay extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.textWrapperElementRef = React.createRef();
		this.makeStyle = memoizeLast(TextSelectionOverlay.makeStyle.bind(this));

		this.renderTextDelayed = delayedAndSynchronizedWithAnimationFrame(this.renderText.bind(this), RENDER_DELAY);
		this.pdfjs = null;
		this.state = {
			textContent: null,
			currentTask: null
		};
		loadPdfJS().then(
			pdfjs => {
				this.pdfjs = pdfjs;
			}
		);
	}

	render() {
		const {width, height} = this.props;
		const style = this.makeStyle(width, height);
		return <div key='pdfjs-text-layer' ref={this.textWrapperElementRef} className='pdf-page--textlayer' style={style} />;
	}

	renderText() {
		const {pdfPage, width, height} = this.props;
		const {textContent} = this.state;
		const scale = calculateViewportScale(pdfPage, width, height);
		const viewport = getScaledPageViewport(pdfPage, scale);
		const container = this.textWrapperElementRef.current;
		const renderTask = this.pdfjs.renderTextLayer({textContentSource: textContent, container, viewport});
		this.setCurrentTask(renderTask);
	}
	
	static makeStyle(width, height) {
		return {width, height};
	}

	setCurrentTask(task) {
		const taskPromise = task.promise ? task.promise : task;
		const cancellableTask = cancellable(taskPromise);
		if (task.cancel) {
			cancellableTask.cancelled(task.cancel.bind(task));
		}
		cancellableTask.finally(() => this.setState({currentTask: null}));
		this.setState({currentTask: cancellableTask});
	}

	cancelCurrentTask() {
		const {currentTask} = this.state;
		if (currentTask) {
			currentTask.cancel();
			this.setState({currentTask: null});
		}
	}

	loadTextContent(pdfPage) {
		const loadTask = pdfPage.getTextContent()
			.then(textContent => this.setTextContent(textContent));
		this.setCurrentTask(loadTask);
	}

	setTextContent(textContent) {
		this.setState({textContent});
	}

	scheduleTextRendering() {
		const {textContent} = this.state;
		if (textContent && this.pdfjs) {
			this.cancelCurrentTask();
			this.clearTextWrapper();
			this.renderTextDelayed();
		}
	}

	clearTextWrapper() {
		const textWrapper = this.textWrapperElementRef.current;
		while (textWrapper.hasChildNodes()) {
			textWrapper.removeChild(textWrapper.lastChild);
		}
	}

	componentDidMount() {
		const {pdfPage} = this.props;
		this.loadTextContent(pdfPage);
	}

	componentDidUpdate(prevProps, prevState) {
		const {width, height, pdfPage} = this.props;
		const {textContent} = this.state;
		const layoutParametersChanged =
				width !== prevProps.width ||
				height !== prevProps.height;


		const textContentChanged = textContent !== prevState.textContent;
		if (pdfPage !== prevProps.pdfPage) {
			this.loadTextContent(pdfPage);
		} else if (textContentChanged || layoutParametersChanged) {
			this.scheduleTextRendering();
		}
	}

	componentWillUnmount() {
		this.cancelCurrentTask();
		this.renderTextDelayed.cancel();
	}
}

TextSelectionOverlay.propTypes = {
	pdfPage: PropTypes.object,
	width: PropTypes.number,
	height: PropTypes.number
};
