import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import Button from './Button.js';

function LinkLikeButton(props) {
	const {children, labelLeftAligned, ...remainingProps} = props;
	const sxProps = useMemoFactory(getSxProps, labelLeftAligned);
	return (
		<Button sx={sxProps} {...remainingProps}>
			{children}
		</Button>
	);
}

LinkLikeButton.propTypes = {
	...Button.propTypes,
	labelLeftAligned: PropTypes.bool
};

LinkLikeButton.defaultProps = {
	...Button.defaultProps
};

function getSxProps(labelLeftAligned) {
	const styles = {
		textTransform: 'none',
		textDecoration: 'underline'
	};
	if (labelLeftAligned) {
		styles.textAlign = 'left';
	}
	return styles;
}

export default React.memo(LinkLikeButton);
