import Immutable from 'immutable';

import DICOM_TAG_NAMES_MAP from '../constants/DicomTagNamesMap.js';
import {
	ABBR_TO_ORIENTATION,
	OPPOSITE_DIRECTIONS,
	ORIENTATION_ANTERIOR, ORIENTATION_FOOT, ORIENTATION_HEAD,
	ORIENTATION_LEFT,
	ORIENTATION_POSTERIOR,
	ORIENTATION_RIGHT, ORIENTATION_TO_ABBR
} from '../constants/OrientationConstants.js';

export function createTagEntry(tagId, value) {
	if (!DICOM_TAG_NAMES_MAP.has(tagId)) {
		throw new Error(`TagName for tag id (${tagId}) not known, yet.`);
	}
	return Immutable.Map({
		value,
		tagName: DICOM_TAG_NAMES_MAP.get(tagId)
	});
}

export function calculateOrientationDescription(unitVector) {
	const numComponents = 3;
	const absComponentLimit = 0.0001;
	const orientations = [
		unitVector[0] > 0 ? [ORIENTATION_RIGHT, ORIENTATION_LEFT] : [ORIENTATION_LEFT, ORIENTATION_RIGHT],
		unitVector[1] > 0
			? [ORIENTATION_ANTERIOR, ORIENTATION_POSTERIOR]
			: [ORIENTATION_POSTERIOR, ORIENTATION_ANTERIOR],
		unitVector[2] > 0 ? [ORIENTATION_FOOT, ORIENTATION_HEAD] : [ORIENTATION_HEAD, ORIENTATION_FOOT]
	];

	const descriptions = [];
	for (let component = 0; component < numComponents; ++component) {
		const absComponent = Math.abs(unitVector[component]);
		if (absComponent > absComponentLimit) {
			descriptions.push([absComponent, orientations[component]]);
		}
	}
	descriptions.sort((a, b) => b[0] - a[0]);
	return reorganizeDescriptions(descriptions);
}

function reorganizeDescriptions(descriptions) {
	return descriptions.reduce(
		(reorganizedDescriptions, description) => {
			const orientations = description[1];
			reorganizedDescriptions[0].push(orientations[0]);
			reorganizedDescriptions[1].push(orientations[1]);
			return reorganizedDescriptions;
		}
		, [[], []]);
}

export function getOppositeOrientations(orientations) {
	return orientations
		.map(orientation => OPPOSITE_DIRECTIONS[orientation]);
}

export function getOrientationsFromAbbreviations(abbreviations) {
	return [...abbreviations]
		.map(abbr => ABBR_TO_ORIENTATION[abbr.toUpperCase()]);
}

export function translateOrientations(orientations) {
	return (orientations && orientations.map(translateOrientation).join('')) || '';
}

function translateOrientation(orientation) {
	return ORIENTATION_TO_ABBR[orientation] || '';
}
