import React, {useCallback, useContext} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {batchActions} from 'redux-batched-actions';

import {getAnnotationToolId} from '../../components/annotations.js';
import ViewerContext from '../../components/ViewerContext.js';
import ViewerToolsMenuEntry from '../../components/ViewerToolsMenuEntry.js';
import {addAnnotation} from '../actions/AnnotationActions.js';
import {showAnnotations} from '../actions/ImageViewerActions.js';

function AnnotationCreationMenuEntryContainer(props) {
	const {
		itemId,
		itemType,
		annotationType,
		label,
		icon
	} = props;
	const metricsKey = getAnnotationToolId(annotationType);
	const {viewerId} = useContext(ViewerContext);
	const dispatch = useDispatch();
	const onClick = useCallback(
		() => dispatch(
			batchActions([
				addAnnotation(itemType, itemId, annotationType),
				showAnnotations(viewerId)
			])
		),
		[annotationType, dispatch, itemId, itemType, viewerId]
	);

	return (
		<ViewerToolsMenuEntry id={annotationType} onClick={onClick}
							  label={label} icon={icon}
							  metricsKey={metricsKey} />
	);
}

AnnotationCreationMenuEntryContainer.propTypes = {
	itemType: PropTypes.string.isRequired,
	itemId: PropTypes.number.isRequired,
	annotationType: PropTypes.string.isRequired,
	label: PropTypes.func.isRequired,
	icon: PropTypes.elementType.isRequired
};

export default AnnotationCreationMenuEntryContainer;
