import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import AnnotationMenuEntries from '../../components/AnnotationMenuEntries.js';
import {useDicomViewerState} from '../../components/dicom/DicomSeriesContextProvider.js';
import {selectGenericFileViewerItem, selectImageViewerItem} from '../selectors/ViewerItemsSelectors.js';

export function DicomImageAnnotationMenuEntriesContainer() {
	const {currentImageId: imageId} = useDicomViewerState();
	const viewerItemSelector = useMemo(() => selectImageViewerItem(imageId), [imageId]);
	return <AnnotationMenuEntries selectViewerItem={viewerItemSelector} />;
}

export function GenericImageAnnotationMenuEntriesContainer(props) {
	const {fileId} = props;
	const viewerItemSelector = useMemo(() => selectGenericFileViewerItem(fileId), [fileId]);
	return <AnnotationMenuEntries selectViewerItem={viewerItemSelector} />;
}
GenericImageAnnotationMenuEntriesContainer.propTypes = {
	fileId: PropTypes.number
};
