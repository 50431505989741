import Immutable from 'immutable';
import {createSelector} from 'reselect';

export const selectStructuredData = state => state.structuredData || Immutable.Map();

export function createStructureSelector(structureId) {
	return createSelector([selectStructuredData], structuredData => structuredData.get(structureId) || Immutable.Map());
}

export function createFieldSelector(structureSelector, fieldId, defaultValue) {
	return createSelector([structureSelector], structure => structure.get(fieldId) || defaultValue);
}
