import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';

import '../../../styles/material-design/components/DialogButtonContainer.scss';

export default function DialogButtonContainer(props) {
	const {alignVertically, className, children} = props;
	let enrichedClassName = classNames({
		'material-dialogbuttoncontainer': true,
		'align-vertically': Boolean(alignVertically)
	});
	if (className) {
		enrichedClassName = combineClassNames(enrichedClassName, className);
	}

	return (
		<div className={enrichedClassName}>
			{children}
		</div>
	);
}

DialogButtonContainer.propTypes = {
	alignVertically: PropTypes.bool,
	className: PropTypes.string
};
