import React from 'react';
import {styled} from '@mui/material/styles';
import color from 'color';
import PropTypes from 'prop-types';

import PrimaryTileText from '../../../webview/material-design/components/text/PrimaryTileText.js';
import SecondaryTileText from '../../../webview/material-design/components/text/SecondaryTileText.js';
import VerticalLayout from '../../../webview/ui/components/layout/VerticalLayout.js';

const BACKGROUND_ALPHA = 0.7;
export default styled(UploadFileTileCaption, {name: 'UploadFileTileCaption'})(props => {
	const {theme} = props;
	return {
		height: '42px',
		maxHeight: '42px',
		boxSizing: 'border-box',
		maxWidth: '100%',
		padding: '0 6px 0 6px',
		backgroundColor: color(theme.palette.primary.dark).alpha(BACKGROUND_ALPHA)
			.string(),
		color: theme.palette.primary.contrastText,
		overflow: 'hidden'
	};
});

function UploadFileTileCaption(props) {
	const {fileName, fileSize, className} = props;
	return (
		<VerticalLayout className={className} justify={'center'} align={'start'} noGrow noShrink>
			<PrimaryTileText>
				{fileName}
			</PrimaryTileText>
			<SecondaryTileText>
				{fileSize}
			</SecondaryTileText>
		</VerticalLayout>
	);
}

UploadFileTileCaption.propTypes = {
	fileName: PropTypes.string,
	fileSize: PropTypes.node,
	className: PropTypes.string
};
