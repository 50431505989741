import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import {convertPositionToTransformProperty} from '../../utils/SVGUtils.js';

import '../../../../styles/viewer/components/annotations/AnnotationLabel.scss';

export default function AnnotationLabel(props) {
	const {className, x, y, textAnchor, children} = props;
	const finalTextClassName = combineClassNames('annotation-label', className);
	const finalShadowClassName = combineClassNames('annotation-label--shadow', className);

	const textTransform = convertPositionToTransformProperty(x, y);
	const shadowTransform = convertPositionToTransformProperty(x + 1, y + 1);
	return (
		<g>
			<text className={finalShadowClassName} transform={shadowTransform} textAnchor={textAnchor}>
				{children}
			</text>
			<text className={finalTextClassName} transform={textTransform} textAnchor={textAnchor}>
				{children}
			</text>
		</g>
	);
}

AnnotationLabel.propTypes = {
	x: PropTypes.number,
	y: PropTypes.number,
	textAnchor: PropTypes.string,
	className: PropTypes.string
};
