import {toRelativePath} from '../../../webview/commons/utils/URLUtils.js';
import {loadReferencedFilesFromFile} from '../../dicom/dicomDir.js';

const DICOMDIR_FILENAME = 'DICOMDIR';

export default async function dicomDirFilter(fileList) {
	const dicomDirPaths = [];
	const fileSetsPromises = [];
	const ordinaryFiles = [];
	fileList.forEach(entry => {
		const {name, path, file} = entry;
		if (fitsDicomDirNamingScheme(name)) {
			let containingPath = path.slice(0, -name.length).toLowerCase();
			if (!containingPath.endsWith('/')) {
				containingPath = `${containingPath}/`;
			}
			dicomDirPaths.push(containingPath);
			fileSetsPromises.push(loadFilesSet(containingPath, file));
		} else {
			ordinaryFiles.push(entry);
		}
	});
	const dicomDirFileSets = await Promise.all(fileSetsPromises);
	return ordinaryFiles.filter(fileItem => {
		const {path} = fileItem;
		const fileIsReferenced = isReferencedInDicomDir(path, dicomDirFileSets);
		const belowDicomDirPath = fileIsReferenced || isBelowDicomDirPaths(path, dicomDirPaths);
		return fileIsReferenced || !belowDicomDirPath;
	});
}

async function loadFilesSet(containingPath, file) {
	let filesSet;
	try {
		const referencedFiles = await loadReferencedFilesFromFile(file);
		filesSet = buildFilesSet(containingPath, referencedFiles);
	} catch (error) {
		filesSet = new Set();
	}
	return filesSet;
}

function buildFilesSet(dicomDirContainingPath, referencedFiles) {
	const normalizedFilePaths = referencedFiles
		.map(filePath => {
			const normalizedPath = toRelativePath(filePath.replaceAll('\\', '/').toLowerCase());
			return `${dicomDirContainingPath}${normalizedPath}`;
		});
	return new Set(normalizedFilePaths);
}

function isReferencedInDicomDir(filePath, fileSets) {
	let isContained = false;
	const lowerCasePath = filePath.toLowerCase();
	for (let mapIndex = 0; mapIndex < fileSets.length && !isContained; ++mapIndex) {
		isContained = fileSets[mapIndex].has(lowerCasePath) || '';
	}
	return isContained;
}

function isBelowDicomDirPaths(filePath, dicomDirPaths) {
	return dicomDirPaths.find(
		dicomDirPath => filePath.toLowerCase().startsWith(dicomDirPath)
	) !== undefined;
}

function fitsDicomDirNamingScheme(fileName) {
	const sanitizedFileName = (fileName || '')
		.toUpperCase()
		.trim();
	return sanitizedFileName === DICOMDIR_FILENAME;
}
