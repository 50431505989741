export const ORIENTATION_LEFT = 'ORIENTATION_LEFT';
export const ORIENTATION_RIGHT = 'ORIENTATION_RIGHT';
export const ORIENTATION_ANTERIOR = 'ORIENTATION_ANTERIOR';
export const ORIENTATION_POSTERIOR = 'ORIENTATION_POSTERIOR';
export const ORIENTATION_HEAD = 'ORIENTATION_HEAD';
export const ORIENTATION_FOOT = 'ORIENTATION_FOOT';

export const ORIENTATION_TO_ABBR = {
	[ORIENTATION_LEFT]: 'L',
	[ORIENTATION_RIGHT]: 'R',
	[ORIENTATION_ANTERIOR]: 'A',
	[ORIENTATION_POSTERIOR]: 'P',
	[ORIENTATION_HEAD]: 'H',
	[ORIENTATION_FOOT]: 'F'
};

export const ABBR_TO_ORIENTATION = {
	L: ORIENTATION_LEFT,
	R: ORIENTATION_RIGHT,
	A: ORIENTATION_ANTERIOR,
	P: ORIENTATION_POSTERIOR,
	H: ORIENTATION_HEAD,
	F: ORIENTATION_FOOT
};

export const OPPOSITE_DIRECTIONS = {
	[ORIENTATION_LEFT]: ORIENTATION_RIGHT,
	[ORIENTATION_RIGHT]: ORIENTATION_LEFT,
	[ORIENTATION_HEAD]: ORIENTATION_FOOT,
	[ORIENTATION_FOOT]: ORIENTATION_HEAD,
	[ORIENTATION_ANTERIOR]: ORIENTATION_POSTERIOR,
	[ORIENTATION_POSTERIOR]: ORIENTATION_ANTERIOR
};
