import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {number} from 'prop-types';
import {createSelector, createStructuredSelector} from 'reselect';

import IntegratedPdfViewer from '../../components/IntegratedPdfViewer.js';
import {getRawPdf} from '../../utils/ViewerItemUtils.js';
import {loadPdfFile} from '../actions/ViewerItemsActions.js';
import {hasFileLoadStarted,	selectGenericFileViewerItem} from '../selectors/ViewerItemsSelectors.js';

function createStateSelector(fileId) {
	const selectFile = selectGenericFileViewerItem(fileId, null);
	return createStructuredSelector({
		hasDownloadStarted: hasFileLoadStarted(fileId),
		fileViewerItem: selectFile,
		rawPdf: createSelector(selectFile, getRawPdf)
	});
}

export default function IntegratedPdfViewerContainer({fileId}) {
	const dispatch = useDispatch();
	const stateSelector = useMemo(() => createStateSelector(fileId), [fileId]);
	const {rawPdf, ...stateProps} = useSelector(stateSelector);
	const loadViewerItem = useCallback(() => dispatch(loadPdfFile(fileId)), [fileId, dispatch]);
	return (<IntegratedPdfViewer fileId={fileId}
		loadViewerItem={loadViewerItem}
		pdfFile={rawPdf}
		{...stateProps} />);
}

IntegratedPdfViewerContainer.propTypes = {
	fileId: number.isRequired
};
