import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import EventTracker from '../../commons/components/EventTracker.js';
import {refPropType} from '../../commons/utils/CustomPropTypes.js';
import {findFirstEventProperty, stopOriginalDOMEvent, toHandlerName} from '../../commons/utils/EventUtils.js';
import {callSafe, noop, synchronizedWithAnimationFrame} from '../../commons/utils/FunctionUtils.js';
import chain from '../../events/createChainedEventProcessor.js';
import renameEvent from '../../events/createEventRenamer.js';
import moveRecognizer from '../../events/createMoveRecognizer.js';
import multiplexEvents from '../../events/createMultiplexerEventProcessor.js';
import propertyTracker from '../../events/createPropertyTracker.js';
import selectBy from '../../events/createSelectingEventProcessor.js';
import tapRecognizer from '../../events/createTapRecognizer.js';
import activeToolRecognizer from '../events/createToolRecognizer.js';
import stopToolEvent from '../events/stopToolEvent.js';

const WINDOW_TOOL = 'WindowTool';
const ZOOM_TOOL = 'ZoomTool';
const PAN_TOOL = 'PanTool';
const PINCH_ZOOM_TOOL = 'PinchZoom';
const DURATION_SINGLE_FRAME = 17; //time of a single frame in 60fps
const MSEC_TO_SEC_DIVISOR = 1000;

export default class ImageViewerGesturesRecognizer extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.scheduledDispatchEvents = synchronizedWithAnimationFrame(this.dispatchEvents.bind(this));
		this.eventProcessor = createEventProcessor(props, this.scheduledDispatchEvents);
		this.eventTrackerNode = React.createRef();
		this.resetLastActiveTool();
	}

	render() {
		const {className, children, targetRef} = this.props;
		return (
			<EventTracker ref={this.eventTrackerNode} targetRef={targetRef} onInput={this.eventProcessor}
			              className={className}>
				{children}
			</EventTracker>
		);
	}

	dispatchEvents(scheduledArgs) {
		let swipeEvents = null;
		scheduledArgs.forEach(scheduledArg => {
			const [event] = scheduledArg;
			const type = event.get('type');
			if (type === 'oneFingerSwipe' || type === 'twoFingersSwipe') {
				if (swipeEvents && swipeEvents.type !== type) {
					const {type: swipeType, args} = swipeEvents;
					this.dispatchEvent(swipeType, args);
					swipeEvents = null;
				}
				if (swipeEvents) {
					swipeEvents.args.push(event);
				} else {
					swipeEvents = {type, args: [event]};
				}
			} else {
				if (swipeEvents) {
					const {type: swipeType, args} = swipeEvents;
					this.dispatchEvent(swipeType, args);
					swipeEvents = null;
				}
				this.dispatchEvent(type, event);
			}
		});
		if (swipeEvents) {
			const {type, args} = swipeEvents;
			this.dispatchEvent(type, args);
		}
	}

	dispatchEvent(type, args) {
		const eventHandlerName = toHandlerName(type);
		const eventHandler = this[eventHandlerName];
		if (eventHandler) {
			eventHandler.call(this, args);
		}
	}

	//is implicitly called by dispatchEvent
	//noinspection JSUnusedGlobalSymbols
	onToolChanged(event) {
		const {onToolChanged} = this.props;
		this.resetLastActiveTool();
		callSafe(onToolChanged, event.get('tool'));
	}

	//is implicitly called by dispatchEvent
	//noinspection JSUnusedGlobalSymbols
	onOneFingerSwipe(swipeEvents) {
		let currentTool = null;
		let events = null;
		swipeEvents.forEach(event => {
			const tool = findFirstEventProperty(event, 'tool', '');
			if (currentTool !== tool && events) {
				this.invokeOneFingerSwipeToolHandler(currentTool, events);
				events = null;
			}
			if (events) {
				events.push(event);
			} else {
				currentTool = tool;
				events = [event];
			}
		});
		if (events) {
			this.invokeOneFingerSwipeToolHandler(currentTool, events);
		}
	}

	invokeOneFingerSwipeToolHandler(tool, oneFingerSwipeEvents) {
		const lastEvent = oneFingerSwipeEvents[oneFingerSwipeEvents.length - 1];
		const [firstEvent] = oneFingerSwipeEvents;
		const totalDiff = vec2.subtract(vec2.create(), lastEvent.get('to').first(), firstEvent.get('from').first());
		switch (tool) {
			case 'window':
				this.handleWindowEvents(firstEvent, lastEvent, totalDiff);
				break;
			case 'zoom':
				this.handleZoomEvents(firstEvent, lastEvent, totalDiff);
				break;
			case 'pan':
				this.handlePanEvents(firstEvent, lastEvent, totalDiff);
				break;
			default:
				break;
		}
	}

	handleWindowEvents(firstEvent, lastEvent, totalDiff) {
		const {onWindow} = this.props;
		let duration = findFirstEventProperty(lastEvent, 'timeStamp', 0) - findFirstEventProperty(firstEvent, 'timeStamp', 0);
		if (duration <= 0) {
			duration = DURATION_SINGLE_FRAME;
		}
		const durationSec = duration / MSEC_TO_SEC_DIVISOR;
		const windowChange = totalDiff
			.map(diff => diff / durationSec);
		const [windowWidthChangeRate, windowCenterChangeRate] = windowChange;
		this.handleToolActivation(WINDOW_TOOL);
		callSafe(onWindow, windowCenterChangeRate, windowWidthChangeRate);
	}

	handleZoomEvents(firstEvent, lastEvent, totalDiff) {
		const zoomDelta = totalDiff[1];
		const {onZoom} = this.props;
		const startPosition = firstEvent.get('start').first();
		if (zoomDelta !== 0) {
			this.handleToolActivation(ZOOM_TOOL);
			callSafe(onZoom, startPosition[0], startPosition[1], zoomDelta);
		}
	}

	handlePanEvents(firstEvent, lastEvent, totalDiff) {
		const {onPan} = this.props;
		if (totalDiff[0] !== 0 || totalDiff[1] !== 0) {
			this.handleToolActivation(PAN_TOOL);
			callSafe(onPan, totalDiff[0], totalDiff[1]);
		}
	}

	//is implicitly called by dispatchEvent
	//noinspection JSUnusedGlobalSymbols
	onTwoFingersSwipe(events) {
		const [firstEvent] = events;
		const {onPinchZoom} = this.props;
		const firstPointerId = firstEvent.get('pointerIds').get(0);
		const secondPointerId = firstEvent.get('pointerIds').get(1);

		const firstFromPoint = firstEvent.getIn(['from', firstPointerId]);
		const secondFromPoint = firstEvent.getIn(['from', secondPointerId]);
		const fromPinchMetrics = calculatePinchMetrics(firstFromPoint, secondFromPoint);

		const lastEvent = events[events.length - 1];
		const firstToPoint = lastEvent.getIn(['to', firstPointerId]);
		const secondToPoint = lastEvent.getIn(['to', secondPointerId]);
		const toPinchMetrics = calculatePinchMetrics(firstToPoint, secondToPoint);

		const panDiff = vec2.subtract(vec2.create(), toPinchMetrics.center, fromPinchMetrics.center);
		this.handleToolActivation(PINCH_ZOOM_TOOL);
		callSafe(onPinchZoom, panDiff[0], panDiff[1], toPinchMetrics.center[0],
			toPinchMetrics.center[1], toPinchMetrics.length / fromPinchMetrics.length);
	}

	//is implicitly called by dispatchEvent
	//noinspection JSUnusedGlobalSymbols
	onSingleTap(event) {
		const {width} = this.eventTrackerNode.current.getTrackingRect();
		if (width > 0) {
			const [xPos] = event.get('position');
			const {onGoToPrevious, onGoToNext} = this.props;
			if (xPos < width / 2) {
				callSafe(onGoToPrevious);
			} else {
				callSafe(onGoToNext());
			}
		}
	}

	componentWillUnmount() {
		this.scheduledDispatchEvents.stop();
	}

	resetLastActiveTool() {
		this.lastActiveTool = '';
	}

	handleToolActivation(tool) {
		const {onToolActivation} = this.props;
		if (this.lastActiveTool !== tool) {
			this.lastActiveTool = tool;
			callSafe(onToolActivation, tool);
		}
	}
}

function createEventProcessor(props, eventSink) {
	const multiplexedHandlers = [];
	const swipeToolsToDetect = getHandledSwipeTools(props);
	const {swipeToolDetectors} = props;

	if (swipeToolsToDetect.size > 0) {
		const toolDependentHandlers = [];
		if (hasHandlerForEvent(props, 'toolChanged')) {
			toolDependentHandlers.push(
				propertyTracker('tool', '')
			);
		}

		const choices = {
			1: renameEvent('oneFingerSwipe')
		};
		if (swipeToolsToDetect.has('pinch/zoom')) {
			choices[2] = renameEvent('twoFingersSwipe');
		}
		toolDependentHandlers.push(
			chain(
				moveRecognizer(),
				selectBy(event => event.get('to', Immutable.List()).size, choices)
			)
		);

		multiplexedHandlers.push(chain(
			activeToolRecognizer([...swipeToolsToDetect], swipeToolDetectors),
			stopToolEvent,
			toolDependentHandlers.length > 0 ? multiplexEvents(...toolDependentHandlers) : toolDependentHandlers[0]
		));
	}

	if (hasHandlerForEvent(props, 'goToNext') || hasHandlerForEvent(props, 'goToPrevious')) {
		multiplexedHandlers.push(chain(
			tapRecognizer(1),
			selectBy(event => (event.get('type') === 'tap' ? 'tap' : 'dropped'), {
				tap: multiplexEvents(
					stopOriginalDOMEvent,
					renameEvent('singleTap')
				)
			})
		));
	}

	let eventProcessor = noop;
	if (multiplexedHandlers.length > 0) {
		const combinedRecognizer = multiplexEvents(...multiplexedHandlers);
		eventProcessor = function(event) {
			combinedRecognizer(event, eventSink);
		};
	}
	return eventProcessor;
}

function hasHandlerForEvent(handler, eventName) {
	const eventHandlerName = toHandlerName(eventName);
	const eventHandler = handler[eventHandlerName];
	return typeof (eventHandler) === 'function';
}

function getHandledSwipeTools(props) {
	const tools = new Set();
	if (hasHandlerForEvent(props, 'window')) {
		tools.add('window');
	}
	if (hasHandlerForEvent(props, 'pinchZoom')) {
		tools.add('pinch/zoom');
	}
	if (hasHandlerForEvent(props, 'zoom')) {
		tools.add('zoom');
	}
	if (hasHandlerForEvent(props, 'pan')) {
		tools.add('pan');
	}
	return tools;
}

function calculatePinchMetrics(first, second) {
	const diffVector = vec2.create();
	vec2.sub(diffVector, second, first);
	const startCenter = vec2.create();
	vec2.add(startCenter, first, vec2.scale(startCenter, diffVector, 0.5));
	return {
		center: startCenter,
		length: vec2.length(diffVector)
	};
}

ImageViewerGesturesRecognizer.propTypes = {
	className: PropTypes.string,
	onToolChanged: PropTypes.func,
	onZoom: PropTypes.func,
	onPan: PropTypes.func,
	onPinchZoom: PropTypes.func,
	onGoToPrevious: PropTypes.func,
	onGoToNext: PropTypes.func,
	onToolActivation: PropTypes.func,
	onWindow: PropTypes.func,
	targetRef: refPropType,
	swipeToolDetectors: PropTypes.shape({
		pan: PropTypes.func,
		zoom: PropTypes.func,
		window: PropTypes.func,
		'pinch/zoom': PropTypes.func
	})
};
