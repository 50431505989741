import search from '../../commons/api/operations/search.js';
import update from '../../commons/api/operations/update.js';
import {webAppURL} from '../../commons/WebViewHelpers.js';

const INBOX_BASE_URL = '/inbox';
const INBOX_DOCUMET_SHARE_URL = webAppURL('/documentshare');

export function filterInbox(filterExpression = null) {
	let searchParams = {};
	if (filterExpression && filterExpression !== '') {
		searchParams = {params: {filter: filterExpression}};
	}
	return search(INBOX_BASE_URL, searchParams);
}

export function markShareDone(shareId, done = true) {
	return update(`${INBOX_DOCUMET_SHARE_URL}/${shareId}`, {id: shareId, done});
}
