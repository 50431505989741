import {identity} from './FunctionUtils.js';

export default class CachingResourceLoader {
	constructor(loadResource, freeResource, getResourceId) {
		this.loadResourceImpl = loadResource || identity;
		this.freeResourceImpl = freeResource || identity;
		this.getResourceIdImpl = getResourceId;
		this.resourceCache = new Map();
	}

	loadResource(unloadedResource) {
		const resourceId = this.getResourceIdImpl(unloadedResource);
		let cacheEntry = this.resourceCache.get(resourceId);
		if (!cacheEntry) {
			cacheEntry = new CacheEntry(unloadedResource, this.loadResourceImpl, this.freeResourceImpl);
			this.resourceCache.set(resourceId, cacheEntry);
		}
		return cacheEntry.acquire();
	}

	async freeLoadedResource(loadedResource) {
		const resourceId = this.getResourceIdImpl(loadedResource);
		const cacheEntry = this.resourceCache.get(resourceId);
		if (cacheEntry) {
			const freedResource = await cacheEntry.release();
			if (cacheEntry.refCount === 0) {
				this.resourceCache.delete(resourceId);
			}
			return freedResource;
		}
		return undefined;
	}
}

class CacheEntry {
	constructor(resource, loadResource, freeResource) {
		this.resource = resource;
		this.loadResource = loadResource;
		this.freeResource = freeResource;
		this.refCount = 0;
		this.resourcePromise = null;
	}

	async acquire() {
		await this.loadFinished();
		if (++this.refCount === 1) {
			this.resourcePromise = this.loadResource(this.resource);
			this.resource = await this.resourcePromise;
			this.resourcePromise = null;
		}
		await this.loadFinished();
		return this.resource;
	}

	async release() {
		await this.loadFinished();
		if (this.refCount > 0) {
			if (--this.refCount === 0) {
				this.resourcePromise = this.freeResource(this.resource);
				this.resource = await this.resourcePromise;
				this.resourcePromise = null;
			}
		}
		await this.loadFinished();
		return this.resource;
	}

	async loadFinished() {
		if (this.resourcePromise !== null) {
			await this.resourcePromise;
		}
	}
}
