import _memoize from 'lodash.memoize';
import {defaultMemoize} from 'reselect';

import {shallowEqual} from './ObjectUtils';

/**
 * Exactly what it claims to be: a function, doing nothing like in
 * function x() {}
 */
export const noop = () => {};

/**
 * returns the exactly same input
 *
 * @param input the input to return
 */
export const identity = input => input;

export function callSafe(callee, ...args) {
	if (Boolean(callee) && typeof (callee) === 'function') {
		return callee(...args);
	}
	return undefined;
}

export function not(booleanReturningFunction) {
	return function negated(...args) {
		return !booleanReturningFunction(...args);
	};
}

export const memoizeLast = defaultMemoize;

export function memoizeLastWithCleanup(memoizedFunction, cleanupFunction) {
	let previousState;
	let executedOnce = false;
	return memoizeLast((...args) => {
		if (executedOnce) {
			cleanupFunction(previousState, ...args);
		} else {
			executedOnce = true;
		}
		previousState = memoizedFunction(...args);
		return previousState;
	});
}


export function memoizeByFirstArg(fn) {
	return _memoize(fn);
}

function defaultCaptureArguments(newArgs, previousArgs) {
	const argsArray = previousArgs || [];
	argsArray.push(newArgs);
	return argsArray;
}

/**
 * Wraps the passed function fn and returns a wrapped variant.
 * The returned function schedules an invocation of the passed function fn for the next animation frame.
 * The passed function captureArguments is used to record arguments passed to the wrapper function.
 * The last result of the captureArguments function will be passed to the original function when it is invoked.
 *
 * @param fn - the function to be called in the next animation frame
 * @param captureArguments - arguments recording function
 * @returns {scheduledFunction} - wrapper function to schedule invocation for the next animation frame
 */
export function synchronizedWithAnimationFrame(fn, captureArguments = defaultCaptureArguments) {
	let collectedArgs = null;
	let scheduledId = null;
	let stopped = false;
	let synchronizedFunction = fn;
	let thisCaptureArguments = captureArguments;

	function invokeFunction() {
		const thisArgs = collectedArgs;
		collectedArgs = null;
		scheduledId = null;
		synchronizedFunction(thisArgs);
	}

	function stop() {
		if (scheduledId !== null) {
			window.cancelAnimationFrame(scheduledId);
			scheduledId = null;
		}
		stopped = true;
		collectedArgs = null;
		synchronizedFunction = null;
		thisCaptureArguments = null;
	}

	function scheduledFunction(...args) {
		if (!stopped) {
			collectedArgs = thisCaptureArguments(args, collectedArgs);
			if (scheduledId === null) {
				scheduledId = window.requestAnimationFrame(invokeFunction);
			}
		}
	}
	scheduledFunction.stop = stop;
	return scheduledFunction;
}

/**
 * Executes all passed functions after each other from right to left.
 * Each function is called with the argument of the previous function like in:
 *    f(g(x,y))
 * witch would be chain(f,g)(x,y)
 * @param fns - the functions to chain.
 * @returns a function calling all passed functions after each other
 */
export function chain(...fns) {
	return function chained(...args) {
		return fns.reduceRight(function reducer(result, fn) {
			return [fn(...result)];
		}, args)[0];
	};
}

export function callDelayed(delay, fn, ...args) {
	const timerId = setTimeout(() => fn(...args), delay);
	return () => clearTimeout(timerId);
}

/**
 * Converts recursive calls of the passed function into sequential calls,
 * NOTE: The passed functions return value won't be returned.
 * @param fn
 * @returns {(function(...[*]=): void)|*}
 */
export function sequentialReentry(fn) {
	let executing = false;
	const scheduledCalls = [];
	return function sequential(...args) {
		if (scheduledCalls.length > 0) {
			const lastArgs = scheduledCalls[scheduledCalls.length - 1];
			if (!shallowEqual(args, lastArgs)) {
				scheduledCalls.push(args);
			}
		} else {
			scheduledCalls.push(args);
		}
		if (!executing) {
			executing = true;
			while (scheduledCalls.length > 0) {
				const currentArgs = scheduledCalls.shift();
				fn(...currentArgs);
			}
			executing = false;
		}
	};
}

/**
 * Passes the results of the selectors on to the passed callback as arguments.
 * The callback is only called again, when the at least one result of the selector functions changes
 * in reference to the previous result.
 * The comparison is carried out using shallowEquals(...).
 *
 * @param selectorsAndCallback - functions to select arguments for callback and the callback.
 * @returns {(function(...[*]): void)|*} - memoized version of callback.
 */
export function withSelectors(...selectorsAndCallback) {
	if (selectorsAndCallback.length < 2) {
		throw new Error('withSelector needs at least 2 arguments.');
	}

	const selectors = selectorsAndCallback.slice(0, selectorsAndCallback.length - 1);
	const callback = selectorsAndCallback[selectorsAndCallback.length - 1];
	let lastSelectorResult;
	let selectorsEvaluatedOnce = false;
	return (...args) => {
		const thisSelectorResult = selectors.map(selector => selector(...args));
		const argumentsChanged = !selectorsEvaluatedOnce ||
			thisSelectorResult.some((thisResult, index) => {
				const previousResult = lastSelectorResult[index];
				return !shallowEqual(previousResult, thisResult);
			});
		if (argumentsChanged) {
			lastSelectorResult = thisSelectorResult;
			if (!selectorsEvaluatedOnce) {
				selectorsEvaluatedOnce = true;
			}
			callback(...lastSelectorResult, ...args);
		}
	};
}
