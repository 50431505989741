import Immutable from 'immutable';

export default function createStateMachine(initialState, createStateHandlers, initialData = Immutable.Map()) {
	let state = Immutable.Map({type: initialState, data: initialData});

	const stateHandlers = createStateHandlers(function transitionFunction(stateType, stateData = initialData) {
		state = state.merge({
			type: stateType,
			data: stateData
		});
	});

	return function stateMachine(...additionalArguments) {
		stateHandlers[state.get('type')](state.get('data'), ...additionalArguments);
	};
}
