import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {preventEventDefault, stopEventPropagation} from '../../commons/utils/DOMEventUtils.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import DomEventsManager from '../../events/DomEventsManager.js';
import Indicator from './Indicator.js';

import '../../../styles/material-design/components/Slider.scss';

const HUNDRED_PERCENT = 100.0;

class Handle extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.boundTouchAreaUpdated = this.touchAreaUpdated.bind(this);
		this.domEventsManager = new DomEventsManager();
	}

	render() {
		const {disabled, focused, className} = this.props;
		const isFocused = !disabled && focused;
		const handleClassNames = className ? className : classNames({
			'material-slider--handle': true,
			disabled,
			focused
		});

		const touchAreaClasses = classNames({
			'material-slider--handle--touch-area': true,
			focused: isFocused
		});

		if (!disabled) {
			return (
				<span className={handleClassNames}>
					<div ref={this.boundTouchAreaUpdated} className={touchAreaClasses} />
				</span>
			);
		}
		return <span className={handleClassNames} />;
	}

	touchAreaUpdated(touchArea) {
		const {onBeginMove} = this.props;
		if (touchArea) {
			this.domEventsManager.addEventListener(
				touchArea, 'mousedown', onBeginMove, {passive: false}
			);
			this.domEventsManager.addEventListener(
				touchArea, 'touchstart', onBeginMove, {passive: false}
			);
		} else {
			this.domEventsManager.removeAllListeners();
		}
	}
}

Handle.propTypes = {
	onBeginMove: PropTypes.func,
	disabled: PropTypes.bool,
	focused: PropTypes.bool,
	className: PropTypes.string
};

class DiscreteHandle extends React.PureComponent {
	render() {
		const {onBeginMove, disabled, focused, children} = this.props;
		const handleClassNames = classNames({
			'material-slider--handle': true,
			disabled,
			focused: !disabled && focused
		});

		const indicatorClassNames = classNames({
			'material-slider--indicator': true,
			hidden: !focused
		});

		return (
			<div className='material-slider--discrete-handle'>
				<Indicator className={indicatorClassNames}>
					{children}
				</Indicator>
				<Handle disabled={disabled} onBeginMove={onBeginMove} focused={focused} className={handleClassNames} />
			</div>
		);
	}
}

DiscreteHandle.propTypes = {
	onBeginMove: PropTypes.func,
	disabled: PropTypes.bool,
	focused: PropTypes.bool
};

export default class Slider extends React.Component {
	constructor(props, context) {
		super(props, context);
		const {value, from, to, discreteStep} = this.props;

		this.boundBeginMove = this.beginMove.bind(this);
		this.moveHandler = null;
		this.endMoveHandler = null;
		this.currentAnimationFrame = null;
		this.domEventsManager = new DomEventsManager();
		this.slider = React.createRef();

		this.state = {
			percentage: Slider.percentFromValue(value, from, to, discreteStep),
			value,
			prevValue: value,
			focused: false
		};
	}

	render() {
		const {className, disabled, discreteStep} = this.props;
		const {focused} = this.state;
		const sliderClassNames = classNames({
			[className || 'dummy-class-name']: Boolean(className),
			'material-slider': true,
			disabled,
			focused: !disabled && focused
		});

		const percentageClassNames = classNames({
			'material-slider--percentage': true,
			disabled
		});

		const handleProps = {
			focused,
			onBeginMove: this.boundBeginMove,
			disabled
		};

		let handle;
		if (discreteStep) {
			handle = (
				<DiscreteHandle {...handleProps}>
					{this.getIndicatorLabel()}
				</DiscreteHandle>
			);
		} else {
			handle = <Handle {...handleProps} />;
		}

		return (
			<div ref={this.slider} className={sliderClassNames}>
				<span className={percentageClassNames} style={this.getPercentageStyle()}>
					{handle}
				</span>
			</div>
		);
	}

	componentWillUnmount() {
		this.removeDynamicEventListeners();
	}

	static getDerivedStateFromProps(props, state) {
		const {value, from, to, discreteStep} = props;
		const {focused, prevValue} = state;
		const derivedState = {
			prevValue: value
		};
		if (!focused && prevValue !== value) {
			derivedState.percentage = Slider.percentFromValue(value, from, to, discreteStep);
		}
		return derivedState;
	}

	static percentFromValue(absoluteValue, from, to, discreteStep) {
		const range = to - from;
		let relativeValue = absoluteValue - from;
		if (discreteStep) {
			relativeValue = Math.floor(relativeValue / discreteStep) * discreteStep;
		}
		return (relativeValue / range) * HUNDRED_PERCENT;
	}

	getPercentageStyle() {
		const {percentage} = this.state;
		return {
			width: `${percentage}%`
		};
	}

	measureWidth() {
		return this.slider.current.getBoundingClientRect().width;
	}

	beginMove(e) {
		const {percentage} = this.state;
		preventEventDefault(e);
		this.removeDynamicEventListeners();

		const isTouch = Boolean(e.touches);

		const moveStartPos = isTouch
			? {left: e.touches.item(0).clientX, top: e.touches.item(0).clientY}
			: {left: e.clientX, top: e.clientY};
		const myWidth = this.measureWidth();
		const percentageWidth = (percentage * myWidth) / HUNDRED_PERCENT;
		let lastHorizontalPosition = e.touches
			? e.touches.item(0).clientX
			: e.clientX;
		let horizontalPosition = lastHorizontalPosition;

		const handleAnimationFrame = () => {
			if (lastHorizontalPosition !== horizontalPosition) {
				this.move(moveStartPos, percentageWidth, myWidth, 1 / myWidth, horizontalPosition);
				lastHorizontalPosition = horizontalPosition;
			}
			this.currentAnimationFrame = window.requestAnimationFrame(handleAnimationFrame);
		};
		this.currentAnimationFrame = window.requestAnimationFrame(handleAnimationFrame);

		this.moveHandler = moveHandlerEvent => {
			horizontalPosition = moveHandlerEvent.touches
				? moveHandlerEvent.touches.item(0).clientX
				: moveHandlerEvent.clientX;
			stopEventPropagation(moveHandlerEvent);
		};
		this.endMoveHandler = this.endMove.bind(this);

		if (isTouch) {
			this.domEventsManager.addEventListener(
				document, 'touchmove', this.moveHandler, true
			);
			this.domEventsManager.addEventListener(
				document, 'touchend', this.endMoveHandler, true
			);
		} else {
			this.domEventsManager.addEventListener(
				document, 'mousemove', this.moveHandler, true
			);
			this.domEventsManager.addEventListener(
				document, 'mouseup', this.endMoveHandler, true
			);
		}

		this.setState({
			focused: true
		});
	}

	move(moveStartPosition, percentageWidth, maxWidth, smallestStep, horizontalPosition) {
		const {onChange} = this.props;
		const {percentage, value} = this.state;
		let newLocalHorizontalPos = percentageWidth + (horizontalPosition - moveStartPosition.left);
		newLocalHorizontalPos = Math.min(maxWidth, Math.max(0, newLocalHorizontalPos));
		const newPercentage = (newLocalHorizontalPos / maxWidth) * HUNDRED_PERCENT;
		const percentageChange = Math.abs(percentage - newPercentage);
		if (percentageChange >= smallestStep) {
			const newState = {
				percentage: newPercentage,
				value: this.snapValue(this.valueFromPercent(newPercentage))
			};

			if (newState.value !== value) {
				callSafe(onChange, newState.value);
			}
			this.setState(newState);
		}
	}

	endMove(e) {
		this.setState(
			{...this.snapStateUpdate(), focused: false}
		);
		this.removeDynamicEventListeners();
		stopEventPropagation(e);
	}

	snapStateUpdate() {
		const {value} = this.state;
		const {from, to, discreteStep} = this.props;
		const snapStateUpdateState = {};
		if (discreteStep) {
			snapStateUpdateState.percentage = Slider.percentFromValue(value, from, to, discreteStep);
		}
		return snapStateUpdateState;
	}

	snapValue(value) {
		let snappedValue = value;
		const {discreteStep} = this.props;
		if (discreteStep) {
			snappedValue = Math.floor(value + (discreteStep / 2.0)) * discreteStep;
		}
		return snappedValue;
	}

	valueFromPercent(percentage) {
		const {from, to} = this.props;
		return from + ((to - from) * percentage / HUNDRED_PERCENT);
	}

	removeDynamicEventListeners() {
		if (this.moveHandler !== null) {
			this.domEventsManager.removeAllListeners();
			if (this.currentAnimationFrame !== null) {
				window.cancelAnimationFrame(this.currentAnimationFrame);
			}
			this.moveHandler = null;
			this.endMoveHandler = null;
		}
	}

	getIndicatorLabel() {
		const {indicatorLabel} = this.props;
		const {focused, value} = this.state;
		let finalLabel;
		if (focused) {
			finalLabel = indicatorLabel || value;
			if (Boolean(finalLabel) && typeof (finalLabel) === 'function') {
				finalLabel = finalLabel(value);
			}
		}
		return finalLabel;
	}
}

Slider.propTypes = {
	onChange: PropTypes.func,
	className: PropTypes.string,
	value: PropTypes.number,
	from: PropTypes.number,
	to: PropTypes.number,
	discreteStep: PropTypes.number,
	disabled: PropTypes.bool,
	indicatorLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
};

Slider.defaultProps = {
	value: 0,
	from: 0,
	to: 1,
	disabled: false
};
