import React from 'react';
import PropTypes from 'prop-types';

import MoreVertIcon from '../../ui/components/icons/MoreVertIcon.js';
import ResetViewerMenuEntry from './ResetViewerMenuEntry.js';
import ViewerToolBarMenu from './ViewerToolBarMenu.js';

export default class ImageViewerToolsMenu extends React.PureComponent {
	render() {
		const {children, disabled} = this.props;
		return (
			<ViewerToolBarMenu disabled={disabled} id='viewer-tools-menu' buttonIcon={MoreVertIcon}>
				{children}
				<ResetViewerMenuEntry key='resetViewer' />
			</ViewerToolBarMenu>
		);
	}
}

ImageViewerToolsMenu.propTypes = {
	disabled: PropTypes.bool
};
