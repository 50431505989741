import React from 'react';
import PropTypes from 'prop-types';

import useTranslation from '../../webview/i18n/hooks/useTranslation.js';
import IconButton from '../../webview/ui/components/IconButton.js';
import GridViewIcon from '../../webview/ui/components/icons/GridViewIcon.js';
import ViewListIcon from '../../webview/ui/components/icons/ViewListIcon.js';

export default function GridViewToggleButton(props) {
	const {gridMode, setGridMode, setTableMode, disabled} = props;
	const handleClick = gridMode ? setTableMode : setGridMode;
	const toGridModeLabel = useTranslation('SwitchToGridView');
	const toTableModeLabel = useTranslation('SwitchToTableView');
	const icon = gridMode ? <ViewListIcon /> : <GridViewIcon />;
	const title = gridMode ? toTableModeLabel : toGridModeLabel;
	return (
		<IconButton id='grid-view-toggle-button' role='switch' aria-checked={gridMode} title={title} icon={icon}
		            onClick={handleClick} disabled={disabled} />
	);
}

GridViewToggleButton.propTypes = {
	gridMode: PropTypes.bool,
	setGridMode: PropTypes.func,
	setTableMode: PropTypes.func,
	disabled: PropTypes.bool
};
