import React from 'react';

import {getHocDisplayName} from '../utils/ReactUtils.js';

export default function createSimplePureRenderComponent(functionalComponent) {
	class SimplePureRenderComponent extends React.PureComponent {
		render() {
			return functionalComponent(this.props);
		}
	}
	SimplePureRenderComponent.displayName = getHocDisplayName(functionalComponent, 'createSimplePureRenderComponent');
	return SimplePureRenderComponent;
}
