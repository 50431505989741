import {isEnableAllLowerCaseUserNameEnabled} from '../../../../webpatient/SettingsSelectors.js';
import {WEBAPP_NAME} from '../../../commons/constants/EnvironmentConstants.js';
import createFormAccessors from '../../../commons/data/FormAccessorsFactory.js';
import {allLowerCaseValidator, required} from '../../../commons/data/validators';
import LoginFormMessagesTranslator from '../../i18n/LoginFormMessagesTranslator.js';

const USERNAME_FIELD_LABEL = WEBAPP_NAME === 'webpatient' ? 'UsernameOrEmail' : 'Username';
const USERNAME_VALIDATORS = WEBAPP_NAME === 'webpatient' && isEnableAllLowerCaseUserNameEnabled()
	? [required, allLowerCaseValidator] : [required];

const LOGIN_FORM_SCHEMA = {
	fields: {
		username: {
			label: USERNAME_FIELD_LABEL,
			defaultValue: null,
			validators: USERNAME_VALIDATORS
		},
		password: {
			label: 'Password',
			defaultValue: null,
			validators: [required]
		}
	}
};
export default createFormAccessors(LOGIN_FORM_SCHEMA, LoginFormMessagesTranslator);
