import {connect} from 'react-redux';

import {uiDevicePixelRatio} from '../../ui/flux/UISelectors.js';
import PreviewableImage from '../components/PreviewableImage.js';

export default connect(
	mapStateToProps,
	undefined,
	mergeProps
)(PreviewableImage);

function mapStateToProps(state) {
	return {
		devicePixelRatio: uiDevicePixelRatio(state)
	};
}

function mergeProps(stateProps, dispatchProps, ownProps) {
	return {
		...stateProps,
		...ownProps
	};
}
