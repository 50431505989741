import {call, cancel, cancelled, fork, select} from 'redux-saga/effects';

import {
	ACTIVATE_SYNC_TOOL,
	DEACTIVATE_SYNC_TOOL, DISCONNECT_VIEWER, SYNC_VIEWERS
} from '../constants/ViewerSyncActionTypes.js';
import {mergeSyncToolProperties} from './ViewerSyncActions.js';
import {selectActiveSyncTools, selectSyncChains, selectSyncToolProperty} from './ViewerSyncSelectors.js';
import {takeBatched} from './ViewerSyncUtils.js';

export default function* viewerSyncSaga(toolsMap) {
	const activeTools = {};
	const boundActionProcessor = createActionHandler(activeTools, toolsMap);
	yield call(startActivatedTools, activeTools, toolsMap);
	while (!(yield cancelled())) {
		yield* takeBatched(
			boundActionProcessor,
			ACTIVATE_SYNC_TOOL,
			DEACTIVATE_SYNC_TOOL,
			DISCONNECT_VIEWER,
			SYNC_VIEWERS
		);
	}
	yield call(stopStartedTools, activeTools, toolsMap);
}

function* handleActions(activeTools, toolsMap, action) {
	const {
		type: actionType,
		payload: toolType
	} = action;
	switch (actionType) {
		case ACTIVATE_SYNC_TOOL:
			yield* startToolTask(activeTools, toolsMap, toolType);
			break;
		case DEACTIVATE_SYNC_TOOL:
			yield* stopToolTask(activeTools, toolType);
			break;
		case DISCONNECT_VIEWER:
			yield* onDisconnectViewer(activeTools, toolsMap);
			break;
		case SYNC_VIEWERS:
			yield* startActivatedTools(activeTools, toolsMap);
			break;
		default:
			break;
	}
}

function* startToolTask(activeTools, toolsMap, toolId) {
	if (!(toolId in activeTools) && (toolId in toolsMap)) {
		const toolSaga = toolsMap[toolId];
		const syncToolAPI = createToolAPI(toolId);
		activeTools[toolId] = yield fork(toolSaga, syncToolAPI);
	}
}

function* stopToolTask(activeTools, toolId) {
	const toolTask = activeTools[toolId];
	if (toolTask) {
		yield cancel(toolTask);
	}
	delete activeTools[toolId];
}

function* onDisconnectViewer(activeTools, toolsMap) {
	const syncChains = yield select(selectSyncChains);
	if (syncChains.size === 0) {
		yield* stopStartedTools(activeTools, toolsMap);
	}
}

function* startActivatedTools(activeTools, toolsMap) {
	const syncChains = (yield select(selectSyncChains));
	if (syncChains.size > 0) {
		const activatedTools = (yield select(selectActiveSyncTools)).toArray();
		for (const activatedTool of activatedTools) {
			yield* startToolTask(activeTools, toolsMap, activatedTool);
		}
	}
}

function* stopStartedTools(activeTools, toolsMap) {
	for (const activeToolId in activeTools) {
		if (activeToolId in toolsMap) {
			yield* stopToolTask(activeTools, activeToolId);
		}
	}
}

function createActionHandler(activeTools, toolsMap) {
	return function* boundHandleAction(action) {
		yield* handleActions(activeTools, toolsMap, action);
	};
}

function createToolAPI(toolId) {
	return {
		setToolProperty: function setProperty(viewerId, name, value) {
			return mergeSyncToolProperties(toolId, viewerId, {[name]: value});
		},
		selectToolProperty: function selectProperty(viewerId, name, fallbackValue = null) {
			return state => selectSyncToolProperty(state, toolId, viewerId, name, fallbackValue);
		}
	};
}
