import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import PropTypes from 'prop-types';

import {identity} from '../../../commons/utils/FunctionUtils.js';

// https://material-ui.com/guides/composition/#wrapping-components

const SVG_ICON_MUI_NAME = 'SvgIcon';
const DEFAULT_VIEWBOX_LENGTH = '24';

export function createSVGComponentIcon(iconComponent, memoized = false, viewBox = DEFAULT_VIEWBOX_LENGTH) {
	const postProcess = memoized ? React.memo : identity;
	function wrappedSVGIcon(props) {
		const {fontSize} = props;
		let viewBoxProps = {};
		if (viewBox !== DEFAULT_VIEWBOX_LENGTH) {
			viewBoxProps = {
				viewBox: `0 0 ${viewBox} ${viewBox}`
			};
			if (!fontSize) {
				viewBoxProps.style = {
					fontSize: `${viewBox}px`
				};
			}
		}
		return <SvgIcon component={iconComponent} {...viewBoxProps} {...props} />;
	}
	wrappedSVGIcon.propTypes = {
		fontSize: PropTypes.string
	};
	wrappedSVGIcon.muiName = SVG_ICON_MUI_NAME;
	wrappedSVGIcon.displayName = iconComponent.name;
	return postProcess(wrappedSVGIcon);
}
