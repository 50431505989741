import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../ui/components/Button.js';
import ExternalLinkIcon from '../../../ui/components/icons/ExternalLinkIcon.js';
import LinkContainer from '../../containers/LinkContainer.js';
import {immutablePropType} from '../../utils/CustomPropTypes.js';

function renderButton(linkButtonProps) {
	const {location, disabled, buttonComponent: ButtonComponent, href, ...remainingProps} = linkButtonProps;
	const buttonProps = {
		...remainingProps,
		disabled: disabled || (!location && !href),
		href
	};

	if (href) {
		buttonProps.endIcon = <ExternalLinkIcon />;
	}

	return <ButtonComponent {...buttonProps} />;
}

function renderLinkContainer(linkButtonProps, button) {
	const {location, title} = linkButtonProps;
	const {
		path = location.get('path'),
		query = location.get('query'),
		fragment = location.get('fragment')
	} = linkButtonProps;
	return (
		<LinkContainer path={path} query={query} fragment={fragment} title={title}>
			{button}
		</LinkContainer>
	);
}

function LinkButton(props) {
	const {location} = props;
	const button = renderButton(props);
	return location ? renderLinkContainer(props, button) : button;
}

LinkButton.propTypes = {
	location: immutablePropType,
	buttonComponent: PropTypes.elementType,
	title: PropTypes.string,
	path: PropTypes.string,
	query: immutablePropType,
	fragment: PropTypes.string,
	disabled: PropTypes.bool,
	href: PropTypes.string
};

LinkButton.defaultProps = {
	disabled: false,
	buttonComponent: Button
};

export default React.memo(LinkButton);
