import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {LOAD_ITEM_INTO_VIEWER} from '../../constants/ViewerActionTypes.js';
import {
	ACTIVATE_SYNC_TOOL,
	DEACTIVATE_SYNC_TOOL,
	DISCONNECT_VIEWER,
	MERGE_TOOL_PROPERTIES,
	SYNC_VIEWERS
} from '../constants/ViewerSyncActionTypes.js';
import {ACTIVE_SYNC_TOOLS, TOOL_STATES, VIEWER_SYNC_CHAINS} from '../constants/ViewerSyncStateFields.js';
import {getNextChainID, getSyncChainID} from './ViewerSyncUtils.js';

const INITIAL_STATE = Immutable.Map();

export default withoutEmptyEntries(
	handleActions({
		[SYNC_VIEWERS]: syncViewers,
		[DISCONNECT_VIEWER]: handleDisconnectViewer,
		[ACTIVATE_SYNC_TOOL]: activateTool,
		[DEACTIVATE_SYNC_TOOL]: deactivateTool,
		[MERGE_TOOL_PROPERTIES]: mergeToolProperties,
		[LOAD_ITEM_INTO_VIEWER]: onLoadItemIntoViewer
	}, INITIAL_STATE)
);

function syncViewers(viewerSyncToolsState, action) {
	const {payload: {viewerA, viewerB}} = action;
	let nextState = viewerSyncToolsState;
	const chainIdA = getSyncChainID(viewerSyncToolsState, viewerA);
	const chainIdB = getSyncChainID(viewerSyncToolsState, viewerB);

	if (chainIdA === null && chainIdB === null) {
		const chainId = getNextChainID(viewerSyncToolsState);
		nextState = [viewerA, viewerB].reduce(
			(newState, viewerId) => addViewerToChain(newState, viewerId, chainId),
			nextState
		);
	} else if (chainIdA !== null && chainIdB !== null) {
		const chainB = nextState.getIn([VIEWER_SYNC_CHAINS, chainIdB]);
		nextState = nextState
			.updateIn([VIEWER_SYNC_CHAINS, chainIdA], chainA => chainA.union(chainB))
			.deleteIn([VIEWER_SYNC_CHAINS, chainIdB]);
		if (chainB) {
			nextState = chainB.reduce(resetStateOfViewer, nextState);
		}
	} else {
		const chainId = chainIdA === null ? chainIdB : chainIdA;
		const viewerId = chainId === chainIdA ? viewerB : viewerA;
		nextState = addViewerToChain(nextState, viewerId, chainId);
	}
	return nextState;
}

function handleDisconnectViewer(viewerSyncToolsState, action) {
	const {payload: viewerId} = action;
	return disconnectViewer(viewerSyncToolsState, viewerId);
}

function activateTool(viewerSyncToolsState, action) {
	const {payload: newTool} = action;
	return viewerSyncToolsState.update(ACTIVE_SYNC_TOOLS, Immutable.Set(),
		tools => tools.add(newTool)
	);
}

function deactivateTool(viewerSyncToolsState, action) {
	const {payload: tool} = action;
	let nextState = viewerSyncToolsState;
	const activeTools = viewerSyncToolsState.get(ACTIVE_SYNC_TOOLS, null);
	if (activeTools) {
		nextState = nextState.set(ACTIVE_SYNC_TOOLS, activeTools.delete(tool));
		nextState = nextState.deleteIn([TOOL_STATES, tool]);
	}
	return nextState;
}

function mergeToolProperties(viewerSyncToolsState, action) {
	const {payload: {toolId, viewerId, partialProperties}} = action;
	return viewerSyncToolsState.mergeIn([TOOL_STATES, toolId, viewerId], partialProperties);
}

function onLoadItemIntoViewer(viewerSyncToolsState, action) {
	const {payload: {viewerId}} = action;
	return disconnectViewer(viewerSyncToolsState, viewerId);
}

function addViewerToChain(viewerSyncToolsState, viewerId, chainId) {
	const nextState = viewerSyncToolsState.updateIn(
		[VIEWER_SYNC_CHAINS, chainId], Immutable.Set(),
		chains => chains.add(viewerId)
	);
	return resetStateOfViewer(nextState, viewerId);
}

function disconnectViewer(viewerSyncToolsState, viewerId) {
	let nextState = removeViewerFromAllChains(viewerSyncToolsState, viewerId);
	nextState = resetStateOfViewer(nextState, viewerId);
	const lonelyViewers = nextState.get(VIEWER_SYNC_CHAINS, Immutable.Map())
		.reduce((singleViewers, chain) => {
			if (chain.size <= 1) {
				return singleViewers.union(chain);
			}
			return singleViewers;
		}, Immutable.Set());
	nextState = lonelyViewers.reduce(disconnectViewer, nextState);
	return nextState;
}

function removeViewerFromAllChains(viewerSyncToolsState, viewerId) {
	return viewerSyncToolsState.update(VIEWER_SYNC_CHAINS, Immutable.Map(),
		chains => chains
			.map(chain => chain.delete(viewerId))
			.filter(chain => chain.size > 0)
	);
}

function resetStateOfViewer(viewerSyncToolsState, viewerId) {
	return viewerSyncToolsState
		.update(TOOL_STATES, Immutable.Map(), toolStates => toolStates
			.map(toolState => toolState.delete(viewerId))
			.filter(toolState => toolState.size > 0)
		);
}

function withoutEmptyEntries(actionHandler) {
	return function filterStateForEmptyKeys(state, action) {
		const nextState = actionHandler(state, action);
		if (nextState && nextState.filter !== undefined) {
			return filterEmptyEntries(nextState);
		}
		return nextState;
	};
}

function filterEmptyEntries(state) {
	return state.filter(
		subValue => subValue && subValue.size !== undefined && subValue.size > 0
	);
}
