import Immutable from 'immutable';
import {batchActions} from 'redux-batched-actions';

import {recordError} from '../../../commons/actions/UnhandledErrorsActions.js';
import FetchError from '../../../commons/api/FetchError.js';
import ImageLoadError from '../../../commons/api/ImageLoadError.js';
import NotFoundError from '../../../commons/api/NotFoundError.js';
import {APPLICATION_PDF, APPLICATION_XML} from '../../../commons/constants/MimeTypes.js';
import {
	getNumberOfFrames,
	isEmbeddedVideo,
	isEncapsulatedPdf,
	isStructuredReport
} from '../../../commons/data/aim/DicomImageHelpers.js';
import {createAction} from '../../../commons/utils/ActionUtils.js';
import {readBlobAsArrayBuffer} from '../../../commons/utils/BlobUtils.js';
import {PRIORITY_HIGH} from '../../../commons/utils/ProbabilisticQueue.js';
import {addOrReplaceTaskSelector, scheduleTask} from '../../../scheduling/api';
import createExactMatchSelector from '../../../scheduling/createExactMatcherTaskSelector.js';
import {
	getDicomDump,
	getDicomImageAsSynAdvancedImage,
	getDicomImagesForSeries,
	getEncapsulatedPdf, getEncapsulatedPdfDownloadUrl, getMarkup
} from '../../api/DicomImageApi.js';
import {getFileAsBlob, getFileAsText, getGenericFileAsSynAdvancedImage} from '../../api/GenericFileApi.js';
import {
	DICOM_ENC_PDF_DATA_TYPE,
	DICOM_IMAGE_DATA_TYPE,
	FILE_VIEWER_ITEMS_SCHEDULING_GROUP, HTML_DATA_TYPE
} from '../../constants/ViewerItemConstants.js';
import {CLEAR_VIEWER_ITEMS, UPDATE_VIEWER_ITEMS} from '../../constants/ViewerItemsActionTypes.js';
import DicomSeriesImageIndex from '../../data/DicomSeriesImageIndex.js';
import {isIntegratedPdfViewerExplicitlyDisabled} from '../../utils/PDFUtils.js';
import {
	buildDicomFrameId, buildEncapsulatedPdfId,
	buildFileTaskSelectorId,
	buildSchedulerSeriesGroupId, buildStructuredReportId,
	hasSeriesLoadStarted
} from '../../utils/ViewerItemUtils.js';
import {hasFileLoadStarted, selectSeriesViewerItems} from '../selectors/ViewerItemsSelectors.js';

const EXPECTED_LOAD_ERRORS = [FetchError, NotFoundError, ImageLoadError];

function isExpectedLoadError(error, expectedErrors) {
	return expectedErrors.find(expectedError => error instanceof expectedError) !== undefined;
}

function handleLoadError(dispatch, itemType, itemId, contentType, ...additionallyExpectedErrors) {
	const finalExpectedErrors = EXPECTED_LOAD_ERRORS.concat(additionallyExpectedErrors);
	return error => {
		dispatch(updateViewerItem(itemType, itemId, Immutable.Map({
			type: contentType,
			loadError: error
		})));
		if (!isExpectedLoadError(error, finalExpectedErrors)) {
			dispatch(recordError(error));
		}
	};
}

const updateViewerItems = createAction(UPDATE_VIEWER_ITEMS);

export function updateViewerItemsOfType(type, partialUpdates) {
	return updateViewerItems(Immutable.Map({[type]: partialUpdates}));
}

export function updateViewerItem(type, id, viewerItemData) {
	return updateViewerItemsOfType(type, Immutable.Map().set(id, viewerItemData));
}

export function startDataDownload(type, id) {
	return updateViewerItem(type, id, Immutable.Map({isLoading: true}));
}

export function finishDataDownload(type, id) {
	return updateViewerItem(type, id, Immutable.Map({isLoading: false}));
}

const clearViewerItemsAction = createAction(CLEAR_VIEWER_ITEMS);

let currentRequestForBatchUpdate = null;
let queuedUpdateActions = Immutable.List();

function executeUpdateRequest(dispatch) {
	const updateAction = updateViewerItems(
		queuedUpdateActions.reduce((mergedUpdates, {payload: partialUpdate}) => mergedUpdates.mergeDeepWith(
			(prev, next) => {
				if (typeof next === 'function') {
					if (typeof prev === 'function') {
						return item => next(prev(item));
					}
					return item => next(item.mergeDeep(prev));
				}
				if (typeof prev === 'function') {
					return item => prev(item).mergeDeep(next);
				} else if (Boolean(prev) && Boolean(prev.mergeDeep)) {
					return prev.mergeDeep(next);
				}
				return next;
			},
			partialUpdate
		), Immutable.Map())
	);

	dispatch(updateAction);

	queuedUpdateActions = Immutable.List();
	currentRequestForBatchUpdate = null;
}

function batchUpdate(dispatch, ...updateActions) {
	queuedUpdateActions = queuedUpdateActions.push(...updateActions);
	if (currentRequestForBatchUpdate === null) {
		currentRequestForBatchUpdate = window.requestAnimationFrame(() => {
			executeUpdateRequest(dispatch);
		});
	}
}

// see Bug #98385, Comment #4
export function clearViewerItems() {
	return function clearViewerItemsThunk(dispatch) {
		if (currentRequestForBatchUpdate) {
			window.cancelAnimationFrame(currentRequestForBatchUpdate);
			currentRequestForBatchUpdate = null;
		}
		dispatch(clearViewerItemsAction());
	};
}

export function loadSeries(seriesId) {
	return function loadSeriesThunk(dispatch, getStore) {
		const seriesLoadStarted = hasSeriesLoadStarted(selectSeriesViewerItems(getStore()).get(seriesId, null));
		if (!seriesLoadStarted) {
			dispatch(startDataDownload('series', seriesId));
			scheduleTask({
				groupIdentifier: 'dicomSeriesMetaData',
				taskIdentifier: seriesId,
				taskCreator: () => getDicomImagesForSeries(seriesId),
				taskConsumer: dicomSeriesSearchTask => dicomSeriesSearchTask.then(searchResult => {
					const dicomImages = searchResult.getList();
					const seriesInfo = buildSeriesInfo(seriesId, dicomImages);
					const imageViewerItems = dicomImages.reduce((imageMap, image) => imageMap.set(image.get('id'), Immutable.Map({metaData: image})), Immutable.Map());
					dispatch(batchActions([
						updateViewerItem('series', seriesId, seriesInfo),
						updateViewerItemsOfType('image', imageViewerItems),
						finishDataDownload('series', seriesId)
					], UPDATE_VIEWER_ITEMS));
					dispatch(loadImages(seriesId, searchResult, seriesInfo.get('imageIndex')));
				}).catch(handleLoadError(dispatch, 'series', seriesId))
			});
			addOrReplaceTaskSelector(
				`seriesMetaDataBooster/${seriesId}`, PRIORITY_HIGH,
				createExactMatchSelector('dicomSeriesMetaData', seriesId)
			);
		}
	};
}

function buildSeriesInfo(seriesId, imageList) {
	return Immutable.Map({
		id: seriesId,
		imageIndex: buildSeriesImageIndex(imageList),
		nrLoadedImages: 0,
		nrLoadedDicomDumps: 0
	});
}

function buildSeriesImageIndex(imageList) {
	return imageList.reduce((imageIndex, image) => imageIndex.addEntry(image.get('id'), getNumberOfFrames(image)),
		new DicomSeriesImageIndex()
	);
}

export function loadImages(seriesId, dicomImages, seriesImageIndex) {
	return function loadImagesThunk(dispatch) {
		dicomImages.forEach((dicomImage, index) => {
			const {id: imageId, firstFrameInSeries, numberOfFrames} = seriesImageIndex.getEntry(index);
			const dicomDumpTaskIndex = index + firstFrameInSeries;
			const taskGroupId = buildSchedulerSeriesGroupId(seriesId, 'image');
			scheduleTask({
				groupIdentifier: taskGroupId,
				taskIdentifier: dicomDumpTaskIndex,
				taskCreator: () => getDicomDump(imageId),
				taskConsumer: dicomDumpDownloadTask => {
					dicomDumpDownloadTask.then(dump => {
						batchUpdate(dispatch,
							updateViewerItem('image', imageId, Immutable.Map({dicomDump: dump, id: imageId})),
							updateViewerItem('series', seriesId, item => item.update('nrLoadedDicomDumps', nrLoadedDicomDumps => nrLoadedDicomDumps + 1))
						);
					}).catch(handleLoadError(dispatch, 'image', imageId, undefined, SyntaxError));
				}
			});
			const firstFrameIndex = dicomDumpTaskIndex + 1;
			if (isEncapsulatedPdf(dicomImage)) {
				scheduleEncapsulatedPdfDownload(dispatch, firstFrameIndex, seriesId, imageId);
			} else if (isStructuredReport(dicomImage)) {
				scheduleStructuredReportDownload(dispatch, firstFrameIndex, seriesId, imageId);
			} else {
				const nrFramesToLoad = isEmbeddedVideo(dicomImage) ? 1 : numberOfFrames;
				for (let frameNumber = 0; frameNumber < nrFramesToLoad; ++frameNumber) {
					const thisTaskId = firstFrameIndex + frameNumber;
					scheduleSeriesFrameDownload(dispatch, thisTaskId, seriesId, imageId, frameNumber);
				}
			}
		});
	};
}

function scheduleSeriesFrameDownload(dispatch, taskId, seriesId, imageId, frameNumber = 0) {
	const frameId = buildDicomFrameId(seriesId, imageId, frameNumber);
	scheduleTask({
		groupIdentifier: buildSchedulerSeriesGroupId(seriesId, 'image'),
		taskIdentifier: taskId,
		taskCreator: () => getDicomImageAsSynAdvancedImage(imageId, frameNumber),
		taskConsumer: dicomImageDownloadTask => dicomImageDownloadTask.then(synAdvancedImage => {
			batchUpdate(dispatch,
				updateViewerItem('imageData', frameId, Immutable.Map({
					type: DICOM_IMAGE_DATA_TYPE,
					rawImage: synAdvancedImage,
					imageId
				})),
				updateViewerItem('series', seriesId, seriesItem => seriesItem.update('nrLoadedImages', nrLoadedImages => nrLoadedImages + 1))
			);
		}).catch(handleLoadError(dispatch, 'imageData', frameId, DICOM_IMAGE_DATA_TYPE, SyntaxError))
	});
}

function scheduleEncapsulatedPdfDownload(dispatch, taskId, seriesId, imageId) {
	const pdfId = buildEncapsulatedPdfId(seriesId, imageId);
	scheduleTask({
		groupIdentifier: buildSchedulerSeriesGroupId(seriesId, 'image'),
		taskIdentifier: taskId,
		taskCreator: () => createEncapsulatedPdfDownloadTask(imageId),
		taskConsumer: pdfDownloadTask => pdfDownloadTask.then(pdf => {
			batchUpdate(dispatch,
				updateViewerItem('imageData', pdfId, Immutable.Map({
					type: DICOM_ENC_PDF_DATA_TYPE,
					rawPdf: pdf,
					imageId
				})),
				updateViewerItem('series', seriesId,
					seriesItem => seriesItem.update('nrLoadedImages', nrLoadedImages => nrLoadedImages + 1))
			);
		}).catch(handleLoadError(dispatch, 'imageData', pdfId, DICOM_ENC_PDF_DATA_TYPE))
	});
}

function scheduleStructuredReportDownload(dispatch, taskId, seriesId, imageId) {
	const markupId = buildStructuredReportId(seriesId, imageId);
	scheduleTask({
		groupIdentifier: buildSchedulerSeriesGroupId(seriesId, 'image'),
		taskIdentifier: taskId,
		taskCreator: () => createMarkupDownloadTask(imageId),
		taskConsumer: downloadTask => downloadTask.then(markup => {
			batchUpdate(dispatch,
				updateViewerItem('imageData', markupId, Immutable.Map({
					type: HTML_DATA_TYPE,
					rawMarkup: markup,
					imageId
				})),
				updateViewerItem('series', seriesId,
					seriesItem => seriesItem.update('nrLoadedImages', nrLoadedImages => nrLoadedImages + 1))
			);
		}).catch(handleLoadError(dispatch, 'imageData', markupId, HTML_DATA_TYPE))
	});
}

function createEncapsulatedPdfDownloadTask(imageId) {
	if (isIntegratedPdfViewerExplicitlyDisabled()) {
		return Promise.resolve(getEncapsulatedPdfDownloadUrl(imageId));
	}
	return getEncapsulatedPdf(imageId)
		.then(readBlobAsArrayBuffer);
}

function createMarkupDownloadTask(imageId) {
	return getMarkup(imageId);
}

function loadGenericFile(fileId, taskCreator) {
	return function loadGenericFileThunk(dispatch, getState) {
		// download for the same fileId might be triggered from multiple viewer instances
		// within the same rendering pass, so we need to double-check here.
		const hasDownloadStarted = hasFileLoadStarted(fileId)(getState());
		if (!hasDownloadStarted) {
			dispatch(startDataDownload('file', fileId));
			scheduleTask({
				groupIdentifier: FILE_VIEWER_ITEMS_SCHEDULING_GROUP,
				taskIdentifier: fileId,
				taskCreator,
				taskConsumer: task => task
					.then(partialResult => {
						dispatch(updateViewerItem(
							'file', fileId, Immutable.Map({loadedPercent: 100, ...partialResult}))
						);
					})
					.catch(handleLoadError(dispatch, 'file', fileId, 'file'))
			});
			addOrReplaceTaskSelector(
				buildFileTaskSelectorId(fileId), PRIORITY_HIGH,
				createExactMatchSelector(FILE_VIEWER_ITEMS_SCHEDULING_GROUP, fileId)
			);
		}
	};
}

export function loadGenericFileImage(genericFileId) {
	return loadGenericFile(genericFileId,
		() => getGenericFileAsSynAdvancedImage(genericFileId)
			.then(synAdvancedImage => ({rawImage: synAdvancedImage}))
	);
}

export function simulateFileLoad(fileId) {
	return function simulateFileLoadThunk(dispatch) {
		batchUpdate(dispatch,
			startDataDownload('file', fileId),
			updateViewerItem('file', fileId, Immutable.Map({loadedPercent: 100}))
		);
	};
}

export function loadPdfFile(fileId) {
	return loadGenericFile(fileId,
		() => getFileAsBlob(fileId, {}, APPLICATION_PDF)
			.then(readBlobAsArrayBuffer)
			.then(pdf => ({rawPdf: pdf})));
}

export function loadXMLFile(fileId) {
	return loadGenericFile(fileId,
		() => getFileAsText(fileId, {}, APPLICATION_XML)
			.then(text => ({rawXML: text}))
	);
}
