import {createSelector} from 'reselect';

import {selectAllDicomSeriesViewerIDs} from '../../../../flux/selectors/DicomSeriesViewerSelectors.js';
import {createSelectChainID, createSelectIsSyncToolActive} from '../../../flux/ViewerSyncSelectors.js';
import {SYNC_TOOL_ID} from '../index.js';

export const selectCanUsePageSync = createSelector(
	selectAllDicomSeriesViewerIDs,
	nonEmptyViewerIds => nonEmptyViewerIds.size > 1
);

export function createSelectIsOffsetPageActiveForViewer(viewerId) {
	const selectChainId = createSelectChainID(viewerId);
	return createSelector(
		selectIsOffsetPageActive,
		selectChainId,
		(isOffsetPageActive, chainId) => isOffsetPageActive && chainId !== null
	);
}

const selectIsOffsetPageActive = createSelectIsSyncToolActive(SYNC_TOOL_ID);
