import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';

import '../../../styles/material-design/components/DialogBody.scss';

export default function DialogBody(props) {
	const {className, children} = props;
	const finalClassNames = combineClassNames('material-dialog-body', className);
	return (
		<VerticalLayout element='section' className={finalClassNames}>
			{children}
		</VerticalLayout>
	);
}

DialogBody.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired
};
