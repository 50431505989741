/*
*/
import {IS_DEBUG_BUILD} from '../../commons/constants/EnvironmentConstants.js';
import {DEFAULT_ENGLISH_LOCALE} from '../../commons/constants/SynSettingsConstants.js';
import {getFormattedMessage, getLanguageAttribute} from '../i18nUtils.js';

const MESSAGE_TEMPLATE_VARIABLE_PATTERN = /\$([a-zA-Z0-9_]+)\$/;

/** Provides utilities to translate messages identified by their message ids.
 * The translations themselves are stored in separate yaml files.
 * It is also possible to specify a parent translator witch is queried for possible
 * translations if the current instance is not able to translate the id.
 */
export default class MessageTranslator {
	#translations;
	#parentTranslator;
	#resolvedMessages;

	/** Constructs a new MessageTranslator with translations as the
	 * primary and parentTranslator as the secondary source for translations.
	 * @param translations {Object} loaded by importing yaml translation files
	 * @param parentTranslator {MessageTranslator} a fallback translator to query if we cannot translate a message.
	 */
	constructor(translations, parentTranslator = null) {
		this.#translations = translations;
		this.defaultLocale = DEFAULT_ENGLISH_LOCALE;
		this.#parentTranslator = parentTranslator;
		this.#resolvedMessages = new Map();
	}

	/** Returns the localized string for the specified message identifier.
	 * If this translator cannot translate the message it queries the parentTranslator passed to its constructor.
	 * @param languageStringIdentifier {String} identifying the message to be translated
	 * @param locale {String} the locale to use instead of the defaultLocale (currently hardcoded to 'de')
	 * @param fallbackTranslation {String} the translation to be used if languageStringIdentifier cannot be found
	 * @returns {String} representing the translation
	 */
	getTranslation(languageStringIdentifier, locale = '', fallbackTranslation = null) {
		const usedLocale = locale ? locale : this.defaultLocale;
		let message = this.#getMessage(languageStringIdentifier, usedLocale);
		if (message) {
			message = this.#resolveMessageTemplate(message, usedLocale);
		} else if (this.#parentTranslator) {
			message = this.#parentTranslator.getTranslation(languageStringIdentifier, usedLocale, fallbackTranslation);
		}
		return message || fallbackTranslation || `TranslationError::${languageStringIdentifier}`;
	}

	#getMessage(messageIdentifier, locale) {
		let finalMessage = null;
		const localizedMessages = this.#translations[messageIdentifier];
		if (localizedMessages) {
			finalMessage = localizedMessages[getLanguageAttribute(locale)] || null;
		}
		if (!finalMessage && !IS_DEBUG_BUILD && locale !== this.defaultLocale) {
			finalMessage = this.#getMessage(messageIdentifier, this.defaultLocale);
		}
		return finalMessage;
	}

	#resolveMessageTemplate(message, locale) {
		const resolvedMessageCacheKey = `${message}_${locale}`;
		let resolvedMessage = this.#resolvedMessages.get(resolvedMessageCacheKey);
		if (resolvedMessage === undefined && message) {
			resolvedMessage = '';
			let remainingMessage = message;
			let lastMatch = remainingMessage.match(MESSAGE_TEMPLATE_VARIABLE_PATTERN);
			while (lastMatch) {
				const matchStart = lastMatch.index;
				const [wholeMatch, messageId] = lastMatch;
				const matchEnd = matchStart + wholeMatch.length;
				const replacement = this.getTranslation(messageId, locale);
				resolvedMessage += remainingMessage.substring(0, matchStart) + replacement;
				remainingMessage = remainingMessage.substring(matchEnd);
				lastMatch = remainingMessage.match(MESSAGE_TEMPLATE_VARIABLE_PATTERN);
			}
			resolvedMessage += remainingMessage;
			this.#resolvedMessages.set(resolvedMessageCacheKey, resolvedMessage);
		}
		return resolvedMessage;
	}

	/**
	 * Returns the formatted message defined by languageStringIdentifier.
	 * If that message cannot be found, the passed fallbackTranslation is used.
	 * NOTE: the fallbackTranslation is not FORMATTED! Passed messageParams will not be
	 * @param languageStringIdentifier {String} - message identifier to be used
	 * @param locale {String} - the locale to be used (de, en, ...)
	 * @param messageParams {Object} - structured parameters to be passed when formatting the message
	 * @param fallbackTranslation {String} - will be returned, if the message does not exist.
	 * @returns {string} - the formatted message
	 */
	getFormattedMessage(languageStringIdentifier, locale = '', messageParams = {}, fallbackTranslation = null) {
		const usedLocale = locale ? locale : this.defaultLocale;
		const translation = this.getTranslation(languageStringIdentifier, usedLocale, fallbackTranslation);
		return translation === fallbackTranslation
			? translation
			: getFormattedMessage(translation, usedLocale, messageParams);
	}

	/** Concatenates the given elements in a readable list of elements, separated by commas.
	 * The last element of the list is separated by the translation of the id specified by the
	 * parameter lastElementSeparator.
	 * If no array or an array with one single element is passed to the method, it simply returns the parameter
	 * or the first element of the array respectively.
	 * @param elements {Array|String|Any} to concatenate
	 * @param lastElementSeparator {String} translation id to use for the separator of the last element
	 * @param locale {String} the locale to use.
	 * @returns {String} like 'a, b and c'
	 */
	toListing(elements, lastElementSeparator = 'And', locale = '') {
		if (!Array.isArray(elements)) {
			return elements;
		} else if (elements.length === 1) {
			return elements[0];
		}
		return `${elements.slice(0, -1).join(', ')} ${this.getTranslation(lastElementSeparator, locale)} ${elements[elements.length - 1]}`;
	}
}
