import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import LocalizationContext from '../../i18n/components/LocalizationContext.js';
import HumanReadableDataSizeTranslator from '../../i18n/translators/HumanReadableDataSizeTranslator.js';
import {useMemoFactory} from '../utils/customHooks';
import {getHumanReadableDataSize} from '../utils/NumberUtils.js';

function HumanReadableDataSize(props) {
	const {amountBytes = 0} = props;
	const humanReadableDataSize = useFormattedDataSize(amountBytes);
	return (
		<span>
			{humanReadableDataSize}
		</span>
	);
}

HumanReadableDataSize.propTypes = {
	amountBytes: PropTypes.number.isRequired
};

export default HumanReadableDataSize;

export function useFormattedDataSize(amountBytes) {
	const contextLocale = useContext(LocalizationContext);
	let readableDataSize = NaN.toLocaleString(contextLocale);
	const {amount: fullAmount, unit} = useMemoFactory(getHumanReadableDataSize, amountBytes);
	if (typeof fullAmount === 'number' && unit !== '') {
		const amount = fullAmount.toLocaleString(contextLocale, {maximumFractionDigits: 1});
		readableDataSize = HumanReadableDataSizeTranslator.getFormattedMessage(unit, contextLocale, {amount});
	}
	return readableDataSize;
}
