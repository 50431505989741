import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import ImageDecoder from '../utils/ImageDecoder.js';

export default function wrapInImageDecoder(ViewerComponent, decodeFunction, releaseFunction, emptyImagePlaceholder, rawImagePropName = 'rawImage') {
	class ImageDecoderContainer extends React.PureComponent {
		constructor(props, context) {
			super(props, context);
			this.imageDecoder = new ImageDecoder(decodeFunction, releaseFunction, img => this.captureDecodedImage(img));
			this.state = {decodedImage: null};
		}

		render() {
			const {decodedImage} = this.state;
			const imageViewerProps = cloneWithoutProperties(this.props, rawImagePropName);

			return <ViewerComponent decodedImage={decodedImage || emptyImagePlaceholder} {...imageViewerProps} />;
		}

		componentDidMount() {
			this.scheduleImageDecoding();
		}

		componentDidUpdate(prevProps) {
			const thisImage = this.getRawImage();
			const prevImage = this.getRawImage(prevProps);
			if (thisImage !== prevImage) {
				this.scheduleImageDecoding();
			}
		}

		componentWillUnmount() {
			this.imageDecoder.stop();
		}

		captureDecodedImage(decodedImage) {
			this.setState({decodedImage});
		}

		scheduleImageDecoding() {
			this.imageDecoder.scheduleDecoding(this.getRawImage());
		}

		getRawImage(props = this.props) {
			const {[rawImagePropName]: rawImageData} = props;
			return rawImageData;
		}
	}

	ImageDecoderContainer.propTypes = {
		[rawImagePropName]: PropTypes.object
	};

	return ImageDecoderContainer;
}
