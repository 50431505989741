import Immutable from 'immutable';
import moment from 'moment';

import {MAX_LIMIT} from '../../document-search/constants/DocumentSearchConstants.js';
import {handleFetchNetworkError} from '../utils/AjaxUtils.js';
import {extractLongDate} from '../utils/DateUtils.js';
import search from './operations/search.js';

const DEFAULT_FETCH_LIMITS = {
	start: 0,
	fetchLimit: 100,
	limit: MAX_LIMIT
};

export default function createRestSearchApiCall(
		searchEndpointUrl, dateRangeParameters = [], defaultSortBy = [], defaultFetchLimits = DEFAULT_FETCH_LIMITS
) {
	const {start: defaultStart, fetchLimit: defaultFetchLimit, limit: defaultLimit} = defaultFetchLimits;
	return function restSearch(
		params, sortBy = defaultSortBy, start = defaultStart, fetchLimit = defaultFetchLimit, limit = defaultLimit
	) {
		const finalParams = {...params};
		deleteUndefinedParams(finalParams);
		prepareDateRangeParameters(dateRangeParameters, finalParams);
		return performSearch(searchEndpointUrl, finalParams, sortBy, start, fetchLimit, limit)
			.then(resultList => convertDateParameters(resultList, dateRangeParameters))
			.catch(handleFetchNetworkError);
	};
}

function deleteUndefinedParams(searchParams) {
	Object.keys(searchParams).forEach(
		name => {
			const value = searchParams[name];
			const valueEmpty = value === null || value === undefined || value === '';
			if (valueEmpty) {
				delete searchParams[name];
			}
		}
	);
}

function prepareDateRangeParameters(dateRangeParameters, searchParams) {
	dateRangeParameters.forEach(paramName => {
		const paramValue = searchParams[paramName];
		if (paramValue) {
			searchParams[paramName] = dateParamToCRUDParam(paramValue);
		}
	});
}

function dateParamToCRUDParam(paramValue) {
	let searchRange = paramValue;
	if (moment.isMoment(paramValue)) {
		searchRange = Immutable.Map({from: paramValue, to: paramValue});
	}
	return dateRangeToCRUDParam(searchRange);
}

function dateRangeToCRUDParam(paramValue) {
	const fromPart = momentToCRUDDate(paramValue.get('from'));
	let toPart = paramValue.get('to') || '';
	if (toPart) {
		toPart = momentToCRUDDate(moment(toPart).add(1, 'days'));
	}
	return `${fromPart};${toPart}`;
}

function momentToCRUDDate(date) {
	return (date && date.format('YYYY-MM-DD')) || '';
}

function convertDateParameters(resultList, dateRangeParameters) {
	return resultList.map(result => dateRangeParameters.reduce(
		(convertedResult, parameter) => convertedResult.update(parameter, null, extractLongDate),
		 result
	));
}

function performSearch(searchEndpointUrl, params, sortBy, start, fetchLimit, limit) {
	const searchParameters = {
		params,
		sortBy,
		nocount: true,
		start,
		limit,
		fetchLimit
	};
	return search(searchEndpointUrl, searchParameters);
}
