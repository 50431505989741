export const WINDOW_PRESET_METRIC_KEYS = [
	'toolusage.WindowPresetSkull',
	'toolusage.WindowPresetLung',
	'toolusage.WindowPresetAbdomen',
	'toolusage.WindowPresetLiver',
	'toolusage.WindowPresetMediastinum',
	'toolusage.WindowPresetBone',
	'toolusage.WindowPresetSpine',
	'toolusage.WindowPresetPostmyelo',
	'toolusage.WindowPresetParsPetrosaOssisTemporalis',
	'toolusage.WindowPresetInitial',
	'toolusage.WindowPresetMinMax'
];

export const ANNOTATION_METRIC_KEYS = [
	'toolusage.PolylineAnnotation',
	'toolusage.GoniometryAnnotation',
	'toolusage.ArrowAnnotation',
	'toolusage.TextAnnotation',
	'toolusage.SyncpointAnnotation',
	'toolusage.CircleAnnotation',
	'toolusage.CirclemeasurementAnnotation',
	'toolusage.UsmeasurementAnnotation',
	'toolusage.MaskAnnotation',
	'toolusage.ShutterAnnotation',
	'toolusage.Four-point-goniometryAnnotation',
	'toolusage.Parallel-line-distanceAnnotation',
	'toolusage.Ellipse-measurementAnnotation',
	'toolusage.HounsfieldAnnotation',
	'toolusage.OffsetPageSyncTool'
];

export const LAYOUT_METRIC_KEYS = [
	'toolusage.Layout1x1',
	'toolusage.Layout1x2',
	'toolusage.Layout2x1',
	'toolusage.Layout2x2'
];

export const IMAGE_VIEWER_TOOLS_METRIC_KEYS = [
	'toolusage.WindowTool',
	'toolusage.ZoomTool',
	'toolusage.PanTool',
	'toolusage.PinchZoom'
];

export const VIEWER_TOOLS_METRIC_KEYS = [
	'toolusage.ShowImageInfo',
	'toolusage.ShowFullScreen',
	'toolusage.ToggleAnnotations',
	'toolusage.ResetViewer',
	'toolusage.PointSyncTool'
];

export const PATIENT_DETAILS_METRIC_KEYS = [
	'toolusage.UnfoldPatientRecordCategory',
	'toolusage.FoldPatientRecordCategory'
];

export const EXPORT_METRIC_KEYS = [
	'export.DownloadDocument',
	'export.DownloadFile',
	'export.DownloadImage',
	'export.DownloadSeries'
];

export const INFO_METRIC_KEYS = [
	'info.WebGLSupported',
	'info.WebGLNotSupported'
];

export const PHOTO_UPLOAD_METRIC_KEYS = [
	'archivedocuments.OpenArchiveDialog',
	'archivedocuments.UploadInitiated'
];

export const SHOW_HELP_METRIC_KEY = 'toolusage.ShowHelp';
