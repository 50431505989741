import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';
import RestoreTrashButton from './RestoreTrashButton.js';

export default function UploadRestoreTrashButton() {
	const {restoreTrashedFiles, numTrashedFiles} = useBrick(UploadFilesCollection, selectRestoreFilesBrickProps);
	return (
		<RestoreTrashButton onClick={restoreTrashedFiles} numTrashedFiles={numTrashedFiles} />
	);
}

function selectRestoreFilesBrickProps(service) {
	return {
		restoreTrashedFiles: service.restoreTrashedFiles,
		numTrashedFiles: service.getTrashedFilesCount()
	};
}
