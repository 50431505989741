import {WEBAPP_NAME} from '../../../commons/constants/EnvironmentConstants.js';
import {debugWarn} from '../../../commons/utils/DebugLog.js';
import RouteBasedPatientDetailsSelectors from './RouteBasedPatientDetailsSelectors.js';
import UserContextBasedPatientDetailsSelectors from './UserContextBasedPatientDetailsSelectors.js';


const FallBackPatientDetailsSelectors = {
	selectPatientDetailsId: () => NaN,
	selectItemType: () => null
};

function selectPatientDetailsSelectors() {
	switch (WEBAPP_NAME) {
		case 'webview':
			return RouteBasedPatientDetailsSelectors;
		case 'webpatient':
			return UserContextBasedPatientDetailsSelectors;
		default:
			debugWarn(`Using fallback patient details selectors for app: ${WEBAPP_NAME}`);
			return FallBackPatientDetailsSelectors;
	}
}

const AppSpecificSelectors = selectPatientDetailsSelectors();

export default AppSpecificSelectors;
