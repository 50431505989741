import {
	CLOSE_ALL_MATCHING_PERSISTENT_PORTALS,
	CLOSE_PERSISTENT_PORTAL,
	UPDATE_PERSISTENT_PORTAL
} from '../constants/PersistentPortalActionTypes.js';
import {createAction} from '../utils/ActionUtils.js';

export const updatePersistentPortal = createAction(UPDATE_PERSISTENT_PORTAL,
	(id, content) => ({id, content})
);
export const closePersistentPortal = createAction(CLOSE_PERSISTENT_PORTAL);
export const closeAllMatchingPortals = createAction(CLOSE_ALL_MATCHING_PERSISTENT_PORTALS);
