import Immutable from 'immutable';

import {createAction} from '../../commons/utils/ActionUtils.js';
import {
	PUSH_LOCATION,
	REPLACE_LOCATION,
	RESTORE_FROM_HISTORY,
	UPDATE_LOCATION_STATE
} from '../constants/LocationActionTypes.js';
import {fromUrlString} from '../LocationUtils.js';

function createLocationAction(actionType) {
	return createAction(actionType,
		(value, path = [], forceUpdate = false) => {
			const valueIsLocationAction = Boolean(value) && typeof (value) === 'object' ? (value.isLocationAction || false) : false;
			return valueIsLocationAction ? value : ({value, path, forceUpdate, isLocationAction: true});
		}
	);
}

export const updateLocationState = createLocationAction(UPDATE_LOCATION_STATE);
export const restoreFromHistory = createAction(RESTORE_FROM_HISTORY,
	(url, historyState) => (
		(historyState === null || historyState === undefined) ? fromUrlString(url) : Immutable.fromJS(historyState)
	)
);

export const pushLocation = createLocationAction(PUSH_LOCATION);
export const replaceLocation = createLocationAction(REPLACE_LOCATION);

export function replaceHiddenLocationInformation(value, path, forceUpdate) {
	return replaceLocation(value, ['hidden'].concat(path), forceUpdate);
}
