import React from 'react';
import {CircularProgress} from '@mui/material';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks/index.js';

const DEFAULT_RADIUS = 30;

export default function Spinner(props) {
	const {description, radius, className, isSpinning, color, sx} = props;
	const styles = useMemoFactory(getSpinnerStyles, isSpinning, sx);
	return (
		<CircularProgress className={className} size={radius * 2} aria-label={description} color={color} sx={styles}
		                  aria-hidden={!isSpinning} />
	);
}

Spinner.propTypes = {
	className: PropTypes.string,
	radius: PropTypes.number,
	isSpinning: PropTypes.bool,
	description: PropTypes.string,
	color: PropTypes.string,
	sx: PropTypes.object
};

Spinner.defaultProps = {
	radius: DEFAULT_RADIUS,
	isSpinning: true
};

function getSpinnerStyles(isSpinning, sx) {
	const mainStyles = {
		...sx,
		transition: 'opacity ease-in-out 0.42s'
	};
	return isSpinning ? mainStyles : {
		...mainStyles,
		opacity: 0,
		animationPlayState: 'paused'
	};
}
