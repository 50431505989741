import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../commons/utils/ObjectUtils';
import {isHTMLTagElement} from '../../commons/utils/ReactUtils.js';
import {range} from '../../commons/utils/SeqUtils.js';

import '../../../styles/material-design/components/Column.scss';

const XS_MAX_COLUMNS = 4;
const XS_ALLOWED_COLUMNS = range(1, XS_MAX_COLUMNS);
const SM_MAX_COLUMNS = 8;
const SM_ALLOWED_COLUMNS = range(1, SM_MAX_COLUMNS);
const DEFAULT_MAX_COLUMNS = 12;
const DEFAULT_ALLOWED_COLUMNS = range(1, DEFAULT_MAX_COLUMNS);

const COLUMN_WIDTH_PROP_TYPE = PropTypes.oneOfType([
	PropTypes.string,
	PropTypes.number,
	PropTypes.shape({
		xs: PropTypes.oneOf(XS_ALLOWED_COLUMNS),
		sm: PropTypes.oneOf(SM_ALLOWED_COLUMNS),
		lg: PropTypes.oneOf(DEFAULT_ALLOWED_COLUMNS),
		xl: PropTypes.oneOf(DEFAULT_ALLOWED_COLUMNS),
		xxl: PropTypes.oneOf(DEFAULT_ALLOWED_COLUMNS)
	})
]);

const XS_TO_SM = 2;
const SM_TO_LG = 1.5;

export default class Column extends React.PureComponent {
	render() {
		const {element} = this.props;
		const Container = element || 'div';
		const childProps = this.getChildProps(Container);
		return <Container {...childProps} />;
	}

	getChildProps(Container) {
		let childProps = {
			...cloneWithoutProperties(this.props, 'element', 'width', 'justifyContent')
		};
		if (isHTMLTagElement(Container)) {
			childProps = cloneWithoutProperties(childProps,
				'viewerId',
				'viewerItem',
				'annotationType',
				'frameOfReferenceUID',
				'show',
				'active',
				'annotationIds',
				'addAnnotation',
				'setActiveViewer',
				'removeAnnotation',
				'remove3dSyncPoint',
				'selector'
			);
		}
		return {
			...childProps,
			className: this.getColumnClasses(),
			style: this.getStyle()
		};
	}

	getColumnClasses() {
		const {justifyContent, className} = this.props;
		const classes = ['material-column'];
		const columnsDefinition = this.getColumnsDefinition();

		Object.keys(columnsDefinition).forEach(sizeType => {
			const columnWidth = columnsDefinition[sizeType];
			if (columnWidth) {
				classes.push(`material-column-${sizeType}-${columnWidth}`);
			}
		});

		if (justifyContent === 'center') {
			classes.push('material-column--justifyCenter');
		} else if (justifyContent === 'right') {
			classes.push('material-column--justifyRight');
		}

		if (className) {
			classes.push(className);
		}

		return classes.join(' ');
	}

	getColumnsDefinition() {
		const {width} = this.props;
		let columnsDefinition = {};
		const numberedWidth = Number(width);
		if (width instanceof Object) {
			columnsDefinition = {...width};
			columnsDefinition.sm = width.sm || columnsDefinition.xs * XS_TO_SM;
			columnsDefinition.lg = width.lg || Math.round(columnsDefinition.sm * SM_TO_LG);
			columnsDefinition.xl = width.xl || columnsDefinition.lg;
			columnsDefinition.xxl = width.xxl || columnsDefinition.xl;
		} else if (!isNaN(numberedWidth) && (typeof numberedWidth) === 'number') {
			columnsDefinition = {xs: width, sm: width, lg: width, xl: width, xxl: width};
		}
		return columnsDefinition;
	}

	getStyle() {
		const {width, style} = this.props;
		if ((typeof width) === 'string') {
			return {
				width,
				...style
			};
		}
		return style;
	}
}

Column.propTypes = {
	justifyContent: PropTypes.oneOf(['center', 'right']),
	width: COLUMN_WIDTH_PROP_TYPE,
	className: PropTypes.string,
	element: PropTypes.elementType,
	style: PropTypes.object
};

Column.defaultProps = {
	width: 1
};
