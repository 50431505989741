import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {SERIES_SORT_REGEX} from '../../commons/constants/SynSettingsConstants';
import {booleanValue} from '../../commons/utils/BooleanUtils.js';
import {extractLongDate} from '../../commons/utils/DateUtils.js';
import {debugError} from '../../commons/utils/DebugLog.js';
import {MARK_SHARES_DONE} from '../../inbox/constants/InboxActionTypes.js';
import {CLEAR_PATIENT_DATA, FILTER_PATIENT_DETAILS, LOAD_PATIENT} from '../constants/PatientDetailsActionTypes.js';
import {PATIENT_DETAILS_DISPLAY_FORMAT, PATIENT_DETAILS_LOAD_ERROR} from '../constants/PatientDetailsPropertyNames.js';
import {createDocumentMatcher} from '../data/FilterParser';
import {filterPatientDocuments, getNodeId, sortSeries} from '../data/PatientDetailsDataUtils.js';
import PatientInfo from '../data/PatientInfo.js';

export default handleActions({
	[LOAD_PATIENT]: handlePatientDetails,
	[MARK_SHARES_DONE]: handleMarkSharesDone,
	[CLEAR_PATIENT_DATA]: getInitialPatientDetailsData,
	[FILTER_PATIENT_DETAILS]: handleFilterPatientDetails
}, getInitialPatientDetailsData());

function handleFilterPatientDetails(patientDetails, filterAction) {
	const {payload: filterString} = filterAction;
	let nextState = patientDetails;
	const hasOriginalData = patientDetails.has('originalDocuments') && patientDetails.has('originalStructure');
	if (filterString === '') {
		if (hasOriginalData) {
			nextState = nextState
				.set('documents', nextState.get('originalDocuments'))
				.set('structure', nextState.get('originalStructure'))
				.remove('originalDocuments')
				.remove('originalStructure')
				.remove('filterString');
		}
	} else {
		if (!hasOriginalData) {
			nextState = nextState
				.set('originalDocuments', nextState.get('documents'))
				.set('originalStructure', nextState.get('structure'));
		}
		nextState = nextState
			.set('documents', nextState.get('originalDocuments'))
			.set('structure', nextState.get('originalStructure'));
		nextState = filterPatientDetails(nextState, filterString);
	}
	return nextState;
}

function handlePatientDetails(currentPatientDetails, {error, payload}) {
	return error
		? handleLoadError(payload)
		: reformatPatientData(payload);
}

function extractPatientInfo(rawPatientData) {
	return new PatientInfo(rawPatientData)
		.update('birth_date', extractLongDate)
		.update('accepts_uploads', false, booleanValue);
}

function convertDocument(rawDocument) {
	let document = Immutable.Map(rawDocument).withMutations(doc => doc
		.update('document_created_when', extractLongDate)
		.update('shareable', false, booleanValue)
		.update('exportable', false, booleanValue)
	);

	if (document.has('document_share')) {
		const documentShare = document.get('document_share');
		if (documentShare === null) {
			document = document.remove('document_share');
		} else {
			document = document.set('document_share', documentShare.update('done', booleanValue));
		}
	}
	if (document.get('document_type_fk') === 'DICOM_STUDY') {
		return document.updateIn(['dicom_study', 'series'],
			null, value => sortSeries(value, SERIES_SORT_REGEX));
	}
	return document;
}

const convertNode = node => node.updateIn(['nodeAttributes', 'expand'], '0', booleanValue);

function reformatPatientData(rawPatientData) {
	const patient = extractPatientInfo(rawPatientData);
	const onlyShares = rawPatientData.get('onlyShares', false);
	const displayFormat = rawPatientData.get(PATIENT_DETAILS_DISPLAY_FORMAT, null);
	const initialDocumentId = rawPatientData.get('initial_document_id', null);
	const queryParameters = rawPatientData.get('queryParameters', {});
	const numRemovedDocuments = rawPatientData.get('num_documents_removed');

	const documents = Immutable.OrderedMap(rawPatientData.get('documents')
		.map(convertDocument)
		.map(doc => [doc.get('id'), doc])
	);

	const structure = Immutable.OrderedMap(
		rawPatientData.get('structure').map(node => [getNodeId(node), convertNode(node)])
	);

	return Immutable.fromJS({
		patient,
		numRemovedDocuments,
		documents,
		structure,
		onlyShares,
		initial_document_id: initialDocumentId,
		queryParameters,
		[PATIENT_DETAILS_DISPLAY_FORMAT]: displayFormat
	});
}

function handleLoadError(error) {
	return Immutable.fromJS({
		[PATIENT_DETAILS_LOAD_ERROR]: error
	});
}

/**
 * @param currentPatientDetails the currentPatientDetails where it should be able to set done marks.
 * @param shareUpdates an array of share updates. Each share update consist of the
 * following structure { patId, shareId, done }.
 */
function handleMarkSharesDone(currentPatientDetails, {payload: shareUpdates}) {
	const currentPatientID = currentPatientDetails.getIn(['patient', 'id'], null);
	const shareUpdatesForPatient = shareUpdates.filter(({patId}) => currentPatientID === patId);

	return currentPatientDetails.update('documents', Immutable.Map(),
		documents => shareUpdatesForPatient.reduce(updateShareInDocuments, documents)
	);
}

function updateShareInDocuments(documents, {shareId, done}) {
	return documents.map(document => updateShareInDocument(document, shareId, done));
}

function updateShareInDocument(document, shareId, done) {
	const documentShare = document.get('document_share', false);
	return ((documentShare && documentShare.get('id') === shareId)
		? document.setIn(['document_share', 'done'], done)
		: document
	);
}

function getInitialPatientDetailsData(currentPatientDetails) {
	const currentDisplayFormat = currentPatientDetails &&
		currentPatientDetails.get(PATIENT_DETAILS_DISPLAY_FORMAT, null);
	let initialPatientDetails = Immutable.Map();
	if (currentDisplayFormat) {
		initialPatientDetails = initialPatientDetails.set(PATIENT_DETAILS_DISPLAY_FORMAT, currentDisplayFormat);
	}
	return initialPatientDetails;
}

export function filterPatientDetails(newState, filterString) {
	let nextState;
	try {
		const documentMatcher = createDocumentMatcher(filterString);
		nextState = filterPatientDocuments(newState, documentMatcher)
			.set('filterString', filterString);
	} catch (parsingError) {
		// NOTE: Errors might be signaled to user in the future.
		debugError('Failed to parse filter string', parsingError);
		nextState = newState.merge({
			documents: Immutable.List(),
			structure: Immutable.List(),
			filterString
		});
	}
	return nextState;
}
