import React, {useCallback, useContext} from 'react';

import clickTracked from '../../metrics-collector/utils/clickTracked.js';
import {EnterFullscreenIcon, ExitFullscreenIcon} from '../../ui/components/icons/FullscreenIcons.js';
import ViewerContext from './ViewerContext.js';
import ViewerToolbarButton from './ViewerToolbarButton.js';

const ClickTrackedFullscreenToggleButton = clickTracked(ViewerToolbarButton);

function ViewerFullscreenToolbarButton() {
	const {toggleFullScreen, isFullScreen} = useContext(ViewerContext);
	const clickHandler = useCallback(() => toggleFullScreen(isFullScreen), [toggleFullScreen, isFullScreen]);
	const Icon = isFullScreen ? ExitFullscreenIcon : EnterFullscreenIcon;
	return (
		<ClickTrackedFullscreenToggleButton onClick={clickHandler} metricsKey='ShowFullScreen'>
			<Icon />
		</ClickTrackedFullscreenToggleButton>
	);
}
export default ViewerFullscreenToolbarButton;
