import React from 'react';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import HumanReadableDataSize, {
	useFormattedDataSize
} from '../../../webview/commons/components/HumanReadableDataSize.js';
import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import {Body2} from '../../../webview/ui/components/typography/Typography.js';
import UploadContext from '../../bricks/UploadContext.js';
import UploadFilesCollection from '../../bricks/UploadFilesCollection.js';

export default React.memo(UploadFilesSummary);

function UploadFilesSummary() {
	const amountWanted = useBrick(UploadFilesCollection, files => files.getTotalSize());
	const uploadQuotaBytes = useBrick(UploadContext, context => context.getUploadQuota());
	const uploadQuota = useFormattedDataSize(uploadQuotaBytes);
	const displayRemainingQuota = uploadQuotaBytes !== Infinity;
	return (
		<Body2 className='upload-files-summary' paragraph={false} mr={2} flexGrow={1} textAlign='right'>
			<SynFormattedMessage message='Phrase_UploadTotal' />
			{displayRemainingQuota &&
					<React.Fragment>
						&nbsp;
						<SynFormattedMessage message='Phrase_AvailableUploadQuota' messageParams={{uploadQuota}} />
					</React.Fragment>
			}
				:&nbsp;
			<HumanReadableDataSize amountBytes={amountWanted} />
		</Body2>
	);
}
