import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import Alert from '../../../webview/ui/components/Alert.js';
import AlertTitle from '../../../webview/ui/components/AlertTitle.js';
import ConfirmationButton from './ConfirmationButton.js';

export default function AlertDisplay(props) {
	const {confirm, patient} = props;
	return (
		<Alert className='other-patients-alert' severity='error' action={<ConfirmationButton confirm={confirm} />}>
			<AlertTitle>
				<SynFormattedMessage message='DataOfOtherPatientsWarningTitle' />
			</AlertTitle>
			<SynFormattedMessage message='DataOfOtherPatientsWarning' messageParams={patient} />
		</Alert>
	);
}

AlertDisplay.propTypes = {
	confirm: PropTypes.func,
	patient: PropTypes.shape({
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		birthDate: PropTypes.instanceOf(Date)
	})
};
