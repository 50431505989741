import createFormAccessors from '../../../commons/data/FormAccessorsFactory.js';
import {equals, notEquals, required} from '../../../commons/data/validators';
import {minLengthValidator} from '../../../commons/data/validators/lengthValidator.js';
import minDistinctCharsValidator from '../../../commons/data/validators/minDistinctCharsValidator.js';
import {succeedingCharsOnlyValidator} from '../../../commons/data/validators/trivialCharSequenceValidator.js';
import PasswordMessagesTranslator from '../../i18n/PasswordMessagesTranslator.js';

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MIN_DISTINCT_CHARS_LENGTH = 3;

export const CHANGE_PASSWORD_FORM_SCHEMA = {
	fields: {
		oldPassword: {
			label: 'OldPassword',
			defaultValue: null,
			validators: [required]
		},
		newPassword: {
			label: 'NewPassword',
			defaultValue: null,
			validators: [required, notEquals('oldPassword', PasswordMessagesTranslator, 'NewPasswordMustBeDifferentMessage')],
			hints: [minLengthValidator(PASSWORD_MIN_LENGTH, 'ToFewCharacters'), minDistinctCharsValidator(PASSWORD_MIN_DISTINCT_CHARS_LENGTH), succeedingCharsOnlyValidator]
		},
		newPasswordConfirmation: {
			label: 'NewPasswordConfirmation',
			defaultValue: null,
			validators: [required, equals('newPassword', PasswordMessagesTranslator, 'InvalidPasswordConfirmationMessage')]
		}
	}
};

export default createFormAccessors(CHANGE_PASSWORD_FORM_SCHEMA, PasswordMessagesTranslator);
