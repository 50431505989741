import ResultList from '../ResultList.js';
import get from './get.js';

const SORT_PROPERTY_PARAMETER_NAME = 'sortProperty';
const SORT_ORDER_PARAMETER_NAME = 'sortOrder';
const LIMIT_PARAMETER_NAME = 'limit';
const FETCH_LIMIT_PARAMETER_NAME = 'fetchLimit';
const START_PARAMETER_NAME = 'start';
const NOCOUNT_PARAMETER_NAME = 'nocount';

export default function search(
		url,
		{
			params = {},
			sortBy = [],
			start = -1,
			limit = -1,
			fetchLimit = -1,
			nocount = false
		} = {},
		resultsProcessor = null
) {
	const searchSpecificParams = toSearchParams(sortBy, start, limit, fetchLimit, nocount);
	const urlParams = {...params, ...searchSpecificParams};
	const rawDataStart = start === -1 ? 0 : start;
	const fetchTimeStamp = new Date().getTime();
	return get(url, urlParams).then(response => new ResultList(
		resultsProcessor && resultsProcessor(response) || response,
		rawDataStart, nocount, fetchTimeStamp
	));
}

export function toSearchParams(sortBy = [], start = -1, limit = -1, fetchLimit = -1, nocount = false) {
	const searchParams = {};

	const [sortProperties, sortOrder] = sortBy.reduce((sortingInformation, [sortProperty, sortDirection]) => {
		sortingInformation[0].push(sortProperty);
		sortingInformation[1].push(sortDirection);
		return sortingInformation;
	}, [[], []]);

	if (sortProperties.length > 0) {
		searchParams[SORT_PROPERTY_PARAMETER_NAME] = sortProperties.join(';');
		searchParams[SORT_ORDER_PARAMETER_NAME] = sortOrder.join(';');
	}

	if (start !== -1) {
		searchParams[START_PARAMETER_NAME] = start;
	}

	if (limit !== -1) {
		searchParams[LIMIT_PARAMETER_NAME] = limit;
	}

	if (fetchLimit !== -1) {
		searchParams[FETCH_LIMIT_PARAMETER_NAME] = fetchLimit;
	}

	if (nocount) {
		searchParams[NOCOUNT_PARAMETER_NAME] = '1';
	}

	return searchParams;
}
