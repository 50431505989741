import {StatusCodes} from 'http-status-codes';

import {TEXT_PLAIN} from '../../constants/MimeTypes.js';
import {handleUnexpectedResponse} from '../../utils/AjaxUtils.js';
import {toQueryString} from '../../utils/URLUtils.js';

/**
 * Retrieves a text file from the given url.
 * Every request which has a status code different from 200 will reject the result promise with an error of type
 * FetchError.
 *
 * @param {String} url the url to retrieve the request from
 * @param {String} expectedMimeType defines the mime type of the blob to retrieve. It is set on the 'Accept' header.
 * @param {Object} [parameters] an optional parameter specifying additional load parameters which are encoded and added
 *    to the URL
 * @returns {Promise} a promise which will contain the response as a USVString if the request finishes successfully.
 */
export default function getTextFile(url, parameters = {}, expectedMimeType = TEXT_PLAIN) {
	const encodedParams = toQueryString(parameters);
	const finalUrl = encodedParams === '' ? url : `${url}?${encodedParams}`;
	return fetch(finalUrl, {
		method: 'GET',
		credentials: 'include',
		headers: new Headers({
			Accept: expectedMimeType
		})
	}).then(handleUnexpectedResponse(StatusCodes.OK, `Could not binary resource at ${finalUrl}`))
		.then(response => response.text());
}
