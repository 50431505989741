import React from 'react';
import {useStore} from 'react-redux';

import AutoBrickFactory from '../../../bricks/components/AutoBrickFactory.js';
import DeviceInfo from '../../bricks/DeviceInfo.js';
import {useMemoFactory} from '../../utils/customHooks';

export default React.memo(function DeviceInfoProvider(props) {
	const reduxStore = useStore();
	const createDeviceInfo = useMemoFactory(createDeviceInfoFactory, reduxStore);
	const {children} = props;
	return (
		<AutoBrickFactory brickType={DeviceInfo} brickFactory={createDeviceInfo}>
			{children}
		</AutoBrickFactory>
	);
});

function createDeviceInfoFactory(reduxStore) {
	return () => new DeviceInfo(reduxStore);
}
