import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../../webview/commons/bricks/DeviceInfo.js';
import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import useTranslation from '../../../webview/i18n/hooks/useTranslation.js';
import IconButton from '../../../webview/ui/components/IconButton.js';
import DeleteAllIcon from '../../../webview/ui/components/icons/DeleteAllIcon.js';
import HorizontalLayout from '../../../webview/ui/components/layout/HorizontalLayout.js';
import FileBrowserTools from '../FileBrowserTools.js';
import {MINIMUM_WINDOW_HEIGHT_UPLOAD_LIST} from '../StepPrepareUploadList.js';
import UploadRestoreTrashButton from '../UploadRestoreTrashButton.js';
import {DeleteHead, MobileInfoHead, ToolButtonsHead} from './Columns.js';

export default function MobileListHeader(props) {
	const {removeAll} = props;
	const removeAllDisabled = !removeAll;
	const deleteAllButtonTitle = useTranslation('RemoveAllButtonTitle');
	const windowHeight = useBrick(DeviceInfo, deviceInfo => deviceInfo.getWindowSize().get('height'));
	const showRestoreTrashButton = windowHeight <= MINIMUM_WINDOW_HEIGHT_UPLOAD_LIST;
	return (
		<HorizontalLayout noGrow justify='start' className='table-header table-row'>
			<DeleteHead>
				<IconButton title={deleteAllButtonTitle} icon={<DeleteAllIcon />} disabled={removeAllDisabled}
				            onClick={removeAll} />
			</DeleteHead>
			<MobileInfoHead>
				<SynFormattedMessage message='Column_SelectedFiles' />
			</MobileInfoHead>
			{showRestoreTrashButton && (
				<DeleteHead>
					<UploadRestoreTrashButton />
				</DeleteHead>
			)}
			<ToolButtonsHead>
				<FileBrowserTools />
			</ToolButtonsHead>
		</HorizontalLayout>
	);
}

MobileListHeader.propTypes = {
	removeAll: PropTypes.func
};
