import React from 'react';
import PropTypes from 'prop-types';

import GridLayout from '../../commons/components/layout/GridLayout.js';
import {range} from '../../commons/utils/SeqUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import ViewerThemeProvider from '../../ui/components/theming/ViewerThemeProvider.js';
import ViewerContainer from '../flux/containers/ViewerContainer.js';
import {viewerIndexToViewerId} from '../utils/ViewerUtils.js';
import ViewerLayoutLocationSynchronizer from './ViewerLayoutLocationSynchronizer.js';

export default class ViewerMatrix extends React.PureComponent {
	render() {
		const {rows, columns, className} = this.props;
		const nrViewers = rows * columns;
		const viewers = range(0, nrViewers - 1).map(
			i => <ViewerContainer key={i} viewerId={viewerIndexToViewerId(i)} />
		);
		const combinedClassName = combineClassNames(className, matrixClassName(columns, rows));
		return (
			<ViewerThemeProvider>
				<GridLayout rows={rows} columns={columns} className={combinedClassName}>
					{viewers}
					<ViewerLayoutLocationSynchronizer />
				</GridLayout>
			</ViewerThemeProvider>
		);
	}
}

ViewerMatrix.propTypes = {
	rows: PropTypes.number,
	columns: PropTypes.number,
	className: PropTypes.string
};

function matrixClassName(columns, rows) {
	return `m${columns}${rows}`;
}
