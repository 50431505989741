import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import GeneralMessagesTranslator from '../../i18n/translators/GeneralTranslator.js';
import Button from '../../ui/components/Button.js';
import Dialog from './Dialog.js';
import DialogBody from './DialogBody.js';
import DialogButtonContainer from './DialogButtonContainer.js';
import DialogFooter from './DialogFooter.js';
import DialogHeader from './DialogHeader.js';

const DEFAULT_CLOSE_BUTTON_LABEL = <SynFormattedMessage message='ClosePhrase' translator={GeneralMessagesTranslator} />;

export default class SimpleDialog extends React.PureComponent {
	render() {
		const {
			title, children, onClose, closeButtonLabel, className, onFocus, onBlur, alwaysShowCloseButton
		} = this.props;
		const finalCloseButtonLabel = closeButtonLabel || DEFAULT_CLOSE_BUTTON_LABEL;

		return (
			<Dialog className={className} onFocus={onFocus} onBlur={onBlur} onClose={onClose}>
				<DialogHeader alwaysShowCloseButton={alwaysShowCloseButton} onClose={onClose} title={title} />
				<DialogBody>
					{children}
				</DialogBody>
				<DialogFooter>
					<DialogButtonContainer>
						<Button flat opaque onClick={onClose}>
							{finalCloseButtonLabel}
						</Button>
					</DialogButtonContainer>
				</DialogFooter>
			</Dialog>
		);
	}
}

SimpleDialog.propTypes = {
	title: PropTypes.node,
	onClose: PropTypes.func,
	closeButtonLabel: PropTypes.node,
	className: PropTypes.string,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	alwaysShowCloseButton: PropTypes.bool
};

SimpleDialog.defaultProps = {
	alwaysShowCloseButton: false
};
