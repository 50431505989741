import {spawn} from 'redux-saga/effects';

import changePasswordSaga from './changePasswordSaga.js';
import requestPasswordResetSaga from './requestPasswordResetSaga.js';
import setNewPasswordSaga from './setNewPasswordSaga.js';

export default function* passwordManagementSaga() {
	yield spawn(changePasswordSaga);
	yield spawn(setNewPasswordSaga);
	yield spawn(requestPasswordResetSaga);
}
