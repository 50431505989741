import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {IS_REPORT} from '../constants/AIMLinkTypes.js';
import {memoizeByFirstArg} from '../utils/FunctionUtils.js';

function getLinkData(state) {
	return state.aimLinks;
}

const getLinksFinder = createSelector(getLinkData,
	data => memoizeByFirstArg(
		documentId => memoizeByFirstArg(
			linkType => findLinks(data, documentId, linkType)
		)
	)
);

function findLinks(linkData, documentId, linkType) {
	return linkData.get(documentId, Immutable.List()).filter(link => link.get('type', '') === linkType);
}

export function getReportLinks(documentId) {
	return createSelector(getLinksFinder, finder => finder(documentId)(IS_REPORT));
}

export function hasReports(documentId) {
	return createSelector(getReportLinks(documentId), links => links.size > 0);
}

export function getFirstReport(documentId) {
	return createSelector(getReportLinks(documentId), links => (links.size > 0 && links.getIn([0, 'properties', 'reportFile'], null) || null));
}
