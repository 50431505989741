import React, {useCallback, useState} from 'react';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';

import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {memoWithName} from '../../commons/utils/ReactUtils.js';
import {ALIGN_LEFT, EXPAND_TOP} from '../constants/PupupMenuConstants.js';
import createPopupMenuDirections from '../createPopupMenuDirection.js';

export default memoWithName(PopupButton);

function PopupButton(props) {
	const {
		id, contentClassName, buttonElement: ButtonElement, children, align, expandDirection, onClick, disabled,
		...remainingProps
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);

	const closePopup = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);

	const handlePopupClick = useCallback(e => {
		if (e?.bubbles) {
			closePopup();
		}
	}, [closePopup]);

	const handleButtonClick = useCallback(e => {
		callSafe(onClick);
		setAnchorEl(e.currentTarget);
	}, [onClick, setAnchorEl]);

	const popoverDirections = createPopupMenuDirections(align, expandDirection);

	return (
		<React.Fragment>
			<ButtonElement className={disabled ? 'button-disabled' : ''} id={`${id}-button`} onClick={handleButtonClick}
								aria-controls={id} aria-haspopup='true' disabled={disabled} {...remainingProps} />
			<Popover PaperProps={{className: contentClassName}} id={id}
						onClick={handlePopupClick} anchorEl={anchorEl} open={Boolean(anchorEl)}
						onClose={closePopup} {...popoverDirections}>
				{children}
			</Popover>
		</React.Fragment>
	);
}

PopupButton.propTypes = {
	contentClassName: PropTypes.string,
	onClick: PropTypes.func,
	buttonElement: PropTypes.elementType.isRequired,
	id: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	expandDirection: PropTypes.string,
	align: PropTypes.string,
	disabled: PropTypes.bool
};

PopupButton.defaultProps = {
	disabled: false,
	expandDirection: EXPAND_TOP,
	align: ALIGN_LEFT
};
