/**
 * Given a urlFunction it creates the inverseFunction which extracts the variables corresponding to the
 * original url Parameters
 * @param urlFunction {Function} the urlFunction used to create its inverse
 * @returns {Function} a function which given a url returns the variables passed corresponding to the parameters
 * of the url parameter.
 * In case the url to extract the variables from can not be generated by the given url Function an Error is thrown.
 */
import {StatusCodes} from 'http-status-codes';

import handleHttpErrorState from '../api/operations/handleHttpErrorState.js';
import {APPLICATION_JSON} from '../constants/MimeTypes.js';
import {markAsHandled} from './ErrorHandlingUtils.js';

export function createUrlVariablesExtractionFunction(urlFunction) {
	const variablePlaceholders = [];
	for (let i = 0; i < urlFunction.length; ++i) {
		variablePlaceholders.push('([^/]+)');
	}
	const urlPattern = urlFunction(...variablePlaceholders);
	const urlRegex = new RegExp(urlPattern);

	return url => {
		const contextMatch = urlRegex.exec(url);
		if (!contextMatch) {
			throw new Error(`Url: ${url} did not match the given pattern ${urlPattern}`);
		}
		const variables = contextMatch.slice(1);
		return (variables.length === 1) ? variables[0] : variables;
	};
}

/**
 * returns the Content-Location header which was provided by an Ajax response
 * This can be useful for example in the case of
 */
export function getContentLocation(response) {
	return response.headers.get('Content-Location');
}

/**
 * returns the content type delivered through the Content-Type header of a
 * XMLHttpRequest
 *
 * @param {XMLHttpRequest} response
 */
export function getContentType(response) {
	return response.headers.get('Content-Type') || '';
}

const JSON_CONTENT_TYPE_REGEX = /^application\/json/;

/**
 * Checks the content type of a given request if it is json
 *
 * @param {XMLHttpRequest} response the response to check for JSON
 * @return true if it is indeed json
 */
export function isJSON(response) {
	return getContentType(response).match(JSON_CONTENT_TYPE_REGEX);
}

const HTML_CONTENT_TYPE_REGEX = /^text\/html/;
const XHTML_CONTENT_TYPE_REGEX = /^application\/xhtml\+xml/;

/**
 * Checks the content type of a given request if it is html
 *
 * @param {XMLHttpRequest} response the response to check for HTML
 * @return true if it is indeed HTML
 */
export function isHTML(response) {
	const contentType = getContentType(response);
	return (contentType.match(HTML_CONTENT_TYPE_REGEX) || contentType.match(XHTML_CONTENT_TYPE_REGEX));
}

/**
 * Helper function for creating the request description of a http request containing the given payload as body.
 * Furthermore it sets the content's mime type as well as the accept mime type to application/json
 *
 * @param {Object} payload the payload to transmit int the body of the fetch request
 * @param {String} requestMethod, defaults to POST
 * @returns {{method: String, headers: {Accept: String, Content-Type: String}, body: (String|*)}}
 */
export function createJSONRequest(payload, requestMethod = 'POST') {
	return {
		method: requestMethod,
		credentials: 'include',
		headers: {
			Accept: APPLICATION_JSON,
			'Content-Type': APPLICATION_JSON
		},
		body: JSON.stringify(payload)
	};
}

export function createMultipartRequest(requestMethod, ...parts) {
	const formData = new FormData();
	parts.forEach((part, i) => formData.append(`part${i}`, part));
	return {
		method: requestMethod,
		credentials: 'include',
		headers: {Accept: APPLICATION_JSON},
		body: formData
	};
}

export function handleUnexpectedResponse(expectedStatusCode = StatusCodes.OK, errorMessage = undefined) {
	return function serviceResponseHandler(response) {
		const isExpectedStatus = response.status === expectedStatusCode;
		return (isExpectedStatus
			? Promise.resolve(undefined)
			: tryParseJson(response)
		).then(json => handleResponseErrors(response, json, expectedStatusCode, errorMessage));
	};
}

export function tryParseJson(response) {
	return response.text()
		.then(text => {
			let parsedJson;
			try {
				parsedJson = JSON.parse(text);
			} catch (error) {
				// JSON parse error is ignored.
			}
			return parsedJson;
		});
}

function handleResponseErrors(response, responseAsJson, expectedStatusCode, errorMessage) {
	const {url} = response;
	const finalMessage = errorMessage || `Request failed for: ${url}`;
	handleHttpErrorState(response, expectedStatusCode, finalMessage, responseAsJson);
	return response;
}

export function handleFetchNetworkError(error) {
	if (error instanceof TypeError) {
		markAsHandled(error);
		throw error;
	}
}
