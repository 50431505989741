import createFormAccessors from '../../../commons/data/FormAccessorsFactory.js';
import {allFieldsFilledOut, equals, required} from '../../../commons/data/validators';
import ValidatorsMessagesTranslator from '../../../commons/data/validators/i18n/ValidatorsMessagesTranslator.js';
import {minLengthValidator} from '../../../commons/data/validators/lengthValidator.js';
import minDistinctCharsValidator from '../../../commons/data/validators/minDistinctCharsValidator.js';
import {succeedingCharsOnlyValidator} from '../../../commons/data/validators/trivialCharSequenceValidator.js';
import PasswordMessagesTranslator from '../../i18n/PasswordMessagesTranslator.js';

const MIN_NEW_PASSWORD_LENGTH = 8;
const MIN_NEW_PASSWORD_DISTINCT_LENGTH = 3;

export const NEW_PASSWORD_FORM_SCHEMA = {
	fields: {
		newPassword: {
			label: 'NewPassword',
			defaultValue: null,
			validators: [required, equals(['newPasswordConfirmation'])],
			hints: [
				minLengthValidator(MIN_NEW_PASSWORD_LENGTH, 'ToFewCharacters'),
				minDistinctCharsValidator(MIN_NEW_PASSWORD_DISTINCT_LENGTH),
				succeedingCharsOnlyValidator
			]
		},
		newPasswordConfirmation: {
			label: 'NewPasswordConfirmation',
			defaultValue: null,
			validators: [required, equals(['newPassword'])]
		}
	},
	validators: [allFieldsFilledOut(ValidatorsMessagesTranslator, 'AllFieldsAreRequired')]
};

export default createFormAccessors(NEW_PASSWORD_FORM_SCHEMA, PasswordMessagesTranslator);
