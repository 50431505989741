import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {BEGIN_SESSION, END_SESSION} from '../../authentication/constants/SessionActionTypes.js';
import {DELETE_STRUCTURE, MERGE_STRUCTURE, RESET_STRUCTURE} from '../constants/StructuredDataActionTypes.js';

const INITIAL_STATE = Immutable.Map();

function resetStructure(state, {payload: {id, structure}}) {
	return state.set(id, structure);
}

function mergeStructure(state, {payload: {id, structure}}) {
	return state.update(id, Immutable.Map(), currentStructure => currentStructure.merge(structure));
}

function deleteStructure(state, {payload: id}) {
	return state.remove(id);
}

function resetReducer() {
	return INITIAL_STATE;
}

export default handleActions({
	[MERGE_STRUCTURE]: mergeStructure,
	[RESET_STRUCTURE]: resetStructure,
	[DELETE_STRUCTURE]: deleteStructure,
	[END_SESSION]: resetReducer,
	[BEGIN_SESSION]: resetReducer
}, INITIAL_STATE);
