import React from 'react';
import PropTypes from 'prop-types';

import {noop} from '../utils/FunctionUtils.js';

/**
 * Renders its children after the specified delay in milliseconds.
 *
 * @author p.spitzlinger@synedra.com
 */
export default class Delayed extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.cancel = noop;
		this.state = {
			delayed: props.delayMS > 0
		};
	}

	render() {
		const {children} = this.props;
		const {delayed} = this.state;
		return delayed ? false : children;
	}

	delay() {
		const {delayMS} = this.props;
		const timerId = window.setTimeout(this.onTimeout.bind(this), delayMS);
		this.cancel = () => {
			window.clearTimeout(timerId);
			this.cancel = noop;
		};
	}

	onTimeout() {
		this.cancel = noop;
		this.setState({delayed: false});
	}

	componentDidMount() {
		const {delayed} = this.state;
		if (delayed) {
			this.delay();
		}
	}

	componentWillUnmount() {
		this.cancel();
	}
}

Delayed.propTypes = {
	delayMS: PropTypes.number
};
