import React from 'react';

import Toolbar from '../../material-design/components/Toolbar.js';

import '../../../styles/commons/components/AppBar.scss';

export default function AppBar(props) {
	const {children} = props;
	return (
		<Toolbar className='webview-appbar'>
			{children}
		</Toolbar>
	);
}

