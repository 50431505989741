import {
	FEATURE_ALL_LOWERCASE_USERNAME_ENABLED, FEATURE_DOCUMENT_PERMITS_ENABLED,
	FEATURE_EXPORT_DOCUMENTS_ENABLED,
	FEATURE_UPLOAD_ENABLED
} from './constants/WebPatientSettingsContants.js';

export function isUploadEnabled() {
	return FEATURE_UPLOAD_ENABLED;
}

export function isExportDocumentsEnabled() {
	return FEATURE_EXPORT_DOCUMENTS_ENABLED;
}

export function isDocumentPermitsEnabled() {
	return FEATURE_DOCUMENT_PERMITS_ENABLED;
}

export function isEnableAllLowerCaseUserNameEnabled() {
	return FEATURE_ALL_LOWERCASE_USERNAME_ENABLED;
}
