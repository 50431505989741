import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../../webview/commons/bricks/DeviceInfo.js';
import {useFormattedDataSize} from '../../../webview/commons/components/HumanReadableDataSize.js';
import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import Alert from '../../../webview/ui/components/Alert.js';

export default function UploadQuotaAlert(props) {
	const {remainingQuota} = props;
	return remainingQuota <= 0 && <QuotaMessage remainingQuota={remainingQuota} />;
}

UploadQuotaAlert.propTypes = {
	remainingQuota: PropTypes.number.isRequired
};

function QuotaMessage(props) {
	const {remainingQuota} = props;
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const exceeded = useFormattedDataSize(Math.abs(remainingQuota));
	return (
		<Alert className='upload-quota-alert' severity='error' sx={isMobileDevice ? null : {my: 1}}>
			<SynFormattedMessage message='UploadQuotaExceeded' messageParams={{exceeded}} />
		</Alert>
	);
}

QuotaMessage.propTypes = {
	remainingQuota: PropTypes.number.isRequired
};
