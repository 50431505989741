import React from 'react';
import PropTypes from 'prop-types';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import DeviceInfo from '../../webview/commons/bricks/DeviceInfo.js';
import {dropZonePropTypes} from '../../webview/commons/components/DropZone.js';
import UploadController from '../bricks/UploadController.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';
import DialogBasedUploadFilesBrowser from './DialogBasedUploadFilesBrowser.js';
import EmptyUploadFilesList from './EmptyUploadFilesList.js';
import OtherPatientsAlert from './OtherPatientsAlert';
import UploadArea from './UploadArea.js';
import UploadFilesBrowser from './UploadFilesBrowser.js';
import UploadQuotaAlert from './UploadFilesList/UploadQuotaAlert.js';

export const MINIMUM_WINDOW_HEIGHT_UPLOAD_LIST = 800;

export default function StepPrepareUploadList(props) {
	const {dropZoneProps, children} = props;
	const {numFiles} = useBrick(UploadFilesCollection, selectUploadFilesBrickProps);
	const remainingQuotaBytes = useBrick(
		UploadController, controller => controller.getRemainingUploadQuota()
	);
	return (
		<React.Fragment>
			{children}
			<UploadArea dropZoneProps={dropZoneProps} />
			<FileListArea visible={numFiles > 0} />
			<EmptyUploadFilesList visible={numFiles === 0} />
			<UploadQuotaAlert remainingQuota={remainingQuotaBytes} />
			<OtherPatientsAlert />
		</React.Fragment>
	);
}

StepPrepareUploadList.propTypes = {
	dropZoneProps: dropZonePropTypes.dropZoneProps
};

function selectUploadFilesBrickProps(service) {
	return {
		numFiles: service.getPreparedFilesCount()
	};
}

function FileListArea(props) {
	const {visible} = props;
	const {windowHeight, isMobile} = useBrick(DeviceInfo, selectDeviceInfo);
	return isMobile && windowHeight <= MINIMUM_WINDOW_HEIGHT_UPLOAD_LIST
		? <DialogBasedUploadFilesBrowser showButton={visible} />
		: <UploadFilesBrowser visible={visible} />;
}

FileListArea.propTypes = {
	visible: PropTypes.bool
};

FileListArea.defaultProps = {
	visible: true
};

function selectDeviceInfo(deviceInfo) {
	return {
		windowHeight: deviceInfo.getWindowSize().get('height'),
		isMobile: deviceInfo.isMobileDevice()
	};
}
