import React from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {FixedSizeList} from 'react-window';

import {useMemoFactory} from '../../utils/customHooks';
import {withForwardRef} from '../../utils/ReactUtils.js';

/**
 * This simple wrapper around the FixedSizeList from react-window applies auto-sizer capabilities
 * to the former if no explicit width or height is specified.
 *
 * All passed props are simply forwarded to the internal FixedSizeList from react-window.
 *
 * @author p.spitzlinger@synedra.com
 */
export default withForwardRef(VirtualizedList, 'outerRef');

function VirtualizedList(props) {
	const boundRenderFunction = useMemoFactory(createRenderWithSizeProps, props);
	return (
		<AutoSizer>
			{boundRenderFunction}
		</AutoSizer>
	);
}
VirtualizedList.propTypes = FixedSizeList.propTypes;

function createRenderWithSizeProps(props) {
	return sizeProps => renderWithSizeProps(sizeProps, props);
}

function renderWithSizeProps(sizeProps, props) {
	const {width, height} = sizeProps;
	const {width: outerWidth, height: outerHeight, outerRef, ...remainingProps} = props;
	const finalWidth = outerWidth || width;
	const finalHeight = outerHeight || height;

	return <FixedSizeList ref={outerRef} width={finalWidth} height={finalHeight} {...remainingProps} />;
}
