import Immutable from 'immutable';

import {DELETE_STRUCTURE, MERGE_STRUCTURE, RESET_STRUCTURE} from '../constants/StructuredDataActionTypes.js';
import {createAction} from '../utils/ActionUtils.js';

export const mergeStructure = createAction(MERGE_STRUCTURE, (id, structure) => ({id, structure}));

export const resetStructure = createAction(RESET_STRUCTURE, (id, structure = Immutable.Map()) => ({id, structure}));

export const deleteStructure = createAction(DELETE_STRUCTURE);

export function updateField(structureId, fieldId, newValue) {
	return mergeStructure(structureId, Immutable.Map({[fieldId]: newValue}));
}
