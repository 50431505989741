import React from 'react';

import {DEVICE_SIZE_EXTRA_SMALL} from '../../webview/commons/constants/DeviceInformation.js';
import VerticalLayout from '../../webview/ui/components/layout/VerticalLayout.js';
import ResponsiveCard from '../../webview/ui/components/ResponsiveCard.js';

export default function WebUploadCard(props) {
	const {children} = props;
	return (
		<ResponsiveCard cardComponent={VerticalLayout} centered maxCardWidth='450px' minCardWidth='400px'
		                breakPoint={DEVICE_SIZE_EXTRA_SMALL} justify='around' style={{textAlign: 'center', minHeight: '200px'}}>
			{children}
		</ResponsiveCard>
	);
}
