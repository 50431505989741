import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

export default function createReduxSelectedComponent(componentSelector) {
	return connect(withComponentSelector(componentSelector))(DynamicComponent);
}

function withComponentSelector(componentSelector) {
	return state => {
		const Component = componentSelector(state);
		if (!Component) {
			throw new Error('Component selector did return an invalid result');
		}
		return {Component};
	};
}

function DynamicComponent(props) {
	const {Component} = props;
	const childProps = {...props};
	delete childProps.Component;

	return (<Component {...childProps} />);
}

DynamicComponent.propTypes = {
	Component: PropTypes.elementType
};
