import Immutable from 'immutable';

import WebViewCrudResources from './CrudResources.js';
import {createCrudResourceFluxApi, mountCrudResourceFluxApi} from './CrudResourcesFluxApiUtils.js';

const mountedCrudResourceFluxApi = mountCrudResourceFluxApi(
	state => state.crudResources || Immutable.Map(),
	createCrudResourceFluxApi(WebViewCrudResources)
);
const {modality, clientConfig, shareReceiver, shareReceiverBackgroundValidator} = mountedCrudResourceFluxApi;

export {
	modality,
	clientConfig,
	shareReceiver,
	shareReceiverBackgroundValidator
};
export default mountedCrudResourceFluxApi;
