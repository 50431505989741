import {IS_DEBUG_BUILD} from '../../commons/constants/EnvironmentConstants.js';
import {isFeatureTrackingEnabled} from '../../commons/selectors/GeneralConfigSelectors.js';
import {METRICS_CONTEXT, METRICS_KEY} from '../constants/MetricsConstants.js';
import {getTrackingInformation, hasTrackingInformation, isExplicitTrackingAction} from '../utils/metadataUtils.js';

function metricsMiddleware(metricsHandler, knownMetrics) {
	return () => next => action => {
		if (hasTrackingInformation(action)) {
			const trackingInfo = getTrackingInformation(action);
			assertMetricIsKnown(trackingInfo, knownMetrics);
			metricsHandler(trackingInfo);
		}
		if (!isExplicitTrackingAction(action) || IS_DEBUG_BUILD) {
			return next(action);
		}
		return undefined;
	};
}

function noopMiddleware(knownMetrics) {
	return () => next => action => {
		checkMetric(action, knownMetrics);
		return next(action);
	};
}

function checkMetric(action, knownMetrics) {
	if (IS_DEBUG_BUILD && hasTrackingInformation(action)) {
		assertMetricIsKnown(getTrackingInformation(action), knownMetrics);
	}
}

function assertMetricIsKnown(trackingInfo, knownMetrics) {
	if (IS_DEBUG_BUILD && knownMetrics) {
		const {
			[METRICS_CONTEXT]: usageContext,
			[METRICS_KEY]: key
		} = trackingInfo;
		knownMetrics.assertMetricIsKnown(usageContext, key);
	}
}

export default function createMetricsMiddleware(metricsHandler, allowedMetrics) {
	return isFeatureTrackingEnabled()
		? metricsMiddleware(metricsHandler, allowedMetrics)
		: noopMiddleware(allowedMetrics);
}
