import GeneralTranslator from './translators/GeneralTranslator.js';
import SynMessageTranslator from './translators/SynMessageTranslator.js';

export default function createTranslator(
		translations, 
		parentTranslator = GeneralTranslator, 
		TranslatorClass = SynMessageTranslator) {
	const newTranslator = new TranslatorClass(translations, parentTranslator);
	newTranslator.tr = newTranslator.getTranslation.bind(newTranslator);
	return newTranslator;
}
