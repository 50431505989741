import React from 'react';

import {combineClassNames} from '../../../webview/commons/utils/StyleUtils.js';
import Column from '../../../webview/material-design/components/Column.js';

const DELETE_WIDTH = '67px';
const TOOL_BUTTONS_WIDTH = '67px';
const NAME_WIDTH = undefined;
const PATIENT_WIDTH = undefined;
const SIZE_WIDTH = '96px';
const DATE_WIDTH = '100px';
const TAN_WIDTH = '130px';
const EMAIL_WIDTH = '170px';

const OTHER_PATIENT_STYLE = {flexShrink: 0};
const DELETE_STYLE = {flexShrink: 0, paddingLeft: '16px'};
const TOOL_BUTTONS_STYLE = {flexShrink: 0};
const NAME_STYLE = {flexGrow: 1};
const PATIENT_STYLE = {flexGrow: 1};
const MOBILE_INFO_WIDTH = 'auto';
const MOBILE_INFO_STYLE = {flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', flexGrow: 1, overflow: 'initial'};
const EMAIL_STYLE = {alignItems: 'flex-start', overflow: 'initial'};
const TAN_STYLE = {alignItems: 'flex-start', overflow: 'initial'};
const DATE_STYLE = {alignItems: 'flex-start', overflow: 'initial'};

export const EmailHead = listColumn('EmailHead', EMAIL_WIDTH, 'table-head');
export const TanHead = listColumn('TanHead', TAN_WIDTH, 'table-head');
export const DateHead = listColumn('DateHead', DATE_WIDTH, 'table-head');
export const DeleteHead = listColumn('DeleteHead', DELETE_WIDTH, 'table-head', DELETE_STYLE);
export const ToolButtonsHead = listColumn('ToolButtonsHead', TOOL_BUTTONS_WIDTH, 'table-head', TOOL_BUTTONS_STYLE);
export const NameHead = listColumn('NameHead', NAME_WIDTH, 'table-head', NAME_STYLE);
export const PatientHead = listColumn('PatientHead', PATIENT_WIDTH, 'table-head', PATIENT_STYLE);
export const SizeHead = listColumn('SizeHead', SIZE_WIDTH, 'table-head');

export const EmailColumn = listColumn('EmailColumn', EMAIL_WIDTH, 'table-data', EMAIL_STYLE);
export const TanColumn = listColumn('TanColumn', TAN_WIDTH, 'table-data', TAN_STYLE);
export const DateColumn = listColumn('DateColumn', DATE_WIDTH, 'table-data', DATE_STYLE);
export const DeleteColumn = listColumn('DeleteColumn', DELETE_WIDTH, 'table-data', DELETE_STYLE);
export const NameColumn = listColumn('NameColumn', NAME_WIDTH, 'table-data', NAME_STYLE);
export const PatientColumn = listColumn('PatientColumn', PATIENT_WIDTH, 'table-data', PATIENT_STYLE);
export const SizeColumn = listColumn('SizeColumn', SIZE_WIDTH, 'table-data');
export const OtherPatientColumn = listColumn('OtherPatientColumn', undefined, 'table-data', OTHER_PATIENT_STYLE);

export const MobileInfoColumn = listColumn('MobileInfoColumn', MOBILE_INFO_WIDTH, 'table-data', MOBILE_INFO_STYLE);
export const MobileInfoHead = listColumn('MobileInfoHead', MOBILE_INFO_WIDTH, 'table-head', MOBILE_INFO_STYLE);

function listColumn(displayName, defaultWidth, className, columnStyle) {
	function ListColumn(props) {
		const {children, className: propsClassName, width = defaultWidth, style, ...remainingProps} = props;
		const finalClassNames = combineClassNames(className, propsClassName);
		const finalStyle = {
			...columnStyle,
			...style
		};
		return (
			<Column width={width} className={finalClassNames} style={finalStyle} {...remainingProps}>
				{children}
			</Column>
		);
	}
	ListColumn.displayName = displayName;
	ListColumn.propTypes = Column.propTypes;
	ListColumn.defaultProps = {
		...Column.defaultProps,
		width: defaultWidth
	};
	return ListColumn;
}
