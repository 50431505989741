import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import StepEnterSecondFactor from '../../components/StepEnterSecondFactor.js';
import {acquireSecondFactorToken} from '../actions/SessionActions.js';
import {selectUsername} from '../selectors/SessionSelectors.js';

export default connect(
	createStructuredSelector({
		username: selectUsername
	}),
	bindActions({
		acquireSecondFactorToken
	})
)(StepEnterSecondFactor);
