import React from 'react';
import MuiList, {listClasses} from '@mui/material/List';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

const LIST_STYLES = {
	flexGrow: 1,
	[`& .${listClasses.padding}}`]: {
		py: 16
	}
};

export default function Navigation(props) {
	const {children} = props;
	return (
		<MuiList sx={LIST_STYLES} component='nav'>
			<ScopedCssBaseline>
				{children}
			</ScopedCssBaseline>
		</MuiList>
	);
}
