import Color from 'color';
import merge from 'lodash.merge';

import {
	SYN_ORANGE,
	SYN_TURQUOISE, TEXT_SECONDARY_COLOR,
	VIEWER_PAPER_COLOR,
	VIEWER_PAPER_HOVER_COLOR, VIEWER_TOOLBAR_BACKGROUND_COLOR, VIEWER_TOOLBAR_BACKGROUND_COLOR_NAME
} from './ColorConstants.js';

export const MUI_FIELD_VARIANTS = {
	FILLED: 'filled',
	OUTLINED: 'outlined',
	STANDARD: 'standard',
	DEFAULT: 'outlined'
};

export const COLOR_DEFAULT = 'default';
export const COLOR_INHERIT = 'inherit';
export const COLOR_PRIMARY = 'primary';
export const COLOR_SECONDARY = 'secondary';
export const COLOR_ACTION = 'action';
export const COLOR_SUCCESS = 'success';
export const COLOR_INFO = 'info';
export const COLOR_ERROR = 'error';
export const COLOR_WARNING = 'warning';
export const TEXT_PRIMARY = 'textPrimary';
export const TEXT_SECONDARY = 'textSecondary';

const THEME_PALETTE = {
	[COLOR_PRIMARY]: {
		light: SYN_TURQUOISE['100'],
		main: SYN_TURQUOISE['500'],
		dark: SYN_TURQUOISE['700'],
		selection: SYN_TURQUOISE['50']
	},
	[COLOR_SECONDARY]: {
		light: SYN_ORANGE['100'],
		main: SYN_ORANGE['500'],
		dark: SYN_ORANGE['700']
	},
	text: {
		[COLOR_SECONDARY]: TEXT_SECONDARY_COLOR
	}
};

const DISABLED_ALPHA_RATIO = 0.46;
const DISABLED_SATURATION_RATIO = 0.8;
const VIEWER_THEME_PALETTE = merge({}, THEME_PALETTE, {
	background: {
		paper: VIEWER_PAPER_COLOR,
		[VIEWER_TOOLBAR_BACKGROUND_COLOR_NAME]: VIEWER_TOOLBAR_BACKGROUND_COLOR
	},
	text: {
		[COLOR_PRIMARY]: SYN_ORANGE['500']
	},
	action: {
		active: SYN_ORANGE['500'],
		hover: VIEWER_PAPER_HOVER_COLOR,
		disabled: new Color(SYN_ORANGE['500'])
			.desaturate(DISABLED_SATURATION_RATIO)
			.fade(DISABLED_ALPHA_RATIO)
			.string()
	}
});

export const SYN_MUI_THEME = {
	palette: THEME_PALETTE,
	typography: {
		h5Mono: {
			fontFamily: 'Roboto Mono, Menlo, Monaco, Courier, monospace',
			fontSize: '1.5rem',
			fontWeight: 400
		}
	}
};

export const SYN_VIEWER_MUI_THEME = {
	palette: VIEWER_THEME_PALETTE,
	components: {
		MuiPaper: {
			square: true
		},
		MuiPopover: {
			elevation: 0
		}
	}
};
