import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import GridList from '../../../material-design/components/layout/GridList.js';
import {immutablePropType} from '../../utils/CustomPropTypes.js';
import Collapsible, {COLLAPSIBLE_DEFAULT_TRANSITION_TIME} from '../Collapsible.js';

const SLOW_DEVICES_TRANSITION_LAG = 400;

export default class PreviewImagesGrid extends React.Component {
	constructor(props, context) {
		super(props, context);

		const {itemsToDisplay, totalTileWidth, itemsPerRow, viewportDimensions, scrollOffset} = this.props;
		this.state = {
			visibleItems: Immutable.Set(),
			itemsToRender: props.itemsToDisplay,
			gridListRef: React.createRef(),
			prevProps: {
				itemsToDisplay,
				totalTileWidth,
				itemsPerRow,
				viewportDimensions,
				scrollOffset
			}
		};

		this.timerId = null;
		this.boundRenderItem = this.renderItem.bind(this);
	}

	render() {
		const {items, totalTileWidth, itemsPerRow, itemsToDisplay, className} = this.props;
		const {gridListRef} = this.state;
		const children = items.map(this.boundRenderItem);
		const listWidth = `${itemsPerRow * totalTileWidth}px`;
		const listStyle = {
			width: listWidth,
			minWidth: listWidth
		};

		return (
			<GridList ref={gridListRef} style={listStyle} className={className}>
				<Collapsible isExpanded={itemsToDisplay > itemsPerRow} element={GridList}
				             minimalHeight={totalTileWidth}>
					{children}
				</Collapsible>
			</GridList>
		);
	}

	renderItem(item) {
		const {visibleItems} = this.state;
		const isVisible = visibleItems.has(item.get('id'));
		const {totalTileWidth, previewImageClass, itemTileComponent: ItemTileComponent} = this.props;
		return (
			<ItemTileComponent key={item.get('id')} item={item} isVisible={isVisible}
			                   tileWidth={totalTileWidth} tileHeight={totalTileWidth}
			                   previewImageClass={previewImageClass} />
		);
	}

	componentDidMount() {
		window.requestAnimationFrame(() => {
			this.setState(prevState => ({
				...calculateVisibleItemsState(
					this.props, prevState, prevState.gridListRef.current.getBoundingClientRect()
				)
			}));
		});
	}

	static getDerivedStateFromProps(nextProps, state) {
		let {itemsToRender} = state;
		let newItemsToRender = false;
		let newState = {};

		if (!state.gridListRef.current) {
			return null;
		}

		if (state.prevProps.itemsToDisplay !== nextProps.itemsToDisplay ||
			state.prevProps.totalTileWidth !== nextProps.totalTileWidth ||
			state.prevProps.itemsPerRow !== nextProps.itemsPerRow) {
			itemsToRender = Math.max(state.itemsToRender, nextProps.itemsToDisplay);
			newState = Object.assign(
				newState,
				{itemsToRender},
				{
					prevProps: {
						itemsToDisplay: nextProps.itemsToDisplay,
						totalTileWidth: nextProps.totalTileWidth,
						itemsPerRow: nextProps.itemsPerRow
					}
				});
			newItemsToRender = true;
		}

		if (state.visibleItems.size < itemsToRender) {
			if (newItemsToRender ||
				(nextProps.viewportDimensions !== state.prevProps.viewportDimensions) ||
				(nextProps.scrollOffset !== state.prevProps.scrollOffset)) {
				newState = Object.assign(
					newState,
					calculateVisibleItemsState(
						nextProps, state, state.gridListRef.current.getBoundingClientRect()
					),
					{
						prevProps: {
							viewportDimensions: nextProps.viewportDimensions,
							scrollOffset: nextProps.scrollOffset
						}
					}
				);
			}
		}
		return Object.keys(newState).length === 0 ? null : newState;
	}

	shouldComponentUpdate(nextProps, nextState) {
		const {items, itemsToDisplay, itemsPerRow, expandedDocuments, filterString} = this.props;
		const {visibleItems, itemsToRender} = this.state;
		return items !== nextProps.items ||
			itemsToDisplay !== nextProps.itemsToDisplay ||
			nextState.visibleItems !== visibleItems ||
			nextState.itemsToRender !== itemsToRender ||
			nextProps.itemsPerRow !== itemsPerRow ||
			nextProps.expandedDocuments !== expandedDocuments ||
			nextProps.filterString !== filterString;
	}

	componentDidUpdate(prevProps) {
		const {expandedDocuments, filterString} = this.props;
		if (!this.timerId &&  
			(prevProps.expandedDocuments !== expandedDocuments ||
			prevProps.filterString !== filterString)) {
			this.timerId = window.setTimeout(() => {
				this.setState(currentState => calculateVisibleItemsState(
					this.props, currentState, currentState.gridListRef.current.getBoundingClientRect()
				),
				() => {
					this.timerId = null;
				}
				);
			}, COLLAPSIBLE_DEFAULT_TRANSITION_TIME + SLOW_DEVICES_TRANSITION_LAG);
		}
	}

	componentWillUnmount() {
		if (this.timerId !== null) {
			window.clearTimeout(this.timerId);
		}
	}
}

PreviewImagesGrid.propTypes = {
	expandedDocuments: immutablePropType,
	previewImageClass: PropTypes.string,
	itemTileComponent: PropTypes.elementType,
	items: PropTypes.object,
	itemsPerRow: PropTypes.number,
	itemsToDisplay: PropTypes.number,
	totalTileWidth: PropTypes.number,
	className: PropTypes.string,
	viewportDimensions: PropTypes.shape({
		height: PropTypes.number,
		width: PropTypes.number
	}),
	scrollOffset: PropTypes.number, 
	filterString: PropTypes.string
};

PreviewImagesGrid.defaultProps = {
	itemsToDisplay: null
};

function calculateVisibleItemsState(props, state, boundingRect) {
	const {totalTileWidth: totalTileLength} = props;
	const {items} = props;
	const {itemsPerRow} = props;
	const itemsToDisplay = props.itemsToDisplay || props.itemsPerRow;
	const maxNumberRows = Math.ceil(itemsToDisplay / itemsPerRow) || 0;
	const {visibleItems} = state;
	const maxHeight = maxNumberRows * totalTileLength;
	let updatedVisibleItems = visibleItems;

	if (visibleItems.size < items.size) {
		const visibleTiles = getVisibleTileCoordinates(boundingRect, maxHeight, totalTileLength, totalTileLength);
		if (visibleTiles) {
			updatedVisibleItems = getUpdatedVisibleItems(
				visibleItems, visibleTiles, maxNumberRows, itemsPerRow, itemsToDisplay, items
			);
		}
	}

	let newState = {};
	if (updatedVisibleItems !== visibleItems) {
		newState = {visibleItems: updatedVisibleItems};
	}
	return newState;
}

function getUpdatedVisibleItems(visibleItems, visibleTiles, maxNumberRows, itemsPerRow, itemsToDisplay, items) {
	let resultingVisibleItems = visibleItems;
	visibleTiles.bottom = Math.min(visibleTiles.bottom, maxNumberRows - 1);
	for (let rowIndex = visibleTiles.top; rowIndex <= visibleTiles.bottom; ++rowIndex) {
		for (let columnIndex = visibleTiles.left; columnIndex <= visibleTiles.right; ++columnIndex) {
			const itemIndex = itemsPerRow * rowIndex + columnIndex;
			if (itemIndex < itemsToDisplay && itemIndex < items.size) {
				const item = items.get(itemIndex);
				const id = item.get('id');
				if (!resultingVisibleItems.has(id)) {
					resultingVisibleItems = resultingVisibleItems.add(id);
				}
			}
		}
	}
	return resultingVisibleItems;
}

function getVisibleTileCoordinates(boundingRect, maxHeight, tileWidthPlusSpacing, rowHeight) {
	const windowWidth = window.innerWidth;
	const windowHeight = window.innerHeight;

	let visibleTileCoordinates = null;

	if (boundingRect.top < windowHeight &&
		boundingRect.bottom > 0 &&
		boundingRect.left < windowWidth &&
		boundingRect.right > 0) {
		const top = Math.max(boundingRect.top, 0) - boundingRect.top;
		const bottom = Math.min(boundingRect.top + maxHeight, windowHeight) - boundingRect.top;
		const left = Math.max(boundingRect.left, 0) - boundingRect.left;
		const right = Math.min(boundingRect.right, windowWidth) - boundingRect.left;

		visibleTileCoordinates = {
			top: Math.floor(top / rowHeight),
			left: Math.floor(left / tileWidthPlusSpacing),
			bottom: Math.ceil(bottom / rowHeight) - 1,
			right: Math.ceil(right / tileWidthPlusSpacing) - 1 //no right overlap but bottom overlap
		};
	}

	return visibleTileCoordinates;
}
