import {useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {selectDicomDump, selectImageDataViewerItem} from '../../flux/selectors/ViewerItemsSelectors.js';
import {buildDicomDumpTaskId, buildFrameTaskId} from '../../utils/DicomSeriesViewerUtils.js';
import {scheduleFrameDownload, scheduleImageDownload} from '../../utils/SchedulingUtils.js';
import ViewerContext from '../ViewerContext.js';
import {useDicomViewerState} from './DicomSeriesContextProvider.js';

function ensureImageData(instanceTaskId, viewerState) {
	const {currentImageId, currentFrameId} = viewerState;
	const frameTaskId = `${instanceTaskId}/frame`;
	const imageTaskId = `${instanceTaskId}/image`;
	return (dispatch, getState) => {
		const state = getState();
		const imageDataViewerItem = selectImageDataViewerItem(currentFrameId)(state);
		const dicomDump = selectDicomDump(currentImageId)(state);
		!dicomDump && scheduleImageDownload(imageTaskId, viewerState.seriesId, buildDicomDumpTaskId(viewerState));
		!imageDataViewerItem && scheduleFrameDownload(frameTaskId, viewerState.seriesId, buildFrameTaskId(viewerState));
	};
}

function DicomImageDataBooster() {
	const {viewerId} = useContext(ViewerContext);
	const viewerState = useDicomViewerState();
	const dispatch = useDispatch();
	useEffect(() => dispatch(ensureImageData(viewerId, viewerState)), [dispatch, viewerId, viewerState]);
	return false;
}

export default DicomImageDataBooster;
