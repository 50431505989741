import React from 'react';
import PropTypes from 'prop-types';

import {useEffectEasily, useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {debugError} from '../../commons/utils/DebugLog.js';
import BrickBase from '../BrickBase.js';
import useBrickRegistry from '../hooks/useBrickRegistry.js';

/**
 * Takes the brick passed by the property brick and registers it with the registry.
 * It also calls the bricks shutdown method when the component gets unmounted.
 *
 * @author p.spitzlinger@synedra.com
 */
export default React.memo(BrickProvider);

function BrickProvider(props) {
	const {brick} = props;
	const brickRegistry = useBrickRegistry();
	const unregister = useMemoFactory(registerBrick, brickRegistry, brick);
	useEffectEasily(cleanup => cleanup, unregister);
	return null;
}
BrickProvider.propTypes = {
	brick: PropTypes.instanceOf(BrickBase)
};

function registerBrick(brickFactory, brick) {
	const unregisterBrick = brickFactory.registerBrick(brick);
	return () => {
		unregisterBrick();
		try {
			brick.shutdown();
		} catch (error) {
			debugError(`Error while shutting down brick: ${error}`);
		}
	};
}
