import React, {useContext} from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import NotchInset from '../../ui/components/layout/NotchInset.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import {BLACK_COLOR_NAME} from '../../ui/constants/ColorConstants.js';
import ViewerContext from './ViewerContext.js';

import '../../../styles/viewer/components/ViewerBox.scss';

export default function ViewerBox(props) {
	const {children, toolbar, className, ...remainingProps} = props;
	const {isFullScreen} = useContext(ViewerContext);
	const combinedClassName = combineClassNames('viewer-box', className);
	return (
		<VerticalLayout className={combinedClassName} {...remainingProps}>
			<div className='viewer-box--viewer'>
				<NotchInset enabled={isFullScreen} variant='left' bgColor={BLACK_COLOR_NAME} />
				{children}
				<NotchInset enabled={isFullScreen} variant='right' bgColor={BLACK_COLOR_NAME} />
			</div>
			{toolbar}
		</VerticalLayout>
	);
}

ViewerBox.propTypes = {
	toolbar: PropTypes.node,
	className: PropTypes.string
};
