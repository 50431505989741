import {StatusCodes} from 'http-status-codes';

/**
 * Executes a update request to a given url with a provided json body.
 *
 * @param {String} url the url to send the put request to
 * @param {Object} jsonBody the payload which will be transmitted in the body of the put request
 * @returns {Promise} a promise representing the success state of the update operation as boolean
 */
import {createJSONRequest, handleUnexpectedResponse} from '../../utils/AjaxUtils.js';

export default function update(url, jsonBody) {
	return fetch(url, createJSONRequest(jsonBody, 'PUT'))
		.then(handleUnexpectedResponse(StatusCodes.OK, `Update was not successful for ${url}`))
		.then(() => true);
}
