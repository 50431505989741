import Immutable from 'immutable';
import reducerReducers from 'reduce-reducers';
import {handleActions} from 'redux-actions';

import {booleanValue} from '../../../commons/utils/BooleanUtils.js';
import {
	BEGIN_SESSION,
	BEGIN_SESSION_TRANSITION,
	END_SESSION,
	FINISH_SESSION_TRANSITION,
	LOGIN,
	RESET_LOGIN_WORKFLOW
} from '../../constants/SessionActionTypes.js';
import {SESSION_STATE_LOGGED_OUT, SESSION_STATE_UNDEFINED} from '../../constants/SessionReducerStates.js';
import {
	SESSION_CURRENT_TRANSITION,
	SESSION_ERROR,
	SESSION_STATE,
	SESSION_USERCONTEXT,
	SESSION_USERNAME
} from '../../constants/SessionStateFields.js';

export const INITIAL_STATE = Immutable.Map({
	[SESSION_USERNAME]: null,
	[SESSION_CURRENT_TRANSITION]: null,
	[SESSION_STATE]: SESSION_STATE_UNDEFINED,
	[SESSION_ERROR]: null,
	[SESSION_USERCONTEXT]:	Immutable.Map()
});

export const LOGGED_OUT_STATE = INITIAL_STATE
	.set(SESSION_STATE, SESSION_STATE_LOGGED_OUT);

export default reducerReducers(
	handleActions({
		[LOGIN]: handleStartLogin,
		[BEGIN_SESSION_TRANSITION]: handleBeginOperation,
		[FINISH_SESSION_TRANSITION]: handleFinishOperation,
		[BEGIN_SESSION]: handleBeginSession,
		[END_SESSION]: handleEndSession,
		[RESET_LOGIN_WORKFLOW]: handleResetLoginWorkflow
	}, INITIAL_STATE)
);

function handleStartLogin(state, action) {
	const {username} = action.payload;
	return state
		.set(SESSION_USERNAME, username);
}

function handleBeginOperation(state, action) {
	return state.set(SESSION_CURRENT_TRANSITION, action.payload)
		.set(SESSION_ERROR, null);
}

function handleFinishOperation(state, action) {
	const {error, payload} = action;
	let nextSessionState = error ? SESSION_STATE_LOGGED_OUT : payload;
	if (!nextSessionState) {
		nextSessionState = state.get(SESSION_CURRENT_TRANSITION);
	}
	return state
		.set(SESSION_ERROR, error ? payload : null)
		.update(SESSION_CURRENT_TRANSITION, currentTransition => (error ? currentTransition : null))
		.set(SESSION_STATE, nextSessionState);
}

function handleBeginSession(state, action) {
	const {user} = action.payload;
	const {login} = user;
	return state
		.set(SESSION_USERNAME, login)
		.set(SESSION_USERCONTEXT, user ? Immutable.Map(user) : LOGGED_OUT_STATE.get(SESSION_USERCONTEXT));
}

function handleEndSession(state, action) {
	return LOGGED_OUT_STATE
		.set(SESSION_CURRENT_TRANSITION, state.get(SESSION_CURRENT_TRANSITION))
		.set(SESSION_STATE, state.get(SESSION_STATE))
		.set(SESSION_ERROR, action.error ? action.payload : null);
}

function handleResetLoginWorkflow() {
	return LOGGED_OUT_STATE;
}

export function prepareSessionProperties(sessionPropertiesFromServer) {
	const {canCreateOrders} = sessionPropertiesFromServer;
	return {
		...sessionPropertiesFromServer,
		canCreateOrders: booleanValue(canCreateOrders)
	};
}
