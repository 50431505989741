import React from 'react';

import ErrorHint from '../../commons/components/webview/ErrorHint.js';
import StatusMessage from '../../commons/components/webview/StatusMessage.js';
import {STATUS_FAILED} from '../../commons/constants/StatusMessageConstants.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import PasswordResetMessagesTranslator from '../i18n/PasswordResetMessagesTranslator.js';

export default React.memo(PasswordResetError);

function PasswordResetError() {
	return (
		<VerticalLayout key='centerLayout'>
			<StatusMessage status={STATUS_FAILED}>
				<SynFormattedMessage translator={PasswordResetMessagesTranslator} message='ErrorMessageShort' />
			</StatusMessage>
			<ErrorHint translator={PasswordResetMessagesTranslator} hintMessage='TechnicalIssueTryAgainLater'
			           solutionMessage='ReTryToRequestPasswordReset' solution={reloadLocation} />
			<ErrorHint translator={PasswordResetMessagesTranslator} hintMessage='RememberPasswordOrDifferentUserHint'
			           solutionMessage='RememberPasswordOrDifferentUserSolution' solution='' />
		</VerticalLayout>
	);
}

function reloadLocation() {
	window.location.reload();
}
