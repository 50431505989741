import React from 'react';
import {bool, number, object} from 'prop-types';

import {transformLine2d} from '../utils/math/Line.js';
import {createRectangle, getIntersection2d} from '../utils/math/Rectangle.js';

import '../../../styles/viewer/components/LocalizerOverlay.scss';

export default class LocalizerOverlay extends React.PureComponent {
	render() {
		const {transformationMatrix, localizer, containerWidth, containerHeight, isPrintPreview} = this.props;
		let line = false;
		if (localizer) {
			const localizerInContainerCoordinates = transformLine2d(localizer, transformationMatrix);
			const containerRectangle = createRectangle(
				new Float64Array([-containerWidth / 2, -containerHeight / 2]),
				new Float64Array([containerWidth / 2, containerHeight / 2])
			);
			const intersection = getIntersection2d(containerRectangle, localizerInContainerCoordinates);

			if (intersection) {
				const x1 = intersection.point1[0];
				const y1 = intersection.point1[1];
				const x2 = intersection.point2[0];
				const y2 = intersection.point2[1];
				const className = isPrintPreview ? 'localizer--line--contour' : undefined;

				line = (
					<g>
						<line className={className} x1={x1} y1={y1} x2={x2} y2={y2} />
					</g>);
			}
		}
		return line;
	}
}

LocalizerOverlay.propTypes = {
	transformationMatrix: object,
	containerWidth: number,
	containerHeight: number,
	isPrintPreview: bool,
	localizer: object
};
