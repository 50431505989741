import React from 'react';

/**
 * Renders an svg path string from the given list of points.
 * Each point should consist of two components accessible by index like: point[0].
 * The result will be a string according to: M points[0][0] pionts[0][1] L points[1][0] points[1][1] ...
 *
 * An empty string is returned if the list of points is empty.
 *
 * @param points {Array} - points to render the path string from
 * @param closed {boolean} - determines whether to close the created path or not (add a 'z' to the string)
 * @returns {string} - SVG path string.
 */
export function renderPathString(points, closed = false) {
	return points.length > 1 ? doRenderPathString(points, closed) : '';
}

function doRenderPathString(points, closed = false) {
	const pathDefinition = points
		.map(point => `${point[0]} ${point[1]}`)
		.join(' L ');
	const path = `M ${pathDefinition}`;
	return closed ? (`${path}z`) : path;
}

export function renderMultilineTextElements(text, lineHeight, additionalProps = {}, SubTextElement = 'tspan') {
	return text
		.split('\n')
		.map((line, index) => (
			<SubTextElement key={line} {...additionalProps} dy={index === 0 ? '0' : lineHeight}>
				{line ? line : ' '}
			</SubTextElement>
		));
}

export function convertPositionToTransformProperty(x, y) {
	let transformString;
	if (Boolean(x) || x === 0) {
		let translateCommand = `translate(${x}`;
		if (Boolean(y) || y === 0) {
			translateCommand = `${translateCommand},${y}`;
		}
		transformString = `${translateCommand})`;
	}
	return transformString;
}
