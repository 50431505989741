import React from 'react';
import PropTypes from 'prop-types';

import {renderPathString} from '../../utils/SVGUtils.js';

import '../../../../styles/viewer/components/annotations/AnnotationPathTouchArea.scss';

export default function AnnotationPathTouchArea(props) {
	const {points} = props;
	return <path className='annotation-path-touch-area-path' d={renderPathString(points)} />;
}

AnnotationPathTouchArea.propTypes = {
	points: PropTypes.arrayOf(PropTypes.instanceOf(Float32Array))
};
