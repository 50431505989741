import createStepperActions from '../../commons/actions/createStepperActions.js';
import {createAction} from '../../commons/utils/ActionUtils.js';
import {hasProperty} from '../../commons/utils/ObjectUtils';
import withTrackingMetadata from '../../metrics-collector/utils/withTrackingMetadata.js';
import {CREATE_PRIVATE_SHARE} from '../constants/CreatePrivateShareActionTypes.js';
import {NR_STEPS, STEPPER_ID} from '../constants/ShareDocumentConstants.js';
import {DATA_FIELD_USER_ID} from '../constants/ShareReceiverConstants.js';

const STEPPER_ACTIONS = createStepperActions(STEPPER_ID, NR_STEPS);

export const {nextStep} = STEPPER_ACTIONS;
export const {previousStep} = STEPPER_ACTIONS;
export const {updateData} = STEPPER_ACTIONS;
export const {deleteStepperState} = STEPPER_ACTIONS;

function createPrivateShareTrackingDataProvider(documentIds, message, receiver) {
	const suffix = hasProperty(receiver, DATA_FIELD_USER_ID) ? 'ExistingReceiver' : 'NewReceiver';
	const metricsKey = `CreateShare${suffix}`;
	return {usageContext: 'share', key: metricsKey};
}

export const createPrivateShare = createAction(
	CREATE_PRIVATE_SHARE,
	(documentIds, message, receiver) => ({documentIds, message, receiver}),
	withTrackingMetadata(createPrivateShareTrackingDataProvider)
);
