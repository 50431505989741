import ExtendableError from '../../commons/errors/ExtendableError.js';
import {markAsHandled} from '../../commons/utils/ErrorHandlingUtils.js';

export default class PasswordChangeError extends ExtendableError {
	constructor(originalError, code = originalError.code) {
		super(originalError, code);
		markAsHandled(this);
		this.originalError = originalError;
		this.code = code;
	}
}
