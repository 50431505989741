/**
 * According to [1] Apple decieded to ignore the property user-scalable on the meta-Tag viewport.
 * This allows all WebSites to be zoomed but on the other hand prevents SPAs to handle pinch themselves
 * and thus break these Apps.
 *
 * According to [2] on might (try to) prevent this default behavior by stopping the default of a
 * gesturestart event on the document element. Who knows how long this "hack" will work.
 *
 [1] https://webkit.org/blog/7367/new-interaction-behaviors-in-ios-10/
 [2] https://stackoverflow.com/questions/4389932/how-do-you-disable-viewport-zooming-on-mobile-safari/38852262#38852262
 [3] https://developer.mozilla.org/en-US/docs/Web/Events/gesturestart
 *
 */
import {preventEventDefault} from './DOMEventUtils.js';

export function tryToPreventPinchZoom() {
	document.addEventListener('gesturestart', preventEventDefault);
}
