import React from 'react';
import PropTypes from 'prop-types';

import {HORIZONTAL} from '../constants/GeneralConstants.js';

const HUNDRED_PERCENT = 100;

function createLinearGradient(orientation, color, percent) {
	const direction = orientation === HORIZONTAL ? 'right' : 'top';
	return `linear-gradient(to ${direction}, transparent ${percent}%, ${color} ${percent}%)`;
}

function normalizePercentage(percent) {
	return Math.max(0, Math.min(HUNDRED_PERCENT, Math.round(Number(percent) || 0)));
}

export function createBackgroundIndicator(WrappedComponent, defaultProps = {}) {
	class BackgroundIndicator extends React.Component {
		constructor(props, context) {
			super(props, context);
			this.state = {
				linearGradient: BackgroundIndicator.renderLinearGradient(props),
				prevPercent: props.percent,
				prevOrientation: props.orientation,
				prevColor: props.color
			};
		}

		render() {
			const {linearGradient} = this.state;
			const {style, ...remainingProps} = this.props;
			const finalStyles = linearGradient
				? {...style, backgroundImage: linearGradient}
				: style;
			return <WrappedComponent style={finalStyles} {...remainingProps} />;
		}

		static renderLinearGradient({color, percent, orientation}) {
			const normalizedPercent = normalizePercentage(percent);
			return normalizedPercent < HUNDRED_PERCENT
				? createLinearGradient(orientation, color, normalizedPercent)
				: null;
		}

		static getDerivedStateFromProps(nextProps, state) {
			const {percent, orientation, color} = nextProps;
			const {prevPercent, prevOrientation, prevColor} = state;
			if (percent !== prevPercent ||
				orientation !== prevOrientation ||
				color !== prevColor) {
				return {
					linearGradient: BackgroundIndicator.renderLinearGradient(nextProps),
					prevPercent: percent,
					prevOrientation: orientation,
					prevColor: color
				};
			}
			return null;
		}
	}
	BackgroundIndicator.propTypes = {
		...WrappedComponent.propTypes, percent: PropTypes.number,
		color: PropTypes.string,
		orientation: PropTypes.string
	};

	BackgroundIndicator.defaultProps = {
		...WrappedComponent.defaultProps, percent: 100,
		color: 'rgba(0,0,0,0.72)',
		orientation: HORIZONTAL, ...defaultProps
	};

	return BackgroundIndicator;
}

export default createBackgroundIndicator('div');
