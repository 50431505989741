import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import UploadFilesBrowserController from '../bricks/UploadFilesBrowserController.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';
import GridViewToggleButton from './GridViewToggleButton.js';

export default function UploadGridViewToggleButton() {
	const {numFiles} = useBrick(UploadFilesCollection, selectUploadFilesBrickProps);
	const {isGridMode, setGridMode, setTableMode} = useBrick(UploadFilesBrowserController, selectFileListSettings);
	return (
		<GridViewToggleButton gridMode={isGridMode} setGridMode={setGridMode} setTableMode={setTableMode}
		                      disabled={numFiles <= 0} />
	);
}

function selectUploadFilesBrickProps(service) {
	return {
		numFiles: service.getPreparedFilesCount()
	};
}

function selectFileListSettings(service) {
	return {
		isGridMode: service.isViewModeGrid(),
		setGridMode: service.setViewModeGrid,
		setTableMode: service.setViewModeTable
	};
}
