import React from 'react';

import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import Badge from './Badge.js';
import IconButton from './IconButton.js';

export default function IconButtonWithBadge(props) {
	const {badgeContent, badgeColor, max, showZero, icon, badgeBottom, ...remainingProps} = props;
	const buttonIcon = useMemoFactory(createIcon, icon, badgeContent, badgeColor, max, showZero, badgeBottom);
	return (
		<IconButton icon={buttonIcon} {...remainingProps} />
	);
}

function createIcon(icon, badgeContent, badgeColor, max, showZero, badgeBottom) {
	return (
		<Badge badgeContent={badgeContent} badgeColor={badgeColor} max={max} showZero={showZero}
		       badgeBottom={badgeBottom}>
			{icon}
		</Badge>
	);
}

IconButtonWithBadge.propTypes = {
	...IconButton.propTypes,
	...Badge.propTypes
};

IconButtonWithBadge.defaultProps = {
	...IconButton.defaultProps,
	...Badge.defaultProps
};
