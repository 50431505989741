import React from 'react';
import PropTypes from 'prop-types';

import {getEncapsulatedPdfDownloadUrl, getImageDownloadURL} from '../../api/DicomImageApi.js';
import DicomImage from '../../data/DicomImage.js';
import {isIntegratedPdfViewerExplicitlyDisabled} from '../../utils/PDFUtils.js';
import EmbeddedPdfView from '../EmbeddedPdfView.js';
import PdfView from '../pdf/PdfView.js';
import PdfLoader from '../PdfLoader.js';
import ViewerItemLoadErrorGuard from '../ViewerItemLoadErrorGuard.js';

export default class DicomPdfViewer extends React.Component {
	render() {
		const {dicomImage} = this.props;
		return (
			<ViewerItemLoadErrorGuard viewerItem={dicomImage} itemType='file'>
				{this.renderPdfViewer()}
			</ViewerItemLoadErrorGuard>
		);
	}

	renderPdfViewer() {
		const {dicomImage} = this.props;
		if (isIntegratedPdfViewerExplicitlyDisabled()) {
			return DicomPdfViewer.renderEmbeddedPdfViewer(dicomImage);
		}
		return this.renderIntegratedPdfViewer(dicomImage);
	}

	renderIntegratedPdfViewer(dicomImage) {
		const {rawImageData} = dicomImage;
		const {onTapLeft, onTapRight} = this.props;
		return (
			<PdfLoader pdfFile={rawImageData}>
				<PdfView onTapLeft={onTapLeft} onTapRight={onTapRight} />
			</PdfLoader>
		);
	}

	static renderEmbeddedPdfViewer(dicomImage) {
		const {imageId} = dicomImage;
		const inlineUrl = getEncapsulatedPdfDownloadUrl(imageId);
		const downloadUrl = getImageDownloadURL(imageId);
		return <EmbeddedPdfView inlineUrl={inlineUrl} downloadUrl={downloadUrl} />;
	}
}

DicomPdfViewer.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage),
	onTapLeft: PropTypes.func,
	onTapRight: PropTypes.func
};
