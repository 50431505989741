import DICOMParser from 'dicom-parser';

import {
	PARSER_DIRECTORY_RECORD_SEQUENCE,
	PARSER_REFERENCED_FILE_ID
} from './dicomParserTagIDs.js';

const DICOMDIR_FILENAME = 'DICOMDIR';

export async function loadReferencedFilesFromFile(fileObject) {
	const fileArray = await fileObject.arrayBuffer();
	const uint8Array = new Uint8Array(fileArray);
	return loadReferencedFilesFromByteArray(uint8Array);
}

export function fitsDicomDirNamingScheme(fileName) {
	const sanitizedFileName = (fileName || '')
		.toUpperCase()
		.trim();
	return sanitizedFileName === DICOMDIR_FILENAME;
}

async function loadReferencedFilesFromByteArray(byteArray) {
	const dataSet = await DICOMParser.parseDicom(byteArray);
	return extractReferencedFiles(dataSet);
}

function extractReferencedFiles(dataSet) {
	const referencedFiles = [];
	for (const directoryRecord of dataSet.elements[PARSER_DIRECTORY_RECORD_SEQUENCE].items) {
		const {dataSet: recordDataSet} = directoryRecord;
		const filePath = recordDataSet.text(PARSER_REFERENCED_FILE_ID);
		if (filePath) {
			referencedFiles.push(filePath);
		}
	}
	return referencedFiles;
}
