import React, {useCallback, useState} from 'react';

import {EMBEDDED_MODE, PATIENT_FILE_DISPLAY} from '../../commons/constants/SynSettingsConstants';
import PatientFileFilter from './PatientFileFilter';
import PatientFileFormatSelect from './PatientFileFormatSelect';

export default function PatientFileSelectOrFilter() {
	const showComboBox = PATIENT_FILE_DISPLAY.length > 1;

	const [searchActive, setSearchActive] = useState(false);
	const activateFilter = useCallback(() => setSearchActive(true), [setSearchActive]);
	const deactivateFilter = useCallback(() => setSearchActive(false), [setSearchActive]);

	if (EMBEDDED_MODE) {
		return null;
	}
	if (!showComboBox || searchActive) {
		return (<PatientFileFilter className='patient-details--search'
								   activateFilter={activateFilter} deactivateFilter={deactivateFilter}
								   autoFocus={showComboBox} alwaysShowClearIcon={showComboBox} />);
	}

	return <PatientFileFormatSelect patientFileOptions={PATIENT_FILE_DISPLAY} activateFilter={activateFilter} />;
}
