import {createSelector} from 'reselect';

import {DATA_FIELD_INDEX_KEY, DATA_FIELD_USER_ID} from '../../../share-document/constants/ShareReceiverConstants.js';
import SynCrudResourceApi from '../../api/SynCrudResourceApi.js';
import {webAppURL} from '../../WebViewHelpers.js';

function buildIndexKey(shareReceiver) {
	return shareReceiver.get(DATA_FIELD_USER_ID);
}

export function addIndexKeyToResults(shareReceivers) {
	return shareReceivers.map(shareReceiver => shareReceiver.set(DATA_FIELD_INDEX_KEY, buildIndexKey(shareReceiver)));
}

const SHARE_RECEIVER_CRUD_RESOURCE_DEFINITION = {
	api: new SynCrudResourceApi(webAppURL('sharereceiver')),
	actionsCreator: apiActions => ({
		...apiActions,
		loadFiltered: filterString => {
			const searchParams = {};
			if (filterString) {
				searchParams.params = {filter: filterString};
			}
			return apiActions.search(searchParams);
		}
	}),
	selectorsCreator: apiSelectors => ({
		...apiSelectors,
		selectFilterString: createSelector(
			apiSelectors.getRequestParams,
			params => Boolean(params) && params.get('filter', '') || ''
		),
		selectShareReceiverIndex: createSelector(
			apiSelectors.getResponse,
			results => Boolean(results) && results.reduce((index, shareReceiver) => {
				const indexKey = shareReceiver.get(DATA_FIELD_INDEX_KEY);
				index.set(indexKey, shareReceiver);
				return index;
			}, new Map()) || null
		)
	}),
	reducer: addIndexKeyToResults
};

export default SHARE_RECEIVER_CRUD_RESOURCE_DEFINITION;
