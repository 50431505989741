import {END} from 'redux-saga';
import {call, cancelled, put, select, take} from 'redux-saga/effects';

import {createPrivateShare} from '../api/DocumentShareApi.js';
import {CREATE_PRIVATE_SHARE} from '../constants/CreatePrivateShareActionTypes.js';
import {
	DATA_FIELD_IS_CREATING_SHARE, DATA_FIELD_SHARE_CREATED_SUCCESSFULLY,
	DATA_FIELD_SHARE_CREATION_ERROR
} from '../constants/ShareDocumentConstants.js';
import {
	DATA_FIELD_EMAIL, DATA_FIELD_FIRSTNAME, DATA_FIELD_LASTNAME,
	DATA_FIELD_MOBILENUMBER,
	DATA_FIELD_USER_ID
} from '../constants/ShareReceiverConstants.js';
import {updateData} from '../flux/ShareDocumentDialogActions.js';
import {selectData} from '../flux/ShareDocumentDialogSelectors.js';

function* performUpdateData(updater) {
	const data = yield select(selectData);
	yield put(updateData(updater(data)));
}

export default function* createPrivateShareSaga(createPrivateShareOperation = createPrivateShare) {
	let nextAction = null;
	while (!(yield cancelled()) && nextAction !== END) {
		nextAction = yield take(CREATE_PRIVATE_SHARE);
		if (nextAction !== END) {
			yield call(performUpdateData, data => data.set(DATA_FIELD_IS_CREATING_SHARE, true));

			const {receiver, documentIds, message} = nextAction.payload;
			const pacsUserId = receiver.get(DATA_FIELD_USER_ID);
			const firstName = receiver.get(DATA_FIELD_FIRSTNAME);
			const lastName = receiver.get(DATA_FIELD_LASTNAME);
			const email = receiver.get(DATA_FIELD_EMAIL);
			const mobileNumber = receiver.get(DATA_FIELD_MOBILENUMBER);

			try {
				yield call(
					createPrivateShareOperation, documentIds, message, pacsUserId, firstName, lastName, email,
					mobileNumber
				);
				yield call(performUpdateData, data => data
					.set(DATA_FIELD_IS_CREATING_SHARE, false)
					.set(DATA_FIELD_SHARE_CREATED_SUCCESSFULLY, true)
				);
			} catch (creationError) {
				yield call(performUpdateData, data => data
					.set(DATA_FIELD_IS_CREATING_SHARE, false)
					.set(DATA_FIELD_SHARE_CREATION_ERROR, creationError)
				);
			}
		}
	}
}
