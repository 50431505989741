import {connect} from 'react-redux';

import {uiSidebarModeElevatedSelector} from '../../ui/flux/UISelectors.js';
import SidebarHeader from '../components/SidebarHeader.js';

export default connect(
	state => ({
		sidebarElevated: uiSidebarModeElevatedSelector(state)
	})
)(SidebarHeader);
