import {debugWarn} from '../commons/utils/DebugLog.js';
import {createCountEntryKey, createEntries as metricApiCreate, createNumberMetricsEntry} from './api/MetricsApi.js';

const UPLOAD_TIMEOUT_INTERVAL = 5000;
const STORE = new Map();
let timerId = null;

async function sendMetricEntries() {
	try {
	  await metricApiCreate(takeEntries());
	} catch (error) {
		debugWarn(`Failed to push metrics: ${error}`);
	}
}

function scheduleUpload() {
	if (timerId) {
		window.clearTimeout(timerId);
	}
	timerId = window.setTimeout(sendMetricEntries, UPLOAD_TIMEOUT_INTERVAL);
}

function increaseCounter(entryKey) {
	const currentValue = STORE.has(entryKey) ? STORE.get(entryKey) : 0;
	STORE.set(entryKey, currentValue + 1);
}

function createOrUpdateEntry(entryKey) {
	increaseCounter(entryKey);
}

/**
 * Push a new entry into the store. An entry is expected to be an object of shape
 * {
 *     key: METRICS_KEY,
 *     context: USAGE_CONTEXT (e.g. 'toolusage', 'export', ...),
 *     type: METRICS_TYPE (currently only type 'count' is supported)
 * }
 * Entries with type 'count' will be treated as counters - existing values will be incremented.
 * @param metricEntry
 */
export function pushEntry(metricEntry) {
	const {context, key} = metricEntry;
	const entryKey = createCountEntryKey(context, key);
	createOrUpdateEntry(entryKey);
	scheduleUpload();
}

/**
 * Take all entries from the store. The store is empty afterwards!
 * @returns {Array} Array of entries in shape { key: KEY, value: VALUE }.
 */
export function takeEntries() {
	const entries = [];
	STORE.forEach((value, key) => {
		entries.push(createNumberMetricsEntry(key, value));
	});
	STORE.clear();
	return entries;
}

export function createMetricsEntry(key, context) {
	return {context, key};
}
