import BrickBase from '../../../webview/bricks/BrickBase.js';
import {createFileEntry} from '../../../webview/commons/utils/FilesCollection.js';
import {
	createFilterAllowedFileExtensions,
	createFilterMaxFileSize
} from '../../../webview/commons/utils/filesCollectionFilters.js';
import {chain} from '../../../webview/commons/utils/FunctionUtils.js';
import {IEC_FACTOR} from '../../../webview/commons/utils/NumberUtils.js';
import {shallowEqual} from '../../../webview/commons/utils/ObjectUtils';
import dicomDirFilter from './dicomDirFilter.js';
import dicomFilter from './dicomFilter.js';

const EMPTY_STATISTICS = {
	processedFiles: 0,
	addedFiles: 0,
	ignoredFiles: 0
};
Object.freeze(EMPTY_STATISTICS);

export default class UploadFilesCollectorImpl extends BrickBase {
	constructor(allowedFileExtensions, allowedFileSizeMB, addFileList, setContainerData) {
		super();
		this.setContainerData = setContainerData;
		this.addFileList = addFileList;
		this.preprocessor = createPreprocessor(allowedFileExtensions, allowedFileSizeMB);

		this.updateBrickState(() => ({
			collectionProcesses: 0,
			collectionSummary: EMPTY_STATISTICS
		}));
	}

	collectFromFileList(fileList) {
		this.updateBrickState(oldState => {
			const {collectionProcesses, collectionSummary} = oldState;
			this.preprocessor(fileList)
				.then(processedFiles => this.handleProcessedFiles(fileList, processedFiles));
			const actualSummary = collectionProcesses === 0 ? EMPTY_STATISTICS : collectionSummary;
			return {
				...oldState,
				collectionSummary: updateObject(actualSummary,
					oldSummary => updateProcessedFilesCount(oldSummary, fileList.length)
				),
				collectionProcesses: collectionProcesses + 1
			};
		});
	}

	handleProcessedFiles(originalFilesList, processedFiles) {
		let processedFilesCount = 0;
		for (const entry of processedFiles) {
			const [containerName, {containerData, fileList}] = entry;
			if (containerData) {
				this.setContainerData(containerName, containerData);
			}
			this.addFileList(fileList, containerName);
			processedFilesCount += fileList.length;
		}
		this.updateBrickState(oldState => {
			const {collectionProcesses, collectionSummary} = oldState;
			return {
				...oldState,
				collectionSummary: updateObject(collectionSummary,
					oldSummary => updateAddedFilesCount(oldSummary, originalFilesList.length, processedFilesCount)
				),
				collectionProcesses: collectionProcesses - 1
			};
		});
	}

	isCollecting() {
		return this.getBrickState().collectionProcesses > 0;
	}

	getCollectionSummary() {
		return this.getBrickState().collectionSummary;
	}
}

function updateProcessedFilesCount(oldSummary, newFiles) {
	let {processedFiles = 0} = oldSummary;
	processedFiles += newFiles;
	return {
		...oldSummary,
		processedFiles
	};
}

function updateAddedFilesCount(oldSummary, originalFilesCount, addedFilesCount) {
	let {addedFiles = 0, ignoredFiles = 0} = oldSummary;
	ignoredFiles += originalFilesCount - addedFilesCount;
	addedFiles += addedFilesCount;
	return {
		...oldSummary,
		ignoredFiles,
		addedFiles
	};
}

function updateObject(data, updater) {
	const newData = Object.freeze(updater(data));
	const dataChanged = !shallowEqual(newData, data);
	return dataChanged ? newData : data;
}

function createPreprocessor(allowedFileExtensions, allowedFileSizeMB) {
	const filterAllowedExtensions = createFilterAllowedFileExtensions([...allowedFileExtensions, '']);
	const filters = [filterAllowedExtensions];
	if (typeof allowedFileSizeMB === 'number') {
		filters.push(createFilterMaxFileSize(allowedFileSizeMB * IEC_FACTOR * IEC_FACTOR));
	}
	const chainedFilters = chain(...filters, convertToFileEntries);
	return function fileListPreprocessor(fileList) {
		return dicomDirFilter(chainedFilters(fileList))
			.then(dicomFilter);
	};
}

function convertToFileEntries(fileList) {
	return fileList.map(createFileEntry);
}
