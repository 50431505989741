import React from 'react';
import PropTypes from 'prop-types';

import LoadingIndicatorOverlay from '../../commons/components/webview/LoadingIndicatorOverlay.js';
import SubtextLinkContainer from '../../commons/containers/SubtextLinkContainer.js';
import {callSafe, noop} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import {STATE_2ND_FACTOR, STATE_ERROR, STATE_NEW_PASSWORD, STATE_SUCCESS} from '../constants/NewPasswordConstants.js';
import {
	PASSWORD_CHANGE_ERROR_2ND_FACTOR_REQUIRED,
	PASSWORD_CHANGE_ERROR_INVALID_PASSWORD,
	PASSWORD_CHANGE_ERROR_OTHER,
	PASSWORD_CHANGE_ERROR_WEAK_PASSWORD,
	PASSWORD_TOO_WEAK
} from '../constants/PasswordStateReducerStates.js';
import PasswordMessagesTranslator from '../i18n/PasswordMessagesTranslator.js';
import NewPasswordFormAccessors, {NEW_PASSWORD_FORM_SCHEMA} from './accessors/NewPasswordFormAccessors.js';
import NewPasswordError from './NewPasswordError.js';
import NewPasswordSuccess from './NewPasswordSuccess.js';
import StepNewPassword from './StepNewPassword.js';
import StepSecondFactorVerification from './StepSecondFactorVerification.js';

export default class NewPasswordController extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.boundOnNewPasswordSubmit = this.onNewPasswordSubmit.bind(this);
		this.boundRestartWorkflow = this.restartWorkflow.bind(this);
		this.boundOnVerificationCodeSubmit = noop;
	}

	render() {
		const {passwordOperationState, isBusy, isSessionBusy, translator, hideCancelLink, children} = this.props;
		let content;
		let renderCancelLink = false;
		switch (passwordOperationState) {
			case STATE_NEW_PASSWORD:
				content = this.renderNewPasswordForm();
				renderCancelLink = true;
				break;
			case STATE_2ND_FACTOR:
				content = this.renderVerificationCodeForm();
				renderCancelLink = true;
				break;
			case STATE_ERROR:
				content = this.renderError();
				break;
			case STATE_SUCCESS:
				content = this.renderSuccess();
				break;
			default:
				content = null;
		}
		return (
			<React.Fragment>
				{content}
				{(!hideCancelLink && renderCancelLink) && (
					<SubtextLinkContainer path='' key='accountAlreadyActivatedLink'>
						<SynFormattedMessage message='CancelNewPasswordWorkflowPhrase' translator={translator} />
					</SubtextLinkContainer>
				)}
				{children}
				<LoadingIndicatorOverlay loading={isBusy || isSessionBusy} />
			</React.Fragment>
		);
	}

	renderVerificationCodeForm() {
		return <StepSecondFactorVerification onSubmit={this.boundOnVerificationCodeSubmit} {...this.props} />;
	}

	renderError() {
		const {translator, bailOutUrl, passwordError, secondFactorAssumed} = this.props;
		return (
			<NewPasswordError error={passwordError} bailOutUrl={bailOutUrl} translator={translator}
			                  restartWorkflow={this.boundRestartWorkflow} secondFactorAssumed={secondFactorAssumed} />
		);
	}

	renderSuccess() {
		const {translator, onFinish} = this.props;
		return <NewPasswordSuccess translator={translator} onFinish={onFinish} />;
	}

	renderNewPasswordForm() {
		return <StepNewPassword onSubmit={this.boundOnNewPasswordSubmit} {...this.props} />;
	}

	onNewPasswordSubmit(formData) {
		const {setNewPassword} = this.props;
		this.boundOnVerificationCodeSubmit = noop;

		if (setNewPassword) {
			const passwordFormData = formData;
			this.boundOnVerificationCodeSubmit = verificationCodeFormData => {
				this.boundOnVerificationCodeSubmit = noop;
				this.setNewPassword({...passwordFormData, ...verificationCodeFormData});
			};
			this.setNewPassword(passwordFormData);
		}
	}

	setNewPassword(combinedFormData) {
		const {setNewPassword} = this.props;
		callSafe(setNewPassword, combinedFormData);
	}

	restartWorkflow() {
		const {resetPasswordState} = this.props;
		callSafe(resetPasswordState);
	}

	componentDidMount() {
		const {resetPasswordState, logout} = this.props;
		callSafe(logout);
		callSafe(resetPasswordState);
	}
}

NewPasswordController.propTypes = {
	passwordOperationState: PropTypes.oneOf([STATE_NEW_PASSWORD, STATE_SUCCESS, STATE_ERROR, STATE_2ND_FACTOR]),
	translator: PropTypes.object,
	passwordFormAccessors: PropTypes.object,
	passwordFormFields: PropTypes.arrayOf(PropTypes.string),
	hideCancelLink: PropTypes.bool,
	onFinish: PropTypes.func,
	secondFactorAssumed: PropTypes.bool,
	isBusy: PropTypes.bool,
	isSessionBusy: PropTypes.bool,
	bailOutUrl: PropTypes.string,
	passwordError: PropTypes.oneOf([
		PASSWORD_CHANGE_ERROR_INVALID_PASSWORD, PASSWORD_CHANGE_ERROR_OTHER, PASSWORD_CHANGE_ERROR_WEAK_PASSWORD,
		PASSWORD_TOO_WEAK, PASSWORD_CHANGE_ERROR_2ND_FACTOR_REQUIRED
	]),
	setNewPassword: PropTypes.func,
	resetPasswordState: PropTypes.func,
	logout: PropTypes.func
};

NewPasswordController.defaultProps = {
	translator: PasswordMessagesTranslator,
	passwordFormAccessors: NewPasswordFormAccessors,
	passwordFormFields: Object.keys(NEW_PASSWORD_FORM_SCHEMA.fields),
	onFinish: noop,
	hideCancelLink: false,
	secondFactorAssumed: true
};
