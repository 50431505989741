import React, {useContext} from 'react';
import {number} from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import PdfViewerMessagesTranslator from '../../i18n/translators/PdfViewerMessagesTranslator.js';
import EmbeddedPdfViewerContainer from '../flux/containers/EmbeddedPdfViewerContainer.js';
import IntegratedPdfViewerContainer from '../flux/containers/IntegratedPdfViewerContainer.js';
import {isIntegratedPdfViewerExplicitlyDisabled} from '../utils/PDFUtils.js';
import ViewerContext from './ViewerContext.js';

import '../../../styles/viewer/components/PdfViewer.scss';

export default function PdfViewer(props) {
	const {fileId} = props;
	const {isPrintPreview} = useContext(ViewerContext);
	return isPrintPreview ? renderPrintMessage() : renderPdfViewer(fileId);
}

PdfViewer.propTypes = {
	fileId: number.isRequired
};

function renderPdfViewer(fileId) {
	const ViewerComponent = isIntegratedPdfViewerExplicitlyDisabled()
		? EmbeddedPdfViewerContainer
		: IntegratedPdfViewerContainer;
	return <ViewerComponent fileId={fileId} />;
}

function renderPrintMessage() {
	return (
		<CenterLayout className='print-message'>
			<SynFormattedMessage translator={PdfViewerMessagesTranslator} message='PrintingNotSupported' />
		</CenterLayout>
	);
}
