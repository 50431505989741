import React from 'react';
import PropTypes from 'prop-types';

import {getHocDisplayName, withForwardRef} from '../../commons/utils/ReactUtils.js';
import MessageTranslator from '../translators/MessageTranslator.js';

export const TranslationContext = React.createContext(null);

export function TranslationProvider(props) {
	const {translator, children} = props;
	return (
		<TranslationContext.Provider value={translator}>
			{children}
		</TranslationContext.Provider>
	);
}
TranslationProvider.propTypes = {
	translator: PropTypes.instanceOf(MessageTranslator)
};

export const TranslationConsumer = TranslationContext.Consumer;

export function wrapInTranslationContext(Component) {
	function withTranslationContext(props) {
		const {translator, forwardRef, ...remainingProps} = props;
		return (
			<TranslationConsumer>
				{contextTranslator => (
					<Component ref={forwardRef} translator={translator || contextTranslator} {...remainingProps} />
				)}
			</TranslationConsumer>
		);
	}
	withTranslationContext.displayName = getHocDisplayName(Component, 'withTranslationContext');

	const componentsPropTypes = Component.propTypes || {};
	withTranslationContext.propTypes = {
		...componentsPropTypes,
		forwardRef: withForwardRef.PropTypes.Ref,
		translator: PropTypes.instanceOf(MessageTranslator)
	};
	withTranslationContext.defaulProps = Component.defaultProps || {};

	return withForwardRef(withTranslationContext, 'forwardRef');
}
