import React from 'react';

import {getFileDownloadURL, getFileDownloadWithDocumentLoadURL} from '../api/GenericFileApi.js';
import createViewerItemViewer from './createViewerItemViewer.js';
import EmbeddedPdfView from './EmbeddedPdfView.js';

export default createViewerItemViewer(renderView, 'pdf-viewer');

function renderView({fileId}) {
	const downloadUrl = getFileDownloadWithDocumentLoadURL(fileId, 'inline');
	return <EmbeddedPdfView inlineUrl={downloadUrl} downloadUrl={getFileDownloadURL(fileId)} />;
}
