import React from 'react';
import {useSelector} from 'react-redux';
import {fromJS} from 'immutable';
import PropTypes from 'prop-types';

import {selectFileMetadata} from '../../patient-details/flux/selectors/PatientDetailsSelectors.js';
import GenericImageViewerContainer from '../flux/containers/GenericImageViewerContainer.js';
import {ViewerItemDownloadOptionsContainer} from '../flux/containers/ViewerItemDownloadOptionsContainer.js';
import XMLViewerContainer from '../flux/containers/XMLViewerContainer.js';
import {isDisplaySupported} from '../utils/SupportedDocumentFormats.js';
import {getFileExtension} from '../utils/ViewerItemUtils.js';
import GenericViewerToolbar from './GenericViewerToolbar.js';
import PdfViewer from './PdfViewer.js';
import UnsupportedItemViewer from './UnsupportedItemViewer.js';
import ViewerBox from './ViewerBox.js';

const TYPE_SPECIFIC_VIEWERS = fromJS({
	pdf: PdfViewer,
	xml: XMLViewerContainer,
	cda: XMLViewerContainer
});

export default function GenericFileViewer({itemId: fileId}) {
	const fileMetadata = useSelector(selectFileMetadata(fileId));
	const ViewerComponent = determineViewerComponent(fileMetadata);
	return <ViewerComponent fileId={fileId} fileMetadata={fileMetadata} />;
}

GenericFileViewer.propTypes = {
	itemId: PropTypes.number.isRequired
};

function UnsupportedFileViewer({fileId, fileMetadata}) {
	const messageParams = {extension: getFileExtension(fileMetadata).toUpperCase()};
	const toolBar = <GenericViewerToolbar fileId={fileId} showTools={false} />;
	return (
		<ViewerBox toolbar={toolBar}>
			<UnsupportedItemViewer className='unsupported-image-viewer' message='FileTypeNotSupported' messageParams={messageParams}>
				<ViewerItemDownloadOptionsContainer />
			</UnsupportedItemViewer>
		</ViewerBox>
	);
}

UnsupportedFileViewer.propTypes = {
	fileId: PropTypes.number,
	fileMetadata: PropTypes.object
};

function determineViewerComponent(fileMetadata) {
	const fileExtension = getFileExtension(fileMetadata);
	const defaultViewer = GenericImageViewerContainer;
	return isDisplaySupported(fileExtension)
		? TYPE_SPECIFIC_VIEWERS.get(fileExtension.toLowerCase(), defaultViewer)
		: UnsupportedFileViewer;
}
