import React from 'react';
import PropTypes from 'prop-types';

import ContentRow from '../../../material-design/components/layout/ContentRow.js';
import {combineClassNames} from '../../utils/StyleUtils.js';

import '../../../../styles/commons/components/webview/AppBarSidebarExtensionArea.scss';

export default function AppBarSidebarExtensionArea(props) {
	const {className} = props;
	const classNames = combineClassNames('app-bar-sidebar-extension-area', className);
	const otherProps = {...props};
	delete otherProps.className;
	return <ContentRow className={classNames} {...otherProps} />;
}

AppBarSidebarExtensionArea.propTypes = {
	className: PropTypes.string
};
