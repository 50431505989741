import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';

import {noop} from '../../../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../../../commons/utils/ObjectUtils';
import ViewerContext from '../../components/ViewerContext.js';
import WindowParametersOverlay from '../../components/WindowParametersOverlay.js';
import {createSelectWindowProperties} from '../selectors/ImageViewerSelectors.js';

export default function GenericImageWindowParametersOverlayContainer(props) {
	const {viewerId} = useContext(ViewerContext);
	const windowPropsSelector = useMemo(() => createSelectWindowProperties(viewerId), [viewerId]);
	const windowProps = useSelector(windowPropsSelector, shallowEqual);

	return <WindowParametersOverlay showPresetsMenu={false} setWindow={noop} {...windowProps} {...props} />;
}

