import Immutable from 'immutable';

import {DICOM_MODALITY_TO_PRESETS, tr} from '../../constants/DicomWindowPresetContstants.js';
import {getInitialWindow, getMinMaxWindow} from './SynAdvancedImage.js';

export function isDicomSeries(item) {
	return getModality(item) !== null;
}

export function getModality(series) {
	return Boolean(series) && series.get !== undefined && series.get('modality_fk') || null;
}

function getWindowPresetsForModality(modality) {
	return Boolean(modality) && DICOM_MODALITY_TO_PRESETS.get(modality.toUpperCase()) || Immutable.List();
}

function combinePresets(modalityPresets, frameSpecificPresets) {
	return modalityPresets.isEmpty() ? modalityPresets : modalityPresets.concat(frameSpecificPresets);
}

export function getAllWindowPresets(modality, frameImage) {
	return combinePresets(
		getWindowPresetsForModality(modality),
		getFrameSpecificPresets(frameImage)
	);
}

function createMinMaxPreset(frameImage) {
	return Immutable.fromJS({
		label: tr('WindowPresetMinMax'),
		toolIdentifier: 'WindowPresetMinMax',
		...getMinMaxWindow(frameImage)
	});
}

function createInitialWindowPreset(frameImage) {
	return Immutable.fromJS({
		label: tr('WindowPresetInitial'),
		toolIdentifier: 'WindowPresetInitial',
		...getInitialWindow(frameImage).toJS()
	});
}

function getFrameSpecificPresets(frameImage) {
	const frameSpecificPresets = [];
	if (frameImage) {
		frameSpecificPresets.push(createMinMaxPreset(frameImage));
		frameSpecificPresets.push(createInitialWindowPreset(frameImage));
	}
	return Immutable.fromJS(frameSpecificPresets);
}
