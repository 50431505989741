import React from 'react';
import PropTypes from 'prop-types';

import {refPropType} from '../../../commons/utils/CustomPropTypes.js';
import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import {combineClassNames} from '../../../commons/utils/StyleUtils.js';

import '../../../../styles/viewer/components/annotations/AnnotationIcon.scss';

function AnnotationIcon(props) {
	const {
		forwardRef, className, transform,
		onClick,
		onTouchStart, onTouchEnd,
		onPointerDown, onPointerUp,
		onMouseDown, onMouseUp,
		onKeyDown, onKeyUp,
		children
	} = props;
	const finalClassName = combineClassNames('annotation-icon--icon', className);
	return (
		<g ref={forwardRef} className={finalClassName} transform={transform}
				 onClick={onClick}
				 onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}
				 onPointerUp={onPointerUp} onPointerDown={onPointerDown}
				 onMouseUp={onMouseUp} onMouseDown={onMouseDown}
				 onKeyDown={onKeyDown} onKeyUp={onKeyUp}>
			{children}
		</g>
	);
}
AnnotationIcon.propTypes = {
	className: PropTypes.string,
	transform: PropTypes.string,
	forwardRef: refPropType,
	onTouchStart: PropTypes.func,
	onTouchEnd: PropTypes.func,
	onClick: PropTypes.func,
	onPointerDown: PropTypes.func,
	onPointerUp: PropTypes.func,
	onMouseDown: PropTypes.func,
	onMouseUp: PropTypes.func,
	onKeyUp: PropTypes.func,
	onKeyDown: PropTypes.func
};

export default withForwardRef(AnnotationIcon, 'forwardRef');
