import React from 'react';
import PropTypes from 'prop-types';

import A11yTranslator from '../../a11y/i18n/A11yTranslator.js';
import TreeView from '../../a11y/treeView/TreeView.js';
import {immutableListPropType} from '../../commons/utils/CustomPropTypes.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import PatientDetailsNodeRenderer from './PatientDetailsNodeRenderer.js';

import '../../../styles/patient-details/components/PatientDetailsDocumentList.scss';

class PatientDetailsDocumentList extends React.PureComponent {
	render() {
		const {structure, locale} = this.props;
		const ariaLabel = A11yTranslator.getFormattedMessage('A11YDocumentsView', locale);
		return (
			<TreeView ariaLabel={ariaLabel} className='patient-details-document-list--container patient-details-document-list--container--root-node'>
				<PatientDetailsNodeRenderer categoryDepth={0} node={structure} {...this.props} />
			</TreeView>
		);
	}
}

PatientDetailsDocumentList.propTypes = {
	structure: immutableListPropType,
	locale: PropTypes.string
};

export default wrapInLocalizationContext(PatientDetailsDocumentList);
