import React from 'react';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import DotTouchArea from './DotTouchArea.js';
import MarkerDot from './MarkerDot.js';
import ModifiablePath from './ModifiablePath.js';

export default class ModifiablePoint extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.wrapperTouchAreaComponent = createWrapperTouchAreaComponent(this);
	}

	render() {
		const {point, onPointUpdate} = this.props;
		const props = Object.assign(
			cloneWithoutProperties(this.props, 'point', 'onPointUpdate'),
			{
				points: [point],
				onPointsUpdate: (points, ...args) => {
					onPointUpdate(points[0], ...args);
				},
				touchArea: this.wrapperTouchAreaComponent
			}
		);
		return (
			<ModifiablePath {...props} />
		);
	}
}
ModifiablePoint.propTypes = {
	point: PropTypes.oneOfType([
		PropTypes.instanceOf(Float32Array),
		PropTypes.instanceOf(Float64Array)
	]),
	marker: ModifiablePath.propTypes.marker,
	touchArea: ModifiablePath.propTypes.touchArea,
	...cloneWithoutProperties(ModifiablePath.propTypes, 'points')
};

ModifiablePoint.defaultProps = {
	marker: function MarkerDotWithoutContour(props) {
		return (<MarkerDot {...props} drawContour={false} />);
	},
	touchArea: DotTouchArea
};

function createWrapperTouchAreaComponent(pointInstance) {
	function WrapperTouchAreaComponent(props) {
		const {touchArea: TouchAreaComponent} = pointInstance.props;
		const {points: [firstPoint]} = props;
		return (<TouchAreaComponent {...props} hidden={false} point={firstPoint} />);
	}
	WrapperTouchAreaComponent.propTypes = {
		points: PropTypes.arrayOf(PropTypes.instanceOf(Float32Array))
	};
	return WrapperTouchAreaComponent;
}
