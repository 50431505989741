import Immutable from 'immutable';

import {IS_DEBUG_BUILD} from '../commons/constants/EnvironmentConstants.js';
import {debugWarn} from '../commons/utils/DebugLog.js';

class DomEventsManager {
	constructor() {
		this.registeredEventListeners = Immutable.Map();
	}

	addEventListener(element, event, callback, eventPropertiesOrCapturePhase = false) {
		this.registeredEventListeners = this.registeredEventListeners.updateIn(
			[element, event, eventPropertiesOrCapturePhase],
			Immutable.Set(),
			callbacks => {
				let newCallbacks = callbacks;
				if (callbacks.has(callback)) {
					debugWarn(`Same callback for event ${event} on element ${element} in ${eventPropertiesOrCapturePhase ? 'capture' : 'bubble'} phase already registered.`);
				} else {
					element.addEventListener(event, callback, eventPropertiesOrCapturePhase);
					newCallbacks = callbacks.add(callback);
				}
				return newCallbacks;
			});
	}

	removeEventListener(element, event, callback, capturePhase = false) {
		const mapKey = [element, event, capturePhase];
		let callbacks = this.registeredEventListeners.getIn(mapKey, null);
		if (callbacks !== null && this.registeredEventListeners.hasIn(mapKey)) {
			element.removeEventListener(event, callback, capturePhase);
			callbacks = callbacks.delete(callback);
			if (callbacks.isEmpty()) {
				this.registeredEventListeners = this.registeredEventListeners.deleteIn(mapKey);
				this.cleanupListenerContainers([element, event]);
			} else {
				this.registeredEventListeners = this.registeredEventListeners.setIn(mapKey, callbacks);
			}
		} else if (IS_DEBUG_BUILD) {
			debugWarn(`Callback for event ${event} on element ${element} in ${capturePhase ? 'capture' : 'bubble'} phase was never registered.`);
		}
	}

	removeAllListeners() {
		this.registeredEventListeners.forEach((events, element) => {
			events.forEach((phases, event) => {
				phases.forEach((callbacks, phase) => {
					callbacks.forEach(callback => {
						this.removeEventListener(element, event, callback, phase);
					});
				});
			});
		});
	}

	removeAllListenersFrom(element) {
		const events = this.registeredEventListeners.get(element, null);
		if (events !== null) {
			events.forEach((phases, event) => {
				phases.forEach((callbacks, phase) => {
					callbacks.forEach(callback => {
						this.removeEventListener(element, event, callback, phase);
					});
				});
			});
			this.cleanupListenerContainers([element]);
		}
	}

	cleanupListenerContainers(mapPath) {
		const possiblyEmptyContainer = this.registeredEventListeners.getIn(mapPath, null);
		if (possiblyEmptyContainer !== null && possiblyEmptyContainer.isEmpty()) {
			this.registeredEventListeners = this.registeredEventListeners.deleteIn(mapPath);
			if (mapPath.length > 1) {
				this.cleanupListenerContainers(mapPath.slice(0, -1));
			}
		}
	}
}

export default DomEventsManager;
