import {vec2} from 'gl-matrix';
import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {
	ADD_ANNOTATION,
	REMOVE_ANNOTATION,
	REMOVE_ANNOTATIONS_OF_TYPE,
	REMOVE_READONLY_ANNOTATIONS,
	SET_ACTIVE_ANNOTATION,
	UPDATE_ANNOTATION_PROPERTIES
} from '../../constants/AnnotationActionTypes.js';
import {
	ACTIVE_ANNOTATION_ID_STATE_FIELD,
	ANNOTATION_MAPPINGS_STATE_FIELD,
	ANNOTATION_META_INFOS_STATE_FIELD,
	ANNOTATION_PROPERTIES_STATE_FIELD
} from '../../constants/AnnotationConstants.js';
import {DICOM_IMAGE_DATA_TYPE} from '../../constants/ViewerItemConstants.js';
import {UPDATE_VIEWER_ITEMS} from '../../constants/ViewerItemsActionTypes.js';
import {getImageDataType} from '../../utils/ViewerItemUtils.js';

const NEXT_ANNOTATION_ID = 'NEXT_ANNOTATION_ID';

export const INITIAL_ANNOTATION_REDUCER_STATE = Immutable.Map({
	[NEXT_ANNOTATION_ID]: 0,
	[ACTIVE_ANNOTATION_ID_STATE_FIELD]: null,
	[ANNOTATION_META_INFOS_STATE_FIELD]: Immutable.Map(),
	[ANNOTATION_PROPERTIES_STATE_FIELD]: Immutable.Map(),
	[ANNOTATION_MAPPINGS_STATE_FIELD]: Immutable.Map()
});

export default handleActions({
	[ADD_ANNOTATION]: addAnnotation,
	[REMOVE_ANNOTATION]: removeAnnotation,
	[REMOVE_ANNOTATIONS_OF_TYPE]: removeAnnotationsOfType,
	[REMOVE_READONLY_ANNOTATIONS]: removeReadOnlyAnnotations,
	[SET_ACTIVE_ANNOTATION]: setActiveAnnotation,
	[UPDATE_ANNOTATION_PROPERTIES]: updateAnnotationProperties,
	[UPDATE_VIEWER_ITEMS]: extractEmbeddedAnnotations
}, INITIAL_ANNOTATION_REDUCER_STATE);

/**
 * @param state the current state
 * @param payload consists of { itemType,itemId, annotationType }
 * @returns the state containing the new search
 */
function addAnnotation(state, {payload: {itemType, itemId, annotationType, properties = null, active = true}}) {
	const annotationId = `${state.get(NEXT_ANNOTATION_ID)}`;
	let newState = (
		state
			.update(NEXT_ANNOTATION_ID, nextAnnotationId => nextAnnotationId + 1)
			.setIn([ANNOTATION_META_INFOS_STATE_FIELD, annotationId], Immutable.Map({annotationType, itemType, itemId}))
			.updateIn(
				[ANNOTATION_MAPPINGS_STATE_FIELD, itemType, `${itemId}`],
				Immutable.Set(),
				annotationIds => annotationIds.add(annotationId)
			)
	);
	if (properties !== null) {
		newState = newState.setIn([ANNOTATION_PROPERTIES_STATE_FIELD, annotationId], properties);
	}
	return active ? newState.set(ACTIVE_ANNOTATION_ID_STATE_FIELD, annotationId) : newState;
}

function extractEmbeddedAnnotations(state, {payload: typedViewerUpdates = Immutable.Map()}) {
	let nextState = state;
	if (typedViewerUpdates.has('imageData')) {
		nextState = typedViewerUpdates.get('imageData')
			.filter(imageData => getImageDataType(imageData) === DICOM_IMAGE_DATA_TYPE)
			.reduce(addAnnotationForFrame, nextState);
	} else if (typedViewerUpdates.has('file')) {
		nextState = typedViewerUpdates.get('file')
			.reduce(addAnnotationForFile, nextState);
	}
	return nextState;
}

function addAnnotationForFile(state, file, fileId) {
	const rawImage = file.get('rawImage');
	return rawImage ? addAnnotationsForSynAdvancedImage(state, rawImage, fileId, 'file') : state;
}

function addAnnotationForFrame(state, frame) {
	const rawImage = frame.get('rawImage');
	return rawImage ? addAnnotationsForSynAdvancedImage(state, rawImage, frame.get('imageId'), 'image') : state;
}

function addAnnotationsForSynAdvancedImage(oldState, advancedImage, imageId, itemType) {
	return advancedImage
		.get('annotations', Immutable.List())
		.reduce(
			(state, serverSideAnnotation) => addEmbeddedAnnotation(state, serverSideAnnotation, imageId, itemType),
			oldState
		);
}

function addEmbeddedAnnotation(state, annotation, imageId, itemType) {
	return addAnnotation(state, {
		payload: {
			itemType,
			itemId: `${imageId}`,
			annotationType: annotation.get('type'),
			properties: annotation.delete('type').update('points', convertPoints)
				.set('readOnly', true),
			active: false
		}
	});
}

function convertPoints(points) {
	return points.reduce((convertedPoints, point) => {
		convertedPoints.push(vec2.set(new Float64Array(2), point.get(0), point.get(1)));
		return convertedPoints;
	}, []);
}


function removeAnnotation(state, {payload: {annotationId}}) {
	return removeAnnotationHelper(state, annotationId);
}

function removeReadOnlyAnnotations(state) {
	return state
		.get(ANNOTATION_PROPERTIES_STATE_FIELD, Immutable.Map())
		.filter(annotation => annotation.get('readOnly', false))
		.keySeq()
		.reduce(removeAnnotationHelper, state);
}

function removeAnnotationHelper(state, annotationId) {
	const existingAnnotation = state.getIn([ANNOTATION_META_INFOS_STATE_FIELD, annotationId], null);
	let newState = state;

	if (existingAnnotation) {
		const {itemType, itemId} = existingAnnotation.toJS();
		const itemKey = itemId.toString();
		newState = newState.update(ACTIVE_ANNOTATION_ID_STATE_FIELD, activeAnnotationID => (
			(activeAnnotationID === annotationId) ? null : activeAnnotationID)
		);

		// TODO Apollon: Clean up empty mappings
		newState = newState
			.deleteIn([ANNOTATION_META_INFOS_STATE_FIELD, annotationId])
			.deleteIn([ANNOTATION_PROPERTIES_STATE_FIELD, annotationId])
			.updateIn([ANNOTATION_MAPPINGS_STATE_FIELD, itemType, itemKey], Immutable.Set(),
				annotations => annotations.delete(annotationId)
			)
			.updateIn([ANNOTATION_MAPPINGS_STATE_FIELD, itemType], Immutable.Map(),
				mappings => (mappings.get(itemKey, Immutable.Set()).isEmpty() ? mappings.delete(itemKey) : mappings)
			);
	}
	return newState;
}

function removeAnnotationsOfType(state, action) {
	const {payload: annotationTypeToRemove} = action;
	return state.get(ANNOTATION_META_INFOS_STATE_FIELD, Immutable.Map())
		.reduce((allIds, metaInfo, annotationId) => {
			const annotationType = metaInfo.get('annotationType');
			if (annotationType === annotationTypeToRemove) {
				return allIds.add(annotationId);
			}
			return allIds;
		}, Immutable.Set())
		.reduce(removeAnnotationHelper, state);
}

function setActiveAnnotation(state, {payload: annotationId}) {
	return state.set(ACTIVE_ANNOTATION_ID_STATE_FIELD, annotationId);
}

function updateAnnotationProperties(state, {payload: {annotationId, annotationProperties: properties}}) {
	return state.setIn([ANNOTATION_PROPERTIES_STATE_FIELD, annotationId], properties);
}
