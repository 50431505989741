import React from 'react';

import ChangePasswordDialogMessagesTranslator
	from '../../authentication/i18n/ChangePasswordDialogMessagesTranslator.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import EditIcon from '../../ui/components/icons/EditIcon.js';
import MenuEntry from '../../ui/components/MenuEntry.js';
import {showChangePasswordDialog} from '../../ui/flux/UIActions.js';
import {useAction} from '../flux/ReactHooks.js';

const MENU_TEXT = <SynFormattedMessage message='DialogTitle' translator={ChangePasswordDialogMessagesTranslator} />;
const MENU_ICON = <EditIcon />;

export default function ChangePasswordMenuEntry(props) {
	const handleClick = useAction(showChangePasswordDialog);
	return <MenuEntry id='changePasswordMenuEntry' icon={MENU_ICON} primaryText={MENU_TEXT} onClick={handleClick} {...props} />;
}
