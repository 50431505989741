import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {buildImagePlaneInfos, findClosestImageIndex, getSyncPointDisplayNumber} from '../../utils/DicomPointSyncUtils.js';
import {selectAnnotationMappings} from './AnnotationSelectors.js';
import {selectImageViewerItems} from './ViewerItemsSelectors.js';
import {selectIsSingleViewerLayout} from './ViewerSelectors.js';

export function selectSyncPointsCount(state) {
	return selectAnnotationMappings(state).get('frameOfReference', Immutable.Map()).size;
}

export function selectSyncPointNumber(state, frameOfReferenceUID) {
	return selectAnnotationMappings(state)
		.get('frameOfReference', Immutable.Map())
		.keySeq()
		.findIndex(key => key === frameOfReferenceUID) + 1;
}

export function selectSyncPointDisplayNumber(state, frameOfReferenceUID) {
	return getSyncPointDisplayNumber(
		selectSyncPointNumber(state, frameOfReferenceUID),
		selectSyncPointsCount(state)
	);
}

export function selectIsPointSyncActive(state, frameOfReferenceUID) {
	return !selectIsSingleViewerLayout(state) &&
		selectAnnotationMappings(state).hasIn(['frameOfReference', frameOfReferenceUID]);
}

export const selectSyncPointAnnotationId = (state, frameOfReferenceUID) => selectAnnotationMappings(state).getIn(['frameOfReference', frameOfReferenceUID], Immutable.Set())
	.first();

export function createSelectClosestFrameOffset(seriesImageIndex) {
	const selectFrameOfReferenceUID = (_, frameOfReferenceUID) => frameOfReferenceUID;
	const selectSyncPoint3d = (_, __, syncPoint3d) => syncPoint3d;
	const selectSeriesImages = createSelector(
		selectImageViewerItems,
		imageViewerItems => seriesImageIndex.entries.map(({id}) => imageViewerItems.get(id))
	);

	const selectImagePlaneInfos = createSelector(
		selectSeriesImages,
		selectFrameOfReferenceUID,
		(seriesImages, frameOfReferenceUID) => buildImagePlaneInfos(seriesImages, frameOfReferenceUID)
	);

	return createSelector(
		selectImagePlaneInfos,
		selectSyncPoint3d,
		(imagePlaneInfos, syncPoint) => (syncPoint && !imagePlaneInfos.isEmpty()
			? seriesImageIndex.frameIndexForImage(findClosestImageIndex(syncPoint.toArray(), imagePlaneInfos))
			: null)
	);
}

