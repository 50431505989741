import React from 'react';
import {connect} from 'react-redux';

import {toggleSidebar} from '../../../ui/flux/UIActions.js';
import {uiIsSidebarVisible} from '../../../ui/flux/UISelectors.js';
import SidebarToggleButton from '../../components/webview/SidebarToggleButton.js';
import {bindActions} from '../../utils/FluxUtils.js';

export default connect(
	state => ({
		checked: uiIsSidebarVisible(state)
	}),
	bindActions({
		onToggle: toggleSidebar
	})
)(SidebarToggleButton);
