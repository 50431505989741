import React from 'react';

import createLayoutComponent from '../../../commons/components/layout/createLayoutComponent.js';
import {withForwardRef} from '../../../commons/utils/ReactUtils.js';

import '../../../../styles/material-design/components/layout/GridList.scss';

const GridListComponent = createLayoutComponent('material-grid-list', 'ul');

GridListComponent.propTypes = {
	forwardRef: withForwardRef.PropTypes.Ref
};

const GridList = withForwardRef(GridListComponent, 'forwardRef');

export default GridList;
