import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../../commons/utils/StyleUtils.js';

import '../../../../styles/material-design/components/layout/PrimaryActionSideAreas.scss';

function createPrimaryActionSideArea(name) {
	PrimaryActionComponent.propTypes = {
		className: PropTypes.string
	};

	function PrimaryActionComponent(props) {
		const {className, children} = props;
		const containerClassNames = combineClassNames('material-primary-action-side-area', className);
		return (
			<div className={containerClassNames}>
				<div className={name}>
					{children}
				</div>
			</div>
		);
	}
	PrimaryActionComponent.displayName = `PrimaryActionComponent[${name}]`;
	return PrimaryActionComponent;
}

export const PrimaryActionIconArea = createPrimaryActionSideArea('icon');
export const PrimaryActionAvatarArea = createPrimaryActionSideArea('avatar');
