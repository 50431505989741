import React from 'react';

import Path from './Path.js';

import '../../../../styles/viewer/components/annotations/SupportPath.scss';

function SupportPath(props) {
	return (
		<Path {...props} className='supportpath--dashed-line' />
	);
}

SupportPath.propTypes = Path.propTypes;
SupportPath.defaultProps = Path.defaultProps;

export default SupportPath;
