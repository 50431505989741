import React from 'react';

import HorizontalLayout from '../../../ui/components/layout/HorizontalLayout.js';
import Spinner from '../../../ui/components/Spinner.js';

export default function UploadSpinner() {
	return (
		<HorizontalLayout noGrow justify='end' align='center'>
			<Spinner />
		</HorizontalLayout>
	);
}
