import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import makeMeasured from '../../../commons/components/makeMeasured.js';
import {not} from '../../../commons/utils/FunctionUtils.js';
import {shallowEqual} from '../../../commons/utils/ObjectUtils';
import {uiDevicePixelRatio, uiIsSidebarVisible, uiWindowSize} from '../../../ui/flux/UISelectors.js';
import {getViewerLayout} from '../selectors/ViewerSelectors.js';

const withLayoutProperties = connect(
	createStructuredSelector({
		windowSize: uiWindowSize,
		viewerLayout: getViewerLayout,
		isSidebarVisible: uiIsSidebarVisible,
		devicePixelRatio: uiDevicePixelRatio
	})
);

export default function withViewerLayoutProps(Component) {
	return withLayoutProperties(makeMeasured(not(shallowEqual), Component));
}
