import React from 'react';
import PropTypes from 'prop-types';

import {noop} from '../../utils/FunctionUtils.js';

export const STATE_HIDDEN = 'STATE_HIDDEN';
export const STATE_MOUNTED = 'STATE_MOUNTED';
export const STATE_ENTERING = 'STATE_ENTERING';
export const STATE_ENTERED = 'STATE_ENTERED';
export const STATE_EXITING = 'STATE_EXITING';
export const STATE_EXITED = 'STATE_EXITED';

export default class SynTransition extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.cancelLastTimedState = noop;
		this.cancelLastRequest = noop;
		this.state = {
			transitionState: STATE_HIDDEN
		};
	}

	render() {
		const {children} = this.props;
		const {transitionState} = this.state;
		return React.cloneElement(children, {transitionState});
	}


	performTransition() {
		const {visible} = this.props;
		if (visible) {
			this.transitionToFullscreen();
		} else {
			this.transitionToHidden();
		}
	}

	setStateOnAnimationFrame(nextState, callBackAfterStateTransition = noop) {
		this.cancelLastRequest();
		const requestId = window.requestAnimationFrame(() => {
			this.cancelLastRequest = noop;
			this.setState(nextState, callBackAfterStateTransition);
		});
		this.cancelLastRequest = () => {
			this.cancelLastRequest = noop;
			window.cancelAnimationFrame(requestId);
		};
	}

	setStateAfter(nextState, timeout, callBackAfterStateTransition = noop) {
		this.cancelLastTimedState();
		const timerId = window.setTimeout(() => {
			this.cancelLastTimedState = noop;
			this.setStateOnAnimationFrame(nextState, callBackAfterStateTransition);
		}, timeout);
		this.cancelLastTimedState = () => {
			this.cancelLastTimedState = noop;
			window.clearTimeout(timerId);
		};
	}

	transitionToFullscreen() {
		const {transitionDuration, onEntered} = this.props;
		const {transitionState} = this.state;
		switch (transitionState) {
			case STATE_HIDDEN:
				this.setStateOnAnimationFrame({transitionState: STATE_MOUNTED});
				break;
			case STATE_MOUNTED:
				this.setStateOnAnimationFrame({transitionState: STATE_ENTERING});
				break;
			case STATE_ENTERING:
				this.setStateAfter({transitionState: STATE_ENTERED}, transitionDuration, onEntered);
				break;
			default:
				break;
		}
	}

	transitionToHidden() {
		const {transitionDuration, onExited} = this.props;
		const {transitionState} = this.state;
		switch (transitionState) {
			case STATE_ENTERED:
				this.setStateOnAnimationFrame({transitionState: STATE_EXITING});
				break;
			case STATE_EXITING:
				this.setStateAfter({transitionState: STATE_EXITED}, transitionDuration, onExited);
				break;
			default:
				break;
		}
	}

	componentDidMount() {
		this.performTransition();
	}

	componentDidUpdate(prevProps, prevState) {
		const {visible} = this.props;
		const {transitionState} = this.state;
		if (prevProps.visible !== visible || prevState.transitionState !== transitionState) {
			this.performTransition();
		}
	}

	componentWillUnmount() {
		this.cancelLastRequest();
		this.cancelLastTimedState();
	}
}

SynTransition.propTypes = {
	visible: PropTypes.bool,
	transitionDuration: PropTypes.number,
	onExited: PropTypes.func,
	onEntered: PropTypes.func
};
