import {connect} from 'react-redux';

import {bindActions} from '../../../commons/utils/FluxUtils.js';
import StepEnterCredentials from '../../components/StepEnterCredentials.js';
import {login} from '../actions/SessionActions.js';

export default connect(
	null,
	bindActions({
		login
	})
)(StepEnterCredentials);
