import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {
	CLOSE_ALL_MATCHING_PERSISTENT_PORTALS,
	CLOSE_PERSISTENT_PORTAL,
	UPDATE_PERSISTENT_PORTAL
} from '../constants/PersistentPortalActionTypes.js';

const INITIAL_REDUCER_STATE = Immutable.Map();

export default handleActions({
	[UPDATE_PERSISTENT_PORTAL]: updatePersistentPortal,
	[CLOSE_PERSISTENT_PORTAL]: closePersistentPortal,
	[CLOSE_ALL_MATCHING_PERSISTENT_PORTALS]: closeAllMatchingPersistentPortals
}, INITIAL_REDUCER_STATE);

/**
 * @param state the current state
 * @param payload the content of the persistent portal to show/update
 * @returns the new state with updated information for the specified portal
 */
function updatePersistentPortal(state, {payload: {id: portalId, content}}) {
	return state.set(portalId, content);
}

/**
 * @param state the current state
 * @param payload the id of the portal to close
 * @returns the new state without the closed portal
 */
function closePersistentPortal(state, {payload: portalId}) {
	return state.delete(portalId);
}

/**
 * Removes all portals registered under an id matching the given pattern.
 * @param state
 * @param pattern - pattern to match against a portals id
 * @returns {*}
 */
function closeAllMatchingPersistentPortals(state, {payload: pattern}) {
	return state.filter((_, key) => !key.match(pattern));
}
