import React from 'react';
import PropTypes from 'prop-types';

import {callSafe, noop} from '../utils/FunctionUtils.js';
import {scheduleTimerRepeated} from '../utils/TimerUtils.js';
import Timer from './Timer.js';

function defaultMessageComponent(props) {
	return `${props.value}`;
}

export default class Counter extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnStep = this.onStep.bind(this);

		this.state = {
			value: props.from,
			finished: false
		};
	}

	render() {
		const {messageComponent: MessageComponent, interval} = this.props;
		const {value, finished} = this.state;
		const message = <MessageComponent messageParams={{value}} />;
		let content = message;
		if (!finished) {
			content = (
				<Timer scheduleTimer={scheduleTimerRepeated} delay={interval} onTimeout={this.boundOnStep}>
					{message}
				</Timer>
			);
		}
		return content;
	}

	onStep() {
		const {step, to, onStep, onFinished} = this.props;
		const {value} = this.state;
		const newValue = value + step;
		const finished = step > 0 && newValue >= to || step < 0 && newValue <= to;
		this.setState({
			value: newValue,
			finished
		});

		callSafe(onStep, newValue);
		if (finished && Boolean(onFinished)) {
			callSafe(onFinished, newValue);
		}
	}
}

Counter.propTypes = {
	messageComponent: PropTypes.element,
	from: PropTypes.number,
	to: PropTypes.number,
	step: PropTypes.number,
	interval: PropTypes.number, // in ms
	onStep: PropTypes.func,
	onFinished: PropTypes.func
};

Counter.defaultProps = {
	messageComponent: defaultMessageComponent,
	from: 10,
	to: 0,
	step: -1,
	interval: 1000,
	onStep: noop,
	onFinished: noop
};
