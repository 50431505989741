import React from 'react';
import PropTypes from 'prop-types';

import ErrorHint from '../../commons/components/webview/ErrorHint.js';
import FormContainer from '../../commons/containers/FormContainer.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import NumberCodeFormField from '../../material-design/components/form/NumberCodeFormField.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import LoginFormMessagesTranslator from '../i18n/LoginFormMessagesTranslator.js';
import LoginPageMessagesTranslator from '../i18n/LoginPageMessagesTranslator.js';
import loginCredentialsFormAccessors from './accessors/LoginCredentialsFormAccessors.js';

export default class StepEnterSecondFactor extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnSubmit = this.onSubmit.bind(this);
		this.boundOnReset = this.onReset.bind(this);
	}

	render() {
		const {isBusy, username} = this.props;
		return (
			<React.Fragment>
				<SynFormattedMessage translator={LoginPageMessagesTranslator} message='TwoFactorEnterTokenMessage' />
				<VerticalLayout justify={'end'} element={FormContainer}
				                description={loginCredentialsFormAccessors} onSubmit={this.boundOnSubmit}>
					<NumberCodeFormField autoFocus forceFloating fieldName='secondfactor' autoCorrect='off'
					                     autoCapitalize='off' autoComplete='one-time-code' />
					<MaterialFormButton disabled={isBusy} flat opaque style={{width: '100%'}} type='submit'>
						<SynFormattedMessage message={this.getLoginButtonMessage()}
						                     translator={LoginFormMessagesTranslator} />
					</MaterialFormButton>
				</VerticalLayout>
				<ErrorHint messageParams={{username}} translator={LoginPageMessagesTranslator} hintMessage='SwitchUserHint'
				           solutionMessage='SwitchUserSolution' solution={this.boundOnReset} />
			</React.Fragment>
		);
	}

	onSubmit(fields) {
		const {secondfactor} = fields;
		const {acquireSecondFactorToken} = this.props;
		callSafe(acquireSecondFactorToken, secondfactor);
	}

	getLoginButtonMessage() {
		const {isBusy} = this.props;
		return isBusy ? 'LoggingIn' : 'Login';
	}

	onReset() {
		const {resetLoginWorkflow} = this.props;
		callSafe(resetLoginWorkflow);
	}
}

StepEnterSecondFactor.propTypes = {
	isBusy: PropTypes.bool,
	username: PropTypes.string,
	acquireSecondFactorToken: PropTypes.func,
	resetLoginWorkflow: PropTypes.func
};
