import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {
	shareReceiver as shareReceiverFluxApi,
	shareReceiverBackgroundValidator as shareReceiverBackgroundValidationFluxApi
} from '../../commons/flux/crudresources/CrudResourcesFluxApi.js';
import createStepperSelectors from '../../commons/selectors/createStepperSelectors.js';
import {createStep, StepperFooterStateBuilder} from '../../commons/utils/StepperUtils.js';
import ShareReceiverFormAccessors from '../components/ShareReceiverFormAccessors.js';
import {
	DATA_FIELD_IS_CREATING_SHARE,
	DATA_FIELD_RECEIVER_FILTER,
	DATA_FIELD_SELECTED_RECEIVER,
	DATA_FIELD_SHARE_CREATED_SUCCESSFULLY,
	DATA_FIELD_SHARE_CREATION_ERROR,
	DATA_FIELD_SHARE_MODE,
	MODE_EXISTING_RECEIVER,
	MODE_NEW_RECEIVER,
	STEPPER_ID
} from '../constants/ShareDocumentConstants.js';
import {
	DATA_FIELD_EMAIL,
	DATA_FIELD_FIRSTNAME,
	DATA_FIELD_INDEX_KEY,
	DATA_FIELD_LASTNAME,
	DATA_FIELD_MOBILENUMBER,
	DATA_FIELD_USER_ID
} from '../constants/ShareReceiverConstants.js';

const {
	isValid: selectIsNewShareReceiverFormValid,
	getAllMappedFormFieldValues, getFormFieldValue, getFieldValidationErrorSelector
} = ShareReceiverFormAccessors;

const {
	selectFilterString, selectShareReceiverIndex
} = shareReceiverFluxApi.selectors;

export const {
	selectFilterString: selectCurrentBackgroundValidationFilterString,
	getResponse: selectBackgroundValidationResponse,
	selectFilterString: selectBackgroundFilterString,
	isLoadInProgress: selectIsBackgroundValidationInProgress
} = shareReceiverBackgroundValidationFluxApi.selectors;

const selectNewShareReceiver = createSelector(
	selectIsNewShareReceiverFormValid, getAllMappedFormFieldValues,
	(newShareReceiverValid, allFormValues) => newShareReceiverValid && Immutable.Map({
		[DATA_FIELD_FIRSTNAME]: allFormValues.firstName,
		[DATA_FIELD_LASTNAME]: allFormValues.lastName,
		[DATA_FIELD_EMAIL]: allFormValues.email,
		[DATA_FIELD_MOBILENUMBER]: allFormValues.mobileNumber
	}) || null
);

const SHARE_DOCUMENT_STEPS = [
	createStep(createSelectReceiverIsComplete),
	createStep(),
	createStep(createSelectShareIsComplete, createSelectCreateShareFooterState)
];

export const selectNewShareReceiverExists = createSelector(
	getFieldValidationErrorSelector('email'),
	state => getFormFieldValue(state, 'email'),
	selectBackgroundValidationResponse,
	selectCurrentBackgroundValidationFilterString,
	(emailValidationError, enteredEMail, validationResponse, backgroundFilterString) => {
		const trimmedEnteredEMail = Boolean(enteredEMail) && enteredEMail.trim() || '';
		const trimmedFilterString = Boolean(backgroundFilterString) && backgroundFilterString.trim() || '';
		return emailValidationError === true && trimmedEnteredEMail === trimmedFilterString &&
			Boolean(validationResponse) && validationResponse.getTotalSize() > 0;
	}
);

export const selectIsValidReceiverEmailAddress = createSelector(
	getFieldValidationErrorSelector('email'),
	state => getFormFieldValue(state, 'email'),
	selectBackgroundValidationResponse,
	selectCurrentBackgroundValidationFilterString,
	selectIsBackgroundValidationInProgress,
	(
		emailValidationError, enteredEMail, validationResponse, backgroundFilterString, isBackgroundValidationInProgress
	) => {
		const trimmedEnteredEMail = Boolean(enteredEMail) && enteredEMail.trim() || '';
		const trimmedFilterString = Boolean(backgroundFilterString) && backgroundFilterString.trim() || '';

		return emailValidationError === true && trimmedEnteredEMail === trimmedFilterString &&
			!isBackgroundValidationInProgress && validationResponse && validationResponse.getTotalSize() === 0;
	}
);

const selectIsNewShareReceiverValid = createSelector(
	selectIsNewShareReceiverFormValid, selectIsValidReceiverEmailAddress,
	(formIsValid, isValidReceiverEmailAddress) => formIsValid && isValidReceiverEmailAddress
);

function createSelectReceiverIsComplete(baseSelectors) {
	const {createSelectDataField} = baseSelectors;
	return createSelector(
		createSelectDataField(DATA_FIELD_SELECTED_RECEIVER, null),
		createSelectDataField(DATA_FIELD_SHARE_MODE, MODE_EXISTING_RECEIVER),
		selectIsNewShareReceiverValid, selectShareReceiverIndex,
		(selectedReceiver, shareMode, isNewShareReceiverValid, shareReceiverIndex) => {
			let isValid = false;
			switch (shareMode) {
				case MODE_EXISTING_RECEIVER:
					isValid = selectedReceiver !== null && selectedReceiver.get(DATA_FIELD_USER_ID, null) !== null &&
						Boolean(shareReceiverIndex) &&
						shareReceiverIndex.has(selectedReceiver.get(DATA_FIELD_INDEX_KEY));
					break;
				case MODE_NEW_RECEIVER:
					isValid = isNewShareReceiverValid;
					break;
				default:
					break;
			}
			return isValid;
		}
	);
}

function createSelectShareIsComplete(baseSelectors) {
	const {selectData} = baseSelectors;
	return createSelector(
		selectData,
		data => Boolean(data) &&
				!data.get(DATA_FIELD_IS_CREATING_SHARE, false) &&
				(
					data.get(DATA_FIELD_SHARE_CREATED_SUCCESSFULLY, false) ||
					data.get(DATA_FIELD_SHARE_CREATION_ERROR, null) !== null
				)
	);
}

function createSelectCreateShareFooterState(baseSelectors) {
	const {selectData} = baseSelectors;
	return createSelector(
		selectData, buildCreateShareFooterState
	);
}

function buildCreateShareFooterState(data) {
	const shareCreationFailed = data.get(DATA_FIELD_SHARE_CREATION_ERROR, null) !== null;
	let footerStateBuilder = new StepperFooterStateBuilder();
	if (shareCreationFailed) {
		footerStateBuilder = footerStateBuilder
			.nextIsClose();
	} else {
		footerStateBuilder = footerStateBuilder
			.hidePreviousButton()
			.nextIsFinish();
	}
	return footerStateBuilder.build();
}

const STEPPER_SELECTORS = createStepperSelectors(STEPPER_ID, SHARE_DOCUMENT_STEPS);

export const {selectNrSteps} = STEPPER_SELECTORS;
export const {selectCurrentStep} = STEPPER_SELECTORS;
export const {selectData} = STEPPER_SELECTORS;
export const {selectHasFinished} = STEPPER_SELECTORS;
export const {selectWasCancelled} = STEPPER_SELECTORS;
export const {selectFooterState} = STEPPER_SELECTORS;
export const {selectIsStepComplete} = STEPPER_SELECTORS;

export const selectReceiverFilter = STEPPER_SELECTORS.createSelectDataField(DATA_FIELD_RECEIVER_FILTER, null);
export const selectShareMode = STEPPER_SELECTORS.createSelectDataField(DATA_FIELD_SHARE_MODE, MODE_EXISTING_RECEIVER);

export const selectIsCreatingShare = createSelector(
	STEPPER_SELECTORS.selectData,
	data => Boolean(data.get(DATA_FIELD_IS_CREATING_SHARE, false))
);

export const selectReceiverFilterOrResourceFilterString = createSelector(
	selectReceiverFilter,
	selectFilterString,
	(receiverFilter, filterString) => (receiverFilter === null ? filterString : receiverFilter) || ''
);

export const selectCurrentShareReceiver = createSelector(
	selectShareMode,
	STEPPER_SELECTORS.createSelectDataField(DATA_FIELD_SELECTED_RECEIVER, null),
	selectNewShareReceiver,
	(shareMode, selectedReceiver, newShareReceiver) => (
		shareMode === MODE_NEW_RECEIVER ? newShareReceiver : selectedReceiver
	)
);
