import React from 'react';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import SynIFrame from '../../commons/components/SynIFrame.js';
import {stopEventPropagation} from '../../commons/utils/DOMEventUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils';
import {useDefaultTheme} from '../../ui/components/theming/ThemeProvider.js';

function HTMLViewerImpl(props) {
	const {srcUrl, className} = props;
	const allClassNames = combineClassNames(className, 'html-viewer');
	return <SynIFrame className={allClassNames} src={srcUrl} disableExternalLinks ariaLabel='' onWheelCapture={stopEventPropagation} />;
}
HTMLViewerImpl.propTypes = {
	srcUrl: PropTypes.string,
	className: PropTypes.string
};

function htmlViewerStyleFactory(componentProps) {
	const {defaultTheme, theme} = componentProps;
	const appliedTheme = defaultTheme || theme;
	const {palette} = appliedTheme;
	return {
		backgroundColor: palette.background.paper,
		color: palette.text.primary,
		padding: appliedTheme.spacing(2),
		overflow: 'auto',
		width: '100%'
	};
}

const StyledHTMLViewer = styled(HTMLViewerImpl, {name: 'HTMLViewer'})(htmlViewerStyleFactory);

export default function HTMLViewer(props) {
	const defaultTheme = useDefaultTheme();
	return <StyledHTMLViewer defaultTheme={defaultTheme} {...props} />;
}
