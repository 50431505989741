import React from 'react';

import createPreviewImagesGrid from '../../commons/components/webview/createPreviewImagesGrid.js';
import {getPreviewImage as getDicomSeriesPreviewImage} from '../../viewer/api/DicomSeriesApi.js';
import {getPreviewImage as getGenericFilePreviewImage} from '../../viewer/api/GenericFileApi.js';
import PreviewTileOverlay from './PreviewTileOverlay.js';

import '../../../styles/patient-details/components/PatientDetailsPreviewGrids.scss';

export const DicomSeriesPreviewGrid = createPreviewGrid(PreviewTileOverlay, getDicomSeriesPreviewImage, 'series');
export const GenericFilesPreviewGrid = createPreviewGrid(PreviewTileOverlay, getGenericFilePreviewImage, 'file');

function createPreviewGrid(CaptionComponent, getPreviewImage, imageType) {
	function createLoadPreviewImageFunction(item) {
		const itemId = item.get('id');
		return function loadPreviewImageForItem(previewDimensions) {
			return getPreviewImage(itemId, previewDimensions);
		};
	}

	function createTaskIdentifier(item) {
		return `${imageType}/${item.get('id')}`;
	}

	return createPreviewImagesGrid(CaptionComponent, createLoadPreviewImageFunction, createTaskIdentifier);
}
