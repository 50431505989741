import React from 'react';
import PropTypes from 'prop-types';

import FormContainer from '../../commons/containers/FormContainer.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import MaterialFormButton from '../../material-design/components/form/MaterialFormButton.js';
import SingleLineFormField from '../../material-design/components/form/SingleLineFormField.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import PasswordResetMessagesTranslator from '../i18n/PasswordResetMessagesTranslator.js';
import PasswordResetFormAccessors from './accessors/PasswordResetFormAccessors.js';

class PasswordResetForm extends React.PureComponent {
	render() {
		const {id, onSubmit} = this.props;
		return (
			<React.Fragment>
				<VerticalLayout element={FormContainer} id={id} description={PasswordResetFormAccessors}
					deleteFormDataOnUnmount onSubmit={onSubmit}>
					<SynFormattedMessage translator={PasswordResetMessagesTranslator} message='ResetPasswordMessage' />
					<SingleLineFormField fieldName='username' name='username' autoCorrect='off' autoCapitalize='off' />
				</VerticalLayout>
				<MaterialFormButton formDescription={PasswordResetFormAccessors} form={id} type='submit' flat opaque>
					<SynFormattedMessage translator={PasswordResetMessagesTranslator} message='ResetPasswordButtonLabel' />
				</MaterialFormButton>
			</React.Fragment>
		);
	}
}

PasswordResetForm.propTypes = {
	id: PropTypes.string,
	onSubmit: PropTypes.func
};

export default PasswordResetForm;
