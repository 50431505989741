import React from 'react';
import {bool, instanceOf, number, object, string} from 'prop-types';

import {
	DEVICE_SIZE_LARGE,
	DEVICE_SIZE_MEDIUM,
	DEVICE_SIZE_SMALL
} from '../../../commons/constants/DeviceInformation.js';
import {formatBirthDate} from '../../../commons/data/aim/PatientHelpers.js';
import {memoizeLast} from '../../../commons/utils/FunctionUtils.js';
import {formatDateTime} from '../../../i18n/FormatHelpers.js';
import DicomImage from '../../data/DicomImage.js';
import DicomImageWindowParametersOverlayContainer
	from '../../flux/containers/DicomImageWindowParametersOverlayContainer.js';
import SyncStateIndicatorOverlay from '../../synchronization/components/SyncStateIndicatorOverlay.js';
import {createDataSelector} from '../../utils/MetadataUtils.js';
import MetaDataOverlay from '../MetaDataOverlay.js';
import MetaDataOverlayArea, {stackLines} from '../MetaDataOverlayArea.js';

export default class DicomImageMetaDataOverlay extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.getOverlayConfig = memoizeLast(this.getOverlayConfig.bind(this));
	}

	render() {
		const {containerWidth, containerHeight, margins, isFullScreen, deviceSize, dicomImage} = this.props;
		const imageMetadata = dicomImage.metadata;
		const overlayConfig = this.getOverlayConfig(isFullScreen, deviceSize);
		const {topLeft, topRight, bottomLeft, bottomRight} = overlayConfig;
		const {horizontalFrom, horizontalTo, verticalFrom, verticalTo} = overlayConfig;

		return (
			<MetaDataOverlay containerWidth={containerWidth} containerHeight={containerHeight} margins={margins}>
				<MetaDataOverlayArea align='left' vAlign='top' {...toAreaProps(topLeft, imageMetadata)} />
				<MetaDataOverlayArea align='right' vAlign='top' {...toAreaProps(topRight, imageMetadata)} />
				<MetaDataOverlayArea align='left' vAlign='bottom' {...toAreaProps(bottomLeft, imageMetadata)}	/>
				<MetaDataOverlayArea align='right' vAlign='bottom' {...toAreaProps(bottomRight, imageMetadata)} />
				{/* Image orientation overlays*/}
				<MetaDataOverlayArea align='left' vAlign='center' {...toAreaProps(horizontalFrom, imageMetadata)}	/>
				<MetaDataOverlayArea align='right' vAlign='center' {...toAreaProps(horizontalTo, imageMetadata)} />
				<MetaDataOverlayArea align='center' vAlign='top' {...toAreaProps(verticalFrom, imageMetadata)} />
				<MetaDataOverlayArea align='center' vAlign='bottom' {...toAreaProps(verticalTo, imageMetadata)} />
			</MetaDataOverlay>
		);
	}

	renderBottomRight(areaProps, selectedProps) {
		const {dicomImage} = this.props;
		const yem = selectedProps ? selectedProps.length * -1 : 0;
		return (
			<React.Fragment>
				<DicomImageWindowParametersOverlayContainer dicomImage={dicomImage} xem={0} yem={yem} {...areaProps} />
				{stackLines(areaProps, selectedProps)}
			</React.Fragment>
		);
	}

	static renderBottomLeft(areaProps, selectedProps) {
		const yemOffset = selectedProps ? selectedProps.length - 1 : 0;
		const yem = yemOffset * -1;
		return (
			<React.Fragment>
				<SyncStateIndicatorOverlay yem={yem} />
				{stackLines(areaProps, selectedProps)}
			</React.Fragment>
		);
	}

	getOverlayConfig(isFullScreen, deviceSize) {
		return isFullScreen ? this.getConfigFullscreen() : this.getSizeDependantConfig(deviceSize);
	}

	getSizeDependantConfig(deviceSize) {
		switch (deviceSize) {
			case DEVICE_SIZE_LARGE:
				return this.getConfigLargeSize();
			case DEVICE_SIZE_MEDIUM:
				return this.getConfigMediumSize();
			case DEVICE_SIZE_SMALL:
				return this.getConfigSmallSize();
			default:
				throw new Error(`Unsupported device size: ${deviceSize}`);
		}
	}

	getConfigFullscreen() {
		const {deviceSize, locale} = this.props;
		const isSmallDevice = deviceSize === DEVICE_SIZE_SMALL;
		const birthDateFormat = isSmallDevice ? 'ShortBirthDate' : 'BirthDate';
		const topLeftFormatter =
			DicomImageMetaDataOverlay.fullScreenTopLeftFormatter.bind(undefined, birthDateFormat, locale);
		return {
			topLeft: {
				dataSelector: createDataSelector('PatientInfo', 'PatientBirthDate'),
				formatter: topLeftFormatter
			},
			topRight: {
				dataSelector: isSmallDevice
					? createDataSelector('Modality', 'DocumentDescription', 'SeriesDescription')
					: createDataSelector('Modality', 'InstitutionName', 'DocumentDescription', 'SeriesDescription')
			},
			bottomLeft: DicomImageMetaDataOverlay.getBottomLeftConfig('SeriesInfo', 'Resolution', 'ImageComments'),
			bottomRight: this.getBottomRightConfig(),
			...DicomImageMetaDataOverlay.getOrientationValuesConfig()
		};
	}

	getConfigLargeSize() {
		return {
			topLeft: {dataSelector: createDataSelector()},
			topRight: {dataSelector: createDataSelector('Modality', 'InstitutionName', 'DocumentDescription', 'SeriesDescription')},
			bottomLeft: DicomImageMetaDataOverlay.getBottomLeftConfig('SeriesInfo', 'Resolution', 'ImageComments'),
			bottomRight: this.getBottomRightConfig(),
			...DicomImageMetaDataOverlay.getOrientationValuesConfig()
		};
	}

	getConfigMediumSize() {
		return {
			topLeft: {dataSelector: createDataSelector('Modality')},
			topRight: {dataSelector: createDataSelector('DocumentDescription', 'SeriesDescription')},
			bottomLeft: DicomImageMetaDataOverlay.getBottomLeftConfig('SeriesInfo', 'Resolution', 'ImageComments'),
			bottomRight: this.getBottomRightConfig(),
			...DicomImageMetaDataOverlay.getOrientationValuesConfig()
		};
	}

	getConfigSmallSize() {
		return {
			topLeft: {dataSelector: createDataSelector('Modality', 'SeriesInfo')},
			topRight: {dataSelector: createDataSelector('DocumentDescription', 'SeriesDescription')},
			bottomLeft: DicomImageMetaDataOverlay.getBottomLeftConfig('ImageComments'),
			bottomRight: this.getBottomRightConfig(),
			...DicomImageMetaDataOverlay.getOrientationValuesConfig()
		};
	}

	getBottomRightConfig() {
		const {deviceSize, locale} = this.props;
		const format = deviceSize === DEVICE_SIZE_SMALL ? 'VeryShortDateTime' : 'ShortDateTime';
		return {
			dataSelector: createDataSelector('DocumentCreationDate'),
			renderer: this.renderBottomRight.bind(this),
			formatter: ([documentDate]) => [formatDateTime(documentDate, format, locale)],
			...DicomImageMetaDataOverlay.getOrientationValuesConfig()
		};
	}

	static getBottomLeftConfig(...overlayProps) {
		return {
			dataSelector: createDataSelector(...overlayProps),
			renderer: DicomImageMetaDataOverlay.renderBottomLeft
		};
	}

	static getOrientationValuesConfig() {
		return {
			horizontalFrom: {dataSelector: createDataSelector('horizontalOrientationFrom')},
			horizontalTo: {dataSelector: createDataSelector('horizontalOrientationTo')},
			verticalFrom: {dataSelector: createDataSelector('verticalOrientationFrom')},
			verticalTo: {dataSelector: createDataSelector('verticalOrientationTo')}
		};
	}

	static fullScreenTopLeftFormatter(birthDateFormat, locale, selectedValues) {
		const [patientInfo, birthDate] = selectedValues;
		return [patientInfo, formatBirthDate(birthDate, birthDateFormat, locale)];
	}
}

DicomImageMetaDataOverlay.propTypes = {
	dicomImage: instanceOf(DicomImage),
	deviceSize: string.isRequired,
	margins: object,
	locale: string,
	containerWidth: number.isRequired,
	containerHeight: number.isRequired,
	isFullScreen: bool
};

function toAreaProps(areaConfig, imageMetadata) {
	return {
		data: areaConfig.dataSelector(imageMetadata),
		formatter: areaConfig.formatter,
		renderer: areaConfig.renderer
	};
}
