function ExtendableError(message) {
	Error.apply(this, [message]);
	this.message = message;
	try {
		throw new Error();
	} catch (e) {
		this.stack = e.stack;
	}
	const nameMatch = this.constructor.toString().match(/function (\w*)/);
	const name = (Boolean(nameMatch) && nameMatch[1]) || this.constructor.name;
	Object.defineProperty(this, 'name', {
		__proto__: null, // no inherited properties
		value: name
	});
}
ExtendableError.prototype = Object.create(Error.prototype);
Object.setPrototypeOf(ExtendableError, Error);

export default ExtendableError;
