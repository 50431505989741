import React from 'react';

import createCenteredSVG from '../../createCenteredSVG.js';

const DEFAULT_SIZE = 24;

// path element from material-design-icons/editor/ic_mode_edit_24.svg
export default createCenteredSVG(DEFAULT_SIZE, DEFAULT_SIZE,
	<path d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
);
