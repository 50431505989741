import React from 'react';
import PropTypes from 'prop-types';

import DialogAnchor from '../../commons/components/DialogAnchor.js';
import {tr} from '../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../i18n/translators/ViewerTranslator.js';
import clickTracked from '../../metrics-collector/utils/clickTracked.js';
import InfoOutlinedIcon from '../../ui/components/icons/InfoIcon.js';
import {VIEWER_INFO_DIALOG_ID} from '../constants/ViewerConstants.js';
import createViewerToolbarIconButton from './createViewerToolbarIconButton.js';
import ViewerToolsMenuEntry from './ViewerToolsMenuEntry.js';

const ViewerToolBarButton = clickTracked(createViewerToolbarIconButton(InfoOutlinedIcon));

const NEXT_ANCHOR_ID_SYMBOL = Symbol('NEXT_ANCHOR_ID');

export default class InfoDialogToolbarButton extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.dialogController = null;
		this.boundSetDialogController = this.setDialogController.bind(this);
		this.boundOpenInfoDialog = this.openInfoDialog.bind(this);
		this.dialogAnchorId = InfoDialogToolbarButton.nextAnchorId();
	}

	render() {
		const {children, menuEntry} = this.props;
		const InfoDialogMenuLabel = tr(ViewerTranslator, 'ImageInformation');

		return (
			<React.Fragment>
				{menuEntry ? (
					<ViewerToolsMenuEntry onClick={this.boundOpenInfoDialog} metricsKey='ShowImageInfo'
					                      id='open-info-dialog' label={InfoDialogMenuLabel}
					                      icon={InfoOutlinedIcon} />
				) : (
					<ViewerToolBarButton onClick={this.boundOpenInfoDialog} metricsKey='ShowImageInfo' />
				)}
				<DialogAnchor id={this.dialogAnchorId} dialogControllerRef={this.boundSetDialogController}>
					{children}
				</DialogAnchor>
			</React.Fragment>
		);
	}

	setDialogController(dialogController) {
		this.dialogController = dialogController;
	}

	openInfoDialog() {
		if (this.dialogController) {
			this.dialogController.openDialog();
		}
	}

	static nextAnchorId() {
		const thisId = InfoDialogToolbarButton[NEXT_ANCHOR_ID_SYMBOL]++;
		return `${VIEWER_INFO_DIALOG_ID}-${thisId}`;
	}
}

InfoDialogToolbarButton[NEXT_ANCHOR_ID_SYMBOL] = 0;

InfoDialogToolbarButton.propTypes = {
	children: PropTypes.element.isRequired,
	menuEntry: PropTypes.bool
};
