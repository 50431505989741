import Immutable from 'immutable';
import {createSelector} from 'reselect';

import {ACTIVE_SYNC_TOOLS, TOOL_STATES, VIEWER_SYNC_CHAINS} from '../constants/ViewerSyncStateFields.js';
import {getNextChainID, getSyncChainID} from './ViewerSyncUtils.js';

export function selectSyncToolsState(state) {
	return state.viewerSyncTools || Immutable.Map();
}

export function selectSyncChains(state) {
	return selectSyncToolsState(state)
		.get(VIEWER_SYNC_CHAINS, Immutable.Map());
}

export function selectActiveSyncTools(state) {
	return selectSyncToolsState(state)
		.get(ACTIVE_SYNC_TOOLS, Immutable.Set());
}

export function createSelectIsSyncToolActive(toolId) {
	return createSelector(
		selectActiveSyncTools,
		tools => tools.has(toolId)
	);
}

export function createSelectChainID(viewerId) {
	return createSelector(
		selectSyncToolsState,
		syncToolsState => getSyncChainID(syncToolsState, viewerId)
	);
}

export const selectNextChainID = createSelector(selectSyncToolsState, getNextChainID);

export function selectSyncToolProperty(state, toolId, viewerId, name, defaultValue = null) {
	return selectSyncToolsState(state)
		.getIn([TOOL_STATES, toolId, viewerId, name], defaultValue);
}

export const selectSyncedViewerIds = createSelector(
	selectSyncChains,
	chains => chains.reduce((syncedIds, chain) => syncedIds.union(chain), Immutable.Set())
);
