import React from 'react';
import PropTypes from 'prop-types';

import {memoWithName, withForwardRef} from '../../commons/utils/ReactUtils.js';

// https://www.w3.org/TR/wai-aria-practices-1.1/#TreeView
// https://www.w3.org/TR/wai-aria-1.1/#group

function TreeViewGroupRenderer(props) {
	const {forwardRef, children, ...remainingProps} = props;
	const Element = 'ul';
	return (
		<Element role='group' ref={forwardRef} {...remainingProps}>
			{children}
		</Element>
	);
}

TreeViewGroupRenderer.propTypes = {
	className: PropTypes.string,
	style: PropTypes.object,
	onTransitionEnd: PropTypes.func,
	forwardRef: withForwardRef.PropTypes.Ref
};

export default withForwardRef(memoWithName(TreeViewGroupRenderer), 'forwardRef');
