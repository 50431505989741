import {IS_DEBUG_BUILD} from '../../commons/constants/EnvironmentConstants.js';
import {getColorDepth, isMultiByteImage} from '../../commons/data/aim/SynAdvancedImage.js';
import {debugLog} from '../../commons/utils/DebugLog.js';
import {
	PIXEL_FORMAT_GRAY8,
	PIXEL_FORMAT_GRAY16,
	PIXEL_FORMAT_GRAY32,
	PIXEL_FORMAT_RGBA8
} from '../constants/PixelFormatConstants.js';

const BLACK = 0;
const WHITE = 255;

const RED_PIXEL_VALUE_OFFSET = 0;
const GREEN_PIXEL_VALUE_OFFSET = 1;
const BLUE_PIXEL_VALUE_OFFSET = 2;
const ALPHA_PIXEL_VALUE_OFFSET = 3;
const RGBA_BYTES_PER_PIXEL = 4;
const SIXTEENBITS_BYTES_PER_PIXEL = 2;
const COLOR_DEPTH_BITS_256 = 8;
const COLOR_DEPTH_BITS_65536 = 16;
const COLORS_FOR_8BITS = 256; // 2^8
const COLORS_FOR_16BITS = 65536; // 2^16
const COLORS_FOR_32BITS = 4294967296; // 2^32
export const FULL_OPACITY = 255;

export function calculateLut(
		lut, windowCenter, windowWidth,
		minPixelValue = 0, maxPixelValue = lut.length - 1, invertOutput = false
) {
	const {leftCutOff, rightCutOff, gradient, offset} = calculateLutParameters(windowCenter, windowWidth);
	const firstCalculatedValue = Math.max(leftCutOff + 1, minPixelValue);
	const lastCalculatedValue = Math.min(rightCutOff - 1, maxPixelValue);

	if (minPixelValue < firstCalculatedValue) {
		fill(lut, invertOutput ? WHITE : BLACK, Math.max(minPixelValue, 0), firstCalculatedValue);
	}
	const valueCalculator = createValueCalculator(offset, gradient, invertOutput);
	for (let i = firstCalculatedValue; i <= lastCalculatedValue; ++i) {
		lut[i] = Math.min(WHITE, Math.max(BLACK, valueCalculator(i)));
	}
	const maxIndex = lut.length - 1;
	if (lastCalculatedValue < maxPixelValue && lastCalculatedValue < maxIndex) {
		fill(lut, invertOutput ? BLACK : WHITE, lastCalculatedValue + 1, Math.min(maxIndex, maxPixelValue));
	}
}

function fill(lut, value, begin, end) {
	for (let i = begin; i <= end; ++i) {
		lut[i] = value;
	}
}

function createValueCalculator(offset, gradient, inverted) {
	let calculator = i => Math.round(offset + (i * gradient));
	if (inverted) {
		const oldCalculator = calculator;
		calculator = i => WHITE - oldCalculator(i);
	}
	return calculator;
}

function calculateLutParameters(windowCenter, windowWidth) {
	const high = WHITE;
	const lutRange = high - BLACK;
	const offset = high - ((((windowCenter - 0.5) / (windowWidth - 1)) + 0.5) * lutRange);
	const gradient = lutRange / (windowWidth - 1);

	const dicomLeftCutOff = windowCenter - 0.5 - ((windowWidth - 1) / 2.0);
	const leftCutOff = Math.floor(dicomLeftCutOff);
	const dicomRightCutOff = windowCenter - 0.5 + ((windowWidth - 1) / 2.0);
	const rightCutOff = Math.floor(dicomRightCutOff + 1.0);

	return {gradient, offset, leftCutOff, rightCutOff};
}

function mapGrayToRGB8(rgbaSamples, rgbPixelIndex, lut, rawPixels) {
	const mappedValue = lut[rawPixels[rgbPixelIndex]];
	const rawIndex = rgbPixelIndex * RGBA_BYTES_PER_PIXEL;
	rgbaSamples[rawIndex + RED_PIXEL_VALUE_OFFSET] = mappedValue;
	rgbaSamples[rawIndex + GREEN_PIXEL_VALUE_OFFSET] = mappedValue;
	rgbaSamples[rawIndex + BLUE_PIXEL_VALUE_OFFSET] = mappedValue;
	rgbaSamples[rawIndex + ALPHA_PIXEL_VALUE_OFFSET] = FULL_OPACITY;
}

function mapRGBA8ToRGBA8(rgbaSamples, rgbaPixelIndex, lut, rawPixels) {
	const rawIndex = rgbaPixelIndex * RGBA_BYTES_PER_PIXEL;
	rgbaSamples[rawIndex + RED_PIXEL_VALUE_OFFSET] = lut[rawPixels[rawIndex]];
	rgbaSamples[rawIndex + GREEN_PIXEL_VALUE_OFFSET] = lut[rawPixels[rawIndex + GREEN_PIXEL_VALUE_OFFSET]];
	rgbaSamples[rawIndex + BLUE_PIXEL_VALUE_OFFSET] = lut[rawPixels[rawIndex + BLUE_PIXEL_VALUE_OFFSET]];
	rgbaSamples[rawIndex + ALPHA_PIXEL_VALUE_OFFSET] = FULL_OPACITY;
}

function applyLut(lut, rgbaSamples, rawPixels, pixelMapper, nrPixels) {
	const finalNrPixels = nrPixels === undefined ? (rgbaSamples.length / RGBA_BYTES_PER_PIXEL) : nrPixels;
	let i = 0;
	for (; i < finalNrPixels; ++i) {
		pixelMapper(rgbaSamples, i, lut, rawPixels);
	}
}

export function applyLutToGray(lut, rgbaSamples, rawPixels, nrPixels) {
	return applyLut(lut, rgbaSamples, rawPixels, mapGrayToRGB8, nrPixels);
}

export function applyLutToRGBA8(lut, rgbaSamples, rawPixels, nrPixels) {
	return applyLut(lut, rgbaSamples, rawPixels, mapRGBA8ToRGBA8, nrPixels);
}

/**
 * Scales the passed pixels to the given dimensions using bilinear interpolation.
 * NOTE: If no scaling is necessary, the original pixels array will be returned!
 * @param pixels {ArrayLike} - containing the image to be scaled as linear sequence
 * @param samplesPerPixel {number} - how many samples make one pixel
 * @param inputWidth {number} - width in pixels of the original image
 * @param inputHeight {number} - height in pixels of the original image
 * @param outputWidth {number} - desired scaled width of the final image
 * @param outputHeight {number} - desired scaled height of the final image
 * @returns {ArrayLike} - Array containing the scaled image and of the same type as the parameter pixels
 */
export const scaleImagePixelsBilinear = IS_DEBUG_BUILD
	  ? measureTime(scaleImagePixelsBilinearImpl)
	  : scaleImagePixelsBilinearImpl;
function scaleImagePixelsBilinearImpl(samples, samplesPerPixel, inputWidth, inputHeight, outputWidth, outputHeight) {
	const validParameters = validateScaleParameters(samples, inputWidth, inputHeight, outputWidth, outputHeight);
	let scaledPixels = validParameters ? samples : undefined;
	if (Boolean(scaledPixels) && (inputWidth !== outputWidth || inputHeight !== outputHeight)) {
		scaledPixels = new samples.constructor(outputWidth * outputHeight * samplesPerPixel);
		const allColumnParameters = prepareParameters(outputWidth, inputWidth, samplesPerPixel);
		const allRowParameters = prepareParameters(outputHeight, inputHeight, samplesPerPixel, inputWidth);
		for (let row = 0; row < outputHeight; ++row) {
			const {
				delta: inputDy,
				startIndices: inputRowStartIndices
			} = allRowParameters[row];

			const outputRowPixelStart = row * outputWidth * samplesPerPixel;
			for (let column = 0; column < outputWidth; ++column) {
				const {
					delta: inputDx,
					startIndices: inputColumnStartIndices
				} = allColumnParameters[column];

				const inputStartIndices = [
					inputRowStartIndices[0] + inputColumnStartIndices[0],
					inputRowStartIndices[0] + inputColumnStartIndices[1],
					inputRowStartIndices[1] + inputColumnStartIndices[0],
					inputRowStartIndices[1] + inputColumnStartIndices[1]
				];

				const interpolatedPixel = calculateBilinearInterpolatedPixel(
					samples, samplesPerPixel,
					inputStartIndices, inputDx, inputDy
				);
				const outputPixelStart = outputRowPixelStart + (column * samplesPerPixel);
				// Copy over pixel samples
				for (let sampleIndex = 0; sampleIndex < samplesPerPixel; ++sampleIndex) {
					scaledPixels[outputPixelStart + sampleIndex] = interpolatedPixel[sampleIndex];
				}
			}
		}
	}
	return scaledPixels;
}

function prepareParameters(outputSize, inputSize, samplesPerPixel, indexFactor = 1) {
	const halfOutputSize = outputSize / 2.0;
	const halfInputSize = inputSize / 2.0;
	const scaleFactor = inputSize / outputSize;
	const maxIndex = inputSize - 1;

	// (new Array(x)).map(...) won't work!
	const parameters = new Array(outputSize);
	for (let i = 0; i < outputSize; ++i) {
		const exactPosition = ((i + 0.5 - halfOutputSize) * scaleFactor) + halfInputSize;
		const snappedPositions = snapToPreviousAndNext(exactPosition);
		const startIndices = snappedPositions.map(snappedValue => Math.max(0,
			Math.min(
				Math.floor(snappedValue),
					 maxIndex
			) * indexFactor * samplesPerPixel
		));
		parameters[i] = {
			delta: exactPosition - snappedPositions[0],
			startIndices
		};
	}
	return parameters;
}

function snapToPreviousAndNext(a) {
	const snapOffset = 0.5;
	const d = Math.ceil(a - snapOffset) - Math.floor(a - snapOffset);
	const previous = Math.ceil(a - snapOffset - d) + snapOffset;
	const next = Math.floor(a - snapOffset + d) + snapOffset;
	return [previous, next];
}

function calculateBilinearInterpolatedPixel(pixels, samplesPerPixel, inputStartIndices, inputDx, inputDy) {
	const pixelFactors = [
		(1 - inputDx) * (1 - inputDy),
		inputDx * (1 - inputDy),
		(1 - inputDx) * inputDy,
		inputDx * inputDy
	];
	const interpolatedPixel = new pixels.constructor(samplesPerPixel);
	for (let sampleIndex = 0; sampleIndex < samplesPerPixel; ++sampleIndex) {
		interpolatedPixel[sampleIndex] = pixelFactors.reduce(
			(finalValue, factor, pixelIndex) => (
				finalValue + (pixels[inputStartIndices[pixelIndex] + sampleIndex] * factor)
			), 0
		);
	}
	return interpolatedPixel;
}

function validateScaleParameters(pixels, imageWidth, imageHeight, targetWidth, targetHeight) {
	return Boolean(pixels) && imageWidth > 0 && imageHeight > 0 && targetWidth > 0 && targetHeight > 0;
}

function measureTime(f, name = f.name) {
	return function measuredFunction(...args) {
		const startTime = performance.now();
		const result = f(...args);
		const endTime = performance.now();
		debugLog(`${name} took ${endTime - startTime}ms`);
		return result;
	};
}

export function getPixelDataArrayType(pixelFormat) {
	switch (pixelFormat) {
		case PIXEL_FORMAT_RGBA8:
		case PIXEL_FORMAT_GRAY8:
			return Uint8Array;
		case PIXEL_FORMAT_GRAY16:
			return Uint16Array;
		case PIXEL_FORMAT_GRAY32:
			return Uint32Array;
		default:
			throw new Error(`Unsupported pixel format: ${pixelFormat}`);
	}
}

export function getPixelFormat(synAdvancedImage) {
	switch (getColorDepth(synAdvancedImage)) {
		case COLOR_DEPTH_BITS_256:
			return PIXEL_FORMAT_GRAY8;
		case COLOR_DEPTH_BITS_65536:
			return PIXEL_FORMAT_GRAY16;
		default:
			return isMultiByteImage(synAdvancedImage) ? PIXEL_FORMAT_GRAY32 : PIXEL_FORMAT_RGBA8;
	}
}

export function getLutSize(pixelFormat) {
	switch (pixelFormat) {
		case PIXEL_FORMAT_GRAY8:
		case PIXEL_FORMAT_RGBA8:
			return COLORS_FOR_8BITS;
		case PIXEL_FORMAT_GRAY16:
			return COLORS_FOR_16BITS;
		case PIXEL_FORMAT_GRAY32:
			return COLORS_FOR_32BITS;
		default:
			throw new Error(`Unsupported pixel format: ${pixelFormat}`);
	}
}

export function getBytesPerPixel(pixelFormat) {
	switch (pixelFormat) {
		case PIXEL_FORMAT_GRAY32:
		case PIXEL_FORMAT_RGBA8:
		case PIXEL_FORMAT_GRAY8:
			return RGBA_BYTES_PER_PIXEL;
		case PIXEL_FORMAT_GRAY16:
			return SIXTEENBITS_BYTES_PER_PIXEL;
		default:
			throw new Error(`Unsupported pixel format: ${pixelFormat}`);
	}
}

export function getLutFunction(pixelFormat) {
	switch (pixelFormat) {
		case PIXEL_FORMAT_GRAY16:
		case PIXEL_FORMAT_GRAY32:
			return applyLutToGray;
		default:
			return applyLutToRGBA8;
	}
}

export function getRenderingContext(canvas) {
	return getRenderingContextWithAttributes(canvas);
}

export function getOffscreenRenderingContext(canvas) {
	return getRenderingContextWithAttributes(canvas, {willReadFrequently: true});
}

function getRenderingContextWithAttributes(canvas, attributes = undefined) {
	const canvasContext = canvas.getContext('2d', attributes);
	const smoothing = true;

	canvasContext.webkitImageSmoothingEnabled = smoothing;
	canvasContext.imageSmoothingEnabled = smoothing;

	return canvasContext;
}
