import React from 'react';
import classnames from 'classnames';

import {combineClassNames} from '../../utils/StyleUtils.js';
import createDelayedPropsComponent from '../createDelayedPropsComponent.js';

import '../../../../styles/commons/components/webview/OverlayContainer.scss';

const PropsDelayedOverlayContainer = createDelayedPropsComponent(props => {
	const classes = combineClassNames(classnames({
		'overlay-container': true,
		visible: props.visible
	}), props.className);
	return (
		<div className={classes} aria-hidden={props.visible ? 'false' : 'true'}>
			{props.children}
		</div>
	);
});

export default function OverlayContainer(props) {
	return <PropsDelayedOverlayContainer {...props} delayedProps={['visible']} delay={1} />;
}
