import React from 'react';
import {connect} from 'react-redux';
import {createSelector} from 'reselect';

import ViewerToolbarNotchInset from '../../components/ViewerToolbarNotchInset.js';
import {getViewerLayout} from '../selectors/ViewerSelectors.js';

export default connect(
	createSelector(getViewerLayout, layout => ({
		bottomViewerIds: layout.get('bottom').toJS()
	}))
)(ViewerToolbarNotchInset);
