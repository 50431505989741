import {useState} from 'react';

import {shallowEqual} from '../ObjectUtils';
import {useMemoFactory} from './index.js';

export default function useChangedState(initialState) {
	const [selectedProps, setSelectedProps] = useState(initialState);
	const memoizedSetChangedState = useMemoFactory(createSetChangedState, setSelectedProps);
	return [
		selectedProps,
		memoizedSetChangedState
	];
}

function createSetChangedState(setState) {
	return newState => {
		setState(oldState => oldOrNewState(oldState, newState));
	};
}

function oldOrNewState(oldState, newState) {
	return shallowEqual(newState, oldState) ? oldState : newState;
}
