import React from 'react';

import Status from '../../webview/a11y/components/landmarks/Status.js';
import useBrick from '../../webview/bricks/hooks/useBrick.js';
import ErrorHint from '../../webview/commons/components/webview/ErrorHint.js';
import StatusMessage from '../../webview/commons/components/webview/StatusMessage.js';
import {STATUS_FAILED} from '../../webview/commons/constants/StatusMessageConstants.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import Button from '../../webview/ui/components/Button.js';
import UploadController from '../bricks/UploadController.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';
import WebUploadCard from './WebUplaodCard.js';

export default function UploadError() {
	const {uploadedCount, failedCount} = useBrick(UploadFilesCollection, selectErrorProps);
	const restartWithFailed = useBrick(UploadController, controller => controller.restartWithFailed);
	return (
		<WebUploadCard>
			<StatusMessage status={STATUS_FAILED}>
				<SynFormattedMessage message='UploadError' />
			</StatusMessage>
			<ErrorHint wrapperComponent={Status} hintMessage='UploadErrorDetails'
			           messageParams={{uploadedCount, failedCount}} />
			<Button flat opaque onClick={restartWithFailed}>
				<SynFormattedMessage message='RestartFailedButton' />
			</Button>
		</WebUploadCard>
	);
}

function selectErrorProps(service) {
	return {
		uploadedCount: service.getUploadedFilesCount(),
		failedCount: service.getFailedFilesCount()
	};
}
