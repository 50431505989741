import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {memoWithName} from '../../commons/utils/ReactUtils.js';
import useTranslation from '../../i18n/hooks/useTranslation.js';
import FoldIcon from '../../material-design/components/icons/FoldIcon.js';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import PrimaryItemText from '../../material-design/components/text/PrimaryItemText.js';
import {ClickTrackedRippleButton} from '../../metrics-collector/components/TrackedComponents.js';
import {BLACK_COLOR_NAME} from '../../ui/constants/ColorConstants.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';

import '../../../styles/patient-details/components/PatientDetailsCategoryHeader.scss';

const INTENT_DEPTH_PER_LEVEL = 16;
const SCROLLBAR_SPACING = 8;

export default memoWithName(PatientDetailsCategoryHeader);

function PatientDetailsCategoryHeader(props) {
	const {
		itemsPerRow, totalTileWidth, isExpanded, label, numChildItems, isNotAssignedDocumentsCategory, categoryDepth,
		onCollapse, onExpand, categoryId
	} = props;
	const contentWidth = (itemsPerRow - 1) * totalTileWidth;
	const categoryIndentation = Math.min(categoryDepth * INTENT_DEPTH_PER_LEVEL, contentWidth + SCROLLBAR_SPACING);
	const toolTip = useTranslation(isExpanded ? 'closeCategory' : 'openCategory', undefined, PatientDetailsMessagesTranslator);
	const notAssignedDocumentsCategoryLabel = useTranslation('NotAssignedDocumentsCategoryLabel', undefined, PatientDetailsMessagesTranslator);
	const categoryLabel = isNotAssignedDocumentsCategory ? notAssignedDocumentsCategoryLabel : label;
	const onToggleExpand = useMemoFactory(createOnToggleExpand, isExpanded, onCollapse, onExpand, categoryId);
	const buttonStyles = useMemoFactory(getButtonStyles, isExpanded);
	const metricsKey = isExpanded ? 'UnfoldPatientRecordCategory' : 'FoldPatientRecordCategory';
	return (
		<ClickTrackedRippleButton title={toolTip} onClick={onToggleExpand} className='patient-details-category-header--row' sx={buttonStyles} metricsKey={metricsKey}>
			<ContentRow>
				<div className='patient-details-category-header--content'>
					{categoryIndentation !== 0 && <div style={{minWidth: `${categoryIndentation}px`}} />}
					<div className='patient-details-category-header--row--category-info'>
						<PrimaryItemText>
							{categoryLabel}
						</PrimaryItemText>
						<PrimaryItemText>
							{`(${numChildItems})`}
						</PrimaryItemText>
					</div>
					<CenterLayout className='patient-details-category-header--row--expand-button'>
						<FoldIcon isFolded={!isExpanded} />
					</CenterLayout>
				</div>
			</ContentRow>
		</ClickTrackedRippleButton>
	);
}

PatientDetailsCategoryHeader.propTypes = {
	itemsPerRow: PropTypes.number,
	totalTileWidth: PropTypes.number,
	isExpanded: PropTypes.bool,
	label: PropTypes.string,
	numChildItems: PropTypes.number,
	isNotAssignedDocumentsCategory: PropTypes.bool,
	categoryDepth: PropTypes.number,
	onCollapse: PropTypes.func,
	onExpand: PropTypes.func,
	categoryId: PropTypes.number
};

PatientDetailsCategoryHeader.defaultProps = {
	categoryDepth: 0
};

function createOnToggleExpand(isExpanded, onCollapse, onExpand, categoryId) {
	return () => {
		if (isExpanded) {
			onCollapse(categoryId);
		} else {
			onExpand(categoryId);
		}
	};
}

function getButtonStyles(isExpanded) {
	return theme => ({
		...theme.typography.body2,
		width: '100%',
		color: isExpanded ? 'primary.main' : BLACK_COLOR_NAME,
		backgroundColor: 'primary.selection',
		borderBottom: '1px solid white',
		flexWrap: 'nowrap',
		minHeight: 0,
		overflow: 'hidden',
		'&:hover': {
			backgroundColor: 'primary.light'
		}
	});
}
