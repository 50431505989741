import React from 'react';
import PropTypes from 'prop-types';

import {DEVICE_SIZE_LARGE} from '../../commons/constants/DeviceInformation.js';
import SelectionPurgerContainer from '../../commons/containers/SelectionPurgerContainer.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import WebViewMessagesTranslator from '../../i18n/translators/WebViewMessagesTranslator.js';
import ViewerMatrixContainer from '../../viewer/flux/containers/ViewerMatrixContainer.js';
import ViewerStateResetter from '../../viewer/flux/containers/ViewerStateResetter.js';
import InitialViewerItemLoader from '../flux/containers/InitialViewerItemLoader.js';
import PatientDetailsContainer from '../flux/containers/PatientDetailsContainer.js';
import PatientDetailsDataGuard from '../flux/containers/PatientDetailsDataGuard.js';
import PatientDetailsLoadErrorCardContainer from '../flux/containers/PatientDetailsLoadErrorCardContainer.js';

import '../../../styles/patient-details/components/PatientDetailsPageBody.scss';

const NON_DIAGNOSTIC_USAGE_NOTE = (
	<SynFormattedMessage className='non-diagnostic-usage-note' translator={WebViewMessagesTranslator}
	                     message='NonDiagnosticPrintMessage' />
);

export default function PatientDetailsPageBody(props) {
	const {deviceSize, showViewer, showPrintLayout, errorComponent} = props;

	const isMobile = deviceSize !== DEVICE_SIZE_LARGE;
	const bodyClassName = `patient-details-page-body${isMobile ? '--mobile' : '--desktop'}`;
	const additionalClassName = (showViewer || showPrintLayout) ? 'show-viewer' : 'show-details';

	return (
		<SelectionPurgerContainer>
			<PatientDetailsDataGuard errorComponent={errorComponent}>
				<div className={`${bodyClassName} ${additionalClassName}`}>
					<PatientDetailsContainer key='patientDetails' className='patient-details' />
					<ViewerMatrixContainer key='viewerMatrix' className='viewer-matrix' />
					{showPrintLayout && NON_DIAGNOSTIC_USAGE_NOTE}
				</div>
				<ViewerStateResetter />
				<InitialViewerItemLoader />
			</PatientDetailsDataGuard>
		</SelectionPurgerContainer>
	);
}

PatientDetailsPageBody.propTypes = {
	deviceSize: PropTypes.string,
	showViewer: PropTypes.bool,
	showPrintLayout: PropTypes.bool,
	errorComponent: PropTypes.elementType
};

PatientDetailsPageBody.defaultProps = {
	errorComponent: PatientDetailsLoadErrorCardContainer
};
