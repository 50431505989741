import {vec2} from 'gl-matrix';

import {round} from '../MathUtils.js';

const PRECISION = 6;

export function toCssTransformMatrix(mat3) {
	return `matrix(${round(mat3[0], PRECISION)}, ${round(mat3[3], PRECISION)}, ${round(mat3[2], PRECISION)}, ${round(mat3[4], PRECISION)}, ${round(mat3[6], PRECISION)}, ${round(mat3[7], PRECISION)})`;
}

export function mat3ToMat4(out, mat3) {
	out[0] = mat3[0];
	out[1] = mat3[1];
	out[2] = 0;
	out[3] = 0;

	out[4] = mat3[3];
	out[5] = mat3[4];
	out[6] = 0;
	out[7] = 0;

	out[8] = 0;
	out[9] = 0;
	out[10] = 1;
	out[11] = 0;

	out[12] = mat3[6];
	out[13] = mat3[7];
	out[14] = 0;
	out[15] = 1;
	return out;
}

export function getZoom(mat3) {
	return mat3[0];
}

export function getPanX(mat3) {
	return mat3[6];
}

export function getPanY(mat3) {
	return mat3[7];
}

export function getScaling(mat3) {
	return vec2.fromValues(mat3[0], mat3[4]);
}
