import {FEATURE_DOCUMENT_PERMITS_ENABLED} from '../../../webpatient/constants/WebPatientSettingsContants.js';
import {
	parsePatientFileDisplayConfig,
	parseSeriesSortRegex
} from '../../patient-details/data/PatientDetailsDataUtils.js';
import {isReleaseVersion, parseVersionString} from '../utils/VersionUtils.js';
import {WEBAPP_NAME} from './EnvironmentConstants';

const DEFAULT_ENGLISH_LANGUAGE_REGION = '001';
export const DEFAULT_ENGLISH_LOCALE = `en-${DEFAULT_ENGLISH_LANGUAGE_REGION}`;
export const SUPPORTED_LANGUAGES = {
	de: 'de',
	en: DEFAULT_ENGLISH_LOCALE,
	fr: 'fr'
};

export const LANGUAGE_TAG = SUPPORTED_LANGUAGES[getSynSetting('languageTag', 'en')] || 'en';
export const FEATURE_INBOX_ENABLED = getSynSetting('enableInbox', true);
export const FEATURE_SEARCH_ENABLED = getSynSetting('enableDocumentSearch');
export const FEATURE_ORDER_CREATION_ENABLED = getSynSetting('enableOrderCreation');
export const FEATURE_DOCUMENT_UPLOAD_ENABLED = getSynSetting('enableArchiveDocuments');
export const FEATURE_PASSWORD_RESET_ENABLED = getSynSetting('enablePasswordReset');
export const FEATURE_INTEGRATED_PDF_VIEWER_ENABLED = getSynSetting('enableIntegratedPdfViewer');
export const FEATURE_BARCODE_SCANNER_ENABLED = getSynSetting('enableBarcodeScanner');
export const ALLOWED_BARCODE_TYPES = getSynSetting('allowedBarcodeTypes', []) || [];
export const PRODUCT_VERSION = parseVersionString(getSynSetting('version', '0.0.0.0'));
export const IS_RELEASE_BUILD = isReleaseVersion(PRODUCT_VERSION);
export const ENFORCE_TWO_FACTOR_LOGIN = getSynSetting('enforceTwoFactorLogin');
export const USE_LOSSLESS_IMAGE_COMPRESSION = getSynSetting('useLosslessImageCompression');
export const SHOW_PATIENT_ID_FORM_FIELD = getSynSetting('enablePatientIdSearchField');
export const FEATURE_TRACKING_ENABLED = getSynSetting('enableFeatureTracking');
export const FEATURE_CREATE_SHARE_RECEIVER_ENABLED = getSynSetting('enableCreateShareReceiver');
export const WEB_PATIENT_URL = getSynSetting('webPatientURL');
export const ALLOWED_UPLOAD_FILE_EXTENSIONS = (getSynSetting('allowedUploadFileExtensions', [])) || [];
export const ALLOWED_UPLOAD_FILE_FILE_SIZE = (getSynSetting('maxUploadFileSizeMB', null)) || null;
export const SESSION_KEY_REQUIRED = getSynSetting('sessionKeyRequired');
export const EMBEDDED_MODE = getSynSetting('enableEmbeddedMode', false);
export const PATIENT_FILE_DISPLAY = parsePatientFileDisplayConfig(getSynSetting('patientFileDisplay', []), /\|/) || [];
export const SERIES_SORT_REGEX = parseSeriesSortRegex(getSynSetting('seriesSortRegex', '.*'), '~~');

export const FEATURE_DOCUMENT_SHARING_ENABLED = getSynSetting('enableShareDocuments') ||
	WEBAPP_NAME === 'webpatient' && FEATURE_DOCUMENT_PERMITS_ENABLED;

function getSynSetting(settingName, fallBack = false) {
	const settingDefined = typeof SynSettings !== 'undefined' && settingName in SynSettings;
	return settingDefined ? SynSettings[settingName] : fallBack;
}
