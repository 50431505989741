// http://mcg.mbitson.com/#!?tuerkis=%23008b95&orange=%23dc5034&themename=Kieselalge
// https://drive.google.com/drive/folders/1JyVGbk5E_2M2vBvqHUk0helfqqqr-vtc
// Syn Orange can be reproduced using that tool. Generation of Syn Turquoise is unclear.

export const SYN_TURQUOISE = {
	50: '#d7f2f4',
	100: '#b5e7ea',
	200: '#80d4d9',
	300: '#4ab4bb',
	400: '#2c9fa7',
	500: '#008b95',
	600: '#006e76',
	700: '#00545a',
	800: '#004247',
	900: '#003033',
	A100: '#b5e7ea',
	A200: '#80d4d9',
	A400: '#2c9fa7',
	A700: '#00545a'
};

export const SYN_TURQUOISE_TEXT = {
	50: 'black',
	100: 'black',
	200: 'black',
	300: 'black',
	400: 'black',
	500: 'white',
	600: 'white',
	700: 'white',
	800: 'white',
	900: 'white',
	A100: 'black',
	A200: 'black',
	A400: 'black',
	A700: 'black'
};

export const SYN_ORANGE = {
	50: '#ffffff',
	100: '#f8dbd5',
	200: '#efb1a5',
	300: '#e57d68',
	400: '#e0664e',
	500: '#dc5034',
	600: '#ce4024',
	700: '#b4381f',
	800: '#9a301b',
	900: '#802816',
	A100: '#ffffff',
	A200: '#f8dbd5',
	A400: '#e0664e',
	A700: '#b4381f'
};

export const SYN_ORANGE_TEXT = {
	50: 'black',
	100: 'black',
	200: 'black',
	300: 'black',
	400: 'black',
	500: 'white',
	600: 'white',
	700: 'white',
	800: 'white',
	900: 'white',
	A100: 'black',
	A200: 'black',
	A300: 'white',
	A400: 'white'
};

export const VIEWER_PAPER_COLOR = 'rgba(51, 51, 51, 0.9)';
export const TEXT_SECONDARY_COLOR = 'rgba(0, 0, 0, 0.54)';
export const BACKGROUND_PALETTE_NAME = 'background';
export const WHITE_COLOR_NAME = 'common.white';
export const BLACK_COLOR_NAME = 'common.black';
export const VIEWER_TOOLBAR_BACKGROUND_COLOR_NAME = 'viewerToolbar';
export const VIEWER_TOOLBAR_BACKGROUND_COLOR = 'rgba(153, 153, 153, 0.3)';
export const VIEWER_PAPER_HOVER_COLOR = 'rgba(102, 102, 102, 0.5)';
