import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import GeneralMessagesTranslator from '../translators/GeneralTranslator.js';
import SynFormattedMessage from './SynFormattedMessage.js';

export default function ShortDateTime(props) {
	const {date, ...remainingProps} = props;
	const messageParams = useMemo(() => ({date, time: date}), [date]);
	return (
		<SynFormattedMessage message='ShortDateTime' messageParams={messageParams} translator={GeneralMessagesTranslator}
		                     {...remainingProps} />
	);
}

ShortDateTime.propTypes = {
	date: PropTypes.instanceOf(Date)
};
