import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import {INVALIDATE_CACHE} from '../constants/CacheActionTypes.js';

const INITIAL_REDUCER_STATE = Immutable.Map({
	valid: true
});

export default handleActions({
	[INVALIDATE_CACHE]: invalidateCache
}, INITIAL_REDUCER_STATE);

export function getInitialState() {
	return INITIAL_REDUCER_STATE;
}

/**
 * @param state the current state
 * @returns the state containing the invalidated cache
 */
function invalidateCache(state) {
	return state.set('valid', false);
}
