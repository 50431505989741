import React from 'react';
import {connect} from 'react-redux';

import {getHocDisplayName, withForwardRef} from '../utils/ReactUtils.js';

export default function connectWithInstanceSelector(
		createMapStateToProps, mapDispatchToProps = undefined, mergeProps = undefined
) {
	const connector = connect(selectorSelector, mapDispatchToProps, mergeProps, {forwardRef: true});

	return function createConnectedComponent(Component) {
		return createWrapperComponent(createMapStateToProps, connector(Component));
	};
}

function createWrapperComponent(createMapStateToProps, WrappedComponent) {
	class WrapperComponent extends React.PureComponent {
		render() {
			const {forwardedRef, ...remainingProps} = this.props;
			return (
				<WrappedComponent ref={forwardedRef} {...remainingProps} selector={createMapStateToProps(this.props)} />
			);
		}
	}

	WrapperComponent.propTypes = {forwardedRef: withForwardRef.PropTypes.Ref};
	WrapperComponent.displayName = getHocDisplayName(WrappedComponent, 'connectWithInstanceSelector', 'createWrapperComponent');

	return withForwardRef(WrapperComponent, 'forwardedRef');
}

function selectorSelector(state, props) {
	return props.selector(state, props);
}
