import React from 'react';

import {createBackgroundIndicator} from '../../commons/components/BackgroundIndicator.js';
import createLayoutComponent from '../../commons/components/layout/createLayoutComponent.js';
import ViewerToolbarNotchInsetContainer from '../flux/containers/ViewerToolbarNotchInsetContainer.js';

import '../../../styles/viewer/components/ViewerToolbar.scss';

const StyledToolbar = createBackgroundIndicator(createLayoutComponent('viewer-toolbar'), {color: 'rgba(0,0,0,0.5)'});

function ViewerToolBar(props) {
	return (
		<React.Fragment>
			<StyledToolbar {...props} />
			<ViewerToolbarNotchInsetContainer />
		</React.Fragment>
	);
}

export default React.memo(ViewerToolBar);
