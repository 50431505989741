import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {clearErrors} from '../actions/UnhandledErrorsActions.js';
import UnhandledErrorsDialog from '../components/errors/UnhandledErrorsDialog.js';
import {IS_DEBUG_BUILD} from '../constants/EnvironmentConstants.js';
import {getUnhandledErrors} from '../selectors/UnhandledErrorsSelectors.js';
import {bindActions} from '../utils/FluxUtils.js';

const STATE_PROPS_SELECTOR = createStructuredSelector({
	unhandledErrors: getUnhandledErrors
});

export default connect(
	STATE_PROPS_SELECTOR,
	IS_DEBUG_BUILD ? bindActions({clearErrors}) : undefined,
	IS_DEBUG_BUILD ? debugPropsMergeFunction : releasePropsMergeFunction
)(UnhandledErrorsDialog);

function debugPropsMergeFunction(stateProps, dispatchProps, props) {
	return {
		...stateProps,
		...props,
		onClose: () => dispatchProps.clearErrors()
	};
}

function releasePropsMergeFunction(stateProps, dispatchProps, props) {
	return {
		...stateProps,
		...props,
		onClose: () => {
			window.location.href = '/';
		}
	};
}
