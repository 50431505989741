import {default as createOperation} from '../../commons/api/operations/create.js';
import {noop} from '../../commons/utils/FunctionUtils.js';

const USER_TOKEN_BASE_URL = '/usertoken';
const TOKEN_PURPOSE_CHANGEPASSWORD = 'changepassword';
const TOKEN_PURPOSE_SECONDFACTOR = 'secondfactor';

/**
 * Requests a password reset token from the server.
 * The specified user will be sent an email with information about how to reset his password.
 *
 * @param username {String} username to reset the password for
 * @returns {Promise}
 */
export function requestPasswordResetToken(username) {
	return createUserToken(username, TOKEN_PURPOSE_CHANGEPASSWORD);
}

/**
 * Requests a second factor token from the server.
 * The specified user will be notified about the token via EMail or SMS  (depending on the servers configuration).
 *
 * @param username {String} username to reset the password for
 * @returns {Promise}
 */
export function requestSecondFactorToken(username) {
	return createUserToken(username, TOKEN_PURPOSE_SECONDFACTOR);
}

function createUserToken(username, purpose) {
	const payload = {username, purpose};
	return createOperation(USER_TOKEN_BASE_URL, payload, noop);
}
