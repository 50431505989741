import React from 'react';
import MUILoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';

import Button from './Button.js';

export default function LoadingButton(props) {
	const {loading, loadingPosition, ...remainingProps} = props;
	return (
		<Button {...remainingProps} loading={loading} loadingPosition={loadingPosition} component={MUILoadingButton} />
	);
}

LoadingButton.propTypes = {
	...Button.propTypes,
	loadingPosition: PropTypes.oneOf(['start', 'end', 'center']),
	loading: PropTypes.bool
};

LoadingButton.defaultProps = {
	...Button.defaultProps,
	loadingPosition: 'start'
};
