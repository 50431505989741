import {composeWithDevTools} from '@redux-devtools/extension';
import {applyMiddleware, combineReducers, createStore} from 'redux';
import {enableBatching} from 'redux-batched-actions';
import promiseMiddleware from 'redux-promise';
import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import createMetricsMiddleware from '../../metrics-collector/flux/createMetricsMiddleware.js';
import {pushEntry} from '../../metrics-collector/MetricsStore.js';
import {IS_DEBUG_BUILD} from '../constants/EnvironmentConstants.js';
import {actionSanitizer, stateSanitizer} from './reduxDevToolsSanitizers.js';


/** Creates a redux instance that is configured to support redux-promise and
 * action counting via the middleware counterMiddleware.
 *
 * Use otherwise as the createRedux factory.
 * @param reducers {object} like { reducer1: reducerFunction, reducer2: ... } the reducers to pass actions to.
 * @param rootSaga {function} an optional root saga to handle side effects using redux-saga
 * @param allowedMetrics {AllowedMetricsSet} an instance of AllowedMetricsSet containing
 * all allowed metrics for the app.
 * @returns {Function} representing the main dispatcher for actions (return value of createRedux)
 */
export function createSynRedux(reducers, rootSaga = null, allowedMetrics = null) {
	const sagaMiddleware = createSagaMiddleware();
	const metricsMiddleware = createMetricsMiddleware(pushEntry, allowedMetrics);
	let createStoreWithMiddleware = applyMiddleware(
		sagaMiddleware, thunkMiddleware, promiseMiddleware, metricsMiddleware
	);
	if (IS_DEBUG_BUILD) {
		const composeEnhancers = composeWithDevTools({actionSanitizer, stateSanitizer});
		createStoreWithMiddleware = composeEnhancers(createStoreWithMiddleware);
	}
	createStoreWithMiddleware = createStoreWithMiddleware(createStore);

	const applicationReducers = enableBatching(combineReducers(reducers));
	const store = createStoreWithMiddleware(applicationReducers);

	if (rootSaga !== null) {
		sagaMiddleware.run(rootSaga);
	}

	return store;
}
