import {createMultiFieldValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';

export function equalsValidatorFunction(locale, translatedFieldLabels, translator, message, fieldValues) {
	return fieldValues.every(value => value === fieldValues[0]) ||
		translator.getFormattedMessage(message, locale,
			{inputFieldLabels: translator.toListing(translatedFieldLabels.slice(1), 'And', locale)});
}

export default function equalsValidator(fields, translator = ValidatorsMessagesTranslator, message = 'Equals') {
	return createMultiFieldValidatorFactory(
		(locale, translatedFieldLabels, ...fieldValues) => equalsValidatorFunction(
			locale, translatedFieldLabels, translator, message, fieldValues
		)
	)(fields);
}
