import {connect} from 'react-redux';

import MenuButton from '../../ui/components/MenuButton.js';
import {toggleSidebar} from '../../ui/flux/UIActions.js';
import {uiSidebarModeElevatedSelector} from '../../ui/flux/UISelectors.js';
import {bindActions} from '../utils/FluxUtils.js';

export default connect(
	state => ({
		visible: uiSidebarModeElevatedSelector(state)
	}),
	bindActions({
		onClick: toggleSidebar
	})
)(MenuButton);
