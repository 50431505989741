import React, {useMemo} from 'react';

import JustChildren from '../../../commons/components/JustChildren.js';
import AutoComplete, {autocompleteClasses} from '../AutoComplete.js';
import VerticalLayout from '../layout/VerticalLayout.js';
import DefaultAutoCompleteTextField from './DefaultAutoCompleteTextField.js';
import ExpandingVirtualizedOptionsList from './ExpandingVirtualizedOptionsList.js';
import withConfirmButton from './withConfirmButton.js';

const WRAPPER_STYLES = {
	[`& .${autocompleteClasses.listbox}`]: {
		maxHeight: 'initial',
		p: 0
	}
};

export default function FullScreenAutoComplete(props) {
	const {lockToOptions, inputTextField = DefaultAutoCompleteTextField} = props;
	const finalInputTextField = useMemo(
		() => (lockToOptions ? inputTextField : withConfirmButton(inputTextField)),
		[lockToOptions, inputTextField]
	);
	return (
		<VerticalLayout justify='end' sx={WRAPPER_STYLES}>
			<AutoComplete {...props} sx={{px: 1, order: 1}} forcePopupIcon={false} paperComponent={JustChildren}
			              autoFocus popperComponent={JustChildren} listComponent={ExpandingVirtualizedOptionsList} open
			              noFullWidth noMargin={!lockToOptions} inputTextField={finalInputTextField} />
		</VerticalLayout>
	);
}

FullScreenAutoComplete.propTypes = AutoComplete.propTypes;
FullScreenAutoComplete.defaultProps = AutoComplete.defaultProps;
