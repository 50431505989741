import {areWebGLRequirementsMet} from '../../viewer/utils/WebGLUtils.js';
import {createMetricsEntry, pushEntry} from '../MetricsStore.js';

function deviceMetricsAlreadyCollected() {
	return Boolean(window.localStorage.getItem('deviceMetricsCollected'));
}

function rememberDeviceMetricsCollected() {
	window.localStorage.setItem('deviceMetricsCollected', 'true');
}

export function collectDeviceMetrics() {
	if (!deviceMetricsAlreadyCollected()) {
		const webGLRequirementsMet = areWebGLRequirementsMet();
		pushEntry(createMetricsEntry(webGLRequirementsMet ? 'WebGLSupported' : 'WebGLNotSupported', 'info'));
		rememberDeviceMetricsCollected();
	}
}
