import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import AnnotationCreationMenuEntryContainer from '../flux/containers/AnnotationCreationMenuEntryContainer.js';
import {
	getAnnotationIcon,
	getAnnotationLabel,
	getSupportedAnnotationTypes,
	showAnnotationInMenu
} from './annotations.js';

export default function AnnotationMenuEntries({selectViewerItem}) {
	const viewerItem = useSelector(selectViewerItem);
	const annotationTypes = useMemo(() => getSupportedAnnotationTypes(viewerItem), [viewerItem]);

	return annotationTypes.filter(showAnnotationInMenu).map(annotationType => {
		const AnnotationLabel = getAnnotationLabel(annotationType);
		const AnnotationIcon = getAnnotationIcon(annotationType);
		const itemId = viewerItem && viewerItem.get('id');
		const itemType = viewerItem && viewerItem.get('type');

		return (<AnnotationCreationMenuEntryContainer key={annotationType}
													 label={AnnotationLabel}
													 icon={AnnotationIcon}
													 itemId={itemId}
													 itemType={itemType}
													 annotationType={annotationType} />);
	});
}
