import React from 'react';
import {func, instanceOf} from 'prop-types';

import DicomImage, {EMPTY_DICOM_IMAGE} from '../../data/DicomImage.js';
import DicomImageDataGuard from '../../flux/containers/DicomImageDataGuard.js';
import DicomImageMetaDataOverlayContainer from '../../flux/containers/DicomImageMetaDataOverlayContainer.js';
import ImageViewerContainer from '../../flux/containers/ImageViewerContainer.js';
import {decodeDicomImage, releaseDicomImage} from '../../utils/decodeDicomImage.js';
import {getEmptyImage} from '../../utils/ImageViewerUtils.js';
import ViewerItemLoadErrorGuard from '../ViewerItemLoadErrorGuard.js';
import wrapInImageDecoder from '../wrapInImageDecoder.js';
import DicomImageViewerSVGOverlay from './DicomImageViewerSVGOverlay.js';

class DicomImageViewer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.renderOverlays = this.renderOverlays.bind(this);
	}

	render() {
		const {decodedImage} = this.props;
		const {rawImageData, isDecoded} = decodedImage;
		const {onTapLeft, onTapRight} = this.props;
		const displayedImage = isDecoded ? rawImageData : getEmptyImage();
		return (
			<ViewerItemLoadErrorGuard viewerItem={decodedImage} itemType='image'>
				<ImageViewerContainer className='dicom-series-viewer'
												overlayRenderer={this.renderOverlays}
												decodedImage={displayedImage}
												onTapLeft={onTapLeft}
												onTapRight={onTapRight} />
			</ViewerItemLoadErrorGuard>
		);
	}

	renderOverlays(transformationMatrix, containerSize) {
		const {decodedImage} = this.props;
		return (
			<DicomImageDataGuard dicomImage={decodedImage}>
				<DicomImageViewerSVGOverlay dicomImage={decodedImage} transformationMatrix={transformationMatrix}
				                            {...containerSize} />
				{/* NOTE: Always render MetaDataOverlay as last element, to stay above all other overlays */}
				<DicomImageMetaDataOverlayContainer dicomImage={decodedImage} {...containerSize} />
			</DicomImageDataGuard>
		);
	}
}

DicomImageViewer.propTypes = {
	decodedImage: instanceOf(DicomImage).isRequired,
	onTapLeft: func,
	onTapRight: func
};

export default wrapInImageDecoder(
	DicomImageViewer, decodeDicomImage, releaseDicomImage, EMPTY_DICOM_IMAGE, 'dicomImage'
);
