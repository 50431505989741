import React from 'react';
import PropTypes from 'prop-types';

import {memoWithName} from '../../../commons/utils/ReactUtils.js';

export default memoWithName(Section);

function Section(props) {
	const {title, className, children} = props;
	return (
		<section className={className} aria-label={title}>
			{children}
		</section>
	);
}

Section.propTypes = {
	title: PropTypes.string,
	className: PropTypes.string
};
