import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import LoadingIndicator from '../../commons/components/webview/LoadingIndicator.js';
import IntermediatePageContainer from '../../commons/containers/webview/IntermediatePageContainer.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import PatientDetailsLoadErrorCardContainer from '../flux/containers/PatientDetailsLoadErrorCardContainer.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';

export default class PatientDetailsLoaderPage extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.componentIsMounted = false;
	}

	render() {
		const {loadFailed, hasPatientDetails, hasPatientDetailsForQuery} = this.props;
		let pageContent;
		if (loadFailed || this.componentIsMounted && hasPatientDetails && !hasPatientDetailsForQuery) {
			pageContent = this.renderError();
		} else {
			pageContent = PatientDetailsLoaderPage.renderLoading();
		}
		return (
			<IntermediatePageContainer>
				<CenterLayout>
					{pageContent}
				</CenterLayout>
			</IntermediatePageContainer>
		);
	}

	renderError() {
		const {itemType} = this.props;
		return <PatientDetailsLoadErrorCardContainer itemType={itemType} />;
	}

	static renderLoading() {
		return (
			<LoadingIndicator>
				<SynFormattedMessage style={{padding: '10px'}} message='LoadingPatientDetails'
					translator={PatientDetailsMessagesTranslator} />
			</LoadingIndicator>
		);
	}

	componentDidMount() {
		const {
			hasPatientDetailsForQuery, hasPatientDetails, unloadCurrentPatient,
			loadPatientForQuery, redirectToPatientDetails
		} = this.props;

		if (hasPatientDetails) {
			if (hasPatientDetailsForQuery) {
				redirectToPatientDetails();
			} else {
				unloadCurrentPatient();
			}
		} else {
			loadPatientForQuery();
		}
		this.componentIsMounted = true;
	}

	componentDidUpdate() {
		const {
			hasPatientDetails, loadPatientForQuery,
			hasPatientDetailsForQuery, redirectToPatientDetails, loadFailed
		} = this.props;

		if (!hasPatientDetails) {
			if (!loadFailed) {
				loadPatientForQuery();
			}
		} else if (hasPatientDetailsForQuery) {
			redirectToPatientDetails();
		}
	}
}

PatientDetailsLoaderPage.propTypes = {
	itemType: PropTypes.string,
	hasPatientDetails: PropTypes.bool,
	hasPatientDetailsForQuery: PropTypes.bool,
	loadFailed: PropTypes.bool,
	unloadCurrentPatient: PropTypes.func,
	loadPatientForQuery: PropTypes.func,
	redirectToPatientDetails: PropTypes.func
};
