import {vec2} from 'gl-matrix';
import Immutable from 'immutable';

import createStateMachine from '../commons/utils/createStateMachine.js';
import {createEvent} from '../commons/utils/EventUtils.js';

const INITIAL_STATE = Symbol('MOVE_RECOGNIZER_INITIAL_STATE');
const MOVE_IN_PROGRESS = Symbol('MOVE_IN_PROGRESS');

export default function createMoveRecognizer() {
	return createStateMachine(INITIAL_STATE, transitionTo => {
		return {
			[INITIAL_STATE]: function moveRecognizerInitialState(state, event) {
				if (event.get('type') === 'pointerdown') {
					startMove(state, event);
				}
			},
			[MOVE_IN_PROGRESS]: function moveRecognizerMoveInProgress(state, event, next) {
				const eventPointers = event.get('pointers');
				const pointerCountMatches = state.get('pointers').size === eventPointers.size;
				const pointerIdMatches = state.get('pointerIds').every(pointerId => eventPointers.has(pointerId));
				const eventType = event.get('type');

				if (!pointerCountMatches || !pointerIdMatches) {
					if (event.get('pointers').size >= 1) {
						startMove(state, event);
					} else {
						transitionTo(INITIAL_STATE, endMove(state));
					}
				} else if (eventType === 'blur' || eventType === 'pointercancel') {
					transitionTo(INITIAL_STATE, endMove(state));
				} else if (eventType === 'pointerup') {
					transitionTo(INITIAL_STATE, endMove(state));
				} else if (eventType === 'pointermove') {
					transitionTo(MOVE_IN_PROGRESS, tryToEmitMove(state, event, next));
				}
			}
		};

		function endMove(state) {
			return Immutable.Map({pointers: Immutable.List(), id: state.get('id')});
		}

		function startMove(state, event) {
			const pointers = event.get('pointers');
			transitionTo(MOVE_IN_PROGRESS, state.merge({
				pointerIds: pointers.keySeq().toList(),
				pointers,
				start: pointers,
				id: state.get('id') + 1
			}));
		}
	}, Immutable.Map({pointers: Immutable.List(), id: 0}));
}

function tryToEmitMove(state, event, next) {
	const eventPointers = event.get('pointers');
	const previousPointers = state.get('pointers');

	const hasPositionUpdate = eventPointers.some((eventPointer, pointerIdentifier) => {
		const previousPointer = previousPointers.get(pointerIdentifier);
		return previousPointer && vec2.distance(eventPointer, previousPointer) > 0;
	});

	if (hasPositionUpdate) {
		next(createEvent('move', {
			pointerIds: state.get('pointerIds'),
			start: state.get('start'),
			from: state.get('pointers'),
			to: eventPointers,
			moveId: state.get('id')
		}, event));
		return state
			.set('pointers', eventPointers);
	}
	return state;
}
