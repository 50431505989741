import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {closePersistentPortal} from '../actions/PersistentPortalActions.js';
import PersistentPortalsPlaceholder from '../components/PersistentPortalsPlaceholder.js';
import {selectAllPersistentPortals} from '../selectors/PersistentPortalsSelectors.js';
import {bindActions} from '../utils/FluxUtils.js';

export default connect(
	createStructuredSelector({
		portals: selectAllPersistentPortals
	}),
	bindActions({
		closePortal: closePersistentPortal
	})
)(PersistentPortalsPlaceholder);
