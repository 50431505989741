import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import ContentRowsContainer from './layout/ContentRowsContainer.js';

import '../../../styles/material-design/components/List.scss';

export default function List(props) {
	const {className, ...remainingProps} = props;
	const finalClassName = useMemoFactory(combineClassNames, 'material-list', className);
	return <ContentRowsContainer className={finalClassName} {...remainingProps} />;
}

List.propTypes = {
	className: PropTypes.string
};
