import {createAction} from 'redux-actions';

export function createErroneousAction(type, error) {
	return createStandardAction(type, error, true);
}

export function createStandardAction(type, payload, erroneus = false) {
	return erroneus ? {type, payload, error: true} : {type, payload};
}

function createActionWithErrorHandlingPayloadCreator(type, actionCreator, metaCreator) {
	const apiActionCreator = createAction(type, undefined, metaCreator);
	let finalActionCreator = (...args) => {
		const action = apiActionCreator(...args);
		if (metaCreator && action.meta === undefined) {
			delete action.meta;
		}
		return action;
	};

	if (actionCreator) {
		const metaAwareActionCreator = finalActionCreator;
		finalActionCreator = (...payload) => {
			const action = metaAwareActionCreator(...payload);
			const finalPayload = actionCreator(...payload);
			if (finalPayload === undefined) {
				delete action.payload;
			} else {
				action.payload = finalPayload;
			}
			return action;
		};
	}
	return finalActionCreator;
}

export {createActionWithErrorHandlingPayloadCreator as createAction};
