import DicomImage from '../data/DicomImage.js';
import {buildDicomFrameId, getDicomDump, getImageDataType, getLoadError, getRawImageData} from './ViewerItemUtils.js';

export function buildNavigationActions(currentState, seriesImageIndex, updateFrameOffset) {
	const {currentFrameOffset, currentImageOffset} = currentState;
	const setFrameIndex = (idx, activate) => seriesImageIndex.hasFrame(idx) && updateFrameOffset(idx, activate);
	return {
		moveNextFrame: () => setFrameIndex(currentFrameOffset + 1),
		movePreviousFrame: () => setFrameIndex(currentFrameOffset - 1),
		moveNextImage: () => setFrameIndex(seriesImageIndex.frameIndexForImage(currentImageOffset + 1)),
		movePreviousImage: () => setFrameIndex(seriesImageIndex.frameIndexForImage(currentImageOffset - 1)),
		setFrameIndex
	};
}

export function createViewerStateBuilder(seriesId, seriesImageIndex) {
	const {numberOfFrames, numberOfImages} = seriesImageIndex;

	function buildViewerState(currentFrameOffset) {
		if (!seriesImageIndex.hasFrame(currentFrameOffset)) {
			throw new RangeError('Invalid frame offset provided!');
		}
		const currentImageOffset = seriesImageIndex.imageIndexForFrame(currentFrameOffset);
		const imageIndexEntry = seriesImageIndex.getEntry(currentImageOffset);
		const imageId = imageIndexEntry.id;
		const isMultiframeImage = imageIndexEntry.numberOfFrames > 1;
		const firstFrameInImage = imageIndexEntry.firstFrameInSeries;
		const frameNumber = currentFrameOffset - firstFrameInImage;

		return {
			seriesId,
			seriesImageIndex,
			numberOfFrames,
			numberOfImages,
			currentFrameOffset,
			currentImageOffset,
			imageRelativeFrameOffset: isMultiframeImage ? frameNumber : null,
			currentImageId: imageId,
			currentFrameId: buildDicomFrameId(seriesId, imageId, frameNumber),
			canMoveNextImage: seriesImageIndex.hasImage(currentImageOffset + 1),
			canMovePreviousImage: seriesImageIndex.hasImage(currentImageOffset - 1),
			canMoveNextFrame: seriesImageIndex.hasFrame(currentFrameOffset + 1),
			canMovePreviousFrame: seriesImageIndex.hasFrame(currentFrameOffset - 1)
		};
	}
	return {build: buildViewerState};
}

export function buildDicomImage(imageViewerItem, imageDataViewerItem, imageMetadata) {
	const dicomDump = getDicomDump(imageViewerItem);
	const rawImageData = getRawImageData(imageDataViewerItem);
	const imageType = getImageDataType(imageDataViewerItem);
	const loadError = getLoadError(imageViewerItem) || getLoadError(imageDataViewerItem);
	return new DicomImage({
		type: imageType,
		imageId: imageViewerItem.get('id'),
		rawImageData: loadError ? null : rawImageData,
		dicomDump: loadError ? null : dicomDump,
		metadata: loadError ? null : imageMetadata,
		loadError
	});
}

export function buildFrameTaskId(dicomViewerState) {
	const {currentImageOffset, currentFrameOffset} = dicomViewerState;
	return currentFrameOffset + 1 + currentImageOffset;
}

export function buildDicomDumpTaskId(dicomViewerState) {
	const {imageRelativeFrameOffset, currentFrameOffset, currentImageOffset} = dicomViewerState;
	const firstFrameInSeries = currentFrameOffset - imageRelativeFrameOffset;
	return firstFrameInSeries + currentImageOffset;
}

