import React from 'react';

import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import AnnotationIcon from './AnnotationIcon.js';

import '../../../../styles/viewer/components/annotations/SyncPointHandleNumberIcon.scss';

export default function SyncPointHandleNumberIcon(props) {
	const {children} = props;
	const finalProps = cloneWithoutProperties({...props, className: 'sync-point-annotation-number-icon'}, 'children');
	return (
		<AnnotationIcon {...finalProps}>
			<rect x={-6.5} y={-9} width={13} height={18} rx={4} ry={4} />
			<text x={0} y={4}>
				{children}
			</text>
		</AnnotationIcon>
	);
}
