import React, {useMemo} from 'react';
import {mat3} from 'gl-matrix';
import {number, object} from 'prop-types';

import ImageViewerSVGOverlayContainer from '../../flux/containers/ImageViewerSVGOverlayContainer.js';
import LocalizerOverlayContainer from '../../flux/containers/LocalizerOverlayContainer.js';
import LengthScaleOverlay from '../LengthScaleOverlay.js';

function DicomImageViewerSVGOverlay(props) {
	const {
		containerWidth,
		containerHeight,
		transformationMatrix,
		dicomImage
	} = props;
	const {
		annotationItems,
		pixelSpacing,
		downscaleVector
	} = dicomImage;
	const scaledTransformationMatrix = useMemo(
		() => mat3.scale(mat3.create(), transformationMatrix, downscaleVector), [downscaleVector, transformationMatrix]
	);
	return (
		<ImageViewerSVGOverlayContainer annotationItems={annotationItems}
										containerWidth={containerWidth}
										containerHeight={containerHeight}
										transformationMatrix={scaledTransformationMatrix}
										viewerItem={dicomImage}>
			<LengthScaleOverlay pixelSpacing={pixelSpacing} />
			<LocalizerOverlayContainer dicomImage={dicomImage} />
		</ImageViewerSVGOverlayContainer>
	);
}

DicomImageViewerSVGOverlay.propTypes = {
	containerWidth: number.isRequired,
	containerHeight: number.isRequired,
	transformationMatrix: object.isRequired,
	dicomImage: object.isRequired
};

export default React.memo(DicomImageViewerSVGOverlay);
