import {createAction} from 'redux-actions';
import {batchActions} from 'redux-batched-actions';

import {selectNonEmptyViewerIds} from '../../flux/selectors/ViewerSelectors.js';
import {validateViewerId} from '../../utils/ViewerUtils.js';
import {
	ACTIVATE_SYNC_TOOL,
	DEACTIVATE_SYNC_TOOL, DISCONNECT_VIEWER,
	MERGE_TOOL_PROPERTIES,
	SYNC_VIEWERS
} from '../constants/ViewerSyncActionTypes.js';
import {selectSyncedViewerIds} from './ViewerSyncSelectors.js';

const syncViewersAction = createAction(SYNC_VIEWERS);
export function syncViewers(viewerA, viewerB) {
	const validatedViewerA = validateViewerId(viewerA);
	const validatedViewerB = validateViewerId(viewerB);
	return syncViewersAction({
		viewerA: validatedViewerA,
		viewerB: validatedViewerB
	});
}

export const disconnectViewerAction = createAction(DISCONNECT_VIEWER);
export function disconnectViewer(viewerId) {
	const validatedViewerId = validateViewerId(viewerId);
	return disconnectViewerAction(validatedViewerId);
}

const mergeSyncToolPropertiesAction = createAction(MERGE_TOOL_PROPERTIES);
export function mergeSyncToolProperties(toolId, viewerId, partialProperties) {
	return mergeSyncToolPropertiesAction({
		toolId,
		viewerId,
		partialProperties: partialProperties || {}
	});
}

/**
 * Disconnects the desired viewers from thier current sync chains and adds them all into a new one.
 *
 * @param viewerIDsToSync - optional Parameter of viewerIDs. If left out all non empty viewers will be synced.
 * @returns {(function(*, *): void)|*}
 */
export function syncAllViewers(viewerIDsToSync) {
	return function syncAllViewersThunk(dispatch, getState) {
		const viewersToSync = viewerIDsToSync || selectNonEmptyViewerIds(getState());
		const updateActions = [];
		disconnectAllViewersHelper(getState(), updateActions, viewersToSync);
		let previousViewer = null;
		viewersToSync.forEach(viewerId => {
			if (previousViewer !== null) {
				updateActions.push(syncViewers(previousViewer, viewerId));
			}
			previousViewer = viewerId;
		});
		dispatch(batchActions(updateActions));
	};
}

/**
 * Disconnects all (specified) viewers from their sync chains.
 *
 * @param viewerIDsToDisconnect - (optional) the list of viewer IDs to disconnect.
 * @returns {(function(*, *): void)|*}
 */
export function disconnectAllSyncedViewers(viewerIDsToDisconnect) {
	return (dispatch, getState) => {
		const updateActions = [];
		disconnectAllViewersHelper(getState(), updateActions, viewerIDsToDisconnect);
		dispatch(batchActions(updateActions));
	};
}

const activateSyncToolAction = createAction(ACTIVATE_SYNC_TOOL);
export function activateSyncTool(tool) {
	return activateSyncToolAction(tool);
}

const deactivateSyncToolAction = createAction(DEACTIVATE_SYNC_TOOL);
export function deactivateSyncTool(tool) {
	return deactivateSyncToolAction(tool);
}

function disconnectAllViewersHelper(state, updateActions, viewerIDs) {
	const synchronizedViewerIds = viewerIDs || selectSyncedViewerIds(state);
	synchronizedViewerIds.forEach(viewerId => updateActions.push(disconnectViewer(viewerId)));
}
