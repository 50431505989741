import React, {useCallback, useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {number} from 'prop-types';
import {createSelector} from 'reselect';

import GenericImageViewer from '../../components/GenericImageViewer.js';
import ViewerContext from '../../components/ViewerContext.js';
import {getRawImage} from '../../utils/ViewerItemUtils.js';
import {loadGenericFileImage} from '../actions/ViewerItemsActions.js';
import {selectGenericFileViewerItem} from '../selectors/ViewerItemsSelectors.js';

function createStateSelector(fileId) {
	const selectFileViewerItem = selectGenericFileViewerItem(fileId, null);
	return createSelector(
		selectFileViewerItem,
		viewerItem => ({
			hasDownloadStarted: viewerItem ? viewerItem.get('hasDownloadStarted', false) : false,
			rawImage: getRawImage(viewerItem),
			fileViewerItem: viewerItem
		})
	);
}

export default function GenericImageViewerContainer({fileId}) {
	const {isPrintPreview, activate} = useContext(ViewerContext);
	const dispatch = useDispatch();
	const stateSelector = useMemo(() => createStateSelector(fileId), [fileId]);
	const stateProps = useSelector(stateSelector);
	const loadViewerItem = useCallback(() => dispatch(loadGenericFileImage(fileId)), [fileId, dispatch]);

	return (<GenericImageViewer fileId={fileId}
							   loadViewerItem={loadViewerItem}
							   activateViewer={activate}
							   renderToolbar={!isPrintPreview}
							   {...stateProps} />);
}

GenericImageViewerContainer.propTypes = {
	fileId: number
};
