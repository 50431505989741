import React from 'react';
import PropTypes from 'prop-types';

import {useEffectEasily, useMemoFactory} from '../../commons/utils/customHooks';
import {noop} from '../../commons/utils/FunctionUtils.js';
import autoRegister from '../autoRegister.js';
import useBrickRegistry from '../hooks/useBrickRegistry.js';

export default React.memo(AutoBrickFactory);

function AutoBrickFactory(props) {
	const {children, brickFactory, brickType, additionalDependencies = []} = props;
	const brickRegistry = useBrickRegistry();
	const memoizedAdditionalDependencies = useMemoFactory(createMemoizedDependencies, ...additionalDependencies);
	const shutdownBrick = useMemoFactory(guardedAutoRegister,
		brickRegistry, brickType, brickFactory, memoizedAdditionalDependencies
	);
	useEffectEasily(shutdownEffect, shutdownBrick);
	return children || false;
}

AutoBrickFactory.propTypes = {
	brickType: PropTypes.func.isRequired,
	brickFactory: PropTypes.func,
	additionalDependencies: PropTypes.arrayOf(PropTypes.func)
};
AutoBrickFactory.defaulProps = {
	additionalDependencies: []
};

function createMemoizedDependencies(...dependencies) {
	return dependencies;
}

function shutdownEffect(shutdownBrick) {
	return shutdownBrick;
}

function guardedAutoRegister(brickRegistry, brickType, brickFactory, additonalDependencies) {
	if (brickRegistry && brickType && additonalDependencies) {
		return autoRegister(brickRegistry, brickType, brickFactory, additonalDependencies);
	}
	return noop;
}
