import React from 'react';
import {connect} from 'react-redux';

import {showPrivacyPolicyDialog} from '../../ui/flux/UIActions.js';
import SynIFrame from '../components/SynIFrame.js';
import {bindActions} from '../utils/FluxUtils.js';

export default connect(
	undefined,
	bindActions({
		showPrivacyPolicyDialog
	})
)(SynIFrame);
