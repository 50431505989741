import {useContext} from 'react';

import {useMemoFactory} from '../../commons/utils/customHooks';
import LocalizationContext from '../components/LocalizationContext.js';
import {TranslationContext} from '../components/TranslationContext.js';

/**
 * Hook to easily use a translator in functional components.
 *
 * @param translator - (optional) the translator to use. The contextual translator will be used if omitted.
 * @param locale - (optional) the locale to use. The contextual locale will be used if omitted.
 * @returns {*}
 */
export default function useTranslator(translator, locale) {
	const contextLocale = useContext(LocalizationContext);
	const contextTranslator = useContext(TranslationContext);
	const finalLocale = locale || contextLocale;
	const finalTranslator = translator || contextTranslator;
	return useMemoFactory(createGetFormattedMessage, finalTranslator, finalLocale);
}

function createGetFormattedMessage(translator, locale) {
	return (message, ...args) => translator.getFormattedMessage(message, locale, ...args);
}
