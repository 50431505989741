import Immutable from 'immutable';

import GeneralMessagesTranslator from '../../../i18n/translators/GeneralTranslator.js';
import {NO_BREAK_SPACE} from '../../constants/UnicodeCharacterConstants.js';

export function getDocuments(patient) {
	return (patient && patient.get('documents', Immutable.List())) || Immutable.List();
}

export function formatPatient(patientMap) {
	return patientMap
		? formatPatientInfo(patientMap.get('sex_fk'), patientMap.get('last_name', '-'), patientMap.get('first_name', '-'))
		: formatPatientInfo();
}

export function formatPatientInfo(sex, lastName = '-', firstName = '-') {
	const nameInfo = `${lastName}, ${firstName}`;
	return sex
		? `${nameInfo + NO_BREAK_SPACE}[${sex}]`
		: nameInfo;
}

export function formatBirthDate(birthDate, dateFormat, locale) {
	return GeneralMessagesTranslator.getFormattedMessage(dateFormat, locale, {birthDate});
}

export function getFirstDocumentId(documents) {
	return (documents && documents.size > 0 ? documents.first().get('id') : null);
}

