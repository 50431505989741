import {StatusCodes} from 'http-status-codes';

import {createJSONRequest, getContentLocation, handleUnexpectedResponse} from '../../utils/AjaxUtils.js';
import {noop} from '../../utils/FunctionUtils.js';

/**
 * Executes a create request to a given url with a provided json body and retrieves information about the created
 * entity from the content location.
 *
 * @param {String} url the url to send the post request to
 * @param {Function} urlVariablesExtractionFunction a function extracting information about the created entity from the
 *    url provided by the content location.
 * @param {Object} jsonBody the payload which will be transmitted in the body of the post request
 * @returns {Promise} a promise representing extracted information of the content location.
 */
export default async function create(url, jsonBody, urlVariablesExtractionFunction = noop) {
	const response = await rawCreate(url, jsonBody);
	return urlVariablesExtractionFunction(getContentLocation(response));
}

/**
 * Executes a create request to given url with a provided json body and returns the body of the response as parsed json.
 *
 * @param {String} url  - the url to send the post request to.
 * @param {Object} jsonBody - the payload which will be transmitted in the body of the post request.
 * @return {Promise<Object>} - the parsed json body of the response.
 */
export async function createWithStructuredResponse(url, jsonBody) {
	const response = await rawCreate(url, jsonBody);
	return response.json();
}

function rawCreate(url, jsonBody) {
	return fetch(url, createJSONRequest(jsonBody, 'POST'))
		.then(handleUnexpectedResponse(StatusCodes.CREATED, `Entity could not be created for ${url}`));
}
