import React from 'react';
import PropTypes from 'prop-types';

// https://www.w3.org/TR/wai-aria-practices-1.1/#TreeView
// https://www.w3.org/TR/wai-aria-1.1/#tree

function TreeView(props) {
	const {ariaLabel, className, children} = props;
	return (
		<ul role='tree' aria-label={ariaLabel} className={className}>
			{children}
		</ul>
	);
}

TreeView.propTypes = {
	ariaLabel: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default React.memo(TreeView);
