import React from 'react';
import PropTypes from 'prop-types';

import OrgunitComboBox from '../../commons/components/OrgunitComboBox';
import {useMemoFactory} from '../../commons/utils/customHooks';
import {immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe} from '../../commons/utils/FunctionUtils';
import useTranslation from '../../i18n/hooks/useTranslation.js';

function ProducerField(props) {
	const {archivePermissions, producerChoice, onChange, hintMessage, fieldLabel, noSelectionErrorMessage} = props;

	const producerFieldLabel = useTranslation('Producer');
	const noProducerErrorMessage = useTranslation('NoProducerErrorMessage');
	const errorMessage = noSelectionErrorMessage ? noSelectionErrorMessage : noProducerErrorMessage;

	const options = useMemoFactory(getOptions, archivePermissions);
	const onChangeImpl = useMemoFactory(createOnChangeImpl, options, onChange);
	const noSelection = typeof producerChoice !== 'number';

	return (
		<OrgunitComboBox id='archive-document-producer-auto-select' onChange={onChangeImpl} orgUnits={options}
		                 error={noSelection} label={fieldLabel ? fieldLabel : producerFieldLabel}
		                 hint={noSelection ? errorMessage : hintMessage}
		                 value={noSelection ? null : options[producerChoice]} />
	);
}

function createOnChangeImpl(options, onChange) {
	return function onChangeImpl(newValue) {
		let optionsEntryKey = options.findIndex(option => option === newValue);
		if (optionsEntryKey === -1) {
			optionsEntryKey = null;
		}
		callSafe(onChange, optionsEntryKey);
	};
}

ProducerField.propTypes = {
	producerChoice: PropTypes.number,
	onChange: PropTypes.func,
	archivePermissions: immutablePropType.isRequired,
	hintMessage: PropTypes.string,
	fieldLabel: PropTypes.string,
	noSelectionErrorMessage: PropTypes.string
};

function getOptions(archivePermissions) {
	return archivePermissions.toArray().map(
		archivePermission => ({abk: archivePermission.orgunitAbk, name: archivePermission.orgunitName})
	);
}

export default ProducerField;
