import {ALIGN_BOTTOM, ALIGN_TOP, EXPAND_TOP} from './constants/PupupMenuConstants.js';

export default function createPopupMenuDirections(align, expandDirection, expandAlignment) {
	const verticalAlignment = expandDirection === EXPAND_TOP ? ALIGN_TOP : ALIGN_BOTTOM;
	return {
		anchorOrigin: {horizontal: align, vertical: verticalAlignment},
		transformOrigin: {
			horizontal: expandAlignment ? expandAlignment : align,
			vertical: verticalAlignment
		}
	};
}
