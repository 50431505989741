import {WEB_APP_PRODUCTNAME} from '../../commons/constants/EnvironmentConstants.js';
import MessageTranslator from './MessageTranslator.js';

const SYN_MESSAGE_TOKENS = {
	SYN_WEB_APP_PRODUCTNAME: WEB_APP_PRODUCTNAME
};

export default class SynMessageTranslator extends MessageTranslator {
	getFormattedMessage(languageStringIdentifier, locale = '', messageParams = {}, fallbackTranslation = null) {
		const finalMessageParams = {...SYN_MESSAGE_TOKENS, ...messageParams};
		return super.getFormattedMessage(languageStringIdentifier, locale, finalMessageParams, fallbackTranslation);
	}
}
