import React, {useContext, useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {createSelector, createStructuredSelector} from 'reselect';

import DicomImageDataBooster from '../../components/dicom/DicomImageDataBooster.js';
import DicomSeriesContextProvider from '../../components/dicom/DicomSeriesContextProvider.js';
import DicomSeriesViewer from '../../components/dicom/DicomSeriesViewer.js';
import SeriesDataGuard from '../../components/SeriesDataGuard.js';
import ViewerContext from '../../components/ViewerContext.js';
import {getSeriesImageIndex} from '../../utils/ViewerItemUtils.js';
import {selectSeriesViewerItem} from '../selectors/ViewerItemsSelectors.js';
import {selectSingleViewerProperty} from '../selectors/ViewerSelectors.js';

export default function DicomSeriesViewerContainer(props) {
	const {itemId: seriesId} = props;
	const {viewerId, updateProperties} = useContext(ViewerContext);

	const stateSelector = useMemo(() => createStateSelector(viewerId, seriesId), [viewerId, seriesId]);
	const setFrameIndex = useMemo(
		() => (newOffset, activate) => updateProperties({seriesRelativeFrameOffset: newOffset}, activate),
		[updateProperties]
	);
	const {frameOffset, imageIndex} = useSelector(stateSelector);

	return (
		<SeriesDataGuard seriesId={seriesId}>
			<DicomSeriesContextProvider seriesId={seriesId} frameOffset={frameOffset} imageIndex={imageIndex}
			                            setFrameIndex={setFrameIndex}>
				<DicomImageDataBooster />
				<DicomSeriesViewer />
			</DicomSeriesContextProvider>
		</SeriesDataGuard>
	);
}

DicomSeriesViewerContainer.propTypes = {
	itemId: PropTypes.number
};

function createStateSelector(viewerId, seriesId) {
	return createStructuredSelector({
		imageIndex: createSelector(selectSeriesViewerItem(seriesId), getSeriesImageIndex),
		frameOffset: selectSingleViewerProperty(viewerId, 'seriesRelativeFrameOffset', 0)
	});
}
