import React from 'react';
import {styled} from '@mui/material/styles';

import {withForwardRef} from '../../../commons/utils/ReactUtils.js';
import VirtualizedOptionsList from './VirtualizedOptionsList.js';

export default withForwardRef(ExpandingVirtualizedOptionsList, 'forwardedRef');

const StyledVirtualizedOptionsList = styled(VirtualizedOptionsList, {name: 'StyledVirtualizedOptionsList'})({
	height: '100%'
});

function ExpandingVirtualizedOptionsList(props) {
	const {forwardedRef, ...remainingProps} = props;
	return <StyledVirtualizedOptionsList ref={forwardedRef} {...remainingProps} />;
}

ExpandingVirtualizedOptionsList.propTypes = {
	forwardedRef: withForwardRef.PropTypes.Ref,
	...VirtualizedOptionsList.propTypes
};
