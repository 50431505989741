import React, {useCallback, useEffect, useState} from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import SynFormattedMessage from '../../webview/commons/containers/SynFormattedMessageContainer.js';
import Toast from '../../webview/ui/components/Toast.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';

export default function RestoreTrashToast() {
	const {numTrashedFiles} = useBrick(UploadFilesCollection, selectRestoreFilesBrickProps);
	const [oldNumTrashedFiles, setOldNumTrashedFiles] = useState(numTrashedFiles);
	const [statusMessage, setStatusMessage] = useState(null);
	useEffect(() => {
		if (numTrashedFiles === 0 && oldNumTrashedFiles > 0) {
			setStatusMessage(<SynFormattedMessage element='div' message='UndoRemoveFilesConfirm' messageParams={{count: oldNumTrashedFiles}} />);
		}
		setOldNumTrashedFiles(numTrashedFiles);
	}, [numTrashedFiles, oldNumTrashedFiles]);
	const handleToastClose = useCallback(() => {
		setStatusMessage(null);
	}, [setStatusMessage]);
	return <Toast message={statusMessage} open={statusMessage !== null} onClose={handleToastClose} />;
}

function selectRestoreFilesBrickProps(service) {
	return {
		numTrashedFiles: service.getTrashedFilesCount()
	};
}
