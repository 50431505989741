import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks/index.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';

import '../../../styles/material-design/components/Indicator.scss';

export default function Indicator(props) {
	const {className, children} = props;
	const finalClassName = useMemoFactory(combineClassNames, 'material-indicator', className);
	return (
		<VerticalLayout align='center' className={finalClassName}>
			<span className='material-indicator--bubble'>
				<span className='material-indicator--bubble-text'>
					{children}
				</span>
			</span>
			<div className='material-indicator--needle' />
		</VerticalLayout>
	);
}

Indicator.propTypes = {
	className: PropTypes.string
};

Indicator.defaultProps = {
	className: null
};
