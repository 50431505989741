import {RBTree} from 'bintrees';

import DoublyLinkedList from './DoublyLinkedList.js';

export const PRIORITY_LOW = 1;
export const PRIORITY_HIGH = 6;
export const PRIORITY_IMMEDIATE = 36;

const PRIORITY = Symbol('priority');
const QUEUE = Symbol('queue');

export default class ProbabilisticQueue {
	constructor() {
		this.itemQueues = new RBTree(priorityComparator);
	}

	enqueue(priority, item) {
		let queueHolder = this.itemQueues.find({[PRIORITY]: priority});
		if (!queueHolder) {
			queueHolder = {
				[PRIORITY]: priority,
				[QUEUE]: new DoublyLinkedList()
			};
			this.itemQueues.insert(queueHolder);
		}
		queueHolder[QUEUE].push(item);
	}

	take() {
		let item = null;
		if (!this.isEmpty()) {
			const maxPriority = this.itemQueues.max()[PRIORITY];
			const searchPriority = (Math.random() * maxPriority) + 1;
			item = this.takeWithPriority(searchPriority);
		}
		return item;
	}

	takeWithPriority(priority) {
		let item = null;
		if (!this.isEmpty()) {
			const iterator = this.itemQueues.lowerBound({[PRIORITY]: priority});
			const queueHolder = iterator.data() || iterator.prev();
			if (queueHolder) {
				const queue = queueHolder[QUEUE];
				item = queue.shift();
				if (queue.isEmpty()) {
					this.itemQueues.remove(queueHolder);
				}
			}
		}
		return item;
	}

	removeMatchingItems(filterFunction) {
		const it = this.itemQueues.iterator();
		let queueHolder;
		const queueHoldersToRemove = [];
		while ((queueHolder = it.next()) !== null) {
			queueHolder[QUEUE].removeMatchingItems(filterFunction);
			if (queueHolder[QUEUE].isEmpty()) {
				queueHoldersToRemove.push(queueHolder);
			}
		}
		queueHoldersToRemove.forEach(queueHolderToRemove => this.itemQueues.remove(queueHolderToRemove));
	}

	isEmpty() {
		return this.itemQueues.size === 0;
	}
}

function priorityComparator(searchPriority, itemQueue) {
	const priorityA = searchPriority[PRIORITY];
	const priorityB = itemQueue[PRIORITY];

	if (priorityA === priorityB) {
		return 0;
	} else if (priorityA < priorityB) {
		return -1;
	}
	return 1;
}
