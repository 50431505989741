import React from 'react';
import {vec2} from 'gl-matrix';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import AnnotationsTranslator from '../../../i18n/translators/AnnotationsTranslator.js';
import {UNINITIALIZED_ANNOTATION_PROPERTIES} from '../../constants/AnnotationConstants.js';
import DeleteAnnotationIconContainer from '../../flux/containers/DeleteAnnotationIconContainer.js';
import EditAnnotationTextIconContainer from '../../flux/containers/EditAnnotationLabelIconContainer.js';
import {
	getAnnotationLabelText, toImagePosition
} from '../../utils/AnnotationUtils.js';
import {
	LABEL_TEXT_PROPERTY_NAME
} from './AnnotationConstants.js';
import createAnnotation from './createAnnotation.js';
import TextAnnotationRenderer from './TextAnnotationRenderer.js';

function isTextAnnotationSupported(viewerItem) {
	return viewerItem && true;
}

function getDefaultPropertiesForTextAnnotation(props) {
	return Immutable.Map({
		points: [toImagePosition(props, vec2.set(vec2.create(), 0.0, 0.0))],
		[LABEL_TEXT_PROPERTY_NAME]: AnnotationsTranslator.getFormattedMessage('DefaultTextAnnotationText', props.locale),
		showDialog: true
	});
}

function TextAnnotation(props) {
	const {annotationProperties} = props;
	const labelText = getAnnotationLabelText(annotationProperties);
	const iconComponents = [
		DeleteAnnotationIconContainer, 
		EditAnnotationTextIconContainer
	];
	return (
		<TextAnnotationRenderer iconComponents={iconComponents} 
			labelText={labelText} {...props} />
	);
}


TextAnnotation.propTypes = {
	readOnly: PropTypes.bool,
	AnnotationRoot: PropTypes.func,
	annotationId: PropTypes.string,
	onAnnotationPropertiesChanged: PropTypes.func,
	annotationProperties: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.oneOf([UNINITIALIZED_ANNOTATION_PROPERTIES])
	]),
	transformationMatrix: PropTypes.instanceOf(Float32Array),
	inverseTransformationMatrix: PropTypes.instanceOf(Float32Array),
	isPrintPreview: PropTypes.bool,
	containerWidth: PropTypes.number,
	containerHeight: PropTypes.number,
	fontSize: PropTypes.number,
	lineHeight: PropTypes.number
};

export default createAnnotation(
	isTextAnnotationSupported, getDefaultPropertiesForTextAnnotation, TextAnnotation
);
