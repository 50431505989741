import '../../polyfills';
import React from 'react';
import ReactDom from 'react-dom';

import {LANGUAGE_TAG} from '../constants/SynSettingsConstants.js';
import MainApplicationContainer from '../containers/MainApplicationContainer.js';
import {addDocumentClassNames} from '../utils/DOMUtils.js';
import {tryToPreventPinchZoom} from '../utils/SafariUtils.js';

import '../../../styles/styles.scss';

/** Searches for the main content element and initiates React rendering
 * on this element.
 * @param contentElementId {string} id of the main application element
 * @param createStore {function} creates the flux store for this application
 * @param appContextComponent {Object} a component being renderer around all pages and routes to provide a specific
 *    context for the startet application.
 */
export function startApplication(contentElementId, createStore, appContextComponent = undefined) {
	window.document.addEventListener('DOMContentLoaded', () => {
		setMarkerClasses();
		document.documentElement.setAttribute('lang', LANGUAGE_TAG);
		window.requestAnimationFrame(() => {
			tryToPreventPinchZoom();
			const store = createStore();
			applicationMain(contentElementId, appContextComponent, store);
		});
	}, false);
}

function setMarkerClasses() {
	addDocumentClassNames('');
}

function applicationMain(contentElementId, appContextComponent, store) {
	// Find main app element
	const contentElement = document.getElementById(contentElementId);
	if (contentElement) {
		ReactDom.render(
			<MainApplicationContainer flux={store} appContextComponent={appContextComponent} />,
			contentElement
		);
	}
}

