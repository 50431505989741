import React from 'react';
import PropTypes from 'prop-types';

import useTranslation from '../../../i18n/hooks/useTranslation.js';
import DownloadOptionsMessagesTranslator from '../../../i18n/translators/DownloadOptionsMessagesTranslator.js';
import {useMemoFactory} from '../../utils/customHooks/index.js';
import DownloadLink from '../DownloadLink.js';

export default function DocumentDownloadLink(props) {
	const {documentId, ...remainingProps} = props;
	const href = useMemoFactory(createDocumentDownloadUrl, documentId);
	const title = useTranslation('Download', {}, DownloadOptionsMessagesTranslator);
	return <DownloadLink {...remainingProps} href={href} title={title} />;
}

DocumentDownloadLink.propTypes = {
	...DownloadLink.propTypes,
	documentId: PropTypes.number.isRequired
};

delete DocumentDownloadLink.propTypes.href;
delete DocumentDownloadLink.propTypes.title;

function createDocumentDownloadUrl(documentId) {
	return `/document/${documentId}.download`;
}
