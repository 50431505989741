import BrickBase from '../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../webview/bricks/brickTools.js';
import NotImplementedError from '../../webview/commons/errors/NotImplementedError.js';
import {isFileEntry} from '../../webview/commons/utils/FilesCollection.js';
import PatientContextMonitor from './PatientContextMonitor.js';
import UploadContext from './UploadContext.js';
import UploadFilesCollection from './UploadFilesCollection.js';

const PERCENT_SCALE = 100.0;

export default class UploadController extends BrickBase {
	constructor(uploadContext, uploadFilesCollection, patientContextMonitor, scheduleUploadTask) {
		super();
		this.impl = new UploadControllerImpl(
			uploadContext, uploadFilesCollection, patientContextMonitor, scheduleUploadTask, this.uploadFile
		);
		this.subscribeTo(this.impl, this.onImplChange);
	}

	uploadFile(/*fileName, file, uploadStartedWhen*/) {
		throw new NotImplementedError('uploadFile');
	}

	onImplChange(controllerImpl) {
		this.updateBrickState(oldState => ({
			...oldState,
			remainingUploadQuota: controllerImpl.getRemainingUploadQuota(),
			uploadedPercent: controllerImpl.getUploadedPercent(),
			state: controllerImpl.getState()
		}));
	}

	startUpload() {
		this.impl.startUpload();
	}

	restart() {
		this.impl.restart();
	}

	restartWithFailed() {
		this.impl.restartWithFailed();
	}

	getRemainingUploadQuota() {
		return this.getBrickState().remainingUploadQuota;
	}

	getUploadedPercent() {
		return this.getBrickState().uploadedPercent;
	}

	getState() {
		return this.getBrickState().state;
	}
}
UploadController.State = {
	PREPARING: Symbol('Preparing'),
	READY: Symbol('Ready'),
	UPLOADING: Symbol('Uploading'),
	FINISHED: Symbol('Finished')
};
declareBrick(UploadController, [UploadContext, UploadFilesCollection, PatientContextMonitor]);

// TODO: All remaining uploads must fail, when bearer Token changes during running upload.
class UploadControllerImpl extends BrickBase {
	constructor(uploadContext, uploadFilesCollection, patientContextMonitor, scheduleUploadTask, uploadFileCall) {
		super();

		this.scheduleUploadTask = scheduleUploadTask;
		this.uploadFileCall = uploadFileCall;
		this.uploadContext = uploadContext;
		this.uploadFilesCollection = uploadFilesCollection;

		this.subscribeTo(this.uploadContext, this.onUploadContextChange);
		this.subscribeTo(this.uploadFilesCollection, this.onUploadFilesCollectionChange);
		this.subscribeTo(patientContextMonitor, this.onPatientContextMonitorChange);
	}

	onUploadContextChange(uploadContext) {
		this.updateBrickState(oldState => {
			const {
				totalSize = 0,
				totalFilesCount = 0,
				uploadingFilesCount = 0,
				hasFinishedUploading = false,
				unconfirmedOtherPatients = false
			} = oldState;
			const uploadQuota = uploadContext.getUploadQuota();
			const remainingUploadQuota = calcRemainingUploadQuota(totalSize, uploadQuota);
			const contextIsLoaded = uploadContext.isLoaded();
			const areUploadPreconditionsMet = determineIfUploadPreconditionsAreMet(
				contextIsLoaded, remainingUploadQuota, totalFilesCount, unconfirmedOtherPatients
			);
			return {
				...oldState,
				contextIsLoaded,
				uploadQuota,
				remainingUploadQuota,
				areUploadPreconditionsMet,
				state: calcState(hasFinishedUploading, uploadingFilesCount, areUploadPreconditionsMet)
			};
		});
	}

	onUploadFilesCollectionChange(uploadFilesCollection) {
		this.updateBrickState(oldState => {
			const {uploadQuota = 0, contextIsLoaded = false, unconfirmedOtherPatients = false} = oldState;
			const totalSize = uploadFilesCollection.getTotalSize();
			const uploadedFilesCount = uploadFilesCollection.getUploadedFilesCount();
			const failedFilesCount = uploadFilesCollection.getFailedFilesCount();
			const processedFilesCount = uploadedFilesCount + failedFilesCount;
			const totalFilesCount = uploadFilesCollection.getTotalFilesCount();
			const uploadedPercent = 	Math.floor((processedFilesCount / totalFilesCount) * PERCENT_SCALE);
			const preparedFilesCount = uploadFilesCollection.getPreparedFilesCount();
			const uploadingFilesCount = uploadFilesCollection.getUploadingFilesCount();
			const hasFinishedUploading = 	uploadingFilesCount === 0 &&
				preparedFilesCount === 0 &&
				processedFilesCount > 0;
			const remainingUploadQuota = calcRemainingUploadQuota(totalSize, uploadQuota);
			const areUploadPreconditionsMet = determineIfUploadPreconditionsAreMet(
				contextIsLoaded, remainingUploadQuota, totalFilesCount, unconfirmedOtherPatients
			);

			return {
				...oldState,
				totalSize,
				totalFilesCount,
				preparedFilesCount,
				uploadingFilesCount,
				uploadedPercent,
				remainingUploadQuota,
				hasFinishedUploading,
				areUploadPreconditionsMet,
				state: calcState(hasFinishedUploading, uploadingFilesCount, areUploadPreconditionsMet)
			};
		});
	}

	onPatientContextMonitorChange(patientContextMonitor) {
		this.updateBrickState(oldState => {
			const {
				contextIsLoaded = false,
				remainingUploadQuota = 0,
				totalFilesCount = 0,
				uploadingFilesCount = 0,
				hasFinishedUploading = false
			} = oldState;
			const unconfirmedOtherPatients = patientContextMonitor.hasUnconfirmedOtherPatients();
			const areUploadPreconditionsMet = determineIfUploadPreconditionsAreMet(
				contextIsLoaded, remainingUploadQuota, totalFilesCount, unconfirmedOtherPatients
			);
			return {
				...oldState,
				unconfirmedOtherPatients,
				areUploadPreconditionsMet,
				state: calcState(hasFinishedUploading, uploadingFilesCount, areUploadPreconditionsMet)
			};
		});
	}

	canStartUpload() {
		return this.getState() === UploadController.State.READY;
	}

	startUpload() {
		if (this.canStartUpload()) {
			const uploadStartedWhen = new Date();
			this.scheduleNextUpload(uploadStartedWhen);
		}
	}

	scheduleNextUpload(uploadStartedWhen) {
		if (this.getValue().preparedFilesCount > 0) {
			const [nextEntryName, containerName] = this.findNextFileToUpload();
			if (nextEntryName) {
				this.scheduleUploadTask({
					groupIdentifier: 'fileUpload',
					taskCreator: () => this.uploadNextFile(nextEntryName, containerName, uploadStartedWhen),
					taskConsumer: uploadPromise => this.consumeUploadPromise(
						nextEntryName, containerName, uploadPromise
					)
				});
			}
		}
	}

	findNextFileToUpload(containerName) {
		let nextEntryName;
		const filesAtPath = this.uploadFilesCollection.getPreparedFiles(containerName);
		if (filesAtPath) {
			nextEntryName =
				this.uploadFilesCollection.getPreparedFiles(containerName)
					.get(0);
			const file = this.uploadFilesCollection.getPreparedFile(nextEntryName, containerName);
			if (!file || !isFileEntry(file)) {
				return this.findNextFileToUpload(nextEntryName);
			}
		} else {
			nextEntryName = undefined;
		}
		return [nextEntryName, containerName];
	}

	uploadNextFile(entryName, containerName, uploadStartedWhen) {
		const {file, name: fileName} = this.uploadFilesCollection.getPreparedFile(entryName, containerName);
		this.uploadFilesCollection.setUploading(entryName, containerName);
		this.scheduleNextUpload(uploadStartedWhen);
		return this.uploadFileCall(fileName, file, uploadStartedWhen);
	}

	consumeUploadPromise(name, containerName, uploadPromise) {
		uploadPromise
			.then(() => {
				this.uploadFilesCollection.setUploaded(name, containerName);
			})
			.catch(() => {
				this.uploadFilesCollection.setFailed(name, containerName);
			});
	}

	getRemainingUploadQuota() {
		return this.getValue().remainingUploadQuota;
	}

	getState() {
		return this.getValue().state;
	}

	getUploadedPercent() {
		return this.getValue().uploadedPercent;
	}

	restartWithFailed() {
		this.uploadFilesCollection.resetWithFailed();
		this.uploadContext.reset();
	}

	restart() {
		this.uploadFilesCollection.clear();
		this.uploadContext.reset();
	}
}

function calcRemainingUploadQuota(totalSize, uploadQuota) {
	return uploadQuota - totalSize;
}

function determineIfUploadPreconditionsAreMet(
		contextIsLoaded, remainingUploadQuota, totalFilesCount, unconfirmedOtherPatients
) {
	return !unconfirmedOtherPatients && contextIsLoaded && remainingUploadQuota >= 0 && totalFilesCount > 0;
}

function calcState(hasFinishedUploading, uploadingFilesCount, areUploadPreconditionsMet) {
	let state = UploadController.State.PREPARING;
	if (areUploadPreconditionsMet) {
		state = UploadController.State.READY;
		if (uploadingFilesCount > 0) {
			state = UploadController.State.UPLOADING;
		} else if (hasFinishedUploading) {
			state = UploadController.State.FINISHED;
		}
	}
	return state;
}
