import React from 'react';
import {glMatrix, vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import {toContainerPosition} from '../../utils/AnnotationUtils.js';
import {rad2deg} from '../../utils/MathUtils.js';

import '../../../../styles/viewer/components/annotations/AnnotationBaseStyles.scss';

export default function Ellipse(props) {
	const {points, transformationMatrix} = props;
	let containerPoints = points;
	if (transformationMatrix) {
		containerPoints = points.map(point => toContainerPosition(props, point));
	}
	const [vertice0, center, covertice] = containerPoints;
	const offset = vec2.subtract(vec2.create(), vertice0, center);
	const angle = rad2deg(Math.atan(offset[1] / offset[0]));
	const rx = vec2.length(offset);
	const ry = vec2.length(vec2.subtract(vec2.create(), covertice, center));
	const [cx, cy] = center;
	return (
		<g>
			<ellipse className='annotation-element' cx={cx} cy={cy} rx={rx} ry={ry} transform={`rotate(${angle} ${cx} ${cy})`} />
		</g>
	);
}

Ellipse.propTypes = {
	points: PropTypes.arrayOf(PropTypes.oneOfType([
		PropTypes.instanceOf(Float32Array),
		PropTypes.instanceOf(Float64Array)
	])),
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE)
};
