import React, {useMemo} from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import PrimaryAction from '../../material-design/components/layout/PrimaryAction.js';
import RemovedNumStudiesPopupButton from '../../ui/components/RemovedNumStudiesPopupButton.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';

function PatientDetailsNumStudiesDisplay(props) {
	const {numDocuments, numDocumentsRemoved} = props;
	const hasRemovedDocuments = Boolean(numDocumentsRemoved);

	const numDocumentsText = useMemo(() => (<SynFormattedMessage id='patient-details-num-studies-display' message='NrStudies' messageParams={{nrStudies: numDocuments}}
																 translator={PatientDetailsMessagesTranslator} element='span' />), [numDocuments]);

	const numDocumentsRemovedText = useMemo(() => {
		let resultText = null;
		if (hasRemovedDocuments) {
			resultText = (<SynFormattedMessage message='NrHiddenStudies' messageParams={{nrStudies: numDocumentsRemoved}}
														 translator={PatientDetailsMessagesTranslator} />);
		}
		return resultText;
	}, [numDocumentsRemoved, hasRemovedDocuments]);

	return (
		<PrimaryAction>
			<RemovedNumStudiesPopupButton id='numDocumentsRemoved' btnText={numDocumentsText} showButton={hasRemovedDocuments}
				popupText={numDocumentsRemovedText} />
		</PrimaryAction>
	);
}

PatientDetailsNumStudiesDisplay.propTypes = {
	numDocuments: PropTypes.number.isRequired,
	numDocumentsRemoved: PropTypes.number.isRequired
};

export default React.memo(PatientDetailsNumStudiesDisplay);
