import BrickBase from '../../bricks/BrickBase.js';
import {declareBrick} from '../../bricks/brickTools.js';
import NotImplementedError from '../../commons/errors/NotImplementedError.js';
import {cancellable} from '../../commons/utils/PromiseUtils.js';

export default class ProducerList extends BrickBase {
	constructor() {
		super();
		this.pendingRequest = null;
	}

	loadResults() {
		this.cancelPendingLoad();
		this.pendingVisitRequest = cancellable(this.fetchProducerList())
			.maybe(this.onLoadingSucceeded)
			.catch(this.onLoadingFailed)
			.cancelled(this.cancelPendingLoad);
	}

	// The following template method is meant to be implemented by inheriting classes.
	// eslint-disable-next-line class-methods-use-this
	fetchProducerList() {
		throw new NotImplementedError();
	}

	onLoadingSucceeded(results) {
		this.pendingVisitRequest = null;
		const producers = results.getList();
		this.updateBrickState(oldState => ({
			...oldState,
			producers
		}));
	}

	onLoadingFailed() {
		this.pendingVisitRequest = null;
		this.reset(false);
	}

	onLoadingCancelled() {
		this.pendingVisitRequest = null;
		this.reset(false);
	}

	cancelPendingLoad() {
		if (this.pendingVisitRequest) {
			this.pendingVisitRequest.cancel();
			this.pendingVisitRequest = null;
		}
	}

	reset(reload = true) {
		const currentPatientId = this.getPatientId();
		this.cancelPendingLoad();
		this.updateBrickState(() => ({
			patientId: currentPatientId
		}));
		if (reload) {
			this.loadResults();
		}
	}

	getProducers() {
		return this.getBrickState().producers;
	}

	hasProducers() {
		const producers = this.getProducers();
		return producers && producers.size > 0;
	}

	getSingleProducer() {
		const producers = this.getProducers();
		if (producers && producers.size === 1) {
			return producers;
		}
		return null;
	}

	getPatientId() {
		return this.getBrickState().patientId;
	}
}
declareBrick(ProducerList);
