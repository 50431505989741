import {StatusCodes} from 'http-status-codes';
import Immutable, {fromJS} from 'immutable';

import FetchError from '../../commons/api/FetchError.js';
import NotFoundError from '../../commons/api/NotFoundError.js';
import {EMBEDDED_MODE} from '../../commons/constants/SynSettingsConstants';
import {getDocuments, getFirstDocumentId} from '../../commons/data/aim/PatientHelpers';
import {createAction} from '../../commons/utils/ActionUtils.js';
import {markAsHandled} from '../../commons/utils/ErrorHandlingUtils.js';
import {identity} from '../../commons/utils/FunctionUtils';
import {pushLocation, replaceLocation} from '../../router/flux/LocationActions';
import {getPatientDetails as getPatientDetailsOperation} from '../api/PatientDetailsApi.js';
import {CLEAR_PATIENT_DATA, FILTER_PATIENT_DETAILS, LOAD_PATIENT} from '../constants/PatientDetailsActionTypes.js';
import {PATIENT_DETAILS_DISPLAY_FORMAT, PATIENT_DETAILS_ONLY_SHARES, PATIENT_DETAILS_QUERY_PARAMS} from '../constants/PatientDetailsPropertyNames.js';
import {filterPatientDocuments} from '../data/PatientDetailsDataUtils';
import {selectDocumentIdFromFragment} from './selectors/PatientDetailsSelectors';

const loadPatientAction = createAction(LOAD_PATIENT);

export const clearPatientData = createAction(CLEAR_PATIENT_DATA);

function enrichResultData(onlyShares, queryParams, displayFormat) {
	return resultData => resultData
		.set(PATIENT_DETAILS_ONLY_SHARES, onlyShares)
		.set(PATIENT_DETAILS_QUERY_PARAMS, fromJS(queryParams))
		.set(PATIENT_DETAILS_DISPLAY_FORMAT, displayFormat);
}

const handleException = e => {
	if (e instanceof NotFoundError || e instanceof FetchError && e.response.status === StatusCodes.BAD_REQUEST) {
		markAsHandled(e);
	}
	throw e;
};

export function loadPatientForQuery(queryParameters, onlyShares, displayFormat) {
	return (dispatch, getState) => {
		dispatch(clearPatientData());
		if (displayFormat) {
			dispatch(replaceDisplayFormat(displayFormat));
		}
		dispatch(loadPatientAction(
			getPatientDetailsOperation({...queryParameters, onlyShares, displayFormat})
				.then(enrichResultData(onlyShares, queryParameters, displayFormat))
				.then(EMBEDDED_MODE
					? rawPatientDetails => filterRawPatientData(rawPatientDetails, getState)
					: identity
				)
				.catch(handleException)
		));
	};
}

function replaceDisplayFormat(displayFormat) {
	const locationPath = ['query', PATIENT_DETAILS_DISPLAY_FORMAT];
	return replaceLocation(displayFormat, locationPath);
}

export function loadPatient(patientId, onlyShares, requestedDisplayFormat) {
	return loadPatientForQuery({patient_aimid: patientId}, onlyShares, requestedDisplayFormat);
}

export function setDisplayFormat(displayFormat) {
	const locationPath = ['query', PATIENT_DETAILS_DISPLAY_FORMAT];
	return pushLocation(displayFormat, locationPath);
}

export const filterPatientDetails = createAction(FILTER_PATIENT_DETAILS);

function filterRawPatientData(rawPatientData, getState) {
	const fragmentDocumentId = selectDocumentIdFromFragment(getState());
	let initialDocumentIds;
	if (fragmentDocumentId === null) {
		const documents = getDocuments(rawPatientData);
		const firstDocumentId = getFirstDocumentId(documents);
		if (firstDocumentId) {
			initialDocumentIds = Immutable.Set([firstDocumentId]);
		}
	} else {
		initialDocumentIds = Immutable.Set([fragmentDocumentId]);
	}
	return filterPatientDocuments(rawPatientData,
		document => initialDocumentIds.includes(document.get('id'))
	);
}
