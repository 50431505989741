import React from 'react';

import ClientConfigLoader from '../../../commons/containers/webview/ClientConfigLoader.js';
import SessionGuard from '../../utils/SessionGuard.js';

export default function SessionContextLoader() {
	return (
		<SessionGuard loadingIndicator={false}>
			<ClientConfigLoader />
		</SessionGuard>
	);
}
