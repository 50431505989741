import ExtendableError from '../../commons/errors/ExtendableError.js';
import {markAsHandled} from '../../commons/utils/ErrorHandlingUtils.js';

export default class LoginError extends ExtendableError {
	constructor(originalError, username, code = originalError.code) {
		super(originalError.message);
		this.code = code;
		this.username = username;
		markAsHandled(this);
	}
}
