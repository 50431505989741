import React from 'react';
import PropTypes from 'prop-types';

import SynedraLogoSmallImage from '../../../../images/design/webview/synedraLogo-small.png';

export default function SynedraLogoSmall(props) {
	const {className} = props;
	return <img className={className} src={SynedraLogoSmallImage} alt='synedra IT GmbH' />;
}

SynedraLogoSmall.propTypes = {
	className: PropTypes.string
};
