import React from 'react';
import PropTypes from 'prop-types';

import {tr} from '../../../../i18n/components/SynFormattedMessage.js';
import WebViewMessagesTranslator from '../../../../i18n/translators/WebViewMessagesTranslator.js';
import Title from '../../../../material-design/components/Title.js';
import AppBar from '../../AppBar.js';
import AppBarOverflowButton from '../../AppBarOverflowButton.js';
import AppBarPrimaryContent from '../../AppBarPrimaryContent.js';
import AppBarSidebarHeader from '../AppBarSidebarHeader.js';

const HelpPageTitle = tr(WebViewMessagesTranslator, 'Help');

export default function HelpAppBar(props) {
	const {sidebarElevated} = props;
	const appBarProps = {};
	if (!sidebarElevated) {
		appBarProps.menuButton = false;
	}

	return (
		<AppBar {...props}>
			<AppBarPrimaryContent {...appBarProps}>
				<Title>
					<HelpPageTitle />
				</Title>
			</AppBarPrimaryContent>
			<AppBarOverflowButton visible={sidebarElevated} />
			<AppBarSidebarHeader sidebarElevated={sidebarElevated} />
		</AppBar>
	);
}

HelpAppBar.propTypes = {
	sidebarElevated: PropTypes.bool
};
