import {hasOwnPropertySafe, setUnenumerableProperty as setProperty} from './ObjectUtils';

const NEXT = Symbol('next');
const PREVIOUS = Symbol('previous');
const UNLINK = Symbol('unlink');

export default class DoublyLinkedList {
	constructor() {
		this.head = null;
		this.tail = null;
	}

	unshift(element) {
		this.insert(element);
		this.tail = element;
	}

	push(element) {
		this.insert(element);
		this.head = element;
	}

	clear() {
		this.head = null;
		this.tail = null;
	}

	insert(element) {
		if (hasOwnPropertySafe(element, NEXT)) {
			throw new Error('Tried to link an already linked element.');
		} else if ((typeof element !== 'object' && typeof _defaultExport !== 'function') || !Object.isExtensible(element)) {
			throw new Error('Only extensible objects or functions can be added to this doubly linked list.');
		}

		if (this.isEmpty()) {
			this.tail = element;
			this.head = element;
		}

		const next = this.tail;
		const previous = this.head;

		this.link(element, previous, next);
	}

	link(element, previous, next) {
		setProperty(element, PREVIOUS, previous);
		setProperty(element, NEXT, next);
		setProperty(previous, NEXT, element);
		setProperty(next, PREVIOUS, element);

		setProperty(element, UNLINK, () => {
			setProperty(element[NEXT], PREVIOUS, element[PREVIOUS]);
			setProperty(element[PREVIOUS], NEXT, element[NEXT]);
			if (this.head === element && this.tail === element) {
				this.head = null;
				this.tail = null;
			} else if (element === this.head) {
				this.head = element[PREVIOUS];
			} else if (element === this.tail) {
				this.tail = element[NEXT];
			}
			delete element[NEXT];
			delete element[PREVIOUS];
			delete element[UNLINK];
			return element;
		});
	}

	removeMatchingItems(filterFunction) {
		let element = this.tail;
		const removedItems = [];

		while (this.head !== null && element !== null) {
			const nextElement = element[NEXT] === this.tail ? null : element[NEXT];
			if (filterFunction(element)) {
				removedItems.push(DoublyLinkedList.unlink(element));
			}
			element = nextElement;
		}
		return removedItems;
	}

	pop() {
		return this.isEmpty() ? null : this.head[UNLINK]();
	}

	shift() {
		return this.isEmpty() ? null : this.tail[UNLINK]();
	}

	static unlink(element) {
		if (hasOwnPropertySafe(element, UNLINK)) {
			element[UNLINK]();
		}
		return element;
	}

	isEmpty() {
		return this.head === null;
	}
}
