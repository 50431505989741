import React from 'react';
import PropTypes from 'prop-types';

import VerticalLayout from '../../../ui/components/layout/VerticalLayout.js';
import UnhandledErrorsDialogContainer from '../../containers/UnhandledErrorsDialogContainer.js';
import PrivacyPolicyDialogContainer from '../../containers/webview/PrivacyPolicyDialogContainer.js';
import {cloneWithoutProperties} from '../../utils/ObjectUtils';
import createLayoutComponent from '../layout/createLayoutComponent.js';
import PageFooter from '../webview/PageFooter.js';

import '../../../../styles/commons/components/webview/IntermediatePage.scss';

const IntermediatePageContentContainer = createLayoutComponent('intermediate-page--content', VerticalLayout);

export default class IntermediatePage extends React.PureComponent {
	render() {
		const pageProps = cloneWithoutProperties(this.props, 'isCacheValid', 'dispatch', 'reloadOnUnmount');
		return (
			<VerticalLayout noShrink className='intermediate-page'>
				<IntermediatePageContentContainer {...pageProps} />
				<PageFooter />

				<UnhandledErrorsDialogContainer />
				<PrivacyPolicyDialogContainer />
			</VerticalLayout>
		);
	}

	componentWillUnmount() {
		const {reloadOnUnmount = false} = this.props;
		if (reloadOnUnmount) {
			window.location.reload();
		}
	}
}

IntermediatePage.propTypes = {
	reloadOnUnmount: PropTypes.bool,
	isCacheValid: PropTypes.bool,
	dispatch: PropTypes.func
};
