import React from 'react';
import ListItem, {listItemClasses} from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';

import LinkContainer from '../../commons/containers/LinkContainer.js';

const SX_PROPS = {
	'&:hover > *': {
		color: 'primary.main'
	},
	[`&.${listItemClasses.selected}`]: {
		'& > *': {
			color: 'primary.main'
		}
	}
};

export default function SidebarLink(props) {
	const {children, icon, active, onClick, path} = props;
	return (
		<LinkContainer path={path} forcedLocationUpdate={active} onClick={onClick}>
			<ListItem selected={active} button sx={SX_PROPS}>
				<ListItemIcon>
					{icon}
				</ListItemIcon>
				<ListItemText>
					{children}
				</ListItemText>
			</ListItem>
		</LinkContainer>
	);
}

SidebarLink.propTypes = {
	active: PropTypes.bool,
	onClick: PropTypes.func,
	path: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired
};

SidebarLink.defaultProps = {
	active: false
};
