import React from 'react';
import PropTypes from 'prop-types';

import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import StepperFooter from './StepperFooter.js';

import '../../../styles/material-design/components/Stepper.scss';

export default class Stepper extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.invokeToNextStep = this.curryWithCurrentStep(this.createSafeInvoke('nextStep'));
		this.invokeToPreviousStep = this.curryWithCurrentStep(this.createSafeInvoke('previousStep'));
	}
	
	render() {
		const {
			nrSteps, className, currentStep, isStepComplete, title, StepComponent, footerState, wasCancelled,
			hasFinished
		} = this.props;

		if (!(wasCancelled || hasFinished)) {
			const {nextLabel, previousLabel, showPreviousButton} = footerState;
			const containerClassName = combineClassNames('material-stepper', className);
			return (
				<VerticalLayout className={containerClassName}>
					<StepComponent title={title} {...this.props} />
					<StepperFooter currentStep={currentStep + 1} nrSteps={nrSteps} title={title}
					               toNextStep={this.invokeToNextStep} toPreviousStep={this.invokeToPreviousStep}
					               stepComplete={isStepComplete} showPreviousButton={showPreviousButton}
					               previousLabel={previousLabel} nextLabel={nextLabel} />
				</VerticalLayout>
			);
		}

		return null;
	}

	curryWithCurrentStep(callback) {
		return () => {
			const {currentStep} = this.props;
			callback(currentStep);
		};
	}

	createSafeInvoke(callbackName) {
		return (...args) => {
			const {[callbackName]: callback} = this.props;
			callSafe(callback, ...args);
		};
	}
}

Stepper.propTypes = {
	nrSteps: PropTypes.number, 
	className: PropTypes.string, 
	currentStep: PropTypes.number, 
	isStepComplete: PropTypes.bool, 
	title: PropTypes.object, 
	StepComponent: PropTypes.elementType, 
	footerState: PropTypes.object,
	wasCancelled: PropTypes.bool,
	hasFinished: PropTypes.bool,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func
};
