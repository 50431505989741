import React from 'react';
import PropTypes from 'prop-types';

export default React.memo(Spacer);

const SPACER_STYLE = {
	flexGrow: 1,
	flexShrink: 1,
	minHeight: 0,
	minWidth: 0,
	visibility: 'hidden'
};

function Spacer(props) {
	const {minHeight, minWidth} = props;
	const finalStyle = {
		...SPACER_STYLE,
		minHeight,
		minWidth
	};
	return <div style={finalStyle} />;
}

Spacer.propTypes = {
	minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Spacer.defaultProps = {
	minHeight: 0,
	minWidth: 0
};
