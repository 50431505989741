import Immutable from 'immutable';
import {handleActions} from 'redux-actions';

import InsufficientPermissionsError from '../../../commons/api/InsufficientPermissionsError.js';
import {AUTH_2ND_FACTOR_REQUIRED, AUTH_WEAK_PASSWORD} from '../../../commons/constants/ResourceErrorStatus.js';
import {
	BEGIN_PASSWORD_OPERATION,
	CLEAR_ERROR,
	FINISH_PASSWORD_OPERATION
} from '../../constants/PasswordActionTypes.js';
import {
	PASSWORD_BUSY,
	PASSWORD_ERROR,
	PASSWORD_OPERATION_SUCCEEDED,
	PASSWORD_STATE,
	PASSWORD_USER
} from '../../constants/PasswordStateFieldNames.js';
import {
	PASSWORD_CHANGE_ERROR_2ND_FACTOR_REQUIRED,
	PASSWORD_CHANGE_ERROR_INVALID_PASSWORD,
	PASSWORD_CHANGE_ERROR_OTHER,
	PASSWORD_CHANGE_ERROR_WEAK_PASSWORD,
	PASSWORD_OK,
	PASSWORD_TOO_WEAK,
	PASSWORD_WRONG
} from '../../constants/PasswordStateReducerStates.js';
import {BEGIN_SESSION, END_SESSION, FINISH_SESSION_TRANSITION} from '../../constants/SessionActionTypes.js';
import LoginError from '../../errors/LoginError.js';
import PasswordChangeError from '../../errors/PasswordChangeError.js';

const INITIAL_STATE = Immutable.fromJS({
	[PASSWORD_STATE]: PASSWORD_OK,
	[PASSWORD_BUSY]: false,
	[PASSWORD_ERROR]: null,
	[PASSWORD_USER]: null,
	[PASSWORD_OPERATION_SUCCEEDED]: false
});

export function getInitialState() {
	return INITIAL_STATE;
}

function setPasswordState(passwordState, newState) {
	return passwordState.set(PASSWORD_STATE, newState);
}

function handleOperationBegin(passwordState, {payload: username}) {
	return passwordState
		.set(PASSWORD_OPERATION_SUCCEEDED, false)
		.set(PASSWORD_BUSY, true)
		.set(PASSWORD_USER, username);
}

function handleOperationFinish(passwordState, {payload, error}) {
	const newPasswordState = clearPasswordError(passwordState)
		.set(PASSWORD_BUSY, false)
		.set(PASSWORD_OPERATION_SUCCEEDED, !error);
	return error ? setPasswordError(newPasswordState, extractErrorCause(payload)) : newPasswordState;
}

function extractErrorCause(error) {
	let cause = PASSWORD_CHANGE_ERROR_OTHER;
	if (error instanceof PasswordChangeError) {
		switch (error.code) {
			case AUTH_2ND_FACTOR_REQUIRED:
				cause = PASSWORD_CHANGE_ERROR_2ND_FACTOR_REQUIRED;
				break;
			case AUTH_WEAK_PASSWORD:
				cause = PASSWORD_CHANGE_ERROR_WEAK_PASSWORD;
				break;
			default:
				if (error.originalError instanceof InsufficientPermissionsError) {
					cause = PASSWORD_CHANGE_ERROR_INVALID_PASSWORD;
				}
				break;
		}
	}
	return cause;
}

function setPasswordError(passwordState, error) {
	return passwordState.set(PASSWORD_ERROR, error);
}

function clearPasswordError(passwordState) {
	return passwordState
		.set(PASSWORD_ERROR, null)
		.set(PASSWORD_OPERATION_SUCCEEDED, false);
}

function setPasswordOk(passwordState) {
	return setPasswordState(clearPasswordError(passwordState), PASSWORD_OK)
		.set(PASSWORD_BUSY, false)
		.set(PASSWORD_OPERATION_SUCCEEDED, false)
		.set(PASSWORD_USER, null);
}

function setPasswordWasWrong(passwordState) {
	return setPasswordState(clearPasswordError(passwordState), PASSWORD_WRONG)
		.set(PASSWORD_BUSY, false)
		.set(PASSWORD_OPERATION_SUCCEEDED, false)
		.set(PASSWORD_USER, null);
}

function setPasswordTooWeak(passwordState, username) {
	return setPasswordState(clearPasswordError(passwordState), PASSWORD_TOO_WEAK)
		.set(PASSWORD_BUSY, false)
		.set(PASSWORD_OPERATION_SUCCEEDED, false)
		.set(PASSWORD_USER, username);
}

function handleCreateSession(passwordState, {error, payload}) {
	let newState;
	if (error) {
		const reason = (payload instanceof LoginError) ? payload.code : null;
		switch (reason) {
			case AUTH_WEAK_PASSWORD:
				newState = setPasswordTooWeak(passwordState, payload.username);
				break;
			default:
				newState = setPasswordWasWrong(passwordState);
				break;
		}
	} else {
		newState = setPasswordOk(passwordState);
	}
	return newState;
}

export default handleActions({
	[BEGIN_PASSWORD_OPERATION]: handleOperationBegin,
	[FINISH_PASSWORD_OPERATION]: handleOperationFinish,
	[CLEAR_ERROR]: clearPasswordError,
	[FINISH_SESSION_TRANSITION]: handleCreateSession,
	[BEGIN_SESSION]:	getInitialState,
	[END_SESSION]: getInitialState
}, getInitialState());
