import Scheduler from '../Scheduler.js';

const DEFAULT_MAX_BROWSER_CONNECTIONS = 6;
const NUM_CHANNELS = localStorage.maxNumChannels
	? parseInt(localStorage.maxNumChannels, 10)
	: DEFAULT_MAX_BROWSER_CONNECTIONS;
const scheduler = new Scheduler(NUM_CHANNELS);

export function cancelTask(groupIdentifier, taskIdentifier) {
	return scheduler.cancelTask(groupIdentifier, taskIdentifier);
}
export function cancelAllTasks() {
	return scheduler.cancelAllTasks();
}
export function scheduleTask(taskProps) {
	const {groupIdentifier, taskIdentifier, taskCreator, taskConsumer} = taskProps;
	return scheduler.scheduleTask(taskCreator, taskConsumer, groupIdentifier, taskIdentifier);
}
export function addTaskSelector(id, priority, selector) {
	return scheduler.addSelector(id, priority, selector);
}
export function addOrReplaceTaskSelector(id, priority, selector) {
	scheduler.removeSelector(id);
	scheduler.addSelector(id, priority, selector);
}
export function removeTaskSelector(id) {
	return scheduler.removeSelector(id);
}
export function suspendTaskExecution(callback) {
	return scheduler.suspend(callback);
}
