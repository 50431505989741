import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import '../../../styles/material-design/components/DialogFooter.scss';

export default function DialogFooter(props) {
	const {alwaysVisible, children} = props;

	const finalClassNames = classNames({
		'material-dialog-footer': true,
		'always-visible': alwaysVisible
	});
	return (
		<footer className={finalClassNames}>
			{children}
		</footer>
	);
}

DialogFooter.propTypes = {
	alwaysVisible: PropTypes.bool,
	children: PropTypes.node.isRequired
};
