import BrickBase from '../../../webview/bricks/BrickBase.js';
import {declareBrick} from '../../../webview/bricks/brickTools.js';
import UploadFilesCollectorImpl from './UploadFilesCollectorImpl.js';

export default class UploadFilesCollector extends BrickBase {
	constructor(allowedFileExtensions, allowedFileSize, addFileList, setContainerData) {
		super();
		this.impl = new UploadFilesCollectorImpl(allowedFileExtensions, allowedFileSize, addFileList, setContainerData);
		this.subscribeTo(this.impl, this.onCollectorImplChange);
	}

	onCollectorImplChange(collectorImpl) {
		this.updateBrickState((oldState = {}) => ({
			...oldState,
			isCollecting: collectorImpl.isCollecting(),
			collectionSummary: collectorImpl.getCollectionSummary()
		}));
	}

	collectFromFileList(fileList) {
		this.impl.collectFromFileList(fileList);
	}

	isCollecting() {
		return this.getBrickState().isCollecting;
	}

	getCollectionSummary() {
		return this.getBrickState().collectionSummary;
	}
}
declareBrick(UploadFilesCollector);
