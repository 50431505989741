import React from 'react';
import PropTypes from 'prop-types';

import {SYNC_CIRCLE_RADIUS, TOUCH_SIZE} from './AnnotationConstants.js';
import CircleTouchArea from './CircleTouchArea.js';

export default function SyncPointTouchHandleArea(props) {
	const {active, hover} = props;
	const renderInActiveState = active || hover;
	const outerRadius = renderInActiveState ? TOUCH_SIZE : (TOUCH_SIZE / 2);
	return (
		<CircleTouchArea innerRadius={SYNC_CIRCLE_RADIUS} outerRadius={outerRadius} {...props} />
	);
}
SyncPointTouchHandleArea.propTypes = {
	active: PropTypes.bool,
	hover: PropTypes.bool
};
