import {createChainedValidatorFactory} from '../ValidatorFactories.js';
import ValidatorsMessagesTranslator from './i18n/ValidatorsMessagesTranslator.js';
import {createRegExValidator} from './regexValidator.js';

export const EMAIL_SYNTAX_REGEX = /^[^@\s]+@[\w-]+[\w-.]*\.[a-z]{2}[a-z]*(:[0-9]{0,5})?$/i;
const NO_LOWER_CASE_OR_SPACES_REGEX = /^[^A-Z\s]*$/;

export default createChainedValidatorFactory(
	createRegExValidator(NO_LOWER_CASE_OR_SPACES_REGEX, 'InvalidEMailDueToCasingOrSpacing', ValidatorsMessagesTranslator),
	createRegExValidator(EMAIL_SYNTAX_REGEX, 'InvalidEMail', ValidatorsMessagesTranslator)
);
