import {findProperUnit} from '../../commons/utils/NumberUtils.js';
import {toScientificNotation} from './MathUtils.js';

const BASE_1 = 1;
const BASE_2 = 2;
const BASE_5 = 5;
const BASE_10 = 10;

const SUBDIVISION_4 = 4;
const SUBDIVISION_5 = 5;
const SUBDIVISION_10 = 10;

const MAP_SUBDIVISIONS_TO_BASE = new Map([
	[BASE_1, SUBDIVISION_10],
	[BASE_2, SUBDIVISION_4],
	[BASE_5, SUBDIVISION_5]
]);

const SCALE_BASES = [BASE_5, BASE_2, BASE_1];

const SCALE_UNITS = [
	{factor: 0.001, symbol: 'm'},
	{factor: 0.1, symbol: 'cm'},
	{factor: 1, symbol: 'mm'},
	{factor: 1000, symbol: 'μm'}
];

/**
 * Calculates all necessary properties to display a length scale based on the viewers total width or height.
 * It takes a length in millimeters and calculates:
 *  - how long the displayed scale should be in mm (scaleLengthMM)
 *  - what measured value to display beside the scale (displayValue: value and unit)
 *  - how many subdivision ticks should be drawn (numSubDivisions)
 * @param annotatedLengthMM (number) - the length in mm to be annotated with the scale (e.g.: viewers width in mm)
 * @returns {{displayValue: {symbol, value}, scaleLengthMM, numSubDivisions}}
 * 				- object with calculated display properties.
 */
export function getLengthScaleParameters(annotatedLengthMM) {
	const {exponent, mantisse} = toScientificNotation(Math.abs(annotatedLengthMM));
	const floor = Math.floor(mantisse);
	const base = SCALE_BASES.find(n => n <= floor);
	const scaleLengthMM = base * Math.pow(BASE_10, exponent);
	const {factor, symbol} = findProperUnit(scaleLengthMM, SCALE_UNITS);
	return {
		scaleLengthMM,
		displayValue: {value: scaleLengthMM * factor, symbol},
		numSubDivisions: MAP_SUBDIVISIONS_TO_BASE.get(base)
	};
}
