import create from '../../commons/api/operations/create.js';
import {noop} from '../../commons/utils/FunctionUtils.js';

const METRICS_BASE_URL = '/metrics';

function createPayloadEntries(keyValuePairs) {
	return keyValuePairs.map(item => {
		const {key, value} = item;
		return {
			key,
			count: value
		};
	});
}

/**
 * Sends collected metrics to Server.
 * @param keyValuePairs Array of metric entries in format { key: KEY, value: VALUE }
 * 						KEY has to be a string, values can be either strings, or numbers
 * @returns {Promise}
 */
export function createEntries(keyValuePairs) {
	const payload = {
		entries: createPayloadEntries(keyValuePairs)
	};
	return create(METRICS_BASE_URL, payload, noop);
}

/**
 * Creates a metrics key accepted by the server.
 *
 * @param context
 * @param key
 * @returns {string}
 */
export function createCountEntryKey(context, key) {
	return `${context}.${key}.count`;
}

/**
 * Creates a metric entry accepted by the server.
 *
 * @param metricsKey
 * @param value
 * @returns {{value: Number, key}}
 */
export function createNumberMetricsEntry(metricsKey, value) {
	if ((typeof value) !== 'number') {
		throw new Error(`This is not a number: ${value}`);
	}
	return {key: metricsKey, value};
}
