import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../commons/utils/customHooks';
import {refPropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import {isHTMLTagElement} from '../../commons/utils/ReactUtils.js';
import {MUI_FIELD_VARIANTS} from '../constants/SynMUIOptions.js';

function SingleLineTextField(props) {
	const {
		value, hint, invalid, label, name, onBlur, autoComplete, autoCorrect, autoCapitalize, autoFocus, disabled,
		placeholder, type, forceFloating, component, textFieldButtons, textFieldButtonsStart, onChange, inputRef,
		id, onFocus, onKeyDown,	readOnly, onClick, small, className, onKeyPress, noMargin
	} = props;

	const inputComponent = component || 'input';
	const valueChangeHandler = useMemoFactory(createValueChangeHandler, inputComponent, onChange);
	const endAdornment = useMemoFactory(createEndAdornment, textFieldButtons);
	const startAdornment = useMemoFactory(createStartAdornment, textFieldButtonsStart);
	const textFieldProps = {
		value: value || '',
		id: id ? id : `id-${name}`,
		fullWidth: true,
		margin: noMargin ? 'none' : 'normal',
		error: invalid,
		helperText: hint,
		onChange: valueChangeHandler,
		onBlur,
		onFocus,
		onKeyDown,
		onKeyPress,
		label,
		name,
		autoComplete,
		autoFocus,
		disabled,
		placeholder,
		onClick,
		type,
		variant: MUI_FIELD_VARIANTS.DEFAULT,
		InputLabelProps: {
			...(forceFloating === true && {shrink: true})
		},
		InputProps: {
			...(inputComponent !== 'input' && {inputComponent}),
			...(endAdornment && {endAdornment}),
			...(startAdornment && {startAdornment}),
			readOnly
		},
		inputProps: {
			autoCapitalize,
			className,
			autoCorrect
		},
		size: small ? 'small' : undefined
	};

	return <TextField ref={inputRef} {...textFieldProps} />;
}

SingleLineTextField.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	value: PropTypes.string,
	invalid: PropTypes.bool,
	hint: PropTypes.node,
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	onBlur: PropTypes.func,
	onKeyDown: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyPress: PropTypes.func,
	label: PropTypes.string,
	name: PropTypes.string.isRequired,
	autoComplete: PropTypes.string,
	autoCorrect: PropTypes.oneOf([
		'on',
		'off'
	]),
	autoCapitalize: PropTypes.oneOf([
		'off',
		'none',
		'on',
		'sentences',
		'words',
		'characters'
	]),
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	forceFloating: PropTypes.bool,
	component: PropTypes.elementType,
	textFieldButtons: PropTypes.node,
	textFieldButtonsStart: PropTypes.node,
	inputRef: refPropType,
	readOnly: PropTypes.bool,
	small: PropTypes.bool,
	noMargin: PropTypes.bool
};

SingleLineTextField.defaultProps = {
	autoFocus: false,
	disabled: false,
	forceFloating: false
};

function createEndAdornment(textFieldButtons) {
	if (textFieldButtons) {
		return (
			<InputAdornment position='end'>
				{textFieldButtons}
			</InputAdornment>
		);
	}
	return undefined;
}

function createStartAdornment(textFieldButtons) {
	if (textFieldButtons) {
		return (
			<InputAdornment position='start'>
				{textFieldButtons}
			</InputAdornment>
		);
	}
	return undefined;
}


function createValueChangeHandler(inputComponent, onChange) {
	if (isHTMLTagElement(inputComponent)) {
		return createHtmlOnChange(onChange);
	}
	return onChange;
}

function createHtmlOnChange(onChange) {
	return e => {
		callSafe(onChange, e.target.value);
	};
}

export default SingleLineTextField;
