import SynCrudResourceApi from '../../api/SynCrudResourceApi.js';

const SEARCH_PARAMS = {
	params: {
		used: '1'
	},
	sortBy: [['id', 'ASC']]
};

const MODALITY_CRUD_RESOURCE_DEFINITION = {
	api: new SynCrudResourceApi('modality'),
	actionsCreator: apiActions => ({
		...apiActions, 
		load: () => apiActions.search(SEARCH_PARAMS)
	}),
	selectorsCreator: apiSelectors => ({
		...apiSelectors, getModalities: apiSelectors.getResponse,
		isModalityLoadInProgress: apiSelectors.isLoadInProgress
	})
};

export default MODALITY_CRUD_RESOURCE_DEFINITION;
