import React from 'react';
import {func, instanceOf} from 'prop-types';

import ErrorBoundary from '../../../commons/components/errors/ErrorBoundary.js';
import DicomImage from '../../data/DicomImage.js';
import {ViewerItemDownloadOptionsContainer} from '../../flux/containers/ViewerItemDownloadOptionsContainer.js';
import SRViewer from '../SRViewer';
import UnsupportedItemViewer from '../UnsupportedItemViewer.js';
import ViewerErrorDisplay from '../ViewerErrorDisplay.js';
import DicomImageViewer from './DicomImageViewer.js';
import DicomPdfViewer from './DicomPdfViewer.js';

export default class DicomViewer extends React.PureComponent {
	render() {
		const {dicomImage} = this.props;
		const {isDisplaySupported, isDownloadComplete} = dicomImage;
		return !isDownloadComplete || isDisplaySupported
			? this.renderViewer(dicomImage)
			: DicomViewer.renderUnsupportedItem();
	}

	renderViewer(dicomImage) {
		const ContentSpecificViewer = DicomViewer.getViewerComponent(dicomImage);
		return (
			<ErrorBoundary errorRenderer={ViewerErrorDisplay}>
				<ContentSpecificViewer {...this.props} />
			</ErrorBoundary>
		);
	}

	static renderUnsupportedItem() {
		return (
			<UnsupportedItemViewer message='DicomImageNotSupported'>
				<ViewerItemDownloadOptionsContainer />
			</UnsupportedItemViewer>
		);
	}

	static getViewerComponent(dicomImage) {
		let viewerComponent = DicomImageViewer;
		if (dicomImage.isEncapsulatedPdf) {
			viewerComponent = DicomPdfViewer;
		} else if (dicomImage.isHtml) {
			viewerComponent = SRViewer;
		}
		return viewerComponent;
	}
}

DicomViewer.propTypes = {
	dicomImage: instanceOf(DicomImage).isRequired,
	onTapLeft: func,
	onTapRight: func
};
