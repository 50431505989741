import {STEPPER_CURRENT_STEP, STEPPER_DATA} from '../constants/StepperStateFieldNames.js';
import {deleteStructure, updateField} from './StructuredDataActions.js';

function createStepperActions(stepperId, nrSteps) {
	function limitStep(step) {
		return Math.min(nrSteps, Math.max(step, -1));
	}

	function nextStep(currentStep) {
		return updateField(stepperId, STEPPER_CURRENT_STEP, limitStep(currentStep + 1));
	}

	function previousStep(currentStep) {
		return updateField(stepperId, STEPPER_CURRENT_STEP, limitStep(currentStep - 1));
	}

	function deleteStepperState() {
		return deleteStructure(stepperId);
	}

	function updateData(data) {
		return updateField(stepperId, STEPPER_DATA, data);
	}

	return {
		nextStep,
		previousStep,
		updateData,
		deleteStepperState
	};
}

export default createStepperActions;
