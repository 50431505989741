import React, {useCallback, useState} from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Popover from '@mui/material/Popover';
import {styled} from '@mui/material/styles';
import PropTypes from 'prop-types';

import {TOUCH_SIZE} from '../../viewer/components/annotations/AnnotationConstants.js';
import {ALIGN_CENTER, ALIGN_RIGHT, EXPAND_TOP} from '../constants/PupupMenuConstants.js';
import createPopupMenuDirections from '../createPopupMenuDirection.js';

const BUTTON_BASE_STYLES = {
	cursor: 'pointer',
	pointerEvents: 'auto',
	'&:hover': {
		opacity: 0.8
	}
};

const StyledCircle = styled('circle')({
	opacity: 0.1,
	fill: 'transparent'
});

function SvgButtonMenu(props) {
	const {x, y, icon: IconComponent, children} = props;
	const [anchorEl, setAnchorEl] = useState(null);

	const handleButtonClick = useCallback(e => {
		setAnchorEl(e.currentTarget);
	}, [setAnchorEl]);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, [setAnchorEl]);

	const handleMenuClick = useCallback(() => {
		handleClose();
	}, [handleClose]);

	const popoverDirections = createPopupMenuDirections(ALIGN_CENTER, EXPAND_TOP, ALIGN_RIGHT);

	return (
		<React.Fragment>
			<ButtonBase sx={BUTTON_BASE_STYLES} onClick={handleButtonClick} component='g' disableRipple>
				<IconComponent x={x} y={y} />
				<StyledCircle cx={x} cy={y} r={TOUCH_SIZE / 2} />
			</ButtonBase>
			<Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose} onClick={handleMenuClick}
			         {...popoverDirections}>
				{children}
			</Popover>
		</React.Fragment>
	);
}

SvgButtonMenu.propTypes = {
	icon: PropTypes.elementType.isRequired,
	x: PropTypes.string,
	y: PropTypes.string
};

SvgButtonMenu.defaultProps = {
	x: '0',
	y: '0'
};

export default React.memo(SvgButtonMenu);
