import React from 'react';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../commons/utils/customHooks';
import {immutableMapPropType} from '../../../commons/utils/CustomPropTypes.js';
import {UNINITIALIZED_ANNOTATION_PROPERTIES} from '../../constants/AnnotationConstants.js';
import {
	alignRectToRect, createMemoizedTextMeasureTool,
	renderAnnotationToolIconComponents,
	snapToRectInplace,
	toContainerPosition
} from '../../utils/AnnotationUtils.js';
import {createRectangle, getCenter, moveRect, moveRectInplace} from '../../utils/math/Rectangle.js';
import {renderMultilineTextElements} from '../../utils/SVGUtils.js';
import {getNormalizedDirection} from '../../utils/VectorUtils.js';
import {
	TOUCH_SIZE
} from './AnnotationConstants.js';
import AnnotationIconsGroup from './AnnotationIconsGroup.js';
import AnnotationLabel from './AnnotationLabel.js';
import ModifiablePoint from './ModifiablePoint.js';

function buildLabelProperties(labelPosition) {
	return {
		textAnchor: 'start',
		x: labelPosition[0],
		y: labelPosition[1]
	};
}

export function calculateLabelRect(
		annotationContainerPoint, labelDimensions, 
		containerWidth, containerHeight, fontSize) {
	const alignmentOffset = [TOUCH_SIZE / 2 + 1, 0];
	let labelRect = createRectangle([
		annotationContainerPoint[0] + alignmentOffset[0],
		annotationContainerPoint[1] + alignmentOffset[1]
	],	[
		annotationContainerPoint[0] + labelDimensions.width + alignmentOffset[0],
		annotationContainerPoint[1] + labelDimensions.height + alignmentOffset[1]
	]);
	moveRectInplace(labelRect, 0, -fontSize / 2);

	const anchorBoundingBox = createRectangle(
		[
			annotationContainerPoint[0] - TOUCH_SIZE / 2,
			annotationContainerPoint[1] - TOUCH_SIZE / 2
		], [
			annotationContainerPoint[0] + TOUCH_SIZE / 2,
			annotationContainerPoint[1] + TOUCH_SIZE / 2
		]
	);
	const containerRect = createRectangle(
		[-containerWidth / 2, -containerHeight / 2], 
		[containerWidth / 2, containerHeight / 2]
	);
	labelRect = alignRectToRect(labelRect, anchorBoundingBox, containerRect);
	snapToRectInplace(labelRect, anchorBoundingBox);
	moveRectInplace(labelRect, 0, fontSize / 2);
	return labelRect;
}

function TextAnnotationRenderer(props) {
	const {
		readOnly, AnnotationRoot, annotationId, annotationProperties,
		transformationMatrix, inverseTransformationMatrix, isPrintPreview,
		containerWidth, containerHeight,
		fontSize, lineHeight,
		onAnnotationPropertiesChanged, labelText, iconComponents
	} = props;

	const [point] = annotationProperties.get('points');
	const containerPosition = toContainerPosition(props, point);
	const textMeasureTool = useMemoFactory(createMemoizedTextMeasureTool);
	const labelRect = calculateLabelRect(containerPosition, 
		textMeasureTool(labelText, 'annotation-label', fontSize, lineHeight), 
		containerWidth, containerHeight, fontSize);
	const labelProperties = buildLabelProperties(labelRect.topLeft);
	const normalizedDirectionalVector = getNormalizedDirection(
		containerPosition, getCenter(moveRect(labelRect, 0, -fontSize / 2))
	);
	const onPointUpdate = React.useCallback(
		newPoint => onAnnotationPropertiesChanged(annotationId, annotationProperties.set('points', [newPoint])),
		[onAnnotationPropertiesChanged, annotationId, annotationProperties]
	);

	return (
		<AnnotationRoot>
			<ModifiablePoint readOnly={readOnly} point={point}
				                 inverseTransformationMatrix={inverseTransformationMatrix}
				                 transformationMatrix={transformationMatrix} 
								 onPointUpdate={onPointUpdate} />
			<AnnotationLabel {...labelProperties}>
				{renderMultilineTextElements(labelText, lineHeight, {x: '0'})}
			</AnnotationLabel>
			<AnnotationIconsGroup readOnly={readOnly} position={containerPosition}>
				{isPrintPreview 
					? false 
					: renderAnnotationToolIconComponents(iconComponents, 
						normalizedDirectionalVector, annotationId, undefined, undefined)}
			</AnnotationIconsGroup>
		</AnnotationRoot>
	);
}

TextAnnotationRenderer.propTypes = {
	readOnly: PropTypes.bool,
	AnnotationRoot: PropTypes.func,
	annotationId: PropTypes.string,
	onAnnotationPropertiesChanged: PropTypes.func,
	annotationProperties: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.oneOf([UNINITIALIZED_ANNOTATION_PROPERTIES])
	]),
	transformationMatrix: PropTypes.instanceOf(Float32Array),
	inverseTransformationMatrix: PropTypes.instanceOf(Float32Array),
	isPrintPreview: PropTypes.bool,
	containerWidth: PropTypes.number,
	containerHeight: PropTypes.number,
	fontSize: PropTypes.number,
	lineHeight: PropTypes.number,
	labelText: PropTypes.string,
	iconComponents: PropTypes.arrayOf(PropTypes.elementType)
};

export default TextAnnotationRenderer;

