import React, {useCallback, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {useMemoFactory} from '../../../../../commons/utils/customHooks';
import SynFormattedMessage from '../../../../../i18n/components/SynFormattedMessage.js';
import ViewerTranslator from '../../../../../i18n/translators/ViewerTranslator.js';
import CheckBoxBlankIcon from '../../../../../ui/components/icons/CheckboxBlankIcon.js';
import CheckBoxCheckedIcon from '../../../../../ui/components/icons/CheckboxCheckedIcon.js';
import ViewerContext from '../../../../components/ViewerContext.js';
import ViewerToolsMenuEntry from '../../../../components/ViewerToolsMenuEntry.js';
import DicomImage from '../../../../data/DicomImage';
import {activateOffsetPaging, deactivateOffsetPaging} from '../flux/OffsetPageSyncActions.js';
import {createSelectIsOffsetPageActiveForViewer, selectCanUsePageSync} from '../flux/OffsetPageSyncSelectors.js';

function MenuEntryLabel() {
	return <SynFormattedMessage message='OffsetPageSyncToolLabel' translator={ViewerTranslator} />;
}

export default function OffsetPageSyncMenuEntry(props) {
	const {dicomImage} = props;
	const {isActive, onClick, shouldDisplay, icon} = useMenuEntryState(dicomImage);
	if (shouldDisplay) {
		return (
			<ViewerToolsMenuEntry id={`offset-page-sync${isActive ? '-active' : ''}`}
										 onClick={onClick}
										 metricsKey='OffsetPageSyncTool'
										 label={MenuEntryLabel}
										 icon={icon} />
		);
	}
	return false;
}
OffsetPageSyncMenuEntry.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage).isRequired
};

function useMenuEntryState(dicomImage) {
	const {viewerId} = useContext(ViewerContext);
	const selectIsOffsetPageToolActive = useMemoFactory(createSelectIsOffsetPageActiveForViewer, viewerId);
	const isActive = useSelector(selectIsOffsetPageToolActive);
	const canUseToolPageSync = useSelector(selectCanUsePageSync);
	const canUseTool = dicomImage && dicomImage.isImage && canUseToolPageSync;
	const actionCreator = isActive ? deactivateOffsetPaging : activateOffsetPaging;
	const dispatch = useDispatch();
	return {
		isActive,
		onClick: useCallback(() => dispatch(actionCreator()), [dispatch, actionCreator]),
		shouldDisplay: isActive || canUseTool,
		icon: isActive ? CheckBoxCheckedIcon : CheckBoxBlankIcon
	};
}
