import {vec2} from 'gl-matrix';
import _once from 'lodash.once';

import {containsPoint2d, createRectangle, moveRect} from '../../../../viewer/utils/math/Rectangle.js';
import {
	PHYSICAL_DELTA_X,
	PHYSICAL_DELTA_Y,
	PHYSICAL_UNITS_X_DIRECTION,
	PHYSICAL_UNITS_Y_DIRECTION,
	REFERENCE_PIXEL_PHYSICAL_VALUE_X,
	REFERENCE_PIXEL_PHYSICAL_VALUE_Y,
	REFERENCE_PIXEL_X0,
	REFERENCE_PIXEL_Y0,
	REGION_DATA_TYPE_TAG_ID, REGION_FLAGS,
	REGION_LOCATION_MAX_X1,
	REGION_LOCATION_MAX_Y1,
	REGION_LOCATION_MIN_X0,
	REGION_LOCATION_MIN_Y0, REGION_SPATIAL_FORMAT,
	US_REGION_FLAG_SCALE_MASK,
	US_REGION_FLAG_SCALE_SHIFT,
	US_REGION_FLAG_SCROLLING_MASK,
	US_REGION_FLAG_SCROLLING_SHIFT
} from '../../../constants/DicomTagIDs.js';
import ReadOnlyDicomMap from './ReadOnlyDicomMap.js';

export default class UltrasoundRegion {
	/**
	 * @param {ReadOnlyDicomMap} regionMap - the map to read all information from.
	 * @param {Float64Array|Array|vec2} imageCenter - the center of the image. 
	 *  All results from getImage* getters are relative to this point.
	 */
	constructor(regionMap, imageCenter) {
		UltrasoundRegion.checkConstructorArgs(regionMap, imageCenter);
		this.regionMap = regionMap;

		this.getRegionFlags = _once(this.extractRegionFlags.bind(this));
		this.getRegionSpatialFormat = _once(this.extractRegionSpatialFormat.bind(this));
		this.getRegionDataType = _once(this.extractRegionDataType.bind(this));
		this.getRect = _once(this.extractRect.bind(this));
		this.getReferencePixel = _once(this.extractNumericalArray.bind(this, REFERENCE_PIXEL_X0, REFERENCE_PIXEL_Y0));
		this.getReferencePixelPhysicalValues = _once(this.extractFloat64Array.bind(
			this, REFERENCE_PIXEL_PHYSICAL_VALUE_X, REFERENCE_PIXEL_PHYSICAL_VALUE_Y)
		);
		this.getPhysicalDelta = _once(this.extractFloat64Array.bind(this, PHYSICAL_DELTA_X, PHYSICAL_DELTA_Y));
		this.getPhysicalUnits = _once(this.extractNumericalArray.bind(
			this, PHYSICAL_UNITS_X_DIRECTION, PHYSICAL_UNITS_Y_DIRECTION)
		);
		this.getImageRect = _once(this.extractImageRect.bind(this, imageCenter));
		this.getImageReferencePixel = _once(this.extractImageReferencePixel.bind(this));
	}

	static checkConstructorArgs(regionMap, imageCenter) {
		if (!(regionMap instanceof ReadOnlyDicomMap)) {
			throw new Error('Passed regionMap must be fo type ReadOnlyDicomMap');
		}
		if (imageCenter.length === undefined || imageCenter.length < 2) {
			throw new Error('Passed imageCenter must be array like with at least two elements');
		}
	}

	containsAllImagePoints(imagePoints) {
		const regionRect = this.getImageRect();
		return Boolean(imagePoints) && imagePoints.every !== undefined &&
			imagePoints.every(point => containsPoint2d(regionRect, point));
	}

	extractRegionDataType() {
		return this.regionMap.getNumericTagValue(REGION_DATA_TYPE_TAG_ID);
	}

	extractRect() {
		return createRectangle(
			this.extractNumericalArray(REGION_LOCATION_MIN_X0, REGION_LOCATION_MIN_Y0),
			this.extractNumericalArray(REGION_LOCATION_MAX_X1, REGION_LOCATION_MAX_Y1)
		);
	}

	extractImageRect(imageCenter) {
		const rect = this.getRect();
		return moveRect(rect, -imageCenter[0], -imageCenter[1]);
	}

	extractRegionSpatialFormat() {
		return this.regionMap.getNumericTagValue(REGION_SPATIAL_FORMAT);
	}

	extractRegionFlags() {
		const flagsValue = this.regionMap.getNumericTagValue(REGION_FLAGS);
		return {
			priority: (flagsValue & 0x1),
			protected: ((flagsValue & 0x2) >> 1) === 1,
			dopplerScaleType: (flagsValue & US_REGION_FLAG_SCALE_MASK) >> US_REGION_FLAG_SCALE_SHIFT,
			scrollingRegion: (flagsValue >> US_REGION_FLAG_SCROLLING_SHIFT) & US_REGION_FLAG_SCROLLING_MASK
		};
	}

	extractImageReferencePixel() {
		const referencePixel = this.getReferencePixel();
		return referencePixel === null
			? null
			: vec2.add([0, 0], this.getImageRect().topLeft, referencePixel);
	}

	extractNumericalArray(...tagIds) {
		const numericValues = tagIds.map(tagId => this.regionMap.getNumericTagValue(tagId));
		return numericValues.some(value => value === null) ? null : numericValues;
	}

	extractFloat64Array(...tagIds) {
		const numericalArray = this.extractNumericalArray(...tagIds);
		return numericalArray === null ? null : new Float64Array(numericalArray);
	}
}
