import {createStructuredSelector} from 'reselect';

import showReport from '../../../commons/actions/hangingprotocols/showReport.js';
import connectWithInstanceSelector from '../../../commons/flux/connectWithInstanceSelector.js';
import {hasReports} from '../../../commons/selectors/AIMLinkSelectors.js';
import {bindActions} from '../../../commons/utils/FluxUtils.js';
import ShowReportButton from '../../components/ShowReportButton.js';

export default connectWithInstanceSelector(
	props => {
		const {document} = props;
		const documentId = document.get('id');
		return createStructuredSelector({
			hasReport: hasReports(documentId)
		});
	},
	bindActions({showReport}),
	(stateProps, dispatchProps, props) => ({
		...props, hasReport: stateProps.hasReport,
		showReport: () => dispatchProps.showReport(props.document)
	})
)(ShowReportButton);
