import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../webview/i18n/components/SynFormattedMessage.js';
import Button from '../../../webview/ui/components/Button.js';

export default function ConfirmationButton(props) {
	const {confirm} = props;
	return (
		<Button color='inherit' onClick={confirm} variant='outlined' size='small'>
			<SynFormattedMessage message='DataOfOtherPatientsWarningConfirmation' />
		</Button>
	);
}

ConfirmationButton.propTypes = {
	confirm: PropTypes.func
};
