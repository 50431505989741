import React from 'react';

import createFormFieldContainer from '../../../commons/containers/createFormFieldContainer.js';
import {cloneWithoutProperties} from '../../../commons/utils/ObjectUtils';
import SingleLineTextField from '../../../ui/components/SingleLineTextField.js';

export default connectTextField(SingleLineTextField);

export function connectTextField(TextFieldComponent) {
	return createFormFieldContainer(
		TextFieldComponent,
		(stateProps, dispatchProps, ownProps) => {
			const {label: propsLabel, value} = ownProps;
			const {value: formValue, label: stateLabel} = stateProps;

			const displayValidationError = shouldDisplayValidationError(ownProps, stateProps);
			const remainingProps = cloneWithoutProperties(ownProps,
				'formDescription',
				'displayValidationResult'
			);
			return {
				...remainingProps,
				value: value === undefined ? formValue : value,
				label: propsLabel === undefined ? stateLabel : propsLabel,
				hint: buildHintMessage(ownProps, stateProps, displayValidationError),
				invalid: isInvalid(ownProps, displayValidationError),
				onChange: buildOnChangeHandler(ownProps, dispatchProps)
			};
		}
	);
}

function shouldDisplayValidationError(ownProps, stateProps) {
	const {displayValidationResult} = ownProps;
	const {isFormValidationForced, validationResult} = stateProps;
	return validationResult !== true &&
		(isFormValidationForced || displayValidationResult);
}

function buildOnChangeHandler(ownProps, dispatchProps) {
	const {onChange} = ownProps;
	const {updateFieldValue} = dispatchProps;

	let onChangeHandler = updateFieldValue;
	if (onChange) {
		onChangeHandler = newValue => {
			updateFieldValue(newValue);
			onChange(newValue);
		};
	}
	return onChangeHandler;
}

function buildHintMessage(ownProps, stateProps, displayValidationError) {
	const {hint} = ownProps;
	const {hintResult} = stateProps;

	const displayValidationHint = hintResult !== true;

	let hintMessage = hint || '';
	if (displayValidationError) {
		hintMessage = stateProps.validationResult;
	} else if (displayValidationHint) {
		hintMessage = hintResult;
	}
	return hintMessage;
}

function isInvalid(ownProps, displayValidationError) {
	const {invalid} = ownProps;
	return invalid === undefined ? displayValidationError : invalid;
}
