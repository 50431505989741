import React from 'react';

import Status from '../../webview/a11y/components/landmarks/Status.js';
import useBrick from '../../webview/bricks/hooks/useBrick.js';
import SynFormattedMessage from '../../webview/i18n/components/SynFormattedMessage.js';
import {Body2} from '../../webview/ui/components/typography/Typography.js';
import UploadFilesCollection from '../bricks/UploadFilesCollection.js';

export default function UploadProgressFileCounter(/*props*/) {
	const {filesCount, uploadedCount} = useBrick(UploadFilesCollection, selectUploadFilesBrickProps);
	return <SynFormattedMessage element={Body2} component={Status} message='UploadInfo' messageParams={{filesCount, uploadedCount}} />;
}

function selectUploadFilesBrickProps(service) {
	return {
		filesCount: service.getTotalFilesCount(),
		uploadedCount: service.getUploadedFilesCount(),
		failedCount: service.getFailedFilesCount()
	};
}
