import React from 'react';
import PropTypes from 'prop-types';

import IconButton from './IconButton.js';
import MenuIcon from './icons/MenuIcon.js';

export default function MenuButton(props) {
	const {onClick, visible} = props;
	const sx = visible ? {} : {display: 'none'};
	return <IconButton color='inherit' icon={<MenuIcon />} onClick={onClick} sx={sx} />;
}

MenuButton.propTypes = {
	visible: PropTypes.bool,
	onClick: PropTypes.func
};

MenuButton.defaultProps = {
	visible: true
};
