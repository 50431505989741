import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {ROOT_PATH} from '../../../commons/constants/CommonRoutingConstants.js';
import UserActivationMessagesTranslator from '../../i18n/UserActivationMessagesTranslator.js';
import {selectActivationToken} from '../selectors/NewPasswordSelectors.js';
import NewPasswordPageContainer from './NewPasswordPageContainer.js';

export default connect(
	createStructuredSelector({
		token: selectActivationToken,
		bailOutUrl: () => ROOT_PATH,
		translator: () => UserActivationMessagesTranslator
	})
)(NewPasswordPageContainer);
