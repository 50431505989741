import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../../commons/utils/StyleUtils.js';
import VerticalLayout from '../../../ui/components/layout/VerticalLayout.js';

import '../../../../styles/material-design/components/layout/TwoLineListItem.scss';

function TwoLineListItem(props) {
	const {className, ...remainingProps} = props;
	const finalClassName = combineClassNames('two-line-list-item--content', className);
	return <VerticalLayout className={finalClassName} align='start' justify='center' {...remainingProps} />;
}

TwoLineListItem.propTypes = {
	className: PropTypes.string
};

export default TwoLineListItem;
