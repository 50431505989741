import React from 'react';
import PropTypes from 'prop-types';

export default function ListEntry(props) {
	const {index, style, data} = props;
	const listItem = data[index];
	return React.cloneElement(listItem, {style});
}
ListEntry.propTypes = {
	index: PropTypes.number,
	style: PropTypes.object,
	data: PropTypes.arrayOf(PropTypes.object)
};
