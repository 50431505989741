import {useFormattedDataSize} from '../../webview/commons/components/HumanReadableDataSize.js';
import {formatPatientInfo} from '../../webview/commons/data/aim/PatientHelpers.js';
import {useMemoFactory} from '../../webview/commons/utils/customHooks/index.js';
import useTranslator from '../../webview/i18n/hooks/useTranslator.js';

export default function useFormattedEntry(entry) {
	const getFormattedMessage = useTranslator();
	const {study, name, patient, size} = entry;
	return {
		name: useMemoFactory(buildDisplayName, name, study, getFormattedMessage),
		patient: useMemoFactory(buildPatientInfo, patient, getFormattedMessage),
		size: useFormattedDataSize(size)
	};
}

function buildDisplayName(name, study, getFormattedMessage) {
	return name || formatStudy(study, getFormattedMessage);
}

function formatStudy(study, getFormattedMessage) {
	return getFormattedMessage('Column_Content_StudyDescription', study);
}

function buildPatientInfo(patient, getFormattedMessage) {
	if (patient) {
		const {firstName, lastName, sex, birthDate} = patient;
		const patientInfo = formatPatientInfo(sex, lastName, firstName);
		return birthDate
			? `${patientInfo}, ${getFormattedMessage('BirthDate', {birthDate})}`
			: patientInfo;
	}
	return '';
}
