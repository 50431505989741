import React from 'react';
import PropTypes from 'prop-types';

import DataGuard from '../../commons/components/data/DataGuard.js';
import GenericViewerToolbarContainer from './GenericViewerToolbar.js';
import ViewerBox from './ViewerBox.js';
import ViewerItemLoadErrorGuard from './ViewerItemLoadErrorGuard.js';

export default function createGenericItemViewer(ViewComponent, viewerClassName = '', ToolBarComponent = DefaultToolbarRenderer) {
	function ViewerItemViewer(props) {
		const {
			hasDownloadStarted, fileViewerItem, 
			loadViewerItem, renderToolbar = true, 
			...viewerProps
		} = props;
		const {fileId} = props;
		const toolBar = renderToolbar && 
			<ToolBarComponent fileId={fileId} showTools={ViewComponent.supportsImageTools} />;

		return (
			<ViewerItemLoadErrorGuard viewerItem={fileViewerItem}>
				<ViewerBox className={viewerClassName} toolbar={toolBar}>
					<DataGuard data={fileViewerItem} 
					isLoadNeeded={!hasDownloadStarted && Boolean(loadViewerItem)} 
					load={loadViewerItem}>
						<ViewComponent {...viewerProps} />
					</DataGuard>
				</ViewerBox>
			</ViewerItemLoadErrorGuard>
		);
	}
	
	ViewerItemViewer.propTypes = {
		hasDownloadStarted: PropTypes.bool, 
		fileViewerItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.object]), 
		loadViewerItem: PropTypes.func, 
		renderToolbar: PropTypes.bool, 
		fileId: PropTypes.number
	};

	return ViewerItemViewer;
}

function DefaultToolbarRenderer(props) {
	const {fileId, showTools} = props;
	return <GenericViewerToolbarContainer fileId={fileId} showTools={showTools} />;
}

DefaultToolbarRenderer.propTypes = {
	fileId: PropTypes.number,
	showTools: PropTypes.bool
};


