import React from 'react';
import PropTypes from 'prop-types';

export default function Alert(props) {
	const {element: Element, ...remainingProps} = props;
	return <Element role='alert' {...remainingProps} />;
}

Alert.propTypes = {
	element: PropTypes.elementType
};

Alert.defaultProps = {
	element: 'div'
};
