import Immutable from 'immutable';

const NON_EXISTING_TAG_VALUE = Symbol('non existing dicom tag value');

export default class ReadOnlyDicomMap {
	constructor(immutableMap) {
		this.rawMap = immutableMap;
	}

	/**
	 * reads a values for a specified tag
	 *
	 * @param {String} tagId - the id of the tag to retrieve. 
	 * 	   it has to be provided as string in the following form <group><element>
	 *     For instance to retrieve the content of the tag "Image Type" 
	 *     which has group 0x0008 and element 0x0008 it has to be provided in the following form '00080008'
	 * @param defaultValue - returned if the tagId cannot be found
	 * @returns the tags value or the passed defaultValue if no such tag exists.
	 */
	getTagValue(tagId, defaultValue = '') {
		return this.rawMap.getIn([tagId, 'value'], defaultValue);
	}

	getRawTagValue(tagId, defaultValue = Immutable.Map.of()) {
		return this.rawMap.get(tagId, defaultValue);
	}

	getNumericTagValue(tagId, defaultValue = null) {
		let numericValue = defaultValue;
		const tagValue = this.getTagValue(tagId, '');
		if (tagValue !== '') {
			numericValue = parseFloat(tagValue);
		}
		return numericValue;
	}

	/**
	 * reads a collection of values for a specified tag which are separated by backslash.
	 *
	 * @param {String} tagId the id of the tag to retrieve. 
	 *     it has to be provided as string in the following form <group><element>
	 *     For instance to retrieve the content of the tag "Image Type" 
	 *     which has group 0x0008 and element 0x0008 it has to be provided in the following form '00080008'
	 * @returns {Immutable.List<String>} the list of values
	 */
	getTagValues(tagId) {
		const rawTag = this.getTagValue(tagId);
		return rawTag ? Immutable.List.of(...rawTag.split('\\')) : Immutable.List.of();
	}

	/**
	 * Reads a collection of values for a specified tag which are separated by backslash. 
	 * Each value is converted into a Number by using parseFloor
	 *
	 * @param {String} tagId the id of the tag to retrieve. 
	 *     it has to be provided as string in the following form <group><element>
	 *     For instance to retrieve the content of the tag "Image Type" 
	 *     which has group 0x0008 and element 0x0008 it has to be provided in the following form '00080008'
	 */
	getNumericTagValues(tagId) {
		return this.getTagValues(tagId).map(parseFloat);
	}

	/**
	 * Checks whether the map contains a tag with the specified id.
	 * @param tagId - tag to look for
	 * @returns {boolean} true if the tag is present, false otherwise
	 */
	hasTag(tagId) {
		return this.getTagValue(tagId, NON_EXISTING_TAG_VALUE) !== NON_EXISTING_TAG_VALUE;
	}

	getTagValueRepresentation(tagId) {
		return this.hasTag(tagId) ? this.getRawTagValue(tagId).get('VR') : undefined;
	}

	/**
	 * Returns an Immutable.List containing ReadOnlyTagMaps, that represent the sequences entries.
	 * @param tagId - DicomTag of the sequence
	 */
	getSequence(tagId) {
		const rawSequence = this.getTagValueRepresentation(tagId) === 'SQ' && this.getTagValue(tagId, Immutable.List.of()) || Immutable.List.of();
		return rawSequence.map(entry => new ReadOnlyDicomMap(entry));
	}
}
