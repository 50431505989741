import React, {useState} from 'react';
import classnames from 'classnames';
import {bool, elementType, number, string} from 'prop-types';

import createDelayedPropsComponent from '../../commons/components/createDelayedPropsComponent.js';
import ErrorBoundary from '../../commons/components/errors/ErrorBoundary.js';
import FullscreenContainer from '../../commons/components/FullScreenContainer.js';
import {FULLSCREEN_TRANSITION_TIME} from '../../commons/constants/FullScreenWrapperConstants.js';
import {memoizeByFirstArg} from '../../commons/utils/FunctionUtils.js';
import FullscreenViewerLocationSynchronizer from './FullscreenViewerLocationSynchronizer.js';
import UnsupportedItemViewer from './UnsupportedItemViewer.js';
import ViewerContext from './ViewerContext.js';
import ViewerErrorDisplay from './ViewerErrorDisplay.js';
import ViewerLocationSynchronizer from './ViewerLocationSynchronizer.js';

import '../../../styles/viewer/components/Viewer.scss';

export default function Viewer(props) {
	const {isFullScreen} = props;
	const [DelayedPropsViewerRenderer] = useState(createDelayedPropsComponent(ViewerRenderer));
	const delay = isFullScreen ? FULLSCREEN_TRANSITION_TIME : 1;
	const delayedProps = isFullScreen ? ['embeddedVisible'] : ['isFullScreen'];
	return (
		<DelayedPropsViewerRenderer embeddedVisible={!isFullScreen} delay={delay} delayedProps={delayedProps}
		                            {...props} />
	);
}

Viewer.propTypes = {
	itemId: number,
	isFullScreen: bool,
	showActiveBorder: bool
};

class ViewerRenderer extends React.PureComponent {
	constructor(props, context) {
		super(props, context);

		this.getErrorBoundaryKey = memoizeByFirstArg(getErrorBoundaryKey);
	}

	render() {
		const {ViewerComponent, showActiveBorder, className, itemExists} = this.props;
		const isEmpty = !ViewerComponent;
		const viewerContainerClassName = classnames({
			'viewer--container': true,
			'active-border': showActiveBorder,
			[className]: Boolean(className)
		});
		return isEmpty || !itemExists
			? this.renderErroneousViewer(viewerContainerClassName)
			: this.renderTypeSpecificViewer(viewerContainerClassName);
	}

	renderTypeSpecificViewer(viewerContainerClassName) {
		const {activate: activateViewer} = this.context;
		const {ViewerComponent, isFullScreen, itemId, embeddedVisible} = this.props;
		const embeddedViewer = <ViewerComponent itemId={itemId} />;
		const errorBoundaryKey = this.getErrorBoundaryKey(itemId);
		return (
			<div className={viewerContainerClassName} onPointerDown={activateViewer}>
				<ViewerLocationSynchronizer />
				<ErrorBoundary key={errorBoundaryKey} errorRenderer={ViewerErrorDisplay}>
					{(embeddedVisible ? embeddedViewer : false)}
					<FullscreenContainer className={`${viewerContainerClassName} fullscreen`} visible={isFullScreen}>
						<React.Fragment>
							<FullscreenViewerLocationSynchronizer />
							{React.cloneElement(embeddedViewer, {isFullScreen: true})}
						</React.Fragment>
					</FullscreenContainer>
				</ErrorBoundary>
			</div>
		);
	}

	renderErroneousViewer(viewerContainerClassName) {
		const {itemExists} = this.props;
		return itemExists
			? <div className={viewerContainerClassName} />
			: (
				<div className={viewerContainerClassName}>
					<UnsupportedItemViewer message='ViewerItemNotFound' />
				</div>
			);
	}
}
ViewerRenderer.propTypes = {
	itemId: number,
	className: string,
	isFullScreen: bool,
	embeddedVisible: bool,
	showActiveBorder: bool,
	ViewerComponent: elementType,
	itemExists: bool
};
ViewerRenderer.defaultProps = {
	className: null,
	isFullScreen: false,
	showActiveBorder: false,
	embeddedVisible: true
};
ViewerRenderer.contextType = ViewerContext;

function getErrorBoundaryKey(itemId) {
	return `error-boundary-for-item-${itemId}`;
}
