import Immutable from 'immutable';

import ResultList from '../../commons/api/ResultList';
import {getScrollPositionPropertyPath} from '../../commons/selectors/ScrollSelectors.js';
import {
	createAction,
	createStandardAction
} from '../../commons/utils/ActionUtils.js';
import {debugLog} from '../../commons/utils/DebugLog';
import {reusePending} from '../../commons/utils/PromiseUtils';
import {getElement, getLength} from '../../commons/utils/SeqUtils.js';
import withTrackingMetadata from '../../metrics-collector/utils/withTrackingMetadata.js';
import {pushLocation, replaceLocation} from '../../router/flux/LocationActions.js';
import {locationSelector} from '../../router/flux/selectors/LocationSelectors.js';
import {updateHiddenLocationState} from '../../router/LocationUtils.js';
import {filterInbox as filterInboxOperation, markShareDone as markShareDoneOperation} from '../api/InboxApi.js';
import {BEGIN_INBOX_FETCH, FILTER_INBOX, LOAD_INBOX, MARK_SHARES_DONE} from '../constants/InboxActionTypes.js';
import {INBOX_QUERY_FILTER_PARAM, INBOX_SCROLL_COMPONENT_ID} from '../constants/InboxPropertyNames.js';
import {getInboxFilterString} from './InboxSelectors.js';

const FETCH_ID_RANDOM_RANGE = 10_000_000;

function shareDoneTrackingDataProvider(sharesToMark) {
	const prefix = getLength(sharesToMark) > 1 ? 'MultipleShares' : 'Share';
	const done = getElement(sharesToMark, 0).done ? 'Done' : 'Undone';
	const metricsKey = prefix + done;
	return {usageContext: 'share', key: metricsKey};
}

const markSharesDoneLocally = createAction(MARK_SHARES_DONE, null, withTrackingMetadata(shareDoneTrackingDataProvider));
const loadInboxAction = createInboxFetchAction(LOAD_INBOX);
const filterInboxAction = createInboxFetchAction(FILTER_INBOX);
const reusingFilterInboxOperation = reusePending(filterInboxOperation);

function clearFilteredResultAction() {
	return filterInboxAction(null);
}

function markSharesDoneRemotely(updateSequence, undo, index = 0) {
	let currentIndex = index;
	if (currentIndex < getLength(updateSequence)) {
		const update = getElement(updateSequence, currentIndex++);
		markShareDoneOperation(update.shareId, update.done)
			.then(() => {
				markSharesDoneRemotely(updateSequence, undo, currentIndex);
			})
			.catch(() => {
				undo(update);
				markSharesDoneRemotely(updateSequence, undo, currentIndex);
			});
	}
}

export function filterInbox(filterString = '') {
	return (dispatch, getState) => {
		const currentLocation = locationSelector(getState());
		const previousFilterString = getInboxFilterString(getState());
		const trimmedPreviousFilterString = previousFilterString ? previousFilterString.trim() : '';
		const trimmedFilterString = filterString ? filterString.trim() : '';

		const previousFilterStringWasDefined = trimmedPreviousFilterString !== '';
		const filterStringChanged = trimmedFilterString !== trimmedPreviousFilterString;

		let newLocationWithFilterString = currentLocation.updateIn(['query'], Immutable.Map(),
			query => query.set(INBOX_QUERY_FILTER_PARAM, filterString)
		);
		if (previousFilterStringWasDefined) {
			if (filterStringChanged) {
				newLocationWithFilterString = resetScrollPositionInLocation(newLocationWithFilterString);
				dispatch(replaceLocation(newLocationWithFilterString));
			}
		} else {
			if (trimmedFilterString !== '') {
				newLocationWithFilterString = resetScrollPositionInLocation(newLocationWithFilterString);
			}
			dispatch(pushLocation(newLocationWithFilterString));
		}
		if (filterStringChanged) {
			if (trimmedFilterString === '') {
				dispatch(clearFilteredResultAction());
			} else {
				dispatch(loadInbox());
			}
		}
	};
}

export function clearInboxFilter() {
	return (dispatch, getState) => {
		if (getInboxFilterString(getState()) !== undefined) {
			const currentLocation = locationSelector(getState());
			const newLocation = currentLocation.updateIn(['query'], Immutable.Map(),
				query => query.delete(INBOX_QUERY_FILTER_PARAM)
			);
			dispatch(pushLocation(newLocation));
		}
		dispatch(clearFilteredResultAction());
	};
}

export function loadInbox() {
	return (dispatch, getState) => {
		const filterString = getInboxFilterString(getState());
		if (filterString === undefined || filterString === '') {
			dispatch(loadInboxAction(reusingFilterInboxOperation('')));
		} else {
			dispatch(filterInboxAction(reusingFilterInboxOperation(filterString)));
		}
	};
}

/**
 * Modifies the done-state of the passed shares.
 *
 * The passed array of updates must contain objects of the following shape:
 * {
 *    // The patient id of the share to be updates
 *    patId: number,
 *    // The share id to be updates
 *    shareId: number,
 *    // Whether to mark the share done (true) or undone (false)
 *    done: (true|false)
 * }
 * @param updates - An array of updates as described above.
 * @return {(function(*): void)|*} - a thunk action performing the necessary updates.
 */
export function markSharesDone(updates) {
	return dispatch => {
		// Optimistically update local store
		dispatch(markSharesDoneLocally(updates));

		// Now actually perform remote update and maybe an undo on error
		markSharesDoneRemotely(updates, update => {
			dispatch(markSharesDoneLocally([{...update, done: !update.done}]));
		});
	};
}

function resetScrollPositionInLocation(location) {
	const scrollPositionHiddenStatePath = getScrollPositionPropertyPath(INBOX_SCROLL_COMPONENT_ID);
	return updateHiddenLocationState(location,
		hidden => hidden.setIn(scrollPositionHiddenStatePath, 0)
	);
}

function createInboxFetchAction(actionType) {
	const action = createAction(actionType);
	return function inboxFetchAction(promisedResult) {
		const randomValue = Math.random() * FETCH_ID_RANDOM_RANGE;
		const fetchId = `fetch_${Date.now()}_${randomValue}`;
		return async dispatch => {
			dispatch(createStandardAction(BEGIN_INBOX_FETCH, fetchId));
			try {
				dispatch(action({
					results: await promisedResult,
					fetchId
				}));
			} catch (error) {
				debugLog('Ignoring inbox load error for now.', error);
				dispatch(action({
					results: new ResultList(),
					fetchId
				}));
			}
		};
	};
}
