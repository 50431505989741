export const STEPPER_ID = 'archiveDocumentStepper';
export const NR_STEPS = 4;
export const DATA_FIELD_IMAGES = 'images';
export const DATA_FIELD_PRODUCER = 'producer';
export const DATA_FIELD_DOCUMENT_CLASS = 'documentClass';
export const DATA_FIELD_VISIT = 'visit';
export const DATA_FIELD_DESCRIPTION = 'description';
export const DATA_FIELD_UPLOAD_STATE = 'uploadState';

export const UPLOAD_STATE_UPLOAD_IDLE = 'stateUploadIdle';
export const UPLOAD_STATE_UPLOAD_IN_PROGRESS = 'stateUploadInProgress';
export const UPLOAD_STATE_ERROR = 'stateUploadError';
export const UPLOAD_STATE_SUCESS = 'stateUploadSuccess';

export const ARCHIVE_DOCUMENTS_METRICS_USAGE_CONTEXT = 'archivedocuments';
export const ARCHIVE_DOCUMENTS_DIALOG_ID = 'ArchiveDocumentsDialog';

export const MAXIMUM_DESCRIPTION_FIELD_LENGTH = 200;
