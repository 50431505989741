import React from 'react';

import useBrick from '../../../webview/bricks/hooks/useBrick.js';
import PatientContext from '../../bricks/PatientContext.js';
import PatientContextMonitor from '../../bricks/PatientContextMonitor.js';
import AlertDisplay from './AlertDisplay.js';

export default function OtherPatientsAlert() {
	const {hasUnconfirmed, confirm} = useBrick(PatientContextMonitor, selectPatientContextMonitorState);
	const patient = useBrick(PatientContext, context => context.getPatient());
	return hasUnconfirmed &&
		<AlertDisplay confirm={confirm} patient={patient} />;
}

function selectPatientContextMonitorState(patientContextMonitor) {
	return {
		hasUnconfirmed: patientContextMonitor.hasUnconfirmedOtherPatients(),
		confirm: patientContextMonitor.confirmOtherPatientWarning
	};
}
