import React from 'react';
import PropTypes from 'prop-types';

// https://www.w3.org/TR/wai-aria-practices-1.1/#TreeView
// https://www.w3.org/TR/wai-aria-1.1/#treeitem

function TreeViewItem(props) {
	const {className, ariaLevel, ariaSetSize, ariaPosInSet, ariaExpanded, children} = props;
	return (
		<li role='treeitem' className={className} aria-level={ariaLevel} aria-setsize={ariaSetSize}
		    aria-posinset={ariaPosInSet} aria-expanded={ariaExpanded}>
			{children}
		</li>
	);
}

TreeViewItem.propTypes = {
	className: PropTypes.string,
	ariaExpanded: PropTypes.bool.isRequired,
	ariaLevel: PropTypes.number.isRequired,
	ariaPosInSet: PropTypes.number.isRequired,
	ariaSetSize: PropTypes.number.isRequired
};

export default React.memo(TreeViewItem);
