import Immutable from 'immutable';
import {BATCH} from 'redux-batched-actions';
import {race, take} from 'redux-saga/effects';

import {VIEWER_STATE_META_INFO} from '../../constants/ViewerConstants.js';
import {VIEWER_SYNC_CHAINS} from '../constants/ViewerSyncStateFields.js';

export function getSyncChainID(viewerSyncToolsState, viewerId) {
	const chainId = viewerSyncToolsState
		.get(VIEWER_SYNC_CHAINS, Immutable.Map())
		.findKey(viewersInChain => (viewersInChain && viewersInChain.has(viewerId)));
	return chainId === undefined ? null : chainId;
}

export function getNextChainID(viewerSyncToolsState) {
	const maxChainID = viewerSyncToolsState
		.get(VIEWER_SYNC_CHAINS, Immutable.Map())
		.keySeq()
		.reduce((maxId, chainId) => Math.max(maxId, chainId), -1);
	return maxChainID + 1;
}

export function getViewersInChain(viewerSyncToolsState, chainId) {
	return viewerSyncToolsState
		.getIn([VIEWER_SYNC_CHAINS, chainId], Immutable.Set());
}

export function getViewerMetaInfo(viewerState, viewerId) {
	return viewerState.getIn([VIEWER_STATE_META_INFO, viewerId], null);
}

export function* takeBatched(processor, ...actionTypes) {
	const actionTypesSet = new Set(actionTypes);
	const {action, batch} = yield race({
		batch: take(isBatchAction),
		action: take(actionTypes)
	});

	if (batch) {
		const {payload: batchedActions} = batch;
		for (const batchedAction of batchedActions) {
			const {type: batchedType} = batchedAction;
			if (actionTypesSet.has(batchedType)) {
				yield* processor(batchedAction);
			}
		}
	} else if (action) {
		yield* processor(action);
	}
}

function isBatchAction(action) {
	const {type, meta} = action;
	return type === BATCH || meta && meta.batch;
}
