import React from 'react';
import {styled} from '@mui/material/styles';
import color from 'color';
import PropTypes from 'prop-types';

import {COLOR_PRIMARY, SYN_MUI_THEME} from '../constants/SynMUIOptions.js';

const DROPZONE_OPACITY = '0.5';
const BACKGROUND_BLUR = '3px';

const STYLES_OPTIONS = {
	shouldForwardProp: prop => !['visible', 'bgColor'].includes(prop)
};

const FROSTED_GLASS_STYLES = ({theme, visible, bgColor}) => ({
	backgroundColor: getBackgroundColor(visible, bgColor),
	color: theme.palette.primary.contrastText,
	zIndex: theme.zIndex.modal,
	visibility: visible ? 'visible' : 'hidden',
	backdropFilter: getBackDropFilter(visible),
	...getTransition(theme)
});

function getBackgroundColor(visible, bgColor) {
	return color(bgColor).alpha(visible ? DROPZONE_OPACITY : 0)
		.string();
}

function getTransition(theme) {
	return {
		transitionProperty: 'background, backdrop-filter',
		transitionDuration: `${theme.transitions.duration.shortest}ms`,
		transitionTimingFunction: theme.transitions.easing.easeInOut
	};
}

function getBackDropFilter(active) {
	return `blur(${active ? BACKGROUND_BLUR : '0px'})`;
}

export default function withFrostedGlassStyles(component) {
	const styledFrostedGlassComponent = styled(component, {name: 'FrostedGlass', ...STYLES_OPTIONS})(FROSTED_GLASS_STYLES);
	styledFrostedGlassComponent.propTypes = {
		visible: PropTypes.bool,
		bgColor: PropTypes.string
	};
	styledFrostedGlassComponent.defaultProps = {
		bgColor: SYN_MUI_THEME.palette[COLOR_PRIMARY].main
	};
	return styledFrostedGlassComponent;
}
