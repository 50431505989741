import ExtendableError from '../errors/ExtendableError.js';

/**
 * An error class which is used if the an image cannot be loaded for a given URL or BLOB object
 */
export default class ImageLoadError extends ExtendableError {
	constructor(message, imageURL) {
		super(message);
		/**
		 * @field {String} imageURL the url of the image which could not be loaded.
		 */
		this.imageURL = imageURL;
	}
}
