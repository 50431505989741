import React from 'react';
import MUICard from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import PropTypes from 'prop-types';

export default React.memo(OutlinedCard);

function OutlinedCard(props) {
	const {children, title} = props;
	return (
		<MUICard variant='outlined'>
			{title && <CardHeader title={title} />}
			{children}
		</MUICard>
	);
}

OutlinedCard.propTypes = {
	title: PropTypes.node
};
