import React from 'react';
import PropTypes from 'prop-types';

import {combineClassNames} from '../../commons/utils/StyleUtils.js';
import {H6, Subtitle1} from '../../ui/components/typography/Typography.js';

import '../../../styles/material-design/components/Title.scss';

/**
 * Provides a title element which can be used in a toolbar
 *
 * @class Title
 */
export default function Title(props) {
	const {id, children, className, subTitle, wrapperComponent: WrapperComponent} = props;
	return (
		<WrapperComponent className={combineClassNames('material-title', className)}>
			<H6 id={id}>
				{children}
			</H6>
			{subTitle && (
				<Subtitle1>
					{subTitle}
				</Subtitle1>
			)}
		</WrapperComponent>
	);
}

Title.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	subTitle: PropTypes.node,
	wrapperComponent: PropTypes.elementType
};

Title.defaultProps = {
	wrapperComponent: 'div'
};
