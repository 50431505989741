import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../i18n/components/SynFormattedMessage.js';
import WebViewMessagesTranslator from '../../../i18n/translators/WebViewMessagesTranslator.js';
import Button from '../../../ui/components/Button.js';
import NonDiagnosticUsageSnackBar from '../../../ui/components/NonDiagnosticUsageSnackBar.js';
import {COLOR_SECONDARY} from '../../../ui/constants/SynMUIOptions.js';
import {HELP_PATH} from '../../constants/CommonRoutingConstants.js';
import {WEBAPP_NAME} from '../../constants/EnvironmentConstants.js';
import {IS_RELEASE_BUILD} from '../../constants/SynSettingsConstants.js';
import LinkContainer from '../../containers/LinkContainer.js';
import Warning from '../text/Warning.js';

import '../../../../styles/commons/components/webview/NonDiagnosticUsageMessage.scss';

// !!! IMPORTANT !!!
// This chapter id must be the same as the id of the appendix chapter containing the CE-Marking at the bottom of
// doc/de/clientdoku/Web/synedra_Web.xml
// TODO RBi, PSp: Can we automatically extract the id from the above file?
const CE_MARKING_CHAPTER_ID = 'a_eec_marking';
// !!! IMPORTANT !!!

const NON_DIAGNOSTIC_USAGE_WARNING = <SynFormattedMessage translator={WebViewMessagesTranslator} message='NonDiagnosticUsage' />;
const NON_DIAGNOSTIC_USAGE_WARNING_FURTHER_INFORMATION = <SynFormattedMessage key='further-info' translator={WebViewMessagesTranslator} message={IS_RELEASE_BUILD ? 'NonDiagnosticUsageFurtherInformation' : 'NonDiagnosticUsageFurtherInformationPlaceholder'} />;
const NON_DIAGNOSTIC_USAGE_WARNING_DOKU_LINK_LABEL = <SynFormattedMessage translator={WebViewMessagesTranslator} message={IS_RELEASE_BUILD ? 'NonDiagnosticUsageDocuLinkLabel' : 'NonDiagnosticUsageDocuLinkLabelPlaceholder'} />;
const ACCEPT_BUTTON_MESSAGE = <SynFormattedMessage translator={WebViewMessagesTranslator} message='NonDiagnosticUsageAcceptButtonTitle' />;
const NON_DIAGNOSTIC_USAGE_LINK_PROPS = {
	className: 'non-diagnostic-usage-message--help-link',
	path: HELP_PATH,
	fragment: IS_RELEASE_BUILD ? CE_MARKING_CHAPTER_ID : ''
};

const FURTHER_INFO_ELEMENTS = [
	<span key='further-info--space'>
&nbsp;
	</span>,
	NON_DIAGNOSTIC_USAGE_WARNING_FURTHER_INFORMATION,
	<LinkContainer key='further-info--link-container' {...NON_DIAGNOSTIC_USAGE_LINK_PROPS}>
		{NON_DIAGNOSTIC_USAGE_WARNING_DOKU_LINK_LABEL}
	</LinkContainer>
];

export default class NonDiagnosticUsageMessage extends React.Component {
	render() {
		return this.shouldRender() ? this.renderMessage() : false;
	}

	renderMessage() {
		const {onAccept} = this.props;
		const message = (
			<Warning>
				{NON_DIAGNOSTIC_USAGE_WARNING}
				{WEBAPP_NAME === 'webview' && FURTHER_INFO_ELEMENTS}
			</Warning>
		);
		const action =	(
			<Button flat opaque color={COLOR_SECONDARY} onClick={onAccept}>
				{ACCEPT_BUTTON_MESSAGE}
			</Button>
		);
		return (
			<NonDiagnosticUsageSnackBar buttonElem={action}>
				{message}
			</NonDiagnosticUsageSnackBar>
		);
	}

	shouldRender() {
		const {show, isPrintPreview} = this.props;
		return show && !isPrintPreview;
	}
}

NonDiagnosticUsageMessage.propTypes = {
	show: PropTypes.bool,
	isPrintPreview: PropTypes.bool,
	onAccept: PropTypes.func
};
