// https://wiki.ubuntu.com/UnitsPolicy

const DATA_SIZE_UNITS = [
	'Byte',
	'KByte',
	'MegaByte',
	'GigaByte',
	'TeraByte',
	'PetaByte',
	'ExaByte',
	'ZettaByte',
	'YottaByte'
];

export const IEC_FACTOR = 1024;
const SI_FACTOR = 1000;

export function getHumanReadableDataSize(inputAmountBytes, iec = false) {
	let amount = NaN;
	let unit = '';
	if (Number.isSafeInteger(inputAmountBytes)) {
		const amountBytes = Math.abs(inputAmountBytes);
		const factor = iec ? IEC_FACTOR : SI_FACTOR;
		const units = DATA_SIZE_UNITS.map(unitPrefix => unitPrefix + (iec ? 'IEC' : 'SI'));
		const i = (amountBytes === 0) ? 0 : Math.min(
			Math.floor(Math.log(amountBytes) / Math.log(factor)), units.length - 1
		);
		amount = (amountBytes / Math.pow(factor, i));
		unit = units[i];
	}
	return {amount, unit};
}

/**
 * Returns the matching entry from the list of unit descriptions.
 *
 * This entry satisfies: (value * unit.factor) >= 1
 * So an entry for calculating cm from mm might look like this:
 * {
 *    factor: 0.1,
 *    symbol: 'mm'
 * }
 * For this entry an input value of 10 satisfies the condition: 10 * 0.1 >= 1
 * NOTE: The order in wich the unit descriptions are passed is crucial to the result.
 *
 * @param value - value to be probed against the unitDescriptions.
 * @param unitDescriptions - Array of unit descriptions like the one returned.
 * @return {{factor: number, symbol: string}}
 */
export function findProperUnit(value, unitDescriptions) {
	const absoluteValue = Math.abs(value);
	const result = unitDescriptions.find(unit => (absoluteValue * unit.factor) >= 1);
	return result ? result : unitDescriptions[unitDescriptions.length - 1];
}

