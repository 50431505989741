import React from 'react';
import PropTypes from 'prop-types';

import '../../../styles/viewer/components/MetaDataOverlay.scss';

export default function MetaDataOverlay(props) {
	const {containerWidth, containerHeight, margins, children} = props;
	const mappedChildren = React.Children.map(
		children,
		child => React.cloneElement(child, {containerWidth, containerHeight, margins})
	);
	return (
		<svg className='meta-data-overlay' viewBox={`0 0 ${containerWidth} ${containerHeight}`} style={{height: `${containerHeight}px`}}>
			{mappedChildren}
		</svg>
	);
}

MetaDataOverlay.propTypes = {
	containerWidth: PropTypes.number.isRequired,
	containerHeight: PropTypes.number.isRequired,
	margins: PropTypes.object
};
