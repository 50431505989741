import React from 'react';
import {styled} from '@mui/material/styles';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import UploadFilesBrowserController from '../bricks/UploadFilesBrowserController.js';
import DialogBasedUploadFilesList from './DialogBasedUploadFilesList.js';
import UploadFilesGrid from './UploadFilesGrid/UploadFilesGrid.js';
import UploadFilesList from './UploadFilesList/index.js';

const DialogUploadFilesGrid = styled(UploadFilesGrid, {name: 'UploadFilesGrid'})({
	padding: '0 8px'
});

export default function DialogBasedUploadFilesBrowser(props) {
	const {showButton} = props;
	const {isGridMode} = useBrick(UploadFilesBrowserController, selectFileListSettings);
	return (
		<DialogBasedUploadFilesList showButton={showButton}>
			{isGridMode ? <DialogUploadFilesGrid /> : <UploadFilesList />}
		</DialogBasedUploadFilesList>
	);
}

DialogBasedUploadFilesBrowser.propTypes = DialogBasedUploadFilesList.propTypes;
DialogBasedUploadFilesBrowser.defaultProps = DialogBasedUploadFilesList.defaultProps;

function selectFileListSettings(service) {
	return {
		isGridMode: service.isViewModeGrid()
	};
}
