import _once from 'lodash.once';

const I_DEVICES = [
	'iPad Simulator',
	'iPhone Simulator',
	'iPod Simulator',
	'iPad',
	'iPhone',
	'iPod'
];

/**
 * Tries to detect a Safari browser running on iOS.
 * @returns {boolean} true if we think this is a Safari browser running on iOS.
 */
export function mightBeIOS() {
	return memoizedDetectIOS();
}

const memoizedDetectIOS = _once(detectIOS);

function detectIOS() {
	const platform = navigator.platform;
	return I_DEVICES.includes(platform) ||
		platform === 'MacIntel' && navigator.maxTouchPoints > 1;
}

/**
 * Tries to detect a browser engine, written by Apple Inc.
 * @returns {boolean} true if we think this is a Safari browser.
 */
export function mightBeSafari() {
	let isSafariBrowser = false;
	if (navigator.vendor) {
		isSafariBrowser = navigator.vendor.toLowerCase() === 'apple computer, inc.';
	}
	return isSafariBrowser;
}

/**
 * Returns a promise resolving to true if the browser has any kind of video source.
 *
 * @returns {Promise<boolean>} true if video source is available, false otherwise.
 */
export async function deviceHasVideoSource() {
	let devices = [];
	if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
		const enumerateDevices = navigator.mediaDevices.enumerateDevices();
		if (enumerateDevices && enumerateDevices.then) {
			devices = await navigator.mediaDevices.enumerateDevices();
		}
	}
	return devices.some(device => device.kind === 'video' || device.kind === 'videoinput');
}

/**
 * Checks whether the browser supports the Clipboard API
 * NOTE: This function does not use the Permission API to check for clipboard access!
 *
 * [1] - https://developer.mozilla.org/en-US/docs/Web/API/Clipboard
 * [2] - https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
 */
export function hasClipboardSupport() {
	return Boolean(navigator.clipboard) && (typeof navigator.clipboard.writeText === 'function');
}
