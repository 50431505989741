import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Region from '../../a11y/components/landmarks/Region.js';
import A11yTranslator from '../../a11y/i18n/A11yTranslator.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import {fromUrlString} from '../../router/LocationUtils.js';
import {callSafe, noop} from '../utils/FunctionUtils.js';
import {notNullOrEmpty} from '../utils/StringUtils.js';
import {combineClassNames} from '../utils/StyleUtils.js';
import {webAppURL} from '../WebViewHelpers.js';
import {SynIFrameLoader} from './SynIFrameLoader.js';

import '../../../styles/commons/components/webview/IFrameStyles.scss';
import '../../../styles/commons/components/webview/SynIFrame.scss';

const CLASS_PREFIX = 'iframe-with-id-';

class SynIFrame extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnLoad = this.#onLoad.bind(this);
		this.boundOnBeforeLoad = this.#onBeforeLoad.bind(this);
		this.clonedContentRef = React.createRef();
		this.state = {
			iframeId: null,
			loaded: false
		};
	}
	
	render() {
		const {locale, className, ariaLabel, src, sandbox, onWheelCapture} = this.props;
		const {iframeId, loaded} = this.state;
		const usedAriaLabel = ariaLabel === undefined
			? A11yTranslator.getFormattedMessage('A11YTemplateLabel', locale)
			: ariaLabel;
		const iframeClasses = combineClassNames(
			classnames({
				[CLASS_PREFIX + iframeId]: true,
				'syn-iframe-container': true,
				hidden: !loaded
			}),
			className
		);

		return (
			<Region className={iframeClasses} title={`${usedAriaLabel} ${iframeId + 1}`} onWheelCapture={onWheelCapture}>
				<div ref={this.clonedContentRef} className='syn-iframe--cloned-content' />
				<SynIFrameLoader src={src} sandbox={sandbox} locale={locale}
									  onBeforeLoad={this.boundOnBeforeLoad} onLoad={this.boundOnLoad} />
			</Region>
		);
	}

	#onBeforeLoad() {
		if (this.clonedContentRef.current) {
			let firstChild = this.clonedContentRef.current.firstChild;
			while (firstChild !== null) {
				this.clonedContentRef.current.removeChild(firstChild);
				firstChild = this.clonedContentRef.current.firstChild;
			}
			this.setIFrameId(null);
		}
	}

	#onLoad(iframeId, iframeDocument) {
		const style = document.createElement('style');
		style.appendChild(document.createTextNode(' '));
		this.clonedContentRef.current.appendChild(style);

		const targetStyleSheet = style.sheet;
		let targetIndex = 0;
		extractTextRules(iframeDocument)
			.forEach(rule => addCSSRule(iframeId, targetStyleSheet, rule, targetIndex++));

		const [bodyNode] = iframeDocument.getElementsByTagName('body');
		removeTags(bodyNode, 'script', 'link');
		Array.from(bodyNode.getElementsByTagName('img'))
			  .forEach(this.toAbsoluteImageSource, this);
		importChildren(bodyNode, this.clonedContentRef.current);

		Array.from(this.clonedContentRef.current.getElementsByTagName('a'))
			.forEach(anchor => this.modifyAnchor(anchor));

		const imageTags = Array.from(this.clonedContentRef.current.getElementsByTagName('img'));
		Promise.all(imageTags.map(waitForImageToBeLoaded))
			.then(() => this.setIFrameId(iframeId));
	}

	modifyAnchor(anchor) {
		const {
			disableExternalLinks,
			showPrivacyPolicyDialog = noop
		} = this.props;
		SynIFrame.enforceLinkTypesPolicy(anchor);
		if (SynIFrame.isPrivacyPolicyLink(anchor)) {
			SynIFrame.redirectAnchor(anchor, showPrivacyPolicyDialog);
		} else if (disableExternalLinks && !SynIFrame.isFragmentAnchor(anchor)) {
			SynIFrame.disableAnchor(anchor);
		}
	}

	static enforceLinkTypesPolicy(linkTag) {
		if (linkTag.getAttribute('target') === '_blank') {
			const relValue = linkTag.hasAttribute('rel') ? linkTag.getAttribute('rel') : '';
			const linkTypes = new Set(relValue.split(' ').filter(notNullOrEmpty));
			linkTypes.add('noopener');
			linkTypes.add('noreferrer');
			linkTag.setAttribute('rel', Array.from(linkTypes).join(' '));
		}
	}

	static isPrivacyPolicyLink(anchorElement) {
		return anchorElement.getAttribute('href') === webAppURL('privacypolicy');
	}

	static isFragmentAnchor(anchorElement) {
		const href = anchorElement.getAttribute('href');
		return href.startsWith('#');
	}

	static disableAnchor(anchorElement) {
		anchorElement.setAttribute('href', '#');
	}

	static redirectAnchor(anchorElement, redirectFunction) {
		const originalOnClick = anchorElement.onclick;
		anchorElement.setAttribute('href', '');
		anchorElement.onclick = e => {
			callSafe(originalOnClick, e);
			e.preventDefault();
			redirectFunction();
		};
	}

	setIFrameId(newIFrameId) {
		const isLoaded = newIFrameId !== null;
		this.setState({
			iframeId: newIFrameId,
			loaded: isLoaded
		});
	}

	componentWillUnmount() {
		this.setIFrameId = noop;
	}

	toAbsoluteImageSource(image) {
		const isAbsolutePattern = /^(https?:\/\/)|(data)/i;
		if (isAbsolutePattern.test(image.src)) {
			image.src = `${image.src}`;
		} else {
			const {base: {href}} = this.props;
			image.src = `${href}${image.src}`;
		}
	}

	scrollToIFrameFragment() {
		const {src} = this.props;
		const locationFragment = fromUrlString(src).get('fragment', '');
		if (locationFragment) {
			const anchorElement = this.clonedContentRef.current.querySelector(`a[name='${locationFragment}']`);
			if (anchorElement) {
				anchorElement.scrollIntoView(true);
			}
		}
	}

	componentDidUpdate() {
		const {loaded} = this.state;
		if (loaded) {
			this.scrollToIFrameFragment();
		}
	}
}

function addCSSRule(iframeId, sheet, rule, index) {
	const {selectorText, style: {cssText}} = rule;
	const modifiedSelector = selectorText
		.split(',')
		.map(selector => `.${CLASS_PREFIX}${iframeId} ${selector}`)
		.join(',');
	if ('insertRule' in sheet) {
		sheet.insertRule(`${modifiedSelector}{${cssText}}`, index);
	} else if ('addRule' in sheet) {
		sheet.addRule(modifiedSelector, cssText, index);
	}
}

SynIFrame.propTypes = {
	sandbox: SynIFrameLoader.propTypes.sandbox,
	className: PropTypes.string,
	src: PropTypes.string.isRequired,
	base: PropTypes.object,
	showPrivacyPolicyDialog: PropTypes.func,
	locale: PropTypes.string,
	ariaLabel: PropTypes.string,
	disableExternalLinks: PropTypes.bool,
	onWheelCapture: PropTypes.func
};

SynIFrame.defaultProps = {
	base: {
		href: '',
		target: '_top'
	},
	disableExternalLinks: false
};

function removeTags(parentNode, ...tagsToRemove) {
	tagsToRemove.forEach(tag => {
		const elements = Array.from(parentNode.getElementsByTagName(tag));
		elements.forEach(element => element.parentNode.removeChild(element));
	});
}

function importChildren(fromNode, toNode) {
	const children = Array.from(fromNode.childNodes);
	children.forEach(child => {
		const newChild = document.importNode(child, true);
		toNode.appendChild(newChild);
	});
}

function waitForImageToBeLoaded(imageNode) {
	return new Promise(resolve => {
		if (imageNode.complete) {
			resolve();
		} else {
			const boundResolve = () => resolve();
			imageNode.addEventListener('load', boundResolve);
			imageNode.addEventListener('error', boundResolve);
		}
	});
}

function extractTextRules(iframeDocument) {
	const styleSheets = Array.from(iframeDocument.styleSheets);
	return styleSheets
		.map(sheet => Array.from(sheet.cssRules))
		.reduce((allRules, rules) => allRules.concat(rules), [])
		.filter(isRuleWithTextSelector);
}

function isRuleWithTextSelector(rule) {
	return Boolean(rule.selectorText) && Boolean(rule.style);
}

export default wrapInLocalizationContext(SynIFrame);
