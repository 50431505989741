import React from 'react';
import PropTypes from 'prop-types';

import LandingPage from '../../commons/components/webview/LandingPage.js';
import LoadingIndicatorOverlay from '../../commons/components/webview/LoadingIndicatorOverlay.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';
import generateUniqueKey from '../../commons/utils/generateUniqueKey.js';
import {wrapInLocalizationContext} from '../../i18n/components/LocalizationContextWrapper.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import PasswordResetMessagesTranslator from '../i18n/PasswordResetMessagesTranslator.js';
import PasswordResetError from './PasswordResetError.js';
import PasswordResetForm from './PasswordResetForm.js';
import PasswordResetSuccess from './PasswordResetSuccess.js';

class RequestPasswordResetPage extends React.PureComponent {
	constructor(props, context) {
		super(props, context);
		this.boundOnSubmit = this.onSubmit.bind(this);
		this.formId = `passwordResetForm${generateUniqueKey()}`;
	}

	render() {
		const {locale} = this.props;
		const title = <SynFormattedMessage translator={PasswordResetMessagesTranslator} message='PasswordResetTitle' />;
		const loadingIndicatorLabel = PasswordResetMessagesTranslator.getFormattedMessage('PasswordResetTitle', locale);
		const {isBusy} = this.props;
		return (
			<LandingPage headerTemplate='loginPage_header' footerTemplate='loginPage_footer' title={title}>
				{this.renderContentComponent()}
				<LoadingIndicatorOverlay loading={isBusy} label={loadingIndicatorLabel} />
			</LandingPage>
		);
	}

	renderContentComponent() {
		const {requestSucceeded, passwordError} = this.props;
		let content;
		if (requestSucceeded) {
			content = <PasswordResetSuccess />;
		} else if (passwordError) {
			content = <PasswordResetError />;
		} else {
			content = <PasswordResetForm id={this.formId} onSubmit={this.boundOnSubmit} />;
		}
		return content;
	}

	onSubmit(formValues) {
		const {username} = formValues;
		const {requestPasswordReset} = this.props;
		callSafe(requestPasswordReset, username);
	}

	componentDidMount() {
		const {isLoggedIn, logout, resetPasswordState} = this.props;
		resetPasswordState();
		if (isLoggedIn) {
			logout();
		}
	}

	componentWillUnmount() {
		const {resetPasswordState} = this.props;
		resetPasswordState();
	}
}

RequestPasswordResetPage.propTypes = {
	requestSucceeded: PropTypes.bool,
	passwordError: PropTypes.string,
	requestPasswordReset: PropTypes.func,
	resetPasswordState: PropTypes.func,
	isBusy: PropTypes.bool,
	isLoggedIn: PropTypes.bool,
	logout: PropTypes.func,
	locale: PropTypes.string
};

RequestPasswordResetPage.defaultProps = {
	isBusy: false
};

export default wrapInLocalizationContext(RequestPasswordResetPage);
