import Immutable from 'immutable';

import {BYTES_PER_RGBA_PIXEL} from '../../../viewer/CanvasRendering.worker';
import incorporatePixelOffsetValueIntoWindowingParamters
	from '../../../viewer/components/incorporatePixelOffsetValueIntoWindowingParameters.js';
import {
	FIELD_COLOR_DEPTHS,
	FIELD_DOM_IMAGES,
	FIELD_DOM_OVERLAY_IMAGES,
	FIELD_IMAGE_HEIGHT,
	FIELD_IMAGE_SCALE,
	FIELD_IMAGE_WIDTH,
	FIELD_INITIAL_WINDOW,
	FIELD_INSTANCE_NUMBER,
	FIELD_INVERT_OUTPUT_COLORS,
	FIELD_MAX_PIXEL_VALUE,
	FIELD_MIN_PIXEL_VALUE,
	FIELD_PIXEL_ASPECT_RATIO,
	FIELD_PIXEL_SPACING,
	FIELD_PIXEL_VALUE_OFFSET,
	FIELD_RAW_IMAGE_BLOBS,
	FIELD_RAW_OVERLAY_IMAGE_BLOBS,
	HEADER_LENGTH_SIZE_BYTES,
	IMAGE_TYPE_OVERLAY,
	IMAGE_TYPE_PIXELS
} from '../../constants/SynAdvancedImageConstants.js';
import {readBlobAsArrayBuffer, readBlobAsJSON} from '../../utils/BlobUtils.js';
import {convertToBlobUrls, decodedBlobUrls, loadImageDataFromBlobs, releaseImage} from '../../utils/ImageUtils.js';
import {readUnsignedLong} from '../../utils/TypedArrayUtils.js';

const LONG_BYTE_SIZE = 4;
const COLOR_DEPTH_16_BITS = 16;
const COLOR_DEPTH_16_WHITE = 0xffff;
const COLOR_DEPTH_8_WHITE = 0xff;
const COLOR_DEPTH_32_BITS = 32;
const BITS_PER_BYTE = 8;

/**
 * Simple utility function to initialize a syn advanced image with basic properties of the image.
 * @param width {Number} - Width in pixels of the image
 * @param height {Number} - Height in pixels of the image
 * @param rawImageBlobs - raw image Blobs.
 * @returns {Immutable.Map} containing the provided properties.
 */
export function createSynAdvancedImage(width, height, rawImageBlobs) {
	return createSynAdvancedImageFromImmutableMap(Immutable.fromJS({
		[FIELD_IMAGE_WIDTH]: width,
		[FIELD_IMAGE_HEIGHT]: height,
		[FIELD_RAW_IMAGE_BLOBS]: rawImageBlobs
	}));
}

let instanceCounter = 0;
function createSynAdvancedImageFromImmutableMap(immutableMap) {
	const thisInstanceNumber = instanceCounter++;
	return immutableMap.set(FIELD_INSTANCE_NUMBER, thisInstanceNumber);
}

export function getInvertOutputColors(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_INVERT_OUTPUT_COLORS, false);
}

export function getMinPixelValue(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_MIN_PIXEL_VALUE, 0);
}

export function getMaxPixelValue(synAdvancedImage) {
	const is16bitGrayScale = getColorDepth(synAdvancedImage) === COLOR_DEPTH_16_BITS;
	const maxValue = is16bitGrayScale ? COLOR_DEPTH_16_WHITE : COLOR_DEPTH_8_WHITE;
	return getFieldOrDefault(synAdvancedImage, FIELD_MAX_PIXEL_VALUE, maxValue);
}

export function getPixelValueInformation(synAdvancedImage) {
	const minValue = getMinPixelValue(synAdvancedImage);
	const maxValue = getMaxPixelValue(synAdvancedImage);
	const valueRange = maxValue - minValue;
	return {
		minPixelValue: minValue,
		maxPixelValue: maxValue,
		pixelValueRange: valueRange
	};
}

export function getMinMaxWindow(synAdvancedImage) {
	const mightBeSynAdvancedImage = (Boolean(synAdvancedImage) && Boolean(synAdvancedImage.get));
	return mightBeSynAdvancedImage ? getMinMaxWindowChecked(synAdvancedImage) : null;
}

function getMinMaxWindowChecked(synAdvancedImage) {
	const {
		minPixelValue,
		pixelValueRange
	} = getPixelValueInformation(synAdvancedImage);
	const pixelCenter = minPixelValue + (pixelValueRange / 2);
	return {
		center: pixelCenter,
		width: pixelValueRange + 1
	};
}
export function releaseAllDecodedImages(synAdvancedImage) {
	// NOTE: Images in FIELD_DOM_IMAGES have already been released during pixel extraction in loadImageDataFromBlobs
	getFieldOrDefault(synAdvancedImage, FIELD_DOM_OVERLAY_IMAGES, Immutable.List()).forEach(releaseImage);
	return synAdvancedImage
		.delete(FIELD_DOM_IMAGES)
		.delete(FIELD_DOM_OVERLAY_IMAGES);
}

export function isMultiByteImage(synAdvancedImage) {
	return getRawImages(synAdvancedImage).size > 1;
}

export function getColorDepth(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_COLOR_DEPTHS, COLOR_DEPTH_32_BITS);
}

export function getPixelOffsetValue(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_PIXEL_VALUE_OFFSET, 0);
}

export function getInitialWindow(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_INITIAL_WINDOW, Immutable.Map());
}

export function getWidth(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_IMAGE_WIDTH, 0);
}

export function getHeight(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_IMAGE_HEIGHT, 0);
}

export function getPixelSpacing(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_PIXEL_SPACING, null);
}

export function getPixelAspectRatio(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_PIXEL_ASPECT_RATIO, null);
}

export function getImageScale(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_IMAGE_SCALE, Immutable.List([1.0, 1.0]));
}

export function isDecoded(synAdvancedImage) {
	return Boolean(getFieldOrDefault(synAdvancedImage, FIELD_DOM_IMAGES, null));
}

export function getInstanceNumber(synAdvancedImage) {
	return synAdvancedImage ? synAdvancedImage.get(FIELD_INSTANCE_NUMBER, -1) : -1;
}

/**
 * returns the pixel spacing based pixel aspect ratio if no pixel aspect ratio is defined.
 * If neither pixel spacing nor pixel aspect ratio are defined a ratio of [1,1] is returned
 *
 * @param synAdvancedImage th
 */
export function getUnifiedPixelAspectRatio(synAdvancedImage) {
	const pixelAspectRatio = getPixelAspectRatio(synAdvancedImage);
	const pixelSpacing = getPixelSpacing(synAdvancedImage);

	return pixelAspectRatio || pixelSpacing || Immutable.List([1, 1]);
}

/**
 * return the ratio between the pixel height and the pixel width
 * @param synAdvancedImage
 * @returns {number} pixelHeight/pixelWidth
 */
export function getHeightToWidthPixelSizeRatio(synAdvancedImage) {
	const ratio = getUnifiedPixelAspectRatio(synAdvancedImage);

	return ratio && ratio.get(0) / ratio.get(1);
}

export function getDomImages(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_DOM_IMAGES, Immutable.List());
}

export function getOverlayDomImages(synAdvancedImage) {
	return getFieldOrDefault(synAdvancedImage, FIELD_DOM_OVERLAY_IMAGES, Immutable.List());
}

function getFieldOrDefault(synAdvancedImage, field, defaultValue) {
	return (Boolean(synAdvancedImage) && Boolean(synAdvancedImage.get))
		? synAdvancedImage.get(field, defaultValue)
		: defaultValue;
}

/**
 * Decodes the passed Blob into a syn-advanced-image containing at leas the following information:
 *  - width: the images width
 *  - height: the images height
 *  - encapsulatedMimeType: the image/* mime type of the fragment images
 *  - rawImageBlobs: an Immutable.List() containing all raw images blobs (encoded using encapsulatedMimeType)
 *
 * @param blob {Blob} the blob to decode the image from
 * @return {Immutable.Map} containing the decoded image.
 * @throws {Error} if any error occurs during decoding.
 */
export function decodeAdvancedImageFromBlob(blob) {
	return readHeaderSizeAndHeader(returnCheckedBlobOrThrow(HEADER_LENGTH_SIZE_BYTES, blob))
		.then(({header, images, rawImagesBlob}) => createSynAdvancedImageFromImmutableMap(
			header.merge(spliceImageBlobs(rawImagesBlob, images))
		)
		);
}

function readHeaderSizeAndHeader(checkedBlob) {
	return readBlobAsArrayBuffer(checkedBlob.slice(0, LONG_BYTE_SIZE))
		.then(readUnsignedLong)
		.then(headerSize => {
			const lengthCheckedBlob = returnCheckedBlobOrThrow(HEADER_LENGTH_SIZE_BYTES + headerSize, checkedBlob);
			return readHeader(headerSize, lengthCheckedBlob);
		});
}

function readHeader(headerSize, checkedBlob) {
	const imageDataOffset = HEADER_LENGTH_SIZE_BYTES + headerSize;
	return readBlobAsJSON(checkedBlob.slice(HEADER_LENGTH_SIZE_BYTES, imageDataOffset))
		.then(finalHeader => {
			const {images} = finalHeader;
			delete finalHeader.images;
			return {
				header: Immutable.fromJS(finalHeader),
				images,
				rawImagesBlob: checkedBlob.slice(imageDataOffset)
			};
		});
}

function spliceImageBlobs(imagesBlob, imageInfos) {
	if (imageInfos === undefined || imageInfos === null || imageInfos.length === 0) {
		throw Error('Encountered invalid field: images');
	}
	const sizeOfAllImages = imageInfos.reduce((sum, {size}) => sum + size, 0);
	return imageInfos.reduce(([imageBlobs, remainingBlob], {type, mimeType, size}) => [
		imageBlobs.update(
			getFieldForImageType(type), Immutable.List(),
			images => images.push(remainingBlob.slice(0, size, mimeType))
		),
		remainingBlob.slice(size)
	], [Immutable.Map(), returnCheckedBlobOrThrow(sizeOfAllImages, imagesBlob)])[0];
}

function getFieldForImageType(imageType) {
	let type;
	switch (imageType) {
		case IMAGE_TYPE_PIXELS:
			type = FIELD_RAW_IMAGE_BLOBS;
			break;
		case IMAGE_TYPE_OVERLAY:
			type = FIELD_RAW_OVERLAY_IMAGE_BLOBS;
			break;
		default:
			throw new Error(`Unsupported image type: ${imageType}`);
	}
	return type;
}

function returnCheckedBlobOrThrow(expectedLength, blob) {
	if (blob.size < expectedLength) {
		throw new Error(`Blob to short: ${blob.size} < ${expectedLength}`);
	}
	return blob;
}

export function getRenderingParameters(windowCenter, windowWidth, decodedImage) {
	const {
		windowCenter: actualWindowCenter, windowWidth: actualWindowWidth
	} = incorporatePixelOffsetValueIntoWindowingParamters(windowCenter, windowWidth, decodedImage);
	const pixelValueOffset = getPixelOffsetValue(decodedImage);
	const minPixelValue = getMinPixelValue(decodedImage) - pixelValueOffset;
	const maxPixelValue = getMaxPixelValue(decodedImage) - pixelValueOffset;
	return {
		windowCenter: actualWindowCenter,
		windowWidth: actualWindowWidth,
		minPixelValue,
		maxPixelValue,
		invertOutput: getInvertOutputColors(decodedImage)
	};
}

/***
 * Returns the grayscale value of the pixel at a given point in the synAdvancedImage.
 * The pixel is selected by the given point at pointX and pointX.
 * This point has his origin in the center of the image.
 * Returns null if there is no pixel at the given point.
 * @param synAdvancedImage
 * @param pointX {float} - x component as float of the given point
 * @param pointY {float} - y component as float of the given point
 * @returns {null|int} the grayscale value of the pixel found at the point
 */
export function getGrayscaleValue(synAdvancedImage, pointX, pointY) {
	let pixelValue = null;
	const width = getWidth(synAdvancedImage);
	const height = getHeight(synAdvancedImage);
	const domImages = getDomImages(synAdvancedImage);
	const pixelPos = transformPointToPixelPosition(pointX, pointY, width, height);
	if (pixelPos !== null && !domImages.isEmpty()) {
		const pixelValueOffset = getPixelOffsetValue(synAdvancedImage);
		const [x, y] = pixelPos;
		const index = y * width + x;
		const rgbSampleIndex = index * BYTES_PER_RGBA_PIXEL;
		const originalValue = domImages.reduce((combinedValue, partialImage, imageIndex) => {
			const partialValue = partialImage.data[rgbSampleIndex];
			const valueShift = imageIndex * BITS_PER_BYTE;
			return combinedValue + (partialValue << valueShift);
		}, 0);
		pixelValue = originalValue + pixelValueOffset;
	}
	return pixelValue;
}

function transformPointToPixelPosition(pointX, pointY, width, height) {
	let imagePosition = null;
	const offsetX = Math.floor(width / 2);
	const offsetY = Math.floor(height / 2);
	const posXInt = width % 2 > 0 ? Math.round(pointX) : Math.floor(pointX);
	const posYInt = height % 2 > 0 ? Math.round(pointY) : Math.floor(pointY);
	const x = posXInt + offsetX;
	const y = posYInt + offsetY;
	if (x < width && y < height && x >= 0 && y >= 0) {
		imagePosition = [x, y];
	}
	return imagePosition;
}

/**
 * Given a synAdvancedImage this function loads a decodedImage for each of the contained rawImages.
 *
 * @param advancedImage an advanced Image, which is an Immutable.Map containing the following properties:
 *     (width, height, encapsulatedMimeType,rawImageBlobs)
 *
 * @return a promise which resolves to a new advancedImage containing a property domImages.
 *     The property decodedImage holds an Immutable.List containing a decodedImage for each Blob in rawImageBlobs
 */
export function loadSynAdvancedDomImages(advancedImage) {
	const rawImageBlobs = getRawImages(advancedImage);
	const promiseForDecodedImages = loadImageDataFromBlobs(rawImageBlobs);
	const overlays = advancedImage.get(FIELD_RAW_OVERLAY_IMAGE_BLOBS, Immutable.List());
	const promiseForDecodedOverlays = decodedBlobUrls(convertToBlobUrls(overlays));
	return Promise.all([promiseForDecodedImages, promiseForDecodedOverlays])
		.then(([domImages, domOverlayImages]) => advancedImage
			.set(FIELD_DOM_IMAGES, Immutable.List(domImages))
			.set(FIELD_DOM_OVERLAY_IMAGES, Immutable.List(domOverlayImages))
		);
}

function getRawImages(synAdvancedImage) {
	return synAdvancedImage.get(FIELD_RAW_IMAGE_BLOBS, Immutable.List());
}
