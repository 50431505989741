import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import MessageTranslator from '../../i18n/translators/MessageTranslator.js';
import TwoLineListItem from '../../material-design/components/layout/TwoLineListItem.js';
import PrimaryItemText from '../../material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../material-design/components/text/SecondaryItemText.js';

export default class PropertyItem extends React.PureComponent {
	render() {
		const {name, children, translator, ...remainingProps} = this.props;
		return (
			<TwoLineListItem key={name} {...remainingProps}>
				<PrimaryItemText>
					{children}
				</PrimaryItemText>
				<SecondaryItemText>
					<SynFormattedMessage message={name} translator={translator} />
				</SecondaryItemText>
			</TwoLineListItem>
		);
	}
}

PropertyItem.propTypes = {
	...TwoLineListItem.propTypes,
	name: PropTypes.string.isRequired,
	translator: PropTypes.instanceOf(MessageTranslator),
	children: PropTypes.node.isRequired
};
