import {END} from 'redux-saga';
import {call, cancelled, put, take} from 'redux-saga/effects';

import PasswordChangeError from '../errors/PasswordChangeError.js';
import {beginPasswordOperation, finishPasswordOperation} from '../flux/actions/PasswordActions.js';

function* finishWithError(error) {
	yield put(finishPasswordOperation(new PasswordChangeError(error)));
}

export default function* passwordOperationSaga(actionType, passwordOperation) {
	let nextAction = null;
	while (nextAction !== END && !(yield cancelled())) {
		nextAction = yield take(actionType);
		if (nextAction.type === actionType) {
			const {username} = nextAction.payload;
			yield put(beginPasswordOperation(username));
			try {
				yield call(passwordOperation, nextAction.payload);
				yield put(finishPasswordOperation());
			} catch (error) {
				yield call(finishWithError, error);
			}
		}
	}
}
