import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {uiWindowSize} from '../../ui/flux/UISelectors.js';
import {closePersistentPortal, updatePersistentPortal} from '../actions/PersistentPortalActions.js';
import {isPortalActive} from '../selectors/PersistentPortalsSelectors.js';
import {bindActions} from '../utils/FluxUtils.js';

export default function createPersistentPortalAnchor(Component) {
	return connect(
		(state, props) => createStructuredSelector({
			dialogIsActive: createIsPortalActiveSelector(props.id),
			windowSize: uiWindowSize
		}),
		bindActions({
			updatePersistentPortal,
			closePersistentPortal
		}),
		(stateProps, dispatchProps, ownProps) => ({
			...ownProps, ...dispatchProps, ...stateProps,
			updatePersistentPortal: function doUpdatePersistentProtal(persistentPortalProperties) {
				dispatchProps.updatePersistentPortal(ownProps.id, persistentPortalProperties);
			},
			closePersistentPortal: function doClosePersistentPortal() {
				dispatchProps.closePersistentPortal(ownProps.id);
			}
		})
	)(Component);
}

function createIsPortalActiveSelector(portalId) {
	return state => isPortalActive(state, portalId);
}
