import React from 'react';
import _debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import SynFormattedMessage from '../../../commons/containers/SynFormattedMessageContainer.js';
import {memoizeLast, noop, synchronizedWithAnimationFrame} from '../../../commons/utils/FunctionUtils.js';
import {cancellable} from '../../../commons/utils/PromiseUtils.js';
import PdfViewerTranslator from '../../../i18n/translators/PdfViewerMessagesTranslator.js';
import Title from '../../../material-design/components/Title.js';
import {calculateViewportScale, getScaledPageViewport} from '../../utils/PDFUtils.js';
import TextSelectionOverlay from './TextSelectionOverlay.js';

import '../../../../styles/viewer/components/PdfPage.scss';

const RENDER_DELAY = 500; // ms
const MAX_CANVAS_SIZE = 4096; // According to the iOS browser console.

function toRenderSize(size, devicePixelRatio) {
	return Math.ceil(size * devicePixelRatio);
}

export default class Page extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundCaptureCanvas = this.captureCanvas.bind(this);
		this.renderPageInAnimationFrame = synchronizedWithAnimationFrame(this.renderPage.bind(this), noop);
		this.renderPageDelayed = _debounce(this.renderPageInAnimationFrame, RENDER_DELAY);
		this.memoizedPageStyle = memoizeLast(Page.buildPageStyle);
		this.canvasElement = null;

		this.state = {
			targetWidth: 0,
			targetHeight: 0,
			pageRenderTask: null,
			canvasContext: null,
			renderedWidth: 0,
			renderedHeight: 0,
			renderedPage: null
		};
	}

	render() {
		const {className, width, height, pdfPage} = this.props;
		const {renderedPage} = this.state;
		const renderingPending = renderedPage !== pdfPage;
		const pageStyle = this.getPageStyle();
		return (
			<PageDecorations key='page-decorations' style={pageStyle}>
				<canvas key='pdfjs-render-canvas' ref={this.boundCaptureCanvas} style={pageStyle} className={className} />
				<TextSelectionOverlay width={width} height={height} pdfPage={pdfPage} />
				<RenderingIndicator visible={renderingPending} pageNumber={pdfPage.pageNumber} />
			</PageDecorations>
		);
	}

	renderPage() {
		const {
			canvasContext: currentContext, targetWidth, targetHeight, 
			renderedWidth, renderedHeight, renderedPage
		} = this.state;
		let nextCanvasContext = currentContext;

		const {pdfPage} = this.props;
		const needsReRender = Boolean(pdfPage) &&
			renderedPage !== pdfPage ||
			targetWidth !== renderedWidth ||
			targetHeight !== renderedHeight;

		if (!nextCanvasContext && this.canvasElement) {
			nextCanvasContext = Page.initializeCanvasContext(this.canvasElement, targetWidth, targetHeight);
		}

		if (nextCanvasContext && needsReRender) {
			this.cancelRenderTask();
			Page.setCanvasSizeOn(nextCanvasContext, targetWidth, targetHeight);
			const renderTask = Page.beginRender(nextCanvasContext, pdfPage, targetWidth, targetHeight);
			const cancellableRenderTask = cancellable(renderTask.promise)
				.cancelled(renderTask.cancel.bind(renderTask))
				.maybe(() => {
					this.setState({pageRenderTask: null});
				});
			this.setState({
				canvasContext: nextCanvasContext,
				pageRenderTask: cancellableRenderTask,
				renderedWidth: targetWidth,
				renderedHeight: targetHeight,
				renderedPage: pdfPage
			});
		}
	}

	schedulePageRender() {
		const {pdfPage} = this.props;
		const {renderedPage, pageRenderTask, canvasContext} = this.state;
		if (canvasContext && pdfPage !== renderedPage && !pageRenderTask) {
			canvasContext.clearRect(0, 0, canvasContext.canvas.width, canvasContext.canvas.height);
		}
		this.renderPageDelayed();
	}

	static beginRender(canvasContext, pdfPage, renderWidth, renderHeight) {
		const actualScale = calculateViewportScale(pdfPage, renderWidth, renderHeight);
		const renderParameters = {
			viewport: getScaledPageViewport(pdfPage, actualScale),
			canvasContext,
			enableWebGL: true
		};
		return pdfPage.render(renderParameters);
	}

	cancelRenderTask() {
		const {pageRenderTask} = this.state;
		if (pageRenderTask) {
			pageRenderTask.cancel();
			this.setState({pageRenderTask: null});
		}
	}

	captureCanvas(pageCanvas) {
		this.canvasElement = pageCanvas;
		if (!pageCanvas) {
			this.setState({
				canvasContext: null
			});
		}
	}

	componentDidMount() {
		this.schedulePageRender();
	}

	componentDidUpdate() {
		this.schedulePageRender();
	}

	componentWillUnmount() {
		this.renderPageDelayed.cancel();
		this.renderPageInAnimationFrame.stop();
		this.cancelRenderTask();

		const {pdfPage} = this.props;
		if (pdfPage) {
			pdfPage.cleanup();
		}
		const {canvasContext} = this.state;
		Page.releaseCanvasContext(canvasContext);
	}

	getPageStyle() {
		const {pdfPage, width, height} = this.props;
		return this.memoizedPageStyle(pdfPage, width, height);
	}

	static buildPageStyle(pdfPage, width, height) {
		const pageStyle = {width, height};
		if (pdfPage) {
			pageStyle['--scale-factor'] = calculateViewportScale(pdfPage, width, height);
		}
		return pageStyle;
	}

	static releaseCanvasContext(canvasContext) {
		if (canvasContext) {
			Page.setCanvasSizeOn(canvasContext, 0, 0);
		}
	}

	static initializeCanvasContext(canvasElement, initialWidth, initialHeight) {
		const newContext = canvasElement && canvasElement.getContext('2d');
		if (newContext) {
			Page.setCanvasSizeOn(newContext, initialWidth, initialHeight);
			newContext.clearRect(0, 0, initialWidth, initialHeight);
		}
		return newContext;
	}

	static setCanvasSizeOn(canvasContext, width, height) {
		if (canvasContext) {
			canvasContext.canvas.width = width;
			canvasContext.canvas.height = height;
		}
	}

	static getDerivedStateFromProps(props /*, state*/) {
		const {width, height, devicePixelRatio} = props;
		let renderWidth = toRenderSize(width, devicePixelRatio);
		let renderHeight = toRenderSize(height, devicePixelRatio);
		const longestSideLength = Math.max(renderWidth, renderHeight);
		if (longestSideLength > MAX_CANVAS_SIZE) {
			const scale = MAX_CANVAS_SIZE / longestSideLength;
			renderWidth = Math.floor(renderWidth * scale);
			renderHeight = Math.floor(renderHeight * scale);
		}
		return {
			targetWidth: renderWidth,
			targetHeight: renderHeight
		};
	}
}

Page.propTypes = {
	pdfPage: PropTypes.object,
	className: PropTypes.string, 
	width: PropTypes.number, 
	height: PropTypes.number,
	devicePixelRatio: PropTypes.number
};

class RenderingIndicator extends React.PureComponent {
	render() {
		const {pageNumber, visible} = this.props;
		return (
			<SynFormattedMessage translator={PdfViewerTranslator} message='PagePlaceholderText'
				element={Title} className={visible ? '' : 'hidden'} messageParams={{pageNumber}} />
		);
	}
}

RenderingIndicator.propTypes = {
	pageNumber: PropTypes.number, 
	visible: PropTypes.bool
};

class PageDecorations extends React.PureComponent {
	render() {
		const {style, children} = this.props;
		return (
			<div key='page-container' style={style} className='pdf-page--container'>
				{children}
			</div>
		);
	}
}

PageDecorations.propTypes = {
	style: PropTypes.object
};


