import {createSelector} from 'reselect';

import {uiDeviceOrientation, uiIsMobileDevice} from '../../../ui/flux/UISelectors.js';
import {restoreLayout, showViewer} from '../../../viewer/flux/actions/ViewerActions.js';
import {selectAllItemMetaInfos} from '../../../viewer/flux/selectors/ViewerSelectors.js';
import {viewerIndexToViewerId} from '../../../viewer/utils/ViewerUtils.js';
import {DEVICE_ORIENTATION_LANDSCAPE} from '../../constants/DeviceInformation.js';
import {
	getDicomSeries,
	getGenericFiles,
	getNumberFiles,
	getNumberSeries,
	isDicom
} from '../../data/aim/DocumentHelpers.js';
import {getFirstReport} from '../../selectors/AIMLinkSelectors.js';

const selectDocumentForFirstViewer = createSelector(
	selectAllItemMetaInfos,
	allItemMetaInfos => allItemMetaInfos.getIn([viewerIndexToViewerId(0), 'document'], null)
);

function getFirstItemHangInfo(state, documentId, document) {
	let hangInfo;
	if (isDicom(document) && getNumberSeries(document) >= 0) {
		hangInfo = {type: 'series', id: getDicomSeries(document).getIn([0, 'id'])};
	} else if (getNumberFiles(document) >= 0) {
		hangInfo = {type: 'file', id: getGenericFiles(document).getIn([0, 'id'])};
	}
	return hangInfo;
}

export default function showReport(document) {
	const documentId = document.get('id');
	const boundGetFirstReport = getFirstReport(documentId);
	return (dispatch, getState) => {
		const state = getState();
		const layoutMode = uiDeviceOrientation(state) === DEVICE_ORIENTATION_LANDSCAPE ? 2 : 1;
		const firstShownDocument = selectDocumentForFirstViewer(state);
		const isMobile = uiIsMobileDevice(state);

		const hangSequence = [];
		if (firstShownDocument === null || firstShownDocument.get('id') !== documentId) {
			const hangInfo = getFirstItemHangInfo(state, documentId, document);
			if (hangInfo) {
				hangSequence.push({viewerId: viewerIndexToViewerId(0), ...hangInfo});
			}
		}
		hangSequence.push({viewerId: viewerIndexToViewerId(1), type: 'file', id: boundGetFirstReport(state)});

		dispatch(restoreLayout(layoutMode, hangSequence));
		if (isMobile) {
			dispatch(showViewer());
		}
	};
}
