import React from 'react';

import createSimplePureRenderComponent from '../../commons/components/createSimplePureRenderComponent.js';
import createLayoutComponent from '../../commons/components/layout/createLayoutComponent.js';
import {WEBAPP_NAME} from '../../commons/constants/EnvironmentConstants.js';
import {isShare, isShareDone} from '../../commons/data/aim/DocumentHelpers.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import MarkDoneButtonContainer from '../flux/containers/ShareDoneButtonContainer.js';
import ReportButtonContainer from '../flux/containers/ShowReportButtonContainer.js';
import PatientDetailsMessagesTranslator from '../i18n/PatientDetailsMessagesTranslator.js';
import PatientDetailsDownloadDocumentButton from './PatientDetailsDownloadDocumentButton.js';

import '../../../styles/patient-details/components/PatientDetailsDocumentIcons.scss';

const MARK_SHARE_DONE_MESSAGE = <SynFormattedMessage translator={PatientDetailsMessagesTranslator} message='MarkShareDoneButtonLabel' />;
const MARK_SHARE_UNDONE_MESSAGE = <SynFormattedMessage translator={PatientDetailsMessagesTranslator} message='MarkShareUndoneButtonLabel' />;

const IconContainer = createLayoutComponent('patient-details-document-icons--icon-container');

export default createSimplePureRenderComponent(({document}) => {
	const documentId = document?.get('id');
	let markShareDoneButton = false;
	let downloadButton = false;
	if (isShare(document)) {
		markShareDoneButton = (
			<IconContainer>
				<MarkDoneButtonContainer round={false} className='patient-details-document-icons--mark-done-button' key='mark-done-button' document={document}>
					{isShareDone(document) ? MARK_SHARE_DONE_MESSAGE : MARK_SHARE_UNDONE_MESSAGE}
				</MarkDoneButtonContainer>
			</IconContainer>
		);
	}
	if (WEBAPP_NAME === 'webpatient' && documentId) {
		downloadButton = (
			<IconContainer>
				<PatientDetailsDownloadDocumentButton documentId={documentId} />
			</IconContainer>
		);
	}
	return (
		<ContentRow className='patient-details-document-icons--container'>
			{markShareDoneButton}
			{downloadButton}
			<IconContainer>
				<ReportButtonContainer key='report-button' document={document} />
			</IconContainer>
		</ContentRow>
	);
});
