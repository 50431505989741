import {recordError} from '../actions/UnhandledErrorsActions.js';
import JavaScriptRuntimeError from '../errors/JavaScriptRuntimeError.js';
import {isHandled} from '../utils/ErrorHandlingUtils.js';

export default function attachJavaScriptRuntimeErrorsRecorder(dispatch) {
	window.onerror = (message, source, lineno, colno, error) => {
		window.requestAnimationFrame(() => !isHandled(error) && dispatch(
			recordError(new JavaScriptRuntimeError(message, source, lineno, colno, error))
		));
	};
}
