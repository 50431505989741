import React from 'react';
import PropTypes from 'prop-types';

import DocumentDownloadLink from '../../commons/components/webview/DocumentDownloadLink.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DownloadOptionsMessagesTranslator from '../../i18n/translators/DownloadOptionsMessagesTranslator.js';
import DownloadIcon from '../../ui/components/icons/DownloadIcon.js';
import PatientDetailsDocumentButton from './PatientDetailsDocumentButton.js';

function PatientDetailsDownloadDocumentButton(props) {
	const {documentId} = props;
	return (
		<DocumentDownloadLink documentId={documentId}>
			<PatientDetailsDocumentButton icon={<DownloadIcon />}>
				<SynFormattedMessage translator={DownloadOptionsMessagesTranslator} message='Download' />
			</PatientDetailsDocumentButton>
		</DocumentDownloadLink>
	);
}

PatientDetailsDownloadDocumentButton.propTypes = {
	documentId: PropTypes.number.isRequired
};

export default React.memo(PatientDetailsDownloadDocumentButton);
