import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import {useDicomViewerContext} from '../../components/dicom/DicomSeriesContextProvider.js';
import DicomSeriesViewerToolbar from '../../components/dicom/DicomSeriesViewerToolbar.js';
import DicomImage from '../../data/DicomImage.js';
import {createSelectSeriesLoadedPercentDeviceAware} from '../selectors/ViewerItemsSelectors.js';

export default function DicomSeriesViewerToolbarContainer({dicomImage}) {
	const [state, actions] = useDicomViewerContext();
	const {seriesId} = state;
	const selectPercentLoaded = useMemo(() => createSelectSeriesLoadedPercentDeviceAware(seriesId), [seriesId]);
	const seriesLoadedPercent = useSelector(selectPercentLoaded);

	return (
		<DicomSeriesViewerToolbar seriesLoadedPercent={seriesLoadedPercent}
								 dicomImage={dicomImage}
								 viewerState={state}
								 viewerActions={actions} />
	);
}

DicomSeriesViewerToolbarContainer.propTypes = {
	dicomImage: PropTypes.instanceOf(DicomImage)
};
