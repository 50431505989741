export const IMAGE_PIXEL_SPACING_TAG_ID = '00181164';
export const SLICE_THICKNESS_TAG_ID = '00180050';
export const FRAME_OF_REFERENCE_UID_TAG_ID = '00200052';

export const IMAGE_ORIENTATION_PATIENT_TAG_ID = '00200037';
export const IMAGE_POSITION_PATIENT_TAG_ID = '00200032';
export const PIXEL_ASPECT_RATIO_TAG_ID = '00280034';
export const IMAGE_COMMENTS_TAG_ID = '00204000';
export const PATIENT_ORIENTATION_TAG_ID = '00200020';

export const SAMPLES_PER_PIXEL_TAG_ID = '00280002';
export const PHOTOMETRIC_INTERPRETATION_TAG_ID = '00280004';
export const BITS_ALLOCATED_TAG_ID = '00280100';
export const BITS_STORED_TAG_ID = '00280101';
export const HIGH_BIT_TAG_ID = '00280102';
export const PIXEL_REPRESENTATION_TAG_ID = '00280103';

export const NUMBER_OF_FRAMES_TAG_ID = '00280008';
export const PIXEL_SPACING_TAG_ID = '00280030';
export const NUMBER_ROWS_TAG_ID = '00280010';
export const NUMBER_COLUMNS_TAG_ID = '00280011';
export const MODALITY_TAG_ID = '00080060';

export const SEQUENCE_OF_ULTRASOUND_REGIONS_TAG_ID = '00186011';
export const REGION_DATA_TYPE_TAG_ID = '00186014';
export const REGION_LOCATION_MIN_X0 = '00186018';
export const REGION_LOCATION_MIN_Y0 = '0018601A';
export const REGION_LOCATION_MAX_X1 = '0018601C';
export const REGION_LOCATION_MAX_Y1 = '0018601E';
export const REGION_SPATIAL_FORMAT = '00186012';
export const REGION_FLAGS = '00186016';
export const REFERENCE_PIXEL_X0 = '00186020';
export const REFERENCE_PIXEL_Y0 = '00186022';
export const REFERENCE_PIXEL_PHYSICAL_VALUE_X = '00186028';
export const REFERENCE_PIXEL_PHYSICAL_VALUE_Y = '0018602A';
export const PHYSICAL_DELTA_X = '0018602C';
export const PHYSICAL_DELTA_Y = '0018602E';
export const PHYSICAL_UNITS_X_DIRECTION = '00186024';
export const PHYSICAL_UNITS_Y_DIRECTION = '00186026';

export const PATIENT_NAME = '00100010';
export const PATIENT_BIRTH_DATE = '00100030';
export const PATIENT_SEX = '00100040';
export const STUDY_DESCRIPTION = '00081030';

export const REFERENCED_FILE_ID = '00041500';
export const DIRECTORY_RECORD_SEQUENCE = '00041220';

export const STUDY_INSTANCE_UID = '0020000D';

export const US_REGION_FLAG_SCALE_MASK = 0x4;
export const US_REGION_FLAG_SCALE_SHIFT = 2;
export const US_REGION_FLAG_SCROLLING_MASK = 0x3;
export const US_REGION_FLAG_SCROLLING_SHIFT = 3;
