import React from 'react';
import {mat3} from 'gl-matrix';
import PropTypes from 'prop-types';

import {IS_RELEASE_BUILD} from '../../commons/constants/SynSettingsConstants';
import {
	getHeight,
	getOverlayDomImages,
	getRenderingParameters,
	getWidth
} from '../../commons/data/aim/SynAdvancedImage.js';
import {immutableMapPropType} from '../../commons/utils/CustomPropTypes.js';
import CanvasImageRenderer from '../CanvasImageRenderer.js';
import {moveToCenter} from '../utils/ImageViewerUtils.js';
import {toCssTransformMatrix} from '../utils/math/MatrixHelper.js';
import {scaleByInverseDevicePixelRatio} from '../utils/ViewerUtils.js';
import ViewerError from '../ViewerError.js';
import {getRenderingContext} from './CanvasRendering';
import ViewerItemLoadErrorMessage from './ViewerItemLoadErrorMessage.js';

import '../../../styles/viewer/components/CanvasImageViewer.scss';

// Use this constant to control worker usage to render images.
// Taking the worker out of the equation simplifies debugging by the millions!
const USE_WORKER = true;

// NOTE: Do switch between worker and direct rendering use the constant USE_WORKER.
// The following constant ensures that the worker is being used for production.
const ACTUALLY_USE_WORKER = IS_RELEASE_BUILD || USE_WORKER;

export default class CanvasImageViewer extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.onNewResult = this.onNewResult.bind(this);
		this.captureCanvas = this.captureCanvas.bind(this);
		this.imageRenderer = new CanvasImageRenderer(this.onNewResult);

		this.state = {
			canvasContext: null
		};
	}

	render() {
		const {currentRenderData: {error} = {}} = this.state;
		if (error) {
			throw new ViewerError('Failed to load image for display', error,
				<ViewerItemLoadErrorMessage itemType='image' />
			);
		}
		return this.renderCanvasViewer();
	}

	renderCanvasViewer() {
		const {currentRenderData} = this.state;
		const {
			isPrintPreview,
			decodedImage, transformationMatrix,
			devicePixelRatio, width, height
		} = this.props;

		let renderedWidth;
		let renderedHeight;
		let matrix;
		if (isPrintPreview || !currentRenderData) {
			renderedWidth = getWidth(decodedImage);
			renderedHeight = getHeight(decodedImage);
			matrix = transformationMatrix;
		} else {
			renderedWidth = currentRenderData.width;
			renderedHeight = currentRenderData.height;
			matrix = mat3.multiply(mat3.create(), transformationMatrix, currentRenderData.scaleMatrix);
		}

		const devicePixelRatioSpecificMatrix = scaleByInverseDevicePixelRatio(matrix, devicePixelRatio);
		const centeredMatrix = moveToCenter(
			devicePixelRatioSpecificMatrix, width, height,
			renderedWidth, renderedHeight, devicePixelRatio
		);
		const cssTransformMatrixString = toCssTransformMatrix(centeredMatrix);
		const transform = `translateZ(0) ${cssTransformMatrixString}`;
		const styleProperties = {
			width: renderedWidth,
			height: renderedHeight,
			transform
		};

		return (
			<div className='canvas-image-viewer--container'>
				{isPrintPreview && (
					<svg key='print-background' className='canvas-image-viewer--print-background' viewBox='0 0 1 1'
						  xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'>
						<rect width='1' height='1' />
					</svg>
				)}
				<canvas ref={this.captureCanvas} key='render-canvas' style={styleProperties} width={renderedWidth}
						  height={renderedHeight} />
			</div>
		);
	}

	renderOverlays(canvasContext) {
		const {decodedImage} = this.props;
		const {width: canvasWidth, height: canvasHeight} = canvasContext.canvas;
		const overlayImages = getOverlayDomImages(decodedImage);
		if (canvasContext && overlayImages && !overlayImages.isEmpty()) {
			overlayImages.forEach(overlayImage => {
				canvasContext.drawImage(overlayImage, 0, 0, canvasWidth, canvasHeight);
			});
		}
	}

	renderImmediate(canvasContext) {
		if (canvasContext) {
			const {windowCenter, windowWidth, decodedImage} = this.props;
			const renderOptions = getRenderingParameters(windowCenter, windowWidth, decodedImage);
			this.imageRenderer.renderImageSync(canvasContext, decodedImage, renderOptions);
			this.renderOverlays(canvasContext);
		}
	}

	componentDidMount() {
		const {isPrintPreview} = this.props;
		const {canvasContext} = this.state;
		if (!ACTUALLY_USE_WORKER || isPrintPreview) {
			this.renderImmediate(canvasContext);
		} else {
			this.scheduleImageRender();
		}
	}

	scheduleImageRender() {
		const {windowCenter, windowWidth, decodedImage} = this.props;
		const renderOptions = getRenderingParameters(windowCenter, windowWidth, decodedImage);
		this.imageRenderer.renderImage(decodedImage, renderOptions);
	}

	componentDidUpdate(prevProps) {
		const {isPrintPreview} = this.props;
		const {canvasContext} = this.state;
		if (!ACTUALLY_USE_WORKER || isPrintPreview) {
			this.renderImmediate(canvasContext);
		} else {
			const {currentRenderData} = this.state;
			if (currentRenderData && canvasContext) {
				const {imageData} = currentRenderData;
				if (imageData) {
					imageData.forEach((image, index) => {
						canvasContext.putImageData(image, 0, index * image.height);
					});
					// NOTE: This is a hack to avoid setState at this point.
					// It is necessary to free the resources bound by all the imageData.
					currentRenderData.imageData = null;
					this.renderOverlays(canvasContext);
				}
			}

			const {windowWidth, windowCenter, decodedImage} = this.props;
			const {
				windowWidth: prevWindowWidth,
				windowCenter: prevWindowCenter,
				decodedImage: prevDecodedImage
			} = prevProps;
			if (windowCenter !== prevWindowCenter ||
					windowWidth !== prevWindowWidth ||
					decodedImage !== prevDecodedImage) {
				this.scheduleImageRender();
			}
		}
	}

	componentWillUnmount() {
		this.imageRenderer.stop();
	}

	captureCanvas(newCanvas) {
		this.setState(state => ({
			...state,
			canvasContext: newCanvas ? getRenderingContext(newCanvas) : null
		}));
	}

	onNewResult(renderResult) {
		this.setState(state => ({
			...state,
			currentRenderData: renderResult
		}));
	}
}

CanvasImageViewer.propTypes = {
	isPrintPreview: PropTypes.bool,
	windowWidth: PropTypes.number,
	windowCenter: PropTypes.number,
	decodedImage: immutableMapPropType,
	transformationMatrix: PropTypes.instanceOf(Float32Array),
	width: PropTypes.number,
	height: PropTypes.number,
	devicePixelRatio: PropTypes.number
};
