import {CLEAR_SELECTION, DESELECT_ITEM, SELECT_ITEM} from '../constants/SelectedItemsActionTypes.js';
import {createAction} from '../utils/ActionUtils.js';

const createSelectItemAction = createAction(SELECT_ITEM);
const createDeselectItemAction = createAction(DESELECT_ITEM);
const createClearSelectionAction = createAction(CLEAR_SELECTION);

export function selectItem(id, item) {
	return createSelectItemAction({id, item});
}

export function deselectItem(id) {
	return createDeselectItemAction(id);
}

export function clearSelection() {
	return createClearSelectionAction();
}
