import DataTree from './DataTree.js';
import Observable from './Observable.js';
import {toRelativePath} from './URLUtils.js';

export default class FilesCollection extends Observable {
	constructor() {
		super(new DataTree(accumulateFileStats));
	}

	addFileList(fileList, path) {
		this.updateValue(dataTree => {
			fileList.forEach(file => this.addFile(file, path));
			return dataTree;
		});
	}

	addFile(file, path) {
		const newFileEntry = isFileEntry(file) ? file : createFileEntry(file);
		const {name} = newFileEntry;
		this.setEntryValue(name, newFileEntry, path);
	}

	setEntryValue(name, value, path) {
		this.updateValue(dataTree => {
			const realPath = toPath(path, name);
			return dataTree.addOrReplace(realPath, value);
		});
	}

	removeEntry(name, path) {
		this.updateValue(dataTree => {
			const realPath = toPath(path, name);
			return dataTree.removeEntry(realPath);
		});
	}

	removeAll(path) {
		this.updateValue(dataTree => {
			if (path) {
				return dataTree.removeEntry(path);
			}
			return dataTree.removeAllEntries();
		});
	}

	hasEntry(id, path) {
		return this.#getDataTree().hasEntry(toPath(path, id));
	}

	getValue(name, path) {
		return this.#getDataTree().getValue(toPath(path, name));
	}

	listEntries(path) {
		return this.#getDataTree().listEntries(path);
	}

	getFilesCount(path) {
		return this.getStat(path, 'count', entry => (entry ? 1 : 0)) || 0;
	}

	getTotalSize(name, path) {
		const realPath = toPath(path, name);
		return this.getStat(realPath, 'size', entry => (entry ? entry.size : 0)) || 0;
	}

	getStat(path, statEntry, entryGetter) {
		if (path) {
			const file = this.#getDataTree().getValue(path);
			if (file && isFileEntry(file)) {
				return entryGetter(file);
			}
			const subTree = this.#getDataTree().getSubTree(path);
			return subTree ? subTree.getAccountingData()[statEntry] : undefined;
		}
		return this.#getDataTree().getAccountingData()[statEntry];
	}

	hasEntriesIn(path) {
		return this.getFilesCount(path) > 0;
	}

	#getDataTree() {
		return super.getValue();
	}
}

function toPath(path, id) {
	let finalPath;
	if (path) {
		finalPath = Array.isArray(path) ? path : [path];
	}
	if (id) {
		if (Array.isArray(id)) {
			finalPath = finalPath ? [...finalPath, ...id] : id;
		} else {
			finalPath = finalPath ? [...finalPath, id] : [id];
		}
	}
	return finalPath;
}

function accumulateFileStats(acc = {count: 0, size: 0}, oldValue = undefined, newValue = undefined) {
	const {count, size} = acc;
	return {
		count: accumulateFileCount(count, oldValue, newValue),
		size: accumulateFileSizes(size, oldValue, newValue)
	};
}

function accumulateFileCount(accumulatedCount = 0, oldValue = undefined, newValue = undefined) {
	let newCount = accumulatedCount;
	if (newValue !== undefined) {
		if (newValue instanceof DataTree) {
			newCount += (newValue.getAccountingData() || {count: 0}).count;
		} else if (isFileEntry(newValue)) {
			newCount++;
		}
	}
	if (oldValue !== undefined) {
		if (oldValue instanceof DataTree) {
			newCount -= oldValue.getAccountingData().count;
		} else if (isFileEntry(oldValue)) {
			newCount--;
		}
	}
	return newCount;
}

function accumulateFileSizes(accumulatedSize = 0, oldValue = undefined, newValue = undefined) {
	let newSize = accumulatedSize;
	if (newValue !== undefined) {
		if (newValue instanceof DataTree) {
			newSize += (newValue.getAccountingData() || {size: 0}).size;
		} else if (isFileEntry(newValue)) {
			newSize += newValue.size;
		}
	}
	if (oldValue !== undefined) {
		if (oldValue instanceof DataTree) {
			newSize -= oldValue.getAccountingData().size;
		} else if (isFileEntry(oldValue)) {
			newSize -= oldValue.size;
		}
	}
	return newSize;
}

export function renameFileEntry(fileEntry, newName) {
	return fileEntry.renamed(newName);
}

export function createFileEntry(fileItem) {
	return isFileEntry(fileItem) ? fileItem : new FileEntry(fileItem);
}

export function isFileEntry(obj) {
	return obj && (obj instanceof FileEntry);
}

/**
 * Marker class to detect file entries.
 */
class FileEntry {
	constructor(fileItem, newName) {
		const {name, size, type, path, file} = fileItem;
		this.name = newName || name;
		this.size = size;
		this.type = type;
		this.file = file || fileItem;
		this.path = toRelativePath(path || this.name);
		Object.freeze(this);
	}

	renamed(newName) {
		return new FileEntry(this, newName);
	}
}
