import FetchError from './FetchError.js';

/**
 * An error class to indicate that the requested entity was not found on the server.
 * This is generally indicated by a 404 HTTP-Status-Code returned by the server.
 */
export default class NotFoundError extends FetchError {
	constructor(response, responseAsJSON) {
		super('The requested entity was not found on the server.', response, responseAsJSON);
	}
}
