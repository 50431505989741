import React from 'react';
import PropTypes from 'prop-types';

/**
 * This component acts as a proxy component to the application specific implementation of the sharing dialog.
 * The actual component can be set during application bootstrap using ShareDocumentDialogProxy.setDialogComponent(...).
 *
 * @author p.spitzilnger@synedra.com
 */
export default class ShareDocumentDialogProxy extends React.PureComponent {
	render() {
		const DialogComponent = ShareDocumentDialogProxy.#dialogComponent;
		return DialogComponent && <DialogComponent {...this.props} />;
	}

	static #dialogComponent = null;
	static setDialogComponent(component) {
		ShareDocumentDialogProxy.#dialogComponent = component;
	}
}
ShareDocumentDialogProxy.propTypes = {
	documentIds: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string).isRequired,
		PropTypes.arrayOf(PropTypes.number).isRequired
	]),
	onClose: PropTypes.func
};
