import React from 'react';

import useBrick from '../../webview/bricks/hooks/useBrick.js';
import {useEffectEasily} from '../../webview/commons/utils/customHooks';
import UploadFilesCollector from '../bricks/UploadFilesCollector';
import UploadAreaToast from './UploadAreaToast.js';

const STORE = Symbol('MUTABLE_STORE');

export default function FilesAddingSummaryToast(/*props*/) {
	const {isCollecting, summary} = useBrick(UploadFilesCollector, selectCollectorProps);
	const [wasCollecting] = React.useState({[STORE]: false});
	const [displayedSummary, setDisplayedSummary] = React.useState(null);
	useEffectEasily(updateDisplayedSummary, wasCollecting, isCollecting, summary, setDisplayedSummary);
	return <UploadAreaToast status={displayedSummary} />;
}

function selectCollectorProps(collector) {
	return {
		isCollecting: collector.isCollecting(),
		summary: collector.getCollectionSummary()
	};
}

function updateDisplayedSummary(wasCollecting, isCollecting, newSummary, setNewSummary) {
	const addingFilesFinished = wasCollecting[STORE] && !isCollecting;
	if (addingFilesFinished) {
		setNewSummary(newSummary);
	}
	wasCollecting[STORE] = isCollecting;
}
