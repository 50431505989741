import React, {useCallback} from 'react';
import {inputBaseClasses} from '@mui/material/InputBase';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';

import {refPropType} from '../../commons/utils/CustomPropTypes.js';
import {callSafe} from '../../commons/utils/FunctionUtils.js';

export default React.memo(MultiLineTextField);

const TEXT_FIELD_STYLES = {
	flexDirection: 'column',
	flexGrow: 1
};

const INPUT_STYLES = {
	position: 'relative',
	flexGrow: 1,
	[`& .${inputBaseClasses.inputMultiline}`]: {
		width: '100%',
		height: '100%'
	}
};

function MultiLineTextField(props) {
	const {value, label, name, autoFocus, onChange, inputRef, className, rows} = props;

	const inputValueChanged = useCallback(e => {
		const newValue = e.target.value;
		callSafe(onChange, newValue);
	}, [onChange]);

	const textFieldProps = {
		value,
		label,
		name,
		autoFocus,
		onChange: inputValueChanged,
		inputRef,
		className,
		InputProps: {
			inputComponent: 'textarea',
			sx: INPUT_STYLES
		},
		sx: TEXT_FIELD_STYLES
	};

	return <TextField rows={rows} multiline {...textFieldProps} />;
}

MultiLineTextField.propTypes = {
	value: PropTypes.string,
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	autoFocus: PropTypes.bool,
	className: PropTypes.string,
	rows: PropTypes.number,
	inputRef: refPropType
};

MultiLineTextField.defaultProps = {
	value: '',
	autoFocus: false,
	className: '',
	rows: 4
};
