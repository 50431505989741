import React from 'react';
import PropTypes from 'prop-types';

import DownloadLink from '../../commons/components/DownloadLink.js';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import DownloadOptionsMessagesTranslator from '../../i18n/translators/DownloadOptionsMessagesTranslator.js';
import DownloadIcon from '../../ui/components/icons/DownloadIcon.js';
import MenuEntry from '../../ui/components/MenuEntry.js';
import ViewerToolBarMenu from './ViewerToolBarMenu.js';

const DOWNLOAD_ICON = <DownloadIcon />;

export default class DownloadMenuButton extends React.PureComponent {
	render() {
		const {downloadOptions} = this.props;
		const isVisible = Boolean(downloadOptions);
		return isVisible && (
			<ViewerToolBarMenu id='viewer-downloads-menu' buttonIcon={DownloadIcon}>
				{downloadOptions.map(renderDownloadMenuEntry)}
			</ViewerToolBarMenu>
		);
	}
}

function renderDownloadMenuEntry(downloadOption, index) {
	const {message, url} = downloadOption;
	const primaryText = <SynFormattedMessage translator={DownloadOptionsMessagesTranslator} message={message} />;
	const id = `download-${index}`;
	return (
		<MenuEntry id={id} key={url} component={DownloadLink}
					icon={DOWNLOAD_ICON} href={url} primaryText={primaryText} />
	);
}

DownloadMenuButton.propTypes = {
	downloadOptions: PropTypes.arrayOf(PropTypes.shape({
		message: PropTypes.string,
		url: PropTypes.string
	}))
};
