import React from 'react';
import PropTypes from 'prop-types';

import GuardedUserInfo from '../../authentication/flux/containers/GuardedUserInfo.js';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import SidebarToggleButtonContainer from '../containers/webview/SidebarToggleButtonContainer.js';
import {combineClassNames} from '../utils/StyleUtils.js';

import '../../../styles/commons/components/SidebarHeader.scss';

const SIDEBAR_TOGLE_BUTTON_CLASSES = {
	root: 'toggle-button'
};

export default function SidebarHeader(props) {
	const {sidebarElevated, className} = props;
	const classNames = combineClassNames('webview-sidebar-header',
		sidebarElevated ? 'webview-sidebar-header-visible' : '', className);
	return (
		<ContentRow className={classNames}>
			<GuardedUserInfo />
			<SidebarToggleButtonContainer classes={SIDEBAR_TOGLE_BUTTON_CLASSES} />
		</ContentRow>
	);
}

SidebarHeader.propTypes = {
	sidebarElevated: PropTypes.bool,
	className: PropTypes.string
};
