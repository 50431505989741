import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {pathSelector} from '../../router/flux/selectors/LocationSelectors.js';
import SidebarLink from '../../ui/components/SidebarLink.js';
import {toggleSidebar} from '../../ui/flux/UIActions.js';
import {uiSidebarModeElevatedSelector} from '../../ui/flux/UISelectors.js';
import {cloneWithoutProperties} from '../utils/ObjectUtils';

function createClickAction(props) {
	return () => (dispatch, getState) => {
		if (uiSidebarModeElevatedSelector(getState())) {
			dispatch(toggleSidebar());
		}
		if (props.action) {
			dispatch(props.action());
		}
	};
}

function mergeProps(stateProps, dispatchProps, ownProps) {
	return {
		...stateProps, ...dispatchProps,
		...cloneWithoutProperties(ownProps, 'action')
	};
}

export default connect(
	(state, ownProps) => ({
		active: Boolean(ownProps.path) && pathSelector(state) === ownProps.path
	}),
	(dispatch, ownProps) => bindActionCreators({
		onClick: createClickAction(ownProps)
	}, dispatch),
	mergeProps
)(SidebarLink);
