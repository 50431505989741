import React from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

import {immutableMapPropType} from '../../commons/utils/CustomPropTypes';
import SynFormattedMessage from '../../i18n/components/SynFormattedMessage.js';
import ViewerMessagesTranslator from '../../i18n/translators/ViewerTranslator.js';

function getErrorMessage(itemType, viewerItem) {
	let message = '';
	switch (itemType || viewerItem.get('type')) {
		case 'series':
			message = 'SeriesResolveErrorMessage';
			break;
		case 'image':
		case 'frame':
			message = 'ImageLoadErrorMessage';
			break;
		case 'file':
			message = 'FileLoadErrorMessage';
			break;
		default:
			throw new Error('Invalid imageType or viewerItem');
	}
	return message;
}

export default function ViewerItemLoadErrorMessage(props) {
	const {viewerItem, itemType} = props;
	return (
		<SynFormattedMessage translator={ViewerMessagesTranslator}
							 message={getErrorMessage(itemType, viewerItem)} />
	);
}

ViewerItemLoadErrorMessage.propTypes = {
	viewerItem: PropTypes.oneOfType([
		immutableMapPropType,
		PropTypes.instanceOf(Immutable.Record)
	]),
	itemType: PropTypes.string
};
