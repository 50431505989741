import React from 'react';
import PropTypes from 'prop-types';

import {getDicomSeries, getGenericFiles, isDicom} from '../../commons/data/aim/DocumentHelpers.js';
import {immutableMapPropType, immutablePropType} from '../../commons/utils/CustomPropTypes.js';
import ContentRow from '../../material-design/components/layout/ContentRow.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import PatientDetailsDocumentHeader from './PatientDetailsDocumentHeader.js';
import PatientDetailsDocumentIcons from './PatientDetailsDocumentIcons.js';
import {DicomSeriesPreviewGrid, GenericFilesPreviewGrid} from './PatientDetailsPreviewGrids.js';

import '../../../styles/patient-details/components/PatientDetailsDocument.scss';

export default class PatientDetailsDocument extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnToggleExpand = this.onToggleExpand.bind(this);
		this.patientDetailsDocumentSection = React.createRef();
	}

	render() {
		const {
			document, label, itemsPerRow, totalTileWidth, isExpanded, viewportDimensions, scrollOffset,
			categoryDepth, expandedDocuments
		} = this.props;
		const items = isDicom(document) ? getDicomSeries(document) : getGenericFiles(document);
		const DetailsGrid = isDicom(document) ? DicomSeriesPreviewGrid : GenericFilesPreviewGrid;
		const itemsToDisplay = isExpanded ? items.size : itemsPerRow;
		const contentWidth = itemsPerRow * totalTileWidth;

		return (
			<VerticalLayout element='section' ref={this.patientDetailsDocumentSection} noGrow justify='start'
			                className='patient-details-document'>
				<PatientDetailsDocumentHeader itemsPerRow={itemsPerRow} document={document}
					label={label} categoryDepth={categoryDepth} isExpanded={isExpanded}
					onToggleExpand={this.boundOnToggleExpand} contentWidth={contentWidth} />
				<PatientDetailsDocumentIcons document={document} />
				<ContentRow>
					<DetailsGrid itemsPerRow={itemsPerRow} totalTileWidth={totalTileWidth}
						expandedDocuments={expandedDocuments}
						items={items} itemsToDisplay={itemsToDisplay}
						previewImageClass='patient-details-preview-grids--preview-image'
						viewportDimensions={viewportDimensions}
						scrollOffset={scrollOffset}
						className='patient-details-document--preview-grid' />
				</ContentRow>
			</VerticalLayout>
		);
	}

	componentDidMount() {
		const {initiallySelectedDocumentIds, document} = this.props;
		if (initiallySelectedDocumentIds.has(document.get('id'))) {
			this.patientDetailsDocumentSection.current.scrollIntoView(true);
		}
	}

	onToggleExpand(expand) {
		const {onExpand, onCollapse, document} = this.props;
		if (expand) {
			onExpand(document.get('id'));
		} else {
			onCollapse(document.get('id'));
		}
	}
}

PatientDetailsDocument.propTypes = {
	isExpanded: PropTypes.bool,
	document: immutableMapPropType,
	onExpand: PropTypes.func,
	onCollapse: PropTypes.func,
	itemsPerRow: PropTypes.number,
	totalTileWidth: PropTypes.number,
	viewportDimensions: PropTypes.exact({
		height: PropTypes.number,
		width: PropTypes.number
	}),
	scrollOffset: PropTypes.number,
	categoryDepth: PropTypes.number,
	expandedDocuments: immutablePropType,
	initiallySelectedDocumentIds: immutablePropType,
	label: PropTypes.string
};

PatientDetailsDocument.defaultProps = {
	isExpanded: false,
	onExpand: () => {},
	itemsPerRow: 0,
	totalTileWidth: 0,
	categoryDepth: 0
};
