import React from 'react';
import PropTypes from 'prop-types';

import CenterLayout from '../../commons/components/layout/CenterLayout.js';
import PatientInfo from '../../commons/components/webview/PatientInfo.js';
import {immutableMapPropType, immutableRecordType} from '../../commons/utils/CustomPropTypes.js';
import Spinner from '../../ui/components/Spinner.js';

export default class GuardedPatientInfo extends React.PureComponent {
	render() {
		const {patient, hasLoadError, canLoad} = this.props;
		let content = false;
		if (canLoad) {
			if (patient) {
				content = (
					<PatientInfo className='webview-details-app-bar--patient-info'
											  firstName={patient.get('first_name')}
											  lastName={patient.get('last_name')}
											  sex={patient.get('sex_fk')}
											  birthDate={patient.get('birth_date')} />
				);
			} else if (!hasLoadError) {
				content = (
					<CenterLayout>
						<Spinner radius={12} color='inherit' sx={{opacity: 0.32}} />
					</CenterLayout>
				);
			}
		}
		return content;
	}
}

GuardedPatientInfo.propTypes = {
	canLoad: PropTypes.bool,
	hasLoadError: PropTypes.bool,
	patient: PropTypes.oneOfType([
		immutableMapPropType,
		immutableRecordType
	])
};
