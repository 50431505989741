import React from 'react';
import PropTypes from 'prop-types';

export default function InnerList(props) {
	const {style, children} = props;
	const thisStyle = {...style, margin: 0};
	delete thisStyle.width;
	return (
		<ul style={thisStyle}>
			{children}
		</ul>
	);
}
InnerList.propTypes = {
	style: PropTypes.object
};

