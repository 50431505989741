import React from 'react';
import {useSelector} from 'react-redux';
import {number, object} from 'prop-types';

import useBrick from '../../../bricks/hooks/useBrick.js';
import DeviceInfo from '../../../commons/bricks/DeviceInfo.js';
import {WEBAPP_NAME} from '../../../commons/constants/EnvironmentConstants.js';
import NextIcon from '../../../ui/components/icons/NextIcon.js';
import NextSceneIcon from '../../../ui/components/icons/NextSceneIcon.js';
import PreviousIcon from '../../../ui/components/icons/PreviousIcon.js';
import PreviousSceneIcon from '../../../ui/components/icons/PreviousSceneIcon.js';
import {DicomImageDownloadMenuButtonContainer} from '../../flux/containers/DownloadMenuButtonContainer.js';
import EnablePointSyncToolMenuEntryContainer from '../../flux/containers/EnablePointSyncToolMenuEntryContainer.js';
import {DicomImageAnnotationMenuEntriesContainer} from '../../flux/containers/ImageAnnotationsMenuEntriesContainer.js';
import {getViewerLayout} from '../../flux/selectors/ViewerSelectors.js';
import OffsetPageSyncMenuEntry
	from '../../synchronization/tools/offset-page-synchronization/components/OffsetPageSyncMenuEntry.js';
import {isMultiColumnLayout} from '../../utils/ViewerUtils.js';
import createViewerToolbarIconButton from '../createViewerToolbarIconButton.js';
import ImageViewerToolsMenu from '../ImageViewerToolsMenu.js';
import InfoDialogToolbarButton from '../InfoDialogToolbarButton.js';
import ViewerFullscreenToolbarButton from '../ViewerFullscreenToolbarButton.js';
import ViewerToggleAnnotationsButton from '../ViewerToggleAnnotationsButton.js';
import ViewerToggleAnnotationsToolsMenu from '../ViewerToggleAnnotationsToolsMenu.js';
import ViewerToolbar from '../ViewerToolbar.js';
import DicomImageDetailsDialog from './DicomImageDetailsDialog.js';
import DicomSeriesViewerSlider from './DicomSeriesViewerSlider.js';

import '../../../../styles/viewer/components/DicomSeriesViewerToolbar.scss';

const PreviousButton = createViewerToolbarIconButton(PreviousIcon);
const NextButton = createViewerToolbarIconButton(NextIcon);
const NextSceneButton = createViewerToolbarIconButton(NextSceneIcon);
const PreviousSceneButton = createViewerToolbarIconButton(PreviousSceneIcon);

const TINY_DEVICE_TOOLBAR_WIDTH = 380; // Value measured due to current toolbar button widths.

function DicomSeriesViewerToolbar(props) {
	const {seriesLoadedPercent, viewerState, dicomImage, viewerActions} = props;
	const {
		isMobileDevice,
		isImage,
		hasMultipleFrames,
		disableViewerToolsMenu,
		overFlowMenuInfoButton
	} = useMenuDisplayParameters(viewerState, dicomImage);
	const imageMetadata = dicomImage ? dicomImage.metadata : {};
	return (
		<ViewerToolbar className='dicom-series-toolbar' percent={seriesLoadedPercent}>
			{hasMultipleFrames && renderNavigationButtons(viewerState, viewerActions)}
			<ViewerFullscreenToolbarButton />
			{!isMobileDevice && <ViewerToggleAnnotationsButton disabled={!isImage} />}
			{!overFlowMenuInfoButton && renderInfoDialogButton(imageMetadata)}
			{WEBAPP_NAME !== 'webpatient' && <DicomImageDownloadMenuButtonContainer />}
			<ImageViewerToolsMenu disabled={disableViewerToolsMenu}>
				{isImage && <DicomImageAnnotationMenuEntriesContainer />}
				{isMobileDevice && isImage && <ViewerToggleAnnotationsToolsMenu />}
				<EnablePointSyncToolMenuEntryContainer dicomImage={dicomImage} key='enablePointSyncToolMenuEntry' />
				<OffsetPageSyncMenuEntry dicomImage={dicomImage} />
				{overFlowMenuInfoButton && renderInfoDialogButton(imageMetadata, true)}
			</ImageViewerToolsMenu>
		</ViewerToolbar>
	);
}

DicomSeriesViewerToolbar.propTypes = {
	dicomImage: object,
	viewerState: object.isRequired,
	viewerActions: object.isRequired,
	seriesLoadedPercent: number
};

function useMenuDisplayParameters(viewerState, dicomImage) {
	const {numberOfFrames} = viewerState;
	const hasMultipleFrames = numberOfFrames > 1;
	const isImage = dicomImage && dicomImage.isImage;
	const isMobileDevice = useBrick(DeviceInfo, deviceInfo => deviceInfo.isMobileDevice());
	const isTinyDeviceToolbar = useBrick(DeviceInfo, selectTinyDeviceToolbar);
	const viewerLayout = useSelector(getViewerLayout);
	const overFlowMenuInfoButton = isTinyDeviceToolbar &&
		detectMultiFrame(viewerState) &&
		!isMultiColumnLayout(viewerLayout);
	const disableViewerToolsMenu = !overFlowMenuInfoButton && (
		!dicomImage ||
		!isImage ||
		!dicomImage.isDisplaySupported
	);

	return {
		disableViewerToolsMenu,
		hasMultipleFrames,
		isImage,
		overFlowMenuInfoButton,
		isMobileDevice
	};
}

function selectTinyDeviceToolbar(deviceInfo) {
	return deviceInfo.getWindowSize().get('width') <= TINY_DEVICE_TOOLBAR_WIDTH;
}

function renderInfoDialogButton(imageMetadata, asMenuEntry) {
	return (
		<InfoDialogToolbarButton key='info' menuEntry={asMenuEntry}>
			<DicomImageDetailsDialog imageMetadata={imageMetadata} />
		</InfoDialogToolbarButton>
	);
}

function renderNavigationButtons(viewerState, viewerActions) {
	const {movePreviousFrame, moveNextFrame, movePreviousImage, moveNextImage, setFrameIndex} = viewerActions;
	const {
		canMoveNextFrame, canMoveNextImage, canMovePreviousFrame, canMovePreviousImage,
		currentFrameOffset, currentImageOffset, imageRelativeFrameOffset,
		numberOfFrames
	} = viewerState;
	const navigationButtons = [
		<PreviousButton key='previousFrameInSeriesButton' className='dicom-series-toolbar--nav-button small-margin-right'
		                onClick={movePreviousFrame} disabled={!canMovePreviousFrame} />,
		<NextButton key='nextFrameInSeriesButton' className='dicom-series-toolbar--nav-button'
		            onClick={moveNextFrame} disabled={!canMoveNextFrame} />,
		<DicomSeriesViewerSlider key='dicomSeriesViewerSlider' onChange={setFrameIndex}
		                         seriesRelativeFrameOffset={currentFrameOffset}
		                         seriesRelativeImageOffset={currentImageOffset}
		                         imageRelativeFrameOffset={imageRelativeFrameOffset} numberOfFrames={numberOfFrames} />
	];
	if (detectMultiFrame(viewerState)) {
		navigationButtons.unshift(
			<PreviousSceneButton key='previousSceneInSeriesButton' className='dicom-series-toolbar--nav-button small-margin-right'
			                     onClick={movePreviousImage} disabled={!canMovePreviousImage} />,
			<NextSceneButton key='nextSceneInSeriesButton' className='dicom-series-toolbar--nav-button'
			                 onClick={moveNextImage} disabled={!canMoveNextImage} />
		);
	}
	return navigationButtons;
}

function detectMultiFrame(viewerState) {
	const {numberOfFrames, numberOfImages} = viewerState;
	const hasMultipleImages = numberOfImages > 1;
	const isMultiFrame = numberOfFrames > numberOfImages;
	return isMultiFrame && hasMultipleImages;
}

export default React.memo(DicomSeriesViewerToolbar);
