import Immutable from 'immutable';

import {webAppURL} from '../WebViewHelpers.js';
import {SYNEDRA_ZIP_FILE_EXTENSION, SYNEDRA_ZIP_FILE_EXTENSION_ALTERNATIVE} from './WebViewConstants.js';

export const ROOT_PATH = '';
export const PASSWORDRESET_PATH = webAppURL('passwordreset');
export const HELP_PATH = webAppURL('help');

// Download rules
export const ITEM_DOWNLOAD_PATH = '/:itemType/:id.:ext';
export const ITEM_DOWNLOAD_PATH_PARAMETER_MATCHERS = Immutable.Map({
	id: '[0-9]+',
	ext: `download|${SYNEDRA_ZIP_FILE_EXTENSION_ALTERNATIVE}|${SYNEDRA_ZIP_FILE_EXTENSION}`
});
