import React from 'react';
import PropTypes from 'prop-types';

import {LANDMARKS} from '../../../a11y/components/landmarks/Landmarks.js';
import {cloneWithoutProperties} from '../../utils/ObjectUtils';
import {getHocDisplayName, withForwardRef} from '../../utils/ReactUtils.js';
import {combineClassNames} from '../../utils/StyleUtils.js';

export default function createLayoutComponent(baseClassName, Container = 'div') {
	class LayoutComponent extends React.PureComponent {
		render() {
			const {className, element, forwardRef} = this.props;
			const remainingProps = cloneWithoutProperties(this.props, 'className', 'element', 'forwardRef');
			const actualClassNames = combineClassNames(baseClassName, className);
			const FinalContainer = LANDMARKS[element] ? LANDMARKS[element] : element || Container;
			return <FinalContainer ref={forwardRef} className={actualClassNames} {...remainingProps} />;
		}
	}

	LayoutComponent.propTypes = {
		forwardRef: withForwardRef.PropTypes.Ref,
		className: PropTypes.string,
		element: PropTypes.elementType
	};

	LayoutComponent.displayName = getHocDisplayName(Container, 'createLayoutComponent');

	return LayoutComponent;
}
