import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {cloneWithoutProperties} from '../../utils/ObjectUtils/index.js';
import {combineClassNames} from '../../utils/StyleUtils.js';
import createLayoutComponent from './createLayoutComponent.js';

import '../../../../styles/commons/components/layout/CenterLayout.scss';

const BasicCenterLayout = createLayoutComponent('center-layout--container');

export const DIRECTION_ROW = 'DIRECTION-ROW';
export const DIRECTION_COLUMN = 'DIRECTION_COLUMN';

function CenterLayout(props) {
	const {direction = DIRECTION_ROW, fitParent, className, ...remainingProps} = props;
	const finalClassNames = combineClassNames(
		classNames({
			'flex-direction-column': direction === DIRECTION_COLUMN,
			'fit-parent': fitParent
		}),
		className
	);
	return <BasicCenterLayout className={finalClassNames} {...remainingProps} />;
}
CenterLayout.propTypes = {
	direction: PropTypes.oneOf([
		DIRECTION_ROW,
		DIRECTION_COLUMN
	]),
	fitParent: PropTypes.bool,
	className: PropTypes.string
};

CenterLayout.defaultProps = {
	direction: DIRECTION_ROW,
	fitParent: false
};

function ColumnCenterLayout(props) {
	return <CenterLayout direction={DIRECTION_COLUMN} {...props} />;
}

ColumnCenterLayout.propTypes = cloneWithoutProperties(CenterLayout.propTypes, 'direction');
ColumnCenterLayout.defaultProps = cloneWithoutProperties(CenterLayout.defaultProps, 'direction');

export {ColumnCenterLayout};
export default CenterLayout;
