import {createAction} from '../../../commons/utils/ActionUtils.js';
import {
	ADD_ANNOTATION,
	REMOVE_ANNOTATION,
	REMOVE_ANNOTATIONS_OF_TYPE,
	REMOVE_READONLY_ANNOTATIONS,
	SET_ACTIVE_ANNOTATION,
	UPDATE_ANNOTATION_PROPERTIES
} from '../../constants/AnnotationActionTypes.js';

export const addAnnotation = createAction(ADD_ANNOTATION,
	(itemType, itemId, annotationType) => ({itemType, itemId, annotationType})
);
export const removeAnnotation = createAction(REMOVE_ANNOTATION, annotationId => ({annotationId}));
export const removeAnnotationsOfType = createAction(REMOVE_ANNOTATIONS_OF_TYPE, annotationType => annotationType);
export const removeReadOnlyAnnotations = createAction(REMOVE_READONLY_ANNOTATIONS);
export const setActiveAnnotation = createAction(SET_ACTIVE_ANNOTATION, annotationId => annotationId);
export const resetActiveAnnotation = createAction(SET_ACTIVE_ANNOTATION, () => null);
export const updateAnnotation = createAction(UPDATE_ANNOTATION_PROPERTIES,
	(annotationId, annotationProperties) => ({annotationId, annotationProperties})
);
