import React from 'react';
import PropTypes from 'prop-types';

import SynFormattedMessage, {tr} from '../../../../i18n/components/SynFormattedMessage.js';
import DownloadOptionsMessagesTranslator from '../../../../i18n/translators/DownloadOptionsMessagesTranslator.js';
import DownloadPageMessagesTranslator from '../../../../i18n/translators/DownloadPageMessagesTranslator.js';
import Card from '../../../../material-design/components/Card.js';
import PrimaryItemText from '../../../../material-design/components/text/PrimaryItemText.js';
import SecondaryItemText from '../../../../material-design/components/text/SecondaryItemText.js';
import DownloadIcon from '../../../../ui/components/icons/DownloadIcon.js';
import Counter from '../../Counter.js';
import DownloadLink from '../../DownloadLink.js';
import CenterLayout from '../../layout/CenterLayout.js';
import DownloadForViewIcon from '../icons/DownloadForViewIcon.js';

import '../../../../../styles/commons/components/webview/download/DownloadBody.scss';

const AutoStartCounterMessage = tr(DownloadPageMessagesTranslator, 'DownloadCountdownMessage');
const DOWNLOAD_STARTED_MESSAGE = <SynFormattedMessage translator={DownloadPageMessagesTranslator} message='DownloadStartedMessage' />;

export default class DownloadBody extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.boundOnCountdownFinished = this.onCountdownFinished.bind(this);
		this.boundOnLinkClicked = this.onLinkClicked.bind(this);

		this.state = {
			linkClicked: false,
			startDownload: false
		};
	}

	render() {
		const {downloadUrl} = this.props;
		return (
			<CenterLayout>
				<Card className='download-body--card'>
					{this.getDownloadIcon()}
					<div className='download-info'>
						<PrimaryItemText className='download-link'>
							<DownloadLink href={downloadUrl} onClick={this.boundOnLinkClicked}>
								<SynFormattedMessage translator={DownloadOptionsMessagesTranslator}
															message={this.getDownloadLinkMessage()} />
							</DownloadLink>
						</PrimaryItemText>
						<SecondaryItemText>
							{this.renderDownloadState()}
							{this.renderDownloadIFrame()}
						</SecondaryItemText>
					</div>
				</Card>
			</CenterLayout>
		);
	}

	renderDownloadState() {
		const {linkClicked, startDownload} = this.state;
		if (linkClicked || startDownload) {
			return DOWNLOAD_STARTED_MESSAGE;
		}
		const {downloadStartTimeout} = this.props;
		return (
			<Counter from={downloadStartTimeout} onFinished={this.boundOnCountdownFinished}
						messageComponent={AutoStartCounterMessage} />
		);
	}

	renderDownloadIFrame() {
		const {downloadUrl} = this.props;
		const {linkClicked, startDownload} = this.state;
		if (!linkClicked && startDownload) {
			return <iframe width={0} height={0} src={downloadUrl} className='download-iframe' />;
		}
		return false;
	}
	
	onCountdownFinished(/*event*/) {
		this.setState({
			startDownload: true
		});
	}

	onLinkClicked() {
		this.setState({
			linkClicked: true
		});
	}

	getDownloadIcon() {
		const {downloadForView} = this.props;
		return downloadForView ? <DownloadForViewIcon /> : <DownloadIcon />;
	}

	getDownloadLinkMessage() {
		const {itemType, downloadForView} = this.props;
		return `Download${itemType}${downloadForView ? 'ForView' : ''}`;
	}
}

DownloadBody.propTypes = {
	downloadStartTimeout: PropTypes.number,
	itemType: PropTypes.string.isRequired,
	downloadForView: PropTypes.bool.isRequired,
	downloadUrl: PropTypes.string.isRequired
};

DownloadBody.defaultProps = {
	downloadStartTimeout: 5
};
