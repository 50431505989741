import React, {useCallback, useRef, useState} from 'react';
import {fromEvent} from 'file-selector';
import PropTypes from 'prop-types';

import DomEventsManager from '../../events/DomEventsManager.js';
import VerticalLayout from '../../ui/components/layout/VerticalLayout.js';
import withFrostedGlassStyles from '../../ui/components/withFrostedGlassStyles.js';
import {useEffectOnMount, useEffectOnUnmount, useMemoFactory} from '../utils/customHooks';
import {callSafe} from '../utils/FunctionUtils.js';

const StyledFrostedGlassComponent = withFrostedGlassStyles(VerticalLayout);

const STYLES = {
	boxSizing: 'border-box',
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	'& *': {
		pointerEvents: 'none'
	}
};

export default function DropZone(props) {
	const {content, entered, onLeave, handleDroppedFiles} = props;
	const [domEventsManager] = useState(new DomEventsManager());
	const dropZoneRef = useRef(null);
	const handleDragLeave = useMemoFactory(createOnDragLeave, entered, onLeave);
	const handleOnDrop = useMemoFactory(createHandleOnDrop, onLeave, handleDroppedFiles);
	useEffectOnMount(() => {
		domEventsManager.addEventListener(dropZoneRef.current, 'drop', handleOnDrop);
	});
	useEffectOnUnmount(() => {
		domEventsManager.removeAllListeners();
	});
	return (
		<StyledFrostedGlassComponent visible={entered} ref={dropZoneRef} align='center' justify='center' sx={STYLES}
		                             onDragLeave={handleDragLeave} onDragOver={onDragOver}>
			{content}
		</StyledFrostedGlassComponent>
	);
}

DropZone.propTypes = {
	content: PropTypes.node.isRequired,
	entered: PropTypes.bool,
	onLeave: PropTypes.func,
	handleDroppedFiles: PropTypes.func
};

DropZone.defaultProps = {
	entered: false
};

function createOnDragLeave(entered, onLeave) {
	return event => {
		if (entered) {
			event.stopPropagation();
			event.preventDefault();
			callSafe(onLeave);
		}
	};
}

function createHandleOnDrop(onLeave, handleDroppedFiles) {
	return async event => {
		event.stopPropagation();
		event.preventDefault();
		const files = await fromEvent(event);
		callSafe(onLeave);
		if (files.length > 0) {
			callSafe(handleDroppedFiles, files);
		}
	};
}

function onDragOver(event) {
	event.stopPropagation();
	event.preventDefault();
}

export function useDropZone() {
	const [dropZoneActive, setDropZoneActive] = useState(false);
	const handleDragEnter = useCallback(event => {
		event.stopPropagation();
		event.preventDefault();
		if (!dropZoneActive) {
			setDropZoneActive(true);
		}
	}, [dropZoneActive, setDropZoneActive]);
	const handleDragLeave = useCallback(() => {
		setDropZoneActive(false);
	}, [setDropZoneActive]);
	return useMemoFactory(createDropZoneProps, handleDragEnter, handleDragLeave, dropZoneActive);
}

function createDropZoneProps(handleDragEnter, handleDragLeave, dropZoneActive) {
	return {
		dropZoneTriggerProps: {
			onDragEnter: handleDragEnter
		},
		dropZoneProps: {
			handleDragLeave,
			dropZoneActive
		}
	};
}

export const dropZonePropTypes = {
	dropZoneProps: PropTypes.exact({
		handleDragLeave: PropTypes.func,
		dropZoneActive: PropTypes.bool
	})
};
