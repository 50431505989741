import React from 'react';
import {glMatrix, vec2} from 'gl-matrix';
import PropTypes from 'prop-types';

import MarkerTouchArea from './MarkerTouchArea.js';
import MoveableSVGGroup from './MoveableSVGGroup.js';

export default function AnnotationHandle(props) {
	const {
		point, transformationMatrix, onMove, isPrintPreview, marker, handleOrientation, readOnly
	} = props;
	const referencePointInContainerCoordinates = vec2.transformMat3(vec2.create(), point, transformationMatrix);
	const MarkerComponent = marker;

	return (
		<MoveableSVGGroup className='annotation-handle' readOnly={readOnly} onMove={onMove}>
			{
				!readOnly && <MarkerTouchArea referencePoint={referencePointInContainerCoordinates}
				                              isPrintPreview={isPrintPreview} />
			}
			<MarkerComponent key='marker' referencePoint={referencePointInContainerCoordinates}
			                 isPrintPreview={isPrintPreview} orientation={handleOrientation} readOnly={readOnly} />
		</MoveableSVGGroup>
	);
}

AnnotationHandle.propTypes = {
	point: PropTypes.oneOfType([
		PropTypes.instanceOf(Float32Array),
		PropTypes.instanceOf(Float64Array)
	]),
	transformationMatrix: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	handleOrientation: PropTypes.instanceOf(glMatrix.ARRAY_TYPE),
	onMove: PropTypes.func,
	marker: PropTypes.elementType,
	isPrintPreview: PropTypes.bool,
	readOnly: PropTypes.bool
};

AnnotationHandle.defaultProps = {
	readOnly: false
};
