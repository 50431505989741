import Immutable from 'immutable';
import {createSelector} from 'reselect';

import FetchError from '../../../commons/api/FetchError.js';
import NotFoundError from '../../../commons/api/NotFoundError.js';
import {PATIENT_FILE_DISPLAY} from '../../../commons/constants/SynSettingsConstants';
import {getDicomSeries, getGenericFiles, isDicom} from '../../../commons/data/aim/DocumentHelpers.js';
import {getDocuments as getDocumentsForPatient, getFirstDocumentId} from '../../../commons/data/aim/PatientHelpers.js';
import {isDocumentUploadEnabled} from '../../../commons/selectors/GeneralConfigSelectors.js';
import {isHandled} from '../../../commons/utils/ErrorHandlingUtils.js';
import {fragmentSelector, querySelector} from '../../../router/flux/selectors/LocationSelectors.js';
import {
	ERROR_DOCUMENT_NOT_FOUND,
	ERROR_PATIENT_NOT_FOUND,
	ERROR_PATIENT_NOT_UNIQUE,
	ERROR_SHARE_NOT_FOUND
} from '../../constants/PatientDetailsLoaderErrors.js';
import {
	PATIENT_DETAILS_DISPLAY_FORMAT,
	PATIENT_DETAILS_LOAD_ERROR,
	PATIENT_DETAILS_QUERY_PARAMS
} from '../../constants/PatientDetailsPropertyNames.js';
import {
	getDocumentId,
	getNodeId,
	isCategoryNode,
	isDocumentNode,
	isRootNode
} from '../../data/PatientDetailsDataUtils.js';
import AppSepcificPatientDetailsSelectors from './AppSepcificPatientDetailsSelectors.js';

const ITEM_SPECIFIC_ERROR_MESSAGES = {
	documentshare: ERROR_SHARE_NOT_FOUND,
	document: ERROR_DOCUMENT_NOT_FOUND
};

const getOrNull = key => function getOrNullWithKey(obj) {
	return obj ? obj.get(key) : null;
};

export function getPatientDataStore(state) {
	return state.patientDetails;
}

export const {selectPatientDetailsId} = AppSepcificPatientDetailsSelectors;
export const {selectItemType} = AppSepcificPatientDetailsSelectors;

export const getQueryParametersUsedToLoadPatientDetailsData = createSelector(
	getPatientDataStore,
	getOrNull(PATIENT_DETAILS_QUERY_PARAMS)
);

export const selectHasPatientDetails = createSelector(
	getPatientDataStore,
	patientDetails => patientDetails.has('patient')
);

function getDefaultDisplayFormat() {
	return PATIENT_FILE_DISPLAY.length > 0 ? PATIENT_FILE_DISPLAY[0].configuration : null;
}

export const selectDisplayFormat = createSelector(
	getPatientDataStore,
	patientData => patientData.get(PATIENT_DETAILS_DISPLAY_FORMAT, null)
);

export const selectRequestedDisplayFormat = createSelector(
	querySelector, selectDisplayFormat,
	(query, storedDisplayFormat) => query.get(PATIENT_DETAILS_DISPLAY_FORMAT, storedDisplayFormat) ||
		getDefaultDisplayFormat()
);

export const hasMatchingPatientDetails = createSelector(
	getPatientDataStore,
	selectPatientDetailsId,
	selectItemType,
	selectDisplayFormat,
	selectRequestedDisplayFormat,
	(patientDetails, expectedPatientId, itemType, currentDisplayFormat, requestedDisplayFormat) => (patientDetails &&
			patientDetails.getIn(['patient', 'id'], null) === expectedPatientId &&
			patientDetails.get('onlyShares', false) === (itemType === 'documentshare') &&
			currentDisplayFormat === requestedDisplayFormat)
);

export const selectPatientDetails = createSelector(
	getPatientDataStore,
	hasMatchingPatientDetails,
	(patientDetails, isMatchinData) => (isMatchinData ? patientDetails : null)
);

export const selectPatient = createSelector(
	selectPatientDetails,
	patientDetails => (patientDetails ? patientDetails.get('patient') : null)
);

export const selectPatientAcceptsUploads = createSelector(
	selectPatient,
	isDocumentUploadEnabled,
	(patient, uploadEnabled) => uploadEnabled && (patient ? patient.get('accepts_uploads') : false)
);

export const selectDocuments = createSelector(
	selectPatientDetails,
	selectPatient,
	(patientDetails, patientInfo) => getDocumentsForPatient(patientDetails)
		.reduce((documents, document) => documents.set(document.get('id'), document.set('patient', patientInfo)), Immutable.OrderedMap())
);

export const selectNumDocuments = createSelector(
	selectDocuments,
	documents => documents.size
);

export const selectNumDocumentsRemoved = createSelector(
	selectPatientDetails,
	patientDetails => (patientDetails ? patientDetails.get('numRemovedDocuments') : 0)
);

const selectFirstDocumentId = createSelector(
	selectDocuments,
	getFirstDocumentId
);

export const selectFilterString = createSelector(
	getPatientDataStore,
	getOrNull('filterString')
);

export const isFiltered = createSelector(
	getPatientDataStore,
	patientData => patientData.has('filterString')
);

export const selectDocumentIdFromFragment = createSelector(
	fragmentSelector,
	fragment => {
		const match = fragment.match(/^document=([0-9]+)$/);
		return match === null ? null : parseInt(match[1], 10);
	}
);

export const selectInitiallySelectedDocumentIds = createSelector(
	selectDocumentIdFromFragment,
	selectFirstDocumentId,
	(docIdFromFragment, firstDocumentId) => ((firstDocumentId === null)
		? Immutable.Set()
		: Immutable.Set([docIdFromFragment === null ? firstDocumentId : docIdFromFragment]))
);

export const selectStructureNodes = createSelector(
	selectPatientDetails,
	patientDetails => (patientDetails ? patientDetails.get('structure') : Immutable.Map())
);

export const selectStructureHierarchy = createSelector(
	selectStructureNodes,
	structureNodes => {
		const nodeList = structureNodes.toList();
		const rootNodes = nodeList.filter(isRootNode);
		const isChildOf = parent => node => node.get('parent') === getNodeId(parent);

		function addChildrenRecursive(node) {
			let result = node;
			if (isCategoryNode(node)) {
				const childNodes = nodeList
					.filter(isChildOf(node))
					.map(addChildrenRecursive);
				result = result.set('children', childNodes);
			}
			return result;
		}

		return rootNodes.map(addChildrenRecursive);
	}
);

function processParentsMap(parentsMap) {
	return parentsMap.map(parents => {
		if (parents.last() !== null) {
			return parents.union(parentsMap.get(parents.last()));
		}
		return parents;
	});
}

const selectNodeParents = createSelector(
	selectStructureNodes,
	nodes => {
		let parentsMap = nodes.map(node => Immutable.Set([node.get('parent')]));
		while (!parentsMap.every(parents => parents.last() === null)) {
			parentsMap = processParentsMap(parentsMap);
		}
		return parentsMap;
	}
);

const selectMapDocumentIdsToNodeIds = createSelector(
	selectStructureNodes,
	nodes => nodes
		.filter(isDocumentNode)
		.mapEntries(([, documentNode]) => [getDocumentId(documentNode), getNodeId(documentNode)])
);

export const selectInitiallyExpandedCategoryIds = createSelector(
	selectStructureNodes,
	selectNodeParents,
	selectInitiallySelectedDocumentIds,
	selectMapDocumentIdsToNodeIds,
	(structure, nodeParents, initialDocuments, documentIds) => structure.reduce((expandedNodeIds, node) => {
		const nodeId = getNodeId(node);
		const categoryContainsInitialDocument = initialDocuments.some(
			documentFK => nodeParents.get(documentIds.get(documentFK), []).includes(nodeId)
		);
		const categoryHasToBeExpanded = isCategoryNode(node) && node.getIn(['nodeAttributes', 'expand']);

		if (categoryHasToBeExpanded || categoryContainsInitialDocument) {
			return expandedNodeIds.add(nodeId).union(nodeParents.get(nodeId));
		}
		return expandedNodeIds;
	}, Immutable.Set()).delete(null)
);

export const selectInitialItemToView = createSelector(
	selectInitiallySelectedDocumentIds,
	selectDocuments,
	(initialDocumentIds, documents) => {
		let firstItem = null;
		const firstItemId = initialDocumentIds.size === 0 ? null : initialDocumentIds.first();
		if (firstItemId !== null && documents.has(firstItemId)) {
			const firstDocument = documents.get(firstItemId);
			const items = isDicom(firstDocument) ? getDicomSeries(firstDocument) : getGenericFiles(firstDocument);
			firstItem = items.get(0, null);
		}
		return firstItem;
	}
);

export const selectAllSeries = createSelector(
	selectDocuments,
	documents => documents
		.reduce((allSeries, document) => {
			let dicomSeries;
			if (isDicom(document)) {
				dicomSeries = getDicomSeries(document)
					.reduce((series, singleSeries) => series
						.set(singleSeries.get('id'), singleSeries.set('document', document)), allSeries);
			}
			return dicomSeries ? dicomSeries : allSeries;
		}, Immutable.OrderedMap())
);

export const selectFiles = createSelector(
	selectDocuments,
	documents => documents
		.reduce((imageFiles, document) => {
			let genericFiles;
			if (!isDicom(document)) {
				genericFiles = getGenericFiles(document)
					.reduce((files, file) => files.set(file.get('id'), file.set('document', document)), imageFiles);
			}
			return genericFiles ? genericFiles : imageFiles;
		}, Immutable.OrderedMap())
);

export const selectTotalNumFiles = createSelector(
	selectFiles, selectAllSeries,
	(genericFiles, allSeries) => genericFiles.size + allSeries.reduce((numImages, series) => numImages + series.get('num_images'), 0)
);

export const selectFileMetadata = fileId => state => selectFiles(state).get(fileId);

export const selectCanLoad = createSelector(selectPatientDetailsId, id => id !== null);

export const getLoadError = createSelector(getPatientDataStore, store => store.get(PATIENT_DETAILS_LOAD_ERROR, null));

export function createSelectErrorForItemType(itemType) {
	return createSelector(getLoadError,
		loadError => (
			(!(loadError instanceof NotFoundError) && (loadError instanceof FetchError) && isHandled(loadError))
				? ERROR_PATIENT_NOT_UNIQUE
				: ITEM_SPECIFIC_ERROR_MESSAGES[itemType] || ERROR_PATIENT_NOT_FOUND
		)
	);
}
